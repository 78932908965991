import {
  ApiResponse,
  CommonApiListParams,
  CommonApiMetadata,
  CommonApiResponse,
} from '../service/api.endpoint';

export type ShipperFormValues = {
  name: string;
  phoneNumber?: string;
  email?: string;
};

// #region API
export type GetShippersCountApiRequest = {
  search?: string;
  isActive?: boolean;
};

export type GetShippersCountApiResponse = CommonApiResponse & {
  shippers: {
    totalCount: number;
  };
};

export interface GetShipperInfoApiRequest {
  id: string;
}

export type GetShipperInfoApiResponse = CommonApiResponse & {
  shipper: Shipper;
};

export interface UpdateShipperRequest {
  id: string;
  name?: string;
  phone_number?: string | null;
  email?: string | null;
}

export type UpdateShipperResponse = CommonApiResponse;

export type GetShippersApiRequest =
  | (CommonApiListParams & { isActive?: boolean; orderBy?: ShipperOrderBy })
  | undefined;

export type GetShippersApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    shippers: Shipper[];
  };

export type GetActiveShipperApiRequest = CommonApiListParams | undefined;
export type GetActiveShipperApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    shippers: Shipper[];
  };

export type DeactivateShipperRequest = { id: string };
export type DeactivateShipperResponse = ApiResponse;
// #endregion

export enum ShipperOrderBy {
  SHIPPER_NAME_ASC = 'SHIPPER_NAME_ASC', // Shipper Name A-Z (default)
  SHIPPER_NAME_DESC = 'SHIPPER_NAME_DESC', // Shipper Name Z-A
  STATUS_ASC = 'STATUS_ASC', // Status A-Z
  STATUS_DESC = 'STATUS_DESC', // Status Z-A
  CREATED_AT_DESC = 'CREATED_AT_DESC', // Recently Added
}

export interface Shipper {
  id: string;
  organizationId: string;
  name: string;
  createdAt: number;
  updatedAt: number;
  phoneNumber: string | null;
  email: string | null;
  isActive: boolean;
}

export interface PostShipperCreateParams {
  name: string;
  phoneNumber?: string | null;
  email?: string | null;
}
export type PostShipperCreateResponse = ApiResponse & {
  shipper: Pick<Shipper, 'id'>;
};

export type ExportShipperApiRequest = Exclude<GetShippersApiRequest, undefined>;

export type ExportShipperApiResponse = CommonApiResponse & {
  shipper: {
    fileUrl: string;
  };
};

export type ShipperSearchParam = {
  page?: string;
  pageSize?: string;
  search?: string;
  status?: string;
  sortBy?: string;
};
