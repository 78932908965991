import { isMobile } from 'react-device-detect';
import tw, { styled } from 'twin.macro';

export const HeadingOne = tw.span`font-sans color[#000000] font-bold font-size[40px] line-height[52px]`;
export const HeadingTwo = tw.span`font-sans font-semibold text-black font-size[32px] line-height[44px]`;
export const HeadingThree = tw.span`font-sans font-semibold text-black font-size[21px] leading-9`;
export const HeadingFour = tw.span`font-sans font-semibold text-black text-base`;
export const HeadingFive = tw.span`font-sans font-semibold text-black text-sm leading-6`;
export const BodyOne = tw.span`font-sans font-normal text-black font-size[16px] line-height[26px] letter-spacing[0.2px]`;
export const BodyTwo = styled.span(() => [
  tw`font-sans text-black text-sm leading-6 `,
  isMobile ? tw`` : tw`text-grey-two`,
]);
export const BodyThree = tw.span`font-sans font-normal text-xs leading-5 letter-spacing[0.2px] text-grey-two`;
export const BodyFour = tw.span`font-sans font-normal font-size[10px] line-height[18px] letter-spacing[0.2px] text-grey-two `;
export const BodyFourteen = tw.span`text-black font-normal text-sm leading-6`;
export const BodyTwelve = tw.span`text-black font-normal text-xs leading-5 letter-spacing[0.2px]`;
export const BodySmallTwelve = tw.span`text-black font-normal text-xs leading-5 letter-spacing[0.2px]`;
export const BodySixteen = tw.span`text-black font-normal font-size[16px] line-height[26px] letter-spacing[0.2px]`;
export const BodyTen = tw.span`font-sans font-normal font-size[10px] line-height[18px] letter-spacing[0.5px] text-black`;
export const Label = tw.span`font-sans font-normal text-sm leading-6 text-grey-two`;
export const LabelPrimaryBold = tw.span`font-sans font-semibold text-sm leading-6 text-orange`;
export const Paragraph = tw.p`font-sans font-normal text-sm leading-6 text-grey-two`;
export const TableParagraph = tw.p`font-sans font-normal text-base leading-5 text-black`;
export const TableParagraphPrimary = tw.p`font-sans font-semibold text-sm leading-6 text-orange`;

export const MobileHeadingTwo = tw.span`font-sans font-semibold text-black font-size[20px] leading-[30px]`;
export const MobileTwelve = tw.span`font-sans font-normal text-xs text-grey-two`;
export const MobileHeadingThree = tw.h3`font-sans font-semibold text-base text-black`;

export const LabelTen = tw.span`font-sans font-semibold font-size[10px] line-height[18px] letter-spacing[0.5px]`;

export const ButtonTwelve = tw.span`font-sans font-semibold text-xs leading-5 letter-spacing[0.5px]`;
export const BoldLabel = tw.span`font-bold`;

export const CTALink = tw.a`text-orange font-semibold hover:underline`;
export const DesktopExtraCardInfo = tw.span`font-size[32px] font-normal line-height[32px] text-black-primary`;
