import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, {
  HTMLProps,
  MutableRefObject,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import tw, { styled, theme } from 'twin.macro';
import { Modal } from '..';
import { ShipperOrderDetailTab, SOStatus } from '../../../constant';
import useTranslator, { UseTranslator } from '../../../hook/useTranslator.hook';
import { JOStatus } from '../../../model/JobOrder.model';
import {
  DeleteShipperOrderApiRequest,
  ShipperOrderInfo,
} from '../../../model/ShipperOrder.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { snackbarAction } from '../../../store/snackbar.store';
import { errorCodeToLabel } from '../../../util/error.util';
import { mapJOStatusToLabel } from '../../../util/jobOrder.util';
import { mapSOFilterStatusToLabel } from '../../../util/shipperOrder.util';
import { formatText } from '../../../util/tracking/trackingTimeline.util';
import { Icon, OutsideAlerter, Popover, Text } from '../../atom';
import IndeterminateCheckbox from '../../atom/Checkbox/IndeterminateCheckbox.atom';
import JobOrderStatus from '../JobOrderStatus/JobOrderStatus.molecule';
import ShipperOrderStatus from '../ShipperOrderStatus/ShipperOrderStatus.molecule';
import { SnackbarTheme } from '../Snackbar/Snackbar.molecule';

// #region INTERFACES
type Props = {
  soInfo: ShipperOrderInfo;
  onClickBack(): void;
  onTabChange(value: ShipperOrderDetailTab): void;
  onSelectJOId(value?: string): void;
  onDiscardConfirmation(): void;
  selectedJOId?: string;
  activeTab: ShipperOrderDetailTab;
  translator: UseTranslator;
};
type MenuProps = HTMLProps<HTMLButtonElement> & {
  customIcon?: ReactNode;
  submenu?: { label: string; onClick: () => void; selected: boolean }[];
};
type OptionDropdownProps = {
  soInfo: ShipperOrderInfo;
  onDiscardConfirmation(): void;
};
type JobOrderSectionProps = Pick<
  Props,
  'soInfo' | 'selectedJOId' | 'activeTab' | 'onSelectJOId' | 'onTabChange'
>;
// #endregion

// #region STYLED
const Container = tw.div`bg-white w-[365px] h-full flex-shrink-0 overflow-y-auto`;
const Header = tw.div`flex flex-row justify-between items-center p-5 space-x-2.5`;
const Submenu = styled.div(({ selected }: { selected: boolean }) => [
  tw`flex items-center px-4 h-14 rounded-md rounded-t-none text-base font-semibold cursor-pointer duration-200 hover:(bg-orange-hover) relative pl-8`,
  selected && tw`shadow-none bg-orange-hover text-orange!`,
]);
const ParagraphWithOnly2Lines = styled.p`
  ${tw`font-sans text-base whitespace-pre-line text-overflow[ellipsis] overflow-hidden font-semibold max-width[180px]`}
  & {
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
const OptionWrapper = tw(
  OutsideAlerter,
)`py-2.5 min-w-[86px] rounded-md shadow-card bg-white`;
const OptionTrigger = tw.button`rotate-90 rounded-md hover:bg-orange-hover`;
const OptionDelete = tw.button`w-full flex py-1.5 pl-2 pr-4 items-center space-x-1 text-status-alert hover:bg-orange-hover`;
const ModalBodyContainer = tw.div`flex flex-col py-5`;
// #endregion

function Menu({ selected, children, onClick, submenu, customIcon }: MenuProps) {
  const [toggle, setToggle] = useState(false);

  return (
    <div
      tw="flex flex-col h-[max-content] animate-slide-in-left"
      css={[submenu && tw`shadow-card rounded-md `]}
    >
      <button
        type="button"
        onClick={(ev) => {
          if (onClick) onClick(ev);

          setToggle((v) => !v);
        }}
        css={[
          tw`flex items-center mb-4 px-4 h-14 rounded-md text-base font-semibold cursor-pointer duration-200 hover:(bg-orange-hover) shadow-card relative`,
          selected && tw`shadow-none bg-orange-hover text-orange!`,
          submenu && tw`shadow-none relative mb-0 bg-white`,
          submenu &&
            toggle &&
            tw`mb-0 rounded-b-none bg-white hover:(bg-white)`,
        ]}
      >
        {children}
        {customIcon}
        {submenu && !customIcon && (
          <div
            css={[
              tw`svg:(w-4 h-4 stroke-[3px]) rotate-90 ml-auto text-black`,
              toggle && tw`-rotate-90`,
            ]}
          >
            <Icon.ChevronRounded />
          </div>
        )}
      </button>
      {submenu &&
        toggle &&
        submenu.map((v) => (
          <Submenu
            key={v.label}
            selected={v.selected}
            role="presentation"
            onClick={v.onClick}
          >
            {v.label}
          </Submenu>
        ))}
    </div>
  );
}

function OptionDropdown({
  soInfo,
  onDiscardConfirmation,
}: OptionDropdownProps) {
  const [triggerDeleteSO, deleteSOResponse] =
    api.useDeleteShipperOrderMutation();
  const { translate } = useTranslator();
  const dispatch = useDispatch();
  const optionDropdownRef = useRef<HTMLButtonElement>(null);
  const [state, setState] = useState({
    showConfirmation: false,
    showOptionDropdown: false,
  });

  const handleToggleDropown = () => {
    setState((prev) => ({
      ...prev,
      showOptionDropdown: !prev.showOptionDropdown,
    }));
  };
  const handleCloseDropdown = () => {
    setState((prev) => ({ ...prev, showOptionDropdown: false }));
  };

  const handleOpenConfirmation = () => {
    setState((prev) => ({ ...prev, showConfirmation: true }));
  };
  const handleCloseConfirmation = () => {
    setState((prev) => ({ ...prev, showConfirmation: false }));
  };

  const handleConfirmDelete = async () => {
    const payload: DeleteShipperOrderApiRequest = {
      id: soInfo.id,
    };

    try {
      await triggerDeleteSO(payload).unwrap();

      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.light,
          message: formatText(
            translate('Successfully deleted %s'),
            soInfo.number,
          ),
        }),
      );
    } catch (error) {
      const data = (error as FetchBaseQueryError)?.data as ApiErrorResponse;

      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translate(errorCodeToLabel(data.error.code)),
        }),
      );
    } finally {
      handleCloseConfirmation();
      onDiscardConfirmation();
    }
  };

  if (soInfo.status !== SOStatus.IN_PROCESS) return null;

  return (
    <>
      <OptionTrigger
        type="button"
        css={[state.showOptionDropdown && tw`bg-orange-hover`]}
        ref={optionDropdownRef}
        onClick={(e) => {
          e.stopPropagation();
          handleToggleDropown();
        }}
      >
        <Icon.MenuOthers width={30} height={30} />
      </OptionTrigger>

      <Popover
        visible={state.showOptionDropdown}
        targetRef={optionDropdownRef as MutableRefObject<null>}
        style={{ zIndex: 10, inset: '0.5rem auto auto -2.3rem' }}
      >
        <OptionWrapper onClickAway={handleCloseDropdown}>
          <OptionDelete type="button" onClick={handleOpenConfirmation}>
            <Icon.Delete width={16} height={16} viewBox="0 0 24 24" />
            <Text.ButtonTwelve>{translate('Delete')}</Text.ButtonTwelve>
          </OptionDelete>
        </OptionWrapper>
      </Popover>

      {state.showConfirmation && (
        <Modal.WithClose
          css={tw`p-8 w-[760px]`}
          actionButtonContainer={tw`justify-between`}
          title={translate('Are you sure to delete this Shipper Order?')}
          onClose={handleCloseConfirmation}
          onOverlayClick={handleCloseConfirmation}
          ctaStyle={tw`justify-between`}
          callAction={{
            label: translate(
              deleteSOResponse.isLoading ? 'Loading...' : 'Delete',
            ),
            action: handleConfirmDelete,
            disabled: deleteSOResponse.isLoading,
          }}
          cancelAction={{
            label: translate('Cancel'),
            action: handleCloseConfirmation,
            disabled: deleteSOResponse.isLoading,
          }}
        >
          <ModalBodyContainer>
            <Text.BodyOne tw="w-[650px]">
              {translate('Once deleted, this Shipper Order cannot be restored')}
            </Text.BodyOne>
          </ModalBodyContainer>
        </Modal.WithClose>
      )}
    </>
  );
}

function JobOrderSection({
  soInfo,
  activeTab,
  selectedJOId,
  onSelectJOId,
  onTabChange,
}: JobOrderSectionProps) {
  const translator = useTranslator();
  const [showDeletedJo, toggleShowDeletedJo] = useToggle(false);
  const includesDeletedJo = useMemo(
    () =>
      soInfo.deliveries.some(
        (_delivery) => _delivery.status === JOStatus.DELETED,
      ),
    [soInfo.deliveries],
  );
  const formattedDeliveries = useMemo(
    () =>
      soInfo.deliveries
        // filter deleted jo based on `showDeletedJo`
        .filter((_delivery) =>
          !showDeletedJo ? _delivery.status !== JOStatus.DELETED : true,
        )
        // sort by newest updates
        .sort(
          (a, b) => (b.jobOrder.updatedAt ?? 0) - (a.jobOrder.updatedAt ?? 0),
        ),
    [showDeletedJo, soInfo.deliveries],
  );
  const selectedJO = useMemo(
    () =>
      formattedDeliveries.find(
        (_delivery) => _delivery.jobOrder.id === selectedJOId,
      ),
    [formattedDeliveries, selectedJOId],
  );

  if (!soInfo.deliveries.length) return null;

  return (
    <>
      <div tw="flex justify-between items-center pt-2">
        <Text.HeadingFour>{translator.translate('Job Order')}</Text.HeadingFour>

        {includesDeletedJo && (
          <span tw="flex items-center place-self-start gap-2">
            <IndeterminateCheckbox
              rootStyle={tw`p-2`}
              checked={showDeletedJo}
              onChange={() => {
                toggleShowDeletedJo();

                // revert back to header section if jo info section currently selected
                if (selectedJO?.status === JOStatus.DELETED) {
                  onSelectJOId(undefined);
                  onTabChange(ShipperOrderDetailTab.HEADER);
                }
              }}
            />

            <Text.HeadingFour tw="text-grey-two text-sm">
              {translator.translate('Show Deleted JO')}
            </Text.HeadingFour>
          </span>
        )}
      </div>

      <div tw="flex flex-col gap-4 mt-3 mb-5">
        {formattedDeliveries.map(
          ({ jobOrder: { status, number, id: joId }, id }) => (
            <Menu
              key={id}
              selected={
                activeTab === ShipperOrderDetailTab.JO_INFO &&
                selectedJOId === joId
              }
              customIcon={
                <JobOrderStatus
                  status={status}
                  statusLabel={translator.translate(mapJOStatusToLabel(status))}
                  containerStyle={tw`ml-auto`}
                />
              }
              submenu={[
                {
                  label: translator.translate('Job Order Detail'),
                  onClick: () => {
                    onSelectJOId(joId);
                    onTabChange(ShipperOrderDetailTab.JO_INFO);
                  },
                  selected:
                    activeTab === ShipperOrderDetailTab.JO_INFO &&
                    selectedJOId === joId,
                },
              ]}
              onClick={() => {
                onSelectJOId(joId);
                onTabChange(ShipperOrderDetailTab.JO_INFO);
              }}
            >
              <ParagraphWithOnly2Lines
                css={[selectedJOId === joId && tw`text-orange`]}
              >
                {number}
              </ParagraphWithOnly2Lines>
            </Menu>
          ),
        )}
      </div>
    </>
  );
}

export default function InnerSidebar({
  onClickBack,
  onTabChange,
  onSelectJOId,
  onDiscardConfirmation,
  selectedJOId,
  activeTab,
  soInfo,
  translator,
}: Props) {
  return (
    <Container tw="px-0!">
      <Header>
        <button type="button" onClick={onClickBack}>
          <Icon.ArrowBack fill={theme`colors.orange.DEFAULT`} />
        </button>
        <Text.HeadingFour tw="flex-1 overflow-ellipsis overflow-hidden pr-4">
          {soInfo.number}
        </Text.HeadingFour>
        <ShipperOrderStatus
          status={soInfo.status}
          statusLabel={translator.translate(
            mapSOFilterStatusToLabel(soInfo.status),
          )}
        />

        <OptionDropdown
          soInfo={soInfo}
          onDiscardConfirmation={onDiscardConfirmation}
        />
      </Header>

      <div tw="px-5">
        <Menu
          selected={activeTab === ShipperOrderDetailTab.HEADER}
          onClick={() => {
            onTabChange(ShipperOrderDetailTab.HEADER);
            onSelectJOId(undefined);
          }}
        >
          {translator.translate('Header')}
        </Menu>
        <Menu
          selected={activeTab === ShipperOrderDetailTab.ACTIVITY}
          onClick={() => {
            onTabChange(ShipperOrderDetailTab.ACTIVITY);
            onSelectJOId(undefined);
          }}
        >
          {translator.translate('Activity')}
        </Menu>
        <Menu
          selected={activeTab === ShipperOrderDetailTab.DELIVERY_COST}
          onClick={() => {
            onTabChange(ShipperOrderDetailTab.DELIVERY_COST);
            onSelectJOId(undefined);
          }}
        >
          {translator.translate('Delivery Cost')}
        </Menu>

        {soInfo.status !== SOStatus.DELETED && (
          <Menu
            selected={activeTab === ShipperOrderDetailTab.TRACKING_ORDER}
            onClick={() => {
              onTabChange(ShipperOrderDetailTab.TRACKING_ORDER);
              onSelectJOId(undefined);
            }}
          >
            {translator.translate('Tracking Order')}
          </Menu>
        )}

        <JobOrderSection
          soInfo={soInfo}
          activeTab={activeTab}
          selectedJOId={selectedJOId}
          onSelectJOId={onSelectJOId}
          onTabChange={onTabChange}
        />
      </div>
    </Container>
  );
}
