import React from 'react';
import tw, { styled } from 'twin.macro';
import { UseHomeDashboardHookObj } from '../../../hook/home/useHomeDashboard.hook';
import useClickOutside from '../../../hook/useClickOutside.hook';
import { indonesianPhoneNumberFormat } from '../../../util/schedule.util';
import { Button, Icon, LoadingIndicator, Text } from '../../atom';

// #region INTERFACES
type DashboardWhatsappContentProps = {
  homeDashboard: UseHomeDashboardHookObj;
  isMobile?: boolean;
};
// #endregion

// #region STYLED
const FABContentContainer = tw.div`fixed z-20 bottom-28 right-8 background[rgb(0, 173, 66)] box-shadow[2.5px 5px 10px 0px rgba(0,0,0,0.5)] p-5 flex flex-col gap-2 rounded w-72 animate-slide-in-bottom duration-300 transition`;
const FABContentHeader = styled.div`
  ${tw`flex items-center justify-between`}

  > svg > path {
    fill: white;
  }
`;
// #endregion

function DashboardWhatsappContent({
  homeDashboard,
  isMobile,
}: DashboardWhatsappContentProps) {
  const ref = useClickOutside<HTMLDivElement>(
    homeDashboard.handleClickToggleFAB,
  );

  return (
    <FABContentContainer
      ref={ref}
      css={[isMobile && tw`bottom-40 right-4 max-width[calc(100% - 2rem)]`]}
    >
      <FABContentHeader>
        <Text.HeadingFour tw="text-white">
          {homeDashboard.ccTitleLabel}
        </Text.HeadingFour>
        <Icon.LogoWhatsapp />
      </FABContentHeader>

      <Text.BodyFourteen tw="max-width[288px] text-white whitespace-pre-wrap">
        {homeDashboard.ccSubtitleLabel}
      </Text.BodyFourteen>

      {homeDashboard.callCenterListIsFetching && (
        <LoadingIndicator size="small" color="white" />
      )}

      {homeDashboard.callCenterList?.map((item) => (
        <Button.Solid
          key={item.name + item.value}
          small
          tw="text-center text-white background[rgba(37, 37, 37, 0.7)] hover:background[rgba(37, 37, 37, 0.3)]"
          onClick={() => homeDashboard.handleClickCallCentre(item.value)}
          testID={`${homeDashboard.ccTestID}:FABWhatsappContent:ActionButton`}
        >
          {indonesianPhoneNumberFormat(item.value)}
        </Button.Solid>
      ))}

      <Button.Text
        small
        tw="border-solid border-b border-b-white w-min rounded-none text-white"
        onClick={homeDashboard.handleClickToggleFAB}
        testID={`${homeDashboard.ccTestID}:FABWhatsappContent:CancelButton`}
      >
        {homeDashboard.ccCancelLabel}
      </Button.Text>
    </FABContentContainer>
  );
}

export default DashboardWhatsappContent;
