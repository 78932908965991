import React from 'react';
import tw from 'twin.macro';
import { SOActivityGoodsType } from '../../../constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { GoodsFormValues } from '../../../model/ShipperOrder.model';
import { numberFormatter } from '../../../util/formatter.util';
import { getActivityGoodsTypeLabel } from '../../../util/shipperOrder.util';

const RelativeContainer = tw.div`relative`;
const CellContainer = tw.div`overflow-ellipsis overflow-hidden word-wrap[normal] whitespace-nowrap`;

export function Type({ type }: GoodsFormValues, translator?: UseTranslator) {
  if (translator)
    return translator?.translate(
      getActivityGoodsTypeLabel(type || SOActivityGoodsType.OTHERS),
    );

  return getActivityGoodsTypeLabel(type || SOActivityGoodsType.OTHERS);
}

export function Qty({ quantity }: GoodsFormValues) {
  return (
    <CellContainer tw="max-width[80px]">
      {quantity ? numberFormatter(quantity || 0) : '-'}
    </CellContainer>
  );
}

export function Description({ description }: GoodsFormValues) {
  return (
    <RelativeContainer>
      <CellContainer tw="max-width[150px]">{description || '-'}</CellContainer>
    </RelativeContainer>
  );
}

export function Uom({ uom }: GoodsFormValues) {
  return (
    <RelativeContainer>
      <CellContainer tw="max-width[40px]">{uom || '-'}</CellContainer>
    </RelativeContainer>
  );
}

export function Weight({ weight }: GoodsFormValues) {
  return (
    <CellContainer tw="max-width[80px]">
      {weight && !Number.isNaN(parseFloat(String(weight)))
        ? numberFormatter(String(weight).replace(',', '.'))
        : 0}
    </CellContainer>
  );
}

export function Volume({ volume }: GoodsFormValues) {
  return (
    <CellContainer tw="max-width[90px] ">
      {volume && !Number.isNaN(parseFloat(String(volume)))
        ? numberFormatter(String(volume).replace(',', '.'))
        : 0}
    </CellContainer>
  );
}
