import React from 'react';

export default function NavReports({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 16C16 14.8954 16.8954 14 18 14H22V20C22 21.1046 21.1046 22 20 22H18C16.8954 22 16 21.1046 16 20V16ZM20 16H18V20H20V16Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2 2.89543 2.89543 2 4 2H16C17.1046 2 18 2.89543 18 4V22H4C2.89543 22 2 21.1046 2 20V4ZM16 4L4 4V20H16V4Z"
      />
      <path d="M6 6H14V8H6V6Z" />
      <path d="M6 10H14V12H6V10Z" />
      <path d="M6 14H11V16H6V14Z" />
    </svg>
  );
}
