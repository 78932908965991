import { Locale } from './locale.config';

const homeLocaleConfig: Locale[] = [
  {
    en: 'Operational',
    id: 'Operasional',
  },
  {
    en: 'Shipper Order in Progress',
    id: 'Shipper Order Sedang Berjalan',
  },
  {
    en: 'Shipper Order Delivered',
    id: 'Shipper Order Terkirim',
  },
  {
    en: 'Job Order in Progress',
    id: 'Perintah Kerja Sedang Berjalan',
  },
  {
    en: 'Job Order Delivered',
    id: 'Perintah Kerja Selesai',
  },
  {
    en: 'Summary of Shipper Order and Job Order',
    id: 'Ringkasan Shipper Order & Perintah Kerja',
  },
  {
    en: 'Last 6 months',
    id: '6 bulan terakhir',
  },
  {
    en: 'Last month',
    id: 'Bulan lalu',
  },
  {
    en: 'This month',
    id: 'Bulan ini',
  },
  {
    en: 'Last 3 months',
    id: '3 bulan terakhir',
  },
  {
    en: 'Last 1 year',
    id: '1 tahun terakhir',
  },
  {
    en: 'Financial',
    id: 'Keuangan',
  },
  {
    en: 'Current Month Revenue',
    id: 'Pendapatan Bulan Ini',
  },
  {
    en: 'Total Revenue',
    id: 'Total Pendapatan',
  },
  {
    en: 'Summary of Revenue',
    id: 'Ringkasan Pendapatan',
  },
  {
    en: 'Monthly Revenue Trend',
    id: 'Grafik Pendapatan Bulanan',
  },
  {
    en: 'Need Help?',
    id: 'Butuh Bantuan?',
  },
  {
    en: 'Please contact our Call Center for further assistance',
    id: 'Silahkan menghubungi Call Center kami untuk bantuan lebih lanjut',
  },
  {
    en: 'No, thank you',
    id: 'Tidak, terima kasih',
  },
  {
    en: 'Good Afternoon. I want to ask...',
    id: 'Selamat Siang. Saya ingin bertanya...',
  },
  {
    en: 'days',
    id: 'hari',
  },
  {
    en: 'day',
    id: 'hari',
  },
  {
    en: 'Largest Receivables Amount',
    id: 'Piutang Terbesar',
  },
  {
    en: 'Longest Receivables Due',
    id: 'Piutang Terlama',
  },
  {
    en: 'Last 12 Months Revenue',
    id: 'Pendapatan 12 Bulan Terakhir',
  },
  {
    en: 'Last 12 Months Net Income',
    id: 'Keuntungan 12 Bulan Terakhir',
  },
  {
    en: 'Current Month Revenue',
    id: 'Pendapatan Bulan Ini',
  },
  {
    en: 'Current Month Payment',
    id: 'Pembayaran Bulan Ini',
  },
  {
    en: 'Total Receivables',
    id: 'Total Piutang',
  },
  {
    en: 'Net Income',
    id: 'Keuntungan',
  },
  {
    en: 'Total Net Income',
    id: 'Total Keuntungan',
  },
  {
    en: "Swipe to see other month's data",
    id: 'Geser untuk lihat data di bulan lainnya',
  },
  {
    en: 'Average in last 3 months',
    id: 'Rata-rata 3 bulan terakhir',
  },
  {
    en: 'Average in last 6 months',
    id: 'Rata-rata 6 bulan terakhir',
  },
  {
    en: 'Average in last 1 year',
    id: 'Rata-rata 1 tahun terakhir',
  },
  {
    en: 'January',
    id: 'Januari',
  },
  {
    en: 'February',
    id: 'Februari',
  },
  {
    en: 'March',
    id: 'Maret',
  },
  {
    en: 'April',
    id: 'April',
  },
  {
    en: 'May',
    id: 'Mei',
  },
  {
    en: 'June',
    id: 'Juni',
  },
  {
    en: 'July',
    id: 'Juli',
  },
  {
    en: 'August',
    id: 'Agustus',
  },
  {
    en: 'September',
    id: 'September',
  },
  {
    en: 'October',
    id: 'Oktober',
  },
  {
    en: 'November',
    id: 'November',
  },
  {
    en: 'December',
    id: 'Desember',
  },
  {
    en: 'mio',
    id: 'jt',
  },
  {
    en: 'bio',
    id: 'm',
  },
  {
    en: 'Shipper indicates the sender or the individual which place the shipment order to your organization.',
    id: 'Pengirim merupakan individu yang membuat order pengiriman menggunakan jasa perusahaan Anda.',
  },
  {
    en: 'Delivery Location is the targeted address(es) to pickup or drop-off goods based on the shipment order.',
    id: 'Lokasi Pengiriman adalah alamat yang dituju untuk pickup (mengambil) atau dropoff (mengantar) dalam sebuah order pengiriman.',
  },
  {
    en: 'A Shipper Order contains activities which need to be done in a delivery locations by your organization (standby, pickup, or drop-off) to carry Shipper shipment order.',
    id: 'Shipper Order berisi aktivitas yang harus dilakukan oleh organisasi Anda pada lokasi pengiriman (standby, pickup, atau dropoff) untuk menyelesaikan sebuah order pengiriman dari Pengirim.',
  },
  {
    en: 'Driver is the individual which assigned by your organization to carry a set of Shipper Orders of an Job Order and perform its activities.',
    id: 'Sopir merupakan individu yang ditugaskan oleh Anda untuk menyelesaikan serangkaian aktivitas dari satu atau lebih Shipper Order dalam sebuah Perintah Kerja.',
  },
  {
    en: 'A Job Order is a work order for a Driver that contains one or more Shipper Order(s) to be delivered by driver and vehicle assigned to complete the work order.',
    id: 'Perintah Kerja merupakan surat tugas untuk seorang Sopir yang berisi satu atau lebih Shipper Order(s) untuk dikirimkan oleh sopir dan kendaraan yang ditugaskan untuk menyelesaikan Perintah kerja tersebut.',
  },
  {
    en: 'Add a Shipper and a Location to start creating a Shipper Order',
    id: 'Tambahkan Pengirim dan Lokasi Pengiriman baru untuk mulai membuat Shipper Order.',
  },
  {
    en: 'Assign a Shipper Order to a Job Order that is assigned to a Driver',
    id: 'Masukkan Shipper Order ke dalam Perintah Kerja yang telah ditugaskan ke Sopir.',
  },
  {
    en: 'Need Shipper and Location',
    id: 'Butuh Pengirim dan Lokasi Pengiriman',
  },
  {
    en: 'Need Driver and Shipper Order',
    id: 'Butuh Sopir dan Shipper Order',
  },
  {
    en: 'Onboarding Progress',
    id: 'Tahapan Orientasi',
  },
  {
    en: 'Onboarding Progress Tracker',
    id: 'Lacak Progress Orientasi',
  },
  {
    en: 'You can always check your Onboarding progress by clicking this button to show what steps are remained',
    id: 'Anda dapat melihat progress orientasi dengan menekan tombol diatas untuk mengetahui tugas yang belum terselesaikan',
  },
  {
    en: 'Onboarding Main Page',
    id: 'Halaman Utama Orientasi',
  },
  {
    en: 'Click this button to go back to the onboarding main page',
    id: 'Tekan tombol ini untuk kembali ke halaman utama orientasi',
  },
  {
    en: 'View Delivery Location List',
    id: 'Lihat Daftar Lokasi Pengiriman',
  },
  {
    en: 'View Shipper List',
    id: 'Lihat Daftar Pengirim',
  },
  {
    en: 'View Driver List',
    id: 'Lihat Daftar Sopir',
  },
  {
    en: 'View Shipper Order List',
    id: 'Lihat Daftar Shipper Order',
  },
  {
    en: 'View Job Order List',
    id: 'Lihat Daftar Perintah Kerja',
  },
  {
    en: 'Summary of SO & JO',
    id: 'Ringkasan SO & JO',
  },
  {
    en: 'Average in last month',
    id: 'Rata-rata bulan lalu',
  },
  {
    en: 'Average in this month',
    id: 'Rata-rata bulan ini',
  },
  {
    en: 'Monthly Revenue',
    id: 'Pendapatan Bulanan',
  },
  {
    en: 'Monthly Net Income',
    id: 'Keuntungan Bulanan',
  },
  {
    en: 'Explore',
    id: 'Telusuri',
  },
  {
    en: 'The mobile version is currently incomplete. For access to all available features, please use the desktop version.',
    id: 'Versi seluler saat ini belum lengkap. Untuk mengakses semua fitur yang tersedia, silakan gunakan versi desktop.',
  },
  {
    en: 'Only calculate orders that were successfully delivered with following calculations:',
    id: 'Hanya menghitung order yang berhasil terkirim dengan perhitungan berikut:',
  },
  {
    en: 'Net Income Percentage',
    id: 'Presentase Keuntungan',
  },
  {
    en: 'Net Income / Revenue',
    id: 'Keuntungan / Pendapatan',
  },
  {
    en: 'Revenue - Total Expense',
    id: 'Pendapatan - Total Pengeluaran',
  },
  {
    en: 'Late',
    id: 'Terlambat',
  },
  {
    en: 'On time',
    id: 'Tepat Waktu',
  },
  {
    en: '%s = Revenue - Total Expense',
    id: '%s = Pendapatan - Total Pengeluaran',
  },
];

export default homeLocaleConfig;
