import 'twin.macro';
import useTranslator from '../../hook/useTranslator.hook';
import useScheduleRemoveLogic from './hooks/useScheduleRemoveLogic.hook';
import useScheduleRemoveTable from './hooks/useScheduleRemoveTable.hook';

export default function useScheduleRemoveViewModel() {
  const translator = useTranslator();

  const logic = useScheduleRemoveLogic();
  const { table } = useScheduleRemoveTable({ logic });

  return {
    translator,
    logic,
    table,
  };
}
