import React from 'react';

export default function CustomVehicleMarker() {
  return (
    <svg width={24} height={57} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.178 17.205c.07-.225.108-.462.108-.705V4.433c0-.335-.042-.984-.484-1.586C20.857 1.562 18.402 0 12 0 5.6 0 3.143 1.562 2.198 2.847c-.442.602-.484 1.25-.484 1.586V16.5c0 .243.038.48.108.705C.842 17.435 0 18.28 0 19.457v34.8c0 1.388 1.169 2.314 2.357 2.314h19.286c1.188 0 2.357-.926 2.357-2.314v-34.8c0-1.177-.841-2.023-1.822-2.252Zm-.535 1.652c.355 0 .643.269.643.6v34.8c0 .332-.288.6-.643.6H2.357c-.355 0-.643-.268-.643-.6v-34.8c0-.331.288-.6.643-.6h19.286ZM3.579 3.863c-.12.162-.15.366-.15.57V16.5c0 .355.279.643.623.643h15.896a.633.633 0 0 0 .623-.643V4.433c0-.204-.03-.408-.15-.57-.46-.625-2.215-2.149-8.42-2.149-6.207 0-7.963 1.524-8.422 2.149Z"
        fill="#fff"
      />
      <path
        d="M1.714 19.457c0-.331.288-.6.643-.6h19.286c.355 0 .643.269.643.6v34.8c0 .331-.288.6-.643.6H2.357c-.355 0-.643-.269-.643-.6v-34.8Z"
        fill="#EECD4A"
      />
      <path
        d="M5.143 17.143h13.714v1.714H5.143v-1.714ZM3.429 52.286H20.57v.857H3.43v-.857Z"
        fill="#252525"
      />
      <path
        d="M3.429 4.433c0-.204.03-.408.15-.57.46-.625 2.215-2.149 8.421-2.149 6.206 0 7.962 1.524 8.42 2.148.12.163.151.367.151.57V16.5c0 .355-.279.643-.623.643H4.052a.633.633 0 0 1-.623-.643V4.433Z"
        fill="#EECD4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.302c-2.339 0-4.244.783-5.418 1.265-.246.102-.46.19-.64.255a.612.612 0 0 1-.748-.305L4.37 5.324c-.12-.222-.119-.505.05-.684C5.215 3.8 7.47 2.57 12 2.57s6.786 1.23 7.58 2.069c.169.179.17.462.05.684l-.823 1.193a.612.612 0 0 1-.749.305c-.179-.065-.393-.153-.64-.254C16.246 6.085 14.34 5.301 12 5.301ZM4.286 6.857c.086.182.204.347.324.515.26.364.533.747.533 1.37v4.115h-.857v-6Zm15.105.515c.12-.168.237-.333.323-.515v6h-.857V8.743c0-.624.274-1.007.534-1.371ZM4.286 13.714h.857v1.715h-.857v-1.715Zm14.571 0h.858v1.715h-.858v-1.715Z"
        fill="#252525"
      />
    </svg>
  );
}
