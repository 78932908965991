import React from 'react';
import tw, { styled } from 'twin.macro';
import { JOStatus } from '../../../model/JobOrder.model';
import { Text } from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region INTERFACES
type StatusProps = { status: JOStatus };
type Props = {
  status: JOStatus;
  statusLabel: string;
  containerStyle?: ExtraStyle;
};
// #endregion

// #region STYLED
const StatusContainer = styled.span(({ status }: StatusProps) => [
  tw`px-2 py-0.5 inline-block font-bold rounded-md`,
  status === JOStatus.ASSIGNED && tw`bg-assigned-light`,
  status === JOStatus.DELIVERING && tw`bg-delivering-light`,
  status === JOStatus.DELIVERED && tw`bg-delivered-light`,
  status === JOStatus.CANCELLED && tw`bg-canceled-dark`,
  status === JOStatus.DELETED && tw`bg-deleted-light`,
  status === JOStatus.DRAFT && tw`bg-draft-light`,
]);
const SquareIcon = styled.span(({ status }: StatusProps) => [
  tw`inline-block rounded-sm width[10px] height[10px] mr-2`,
  status === JOStatus.ASSIGNED && tw`bg-assigned-dark`,
  status === JOStatus.DELIVERING && tw`bg-delivering-dark`,
  status === JOStatus.DELIVERED && tw`bg-delivered-dark`,
  status === JOStatus.CANCELLED && tw`bg-canceled-light`,
  status === JOStatus.DELETED && tw`bg-deleted-dark`,
  status === JOStatus.DRAFT && tw`bg-draft-dark`,
]);
// #endregion

function JobOrderStatus({ status, statusLabel, containerStyle }: Props) {
  return (
    <StatusContainer status={status} css={containerStyle}>
      <SquareIcon status={status} />

      <Text.BodyTwo
        css={[
          status === JOStatus.ASSIGNED && tw`text-assigned-dark`,
          status === JOStatus.CANCELLED && tw`text-canceled-light`,
          status === JOStatus.DELETED && tw`text-deleted-dark`,
          status === JOStatus.DELIVERED && tw`text-delivered-dark`,
          status === JOStatus.DELIVERING && tw`text-delivering-dark`,
          status === JOStatus.DRAFT && tw`text-draft-dark`,
        ]}
      >
        {statusLabel}
      </Text.BodyTwo>
    </StatusContainer>
  );
}

export default JobOrderStatus;
