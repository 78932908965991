import { Locale } from './locale.config';

const detailJoOrderLocaleConfig: Locale[] = [
  {
    en: '%s has been successfully completed',
    id: '%s berhasil diselesaikan',
  },
  {
    en: 'Unknown vehicle or driver position',
    id: 'Posisi kendaraan atau sopir tidak diketahui',
  },
  {
    en: 'more locations...',
    id: 'lokasi lain...',
  },
  {
    en: 'JO Detail',
    id: 'Detail Perintah Kerja',
  },
  {
    en: 'Overview',
    id: 'Ringkasan',
  },
  {
    en: 'Details',
    id: 'Detail',
  },
  {
    en: 'Images',
    id: 'Gambar',
  },
  {
    en: 'Photo(s)',
    id: 'Foto',
  },
  {
    en: 'Fail to reject the expense. Please reload this page.',
    id: 'Gagal menolak pengeluaran. Silahkan memuat ulang halaman ini.',
  },
  {
    en: 'Amount',
    id: 'Jumlah',
  },
  {
    en: 'Force Complete Job Order',
    id: 'Selesaikan Perintah Kerja',
  },
  {
    en: 'Force Complete Shipper Order',
    id: 'Selesaikan Shipper Order',
  },
  {
    en: 'Force Complete Activity',
    id: 'Selesaikan Aktivitas',
  },
  {
    en: "You don't have any selected Activities to complete",
    id: 'Tidak ada aktivitas terpilih untuk diselesaikan',
  },
  {
    en: 'Back to Job Order Detail page and select active Shipper Order',
    id: 'Kembali ke halaman Detail Job Order dan pilih Shipper Order yang aktif',
  },
  {
    en: 'Try a different search terms so we can show you some available Activities',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Aktivitas yang tersedia',
  },
  {
    en: 'Search by Location',
    id: 'Cari berdasarkan Lokasi',
  },
  {
    en: 'No Shipper Order selected for this Job Order',
    id: 'Tidak ada Shipper Order terpilih untuk Perintah Kerja ini',
  },
  {
    en: 'There is no Change History',
    id: 'Tidak ada Riwayat Perubahan',
  },
  {
    en: 'No Changes have been made to this Job Order',
    id: 'Tidak ada Perubahan yang dibuat di Perintah Kerja ini',
  },
  {
    en: 'You can search by Location name and address',
    id: 'Anda bisa mencari berdasarkan nama dan alamat Lokasi',
  },
  {
    en: 'Force Complete Succeeded',
    id: 'Berhasil diselesaikan',
  },
  {
    en: 'All Activities have been completed!',
    id: 'Semua Aktivitas sudah diselesaikan',
  },
  {
    en: 'Click this button below to go back to Job Order Detail',
    id: 'Klik tombol di bawah untuk kembali ke Detail Job Order',
  },
  {
    en: 'Back to Job Order Detail',
    id: 'Kembali ke Detail Job Order',
  },
  {
    en: 'You can search by Location Name or Address',
    id: 'Cari menggunakan Nama atau Alamat Lokasi',
  },
  {
    en: 'Show %s Activities',
    id: 'Tampilkan %s Aktivitas',
  },
  {
    en: 'Activity %s',
    id: 'Aktivitas %s',
  },
  {
    en: 'Delivery already completed',
    id: 'Pengiriman sudah selesai',
  },
  {
    en: 'Activity already completed',
    id: 'Aktivitas sudah selesai',
  },
  {
    en: 'Expense List',
    id: 'Daftar Pengeluaran',
  },
  {
    en: 'Expense Number',
    id: 'Nomor Pengeluaran',
  },
  {
    en: 'Category',
    id: 'Kategori',
  },
  {
    en: 'Submitted At',
    id: 'Diajukan Pada',
  },
  {
    en: 'Expense Amount',
    id: 'Jumlah Pengeluaran',
  },
  {
    en: 'Expenses',
    id: 'Pengeluaran',
  },
  {
    en: 'Travel Budget',
    id: 'Uang Jalan',
  },
  {
    en: 'Remaining Travel Budget',
    id: 'Sisa Uang Jalan',
  },
  {
    en: 'Total Expense',
    id: 'Total Pengeluaran',
  },
  {
    en: 'Remaining Budget',
    id: 'Sisa Uang Jalan',
  },
  {
    en: 'Need Review',
    id: 'Perlu Ditinjau',
  },
  {
    en: 'Approved',
    id: 'Disetujui',
  },
  {
    en: 'Tolls',
    id: 'Tol',
  },
  {
    en: 'Tolls / Roads Fees',
    id: 'Tol',
  },
  {
    en: 'Fuels',
    id: 'Bensin',
  },
  {
    en: 'Fuel',
    id: 'Bensin',
  },
  {
    en: 'Parking Fees',
    id: 'Parkir',
  },
  {
    en: 'Expense Number A-Z',
    id: 'Nomor Pengeluaran A-Z',
  },
  {
    en: 'Expense Number Z-A',
    id: 'Nomor Pengeluaran Z-A',
  },
  {
    en: 'Newest Submission',
    id: 'Pengajuan Terbaru',
  },
  {
    en: 'Oldest Submission',
    id: 'Pengajuan Terlama',
  },
  {
    en: 'Amount',
    id: 'Jumlah',
  },
  {
    en: 'Approve Expenses',
    id: 'Setujui Pengeluaran',
  },
  {
    en: 'This operation cannot be performed for multiple expenses',
    id: 'Tindakan ini tidak dapat dilakukan untuk lebih dari satu pengeluaran',
  },
  {
    en: 'This operation cannot be performed for approved/rejected expense',
    id: 'Tindakan ini tidak dapat dilakukan untuk pengeluaran yang telah disetujui atau ditolak',
  },
  {
    en: 'Reject Expense',
    id: 'Tolak Pengajuan',
  },
  {
    en: 'Are you sure to reject',
    id: 'Apa anda setuju menolak',
  },
  {
    en: 'Reject Notes',
    id: 'Alasan Penolakan',
  },
  {
    en: 'Reject',
    id: 'Tolak',
  },
  {
    en: 'Rejected',
    id: 'Ditolak',
  },
  {
    en: 'Expense Rejected',
    id: 'Pengeluaran Ditolak',
  },
  {
    en: 'Expense Approved!',
    id: 'Pengeluaran Diterima!',
  },
  {
    en: 'Expense Re-submitted',
    id: 'Pengeluaran Diajukan Kembali',
  },
  {
    en: 'Expense Submitted',
    id: 'Pengeluaran Dikirim',
  },
  {
    en: 'Approve',
    id: 'Setuju',
  },
  {
    en: 'Approved',
    id: 'Disetujui',
  },
  {
    en: 'Successfully Approved',
    id: 'Berhasil Menyetujui',
  },
  {
    en: 'Approval Succeed',
    id: 'Berhasil Disetujui',
  },
  {
    en: 'Successfully Rejected',
    id: 'Berhasil Menolak',
  },
  {
    en: 'Resubmitted',
    id: 'Diajukan Kembali',
  },
  {
    en: 'Descriptions',
    id: 'Keterangan',
  },
  {
    en: 'Tolls/ Road Fees',
    id: 'Uang Tol/ Perjalanan',
  },
  {
    en: 'Max 160 characters.',
    id: 'Maksimal 160 karakter.',
  },
  {
    en: 'Are you sure to delete this job order?',
    id: 'Apakah Anda yakin untuk menghapus perintah kerja ini?',
  },
  {
    en: 'Job Order already assigned.',
    id: 'Perintah Kerja sudah ditugaskan.',
  },
  {
    en: 'Job Order already deleted.',
    id: 'Perintah Kerja sudah dihapus.',
  },
  {
    en: 'Job Order deleted successfully.',
    id: 'Perintah Kerja berhasil dihapus.',
  },
  {
    en: 'Once deleted, this job order cannot be restored',
    id: 'Setelah dihapus, perintah kerja ini tidak dapat dipulihkan',
  },
  {
    en: 'Make sure the list below is the job order list that you want to delete from your organization. Deleted Job Order still can be accessed in Job Order List page.',
    id: 'Pastikan daftar di bawah ini adalah daftar perintah kerja yang akan Anda hapus dari organisasi Anda. Perintah Kerja yang telah Dihapus masih dapat diakses pada halaman Daftar Perintah Kerja.',
  },
  {
    en: 'Activity %s • Proof of Activity',
    id: 'Aktivitas %s • Bukti kirim',
  },
  {
    en: 'Expense Image',
    id: 'Bukti Pengeluaran',
  },
  {
    en: 'Changes History',
    id: 'Riwayat Perubahan',
  },
  {
    en: 'Job Order assigned',
    id: 'Perintah Kerja ditugaskan',
  },
  {
    en: 'Job Order delivered',
    id: 'Perintah Kerja terkirim',
  },
  {
    en: 'Detail is edited',
    id: 'Detail diubah',
  },
  {
    en: 'Changing Driver from',
    id: 'Mengubah Sopir dari',
  },
  {
    en: 'Changing Vehicle from',
    id: 'Mengubah Kendaraan dari',
  },
  {
    en: 'Changing Seal Number from',
    id: 'Mengubah Nomor Segel dari',
  },
  {
    en: 'Changing Travel Budget from',
    id: 'Mengubah Uang Jalan dari',
  },
  {
    en: 'Travel Expense has been added',
    id: 'Uang Jalan telah ditambahkan',
  },
  {
    en: 'Seal Number has been added',
    id: 'Nomor Segel telah ditambahkan',
  },
  {
    en: 'Travel Expense has been removed',
    id: 'Uang Jalan telah dihapus',
  },
  {
    en: 'Seal Number has been removed',
    id: 'Nomor Segel telah dihapus',
  },
  {
    en: 'Status is updated',
    id: 'Status diperbarui',
  },
  {
    en: 'Job Order is assigned',
    id: 'Perintah Kerja telah ditugaskan',
  },
  {
    en: 'Job Order started by',
    id: 'Perintah Kerja dimulai oleh',
  },
  {
    en: 'Job Order finished by',
    id: 'Perintah Kerja diselesaikan oleh',
  },
  {
    en: 'and status updated from',
    id: 'dan status diperbarui dari',
  },
  {
    en: 'Total Expenses exceeded Travel Budget',
    id: 'Jumlah Pengeluaran melebihi Uang Jalan',
  },
  {
    en: 'Travel Budget updated from',
    id: 'Uang Jalan diperbarui dari',
  },
  {
    en: 'Show Deleted JO',
    id: 'Tampilkan JO Dihapus',
  },
];

export default detailJoOrderLocaleConfig;
