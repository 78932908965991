import React from 'react';
import tw from 'twin.macro';
import { TextArea } from '..';
import { LabelInput, Row } from '../../atom';

type Props = {
  label?: string;
  isRequired?: boolean;
  id: string;
  values?: string;
  error?: string;
  placeholder?: string;
  onBlur?: (data?: string) => void;
  onChange: (data?: string) => void;
  autoSize?: boolean;
  disabled?: boolean;
};

const Container = tw.div`flex-1`;
export default function TextInput({
  label,
  isRequired,
  placeholder,
  error,
  id,
  values,
  onBlur = () => {},
  onChange,
  autoSize,
  disabled,
}: Props) {
  return (
    <Row>
      {label && (
        <LabelInput position="start" label={label} isRequired={isRequired} />
      )}
      <Container>
        <TextArea
          onBlur={(e) => onBlur(e.target.value)}
          css={[
            tw`pt-3 pb-2.5`,
            (!values || values === '-') && tw`resize-none`,
          ]}
          id={id}
          name={id}
          error={error}
          value={values ?? ''}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          autoSize={autoSize}
          disabled={disabled}
        />
      </Container>
    </Row>
  );
}
