import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fillPath?: string;
};

export default function EditV2({
  width = 16,
  height = 20,
  viewBox = '0 0 16 20',
  fill = 'none',
  fillPath = 'currentColor',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <path
        fill={fillPath}
        d="M15.045 5.401c.378-.378.586-.88.586-1.414 0-.534-.208-1.036-.586-1.414L13.459.987a1.986 1.986 0 00-1.414-.586c-.534 0-1.036.208-1.413.585L0 11.585V16h4.413L15.045 5.401zm-3-3l1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM2 14v-1.585l7.04-7.018 1.586 1.586L3.587 14H2zm-2 4h16v2H0v-2z"
      />
    </svg>
  );
}
