import {
  JobOrderExpenseOrderBy,
  JobOrderExpenseStatus,
  JobOrderExpenseType,
} from '../model/JobOrder.model';

export enum JODetailTab {
  SO_LIST = 'so-list',
  SPENDING_REPORT = 'spending-report',
  EVENT_HISTORY = 'event-history',
}

export type JODetailSpendingReportFilterOrderByValue = {
  label: string;
  value: JobOrderExpenseOrderBy;
};
export type JODetailSpendingReportFilterCategoryValue = {
  label: string;
  value: JobOrderExpenseType;
};
export type JODetailSpendingReportFilterStatusValue = {
  label: string;
  value: JODetailSpendingReportFilterStatus;
};
export enum JODetailSpendingReportFilterStatus {
  NEED_REVIEW = 'NEED_REVIEW', // JobOrderExpenseStatus.PENDING
  APPROVED = 'APPROVED', // JobOrderExpenseStatus.APPROVED
  REJECTED = 'REJECTED', // JobOrderExpenseStatus.REJECTED
}
export type JODetailSpendingReportFilterInitialValues = {
  orderBy: JobOrderExpenseOrderBy;
  category: JobOrderExpenseType[];
  status: JODetailSpendingReportFilterStatus[];
};

export type JODetailSpendingProps = {
  joId: string;
  id: string;
  number: string;
};

/**
 * polling for current JO status based on SO status
 */
export const MAX_POLLING_ATTEMPT = 3;

/**
 * Interval time for refresh vehicle last position
 */
export const intervalDelay = 10 * 1000;

/**
 * initial values in list hook in type of number from `JobOrderExpenseStatus` instead of string from `JODetailSpendingReportFilterStatus`
 */
export const joDetailSpendingReportStatusFilterInitialValues = [
  JobOrderExpenseStatus.PENDING,
];

export const joDetailSpendingReportFilterAllStatus = Object.values(
  JODetailSpendingReportFilterStatus,
);

export const joDetailSpendingReportFilterInitialValues: JODetailSpendingReportFilterInitialValues =
  {
    orderBy: JobOrderExpenseOrderBy.SUBMITTED_AT_ASC,
    category: Object.values(JobOrderExpenseType),
    status: [JODetailSpendingReportFilterStatus.NEED_REVIEW],
  };
