import { RowData, Table } from '@tanstack/react-table';
import React, { ReactNode, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import tw, { styled, theme } from 'twin.macro';
import { RootState } from '../../../app/store/store.app';
import useTranslator from '../../../hook/useTranslator.hook';
import { Button, Icon, LoadingIndicator, Text } from '../../atom';
import { TanstackTable, Tooltip } from '../../molecule';
import { InfoAlert } from '../../organism';
import { ExtraStyle } from '../../Type.component';

type Props<RD extends RowData> = {
  descriptionLabel: string;
  descriptionLabelBold?: string;
  actionLabel: string;
  cancelLabel: string;
  submitLabel: string;
  doneLabel: string;
  headerLabel?: string;
  table: Table<RD>;
  entityLength: number;
  showDoneBtn: boolean;
  isLoading: boolean;
  descriptionTooltipLabel?: string;

  noDataPage?: ReactNode;
  alwaysShowDescriptionLabel?: boolean;
  descriptionStyle?: ExtraStyle;

  onBack?: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  onDone: () => void;
};

// #region STYLED
const Wrapper = tw.div`relative `;
const Container = tw.div`w-[1032px] mx-auto px-4 gap-4 flex flex-col  min-h-[calc(100vh - 190px)] `;
const HeaderContainer = tw.div`flex items-center gap-2.5`;
const BottomContainer = styled.div(
  ({ isNavbarExpanded }: { isNavbarExpanded?: boolean }) => [
    tw`flex items-center justify-between gap-4 bg-white px-6 py-4 bottom-0 fixed border-t border-t-beige-lines  w-[calc(100% - 84px)] min-h-[58px] z-[3]`,
    isNavbarExpanded && tw`w-[calc(100% - 240px)]`,
  ],
);
const ActionLabelContainer = tw.div`w-full flex items-center whitespace-normal break-words`;
const ActionDescriptionContainer = tw.div`flex justify-end space-x-5 col-span-4 `;
const ActionContainer = tw.div`flex justify-end space-x-5 col-span-4 flex-1`;
const SubmitNumberContainer = tw.p`px-1 bg-white text-orange rounded-md min-w-[25px]`;
const AlertText = tw(Text.BodySixteen)`-mt-0.5 text-grey-two`;
const HeaderText = tw(Text.HeadingFour)`font-bold`;
const BoldLabel = tw(Text.BodySixteen)`font-bold`;
const BackButton = tw.button`p-1 mr-2.5 rounded-full hover:bg-orange-hover`;
const TooltipText = tw(Text.BodyFourteen)`whitespace-normal break-words`;
const CancelButton = tw(Button.Outlined)`py-3.5 px-4 h-11`;
const SubmitButton = tw(
  Button.Solid,
)`px-4 py-3.5 h-11 flex justify-between gap-2`;
const DoneButton = tw(
  Button.Solid,
)`py-3.5 px-4 h-11 flex justify-between gap-2 animate-slide-in-left`;

const TooltipContentStyle = tw`max-w-[248px]`;
// #endregion

function BulkActionSection({
  doneLabel,
  cancelLabel,
  submitLabel,
  isLoading,
  entityLength,
  showDoneBtn,
  onSubmit,
  onDone,
  onCancel,
}: {
  doneLabel: string;
  cancelLabel: string;
  submitLabel: string;
  isLoading: boolean;
  entityLength: number;
  showDoneBtn: boolean;
  onSubmit: () => void;
  onDone: () => void;
  onCancel: () => void;
}) {
  if (showDoneBtn)
    return (
      <ActionContainer>
        <DoneButton type="button" onClick={onDone}>
          {doneLabel}
        </DoneButton>
      </ActionContainer>
    );
  return (
    <ActionDescriptionContainer>
      <CancelButton
        type="button"
        disabled={isLoading || !entityLength}
        onClick={onCancel}
      >
        {cancelLabel}
      </CancelButton>

      <SubmitButton
        type="button"
        disabled={isLoading || !entityLength}
        onClick={onSubmit}
      >
        {submitLabel}

        {isLoading ? (
          <LoadingIndicator size="small" />
        ) : (
          <SubmitNumberContainer>{entityLength}</SubmitNumberContainer>
        )}
      </SubmitButton>
    </ActionDescriptionContainer>
  );
}

export default function BulkActionLayout<RD extends RowData>({
  descriptionLabel,
  descriptionLabelBold,
  cancelLabel,
  submitLabel,
  doneLabel,
  headerLabel,
  table,
  entityLength,
  showDoneBtn,
  isLoading,
  noDataPage,
  actionLabel,
  alwaysShowDescriptionLabel,
  descriptionStyle,
  descriptionTooltipLabel,
  onCancel,
  onSubmit,
  onDone,
  onBack,
}: Props<RD>) {
  const { isHovered, isExpanded } = useSelector(
    (state: RootState) => state.navbar,
  );
  const { translate } = useTranslator();
  const isNavbarExpanded = isHovered || isExpanded;

  const tooltipRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Wrapper>
      <Container>
        {!!onBack && !showDoneBtn && (
          <HeaderContainer>
            <BackButton type="button" disabled={isLoading} onClick={onBack}>
              <Icon.ArrowBack fill={theme`colors.orange.DEFAULT`} />
            </BackButton>
            <HeaderText
              ref={tooltipRef}
              onMouseEnter={() =>
                !!descriptionTooltipLabel && setShowTooltip(true)
              }
              onMouseLeave={() =>
                !!descriptionTooltipLabel && setShowTooltip(false)
              }
            >
              {headerLabel}
            </HeaderText>
            <Tooltip
              withPortal
              variant="light"
              placement="bottom"
              targetRef={tooltipRef}
              visible={!!descriptionTooltipLabel && showTooltip}
              contentStyle={TooltipContentStyle}
            >
              <TooltipText>{descriptionTooltipLabel}</TooltipText>
            </Tooltip>
          </HeaderContainer>
        )}
        {!showDoneBtn && (
          <InfoAlert
            title={
              <AlertText>
                {descriptionLabel}
                {!!descriptionLabelBold && (
                  <BoldLabel>{descriptionLabelBold} </BoldLabel>
                )}
              </AlertText>
            }
          />
        )}

        <TanstackTable<RD>
          table={table}
          noDataPage={noDataPage}
          rootStyle={tw`mb-20`}
        />
      </Container>
      <BottomContainer isNavbarExpanded={isNavbarExpanded}>
        <ActionLabelContainer>
          {(alwaysShowDescriptionLabel || !showDoneBtn) && (
            <Text.BodySixteen css={descriptionStyle}>
              {translate('Click')} <BoldLabel>{submitLabel} </BoldLabel>
              {actionLabel}
            </Text.BodySixteen>
          )}
        </ActionLabelContainer>

        <BulkActionSection
          doneLabel={doneLabel}
          cancelLabel={cancelLabel}
          submitLabel={submitLabel}
          isLoading={isLoading}
          entityLength={entityLength}
          showDoneBtn={showDoneBtn}
          onCancel={onCancel}
          onSubmit={onSubmit}
          onDone={onDone}
        />
      </BottomContainer>
    </Wrapper>
  );
}
