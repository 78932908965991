import React from 'react';
import tw from 'twin.macro';
import { UseReportShipperOrderDriverVehicleModalHookObj } from '../../../view/Report/ShipperOrder/hook/useReportShipperOrderDriverVehicleModal.hook';
import { Modal, TanstackTable } from '../../molecule';

// #region INTERFACES
export type ReportSODriverVehicleModalProps = {
  reportSODriverVehicleModal: UseReportShipperOrderDriverVehicleModalHookObj;
};
// #endregion

export default function ReportSODriverVehicleModal({
  reportSODriverVehicleModal,
}: ReportSODriverVehicleModalProps) {
  return (
    <Modal.WithHeader
      containerStyle={tw`p-0 w-[720px]`}
      headerContainerStyle={tw`p-5`}
      headerTitleStyle={tw`text-sm leading-6`}
      title={reportSODriverVehicleModal.titleLabel}
      onClose={reportSODriverVehicleModal.handleCloseModal}
      onOverlayClick={reportSODriverVehicleModal.handleCloseModal}
    >
      <TanstackTable
        stickyHeader
        fullWidth={false}
        table={reportSODriverVehicleModal.table}
        tableContainerStyle={[
          tw`h-[540px] rounded-br`,
          (reportSODriverVehicleModal.selectedSO?.deliveries ?? []).length <=
            8 && tw`h-[unset]`,
        ]}
      />
    </Modal.WithHeader>
  );
}
