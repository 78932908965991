import React, { ReactChild, ReactChildren, ReactNode } from 'react';
import tw from 'twin.macro';
import { ExtraStyle } from '../../Type.component';

const Container = tw.div`p-5`;
interface Props {
  className?: string;
  containerStyle?: ExtraStyle;
  children?:
    | ReactChild
    | ReactChild[]
    | ReactChildren
    | ReactChildren[]
    | ReactNode;
}

export default function CardContent({
  children,
  className,
  containerStyle,
}: Props) {
  return (
    <Container css={containerStyle} className={className}>
      {children}
    </Container>
  );
}
