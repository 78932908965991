import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { HaulingOrderSearchParam } from '../../model/HaulingOrder.model';
import { ReportHaulingOrderSearchParam } from '../../model/ReportHaulingOrder.model';

export type HaulingOrderDetailRouteParam =
  | {
      searchParamsFromHOList?: HaulingOrderSearchParam;
      searchParamsFromReportHOList?: ReportHaulingOrderSearchParam;
    }
  | undefined;

export const haulingOrderDetailRoute: RouteConfig = {
  name: 'Hauling Order Detail',
  Component: React.lazy(() => import('./HaulingOrderDetail.view')),
  path: '/hauling-order/:hoId',
  isPrivate: true,
  breadcrumb: 'Hauling Order Detail',
};

export default { haulingOrderDetailRoute };
