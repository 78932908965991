import React from 'react';
import tw from 'twin.macro';
import { JOAssignmentBy } from '../../../constant/JOAssignment.constant';
import { UseJODateAssignmentFormHookObj } from '../../../hook/joAssignmentForm/useJODateAssignmentForm.hook';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';
import SchedulesSection from '../SchedulesSection/SchedulesSection.organism';

type Props = {
  joDateAssignmentHookObj: UseJODateAssignmentFormHookObj;
};

const Container = tw.div`flex flex-col gap-6`;

export default function JODateAssignmentForm({
  joDateAssignmentHookObj,
}: Props) {
  const {
    dateAssignment: {
      selectedHighlightId: selectedHighlightIdx,
      schedules,
      isLoading,
    },
  } = joDateAssignmentHookObj;
  return (
    <Container>
      {/* type already described inside component */}
      <ComposedFormField {...joDateAssignmentHookObj.formData} />
      <SchedulesSection
        isLoading={isLoading}
        listContainerHeight={350}
        type={JOAssignmentBy.DATE}
        selectedHighlightId={selectedHighlightIdx}
        title={joDateAssignmentHookObj.title}
        emptyTitle={joDateAssignmentHookObj.emptyTitle}
        emptySubtitle={joDateAssignmentHookObj.emptySubtitle}
        handleClickScheduleItem={
          joDateAssignmentHookObj.handleSelectScheduleItem
        }
        schedules={schedules}
      />
    </Container>
  );
}
