import React from 'react';
import tw, { theme } from 'twin.macro';
import { UseGetShippersAutocompleteObj } from '../../../hook/useGetShippersAutocomplete.hook';
import { UseDeliveryLocationFilterHookObj } from '../../../view/DeliveryLocation/hooks/useDeliveryLocationFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete } from '../../molecule';

// #region INTERFACES
type Props = {
  locationFilter: UseDeliveryLocationFilterHookObj;
  shippersAutocomplete: UseGetShippersAutocompleteObj;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full max-h-screen bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)`mb-2`;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
// #endregion

function DeliveryLocationFilter({
  locationFilter,
  shippersAutocomplete,
}: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />
        <Text.HeadingFour tw="flex-1 ml-2">
          {locationFilter.titleLabel}
        </Text.HeadingFour>
        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          onClick={locationFilter.onClickReset}
        >
          {locationFilter.resetButtonLabel}
        </Button.Outlined>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading>{locationFilter.orderByLabel}</InputHeading>
          <ChipContainer>
            {locationFilter.filterOrderByValues.map((el) => {
              const selected = locationFilter.form.values.orderBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() => locationFilter.onClickOrderByChip(el.value)}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${locationFilter.testID || ''}Filter:TypeLabel`}
            >
              {locationFilter.typeLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                locationFilter.isQueryTypeAll
                  ? locationFilter.onClickUnselectAllType
                  : locationFilter.onClickSelectAllType
              }
            >
              {locationFilter.isQueryTypeAll
                ? locationFilter.unselectAllLabel
                : locationFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {locationFilter.filterTypeValues.map((el, i) => {
              const selected = locationFilter.form.values.type.includes(
                el.value,
              );

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() => locationFilter.onClickTypeChip(el.value)}
                    testID={`${
                      locationFilter.testID || ''
                    }Filter:TypeOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {locationFilter.isQueryTypeEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {locationFilter.errorTypeLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${locationFilter.testID || ''}Filter:ShipperLabel`}
          >
            {locationFilter.shipperLabel}
          </InputHeading>

          <AutoComplete
            tw="bg-orange-dark-lines"
            disabled={locationFilter.shipperIsDisabled}
            value={locationFilter.form.values.shipperId}
            datas={shippersAutocomplete.shipperOptions}
            selectedOption={locationFilter.queryShipperOption}
            placeholder={locationFilter.shipperPlaceholder}
            loading={shippersAutocomplete.shipperListFetchLoading}
            changeData={locationFilter.onChangeShipper}
            changeInputText={shippersAutocomplete.onChangeShipperAutotext}
            onFetchMore={shippersAutocomplete.onFetchMoreShipper}
            onRemoveData={locationFilter.onRemoveShipper}
          />
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={
            locationFilter.isQueryTypeEmpty ||
            locationFilter.filterLocalIsFetching
          }
          onClick={locationFilter.onClickSubmit}
        >
          {locationFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default DeliveryLocationFilter;
