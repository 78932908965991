import React from 'react';
import { RouteConfig } from '../../config/router/router.config';

export const jobOrderCreateRoute: RouteConfig = {
  name: 'Create Job Order',
  Component: React.lazy(() => import('./JobOrderCreate.view')),
  path: '/job-order/create',
  isPrivate: true,

  breadcrumb: 'Create Job Order',

  options: {
    label: 'Create Job Order',
    testID: 'MenuJobOrderCreate',
  },
};
