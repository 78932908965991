import React from 'react';
import tw, { theme } from 'twin.macro';
import { Button } from '../../../component/atom';
import { Close } from '../../../component/atom/Icon/Icon.atom';
import {
  BodyFourteen,
  HeadingFive,
  HeadingThree,
  Label,
} from '../../../component/atom/Text/Text.atom';
import { TextArea } from '../../../component/molecule';
import useTranslator from '../../../hook/useTranslator.hook';
import { UseJODetailSpendingReject } from '../hooks/useJODetailSpendingReject.hook';

type Props = {
  spendingReportReject: UseJODetailSpendingReject;
  handleClose: () => void;
};

const Container = tw.div`w-[740px]`;
const NumberLabel = tw(HeadingFive)`font-semibold`;
const Body = tw.div`p-5 flex flex-col gap-4`;
const Section = tw.div`flex flex-col`;
const FormSection = tw.div`flex flex-col gap-2`;
const Footer = tw.div`px-5 py-3 flex justify-between border-t border-beige-lines`;

const RejectButtonStyle = tw`bg-red-500 pl-2.5 pr-[17px]`;
const ButtonLabelStyle = tw`text-white -mb-0.5 -ml-2 font-sans font-semibold text-base`;

export default function JODetailSpendingRejectModal({
  spendingReportReject,
  handleClose,
}: Props) {
  const { translate } = useTranslator();

  return (
    <Container>
      <Body>
        <Section>
          <HeadingThree>{translate('Reject Expense')}</HeadingThree>
          <BodyFourteen>
            {translate('Are you sure to reject')}{' '}
            <NumberLabel>
              {spendingReportReject.modalVisible?.number || ''}
            </NumberLabel>{' '}
            ?
          </BodyFourteen>
        </Section>
        <FormSection>
          <Label>{translate('Reject Notes')}</Label>
          <TextArea
            maxLength={161}
            error={spendingReportReject.errorMessage}
            onChange={(e) => {
              spendingReportReject.setRejectText(e.target.value);
            }}
            tw="pt-3 pb-2.5 resize-none h-[72px]"
            placeholder={translate('Reject Notes')}
            id={spendingReportReject.modalVisible?.id}
            name={spendingReportReject.modalVisible?.number}
          />
        </FormSection>
      </Body>
      <Footer>
        <Button.Outlined tw="h-11" onClick={handleClose}>
          {translate('Cancel')}
        </Button.Outlined>
        <Button.Icon
          isLoading={spendingReportReject.isLoading}
          containerStyle={RejectButtonStyle}
          labelStyle={ButtonLabelStyle}
          action={spendingReportReject.handleReject}
          icon={<Close strokeColor={theme`colors.white`} />}
          label={translate('Reject')}
        />
      </Footer>
    </Container>
  );
}
