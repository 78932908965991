import React, {
  ReactChild,
  ReactChildren,
  ReactElement,
  ReactNode,
} from 'react';
import tw from 'twin.macro';
import { ExtraStyle } from '../../Type.component';

interface Props {
  children?:
    | ReactChild
    | ReactChild[]
    | ReactChildren
    | ReactChildren[]
    | ReactElement
    | ReactNode;
  containerStyle?: ExtraStyle;
}

const Container = tw.div`bg-white shadow-card rounded-md`;

export default function CardContainer({ containerStyle, children }: Props) {
  return <Container css={containerStyle}>{children}</Container>;
}
