import React from 'react';

export default function Driver({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1108_51453)">
        <path
          fill={fill || 'currentColor'}
          d="M16 6A6 6 0 114 6a6 6 0 0112 0zM6 6a4 4 0 108 0 4 4 0 00-8 0z"
        />
        <path
          fill={fill || 'currentColor'}
          d="M20 20c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10 10 4.477 10 10zM2 20a8 8 0 1016 0 8 8 0 00-16 0z"
        />
        <path
          fill={fill || 'currentColor'}
          d="M16 20a6 6 0 11-12 0 6 6 0 0112 0zM6 20a4 4 0 108 0 4 4 0 00-8 0z"
        />
        <circle cx="10" cy="20" r="2" fill={fill || 'currentColor'} />
        <path
          stroke={fill || 'currentColor'}
          strokeWidth="2"
          d="M13.707 16.707L10.707 19.707"
        />
        <path
          stroke={fill || 'currentColor'}
          strokeWidth="2"
          d="M6.707 17.293L9.707 20.293"
        />
      </g>
      <defs>
        <clipPath id="clip0_1108_51453">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
