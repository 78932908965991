import React from 'react';
import tw from 'twin.macro';
import {
  reportRevenueDateRangeFilterInitialValues,
  reportRevenueMaxDateRangeInDays,
} from '../../../constant/ReportRevenue.constant';
import { UseReportRevenueFilterObj } from '../../../hook/report/revenue/useReportRevenueFilter.hook';
import { UseGetShippersAutocompleteObj } from '../../../hook/useGetShippersAutocomplete.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region INTERFACES
type Props = {
  reportRevenueFilter: UseReportRevenueFilterObj;
  shippersAutocomplete: UseGetShippersAutocompleteObj;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
const DatePickerContainer = tw.div`w-full flex items-center justify-between space-x-2`;
// #endregion

function ReportRevenueFilter({
  reportRevenueFilter,
  shippersAutocomplete,
}: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <Text.HeadingFour
          tw="flex-1 ml-2"
          data-testid={`${reportRevenueFilter.testID}Filter:Title`}
        >
          {reportRevenueFilter.titleLabel}
        </Text.HeadingFour>

        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          data-testid={`${reportRevenueFilter.testID}Filter:ResetAction`}
          onClick={reportRevenueFilter.onClickResetFilter}
        >
          {reportRevenueFilter.resetButtonLabel}
        </Button.Outlined>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportRevenueFilter.testID}Filter:OrderByLabel`}
          >
            {reportRevenueFilter.orderByLabel}
          </InputHeading>

          <ChipContainer>
            {reportRevenueFilter.filterOrderByValues.map((el, i) => {
              const selected =
                reportRevenueFilter.filterForm.values.orderBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      reportRevenueFilter.onClickOrderByChipFilter(el.value)
                    }
                    testID={`${reportRevenueFilter.testID}Filter:OrderByOption:${i}`}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportRevenueFilter.testID}Filter:ShipperLabel`}
          >
            {reportRevenueFilter.shipperLabel}
          </InputHeading>

          <AutoComplete
            tw="bg-orange-dark-lines"
            value={reportRevenueFilter.filterForm.values.shipperId}
            datas={shippersAutocomplete.shipperOptions}
            selectedOption={reportRevenueFilter.queryShipperOption}
            placeholder={reportRevenueFilter.shipperPlaceholder}
            loading={shippersAutocomplete.shipperListFetchLoading}
            changeData={reportRevenueFilter.onChangeShipperFilter}
            changeInputText={shippersAutocomplete.onChangeShipperAutotext}
            onFetchMore={shippersAutocomplete.onFetchMoreShipper}
            onRemoveData={reportRevenueFilter.onRemoveShipperFilter}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportRevenueFilter.testID}Filter:DateRangeLabel`}
          >
            {reportRevenueFilter.dateLabel}
          </InputHeading>

          <DatePickerContainer>
            <DateRangePickerInput
              popoverPlacement="right"
              sdLabel={reportRevenueFilter.sdLabel}
              edLabel={reportRevenueFilter.edLabel}
              sdDateValue={reportRevenueFilter.filterForm.values.startDate}
              sdOnChangeDateValue={reportRevenueFilter.onChangeStartDateFilter}
              edDateValue={reportRevenueFilter.filterForm.values.endDate}
              edOnChangeDateValue={reportRevenueFilter.onChangeEndDateFilter}
              onClickApplyDateRange={reportRevenueFilter.onClickApplyDateRange}
              outsideAlerterStyle={{ width: '100%' }}
              definedRangeOptions={
                reportRevenueFilter.dateRangeDefinedRangeOptions
              }
              defaultDateRange={reportRevenueDateRangeFilterInitialValues}
              maxDateRangeInDays={reportRevenueMaxDateRangeInDays}
              disabledDays={reportRevenueFilter.dateRangeDisabledDays}
              notes={reportRevenueFilter.dateRangeNotes}
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={reportRevenueFilter.filterLocalIsFetching}
          onClick={reportRevenueFilter.onClickSubmitFilter}
          data-testid={`${reportRevenueFilter.testID}Filter:SubmitButton`}
        >
          {reportRevenueFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default React.memo(ReportRevenueFilter);
