import { Locale } from './locale.config';

const sidebarMenuLocaleConfig: Locale[] = [
  {
    en: 'Shipper Order',
    id: 'Shipper Order',
  },
  {
    en: 'Job Order',
    id: 'Perintah Kerja',
  },
  {
    en: 'Report',
    id: 'Laporan',
  },
  {
    en: 'Scheduling',
    id: 'Penjadwalan',
  },
  {
    en: 'Delivery Location',
    id: 'Lokasi Pengiriman',
  },
  {
    en: 'Activity Template',
    id: 'Templat Pengiriman',
  },
  {
    en: 'Driving Behavior by Vehicle',
    id: 'Perilaku Mengemudi (Kendaraan)',
  },
  {
    en: 'Driving Behavior by Job Order',
    id: 'Perilaku Mengemudi (Perintah Kerja)',
  },
  {
    en: 'Driving Behavior by Schedule',
    id: 'Perilaku Mengemudi (Jadwal)',
  },
  {
    en: 'Revenue',
    id: 'Pendapatan',
  },
  {
    en: 'Maps',
    id: 'Peta',
  },
  {
    en: 'Logout',
    id: 'Keluar',
  },
  {
    en: 'Sign Out',
    id: 'Keluar',
  },
];

export default sidebarMenuLocaleConfig;
