import { Locale } from './locale.config';

const mapLocaleConfig: Locale[] = [
  {
    en: 'Map View',
    id: 'Tampilan Peta',
  },
  {
    en: 'Satellite',
    id: 'Satelit',
  },
  {
    en: 'Traffic Condition',
    id: 'Lalu Lintas',
  },
  {
    en: 'Truck',
    id: 'Truk',
  },
];

export default mapLocaleConfig;
