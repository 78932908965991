import { nanoid } from '@reduxjs/toolkit';
import {
  ChangeEventHandler,
  DragEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import envConfig from '../../config/env/env.config';
import { navigationParamAction } from '../../store/param.store';
import { readXlsx, worksheetToRowColumn } from '../../util/helper.util';
import { checkShipperAddExcelDataValidity } from '../../util/shipper/shipper.util';
import {
  ShipperAddBulk,
  shipperAddBulkRoute,
  ShipperAddBulkRouteParam,
} from '../../view/ShipperAddBulk/ShipperAddBulk.route';
import useTranslator from '../useTranslator.hook';

// #region TYPES
export type UseShipperUploadModal = ReturnType<typeof useShipperUploadModal>;
// #endregion

export default function useShipperUploadModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translator = useTranslator();
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filename, setFilename] = useState('');
  const [error, setError] = useState('');
  const [dragActive, setDragActive] = useState(false);

  const truncatedFilename = useMemo(() => {
    if (filename.length < 30) return filename;

    const [name, format] = filename.split('.');
    const truncatedName = name.slice(0, 25);

    return `${truncatedName}...${format}`;
  }, [filename]);

  const requiredLabels = useMemo(
    () => [
      'Shipper name are mandatory',
      "Phone number must start with apostrophe mark. For example : “'+6281234567890“",
      'Maximum amount of data is 100 rows',
    ],
    [],
  );
  const sampleLink = `${envConfig.templatesUrl}Import-Shipper-Template.xlsx`;
  const subtitleLabel = translator.translate(
    'Upload a XLS file to import shipper data',
  );

  // #region HANDLERS
  const onOpenUploadModal = useCallback(() => {
    setShowUploadModal(true);
  }, []);

  const onCloseUploadModal = useCallback(() => {
    setShowUploadModal(false);
    setIsLoading(false);
    setError('');
  }, []);

  const handleImportExcel = useCallback(
    (file: File) => {
      readXlsx({
        xlsData: file,
        sheetNumber: 1,
        callback: (worksheet, data) => {
          const { columns } = worksheetToRowColumn(worksheet);
          const formattedDatas = data.slice(1); //start from data in row 2
          const errorLabel = checkShipperAddExcelDataValidity(
            columns,
            formattedDatas,
          );
          // on error, render error display
          if (errorLabel !== '') {
            setIsLoading(false);
            setFilename(file.name);
            setError(errorLabel);
            return;
          }

          // on success, close modal, navigate and send the state along to /shipper/add-bulk
          const shippers = (formattedDatas as ShipperAddBulk[]).map(
            (_shipper) => ({
              ..._shipper,
              id: nanoid(),
            }),
          );
          const navigationParams: ShipperAddBulkRouteParam = {
            filename: file.name,
            shippers,
          };

          onCloseUploadModal();
          dispatch(
            navigationParamAction.changeShipperAddBulkParams(navigationParams),
          );
          navigate(shipperAddBulkRoute.path);
        },
      });
    },
    [dispatch, navigate, onCloseUploadModal],
  );

  // triggers when file is dragged
  const handleDrag: DragEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    }
    if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (!e.dataTransfer.files || !e.dataTransfer.files[0]) return; // no files / unsupported
    if (e.dataTransfer.files.length > 1) return; // multiple files
    if (!e.dataTransfer.files[0].type) return; // folder

    setIsLoading(true);
    handleImportExcel(e.dataTransfer.files[0]);
  };

  // triggers when file is selected with click
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    if (!e.target.files || !e.target.files[0]) return; // no files / unsupported

    setIsLoading(true);
    handleImportExcel(e.target.files[0]);
  };

  // reset `isLoading` and `error`
  const handleReupload = () => {
    setIsLoading(false);
    setError('');
  };
  // #endregion

  return {
    filename: truncatedFilename,
    error,
    dragActive,
    showUploadModal,
    isLoading,
    requiredLabels,
    sampleLink,
    subtitleLabel,
    setIsLoading,
    onOpenUploadModal,
    onCloseUploadModal,
    handleReupload,
    handleDrag,
    handleDrop,
    handleChange,
  };
}
