import startOfToday from 'date-fns/startOfToday';
import subDays from 'date-fns/subDays';
import { mixed } from 'yup';

// #region INTERFACES
export enum ReportJOFilterOrderBy {
  DATE_ASC = 'DATE_ASC', // JO Date A-Z
  DATE_DESC = 'DATE_DESC', // JO Date Z-A
  NUMBER_ASC = 'NUMBER_ASC', // JO Number A-Z
  NUMBER_DESC = 'NUMBER_DESC', // JO Number Z-A
  DURATION_ASC = 'DURATION_ASC', // Delivery Duration Time A-Z
  DURATION_DESC = 'DURATION_DESC', // Delivery Duration Time Z-A
  START_TIME_ASC = 'START_TIME_ASC', // Start Time A-Z
  START_TIME_DESC = 'START_TIME_DESC', // Start Time Z-A
  COMPLETE_TIME_ASC = 'COMPLETE_TIME_ASC', // Finished Time A-Z
  COMPLETE_TIME_DESC = 'COMPLETE_TIME_DESC', // Finished Time Z-A
}

export enum ReportJOFilterStatus {
  ASSIGNED = 'ASSIGNED',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
}

export type ReportJOFilterStatusValue = {
  label: string;
  value: ReportJOFilterStatus;
};

export type ReportJOFilterOrderByValue = {
  label: string;
  value: ReportJOFilterOrderBy;
};

export enum ReportJobOrderFilterShowDeliveryEnum {
  HIDE_DELIVERY = 0,
  SHOW_DELIVERY = 1,
}

export type ReportJobOrderFilterShowDeliveryValue = {
  label: string;
  value: ReportJobOrderFilterShowDeliveryEnum;
};

export enum ReportJOFilterFinishingMethod {
  USER = 'USER', // By Admin
  DRIVER = 'DRIVER', // By User
  GEOFENCE = 'GEOFENCE', // By Geofence
}

export type ReportJOFilterFinishingMethodValue = {
  label: string;
  value: ReportJOFilterFinishingMethod;
};

export type ReportJobOrderFilterInitialValues = {
  orderBy: ReportJOFilterOrderBy;
  showDelivery: ReportJobOrderFilterShowDeliveryEnum;
  status: ReportJOFilterStatus[];
  finishingMethod: ReportJOFilterFinishingMethod[];
  startDate: Date;
  endDate: Date;
  driverId?: string;
  vehicleId?: string;
};
// #endregion

export const reportJobOrderDateRangeFilterInitialValues: Pick<
  ReportJobOrderFilterInitialValues,
  'startDate' | 'endDate'
> = {
  startDate: subDays(startOfToday(), 6),
  endDate: startOfToday(),
};

export const reportJobOrderFilterInitialValues: ReportJobOrderFilterInitialValues =
  {
    ...reportJobOrderDateRangeFilterInitialValues,
    orderBy: ReportJOFilterOrderBy.DATE_DESC,
    showDelivery: 0,
    status: Object.values(ReportJOFilterStatus),
    finishingMethod: Object.values(ReportJOFilterFinishingMethod),
    driverId: undefined,
    vehicleId: undefined,
  };

export const reportJobOrderMaxDateRangeInDays = 366;

export const reportJOShowDeliverySchema = mixed().oneOf([0, 1]);
