import React from 'react';
import { Link } from 'react-router-dom';
import tw, { theme } from 'twin.macro';
import { ScheduleInfoProps } from '../../../constant/Schedule.constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { DriverPINInfoProps } from '../../../model/Driver.model';
import { getShortFormatDate } from '../../../util/helper.util';
import { scheduleRoute } from '../../../view/Schedule/Schedule.route';
import { scheduleAddRoute } from '../../../view/ScheduleAdd/ScheduleAdd.route';
import {
  CircleCheck,
  Driver,
  DrivingSchedule,
  InfoOutlined,
  LogoWhatsapp,
} from '../../atom/Icon/Icon.atom';
import {
  BodyFourteen,
  BodySixteen,
  HeadingFour,
  HeadingThree,
} from '../../atom/Text/Text.atom';
import { CopyToClipboard } from '../../molecule';

// #region STYLING

const Container = tw.div`flex justify-center`;
const Content = tw.div`w-full flex flex-col`;
const HeaderIcon = tw.div`pb-6`;
const WAButton = tw.div`flex gap-2 px-4 py-2.5 bg-whatsapp cursor-pointer rounded-md  items-center hover:opacity-25`;
const ContentSection = tw.div`p-6 w-full flex flex-col gap-3 not-last:(border-r border-r-beige-lines )`;
const ContentHeader = tw.div`flex gap-2`;
const ContentMeta = tw.div`flex flex-col`;
const AlertContainer = tw.div`flex gap-2.5 rounded-lg p-2.5 border`;
const ContentText = tw(BodySixteen)`text-sm leading-6`;
const LinkText = tw(BodyFourteen)`mx-1 font-black cursor-pointer text-orange`;

// #endregion

// #region INTERFACE

type Props = {
  translator: UseTranslator;
  driverPinInfo?: DriverPINInfoProps;
  scheduleInfo?: ScheduleInfoProps;
  handleShareToWA?: () => void;
  handleClickCallCenter: () => void;
};

// #endregion

export default function DriverSubmissionModal({
  translator,
  driverPinInfo,
  scheduleInfo,
  handleShareToWA,
  handleClickCallCenter,
}: Props) {
  return (
    <Container>
      <Content>
        <div tw="border-b border-b-beige-lines py-6 w-full">
          <div tw="flex gap-2.5 h-8 w-full content-center justify-center">
            <HeaderIcon>
              <CircleCheck
                width="35"
                height="32"
                rectX="0"
                rectY="0"
                rectRX="16"
                rectWidth="32"
                rectHeight="32"
                pathStrokeWidth="4"
                isSmall
              />
            </HeaderIcon>

            <HeadingThree>
              {translator.translate(
                scheduleInfo
                  ? 'Driver and Schedule Successfully Added!'
                  : 'Driver Successfully Added!',
              )}
            </HeadingThree>
          </div>
        </div>

        <div tw="flex w-full">
          <ContentSection>
            <ContentHeader>
              <Driver />{' '}
              <HeadingFour>{translator.translate('Driver')}</HeadingFour>
            </ContentHeader>
            <ContentMeta>
              <ContentText tw="truncate w-[312px] ">
                {driverPinInfo?.name}
              </ContentText>
              <ContentText tw="text-grey-two">
                {driverPinInfo?.phoneNumber
                  ? `${driverPinInfo?.phoneNumber}`
                  : ''}
              </ContentText>
            </ContentMeta>
            <div tw="flex gap-2">
              <div tw="flex flex-none">
                <HeadingFour tw="py-2.5 pl-4 pr-2 bg-beige-bg border border-r-0 border-beige-lines rounded-l-lg">
                  {driverPinInfo?.pin}
                </HeadingFour>
                <div>
                  <CopyToClipboard
                    label={translator.translate('Copy')}
                    valueToCopy={driverPinInfo?.pin || ''}
                    labelStyle={tw`text-white font-black`}
                    iconColor={theme`colors.white`}
                    buttonStyle={tw`rounded-l-none rounded-r-md mr-0 h-full py-2.5 px-4 bg-black flex gap-2 border-beige-lines hover:(bg-black bg-opacity-25)`}
                  />
                </div>
              </div>
              <WAButton onClick={handleShareToWA}>
                <LogoWhatsapp
                  width={16}
                  height={16}
                  pathFill={theme`colors.white`}
                />
                <HeadingFour tw="text-white">
                  {translator.translate('Send WA')}
                </HeadingFour>
              </WAButton>
            </div>
          </ContentSection>
          <ContentSection>
            <ContentHeader>
              <DrivingSchedule />{' '}
              <HeadingFour> {translator.translate('Schedule')}</HeadingFour>
            </ContentHeader>
            {scheduleInfo ? (
              <>
                <ContentMeta>
                  <ContentText>{`${getShortFormatDate(
                    scheduleInfo.from || new Date(),
                  )} - ${getShortFormatDate(
                    scheduleInfo.to || new Date(),
                  )}`}</ContentText>
                  <ContentText tw="text-grey-two">
                    {`${
                      scheduleInfo?.vehicle?.name ||
                      scheduleInfo?.vehicle?.chassisNumber ||
                      ''
                    } ${
                      scheduleInfo?.vehicle?.number
                        ? `— ${scheduleInfo?.vehicle?.number}`
                        : ''
                    }`}
                  </ContentText>
                </ContentMeta>
                <AlertContainer tw="bg-blue-five border-status-fuel-anomaly">
                  <InfoOutlined
                    svgStyle={tw`mt-1`}
                    width={16}
                    height={16}
                    fillPath={theme`colors.status.fuel-anomaly`}
                  />
                  <BodyFourteen>
                    {translator.translate('Go to')}
                    <Link to={scheduleRoute.path}>
                      <LinkText>
                        {translator.translate('Schedule List')}
                      </LinkText>
                    </Link>
                    {translator.translate('to reschedule.')}
                  </BodyFourteen>
                </AlertContainer>
              </>
            ) : (
              <AlertContainer tw="border-status-alert bg-status-alert-light mt-auto">
                <div tw="pt-0.5">
                  <InfoOutlined
                    width={16}
                    height={16}
                    fillPath={theme`colors.status.alert`}
                  />
                </div>
                <div tw="flex flex-col gap-1">
                  <HeadingFour tw="font-bold">
                    {translator.translate('Failed to add schedule.')}
                  </HeadingFour>
                  <BodyFourteen>
                    {translator.translate('Go to')}
                    <Link to={scheduleAddRoute.path}>
                      <LinkText>
                        {translator.translate('Add Schedule')}
                      </LinkText>
                    </Link>
                    {translator.translate('to reschedule, or contact')}
                    <LinkText onClick={handleClickCallCenter}>
                      {translator.translate('Call Center')}
                    </LinkText>
                    {translator.translate('for more information.')}
                  </BodyFourteen>
                </div>
              </AlertContainer>
            )}
          </ContentSection>
        </div>
      </Content>
    </Container>
  );
}
