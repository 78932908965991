import React, { PropsWithChildren } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import tw, { styled } from 'twin.macro';
import { SnackbarAdapter } from '../../component/molecule';
import { HelpCenterSidebar, HelpCenterTopBar } from '../../component/organism';
import MobileHelpCenterSidebar from '../../component/organism/MobileHelpCenterSidebar/MobileHelpCenterSidebar.organism';
import { RouteConfig } from '../../config/router/router.config';
import useWrapperHelpCenterViewModel from './hooks/useWrapperHelpCenter.viewModel';

const Container = styled.div(() => [
  tw`min-h-screen min-w-min bg-white flex items-stretch`,
]);
const ContentWrapper = styled.div(({ isMobile }: { isMobile?: boolean }) => [
  tw`flex-1 pt-16 relative overflow-x-auto whitespace-nowrap`,
  isMobile ? tw`sm:(w-[calc(100vw - 320px)])` : tw`w-[calc(100vw - 320px)]`,
]);
const ContentArea = styled.div(({ isMobile }: { isMobile?: boolean }) => [
  isMobile ? tw`w-full sm:(w-[1080px])` : tw`w-[1080px] mx-auto`,
]);
const SidebarWrapper = styled.div(({ isMobile }: { isMobile?: boolean }) => [
  tw`z-30 bg-white border-r border-beige-lines inline-block duration-200 pt-16 flex-shrink-0 relative`,
  isMobile ? tw`h-screen w-[75%]` : tw`w-60 h-[100vh]`,
  `ul, span {
      display: block;
    }`,
]);

const SidebarOverlay = styled.div(({ show }: { show?: boolean }) => [
  tw`w-[300vw] h-screen bg-black opacity-40 -mt-16 absolute`,
  show && tw`block`,
]);

const SidebarContent = tw.div`pr-1 py-2`;

const SidebarThumb = tw.div`bg-black bg-opacity-20 absolute -right-0.5 w-2.5 hover:bg-opacity-30 hidden group-hover:block`;

const MobileDrawer = styled.div(({ show }: { show: boolean }) => [
  tw`fixed z-50 w-screen transition-all `,
  show
    ? tw`right-0 opacity-100 duration-300`
    : tw`right-[100vh] opacity-0 invisible duration-500`,
]);

type Props = PropsWithChildren<Record<string, unknown>> & {
  currentPath: string;
};

function Sidebar({
  show,
  isMobile,
  currentPath,
  sidebarItems,
  handleClose,
}: {
  show?: boolean;
  isMobile?: boolean;
  currentPath: string;
  sidebarItems: RouteConfig[];
  handleClose: () => void;
}) {
  if (isMobile)
    return (
      <MobileDrawer show={!!show}>
        <SidebarWrapper isMobile>
          <SidebarOverlay show={!!show} onClick={handleClose} />
          <HelpCenterTopBar isSidebarVisible />
          <Scrollbars
            className="group scrollbarStyle"
            style={{
              overflowY: 'auto',
              background: 'white',
            }}
            renderView={() => <SidebarContent />}
            renderThumbVertical={() => <SidebarThumb />}
          >
            <MobileHelpCenterSidebar
              currentPath={currentPath}
              items={sidebarItems}
              onClickItem={handleClose}
            />
          </Scrollbars>
        </SidebarWrapper>
      </MobileDrawer>
    );

  return (
    <>
      <SidebarWrapper />
      <SidebarWrapper tw="fixed">
        <Scrollbars
          className="group scrollbarStyle"
          style={{ overflowY: 'auto' }}
          renderView={() => <SidebarContent />}
          renderThumbVertical={() => <SidebarThumb />}
        >
          <HelpCenterSidebar
            currentPath={currentPath}
            items={sidebarItems}
            onItemClick={() => {}}
          />
        </Scrollbars>
      </SidebarWrapper>
    </>
  );
}

export default function WrapperHelpCenterView({
  children,
  currentPath,
}: Props) {
  const {
    isLoggedIn,
    isMobile,
    sidebarItems,
    showMobileMenu,
    handleOpenMobileMenu,
    handleCloseMobileMenu,
  } = useWrapperHelpCenterViewModel();

  if (!isLoggedIn) return null;

  return (
    <Container id="helpCenterRootContainer">
      <HelpCenterTopBar onBurgerMenuClick={handleOpenMobileMenu} />
      <Sidebar
        show={showMobileMenu}
        isMobile={isMobile}
        currentPath={currentPath}
        sidebarItems={sidebarItems}
        handleClose={handleCloseMobileMenu}
      />
      <ContentWrapper id="contentWrapper" isMobile={isMobile}>
        <ContentArea id="contentArea" isMobile={isMobile}>
          {children}
        </ContentArea>
        <SnackbarAdapter />
      </ContentWrapper>
    </Container>
  );
}
