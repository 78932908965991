import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../service/api.service';

// #region INTERFACES
export type HaulingJOTooltipEntity = {
  id: string; // from ho.id
  deliveryId: string;
};

export type HaulingJOTooltipStore = {
  showTooltip: boolean;
  entities: HaulingJOTooltipEntity[];
};
// #endregion

const haulingJOTooltipInitialState: HaulingJOTooltipStore = {
  showTooltip: true,
  entities: [],
};

const haulingJOTooltipSlice = createSlice({
  name: 'haulingJOTooltip',
  initialState: haulingJOTooltipInitialState,
  reducers: {
    setHaulingJOTooltip: (
      state,
      action: PayloadAction<HaulingJOTooltipEntity>,
    ) => ({
      ...state,
      entities: [...state.entities, action.payload],
    }),
    removeHaulingJOTooltip: (
      state,
      action: PayloadAction<HaulingJOTooltipEntity['id']>,
    ) => ({
      ...state,
      entities: state.entities.filter((entity) => entity.id === action.payload),
    }),
    showHaulingJOTooltip: (state) => ({
      ...state,
      showTooltip: true,
    }),
    hideHaulingJOTooltip: (state) => ({
      ...state,
      showTooltip: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getHaulingOrderList.matchFulfilled,
      (state, { payload }) => {
        const newState = {
          ...state,
          entities: state.entities.slice(),
        };

        for (const ho of payload.haulingOrders) {
          // if not exists in state, add it
          if (!newState.entities.find((entity) => entity.id === ho.id)) {
            newState.entities.push({
              id: ho.id,
              deliveryId: ho.deliveries[0].id,
            });
          }
        }

        return newState;
      },
    );
  },
});

export const haulingJOTooltipAction = haulingJOTooltipSlice.actions;
export const haulingJOTooltipReducer = haulingJOTooltipSlice.reducer;
