import { Locale } from './locale.config';

const addShipperLocaleConfig: Locale[] = [
  {
    en: 'Shipper Name',
    id: 'Nama Pengirim',
  },
  {
    en: 'Phone Number',
    id: 'Nomor Telepon',
  },
  {
    en: 'Email',
    id: 'Email',
  },
  {
    en: 'Add Shipper',
    id: 'Tambah Pengirim',
  },
];

export default addShipperLocaleConfig;
