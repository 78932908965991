import React from 'react';
import tw, { theme } from 'twin.macro';
import { MAX_SO_ACTIVITY } from '../../../constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { SOActivityFormProps } from '../../../model/ShipperOrder.model';
import { promiseToVoid } from '../../../util/helper.util';
import { Icon, Text } from '../../atom';
import { CardAction, SOSection } from '../../molecule';
import SOCActivityInfo from '../../molecule/SOCActivityInfo/SOCActivityInfo.molecule';
import SOActivityFormItem from '../SOActivityFormItem/SOActivityFormItem.organism';
import SOCreateActivityFormAction from '../SOCreateActivityFormAction/SOCreateActivityFormAction.organism';
import SOCreateATInfoForm from '../SOCreateATInfoForm/SOCreateATInfoForm.organism';

const HeaderContainer = tw.div`flex justify-between`;
const HeaderAction = tw.div`cursor-pointer`;

interface Props {
  translator: UseTranslator;
  soActivityFormData: SOActivityFormProps;
}
export default function SOActivityForm({
  translator,
  soActivityFormData,
}: Props) {
  return (
    <SOSection
      header={
        <HeaderContainer>
          <Text.HeadingFour>{soActivityFormData.title}</Text.HeadingFour>
          {!!soActivityFormData.handleShowActivitySelectionMethod && (
            <HeaderAction
              onClick={soActivityFormData.handleShowActivitySelectionMethod}
            >
              <Text.HeadingFive tw="text-orange">
                {translator.translate('Choose How to Input')}
              </Text.HeadingFive>
            </HeaderAction>
          )}
        </HeaderContainer>
      }
      footer={
        soActivityFormData.isFooterVisible && (
          <CardAction
            title={soActivityFormData.actionLabel}
            additionalTitle={soActivityFormData.secondaryActionLabel}
            isActionDisabled={soActivityFormData.isActionDisabled}
            isAdditionalActionDisabled={
              soActivityFormData.isSecondaryActionDisabled
            }
            handleAction={soActivityFormData.handleAction}
            handleAdditionalAction={soActivityFormData.handleSecondaryAction}
          />
        )
      }
    >
      {soActivityFormData.isActivityFromTemplate && (
        <SOCActivityInfo
          label={translator.translate('Template Name')}
          activityName={soActivityFormData.activityName}
          buttonLabel={translator.translate('Browse')}
          onClickAction={soActivityFormData.handleChangeActivity}
        />
      )}
      <div tw="grid gap-4">
        {soActivityFormData.activities.map((activity, indexOfActivity) => (
          <SOActivityFormItem
            translator={translator}
            activityItem={activity}
            activities={soActivityFormData.activities}
            index={indexOfActivity}
            panelAction={
              soActivityFormData.activities.length > 1
                ? {
                    click: () =>
                      promiseToVoid([
                        soActivityFormData.handleRemoveActivity(
                          indexOfActivity,
                        ),
                      ]),
                    icon: <Icon.Delete />,
                    text: translator.translate('Delete'),
                  }
                : undefined
            }
            panelAdditionalAction={
              soActivityFormData.activities.length < MAX_SO_ACTIVITY
                ? {
                    click: () =>
                      promiseToVoid([
                        soActivityFormData.handleDuplicateActivity(
                          indexOfActivity,
                        ),
                      ]),
                    icon: (
                      <Icon.Duplicate
                        stroke={theme`colors.grey.two`}
                        fill={theme`colors.beige.bg`}
                      />
                    ),
                    text: translator.translate('Duplicate'),
                  }
                : undefined
            }
            isLocationListFetchLoading={
              soActivityFormData.isLocationListFetchLoading
            }
            locationOptions={soActivityFormData.locationOptions}
            soDate={soActivityFormData.soDate}
            handleAddNewLocation={() => {
              soActivityFormData.handleAddNewLocation(indexOfActivity);
            }}
            handleChangeLocationAutotext={
              soActivityFormData.handleChangeLocationAutotext
            }
            handleClickChoice={soActivityFormData.handleClickChoice}
            handleChangeDataLocation={
              soActivityFormData.handleChangeDataLocation
            }
            handleFetchMoreLocation={soActivityFormData.handleFetchMoreLocation}
            handleChangeDateActivity={
              soActivityFormData.handleChangeDateActivity
            }
            handleRemoveLocation={soActivityFormData.handleRemoveLocation}
            handleClickEditActivity={soActivityFormData.handleClickEditActivity}
            getActivityFormErrors={soActivityFormData.getActivityFormErrors}
            key={`activity-item-${activity.index}-${indexOfActivity}`}
          />
        ))}

        <SOCreateActivityFormAction
          translator={translator}
          isMaxExceeded={
            soActivityFormData.activities.length >= MAX_SO_ACTIVITY
          }
          hasActivities={!!soActivityFormData.activities.length}
          activitiesError={soActivityFormData.activitiesError}
          handleAddMoreActivity={soActivityFormData.handleAddMoreActivity}
        />
      </div>
      {soActivityFormData.isCreateATInfoVisible && (
        <SOCreateATInfoForm
          translator={translator}
          isTemplateRequireSaveDisabled={
            soActivityFormData.isTemplateRequireSaveDisabled
          }
          isTemplateRequireSave={soActivityFormData.isTemplateRequireSave}
          soActivityTemplateFormData={
            soActivityFormData.soActivityTemplateFormData || []
          }
          handleChangeSaveTemplate={(e) => {
            if (soActivityFormData.handleChangeSaveTemplate)
              promiseToVoid([soActivityFormData.handleChangeSaveTemplate(e)]);
          }}
        />
      )}
    </SOSection>
  );
}
