import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import getUnixTime from 'date-fns/getUnixTime';
import parse from 'date-fns/parse';
import startOfToday from 'date-fns/startOfToday';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../app/store/store.app';
import {
  BulkActionEntity,
  BulkActionStatusEnum,
  ErrorCodes,
} from '../../../constant';
import useOrganizationOrientation from '../../../hook/organization/useOrganizationOrientation.hook';
import { PostDriverCreateParams } from '../../../model/Driver.model';
import { logEvent } from '../../../service/analytic/analytic.service';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { navigationParamAction } from '../../../store/param.store';
import { errorCodeToLabel } from '../../../util/error.util';
import { driverRoute } from '../../Driver/driver.route';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';
import { DriverAddBulk } from '../driverAddBulk.route';

const mapStoreToState = (drivers: DriverAddBulk[]) =>
  drivers.map((v) => ({
    ...v,
    _actionStatus: BulkActionStatusEnum.WAITING,
    _actionDetail: 'Waiting for registration',
  }));

const mapStoreToDto = (
  entity: AddBulkDriverEntity,
): PostDriverCreateParams => ({
  fullName: entity['Nama Lengkap Sopir'],
  phoneNumber: entity['Nomor Telepon'],
  pin: entity?.PIN?.toString() ?? '',
  ...(entity.Alamat && { address: entity.Alamat }),
  ...(entity['Tanggal Lahir'] && {
    // in seconds
    dateOfBirth: getUnixTime(
      parse(entity['Tanggal Lahir'], 'dd/MM/yyyy', startOfToday()),
    ),
  }),
  ...(entity['Nomor SIM'] && { licenseNumber: entity['Nomor SIM'] }),
});

// #region TYPES
export type AddBulkDriverEntity = BulkActionEntity<DriverAddBulk>;
export type UseDriverAddBulkLogic = ReturnType<typeof useDriverAddBulkLogic>;
// #endregion

export default function useDriverAddBulkLogic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleVerifyAuth } = useVerifyAuth();
  const { handleFetchOrganizationData } = useOrganizationOrientation();

  // #region VALUES
  const [showStatus, setShowStatus] = useState(false);

  const [truncatedFilename, setTruncatedFilename] = useState('');

  const { filename, drivers: driversStore } = useSelector(
    (state: RootState) =>
      state.navigationParam.driverAddBulkParams ?? {
        filename: '',
        drivers: [],
      },
  );

  const [driversWithStatusDetail, setDriversWithStatusDetail] = useState<
    AddBulkDriverEntity[]
  >(mapStoreToState(driversStore));
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(true);
  const [showDoneBtn, setShowDoneBtn] = useState(false);
  // to disable submit button when mutations is still ongoing
  const [mutationsIsLoading, setMutationsIsLoading] = useState(false);
  const [doAddDriver] = api.useAddDriverMutation();
  // #endregion

  // #region HANDLERS
  const handleAddBulk = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    setShowStatus(true);
    const promises = driversWithStatusDetail.map((driver) =>
      doAddDriver(mapStoreToDto(driver)).unwrap(),
    );

    // track loading state
    setMutationsIsLoading(true);

    // NOTE: backend said this endpoint is safe to the parallel mutation
    await Promise.allSettled(promises)
      .then((results) => {
        for (const [idx, result] of results.entries()) {
          // on rejected
          if (result.status === 'rejected') {
            const errorQuery = (result.reason as FetchBaseQueryError)
              ?.data as ApiErrorResponse;
            const errorCode = errorQuery?.error?.code;
            const errorDetails = errorQuery?.error?.details;

            setDriversWithStatusDetail((_drivers) =>
              _drivers.map((_driver) =>
                _driver.id === driversWithStatusDetail[idx].id
                  ? {
                      ..._driver,
                      _actionStatus: BulkActionStatusEnum.FAILED,
                      _actionDetail:
                        errorCode === ErrorCodes.REQUEST_INVALID_ARGUMENT
                          ? errorDetails
                          : errorCodeToLabel(errorCode),
                    }
                  : _driver,
              ),
            );
            continue;
          }

          // on fulfilled
          setDriversWithStatusDetail((_drivers) =>
            _drivers.map((_driver) =>
              _driver.id === driversWithStatusDetail[idx].id
                ? {
                    ..._driver,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Driver registered successfully',
                  }
                : _driver,
            ),
          );

          logEvent('Driver:AddBulk');
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        // on done
        setShowDoneBtn(true);
        setMutationsIsLoading(false);
        setHasUnsavedChanges(false);
      });
  }, [doAddDriver, handleVerifyAuth, driversWithStatusDetail]);

  const handleDone = useCallback(() => {
    // clear store
    dispatch(navigationParamAction.clearDriverAddBulkParams());
    navigate(driverRoute.path);
    handleFetchOrganizationData();
  }, [dispatch, handleFetchOrganizationData, navigate]);
  // #endregion

  // sync `driversStore` with `driversWithStatusDetail`
  useEffect(() => {
    if (driversStore.length > 0)
      setDriversWithStatusDetail(mapStoreToState(driversStore));
  }, [driversStore]);

  // sync redux store with state
  useEffect(() => {
    if (filename.length < 30) {
      setTruncatedFilename(filename);
      return;
    }
    const [name, format] = filename.split('.');
    const truncatedName = name.slice(0, 25);
    setTruncatedFilename(`${truncatedName}...${format}`);
  }, [filename]);

  return {
    hasUnsavedChanges,
    filename,
    truncatedFilename,
    driversWithStatusDetail,
    showDoneBtn,
    mutationsIsLoading,
    showStatus,
    handleAddBulk,
    handleDone,
  };
}
