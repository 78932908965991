import React from 'react';
import { Icon } from '../../component/atom';
import { RouteConfig } from '../../config/router/router.config';
import { JobOrderForm } from '../../model/JobOrder.model';
import { ShipperOrderFormValues } from '../../model/ShipperOrder.model';

export type ActivityTemplateAddRouteParam = {
  originPath?: string;
  soState?: ShipperOrderFormValues;
  joState?: JobOrderForm;
};

export const activityTemplateAddRoute: RouteConfig = {
  name: 'Add Activity Template',
  path: '/activity-template/add',
  Component: React.lazy(() => import('./ActivityTemplateAdd.view')),
  isPrivate: true,
  breadcrumb: 'Add Activity Template',
  options: {
    icon: <Icon.NavActivityTemplate />,
    label: 'Add Activity Template',
    testID: 'MenuAddActivityTemplate',
  },
};
