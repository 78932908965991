import React from 'react';
import { RouteConfig } from '../../../../../config/router/router.config';

export type ReportDrivingBehaviorByScheduleRouteParam = string;

export type ReportDrivingBehaviorByScheduleRouteSearchParam = {
  page?: string; // number
  pageSize?: string; // number
  search?: string;
  orderBy?: string; // RDBScheduleFilterOrderByEnum
  driverId?: string;
  driverName?: string;
  filterBy?: string; // RDBScheduleFilterByEnum
  startDate?: string; // number in milliseconds
  endDate?: string; // number in milliseconds
};

export type ReportDrivingBehaviorByScheduleRouteNavigationState = undefined;

export const reportDrivingBehaviorByScheduleRoute: RouteConfig = {
  name: 'Driving Behavior by Schedule',
  path: '/report/driving-behavior-by-schedule',
  Component: React.lazy(() => import('./ReportDrivingBehaviorBySchedule.view')),
  isPrivate: true,

  breadcrumb: 'Driving Behavior by Schedule',

  options: {
    icon: <div />,
    label: 'Driving Behavior by Schedule',
    testID: 'MenuReportDrivingBehaviorBySchedule',
  },
};

export default {};
