import React, { Ref, useMemo } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { RefHandles } from 'react-spring-bottom-sheet/dist/types';
import tw from 'twin.macro';
import { JODetailMobileSheetContent } from '..';
import { SOStatus } from '../../../constant';
import { UseJODetailSheetHookObj } from '../../../hook/jobOrderDetail/useJODetailSheet.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { JobOrderInfo, JOStatus } from '../../../model/JobOrder.model';
import JODetailMobileFooterAction from '../JODetailMobileFooterAction/JODetailMobileFooterAction.organism';
import JODetailMobileHeader from '../JODetailMobileHeader/JODetailMobileHeader.organism';

// #region STYLED
const SheetContainer = tw.div`h-full flex flex-col`;
// #endregion

// #region INTERFACES

type Props = {
  joInfoData: JobOrderInfo;
  isLoading?: boolean;
  translator: UseTranslator;
  forceLoading?: boolean;
  joDetailSheet: UseJODetailSheetHookObj;
  handleBack: () => void;
  handleForceComplete: () => void;
};

interface FooterProps {
  showJOAction: boolean;
  showSOAction: boolean;
  isLoading: boolean;
  translator: UseTranslator;
  handleClick: () => void;
}

// #endregion

function Footer({
  translator,
  showJOAction,
  showSOAction,
  isLoading,
  handleClick,
}: FooterProps) {
  if (!showJOAction && !showSOAction) return null;
  if (showJOAction)
    return (
      <JODetailMobileFooterAction
        translator={translator}
        isLoading={isLoading}
        label="Force Complete Job Order"
        handleClick={handleClick}
      />
    );
  return (
    <JODetailMobileFooterAction
      translator={translator}
      isLoading={isLoading}
      label="Force Complete Shipper Order"
      handleClick={handleClick}
    />
  );
}

export default function JODetailMobileBottomSheet({
  joInfoData,
  translator,
  isLoading,
  forceLoading,
  handleForceComplete,
  handleBack,
  joDetailSheet,
}: Props) {
  const handleBackTernary = !joDetailSheet.selectedSOId
    ? handleBack
    : joDetailSheet.handleBackSODetail;
  const showJOAction = useMemo(
    () =>
      !joDetailSheet.hideMap &&
      joDetailSheet.showJONav &&
      !joDetailSheet.selectedActivityId &&
      !joDetailSheet.selectedSOId &&
      ![JOStatus.DELIVERED].includes(joInfoData.status),
    [
      joDetailSheet.hideMap,
      joDetailSheet.selectedActivityId,
      joDetailSheet.selectedSOId,
      joDetailSheet.showJONav,
      joInfoData.status,
    ],
  );

  const showSOAction =
    !joDetailSheet.selectedActivityId &&
    ![JOStatus.DELIVERED].includes(joInfoData.status) &&
    [SOStatus.ASSIGNED, SOStatus.DELIVERING, SOStatus.TRANSITING].includes(
      joDetailSheet.renderedSelectedSO?.status as unknown as SOStatus,
    );

  return (
    <BottomSheet
      className={`override-bottom-sheet-jo${
        joDetailSheet.showJONav || joDetailSheet.showHeaderBackButton
          ? ''
          : '-collapsed'
      } ${
        joDetailSheet.renderedSelectedSOActivity &&
        joDetailSheet.selectedActivityId
          ? 'override-bottom-sheet-so'
          : ''
      }`}
      ref={joDetailSheet.bottomSheetRef as unknown as Ref<RefHandles>}
      blocking={joDetailSheet.hideMap}
      open
      scrollLocking
      header={
        <JODetailMobileHeader
          showBackButton={joDetailSheet.showHeaderBackButton}
          handleBack={
            joDetailSheet.selectedActivityId
              ? joDetailSheet.handleBackFromActivityPhoto
              : handleBackTernary
          }
          handleDismiss={joDetailSheet.handleDismissHeader}
          showNavigation={joDetailSheet.showJONav}
          headerTitle={joDetailSheet.headerTitle}
          selectedActivityId={joDetailSheet.selectedActivityId}
          status={joInfoData.status}
          soStatus={joDetailSheet.renderedSelectedSO?.status}
          statusLabel={joDetailSheet.headerStatusLabel}
        />
      }
      footer={
        !isLoading && (showJOAction || showSOAction) ? (
          <Footer
            showJOAction={showJOAction}
            showSOAction={showSOAction}
            translator={translator}
            isLoading={!!forceLoading}
            handleClick={handleForceComplete}
          />
        ) : undefined
      }
      onSpringStart={joDetailSheet.handleSpringStart}
      defaultSnap={
        joDetailSheet.hideMap || joDetailSheet.selectedSOId
          ? joDetailSheet.sheetSnapPoints.top
          : joDetailSheet.sheetSnapPoints.center
      }
      snapPoints={() =>
        joDetailSheet.hideMap || joDetailSheet.selectedSOId
          ? joDetailSheet.sheetSnapPoints.top
          : [
              joDetailSheet.sheetSnapPoints.min,
              joDetailSheet.sheetSnapPoints.center,
              joDetailSheet.sheetSnapPoints.top,
            ]
      }
    >
      <SheetContainer data-testid="JODetailMobileBottomSheet:SheetContainer">
        <JODetailMobileSheetContent
          isLoading={isLoading}
          translator={translator}
          joDetailSheet={joDetailSheet}
        />
      </SheetContainer>
    </BottomSheet>
  );
}
