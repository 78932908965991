import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import { BulkActionEntity, BulkActionStatusEnum } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { JobOrderExpense } from '../../../model/JobOrder.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { ApproveJOExpenseRequest } from '../../../service/endpoint/jobOrder/jobOrder.endpoint';
import { snackbarAction } from '../../../store/snackbar.store';
import { errorCodeToLabel } from '../../../util/error.util';
import { jobOrderDetailRoute } from '../../JobOrderDetail/JobOrderDetail.route';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';
import { JobOrderDetailExpenseApproveRouteState } from '../JobOrderDetailExpenseApprove.route';

// #region INTERFACES
type ApproveExpenseEntity = BulkActionEntity<JobOrderExpense>;
export type UseJobOrderDetailExpenseApproveLogic = ReturnType<
  typeof useJobOrderDetailExpenseApproveLogic
>;
// #endregion

export default function useJobOrderDetailExpenseApproveLogic() {
  const translator = useTranslator();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleVerifyAuth } = useVerifyAuth();

  const navigationState = useLocation()
    .state as JobOrderDetailExpenseApproveRouteState;
  const params = useParams();

  const [showStatus, setShowStatus] = useState(false);
  const [expensesWithStatusDetail, setExpensesWithStatusDetail] = useState<
    ApproveExpenseEntity[]
  >(
    (navigationState?.expenses ?? []).map((v) => ({
      ...v,
      _actionStatus: BulkActionStatusEnum.WAITING,
      _actionDetail: 'Waiting for approvement',
    })),
  );
  const [showDoneBtn, setShowDoneBtn] = useState<boolean>(false);
  const [mutationsIsLoading, setMutationsIsLoading] = useState<boolean>(false); // to disable submit button when mutations is still ongoing
  const [doApproveExpense] = api.useApproveJOExpenseMutation();
  // #endregion

  // #region HANDLERS
  const onApprove = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    const onApprovePromise = async () => {
      // track loading state
      setMutationsIsLoading(true);
      // map expenses to DTO
      const payload: ApproveJOExpenseRequest = {
        expenses: expensesWithStatusDetail.map((entity) => ({
          id: entity.id,
          joId: entity.joId,
        })),
      };

      try {
        const response = await doApproveExpense(payload).unwrap();

        setExpensesWithStatusDetail((_expenses) =>
          _expenses.map((_expense) => {
            const found = response.result.errors.find(
              ({ data }) => data.id === _expense.id,
            );

            return {
              ..._expense,
              _actionStatus: found
                ? BulkActionStatusEnum.FAILED
                : BulkActionStatusEnum.SUCCEED,
              _actionDetail: found
                ? errorCodeToLabel(found.code)
                : 'Approval Succeed',
            };
          }),
        );
      } catch (err) {
        // unpredicted errors
        const errorQuery = (err as FetchBaseQueryError)
          ?.data as ApiErrorResponse;
        const message = errorCodeToLabel(errorQuery?.error?.code);
        dispatch(
          snackbarAction.show({
            type: SnackbarTheme.warning,
            message: translator.translate(message),
          }),
        );
      }

      // on done
      setShowDoneBtn(true);
      setMutationsIsLoading(false);
    };

    setShowStatus(true);
    await onApprovePromise();
  }, [
    dispatch,
    doApproveExpense,
    handleVerifyAuth,
    expensesWithStatusDetail,
    translator,
  ]);

  const onCancelOrDone = useCallback(() => {
    const searchParams = new URLSearchParams();
    searchParams.append('breadcrumb', navigationState?.breadcrumb ?? '');
    searchParams.append('tab', 'spending-report');

    const joDetailPath = jobOrderDetailRoute.path.replace(
      ':id',
      params?.id ?? 'unknown',
    );
    const expensePath = `${joDetailPath}?${searchParams.toString()}`;

    navigate(expensePath);
  }, [navigate, navigationState?.breadcrumb, params?.id]);

  return {
    expensesWithStatusDetail,
    showDoneBtn,
    mutationsIsLoading,
    showStatus,
    onApprove,
    onCancelOrDone,
  };
}
