import React from 'react';
import { Icon } from '../../component/atom';
import { RouteConfig } from '../../config/router/router.config';
import { ShipperSearchParam } from '../../model/Shipper.model';

export type ShipperAddRouteParam =
  | {
      searchParamsFromShipperList?: ShipperSearchParam;
    }
  | undefined;

export const shipperAddRoute: RouteConfig = {
  name: 'Add Shipper',
  Component: React.lazy(() => import('./ShipperAdd.view')),
  path: '/shipper/add',
  isPrivate: true,
  options: {
    icon: <Icon.Shipper />,
    label: 'Shipper',
    testID: 'MenuShipperAdd',
  },
};
