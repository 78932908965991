import { useEffect } from 'react';
import { Location } from 'react-router-dom';
import { routeToDocumentTitle } from '../../util/wrapper.util';
import { UseTranslator } from '../useTranslator.hook';

function useDocumentTitleUpdate(translator: UseTranslator, location: Location) {
  useEffect(() => {
    document.title = translator.translate(
      routeToDocumentTitle(location.pathname),
    );
  }, [location.pathname, translator]);
}

export default useDocumentTitleUpdate;
