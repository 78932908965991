import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormItem } from '../../../types/input.type';
import { CardContainer, Text } from '../../atom';
import { CardAction, SOSection } from '../../molecule';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';

type Props = {
  title: string;
  formData: FormItem[];
  isEditable?: boolean;
  isSubmitDisabled?: boolean;
  submitTestId?: string;
  submitTitle?: string;
  subHeader?: React.ReactNode;
  handleSubmit?(): void;
};

const HeaderContainer = tw.div`flex justify-between`;

const Container = styled.div`
  ${tw`width[720px] mx-auto pb-5`}
  & input {
    text-overflow: ellipsis;
  }

  & textarea {
    min-height: 50px;
    height: max-content;
  }
`;

export default function SODHeaderForm({
  title,
  formData,
  isEditable,
  isSubmitDisabled,
  submitTestId,
  submitTitle,
  subHeader,
  handleSubmit,
}: Props) {
  return (
    <Container>
      <CardContainer>
        <SOSection
          header={
            <HeaderContainer>
              <Text.HeadingFour>{title}</Text.HeadingFour>
              {subHeader}
            </HeaderContainer>
          }
          footer={
            isEditable &&
            !!handleSubmit && (
              <CardAction
                actionButtonType="submit"
                title={submitTitle || ''}
                buttonTestID={submitTestId}
                isActionDisabled={isSubmitDisabled}
                handleAction={handleSubmit}
              />
            )
          }
        >
          <div>
            {formData.map((item) => (
              <ComposedFormField
                key={`SO-header-form-${item.id}`}
                // type already described inside component
                {...item}
              />
            ))}
          </div>
        </SOSection>
      </CardContainer>
    </Container>
  );
}
