import { MutableRefObject } from 'react';
import {
  HelpCenterGeneralTerminologyAnchor,
  HelpCenterJOTerminologyAnchor,
  helpCenterScrollIntoViewOptions,
  HelpCenterSOTerminologyAnchor,
  HelpCenterTerminology,
  helpCenterTerminologyData,
} from '../../constant/HelpCenter.constant';
import { helpCenterGettingStartedTerminologyRoute } from '../../view/HelpCenter/sections/GettingStarted/Terminology/HelpCenterGettingStartedTerminology.route';

/**
 *
 * @param {MutableRefObject<HTMLDivElement | null>} offsetTop
 * @returns void
 *
 * @summary scroll into view ref
 */
export const handleScrollIntoViewHelpCenter = ({
  ref,
}: { ref?: MutableRefObject<HTMLDivElement | null> }) => {
  ref?.current?.scrollIntoView(helpCenterScrollIntoViewOptions);
};

export const mapperHelpCenterObjFromType = (type: string) => {
  const literal: Record<string, HelpCenterTerminology> = {
    [HelpCenterGeneralTerminologyAnchor.LASTMILE]:
      helpCenterTerminologyData.general.lastMile,
    [HelpCenterGeneralTerminologyAnchor.TRANSIT]:
      helpCenterTerminologyData.general.transit,
    [HelpCenterGeneralTerminologyAnchor.TEMPLATE]:
      helpCenterTerminologyData.general.template,
    [HelpCenterGeneralTerminologyAnchor.STANDBY]:
      helpCenterTerminologyData.general.standby,
    [HelpCenterGeneralTerminologyAnchor.PICKUP]:
      helpCenterTerminologyData.general.pickUp,
    [HelpCenterGeneralTerminologyAnchor.DROPOFF]:
      helpCenterTerminologyData.general.dropOff,
    [HelpCenterGeneralTerminologyAnchor.DRIVER]:
      helpCenterTerminologyData.general.driver,
    [HelpCenterGeneralTerminologyAnchor.SHIPPER]:
      helpCenterTerminologyData.general.shipper,
    [HelpCenterJOTerminologyAnchor.ASSIGNED]:
      helpCenterTerminologyData.jo.assigned,
    [HelpCenterJOTerminologyAnchor.DELIVERING]:
      helpCenterTerminologyData.jo.delivering,
    [HelpCenterJOTerminologyAnchor.DRAFT]: helpCenterTerminologyData.jo.draft,
    [HelpCenterJOTerminologyAnchor.DELIVERED]:
      helpCenterTerminologyData.jo.delivered,
    [HelpCenterSOTerminologyAnchor.INPROCESS]:
      helpCenterTerminologyData.so.delivered,
    [HelpCenterSOTerminologyAnchor.ASSIGNED]:
      helpCenterTerminologyData.so.delivered,
    [HelpCenterSOTerminologyAnchor.DELIVERING]:
      helpCenterTerminologyData.so.delivered,
    [HelpCenterSOTerminologyAnchor.INTRANSIT]:
      helpCenterTerminologyData.so.delivered,
    [HelpCenterSOTerminologyAnchor.DELIVERED]:
      helpCenterTerminologyData.so.delivered,
  };
  return literal[type];
};
/**
 *
 * @param searchParams
 * @param title
 * @returns boolean
 *
 * @summary check if is terminology need to be highlighted in table
 */
export const isTerminologyHighlighted = ({
  searchParams,
  title,
}: { searchParams?: string | null; title: string }) => {
  if (!searchParams) return false;

  return mapperHelpCenterObjFromType(searchParams).title === title;
};

export const helpCenterUrlAnchorWrapper = (anchor: string) => {
  return `${helpCenterGettingStartedTerminologyRoute.path}?ref=${anchor}`;
};
