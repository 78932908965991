import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import envConfig from '../../../config/env/env.config';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import { RedirectUrlTarget, ShipperOrderDetailTab } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { snackbarAction } from '../../../store/snackbar.store';
import {
  copyToClipboard,
  doRedirectUrl,
  generateArrayUrlParams,
} from '../../../util/helper.util';
import { formatText } from '../../../util/tracking/trackingTimeline.util';
import { jobOrderDetailRoute } from '../../JobOrderDetail/JobOrderDetail.route';
import { shipperOrderRoute } from '../../ShipperOrder/ShipperOrder.route';
import { trackingRoute } from '../../Tracking/Tracking.route';
import {
  shipperOrderDetailRoute,
  ShipperOrderDetailRouteParam,
} from '../ShipperOrderDetail.route';
import { UseShipperOrderInfo } from './useShipperOrderInfo.hook';
import useSOShareDisclaimer, {
  SOTrackingDisclaimerType,
} from './useSOShareDisclaimer.hook';

type Props = {
  info: UseShipperOrderInfo;
};
export type UseSODetailController = ReturnType<typeof useSODetailController>;

export default function useSODetailController({ info: { soInfo } }: Props) {
  // #region GENERAL
  const dispatch = useDispatch();
  const translator = useTranslator();
  const navigate = useNavigate();
  const navigationState = useLocation()
    .state as ShipperOrderDetailRouteParam | null;
  const navigationStateRef = useRef(navigationState);
  const params = useParams();
  const [urlSearchParams] = useSearchParams();

  const searchParams = generateArrayUrlParams(
    navigationStateRef.current?.searchParamsFromSOList,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState<ShipperOrderDetailTab>(
    ShipperOrderDetailTab.HEADER,
  );
  const [selectedJOId, setSelectedJOId] = useState<string | undefined>(
    undefined,
  );

  const [isCurrentSectionChanged, setIsCurrentSectionChanged] = useState<
    ShipperOrderDetailTab | undefined
  >(undefined);
  const [showConfirmationModal, setShowConfirmationModal] = useState<
    ShipperOrderDetailTab | undefined
  >(undefined);

  const { disclaimerType, setDisclaimerType, handleApproveDisclaimer } =
    useSOShareDisclaimer({
      soId: params?.id || '',
    });

  const isValidPhoneNumber = useMemo(() => {
    if (!soInfo) return false;
    if (!soInfo.shipper.phoneNumber) return false;
    const phoneNumber = soInfo.shipper.phoneNumber.slice(3);
    return phoneNumber.startsWith('8');
  }, [soInfo]);

  const onTabChange = (value: ShipperOrderDetailTab) => {
    if (value !== tab && isCurrentSectionChanged) {
      setShowConfirmationModal(value);
      return;
    }
    setTab(value);
  };

  const handleCloseDisclaimer = useCallback(() => {
    setDisclaimerType(undefined);
  }, [setDisclaimerType]);

  const handleDismissChangeSection = () => {
    setShowConfirmationModal(undefined);
  };

  const handleDiscardConfirmation = () => {
    handleDismissChangeSection();
    setIsCurrentSectionChanged(undefined);
  };

  const handleConfirmChangeSection = (value: ShipperOrderDetailTab) => {
    handleDiscardConfirmation();
    setTab(value);
  };

  const handleBackHeader = () => {
    navigate({ pathname: shipperOrderRoute.path, search: searchParams });
  };

  const handleJONumberClicked = useCallback((id: string) => {
    doRedirectUrl({
      url: `${window.location.origin}${jobOrderDetailRoute.path.replace(
        ':id',
        id,
      )}`,
      target: RedirectUrlTarget.BLANK,
    });
  }, []);

  const handleWhatsAppClick = useCallback(() => {
    if (!soInfo || !soInfo.shipper.phoneNumber) return;
    handleCloseDisclaimer();
    doRedirectUrl({
      url: `https://wa.me/${soInfo.shipper.phoneNumber}?text=${formatText(
        translator.translate(
          'Thank you for choosing our delivery service! Your tracking number is %s. You can track your order with the link: %s%s?receipt=%s',
          SupportedLanguage.id,
        ),
        soInfo.trackingCode,
        envConfig.ktbJOServiceUrl,
        trackingRoute.path,
        soInfo.trackingCode,
      )}`,
      target: RedirectUrlTarget.BLANK,
    });
  }, [soInfo, translator, handleCloseDisclaimer]);

  const handleCopyClick = useCallback(() => {
    if (!soInfo || !soInfo.trackingCode) return;

    dispatch(
      snackbarAction.show({
        type: SnackbarTheme.dark,
        message: translator.translate('Successfully copy tracking link'),
      }),
    );
    handleCloseDisclaimer();

    copyToClipboard(
      `${envConfig.ktbJOServiceUrl}${trackingRoute.path}?receipt=${soInfo.trackingCode}`,
    );
  }, [handleCloseDisclaimer, dispatch, translator, soInfo]);

  const handleSuccessDisclaimer = useCallback(() => {
    if (!disclaimerType) return;

    if (disclaimerType === SOTrackingDisclaimerType.WA) {
      return handleWhatsAppClick();
    }

    return handleCopyClick();
  }, [disclaimerType, handleCopyClick, handleWhatsAppClick]);

  const handleClickApproveDisclaimer = useCallback(() => {
    handleApproveDisclaimer({ onSuccess: handleSuccessDisclaimer });
  }, [handleSuccessDisclaimer, handleApproveDisclaimer]);
  // #endregion

  // #region ERROR
  const errorTitle = useMemo(
    () => translator.translate('Page Not Found'),
    [translator],
  );
  const errorBody = useMemo(
    () =>
      translator.translate(
        "Either the URL you requested was not found on this server or you don't have permission to access this page.",
      ),
    [translator],
  );
  const errorActionLabel = useMemo(
    () => translator.translate('Back to Shipper Order List'),
    [translator],
  );

  const handleErrorAction = useCallback(() => {
    navigate(shipperOrderRoute.path);
  }, [navigate]);
  // #endregion

  // #region ACTION HANDLER
  useEffect(() => {
    if (navigationState?.tab) {
      setTab(navigationState?.tab);
    }
  }, [navigationState]);

  useEffect(() => {
    const searchParamObj = Object.fromEntries(urlSearchParams);
    if (!params.id || !!searchParamObj.breadcrumb) return;
    navigate(
      {
        pathname: shipperOrderDetailRoute.path.replace(':id', params.id),
        search: generateArrayUrlParams({ breadcrumb: soInfo?.number }),
      },
      { replace: true },
    );
  }, [navigate, soInfo?.number, urlSearchParams, params.id]);
  // #endregion

  return {
    isLoading,
    tab,
    errorTitle,
    errorBody,
    errorActionLabel,
    showConfirmationModal,
    isCurrentSectionChanged,
    selectedJOId,
    isValidPhoneNumber,
    disclaimerType,
    setDisclaimerType,
    handleCloseDisclaimer,
    handleClickApproveDisclaimer,
    setSelectedJOId,
    setIsLoading,
    setIsCurrentSectionChanged,
    onTabChange,
    handleErrorAction,
    handleBackHeader,
    handleJONumberClicked,
    handleDiscardConfirmation,
    handleConfirmChangeSection,
    handleDismissChangeSection,
    handleWhatsAppClick,
    handleCopyClick,
  };
}
