import { Locale } from './locale.config';

const legalLocaleConfig: Locale[] = [
  {
    en: 'The following terms and conditions apply in respect of any services, information or materials provided by Runner-KTBFuso.co.id ("RUNNER") via this website and the Internet (collectively "information"). By accessing this website, you agree to these terms and conditions. This website is for informational purposes only. While we endeavor to ensure the accuracy of all information, we do not represent that the information is correct, complete or up-to-date. RUNNER excludes to the extent lawfully permitted all liability (including for consequential loss) however arising (including due to negligence) that may be incurred in connection with any use of or reliance upon this information. We exclude all implied conditions and warranties except where to do so would cause any of these provisions to be void. RUNNER reserves the right to update any information on this website (including these terms and conditions) at any time, without prior notice. While we endeavor to ensure that all vehicle specifications, features and accessories are described and positioned as accurately as possible, all such information is indicative only. Your Mitsubishi dealer can confirm specific details and can provide you with an official quotation. Unless stated otherwise, RUNNER owns or licenses the copyright and all other proprietary rights in information on this website (including text and images). You may not use any copyrighted information or trade marks appearing on this website without the permission of RUNNER or as permitted by Indonesian law.',
    id: 'Syarat dan ketentuan berikut berlaku untuk setiap layanan, informasi atau materi yang disediakan oleh Runner-KTBFuso.co.id ("RUNNER") melalui situs web ini dan Internet (secara kolektif "informasi"). Dengan mengakses situs web ini, Anda menyetujui syarat dan ketentuan ini. Situs web ini hanya untuk tujuan informasi. Meskipun kami berusaha keras untuk memastikan keakuratan semua informasi, kami tidak menyatakan bahwa informasi tersebut benar, lengkap, atau terbaru. RUNNER mengecualikan sejauh yang diizinkan secara hukum semua tanggung jawab (termasuk untuk kerugian konsekuensial) yang timbul (termasuk karena kelalaian) yang mungkin timbul sehubungan dengan penggunaan atau ketergantungan pada informasi ini. Kami mengecualikan semua ketentuan dan jaminan yang tersirat kecuali jika melakukannya akan menyebabkan salah satu ketentuan ini menjadi tidak berlaku. RUNNER berhak memperbarui informasi apa pun di situs web ini (termasuk syarat dan ketentuan ini) kapan saja, tanpa pemberitahuan sebelumnya. Meskipun kami berusaha keras untuk memastikan bahwa semua spesifikasi kendaraan, fitur, dan aksesori dijelaskan dan ditempatkan seakurat mungkin, semua informasi tersebut hanya bersifat indikatif. Dealer Mitsubishi Anda dapat mengonfirmasi detail spesifik dan memberi Anda penawaran resmi. Kecuali dinyatakan lain, RUNNER memiliki atau melisensikan hak cipta dan semua hak kepemilikan lainnya atas informasi di situs web ini (termasuk teks dan gambar). Anda tidak boleh menggunakan informasi berhak cipta atau merek dagang yang muncul di situs web ini tanpa izin dari RUNNER atau sebagaimana diizinkan oleh hukum Indonesia.',
  },
];

export default legalLocaleConfig;
