import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BulkActionStatusEnum,
  DeleteActivityTemplateEntity,
} from '../../../constant';
import { ActivityTemplate } from '../../../model/ActivityTemplate.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { errorCodeToLabel } from '../../../util/error.util';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';

export type UseActivityTemplateDeleteLogicObj = ReturnType<
  typeof useActivityTemplateDeleteLogic
>;

export default function useActivityTemplateDeleteLogic() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { handleVerifyAuth } = useVerifyAuth();

  const [showStatus, setShowStatus] = useState(false);

  const activityTemplatesFromNavState = (state || []) as ActivityTemplate[];

  const [
    activityTemplatesWithStatusDetail,
    setActivityTemplatesWithStatusDetail,
  ] = useState<DeleteActivityTemplateEntity[]>(
    activityTemplatesFromNavState.map((v) => ({
      ...v,
      _actionStatus: BulkActionStatusEnum.WAITING,
      _actionDetail: 'Waiting for deletion',
    })),
  );
  const [showDoneBtn, setShowDoneBtn] = useState<boolean>(false);
  const [mutationsIsLoading, setMutationsIsLoading] = useState<boolean>(false);
  const [doDeleteAT] = api.useDeleteActivityTemplateMutation();
  // #endregion

  // #region HANDLERS
  const onDelete = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    const onDeletePromise = async () => {
      setMutationsIsLoading(true);

      for (let i = 0; i < activityTemplatesWithStatusDetail.length; i += 1) {
        const { id } = activityTemplatesWithStatusDetail[i];

        try {
          // NOTE: currently our backend can not receive excessive amount of requests in parallel, that is why we used for loop instead of `Promise.allSettled`
          await doDeleteAT({ id }).unwrap();

          setActivityTemplatesWithStatusDetail((_activityTemplates) =>
            _activityTemplates.map((_at) =>
              _at.id === id
                ? {
                    ..._at,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Removed from activity template',
                  }
                : _at,
            ),
          );
        } catch (err) {
          const errorQuery = (err as FetchBaseQueryError)
            ?.data as ApiErrorResponse;

          setActivityTemplatesWithStatusDetail((_activityTemplates) =>
            _activityTemplates.map((_at) =>
              _at.id === id
                ? {
                    ..._at,
                    _actionStatus: BulkActionStatusEnum.FAILED,
                    _actionDetail: errorCodeToLabel(errorQuery?.error?.code),
                  }
                : _at,
            ),
          );
        }
      }

      setShowDoneBtn(true);
      setMutationsIsLoading(false);
    };
    setShowStatus(true);
    await onDeletePromise();
  }, [activityTemplatesWithStatusDetail, doDeleteAT, handleVerifyAuth]);

  const onCancelOrDone = useCallback(() => {
    navigate('/activity-template');
  }, [navigate]);

  return {
    activityTemplatesWithStatusDetail,
    showDoneBtn,
    mutationsIsLoading,
    showStatus,
    onDelete,
    onCancelOrDone,
  };
}
