import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { Text } from '../../../component/atom';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../../component/molecule';
import { BulkActionStatusEnum } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { ErrorDetail } from '../../../service/api.endpoint';
import {
  mapBulkActionStatusToLabel,
  mapErrorMessages,
} from '../../../util/helper.util';
import {
  AddBulkDriverEntity,
  UseDriverAddBulkLogic,
} from './useDriverAddBulkLogic.hook';

// #region TYPES
type Props = {
  bulkLogic: UseDriverAddBulkLogic;
};
// #endregion

export default function useDriverAddBulkTable({
  bulkLogic: { driversWithStatusDetail, showStatus },
}: Props) {
  const translator = useTranslator();

  const columns = useMemo<ColumnDef<AddBulkDriverEntity>[]>(
    () => [
      {
        size: 200,
        header: translator.translate('Full Name'),
        accessorKey: 'Nama Lengkap Sopir',
        cell: ({ getValue }) => {
          const fullName = getValue() as string;

          return (
            <CustomTableRowWithPopover
              primaryLabel={fullName ?? '-'}
              containerStyle={tw`max-w-[240px]`}
            />
          );
        },
      },
      {
        size: 150,
        header: translator.translate('Phone Number'),
        accessorKey: 'Nomor Telepon',
        cell: ({ getValue }) => {
          const phoneNumber = getValue() as string;

          return <Text.Paragraph>{phoneNumber ?? '-'}</Text.Paragraph>;
        },
      },
      ...(showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({
                getValue,
              }: { getValue: () => string | ErrorDetail[] }) => {
                const accessor = getValue();
                const label =
                  typeof accessor === 'string'
                    ? translator.translate(accessor)
                    : mapErrorMessages(translator, accessor);

                return (
                  <CustomTableRowWithPopover
                    primaryLabel={label}
                    containerStyle={tw`max-w-[290px]`}
                  />
                );
              },
            },
          ]
        : []),
    ],
    [showStatus, translator],
  );

  const table = useReactTable({
    data: driversWithStatusDetail,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
