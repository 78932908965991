import React from 'react';

export default function StartFromScratch() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={174}
      height={148}
      fill="none"
    >
      <rect width={173.333} height={18.122} x={0.333} fill="#F3532E" rx={6} />
      <rect
        width={168.333}
        height={21.941}
        x={2.833}
        y={24.452}
        fill="#E4E0CC"
        stroke="#E4E0CC"
        strokeWidth={5}
        rx={3.5}
      />
      <rect
        width={171.333}
        height={24.941}
        x={1.333}
        y={54.882}
        stroke="#E4E0CC"
        strokeWidth={2}
        rx={5}
      />
      <rect
        width={171.333}
        height={55.873}
        x={1.333}
        y={86.313}
        stroke="#E4E0CC"
        strokeWidth={2}
        rx={5}
      />
    </svg>
  );
}
