import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import { JODetailSpendingProps } from '../../../constant/JobOrderDetail.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { logEvent } from '../../../service/analytic/analytic.service';
import api from '../../../service/api.service';
import { snackbarAction } from '../../../store/snackbar.store';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';
import { UseJODetailSpendingReportController } from './useJODetailSpendingController.hook';
import { UseJODetailSpendingReportTable } from './useJODetailSpendingReportTable.hook';

type Props = {
  spendingReportController: UseJODetailSpendingReportController;
  spendingReportTable: UseJODetailSpendingReportTable;
};
export type UseJODetailSpendingReject = ReturnType<
  typeof useJODetailSpendingReject
>;

export default function useJODetailSpendingReject({
  spendingReportController: {
    showSpendingDetail,
    spendingProps,
    setSpendingProps,
    setShowSpendingDetail,
  },
  spendingReportTable: { disableRejectButton, checkedItems, setCheckedItems },
}: Props) {
  const translator = useTranslator();
  const dispatch = useDispatch();

  const { handleVerifyAuth } = useVerifyAuth();

  const [rejectText, setRejectText] = useState<string | undefined>(undefined);
  const [modalVisible, setModalVisible] = useState<
    JODetailSpendingProps | undefined
  >(undefined);

  const errorMessage = useMemo(() => {
    if ((rejectText?.length || 0) <= 160) return '';
    return translator.translate('Max 160 characters.');
  }, [rejectText, translator]);

  const [rejectExpenseMutation, rejectExpenseMutationResponse] =
    api.useRejectJOExpenseMutation();

  const handleCloseRejectModal = useCallback(() => {
    setModalVisible((v) => {
      // if there's no selection, means that the expense detail opens
      if (spendingProps) setShowSpendingDetail(v);
      return undefined;
    });
    setSpendingProps(undefined);
    setRejectText(undefined);
  }, [setShowSpendingDetail, setSpendingProps, spendingProps]);

  const handleOpenRejectModal = useCallback(
    (isDetail?: boolean) => {
      if (isDetail) setSpendingProps(showSpendingDetail);
      setShowSpendingDetail((v) => {
        setModalVisible(v);
        return undefined;
      });
    },
    [setShowSpendingDetail, setSpendingProps, showSpendingDetail],
  );

  const handleReject = useCallback(async () => {
    try {
      logEvent('JobOrderDetail:RejectExpense');
      const authRes = await handleVerifyAuth();
      if (!authRes) return;
      const response = await rejectExpenseMutation({
        id: modalVisible?.id || '',
        joId: modalVisible?.joId || '',
        ...(rejectText && { rejectionNote: (rejectText ?? '').trim() }),
      }).unwrap();

      if (!response.ok) return;
      dispatch(
        snackbarAction.show({
          message: `${translator.translate('Successfully Rejected')} ${
            modalVisible?.number || ''
          }!`,
        }),
      );
      handleCloseRejectModal();
    } catch (err) {
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translator.translate(
            'Fail to reject the expense. Please reload this page.',
          ),
        }),
      );
    } finally {
      rejectExpenseMutationResponse.reset();
      // clear table selection if there's any
      if (checkedItems.length) setCheckedItems([]);
    }
  }, [
    checkedItems.length,
    dispatch,
    handleCloseRejectModal,
    handleVerifyAuth,
    modalVisible?.id,
    modalVisible?.joId,
    modalVisible?.number,
    rejectExpenseMutation,
    rejectExpenseMutationResponse,
    rejectText,
    setCheckedItems,
    translator,
  ]);

  const handleBulkReject = useCallback(() => {
    // we can't use native button `disabled` attributes, because it will negates the activation of all event listeners/handlers for showing the tooltip
    // instead we guard it in the `onClick` handlers, and adjust styling based on `disableRejectButton` derived state
    if (disableRejectButton || !checkedItems.length) return;

    setSpendingProps(undefined);
    setModalVisible(checkedItems[0]);
  }, [checkedItems, disableRejectButton, setSpendingProps]);

  return {
    rejectText,
    translator,
    modalVisible,
    errorMessage,
    isLoading: rejectExpenseMutationResponse.isLoading,
    setRejectText,
    setModalVisible,
    handleReject,
    handleBulkReject,
    handleCloseRejectModal,
    handleOpenRejectModal,
  };
}
