import type { Blocker, Transition } from 'history';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { redirectionAction } from '../../store/redirection.store';

type BlockerNavigator = Navigator & {
  location: Location;
  block(blocker: Blocker): () => void;
};

export type UseBlockRouteProps = {
  hasUnsavedChanges: boolean;
  onCancelActionClick(): void;
  isStayOnSubmit?: boolean;
  onActionClick?(): void;
  onActionClickPromise?(): Promise<boolean>;
};

function useBlockRoute({
  hasUnsavedChanges,
  onCancelActionClick,
  isStayOnSubmit,
  onActionClick = () => {},
  onActionClickPromise,
}: UseBlockRouteProps) {
  const [displayAlert, setDisplayAlert] = useState(false);
  const [transition, setTransition] = useState<Transition>();
  const dispatch = useDispatch();
  const navigator = useContext(UNSAFE_NavigationContext)
    .navigator as unknown as BlockerNavigator;

  useEffect(() => {
    if (!hasUnsavedChanges) return undefined;

    const unblock = navigator.block((tx: Transition) => {
      const {
        location: { pathname: targetPathname },
      } = tx;

      if (targetPathname === navigator.location.pathname) {
        return;
      }

      dispatch(redirectionAction.update({ blockedRoute: targetPathname }));
      setDisplayAlert(true);

      setTransition({
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      });
    });

    return () => {
      unblock();
      dispatch(redirectionAction.clear());
    };
  }, [navigator, hasUnsavedChanges, dispatch]);

  const leavePage = useCallback(() => {
    if (!onActionClickPromise) {
      onActionClick();
      setDisplayAlert(false);
      if (!isStayOnSubmit) {
        transition?.retry();
      }
    } else {
      (async () => {
        const isSuccess = await onActionClickPromise();
        setDisplayAlert(false);

        if (isSuccess) {
          transition?.retry();
        }
      })().catch(() => {});
    }
  }, [onActionClickPromise, onActionClick, transition, isStayOnSubmit]);

  const stayOnPage = useCallback(() => {
    setDisplayAlert(false);
  }, []);
  const handleSecondaryAction = useCallback(() => {
    transition?.retry();
    onCancelActionClick();
    setDisplayAlert(false);
  }, [onCancelActionClick, transition]);
  return {
    displayAlert,
    leavePage,
    stayOnPage,
    handleSecondaryAction,
  };
}

export default useBlockRoute;
