import fromUnixTime from 'date-fns/fromUnixTime';
import truncate from 'lodash/truncate';
import { RedirectUrlTarget, SOActivityType } from '../../constant';
import {
  JODetailSpendingReportFilterCategoryValue,
  JODetailSpendingReportFilterOrderByValue,
  JODetailSpendingReportFilterStatus,
  JODetailSpendingReportFilterStatusValue,
} from '../../constant/JobOrderDetail.constant';
import { DELIVERYLOCATIONTYPE } from '../../constant/Location.constant';
import { IJOSOListData } from '../../hook/jobOrderDetail/useJODetailSheet.hook';
import { UseTranslator } from '../../hook/useTranslator.hook';
import { Driver } from '../../model/Driver.model';
import {
  JOActivityInfo,
  JobOrderExpenseEventType,
  JobOrderExpenseOrderBy,
  JobOrderExpenseStatus,
  JobOrderExpenseType,
  JobOrderForm,
  JobOrderInfo,
} from '../../model/JobOrder.model';
import { Vehicle } from '../../model/Vehicle.model';
import { shipperOrderDetailRoute } from '../../view/ShipperOrderDetail/ShipperOrderDetail.route';
import { getDateFormat, getFullDateTimeFormat } from '../date.util';
import { currencyFormatter, getShortLabel } from '../formatter.util';
import { doRedirectUrl } from '../helper.util';
import {
  getJODetailActivities,
  getJODetailTravelExpense,
  getJODetailVehicle,
  mapJODetailLocationTypeToLabel,
  mapJODetailSOActivityTypeToLabel,
} from '../jobOrder.util';
import { mapSOFilterStatusToLabel } from '../shipperOrder.util';

export const generateHeaderContentItem = (
  translator: UseTranslator,
  joInfo?: JobOrderInfo,
) => {
  if (!joInfo) return [];
  return [
    {
      label: translator.translate('Job Order Date'),
      value: getDateFormat(fromUnixTime(joInfo.date)),
    },
    {
      label: translator.translate('Vehicle'),
      value: joInfo.vehicle ? getJODetailVehicle(joInfo.vehicle) : '-',
    },
    {
      label: translator.translate('Seal Number'),
      value: joInfo.sealNumber || '-',
    },
    {
      label: translator.translate('Travel Budget'),
      value: getJODetailTravelExpense(joInfo.travelExpense),
    },
    {
      label: translator.translate('Driver'),
      value: joInfo?.driver?.fullName || '-',
    },
    {
      label: translator.translate('Total Expense'),
      value: getJODetailTravelExpense(
        joInfo.expenseSummary.totalApprovedExpense,
      ),
    },
  ];
};

export const generateJOMobileContentItem = (
  translator: UseTranslator,
  joInfo?: JobOrderInfo,
) => {
  if (!joInfo) return [];
  return [
    {
      label: translator.translate('Job Order Date'),
      value: getDateFormat(fromUnixTime(joInfo.date)),
    },
    {
      label: 'Driver',
      value: joInfo?.driver?.fullName || '-',
    },
    {
      label: 'Travel Budget',
      value: getJODetailTravelExpense(joInfo.travelExpense),
    },
    {
      label: 'Seal Number',
      value: joInfo.sealNumber || '-',
    },
    {
      label: 'Last Mile/Transit',
      value: translator.translate(
        mapJODetailLocationTypeToLabel(DELIVERYLOCATIONTYPE.LAST_MILE),
      ),
    },
    {
      label: 'Vehicle',
      value: joInfo?.vehicle ? getJODetailVehicle(joInfo?.vehicle) : '-',
    },
  ];
};

const handleSelectShipperOrder = (soId: string, soNumber: string) => {
  doRedirectUrl({
    url: `${window.location.origin}${shipperOrderDetailRoute.path.replace(
      ':id',
      soId,
    )}?breadcrumb=${getShortLabel(soNumber)}`,
    target: RedirectUrlTarget.BLANK,
  });
};

export const generateSOListMobileContentItem = (
  refetchJOInfo: () => void,
  setSelectedSOId: (id: string) => void,
  translator: UseTranslator,
  joInfoData?: JobOrderInfo,
): IJOSOListData[] => {
  if (!joInfoData) return [];
  const formattedObj = joInfoData.deliveries.map(
    (item, idx): IJOSOListData => ({
      id: item.shipperOrder.id,
      number: item.shipperOrder.number,
      shipperName: item.shipperOrder.shipper?.name,
      status: item.status,
      isSOTransit: item.shipperOrder.isTransitable,
      transitGoods: item.shipperOrder?.activities[idx]?.goods,
      statusLabel: translator.translate(
        mapSOFilterStatusToLabel(item.shipperOrder.status),
      ),
      activities: getJODetailActivities(item.activities),
      handleClickTitle: () =>
        handleSelectShipperOrder(
          item.shipperOrder.id,
          item.shipperOrder.number,
        ),
      handleClickActivities: () => {
        refetchJOInfo();
        setSelectedSOId(item.shipperOrder.id);
      },
    }),
  );
  return formattedObj;
};

export const generateJOSOMobileHeaderItem = (
  translator: UseTranslator,
  deliveryItem?: JOActivityInfo,
) => {
  if (!deliveryItem) return [];
  return [
    {
      label: 'Expected Time',
      value: deliveryItem.soActivity?.expectedFinishAt
        ? getFullDateTimeFormat(
            deliveryItem.soActivity.expectedFinishAt * 1000,
            true,
          )
        : '-',
    },
    {
      label: `${mapJODetailSOActivityTypeToLabel({
        activityType: deliveryItem.type || deliveryItem.soActivity?.type,
        translator,
      })} Location`,
      value:
        deliveryItem.location?.name ||
        deliveryItem.soActivity?.location?.name ||
        '',
    },
  ];
};

export const generateJOFormValues = (
  joInfo?: JobOrderInfo,
): JobOrderForm | undefined => {
  if (!joInfo) return undefined;

  return {
    joDate: joInfo?.date,
    joNumber: joInfo?.number,
    deliveries: joInfo?.deliveries.map((item) => {
      // activity with type dropoff inside the deliveries obj is a transit activity
      const transitActivity = item.activities.find(
        (activity) => activity.type === SOActivityType.DROP_OFF,
      );
      return {
        soId: item.shipperOrder.id,
        ...(transitActivity && {
          transitLocationId: String(transitActivity.locationId),
        }),
      };
    }),

    driverId: joInfo?.driver?.id,
    ...(joInfo?.driver && {
      driverOption: {
        label: joInfo?.driver?.fullName || '',
        value: joInfo?.driver?.id || '',
      },
    }),
    vehicleId: joInfo?.vehicle?.id,
    vehicleOption: joInfo?.vehicle,
    isUseAssignment: joInfo?.isUseAssignment,
    deliveryLocationType: DELIVERYLOCATIONTYPE.LAST_MILE,
    travelExpenses: joInfo?.travelExpense,
    sealNumber: joInfo.sealNumber,
    approvedTravelBudget: joInfo.expenseSummary?.totalApprovedExpense,
    ...(joInfo.isUseAssignment &&
      joInfo?.driver &&
      joInfo?.vehicle && {
        schedule: {
          id: '',
          driver: joInfo?.driver,
          vehicle: joInfo?.vehicle,
          from: 0,
          createdAt: 0,
          to: 0,
          updatedAt: 0,
        },
      }),
  };
};

/**
 * map through `orderBy` search params to get the string label
 */
export function mapJODetailSpendingReportFilterOrderByToLabel(
  orderBy: JobOrderExpenseOrderBy,
): string {
  const literal: Record<JobOrderExpenseOrderBy, string> = {
    [JobOrderExpenseOrderBy.NUMBER_DESC]: 'Expense Number Z-A',
    [JobOrderExpenseOrderBy.NUMBER_ASC]: 'Expense Number A-Z',
    [JobOrderExpenseOrderBy.SUBMITTED_AT_DESC]: 'Newest Submission',
    [JobOrderExpenseOrderBy.SUBMITTED_AT_ASC]: 'Oldest Submission', // default
  };

  return literal[orderBy ?? JobOrderExpenseOrderBy.SUBMITTED_AT_ASC];
}

/**
 * get JO detail spending report filter orderBy values
 */
export function getJODetailSpendingReportFilterOrderByValues(
  translator: UseTranslator,
): JODetailSpendingReportFilterOrderByValue[] {
  return [
    {
      label: translator.translate('Expense Number Z-A'),
      value: JobOrderExpenseOrderBy.NUMBER_DESC,
    },
    {
      label: translator.translate('Expense Number A-Z'),
      value: JobOrderExpenseOrderBy.NUMBER_ASC,
    },
    {
      label: translator.translate('Newest Submission'),
      value: JobOrderExpenseOrderBy.SUBMITTED_AT_DESC,
    },
    {
      label: translator.translate('Oldest Submission'),
      value: JobOrderExpenseOrderBy.SUBMITTED_AT_ASC,
    },
  ];
}

/**
 * get JO detail spending report filter category values
 */
export function getJODetailSpendingReportFilterCategoryValues(
  translator: UseTranslator,
): JODetailSpendingReportFilterCategoryValue[] {
  return [
    {
      label: translator.translate('Fuel'),
      value: JobOrderExpenseType.FUELS,
    },
    {
      label: translator.translate('Tolls / Roads Fees'),
      value: JobOrderExpenseType.TOLLS,
    },
    {
      label: translator.translate('Parking Fees'),
      value: JobOrderExpenseType.PARKING_FEES,
    },
    {
      label: translator.translate('Others'),
      value: JobOrderExpenseType.OTHERS,
    },
  ];
}

/**
 * get JO detail spending report filter status values
 */
export function getJODetailSpendingReportFilterStatusValues(
  translator: UseTranslator,
): JODetailSpendingReportFilterStatusValue[] {
  return [
    {
      label: translator.translate('Need Review'),
      value: JODetailSpendingReportFilterStatus.NEED_REVIEW,
    },
    {
      label: translator.translate('Approved'),
      value: JODetailSpendingReportFilterStatus.APPROVED,
    },
    {
      label: translator.translate('Rejected'),
      value: JODetailSpendingReportFilterStatus.REJECTED,
    },
  ];
}

/**
 * get JO detail spending report status number from query string
 */
export function mapJODetailSpendingReportFilterStatuses(
  statuses: JODetailSpendingReportFilterStatus[],
): JobOrderExpenseStatus[] {
  return statuses.map((status) => {
    const literal: Record<
      JODetailSpendingReportFilterStatus,
      JobOrderExpenseStatus
    > = {
      [JODetailSpendingReportFilterStatus.NEED_REVIEW]:
        JobOrderExpenseStatus.PENDING,
      [JODetailSpendingReportFilterStatus.APPROVED]:
        JobOrderExpenseStatus.APPROVED,
      [JODetailSpendingReportFilterStatus.REJECTED]:
        JobOrderExpenseStatus.REJECTED,
    };

    return literal[status];
  });
}

/**
 * map expense status number to label
 */
export function mapJODetailExpenseStatusNumberToLabel(
  status: JobOrderExpenseStatus,
): string {
  const literal: Record<JobOrderExpenseStatus, string> = {
    [JobOrderExpenseStatus.DRAFT]: 'Draft',
    [JobOrderExpenseStatus.PENDING]: 'Need Review',
    [JobOrderExpenseStatus.APPROVED]: 'Approved',
    [JobOrderExpenseStatus.REJECTED]: 'Rejected',
  };

  return literal[status];
}

/**
 * map expense category to label
 */
export function mapJODetailExpenseCategoryToLabel(
  category: JobOrderExpenseType,
): string {
  const literal: Record<JobOrderExpenseType, string> = {
    [JobOrderExpenseType.FUELS]: 'Fuels',
    [JobOrderExpenseType.TOLLS]: 'Tolls',
    [JobOrderExpenseType.PARKING_FEES]: 'Parking Fees',
    [JobOrderExpenseType.OTHERS]: 'Others',
  };

  return literal[category];
}

/**
 * map spending type label
 * @param type
 * @returns
 */
export const getExpenseLabelByType = (type?: JobOrderExpenseType) => {
  const text: Record<string, string> = {
    [JobOrderExpenseType.FUELS]: 'Fuels',
    [JobOrderExpenseType.OTHERS]: 'Others',
    [JobOrderExpenseType.TOLLS]: 'Tolls/ Road Fees',
    [JobOrderExpenseType.PARKING_FEES]: 'Parking Fees',
  };
  return type ? text[type] : 'Others';
};

/**
 * map spending type label
 * @param type
 * @returns
 */
export const getExpenseLabelByEvent = (type?: JobOrderExpenseEventType) => {
  switch (type) {
    case JobOrderExpenseEventType.SUBMITTED:
      return 'Expense Submitted';
    case JobOrderExpenseEventType.APPROVED:
      return 'Expense Approved!';
    case JobOrderExpenseEventType.REJECTED:
      return 'Expense Rejected';
    case JobOrderExpenseEventType.PENDING:
      return 'Pending';
    case JobOrderExpenseEventType.RE_SUBMITTED:
      return 'Expense Re-submitted';
    default:
      return 'Unknown Type';
  }
};

/**
 * format all the possible event history data value to either string | null
 */
export const formatEventDataValue = (
  value:
    | null
    | string
    | number
    | Pick<Vehicle, 'id' | 'name'>
    | Pick<Driver, 'id' | 'fullName'>,
) => {
  let data: null | string | number;

  if (typeof value === 'string') {
    data = truncate(value, { length: 30 });
  } else if (typeof value === 'number' || !value) {
    data = value; // This is travel expense
  } else if ('name' in value) {
    data = value.name; // This is vehicle
  } else {
    data = truncate(value.fullName, { length: 30 }); // This is driver
  }

  // format the travel expense with currency formatter
  return typeof data === 'number' ? currencyFormatter(data) : data;
};
