import React from 'react';

export default function SizeFit({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={fill || 'currentColor'}
        strokeWidth="2"
        d="M19 5l-5 5m0 0h4m-4 0V6M5 19l5-5m0 0H6m4 0v4"
      />
    </svg>
  );
}
