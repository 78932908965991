export enum RDBVehicleFilterOrderByEnum {
  NAME_ASC = 'NAME_ASC', // Vehicle Name A-Z (default)
  NAME_DESC = 'NAME_DESC', // Vehicle Name Z-A
}

export type ReportDrivingBehaviorByVehicle = {
  chassisNumber: string;
  completedHoDelivery: number | null;
  completedJo: number | null;
  distance: number | null;
  harshAcceleration: number | null;
  harshBraking: number | null;
  harshCornering: number | null;
  hoDeliveryDriversName: string[];
  joDriversName: string[];
  maxSpeed: number | null;
  modelName: string;
  overspeedCount: number | null;
  vehicleName: string;
  vehicleNumber: string;
};

export type ReportDrivingBehaviorSummary = {
  totalJo?: number | null;
  totalHoDelivery?: number | null;
  totalHarshAcceleration?: number | null;
  totalHarshBraking?: number | null;
  avgMileage?: number | null;
  avgMaxSpeed?: number | null;
  totalOverspeedCount?: number | null;
  totalHarshCornering?: number | null;
};

export type ReportDrivingBehaviorTopDriver = {
  id: string;
  fullName: string;
};
export type ReportDrivingBehaviorTopVehicle = {
  id: string;
  name: string;
  chassisNumber: string;
};
