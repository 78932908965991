import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jobOrderRoute } from '../../view/JobOrder/JobOrder.route';
import useTranslator from '../useTranslator.hook';

export type JOSubmissionModalProps = {
  joNumber: string;
  vehicleName?: string;
  driverName?: string;
};
export default function useJOFormSubmissionModal() {
  const { translate } = useTranslator();
  const navigate = useNavigate();
  const [joSubmissionModalProps, setJOSubmissionModalProps] = useState<
    JOSubmissionModalProps | undefined
  >(undefined);

  const isAssigned = useMemo(
    () =>
      joSubmissionModalProps?.driverName && joSubmissionModalProps.vehicleName,
    [joSubmissionModalProps?.driverName, joSubmissionModalProps?.vehicleName],
  );

  const title = translate(
    isAssigned ? 'Job Order Assigned' : 'Job Order Saved as a Draft',
  );

  const backActionLabel = translate('Back to Job Order List');
  const joNumberLabel = translate('Your Job Order Number is');

  const assignedLabel = translate('Assigned Driver & Vehicle :');

  const descriptionLabel = translate(
    isAssigned
      ? 'Successfully assign Job Order. Use the Job Order Number to see the detail of your order.'
      : 'Successfully saved Job Order as a draft. Use the Job Order Number to see the detail of your order. You can return to this Job Order and complete the assignment later.',
  );

  const handleBack = () => {
    navigate(jobOrderRoute.path, { replace: true });
  };
  return {
    title,
    isAssigned,
    backActionLabel,
    joNumberLabel,
    assignedLabel,
    descriptionLabel,
    joSubmissionModalProps,
    handleBack,
    setJOSubmissionModalProps,
  };
}
