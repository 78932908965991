import React, { createRef, MutableRefObject, useEffect, useState } from 'react';
import tw from 'twin.macro';
import {
  Checkbox,
  Chip,
  Icon,
  IconButton,
  Input,
  ListItem,
  OutsideAlerter,
  Paper,
  Popover,
} from '../../atom';
import { SelectOption } from '../../Type.component';

type Props = React.ComponentProps<'input'> & {
  label?: string;
  selectedDatas?: string[];
  datas?: SelectOption[];
  changeData?(data: string[]): void;
  error?: string;
};

const Container = tw.div`relative border border-solid border-beige-lines px-4 py-2 pb-0.5`;
const ErrorMessage = tw.div`font-sans font-size[12px] line-height[20px] letter-spacing[0.2px] mt-0.5 mx-4 text-status-alert`;
const SelectedChip = tw(
  Chip.Small,
)`bg-grey-six mr-1.5 mb-1.5 max-w-full md:max-width[60%]`;

export function CheckboxDropdown({
  disabled,
  selectedDatas,
  children,
  datas,
  error,
  changeData,
  ...props
}: Props) {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const inputRef = createRef<HTMLInputElement>();
  const [height, setHeight] = useState<number>(0);
  const handleSelect = (item: string) => {
    const match = selectedDatas?.filter((x) => x === item);
    if (match && match.length > 0) {
      changeData?.(selectedDatas?.filter((x) => x !== item) ?? []);
    } else {
      changeData?.(selectedDatas?.concat(item) ?? []);
    }
  };

  useEffect(() => {
    setHeight(inputRef.current?.getBoundingClientRect().height ?? 0);
  }, [inputRef]);

  const filteredDatas = search
    ? datas?.filter((x) => x.label.toLowerCase().includes(search.toLowerCase()))
    : datas;

  return (
    <OutsideAlerter onClickAway={() => setOpenDropdown(false)}>
      <Container ref={inputRef}>
        <div tw="flex w-full">
          <div tw="flex-1 flex flex-wrap items-center">
            {selectedDatas?.map((item) =>
              datas?.filter((x) => x.value === item)[0] ? (
                <SelectedChip
                  key={`CheckboxDropdown-item-${item}`}
                  label={datas?.filter((x) => x.value === item)[0].label ?? ''}
                  right={
                    <button
                      type="button"
                      tw="p-0.5 rounded-full bg-black cursor-pointer duration-200 hover:bg-opacity-80"
                      onClick={() => handleSelect(item)}
                    >
                      <Icon.Close tw="h-3.5 w-3.5 text-white" />
                    </button>
                  }
                />
              ) : (
                <> </>
              ),
            )}
            <Input
              onFocus={() => (disabled ? null : setOpenDropdown(true))}
              onClick={() => (disabled ? null : setOpenDropdown(true))}
              disabled={disabled}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              tw="flex-auto p-0 mb-1.5 height[34px] border-none"
              {...props}
            />
          </div>
          <div tw="flex-shrink-0">
            <IconButton
              disabled={disabled}
              css={[
                tw`-mr-2 p-2.5 transform[rotate(90deg)]`,
                openDropdown && tw`transform[rotate(-90deg)]`,
              ]}
              onClick={(e) => {
                if (!disabled) {
                  e.stopPropagation();
                  setOpenDropdown(!openDropdown);
                }
              }}
            >
              <Icon.ChevronSharp tw="w-5 h-5 text-grey-two" />
            </IconButton>
          </div>
        </div>
        <Popover
          variableRefHeight={height}
          visible={openDropdown}
          targetRef={inputRef as MutableRefObject<null>}
          tw="min-w-full z-10 overflow-visible"
          onClick={() => setOpenDropdown(false)}
        >
          <Paper tw="py-4">
            {filteredDatas?.map((item) => (
              <ListItem.Small
                tw="max-h-80 overflow-auto"
                key={`ChecboxDropdown-${item.value}`}
                left={
                  <Checkbox
                    checked={
                      !!selectedDatas?.filter((x) => x === item.value)[0]
                    }
                    onChange={() => handleSelect(item.value)}
                  />
                }
                label={item.label}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelect(item.value);
                }}
              />
            ))}
          </Paper>
        </Popover>
      </Container>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </OutsideAlerter>
  );
}

export default CheckboxDropdown;
