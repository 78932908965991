import React, { PropsWithChildren, useRef, useState } from 'react';
import tw, { styled, theme } from 'twin.macro';
import { AutocompleteType } from '../../../hook/useAutocomplete.hook';
import { Icon, Row, Text } from '../../atom';
import AutoComplete from '../AutoComplete/AutoComplete.molecule';
import { OptionType } from '../Select/Select.molecule';
import Tooltip from '../Tooltip/Tooltip.molecule';

type Props = {
  label?: string;
  isRequired?: boolean;
  id: string;
  values?: string;
  error?: string;
  hideIcon?: boolean;
  hideCloseButton?: boolean;
  hasExtraLabel?: boolean;
  placeholder?: string;
  options?: OptionType[];
  loading?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  labelKey?: 'label' | 'extraLabel';
  selectedOption?: AutocompleteType;
  hasDivider?: boolean;
  insideLabel?: string;
  onSelect: (data?: AutocompleteType) => void;
  onChange?: (data?: string) => void;
  onFetchMore?: () => void;
  onBlur?: () => void;
  onRemoveData?: () => void;
};

const Container = tw.div`flex-1`;
const ChildrenContainer = styled.div(({ hasLabel }: { hasLabel?: boolean }) => [
  hasLabel && tw`ml-[200px]`,
]);
const LabelFormContainer = tw.div`width[180px] margin-right[20px]`;
const Column = styled.div(({ hasDivider }: { hasDivider?: boolean }) => [
  hasDivider && tw`border-b mb-2.5 pb-2.5 border-beige-lines`,
]);

export default function AutocompleteInput({
  id,
  values,
  label,
  isRequired,
  error,
  disabled,
  placeholder,
  hideIcon,
  hideCloseButton,
  hasExtraLabel,
  options,
  loading,
  tooltipText,
  selectedOption,
  labelKey,
  hasDivider,
  insideLabel,
  onBlur = () => {},
  onFetchMore,
  onChange,
  onSelect,
  onRemoveData,
  children,
}: PropsWithChildren<Props>) {
  const tooltipRef = useRef(null);
  const [visible, setVisible] = useState(false);
  return (
    <Column hasDivider={hasDivider}>
      <Row>
        {label && (
          <LabelFormContainer
            css={[
              tooltipText && tw`display[flex] flex-direction[row] items-center`,
            ]}
          >
            <Text.BodyFourteen tw="text-grey-three">
              {`${label} ${isRequired ? '*' : ''}`}
            </Text.BodyFourteen>
            {tooltipText && (
              <>
                <Icon.InfoOutlined
                  svgRef={tooltipRef}
                  svgStyle={[tw`ml-1 cursor-default`]}
                  fillPath={theme`colors.grey.three`}
                  onMouseEnter={() => setVisible(true)}
                  onMouseLeave={() => setVisible(false)}
                />
                <Tooltip
                  targetRef={tooltipRef}
                  visible={visible}
                  variant="light"
                  placement="top-start"
                  offset={[-20, 20]}
                >
                  <Text.Label tw="whitespace-pre-wrap">
                    {tooltipText}
                  </Text.Label>
                </Tooltip>
              </>
            )}
          </LabelFormContainer>
        )}
        <Container>
          <AutoComplete
            insideLabel={insideLabel}
            selectedOption={selectedOption}
            disabled={disabled}
            id={id}
            name={id}
            hideIcon={hideIcon}
            hideCloseButton={hideCloseButton}
            value={values}
            error={error}
            datas={options}
            hasExtraLabel={hasExtraLabel}
            placeholder={placeholder}
            tw="bg-orange-dark-lines"
            loading={loading}
            labelKey={labelKey}
            changeData={onSelect}
            changeInputText={onChange}
            onFetchMore={onFetchMore}
            onRemoveData={onRemoveData}
            onBlurAutocomplete={onBlur}
          />
        </Container>
      </Row>
      {!!children && (
        <Row>
          <ChildrenContainer hasLabel={!!label}>{children}</ChildrenContainer>
        </Row>
      )}
    </Column>
  );
}
