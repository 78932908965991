import React from 'react';
import tw from 'twin.macro';
import { FormItem } from '../../../types/input.type';
import { Button, CardContainer } from '../../atom';
import { CardContent, CardHeader } from '../../molecule';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';

type Props = {
  title: string;
  handleBack(): void;
  handleSubmit?(): void;
  formData: FormItem[];
  submitLabel: string;
};

const Container = tw.div`width[720px] mx-auto pb-5`;
const ContainerForm = tw.div`pb-2`;

export default function DeliveryLocationAddHeaderForm({
  title,
  formData,
  submitLabel,
  handleBack,
  handleSubmit,
}: Props) {
  return (
    <Container>
      <CardContainer>
        <CardHeader handleBack={handleBack} title={title} />
        <CardContent>
          {formData.map((item) => (
            <ContainerForm key={`DLA-header-form-${item.id}`}>
              <ComposedFormField
                // type already described inside component
                {...item}
              />
            </ContainerForm>
          ))}
        </CardContent>
        <div tw="p-4 border-t border-t-beige-lines text-right">
          <Button.Solid
            disabled={!handleSubmit}
            onClick={handleSubmit}
            tw="ml-auto"
            id="addDeliveryLocationBtn"
          >
            {submitLabel}
          </Button.Solid>
        </div>
      </CardContainer>
    </Container>
  );
}
