import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { fromUnixTime, getUnixTime, startOfDay } from 'date-fns';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import { ShipperOrderDetailTab, SOStatus } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import {
  ShipperOrderInfo,
  SOHeaderUpdateParam,
  SOHeaderUpdateResponse,
} from '../../../model/ShipperOrder.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { snackbarAction } from '../../../store/snackbar.store';
import { FormItem } from '../../../types/input.type';
import { errorCodeToLabel } from '../../../util/error.util';
import { getShortLabel } from '../../../util/formatter.util';
import { isFormChanged } from '../../../util/helper.util';
import { validateShipperOrder } from '../../../util/shipperOrderCreate.util';
import { isSODetailEditable } from '../../../util/shipperOrderDetail.util';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';
import { shipperOrderDetailRoute } from '../ShipperOrderDetail.route';

type Props = {
  soInfo?: ShipperOrderInfo;
  showConfirmationModal?: ShipperOrderDetailTab;
  setIsLoading: (val: boolean) => void;
  setIsCurrentSectionChanged: (val?: ShipperOrderDetailTab) => void;
  handleConfirmChangeSection: (val: ShipperOrderDetailTab) => void;
};
export type UseSODetailHeader = ReturnType<typeof useSODetailHeader>;

export default function useSODetailHeader({
  soInfo,
  showConfirmationModal,
  setIsLoading,
  setIsCurrentSectionChanged,
  handleConfirmChangeSection,
}: Props) {
  // #region GENERAL
  const translator = useTranslator();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleVerifyAuth } = useVerifyAuth();

  const isFormEditable = useMemo(
    () =>
      soInfo?.status &&
      [SOStatus.IN_PROCESS, SOStatus.RESERVED].includes(soInfo?.status) &&
      soInfo?.deliveries.length <= 1,
    [soInfo?.deliveries.length, soInfo?.status],
  );

  const isNumberAutoGenerated: boolean = useMemo(
    () => /^SH-/g.test(soInfo?.number || ''),
    [soInfo?.number],
  );

  const title = useMemo(() => translator.translate('Header'), [translator]);
  const submitLabel = useMemo(
    () => translator.translate('Save Changes'),
    [translator],
  );

  // #endregion

  // #region API CALL
  const [updateSOHeader, updateSOHeaderResponse] =
    api.useUpdateSOHeaderMutation();
  // #endregion

  // #region ACTION HANDLER
  const handleError = useCallback(
    (error: FetchBaseQueryError | SerializedError) => {
      if (!error) return;
      const data = (error as FetchBaseQueryError).data as ApiErrorResponse;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translator.translate(errorCodeToLabel(data.error.code)),
        }),
      );
    },
    [dispatch, translator],
  );
  const handleSuccessSOHeader = useCallback(
    (response: SOHeaderUpdateResponse, soNumber: string) => {
      if (!response || (response && !response.ok)) return;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.light,
          message: translator.translate('SO Successfully Updated.'),
        }),
      );
      if (showConfirmationModal) {
        navigate(
          `${shipperOrderDetailRoute.path.replace(
            ':id',
            soInfo?.id || '',
          )}?breadcrumb=${getShortLabel(soNumber)}`,
        );
        handleConfirmChangeSection(showConfirmationModal);
      }
    },
    [
      dispatch,
      handleConfirmChangeSection,
      showConfirmationModal,
      navigate,
      soInfo?.id,
      translator,
    ],
  );
  // #endregion

  // #region FORM
  const initialValues: SOHeaderUpdateParam = useMemo(
    () => ({
      soId: soInfo?.id || '',
      notes: soInfo?.notes || '',
      referenceNumber: soInfo?.referenceNumber || '',
      soDate: soInfo?.date,
      soNumber: soInfo?.number,
    }),
    [
      soInfo?.date,
      soInfo?.id,
      soInfo?.notes,
      soInfo?.number,
      soInfo?.referenceNumber,
    ],
  );

  const handleSubmitHeader = useCallback(
    async (values: SOHeaderUpdateParam) => {
      try {
        const authRes = await handleVerifyAuth();
        if (!authRes) return;
        setIsLoading(true);
        const formattedValues = {
          soId: values.soId,
          notes: values?.notes ? values.notes : null,
          referenceNumber: values?.referenceNumber
            ? values.referenceNumber
            : null,
          soDate: values?.soDate,
          soNumber: values?.soNumber,
        };
        const res = await updateSOHeader(formattedValues).unwrap();
        handleSuccessSOHeader(res, values?.soNumber || '');
      } catch (err) {
        handleError(err as FetchBaseQueryError);
        setIsLoading(false);
      } finally {
        updateSOHeaderResponse.reset();
        setIsLoading(false);
      }
    },
    [
      handleError,
      handleVerifyAuth,
      handleSuccessSOHeader,
      setIsLoading,
      updateSOHeader,
      updateSOHeaderResponse,
    ],
  );

  const soHeaderForm = useFormik<SOHeaderUpdateParam>({
    initialValues,

    onSubmit: handleSubmitHeader,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      notes: yup
        .string()
        .optional()
        .max(1000, translator.translate('Maximum 1000 characters')),
      ...(!isNumberAutoGenerated && {
        soNumber: yup
          .string()
          .max(255, translator.translate('Max 255 characters.'))
          .test('soNumber', {}, validateShipperOrder('soNumber'))
          .required('Order number is required.'),
      }),
      soDate: yup
        .string()
        .required(translator.translate('Shipper date is required')),
      referenceNumber: yup
        .string()
        .max(255, translator.translate('Max 255 characters.'))
        .optional(),
    }),
  });

  const isHeaderFormChanged = useMemo(
    () =>
      isFormChanged<SOHeaderUpdateParam>(soHeaderForm.values, initialValues),
    [initialValues, soHeaderForm.values],
  );

  useEffect(() => {
    setIsCurrentSectionChanged(
      isHeaderFormChanged ? ShipperOrderDetailTab.HEADER : undefined,
    );
  }, [isHeaderFormChanged, setIsCurrentSectionChanged]);

  // #endregion

  // #region FORM ITEM
  const headerFormData = useMemo(
    (): FormItem[] => [
      {
        id: 'soDate',
        label: translator.translate('Shipper Order Date*'),
        placeholder: translator.translate('10 Jun 2022'),
        type: 'date',
        hideIcon: true,
        disabledDays: { after: new Date() },
        values: soHeaderForm.values?.soDate
          ? fromUnixTime(soHeaderForm.values.soDate)
          : undefined,
        onChange: (date) => {
          if (date) {
            void soHeaderForm.setFieldValue('soDate', getUnixTime(date));
          } else {
            void soHeaderForm.setFieldValue(
              'soDate',
              getUnixTime(startOfDay(new Date())),
            );
          }
        },
        error:
          soHeaderForm.errors?.soDate &&
          translator.translate(soHeaderForm.errors?.soDate),
        disabled: !isFormEditable,
      },
      {
        id: 'soNumber',
        label: translator.translate('Shipper Order Number'),
        placeholder: translator.translate('Order Number'),
        type: 'text',
        values: isSODetailEditable(
          soHeaderForm?.values.soNumber,
          isFormEditable,
        ),
        onChange: (soNumber) =>
          void soHeaderForm.setFieldValue('soNumber', soNumber),
        error:
          soHeaderForm.errors?.soNumber &&
          translator.translate(soHeaderForm.errors?.soNumber),
        disabled: !isFormEditable || isNumberAutoGenerated,
      },
      {
        id: 'referenceNumber',
        label: translator.translate('Reference Number'),
        placeholder: translator.translate('Reference Number'),
        type: 'text',
        values: isSODetailEditable(
          soHeaderForm?.values.referenceNumber,
          isFormEditable,
        ),
        error:
          soHeaderForm?.errors?.referenceNumber &&
          translator.translate(soHeaderForm?.errors?.referenceNumber),
        onChange: (referenceNumber) =>
          void soHeaderForm.setFieldValue('referenceNumber', referenceNumber),
        disabled: !isFormEditable,
      },
      {
        id: 'ShipperName',
        label: translator.translate('Shipper Name*'),
        placeholder: translator.translate('Shipper Name'),
        type: 'text',
        values: soInfo?.shipper.name || '-',
        onChange: () => {},
        disabled: true,
      },
      {
        id: 'notes',
        placeholder: translator.translate('Notes'),
        label: translator.translate('Notes'),
        type: 'text-area',
        values: isSODetailEditable(soHeaderForm?.values.notes, isFormEditable),
        error:
          soHeaderForm?.errors?.notes &&
          translator.translate(soHeaderForm?.errors?.notes),
        onChange: (notes) => {
          void soHeaderForm.setFieldValue('notes', notes);
        },
        disabled: !isFormEditable,
        autoSize: true,
      },
    ],
    [
      translator,
      soHeaderForm,
      isFormEditable,
      isNumberAutoGenerated,
      soInfo?.shipper.name,
    ],
  );
  // #endregion

  return {
    title,
    submitLabel,
    isFormEditable,
    isHeaderFormChanged,
    soHeaderForm,
    headerFormData,
  };
}
