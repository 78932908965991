import {
  ApiResponse,
  CommonApiMetadata,
  CommonApiResponse,
} from '../service/api.endpoint';
import { Driver } from './Driver.model';
import { Vehicle } from './Vehicle.model';
/**
 * @alias DriverVehicle
 */
export interface Schedule {
  id: string;
  driver: Driver;
  vehicle: Vehicle;
  from: number;
  to: number;
  createdAt: number;
  updatedAt: number;
}

export enum ScheduleOrderBy {
  DRIVER_NAME_ASC = 'DRIVER_NAME_ASC',
  DRIVER_NAME_DESC = 'DRIVER_NAME_DESC',
  VEHICLE_NAME_ASC = 'VEHICLE_NAME_ASC',
  VEHICLE_NAME_DESC = 'VEHICLE_NAME_DESC',
  FROM_DATE_ASC = 'FROM_DATE_ASC',
  FROM_DATE_DESC = 'FROM_DATE_DESC',
}

export type GetSchedulesApiRequest =
  | {
      page?: number;
      pageSize?: number;
      search?: string;
      orderBy?: string;
      from?: number;
      to?: number;
      driverId?: string;
      vehicleId?: string;
    }
  | never;

export type GetSchedulesApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    driverVehicles: Schedule[];
  };

export type RemoveScheduleApiRequest = {
  driverId: string;
  assignmentId: string;
};

export type RemoveScheduleApiResponse = ApiResponse;

export interface ScheduleParams {
  vehicleId: string;
  driverId: string;
  from: number;
  to: number;
}

export type ScheduleForm = Omit<ScheduleParams, 'from' | 'to'> & {
  from: Date;
  to: Date;
};

export type ScheduleResponse = ApiResponse;

export type ScheduleSearchParam = {
  page?: string;
  pageSize?: string;
  search?: string;
  orderBy?: string;
  driverId?: string;
  vehicleId?: string;
  startDate?: string;
  endDate?: string;
};
