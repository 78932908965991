import { Locale } from './locale.config';

const locationLocaleConfig: Locale[] = [
  {
    en: 'Import Delivery Location',
    id: 'Unggah Lokasi Pengiriman',
  },
  {
    en: 'Location Name, Address, Site Radius, Latitude and Longitude are mandatory',
    id: 'Nama Lokasi, Alamat, Radius, Garis Lintang dan Garis Bujur wajib diisi',
  },
  {
    en: 'Upload a XLS file to import location data',
    id: 'Unggah file XLS untuk mendaftarkan data lokasi',
  },
  {
    en: 'Location registered successfully',
    id: 'Lokasi berhasil ditambahkan',
  },
  {
    en: 'Make sure the list below is the location list that you want to register to your organization. Incompatible data formats will not be registered into the system.',
    id: 'Pastikan daftar di bawah ini adalah daftar Lokasi yang ingin Anda daftarkan ke organisasi Anda. Data dengan format yang tidak sesuai tidak dapat didaftarkan ke dalam sistem.',
  },
  {
    en: 'Invalid Shipper Name',
    id: 'Nama Pengirim Salah',
  },
  {
    en: 'Please make sure to enter a valid Indonesian area latitude.',
    id: 'Pastikan untuk memasukkan area lintang Indonesia yang tepat.',
  },
  {
    en: 'Please make sure to enter a valid Indonesian area longitude.',
    id: 'Pastikan untuk memasukkan area bujur Indonesia yang tepat.',
  },
  {
    en: 'Shipper Name is not Allowed',
    id: 'Nama Pengirim tidak boleh diisi',
  },
  {
    en: 'Invalid Location Name',
    id: 'Nama Lokasi Salah',
  },
  {
    en: 'Invalid Longitude',
    id: 'Koordinat Bujur Salah',
  },
  {
    en: 'Invalid Latitude',
    id: 'Koordinat Lintang Salah',
  },
  {
    en: 'Invalid Type',
    id: 'Tipe Lokasi Salah',
  },
  {
    en: 'Invalid Contact Number',
    id: 'Nomor Telepon Salah',
  },
  {
    en: 'Invalid Site Radius',
    id: 'Titik Radius Salah',
  },
  {
    en: 'Try to import some Location by clicking the Re-upload button, or you can click the button below',
    id: 'Unggah ulang Lokasi dari halaman Lokasi, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: "You don't have any imported Location that you want to register",
    id: 'Anda tidak memiliki Lokasi yang diunggah untuk didaftarkan',
  },
];

export default locationLocaleConfig;
