import React from 'react';
import { JOAssignmentBy } from '../../../constant/JOAssignment.constant';
import { UseJODateAssignmentFormHookObj } from '../../../hook/joAssignmentForm/useJODateAssignmentForm.hook';
import { UseJODriverAssignmentFormHookObj } from '../../../hook/joAssignmentForm/useJODriverAssignmentForm.hook';
import { UseJOVehicleAssignmentFormHookObj } from '../../../hook/joAssignmentForm/useJOVehicleAssignmentForm.hook';
import { JOBaseAssignmentForm, JODateAssignmentForm } from '../index';

// #region Assignment Form

type Props = {
  tab: JOAssignmentBy;
  joVehicleAssignmentForm: UseJOVehicleAssignmentFormHookObj;
  joDriverAssignmentForm: UseJODriverAssignmentFormHookObj;
  joDateAssignmentForm: UseJODateAssignmentFormHookObj;
};

export default function AssignmentForm({
  tab,
  joVehicleAssignmentForm,
  joDriverAssignmentForm,
  joDateAssignmentForm,
}: Props) {
  if (tab === JOAssignmentBy.VEHICLE) {
    return (
      <JOBaseAssignmentForm
        type={JOAssignmentBy.VEHICLE}
        isLoading={joVehicleAssignmentForm.vehicleAssignment.isLoading}
        title={joVehicleAssignmentForm.title}
        emptyTitle={joVehicleAssignmentForm.emptyTitle}
        emptySubtitle={joVehicleAssignmentForm.emptySubtitle}
        formData={joVehicleAssignmentForm.formData}
        nextMonth={joVehicleAssignmentForm.vehicleAssignment.nextMonth}
        selectedMonth={joVehicleAssignmentForm.vehicleAssignment.selectedMonth}
        selectedHighlight={
          joVehicleAssignmentForm.vehicleAssignment.selectedHighlight
        }
        selectedHighlightId={
          joVehicleAssignmentForm.vehicleAssignment.selectedHighlightId
        }
        highlights={joVehicleAssignmentForm.vehicleAssignment.ongoingHighlights}
        schedules={joVehicleAssignmentForm.vehicleAssignment.ongoingSchedules}
        handleSelectScheduleItem={
          joVehicleAssignmentForm.handleSelectScheduleItem
        }
        handleClickDay={joVehicleAssignmentForm.handleClickDay}
      />
    );
  }
  if (tab === JOAssignmentBy.DRIVER) {
    return (
      <JOBaseAssignmentForm
        type={JOAssignmentBy.DRIVER}
        isLoading={joDriverAssignmentForm.driverAssignment.isLoading}
        title={joDriverAssignmentForm.title}
        emptyTitle={joDriverAssignmentForm.emptyTitle}
        emptySubtitle={joDriverAssignmentForm.emptySubtitle}
        formData={joDriverAssignmentForm.formData}
        nextMonth={joDriverAssignmentForm.driverAssignment.nextMonth}
        selectedMonth={joDriverAssignmentForm.driverAssignment.selectedMonth}
        selectedHighlight={
          joDriverAssignmentForm.driverAssignment.selectedHighlight
        }
        selectedHighlightId={
          joDriverAssignmentForm.driverAssignment.selectedHighlightId
        }
        highlights={joDriverAssignmentForm.driverAssignment.ongoingHighlights}
        schedules={joDriverAssignmentForm.driverAssignment.ongoingSchedules}
        handleSelectScheduleItem={
          joDriverAssignmentForm.handleSelectScheduleItem
        }
        handleClickDay={joDriverAssignmentForm.handleClickDay}
      />
    );
  }
  return (
    <JODateAssignmentForm joDateAssignmentHookObj={joDateAssignmentForm} />
  );
}
// #endregion
