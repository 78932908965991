import { Locale } from './locale.config';

const trackingOrderLocaleConfig: Locale[] = [
  { en: 'Delivering', id: 'Pengiriman' },
  { en: 'Arrive at transit location: %s', id: 'Sampai di lokasi transit: %s' },
  { en: 'Arrive at Dropoff Location: %s', id: 'Sampai di lokasi dropoff:  %s' },
  {
    en: 'Finish dropping off goods at %s',
    id: 'Selesai mengantar barang ke %s',
  },
  { en: 'Arrive at Pickup Location: %s', id: 'Sampai di lokasi pickup: %s' },
  {
    en: 'Finish picking up goods at %s0. Towards transit location: %s1',
    id: 'Selesai mengambil barang di %s0. Menuju lokasi transit: %s1',
  },
  {
    en: 'Finish picking up goods at %s',
    id: 'Selesai mengambil barang di %s',
  },
  {
    en: 'Towards pickup location: %s',
    id: 'Menuju lokasi pickup: %s',
  },
  {
    en: 'Towards transit location',
    id: 'Menuju lokasi transit',
  },
  {
    en: 'Standby activity completed: %s',
    id: 'Aktivitas standby selesai: %s',
  },
  {
    en: 'Proof of activity uploaded',
    id: 'Bukti aktivitas ditambahkan',
  },
  { en: 'Order is received', id: 'Order diterima' },
  { en: 'Notes has been added', id: 'Catatan ditambahkan' },
  { en: 'Standby', id: 'Standby' },
  { en: 'Standby completed', id: 'Standby selesai' },
  { en: 'Tracking Number: %s', id: 'Nomor Resi: %s' },
  { en: 'Pickup at %s', id: 'Pickup di %s' },
  { en: 'Dropoff at %s', id: 'Dropoff di %s' },
  { en: 'Standby at %s', id: 'Standby di %s' },
  {
    en: 'Receipt Number is Not Found',
    id: 'Tidak Menemukan Nomor Resi yang Dicari',
  },
  {
    en: 'Receipt number is not valid or already delivered. Please check the tracking number You entered.',
    id: 'Nomor resi yang dimasukkan tidak valid atau pengiriman sudah terkirim. Periksa kembali nomor resi yang Anda masukkan.',
  },
  {
    en: 'Lacak Pengiriman',
    id: 'Lacak Pengiriman',
  },
  {
    en: 'Nomor Resi',
    id: 'Nomor Resi',
  },
  {
    en: 'In Progress',
    id: 'Dalam Proses',
  },
  {
    en: 'Timeline information is not available',
    id: 'Informasi linimasa tidak ada',
  },
  {
    en: 'Timeline',
    id: 'Timeline',
  },
  {
    en: 'Organization',
    id: 'Organisasi',
  },
  {
    en: 'Goods Detail',
    id: 'Detail Barang',
  },
  {
    en: 'Tracking information is not available',
    id: 'Informasi pelacakan tidak ada',
  },
  {
    en: 'Share',
    id: 'Bagikan',
  },
  {
    en: 'Destination',
    id: 'Tujuan',
  },
  {
    en: 'Receipt Number',
    id: 'Nomor Resi',
  },
  {
    en: 'Order is completed',
    id: 'Order telah selesai',
  },
  {
    en: 'Depart from transit location: %s',
    id: 'Berangkat dari lokasi transit: %s',
  },
  {
    en: 'Arrive at standby location: %s',
    id: 'Sampai di lokasi standby: %s',
  },
  {
    en: 'Delivery Order is started',
    id: 'Pengiriman order dimulai',
  },
  {
    en: 'Unavailable',
    id: 'Belum tersedia',
  },
  {
    en: 'Thank you for choosing our delivery service! Your tracking number is %s. You can track your order with the link: %s%s?receipt=%s',
    id: 'Terima kasih sudah menggunakan jasa pengiriman kami! Nomor pelacakan order Anda adalah %s. Anda dapat melacak order pengiriman Anda dengan menggunakan link berikut: %s%s?receipt=%s',
  },
  {
    en: 'Pickup location',
    id: 'Lokasi pickup',
  },
  {
    en: 'Dropoff location',
    id: 'Lokasi dropoff',
  },
];

export default trackingOrderLocaleConfig;
