import useTranslator from '../useTranslator.hook';
import useHomeOnboarding from './useHomeOnboarding.hook';

function useOnboardingPopup() {
  const translator = useTranslator();
  const homeOnboarding = useHomeOnboarding({ translator });

  const progressLabel = translator.translate('Onboarding Progress');

  return {
    homeOnboarding,
    progressLabel,
  };
}

export type UseOnboardingPopupHookObj = ReturnType<typeof useOnboardingPopup>;
export default useOnboardingPopup;
