import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fillPath?: string;
};

export default function Medal({
  width = 17,
  height = 16,
  fillPath = '#F3532E',
  viewBox = '0 0 17 16',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path
        fill={fillPath}
        fillRule="evenodd"
        d="M.016 0l4.657 5.898a5.6 5.6 0 106.432-.16L16.003 0h-2.106L9.586 5.028a5.6 5.6 0 00-.536-.13L5.24 0H3.211l3.803 4.888c-.31.056-.614.137-.909.243L2.054 0H.016zm5.159 7.572a4 4 0 115.657 5.656 4 4 0 01-5.657-5.656z"
        clipRule="evenodd"
      />
      <path
        fill={fillPath}
        d="M10.678 0L9.05 1.873l.953 1.327L12.774 0h-2.096z"
      />
    </svg>
  );
}
