import { SOStatus } from '../../constant';
import { DrivingContestStatus } from '../../model/DrivingContest.model';
import {
  HaulingOrderDeliveryAssignmentStatusEnum,
  HaulingOrderStatusEnum,
} from '../../model/HaulingOrder.model';
import { JOStatus } from '../../model/JobOrder.model';
import {
  IOrganizationOrientation,
  IOrganizationSetting,
} from '../../model/Organization.model';
import { SettingDriverManagementParams } from '../../model/Setting.model';

export const soQueryParams = () => {
  const params = new URLSearchParams('');
  const prohibitedSOStatus = [
    SOStatus.ASSIGNED,
    SOStatus.DELIVERING,
    SOStatus.IN_TRANSIT,
    SOStatus.TRANSITING,
  ];

  for (const _status of prohibitedSOStatus) {
    params.append('status[]', String(_status));
  }

  return params.toString();
};

export const joQueryParams = () => {
  const params = new URLSearchParams('');
  const prohibitedJOStatus = [JOStatus.ASSIGNED, JOStatus.DELIVERING];

  for (const _status of prohibitedJOStatus) {
    params.append('status[]', String(_status));
  }

  return params.toString();
};

export const hoQueryParams = () => {
  const params = new URLSearchParams('');
  const prohibitedHOStatus = [
    HaulingOrderStatusEnum.ASSIGNED,
    HaulingOrderStatusEnum.DELIVERING,
  ];

  for (const _status of prohibitedHOStatus) {
    params.append('status[]', String(_status));
  }

  return params.toString();
};

export const hjoQueryParams = () => {
  const params = new URLSearchParams('');
  const prohibitedHJOStatus = [
    HaulingOrderDeliveryAssignmentStatusEnum.ASSIGNED,
    HaulingOrderDeliveryAssignmentStatusEnum.DELIVERING,
  ];

  for (const _status of prohibitedHJOStatus) {
    params.append('status[]', String(_status));
  }

  return params.toString();
};

export const drivingContestQueryParams = () => {
  const params = new URLSearchParams('');
  const prohibitedDrivingContestStatus = [
    DrivingContestStatus.READY,
    DrivingContestStatus.NOT_READY,
  ];

  for (const _status of prohibitedDrivingContestStatus) {
    params.append('status[]', String(_status));
  }

  return params.toString();
};

export const formatSettingOrganizationToParams = (
  data: IOrganizationSetting,
): SettingDriverManagementParams => ({
  isAlwaysUseTransitForm: !!data.isAlwaysUseTransitForm,
  isGeofenceStatusEnabled: !!data.isGeofenceStatusEnabled,
  isPhotoGalleryAllowed: !!data.isPhotoGalleryAllowed,
  isPoaRequired: !!data.isProofOfActivityRequired,
});

export const isOrganizationOrientationCompleted = (
  orientation?: IOrganizationOrientation,
) => {
  if (!orientation) return false;
  return [
    !!orientation?.isDriverCompleted,
    !!orientation?.isJobOrderCompleted,
    !!orientation?.isLocationCompleted,
    !!orientation?.isShipperCompleted,
    !!orientation?.isShipperOrderCompleted,
  ].every((v) => !!v);
};
