import { Locale } from './locale.config';

const createJobOrderLocaleConfig: Locale[] = [
  {
    en: 'Driver',
    id: 'Sopir',
  },
  {
    en: 'Vehicle',
    id: 'Kendaraan',
  },
  {
    en: 'Select Driver',
    id: 'Pilih Sopir',
  },
  {
    en: 'Select Vehicle',
    id: 'Pilih Kendaraan',
  },
  {
    en: 'Driver is required.',
    id: 'Sopir wajib diisi.',
  },
  {
    en: 'Vehicle is required.',
    id: 'Kendaraan wajib diisi.',
  },
  {
    en: 'Travel Expenses',
    id: 'Uang Jalan',
  },
  {
    en: 'Transporter',
    id: 'Milik Sendiri',
  },
  {
    en: 'Seal Number',
    id: 'Nomor Segel',
  },
  {
    en: 'Delivery Location',
    id: 'Lokasi Pengiriman',
  },
  {
    en: 'Add Shipper Order',
    id: 'Tambah Shipper Order',
  },
  {
    en: 'SO Number',
    id: 'Nomor SO',
  },
  {
    en: 'SO, Ref, Location',
    id: 'SO, Ref, Lokasi',
  },
  {
    en: 'SO Number/Ref',
    id: 'Nomor SO/Ref',
  },
  {
    en: 'Shipper',
    id: 'Pengirim',
  },
  {
    en: 'Dropoff Location',
    id: 'Lokasi Dropoff',
  },
  {
    en: 'Add to Job Order',
    id: 'Tambahkan ke JO',
  },
  {
    en: 'Selected Shipper Order',
    id: 'Shipper Order terpilih',
  },
  {
    en: 'Shipper Order Candidate',
    id: 'Calon Shipper Order',
  },
  {
    en: 'Order Date is Required.',
    id: 'Tanggal Order wajib diisi.',
  },
  {
    en: 'Schedule is required.',
    id: 'Jadwal wajib diisi.',
  },
  {
    en: 'Max 50 characters.',
    id: 'Maksimal 50 karakter.',
  },
  {
    en: 'Max 255 characters.',
    id: 'Maksimal 255 karakter.',
  },
  {
    en: 'Maximum 1000 characters',
    id: 'Maksimal 1000 karakter.',
  },
  {
    en: 'Seal No',
    id: 'Nomor Segel',
  },
  {
    en: 'Pickup and Dropoff',
    id: 'Pickup dan Dropoff',
  },
  {
    en: 'Close',
    id: 'Tutup',
  },
  {
    en: 'Send to Driver',
    id: 'Tugaskan ke Sopir',
  },
  {
    en: 'Save as Draft',
    id: 'Simpan sebagai Draft',
  },
  {
    en: 'Job order has been created and saved as Draft',
    id: 'Perintah Kerja berhasil dibuat dan tersimpan sebagai draft',
  },
  {
    en: 'Job order has been created',
    id: 'Perintah Kerja berhasil dibuat',
  },
  {
    en: 'Job order has been created but failed to assign',
    id: 'Perintah Kerja berhasil dibuat. tetapi, gagal ditugaskan',
  },
  {
    en: 'Manual JO number cannot be started with char "JO-"',
    id: 'Nomor JO tidak dapat diawali dengan “JO-”',
  },
  {
    en: 'Add New Driver',
    id: 'Tambah Sopir Baru',
  },
  {
    en: 'Save and Use Driver',
    id: 'Simpan dan Gunakan Sopir',
  },
  {
    en: 'Select Transit Location',
    id: 'Pilih Lokasi Transit',
  },
  {
    en: 'Select Delivery Location',
    id: 'Pilih Lokasi Pengiriman',
  },
  {
    en: 'Change Location',
    id: 'Ganti Lokasi',
  },
  {
    en: 'Location Name or Address',
    id: 'Nama Lokasi atau Alamat',
  },
  {
    en: 'Last Mile Location',
    id: 'Lokasi Last Mile',
  },
  {
    en: 'Job Order Successfully Assigned',
    id: 'Perintah Kerja Berhasil ditugaskan',
  },
  {
    en: 'Set selected SO to the final destination',
    id: 'Atur SO yang dipilih ke lokasi tujuan terakhir',
  },
  {
    en: 'Change Delivery Location',
    id: 'Ubah Lokasi Pengiriman',
  },
  {
    en: 'Search Transit Location',
    id: 'Cari Lokasi Transit',
  },
  {
    en: 'Set selected SO to a transit location',
    id: 'Atur SO yang dipilih ke lokasi transit',
  },
  {
    en: 'This operation cannot be performed for SO last mile type',
    id: 'Tindakan ini tidak dapat dilakukan untuk jenis SO last mile',
  },
  {
    en: 'Maximum number of selected SO is 100',
    id: 'Jumlah maksimum SO yang dipilih adalah 100',
  },
  {
    en: 'Update Delivery Location',
    id: 'Perbarui Lokasi Pengiriman',
  },
  {
    en: 'Assignment',
    id: 'Penugasan',
  },
  {
    en: 'Current Location',
    id: 'Lokasi Saat Ini',
  },
  {
    en: 'Unassigned',
    id: 'Belum Ditugaskan',
  },
  {
    en: 'Multiple Locations',
    id: 'Banyak Lokasi',
  },
  {
    en: "Some of the delivery location can't be assigned",
    id: 'Beberapa lokasi pengiriman tidak dapat ditetapkan',
  },
  {
    en: "The delivery location can't be the same as current location. Please select another delivery location.",
    id: 'Pilihan Lokasi Pengiriman perlu dibedakan dengan Lokasi Saat Ini. Silakan pilih lokasi pengiriman lain.',
  },
  {
    en: "Can't be the same as Current Location",
    id: 'Tidak boleh sama dengan Lokasi Saat Ini',
  },
  {
    en: "Delivery Location can't be updated",
    id: 'Lokasi Pengiriman tidak dapat diperbarui',
  },
  {
    en: 'The Delivery Location must be different than Current Location. Please choose another Delivery Location.',
    id: 'Pilihan Lokasi Pengiriman perlu dibedakan dengan Lokasi Saat Ini. Silakan pilih Lokasi Pengiriman lain.',
  },
  {
    en: 'Select Driver & Vehicle',
    id: 'Pilih Sopir & Kendaraan',
  },
  {
    en: 'Select by Schedule',
    id: 'Berdasarkan Jadwal',
  },
  {
    en: 'Select Manually',
    id: 'Pilih Manual',
  },
  {
    en: 'Search Schedule',
    id: 'Cari Jadwal',
  },
  {
    en: 'Schedule by Vehicle',
    id: 'Jadwal Kendaraan',
  },
  {
    en: 'Schedule by Driver',
    id: 'Jadwal Sopir',
  },
  {
    en: 'Schedule by Date',
    id: 'Rentang Penjadwalan',
  },
  {
    en: 'There is no schedule.',
    id: 'Tidak ada jadwal.',
  },
  {
    en: 'Select Schedule',
    id: 'Pilih Jadwal',
  },
  {
    en: 'Failed to Submit JO Form',
    id: 'Gagal Mengirim Formulir Perintah Kerja',
  },
  {
    en: 'Please select Date to show schedule list.',
    id: 'Silahkan pilih Tanggal untuk menampilkan daftar jadwal.',
  },
  {
    en: 'Please select Driver to show schedule list.',
    id: 'Silahkan pilih Sopir untuk menampilkan daftar jadwal.',
  },
  {
    en: 'Please select Vehicle to show schedule list.',
    id: 'Silahkan pilih Kendaraan untuk menampilkan daftar jadwal.',
  },
  {
    en: "Travel Budget can't be set lower than total expenses which are",
    id: 'Uang Jalan tidak dapat diatur lebih rendah dari total pengeluaran sebesar',
  },
  {
    en: 'Max 999,999,999',
    id: 'Maksimal 999,999,999',
  },
  {
    en: '%s of %s Assigned',
    id: '%s dari %s Ditugaskan',
  },
  {
    en: 'Auto-generated JO Number will be used if you leave this field empty.',
    id: 'Nomor Perintah Kerja yang dibuat secara otomatis akan digunakan jika Anda membiarkan kolom ini kosong.',
  },
  {
    en: 'All selected Shipper Order must be assigned to their delivery location.',
    id: 'Semua Shipper Order terpilih harus ditugaskan ke lokasi pengirimannya.',
  },
  {
    en: 'Please select at least 1 %s and assign the %s.',
    id: 'Mohon untuk memilih setidaknya 1 %s dan tugaskan ke %s',
  },
  {
    en: 'Select Driver & Vehicle fields must be filled.',
    id: 'Kolom pilih Sopir & Kendaraan harus diisi.',
  },
  {
    en: 'Please correct any error fields before moving to the next step.',
    id: 'Mohon untuk memperbaiki kolom yang belum sesuai sebelum melanjutkan ke tahap berikutnya.',
  },
  {
    en: 'Please correct any error fields before finishing this process.',
    id: 'Mohon untuk memperbaiki kolom yang belum sesuai sebelum menyelesaikan proses ini.',
  },
  {
    en: 'Please correct any error fields.',
    id: 'Mohon untuk memperbaiki kolom yang belum sesuai.',
  },
  {
    en: 'Selected Shipper Order',
    id: 'Shipper Order Terpilih',
  },
  {
    en: 'must be assigned to their delivery location.',
    id: 'harus ditugaskan ke lokasi pengirimannya.',
  },
  {
    en: 'Please select at least 1 %s %s %s',
    id: 'Mohon untuk memilih setidaknya 1 %s %s %s',
  },
  {
    en: 'and assign the',
    id: 'dan tugaskan ke lokasi pengirimannya.',
  },
  {
    en: 'delivery location',
    id: 'lokasi pengirimannya',
  },
  {
    en: 'Fields %s %s',
    id: '%s %s ',
  },
  {
    en: 'fields must be filled.',
    id: 'harus diisi',
  },
  {
    en: '%s fields must be filled.',
    id: 'Kolom %s harus diisi.',
  },
  {
    en: 'All %s must be assigned to their delivery location.',
    id: 'Semua %s harus ditugaskan ke lokasi pengirimannya.',
  },
];

export default createJobOrderLocaleConfig;
