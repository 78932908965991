import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import 'twin.macro';
import { theme } from 'twin.macro';
import { InfoOutlined } from '../../component/atom/Icon/Icon.atom';
import { BodyFourteen } from '../../component/atom/Text/Text.atom';
import { FormikHookProps } from '../../constant/Types.constant';
import { JobOrderForm } from '../../model/JobOrder.model';
import api from '../../service/api.service';
import { FormItem } from '../../types/input.type';
import useTranslator from '../useTranslator.hook';
import { UseJOFormControllerHookObj } from './useJOFormController.hook';

type Props = {
  number?: string;
  joFormik: FormikHookProps<JobOrderForm>;
  controller: UseJOFormControllerHookObj;
  previousValue?: JobOrderForm;
};

export default function useJOFormHeader({
  number,
  controller: { handleBackHeader },
  joFormik: { values, errors, touched, setFieldValue, setFieldTouched },
  previousValue,
}: Props) {
  const { translate } = useTranslator();
  const params = useParams();
  const joId = params?.id ?? '';

  const { data } = api.useJobOrderNumbersInfoQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !!joId,
  });

  const predefinedJONumber = useMemo(
    () => data?.jobOrder?.number,
    [data?.jobOrder?.number],
  );

  const title = useMemo(() => {
    if (number) return number;
    if (!number && joId) return translate('Edit Job Order');
    return translate('Create Job Order');
  }, [joId, number, translate]);

  // #region Form
  const formData: FormItem[] = useMemo(
    () => [
      {
        id: 'joDate',
        disabled: true,
        values: values.joDate ? fromUnixTime(values.joDate) : undefined,
        label: translate('Job Order Date'),
        isRequired: true,
        type: 'date',
        error: translate(errors?.joDate ?? ''),
        onChange: (date) => {
          void setFieldValue(
            'joDate',
            getUnixTime(date || startOfDay(new Date())),
          );
        },
      },
      {
        id: 'joNumber',
        values: values.joNumber || '',
        placeholder: predefinedJONumber ?? translate('JO Number'),
        label: translate('JO Number'),
        disabled: previousValue?.joNumber
          ? /^JO-/i.test(previousValue?.joNumber)
          : false,
        type: 'text',
        onChange: async (joNumber) => {
          const data = joNumber as string;
          await setFieldValue('joNumber', data);
          setFieldTouched('joNumber', true);
        },
        error:
          touched?.joNumber && errors?.joNumber
            ? translate(errors?.joNumber)
            : '',
        children:
          joId || (touched.joNumber && errors.joNumber) ? undefined : (
            <div tw="flex gap-2 items-start ml-[200px] whitespace-pre-wrap -mt-2 letter-spacing[-0.1px]">
              <div tw="h-4 w-4 mt-1">
                <InfoOutlined
                  fillPath={theme`colors.text.color-text-secondary`}
                />
              </div>
              <BodyFourteen tw="text-text-color-text-secondary">
                {translate(
                  'Auto-generated JO Number will be used if you leave this field empty.',
                )}
              </BodyFourteen>
            </div>
          ),
      },
      {
        id: 'sealNumber',
        values: values.sealNumber,
        label: translate('Seal Number'),
        placeholder: translate('Seal Number'),
        type: 'text',
        onChange: async (sealNo) => {
          await setFieldValue(
            'sealNumber',
            previousValue ? sealNo || null : sealNo,
          );

          setFieldTouched('sealNumber');
        },
        error:
          touched.sealNumber && errors?.sealNumber
            ? translate(errors?.sealNumber)
            : '',
      },
    ],
    [
      joId,
      predefinedJONumber,
      errors?.joDate,
      errors?.joNumber,
      errors?.sealNumber,
      previousValue,
      setFieldValue,
      translate,
      setFieldTouched,
      touched,
      values.joDate,
      values.joNumber,
      values.sealNumber,
    ],
  );
  const isValid = useMemo(
    () => !errors.joNumber && !errors.sealNumber,
    [errors.joNumber, errors.sealNumber],
  );
  // #endregion

  return {
    title,
    isValid,
    formData,
    handleBackHeader,
  };
}

export type UseJOFormHeaderObj = ReturnType<typeof useJOFormHeader>;
