import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store/store.app';
import {
  BulkActionEntity,
  BulkActionStatusEnum,
  ErrorCodes,
} from '../../constant';
import { PostShipperCreateParams } from '../../model/Shipper.model';
import { logEvent } from '../../service/analytic/analytic.service';
import { ApiErrorResponse } from '../../service/api.endpoint';
import api from '../../service/api.service';
import { navigationParamAction } from '../../store/param.store';
import { errorCodeToLabel } from '../../util/error.util';
import { shipperRoute } from '../../view/Shipper/shipper.route';
import { ShipperAddBulk } from '../../view/ShipperAddBulk/ShipperAddBulk.route';
import useVerifyAuth from '../../view/Wrapper/hooks/useVerifyAuth.hook';
import useOrganizationOrientation from '../organization/useOrganizationOrientation.hook';

const mapStoreToState = (shippers: ShipperAddBulk[]) =>
  shippers.map((v) => ({
    ...v,
    _actionStatus: BulkActionStatusEnum.WAITING,
    _actionDetail: 'Waiting for registration',
  }));

const mapStoreToDto = (
  entity: AddBulkShipperEntity,
): PostShipperCreateParams => ({
  name: entity['Nama Pengirim'],
  email: entity['Alamat Email'],
  phoneNumber: entity['Nomor Telepon Pengirim'],
});

// #region INTERFACES
export type AddBulkShipperEntity = BulkActionEntity<ShipperAddBulk>;
export type UseShipperAddBulkLogic = ReturnType<typeof useShipperAddBulkLogic>;
// #endregion

export default function useShipperAddBulkLogic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleFetchOrganizationData } = useOrganizationOrientation();
  const { handleVerifyAuth } = useVerifyAuth();

  const [showStatus, setShowStatus] = useState(false);

  const [truncatedFilename, setTruncatedFilename] = useState('');
  const { filename, shippers: shippersStore } = useSelector(
    (state: RootState) =>
      state.navigationParam.shipperAddBulkParams ?? {
        filename: '',
        shippers: [],
      },
  );

  useEffect(() => {
    if (filename.length < 30) {
      setTruncatedFilename(filename);
      return;
    }
    const [name, format] = filename.split('.');
    const truncatedName = name.slice(0, 25);
    setTruncatedFilename(`${truncatedName}...${format}`);
  }, [filename]);

  const [shippersWithStatusDetail, setShippersWithStatusDetail] = useState<
    AddBulkShipperEntity[]
  >(mapStoreToState(shippersStore));
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(true);
  const [showDoneBtn, setShowDoneBtn] = useState(false);
  // to disable submit button when mutations is still ongoing
  const [mutationsIsLoading, setMutationsIsLoading] = useState(false);
  const [doAddShipper] = api.useCreateShipperMutation();
  // #endregion

  // #region HANDLERS
  const handleAddBulk = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    setShowStatus(true);
    logEvent('Shipper:AddBulk:Submit', 'Shipper');
    const promises = shippersWithStatusDetail.map((shipper) =>
      doAddShipper(mapStoreToDto(shipper)).unwrap(),
    );

    // track loading state
    setMutationsIsLoading(true);

    // NOTE: backend said this endpoint is safe to the parallel mutation
    await Promise.allSettled(promises)
      .then((results) => {
        for (const [idx, result] of results.entries()) {
          // on rejected
          if (result.status === 'rejected') {
            const errorQuery = (result.reason as FetchBaseQueryError)
              ?.data as ApiErrorResponse;
            const errorCode = errorQuery?.error?.code;
            const errorDetails = errorQuery?.error?.details;

            setShippersWithStatusDetail((_shippers) =>
              _shippers.map((_shipper) =>
                _shipper.id === shippersWithStatusDetail[idx].id
                  ? {
                      ..._shipper,
                      _actionStatus: BulkActionStatusEnum.FAILED,
                      _actionDetail:
                        errorCode === ErrorCodes.REQUEST_INVALID_ARGUMENT
                          ? errorDetails
                          : errorCodeToLabel(errorCode),
                    }
                  : _shipper,
              ),
            );
            continue;
          }

          // on fulfilled
          setShippersWithStatusDetail((_shippers) =>
            _shippers.map((_shipper) =>
              _shipper.id === shippersWithStatusDetail[idx].id
                ? {
                    ..._shipper,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Shipper registered successfully.',
                  }
                : _shipper,
            ),
          );
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        // on done
        setShowDoneBtn(true);
        setMutationsIsLoading(false);
        setHasUnsavedChanges(false);
      });
  }, [doAddShipper, handleVerifyAuth, shippersWithStatusDetail]);

  const handleDone = useCallback(() => {
    // clear store
    dispatch(navigationParamAction.clearShipperAddBulkParams());
    navigate(shipperRoute.path);
    handleFetchOrganizationData();
  }, [dispatch, handleFetchOrganizationData, navigate]);

  // sync `shippersStore` with `shippersWithStatusDetail`
  useEffect(() => {
    if (shippersStore.length > 0)
      setShippersWithStatusDetail(mapStoreToState(shippersStore));
  }, [shippersStore]);

  return {
    hasUnsavedChanges,
    filename,
    truncatedFilename,
    shippersWithStatusDetail,
    showDoneBtn,
    mutationsIsLoading,
    showStatus,
    handleAddBulk,
    handleDone,
  };
}
