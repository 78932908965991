import React from 'react';
import { RouteConfig } from '../../config/router/router.config';

export type DriverDetailRouteParam = {
  id: string;
};

export type DriverDetailRouteSearchParam = {
  startDate?: string; // number in milliseconds
  endDate?: string; // number in milliseconds
};

export type DriverDetailRouteNavigationState = undefined;

export const driverDetailRoute: RouteConfig = {
  name: 'Driver Profile',
  Component: React.lazy(() => import('./DriverDetail.view')),
  path: '/driver/detail/:id',
  isPrivate: true,
  breadcrumb: 'Driver Profile',
  props: {
    isUnclickable: true,
  },
};
