import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorCodes } from '../../../constant';
import useOrganizationSelector from '../../../hook/organization/useOrganizationSelector.hook';
import {
  IOrganizationOrientation,
  IOrganizationSetting,
} from '../../../model/Organization.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { sessionAction } from '../../../store/session.store';
import { settingAction } from '../../../store/setting.store';
import { ClientAuthenticationResponse } from './useSession.hook';

function useGetOrganization() {
  const dispatch = useDispatch();
  const [getOrg] = api.useLazyGetOrganizationMeQuery();

  const handleGetOrganization = useCallback(
    async (
      token: string,
    ): Promise<
      ClientAuthenticationResponse<{
        organizationName?: string;
        isResetting?: boolean;
        orientation?: IOrganizationOrientation;
        organization?: IOrganizationSetting;
      }>
    > => {
      try {
        const getOrgResponse = await getOrg({ token }).unwrap();
        return {
          ok: true,
          data: {
            organizationName: getOrgResponse?.organization.name,
            organization: {
              isPhotoGalleryAllowed:
                !!getOrgResponse?.organization?.settings?.isPhotoGalleryAllowed,
              isProofOfActivityRequired:
                !!getOrgResponse?.organization?.settings
                  ?.isProofOfActivityRequired,
              isGeofenceStatusEnabled:
                !!getOrgResponse?.organization?.settings
                  ?.isGeofenceStatusEnabled,
              isAlwaysUseTransitForm:
                !!getOrgResponse?.organization?.settings
                  ?.isAlwaysUseTransitForm,
            },
            orientation: getOrgResponse.organization.orientation,
          },
        };
      } catch (error: unknown) {
        const data = (error as FetchBaseQueryError).data as ApiErrorResponse;
        const isOrganizationResetting =
          data?.error.code === ErrorCodes.ORGANIZATION_IN_RESETTING;
        return {
          ok: isOrganizationResetting,
          code: data?.error?.code,
          data: {
            isResetting: true,
          },
        };
      }
    },
    [getOrg],
  );

  const { data } = useOrganizationSelector();
  /**
   * this useeffect is listener if there's update on organizationMe
   */

  useEffect(() => {
    if (data?.organization) {
      dispatch(
        settingAction.changeOrganizationSetting(data.organization.settings),
      );
      dispatch(sessionAction.changeOrganizationName(data.organization.name));
    }
  }, [data?.organization, dispatch]);

  return { handleGetOrganization };
}

export default useGetOrganization;
