import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfYear from 'date-fns/startOfYear';
import { DefinedRangeOption } from '../component/molecule/DateRangePicker/DateRangePicker.molecule';
import {
  endOfLastMonth,
  endOfLastWeek,
  endOfLastYear,
  lastFourteenDays,
  lastNinetyDays,
  lastOneMonthDate,
  lastOneYearDate,
  lastSevenDays,
  lastSixMonthsDate,
  lastThreeMonthsDate,
  lastTwoWeeksDate,
  startOfLastMonth,
  startOfLastWeek,
  startOfLastYear,
  startoftoday,
  thisMonth,
  thisWeek,
  thisYear,
} from '../constant/Date.constant';
import { UseTranslator } from '../hook/useTranslator.hook';

/**
 * @returns string
 *
 * @summary get timezone WIB / WITA / WIT based on current locale time as string
 */
export const getTimeOffset = (): string => {
  const dateOffset = `${new Date().getTimezoneOffset() / 60}`;

  switch (dateOffset) {
    case '-7':
      return 'WIB';
    case '-8':
      return 'WITA';
    case '-9':
      return 'WIT';
    default:
      return '';
  }
};

/**
 * @param  {number} date - in milliseconds
 * @param  {boolean} withTimezone
 * @returns string
 */
export const getFullDateTimeFormat = (
  date: number | Date | undefined,
  withTimezone: boolean,
): string => {
  if (!date) return '-';
  return `${format(date, 'dd MMM yyyy, HH:mm')} ${
    withTimezone ? getTimeOffset() : ''
  }`;
};

/**
 * format input date to readable string
 * @param  {number | Date} date - in milliseconds or Date
 * @returns string
 */
export const getDateFormat = (date: number | Date): string =>
  `${format(date, 'dd MMM yyyy')}`;

/**
 * @param  {locales} string - locale code
 * @param  {formatDate} string - format month long or short
 * @returns string[]
 */
export function getMonthList(
  locales?: string | string[],
  formatDate: 'long' | 'short' = 'long',
): string[] {
  const year = new Date().getFullYear();
  const monthList = [...Array(12).keys()];
  const formatter = new Intl.DateTimeFormat(locales, {
    month: formatDate,
  });

  const getMonthName = (monthIndex: number) =>
    formatter.format(new Date(year, monthIndex));

  return monthList.map(getMonthName);
}

/**
 * Turns Date into short format date string or empty string
 * @param date
 */
export function inputDateValue(date: Date | undefined): string | undefined {
  return date ? getDateFormat(date) : undefined;
}

/**
 * get date range label from filter date range
 * @param startDate number in milliseconds
 * @param endDate number in milliseconds
 * @returns {string} label like "Last two weeks" / "Last one month"
 */
export const mapFilterDateRangeToLabel = (
  startDate: number,
  endDate: number,
) => {
  const startDayOfStartDate = startOfDay(startDate);
  const startDayOfEndDate = startOfDay(endDate);
  const endDateSameDayAsToday = isSameDay(startDayOfEndDate, startoftoday);

  // "monday" of last week until "sunday"
  if (
    isSameDay(startDayOfStartDate, startOfLastWeek) &&
    isSameDay(startDayOfEndDate, endOfLastWeek)
  )
    return 'Last Week';

  // "start of month" of last month until "end of month" of last month
  if (
    isSameDay(startDayOfStartDate, startOfLastMonth) &&
    isSameDay(startDayOfEndDate, endOfLastMonth)
  )
    return 'Last Month';

  // "start of year" of last year until "end of year" of last year
  if (
    isSameDay(startDayOfStartDate, startOfLastYear) &&
    isSameDay(startDayOfEndDate, endOfLastYear)
  )
    return 'Last Year';

  // "monday" of this week until "today"
  if (isSameDay(startDayOfStartDate, thisWeek) && endDateSameDayAsToday)
    return 'This Week';

  // "start of month" of this month until "today"
  if (
    isSameDay(startDayOfStartDate, startOfMonth(startoftoday)) &&
    endDateSameDayAsToday
  )
    return 'This Month';

  // "start of year" of this year until "today"
  if (
    isSameDay(startDayOfStartDate, startOfYear(startoftoday)) &&
    endDateSameDayAsToday
  )
    return 'This Year';

  // "today - 6 days" until "today"
  if (isSameDay(startDayOfStartDate, lastSevenDays) && endDateSameDayAsToday)
    return 'Last 7 Days';

  // "today - 13 days" until "today"
  if (isSameDay(startDayOfStartDate, lastFourteenDays) && endDateSameDayAsToday)
    return 'Last 14 Days';

  // "today - 90 days" until "today"
  if (isSameDay(startDayOfStartDate, lastNinetyDays) && endDateSameDayAsToday)
    return 'Last 90 Days';

  // "today - 2 weeks" until "today"
  if (isSameDay(startDayOfStartDate, lastTwoWeeksDate) && endDateSameDayAsToday)
    return 'Last 2 Weeks';

  // "today - 1 month" until "today"
  if (isSameDay(startDayOfStartDate, lastOneMonthDate) && endDateSameDayAsToday)
    return 'Last 1 Month';

  // "today - 3 months" until "today"
  if (
    isSameDay(startDayOfStartDate, lastThreeMonthsDate) &&
    endDateSameDayAsToday
  )
    return 'Last 3 Months';

  // "today - 6 months" until "today"
  if (
    isSameDay(startDayOfStartDate, lastSixMonthsDate) &&
    endDateSameDayAsToday
  )
    return 'Last 6 Months';

  // "today - 1 year" until "today"
  if (isSameDay(startDayOfStartDate, lastOneYearDate) && endDateSameDayAsToday)
    return 'Last 1 Year';

  return `${getDateFormat(startDate)} - ${getDateFormat(endDate)}`;
};

/**
 * get default date range defined range options for report SO, JO, HO, and Revenue
 *
 * @param {UseTranslator} translator
 */
export const getReportFilterDateRangeDefinedRangeOptions = (
  translator: UseTranslator,
): DefinedRangeOption[] => [
  {
    label: translator.translate('Last 7 Days'),
    startRange: lastSevenDays,
    endRange: startoftoday,
  },
  {
    label: translator.translate('This Week'),
    startRange: thisWeek,
    endRange: startoftoday,
  },
  {
    label: translator.translate('Last 14 Days'),
    startRange: lastFourteenDays,
    endRange: startoftoday,
  },
  {
    label: translator.translate('Last Week'),
    startRange: startOfLastWeek,
    endRange: endOfLastWeek,
  },
  {
    label: translator.translate('This Month'),
    startRange: thisMonth,
    endRange: startoftoday,
  },
  {
    label: translator.translate('Last Month'),
    startRange: startOfLastMonth,
    endRange: endOfLastMonth,
  },
  {
    label: translator.translate('Last 90 Days'),
    startRange: lastNinetyDays,
    endRange: startoftoday,
  },
  {
    label: translator.translate('This Year'),
    startRange: thisYear,
    endRange: startoftoday,
  },
  {
    label: translator.translate('Last Year'),
    startRange: startOfLastYear,
    endRange: endOfLastYear,
  },
];
