import { AutocompleteType } from '../hook/useAutocomplete.hook';
import { Schedule } from '../model/Schedule.model';
import { Vehicle } from '../model/Vehicle.model';

export enum JOAssignmentBy {
  VEHICLE = 'VEHICLE',
  DRIVER = 'DRIVER',
  DATE = 'DATE',
}

export type JOAssignmentFormType = {
  scheduleId?: string;
  schedule?: Schedule;
};

export type JOVehicleAssignmentFormType = JOAssignmentFormType & {
  vehicleId?: string;
  vehicleOption?: Vehicle;
};

export type JODriverAssignmentFormType = JOAssignmentFormType & {
  driverId?: string;
  driverOption?: AutocompleteType;
};
export type JODateAssignmentFormType = JOAssignmentFormType & {
  from?: Date;
  to?: Date;
};
