import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BulkActionEntity, BulkActionStatusEnum } from '../../../constant';
import {
  DeleteShipperOrderApiRequest,
  ShipperOrder,
} from '../../../model/ShipperOrder.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { getSOCustomErrorMessage } from '../../../util/shipperOrder.util';
import { shipperOrderRoute } from '../../ShipperOrder/ShipperOrder.route';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';

// #region TYPES
export type DeleteBulkShipperOrderEntity = BulkActionEntity<ShipperOrder>;
export type UseShipperOrderDeleteBulkLogic = ReturnType<
  typeof useShipperOrderDeleteBulkLogic
>;
// #endregion

const mapStoreToState = (shipperOrder: ShipperOrder[]) =>
  shipperOrder.map((v) => ({
    ...v,
    _actionStatus: BulkActionStatusEnum.WAITING,
    _actionDetail: 'Waiting for deletion',
  }));

const mapStoreToDto = (
  entity: DeleteBulkShipperOrderEntity,
): DeleteShipperOrderApiRequest => ({
  id: entity.id,
});

export default function useShipperOrderDeleteBulkLogic() {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleVerifyAuth } = useVerifyAuth();

  const [showStatus, setShowStatus] = useState(false);

  const shippersOrderStore = useMemo(
    () => (location?.state || []) as ShipperOrder[],
    [location?.state],
  );

  const [shipperOrderWithStatusDetail, setShipperOrderWithStatusDetail] =
    useState<DeleteBulkShipperOrderEntity[]>(
      mapStoreToState(shippersOrderStore),
    );
  const [showDoneBtn, setShowDoneBtn] = useState(false);
  const [mutationsIsLoading, setMutationsIsLoading] = useState(false);
  const [doDeleteShipperOrder] = api.useDeleteShipperOrderMutation();
  // #endregion

  // #region HANDLERS
  const handleDeleteBulk = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    setShowStatus(true);
    const promises = shipperOrderWithStatusDetail.map((so) =>
      doDeleteShipperOrder(mapStoreToDto(so)).unwrap(),
    );

    // track loading state
    setMutationsIsLoading(true);

    // NOTE: backend said this endpoint is safe to the parallel mutation
    await Promise.allSettled(promises)
      .then((results) => {
        for (const [idx, result] of results.entries()) {
          // on rejected
          if (result.status === 'rejected') {
            const errorQuery = (result.reason as FetchBaseQueryError)
              ?.data as ApiErrorResponse;
            const errorCode = errorQuery?.error?.code;

            setShipperOrderWithStatusDetail((_so) =>
              _so.map((so) =>
                so.id === shipperOrderWithStatusDetail[idx].id
                  ? {
                      ...so,
                      _actionStatus: BulkActionStatusEnum.FAILED,
                      _actionDetail: getSOCustomErrorMessage(so, errorCode),
                    }
                  : so,
              ),
            );
            continue;
          }

          // on fulfilled
          setShipperOrderWithStatusDetail((_so) =>
            _so.map((so) =>
              so.id === shipperOrderWithStatusDetail[idx].id
                ? {
                    ...so,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Shipper Order deleted successfully',
                  }
                : so,
            ),
          );
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        // on done
        setShowDoneBtn(true);
        setMutationsIsLoading(false);
      });
  }, [doDeleteShipperOrder, handleVerifyAuth, shipperOrderWithStatusDetail]);

  const handleDone = useCallback(() => {
    navigate(shipperOrderRoute.path);
  }, [navigate]);

  return {
    showDoneBtn,
    shipperOrderWithStatusDetail,
    mutationsIsLoading,
    showStatus,
    handleDeleteBulk,
    handleDone,
  };
}
