import React from 'react';
import tw from 'twin.macro';
import { UseJODetailMapsHookObj } from '../../../hook/jobOrderDetail/useJODetailMaps.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { Button, Text } from '../../atom';
import { HeadingFour } from '../../atom/Text/Text.atom';
import MarkerDriver from '../../molecule/MarkerDriver/MarkerDriver.molecule';
import JODetailMapsHeader from '../JODetailMapsHeader/JODetailMapsHeader.organism';
import RunnerMap from '../Map/Map.organism';
import MarkerVehicle from '../MarkerVehicle/MarkerVehicle.organism';

const HeaderContainer = tw.section`flex flex-col shadow-card rounded-md bg-white`;
const HeaderTopContainer = tw.div`flex flex-row justify-between items-center w-full p-4 rounded-t-md border-b-2 border-b-beige-lines`;
const MapContainer = tw.div` h-[500px] bg-assigned-light relative`;
const MapOverlayContainer = tw.div` h-[500px] background-color[rgba(97, 97, 97, 0.74)] w-full  absolute z-[2] flex justify-center items-center`;
const MapOverlayContent = tw.div`flex flex-col`;
type Props = {
  translator: UseTranslator;
  joDetailMaps: UseJODetailMapsHookObj;
};

export default function JODetailMaps({ translator, joDetailMaps }: Props) {
  return (
    <HeaderContainer>
      <HeaderTopContainer>
        <Text.HeadingFour>
          {translator.translate('Real Time Maps')}
        </Text.HeadingFour>
        <JODetailMapsHeader mapsAction={joDetailMaps.mapActions} />
      </HeaderTopContainer>

      <MapContainer>
        {joDetailMaps.isLocationHidden && (
          <MapOverlayContainer>
            <MapOverlayContent>
              <HeadingFour tw="text-assigned-light">
                {translator.translate(
                  'You currently hide both the Truck and the Driver.',
                )}
              </HeadingFour>
              <div tw="self-center mt-2.5 z-10">
                <Button.Solid
                  tw="bg-orange "
                  onClick={joDetailMaps.handleMapsCenter}
                >
                  {translator.translate('Reset Settings')}
                </Button.Solid>
              </div>
            </MapOverlayContent>
          </MapOverlayContainer>
        )}
        {!joDetailMaps.targetLocations?.length &&
          !joDetailMaps.isLocationHidden && (
            <MapOverlayContainer>
              <HeadingFour tw="text-assigned-light">
                {translator.translate('Unknown vehicle or driver position')}
              </HeadingFour>
            </MapOverlayContainer>
          )}
        <RunnerMap
          targetLocations={joDetailMaps.targetLocations}
          disableDragMap
          disableOnclickTarget
          showButtonCurrentLocation
          onGetCurrentLocation={joDetailMaps.handleMapsCenter}
        >
          {joDetailMaps.renderDriverPosition && (
            <MarkerDriver
              isOnline={joDetailMaps.isDriverOnline}
              driverName={joDetailMaps.driverName}
              driverPhoneNumber={joDetailMaps.driverPhoneNumber}
              position={joDetailMaps.renderDriverPosition}
              handleClick={joDetailMaps.handleClickMarkerDriver}
            />
          )}

          {joDetailMaps.renderVehiclePosition && (
            <MarkerVehicle
              position={joDetailMaps.renderVehiclePosition}
              rotation={joDetailMaps.vehicleHeading}
              handleClick={joDetailMaps.handleClickMarkerVehicle}
            />
          )}
        </RunnerMap>
      </MapContainer>
    </HeaderContainer>
  );
}
