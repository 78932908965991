import * as Popover from '@radix-ui/react-popover';
import format from 'date-fns/format';
import React, { useState } from 'react';
import { Modifier } from 'react-day-picker';
import tw from 'twin.macro';
import { DateRangePicker, TextField } from '..';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import { Icon, IconButton, Portal, RootPortal } from '../../atom';
import { RangeYear } from '../DatePicker/DatePicker.molecule';
import {
  CurrentSelection,
  DefaultDateRangeValue,
  DefinedRangeOption,
} from '../DateRangePicker/DateRangePicker.molecule';

// #region INTERFACES
export type DateRangePickerInputProps = {
  readonly sdDateValue: Date | undefined;
  sdOnChangeDateValue?(date?: Date): void;
  readonly edDateValue: Date | undefined;
  edOnChangeDateValue?(date?: Date): void;

  readonly sdId?: string;
  readonly sdName?: string;
  readonly sdLabel?: string;
  readonly sdDisabled?: boolean;
  readonly sdError?: string;
  readonly edId?: string;
  readonly edName?: string;
  readonly edLabel?: string;
  readonly edDisabled?: boolean;
  readonly edError?: string;

  onClickApplyDateRange?(
    _startDate: Date | undefined,
    _endDate: Date | undefined,
  ): void;
  readonly definedRangeOptions?: DefinedRangeOption[];
  readonly maxDateRangeInDays?: number;
  readonly rangeYear?: RangeYear;
  readonly disabledDays?: Modifier;
  readonly side?: Popover.PopoverContentProps['side'];
  readonly align?: Popover.PopoverContentProps['align'];
  readonly textFieldInputBgBeige?: boolean;
  readonly notes?: string;
  readonly disableDefinedRange?: boolean;
  readonly defaultDateRange?: DefaultDateRangeValue;
  readonly allowUndefinedDateRange?: boolean;
};
// #endregion

export default function DateRangePickerInputV2({
  sdDateValue = undefined,
  sdId = 'startDate',
  sdName = 'startDate',
  sdLabel = 'Start Date',
  sdDisabled = false,
  sdError,
  sdOnChangeDateValue = () => {},

  edDateValue = undefined,
  edId = 'endDate',
  edName = 'endDate',
  edLabel = 'End Date',
  edDisabled = false,
  edError,
  edOnChangeDateValue = () => {},

  onClickApplyDateRange,
  definedRangeOptions,
  maxDateRangeInDays,
  rangeYear,
  disabledDays,
  side = 'bottom',
  align = 'center',
  textFieldInputBgBeige = false,
  notes,
  disableDefinedRange = false,
  defaultDateRange,
  allowUndefinedDateRange = false,
}: DateRangePickerInputProps) {
  // #region VALUES
  const { isMobile } = useScreenDetection();
  const [state, setState] = useState<{
    selection: CurrentSelection;
    openDatePickerInput: boolean;
  }>({
    selection: 'from',
    openDatePickerInput: false,
  });

  const sdInputValue = sdDateValue ? format(sdDateValue, 'dd MMM yyyy') : '';
  const edInputValue = edDateValue ? format(edDateValue, 'dd MMM yyyy') : '';
  const handleCloseDatePickerInput = () =>
    setState((prev) => ({ ...prev, openDatePickerInput: false }));

  const inputContainer = (
    <section tw="flex items-center space-x-2">
      <TextField
        readOnly
        value={sdInputValue}
        disabled={sdDisabled}
        onClick={() => {
          if (sdDisabled) return;

          setState({ selection: 'from', openDatePickerInput: true });
        }}
        contentEditable={false}
        id={sdId}
        name={sdName}
        label={sdLabel}
        error={sdError}
        inputBgBeige={textFieldInputBgBeige}
        right={
          <IconButton
            tw="-mr-2 origin-center rotate-90"
            disabled={sdDisabled}
            onClick={() => {
              if (sdDisabled) return;

              setState({ selection: 'from', openDatePickerInput: true });
            }}
          >
            <Icon.ChevronSharp height={22} width={22} />
          </IconButton>
        }
      />

      <Icon.Arrow />

      <TextField
        readOnly
        value={edInputValue}
        disabled={edDisabled}
        onClick={() => {
          if (edDisabled) return;

          setState({ selection: 'to', openDatePickerInput: true });
        }}
        contentEditable={false}
        id={edId}
        name={edName}
        label={edLabel}
        error={edError}
        inputBgBeige={textFieldInputBgBeige}
        right={
          <IconButton
            tw="-mr-2 origin-center rotate-90"
            disabled={edDisabled}
            onClick={() => {
              if (edDisabled) return;

              setState({ selection: 'to', openDatePickerInput: true });
            }}
          >
            <Icon.ChevronSharp height={22} width={22} />
          </IconButton>
        }
      />
    </section>
  );

  const dateRangePicker = (
    <DateRangePicker.Wrapper
      selection={state.selection}
      startDate={sdDateValue}
      endDate={edDateValue}
      onClickCancel={() => handleCloseDatePickerInput()}
      onClickApply={(_startDate, _endDate) => {
        sdOnChangeDateValue(_startDate);
        edOnChangeDateValue(_endDate);
        onClickApplyDateRange?.(_startDate, _endDate);
        handleCloseDatePickerInput();
      }}
      definedRangeOptions={definedRangeOptions}
      maxDateRangeInDays={maxDateRangeInDays}
      disabledDays={disabledDays}
      rangeYear={rangeYear}
      notes={notes}
      disableDefinedRange={disableDefinedRange}
      defaultDateRange={defaultDateRange}
      allowUndefinedDateRange={allowUndefinedDateRange}
    />
  );
  // #endregion

  if (isMobile)
    return (
      <div tw="relative">
        {inputContainer}

        {state.openDatePickerInput && (
          <RootPortal>
            <Portal
              onClick={(e) => {
                e.stopPropagation();
                handleCloseDatePickerInput();
              }}
              tw="items-end transition-all animate-fade-in"
              data-testid="DateRangePickerInput:MobileView:Portal"
            >
              {state.openDatePickerInput && dateRangePicker}
            </Portal>
          </RootPortal>
        )}
      </div>
    );

  return (
    <Popover.Root open={state.openDatePickerInput}>
      <Popover.Trigger asChild>{inputContainer}</Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          side={side}
          align={align}
          css={[
            tw`z-10 transition-all animate-fade-in`,
            isMobile &&
              tw`flex flex-col fixed inset-0 justify-center items-end`,
          ]}
        >
          {!isMobile && (
            <div
              css={[
                tw`absolute right-5 top-3 z-10 text-white`,
                notes && tw`top-5`,
              ]}
            >
              <IconButton
                tw="-mx-2 text-white hover:(bg-white bg-opacity-20)"
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    openDatePickerInput: false,
                  }))
                }
              >
                <Icon.Close />
              </IconButton>
            </div>
          )}

          {dateRangePicker}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
