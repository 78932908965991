import React, { useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import tw, { styled } from 'twin.macro';
import { Button, Icon, Text } from '../../atom';
import Tooltip from '../Tooltip/Tooltip.molecule';

const componentId = 'ButtonDropdownOutline';
const testIds = {
  button: (idx: number) => `${componentId}:Button:${idx}`,
  buttonText: (idx: number) => `${componentId}:ButtonText:${idx}`,
} as const;

// #region STYLED
const Root = tw.div``;
const MainButton = styled.div(({ disabled }: { disabled?: boolean }) => [
  tw`pl-2 pr-1 flex h-6 items-center justify-center font-bold rounded-md text-grey-three text-xs mr-5 border border-beige-lines cursor-pointer`,
  disabled && tw`text-grey-five cursor-not-allowed border-grey-five`,
]);
const DropdownIcon = styled.div(({ expanded }: { expanded: boolean }) => [
  tw`transition rotate-90 w-5 h-5 flex items-center justify-center  [> svg]:(h-4 w-4)`,
  expanded && tw`-rotate-90`,
]);

const DropdownButton = styled(Button.Text)(() => [
  tw`px-6 py-1.5 flex font-bold rounded-none hover:(bg-orange-hover [> span]:text-orange) items-center justify-start`,
]);
const DropdownText = styled(Text.HeadingFive)([tw`text-grey-two`]);
// #endregion

// #region TYPE
export type ButtonDropdownAction = {
  id: string;
  label: string;
  action?: <T>(param?: T) => void;
};
type Props = {
  label: string;
  disabled?: boolean;
  actions: ButtonDropdownAction[];
};
// #endregion

function ButtonDropdownOutline({ label, actions, disabled }: Props) {
  const ref = useRef(null);
  const [isExpanded, toggleExpanded] = useToggle(false);
  useClickAway(ref, (ev: MouseEvent) => {
    const attrValue = (ev.target as Element).getAttribute('data-testid');
    const ignoredTestIDs = [
      'Tooltip:Container:Content',
      ...actions.flatMap((_, idx) => [
        testIds.button(idx),
        testIds.buttonText(idx),
      ]),
    ];

    // do NOT close popup whenever user click inside popup
    if (attrValue && ignoredTestIDs.includes(attrValue)) return;

    // close popup whenever user click outside bulk button
    toggleExpanded(false);
  });

  return (
    <Root ref={ref}>
      <MainButton
        onClick={!disabled ? toggleExpanded : undefined}
        disabled={disabled}
      >
        {label}
        <DropdownIcon expanded={isExpanded}>
          <Icon.ChevronSharp />
        </DropdownIcon>
      </MainButton>
      <Tooltip
        withPortal
        addArrow={false}
        offset={[-18, 10]}
        variant="light"
        placement="bottom-end"
        targetRef={ref}
        visible={isExpanded}
        contentStyle={tw`px-0 py-2.5 max-width[300px] flex flex-col rounded-md animate-slide-in-top duration-300 transition shadow-card`}
      >
        {actions.map((v, idx) => (
          <DropdownButton
            data-testid={testIds.button(idx)}
            key={v.id}
            onClick={() => {
              v.action?.();
              toggleExpanded(false);
            }}
          >
            <DropdownText data-testid={testIds.buttonText(idx)}>
              {v.label}
            </DropdownText>
          </DropdownButton>
        ))}
      </Tooltip>
    </Root>
  );
}

export default ButtonDropdownOutline;
