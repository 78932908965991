import { useNavigate } from 'react-router-dom';
import { legalRoute } from '../../view/Legal/Legal.route';
import { LegalNavSection } from '../../view/Legal/Legal.viewModel';
import useTranslator from '../useTranslator.hook';

export interface ProfileCardLegalItemProps {
  title: string;
  path: string;
  onClick(): void;
}

function useProfile() {
  const translator = useTranslator();
  const navigate = useNavigate();
  const profileCardLegalItems: ProfileCardLegalItemProps[] = [
    {
      title: translator.translate('Disclaimer'),
      path: `${legalRoute.path}?menu=${LegalNavSection.DISCLAIMER}`,
      onClick: () => {
        navigate(legalRoute.path, {
          state: LegalNavSection.DISCLAIMER,
        });
      },
    },
    {
      title: translator.translate('Terms of Use'),
      path: `${legalRoute.path}?menu=${LegalNavSection.TERMS}`,
      onClick: () => {
        navigate(legalRoute.path, {
          state: LegalNavSection.TERMS,
        });
      },
    },
    {
      title: translator.translate('Privacy Policy'),
      path: `${legalRoute.path}?menu=${LegalNavSection.PRIVACY}`,
      onClick: () => {
        navigate(legalRoute.path, {
          state: LegalNavSection.PRIVACY,
        });
      },
    },
  ];
  return {
    profileCardLegalItems,
  };
}

export default useProfile;
