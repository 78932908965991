import React from 'react';

export default function BurgerMenu({
  fill = '#272727',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6312_3715)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.25a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1zM0 10a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1zm0 6.75a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_6312_3715">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
