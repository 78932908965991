import { useCallback, useMemo } from 'react';
import useJODetailController from '../../hook/jobOrderDetail/useJODetailController.hook';
import useJODetailErrorPlaceholder from '../../hook/jobOrderDetail/useJODetailErrorPlaceholder.hook';
import useJODetailMaps from '../../hook/jobOrderDetail/useJODetailMaps.hook';
import useJODetailModalForceJO from '../../hook/jobOrderDetail/useJODetailModalForceJO';
import useJODetailSO from '../../hook/jobOrderDetail/useJODetailSO.hook';
import useSelectedShipperOrder from '../../hook/jobOrderDetail/useSelectedShipperOrder.hook';
import useForceCompleteJO from '../../hook/useForceCompleteJO/useForceCompleteJO.hook';
import useForceCompleteSO from '../../hook/useForceCompleteSO/useForceCompleteSO.hook';
import useJobOrderDetailPolling from '../../hook/useJobOrderDetailPolling/useJobOrderDetailPolling.hook';
import useJobOrderInfo from '../../hook/useJobOrderInfo/useJobOrderInfo.hook';
import useTranslator from '../../hook/useTranslator.hook';
import { Photo } from '../../model/Common.model';
import { generateCommonGalleryCaption } from '../../util/helper.util';
import { formatText } from '../../util/tracking/trackingTimeline.util';
import useImageGalleryController from '../ImageGallery/hooks/useImageGalleryController.hook';
import useJODetailEventHistory from './hooks/useJODetailEventHistory';

export default function useJobOrderDetailViewModel() {
  const translator = useTranslator();

  const jobOrderInfo = useJobOrderInfo();
  const joDetailErrorPlaceholder = useJODetailErrorPlaceholder();
  const joDetailController = useJODetailController({
    jobOrderInfo,
    isMobile: false,
  });
  const joDetailSO = useJODetailSO({ jobOrderInfo });

  const selectedDeliveryId = useMemo(
    () => joDetailSO.selectedDeliveryId,
    [joDetailSO.selectedDeliveryId],
  );
  const joDetailPolling = useJobOrderDetailPolling({ jobOrderInfo });

  const joDetailMaps = useJODetailMaps({ joInfoData: jobOrderInfo.joInfoData });

  const forceCompleteJO = useForceCompleteJO({ jobOrderInfo });

  const selectedShipperOrder = useSelectedShipperOrder({
    jobOrderInfo,
    selectedDeliveryId,
  });

  const forceCompleteSO = useForceCompleteSO({
    jobOrderInfo,
    forceCompleteJO,
    selectedShipperOrder,
  });

  const joDetailModalForceJO = useJODetailModalForceJO({
    joDetailController,
    selectedDeliveryId,
    jobOrderInfo,
    forceCompleteSO,
    forceCompleteJO,
    selectedShipperOrder,
  });

  const joEventHistory = useJODetailEventHistory();

  const imageGalleryController = useImageGalleryController();

  const handlePhotoClick = useCallback(
    (photos: Photo[], startIndex: number, activityIndex: number) => {
      imageGalleryController.handleGalleryThumbnailsClick({
        images: photos.map(({ accessUrl, createdAt, fileName }) => ({
          source: accessUrl,
          caption: generateCommonGalleryCaption(fileName, createdAt),
          title: formatText(
            translator.translate('Activity %s • Proof of Activity'),
            activityIndex.toString(),
          ),
        })),
        startIndex,
      });
    },
    [imageGalleryController, translator],
  );

  return {
    translator,
    jobOrderInfo,
    joDetailErrorPlaceholder,
    joDetailController,
    joDetailSO,
    joDetailMaps,
    joDetailPolling,
    joDetailModalForceJO,
    forceCompleteSO,
    forceCompleteJO,
    joEventHistory,
    imageGalleryController,
    handlePhotoClick,
  };
}
