import React, { MutableRefObject } from 'react';
import { isMobile } from 'react-device-detect';
import { FixedSizeList } from 'react-window';
import tw, { css, styled, TwStyle } from 'twin.macro';
import useAutocompleteVehicle, {
  VehicleAutocompleteProps,
} from '../../../hook/useVehicleAutocomplete.hook';
import { OutsideAlerter, Popover, Text } from '../../atom';
import { BodyFourteen, HeadingFive } from '../../atom/Text/Text.atom';
import TextField from '../TextField/TextField.molecule';
import VehicleChipInfo from '../VehicleChipInfo/VehicleChipInfo.molecule';

// #region STYLED
const List = styled(FixedSizeList)(() => [
  tw`flex-grow bg-white`,
  css`
    .group-list-item:hover:has(.group-chip-list:hover) {
      ${tw`bg-white`}
    }
  `,
]);
const PopOverContainer = tw.div`relative`;
const ListItemMeta = tw.div`whitespace-nowrap overflow-hidden overflow-ellipsis flex flex-col flex-1`;
const ListItemContainer = tw.div`relative p-3 cursor-pointer whitespace-nowrap overflow-hidden overflow-ellipsis flex hover:bg-orange-hover`;
const Input = styled(TextField)(
  ({ value, label }: { value?: string; label?: string }) => [
    tw`px-5 py-3 whitespace-nowrap overflow-hidden overflow-ellipsis `,
    !!value && tw`pr-10`,
    !!label && tw`pt-6 pl-3`,
  ],
);

const Container = styled.div(({ isOpen }: { isOpen?: boolean }) => [
  `input[type='search']::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    input[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }`,
  isOpen && tw`w-full`,
]);
// #endregion

type Props = VehicleAutocompleteProps & { rootStyle?: TwStyle };

export default function AutoCompleteVehicle({
  alwaysOpen = false,
  datas,
  disabled,
  error,
  inputStyle,
  insideLabel,
  loading = false,
  placeholder,
  rootStyle,
  selectedOption,
  value,
  refetch = () => {},
  onBlurAutocomplete = () => {},
  onRemoveData = () => {},
  onFetchMore = () => {},
  changeInputText = () => {},
  changeData = () => {},
}: Props) {
  const {
    inputRef,
    listRef,
    listHeight,
    itemHeight,
    isOpenSearch,
    searchValue,
    openDropdown,
    setOpenDropdown,
    handleClickAway,
    handleFocus,
    handleClickTextField,
    handleOnScrollList,
    handleChangeTextField,
    handleSelect,
    renderIcon,
  } = useAutocompleteVehicle({
    alwaysOpen,
    datas,
    disabled,
    error,
    loading,
    placeholder,
    selectedOption,
    value,
    refetch,
    onBlurAutocomplete,
    onRemoveData,
    onFetchMore,
    changeInputText,
    changeData,
  });

  return (
    <OutsideAlerter
      css={[isMobile && tw`w-full`, rootStyle]}
      onClickAway={handleClickAway}
    >
      <Container className="search-container" isOpen={isOpenSearch}>
        <Input
          label={insideLabel}
          inputStyle={inputStyle}
          onFocus={handleFocus}
          ref={inputRef}
          onClick={handleClickTextField}
          disabled={disabled}
          value={searchValue || ''}
          type="search"
          onChange={(e) => {
            handleChangeTextField(e?.target?.value);
          }}
          error={error}
          placeholder={placeholder}
          right={renderIcon()}
        />
        {datas?.length ? (
          <PopOverContainer tw="relative">
            <Popover
              visible={openDropdown}
              targetRef={inputRef as MutableRefObject<null>}
              tw="min-w-full z-20 overflow-visible bg-white shadow"
              onClick={() => setOpenDropdown(false)}
            >
              {datas && (
                <List
                  ref={listRef}
                  useIsScrolling
                  height={listHeight}
                  onScroll={handleOnScrollList}
                  itemCount={datas.length}
                  itemSize={itemHeight}
                  width="100%"
                >
                  {({ index, style }) => {
                    const currentData = datas[index];

                    return (
                      <ListItemContainer
                        className="group-list-item"
                        role="option"
                        onClick={() => {
                          handleSelect(currentData);
                        }}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            handleSelect(currentData);
                          }
                        }}
                        aria-selected
                        tabIndex={0}
                        style={style}
                      >
                        <ListItemMeta>
                          <HeadingFive tw="truncate">
                            {currentData?.name ?? '-'}
                          </HeadingFive>
                          <BodyFourteen tw="truncate">
                            {currentData?.number ?? '-'}
                          </BodyFourteen>
                        </ListItemMeta>
                        <VehicleChipInfo vehicle={currentData} />
                      </ListItemContainer>
                    );
                  }}
                </List>
              )}
            </Popover>
          </PopOverContainer>
        ) : (
          <div tw="relative">
            <Popover
              visible={openDropdown}
              targetRef={inputRef as MutableRefObject<null>}
              tw="min-w-full z-20 overflow-visible bg-white shadow"
              onClick={() => setOpenDropdown(false)}
            >
              {!loading && (
                <Text.BodyOne tw="bg-white text-status-alert rounded shadow-card flex px-4 py-2">
                  No Options
                </Text.BodyOne>
              )}
            </Popover>
          </div>
        )}
      </Container>
    </OutsideAlerter>
  );
}
