import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';

// #region PROPS
export type RadioProps = React.ComponentProps<'input'>;
// #endregion

// #region STYLED COMPONENTS
const Control = styled.span(({ disabled }: { disabled?: boolean }) => [
  tw`relative padding[18px] -m-2.5 flex-none duration-200`,
  !disabled &&
    tw`after:(content-[' '] absolute inset-0 rounded-full bg-orange-four bg-opacity-0 hover:bg-opacity-40 group-hover:bg-opacity-40 focus:bg-opacity-100 group-focus:bg-opacity-100)`,
]);
const HiddenInput = tw.input`w-9 h-9 absolute m-0 inset-0 opacity-0 cursor-pointer`;
const RadioButton = styled.div(
  (props: { checked?: boolean; disabled?: boolean }) => [
    tw`width[18px] height[18px] absolute inset-2 m-px rounded-full border-2 border-solid border-grey-two bg-white duration-200`,
    props.checked && tw`border-orange`,
    props.disabled && tw`border-grey-four bg-grey-four`,
    props.checked && props.disabled && tw`border-grey-four bg-transparent`,
  ],
);
const RadioMark = styled.div(
  (props: { checked?: boolean; disabled?: boolean }) => [
    tw`w-2.5 h-2.5 absolute inset-3 m-px rounded-full duration-200`,
    props.checked && tw`bg-orange`,
    props.disabled && tw`bg-grey-four`,
  ],
);
// #endregion

export function Radio(props: RadioProps) {
  const { checked, disabled } = props;
  const [isChecked, setChecked] = useState<boolean>(checked ?? false);
  const [isDisabled, setDisabled] = useState<boolean>(disabled ?? false);

  useEffect(() => {
    setChecked(checked ?? false);
  }, [checked]);

  useEffect(() => {
    setDisabled(disabled ?? false);
  }, [disabled]);

  return (
    <Control disabled={disabled}>
      <HiddenInput type="radio" className="peer" {...props} />
      <RadioButton checked={isChecked} disabled={isDisabled} />
      <RadioMark checked={isChecked} disabled={isDisabled} />
    </Control>
  );
}

export default Radio;
