import { Locale } from './locale.config';

const shipperEditLocaleConfig: Locale[] = [
  {
    en: 'Edit Shipper',
    id: 'Ubah Pengirim',
  },
  {
    en: 'Shipper has been edited',
    id: 'Pengirim berhasil diubah',
  },
];

export default shipperEditLocaleConfig;
