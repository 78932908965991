import React, { PropsWithChildren } from 'react';
import tw, { styled } from 'twin.macro';
import { Text } from '../../../component/atom';
import { ExtraStyle } from '../../../component/Type.component';

// #region TYPES
export type JODetailEventHistoryRootProps = PropsWithChildren<{
  containerStyle?: ExtraStyle;
  listStyle?: ExtraStyle;
}>;
export type JODetailEventHistoryItemProps = PropsWithChildren<{
  title: string;
  date: string;
  titleStyle?: ExtraStyle;
}>;
export type JODetailEventHistoryItemSkeletonProps = Pick<
  JODetailEventHistoryItemProps,
  'titleStyle'
>;
// #endregion

// #region STYLED
const RootContainer = tw.div`p-6`;
const List = tw.ul`flex flex-col relative`;
const ItemLi = styled.li(() => [
  tw`relative flex flex-col pb-5 border-l-2 border-dashed border-grey-five`,
  tw`before:(left-[-7px] top-[-3px] w-3 h-3 rounded-full absolute bg-grey-three)`,
  tw`first-of-type:before:(w-4 h-4 left-[-9px] top-[-5px] bg-status-success)`,
  tw`last-of-type:(border-0 pb-0 before:left-[-5px])`,
]);
const ItemTitle = tw(Text.HeadingFive)`-mt-2 font-semibold`;
const ItemDate = tw(Text.BodySmallTwelve)`ml-5 text-grey-three`;
// #endregion

function Root({
  children,
  containerStyle,
  listStyle,
}: JODetailEventHistoryRootProps) {
  return (
    <RootContainer css={containerStyle}>
      <List css={listStyle}>{children}</List>
    </RootContainer>
  );
}

function Item({
  children,
  titleStyle,
  title,
  date,
}: JODetailEventHistoryItemProps) {
  return (
    <ItemLi>
      <ItemTitle css={titleStyle}>{title}</ItemTitle>

      {children}

      <ItemDate>{date}</ItemDate>
    </ItemLi>
  );
}

function ItemSkeleton({ titleStyle }: JODetailEventHistoryItemSkeletonProps) {
  return (
    <ItemLi tw="animate-pulse">
      <ItemTitle tw="bg-grey-three w-24 h-3 ml-4 -mt-1" css={titleStyle} />

      <Text.BodyFourteen tw="bg-grey-three my-2 w-40 h-3 ml-4" />
      <ItemDate tw="bg-grey-three w-20 h-3 ml-4" />
    </ItemLi>
  );
}

export const JODetailEventHistory = {
  Root,
  Item,
  ItemSkeleton,
};
