import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useFormik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store/store.app';
import { SnackbarTheme } from '../../component/molecule/Snackbar/Snackbar.molecule';
import {
  settingInitialValues,
  SettingNavigationItemType,
} from '../../constant/Setting.constant';
import useToggleSettingModal from '../../hook/useToggleSettingModal.hook';
import useTranslator from '../../hook/useTranslator.hook';
import { IOrganizationSetting } from '../../model/Organization.model';
import { SettingDriverManagementResponse } from '../../model/Setting.model';
import { ApiErrorResponse } from '../../service/api.endpoint';
import api from '../../service/api.service';
import { snackbarAction } from '../../store/snackbar.store';
import { FormItem } from '../../types/input.type';
import { errorCodeToLabel } from '../../util/error.util';
import { formatSettingOrganizationToParams } from '../../util/setting/setting.util';
import useSettingAdvanced from './hook/useSettingAdvanced.hook';
import { useSettingDisplay } from './hook/useSettingDisplay.hook';
import useSettingDriverManagement from './hook/useSettingDriverManagement.hook';
import useSettingGeofence from './hook/useSettingGeofence.hook';
import useSettingResetConfirmation from './hook/useSettingResetConfirmation.hook';
import useSettingShipperOrder from './hook/useSettingShipperOrder.hook';

export default function SettingViewModel() {
  // #region GENERAL
  const { translate } = useTranslator();
  const dispatch = useDispatch();
  const { organization } = useSelector((state: RootState) => state.setting);
  const { isModalSettingShown, toggleModal } = useToggleSettingModal();
  // #endregion

  // #region STATE
  const title = translate('Setting');
  const initialValues = useMemo(
    (): IOrganizationSetting => organization || settingInitialValues,
    [organization],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(
    SettingNavigationItemType.DISPLAY,
  );
  // #endregion

  // #region API
  const [doUpdateSettingOrganization, updateSettingOrganizationResponse] =
    api.useUpdateSettingOrganizationMutation();
  // #endregion

  // #region FORM ACTION HANDLER
  const handleSelectSidebarItem = useCallback((item: string) => {
    setCurrentMenu(item as SettingNavigationItemType);
  }, []);

  const handleSuccess = useCallback(
    (response: SettingDriverManagementResponse) => {
      if (!response || (response && !response.ok)) return;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.dark,
          message: translate('Setting has been updated'),
        }),
      );
    },
    [dispatch, translate],
  );

  const handleError = useCallback(
    (error: FetchBaseQueryError | SerializedError) => {
      if (!error) return;
      const data = (error as FetchBaseQueryError).data as ApiErrorResponse;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translate(errorCodeToLabel(data.error.code)),
        }),
      );
    },
    [dispatch, translate],
  );
  // #endregion

  // #region FORM
  const handleSubmit = useCallback(
    async (values: IOrganizationSetting) => {
      try {
        const formattedParams = formatSettingOrganizationToParams(values);
        const res = await doUpdateSettingOrganization(formattedParams).unwrap();
        handleSuccess(res);
      } catch (err) {
        handleError(err as FetchBaseQueryError);
      } finally {
        updateSettingOrganizationResponse.reset();
      }
    },
    [
      doUpdateSettingOrganization,
      handleError,
      handleSuccess,
      updateSettingOrganizationResponse,
    ],
  );

  const { values, setFieldValue, setValues, submitForm } =
    useFormik<IOrganizationSetting>({
      initialValues,
      enableReinitialize: true,
      onSubmit: handleSubmit,
    });

  // #endregion

  // #region FORM SECTION
  const settingDisplay = useSettingDisplay();
  const settingDriver = useSettingDriverManagement({
    values,
    setFieldValue,
  });
  const settingGeofence = useSettingGeofence({
    values,
    setFieldValue,
  });
  const settingShipperOrder = useSettingShipperOrder({
    values,
    setFieldValue,
  });

  const settingAdvanced = useSettingAdvanced({ currentMenu, toggleModal });

  const formData = useMemo((): FormItem[] => {
    const items: Record<SettingNavigationItemType | string, FormItem[]> = {
      [SettingNavigationItemType.DISPLAY]: settingDisplay.formData,
      [SettingNavigationItemType.GEOFENCE]: settingGeofence.formData,
      [SettingNavigationItemType.DRIVER_MANAGEMENT]: settingDriver.formData,
      [SettingNavigationItemType.SHIPPER_ORDER]: settingShipperOrder.formData,
      DEFAULT: [],
    };
    return items[currentMenu] || items.DEFAULT;
  }, [
    currentMenu,
    settingDisplay.formData,
    settingDriver.formData,
    settingGeofence.formData,
    settingShipperOrder.formData,
  ]);

  const handleResetForm = useCallback(async () => {
    await settingDisplay.handleReset();
    await setValues(settingInitialValues);
  }, [setValues, settingDisplay.handleReset]);

  // #endregion

  // #region ACTION
  const handleCloseModal = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleOverlayClick = useCallback(() => {
    if (isLoading) return;
    handleCloseModal();
  }, [handleCloseModal, isLoading]);

  const handleSubmitSave = useCallback(async () => {
    await submitForm();
    await settingDisplay.handleSave();
  }, [submitForm, settingDisplay]);

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true);
      await handleSubmitSave();
    } catch (err) {
      handleError(err as FetchBaseQueryError);
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  }, [handleCloseModal, handleError, handleSubmitSave]);
  // #endregion

  const resetConfirmation = useSettingResetConfirmation({
    currentMenu,
    toggleModal,
    handleResetForm,
    handleSaveSetting: handleSubmitSave,
  });

  const modalAction = useMemo(
    () => ({
      label: translate('Save'),
      isLoading,
      action: () => void handleSave(),
    }),
    [handleSave, isLoading, translate],
  );

  const modalCancelAction = useMemo(
    () => ({
      label: translate('Reset'),
      disabled: isLoading,
      action: () => {
        resetConfirmation.setIsModalVisible(true);
        handleCloseModal();
      },
    }),
    [isLoading, resetConfirmation, translate, handleCloseModal],
  );

  return {
    title,
    formData,
    modalAction,
    currentMenu,
    modalCancelAction,
    resetConfirmation,
    isModalSettingShown,
    settingAdvanced,
    handleSave,
    handleCloseModal,
    handleOverlayClick,
    handleSelectSidebarItem,
  };
}
