import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../config/router/router.config';
import { helpCenterDriverContestRegisterRoute } from './HelpCenterDriverContestRegister/HelpCenterDriverContestRegister.route';
import { helpCenterDriverContestRemovalRoute } from './HelpCenterDriverContestRemoval/HelpCenterDriverContestRemoval.route';

export type HelpCenterRouteParam = DefaultRouteParam;

export const helpCenterDriverContestRoute: RouteConfig = {
  name: 'Driver Contest',
  Component: React.lazy(() => import('./HelpCenterDriverContest.view')),
  path: '/help-center/driver-contest',
  isPrivate: false,
  breadcrumb: 'Driver Contest',
  children: [
    {
      ...helpCenterDriverContestRegisterRoute,
      path: 'register',
    },
    {
      ...helpCenterDriverContestRemovalRoute,
      path: 'removal',
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    label: 'Driver Contest',
    testID: 'MenuHelpCenterDriverContest',
    hasDivider: true,
    hasSub: [
      helpCenterDriverContestRegisterRoute,
      helpCenterDriverContestRemovalRoute,
    ],
  },
};
