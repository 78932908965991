import { Locale } from './locale.config';

const driverListLocaleConfig: Locale[] = [
  {
    en: 'Name',
    id: 'Nama',
  },
  {
    en: 'Full Name',
    id: 'Nama Lengkap',
  },
  {
    en: 'Name A-Z',
    id: 'Nama A-Z',
  },
  {
    en: 'Name Z-A',
    id: 'Nama Z-A',
  },
  {
    en: 'Status Ascending',
    id: 'Status Meningkat',
  },
  {
    en: 'Status Descending',
    id: 'Status Menurun',
  },
  {
    en: 'Filter Driver',
    id: 'Filter Sopir',
  },
  {
    en: 'Phone Number',
    id: 'Nomor Telepon',
  },
  {
    en: 'Name or Phone',
    id: 'Nama atau No HP',
  },
  {
    en: 'Active',
    id: 'Aktif',
  },
  {
    en: 'Inactive',
    id: 'Nonaktif',
  },
  {
    en: 'On Duty',
    id: 'Bertugas',
  },
  {
    en: 'Driver has active schedules',
    id: 'Sopir memiliki jadwal aktif',
  },
  {
    en: 'Driver has active schedules in the future',
    id: 'Sopir memiliki jadwal yang akan datang',
  },
  {
    en: 'Driver already on duty',
    id: 'Sopir sedang bertugas',
  },
  {
    en: 'Driver already deactivated',
    id: 'Sopir sudah dinonaktifkan',
  },
  {
    en: "You don't have any selected Drivers that you want to deactivate",
    id: 'Anda tidak memiliki daftar Sopir yang ingin dinonaktifkan',
  },
  {
    en: 'Try to select some Drivers from Driver page',
    id: 'Cobalah untuk memilih beberapa Sopir di halaman Sopir',
  },
  {
    en: 'Go back to Driver page',
    id: 'Kembali ke halaman Sopir',
  },
  {
    en: 'Download Timesheet',
    id: 'Unduh Lembar Kerja',
  },
  {
    en: 'Max. 20 selected drivers and period of 31 days',
    id: 'Maksimum 20 sopir terpilih dan periode waktu 31 hari',
  },
  {
    en: 'Maximum of 20 selected drivers in one download',
    id: 'Maksimum 20 sopir terpilih dalam satu kali unduh',
  },
  {
    en: 'Please make sure the selected period is 31 days',
    id: 'Harap pastikan periode yang dipilih adalah 31 hari',
  },
];

export default driverListLocaleConfig;
