import useTranslator from '../../hook/useTranslator.hook';
import useJobOrderDetailExpenseApproveLogic from './hooks/useJobOrderDetailExpenseApproveLogic.hook';
import useJobOrderDetailExpenseApproveTable from './hooks/useJobOrderDetailExpenseApproveTable.hook';

export default function useJobOrderDetailExpenseApproveViewModel() {
  const translator = useTranslator();
  const logic = useJobOrderDetailExpenseApproveLogic();
  const table = useJobOrderDetailExpenseApproveTable({ logic });

  return {
    translator,
    logic,
    table,
  };
}
