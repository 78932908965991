import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format, fromUnixTime } from 'date-fns';
import React, { useMemo } from 'react';
import 'twin.macro';
import { Text } from '../../../component/atom';
import { BulkActionStatus } from '../../../component/molecule';
import { BulkActionEntity, BulkActionStatusEnum } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { Schedule } from '../../../model/Schedule.model';
import { mapBulkActionStatusToLabel } from '../../../util/helper.util';
import { indonesianPhoneNumberFormat } from '../../../util/schedule.util';
import { UseScheduleRemoveLogic } from './useScheduleRemoveLogic.hook';

// #region TYPES
type Props = {
  logic: UseScheduleRemoveLogic;
};
export type RemoveScheduleEntity = BulkActionEntity<Schedule>;
export type UseScheduleRemoveTable = ReturnType<typeof useScheduleRemoveTable>;
// #endregion

export default function useScheduleRemoveTable({
  logic: { showStatus, schedulesWithStatusDetail },
}: Props) {
  const translator = useTranslator();

  const columns = useMemo<ColumnDef<RemoveScheduleEntity>[]>(
    () => [
      {
        id: 'driver',
        size: 180,
        header: translator.translate('Driver Name / Phone Number'),
        accessorKey: 'driver',
        cell: ({ getValue }) => {
          const driver = getValue() as Schedule['driver'];

          return (
            <div tw="max-w-[325px]">
              <div tw="truncate">
                <Text.BodyFourteen tw="font-semibold truncate">
                  {driver.fullName}
                </Text.BodyFourteen>
              </div>
              <div>
                <Text.BodyTen tw="truncate">
                  {indonesianPhoneNumberFormat(driver.phoneNumber)}
                </Text.BodyTen>
              </div>
            </div>
          );
        },
      },
      {
        id: 'vehicle',
        size: 110,
        header: translator.translate('Vehicle'),
        accessorKey: 'vehicle',
        cell: ({ getValue }) => {
          const vehicle = getValue() as Schedule['vehicle'];

          return (
            <Text.Paragraph tw="text-black font-semibold truncate">
              {vehicle.name}
            </Text.Paragraph>
          );
        },
      },
      {
        id: 'from',
        size: 100,
        header: translator.translate('Start Date'),
        accessorKey: 'from',
        cell: ({ getValue }) => (
          <Text.BodySixteen>
            {format(fromUnixTime(Number(getValue())), 'dd MMM yyyy')}
          </Text.BodySixteen>
        ),
      },
      {
        id: 'to',
        size: 100,
        header: translator.translate('End Date'),
        accessorKey: 'to',
        cell: ({ getValue }) => (
          <Text.BodySixteen>
            {format(fromUnixTime(Number(getValue())), 'dd MMM yyyy')}
          </Text.BodySixteen>
        ),
      },
      ...(showStatus
        ? [
            {
              id: '_actionStatus',
              size: 90,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              id: '_actionDetail',
              size: 200,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({ getValue }: { getValue: () => string }) => (
                <Text.Paragraph tw="truncate animate-slide-in-left">
                  {translator.translate(getValue())}
                </Text.Paragraph>
              ),
            },
          ]
        : []),
    ],
    [showStatus, translator],
  );

  const table = useReactTable({
    columns,
    data: schedulesWithStatusDetail,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
