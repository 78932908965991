import React from 'react';
import tw, { styled } from 'twin.macro';
import { ShipperStatusFilter } from '../../../constant/Shipper.constant';
import { Text } from '../../atom';

// #region INTERFACES
type StatusProps = { status: ShipperStatusFilter };
type Props = {
  status: ShipperStatusFilter;
  statusLabel: string;
};
// #endregion

// #region STYLED
const StatusContainer = styled.div(({ status }: StatusProps) => [
  tw`px-2 py-0.5 inline-block font-bold rounded-md`,
  status === ShipperStatusFilter.INACTIVE && tw`bg-grey-six`,
  status === ShipperStatusFilter.ACTIVE && tw`bg-delivered-light`,
]);
const SquareIcon = styled.div(({ status }: StatusProps) => [
  tw`inline-block rounded-sm width[10px] height[10px] mr-2`,
  status === ShipperStatusFilter.INACTIVE && tw`bg-grey-three`,
  status === ShipperStatusFilter.ACTIVE && tw`bg-delivered-dark`,
]);
// #endregion

function ShipperStatus({ status, statusLabel }: Props) {
  return (
    <StatusContainer status={status}>
      <SquareIcon status={status} />

      <Text.BodyTwo
        css={[
          status === ShipperStatusFilter.INACTIVE && tw`text-grey-three`,
          status === ShipperStatusFilter.ACTIVE && tw`text-delivered-dark`,
        ]}
      >
        {statusLabel}
      </Text.BodyTwo>
    </StatusContainer>
  );
}

export default ShipperStatus;
