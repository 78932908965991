import React, { PropsWithChildren } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { ToastContainer } from 'react-toastify';
import tw, { styled } from 'twin.macro';
import {
  DrivingContestBanner,
  SnackbarAdapter,
} from '../../component/molecule';
import { Sidebar, TopBar } from '../../component/organism';
import { sidebarConfig } from '../../config/router/router.config';
import { ProfileCardLegalItemProps } from '../../hook/useProfile/userProfile.hook';
import { UseTopBarHookObj } from '../../hook/useTopBar/useTopBar.hook';
import { UseTopbarOnboardingHookObj } from '../../hook/useTopbarOnboarding/useTopbarOnboarding.hook';
import { UseTranslator } from '../../hook/useTranslator.hook';
import SettingView from '../Setting/Setting.view';
import { UseSessionHookObj } from './hooks/useSession.hook';

const Container = tw.div`min-h-screen min-w-min bg-beige-bg flex items-stretch`;
const ContentWrapper = tw.div`flex-1 pt-16 relative w-[calc(100vw - 320px)] overflow-x-auto whitespace-nowrap`;

const ContentArea = styled.div<{
  fullWidth?: boolean;
  showDrivingContestBanner?: boolean;
}>`
  ${({ fullWidth, showDrivingContestBanner }) => [
    tw`w-full h-full mx-auto overflow-y-hidden`,
    !fullWidth && tw`py-10 overflow-y-auto!`,
    showDrivingContestBanner && tw`py-0`,
  ]}
  @media (max-width: 854px) {
    padding-left: ${({ fullWidth }) => (fullWidth ? 0 : '16px')};
    padding-right: ${({ fullWidth }) => (fullWidth ? 0 : '16px')};
  }
`;
const SidebarWrapper = styled.div((props: { expanded: boolean }) => [
  tw`z-30 bg-white border-r border-beige-lines inline-block width[84px] duration-200 h-[100vh] pt-16 flex-shrink-0 relative`,
  props.expanded && tw`w-60`,
  tw`hover:(w-60)`,
  `ul, span {
      display: none;
    }
    :hover ul, :hover span {
      display: block;
    }`,
  props.expanded &&
    `ul, span {
      display: block;
    }`,
]);

const SidebarContent = styled.div(
  ({ sideBarExpanded }: { sideBarExpanded?: boolean }) => [
    sideBarExpanded && tw`pr-1`,
  ],
);

const SidebarThumb = tw.div`bg-black bg-opacity-20 absolute -right-0.5 w-2.5 hover:bg-opacity-30 hidden group-hover:block`;

type Props = PropsWithChildren<Record<string, unknown>> & {
  translator: UseTranslator;
  onboarding: UseTopbarOnboardingHookObj;
  topBar: UseTopBarHookObj;
  session: UseSessionHookObj;
  profileCardLegalItems: ProfileCardLegalItemProps[];
  sideBarExpanded: boolean;
  drivingContestYear?: number;
  currentPath: string;
  showDrivingContestBanner: boolean;
  fullWidth?: boolean;
  onClickTopBarMenu(): void;
  onClickSidebarItem(path: string, externalLink?: () => void): void;
  onMouseEnterSidebar(): void;
  onMouseLeaveSidebar(): void;
  onToggleModalSetting(): void;
};

export default function WrapperAuthenticatedView({
  translator,
  onboarding,
  topBar,
  session,
  children,
  profileCardLegalItems,
  sideBarExpanded,
  currentPath,
  drivingContestYear,
  showDrivingContestBanner,
  fullWidth,
  onClickTopBarMenu,
  onClickSidebarItem,
  onMouseEnterSidebar,
  onMouseLeaveSidebar,
  onToggleModalSetting,
}: Props) {
  return (
    <Container id="rootContainer">
      <ToastContainer
        theme="light"
        position="bottom-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable
        hideProgressBar
      />
      <TopBar
        isLoggedIn
        onboarding={onboarding}
        topBar={topBar}
        session={session}
        translator={translator}
        left={null}
        right={null}
        profileCardLegalItems={profileCardLegalItems}
        onClickHelp={topBar.handleClickHelp}
        onClickSetting={onToggleModalSetting}
        onClickMenu={onClickTopBarMenu}
      />

      <SidebarWrapper expanded={sideBarExpanded} />
      <SidebarWrapper
        expanded={sideBarExpanded}
        tw="fixed"
        onMouseEnter={onMouseEnterSidebar}
        onMouseLeave={onMouseLeaveSidebar}
      >
        <Scrollbars
          className="group scrollbarStyle"
          style={{ overflowY: 'auto' }}
          renderView={() => <SidebarContent />}
          renderThumbVertical={() => <SidebarThumb />}
        >
          <Sidebar
            currentPath={currentPath}
            items={sidebarConfig}
            expandedSidebar={sideBarExpanded}
            onItemClick={onClickSidebarItem}
          />
        </Scrollbars>
      </SidebarWrapper>

      <ContentWrapper id="contentWrapper">
        <ContentArea
          id="contentArea"
          fullWidth={fullWidth}
          showDrivingContestBanner={showDrivingContestBanner}
        >
          <DrivingContestBanner
            year={drivingContestYear}
            showBanner={showDrivingContestBanner}
          />
          {children}
        </ContentArea>
        <SnackbarAdapter />
      </ContentWrapper>
      <SettingView />
    </Container>
  );
}
