import { Locale } from './locale.config';

const driverLocaleConfig: Locale[] = [
  {
    en: 'driver',
    id: 'sopir',
  },
  {
    en: 'Add Driver',
    id: 'Tambah Sopir',
  },
  {
    en: 'Full name must be at least 3 characters.',
    id: 'Nama Lengkap minimal 3 karakter.',
  },
  {
    en: 'Full name must be at most 255 characters.',
    id: 'Nama Lengkap maksimal 255 karakter.',
  },
  {
    en: 'Full name is required.',
    id: 'Nama Lengkap wajib diisi.',
  },
  {
    en: 'Phone number is required.',
    id: 'Nomor Telepon wajib diisi.',
  },
  {
    en: 'Phone number must be at least 9 digits.',
    id: 'Nomor Telepon minimal 9 digit.',
  },
  {
    en: 'PIN must be 6 digits.',
    id: 'PIN harus 6 digit.',
  },
  {
    en: 'Phone number maximum 12 digits.',
    id: 'Nomor Telepon maksimal 12 digit.',
  },
  {
    en: 'Phone number is invalid.',
    id: 'Nomor Telepon tidak sesuai.',
  },
  {
    en: 'Driver Added to Driver List',
    id: 'Sopir Ditambahkan ke Daftar Sopir.',
  },
  {
    en: 'Back to Driver',
    id: 'Kembali ke Daftar Sopir',
  },
  {
    en: 'Back to Job Order Form',
    id: 'Kembali ke Form Perintah Kerja',
  },
  {
    en: 'Phone number start with 8.',
    id: 'Nomor Telepon diawali dengan 8.',
  },
  {
    en: 'Successfully Created PIN!',
    id: 'Berhasil Membuat PIN!',
  },
  {
    en: 'Successfully Reset PIN!',
    id: 'Berhasil Reset PIN!',
  },
  {
    en: 'This Driver has been added to the Driver List:',
    id: 'Sopir berikut ditambahkan ke daftar sopir:',
  },
  {
    en: "This Driver's PIN has been reset:",
    id: 'PIN Sopir berikut telah direset:',
  },
  {
    en: 'PIN copied to clipboard',
    id: 'PIN disalin ke clipboard',
  },
  {
    en: "Driver's PIN",
    id: 'PIN Sopir',
  },
  {
    en: 'PIN is required.',
    id: 'PIN wajib diisi',
  },
  {
    en: 'PIN is not valid.',
    id: 'PIN tidak valid.',
  },
  {
    en: 'Repeat pattern is not allowed.',
    id: 'Pola tidak boleh berulang.',
  },
  {
    en: 'Back to Driver List',
    id: 'Kembali ke daftar Sopir',
  },
  {
    en: 'Consecutive digit is not allowed.',
    id: 'PIN tidak boleh berurutan.',
  },
  {
    en: 'You can only see this screen once, this PIN will be expired on ',
    id: 'Anda hanya dapat melihat halaman ini sekali, PIN ini berlaku hingga ',
  },
  {
    en: 'Don’t lose the PIN and don’t forget to contact the Driver.',
    id: 'Jangan hilangkan PIN dan jangan lupa untuk kontak ke sopir.',
  },
  {
    en: 'You can search by Name or Phone Number',
    id: 'Cari menggunakan Nama atau Nomor Telepon',
  },
  {
    en: 'You can search by Name, Phone Number, or Driver License',
    id: 'Cari menggunakan Nama, Nomor Telepon, atau Nomor SIM',
  },
  {
    en: 'You can search by Name, or Phone Number',
    id: 'Cari menggunakan Nama, atau Nomor Telepon',
  },
  {
    en: 'Driver license minimum 12 characters.',
    id: 'Nomor SIM minimal 12 karakter',
  },
  {
    en: 'Driver license maximum 14 characters.',
    id: 'Nomor SIM maksimal 14 karakter',
  },
  {
    en: 'Please enter a valid driver license number',
    id: 'Pastikan nomor SIM yang Anda masukkan benar',
  },
  {
    en: 'Address must be at most 166 characters.',
    id: 'Alamat maksimal 166 karakter',
  },
  {
    en: 'Date of Birth',
    id: 'Tanggal Lahir',
  },
  {
    en: 'Driver License',
    id: 'Nomor SIM',
  },
  {
    en: 'Phone Number',
    id: 'Nomor HP',
  },
  {
    en: 'Driver Information',
    id: 'Informasi Sopir',
  },
  {
    en: 'Personal Information',
    id: 'Informasi Personal',
  },
  {
    en: 'Login Information',
    id: 'Informasi Login',
  },
  {
    en: 'Randomize',
    id: 'Acak',
  },
  {
    en: 'Send WA',
    id: 'Kirim WA',
  },
  {
    en: 'Go to',
    id: 'Lihat',
  },
  {
    en: 'to reschedule.',
    id: 'untuk mengubah jadwal',
  },
  {
    en: 'to reschedule, or contact',
    id: 'untuk penjadwalan ulang, atau hubungi',
  },
  {
    en: 'Try Again',
    id: 'Coba Lagi',
  },
  {
    en: 'Failed to Add Driver and Schedule',
    id: 'Gagal Menambahkan Sopir dan Jadwal',
  },
  {
    en: 'Please try again later or contact',
    id: 'Mohon untuk mencoba lagi atau menghubungi',
  },
  {
    en: 'Call Center',
    id: 'Pusat Bantuan',
  },
  {
    en: 'for more information.',
    id: 'untuk informasi lebih lengkap.',
  },
  {
    en: 'Failed to add schedule.',
    id: 'Gagal menambah Jadwal.',
  },
  {
    en: 'Driver Successfully Added!',
    id: 'Sopir Berhasil Ditambahkan!',
  },
  {
    en: 'Driver and Schedule Successfully Added!',
    id: 'Sopir dan Jadwal Berhasil Ditambahkan!',
  },
  {
    en: 'Add Driver and Schedule',
    id: 'Tambah Sopir dan Jadwal',
  },
  {
    en: 'Add Driver & Schedule',
    id: 'Tambah Sopir & Jadwal',
  },
  {
    en: 'Schedule List',
    id: 'Daftar Jadwal',
  },
  {
    en: 'Driver Successfully Registered to Driver Contest',
    id: 'Berhasil mendaftarkan Sopir ke Driver Contest',
  },
  {
    en: 'Failed to register Driver to Driver Contest',
    id: 'Gagal mendaftarkan Sopir ke Driver Contest',
  },
  {
    en: 'Additional Information',
    id: 'Informasi Tambahan',
  },
  {
    en: 'This information will be used to log into the Driver App. Please ensure you save this information before completing the registration.',
    id: 'Informasi dibawah ini digunakan untuk masuk ke Aplikasi Pengemudi. Pastikan Anda menyimpan informasi ini sebelum menyelesaikan pendaftaran.',
  },
  {
    en: 'Registration Succeeded',
    id: 'Registrasi Berhasil',
  },
  {
    en: 'Waiting for registration',
    id: 'Menunggu pendaftaran',
  },
  {
    en: 'Back',
    id: 'Kembali',
  },
  {
    en: 'Driver already assigned to driver contest',
    id: 'Sopir sudah terdaftar di driver contest',
  },
  {
    en: "You don't have any selected Drivers that you want to register",
    id: 'Anda belum memilih Sopir yang akan didaftarkan',
  },
  {
    en: 'Try to select some Drivers from Driver Contest Register page',
    id: 'Silahkan pilih Sopir dari halaman Pendaftaran Driver Contest',
  },
  {
    en: 'Go back to Driver Contest Register page',
    id: 'Kembali ke halaman Pendaftaran Driver Contest',
  },
  {
    en: 'Confirm Registration',
    id: 'Konfirmasi Pendaftaran',
  },
  {
    en: 'Driver Contest Participant ?',
    id: 'Peserta Driver Contest ?',
  },
  {
    en: 'Register Driver Contest Participant',
    id: 'Pendaftaran Peserta Driver Contest',
  },
  {
    en: 'Upload a XLS file to import drivers data',
    id: 'Unggah file XLS untuk mendaftarkan data sopir',
  },
  {
    en: `Phone number, Driver's name and PIN are mandatory`,
    id: 'Nomor Telepon, Nama Driver dan PIN wajib diisi',
  },
  {
    en: `Driver license and Phone number must start with apostrophe mark. For example : “'6281234567890“`,
    id: `Nomor SIM dan Nomor Telepon harus dimulai dengan tanda petik satu. Contoh : “'6281234567890“`,
  },
  {
    en: 'Maximum amount of data is 100 rows',
    id: 'Maksimum jumlah data adalah 100 baris',
  },
  {
    en: 'Driver registered successfully',
    id: 'Sopir berhasil didaftarkan',
  },
  {
    en: 'Import Driver',
    id: 'Unggah Sopir',
  },
  {
    en: 'Basic Information',
    id: 'Informasi Umum',
  },
  {
    en: 'Last Reset',
    id: 'Terakhir Reset',
  },
  {
    en: 'Driver Profile',
    id: 'Profil Sopir',
  },
  {
    en: 'Edit Driver Info',
    id: 'Ubah Informasi Sopir',
  },
  {
    en: 'Delivery Timesheet',
    id: 'Daftar Waktu Pengiriman',
  },
  {
    en: 'Earliest Start',
    id: 'Mulai Terawal',
  },
  {
    en: 'Latest End',
    id: 'Selesai Terakhir',
  },
  {
    en: 'Work Day',
    id: 'Hari Kerja',
  },
  {
    en: 'Does driver has schedule',
    id: 'Apakah sopir mempunyai jadwal',
  },
  {
    en: 'Total Delivery Days',
    id: 'Total Hari Pengiriman',
  },
  {
    en: 'Total Delivery Durations',
    id: 'Total Durasi Pengiriman',
  },
  {
    en: 'Delivery Time',
    id: 'Waktu Pengiriman',
  },
  {
    en: 'Add Delivery Time',
    id: 'Tambah Waktu Pengiriman',
  },
  {
    en: 'Selected date already has the same delivery time. Please choose another delivery time.',
    id: 'Tanggal yang dipilih sudah memiliki waktu pengiriman yang sama. Silakan pilih waktu pengiriman lain.',
  },
  {
    en: 'Selected time already registered. Please choose another delivery time.',
    id: 'Waktu yang dipilih sudah terdaftar. Silakan pilih waktu pengiriman lain.',
  },
  {
    en: 'Are you sure to delete this Delivery Time?',
    id: 'Apakah Anda yakin akan menghapus Waktu Pengiriman ini?',
  },
  {
    en: "This data won't be able to recovered once it got deleted.",
    id: 'Data ini tidak akan dapat dipulihkan setelah dihapus.',
  },
  {
    en: 'Timesheet successfully deleted',
    id: 'Waktu Pengiriman berhasil dihapus',
  },
  {
    en: 'Timesheet successfully created',
    id: 'Waktu Pengiriman berhasil dibuat',
  },
  {
    en: 'Timesheet successfully updated',
    id: 'Waktu Pengiriman berhasil diubah',
  },
  {
    en: 'Add Time',
    id: 'Tambah Waktu',
  },
  {
    en: 'Save Time',
    id: 'Ubah Waktu',
  },
  {
    en: 'Time Entry',
    id: 'Catatan Waktu',
  },
  {
    en: 'Edit Delivery Time',
    id: 'Ubah Waktu Pengiriman',
  },
  {
    en: 'Action',
    id: 'Aksi',
  },
  {
    en: 'Action',
    id: 'Aksi',
  },
  {
    en: 'Performed by',
    id: 'Dilakukan oleh',
  },
  {
    en: 'Deleted Value',
    id: 'Nilai yang Dihapus',
  },
  {
    en: 'Previous Value',
    id: 'Nilai Sebelumnya',
  },
  {
    en: 'New Value',
    id: 'Nilai Baru',
  },
  {
    en: 'Added Value',
    id: 'Nilai yang Ditambahkan',
  },
  {
    en: 'Created by',
    id: 'Dibuat oleh',
  },
  {
    en: 'Add Time Entry',
    id: 'Menambah Catatan Waktu',
  },
  {
    en: 'Edit Time Entry',
    id: 'Mengubah Catatan Waktu',
  },
  {
    en: 'Delete Time Entry',
    id: 'Menghapus Catatan Waktu',
  },
];

export default driverLocaleConfig;
