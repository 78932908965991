import React from 'react';
import { isMobile } from 'react-device-detect';
import tw from 'twin.macro';
import { Text } from '../../atom';

type Props = {
  image: React.ReactElement;
  postingDate: string;
  contentNews: string;
  onClick?: () => void;
};

const Container = tw.div`inline-block items-center w-full`;
const ContentItem = tw.div`flex-1 p-4 pb-3.5 w-full`;

export function NewsCard({ postingDate, contentNews, image, onClick }: Props) {
  return (
    <Container onClick={onClick}>
      {image}
      <ContentItem>
        {isMobile ? (
          <Text.BodyThree tw="text-status-stand-by pb-1">
            {postingDate}
          </Text.BodyThree>
        ) : (
          <Text.BodyOne tw="text-status-stand-by pb-1">
            {postingDate}
          </Text.BodyOne>
        )}
        {isMobile ? (
          <Text.BodyThree tw="text-black font-semibold">
            {contentNews}
          </Text.BodyThree>
        ) : (
          <Text.HeadingThree tw="text-black font-semibold">
            {contentNews}
          </Text.HeadingThree>
        )}
      </ContentItem>
    </Container>
  );
}

export default NewsCard;
