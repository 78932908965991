import React from 'react';
import { PanViewer } from 'react-image-pan-zoom-rotate';
import tw, { styled } from 'twin.macro';
import { Icon, IconButton, Text } from '../../component/atom';
import { Modal } from '../../component/molecule';
import { MAX_ZOOM, MIN_ZOOM, STEP } from '../../constant/Gallery.constant';
import useImageGalleryViewModel, {
  UseGalleryViewModelProps,
} from './ImageGallery.viewModel';

const ImgContainer = styled.div`
  ${tw`bg-black overflow-hidden`}
  & .pan-container {
    & div {
      @media (-webkit-min-device-pixel-ratio: 1.25) {
        zoom: 0.8;
      }
      ${tw`h-[512px] w-[896px] 2xl:(h-[640px] w-[1024px]) flex justify-center items-center`}
      & > img {
        ${tw`h-full w-auto`}
      }
    }
  }
`;

type Props = UseGalleryViewModelProps;
export default function ImageGallery({
  testID,
  showDownload,
  hideFooter,
  enablePan,
  ...rest
}: Props) {
  const gallery = useImageGalleryViewModel(rest);
  if (!rest.controller.gallery) return null;
  return (
    <Modal.WithClose
      onClose={rest.controller.handleGalleryClose}
      onOverlayClick={rest.controller.handleGalleryClose}
      css={[tw`p-0 max-w-[1024px] max-h-[768px] h-auto w-auto`]}
      titleStyle={tw`h-16 p-4 block mb-0`}
    >
      <div tw="flex items-center justify-between p-5 h-16">
        <Text.HeadingFour>{gallery.title}</Text.HeadingFour>
        {showDownload && (
          <div>
            <IconButton
              tw="rounded-sm border border-beige-lines"
              onClick={gallery.handleDownload}
            >
              <Icon.Download.Medium />
            </IconButton>
          </div>
        )}
      </div>

      <div tw="relative">
        {gallery.showPrev && (
          <div tw="absolute left-0 h-full items-center flex z-50 p-2">
            <IconButton
              tw="text-white -rotate-180"
              onClick={gallery.handlePrev}
              data-testid={`${testID ? `${testID}:` : ''}Gallery:PrevImage`}
            >
              <Icon.ChevronRounded />
            </IconButton>
          </div>
        )}
        <ImgContainer>
          <PanViewer
            zoom={gallery.zoom}
            pandx={gallery.dx}
            pandy={gallery.dy}
            onPan={gallery.handlePanning}
            key={gallery.dx}
            setZoom={gallery.handleScrollZoom}
            enablePan={enablePan}
          >
            <img
              src={gallery.selectedImage?.source}
              alt={gallery.selectedImage?.caption}
              data-testid={`${testID ? `${testID}:` : ''}Gallery:Image`}
            />
          </PanViewer>
        </ImgContainer>

        {gallery.showNext && (
          <div tw="absolute right-0 top-0 h-full items-center flex z-50 p-2">
            <IconButton
              tw="text-white"
              onClick={gallery.handleNext}
              data-testid={`${testID ? `${testID}:` : ''}Gallery:NextImage`}
            >
              <Icon.ChevronRounded />
            </IconButton>
          </div>
        )}
      </div>
      <div tw="h-16 flex items-center px-5">
        {(typeof hideFooter === 'undefined' || !hideFooter) && (
          <>
            <Text.HeadingFour
              tw="overflow-ellipsis overflow-hidden whitespace-nowrap max-w-[75%]"
              data-testid={`${testID ? `${testID}:` : ''}Gallery:caption`}
            >
              {gallery.selectedImage?.caption || 'Untitled'}
            </Text.HeadingFour>
            <div tw="ml-auto flex items-center">
              {gallery.zoom > MIN_ZOOM && (
                <div
                  tw="mr-2 border border-beige-lines rounded cursor-pointer"
                  onClick={gallery.handleZoomToFit}
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => {}}
                  data-testid={`${testID ? `${testID}:` : ''}Gallery:ZoomToFit`}
                >
                  <Icon.SizeFit tw=" w-8 h-8" />
                </div>
              )}
              <Text.HeadingFour
                role="button"
                tw="cursor-pointer"
                onClick={gallery.handleZoomOut}
                data-testid={`${testID ? `${testID}:` : ''}Gallery:ZoomOut`}
              >
                –
              </Text.HeadingFour>
              <input
                ref={gallery.sliderRef}
                className="slider-thumb"
                type="range"
                min={MIN_ZOOM}
                max={MAX_ZOOM}
                step={STEP}
                value={gallery.zoom}
                onChange={gallery.handleZoomSliderChange}
                tw="appearance-none w-full h-1 p-0 bg-grey-four rounded mx-2 accent-color[yellow]"
                data-testid={`${testID ? `${testID}:` : ''}Gallery:InputRange`}
              />
              <Text.HeadingFour
                role="button"
                tw="cursor-pointer"
                onClick={gallery.handleZoomIn}
                data-testid={`${testID ? `${testID}:` : ''}Gallery:ZoomIn`}
              >
                +
              </Text.HeadingFour>
            </div>
          </>
        )}
      </div>
    </Modal.WithClose>
  );
}
