// #region IMPORT
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  GetSchedulesApiRequest,
  GetSchedulesApiResponse,
  RemoveScheduleApiRequest,
  RemoveScheduleApiResponse,
  ScheduleParams,
  ScheduleResponse,
} from '../../../model/Schedule.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, MD, QD } from '../../api.baseQuery';
import { drivingContestTagTypes } from '../drivingContest/drivingContest.endpoint';
// #endregion

// #region INTERFACES
export type ScheduleEndpoint = {
  getScheduleList: QD<GetSchedulesApiRequest, GetSchedulesApiResponse>;
  addSchedule: MD<ScheduleParams, ScheduleResponse>;
  removeSchedule: MD<RemoveScheduleApiRequest, RemoveScheduleApiResponse>;
};
// #endregion

// #region ENDPOINT
export const scheduleTagTypes: EndpointTagTypes<ScheduleEndpoint> = {
  getScheduleList: 'schedule',
  addSchedule: '',
  removeSchedule: '',
};

export const scheduleEndpoint = (builder: Builder): ScheduleEndpoint => ({
  getScheduleList: builder.query({
    query: (param) => urlcat('/v1/drivers.vehicles.list', toSnakeCase(param)),
    providesTags: [scheduleTagTypes.getScheduleList],
  }),
  addSchedule: builder.mutation({
    query: (body) => ({
      url: '/v1/drivers.vehicles.assign',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      scheduleTagTypes.getScheduleList,
      drivingContestTagTypes.getDrivingContestList,
      drivingContestTagTypes.getDrivingContestCount,
    ],
  }),
  removeSchedule: builder.mutation({
    query: (body) => ({
      url: '/v1/drivers.vehicles.remove',
      body,
      method: 'post',
    }),
    invalidatesTags: [scheduleTagTypes.getScheduleList],
  }),
});
// #endregion
