import React from 'react';

export default function UploadExcel({
  fill = 'none',
  width = 37,
  height = 44,
  viewBox = '0 0 37 44',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#252525"
        fillRule="evenodd"
        d="M6 2h14v6a3 3 0 003 3h7v23a4 4 0 01-4 4H6a4 4 0 01-4-4V6a4 4 0 014-4zm26 32V10L21 0H6a6 6 0 00-6 6v28a6 6 0 006 6h20a6 6 0 006-6zM22 3.612L27.927 9H23a1 1 0 01-1-1V3.612zM25 17H7v2h18v-2zM7 23h18v2H7v-2zm18 6H7v2h18v-2z"
        clipRule="evenodd"
      />
      <rect width="16" height="16" x="21" y="27.5" fill="#F3532E" rx="8" />
      <path stroke="#fff" d="M29 32v7m0-7l-3.5 3.145M29 32l3.5 3.145" />
    </svg>
  );
}
