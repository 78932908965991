import { Locale } from './locale.config';

const locationListLocaleConfig: Locale[] = [
  {
    en: 'Add',
    id: 'Tambah',
  },
  {
    en: 'Location Name',
    id: 'Nama Lokasi',
  },
  {
    en: 'Address',
    id: 'Alamat',
  },
  {
    en: 'Type',
    id: 'Tipe',
  },
  {
    en: 'Name or Address',
    id: 'Lokasi atau Alamat',
  },
  {
    en: "You don't have any selected Delivery Locations that you want to remove",
    id: 'Anda tidak memiliki daftar Lokasi Pengiriman yang akan anda hapus',
  },
  {
    en: 'Try to select some Delivery Locations from Delivery Location page',
    id: 'Coba untuk memilih beberapa Lokasi Pengiriman dari halaman Lokasi Pengiriman',
  },
  {
    en: 'Go back to Delivery Location page',
    id: 'Kembali ke halaman Lokasi Pengiriman',
  },
  {
    en: 'You can search by Location Name, Address, or Shipper',
    id: 'Cari menggunakan Nama Lokasi, Alamat, atau Pengirim',
  },
];

export default locationListLocaleConfig;
