import { OverlayView } from '@react-google-maps/api';
import React from 'react';
import tw, { styled, theme } from 'twin.macro';
import { LatLng } from '../../../model/Map.model';
import { Icon, Text } from '../../atom';

type Props = {
  title: string;
  subtitle?: string;
  position: LatLng;
  isDropoff?: boolean;
};
const TooltipContainer = styled.div((props: { isMobile?: boolean }) => [
  tw`max-w-max rounded shadow bg-white py-1 px-2 mb-2 z-10 flex-col hidden (peer-hover:flex) relative transform[translateX(calc(-50% + 12px))]`,
  props?.isMobile && tw`visible`,
]);

const MarkerContainer = tw.div`w-6 h-6 cursor-pointer relative rounded-full bg-orange flex justify-center items-center `;

export default function MarkerDelivery({
  position,
  subtitle,
  title,
  isDropoff = false,
}: Props) {
  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div className="peer" tw="items-center relative ">
        <MarkerContainer className="peer" css={[isDropoff && tw`bg-white`]}>
          <div>
            <Icon.MapMarker
              fill={isDropoff ? theme`colors.black` : theme`colors.white`}
            />
          </div>
        </MarkerContainer>
        <TooltipContainer>
          <Text.BodyTwelve>{title}</Text.BodyTwelve>
          {subtitle && <Text.HeadingFive>{subtitle}</Text.HeadingFive>}
        </TooltipContainer>
      </div>
    </OverlayView>
  );
}
