import { useCallback, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store/store.app';
import { SupportedLanguage } from '../../config/locale/locale.config';
import useTranslator from '../useTranslator.hook';

const useHelpCenterBreadcrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const translator = useTranslator();

  const { isLoggedIn } = useSelector(
    (state: RootState) => state.session,
    shallowEqual,
  );
  const listRoute = useMemo(() => {
    const routes = location.pathname.split('/').slice(1);
    if (isMobile) return routes.filter((v) => v !== 'app-guides');

    return routes;
  }, [location.pathname]);
  const forceLang = !isLoggedIn ? SupportedLanguage.id : undefined;

  const handleClickBreadcrumb = useCallback(
    (idx: number, isLast?: boolean) => {
      if (isLast) return;
      const combinedRoute = listRoute.slice(0, idx + 1).join('/');

      navigate(`/${combinedRoute}`);
    },
    [listRoute, navigate],
  );
  return { translator, listRoute, forceLang, handleClickBreadcrumb };
};

export default useHelpCenterBreadcrumb;
