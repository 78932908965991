import React, { useState } from 'react';
import 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import useTranslator from '../../../hook/useTranslator.hook';
import { Button, Icon, Text } from '../../atom';
import { SearchInput } from '../../molecule';
export default function TrackingNumberNotFound({
  onSubmit,
  initialValue,
}: {
  initialValue?: string;
  onSubmit?: (data: string) => void;
}) {
  const translator = useTranslator();
  const [trackingCode, setTrackingCode] = useState(initialValue || '');
  return (
    <div tw="flex flex-col justify-center items-center h-screen text-center bg-white px-4">
      <Icon.TrackingNotFound />
      <Text.HeadingThree tw="whitespace-normal">
        {translator.translate(
          'Receipt Number is Not Found',
          SupportedLanguage.id,
        )}
      </Text.HeadingThree>
      <div tw="pt-2.5">
        <Text.BodyFourteen tw="whitespace-normal">
          {translator.translate(
            'Receipt number is not valid or already delivered. Please check the tracking number You entered.',
            SupportedLanguage.id,
          )}
        </Text.BodyFourteen>
      </div>
      {onSubmit && (
        <div tw="mt-5 overflow-hidden">
          <SearchInput
            tw="bg-transparent focus:bg-white rounded-t-md w-[320px]"
            placeholder={translator.translate(
              'Receipt Number',
              SupportedLanguage.id,
            )}
            value={trackingCode}
            changeData={setTrackingCode}
          />
          <Button.Solid onClick={() => onSubmit(trackingCode)} tw="mt-4 w-full">
            {translator.translate('Track Delivery', SupportedLanguage.id)}
          </Button.Solid>
        </div>
      )}
    </div>
  );
}
