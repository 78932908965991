import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterOrderManagementSORouteParam = DefaultRouteParam;

export const helpCenterOrderManagementSORoute: RouteConfig = {
  name: 'Help Center Order Management SO',
  Component: React.lazy(() => import('./HelpCenterOrderManagementSO.view')),
  path: '/help-center/order-management/shipper-order',
  isPrivate: false,
  breadcrumb: 'Help Center Order Management SO',
  options: {
    label: 'Shipper Order',
    testID: 'MenuHelpCenterOrderManagementSO',
  },
};
