import React, { ReactChild, ReactChildren, ReactElement } from 'react';
import tw from 'twin.macro';
import { SearchInput } from '..';
import { ExtraStyle } from '../../Type.component';
import { Button, Chip, Icon, IconButton, Text } from '../../atom';
import IndeterminateCheckbox from '../../atom/Checkbox/IndeterminateCheckbox.atom';

const ContainerWithChild = tw.div`rounded-t-md border-b-beige-lines pb-5`;
const Container = tw.div`rounded-t-md border-b-beige-lines flex flex-row pb-5 justify-between`;
const MetaTitle = tw.div`flex flex-row items-center`;
const RefreshButton = tw(Button.Outlined)`p-0`;
interface Props {
  actionLabel?: string;
  actionLabelStyle?: ExtraStyle;
  actionStyle?: ExtraStyle;
  containerStyle?: ExtraStyle;
  refreshStyle?: ExtraStyle;
  activeFilters?: string[];
  children?: ReactChild | ReactChildren;
  debouncedSearch?: string;
  filterChipLabel?: string;
  icon?: ReactElement;
  isAllChecked?: boolean;
  isActionDisabled?: boolean;
  isCheckedDisabled?: boolean;
  isIndeterminateChecked?: boolean;
  isRefreshing?: boolean;
  prefixActionButton?: ReactElement;
  search?: string;
  searchPlaceholder?: string;
  showFilter?: boolean;
  sortByChipLabel?: string;
  title: string;
  handleAction?(): void;
  handleChangeCheckbox?(): void;
  handleOpenFilter?(): void;
  handleSearch?(): void;
  handleRefresh?(): void;
  onBack?(): void;
}

export default function ContainerHeader({
  actionLabel,
  actionLabelStyle,
  actionStyle,
  activeFilters,
  children,
  debouncedSearch,
  filterChipLabel,
  icon,
  isActionDisabled,
  isAllChecked,
  isCheckedDisabled,
  isIndeterminateChecked,
  prefixActionButton,
  search,
  searchPlaceholder,
  showFilter,
  sortByChipLabel,
  title,
  handleSearch,
  handleChangeCheckbox,
  onBack,
  handleAction,
  handleOpenFilter,
  handleRefresh,
  containerStyle,
  refreshStyle,
  isRefreshing,
}: Props) {
  if (children) <ContainerWithChild>{children}</ContainerWithChild>;

  return (
    <Container css={containerStyle}>
      <MetaTitle css={[!onBack && tw`pl-6`]}>
        {onBack && (
          <IconButton onClick={onBack}>
            <Icon.ArrowBack fill="#F3532E" />
          </IconButton>
        )}
        {handleChangeCheckbox && (
          <IndeterminateCheckbox
            id="allCheckbox"
            name="allCheckbox"
            indeterminate={isIndeterminateChecked}
            disabled={isCheckedDisabled}
            checked={isAllChecked}
            onChange={handleChangeCheckbox}
          />
        )}
        <Text.HeadingFour tw="ml-2.5">{title}</Text.HeadingFour>

        {filterChipLabel && handleOpenFilter && (
          <Chip.Small
            tw="bg-transparent text-grey-two ml-5"
            label={filterChipLabel}
            left={<Icon.FilterV2 />}
            selected={showFilter}
            onClick={handleOpenFilter}
          />
        )}

        {sortByChipLabel && handleOpenFilter && (
          <Chip.Small
            tw="bg-orange-hover text-orange border-0 ml-5"
            title={sortByChipLabel}
            label={sortByChipLabel}
            left={<Icon.Sort />}
            onClick={handleOpenFilter}
          />
        )}

        {activeFilters && activeFilters.length > 0 && (
          <Chip.Small
            tw="bg-orange-hover text-orange border-0 ml-5 truncate max-width[225px]"
            title={activeFilters[0]}
            label={
              activeFilters[0].includes(':')
                ? activeFilters[0].split(':')[0]
                : activeFilters[0]
            }
            numberRight={
              activeFilters[0].includes(':')
                ? activeFilters[0].split(':')[1]
                : undefined
            }
            onClick={handleOpenFilter}
          />
        )}
        {activeFilters && activeFilters.length > 1 && (
          <Chip.Small
            tw="bg-orange-hover text-orange border-0 ml-5"
            label={`+${activeFilters.length - 1}`}
            onClick={handleOpenFilter}
          />
        )}
      </MetaTitle>

      {actionLabel && handleAction && (
        <Button.Solid
          css={actionStyle}
          disabled={isActionDisabled}
          onClick={handleAction}
          tw="rounded-md"
        >
          {icon}
          <Text.HeadingFour css={actionLabelStyle}>
            {actionLabel}
          </Text.HeadingFour>
          {prefixActionButton}
        </Button.Solid>
      )}
      {handleRefresh && (
        <RefreshButton
          css={refreshStyle}
          disabled={isRefreshing}
          onClick={handleRefresh}
          tw="rounded-md"
        >
          <Icon.Refresh2 svgStyle={[isRefreshing && tw`animate-spin`]} />
        </RefreshButton>
      )}
      {searchPlaceholder && handleSearch && (
        <SearchInput
          tw="bg-transparent focus:bg-white rounded-t-md"
          placeholder={searchPlaceholder}
          loading={search !== debouncedSearch}
          value={search}
          changeData={handleSearch}
        />
      )}
    </Container>
  );
}
