import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { format, fromUnixTime, getUnixTime } from 'date-fns';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import {
  ShipperOrderDetailTab,
  SOActivityListData,
  SOActivityType,
  SOStatus,
} from '../../../constant';
import { locationInitialValues } from '../../../constant/Location.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import {
  Goods,
  ShipperOrderInfo,
  SOActivitiesUpdateParam,
  SOActivitiesUpdateResponse,
  SOActivityDetail,
  SOUpdateActivitiesProps,
} from '../../../model/ShipperOrder.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { snackbarAction } from '../../../store/snackbar.store';
import { errorCodeToLabel } from '../../../util/error.util';
import { isFormChanged } from '../../../util/helper.util';
import { activityTypeToLabel } from '../../../util/shipperOrder.util';
import {
  getActivitiesSectionScheme,
  getActivityLocationLabelByType,
} from '../../../util/shipperOrderCreate.util';
import { ShipperOrderDetailRouteParam } from '../ShipperOrderDetail.route';

type Props = {
  soInfo?: ShipperOrderInfo;
  showConfirmationModal?: ShipperOrderDetailTab;
  setIsLoading: (val: boolean) => void;
  setIsCurrentSectionChanged: (val?: ShipperOrderDetailTab) => void;
  handleConfirmChangeSection: (val: ShipperOrderDetailTab) => void;
};
export type UseSODetailActivity = ReturnType<typeof useSODetailActivity>;

export default function useSODetailActivity({
  soInfo,
  showConfirmationModal,
  setIsLoading,
  setIsCurrentSectionChanged,
  handleConfirmChangeSection,
}: Props) {
  // #region GENERAL
  const translator = useTranslator();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const isFormEditable = useMemo(
    () =>
      soInfo?.status &&
      [SOStatus.IN_PROCESS, SOStatus.RESERVED].includes(soInfo?.status) &&
      soInfo?.deliveries.length <= 1,
    [soInfo?.deliveries.length, soInfo?.status],
  );

  const navigateParams = state as ShipperOrderDetailRouteParam;

  const navigationStateRef = useRef(navigateParams);

  const title = translator.translate('Activity');
  // #endregion

  // #region API CALL
  const [updateSOActivity, updateSOActivityResponse] =
    api.useUpdateSOActivitiesMutation();
  // #endregion

  // #region ACTION HANDLER
  const handleError = useCallback(
    (error: FetchBaseQueryError | SerializedError) => {
      if (!error) return;
      const data = (error as FetchBaseQueryError).data as ApiErrorResponse;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translator.translate(errorCodeToLabel(data.error.code)),
        }),
      );
    },
    [dispatch, translator],
  );
  const handleSuccessSOActivity = useCallback(
    (response: SOActivitiesUpdateResponse) => {
      if (!response || (response && !response.ok)) return;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.light,
          message: translator.translate('SO Successfully Updated.'),
        }),
      );
      if (showConfirmationModal)
        handleConfirmChangeSection(showConfirmationModal);
    },
    [dispatch, handleConfirmChangeSection, showConfirmationModal, translator],
  );
  // #endregion

  // #region FORM

  const formattedActivities = useMemo((): SOUpdateActivitiesProps[] => {
    if (!soInfo?.activities?.length) return [];
    return soInfo?.activities
      .slice()
      .sort((a, b) => a.index - b.index)
      .map((item, index): SOUpdateActivitiesProps => {
        const goodsItem =
          soInfo.activities[index].goods || item.soActivity?.goods;
        const formattedGoods = goodsItem?.map(
          (good): Goods => ({
            type: good.type,
            ...(good?.description && { description: good.description }),
            ...(good?.quantity && { quantity: good.quantity }),
            ...(good?.uom && { uom: good.uom }),
            ...(good?.volume && { volume: good.volume }),
            ...(good?.weight && { weight: good.weight }),
          }),
        );
        const locationData = item.location || item.soActivity?.location;
        const formattedLocation =
          locationData || soInfo.activities[index].location;

        return {
          index,
          location: formattedLocation || locationInitialValues,
          locationId: formattedLocation?.id || '',
          type:
            soInfo.activities[index].type ||
            item.type ||
            item.soActivity?.type ||
            SOActivityType.STAND_BY,
          expectedFinishAt:
            item.soActivity?.expectedFinishAt || item.expectedFinishAt,
          ...(formattedGoods && { goods: formattedGoods }),
        };
      });
  }, [soInfo?.activities]);

  const initialValues = useMemo(
    (): SOActivitiesUpdateParam => ({
      soId: soInfo?.id || '',
      activities:
        navigationStateRef.current?.activityState || formattedActivities || [],
    }),
    [formattedActivities, soInfo?.id],
  );
  const handleSubmitActivity = useCallback(
    async (values: SOActivitiesUpdateParam) => {
      const activitiesData = () => {
        if (!values?.activities) return [];
        if (soInfo?.isTransitable)
          return values.activities.map(
            (
              {
                location,
                goods,
                expectedFinishAt,
                ...rest
              }: SOUpdateActivitiesProps,
              index,
              arr,
            ) => ({
              ...rest,
              ...(expectedFinishAt && { expectedFinishAt }),
              ...(!!arr.length &&
                !!arr[0].goods?.length &&
                typeof index === 'number' && { goods: arr[0].goods }),
            }),
          );
        return values.activities.map(
          ({
            location,
            goods,
            expectedFinishAt,
            ...rest
          }: SOUpdateActivitiesProps) => ({
            ...rest,
            ...(expectedFinishAt && { expectedFinishAt }),
            ...(goods?.length && { goods }),
          }),
        );
      };
      try {
        setIsLoading(true);
        const formattedValues: SOActivitiesUpdateParam = {
          soId: values.soId,
          activities: activitiesData(),
        };
        const res = await updateSOActivity(formattedValues).unwrap();
        handleSuccessSOActivity(res);
      } catch (err) {
        handleError(err as FetchBaseQueryError);
        setIsLoading(false);
      } finally {
        if (navigationStateRef.current?.activityState) {
          navigationStateRef.current.activityState = undefined;
        }
        updateSOActivityResponse.reset();
        setIsLoading(false);
      }
    },
    [
      handleError,
      handleSuccessSOActivity,
      setIsLoading,
      soInfo?.isTransitable,
      updateSOActivity,
      updateSOActivityResponse,
    ],
  );

  const soActivityForm = useFormik<SOActivitiesUpdateParam>({
    initialValues,
    onSubmit: handleSubmitActivity,
    enableReinitialize: true,
    validationSchema: yup
      .object()
      .shape(getActivitiesSectionScheme(translator)),
  });

  const isActivityFormChanged = useMemo(() => {
    if (navigationStateRef.current?.activityState) {
      return isFormChanged<SOActivitiesUpdateParam>(
        { soId: soInfo?.id || '', activities: formattedActivities },
        {
          soId: soInfo?.id || '',
          activities: navigationStateRef.current?.activityState,
        },
      );
    }
    return isFormChanged<SOActivitiesUpdateParam>(soActivityForm.values, {
      soId: soInfo?.id || '',
      activities: formattedActivities,
    });
  }, [formattedActivities, soActivityForm.values, soInfo?.id]);

  useEffect(() => {
    setIsCurrentSectionChanged(
      isActivityFormChanged ? ShipperOrderDetailTab.ACTIVITY : undefined,
    );
  }, [isActivityFormChanged, setIsCurrentSectionChanged]);
  // #endregion

  // #region FORM ITEM

  const activityFormData: SOActivityListData[] =
    useMemo((): SOActivityListData[] => {
      if (!soInfo) return [];

      const data: SOActivityListData[] = soInfo.activities.map(
        (activity, idx): SOActivityListData => {
          const formattedGoods =
            soInfo.activities[idx].goods || activity.soActivity?.goods;
          const formattedGoodsOut =
            soInfo.activities[idx].goodsOut || activity.soActivity?.goodsOut;
          const activityType =
            soInfo.activities[idx].type ||
            activity.type ||
            activity.soActivity?.type;
          const formattedExpectedFinishAt =
            activity.soActivity?.expectedFinishAt || activity.expectedFinishAt;

          const formattedActivity: SOActivityDetail = {
            ...activity,
            expectedFinishAt: formattedExpectedFinishAt,
            completedAt: activity.completedAt ?? 0,
            location:
              activity.location ||
              activity.soActivity?.location ||
              locationInitialValues,
            type: activityType || SOActivityType.STAND_BY,
            goods: formattedGoods || [],
            goodsOut: formattedGoodsOut || [],
          };

          return {
            ...formattedActivity,
            header: [
              {
                id: '0',
                label: translator.translate('Activity Type'),
                value: activityTypeToLabel(activityType),
              },
              {
                id: '1',
                label: getActivityLocationLabelByType(activityType),
                value:
                  activity?.location?.name ||
                  activity.soActivity?.location?.name ||
                  '-',
              },
              {
                id: '2',
                label: translator.translate('Expected Time'),
                value: !formattedExpectedFinishAt
                  ? '-'
                  : format(
                      fromUnixTime(formattedExpectedFinishAt),
                      'dd MMM yyyy, hh:mm aaa',
                    ),
              },
            ],
          };
        },
      );

      return data;
    }, [soInfo, translator]);

  // #endregion

  // #region ACTION
  const handleRemoveActivity = useCallback(
    async (index) => {
      await soActivityForm.setFieldValue(
        'activities',
        soActivityForm.values.activities
          .filter((_, j) => index !== j)
          .map((data, idx) => ({ ...data, index: idx })),
      );
      await soActivityForm.setTouched({
        ...soActivityForm.touched,
        activities: soActivityForm.touched.activities?.length
          ? [
              ...soActivityForm.touched.activities.filter(
                (_, j) => index !== j,
              ),
              {
                type: true,
              },
            ]
          : [{ type: true }],
      });
    },
    [soActivityForm],
  );

  const handleDuplicateActivity = useCallback(
    async (index) => {
      const duplicatedValue = soActivityForm.values.activities?.find(
        (_, j) => index === j,
      );
      const formattedArr = duplicatedValue ? [duplicatedValue] : [];
      await soActivityForm.setFieldValue(
        'activities',
        [...soActivityForm.values.activities, ...formattedArr].map(
          (data, idx) => ({
            ...data,
            index: idx,
          }),
        ),
      );
      await soActivityForm.setTouched({
        ...soActivityForm.touched,
        activities: soActivityForm.touched.activities?.length
          ? [
              ...soActivityForm.touched.activities,
              {
                type: true,
              },
            ]
          : [{ type: true }],
      });
    },
    [soActivityForm],
  );

  const handleRemoveLocation = useCallback(
    (index) => {
      void soActivityForm.setValues({
        ...soActivityForm.values,
        activities: soActivityForm.values?.activities?.map((item, i) =>
          i === index
            ? { ...item, location: undefined, locationId: undefined }
            : item,
        ),
      });
    },
    [soActivityForm],
  );

  const handleAddMoreActivity = useCallback(async () => {
    await soActivityForm.setFieldValue('activities', [
      ...soActivityForm.values.activities,
      {
        index: soActivityForm.values.activities.length,
        expectedFinishAt: undefined,
        locationId: undefined,
        type: SOActivityType.STAND_BY,
        goods: [],
      },
    ]);
    await soActivityForm.setTouched({
      ...soActivityForm.touched,
      activities: soActivityForm.touched.activities?.length
        ? [
            ...soActivityForm.touched.activities,
            {
              type: true,
            },
          ]
        : [{ type: true }],
    });
  }, [soActivityForm]);

  const handleClickChoice = useCallback(
    async (index: number, currentChoice: SOActivityType) => {
      await soActivityForm.setFieldValue(
        `activities.[${index}].type`,
        currentChoice,
      );
      await soActivityForm.setFieldTouched(`activities.[${index}].type`, true);
      if (currentChoice === SOActivityType.STAND_BY)
        await soActivityForm.setFieldValue(`activities.[${index}].goods`, []);
    },
    [soActivityForm],
  );

  const handleChangeDateActivity = useCallback(
    async (index: number, date?: Date, isTransit?: boolean) => {
      await soActivityForm.setFieldTouched(
        `activities.[${index}].expectedFinishAt`,
        true,
      );
      await soActivityForm.setFieldValue(
        `activities.[${index}].expectedFinishAt`,
        date ? getUnixTime(date) : undefined,
      );
      if (isTransit && index === 0)
        await soActivityForm.setFieldValue(
          'activities.[1].expectedFinishAt',
          undefined,
        );
    },
    [soActivityForm],
  );
  // #endregion

  return {
    title,
    isFormEditable,
    isActivityFormChanged,
    soActivityForm,
    activityFormData,
    handleDuplicateActivity,
    handleRemoveActivity,
    handleRemoveLocation,
    handleAddMoreActivity,
    handleClickChoice,
    handleChangeDateActivity,
  };
}
