import React from 'react';
import tw, { styled } from 'twin.macro';
import { SOPaymentStatus } from '../../../model/ShipperOrder.model';
import { Text } from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region INTERFACES
type PaymentStatusProps = { status: SOPaymentStatus };
type ShipperOrderPaymentStatusProps = {
  status: SOPaymentStatus;
  statusLabel: string;
  containerStyle?: ExtraStyle | React.CSSProperties | null;
};
// #endregion

// #region STYLED
const StatusContainer = styled.div(({ status }: PaymentStatusProps) => [
  tw`px-2 py-0.5 inline-block font-bold rounded-md`,
  status === SOPaymentStatus.UNPAID && tw`bg-deleted-light`,
  status === SOPaymentStatus.PAID && tw`bg-delivered-light`,
]);
const SquareIcon = styled.div(({ status }: PaymentStatusProps) => [
  tw`inline-block rounded-sm width[10px] height[10px] mr-2`,
  status === SOPaymentStatus.UNPAID && tw`bg-deleted-dark`,
  status === SOPaymentStatus.PAID && tw`bg-delivered-dark`,
]);
// #endregion

function ShipperOrderPaymentStatus({
  status,
  statusLabel,
  containerStyle,
}: ShipperOrderPaymentStatusProps) {
  return (
    <StatusContainer status={status} css={containerStyle}>
      <SquareIcon status={status} />

      <Text.BodyTwo
        css={[
          status === SOPaymentStatus.UNPAID && tw`text-deleted-dark`,
          status === SOPaymentStatus.PAID && tw`text-delivered-dark`,
        ]}
      >
        {statusLabel}
      </Text.BodyTwo>
    </StatusContainer>
  );
}

export default ShipperOrderPaymentStatus;
