import { VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import fromUnixTime from 'date-fns/fromUnixTime';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { JobOrder } from '../../../model/JobOrder.model';
import { getDateFormat } from '../../../util/date.util';
import { numberFormatter } from '../../../util/formatter.util';
import { mapJOStatusToLabel } from '../../../util/jobOrder.util';
import {
  jobOrderDetailRoute,
  JobOrderDetailRouteParam,
} from '../../../view/JobOrderDetail/JobOrderDetail.route';
import { Text } from '../../atom';
import { JobOrderStatus, VirtualItemLoaderRow } from '../../molecule';

// #region STYLED
const Container = tw.div``;
// #endregion

// #region INTERFACES
interface Props {
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  virtualItem: VirtualItem;
  hasNextPage: boolean;
  list: JobOrder[];
  translator: UseTranslator;
  searchParamsFromJOList: JobOrderDetailRouteParam['searchParamsFromJOList'];
}
// #endregion

export default function JobOrderVirtualItem({
  virtualizer,
  virtualItem,
  hasNextPage,
  list,
  translator,
  searchParamsFromJOList,
}: Props) {
  const navigate = useNavigate();
  const isLoaderRow = virtualItem.index > list.length - 1;
  const jo = list[virtualItem.index];
  const navigationState: JobOrderDetailRouteParam = {
    searchParamsFromJOList,
    status: jo?.status,
  };

  const height = virtualItem.size;
  const translateY =
    virtualItem.index === 0
      ? virtualItem.start
      : virtualItem.start + virtualItem.index * 12;

  return (
    <div
      tw="flex flex-col w-full p-4 rounded-md bg-white absolute top-0 left-0 shadow-card"
      ref={virtualizer.measureElement}
      data-index={virtualItem.index}
      key={virtualItem.key}
      style={{
        height: `${height}px`,
        transform: `translateY(${translateY}px)`,
      }}
    >
      {isLoaderRow ? (
        <VirtualItemLoaderRow
          hasNextPage={hasNextPage}
          size={virtualItem.size}
          translator={translator}
        />
      ) : (
        <Container
          onClick={() =>
            navigate(
              `${jobOrderDetailRoute.path.replace(':id', jo.id)}?joNumber=${
                jo.number
              }`,
              {
                state: navigationState,
              },
            )
          }
        >
          <div tw="flex justify-between items-center">
            <div tw="flex flex-col space-y-0.5 max-width[50%]">
              <div tw="text-orange truncate font-sans font-semibold text-base">
                {jo.number}
              </div>
              <Text.BodyTwelve tw="text-grey-three">
                {getDateFormat(fromUnixTime(jo.date))}
              </Text.BodyTwelve>
            </div>

            <JobOrderStatus
              status={jo.status}
              statusLabel={translator.translate(mapJOStatusToLabel(jo.status))}
            />
          </div>

          <hr tw="text-grey-six my-2" />

          <div tw="flex justify-between pb-1">
            <div tw="flex flex-col space-y-0.5">
              <Text.BodyTwelve tw="text-grey-three">
                {translator.translate('Driver Name')}
              </Text.BodyTwelve>
              <Text.BodySixteen tw="max-w-[40vw] truncate">
                {jo?.driver?.fullName ? jo.driver.fullName : '-'}
              </Text.BodySixteen>
            </div>

            <div tw="flex flex-col space-y-0.5">
              <Text.BodyTwelve tw="text-grey-three text-right">
                {translator.translate('Travel Budget')}
              </Text.BodyTwelve>
              <Text.BodySixteen tw="text-right">
                {jo.travelExpense
                  ? `Rp${numberFormatter(jo.travelExpense)}`
                  : '-'}
              </Text.BodySixteen>
            </div>
          </div>

          <div tw="flex">
            <div tw="flex flex-col space-y-0.5">
              <Text.BodyTwelve tw="text-grey-three">
                {translator.translate('Vehicle')}
              </Text.BodyTwelve>
              <Text.BodySixteen tw="max-width[50vw] truncate">
                {jo?.vehicle?.name ? jo.vehicle.name : '-'}
              </Text.BodySixteen>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}
