import {
  HaulingOrderDeliveryAssignmentStatusEnum,
  HaulingOrderDeliveryTargetUnitEnum,
  HaulingOrderStatusEnum,
} from './HaulingOrder.model';

export enum ReportHaulingOrderFilterOrderByEnum {
  DATE_ASC = 'DATE_ASC', // HO Date A-Z
  DATE_DESC = 'DATE_DESC', // HO Date Z-A
  DURATION_ASC = 'DURATION_ASC', // Delivery Duration Time A-Z
  DURATION_DESC = 'DURATION_DESC', // Delivery Duration Time Z-A
  DELIVERED_TIME_ASC = 'DELIVERED_TIME_ASC', // Delivered Time A-Z
  DELIVERED_TIME_DESC = 'DELIVERED_TIME_DESC', // Delivered Time Z-A
  UPDATE_ASC = 'UPDATE_ASC', // Oldest Updates
  UPDATE_DESC = 'UPDATE_DESC', // Newest Updates
  GOODS_TARGET_ASC = 'GOODS_TARGET_ASC', // Goods Target A-Z
  GOODS_TARGET_DESC = 'GOODS_TARGET_DESC', // Goods Target Z-A
}

export type ReportHaulingOrderDropoff = {
  locationId: string;
  locationName: string;
  locationAddress: string;
};

export type ReportHaulingOrderPickup = ReportHaulingOrderDropoff & {
  goods: string | null;
  notes: string | null;
};

export type ReportHaulingOrderDeliveryDuration = {
  days: number;
  hours: number;
  minutes: number;
};

export type ReportHaulingOrderDeliveryAssignment = {
  assignmentId: string;
  assignmentNumber: string;
  status: HaulingOrderDeliveryAssignmentStatusEnum;
  statusText: keyof typeof HaulingOrderDeliveryAssignmentStatusEnum;
  driverId: string;
  driverName: string;
  vehicleId: string;
  vehicleName: string;
  dropoff: ReportHaulingOrderDropoff;
  hoDeliveryId: string;
  notes: string;
  startedAt: number | null;
  completedAt: number | null;
  completedBy: string;
  goodsIn: number;
  goodsOut: number;
  goodsUnit: HaulingOrderDeliveryTargetUnitEnum;
};

export type ReportHaulingOrder = {
  id: string;
  hoNumber: string;
  referenceNumber: string | null;
  date: number; // seconds
  status: HaulingOrderStatusEnum;
  statusText: keyof typeof HaulingOrderStatusEnum;
  shipperId: string;
  shipperName: string;
  deliveryDuration: ReportHaulingOrderDeliveryDuration | null;
  deliveredTime: number | null; // seconds
  pickup: ReportHaulingOrderPickup;
  totalTarget: number;
  totalCompletedTarget: number;
  completedPercentage?: number | null;
  deliveryAssignments: ReportHaulingOrderDeliveryAssignment[];
};

export type ReportHaulingOrderSearchParam = {
  page?: string;
  pageSize?: string;
  search?: string;
  orderBy?: string; // string of ReportHaulingOrderFilterOrderByEnum collection
  showDelivery?: string; // 0 | 1
  status?: string; // string of HaulingOrderStatusEnum collection
  shipperId?: string;
  startDate?: string; // milliseconds
  endDate?: string; // milliseconds
};
