import { orderBy } from 'lodash';
import { TimelineContentPhoto } from '../../component/molecule/TimelineContentItem/TimelineContentItem.type';
import { SupportedLanguage } from '../../config/locale/locale.config';
import { UseTranslator } from '../../hook/useTranslator.hook';
import { HaulingOrderDeliveryTargetUnitEnum } from '../../model/HaulingOrder.model';
import {
  HaulingOrderTracking,
  HOIndexedTimeline,
  HOTimeline,
  HOTimelineDataPhoto,
  HOTimelinePhoto,
  HOTimelineType,
} from '../../model/HaulingOrderTracking.model';
import {
  SOIndexedTimeline,
  SOTracking,
  SOTrackingActivity,
  SOTrackingActivityType,
  Timeline,
  TimelineDataLocation,
  TimelineDataPhoto,
  TimelineType,
} from '../../model/ShipperOrder.model';
import {
  HOTrackingType,
  SOTrackingType,
  TrackingDataType,
} from '../../model/TrackingOrder.model';
import { numberFormatter } from '../formatter.util';

export const getSOLabelByType = (type: TimelineType) => {
  const text: Record<string, string> = {
    [TimelineType.DROP_OFF]: 'Arrive at Dropoff Location: %s',
    [TimelineType.DROP_OFF_STARTED]: 'Arrive at Dropoff Location: %s',
    [TimelineType.DROP_OFF_COMPLETED]: 'Finish dropping off goods at %s',
    [TimelineType.PICK_UP]: 'Arrive at Pickup Location: %s',
    [TimelineType.PICK_UP_STARTED]: 'Arrive at Pickup Location: %s',
    [TimelineType.PICK_UP_COMPLETED]: 'Finish picking up goods at %s',
    [TimelineType.PROOF_OF_ACTIVITY_ADDED]: 'Proof of activity uploaded',
    [TimelineType.NOTES_ADDED]: 'Notes has been added',
    [TimelineType.STAND_BY]: 'Standby',
    [TimelineType.STAND_BY_STARTED]: 'Arrive at standby location: %s',
    [TimelineType.STAND_BY_COMPLETED]: 'Standby activity completed: %s',
    [TimelineType.TRANSITED]: 'Standby completed',
    [TimelineType.TRANSIT_ACTIVITY_COMPLETED]:
      'Depart from transit location: %s',
    [TimelineType.TRANSIT_ACTIVITY_STARTED]: 'Arrive at transit location: %s',
    [TimelineType.PICK_UP_TRANSIT_STARTED]: 'Arrive at transit location: %s',
    [TimelineType.PICK_UP_TRANSIT_COMPLETED]:
      'Depart from transit location: %s',
    [TimelineType.DROP_OFF_TRANSIT_STARTED]: 'Arrive at transit location: %s',
    [TimelineType.DROP_OFF_TRANSIT_COMPLETED]:
      'Depart from transit location: %s',
    [TimelineType.ACTIVITY_STARTED]: '',
    // Last mile
    [TimelineType.DELIVERING]: 'Delivery Order is started',
    [TimelineType.IN_PROCESS]: 'Order is received',
    [TimelineType.DELIVERED]: 'Order is completed',
    [TimelineType.IN_TRANSIT]: 'Arrive at transit location: %s',
    [TimelineType.TRANSITING]: 'Towards pickup location: %s',
    [TimelineType.GEOFENCE_ENTER]: 'Arrive at Pickup Location: %s',
    [TimelineType.GEOFENCE_EXIT]: 'Finish picking up goods at %s',
  };
  return text[type];
};

export const getHOLabelByType = (type: HOTimelineType) => {
  const text: Record<string, string> = {
    [HOTimelineType.IN_PROCESS]: 'Order is received',
    [HOTimelineType.DELIVERING]: 'Delivery order is started',
    [HOTimelineType.DELIVERY_ASSIGNMENT_COMPLETED]:
      'Driver: %s has finished delivering %s to %s',
    [HOTimelineType.DELIVERY_COMPLETED]:
      'Finish dropping off %s of total %s to %s',
    [HOTimelineType.PROOF_OF_DELIVERY_SUBMITTED]: 'Proof of delivery submitted',
    [HOTimelineType.COMPLETED]: 'Order is completed',
  };
  return text[type];
};

/**
 * @deprecated use formatText from formatter.util.tsx
 * Format string with arguments, param `replacements` will replace occurence(s) of %s in the `text` in order
 * @example
 * formatText("%s is barking", "Dog")
 * // Dog is barking
 *
 * formatText("%s is barking, %s is meowing", "Dog", "Cat")
 * // Dog is barking, Cat is meowing
 * @param text
 * @param replacements
 * @returns
 */
export const formatText = (text: string, ...replacements: string[]) => {
  const stringifiedReplacements = replacements.map((v) =>
    typeof v === 'number' ? (v as unknown as number).toString() : v,
  );
  if (stringifiedReplacements.length > 0)
    return text.replace(/%s/g, () => stringifiedReplacements.shift() || '%s');

  return text;
};

/**
 * Get tracking timelinelabel based on type. The location name(s) should be in correct order if occur more than one.
 * If the result is not as expected, ensure add %s as identifier in the translation text for replacement.
 * @example
 * `Arrive at Pickup Location: %s`
 * getTrackingTimelineLabel(TimelineType.PICKUP, translate, 'Lokasi 1')
 * // result: Arrive at Pickup Location: Lokasi 1
 *
 * For multiple replacement add zero based index number in every last word, e.g:
 * `Finish picking up goods at %s0. Towards transit location: %s1`
 * getTrackingTimelineLabel( TimelineType.PICK_UP_COMPLETED, translate, "Lokasi 1", "Lokasi 2")
 * // result: Finish picking up goods at Lokasi 1. Towards transit location: Lokasi 2
 *
 * @param type
 * @param translate the translator function
 * @param replacements array of string to replace the %s text
 * @returns
 *
 */
export const getTrackingTimelineLabel = (
  {
    translate,
    type,
    forceLang,
  }: {
    type: TimelineType;
    translate: (text: string, forceLang?: SupportedLanguage) => string;
    forceLang?: SupportedLanguage;
  },
  ...replacements: string[]
) => {
  if (replacements.length > 0) {
    return formatText(
      translate(getSOLabelByType(type), forceLang || undefined),
      ...replacements,
    );
  }

  return translate(getSOLabelByType(type), forceLang || undefined);
};
export const getGoodsUnitByEnum = (
  type: HaulingOrderDeliveryTargetUnitEnum,
) => {
  const obj: Record<HaulingOrderDeliveryTargetUnitEnum, string> = {
    [HaulingOrderDeliveryTargetUnitEnum.QUANTITY]: 'Unit',
    [HaulingOrderDeliveryTargetUnitEnum.VOLUME]: 'm³',
    [HaulingOrderDeliveryTargetUnitEnum.WEIGHT]: 'kg',
  };

  return obj[type];
};
export const getHOTrackingTimelineLabel = (
  {
    translate,
    type,
    forceLang,
  }: {
    type: HOTimelineType;
    forceLang?: SupportedLanguage;
    translate: (text: string, forceLang?: SupportedLanguage) => string;
  },
  ...replacements: string[]
) => {
  if (replacements.length > 0) {
    return formatText(
      translate(getHOLabelByType(type), forceLang || undefined),
      ...replacements,
    );
  }
  return translate(getHOLabelByType(type), forceLang || undefined);
};

export const getGoodsDetailLabel = (type: SOTrackingActivityType) => {
  const obj = {
    [SOTrackingActivityType.DROP_OFF]: 'Dropoff at %s',
    [SOTrackingActivityType.PICK_UP]: 'Pickup at %s',
    [SOTrackingActivityType.STAND_BY]: 'Standby at %s',
  };

  return obj[type];
};

/**
 * Quite complicated this one
 * @param timelines
 * @returns
 */
export const remapSOTimelines = (
  timelines: Timeline[],
): SOIndexedTimeline[] => {
  /**
   * sometimes timelines comes without proper orders as workaround
   * we need to re-order in the client side by adding index to it and later sorting it
   * so that will make life easier
   */
  const indexedTimelines: SOIndexedTimeline[] = timelines.map((v, index) => ({
    ...v,
    index,
  }));

  /**
   * Transit and lastmile has different rules and we cant rely to `isTransitable` only
   * in addition we checked the IN_TRANSIT or TRANSIT_ACTIVITY_STARTED are exist in the timelines object since these types
   * only appear in transit delivery
   */
  const hasTransitTimeline =
    indexedTimelines.findIndex((tl) => tl.type === TimelineType.IN_TRANSIT) !==
      -1 ||
    indexedTimelines.findIndex(
      (tl) => tl.type === TimelineType.TRANSIT_ACTIVITY_STARTED,
    ) !== -1;

  const transitingTimelines: SOIndexedTimeline[] = [];
  const pickupTimelines: SOIndexedTimeline[] = [];
  const deliveredTimelines: SOIndexedTimeline[] = [];
  const transitActivityCompletedPickupTimelines: SOIndexedTimeline[] = [];

  /**
   * Any timelines which are allowed to be rendered without particular condition
   * it related to `excludedTimelineTypes`
   */
  const safeToRenderTimelines: SOIndexedTimeline[] = [];

  const excludedTimelineTypes = [
    /**
     * TRANSITING, PICK_UP_STARTED are only allowed to rendered once so
     * note: will be appended to `safeToRenderTimelines`
     */
    TimelineType.TRANSITING,
    TimelineType.PICK_UP_STARTED,
    /**
     * DELIVERED should be placed to the end of the cycle but in real case
     * this could be placed between others timeline so first we exclude them
     * note: will be appended to `safeToRenderTimelines`
     */
    TimelineType.DELIVERED,
    /**
     * BE already transform GEOFENCE_EXIT and GEOFENCE_ENTER types to particular timeline types
     * what we want is completely removed these types from render.
     * ACTIVITY_STARTED we dont need it
     */
    TimelineType.GEOFENCE_EXIT,
    TimelineType.GEOFENCE_ENTER,
    TimelineType.ACTIVITY_STARTED,
  ];

  if (hasTransitTimeline)
    excludedTimelineTypes.push(
      // In so transit we dont want to render the delivering type
      TimelineType.DELIVERING,
      // We also want to pick certain transit activity completed type
      TimelineType.TRANSIT_ACTIVITY_COMPLETED,
    );

  /**
   * Each grouped timelines are meant to be rendered once or there should be certain condition
   * therefore we need to grouped it then only expose selected element of each group
   */
  for (const timeline of indexedTimelines) {
    if (timeline.type === TimelineType.TRANSITING)
      transitingTimelines.push(timeline);
    if (timeline.type === TimelineType.PICK_UP_STARTED)
      pickupTimelines.push(timeline);
    if (timeline.type === TimelineType.DELIVERED)
      deliveredTimelines.push(timeline);
    if (timeline.type === TimelineType.TRANSIT_ACTIVITY_COMPLETED)
      transitActivityCompletedPickupTimelines.push(timeline);

    if (!excludedTimelineTypes.includes(timeline.type))
      safeToRenderTimelines.push(timeline);
  }

  // Take item from grouped timelines based on criteria
  // TRANSITING and PICK_UP type only allowed to rendered once, the first item, the timelines object
  // could have multiple item of these type
  const transitingTimeline = transitingTimelines.shift();
  const pickupTimeline = pickupTimelines.shift();

  // TRANSIT_ACTIVITY_COMPLETED should be only render when the location activity type is PICK_UP
  const transitActivityCompletedPickupTimeline =
    transitActivityCompletedPickupTimelines.filter(
      (v) =>
        (v.data as TimelineDataLocation).location.activityType ===
        SOTrackingActivityType.PICK_UP,
    );

  // Append the shifted element to the filteredTimelines
  if (transitingTimeline) safeToRenderTimelines.push(transitingTimeline);
  if (pickupTimeline) safeToRenderTimelines.push(pickupTimeline);
  if (transitActivityCompletedPickupTimeline)
    safeToRenderTimelines.push(...transitActivityCompletedPickupTimeline);

  return [
    ...deliveredTimelines,
    ...orderBy([...safeToRenderTimelines], ['index'], 'desc'),
  ];
};

export const remapHOTimelines = (
  timelines: HOTimeline[],
): HOIndexedTimeline[] => {
  const indexedTimelines: HOIndexedTimeline[] = timelines.map((v, index) => ({
    ...v,
    index,
  }));
  return indexedTimelines;
};

export const getSOTrackingPOAPhotos = (
  data: TimelineDataPhoto,
): TimelineContentPhoto[] =>
  data.photos.map(({ id, url }) => ({
    url,
    id,
    activityId: (data as TimelineDataPhoto).soActivityId,
    type: 'so',
  }));

export const getHOTrackingPOAPhotos = (
  data: HOTimelineDataPhoto,
): TimelineContentPhoto[] => data.photos.map((v) => ({ ...v, type: 'ho' }));

export const soTimelinePhotoActivitySelector = (photo: TimelineContentPhoto) =>
  photo.type === 'so' ? photo.activityId || '' : '';

export const hoTimelinePhotoSelector =
  ({
    id,
    createdAt,
    caption,
  }: { id: string; createdAt?: number; caption?: string }) =>
  (photo: HOTimelinePhoto) => ({
    accessUrl: photo.url,
    createdAt: createdAt || 0,
    id,
    fileName: '',
    mimeType: 'jpeg',
    size: 0,
    caption,
  });

export const getTrackingGalleryTitle = (
  translator: UseTranslator,
  activity?: SOTrackingActivity,
  forceLang?: SupportedLanguage,
) => {
  if (!activity) return '';
  const obj = {
    [SOTrackingActivityType.PICK_UP]: formatText(
      translator.translate('Pickup at %s', forceLang),
      activity.locationName,
    ),
    [SOTrackingActivityType.DROP_OFF]: formatText(
      translator.translate('Dropoff at %s', forceLang),
      activity.locationName,
    ),
    [SOTrackingActivityType.STAND_BY]: formatText(
      translator.translate('Standby at %s', forceLang),
      activity.locationName,
    ),
  };

  return obj[activity.type];
};

export const getSOTimelineLocationName = (v: SOIndexedTimeline) =>
  [
    TimelineType.DROP_OFF,
    TimelineType.STAND_BY,
    TimelineType.PICK_UP,
    TimelineType.STAND_BY_COMPLETED,
    TimelineType.PICK_UP_COMPLETED,
    TimelineType.DROP_OFF_STARTED,
    TimelineType.DROP_OFF_COMPLETED,
    TimelineType.IN_TRANSIT,
    TimelineType.TRANSIT_ACTIVITY_COMPLETED,
    TimelineType.TRANSIT_ACTIVITY_STARTED,
    TimelineType.TRANSITED,
    TimelineType.TRANSITING,
    TimelineType.PICK_UP_STARTED,
    TimelineType.STAND_BY_STARTED,
    TimelineType.GEOFENCE_ENTER,
    TimelineType.GEOFENCE_EXIT,
    TimelineType.PICK_UP_TRANSIT_STARTED,
    TimelineType.PICK_UP_TRANSIT_COMPLETED,
    TimelineType.DROP_OFF_TRANSIT_STARTED,
    TimelineType.DROP_OFF_TRANSIT_COMPLETED,
  ].includes(v.type) && v.data
    ? (v.data as TimelineDataLocation).location.name
    : '';

export const getHOTimelineLocationName = (v: HOIndexedTimeline) =>
  [
    HOTimelineType.IN_PROCESS,
    HOTimelineType.DELIVERING,
    HOTimelineType.DELIVERY_ASSIGNMENT_COMPLETED,
    HOTimelineType.DELIVERY_COMPLETED,
    HOTimelineType.COMPLETED,
    HOTimelineType.PROOF_OF_DELIVERY_SUBMITTED,
  ].includes(v.type) && v.data
    ? v.data.locationName
    : '';

export const getHOTimelinePlaceholderReplacements = (v: HOIndexedTimeline) => {
  if (v.type === HOTimelineType.DELIVERY_ASSIGNMENT_COMPLETED) {
    return [
      v.data.driverName,
      `${numberFormatter(v.data.goodsOut)} ${getGoodsUnitByEnum(
        v.data.goodsUnit,
      )}`,
      v.data.locationName,
    ];
  }

  if (v.type === HOTimelineType.DELIVERY_COMPLETED) {
    return [
      `${numberFormatter(v.data.goodsOut)} ${getGoodsUnitByEnum(
        v.data.goodsUnit,
      )}`,
      `${numberFormatter(v.data.target)} ${getGoodsUnitByEnum(
        v.data.goodsUnit,
      )}`,
      v.data.locationName,
    ];
  }

  return [];
};
export const getTimelinePhoto = (v: SOIndexedTimeline) =>
  [
    TimelineType.PROOF_OF_ACTIVITY_ADDED,
    TimelineType.PICK_UP_COMPLETED,
    TimelineType.DROP_OFF_COMPLETED,
    TimelineType.STAND_BY_COMPLETED,
    TimelineType.TRANSIT_ACTIVITY_COMPLETED,
  ].includes(v.type) && v.data
    ? ({ ...v.data, createdAt: v.time } as TimelineDataPhoto)
    : undefined;

export const getHOTimelinePhoto = (v: HOIndexedTimeline) => {
  if (v.type === HOTimelineType.DELIVERY_ASSIGNMENT_COMPLETED && v.data) {
    return {
      createdAt: v.time,
      photos: v.data.photos,
      caption: v.data.podNotes,
      type: 'ho',
    } as HOTimelineDataPhoto;
  }

  return undefined;
};

export const convertTrackingToSOTrackingType = (
  tracking: SOTracking,
): SOTrackingType => ({
  ...tracking,
  type: 'so',
});

export const convertTrackingToHOTrackingType = (
  tracking: HaulingOrderTracking,
): HOTrackingType => ({
  ...tracking,
  type: 'ho',
});

export const getTimelineTitleReplacements = ({
  data,
  timeline,
}: {
  data: TrackingDataType;
  timeline: SOIndexedTimeline | HOIndexedTimeline;
}) =>
  data.type === 'so'
    ? getSOTimelineLocationName(timeline as SOIndexedTimeline)
    : getHOTimelinePlaceholderReplacements(timeline as HOIndexedTimeline);

export const getTimelinePhotos = ({
  data,
  timeline,
}: {
  data: TrackingDataType;
  timeline: SOIndexedTimeline | HOIndexedTimeline;
}) => {
  return data.type === 'so'
    ? getTimelinePhoto(timeline as SOIndexedTimeline)
    : getHOTimelinePhoto(timeline as HOIndexedTimeline);
};

export const getTimelineTitle = ({
  data,
  timeline,
  translator,
  forceLang,
  replacementWords,
}: {
  data: TrackingDataType;
  translator: UseTranslator;
  timeline: SOIndexedTimeline | HOIndexedTimeline;
  forceLang?: SupportedLanguage;
  replacementWords?: string | string[];
}) => {
  if (data.type === 'so') {
    return formatText(
      translator.translate(
        getSOLabelByType((timeline as SOIndexedTimeline).type),
        forceLang,
      ),
      ...(replacementWords || []),
    );
  }
  return formatText(
    translator.translate(
      getHOLabelByType((timeline as HOIndexedTimeline).type),
      forceLang,
    ),
    ...(replacementWords || []),
  );
};

export const getSOTimelineCustomGalleryTitle =
  ({
    activities,
    translator,
    forceLang,
  }: {
    activities: SOTrackingActivity[];
    translator: UseTranslator;
    forceLang?: SupportedLanguage;
  }) =>
  (title?: string) =>
    [
      title
        ? getTrackingGalleryTitle(
            translator,
            activities.find((activity) => activity.id === title),
            forceLang,
          )
        : translator.translate('Proof of activity uploaded', forceLang),
      translator.translate('Proof of Activity', forceLang),
    ].join(' • ');
