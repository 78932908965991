import React from 'react';

type Props = React.SVGProps<SVGCircleElement> & {
  innerCircleR?: string;
  pathFill?: string;
};
export default function CircleRadioSelected({
  cx = '9',
  cy = '9',
  fill = 'none',
  height = 18,
  innerCircleR = '5.25',
  pathFill = 'currentColor',
  r = '5.25',
  stroke = 'currentColor',
  strokeWidth = 2,
  viewBox = '0 0 18 18',
  width = 18,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <g clipPath="url(#clip0_4212_7686)">
        <circle
          cx={cx}
          cy={cy}
          r={r}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
        <circle cx={cx} cy={cy} r={innerCircleR} fill={stroke} />
      </g>
      <defs>
        <clipPath id="clip0_4212_7686">
          <path fill={pathFill} d="M0 0H18V18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
