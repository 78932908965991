import { Placement } from '@popperjs/core';
import format from 'date-fns/format';
import React, { createRef, MutableRefObject, useState } from 'react';
import { Modifier } from 'react-day-picker';
import tw, { styled } from 'twin.macro';
import { DateRangePicker, TextField } from '..';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import { Icon, IconButton, OutsideAlerter, Popover, Portal } from '../../atom';
import { RangeYear } from '../DatePicker/DatePicker.molecule';
import {
  CurrentSelection,
  DefaultDateRangeValue,
  DefinedRangeOption,
} from '../DateRangePicker/DateRangePicker.molecule';

// #region INTERFACES
export type DateRangePickerInputProps = {
  readonly sdDateValue: Date | undefined;
  sdOnChangeDateValue?(date?: Date): void;
  readonly edDateValue: Date | undefined;
  edOnChangeDateValue?(date?: Date): void;

  readonly sdId?: string;
  readonly sdName?: string;
  readonly sdLabel?: string;
  readonly sdDisabled?: boolean;
  readonly sdError?: string;
  readonly edId?: string;
  readonly edName?: string;
  readonly edLabel?: string;
  readonly edDisabled?: boolean;
  readonly edError?: string;

  onClickApplyDateRange?(
    _startDate: Date | undefined,
    _endDate: Date | undefined,
  ): void;
  readonly definedRangeOptions?: DefinedRangeOption[];
  readonly maxDateRangeInDays?: number;
  readonly rangeYear?: RangeYear;
  readonly disabledDays?: Modifier;
  readonly outsideAlerterStyle?: React.CSSProperties;
  readonly popoverPlacement?: Placement;
  readonly textFieldInputBgBeige?: boolean;
  readonly notes?: string;
  readonly disableDefinedRange?: boolean;
  readonly defaultDateRange?: DefaultDateRangeValue;
  readonly allowUndefinedDateRange?: boolean;
};
// #endregion

// #region STYLED
const Container = styled.div((props: { isMobile: boolean }) => [
  props.isMobile && tw`relative`,
]);
const InputContainer = tw.div`flex items-center space-x-2`;
const CloseButtonWrapper = tw.div`absolute right-5 top-3 text-white z-10`;
const CloseButton = tw(
  IconButton,
)`text-white -mx-2 hover:(bg-white bg-opacity-20)`;
// #endregion

export default function DateRangePickerInput({
  sdDateValue = undefined,
  sdId = 'startDate',
  sdName = 'startDate',
  sdLabel = 'Start Date',
  sdDisabled = false,
  sdError,
  sdOnChangeDateValue = () => {},

  edDateValue = undefined,
  edId = 'endDate',
  edName = 'endDate',
  edLabel = 'End Date',
  edDisabled = false,
  edError,
  edOnChangeDateValue = () => {},

  onClickApplyDateRange,
  definedRangeOptions,
  maxDateRangeInDays,
  rangeYear,
  disabledDays,
  outsideAlerterStyle,
  popoverPlacement = 'bottom',
  textFieldInputBgBeige = false,
  notes,
  disableDefinedRange = false,
  defaultDateRange,
  allowUndefinedDateRange = false,
}: DateRangePickerInputProps) {
  // #region MOBILE
  const { isMobile } = useScreenDetection();
  // #endregion

  // #region VALUES
  const inputRef = createRef<HTMLInputElement>();
  const [openDatePickerInput, setOpenDatePickerInput] =
    useState<boolean>(false);
  const [selection, setSelection] = useState<CurrentSelection>('from');

  const sdInputValue = sdDateValue ? format(sdDateValue, 'dd MMM yyyy') : '';
  const edInputValue = edDateValue ? format(edDateValue, 'dd MMM yyyy') : '';
  // #endregion

  return (
    <OutsideAlerter
      onClickAway={() => setOpenDatePickerInput(false)}
      style={outsideAlerterStyle}
    >
      <Container ref={inputRef} isMobile={isMobile}>
        <InputContainer>
          <TextField
            readOnly
            value={sdInputValue}
            disabled={sdDisabled}
            onClick={() => {
              if (sdDisabled) return;

              setSelection('from');
              setOpenDatePickerInput(true);
            }}
            contentEditable={false}
            id={sdId}
            name={sdName}
            label={sdLabel}
            error={sdError}
            inputBgBeige={textFieldInputBgBeige}
            right={
              <IconButton
                tw="-mr-2 origin-center rotate-90"
                disabled={sdDisabled}
                onClick={() => {
                  if (sdDisabled) return;

                  setSelection('from');
                  setOpenDatePickerInput(true);
                }}
              >
                <Icon.ChevronSharp height={22} width={22} />
              </IconButton>
            }
          />

          <Icon.Arrow />

          <TextField
            readOnly
            value={edInputValue}
            disabled={edDisabled}
            onClick={() => {
              if (edDisabled) return;

              setSelection('to');
              setOpenDatePickerInput(true);
            }}
            contentEditable={false}
            id={edId}
            name={edName}
            label={edLabel}
            error={edError}
            inputBgBeige={textFieldInputBgBeige}
            right={
              <IconButton
                tw="-mr-2 origin-center rotate-90"
                disabled={edDisabled}
                onClick={() => {
                  if (edDisabled) return;

                  setSelection('to');
                  setOpenDatePickerInput(true);
                }}
              >
                <Icon.ChevronSharp height={22} width={22} />
              </IconButton>
            }
          />
        </InputContainer>

        {!isMobile && (
          <Popover
            visible={openDatePickerInput}
            targetRef={inputRef as MutableRefObject<null>}
            placement={popoverPlacement}
            tw="z-10 transition-all animate-fade-in"
          >
            <CloseButtonWrapper css={[notes && tw`top-5`]}>
              <CloseButton onClick={() => setOpenDatePickerInput(false)}>
                <Icon.Close />
              </CloseButton>
            </CloseButtonWrapper>

            <DateRangePicker.Wrapper
              selection={selection}
              startDate={sdDateValue}
              endDate={edDateValue}
              onClickCancel={() => setOpenDatePickerInput(false)}
              onClickApply={(_startDate, _endDate) => {
                sdOnChangeDateValue(_startDate);
                edOnChangeDateValue(_endDate);
                onClickApplyDateRange?.(_startDate, _endDate);
                setOpenDatePickerInput(false);
              }}
              definedRangeOptions={definedRangeOptions}
              maxDateRangeInDays={maxDateRangeInDays}
              disabledDays={disabledDays}
              rangeYear={rangeYear}
              notes={notes}
              disableDefinedRange={disableDefinedRange}
              defaultDateRange={defaultDateRange}
              allowUndefinedDateRange={allowUndefinedDateRange}
            />
          </Popover>
        )}

        {isMobile && openDatePickerInput && (
          <Portal
            onClick={(e) => {
              e.stopPropagation();
              setOpenDatePickerInput(false);
            }}
            tw="items-end transition-all animate-fade-in"
            data-testid="DateRangePickerInput:MobileView:Portal"
          >
            <DateRangePicker.Wrapper
              selection={selection}
              startDate={sdDateValue}
              endDate={edDateValue}
              onClickCancel={() => setOpenDatePickerInput(false)}
              onClickApply={(_startDate, _endDate) => {
                sdOnChangeDateValue(_startDate);
                edOnChangeDateValue(_endDate);
                onClickApplyDateRange?.(_startDate, _endDate);

                setOpenDatePickerInput(false);
              }}
              definedRangeOptions={definedRangeOptions}
              maxDateRangeInDays={maxDateRangeInDays}
              disabledDays={disabledDays}
              rangeYear={rangeYear}
              notes={notes}
              disableDefinedRange={disableDefinedRange}
              defaultDateRange={defaultDateRange}
              allowUndefinedDateRange={allowUndefinedDateRange}
            />
          </Portal>
        )}
      </Container>
    </OutsideAlerter>
  );
}
