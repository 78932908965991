import {
  ScheduleFilterOrderBy,
  ScheduleFilterOrderByValue,
} from '../constant/Schedule.constant';
import { UseTranslator } from '../hook/useTranslator.hook';

/**
 * @param  {ScheduleFilterOrderBy} filterOrderBy
 * @returns string
 *
 * @summary map through `SchedulingFilterOrderBy` enum to get the `filterOrderBy` label
 */
export function mapScheduleOrderByToLabel(
  filterOrderBy: ScheduleFilterOrderBy,
): string {
  const obj: Record<ScheduleFilterOrderBy, string> = {
    [ScheduleFilterOrderBy.DRIVER_NAME_ASC]: 'Driver Name A-Z',
    [ScheduleFilterOrderBy.DRIVER_NAME_DESC]: 'Driver Name Z-A',
    [ScheduleFilterOrderBy.VEHICLE_NAME_ASC]: 'Vehicle A-Z',
    [ScheduleFilterOrderBy.VEHICLE_NAME_DESC]: 'Vehicle Z-A',
  };

  return obj[filterOrderBy];
}

/**
 * Format phone number based on mockup, currently only covered minimum 11 characters and max 15 characters include +62
 * e.g +62-812-7363-6365
 * @param phoneNumber
 */
export const indonesianPhoneNumberFormat = (phoneNumber?: string) => {
  if (!phoneNumber) return '';
  // e.g: +62
  const code = phoneNumber.slice(0, 3);
  const numbers = phoneNumber.slice(3);
  // e.g 812, 852
  const ndc = numbers.slice(0, 3);
  // e.g the rest of the numbers
  const uniqNumber = numbers.slice(3);
  let regexp = null;

  if (uniqNumber.length <= 6) {
    regexp = /(\d{3})(\d{1,})/;
  } else if (uniqNumber.length === 7) {
    regexp = /(\d{3})(\d{4})/;
  } else if (uniqNumber.length === 8) {
    regexp = /(\d{4})(\d{4})/;
  } else {
    regexp = /(\d{4})(\d{5,})/;
  }

  const matches = uniqNumber.replace(regexp, '$1-$2');

  return [code, ndc, matches].join('-');
};

export const scheduleOrderByValueIsValid = (value: string) =>
  [
    ScheduleFilterOrderBy.DRIVER_NAME_ASC,
    ScheduleFilterOrderBy.DRIVER_NAME_DESC,
    ScheduleFilterOrderBy.VEHICLE_NAME_ASC,
    ScheduleFilterOrderBy.VEHICLE_NAME_DESC,
  ].includes(value as ScheduleFilterOrderBy);

/**
 * Get schedule filter sort by label & value array
 * @param {UseTranslator} translator - The translator object
 * @returns {ScheduleFilterOrderByValue[]} sort by label & value array
 */
export function getScheduleFilterSortByValues(
  translator: UseTranslator,
): ScheduleFilterOrderByValue[] {
  return [
    {
      label: translator.translate('Driver Name A-Z'),
      value: ScheduleFilterOrderBy.DRIVER_NAME_ASC,
    },
    {
      label: translator.translate('Driver Name Z-A'),
      value: ScheduleFilterOrderBy.DRIVER_NAME_DESC,
    },
    {
      label: translator.translate('Vehicle A-Z'),
      value: ScheduleFilterOrderBy.VEHICLE_NAME_ASC,
    },
    {
      label: translator.translate('Vehicle Z-A'),
      value: ScheduleFilterOrderBy.VEHICLE_NAME_DESC,
    },
  ];
}
