import React from 'react';
import { RouteConfig } from '../../../../../config/router/router.config';

export type ReportDrivingBehaviorByJobOrderRouteParam = string;

export type ReportDrivingBehaviorByJobOrderRouteSearchParam = {
  page?: string; // number
  pageSize?: string; // number
  search?: string;
  orderBy?: string; // RDBJOFilterOrderByEnum
  driverId?: string;
  driverName?: string;
  filterBy?: string; // RDBJOFilterByEnum
  startDate?: string; // number in milliseconds
  endDate?: string; // number in milliseconds
};

export type ReportDrivingBehaviorByJobOrderRouteNavigationState = undefined;

export const reportDrivingBehaviorByJobOrderRoute: RouteConfig = {
  name: 'Driving Behavior by Job Order',
  path: '/report/driving-behavior-by-job-order',
  Component: React.lazy(() => import('./ReportDrivingBehaviorByJobOrder.view')),
  isPrivate: true,

  breadcrumb: 'Driving Behavior by Job Order',

  options: {
    icon: <div />,
    label: 'Driving Behavior by Job Order',
    testID: 'MenuReportDrivingBehaviorByJobOrder',
  },
};
