import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarTheme } from '../component/molecule/Snackbar/Snackbar.molecule';

// #region INTERFACES
interface SnackbarData {
  type?: SnackbarTheme;
  message: string;
}

interface SnackbarOptions {
  autoHide?: boolean;
  withClose?: boolean;
}

export interface SnackbarStore {
  options: SnackbarOptions;
  data?: SnackbarData;
}

interface SnackbarShowAction extends SnackbarData, SnackbarOptions {}
// #endregion

const initialState: SnackbarStore = {
  options: {
    autoHide: true,
    withClose: true,
  },
  data: undefined,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    show: (_state, { payload }: PayloadAction<SnackbarShowAction>) => ({
      options: {
        autoHide: payload.autoHide || initialState.options.autoHide,
        withClose: payload.withClose ?? initialState.options.withClose,
      },
      data: { type: payload.type, message: payload.message },
    }),
    hide: (state) => ({
      ...state,
      data: undefined,
    }),
  },
});

export const snackbarAction = snackbarSlice.actions;
export const snackbarReducer = snackbarSlice.reducer;
