import { FormikErrors } from 'formik';
import { useCallback, useMemo, useRef, useState } from 'react';
import { JOAssignmentBy } from '../../constant/JOAssignment.constant';
import { JobOrderForm } from '../../model/JobOrder.model';
import { GetVehicleOrderBy } from '../../model/Vehicle.model';
import useBlockerConfirmation from '../useBlockerConfirmation/useBlockerConfirmation.hook';
import useTranslator from '../useTranslator.hook';
import useJODateAssignmentForm from './useJODateAssignmentForm.hook';
import useJODriverAssignmentForm from './useJODriverAssignmentForm.hook';
import useJOVehicleAssignmentForm from './useJOVehicleAssignmentForm.hook';

type Props = {
  values: JobOrderForm;
  vehicleAutocompleteParams?: GetVehicleOrderBy;
  setValues: (
    values: React.SetStateAction<JobOrderForm>,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<JobOrderForm>>;
};

export default function useJOAssignmentForm({
  values,
  vehicleAutocompleteParams,
  setValues,
}: Props) {
  const { translate } = useTranslator();
  const tabRef = useRef<JOAssignmentBy>(JOAssignmentBy.VEHICLE);

  const [isSidebarDisplayed, setSidebarDisplayed] = useState(false);
  const [showBlockerModal, setShowBlockerModal] = useState(false);
  const [tab, setTab] = useState(JOAssignmentBy.VEHICLE);

  const submitLabel = translate('Select Schedule');

  const vehicleAssignmentForm = useJOVehicleAssignmentForm({
    joFormValues: values,
    setJOFormValues: setValues,
    vehicleAutocompleteParams,
  });
  const driverAssignmentForm = useJODriverAssignmentForm({
    joFormValues: values,
    setJOFormValues: setValues,
  });
  const dateAssignmentForm = useJODateAssignmentForm({
    joFormValues: values,
    setJOFormValues: setValues,
  });

  const handleChangeTab = useCallback(
    (val: JOAssignmentBy) => {
      if (val === tab) return;
      setTab(val);
    },
    [tab],
  );

  const isFormValid = useMemo(() => {
    if (tab === JOAssignmentBy.DATE) return dateAssignmentForm.isValid;
    if (tab === JOAssignmentBy.VEHICLE) return vehicleAssignmentForm.isValid;
    return driverAssignmentForm.isValid;
  }, [
    tab,
    dateAssignmentForm.isValid,
    driverAssignmentForm.isValid,
    vehicleAssignmentForm.isValid,
  ]);

  const isFormChanged = useMemo(() => {
    if (tab === JOAssignmentBy.DATE)
      return dateAssignmentForm.hasUnsavedChanges;
    if (tab === JOAssignmentBy.VEHICLE)
      return vehicleAssignmentForm.hasUnsavedChanges;
    return driverAssignmentForm.hasUnsavedChanges;
  }, [
    tab,
    dateAssignmentForm.hasUnsavedChanges,
    driverAssignmentForm.hasUnsavedChanges,
    vehicleAssignmentForm.hasUnsavedChanges,
  ]);

  const handleSetSidebarDisplayed = useCallback(
    (val: boolean) => {
      if (val !== isSidebarDisplayed && isFormChanged) {
        setShowBlockerModal(true);
        return;
      }
      setTab(tabRef.current);
      setSidebarDisplayed(val);
    },
    [isFormChanged, isSidebarDisplayed],
  );

  const handleInitValues = useCallback(
    (val?: JOAssignmentBy) => {
      if (val === JOAssignmentBy.VEHICLE) {
        void vehicleAssignmentForm.setValues({
          schedule: values.schedule,
          scheduleId: values.schedule?.id,
          vehicleId: values.vehicleId,
          vehicleOption: values.vehicleOption,
        });
      }
      if (val === JOAssignmentBy.DRIVER) {
        void driverAssignmentForm.setValues({
          schedule: values.schedule,
          scheduleId: values.schedule?.id,
          driverId: values.driverId,
          driverOption: values.driverOption,
        });
      }
      if (val === JOAssignmentBy.DATE) {
        void dateAssignmentForm.setValues({
          schedule: values.schedule,
          scheduleId: values.schedule?.id,
          from: new Date((values.schedule?.from || 0) * 1000),
          to: new Date((values.schedule?.to || 0) * 1000),
        });
      }
    },
    [
      dateAssignmentForm,
      driverAssignmentForm,
      values.driverId,
      values.driverOption,
      values.schedule,
      values.vehicleId,
      values.vehicleOption,
      vehicleAssignmentForm,
    ],
  );

  const handleReset = useCallback(
    (val?: JOAssignmentBy) => {
      if (val !== JOAssignmentBy.DATE) dateAssignmentForm.handleReset();
      if (val !== JOAssignmentBy.DRIVER) driverAssignmentForm.handleReset();
      if (val !== JOAssignmentBy.VEHICLE) vehicleAssignmentForm.handleReset();
    },
    [dateAssignmentForm, driverAssignmentForm, vehicleAssignmentForm],
  );

  const handleSelectSchedule = useCallback(() => {
    setSidebarDisplayed(false);
    setShowBlockerModal(false);
    handleReset(tab);
    tabRef.current = tab;
    if (tab === JOAssignmentBy.DATE) return dateAssignmentForm.handleSubmit();
    if (tab === JOAssignmentBy.VEHICLE)
      return vehicleAssignmentForm.handleSubmit();

    return driverAssignmentForm.handleSubmit();
  }, [
    tab,
    driverAssignmentForm,
    vehicleAssignmentForm,
    dateAssignmentForm,
    handleReset,
  ]);

  const handleCloseBlocker = useCallback(() => {
    setShowBlockerModal(false);
  }, []);
  const handleDiscardBlocker = useCallback(() => {
    setSidebarDisplayed(false);
    handleCloseBlocker();
    handleReset();
    setTab(tabRef.current);
    handleInitValues(tabRef.current);
  }, [handleCloseBlocker, handleInitValues, handleReset]);

  const blocker = useBlockerConfirmation({
    isFormChanged,
    isFormValid,
    showBlockerModal,
    onClose: handleCloseBlocker,
    onDismiss: handleCloseBlocker,
    onDiscard: handleDiscardBlocker,
    onSubmit: handleSelectSchedule,
  });

  return {
    tab,
    submitLabel,
    isSidebarDisplayed,
    vehicleAssignmentForm,
    driverAssignmentForm,
    dateAssignmentForm,
    isFormValid,
    blocker,
    translate,
    handleSetSidebarDisplayed,
    handleSelectSchedule,
    setSidebarDisplayed,
    handleChangeTab,
  };
}

export type UseJOAssignmentFormHookObj = ReturnType<typeof useJOAssignmentForm>;
