import React from 'react';

export default function ActiveOrderBG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="130"
      fill="none"
      viewBox="0 0 70 130"
    >
      <g clipPath="url(#clip0_1881_183687)">
        <path
          stroke="#E4E0CC"
          strokeWidth="2.3"
          d="M27 21h46a6 6 0 016 6v74a6 6 0 01-6 6H27a6 6 0 01-6-6V27a6 6 0 016-6z"
        />
        <rect width="16" height="2.5" x="31" y="35" fill="#E4E0CC" rx="0.4" />
        <rect width="16" height="2.5" x="31" y="42.5" fill="#E4E0CC" rx="0.4" />
        <rect width="9" height="2.5" x="31" y="50" fill="#E4E0CC" rx="0.4" />
        <path
          stroke="#E4E0CC"
          strokeWidth="2.3"
          d="M55.5 54.568V23a2 2 0 012-2h13a2 2 0 012 2v20.097a2 2 0 01-.677 1.5l-13 11.47c-1.291 1.14-3.323.223-3.323-1.499z"
        />
        <circle
          cx="25.5"
          cy="93.5"
          r="18.35"
          fill="#fff"
          stroke="#E4E0CC"
          strokeWidth="2.3"
        />
        <path
          stroke="#E4E0CC"
          strokeWidth="2.3"
          d="M20.26 85.366a7.41 7.41 0 0111.747 8.785l-5.76 10.575a.85.85 0 01-1.493 0l-5.761-10.575a7.41 7.41 0 011.267-8.785z"
        />
        <circle cx="25.5" cy="90.5" r="2.5" fill="#E4E0CC" />
      </g>
      <defs>
        <clipPath id="clip0_1881_183687">
          <path fill="#fff" d="M0 0H70V130H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
