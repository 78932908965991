import { FormikErrors } from 'formik';
import { OptionType } from '../component/molecule/Select/Select.molecule';
import {
  ShipperOrderActivityEventType,
  SOActivityGoodsType,
  SOActivityType,
  SOOrderBy,
  SOStatus,
} from '../constant';
import { AutocompleteType } from '../hook/useAutocomplete.hook';
import {
  CommonApiListParams,
  CommonApiMetadata,
  CommonApiResponse,
  CommonErrorResponse,
} from '../service/api.endpoint';
import { FormItem } from '../types/input.type';
import { Delivery } from './Delivery.model';
import { HOTimelineType } from './HaulingOrderTracking.model';
import { JOActivityInfo, JOStatus } from './JobOrder.model';
import { Location } from './Location.model';
import { Shipper } from './Shipper.model';

export interface SOActivityDetailNote {
  id: string;
  notes: string;
  createdAt: number; // in seconds
}

export interface SOActivityDetail {
  id: string;
  type: SOActivityType;
  expectedFinishAt?: number;
  location: Location;
  goods: Goods[];
  goodsOut: Goods[];
  notes: SOActivityDetailNote[];
  completedAt: number;
}

export enum SOPaymentStatus {
  UNPAID = 101,
  PAID = 201,
}

export interface ShipperOrder {
  id: string;
  date: number;
  number: string;
  trackingCode: string;
  shipper: Shipper;
  status: SOStatus;
  statusText: keyof typeof SOStatus;
  cost: ShipperOrderCost;
  activities: SOListActivity[];
  createdAt: number;
  updatedAt: number;
  isTransitable?: boolean;
  referenceNumber: string | null;
  notes: string | null;
  deliveries: Delivery[];
  totalPayment: number;
  totalGoodsVolume?: number;
  totalGoodsWeight?: number;
  paymentStatus: SOPaymentStatus;
  paymentStatusText: keyof typeof SOPaymentStatus;
}

export interface ShipperOrderUnassigned extends ShipperOrder {
  transitLocation?: Location;
}

type SOInfoDeliveries = Delivery & {
  activities: JOActivityInfo[];
  status: JOStatus;
  statusText: keyof typeof JOStatus;
};

export type ShipperOrderInfo = Omit<
  ShipperOrder,
  'activities' | 'deliveries'
> & {
  activities: JOActivityInfo[];
  deliveries: SOInfoDeliveries[];
  completedAt?: number;
  trackingCode?: string;
};

export interface SOListActivity extends SOActivityDetail {
  locationId: string;
  status: number;
  statusText: string;
  events: ShipperOrderEvent[];
  index?: number;
}

export interface Goods {
  id?: string;
  type: SOActivityGoodsType;
  description?: string | null;
  quantity?: number | null;
  uom?: string | null;
  weight?: number | null;
  volume?: number | null;
}
export type GoodsFormValues = Goods;

export interface ShipperOrderActivity {
  type: SOActivityType;
  index: number;
  location?: Location;
  expectedFinishAt?: number;
  locationId?: string;
  goods?: Goods[];
  goodsOut?: Goods[];
}

export type ShipperOrderActivityFormValues = Omit<
  ShipperOrderActivity,
  'locationId' | 'goods' | 'expectedFinishAt'
> & {
  locationId?: string;
  expectedFinishAt?: number;
  goods: GoodsFormValues[];
  location?: Location;
};

export interface ShipperOrderCost {
  deliveryFee: number;
  tax: number;
  insurance: number;
}

export type ShipperOrderCostFormValues = Partial<ShipperOrderCost>;

export interface ShipperOrderActivityTimeline {
  timestamp: number;
  title: string;
}
export interface ShipperOrderEvent {
  eventType: ShipperOrderActivityEventType;
  eventTime: number;
}

export enum JOSOCandidateFilterFormSortBy {
  UPDATED_AT_DESC = 'UPDATED_AT_DESC', // Newest Update
  UPDATED_AT_ASC = 'UPDATED_AT_ASC', // Oldest Update
  DATE_DESC = 'DATE_DESC', // Newest Date
  DATE_ASC = 'DATE_ASC', // Oldest Date
  SHIPPER_NAME_DESC = 'SHIPPER_NAME_DESC', // Shipper Name Z-A
  SHIPPER_NAME_ASC = 'SHIPPER_NAME_ASC', // Shipper Name A-Z
  DELIVERY_COST_DESC = 'DELIVERY_COST_DESC', // Highest Delivery Cost
}

export enum JOSOCandidateFilterFormStatus {
  IN_TRANSIT = 'IN_TRANSIT', // In Transit
  IN_PROCESS = 'IN_PROCESS', // In Process
}

export type SOPaymentHistory = {
  id: string;
  soId: string;
  amount: number;
  paymentDate: number; // in seconds
  createdAt: number; // in seconds
  notes?: string;
};

// #region API
export interface GetSOUnassignedListParams {
  from?: number;
  to?: number;
  search?: string;
  orderBy?: JOSOCandidateFilterFormSortBy;
  driverId?: string;
  vehicleId?: string;
  shipperId?: string;
  locationId?: string;
  page?: number;
  pageSize?: number;
  joId?: string;
  'status[]'?: SOStatus;
}

export type GetSOApiRequest = {
  from?: number;
  to?: number;
  search?: string;
  orderBy?: SOOrderBy;
  page?: number;
  pageSize?: number;
  shipperId?: string;
  'paymentStatus[]'?: SOPaymentStatus;
  'status[]'?: SOStatus;
};

export type GetSOApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    shipperOrders: ShipperOrder[];
  };

export type GetSOCountApiRequest = Omit<
  GetSOApiRequest,
  'page' | 'pageSize' | 'orderBy'
>;

export type GetSOCountApiResponse = CommonApiResponse & {
  shipperOrders: {
    totalCount: number;
  };
};

export type DeleteShipperOrderApiRequest = {
  id: string;
};
export type DeleteShipperOrderApiResponse = CommonApiResponse;

export type SOAPIParam = {
  id: string;
};
export interface ShipperOrderCreateParam {
  shipperId: string;
  soDate: number;
  soNumber?: string;
  isTransitable?: boolean;
  referenceNumber?: string;
  cost?: ShipperOrderCost;
  notes?: string;
  trackingCode?: string;
  activities: ShipperOrderActivity[];
}

export type ShipperOrderFormValues = Partial<
  Omit<ShipperOrderCreateParam, 'activities'>
> & {
  shipper?: AutocompleteType;
  activities: ShipperOrderActivityFormValues[];
};

export type ShipperOrderCreateResponse = CommonApiResponse & {
  shipperOrder: { id: string; number: string; trackingCode: string };
};
export type ShipperOrderCreateError = CommonErrorResponse;

export interface SOHeaderUpdateParam {
  soId: string;
  soDate?: number;
  soNumber?: string;
  referenceNumber?: string | null;
  notes?: string | null;
}
export type SOHeaderUpdateResponse = CommonApiResponse;

export interface SOCostUpdateParam {
  soId: string;
  deliveryFee?: number;
  tax?: number;
  insurance?: number;
}

export type SOCostUpdateResponse = CommonApiResponse;

export type SOUpdateActivitiesProps = Omit<
  ShipperOrderActivityFormValues,
  'goods'
> & {
  goods?: GoodsFormValues[];
};
export interface SOActivitiesUpdateParam {
  soId: string;
  activities: SOUpdateActivitiesProps[];
}

export type SOActivitiesUpdateResponse = CommonApiResponse;
// #endregion

// #endregion PATH PARAMS
export type GetShipperOrderPaymentHistoryListRequest = Omit<
  CommonApiListParams,
  'search'
> & {
  soId: string;
};
export type GetShipperOrderPaymentHistoryListResponse = CommonApiResponse & {
  payments: SOPaymentHistory[];
};

export type CreateShipperOrderPaymentHistoryRequest = Omit<
  SOPaymentHistory,
  'id' | 'createdAt'
>;
export type CreateShipperOrderPaymentHistoryResponse = CommonApiResponse;

export type DeleteShipperOrderPaymentHistoryRequest = {
  soId: string;
  soPaymentId: string;
};
export type DeleteShipperOrderPaymentHistoryResponse = CommonApiResponse;
// #endregion

export interface SOActivityFormProps {
  actionLabel: string;
  activities: SOUpdateActivitiesProps[];
  activitiesError?:
    | string
    | string[]
    | FormikErrors<ShipperOrderActivityFormValues>[];
  activityName?: string;
  isActionDisabled?: boolean;
  isActivityFromTemplate?: boolean;
  isCreateATInfoVisible?: boolean;
  isFooterVisible?: boolean;
  isLocationListFetchLoading?: boolean;
  isSecondaryActionDisabled?: boolean;
  isTemplateRequireSave?: boolean;
  isTemplateRequireSaveDisabled?: boolean;
  locationOptions?: AutocompleteType[];
  secondaryActionLabel?: string;
  soActivityTemplateFormData?: FormItem[];
  soDate?: number;
  title: string;
  handleAction?: () => void;
  handleAddMoreActivity: () => Promise<void>;
  handleAddNewLocation: (idx?: number) => void;
  handleChangeActivity?: () => void;
  handleChangeDataLocation: (
    index: number,
    option?: OptionType,
  ) => Promise<void>;
  handleChangeDateActivity: (
    index: number,
    date?: Date | undefined,
    isTransit?: boolean,
  ) => Promise<void>;
  handleChangeLocationAutotext: (val?: string) => void;
  handleChangeSaveTemplate?: (value: boolean) => Promise<void>;
  handleClickChoice: (
    index: number,
    currentChoice: SOActivityType,
  ) => Promise<void>;
  handleClickEditActivity: (index: number) => void;
  handleDuplicateActivity: (index: number) => Promise<void>;
  handleFetchMoreLocation: () => void;
  handleRemoveActivity: (index: number) => Promise<void>;
  handleRemoveLocation: (index: number) => void;
  handleSecondaryAction?: () => void;
  handleShowActivitySelectionMethod?: () => void;
  handleCancelActivitySelectionMethod?: () => void;
  getActivityFormErrors: (
    index: number,
    key:
      | 'locationId'
      | 'goods'
      | 'expectedFinishAt'
      | 'index'
      | 'type'
      | 'location',
  ) => string;
}

export type ExportSOApiRequest = Omit<
  GetSOApiRequest,
  'status[]' | 'paymentStatus[]'
> & {
  status?: SOStatus[];
  paymentStatus?: SOPaymentStatus[];
};
export type ExportSOApiResponse = CommonApiResponse & {
  shipperOrder: {
    fileUrl: string;
  };
};

export type ShipperOrderSearchParam = {
  startDate?: string; // milliseconds
  endDate?: string; // milliseconds
  orderBy?: string; // SOOrderBy
  status?: string; // string of SOOrderStatus collection => 'IN_PROCESS,RESERVED,ASSIGNED'
  paymentStatus?: string; // string of SOPaymentStatusLabel collection => 'PAID,UNPAID'
  shipperId?: string;
  shipperName?: string;
  search?: string;
  page?: string;
  pageSize?: string;
};

export type GetSOByTrackingCodeApiRequest = {
  trackingCode: string;
};

export type GetSOByTrackingCodeApiResponse = CommonApiResponse & {
  tracking: SOTracking;
};

export type GetTrackingSORequest = { id?: string };
export type GetTrackingSOResponse = CommonApiResponse & {
  tracking: SOTracking;
};

// #region TRACKING & TIMELINE
type TimelineLocation = {
  id: string;
  name: string;
  activityType?: SOTrackingActivityType;
};
export type TimelinePhoto = {
  id: string;
  url: string;
};

export type TimelineDataLocation = { location: TimelineLocation };
export type TimelineDataPhoto = {
  type: 'so';
  photos: TimelinePhoto[];
  proofOfActivityNotes?: string;
  soActivityId?: string;
  /**
   * For client side purpose
   */
  createdAt?: number;
};
export type SOTimelinePhoto = {
  type: 'so';
  url: string;
  id: string;
  activityId?: string;
};

export type TimelineDataNote = { notes: string };

/**
 * Shipper order has almost the same constant but instead of extends it, I created new constant since it 2 differents context
 */
export enum TimelineType {
  ACTIVITY_STARTED = 'ACTIVITY_STARTED',
  IN_PROCESS = 'IN_PROCESS',
  DELIVERING = 'DELIVERING',
  DROP_OFF = 'DROP_OFF',
  DROP_OFF_STARTED = 'DROP_OFF_STARTED',
  STAND_BY_STARTED = 'STAND_BY_STARTED',
  STAND_BY = 'STAND_BY',
  PICK_UP = 'PICK_UP',
  PICK_UP_STARTED = 'PICK_UP_STARTED',
  PROOF_OF_ACTIVITY_ADDED = 'PROOF_OF_ACTIVITY_ADDED',
  NOTES_ADDED = 'NOTES_ADDED',
  STAND_BY_COMPLETED = 'STAND_BY_COMPLETED',
  PICK_UP_COMPLETED = 'PICK_UP_COMPLETED',
  DROP_OFF_COMPLETED = 'DROP_OFF_COMPLETED',
  DELIVERED = 'DELIVERED',
  TRANSITED = 'TRANSITED',
  IN_TRANSIT = 'IN_TRANSIT',
  TRANSITING = 'TRANSITING',
  TRANSIT_ACTIVITY_COMPLETED = 'TRANSIT_ACTIVITY_COMPLETED',
  TRANSIT_ACTIVITY_STARTED = 'TRANSIT_ACTIVITY_STARTED',
  PICK_UP_TRANSIT_STARTED = 'PICK_UP_TRANSIT_STARTED',
  PICK_UP_TRANSIT_COMPLETED = 'PICK_UP_TRANSIT_COMPLETED',
  DROP_OFF_TRANSIT_STARTED = 'DROP_OFF_TRANSIT_STARTED',
  DROP_OFF_TRANSIT_COMPLETED = 'DROP_OFF_TRANSIT_COMPLETED',
  GEOFENCE_ENTER = 'GEOFENCE_ENTER',
  GEOFENCE_EXIT = 'GEOFENCE_EXIT',
}

export type BaseTimeline<
  TYPE extends TimelineType | HOTimelineType,
  Data = null | undefined,
> = {
  type: TYPE;
  time: number;
  data: Data;
};

type TimelineInProcess = BaseTimeline<TimelineType.IN_PROCESS>;
type TimelineDelivering = BaseTimeline<TimelineType.DELIVERING>;
type TimelineDropOff = BaseTimeline<
  TimelineType.DROP_OFF,
  TimelineDataLocation
>;
type TimelineStandBy = BaseTimeline<
  TimelineType.STAND_BY,
  TimelineDataLocation
>;
type TimelinePickup = BaseTimeline<TimelineType.PICK_UP, TimelineDataLocation>;
export type TimelinePOA = BaseTimeline<
  TimelineType.PROOF_OF_ACTIVITY_ADDED,
  TimelineDataPhoto
>;
type TimelineNotes = BaseTimeline<TimelineType.NOTES_ADDED, TimelineDataNote>;
type TimelineStandByCompleted = BaseTimeline<
  TimelineType.STAND_BY_COMPLETED,
  TimelineDataLocation & TimelineDataPhoto
>;
type TimelinePickupCompleted = BaseTimeline<
  TimelineType.PICK_UP_COMPLETED,
  TimelineDataLocation & TimelineDataPhoto
>;
type TimelineDropoffCompleted = BaseTimeline<
  TimelineType.DROP_OFF_COMPLETED,
  TimelineDataLocation & TimelineDataPhoto
>;
type TimelineTransiting = BaseTimeline<
  TimelineType.TRANSITING,
  TimelineDataLocation
>;
type TimelineInTransit = BaseTimeline<
  TimelineType.IN_TRANSIT,
  TimelineDataLocation
>;
export type TimelineTransitActivityCompleted = BaseTimeline<
  TimelineType.TRANSIT_ACTIVITY_COMPLETED,
  TimelineDataLocation & TimelineDataPhoto
>;

type TimelinePickUpStarted = BaseTimeline<
  TimelineType.PICK_UP_STARTED,
  TimelineDataLocation
>;
type TimelineGeofenceExit = BaseTimeline<
  TimelineType.GEOFENCE_EXIT,
  TimelineDataLocation
>;
type TimelineGeofenceEnter = BaseTimeline<
  TimelineType.GEOFENCE_ENTER,
  TimelineDataLocation
>;
type TimelineTransitActivityStarted = BaseTimeline<
  TimelineType.TRANSIT_ACTIVITY_STARTED,
  TimelineDataLocation
>;

type TimelineDelivered = BaseTimeline<TimelineType.DELIVERED>;

export type Timeline =
  | TimelineDelivering
  | TimelineDelivered
  | TimelineDropOff
  | TimelineDropoffCompleted
  | TimelineInProcess
  | TimelinePOA
  | TimelineStandBy
  | TimelineStandByCompleted
  | TimelinePickupCompleted
  | TimelinePickup
  | TimelineNotes
  | TimelineTransiting
  | TimelinePickUpStarted
  | TimelineInTransit
  | TimelineTransitActivityCompleted
  | TimelineGeofenceExit
  | TimelineGeofenceEnter
  | TimelineTransitActivityStarted;
/**
 * the `index` key is meant to be as re-ordering reference since `time` is not always unique, in some cases time can be equal
 */
export type SOIndexedTimeline = Timeline & { index: number };
export type SOTrackingLocation = {
  name: string;
  latitude: number;
  longitude: number;
};

export enum SOTrackingActivityType {
  DROP_OFF = 'DROP_OFF',
  PICK_UP = 'PICK_UP',
  STAND_BY = 'STAND_BY',
}

export type SOTrackingActivity = {
  id: string;
  type: SOTrackingActivityType;
  locationName: string;
  goods: Goods[];
  goodsOut: Goods[];
};

export type SOTrackingJOInfo = {
  number: string;
  date?: number;
  sealNumber?: string;
  driverName?: string;
  vehicleName?: string;
  isResolved?: boolean;
  status: JOStatus;
  statusText: string;
};

export type SOTrackingDelivery = {
  jobOrder: SOTrackingJOInfo;
  status: JOStatus;
  statusText: string;
};

export type SOTracking = {
  pickupLocation: SOTrackingLocation[];
  dropoffLocation: SOTrackingLocation[];
  timelines: Timeline[];
  latestPosition?: Omit<SOTrackingLocation, 'name'> | null;
  orgName: string;
  activities: SOTrackingActivity[];
  isTransitable: boolean;
  status: SOStatus;
  deliveries?: SOTrackingDelivery[];
};
// #endregion
