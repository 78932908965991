import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeliveryLocationAddBulkRouteParam } from '../view/DeliveryLocationAddBulk/DeliveryLocationAddBulk.route';
import { DriverAddBulkRouteParam } from '../view/DriverAddBulk/driverAddBulk.route';
import { HOLocationAddRouteParam } from '../view/HOLocationAdd/HOLocationAdd.route';
import { HOShipperAddRouteParam } from '../view/HOShipperAdd/HOShipperAdd.route';
import { JOLocationAddRouteParam } from '../view/JOCreateLocationAdd/JOCreateLocationAdd.route';
import { JODriverAddRouteParam } from '../view/JODriverAdd/JODriverAdd.route';
import { ShipperAddBulkRouteParam } from '../view/ShipperAddBulk/ShipperAddBulk.route';
import { SODDeliveryLocationAddRouteParam } from '../view/SODDeliveryLocationAdd/SODDeliveryLocationAdd.route';
import { SODeliveryLocationAddRouteParam } from '../view/SODeliveryLocationAdd/SODeliveryLocationAdd.route';
import { SOShipperAddRouteParam } from '../view/SOShipperAdd/SOShipperAdd.route';

export type NavigationParamStore = {
  soShipperAddParams: SOShipperAddRouteParam;
  soDeliveryLocationAddParams: SODeliveryLocationAddRouteParam;
  soDetailDeliveryLocationAddParams: SODDeliveryLocationAddRouteParam;
  joDriverAddParams: JODriverAddRouteParam;
  hoShipperAddParams?: HOShipperAddRouteParam;
  hoLocationAddParams?: HOLocationAddRouteParam;
  joLocationAddParams?: JOLocationAddRouteParam;
  driverAddBulkParams?: DriverAddBulkRouteParam;
  shipperAddBulkParams?: ShipperAddBulkRouteParam;
  locationAddBulkParams?: DeliveryLocationAddBulkRouteParam;
};

const initialState: NavigationParamStore = {
  soDeliveryLocationAddParams: undefined,
  soDetailDeliveryLocationAddParams: undefined,
  soShipperAddParams: undefined,
  joDriverAddParams: undefined,
  hoShipperAddParams: undefined,
  hoLocationAddParams: undefined,
  joLocationAddParams: undefined,
  driverAddBulkParams: undefined,
  shipperAddBulkParams: undefined,
  locationAddBulkParams: undefined,
};

const navigationParamSlice = createSlice({
  name: 'navigationParam',
  initialState,
  reducers: {
    changeShipperOrderShipperAddParams: (
      state,
      action: PayloadAction<SOShipperAddRouteParam>,
    ) => ({
      ...state,
      soShipperAddParams: action.payload,
    }),
    changeShipperOrderDeliveryLocationAddParams: (
      state,
      action: PayloadAction<SODeliveryLocationAddRouteParam>,
    ) => ({
      ...state,
      soDeliveryLocationAddParams: action.payload,
    }),
    changeHaulingOrderLocationAddParams: (
      state,
      action: PayloadAction<HOLocationAddRouteParam>,
    ) => ({
      ...state,
      hoLocationAddParams: action.payload,
    }),
    changeJobOrderLocationAddParams: (
      state,
      action: PayloadAction<JOLocationAddRouteParam>,
    ) => ({
      ...state,
      joLocationAddParams: action.payload,
    }),
    changeShipperOrderDetailDeliveryLocationAddParams: (
      state,
      action: PayloadAction<SODDeliveryLocationAddRouteParam>,
    ) => ({
      ...state,
      soDetailDeliveryLocationAddParams: action.payload,
    }),
    changeJobOrderDriverAddParams: (
      state,
      action: PayloadAction<JODriverAddRouteParam>,
    ) => ({
      ...state,
      joDriverAddParams: action.payload,
    }),
    changeHaulingOrderShipperAddParams: (
      state,
      action: PayloadAction<HOShipperAddRouteParam>,
    ) => ({
      ...state,
      hoShipperAddParams: action.payload,
    }),
    changeDriverAddBulkParams: (
      state,
      action: PayloadAction<DriverAddBulkRouteParam>,
    ) => ({
      ...state,
      driverAddBulkParams: action.payload,
    }),
    clearDriverAddBulkParams: (state) => ({
      ...state,
      driverAddBulkParams: undefined,
    }),
    changeShipperAddBulkParams: (
      state,
      action: PayloadAction<ShipperAddBulkRouteParam>,
    ) => ({
      ...state,
      shipperAddBulkParams: action.payload,
    }),
    clearShipperAddBulkParams: (state) => ({
      ...state,
      shipperAddBulkParams: undefined,
    }),
    changeLocationAddBulkParams: (
      state,
      action: PayloadAction<DeliveryLocationAddBulkRouteParam>,
    ) => ({
      ...state,
      locationAddBulkParams: action.payload,
    }),
    clearLocationAddBulkParams: (state) => ({
      ...state,
      locationAddBulkParams: undefined,
    }),
  },
});
export const navigationParamAction = navigationParamSlice.actions;
export const navigationParamReducer = navigationParamSlice.reducer;
