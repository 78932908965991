import React from 'react';

export default function Phone({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" fill={fill || '#616161'} />
      <path
        d="M11 2.069V0H9v2.069A8.008 8.008 0 0 0 2.069 9H0v2h2.069A8.007 8.007 0 0 0 9 17.931V20h2v-2.069A8.007 8.007 0 0 0 17.931 11H20V9h-2.069A8.008 8.008 0 0 0 11 2.069ZM10 16c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6Z"
        fill={fill || '#616161'}
      />
    </svg>
  );
}
