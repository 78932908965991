import React, { ReactElement, useMemo, useRef } from 'react';
import { useHoverDirty } from 'react-use';
import { BodyFourteen, BoldLabel } from '../../component/atom/Text/Text.atom';
import { JOFormStep } from '../../constant/JobOrder.constant';
import { formatTextWithElementTag } from '../../util/formatter.util';
import useTranslator from '../useTranslator.hook';
import { UseJOFormControllerHookObj } from './useJOFormController.hook';
import { UseJOFormStateObj } from './useJOFormState.hook';

export type JOFormErrorTooltipItem = {
  key: string;
  element: ReactElement;
};
type Props = {
  controller: UseJOFormControllerHookObj;
  state: UseJOFormStateObj;
};

export default function useJOFormErrorTooltip({ state, controller }: Props) {
  const { translate } = useTranslator();

  //#region PRIMARY TOOLTIP
  const tooltipPrimaryRef = useRef(null);
  const isPrimaryHovered = useHoverDirty(tooltipPrimaryRef);

  const tooltipPrimaryAssignSectionLabelMapper = useMemo(
    (): JOFormErrorTooltipItem[] => [
      ...(state.soSelectionForm.isDisabled &&
      !!controller.soController?.selectionSOList?.length
        ? [
            {
              key: 'invalidDeliveryLocation',
              element: (
                <BodyFourteen>
                  {formatTextWithElementTag(
                    translate(
                      'All %s must be assigned to their delivery location.',
                    ),
                    <BoldLabel>
                      {translate('Selected Shipper Order')}
                    </BoldLabel>,
                  )}
                </BodyFourteen>
              ),
            },
          ]
        : []),
      ...(state.soSelectionForm.isDisabled &&
      !controller.soController?.selectionSOList?.length
        ? [
            {
              key: 'noDelivery',
              element: (
                <BodyFourteen>
                  {formatTextWithElementTag(
                    translate('Please select at least 1 %s and assign the %s.'),
                    <BoldLabel>{translate('Shipper Order')}</BoldLabel>,
                    <BoldLabel>{translate('delivery location')}</BoldLabel>,
                  )}
                </BodyFourteen>
              ),
            },
          ]
        : []),
      ...(!state.joFormik.values.driverId || !state.joFormik.values.vehicleId
        ? [
            {
              key: 'invalidDriver&Vehicle',
              element: (
                <BodyFourteen>
                  {formatTextWithElementTag(
                    translate('%s fields must be filled.'),
                    <BoldLabel>
                      {translate('Select Driver & Vehicle')}
                    </BoldLabel>,
                  )}
                </BodyFourteen>
              ),
            },
          ]
        : []),
      ...(state.joFormik.errors.travelExpenses
        ? [
            {
              key: 'invalidDriver&Vehicle',
              element: (
                <BodyFourteen>
                  {translate(
                    'Please correct any error fields before finishing this process.',
                  )}
                </BodyFourteen>
              ),
            },
          ]
        : []),
    ],
    [
      translate,
      controller.soController?.selectionSOList?.length,
      state.soSelectionForm.isDisabled,
      state.joFormik.values.driverId,
      state.joFormik.values.vehicleId,
      state.joFormik.errors.travelExpenses,
    ],
  );

  const tooltipPrimaryMapper = useMemo(() => {
    if (
      controller.formStep === JOFormStep.HEADER &&
      !state.headerForm.isValid
    ) {
      return [
        {
          key: 'invalidHeaderField',
          element: (
            <BodyFourteen>
              {translate(
                'Please correct any error fields before moving to the next step.',
              )}
            </BodyFourteen>
          ),
        },
      ];
    }
    if (controller.formStep === JOFormStep.ASSIGNMENT) {
      return tooltipPrimaryAssignSectionLabelMapper;
    }
    return [];
  }, [
    translate,
    controller.formStep,
    state.headerForm.isValid,
    tooltipPrimaryAssignSectionLabelMapper,
  ]);

  const isToolTipPrimaryVisible = useMemo(
    () => isPrimaryHovered && !!tooltipPrimaryMapper.length,
    [isPrimaryHovered, tooltipPrimaryMapper.length],
  );

  //#endregion
  //#region SECONDARY TOOLTIP
  const tooltipSecondaryRef = useRef(null);
  const isSecondaryHovered = useHoverDirty(tooltipSecondaryRef);
  const tooltipSecondaryAssignSectionLabelMapper = useMemo(
    (): JOFormErrorTooltipItem[] => [
      ...(state.joFormik.errors.travelExpenses
        ? [
            {
              key: 'invalidTravelExpenses',
              element: (
                <BodyFourteen>
                  {translate('Please correct any error fields.')}
                </BodyFourteen>
              ),
            },
          ]
        : []),
    ],
    [translate, state.joFormik.errors.travelExpenses],
  );

  const tooltipSecondaryMapper = useMemo((): JOFormErrorTooltipItem[] => {
    if (
      controller.formStep === JOFormStep.HEADER &&
      !state.headerForm.isValid
    ) {
      return [
        {
          key: 'invalidHeaderForm',
          element: (
            <BodyFourteen>
              {translate('Please correct any error fields.')}
            </BodyFourteen>
          ),
        },
      ];
    }
    if (controller.formStep === JOFormStep.ASSIGNMENT) {
      return tooltipSecondaryAssignSectionLabelMapper;
    }
    return [];
  }, [
    translate,
    controller.formStep,
    state.headerForm.isValid,
    tooltipSecondaryAssignSectionLabelMapper,
  ]);

  const isToolTipSecondaryVisible = useMemo(
    () => isSecondaryHovered && !!tooltipSecondaryMapper.length,
    [isSecondaryHovered, tooltipSecondaryMapper.length],
  );

  //#endregion

  return {
    isSecondaryHovered,
    tooltipPrimaryRef,
    tooltipSecondaryRef,
    tooltipPrimaryMapper,
    tooltipSecondaryMapper,
    isToolTipSecondaryVisible,
    isToolTipPrimaryVisible,
  };
}
