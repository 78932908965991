import { Locale } from './locale.config';

const editLocationPageLocaleConfig: Locale[] = [
  {
    en: 'Location Name',
    id: 'Nama Lokasi',
  },
  {
    en: 'Search Address',
    id: 'Cari Alamat',
  },
  {
    en: 'Address',
    id: 'Alamat',
  },
  {
    en: 'Latitude',
    id: 'Koordinat Lintang',
  },
  {
    en: 'Longitude',
    id: 'Koordinat Bujur',
  },
  {
    en: 'Contact Name',
    id: 'Nama Kontak',
  },
  {
    en: 'Phone Number',
    id: 'Nomor Telepon',
  },
  {
    en: 'Type',
    id: 'Tipe',
  },
  {
    en: 'Save Changes',
    id: 'Simpan Perubahan',
  },
  {
    en: 'Location name is required.',
    id: 'Nama Lokasi wajib diisi.',
  },
  {
    en: 'Address is required.',
    id: 'Alamat wajib diisi.',
  },
  {
    en: 'Latitude must be valid.',
    id: 'Koordinat Lintang harus sesuai.',
  },
  {
    en: 'Latitude is required.',
    id: 'Koordinat Lintang wajib diisi.',
  },
  {
    en: 'Latitude must be a number.',
    id: 'Koordinat Lintang harus angka.',
  },
  {
    en: 'Longitude must be valid.',
    id: 'Koordinat Bujur harus sesuai.',
  },
  {
    en: 'Longitude is required.',
    id: 'Koordinat Bujur wajib diisi.',
  },
  {
    en: 'Longitude must be a number.',
    id: 'Koordinat Bujur harus angka.',
  },
  {
    en: 'Longitude must be a number.',
    id: 'Koordinat Bujur harus angka.',
  },
  {
    en: 'Location name must be at most 255 characters.',
    id: 'Nama lokasi maksimal 255 karakter.',
  },
  {
    en: 'Contact name must be at most 50 characters.',
    id: 'Nama kontak maksimal 50 karakter.',
  },
  {
    en: 'Shipper Name is required.',
    id: 'Nama Pengirim wajib diisi.',
  },
  {
    en: 'Shipper is required.',
    id: 'Pengirim wajib diisi.',
  },
  {
    en: 'Location has been created',
    id: 'Lokasi berhasil dibuat',
  },
  {
    en: 'My Location',
    id: 'Lokasi Milik Sendiri',
  },
  {
    en: 'Add Delivery Location',
    id: 'Tambah Lokasi Pengiriman',
  },
  {
    en: 'Edit Delivery Location',
    id: 'Ubah Lokasi Pengiriman',
  },
  {
    en: 'Location has been edited',
    id: 'Lokasi berhasil diubah',
  },
  {
    en: 'Are you sure you want to leave?',
    id: 'Anda yakin ingin meninggalkan halaman?',
  },
  {
    en: 'It looks like you are in the middle of writing something and you have not saved the changes.  Unsaved changes will be lost',
    id: 'Anda sedang melakukan perubahan dan belum menyimpan perubahannya. Perubahan yang belum disimpan akan hilang',
  },
  {
    en: 'Discard Changes',
    id: 'Buang Perubahan',
  },
  {
    en: 'Save and Use Location',
    id: 'Simpan dan Gunakan Lokasi',
  },
  {
    en: 'You have changed the Delivery Location coordinate',
    id: 'Anda telah merubah koordinat lokasi pengiriman',
  },
  {
    en: 'Changing delivery location coordinate will affects Shipper Order and Job Order that used this delivery location. This action can not be undo.',
    id: 'Perubahan koordinat lokasi pengiriman akan mempengaruhi Shipper Order dan Perintah Kerja yang menggunakan lokasi pengiriman ini. Tindakan ini tidak dapat diurungkan.',
  },
  {
    en: 'This will only applied to newly created Job Order.',
    id: 'Pengaturan ini akan diterapkan pada Perintah Kerja baru',
  },
  {
    en: 'This will only applied to newly created Job Order.',
    id: 'Pengaturan ini akan diterapkan pada Perintah Kerja baru',
  },
  {
    en: 'Minimum radius is 1 m.',
    id: 'Batas radius minimal adalah 1 m.',
  },
  {
    en: 'Maximum radius is 999.999 m.',
    id: 'Batas radius maksimal adalah 999.999 m.',
  },
  {
    en: 'Radius is required.',
    id: 'Radius harus diisi.',
  },
  {
    en: 'meters',
    id: 'meter',
  },
  {
    en: 'Site Custom Radius',
    id: 'Custom Titik Radius',
  },
  {
    en: 'Site Radius',
    id: 'Titik Radius',
  },
  {
    en: 'The maximum radius is 999.999 meters',
    id: 'Batas maksimal radius adalah 999.999 meter',
  },
  {
    en: 'Value must be less than or equal to 999.999',
    id: 'Nilai harus kurang dari atau sama dengan 999.999',
  },
];

export default editLocationPageLocaleConfig;
