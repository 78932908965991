import fromUnixTime from 'date-fns/fromUnixTime';
import sortBy from 'lodash/sortBy';
import React from 'react';
import tw from 'twin.macro';
import { getDateFormat } from '../../../util/date.util';
import { numberFormatter } from '../../../util/formatter.util';
import { UseShipperOrderPaymentHistory } from '../../../view/ShipperOrder/hooks/useShipperOrderPaymentHistory.hook';
import { UseShipperOrderTable } from '../../../view/ShipperOrder/hooks/useShipperOrderTable.hook';
import { Button, Divider, Icon, LoadingIndicator, Text } from '../../atom';

// #region TYPES
type ShipperOrderPaymentProps = {
  shipperOrderTable: UseShipperOrderTable;
  shipperOrderPaymentHistory: UseShipperOrderPaymentHistory;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const ListContainer = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-4`;
const ListEmptyContainer = tw.div`flex flex-col h-full justify-center items-center space-y-2`;
const ListItem = tw.div`flex flex-col space-y-2 duration-300 transition-opacity animate-fade-in`;
const ListItemHeader = tw.div`flex items-center justify-between`;
const ListItemHeaderAside = tw.div`flex items-center`;
const ListItemBody = tw.div`flex`;
const PaymentTextContainer = tw.div`flex justify-between mb-2.5`;
// #endregion

export default function ShipperOrderPaymentHistory({
  shipperOrderTable,
  shipperOrderPaymentHistory,
}: ShipperOrderPaymentProps) {
  if (shipperOrderPaymentHistory.paymentHistoryListIsFetching)
    return (
      <Container tw="justify-center items-center">
        <LoadingIndicator />
      </Container>
    );

  return (
    <Container>
      <Header>
        <Text.HeadingFour
          tw="flex-1"
          data-testid={`${shipperOrderPaymentHistory.testID || ''}:DrawerTitle`}
        >
          {shipperOrderPaymentHistory.titleLabel}
        </Text.HeadingFour>

        {shipperOrderPaymentHistory.isEditStep ? (
          <Button.Solid
            small
            tw="px-4"
            onClick={shipperOrderPaymentHistory.onClickCloseEditStep}
            data-testid={`${
              shipperOrderPaymentHistory.testID || ''
            }:DoneAction`}
          >
            {shipperOrderPaymentHistory.titleButtonLabel}
          </Button.Solid>
        ) : (
          <Button.Outlined
            small
            tw="bg-white text-grey-two px-4"
            onClick={shipperOrderPaymentHistory.onClickOpenEditStep}
            data-testid={`${
              shipperOrderPaymentHistory.testID || ''
            }:EditAction`}
          >
            {shipperOrderPaymentHistory.titleButtonLabel}
          </Button.Outlined>
        )}
      </Header>

      <Divider />

      <ListContainer>
        {shipperOrderPaymentHistory.paymentHistoryList &&
        shipperOrderPaymentHistory.paymentHistoryList.length > 0 ? (
          sortBy(shipperOrderPaymentHistory.paymentHistoryList.slice(), [
            'paymentDate',
            'createdAt',
          ]).map((_payment) => (
            <ListItem key={_payment.id}>
              <ListItemHeader>
                <Text.HeadingFive tw="font-semibold">
                  {getDateFormat(fromUnixTime(_payment.paymentDate))}
                </Text.HeadingFive>

                <ListItemHeaderAside>
                  <Text.BodyFourteen>
                    Rp{numberFormatter(_payment.amount)}
                  </Text.BodyFourteen>

                  {shipperOrderPaymentHistory.isEditStep && (
                    <Button.Outlined
                      small
                      tw="bg-white text-grey-two px-4 ml-5"
                      onClick={() =>
                        shipperOrderPaymentHistory.onClickDeletePayment(
                          _payment,
                        )
                      }
                      data-testid={`${
                        shipperOrderPaymentHistory.testID || ''
                      }:DeleteAction`}
                    >
                      {shipperOrderPaymentHistory.deletePaymentButtonLabel}
                    </Button.Outlined>
                  )}
                </ListItemHeaderAside>
              </ListItemHeader>

              {_payment.notes && (
                <ListItemBody>
                  <Text.BodyFourteen>{_payment.notes}</Text.BodyFourteen>
                </ListItemBody>
              )}
            </ListItem>
          ))
        ) : (
          <ListEmptyContainer>
            <Icon.EmptyPayment />
            <Text.HeadingFour tw="text-center">
              {shipperOrderPaymentHistory.emptyTitleLabel}
            </Text.HeadingFour>
            <Text.BodySixteen tw="text-center">
              {shipperOrderPaymentHistory.emptySubtitleLabel}
            </Text.BodySixteen>
          </ListEmptyContainer>
        )}
      </ListContainer>

      <Divider tw="border-4 border-grey-six" />

      <Footer>
        <PaymentTextContainer>
          <Text.HeadingFive tw="font-semibold">
            {shipperOrderPaymentHistory.totalPaymentLabel}
          </Text.HeadingFive>
          <Text.BodyFourteen>
            {shipperOrderPaymentHistory.totalPaymentValue}
          </Text.BodyFourteen>
        </PaymentTextContainer>

        <PaymentTextContainer>
          <Text.HeadingFive>
            {shipperOrderPaymentHistory.deliveryCostLabel}
          </Text.HeadingFive>
          <Text.BodyFourteen tw="text-orange">
            {shipperOrderPaymentHistory.deliveryCostValue}
          </Text.BodyFourteen>
        </PaymentTextContainer>

        <Divider />

        <PaymentTextContainer
          tw="mt-2.5 mb-5"
          css={[shipperOrderPaymentHistory.isEditStep && tw`mb-0`]}
        >
          <Text.HeadingFive>
            {shipperOrderPaymentHistory.remainingCreditLabel}
          </Text.HeadingFive>
          <Text.BodyFourteen tw="font-semibold text-orange">
            {shipperOrderPaymentHistory.remainingCreditValue}
          </Text.BodyFourteen>
        </PaymentTextContainer>

        {!shipperOrderPaymentHistory.isEditStep && (
          <Button.Solid
            type="submit"
            disabled={shipperOrderPaymentHistory.isPaid}
            onClick={shipperOrderTable.onClickOpenAddPaymentHistory}
            data-testid={`${
              shipperOrderPaymentHistory.testID || ''
            }:SubmitAction`}
          >
            {shipperOrderPaymentHistory.addPaymentButtonLabel}
          </Button.Solid>
        )}
      </Footer>
    </Container>
  );
}
