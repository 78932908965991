import useHaulingOrderForceCompleteLogic from '../../hook/haulingOrderForceComplete/useHaulingOrderForceCompleteLogic.hook';
import useHaulingOrderForceCompleteTable from '../../hook/haulingOrderForceComplete/useHaulingOrderForceCompleteTable.hook';
import useTranslator from '../../hook/useTranslator.hook';

export default function useHaulingOrderForceCompleteViewModel() {
  const translator = useTranslator();

  const haulingOrderForceCompleteLogic = useHaulingOrderForceCompleteLogic();

  const haulingOrderForceCompleteTable = useHaulingOrderForceCompleteTable({
    translator,
    haulingOrderForceCompleteLogic,
  });

  return {
    translator,
    haulingOrderForceCompleteLogic,
    haulingOrderForceCompleteTable,
  };
}
