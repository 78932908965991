import React from 'react';
import tw from 'twin.macro';
import { UseHomeOnboardingHookObj } from '../../../hook/home/useHomeOnboarding.hook';
import { Container, Icon, Text } from '../../atom';
import { OnboardingDetails } from '../../molecule';

// #region INTERFACES
type HomeOnboardingProps = {
  homeOnboarding: UseHomeOnboardingHookObj;
};
// #endregion

const componentName = 'HomeOnboarding';
const componentSection = {
  soSection: `${componentName}:SOSection`,
  joSection: `${componentName}:JOSection`,
};
const testIds = {
  root: `${componentName}:Root`,
  container: `${componentName}:Root:Container`,
  soSection: componentSection.soSection,
  soSectionProgresses: `${componentSection.soSection}:Progresses`,
  joSection: componentSection.joSection,
  joSectionProgresses: `${componentSection.joSection}:Progresses`,
};

function HomeOnboarding({ homeOnboarding }: HomeOnboardingProps) {
  return (
    <Container data-testid={testIds.root}>
      <div tw="flex flex-col space-y-6" data-testid={testIds.container}>
        <section
          tw="rounded-lg shadow-card bg-white"
          data-testid={testIds.soSection}
        >
          <div tw="flex flex-col py-5 px-6 border-b border-beige-lines">
            <div tw="flex justify-between items-center">
              <Text.HeadingThree>
                {homeOnboarding.firstSectionTitleLabel}
              </Text.HeadingThree>

              <div
                tw="flex space-x-1"
                data-testid={testIds.soSectionProgresses}
              >
                <span
                  tw="w-12 h-2.5 rounded-l-full bg-grey-five"
                  css={[
                    homeOnboarding.firstSectionProgress >= 1 &&
                      tw`bg-status-success`,
                  ]}
                />
                <span
                  tw="w-12 h-2.5 bg-grey-five"
                  css={[
                    homeOnboarding.firstSectionProgress >= 2 &&
                      tw`bg-status-success`,
                  ]}
                />
                <span
                  tw="w-12 h-2.5 rounded-r-full bg-grey-five"
                  css={[
                    homeOnboarding.firstSectionProgress === 3 &&
                      tw`bg-status-success`,
                  ]}
                />
              </div>
            </div>

            <Text.BodySixteen>
              {homeOnboarding.firstSectionSubtitleLabel}
            </Text.BodySixteen>
          </div>

          <OnboardingDetails.Collapsible
            identity="addShipper"
            details={homeOnboarding.details}
            title={`1. ${homeOnboarding.addShipperTitleLabel}`}
            subtitle={homeOnboarding.addShipperSubtitleLabel}
            checked={homeOnboarding.isAddShipperChecked}
            btnLabel={homeOnboarding.addShipperBtnLabel}
            svg={<Icon.OnboardingShipper tw="w-1/4" />}
            btnOnClick={homeOnboarding.handleClickAddShipper}
          />

          <OnboardingDetails.Collapsible
            identity="addDeliveryLocation"
            details={homeOnboarding.details}
            title={`2. ${homeOnboarding.addDeliveryLocationTitleLabel}`}
            subtitle={homeOnboarding.addDeliveryLocationSubtitleLabel}
            checked={homeOnboarding.isAddDeliveryLocationChecked}
            btnLabel={homeOnboarding.addDeliveryLocationBtnLabel}
            svg={<Icon.OnboardingLocation tw="w-1/4" />}
            btnOnClick={homeOnboarding.handleClickAddLocation}
          />

          <OnboardingDetails.Collapsible
            identity="createShipperOrder"
            details={homeOnboarding.details}
            title={`3. ${homeOnboarding.createShipperOrderTitleLabel}`}
            subtitle={homeOnboarding.createShipperOrderSubtitleLabel}
            checked={homeOnboarding.isCreateShipperOrderChecked}
            btnLabel={homeOnboarding.createShipperOrderBtnLabel}
            btnDisabled={homeOnboarding.isCreateShipperOrderBtnDisabled}
            btnDisabledLabel={homeOnboarding.createShipperOrderDisabledLabel}
            svg={<Icon.OnboardingShipperOrder tw="w-1/4" />}
            btnOnClick={homeOnboarding.handleClickCreateShipperOrder}
          />
        </section>

        <section
          tw="rounded-lg shadow-card bg-white"
          data-testid={testIds.joSection}
        >
          <div tw="flex flex-col py-5 px-6 border-b border-beige-lines">
            <div tw="flex justify-between items-center">
              <Text.HeadingThree>
                {homeOnboarding.secondSectionTitleLabel}
              </Text.HeadingThree>

              <div
                tw="flex space-x-1"
                data-testid={testIds.joSectionProgresses}
              >
                <span
                  tw="w-12 h-2.5 rounded-l-full bg-grey-five"
                  css={[
                    homeOnboarding.secondSectionProgress >= 1 &&
                      tw`bg-status-success`,
                  ]}
                />
                <span
                  tw="w-12 h-2.5 rounded-r-full bg-grey-five"
                  css={[
                    homeOnboarding.secondSectionProgress === 2 &&
                      tw`bg-status-success`,
                  ]}
                />
              </div>
            </div>

            <Text.BodySixteen>
              {homeOnboarding.secondSectionSubtitleLabel}
            </Text.BodySixteen>
          </div>

          <OnboardingDetails.Collapsible
            identity="addDriver"
            details={homeOnboarding.details}
            title={`1. ${homeOnboarding.addDriverTitleLabel}`}
            subtitle={homeOnboarding.addDriverSubtitleLabel}
            checked={homeOnboarding.isAddDriverChecked}
            btnLabel={homeOnboarding.addDriverBtnLabel}
            svg={<Icon.OnboardingDriver tw="w-1/4" />}
            btnOnClick={homeOnboarding.handleClickAddDriver}
          />

          <OnboardingDetails.Collapsible
            identity="createJobOrder"
            details={homeOnboarding.details}
            title={`2. ${homeOnboarding.createJobOrderTitleLabel}`}
            subtitle={homeOnboarding.createJobOrderSubtitleLabel}
            checked={homeOnboarding.isCreateJobOrderChecked}
            btnLabel={homeOnboarding.createJobOrderBtnLabel}
            btnDisabled={homeOnboarding.isCreateJobOrderBtnDisabled}
            btnDisabledLabel={homeOnboarding.createJobOrderDisabledLabel}
            svg={<Icon.OnboardingJobOrder tw="w-1/4" />}
            btnOnClick={homeOnboarding.handleClickCreateJobOrder}
          />
        </section>
      </div>
    </Container>
  );
}

export default HomeOnboarding;
