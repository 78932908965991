import React from 'react';
import { Icon } from '../../component/atom';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type DrivingContestListSearchParams = {
  page?: string; // number
  pageSize?: string; // number
  orderBy?: string; // DrivingContestOrderBy
  status?: string[]; // DrivingContestStatusFilter[]
  driverId?: string;
};

export type DrivingContestListNavigationState = DefaultRouteParam | undefined;

export const drivingContestRoute: RouteConfig = {
  name: 'Driver Contest',
  Component: React.lazy(() => import('./DrivingContest.view')),
  path: '/driver-contest',
  isPrivate: true,
  options: {
    icon: <Icon.DrivingContest />,
    label: 'Driver Contest',
    testID: 'MenuDriverContest',
    event: {
      label: 'Event',
    },
  },
};
