import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { QD } from '../service/api.baseQuery';
import {
  CommonApiListParams,
  CommonApiMetadata,
} from '../service/api.endpoint';
import { AutocompleteType } from './useAutocomplete.hook';
import useDebounce from './useDebounce.hook';

interface CommonObj<T> {
  [key: string]: T[];
}
// biome-ignore lint/suspicious/noConfusingVoidType: void is meant to be ignored, maybe we should upgrade our typescript later
export type ParamProps<T> = CommonApiListParams | T | string | void;

export type ResponseProps<R> = R & CommonApiMetadata;

export interface AutocompleteOptionsData<T> {
  dataKey: string;
  labelKey: keyof T;
  valueKey: keyof T;
  extraLabelKey?: keyof T;
}
export interface FetchMoreAutocompleteProps<T, P, R> {
  options: AutocompleteOptionsData<T>;
  api: UseLazyQuery<QD<ParamProps<P>, ResponseProps<R>>>;
  filterCallback?: (value: T, index?: number, array?: T[]) => boolean;
}

export default function useFetchMoreAutocomplete<T, P, R>({
  options,
  api,
  filterCallback,
}: FetchMoreAutocompleteProps<T, P, R>) {
  const [searchParams, setSearchParams] = useState<string | undefined>(
    undefined,
  );
  const [rows, setRows] = useState<T[]>([]);
  const [page, setPage] = useState(1);
  const debouncedSearchData = useDebounce(searchParams, 500);

  const [fetchData, { data, isFetching, isSuccess }] = api();
  const metadata = useMemo(() => data?.metadata, [data?.metadata]);

  const hasNextPage = useMemo(
    () =>
      metadata
        ? metadata.page * metadata.pageSize < metadata.totalCount
        : false,
    [metadata],
  );

  const refetch = useCallback(() => {
    setPage(1);
    setSearchParams(undefined);
    void fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPage(1);
    void fetchData({
      ...(debouncedSearchData ? { search: debouncedSearchData } : {}),
    });
  }, [debouncedSearchData, fetchData]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TO PREVENT INFINITE LOOP
  useEffect(() => {
    if (isSuccess && data) {
      const res =
        ((data as unknown as CommonObj<T>)[
          options.dataKey
        ] as unknown as T[]) || [];
      setRows((loc) => uniqBy([...(page > 1 ? loc : []), ...res], 'id'));
    }
  }, [data]);

  const dataOptions: AutocompleteType[] = useMemo(
    () =>
      rows
        ? rows.filter(filterCallback || ((item) => item)).map((item) => ({
            label: item[options.labelKey] as unknown as string,
            value: item[options.valueKey] as unknown as string,
            ...(options.extraLabelKey
              ? { extraLabel: item[options.extraLabelKey] as unknown as string }
              : {}),
          }))
        : [],
    [
      filterCallback,
      options.extraLabelKey,
      options.labelKey,
      options.valueKey,
      rows,
    ],
  );

  const handleChangeAutotext = useCallback((val?: string) => {
    setSearchParams(val);
  }, []);

  const handleFetchMore = useCallback(() => {
    if (hasNextPage) {
      void fetchData({
        ...(searchParams ? { search: searchParams } : {}),
        page: page + 1,
      });
      void setPage((v) => v + 1);
    }
  }, [fetchData, hasNextPage, page, searchParams]);

  return {
    rows,
    dataOptions,
    isFetching,
    refetch,
    handleChangeAutotext,
    handleFetchMore,
  };
}
