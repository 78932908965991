import { truncate } from 'lodash';
import { createRef, useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useReactRouterBreadcrumbs, {
  BreadcrumbData,
  BreadcrumbsRoute,
} from 'use-react-router-breadcrumbs';
import { RootState } from '../../app/store/store.app';
import routerConfig from '../../config/router/router.config';
import { helpCenterRoute } from '../../view/HelpCenter/HelpCenter.route';
import useAuthRevoke from '../../view/Wrapper/hooks/useAuthRevoke.hook';
import useRouteCheck from '../useRouteCheck/useRouteCheck.hook';

export type ProfileCardInfo = {
  username: string;
  organizationName: string;
};
type ExtendedRouteProps = BreadcrumbsRoute & { name: string };
type ExtendedBreadcrumbData = BreadcrumbData<string> & {
  isUnclickable?: boolean;
};
export type UseTopBarHookObj = ReturnType<typeof useTopBar>;

function useTopBar() {
  const navigate = useNavigate();
  const { handleAuthRevoke } = useAuthRevoke();

  const routeCheck = useRouteCheck();
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const profileRef = createRef<HTMLDivElement>();
  const crumbs = useReactRouterBreadcrumbs(routerConfig);

  const { isLoggedIn } = useSelector(
    (state: RootState) => state.session,
    shallowEqual,
  );

  const hideTopbar = useMemo(
    () => (routeCheck.isPublic && !isLoggedIn) || routeCheck.isPublicFullWidth,
    [isLoggedIn, routeCheck.isPublic, routeCheck.isPublicFullWidth],
  );

  const [userInfo, setUserInfo] = useState<ProfileCardInfo | undefined>(
    undefined,
  );

  const breadcrumbs = useMemo(
    () =>
      crumbs.map(
        (
          {
            match,
            location,
            breadcrumb,
            isUnclickable,
            key,
          }: ExtendedBreadcrumbData,
          index,
        ) => {
          const route = match.route as unknown as ExtendedRouteProps;
          const routeName = route?.name ?? '';
          const routeBreadcrumb = breadcrumb as string;
          const routePath = route?.path ?? '';
          const url = match.pathname;

          // TODO: use url path instead of "breadcrumb" query params
          // ex: /shipper-order/12345-67890/SO-0180-04-23
          const searchParams = new URLSearchParams(location.search);
          const breadcrumbParams = searchParams.get('breadcrumb');

          // NOTE: customName will always have value from `breadcrumb` value which we get from `useReactRouterBreadcrumbs()`
          // the default value will be based on current route. To override, define `breadcrumb` key in `.route` file
          // ex: /shipper-order , breadcrumb === 'Shipper order'
          // ex: /shipper-order/create , breadcrumb === 'Create'

          const customName =
            routePath.includes(':') &&
            breadcrumbParams &&
            index === crumbs.length - 1
              ? truncate(breadcrumbParams.replace('+', ' '), {
                  length: 16,
                })
              : routeBreadcrumb;

          const customUrl = routePath.includes(':') ? url : key;

          return {
            name: routeName,
            url,
            customName,
            customUrl,
            isUnclickable: isUnclickable || false,
          };
        },
      ),
    [crumbs],
  );

  const onBreadcrumbClick = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate],
  );
  const handleLogoutClick = useCallback(
    (profileInfo: ProfileCardInfo) => {
      handleAuthRevoke();
      setUserInfo(profileInfo);
      setShowProfileDropdown(false);
    },
    [handleAuthRevoke],
  );

  const handleProfileClick = useCallback(() => {
    setShowProfileDropdown((v) => !v);
  }, []);

  const handleCloseProfile = useCallback(() => {
    setShowProfileDropdown(false);
  }, []);

  const handleClickHelp = useCallback(() => {
    navigate(helpCenterRoute.path);
  }, [navigate]);

  return {
    breadcrumbs,
    userInfo,
    profileRef,
    showProfileDropdown,
    hideTopbar,
    onBreadcrumbClick,
    handleClickHelp,
    handleProfileClick,
    handleLogoutClick,
    handleCloseProfile,
  };
}

export default useTopBar;
