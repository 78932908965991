import React from 'react';
import tw, { styled, theme } from 'twin.macro';
import { helpCenterBreadCrumbMapper } from '../../../constant/HelpCenter.constant';
import useHelpCenterBreadcrumb from '../../../hook/useHelpCenterBreadcrumb/useHelpCenterBreadcrumb.hook';
import { ChevronSharp } from '../../atom/Icon/Icon.atom';
import { BodyFourteen } from '../../atom/Text/Text.atom';

const Container = tw.div`flex gap-1 flex-wrap`;
const Content = tw.div`flex items-center gap-1`;
const BreadCrumbLabel = styled(BodyFourteen)(
  ({ isLast }: { isLast?: boolean }) => [
    tw`text-grey-two`,
    !isLast && tw`font-semibold  cursor-pointer`,
  ],
);

export default function HelpCenterBreadcrumb() {
  const { translator, listRoute, forceLang, handleClickBreadcrumb } =
    useHelpCenterBreadcrumb();
  return (
    <Container>
      {listRoute.map((item, idx, arr) => {
        const isLast = idx === arr.length - 1;
        return (
          <Content key={`help-center-breadcrumb-${item}`}>
            <BreadCrumbLabel
              onClick={() => handleClickBreadcrumb(idx, isLast)}
              isLast={isLast}
            >
              {translator.translate(
                helpCenterBreadCrumbMapper(item),
                forceLang,
              )}
            </BreadCrumbLabel>
            {!isLast && (
              <ChevronSharp
                width={20}
                height={20}
                viewBox="0 0 24 22"
                stroke={theme`colors.grey.two`}
              />
            )}
          </Content>
        );
      })}
    </Container>
  );
}
