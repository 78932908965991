// #region IMPORT
import { EndpointTagTypes } from '../../../constant/Types.constant';
import { GetCallCenterListResponse } from '../../../model/CallCenter.model';
import { Builder, QD } from '../../api.baseQuery';
// #endregion

// #region INTERFACES
export type CallCenterEndpoint = {
  getCallCenterList: QD<void, GetCallCenterListResponse>;
};
// #endregion

// #region ENDPOINT
export const callCenterTagTypes: EndpointTagTypes<CallCenterEndpoint> = {
  getCallCenterList: 'callCenter',
};

export const callCenterEndpoint = (builder: Builder): CallCenterEndpoint => ({
  getCallCenterList: builder.query({
    query: () => '/v1/contact-centers.list',
    providesTags: [callCenterTagTypes.getCallCenterList],
    keepUnusedDataFor: 60 * 60 * 24, // cache for 1 day
  }),
});
// #endregion
