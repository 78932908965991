import React from 'react';
import tw, { TwStyle } from 'twin.macro';
import { Text } from '..';

const LabelFormContainer = tw.div`width[180px] margin-right[20px]`;

type Props = {
  label: string;
  isRequired?: boolean;
  position?: 'center' | 'start';
  labelStyle?: false | '' | TwStyle;
};

export default function LabelInput({
  label,
  labelStyle,
  isRequired,
  position,
}: Props) {
  return (
    <LabelFormContainer
      css={[position === 'start'] && tw`align-self[flex-start] mt-3`}
    >
      <Text.BodyFourteen css={[tw`text-grey-three`, labelStyle]}>{`${label} ${
        isRequired ? '*' : ''
      }`}</Text.BodyFourteen>
    </LabelFormContainer>
  );
}
