import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fillPath?: string;
  isExpanded?: boolean;
};

export default function DrivingContest({
  fill = 'none',
  width = '20',
  height = '20',
  viewBox = '0 0 20 20',
  fillPath = 'currentColor',
  isExpanded,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fillPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.016 0l5.821 7.372a7 7 0 108.04-.2L20 0h-2.633l-5.389 6.285a7 7 0 00-.67-.162L6.546 0H4.011l4.753 6.11a7 7 0 00-1.135.304L2.564 0H.016zm6.449 9.464a5 5 0 117.07 7.072 5 5 0 01-7.07-7.072z"
      />
      <path fill={fillPath} d="M13.343 0L11.31 2.341 12.5 4l3.464-4h-2.62z" />

      {!isExpanded && (
        <circle
          cx="17"
          cy="17"
          r="4"
          fill="#F3532E"
          stroke="#fff"
          strokeWidth="2"
        />
      )}
    </svg>
  );
}
