import React from 'react';
import { Icon, TableNoData } from '../../component/atom';
import { BulkActionLayout } from '../../component/template';
import useJobOrderDeleteBulkViewModel from './JobOrderDeleteBulk.viewModel';

export default function JobOrderDeleteBulkView() {
  const { translator, bulkLogic, bulkTable } = useJobOrderDeleteBulkViewModel();

  return (
    <BulkActionLayout
      actionLabel={translator.translate('to start the deletion process.')}
      descriptionLabel={translator.translate(
        'Make sure the list below is the job order list that you want to delete from your organization. Deleted Job Order still can be accessed in Job Order List page.',
      )}
      cancelLabel={translator.translate('Cancel')}
      submitLabel={translator.translate('Delete')}
      doneLabel={translator.translate('Done')}
      table={bulkTable.table}
      entityLength={bulkLogic.jobOrderWithStatusDetail.length}
      showDoneBtn={bulkLogic.showDoneBtn}
      isLoading={bulkLogic.mutationsIsLoading}
      noDataPage={
        <TableNoData
          icon={<Icon.NoDataFound />}
          titleLabel={translator.translate(
            "You don't have any Job Order that you want to delete",
          )}
          descriptionLabel={translator.translate(
            'Try to select some Jobs from Job page',
          )}
          actionLabel={translator.translate('Go back to Job Order page')}
          onClickAction={bulkLogic.handleDone}
        />
      }
      onCancel={bulkLogic.handleDone}
      onSubmit={bulkLogic.handleDeleteBulk}
      onDone={bulkLogic.handleDone}
    />
  );
}
