import React from 'react';
import tw, { styled, TwStyle } from 'twin.macro';
import { LabelInput, Row } from '../../atom';
import TextField from '../TextField/TextField.molecule';

type Props = {
  label?: string;
  isRequired?: boolean;
  id: string;
  values?: string | number;
  error?: string;
  errorBold?: string;
  placeholder?: string;
  disabled?: boolean;
  left?: React.ReactNode;
  right?: React.ReactNode;
  action?: React.ReactNode;
  maxLength?: number;
  isNumber?: boolean;
  decimalScale?: number;
  hasDivider?: boolean;
  inputStyle?: TwStyle;
  onChange: (data?: string) => void;
  onBlur?: (data?: string) => void;
};

const Container = styled.div(({ hasLabel }: { hasLabel?: boolean }) => [
  tw`w-full flex flex-row`,
  hasLabel && tw`w-[calc(100% - 200px)]`,
]);

export default function TextInput({
  label,
  isRequired,
  placeholder,
  error,
  id,
  values,
  disabled,
  left,
  right,
  action,
  maxLength,
  isNumber,
  decimalScale,
  hasDivider,
  inputStyle,
  children,
  errorBold,
  onChange,
  onBlur = () => {},
}: React.PropsWithChildren<Props>) {
  return (
    <>
      <Row hasDivider={hasDivider}>
        {!!label && <LabelInput label={label} isRequired={isRequired} />}
        <Container hasLabel={!!label}>
          <TextField
            inputStyle={inputStyle}
            isNumber={isNumber}
            disabled={disabled}
            placeholder={placeholder}
            id={id}
            left={left}
            right={right}
            decimalScale={decimalScale}
            name={id}
            maxLength={maxLength}
            value={values}
            error={error || ''}
            errorBold={errorBold}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => onBlur(e.target.value)}
          />
          {action && <div tw="mb-auto">{action}</div>}
        </Container>
      </Row>
      {children && <Row>{children}</Row>}
    </>
  );
}
