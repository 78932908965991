import { Locale } from './locale.config';

const reportLocaleConfig: Locale[] = [
  {
    en: 'Try a different date so we can show you some available Driving Behavior Report',
    id: 'Gunakan ketentuan yang lain untuk menampilkan laporan perilaku mengemudi yang tersedia',
  },
  {
    en: 'Try a different date so we can show you some available Shipper Order Report',
    id: 'Gunakan ketentuan yang lain untuk menampilkan laporan Shipper Order yang tersedia',
  },
  {
    en: 'Try a different filter so we can show you some available Job Order Report',
    id: 'Gunakan ketentuan yang lain untuk menampilkan laporan Perintah Kerja yang tersedia',
  },
  {
    en: 'Try a different filter so we can show you some available Net Income Report',
    id: 'Gunakan ketentuan yang lain untuk menampilkan laporan Keuntungan yang tersedia',
  },
  {
    en: 'Try a different date so we can show you some available Revenue Report',
    id: 'Gunakan ketentuan yang lain untuk menampilkan laporan Pendapatan yang tersedia',
  },
  {
    en: 'Number of Delivered HJO',
    id: 'Jumlah HJO Terkirim',
  },
  {
    en: 'Number of Delivered Hauling Job Order',
    id: 'Jumlah Hauling Job Order Terkirim',
  },
  {
    en: 'Finished Time',
    id: 'Waktu Selesai',
  },
  {
    en: 'Pickup Finished Time',
    id: 'Waktu Selesai Pickup',
  },
  {
    en: 'Dropoff Finished Time',
    id: 'Waktu Selesai Dropoff',
  },
  {
    en: 'Start Time',
    id: 'Waktu Mulai',
  },
  {
    en: 'End Time',
    id: 'Waktu Akhir',
  },
  {
    en: 'Finish Time',
    id: 'Waktu Selesai',
  },
  {
    en: 'Mileage',
    id: 'Jarak Tempuh',
  },
  {
    en: 'Finished By',
    id: 'Diselesaikan Oleh',
  },
  {
    en: 'Finishing Method',
    id: 'Metode Selesai',
  },
  {
    en: 'By Admin',
    id: 'Oleh Admin',
  },
  {
    en: 'By Geofence',
    id: 'Oleh Geofence',
  },
  {
    en: 'By User',
    id: 'Oleh User',
  },
  {
    en: 'By Driver',
    id: 'Oleh Sopir',
  },
  {
    en: 'Automatic',
    id: 'Otomatis',
  },
  {
    en: 'Start Date',
    id: 'Tanggal Mulai',
  },
  {
    en: 'End Date',
    id: 'Tanggal Selesai',
  },
  {
    en: 'Vehicle Name',
    id: 'Nama Kendaraan',
  },
  {
    en: 'All Driver',
    id: 'Semua Sopir',
  },
  {
    en: 'Driver Contest Participant',
    id: 'Peserta Driver Contest',
  },
  {
    en: 'Participating in Driver Contest',
    id: 'Berpartisipasi di Driver Contest',
  },
  {
    en: 'Number of Delivered JO',
    id: 'Jumlah PK Terkirim',
  },
  {
    en: 'Number of Delivered Job Order',
    id: 'Jumlah Perintah Kerja Terkirim',
  },
  {
    en: 'Driver Name',
    id: 'Nama Sopir',
  },
  {
    en: 'Total Mileage',
    id: 'Jarak Tempuh',
  },
  {
    en: 'Maximum Speed',
    id: 'Kecepatan Maksimum',
  },
  {
    en: 'Trip Statistic',
    id: 'Data Perjalanan',
  },
  {
    en: 'Total Exceeding Speed Limit',
    id: 'Melebihi Batas Kecepatan',
  },
  {
    en: 'Total Sudden Stops',
    id: 'Berhenti Mendadak',
  },
  {
    en: 'Total Sudden Acceleration',
    id: 'Percepatan Mendadak',
  },
  {
    en: 'Total Harsh Cornering',
    id: 'Menikung Tajam',
  },
  {
    en: 'Date Range Max 31 Days',
    id: 'Maksimal 31 hari',
  },
  {
    en: '*Date Range Max 31 Days',
    id: '*Maksimal 31 hari',
  },
  {
    en: 'Driver Name / Phone Number',
    id: 'Nama Sopir / Nomor HP',
  },
  {
    en: 'Total Working Days',
    id: 'Jumlah Hari Kerja',
  },
  {
    en: 'Filter',
    id: 'Filter',
  },
  {
    en: 'Reset',
    id: 'Reset',
  },
  {
    en: 'Driver',
    id: 'Sopir',
  },
  {
    en: 'Drivers',
    id: 'Sopir',
  },
  {
    en: 'Vehicles',
    id: 'Kendaraan',
  },
  {
    en: 'Choose Driver',
    id: 'Pilih Sopir',
  },
  {
    en: 'Filter Driver',
    id: 'Filter Sopir',
  },
  {
    en: 'Driver Name A-Z',
    id: 'Nama Sopir A-Z',
  },
  {
    en: 'Delivery Duration',
    id: 'Durasi Pengiriman',
  },
  {
    en: 'Delivered Time',
    id: 'Waktu Terkirim',
  },
  {
    en: 'Delivered Time',
    id: 'Waktu Terkirim',
  },
  {
    en: 'Pickup Activities',
    id: 'Aktivitas Pickup',
  },
  {
    en: 'Dropoff Activities',
    id: 'Aktivitas Dropoff',
  },
  {
    en: 'No Pickup Goods',
    id: 'Tidak ada barang pickup',
  },
  {
    en: 'No Dropoff Goods',
    id: 'Tidak ada barang dropoff',
  },
  {
    en: 'No Pickup Location',
    id: 'Tidak ada lokasi pickup',
  },
  {
    en: 'No Dropoff Location',
    id: 'Tidak ada lokasi dropoff',
  },
  {
    en: 'No Dropoff Activity',
    id: 'Tidak ada aktivitas dropoff',
  },
  {
    en: 'Location',
    id: 'Lokasi',
  },
  {
    en: 'Locations',
    id: 'Lokasi',
  },
  {
    en: 'Locations',
    id: 'Lokasi',
  },
  {
    en: 'Download is starting',
    id: 'Mengunduh dimulai',
  },
  {
    en: 'SO Date represent Shipper Order Date inputted by user while creating Shipper Order',
    id: 'Tanggal SO merupakan Tanggal Shipper Order yang dimasukkan oleh pengguna saat membuat Shipper Order',
  },
  {
    en: 'times',
    id: 'waktu',
  },
  {
    en: 'Actual Finish Time',
    id: 'Waktu Selesai Aktual',
  },
  {
    en: 'Total Delivered Order',
    id: 'Jumlah Order Terkirim',
  },
  {
    en: 'Invoice Amount',
    id: 'Jumlah Tagihan',
  },
  {
    en: 'Payment',
    id: 'Pembayaran',
  },
  {
    en: 'Receivables Amount',
    id: 'Jumlah Piutang',
  },
  {
    en: 'Show Delivery',
    id: 'Tunjukkan Pengiriman',
  },
  {
    en: 'You can search by Vehicle Name or Driver Name',
    id: 'Cari menggunakan Kendaraan atau Nama Sopir',
  },
  {
    en: 'You can search by JO Number, Vehicle Name or Driver Name',
    id: 'Cari menggunakan Nomor JO, Kendaraan atau Nama Sopir',
  },
  {
    en: 'You can search by Phone Number, Vehicle Name, or Driver Name',
    id: 'Cari menggunakan Nomor Telepon, Kendaraan, atau Nama Sopir',
  },
  {
    en: 'Job Orders',
    id: 'Perintah Kerja',
  },
  {
    en: 'JO Delivered Time',
    id: 'Waktu Selesai JO',
  },
  {
    en: 'Expense',
    id: 'Pengeluaran',
  },
  {
    en: 'Good Delivery Cost',
    id: 'Biaya Kirim Barang',
  },
  {
    en: 'Total Travel Budget',
    id: 'Total Uang Jalan',
  },
  {
    en: 'Number of Delivered Order',
    id: 'Jumlah Order Terkirim',
  },
  {
    en: 'Trip Statistics',
    id: 'Data Perjalanan',
  },
  {
    en: 'Total Mileage',
    id: 'Jarak Tempuh',
  },
  {
    en: 'There is no Report',
    id: 'Belum Ada Laporan',
  },
  {
    en: 'Report will appear once new data is added.',
    id: 'Laporan akan muncul setelah data baru ditambahkan.',
  },
  {
    en: 'Total Speed Limit Exceeded',
    id: 'Melebihi Batas Kecepatan',
  },
  {
    en: 'Total Sudden Accelerations',
    id: 'Percepatan Mendadak',
  },
  {
    en: 'Total Harsh Cornering',
    id: 'Menikung Tajam',
  },
  {
    en: 'Vehicle Names',
    id: 'Nama Kendaraan',
  },
  {
    en: 'Driving Behaviour by Schedule',
    id: 'Perilaku Mengemudi (Jadwal)',
  },
  {
    en: 'Driving Behaviour by Job Order',
    id: 'Perilaku Mengemudi (Perintah Kerja)',
  },
  {
    en: 'Driving Behaviour by Vehicle',
    id: 'Perilaku Mengemudi (Kendaraan)',
  },
  {
    en: 'Total Delivery Order',
    id: 'Jumlah Order Terkirim',
  },
  {
    en: 'Total Delivered HJO',
    id: 'Jumlah HJO Terkirim',
  },
  {
    en: 'Total Delivered JO',
    id: 'Jumlah PK Terkirim',
  },
  {
    en: 'Total Job Order Delivered',
    id: 'Jumlah Perintah Kerja Terkirim',
  },
  {
    en: 'Top Driver',
    id: 'Sopir Terbaik',
  },
  {
    en: 'Top Vehicle',
    id: 'Kendaran Terbaik',
  },
  {
    en: 'Average Mileage',
    id: 'Jarak Tempuh Rata-rata',
  },
  {
    en: 'Compared Period:',
    id: 'Periode Pembanding:',
  },
  {
    en: 'Compared Value:',
    id: 'Nilai Pembanding:',
  },
  {
    en: 'Report Detail',
    id: 'Detil Report',
  },
  {
    en: 'Average Mileage',
    id: 'Jarak Tempuh Rata-rata',
  },
  {
    en: 'Average Maximum Speed',
    id: 'Rata-rata Kecepatan Maksimal',
  },
  {
    en: 'Total Hauling Job Order Delivered',
    id: 'Jumlah Hauling Job Order Terkirim',
  },
  {
    en: 'Try a different search terms so we can show you some available Driving Behavior by Schedule',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Perilaku Mengemudi (Jadwal) yang tersedia',
  },
  {
    en: 'Try adding Schedule in the Desktop version',
    id: 'Tambahkan Jadwal pada Versi Desktop',
  },
  {
    en: 'You can search by Phone Number, or Driver Name',
    id: 'Cari menggunakan Nomor Telepon, atau Nama Sopir',
  },
  {
    en: 'Total number of distance traveled by the vehicle',
    id: 'Jumlah jarak yang ditempuh oleh kendaraan',
  },
  {
    en: 'Maximum speed traveled by the vehicle',
    id: 'Maksimum kecepatan oleh kendaraan',
  },
  {
    en: 'The total number of times the vehicle exceeds the speed limit',
    id: 'Jumlah berapa kali kendaraan melebihi batas kecepatan',
  },
  {
    en: 'The total number of sudden stops by vehicle',
    id: 'Jumlah berapa kali kendaraan melakukan pengereman mendadak',
  },
  {
    en: 'The total number of sudden accelerations by vehicle',
    id: 'Jumlah berapa kali kendaraan melakukan percepatan mendadak',
  },
  {
    en: 'The total number of harsh cornering by vehicle',
    id: 'Jumlah berapa kali kendaraan melakukan tikungan tajam',
  },
  {
    en: 'Percentage of goods sent based on goods delivery target',
    id: 'Presentase barang terkirim berdasarkan target pengiriman barang',
  },
  {
    en: 'The amount that must be paid by the shipper',
    id: 'Jumlah tagihan yang harus dibayarkan oleh pengirim',
  },
  {
    en: 'The remaining amount that must be paid by the shipper',
    id: 'Jumlah sisa tagihan yang harus dibayarkan oleh pengirim',
  },
  {
    en: 'Total amount of goods delivery cost from all shipper order',
    id: 'Jumlah total dari biaya pengiriman semua shipper order',
  },
  {
    en: 'Active Order',
    id: 'Order Aktif',
  },
  {
    en: 'Completed Order',
    id: 'Order Selesai',
  },
  {
    en: 'Pickup On Time',
    id: 'Pickup Tepat Waktu',
  },
  {
    en: 'Achieved',
    id: 'Tercapai',
  },
  {
    en: 'The total number of times from all vehicles that make a harsh cornering',
    id: 'Jumlah total semua kendaraan yang menikung tajam',
  },
  {
    en: 'The total number of times from all vehicles that make a sudden acceleration',
    id: 'Jumlah total semua kendaraan yang  berakselerasi tiba-tiba',
  },
  {
    en: 'The total number of times from all the vehicles that exceeds the speed limit',
    id: 'Jumlah total semua kendaraan yang melebihi batas kecepatan',
  },
  {
    en: 'The total number of times from all vehicles that make a sudden stop',
    id: 'Jumlah total semua kendaraan yang berhenti mendadak',
  },
  {
    en: 'The average maximum speed of all registered vehicles',
    id: 'Kecepatan maksimum rata-rata semua kendaraan',
  },
  {
    en: 'Average mileage from all vehicles registered',
    id: 'Jarak tempuh rata-rata dari semua kendaraan',
  },
  {
    en: 'Dropoff On Time',
    id: 'Dropoff Tepat Waktu',
  },
  {
    en: 'This percentage is based on on-time Pickup activities compared to the total number of Pickup activities that have an Estimated Time.',
    id: 'Persentase ini berdasarkan aktivitas Pickup tepat waktu dibandingkan dengan jumlah total aktivitas Pickup yang memiliki Perkiraan Waktu.',
  },
  {
    en: 'This percentage is based on on-time Dropoff activities compared to the total number of Dropoff activities that have an Estimated Time.',
    id: 'Persentase ini berdasarkan aktivitas Dropoff tepat waktu dibandingkan dengan jumlah total aktivitas Dropoff yang memiliki Perkiraan Waktu.',
  },
];

export default reportLocaleConfig;
