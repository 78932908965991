import startOfToday from 'date-fns/startOfToday';
import subDays from 'date-fns/subDays';
import { ReportHaulingOrderFilterOrderByEnum } from '../../model/ReportHaulingOrder.model';
import { HOFilterStatusEnum } from '../../types/haulingOrder.type';
import { ReportJobOrderFilterShowDeliveryEnum } from '../ReportJobOrder.constant';

// #region TYPES
export type ReportHOFilterOrderByValue = {
  label: string;
  value: ReportHaulingOrderFilterOrderByEnum;
};

export type ReportHOFilterInitialValues = {
  orderBy: ReportHaulingOrderFilterOrderByEnum;
  showDelivery: ReportJobOrderFilterShowDeliveryEnum; // the same as in report JO
  status: HOFilterStatusEnum[];
  shipperId?: string;
  startDate: Date;
  endDate: Date;
};
// #endregion

export const reportHODateRangeFilterInitialValues: Pick<
  ReportHOFilterInitialValues,
  'startDate' | 'endDate'
> = {
  startDate: subDays(startOfToday(), 6),
  endDate: startOfToday(),
};

export const reportHOFilterInitialValues: ReportHOFilterInitialValues = {
  orderBy: ReportHaulingOrderFilterOrderByEnum.UPDATE_DESC,
  showDelivery: 0,
  status: Object.values(HOFilterStatusEnum),
  shipperId: undefined,
  ...reportHODateRangeFilterInitialValues,
};

export const reportHaulingOrderMaxDateRangeInDays = 366;
