import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MAX_SO_CANDIDATE, SelectionSO } from '../../constant';

type Props = {
  initSelectedSO: SelectionSO[];
};

export default function useJOFormSOController({ initSelectedSO }: Props) {
  const soSelectionRef = useRef<SelectionSO[]>([]);
  const [init, setInit] = useState(true);
  const [selectedSO, setSelectedSO] = useState<SelectionSO[]>([]);
  const [selectionSOList, setSelectionSOList] = useState<SelectionSO[]>([]);
  const selectedSOIds: string[] = useMemo(
    () => selectedSO.map((item) => item.id),
    [selectedSO],
  );

  // #region CONTROLLER
  const handleSetSelectedSO = useCallback((data: SelectionSO[]) => {
    setSelectedSO((so) => {
      const restCount = MAX_SO_CANDIDATE - so.length;
      if (!restCount) return so;
      return [...so, ...data.slice(0, restCount)];
    });
  }, []);

  const handleClickCheckbox = useCallback(
    (shipperOrder: SelectionSO, isCandidate?: boolean): void => {
      const isExist = selectedSOIds.includes(shipperOrder.id);
      if (isExist) {
        setSelectedSO((so) => so.filter((item) => item.id !== shipperOrder.id));
        return;
      }
      if (
        isCandidate &&
        selectionSOList.length + selectedSOIds.length >= MAX_SO_CANDIDATE
      )
        return;
      handleSetSelectedSO([shipperOrder]);
    },
    [handleSetSelectedSO, selectedSOIds, selectionSOList?.length],
  );

  const handleClearSelectedSO = () => {
    setSelectedSO([]);
  };

  const handleDeselectAll = useCallback((SOList: SelectionSO[]) => {
    const selectedSOListId = SOList.map((data) => data.id);
    setSelectedSO((so) =>
      so.filter((item) => !selectedSOListId.includes(item.id)),
    );
  }, []);

  const handleSelectAll = useCallback(
    (SOList: SelectionSO[]) => {
      const formattedData = SOList.filter(
        (shipperOrder: SelectionSO) => !selectedSOIds.includes(shipperOrder.id),
      );
      handleSetSelectedSO(formattedData);
    },
    [handleSetSelectedSO, selectedSOIds],
  );

  // #endregion

  useEffect(() => {
    if (!init || !initSelectedSO.length) return;
    setSelectionSOList(initSelectedSO);
    setInit(false);
  }, [initSelectedSO, init]);

  // synchronize `selectionSOList` when `initSelectedSO` changes
  useEffect(() => {
    if (!isEqual(soSelectionRef.current, initSelectedSO)) {
      setInit(true);
      soSelectionRef.current = initSelectedSO;
    }
  }, [initSelectedSO]);

  const selectionSODimensionTotal = useMemo(() => {
    const reducer =
      (key: keyof SelectionSO) => (acc: number, data: SelectionSO) =>
        acc + Number(data[key] || 0);
    return {
      weight: selectionSOList.reduce(reducer('totalGoodsWeight'), 0),
      volume: selectionSOList.reduce(reducer('totalGoodsVolume'), 0),
    };
  }, [selectionSOList]);

  return {
    selectedSO,
    selectedSOIds,
    selectionSOList,
    selectionSODimensionTotal,
    setSelectionSOList,
    handleClickCheckbox,
    handleClearSelectedSO,
    handleDeselectAll,
    handleSelectAll,
  };
}

export type UseJOFormSOControllerHookObj = ReturnType<
  typeof useJOFormSOController
>;
