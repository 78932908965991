import { nanoid } from '@reduxjs/toolkit';
import React, { Fragment, useMemo } from 'react';
import tw from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import { SOStatus } from '../../../constant';
import {
  hoTrackingStages,
  soTrackingStages,
} from '../../../constant/tracking/tracking.constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { HaulingOrderStatusEnum } from '../../../model/HaulingOrder.model';
import { Icon, Text } from '../../atom';

const Container = tw.div`flex text-center `;
const StageWrapper = tw.div`flex relative flex-row`;
const PathContainer = tw.div`flex-1 relative`;
const Path = tw.div`bg-beige-bg w-full z-0 h-1 absolute transform translate-y-2.5`;
const Point = tw.div`w-3 h-3 rounded-full bg-white border-beige-lines border-2 z-10 justify-center items-center flex relative`;
const PointGroup = tw.div`flex flex-col items-center justify-center h-6`;
const PointLabelContainer = tw.div``;
const LabelContainer = tw.div`flex justify-center`;
type BaseTrackingHeader = {
  translator: UseTranslator;
  forceLang?: SupportedLanguage;
};

type HOTrackingHeader = {
  type: 'ho';
  status: HaulingOrderStatusEnum;
} & BaseTrackingHeader;

type SOTrackingHeader = {
  type: 'so';
  status: SOStatus;
} & BaseTrackingHeader;

type Props = SOTrackingHeader | HOTrackingHeader;
function TrackingHeader({ status, translator, forceLang, type }: Props) {
  const stages = useMemo(() => {
    if (type === 'ho') return hoTrackingStages;
    return soTrackingStages;
  }, [type]);
  const currentStage = useMemo(() => {
    let index = null;

    if (type === 'ho') {
      index = hoTrackingStages.findIndex((v) => v.status.includes(status));
    } else {
      index = soTrackingStages.findIndex((v) => v.soStatus.includes(status));
    }

    if (index !== -1) return index;
    return 0;
  }, [status, type]);

  return (
    <Container>
      {stages.map((stage, idx) => (
        <Fragment key={nanoid()}>
          <StageWrapper key={stage.label} css={[idx === 0 && tw`items-start`]}>
            <PointLabelContainer>
              <PointGroup>
                <Path
                  css={[
                    tw`translate-y-0 w-1/2`,
                    idx === 0 && tw`translate-x-1/2`,
                    idx > 0 && idx < stages.length && tw`-translate-x-1/2`,
                    idx <= currentStage && tw`bg-orange-hover`,
                  ]}
                />
                <Point
                  css={[
                    idx < currentStage && tw`bg-orange border-orange`,
                    idx === currentStage && tw`bg-orange border-orange w-6 h-6`,
                  ]}
                >
                  {idx === currentStage && <Icon.BoxWireframe />}
                </Point>
                {idx < stages.length - 1 && (
                  <Path
                    css={[
                      tw`translate-y-0 w-1/2`,
                      idx === 0 && tw`translate-x-1/2`,
                      idx > 0 && idx < stages.length && tw`translate-x-1/2`,
                      idx >= 0 &&
                        idx <= currentStage - 1 &&
                        tw`bg-orange-hover`,
                    ]}
                  />
                )}
              </PointGroup>
              <LabelContainer>
                <Text.BodyFourteen tw="flex w-[90%]">
                  {translator.translate(stage.label, forceLang || undefined)}
                </Text.BodyFourteen>
              </LabelContainer>
            </PointLabelContainer>
          </StageWrapper>
          {idx < stages.length - 1 && (
            <PathContainer>
              <Path
                css={[
                  idx < currentStage && tw`bg-orange-hover border-orange-hover`,
                ]}
              />
            </PathContainer>
          )}
        </Fragment>
      ))}
    </Container>
  );
}

export default TrackingHeader;
