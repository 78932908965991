import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { DateRangeOption } from '../../../..';
import { homeDateRangeOptions } from '../../../constant/Home.constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { ChartBarProps, ChartLineProps } from '../../../model/Summary.model';
import { Text } from '../../atom';
import { Dropdown, Legend } from '../../molecule';
import RevenueLineChart from '../../molecule/RevenueLineChart/RevenueLineChart.molecule';
import SummaryBarChart from '../../molecule/SummaryBarChart/SummaryBarChart.molecule';

const Container = tw.div`grid p-4 gap-4 relative grid-cols-1`;
const Header = tw.header`flex items-center justify-between`;

type Props = {
  title: string;
  dateLabel: string;
  data: ChartBarProps | ChartLineProps;
  onDateChange(dates: DateRangeOption): void;
  translator: UseTranslator;
};

function HomeChart({
  title,
  data,
  onDateChange,
  translator,
  dateLabel,
}: Props) {
  const renderChart = useMemo(() => {
    if (data.type === 'bar')
      return <SummaryBarChart data={data.data} filterLabel={dateLabel} />;

    return <RevenueLineChart data={data.data} filterLabel={dateLabel} />;
  }, [data.data, data.type, dateLabel]);
  return (
    <Container>
      <Header>
        <Text.HeadingThree>{translator.translate(title)}</Text.HeadingThree>
        <Dropdown
          selectedLabel={translator.translate(dateLabel)}
          options={homeDateRangeOptions}
          onSelected={(selected) => {
            onDateChange(selected);
          }}
          testID={`Dashboard:${data.type === 'bar' ? 'Bar' : 'Line'}Chart`}
        />
      </Header>

      <div tw="flex">
        {data.legends.map((v) => (
          <Legend
            key={v.label}
            label={translator.translate(v.label)}
            color={v.color}
          />
        ))}
      </div>

      <div tw="flex h-[300px] w-full overflow-hidden">{renderChart}</div>
    </Container>
  );
}

export default React.memo(HomeChart);
