import addDays from 'date-fns/addDays';
import startOfToday from 'date-fns/startOfToday';
import { TableHeaderItem } from '../component/molecule/ActivityTableHeader/ActivityTableHeader.molecule';
import { ReportShipperOrder } from '../model/ReportShipperOrder.model';

// #region INTERFACES
export enum ReportSOOrderBy {
  DATE_ASC = 'DATE_ASC', // SO Date A-Z
  DATE_DESC = 'DATE_DESC', // SO Date Z-A
  NUMBER_ASC = 'NUMBER_ASC', // SO Number A-Z
  NUMBER_DESC = 'NUMBER_DESC', // SO Number Z-A
  DELIVERED_TIME_ASC = 'DELIVERED_TIME_ASC', // Delivered Time A-Z
  DELIVERED_TIME_DESC = 'DELIVERED_TIME_DESC', // Delivered Time Z-A
}

export type ReportSOOrderByValue = {
  label: string;
  value: ReportSOOrderBy;
};

export enum ReportSOOrderStatus {
  IN_PROCESS = 'IN_PROCESS',
  RESERVED = 'RESERVED',
  ASSIGNED = 'ASSIGNED',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
  IN_TRANSIT = 'IN_TRANSIT',
  TRANSITING = 'TRANSITING',
}

export type ReportSOOrderStatusValue = {
  label: string;
  value: ReportSOOrderStatus;
};

export type ReportSOActivityGood = {
  id: string; // for mapping `key` props
  locationName: string | undefined;
  type: string | null;
  qty: number | null;
  weight: number | null;
  volume: number | null;
};

export type ReportSOActivityTime = {
  id: string; // for mapping `key` props
  locationName: string | undefined;
  expectedFinishedAt: string;
  completedAt: string;
  isBadgeVisible?: boolean;
  isLate?: boolean;
};

export type ReportSOSelectedModal = {
  type: 'location' | 'time';
  data: ReportShipperOrder;
};

export type ReportSOSelectedModalLocationValue = {
  modalTitle: string;
  modalTableHeader: TableHeaderItem[];
  pickupGoods: ReportSOActivityGood[];
  dropoffGoods: ReportSOActivityGood[];
};

export type ReportSOSelectedModalExpectedTimeValue = {
  modalTitle: string;
  modalTableHeader: TableHeaderItem[];
  pickupExpectedTimes: ReportSOActivityTime[];
  dropoffExpectedTimes: ReportSOActivityTime[];
};

export type ReportSOSelectedModalValue =
  | ReportSOSelectedModalLocationValue
  | ReportSOSelectedModalExpectedTimeValue
  | undefined;

export type ReportSOFormInitialValues = {
  orderBy: ReportSOOrderBy;
  status: ReportSOOrderStatus[];
  startDate: Date;
  endDate: Date;
  shipperId?: string;
  driverId?: string;
  vehicleId?: string;
};
// #endregion

export const reportSOFormDateRangeInitialValues: Pick<
  ReportSOFormInitialValues,
  'startDate' | 'endDate'
> = {
  startDate: addDays(startOfToday(), -6),
  endDate: startOfToday(),
};

export const reportSOFormInitialValues: ReportSOFormInitialValues = {
  ...reportSOFormDateRangeInitialValues,
  orderBy: ReportSOOrderBy.DATE_DESC,
  status: Object.values(ReportSOOrderStatus),
  shipperId: undefined,
  driverId: undefined,
  vehicleId: undefined,
};

export const reportSOMaxDateRangeInDays = 366;
