// #region IMPORT
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  DeactivateShipperRequest,
  DeactivateShipperResponse,
  ExportShipperApiRequest,
  ExportShipperApiResponse,
  GetActiveShipperApiRequest,
  GetActiveShipperApiResponse,
  GetShipperInfoApiRequest,
  GetShipperInfoApiResponse,
  GetShippersApiRequest,
  GetShippersApiResponse,
  GetShippersCountApiRequest,
  GetShippersCountApiResponse,
  PostShipperCreateParams,
  PostShipperCreateResponse,
  UpdateShipperRequest,
  UpdateShipperResponse,
} from '../../../model/Shipper.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, MD, QD } from '../../api.baseQuery';
// #endregion

// #region INTERFACES

export type ShipperEndpoint = {
  getShipperInfo: QD<GetShipperInfoApiRequest, GetShipperInfoApiResponse>;
  getShippersCount: QD<GetShippersCountApiRequest, GetShippersCountApiResponse>;
  getShippers: QD<GetShippersApiRequest, GetShippersApiResponse>;
  getActiveShippers: QD<
    GetActiveShipperApiRequest,
    GetActiveShipperApiResponse
  >;
  createShipper: MD<PostShipperCreateParams, PostShipperCreateResponse>;
  deactivateShipper: MD<DeactivateShipperRequest, DeactivateShipperResponse>;
  updateShipper: MD<UpdateShipperRequest, UpdateShipperResponse>;
  exportShipper: MD<ExportShipperApiRequest, ExportShipperApiResponse>;
};
// #endregion

// #region ENDPOINT
export const shipperTagTypes: EndpointTagTypes<ShipperEndpoint> = {
  getShipperInfo: '',
  getShippersCount: '',
  getShippers: 'shipper',
  getActiveShippers: '',
  createShipper: '',
  deactivateShipper: '',
  updateShipper: '',
  exportShipper: '',
};

export const shipperEndpoint = (builder: Builder): ShipperEndpoint => ({
  getShipperInfo: builder.query({
    query: (param) => urlcat('/v1/shippers.info', toSnakeCase(param)),
  }),
  getShippersCount: builder.query({
    query: (param) => urlcat('/v1/shippers.count', toSnakeCase(param)),
    providesTags: [shipperTagTypes.getShippers],
  }),
  getShippers: builder.query({
    query: (param) => urlcat('/v1/shippers.list', toSnakeCase(param)),
    providesTags: (result) =>
      result
        ? [
            ...result.shippers.map(({ id }) => ({
              type: shipperTagTypes.getShippers,
              id,
            })),
            shipperTagTypes.getShippers,
          ]
        : [shipperTagTypes.getShippers],
  }),
  getActiveShippers: builder.query({
    query: (param) => urlcat('/v1/shippers.active.list', toSnakeCase(param)),
  }),
  createShipper: builder.mutation({
    query: (body) => ({ url: '/v1/shippers.create', body, method: 'post' }),
    invalidatesTags: [shipperTagTypes.getShippers],
  }),
  deactivateShipper: builder.mutation({
    query: (body) => ({ url: '/v1/shippers.deactivate', body, method: 'post' }),
    invalidatesTags: [shipperTagTypes.getShippers],
  }),
  updateShipper: builder.mutation({
    query: (body) => ({
      url: '/v1/shippers.update',
      body,
      method: 'post',
    }),
    invalidatesTags: [shipperTagTypes.getShippers],
  }),
  exportShipper: builder.mutation({
    query: (body) => ({ url: '/v1/shippers.export', body, method: 'post' }),
  }),
});
// #endregion
