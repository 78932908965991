import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportedLanguage } from '../config/locale/locale.config';
import {
  settingInitialValues,
  SettingNavigationItemType,
} from '../constant/Setting.constant';
import {
  IOrganizationOrientation,
  IOrganizationSetting,
} from '../model/Organization.model';

export interface SettingStore {
  currentLanguage: SupportedLanguage;
  showModal?: SettingNavigationItemType;
  organization: IOrganizationSetting;
  orientation?: IOrganizationOrientation;
  isResetting: boolean;
}

const initialState: SettingStore = {
  currentLanguage: SupportedLanguage.en,
  showModal: undefined,
  organization: settingInitialValues,
  orientation: undefined,
  isResetting: false,
};

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    toggleModal: (
      state,
      action: PayloadAction<SettingNavigationItemType | undefined>,
    ) => ({
      ...state,
      showModal: action.payload,
    }),
    changeLanguage: (state, action: PayloadAction<SupportedLanguage>) => ({
      ...state,
      currentLanguage: action.payload,
    }),
    changeOrganizationSetting: (
      state,
      action: PayloadAction<IOrganizationSetting>,
    ) => ({
      ...state,
      organization: action.payload,
    }),
    changeIsResetSetting: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isResetting: action.payload,
    }),
    changeOrientationSetting: (
      state,
      action: PayloadAction<IOrganizationOrientation | undefined>,
    ) => ({
      ...state,
      orientation: action.payload,
    }),
  },
});

export const settingAction = settingSlice.actions;
export const settingReducer = settingSlice.reducer;
