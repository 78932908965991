import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterDataManagementDriverRouteParam = DefaultRouteParam;

export const helpCenterDataManagementDriverRoute: RouteConfig = {
  name: 'Help Center Data Management Driver',
  Component: React.lazy(() => import('./HelpCenterDataManagementDriver.view')),
  path: '/help-center/data-management/driver',
  isPrivate: false,
  breadcrumb: 'Help Center Data Management Driver',
  options: {
    label: 'Sopir',
    testID: 'MenuHelpCenterDataManagementDriver',
  },
};
