import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterDriverAppPerformanceReportRouteParam = DefaultRouteParam;

export const helpCenterDriverAppPerformanceReportRoute: RouteConfig = {
  name: 'Help Center Driver App Performance Report',
  Component: React.lazy(
    () => import('./HelpCenterDriverAppPerformanceReport.view'),
  ),
  path: '/help-center/driver-app/performance-report',
  isPrivate: false,
  breadcrumb: 'Help Center Driver App Performance Report',
  options: {
    label: 'Melihat Laporan Performa',
    testID: 'MenuHelpCenterDriverAppPerformanceReport',
  },
};
