import React from 'react';
import 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { LoadingIndicator } from '../../atom';

// #region INTERFACES
interface Props {
  hasNextPage: boolean;
  size: number;
  translator: UseTranslator;
}
// #endregion

export default function VirtualItemLoaderRow({
  hasNextPage,
  size,
  translator,
}: Props) {
  if (hasNextPage)
    return (
      <div
        tw="w-full"
        style={{
          height: `${size + 25}px`,
        }}
      >
        <div tw="w-full h-full flex items-center justify-center">
          <LoadingIndicator size="small" />
        </div>
      </div>
    );

  return (
    <div tw="w-full" style={{ height: `${size}px` }}>
      <div tw="w-full h-full flex items-center justify-center">
        {translator.translate('Nothing more to load')}
      </div>
    </div>
  );
}
