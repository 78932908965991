import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RedirectionStore {
  pathName: string;
  search: string;
  blockedRoute: string;
  isHelpCenterRefValid?: boolean;
}

const initialState: RedirectionStore = {
  pathName: '',
  search: '',
  blockedRoute: '',
  isHelpCenterRefValid: false,
};

const redirectionSlice = createSlice({
  name: 'redirection',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<RedirectionStore>>) => ({
      ...state,
      ...action.payload,
    }),
    setHelpCenterRefValidity: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isHelpCenterRefValid: action.payload,
    }),
    clear: () => ({
      ...initialState,
    }),
  },
});

export const redirectionAction = redirectionSlice.actions;
export const redirectionReducer = redirectionSlice.reducer;
