import delay from 'lodash/delay';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import { SettingNavigationItemType } from '../../../constant/Setting.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import api from '../../../service/api.service';
import { GenerateTokenResetOrganizationResponse } from '../../../service/endpoint/organization/organization.endpoint';
import { settingAction } from '../../../store/setting.store';
import { snackbarAction } from '../../../store/snackbar.store';
import {
  drivingContestQueryParams,
  hjoQueryParams,
  hoQueryParams,
  joQueryParams,
  soQueryParams,
} from '../../../util/setting/setting.util';
import { organizationResetRoute } from '../../OrganizationReset/OrganizationReset.route';

type Props = {
  currentMenu: SettingNavigationItemType;
  toggleModal: (menu?: SettingNavigationItemType) => void;
};

export default function useSettingResetData({
  currentMenu,
  toggleModal,
}: Props) {
  const dispatch = useDispatch();
  const { translate } = useTranslator();
  const navigate = useNavigate();

  const submitTimerId = useRef<number | undefined>(undefined);

  const [confirmationText, setConfirmationText] = useState('');
  const [debouncedConfirmationText, setDebouncedConfirmationText] =
    useState<string>('');
  const [debouncedTimerId, setDebouncedTimerId] = useState<
    number | undefined
  >();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  // #region LABEL
  const title = translate('Organization Data Reset');
  const description = translate(
    'To perform data reset, these conditions must be fulfilled :',
  );
  const alertLabel = translate(
    'Some of the conditions to perform this action is not fulfilled. You can try again later after all conditions is achieved.',
  );
  const confirmationLabel = translate('Please type');
  const confirmationAdditionalLabel = translate('to confirm.');
  const confirmationKeyword = translate('DELETE ALL DATA');
  const confirmationPlaceholder = translate('Confirmation Text');
  const errorLabel = translate('Confirmation word does not match');
  // #endregion

  // #region API
  const [triggerGenerateResetToken, generateResetTokenResponse] =
    api.useGenerateTokenResetOrganizationMutation();
  const [triggerResetOrganization, resetOrganizationResponse] =
    api.usePostResetOrganizationMutation();

  const { isSOMetCondition, isSOCountFetching } =
    api.useGetShipperOrdersCountQuery(soQueryParams(), {
      skip: !isModalVisible,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isFetching }) => ({
        isSOCountFetching: isFetching,
        isSOMetCondition: !data?.shipperOrders.totalCount,
      }),
    });

  const { isHOMetCondition, isHOCountFetching } =
    api.useGetHaulingOrderListCountQuery(hoQueryParams(), {
      skip: !isModalVisible,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isFetching }) => ({
        isHOCountFetching: isFetching,
        isHOMetCondition: !data?.haulingOrders.totalCount,
      }),
    });
  const { isHJOMetCondition, isHJOCountFetching } =
    api.useGetHaulingOrderDeliveryAssignmentCountQuery(hjoQueryParams(), {
      skip: !isModalVisible,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isFetching }) => ({
        isHJOCountFetching: isFetching,
        isHJOMetCondition: !data?.deliveryAssignments.totalCount,
      }),
    });

  const { isJOMetCondition, isJOCountFetching } = api.useGetJobOrdersCountQuery(
    joQueryParams(),
    {
      skip: !isModalVisible,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isFetching }) => ({
        isJOCountFetching: isFetching,
        isJOMetCondition: !data?.jobOrders.totalCount,
      }),
    },
  );

  const { isDrivingContestMetCondition, isDrivingContestFetching } =
    api.useGetDrivingContestCountQuery(drivingContestQueryParams(), {
      skip: !isModalVisible,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isFetching }) => ({
        isDrivingContestFetching: isFetching,
        isDrivingContestMetCondition: !data?.drivers.totalCount,
      }),
    });

  const isResetDisabled = useMemo(
    () => debouncedConfirmationText !== confirmationKeyword,
    [confirmationKeyword, debouncedConfirmationText],
  );

  const isConditionFetchLoading = useMemo(
    () =>
      [
        isSOCountFetching,
        isJOCountFetching,
        isHOCountFetching,
        isDrivingContestFetching,
        isHJOCountFetching,
      ].every((item) => !!item),
    [
      isDrivingContestFetching,
      isHJOCountFetching,
      isHOCountFetching,
      isJOCountFetching,
      isSOCountFetching,
    ],
  );
  const isProcessLoading = useMemo(
    () => isLoading || isConditionFetchLoading,
    [isConditionFetchLoading, isLoading],
  );

  const isConditionMet = useMemo(
    () =>
      [
        isSOMetCondition,
        isJOMetCondition,
        isHOMetCondition,
        isDrivingContestMetCondition,
        isHJOMetCondition,
      ].every((item) => !!item),
    [
      isDrivingContestMetCondition,
      isHJOMetCondition,
      isHOMetCondition,
      isJOMetCondition,
      isSOMetCondition,
    ],
  );

  const isResetValid = useMemo(
    () =>
      !isProcessLoading &&
      isConditionMet &&
      debouncedConfirmationText === confirmationKeyword,
    [
      confirmationKeyword,
      debouncedConfirmationText,
      isConditionMet,
      isProcessLoading,
    ],
  );
  // #endregion

  const conditionMapper = useMemo(
    (): { label: string; isLoading: boolean; isConditionMet: boolean }[] => [
      {
        label: translate('No Job Order with status: Assigned & Delivering.'),
        isLoading: isJOCountFetching,
        isConditionMet: isJOMetCondition,
      },
      {
        label: translate(
          'No Shipper Order with status: Assigned, Delivering, In Transit, & Transiting.',
        ),
        isLoading: isSOCountFetching,
        isConditionMet: isSOMetCondition,
      },
      {
        label: translate(
          'No Hauling Order with status: Assigned & Delivering.',
        ),
        isLoading: isHOCountFetching,
        isConditionMet: isHOMetCondition,
      },
      {
        label: translate(
          'No Hauling Job Order with status: Assigned & Delivering.',
        ),
        isLoading: isHJOCountFetching,
        isConditionMet: isHJOMetCondition,
      },
      {
        label: translate('No Driver Contest Participant.'),
        isLoading: isDrivingContestFetching,
        isConditionMet: isDrivingContestMetCondition,
      },
    ],
    [
      translate,
      isSOCountFetching,
      isSOMetCondition,
      isJOCountFetching,
      isJOMetCondition,
      isHOCountFetching,
      isHOMetCondition,
      isHJOCountFetching,
      isHJOMetCondition,
      isDrivingContestFetching,
      isDrivingContestMetCondition,
    ],
  );

  // #endregion

  // #region ACTION

  const handleChangeConfirmationInput = useCallback((text: string) => {
    setConfirmationText(text);

    const timerId = delay(() => {
      setDebouncedConfirmationText(text);
    }, 500);

    setDebouncedTimerId(timerId);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalVisible(false);
    setConfirmationText('');
    setDebouncedConfirmationText('');
    setIsErrorVisible(false);
  }, []);

  const handleCancelReset = useCallback(() => {
    handleCloseModal();
    toggleModal(currentMenu);
  }, [currentMenu, handleCloseModal, toggleModal]);

  const handleOverlayClick = useCallback(() => {
    if (isProcessLoading) return;
    handleCancelReset();
  }, [handleCancelReset, isProcessLoading]);

  const handleOpenModal = useCallback(() => {
    toggleModal(undefined);
    setIsModalVisible(true);
  }, [toggleModal]);

  const handleSuccessTriggerReset = useCallback(() => {
    submitTimerId.current = delay(() => {
      handleCloseModal();
      setIsLoading(false);
      dispatch(settingAction.changeIsResetSetting(true));
      navigate(organizationResetRoute.path, {
        replace: true,
        state: {
          isResetting: true,
        },
      });
    }, 1000);
  }, [dispatch, handleCloseModal, navigate]);

  const handleSuccessGenerateResetToken = useCallback(
    async (response: GenerateTokenResetOrganizationResponse) => {
      if (!response || (response && !response.ok)) return;
      try {
        await triggerResetOrganization({
          resetToken: response.organization.resetToken,
        }).unwrap();
        handleSuccessTriggerReset();
      } catch (err) {
        setIsLoading(false);
        dispatch(
          snackbarAction.show({
            message: translate('Failed to Reset Data.'),
            type: SnackbarTheme.warning,
          }),
        );
      } finally {
        resetOrganizationResponse.reset();
      }
    },
    [
      triggerResetOrganization,
      handleSuccessTriggerReset,
      dispatch,
      translate,
      resetOrganizationResponse,
    ],
  );

  const handleGenerateResetToken = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await triggerGenerateResetToken({
        confirmationKey: debouncedConfirmationText,
      }).unwrap();
      await handleSuccessGenerateResetToken(res);
    } catch (err) {
      setIsLoading(false);
      dispatch(
        snackbarAction.show({
          message: translate('Failed to Generate Reset Token.'),
          type: SnackbarTheme.warning,
        }),
      );
    } finally {
      generateResetTokenResponse.reset();
    }
  }, [
    debouncedConfirmationText,
    generateResetTokenResponse,
    dispatch,
    translate,
    handleSuccessGenerateResetToken,
    triggerGenerateResetToken,
  ]);

  const modalAction = useMemo(() => {
    if (isConditionFetchLoading) return undefined;
    if (isConditionMet)
      return {
        label: translate('Reset Data'),
        action: () => {
          if (isResetDisabled) {
            setIsErrorVisible(true);
            return;
          }
          void handleGenerateResetToken();
        },
        isLoading,
        containerStyle: isProcessLoading ? undefined : tw`bg-red-500`,
      };
    return {
      label: translate('Back to Setting'),
      containerStyle: tw`ml-auto`,
      action: handleCancelReset,
    };
  }, [
    handleCancelReset,
    handleGenerateResetToken,
    isConditionFetchLoading,
    isConditionMet,
    isLoading,
    isProcessLoading,
    isResetDisabled,
    translate,
  ]);
  const modalCancelAction = useMemo(() => {
    if (!isConditionFetchLoading && isConditionMet)
      return {
        label: translate('Cancel'),
        action: handleCancelReset,
      };
    return undefined;
  }, [handleCancelReset, isConditionFetchLoading, isConditionMet, translate]);

  // #endregion

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(
    () => () => {
      clearTimeout(debouncedTimerId);
      clearTimeout(submitTimerId.current);
    },
    [debouncedTimerId, submitTimerId],
  );

  const handleFocusInput = useCallback(() => {
    setIsErrorVisible(false);
  }, []);

  return {
    title,
    isLoading,
    alertLabel,
    description,
    modalAction,
    isResetValid,
    isConditionMet,
    isModalVisible,
    conditionMapper,
    confirmationText,
    modalCancelAction,
    confirmationLabel,
    confirmationKeyword,
    confirmationPlaceholder,
    isConditionFetchLoading,
    isProcessLoading,
    errorLabel,
    isErrorVisible,
    debouncedConfirmationText,
    confirmationAdditionalLabel,
    handleGenerateResetToken,
    handleFocusInput,
    handleOpenModal,
    handleCloseModal,
    handleCancelReset,
    handleOverlayClick,
    handleChangeConfirmationInput,
  };
}

export type UseSettingResetDataObj = ReturnType<typeof useSettingResetData>;
