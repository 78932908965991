import React, { ReactNode, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { Icon, Text } from '../../atom';

// #region STYLE
const Container = styled.div(({ isSelected }: { isSelected?: boolean }) => [
  tw`relative pl-7 pr-3 text-grey-two rounded-tr-lg rounded-br-lg py-2 hover:bg-orange-hover`,
  isSelected && tw`text-orange`,
]);
const SubContainer = styled.ul(({ expanded }: { expanded: boolean }) => [
  tw`duration-200 `,
  !expanded && tw`h-0 overflow-hidden`,
]);

const ItemContainer = tw.div`flex items-center space-x-3 cursor-pointer overflow-hidden`;
const ItemContainerLink = tw(
  Link,
)`flex items-center space-x-3 cursor-pointer overflow-hidden`;
const ItemIcon = tw.div`w-6 flex-shrink-0 items-center h-full`;
const ItemLabel = tw(
  Text.HeadingFive,
)`text-current flex-auto leading-4 flex-basis[0] overflow-hidden flex-wrap min-w-[150px]`;
const ItemSubIcon = styled.span(({ expanded }: { expanded: boolean }) => [
  tw`absolute left-2 top[calc(50% - 7px)]`,
  expanded && tw`duration-200 transform[rotate(90deg)]`,
]);
// #endregion

type NavProps = {
  path: string;
  externalPath?: string;
  isSelected: boolean;
  label?: string;
  icon?: React.ReactNode;
  testID?: string;
  event?: {
    label: string;
  };
};

type Props = NavProps & {
  expandedSidebar?: boolean;
  hasSub?: NavProps[] | undefined;
  isLink?: boolean;
  hasDivider?: boolean;
  onClickItem: () => void;
};

type ItemProps = Omit<Props, 'hasDivider'> & {
  expandSubMenu?: boolean;
  setExpandSubMenu?(expanded: boolean): void;
};

type ItemLinkProps = {
  path: string;
  icon: ReactNode;
  label?: string;
  hasSub?: NavProps[] | undefined;
  externalPath?: string;
};

// #region RENDER ITEM

function ItemLink({ path, icon, label, hasSub }: ItemLinkProps) {
  const { translate } = useTranslator();

  if (!hasSub?.length)
    return (
      <ItemContainerLink to={path}>
        {!!icon && <ItemIcon>{icon}</ItemIcon>}
        {label && <ItemLabel>{translate(label)}</ItemLabel>}
      </ItemContainerLink>
    );
  return (
    <ItemContainer>
      {!!icon && <ItemIcon>{icon}</ItemIcon>}
      {label && <ItemLabel>{translate(label)}</ItemLabel>}
    </ItemContainer>
  );
}

function Item({
  isSelected,
  hasSub,
  path,
  testID,
  icon,
  label,
  event,
  expandSubMenu,
  onClickItem,
  setExpandSubMenu,
}: ItemProps) {
  const navigate = useNavigate();
  return (
    <Container
      key={`NavItemContainer_${testID as string}`}
      isSelected={isSelected}
      css={[
        isSelected && !hasSub && tw`bg-orange-hover`,
        event && tw`flex items-center`,
      ]}
      data-testid={testID}
      onClick={() => {
        if (hasSub && typeof setExpandSubMenu === 'function') {
          setExpandSubMenu(!expandSubMenu);
          return;
        }
        navigate(path);
        onClickItem();
      }}
    >
      <ItemLink path={path} icon={icon} label={label} hasSub={hasSub} />

      {hasSub?.length && (
        <ItemSubIcon expanded={!!expandSubMenu}>
          <Icon.ChevronSharp
            fill="none"
            width={16}
            height={16}
            stroke="black"
          />
        </ItemSubIcon>
      )}
    </Container>
  );
}
// #endregion

export default function MobileHelpCenterSidebarItem({
  isSelected,
  icon,
  path,
  label,
  testID,
  hasSub,
  externalPath,
  event,
  onClickItem,
}: Props) {
  const [expandSubMenu, setExpandSubMenu] = useState<boolean>(isSelected);

  useEffect(() => {
    setExpandSubMenu(isSelected);
  }, [isSelected]);

  return (
    <>
      <Item
        onClickItem={onClickItem}
        isSelected={isSelected}
        hasSub={hasSub}
        icon={icon}
        path={path}
        testID={testID}
        expandSubMenu={expandSubMenu}
        setExpandSubMenu={setExpandSubMenu}
        externalPath={externalPath}
        label={label}
        event={event}
      />

      {hasSub?.length && (
        <SubContainer expanded={expandSubMenu}>
          {hasSub.map((item) => (
            <Item
              onClickItem={onClickItem}
              key={item.path}
              isSelected={item.isSelected}
              icon={item.icon}
              path={item.path}
              label={item.label}
              testID={item.testID}
            />
          ))}
        </SubContainer>
      )}
    </>
  );
}
