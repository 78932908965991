import React from 'react';
import { UseJOFormControllerHookObj } from '../../../hook/jobOrderForm/useJOFormController.hook';
import { UseJOFormStateObj } from '../../../hook/jobOrderForm/useJOFormState.hook';
import JOFormSOCandidateList from './JOFormSOCandidateList.organism';
import JOFormSOSelectionList from './JOFormSOSelectionList.organism';

// #region  INTERFACES
type Props = {
  controller: UseJOFormControllerHookObj;
  state: UseJOFormStateObj;
};

export default function JOFormSO({ controller, state }: Props) {
  if (state.isSOCandidateFormDisplayed)
    return <JOFormSOCandidateList soCandidate={state.soCandidateForm} />;
  return <JOFormSOSelectionList controller={controller} state={state} />;
}
