import React from 'react';
import tw, { styled } from 'twin.macro';
import {
  BodyFourteen,
  BodySmallTwelve,
  HeadingFive,
} from '../../../component/atom/Text/Text.atom';
import useTranslator from '../../../hook/useTranslator.hook';
import {
  JobOrderExpenseEventDTO,
  JobOrderExpenseEventType,
} from '../../../model/JobOrder.model';
import { getFullDateTimeFormat } from '../../../util/date.util';
import { getExpenseLabelByEvent } from '../../../util/jobOrderDetail/jobOrderDetail.util';

const Container = tw.div`pt-3 overflow-auto max-h-[350px]`;
const ItemContainer = tw.div`grid grid-template-columns[15px 1fr] gap-2`;
const ItemContent = tw.div`flex flex-col pb-2`;

const Title = styled(HeadingFive)(
  ({ isFirstItem }: { isFirstItem?: boolean }) => [
    tw`text-grey-three`,
    isFirstItem && tw`text-black-primary`,
  ],
);
const Description = styled(BodyFourteen)(
  ({ isFirstItem }: { isFirstItem?: boolean }) => [
    tw`text-grey-placeholder overflow-wrap[anywhere]`,
    isFirstItem && tw`text-black-primary`,
  ],
);
const Subtitle = tw(BodySmallTwelve)`text-grey-three`;

const Circle = styled.div(
  ({
    isFirstItem,
    type,
  }: { isFirstItem?: boolean; type?: JobOrderExpenseEventType }) => [
    tw`w-[15px] h-[15px] border-2 mt-1 rounded-3xl bg-status-fuel-anomaly border-status-fuel-anomaly`,
    type === JobOrderExpenseEventType.APPROVED &&
      tw`border-green-500 bg-green-500`,
    type === JobOrderExpenseEventType.REJECTED &&
      tw`border-status-alert bg-status-alert`,
    !isFirstItem && tw`p-1 border-white`,
  ],
);
const DotContainer = tw.div`relative`;
const DotFootprint = tw.div`border-l absolute top-[20px] left-[7px] h-[90%] border-dashed border-grey-four`;

type Props = {
  timelines?: JobOrderExpenseEventDTO[];
};

type ItemProps = {
  timeline: JobOrderExpenseEventDTO;
  isFirstItem?: boolean;
  isLastItem?: boolean;
};

type DotProps = {
  isFirstItem?: boolean;
  isLastItem?: boolean;
  type: JobOrderExpenseEventType;
};

function Dot({ isFirstItem, isLastItem, type }: DotProps) {
  return (
    <DotContainer>
      <Circle isFirstItem={isFirstItem} type={type} />
      {!isLastItem && <DotFootprint />}
    </DotContainer>
  );
}

function Item({ timeline, isFirstItem, isLastItem }: ItemProps) {
  const { translate } = useTranslator();
  return (
    <ItemContainer>
      <Dot
        isFirstItem={isFirstItem}
        isLastItem={isLastItem}
        type={timeline.eventType}
      />
      <ItemContent>
        <Title isFirstItem={isFirstItem}>
          {translate(getExpenseLabelByEvent(timeline.eventType))}
        </Title>
        {timeline.eventData?.notes && (
          <Description isFirstItem={isFirstItem}>
            {timeline.eventData?.notes}
          </Description>
        )}
        <Subtitle>
          {getFullDateTimeFormat(timeline.eventTime * 1000, true)}
        </Subtitle>
      </ItemContent>
    </ItemContainer>
  );
}

export default function JODetailSpendingTimeline({ timelines }: Props) {
  return (
    <Container>
      {timelines?.map((item, idx, arr) => (
        <Item
          key={item.id}
          timeline={item}
          isFirstItem={idx === 0}
          isLastItem={idx === arr.length - 1}
        />
      ))}
    </Container>
  );
}
