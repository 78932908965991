import React, { PropsWithChildren } from 'react';
import tw from 'twin.macro';
import { Text } from '../../../component/atom';
import { UseJODetailControllerHookObj } from '../../../hook/jobOrderDetail/useJODetailController.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';

type Props = PropsWithChildren<{
  translator: UseTranslator;
  joDetailController: UseJODetailControllerHookObj;
}>;

const Root = tw.section`flex z-index[3] flex-col shadow-card rounded-md bg-white`; // z-index set to 3 to display pagination dropdown on top of maps section
const Header = tw.header`flex flex-row items-center space-x-2 w-full rounded-t-md border-b border-b-beige-lines`;
const TabButton = tw.button`p-5`;

export default function JODetailTabs({
  translator,
  joDetailController,
  children,
}: Props) {
  return (
    <Root>
      <Header>
        <TabButton
          type="button"
          css={[
            joDetailController.tab === 'so-list' &&
              tw`border-b border-b-orange`,
          ]}
          onClick={joDetailController.handleChangeTabsToSOList}
        >
          <Text.HeadingFour
            css={[joDetailController.tab === 'so-list' && tw`text-orange`]}
          >
            {translator.translate('Shipper Order List')}
          </Text.HeadingFour>
        </TabButton>
        <TabButton
          type="button"
          css={[
            joDetailController.tab === 'spending-report' &&
              tw`border-b border-b-orange`,
          ]}
          onClick={joDetailController.handleChangeTabsToExpense}
        >
          <Text.HeadingFour
            css={[
              joDetailController.tab === 'spending-report' && tw`text-orange`,
            ]}
          >
            {translator.translate('Expenses')}
          </Text.HeadingFour>
        </TabButton>
        <TabButton
          type="button"
          css={[
            joDetailController.tab === 'event-history' &&
              tw`border-b border-b-orange`,
          ]}
          onClick={joDetailController.handleChangeTabsToEventHistory}
        >
          <Text.HeadingFour
            css={[
              joDetailController.tab === 'event-history' && tw`text-orange`,
            ]}
          >
            {translator.translate('Changes History')}
          </Text.HeadingFour>
        </TabButton>
      </Header>

      {children}
    </Root>
  );
}
