import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import useTranslator from '../../../hook/useTranslator.hook';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { snackbarAction } from '../../../store/snackbar.store';
import { errorCodeToLabel } from '../../../util/error.util';

export type UseShipperOrderInfo = ReturnType<typeof useShipperOrderInfo>;

export default function useShipperOrderInfo() {
  const translator = useTranslator();
  const params = useParams();
  const dispatch = useDispatch();

  const { soInfo, soInfoError } = api.useGetShipperOrderInfoQuery(
    { id: String(params?.id) },
    {
      refetchOnMountOrArgChange: true,
      skip: !params.id,
      selectFromResult: ({ data, error, isLoading, isFetching }) => ({
        soInfo: data?.shipperOrder,
        soInfoError: error,
        loading: isLoading || isFetching,
      }),
    },
  );

  // #region ERROR
  useEffect(() => {
    if (!soInfoError) return;
    const err = (soInfoError as FetchBaseQueryError).data as ApiErrorResponse;
    dispatch(
      snackbarAction.show({
        type: SnackbarTheme.warning,
        message: translator.translate(errorCodeToLabel(err.error.code)),
      }),
    );
  }, [dispatch, soInfoError, translator]);
  // #endregion

  return { soInfo, soInfoError };
}
