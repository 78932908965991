import React from 'react';
import { Icon } from '../../component/atom';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type JobOrderRouteParam = DefaultRouteParam;

export const jobOrderRoute: RouteConfig = {
  name: 'Job Order',
  Component: React.lazy(() => import('./JobOrder.view')),
  path: '/job-order',
  isPrivate: true,

  breadcrumb: 'Job Order',

  options: {
    icon: <Icon.NavJobOrder />,
    label: 'Job Order',
    testID: 'MenuJobOrder',
  },
};
