import React from 'react';
import { Icon } from '../../component/atom';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';
import { helpCenterDataManagementRoute } from './sections/DataManagement/HelpCenterDataManagement.route';
import { helpCenterDriverContestRoute } from './sections/DriverContest/HelpCenterDriverContest.route';
import { helpCenterGettingStartedRoute } from './sections/GettingStarted/HelpCenterGettingStarted.route';
import { helpCenterOrderManagementRoute } from './sections/OrderManagement/HelpCenterOrderManagement.route';

export type HelpCenterRouteParam = DefaultRouteParam;

export const helpCenterRoute: RouteConfig = {
  name: 'Help Center',
  Component: React.lazy(() => import('./HelpCenter.view')),
  path: '/help-center',
  isPrivate: false,
  breadcrumb: 'Help Center',
  children: [
    {
      ...helpCenterGettingStartedRoute,
      path: 'getting-started',
    },
    {
      ...helpCenterOrderManagementRoute,
      path: 'order-management',
    },
    {
      ...helpCenterDataManagementRoute,
      path: 'data-management',
    },
    {
      ...helpCenterDriverContestRoute,
      path: 'driver-contest',
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    icon: <Icon.DrivingContest />,
    label: 'Help Center',
    testID: 'MenuHelpCenter',
    hasDivider: true,
    hasSub: [
      helpCenterGettingStartedRoute,
      helpCenterOrderManagementRoute,
      helpCenterDataManagementRoute,
      helpCenterDriverContestRoute,
    ],
  },
};
