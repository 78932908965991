import React from 'react';
import { Icon } from '../../component/atom';
import { RouteConfig } from '../../config/router/router.config';

export const haulingOrderRoute: RouteConfig = {
  name: 'Hauling Order',
  Component: React.lazy(() => import('./HaulingOrder.view')),
  path: '/hauling-order',
  isPrivate: true,

  breadcrumb: 'Hauling Order',

  options: {
    icon: <Icon.NavHaulingOrder />,
    label: 'Hauling Order',
    testID: 'MenuHaulingOrder',
  },
};

export default { haulingOrderRoute };
