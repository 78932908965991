import React from 'react';

export default function NavDashboard({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6ZM6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V15.3318L8.06367 11.4264L11.0634 14L16.0637 9.41421V12H18.0637V6H12V8H14.6494L10.8085 11.4264L7.80884 8.85282L4 12.5457V6C4 4.89543 4.89543 4 6 4Z"
      />
    </svg>
  );
}
