import React from 'react';
import tw from 'twin.macro';
import { JODetailMapAction } from '../../../model/JobOrder.model';
import { Chip, Icon } from '../../atom';

const Container = tw.div`flex flex-row items-center`;

interface Props {
  mapsAction: JODetailMapAction[];
}

function JODetailMapsHeader({ mapsAction }: Props) {
  return (
    <Container>
      {mapsAction.map((item, index, arr) => {
        const isLast = index === arr.length - 1;

        return (
          <Chip.Small
            disabled={item.disabled}
            right={
              <Icon.Target
                fill={item.selected && !item.disabled ? '#F3532E' : undefined}
              />
            }
            key={item.label}
            css={[tw`mr-0`, !isLast && tw`mr-2`]}
            label={item.label}
            selected={item.selected && !item.disabled}
            onClick={item.action}
          />
        );
      })}
    </Container>
  );
}

export default JODetailMapsHeader;
