import React from 'react';
import tw, { TwStyle } from 'twin.macro';
import { Button, Text } from '../../atom';

interface Props {
  css?: TwStyle;
  imageHeader?: string | React.ReactNode;
  title: string;
  body: string;
  actionClick: () => void;
  actionLabel: string;
}

const Container = tw.div`grid justify-items-center relative gap-4 max-w-md`;
const ImageHeaderContainer = tw.div``;
const TextContainer = tw.div`text-center flex-wrap break-words flex whitespace-pre-wrap max-w-md`;
export default function ErrorPlaceholder({
  css,
  imageHeader,
  title,
  body,
  actionLabel,
  actionClick,
}: Props) {
  const renderImageHeader = () => {
    if (!imageHeader) return null;
    if (typeof imageHeader === 'string') {
      return (
        <ImageHeaderContainer>
          <img src={imageHeader} alt="error-placeholder" />
        </ImageHeaderContainer>
      );
    }

    return <ImageHeaderContainer>{imageHeader}</ImageHeaderContainer>;
  };
  return (
    <Container css={css}>
      {renderImageHeader()}
      <TextContainer>
        <Text.HeadingThree>{title}</Text.HeadingThree>
      </TextContainer>
      <TextContainer>
        <Text.BodyFourteen>{body}</Text.BodyFourteen>
      </TextContainer>
      <Button.Solid
        tw="text-white bg-black px-3 hover:bg-grey-two active:bg-grey-two rounded-md"
        onClick={actionClick}
      >
        {actionLabel}
      </Button.Solid>
    </Container>
  );
}
