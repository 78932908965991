import { useCallback } from 'react';
import useTranslator from '../useTranslator.hook';

type Props = {
  isFormValid?: boolean;
  isFormChanged?: boolean;
  showBlockerModal?: boolean;
  onClose(): void;
  onSubmit(): void;
  onDiscard(): void;
  onDismiss(): void;
};

export default function useBlockerConfirmation({
  isFormValid,
  isFormChanged,
  showBlockerModal,
  onClose,
  onSubmit,
  onDiscard,
  onDismiss,
}: Props) {
  const { translate } = useTranslator();

  const title = translate('Are you sure you want to leave?');
  const actionLabel = translate(
    isFormValid ? 'Save Changes' : 'Discard Changes',
  );
  const cancelLabel = translate(
    isFormValid ? 'Discard Changes' : 'Stay on Page',
  );
  const description = translate(
    'It looks like you are in the middle of writing something and you have not saved the changes.  Unsaved changes will be lost',
  );
  const isSecondaryValid = isFormValid;
  const isModalVisible = showBlockerModal;
  const handleConfirmAction = useCallback(() => {
    if (!showBlockerModal || !isFormChanged) return;
    if (isFormValid) {
      onSubmit();
      return;
    }
    onDiscard();
  }, [isFormChanged, isFormValid, onDiscard, onSubmit, showBlockerModal]);

  const handleCancelAction = useCallback(() => {
    if (!showBlockerModal || !isFormChanged) return;
    if (isFormValid) {
      onDiscard();
      return;
    }
    onDismiss();
  }, [isFormChanged, isFormValid, onDiscard, onDismiss, showBlockerModal]);

  const handleCloseAction = useCallback(() => {
    onClose();
  }, [onClose]);

  return {
    title,
    actionLabel,
    cancelLabel,
    description,
    isSecondaryValid,
    isModalVisible,
    handleConfirmAction,
    handleCancelAction,
    handleCloseAction,
  };
}

export type UseBlockerConfirmation = ReturnType<typeof useBlockerConfirmation>;
