import React, { ReactChild, ReactChildren } from 'react';
import tw from 'twin.macro';
import { HeadingFive } from '../../atom/Text/Text.atom';
import { ExtraStyle } from '../../Type.component';

const AlertContainer = tw.div`border-2 items-center rounded-md px-5 py-2.5`;
type Props = {
  text?: string;
  containerStyle?: ExtraStyle;
  titleStyle?: ExtraStyle;
  children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
};
function Alert({ text, containerStyle, titleStyle, children }: Props) {
  if (children) {
    return <AlertContainer css={containerStyle}>{children}</AlertContainer>;
  }

  return (
    <AlertContainer css={containerStyle}>
      <HeadingFive css={titleStyle} tw="ml-1">
        {text}
      </HeadingFive>
    </AlertContainer>
  );
}

export default Alert;
