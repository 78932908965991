import React from 'react';
import { ExtraStyle } from '../../../Type.component';

type Props = React.SVGProps<SVGSVGElement> & {
  svgStyle?: ExtraStyle;
};

export default function Refresh2({
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  fill = 'none',
  svgStyle,
}: Props) {
  return (
    <svg
      css={svgStyle}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16c-2.233 0-4.125-.775-5.675-2.325C.775 12.125 0 10.233 0 8c0-2.233.775-4.125 2.325-5.675C3.875.775 5.767 0 8 0c1.15 0 2.25.237 3.3.712A7.612 7.612 0 0114 2.75V0h2v7H9V5h4.2a5.835 5.835 0 00-2.188-2.2A5.928 5.928 0 008 2c-1.667 0-3.083.583-4.25 1.75C2.583 4.917 2 6.333 2 8c0 1.667.583 3.083 1.75 4.25C4.917 13.417 6.333 14 8 14a5.863 5.863 0 003.475-1.1A5.81 5.81 0 0013.65 10h2.1c-.467 1.767-1.417 3.208-2.85 4.325S9.833 16 8 16z"
        fill="#272727"
      />
    </svg>
  );
}
