import { useEffect } from 'react';
import { intervalDelay } from '../../constant/JobOrderDetail.constant';
import { JobOrderInfo, JOStatus } from '../../model/JobOrder.model';
import api from '../../service/api.service';
import useInterval from '../useInterval.hook';

type Props = {
  joInfo?: JobOrderInfo;
};
function useWatchVehiclePosition({ joInfo }: Props) {
  const showVehicleLocation =
    joInfo?.vehicle?.id &&
    [JOStatus.ASSIGNED, JOStatus.DELIVERING].includes(joInfo.status);
  const [
    fetchVehiclePositionData,
    { vehicleLastPosition, vehicleLastLatLng, vehicleHeading },
  ] = api.useLazyGetVehicleLastPositionQuery({
    selectFromResult: ({ data }) => ({
      vehicleLastPosition: data?.lastPosition,
      vehicleHeading: data?.lastPosition?.heading,
      vehicleLastLatLng:
        data?.lastPosition?.latitude && data?.lastPosition.longitude
          ? {
              lat: data.lastPosition.latitude,
              lng: data.lastPosition.longitude,
            }
          : undefined,
    }),
  });

  useEffect(() => {
    if (showVehicleLocation && joInfo?.vehicle?.id) {
      void fetchVehiclePositionData({ vehicleId: joInfo?.vehicle?.id });
    }
  }, [fetchVehiclePositionData, joInfo, showVehicleLocation]);

  useInterval(
    () => {
      if (showVehicleLocation && joInfo?.vehicle?.id) {
        void fetchVehiclePositionData({ vehicleId: joInfo?.vehicle?.id });
      }
    },
    !window.navigator.onLine ? null : intervalDelay,
  );

  return { vehicleHeading, vehicleLastPosition, vehicleLastLatLng };
}

export default useWatchVehiclePosition;
