import React from 'react';
import tw from 'twin.macro';
import { Modal } from '../../component/molecule';
import Settings from '../../component/organism/Settings/Settings.organism';
import SettingsResetData from '../../component/organism/Settings/SettingsResetData.organism';
import useSetting from './Setting.viewModel';

const ModalContainer = tw`p-0 w-[760px]`;
const ModalResetConfirmation = tw`p-8 w-[760px]`;
const ModalBodyContainer = tw.div`flex flex-row `;
const ModalResetConfirmationBodyContainer = tw.div`flex flex-col py-5 `;
const ModalActionButtonContainer = tw`justify-between`;
const ModalResetDataConfirmation = tw`p-0 w-[760px]`;
const ModalResetDataConfirmationContent = tw`p-5`;
const ModalResetDataActionButtonContainer = tw`justify-between p-5 border-t border-t-beige-lines rounded-b`;

function SettingView() {
  const {
    title,
    formData,
    modalAction,
    currentMenu,
    resetConfirmation,
    isModalSettingShown,
    settingAdvanced,
    modalCancelAction,
    handleCloseModal,
    handleOverlayClick,
    handleSelectSidebarItem,
  } = useSetting();
  return (
    <>
      {isModalSettingShown && (
        <Modal.WithHeader
          css={ModalContainer}
          title={title}
          onClose={handleCloseModal}
          onOverlayClick={handleOverlayClick}
          ctaStyle={tw`justify-between`}
          callAction={modalAction}
          cancelAction={modalCancelAction}
          actionButtonContainer={ModalActionButtonContainer}
        >
          <ModalBodyContainer>
            <Settings
              settingAdvanced={settingAdvanced}
              currentMenu={currentMenu}
              formData={formData}
              handleSelectSidebarItem={handleSelectSidebarItem}
            />
          </ModalBodyContainer>
        </Modal.WithHeader>
      )}
      {resetConfirmation.isModalVisible && (
        <Modal.WithClose
          title={resetConfirmation.title}
          css={ModalResetConfirmation}
          onClose={resetConfirmation.handleCloseModal}
          onOverlayClick={resetConfirmation.handleOverlayClick}
          ctaStyle={tw`justify-between`}
          callAction={resetConfirmation.modalAction}
          cancelAction={resetConfirmation.modalCancelAction}
          actionButtonContainer={ModalActionButtonContainer}
        >
          <ModalResetConfirmationBodyContainer>
            {resetConfirmation.description}
          </ModalResetConfirmationBodyContainer>
        </Modal.WithClose>
      )}
      {settingAdvanced.resetData.isModalVisible && (
        <Modal.WithClose
          onClose={settingAdvanced.resetData.handleOverlayClick}
          contentStyle={ModalResetDataConfirmationContent}
          containerStyle={ModalResetDataConfirmation}
          hideCloseButton={settingAdvanced.resetData.isProcessLoading}
          onOverlayClick={settingAdvanced.resetData.handleOverlayClick}
          ctaStyle={ModalResetDataActionButtonContainer}
          callAction={settingAdvanced.resetData.modalAction}
          cancelAction={settingAdvanced.resetData.modalCancelAction}
        >
          <SettingsResetData resetDataHook={settingAdvanced.resetData} />
        </Modal.WithClose>
      )}
    </>
  );
}

export default SettingView;
