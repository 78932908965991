import React from 'react';
import { RouteConfig } from '../../config/router/router.config';

export type CompleteActivityNavigationState = {
  page?: string;
  pageSize?: string;
  search?: string;
  orderBy?: string;
};

export type CompleteActivityRouteParam =
  | {
      searchParamsFromCompleteActivityList?: CompleteActivityNavigationState;
    }
  | undefined;

export const completeActivityRoute: RouteConfig = {
  name: 'Force Complete',
  Component: React.lazy(() => import('./CompleteActivity.view')),
  path: '/job-order/:id/delivery-activities',
  isPrivate: true,
  props: {
    isUnclickable: true,
  },
};
