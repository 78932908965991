import { ActivityTemplate } from '../model/ActivityTemplate.model';
import { HaulingOrder } from '../model/HaulingOrder.model';
import { Location } from '../model/Location.model';
import { ErrorDetail } from '../service/api.endpoint';

// #region INTERFACES
export enum BulkActionStatusEnum {
  WAITING = 'WAITING',
  SUCCEED = 'SUCCEED',
  FAILED = 'FAILED',
}

export type BulkActionEntity<T> = T & {
  _actionDetail: string | ErrorDetail[];
  _actionStatus: BulkActionStatusEnum;
};

export type DeleteDeliveryLocationEntity = BulkActionEntity<Location>;
export type DeleteActivityTemplateEntity = BulkActionEntity<ActivityTemplate>;
export type ForceCompleteHaulingOrderEntity = BulkActionEntity<HaulingOrder>;
// #endregion

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const REPORT_REVENUE_DEFAULT_PAGE_SIZE = 12;
export const HAULING_JO_DEFAULT_PAGE_SIZE = 50;
