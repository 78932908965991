import { Locale } from './locale.config';

const filterLocaleConfig: Locale[] = [
  {
    en: 'Select All',
    id: 'Pilih Semua',
  },
  {
    en: 'Unselect All',
    id: 'Batal Pilih Semua',
  },
  {
    en: 'Sort by',
    id: 'Urutkan berdasarkan',
  },
  {
    en: 'Filter by',
    id: 'Saring berdasarkan',
  },
  {
    en: 'Newest Date',
    id: 'Tanggal Terbaru',
  },
  {
    en: 'Oldest Date',
    id: 'Tanggal Terlama',
  },
  {
    en: 'Newest Update',
    id: 'Update Terbaru',
  },
  {
    en: 'Oldest Update',
    id: 'Update Terlama',
  },
  {
    en: 'Net Income A-Z',
    id: 'Keuntungan A-Z',
  },
  {
    en: 'Net Income Z-A',
    id: 'Keuntungan Z-A',
  },
  {
    en: 'Travel Budget A-Z',
    id: 'Uang Jalan A-Z',
  },
  {
    en: 'Travel Budget Z-A',
    id: 'Uang Jalan Z-A',
  },
  {
    en: 'Delivery Cost A-Z',
    id: 'Biaya Kirim A-Z',
  },
  {
    en: 'Delivery Cost Z-A',
    id: 'Biaya Kirim Z-A',
  },
  {
    en: 'Shipper name A-Z',
    id: 'Nama Pengirim A-Z',
  },
  {
    en: 'Shipper name Z-A',
    id: 'Nama Pengirim Z-A',
  },
  {
    en: 'Total Delivered Order A-Z',
    id: 'Jumlah Order Terkirim A-Z',
  },
  {
    en: 'Total Delivered Order Z-A',
    id: 'Jumlah Order Terkirim Z-A',
  },
  {
    en: 'Invoice Amount Z-A',
    id: 'Jumlah Tagihan Z-A',
  },
  {
    en: 'Payment Amount Z-A',
    id: 'Jumlah Pembayaran Z-A',
  },
  {
    en: 'Receivable Amount Z-A',
    id: 'Jumlah Piutang Z-A',
  },
  {
    en: 'HO Date A-Z',
    id: 'Tanggal HO Terlama',
  },
  {
    en: 'HO Date Z-A',
    id: 'Tanggal HO Terbaru',
  },
  {
    en: '% Fulfillment A-Z',
    id: '% Pengerjaan Terkecil',
  },
  {
    en: '% Fulfillment Z-A',
    id: '% Pengerjaan Terbesar',
  },
  {
    en: 'HJO Number A-Z',
    id: 'Nomor HJO A-Z',
  },
  {
    en: 'HJO Number Z-A',
    id: 'Nomor HJO Z-A',
  },
  {
    en: 'Finished Time A-Z',
    id: 'Waktu Selesai A-Z',
  },
  {
    en: 'Finished Time Z-A',
    id: 'Waktu Selesai Z-A',
  },
  {
    en: 'Highest Delivery Cost',
    id: 'Biaya Kirim Tertinggi',
  },
  {
    en: 'Highest Remaining Receivables',
    id: 'Sisa Piutang Tertinggi',
  },
  {
    en: 'SO Date A-Z',
    id: 'Tanggal SO A-Z',
  },
  {
    en: 'SO Date Z-A',
    id: 'Tanggal SO Z-A',
  },
  {
    en: 'SO Number A-Z',
    id: 'Nomor SO A-Z',
  },
  {
    en: 'SO Number Z-A',
    id: 'Nomor SO Z-A',
  },
  {
    en: 'Delivery Duration Time A-Z',
    id: 'Durasi Pengiriman A-Z',
  },
  {
    en: 'Delivery Duration Time Z-A',
    id: 'Durasi Pengiriman Z-A',
  },
  {
    en: 'Delivery Duration A-Z',
    id: 'Durasi Pengiriman A-Z',
  },
  {
    en: 'Delivery Duration Z-A',
    id: 'Durasi Pengiriman Z-A',
  },
  {
    en: 'Delivered Time A-Z',
    id: 'Waktu Terkirim A-Z',
  },
  {
    en: 'Delivered Time Z-A',
    id: 'Waktu Terkirim Z-A',
  },
  {
    en: 'Total Weight A-Z',
    id: 'Jumlah Berat A-Z',
  },
  {
    en: 'Total Weight Z-A',
    id: 'Jumlah Berat Z-A',
  },
  {
    en: 'JO Number A-Z',
    id: 'Nomor JO A-Z',
  },
  {
    en: 'JO Number Z-A',
    id: 'Nomor JO Z-A',
  },
  {
    en: 'JO Date A-Z',
    id: 'Tanggal JO A-Z',
  },
  {
    en: 'JO Date Z-A',
    id: 'Tanggal JO Z-A',
  },
  {
    en: 'Start Time A-Z',
    id: 'Waktu Mulai JO A-Z',
  },
  {
    en: 'Start Time Z-A',
    id: 'Waktu Mulai JO Z-A',
  },
  {
    en: 'Mileage A-Z',
    id: 'Jarak Tempuh JO A-Z',
  },
  {
    en: 'Mileage Z-A',
    id: 'Jarak Tempuh JO Z-A',
  },
  {
    en: 'Vehicle Name A-Z',
    id: 'Nama Kendaraan A-Z',
  },
  {
    en: 'Vehicle Name Z-A',
    id: 'Nama Kendaraan Z-A',
  },
  {
    en: 'Location Name A-Z',
    id: 'Nama Lokasi A-Z',
  },
  {
    en: 'Location Name Z-A',
    id: 'Nama Lokasi Z-A',
  },
  {
    en: 'Total Activity A-Z',
    id: 'Jumlah Aktivitas A-Z',
  },
  {
    en: 'Total Activity Z-A',
    id: 'Jumlah Aktivitas Z-A',
  },
  {
    en: 'Goods Target A-Z',
    id: 'Target Barang A-Z',
  },
  {
    en: 'Goods Target Z-A',
    id: 'Target Barang Z-A',
  },
  {
    en: 'Order Status',
    id: 'Status Order',
  },
  {
    en: 'Recently Added',
    id: 'Baru Ditambahkan',
  },
  {
    en: 'All',
    id: 'Semua',
  },
  {
    en: '2 weeks',
    id: '2 minggu terakhir',
  },
  {
    en: '1 month',
    id: '1 bulan',
  },
  {
    en: '3 months',
    id: '3 bulan',
  },
  {
    en: '6 months',
    id: '6 bulan',
  },
  {
    en: '1 year',
    id: 'Setahun Terakhir',
  },
  {
    en: 'Start Date',
    id: 'Tanggal Mulai',
  },
  {
    en: 'End Date',
    id: 'Tanggal Selesai',
  },
  {
    en: 'Filter Shipper Order',
    id: 'Filter Shipper Order',
  },
  {
    en: 'Filter Job Order',
    id: 'Filter Perintah Kerja',
  },
  {
    en: 'Newest Updates',
    id: 'Update Terbaru',
  },
  {
    en: 'Oldest Updates',
    id: 'Update Terlama',
  },
  {
    en: 'Reset',
    id: 'Reset',
  },
  {
    en: 'SO Status',
    id: 'Status SO',
  },
  {
    en: 'Paid',
    id: 'Lunas',
  },
  {
    en: 'Unpaid',
    id: 'Belum Lunas',
  },
  {
    en: 'Driver',
    id: 'Sopir',
  },
  {
    en: 'Choose Driver',
    id: 'Pilih Sopir',
  },
  {
    en: 'Choose Shipper',
    id: 'Pilih Pengirim',
  },
  {
    en: 'Choose Location',
    id: 'Pilih Lokasi',
  },
  {
    en: 'Vehicle',
    id: 'Kendaraan',
  },
  {
    en: 'Last 7 Days',
    id: '7 hari terakhir',
  },
  {
    en: 'Last 14 Days',
    id: '14 hari terakhir',
  },
  {
    en: 'Last 90 Days',
    id: '90 hari terakhir',
  },
  {
    en: 'Last 2 Weeks',
    id: '2 minggu terakhir',
  },
  {
    en: 'Previous Week',
    id: 'Minggu lalu',
  },
  {
    en: 'This Week',
    id: 'Minggu ini',
  },
  {
    en: 'This Month',
    id: 'Bulan ini',
  },
  {
    en: 'Last 6 Months',
    id: '6 bulan terakhir',
  },
  {
    en: 'Last 3 Months',
    id: '3 bulan terakhir',
  },
  {
    en: 'Last 1 Month',
    id: '1 bulan terakhir',
  },
  {
    en: 'Last 1 Year',
    id: '1 tahun terakhir',
  },
  {
    en: '2 Weeks',
    id: '2 Minggu Terakhir',
  },
  {
    en: 'Choose Vehicle',
    id: 'Pilih Kendaraan',
  },
  {
    en: 'Maximum 366 days period including the start date',
    id: 'Rentang tanggal maksimal 366 hari termasuk tanggal mulai',
  },
  {
    en: 'SO Delivered Date',
    id: 'Tanggal SO Terkirim',
  },
  {
    en: 'Filter Revenue',
    id: 'Filter Pendapatan',
  },
  {
    en: 'Delivery Table',
    id: 'Tabel Pengiriman',
  },
  {
    en: 'Show Shipper Order',
    id: 'Tunjukkan Shipper Order',
  },
  {
    en: 'Show Column',
    id: 'Tampilkan Kolom',
  },
  {
    en: 'Hide Shipper Order',
    id: 'Sembunyikan Shipper Order',
  },
];

export default filterLocaleConfig;
