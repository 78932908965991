import React from 'react';

export default function OnboardingJobOrder({
  fill = 'none',
  width = 138,
  height = 138,
  viewBox = '0 0 138 138',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#F5F5F5"
        d="M26.642 107.855c23.46 12.255 61.48 12.255 84.934 0 23.454-12.254 23.46-32.115 0-44.37-23.46-12.254-61.48-12.251-84.934 0-23.454 12.252-23.452 32.119 0 44.37z"
      />
      <path
        fill="#F3532E"
        d="M19.684 88.32h.988c.453-1.512.75-3.067.886-4.64.216-2.912-1.195-7.369-3.778-8.862-2.583-1.493-3.983.353-3.116 1.932s3.79 6.07 3.828 9.933l1.192 1.637z"
      />
      <path
        fill="#fff"
        d="M19.447 88.32h.276c.27-1.907.27-3.842 0-5.749-.447-3.778-1.69-5.534-2.843-6.754a.139.139 0 00-.201.19c1.123 1.185 2.332 2.896 2.76 6.594a20.24 20.24 0 01.008 5.72z"
      />
      <path
        fill="#F3532E"
        d="M19.764 88.32c-.508-2.396-2.815-5.244-4.992-5.735-2.526-.552-3.754.78-3.393 1.932.362 1.15 2.357 1.686 4.295 2.589a6.347 6.347 0 011.764 1.214h2.326z"
      />
      <path
        fill="#fff"
        d="M18.845 88.32h.296c-1.068-2.686-3.497-4.601-5.484-4.778a.136.136 0 00-.135.073.135.135 0 00-.015.052.132.132 0 00.125.148c1.876.169 4.17 1.968 5.213 4.505z"
      />
      <path
        fill="#F3532E"
        d="M108.86 63.069l2.067 1.084.174-1.294c-1.024-2.887-.182-6.16.925-7.524 1.107-1.363.894-2.365.494-2.812-.4-.447-1.918-.787-3.42.968-1.501 1.756-1.846 3.887-1.595 6.037.16 1.344.867 2.724 1.355 3.54z"
      />
      <path
        fill="#000"
        d="M108.86 63.069l2.067 1.084.174-1.294c-1.024-2.887-.182-6.16.925-7.524 1.107-1.363.894-2.365.494-2.812-.4-.447-1.918-.787-3.42.968-1.501 1.756-1.846 3.887-1.595 6.037.16 1.344.867 2.724 1.355 3.54z"
        opacity="0.2"
      />
      <path
        fill="#fff"
        d="M109.713 63.516l.408.215a.178.178 0 00-.014-.022c-.585-.695-1.341-4.101-1.024-6.097.392-2.464 1.656-4.073 2.738-4.755a.119.119 0 00.053-.074.12.12 0 000-.046.119.119 0 00-.092-.094.12.12 0 00-.09.015c-1.104.71-2.44 2.374-2.846 4.918-.293 1.858.293 4.8.867 5.94z"
      />
      <path
        fill="#F3532E"
        d="M110.969 58.142c.604 1.482.601 2.208-.384 2.36-.889.133-1.968 1.212-.966 2.964l5.862 3.075a2.438 2.438 0 01.828-1.72c.963-.963 1.852-1.689 1.714-2.76-.138-1.07-1.068-2.103-1.223-3.035-.154-.933.321-1.466 1.333-2.512 1.013-1.046 1.932-2.669.58-3.409-1.352-.74-2.901.527-3.547 1.38-.645.853-1.236 1.229-2.508 1.157-1.273-.072-2.294 1.018-1.689 2.5z"
      />
      <path
        fill="#fff"
        d="M112.628 65.045c-.028-4.548 3.231-9.875 5.045-10.952a.12.12 0 00.053-.074.12.12 0 00-.012-.091.12.12 0 00-.163-.042c-1.838 1.104-5.125 6.434-5.161 11.04l.238.119z"
      />
      <path
        fill="#fff"
        d="M113.499 60.317a.113.113 0 01-.127-.06 19.953 19.953 0 01-1.324-3.064.12.12 0 01-.006-.046.136.136 0 01.013-.045.119.119 0 01.16-.05.133.133 0 01.037.028c.01.012.017.026.022.04a19.898 19.898 0 001.308 3.023.122.122 0 01.009.092.125.125 0 01-.059.071l-.033.011zM112.716 63.105a.127.127 0 01-.068-.006.127.127 0 01-.054-.041.12.12 0 01-.019-.09.116.116 0 01.05-.079 6.219 6.219 0 014.076-1.104.12.12 0 01.08.046.122.122 0 01.024.088.12.12 0 01-.134.104 6.087 6.087 0 00-3.955 1.082z"
      />
      <path
        fill="#F5F5F5"
        d="M78.077 35.32l-6.726-3.884-6.723 3.884 6.723 3.883 6.726-3.883z"
      />
      <path
        fill="#EBEBEB"
        d="M71.352 46.972l6.726-3.883v-7.77l-6.726 3.884v7.77z"
      />
      <path
        fill="#fff"
        d="M72.648 44.394a.207.207 0 01-.102-.024.312.312 0 01-.127-.276v-.889h.042l.646-.378v.919a.74.74 0 01-.318.601.276.276 0 01-.14.047zm-.124-1.068v.762a.213.213 0 00.075.19.135.135 0 00.138-.016.646.646 0 00.276-.51v-.067h-.24l-.047-.323-.202-.036zm.332.254h.148v-.566l-.386.224.19.036.047.306z"
      />
      <path
        fill="#fff"
        d="M72.764 45.051a.052.052 0 01-.037-.015.052.052 0 01-.015-.037v-.69a.05.05 0 01.032-.05.05.05 0 01.02-.003.053.053 0 01.053.053v.679a.056.056 0 01-.03.058.055.055 0 01-.023.005z"
      />
      <path
        fill="#fff"
        d="M72.464 45.225a.049.049 0 01-.044-.027.047.047 0 01.019-.07l.599-.347a.052.052 0 01.04-.005.052.052 0 01.032.024.055.055 0 01-.02.072l-.601.345-.025.008zM74.605 44.033a.1.1 0 01-.058 0 .138.138 0 01-.06-.124v-.141a.05.05 0 01.05-.053.05.05 0 01.049.032.05.05 0 01.003.02V43.918h.033a.295.295 0 00.097-.232v-.905a.05.05 0 01.015-.036.048.048 0 01.037-.014.05.05 0 01.05.05v.922a.4.4 0 01-.147.323.185.185 0 01-.069.008z"
      />
      <path
        fill="#fff"
        d="M74.06 43.23v-.089a1.47 1.47 0 01.662-1.148.438.438 0 01.714.411v.03l-1.377.796zm.97-1.226a.503.503 0 00-.256.08 1.38 1.38 0 00-.607.963l1.164-.67a.38.38 0 00-.16-.337.277.277 0 00-.14-.036zM76.452 42.973a.053.053 0 01-.053-.05v-1.76a.052.052 0 01.053-.053.052.052 0 01.052.053v1.76a.053.053 0 01-.052.05z"
      />
      <path
        fill="#fff"
        d="M76.311 41.563h-.019a.053.053 0 01-.032-.05.05.05 0 01.004-.02l.186-.424.204.237a.051.051 0 01-.043.084.052.052 0 01-.035-.018l-.099-.11-.119.276a.05.05 0 01-.047.025zM76.94 42.703a.052.052 0 01-.049-.053v-1.76a.053.053 0 01.028-.06.053.053 0 01.064.015.053.053 0 01.01.045v1.76a.052.052 0 01-.052.053z"
      />
      <path
        fill="#fff"
        d="M76.788 41.29h-.019a.054.054 0 01-.028-.03.052.052 0 010-.04l.186-.425.204.238a.052.052 0 010 .072.055.055 0 01-.075 0l-.096-.114-.119.276a.053.053 0 01-.052.022z"
      />
      <path
        fill="#E0E0E0"
        d="M71.352 46.972l-6.727-3.883.003-7.77 6.724 3.884v7.77z"
      />
      <path
        fill="#fff"
        d="M68.354 37.472l6.72-3.886-.758-.438-6.724 3.885.762.44z"
      />
      <path
        fill="#F3532E"
        d="M65.646 39.774l2.41 1.38a1.066 1.066 0 01.477.828v1.485c0 .306-.215.43-.477.276l-2.41-1.38a1.063 1.063 0 01-.477-.828V40.05c0-.306.215-.427.477-.276z"
      />
      <path
        fill="#F3532E"
        d="M65.461 40.144l.091.055v1.648l-.09-.053v-1.65zM65.835 40.36l.157.093v1.648l-.157-.091v-1.65zM66.207 40.575l.091.052v1.65l-.091-.052v-1.65zM66.486 40.735l.185.108v1.65l-.185-.11v-1.648zM66.95 41.005l.093.053v1.65l-.094-.055v-1.648zM67.22 41.157l.193.116v1.648l-.196-.116.003-1.648zM67.694 41.436l.091.053v1.647l-.09-.052v-1.648zM67.907 41.555l.047.027v1.648l-.047-.025v-1.65zM67.526 41.345l.044.027v1.648l-.044-.025v-1.65zM66.775 40.906l.045.025v1.65l-.045-.028v-1.647zM66.364 40.669l.047.027v1.65l-.047-.027v-1.65zM66.069 40.506l.047.027v1.65l-.047-.027v-1.65zM65.668 40.263l.047.027v1.65l-.047-.027v-1.65zM68.067 41.648l.091.053v1.65l-.091-.052v-1.65z"
      />
      <path
        fill="#F5F5F5"
        d="M69.11 34.025l-7.85-4.529-7.846 4.53 7.847 4.531 7.85-4.532z"
      />
      <path
        fill="#fff"
        d="M57.897 36.614l7.85-4.53-.763-.438-7.846 4.53.759.438z"
      />
      <path
        fill="#EBEBEB"
        d="M61.26 52.796l7.85-4.529V34.025l-7.85 4.532v14.24z"
      />
      <path
        fill="#fff"
        d="M62.558 50.232a.191.191 0 01-.102-.027.304.304 0 01-.127-.276v-.889h.044l.643-.378v.919a.746.746 0 01-.317.601.277.277 0 01-.141.05zm-.124-1.068v.762a.212.212 0 00.074.19.134.134 0 00.138-.016.645.645 0 00.276-.511v-.066h-.24l-.047-.323-.201-.036zm.331.254h.163v-.566l-.386.226.19.033.033.307z"
      />
      <path
        fill="#fff"
        d="M62.674 50.878a.053.053 0 01-.052-.053v-.692a.05.05 0 01.032-.047.05.05 0 01.02-.003.052.052 0 01.053.05v.692a.055.055 0 01-.053.053z"
      />
      <path
        fill="#fff"
        d="M62.376 51.06a.05.05 0 01-.044-.025.055.055 0 01.02-.072l.598-.345a.052.052 0 01.04-.005.052.052 0 01.037.063.049.049 0 01-.025.03l-.601.348a.041.041 0 01-.025.006zM64.515 49.86a.126.126 0 01-.058-.014.138.138 0 01-.06-.125v-.143a.05.05 0 01.015-.036.05.05 0 01.037-.014.05.05 0 01.05.05v.177c0 .002.016 0 .033 0a.3.3 0 00.096-.235v-.92a.053.053 0 01.053-.052.055.055 0 01.052.053v.919a.388.388 0 01-.149.323.157.157 0 01-.069.016z"
      />
      <path
        fill="#fff"
        d="M63.968 49.056v-.09a1.468 1.468 0 01.663-1.146.44.44 0 01.715.411v.03l-1.378.795zm.972-1.228a.53.53 0 00-.257.08 1.38 1.38 0 00-.607.966l1.165-.67a.389.389 0 00-.16-.34.295.295 0 00-.141-.036zM66.372 48.8a.053.053 0 01-.052-.053v-1.761a.052.052 0 01.09-.037c.01.01.015.023.015.037v1.76a.052.052 0 01-.053.053z"
      />
      <path
        fill="#fff"
        d="M66.22 47.39h-.019a.049.049 0 01-.028-.028.05.05 0 010-.039l.186-.425.204.237c.008.01.013.024.012.037a.051.051 0 01-.09.03l-.099-.114-.119.276a.05.05 0 01-.046.025zM66.85 48.526a.05.05 0 01-.05-.05v-1.76a.05.05 0 01.014-.037.051.051 0 01.036-.016.05.05 0 01.053.053v1.76a.05.05 0 01-.052.05z"
      />
      <path
        fill="#fff"
        d="M66.701 47.116h-.022a.055.055 0 01-.028-.069l.185-.425.207.237a.056.056 0 010 .072.054.054 0 01-.037.016.052.052 0 01-.037-.016l-.097-.113-.118.276a.05.05 0 01-.053.022z"
      />
      <path
        fill="#E0E0E0"
        d="M61.26 52.796l-7.846-4.529V34.025l7.847 4.532v14.24z"
      />
      <path
        fill="#F5F5F5"
        d="M55.01 45.306l2.406 1.38a1.063 1.063 0 01.48.828V49c0 .304-.215.428-.48.276l-2.406-1.38a1.055 1.055 0 01-.478-.828v-1.485c0-.32.216-.43.478-.279z"
      />
      <path
        fill="#E0E0E0"
        d="M54.825 45.675l.09.056v1.647l-.09-.052v-1.65zM55.197 45.89l.158.094v1.65l-.158-.093v-1.65zM55.567 46.106l.094.052-.003 1.65-.091-.052v-1.65zM55.849 46.266l.182.107v1.65l-.182-.11v-1.647zM56.313 46.536l.09.053v1.65l-.09-.055v-1.648zM56.58 46.688l.196.116v1.648l-.196-.116v-1.648zM57.058 46.964l.09.056v1.647l-.09-.052v-1.65zM57.27 47.085l.047.028v1.65l-.047-.027v-1.65zM56.89 46.876l.043.028v1.647l-.044-.025v-1.65zM56.138 46.437l.044.025v1.65l-.044-.027v-1.648zM55.727 46.2l.044.027v1.65l-.044-.027V46.2zM55.432 46.037l.047.027v1.65l-.047-.027v-1.65zM55.032 45.794l.047.028v1.65l-.047-.028v-1.65zM57.43 47.18l.091.052v1.65l-.09-.052v-1.65z"
      />
      <path fill="#F0F0F0" d="M57.138 38.013l.759.44v-1.839l-.76-.439v1.838z" />
      <path
        fill="#F5F5F5"
        d="M45.565 48.913l-5.605-3.235-7.847 4.529 5.605 3.237 7.847-4.532z"
      />
      <path
        fill="#EBEBEB"
        d="M37.718 66.389l7.847-4.53V48.914l-7.847 4.532v12.944z"
      />
      <path
        fill="#fff"
        d="M39.013 63.814a.18.18 0 01-.1-.028.307.307 0 01-.127-.276V62.623h.042l.646-.378v.919a.746.746 0 01-.318.601.293.293 0 01-.143.05zm-.122-1.068v.762a.207.207 0 00.075.19.146.146 0 00.138-.016.646.646 0 00.276-.511v-.066h-.24l-.047-.323-.202-.036zm.331.254h.15v-.569l-.387.227.19.033.047.309z"
      />
      <path
        fill="#fff"
        d="M39.131 64.47a.052.052 0 01-.052-.052v-.693a.05.05 0 01.015-.036.048.048 0 01.037-.013.05.05 0 01.05.05v.692a.052.052 0 01-.05.053z"
      />
      <path
        fill="#fff"
        d="M38.83 64.642a.047.047 0 01-.044-.025.051.051 0 01.001-.058.05.05 0 01.016-.014l.602-.345a.052.052 0 01.058.004.052.052 0 01.018.054.049.049 0 01-.024.03l-.602.349a.041.041 0 01-.025.005zM40.972 63.452a.125.125 0 01-.058-.014.138.138 0 01-.06-.124v-.143a.05.05 0 01.049-.05.052.052 0 01.037.014.05.05 0 01.016.036v.176c0 .003.016 0 .033 0a.313.313 0 00.096-.234v-.92a.053.053 0 01.028-.058.054.054 0 01.046 0 .051.051 0 01.028.06v.918a.397.397 0 01-.146.323.157.157 0 01-.069.016z"
      />
      <path
        fill="#fff"
        d="M40.426 62.652v-.091a1.468 1.468 0 01.662-1.145.435.435 0 01.712.41v.031l-1.374.795zm.971-1.228a.53.53 0 00-.256.08 1.38 1.38 0 00-.608.966l1.165-.673a.386.386 0 00-.16-.337.295.295 0 00-.138-.039l-.003.003zM42.83 62.393a.053.053 0 01-.053-.053v-1.76a.053.053 0 01.09-.038c.01.01.015.023.015.037v1.761a.053.053 0 01-.015.037.053.053 0 01-.037.016z"
      />
      <path
        fill="#fff"
        d="M42.678 60.996h-.02a.051.051 0 01-.027-.027.049.049 0 010-.039l.185-.425.204.237a.051.051 0 01-.005.072.05.05 0 01-.072-.005l-.1-.114-.118.276a.05.05 0 01-.047.025zM43.307 62.12a.053.053 0 01-.052-.05v-1.761a.053.053 0 01.052-.053.05.05 0 01.05.032.05.05 0 01.003.02v1.762a.05.05 0 01-.016.036.05.05 0 01-.037.014z"
      />
      <path
        fill="#fff"
        d="M43.156 60.72a.028.028 0 01-.02 0 .049.049 0 01-.028-.027.049.049 0 010-.04l.186-.425.204.238a.052.052 0 010 .072.052.052 0 01-.075 0l-.096-.113-.119.276a.052.052 0 01-.052.019z"
      />
      <path
        fill="#E0E0E0"
        d="M37.718 66.389l-5.605-3.235V50.207l5.605 3.237V66.39z"
      />
      <path fill="#F5F5F5" d="M34.715 53.55l.76.438V52.15l-.76-.439v1.839z" />
      <path
        fill="#fff"
        d="M35.474 52.15l7.85-4.532-.762-.439-7.847 4.532.76.439z"
      />
      <path
        fill="#F5F5F5"
        d="M35.474 59.917l-10.087-5.824-10.091 5.824 10.09 5.826 10.088-5.826z"
      />
      <path
        fill="#EBEBEB"
        d="M25.386 73.51l10.088-5.824v-7.769l-10.088 5.826v7.767z"
      />
      <path
        fill="#fff"
        d="M26.681 70.932a.208.208 0 01-.1-.028.31.31 0 01-.126-.275v-.889h.041l.646-.381v.922a.745.745 0 01-.317.599.298.298 0 01-.144.052zm-.124-1.068v.762a.205.205 0 00.074.187.141.141 0 00.141-.013.652.652 0 00.276-.511v-.069H26.8l-.047-.323-.196-.033zm.331.254h.16v-.566l-.384.226.19.033.034.307z"
      />
      <path
        fill="#fff"
        d="M26.797 71.59a.05.05 0 01-.035-.016.05.05 0 01-.015-.035v-.692a.051.051 0 01.01-.045.053.053 0 01.041-.02c.008 0 .016.002.023.005a.053.053 0 01.028.06v.692a.05.05 0 01-.015.037.051.051 0 01-.037.014z"
      />
      <path
        fill="#fff"
        d="M26.496 71.76a.052.052 0 01-.048-.04.053.053 0 01.023-.057l.602-.345a.046.046 0 01.038-.007.046.046 0 01.03.024.05.05 0 01.008.04.05.05 0 01-.024.032l-.602.353h-.027zM28.638 70.573a.128.128 0 01-.056 0 .138.138 0 01-.063-.127v-.14a.052.052 0 11.105 0v.14a.058.058 0 000 .036h.036a.312.312 0 00.094-.235v-.919a.055.055 0 01.052-.052c.014 0 .027.005.037.015.01.01.015.023.015.037v.92a.397.397 0 01-.146.322.16.16 0 01-.074.003z"
      />
      <path
        fill="#fff"
        d="M28.094 69.77v-.091a1.454 1.454 0 01.663-1.146.437.437 0 01.712.412v.03l-1.375.795zm.972-1.228a.553.553 0 00-.26.08 1.381 1.381 0 00-.604.966l1.162-.674a.383.383 0 00-.158-.337.276.276 0 00-.14-.035zM30.498 69.513a.05.05 0 01-.05-.032.05.05 0 01-.002-.02v-1.76a.053.053 0 01.028-.06.052.052 0 01.046 0 .052.052 0 01.028.06v1.76a.05.05 0 01-.014.037.05.05 0 01-.036.015z"
      />
      <path
        fill="#fff"
        d="M30.36 68.103h-.022a.05.05 0 01-.028-.03.05.05 0 01.003-.04l.182-.424.207.237a.053.053 0 01-.006.075.053.053 0 01-.074-.006l-.097-.113-.118.276a.053.053 0 01-.047.025zM30.975 69.24a.053.053 0 01-.052-.052v-1.761a.052.052 0 11.105 0v1.76a.053.053 0 01-.053.053z"
      />
      <path
        fill="#fff"
        d="M30.823 67.83h-.019a.05.05 0 01-.031-.047c0-.007 0-.013.004-.02l.184-.427.205.237a.054.054 0 01.015.038.053.053 0 01-.032.048.049.049 0 01-.039 0 .049.049 0 01-.016-.011l-.1-.113-.118.276a.05.05 0 01-.053.019z"
      />
      <path
        fill="#E0E0E0"
        d="M25.387 73.51l-10.091-5.824v-7.769l10.09 5.826v7.767z"
      />
      <path fill="#F5F5F5" d="M19.96 64.448l.76.44v-1.839l-.76-.439v1.838z" />
      <path
        fill="#fff"
        d="M20.72 63.05l10.09-5.824-.759-.442-10.09 5.827.758.438z"
      />
      <path
        fill="#F5F5F5"
        d="M16.894 64.725l2.407 1.38a1.064 1.064 0 01.48.828v1.488c0 .303-.215.427-.48.276l-2.407-1.38a1.055 1.055 0 01-.477-.828v-1.485c0-.31.215-.42.477-.28z"
      />
      <path
        fill="#E0E0E0"
        d="M16.709 65.095l.091.052v1.65l-.091-.052v-1.65zM17.08 65.31l.16.094v1.648l-.16-.092v-1.65zM17.452 65.525l.093.052-.002 1.65-.091-.052v-1.65zM17.733 65.685l.185.108-.003 1.65-.182-.11v-1.648zM18.197 65.956l.09.052v1.65l-.09-.055v-1.647zM18.464 66.107l.196.116v1.648l-.196-.116v-1.648zM18.942 66.386l.091.053v1.647l-.091-.052v-1.648zM19.154 66.505l.047.028v1.647l-.047-.025v-1.65zM18.773 66.295l.045.025v1.65l-.045-.024v-1.65zM18.023 65.856l.044.025v1.65l-.044-.027v-1.648zM17.611 65.62l.047.027v1.647l-.047-.024v-1.65zM17.316 65.456l.047.028v1.65l-.047-.028v-1.65zM16.916 65.213l.047.028v1.648l-.047-.025v-1.65zM19.314 66.599l.091.055v1.648l-.09-.053V66.6z"
      />
      <path
        fill="#F5F5F5"
        d="M33.233 44.383l-7.846-4.531-7.85 4.532 7.85 4.529 7.846-4.53z"
      />
      <path
        fill="#EBEBEB"
        d="M25.386 64.448l7.847-4.531V44.383l-7.847 4.53v15.535z"
      />
      <path
        fill="#fff"
        d="M26.681 61.87a.205.205 0 01-.1-.024.309.309 0 01-.126-.276V60.682h.041l.646-.379v.92a.75.75 0 01-.317.601.293.293 0 01-.144.047zm-.124-1.067v.764a.209.209 0 00.074.188.14.14 0 00.141-.014.658.658 0 00.276-.513v-.066H26.8l-.047-.323-.196-.036zm.331.254h.16v-.566l-.384.226.19.033.034.307z"
      />
      <path
        fill="#fff"
        d="M26.797 62.528a.05.05 0 01-.036-.016.05.05 0 01-.014-.037v-.69a.051.051 0 01.014-.036.05.05 0 01.036-.016.05.05 0 01.05.032.05.05 0 01.002.02v.69a.05.05 0 01-.032.05.05.05 0 01-.02.003z"
      />
      <path
        fill="#fff"
        d="M26.496 62.702a.052.052 0 01-.048-.04.053.053 0 01.023-.057l.602-.347a.052.052 0 11.052.09l-.602.346a.047.047 0 01-.027.008zM28.638 61.512a.099.099 0 01-.056-.017.133.133 0 01-.063-.124v-.14a.052.052 0 11.105 0v.14a.058.058 0 000 .036.066.066 0 00.036 0 .299.299 0 00.094-.232v-.919a.052.052 0 01.052-.052.05.05 0 01.05.032.05.05 0 01.002.02v.92a.398.398 0 01-.146.322.16.16 0 01-.074.014z"
      />
      <path
        fill="#fff"
        d="M28.094 60.72v-.088a1.466 1.466 0 01.663-1.148.44.44 0 01.712.414v.027l-1.375.795zm.972-1.225a.552.552 0 00-.26.08 1.38 1.38 0 00-.604.963l1.162-.67a.377.377 0 00-.158-.337.276.276 0 00-.14-.05v.014zM30.498 60.444a.052.052 0 01-.052-.052V58.63a.053.053 0 01.028-.06.053.053 0 01.064.015.052.052 0 01.01.045v1.76a.052.052 0 01-.05.053z"
      />
      <path
        fill="#fff"
        d="M30.36 59.039h-.022a.05.05 0 01-.028-.03.05.05 0 01.003-.039l.182-.425.207.237a.052.052 0 010 .072.055.055 0 01-.075 0l-.096-.113-.119.276a.05.05 0 01-.052.022zM30.975 60.168a.055.055 0 01-.052-.052v-1.761a.053.053 0 11.105 0v1.76a.055.055 0 01-.053.053z"
      />
      <path
        fill="#fff"
        d="M30.824 58.769h-.02a.054.054 0 01-.028-.029.052.052 0 010-.04l.186-.426.204.238c.005.005.009.01.011.017a.052.052 0 010 .04.054.054 0 01-.011.017.055.055 0 01-.072 0l-.1-.113-.118.276a.052.052 0 01-.052.02z"
      />
      <path
        fill="#E0E0E0"
        d="M25.387 64.448l-7.85-4.531V44.383l7.85 4.53v15.535z"
      />
      <path
        fill="#F5F5F5"
        d="M21.26 48.369l.762.439v-1.836l-.762-.441v1.838z"
      />
      <path
        fill="#fff"
        d="M22.022 46.972l7.847-4.532-.76-.438-7.849 4.529.762.441z"
      />
      <path
        fill="#F5F5F5"
        d="M19.135 56.955l2.407 1.38a1.051 1.051 0 01.477.828v1.485c0 .304-.212.428-.477.276l-2.407-1.38a1.054 1.054 0 01-.48-.828v-1.485c.002-.303.215-.427.48-.276z"
      />
      <path
        fill="#E0E0E0"
        d="M18.947 57.328l.091.053v1.65l-.09-.055v-1.648zM19.32 57.544l.157.09v1.651l-.157-.094v-1.648zM19.692 57.756l.091.055v1.648l-.09-.053v-1.65zM19.974 57.916l.182.11v1.648l-.182-.108v-1.65zM20.438 58.187l.091.052v1.65l-.091-.052v-1.65zM20.706 58.338l.195.116v1.65l-.195-.116v-1.65zM21.183 58.617l.091.052v1.65l-.09-.055v-1.647zM21.395 58.738l.045.025v1.65l-.045-.027v-1.648zM21.012 58.526l.047.027v1.651l-.047-.028v-1.65zM20.261 58.087l.047.027v1.648l-.047-.025v-1.65zM19.85 57.852l.047.025v1.65l-.047-.027v-1.648zM19.557 57.69l.044.024v1.65l-.044-.027V57.69zM19.157 57.447l.044.025v1.65l-.044-.028v-1.647zM21.553 58.832l.094.053v1.647l-.094-.052v-1.648z"
      />
      <path
        fill="#455A64"
        d="M17.612 87.147l30.123 17.388a2.64 2.64 0 002.39 0l66-38.088c.659-.38.659-1 0-1.38L86.002 47.679a2.625 2.625 0 00-2.39 0l-66 38.088c-.66.38-.66 1 0 1.38z"
      />
      <path
        fill="#263238"
        d="M33.893 95.22a3.677 3.677 0 003.312 0c.919-.53.919-1.38 0-1.918a3.66 3.66 0 00-3.312 0c-.92.538-.92 1.394 0 1.918z"
      />
      <path
        fill="#37474F"
        d="M48.3 104.535L18.178 87.147c-.591-.342-.652-.872-.185-1.253a2.676 2.676 0 00-1.01 1.932v1.123a2.645 2.645 0 001.194 2.07L48.3 108.407c.369.193.78.288 1.195.276v-3.861a2.44 2.44 0 01-1.195-.287z"
      />
      <path
        fill="#263238"
        d="M116.121 70.317a2.633 2.633 0 001.198-2.07v-1.124a2.643 2.643 0 00-1.013-1.932c.47.381.409.911-.182 1.25l-66 38.094c-.368.192-.78.287-1.195.276v3.884c.415.01.827-.085 1.195-.276l65.997-38.102z"
      />
      <path
        fill="#fff"
        d="M52.697 100.933L23.866 84.279c-.398-.229-.398-.601 0-.828l55.73-32.184a1.584 1.584 0 011.435 0l28.842 16.651c.395.23.395.6 0 .828l-55.752 32.187a1.586 1.586 0 01-1.424 0z"
      />
      <path
        fill="#F3532E"
        d="M41.08 91.635l3.364-1.943-1.12-.646-3.364 1.94 1.12.649zM81.442 68.332l3.365-1.943-1.124-.646-3.361 1.943 1.12.646zM88.168 64.449l3.365-1.943-1.12-.646-3.365 1.94 1.12.649z"
      />
      <path
        fill="#263238"
        d="M98.966 57.645a.94.94 0 00.841 0 .256.256 0 000-.486.923.923 0 00-.841 0 .256.256 0 000 .486zM100.31 61.457a1.857 1.857 0 001.681 0c.463-.276.463-.704 0-.972a1.864 1.864 0 00-1.681 0c-.464.268-.464.704 0 .972zM99.123 59.768c.309-.18.292-.478-.036-.668l-5.52-3.194a1.273 1.273 0 00-1.16-.022c-.308.18-.292.478.037.668l5.536 3.194a1.275 1.275 0 001.143.022z"
      />
      <path
        fill="#F3532E"
        d="M89.7 46.818l.188-.11c-.13.47-.193.958-.188 1.446v.673c0 2.418 2.9 8.595 3.969 10.78.11.23-1.355 1.79-1.355 1.79l1.741-1.005s4.353-3.588 4.353-6.536v-.673c0-2.962-1.949-6.486-4.353-7.875-1.214-.698-2.31-.723-3.1-.193l-1.655.95.4.753zm2.178 2.26c0-1.38.974-1.95 2.177-1.255-.1.891 1.53 3.668 2.175 3.77 0 1.38-.974 1.951-2.175 1.256a4.807 4.807 0 01-2.166-3.77h-.011z"
      />
      <path
        fill="#F3532E"
        d="M87.909 49.907c.05 2.958 4.543 11.49 4.543 11.49s4.294-3.65 4.247-6.608v-.674c-.05-2.958-2.056-6.452-4.485-7.8-2.429-1.346-4.355-.043-4.305 2.918v.674zm2.18.215c-.022-1.38.944-1.968 2.156-1.295a4.81 4.81 0 012.238 3.735c.025 1.38-.941 1.968-2.156 1.294a4.824 4.824 0 01-2.238-3.734z"
      />
      <path
        fill="#000"
        d="M87.909 49.907c.05 2.958 4.543 11.49 4.543 11.49s4.294-3.65 4.247-6.608v-.674c-.05-2.958-2.056-6.452-4.485-7.8-2.429-1.346-4.355-.043-4.305 2.918v.674zm2.18.215c-.022-1.38.944-1.968 2.156-1.295a4.81 4.81 0 012.238 3.735c.025 1.38-.941 1.968-2.156 1.294a4.824 4.824 0 01-2.238-3.734z"
        opacity="0.1"
      />
      <path
        fill="#F0F0F0"
        d="M45.816 89.366c2.79 1.607 9.331.44 14.611-2.61l14.504-8.372c5.28-3.05 7.295-6.82 4.499-8.423-2.796-1.604-9.343-.43-14.628 2.616l-14.476 8.36c-5.277 3.047-7.298 6.831-4.51 8.43z"
      />
      <path
        fill="#37474F"
        d="M88.044 19.188h.014l2.387-1.38h.02c1.504-.762 3.513-.552 5.677.855 4.35 2.799 7.67 9.257 7.419 14.421-.127 2.575-1.104 4.342-2.614 5.12h-.014l-2.39 1.38h-.017c-1.504.765-3.516.552-5.682-.853-4.347-2.801-7.668-9.257-7.417-14.42.133-2.578 1.124-4.345 2.617-5.123z"
      />
      <path
        fill="#263238"
        d="M98.665 39.512l2.277-1.324h.016c1.496-.782 2.484-2.548 2.611-5.12.11-2.302-.488-4.86-1.57-7.237l-2.39 1.38c1.084 2.376 1.683 4.935 1.57 7.24-.119 2.511-1.068 4.258-2.514 5.061z"
      />
      <path
        fill="#455A64"
        d="M92.855 38.729c-4.347-2.802-7.668-9.258-7.416-14.421.25-5.164 3.985-7.083 8.324-4.284 4.338 2.799 7.67 9.257 7.419 14.421-.251 5.164-3.98 7.082-8.327 4.284z"
      />
      <path
        fill="#FAFAFA"
        d="M96.498 38.364c2.564.124 2.98-2.76 3.036-4.002.22-4.554-2.832-10.488-6.666-12.958a5.796 5.796 0 00-2.76-1.021c-2.561-.124-2.978 2.76-3.036 4.002-.22 4.554 2.83 10.488 6.666 12.972a5.796 5.796 0 002.76 1.021v-.014z"
      />
      <path
        fill="#37474F"
        d="M92.808 30.23c.03 0 .06-.005.088-.016a.276.276 0 00.19-.276v-6.577a.276.276 0 10-.552 0v5.777l-3.071-3.947a.28.28 0 00-.442.342l3.574 4.59a.277.277 0 00.213.108z"
      />
      <path
        fill="#F3532E"
        d="M94.392 35.096a.1.1 0 00.039 0 .118.118 0 00.08-.143l-1.549-5.214a.117.117 0 00-.223.066l1.545 5.214a.116.116 0 00.108.077z"
      />
      <path
        fill="#F3532E"
        d="M92.896 28.633c-.514-.329-.952-.102-.98.505a2.104 2.104 0 00.872 1.694c.51.331.95.105.98-.502a2.117 2.117 0 00-.872-1.697z"
      />
      <path
        fill="#EECD4A"
        d="M63.176 57.793l10.982 15.748 13.29-8.405-5.537-5.71-5.312 1.405a.983.983 0 01-.815-.176l-6.948-5.125-5.66 2.263z"
      />
      <path
        fill="#455A64"
        d="M65.719 58.573l4.03 5.639 5.08-2.584-5.914-4.777-3.196 1.722z"
      />
      <g fill="#fff" opacity="0.3">
        <path d="M67.926 61.662l.92 1.286 2.347.529 1.47-.747-4.737-1.068zM66.743 58.022l5.02 1.13-.925-.749-3.389-.763-.706.382zM66.193 59.236l.11.156 7.983 1.797-.226-.182-7.867-1.771zM69.185 63.422l.111.157 1.175.265.18-.092-1.466-.33z" />
      </g>
      <path
        fill="#263238"
        d="M65.719 58.573l3.196-1.722 5.914 4.777-.34.172-5.591-4.515-3.002 1.535-.177-.247zM76.532 61.076l-.087.055 4.796 7.585.088-.056-4.797-7.584z"
      />
      <path
        fill="#fff"
        d="M82.506 59.074l-1.191.509 2.037 1.827.52-.41a.272.272 0 00.05-.376l-1.093-1.466a.275.275 0 00-.323-.084z"
      />
      <path
        fill="#EECD4A"
        d="M74.43 59.68c-.123.386 1.37 2.222 1.532 2.072.163-.15.51-.476.59-.702.08-.226-.038-2.401-.467-2.597-.43-.195-1.56.928-1.655 1.227z"
      />
      <path
        fill="#000"
        d="M74.43 59.68c-.123.386 1.37 2.222 1.532 2.072.163-.15.51-.476.59-.702.08-.226-.038-2.401-.467-2.597-.43-.195-1.56.928-1.655 1.227zM72.743 64.71l-1.647 1.04-.292-.462 1.703-.951.236.372z"
        opacity="0.1"
      />
      <path
        fill="#EECD4A"
        d="M73.79 73.773L46.471 91.051 35.597 70.656 59.65 53.594l14.14 20.18z"
      />
      <path
        fill="#000"
        d="M73.79 73.773L46.47 91.05l-.582-1.094 26.035-18.85 1.867 2.667z"
        opacity="0.1"
      />
      <path
        fill="#EBEBEB"
        d="M46.888 92.014L88.61 65.627l-.785-1.242-41.723 26.387.785 1.242z"
      />
      <path
        fill="#DBDBDB"
        d="M88.316 63.24l-4 2.887 1.12 1.771 4.473-2.828-1.593-1.83zM48.803 91.066l-1.096-1.733-2.798 1.39.945 2.208 2.95-1.865z"
      />
      <path
        fill="#C7C7C7"
        d="M52.17 89.287l-.944.42c-.545-1.228-.905-3.975 1.644-5.586 2.548-1.612 4.859-.094 5.721.935l-.79.663.399-.334-.399.335c-.079-.093-1.96-2.257-4.38-.726-2.439 1.542-1.301 4.181-1.251 4.293z"
      />
      <path
        fill="#263238"
        d="M53.188 84.66c-4.068 2.574-.076 8.9 3.999 6.323 4.074-2.576.077-8.9-3.999-6.322z"
      />
      <path
        fill="#C7C7C7"
        d="M54.28 86.387c-1.846 1.168-.035 4.04 1.815 2.87 1.85-1.17.035-4.04-1.815-2.87zM76.54 73.876l-.945.415c-.546-1.226-.906-3.973 1.644-5.585 2.549-1.613 4.858-.094 5.72.935l-.79.667.4-.334-.4.335c-.079-.093-1.96-2.257-4.38-.727-2.419 1.53-1.297 4.182-1.249 4.294z"
      />
      <path
        fill="#263238"
        d="M77.573 69.239c-4.068 2.573-.076 8.9 3.999 6.322 4.074-2.576.076-8.9-3.999-6.322z"
      />
      <path
        fill="#C7C7C7"
        d="M78.665 70.965c-1.846 1.168-.035 4.04 1.815 2.87 1.85-1.17.035-4.04-1.815-2.87z"
      />
    </svg>
  );
}
