import React, { PropsWithChildren } from 'react';
import tw from 'twin.macro';
import { MobileBottomBar } from '../../organism';
import MobileTopBar from '../../organism/MobileTopBar/MobileTopBar.organism';

type Props<T> = PropsWithChildren<T>;

const Container = tw.div`flex flex-col`;

export default function MobileScreenContainer<T>({ children }: Props<T>) {
  return (
    <Container id="mobileScreenContainer">
      <MobileTopBar />
      {children}
      <MobileBottomBar />
    </Container>
  );
}
