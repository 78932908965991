import { ShipperOrderBy } from '../model/Shipper.model';

// #region INTERFACES
export enum ShipperStatusFilter {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type ShipperSortByValue = {
  label: string;
  value: ShipperOrderBy;
};

export type ShipperStatusValue = {
  label: string;
  value: ShipperStatusFilter;
};

export type ShipperFilterInitialValues = {
  sortBy: ShipperOrderBy;
  status: ShipperStatusFilter[];
};
// #endregion

export const shipperFilterInitialValues: ShipperFilterInitialValues = {
  sortBy: ShipperOrderBy.STATUS_ASC,
  status: Object.values(ShipperStatusFilter),
};
