import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';
import DriverDeactivateView from './DriverDeactivate.view';

export type DriverDeactivateRouteParam = DefaultRouteParam;

export const driverDeactivateRoute: RouteConfig = {
  name: 'Deactivate',
  Component: React.lazy(() => import('./DriverDeactivate.view')),
  NormalComponent: <DriverDeactivateView />,
  path: '/driver/deactivate',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
