import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';
import DeliveryLocationDeleteView from './DeliveryLocationDelete.view';

export type DeliveryLocationDeleteRouteParam = DefaultRouteParam;

export const deliveryLocationDeleteRoute: RouteConfig = {
  name: 'Delete',
  Component: React.lazy(() => import('./DeliveryLocationDelete.view')),
  NormalComponent: <DeliveryLocationDeleteView />,
  path: '/delivery-location/delete',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
