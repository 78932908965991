import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterOrderManagementJORouteParam = DefaultRouteParam;

export const helpCenterOrderManagementJORoute: RouteConfig = {
  name: 'Help Center Order Management JO',
  Component: React.lazy(() => import('./HelpCenterOrderManagementJO.view')),
  path: '/help-center/order-management/job-order',
  isPrivate: false,
  breadcrumb: 'Help Center Order Management JO',
  options: {
    label: 'Perintah Kerja',
    testID: 'MenuHelpCenterOrderManagementJO',
  },
};
