import React, { PropsWithChildren } from 'react';
import tw, { theme } from 'twin.macro';
import { Photo } from '../../../model/Common.model';
import { FormItem } from '../../../types/input.type';
import {
  Button,
  CardContainer,
  Icon,
  IconButton,
  Image,
  Text,
} from '../../atom';
import { CardContent, CardHeader, ShipperOrderStatus } from '../../molecule';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';

const Container = tw.div`flex flex-col w-full`;
const ToolsContainer = tw.div`flex flex-col items-center md:(flex-row justify-between)`;
const ToolsDividerContainer = tw.div`flex items-center space-x-1 mb-2`;
const ContainerForm = tw.div`pb-2`;
const Spacer = tw.div`my-2`;
const ImageContainer = tw.div`w-[216px] h-[216px] overflow-hidden rounded-xl cursor-pointer`;
const StatusBadgeContainer = tw.div`ml-auto`;
const DownloadButton = tw(Button.Outlined)`p-0 h-6 w-6`;

type Props = PropsWithChildren<{
  onImageClick: (idx: number) => void;
  onDownloadClick?: () => void;
  onBack: () => void;
  action?: React.ReactElement;
  headerTitle: string;
  formTitle: string;
  formItem: FormItem[];
  pod: Photo[];
  podTitle: string;
  status: number;
  statusLabel: string;
}>;

function HaulingJobOrderForm({
  headerTitle,
  onBack,
  formTitle,
  formItem,
  pod,
  podTitle,
  onImageClick,
  action,
  children,
  status,
  statusLabel,
  onDownloadClick,
}: Props) {
  return (
    <Container>
      <ToolsContainer>
        <ToolsDividerContainer>
          <IconButton tw="justify-center" onClick={onBack}>
            <Icon.ArrowBack fill={theme`colors.orange.DEFAULT`} />
          </IconButton>

          <Text.TableParagraph tw="font-semibold truncate max-width[500px]">
            {headerTitle}
          </Text.TableParagraph>
        </ToolsDividerContainer>
      </ToolsContainer>
      <CardContainer>
        <CardHeader
          title={formTitle}
          containerStyle={tw`py-4 px-5`}
          subheaderStyle={tw`mr-0`}
          subHeader={
            <StatusBadgeContainer>
              <ShipperOrderStatus status={status} statusLabel={statusLabel} />
            </StatusBadgeContainer>
          }
        />
        <CardContent>
          {formItem.map((item) => (
            <ContainerForm key={`haulingJobOrder-${item.id}`}>
              {/*
               * NOTE: ComposedFormField is a generic component which contains numbers of different types field (text, combo box, etc), without spreading
               * we need to classify each `item` type and this render section will grow. on the otherhand we're using some strict eslint rules which disallowed us to spread the props
               * so to simplify the implementation we disabled the rule on this line and anoother file which use the same pattern.
               */}
              <ComposedFormField {...item} />
            </ContainerForm>
          ))}
          {children}
        </CardContent>
        {action}
      </CardContainer>
      <Spacer />
      {pod?.length > 0 && (
        <CardContainer>
          <CardHeader
            title={podTitle}
            containerStyle={tw`py-4 px-5`}
            subheaderStyle={tw`mr-0`}
            subHeader={
              onDownloadClick ? (
                <DownloadButton onClick={onDownloadClick}>
                  <Icon.Download.Small />
                </DownloadButton>
              ) : undefined
            }
          />
          <CardContent>
            <div tw="grid-cols-3 grid gap-x-4 gap-y-11">
              {pod.map((photo, idx) => (
                <ImageContainer
                  key={photo.id}
                  onClick={() => onImageClick(idx)}
                >
                  <Image src={photo.accessUrl} tw="w-[216px] h-[216px]" />
                </ImageContainer>
              ))}
            </div>
          </CardContent>
        </CardContainer>
      )}
    </Container>
  );
}

export default HaulingJobOrderForm;
