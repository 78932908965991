import React, { useMemo } from 'react';
import tw, { theme } from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { promiseToVoid } from '../../../util/helper.util';
import { UseJODriverAddFormHookObj } from '../../../view/DriverAdd/hooks/useJODriverAddForm.hook';
import { CardContainer, Divider, IconButton } from '../../atom';
import { ArrowBack, InfoOutlined } from '../../atom/Icon/Icon.atom';
import { BodyFourteen, HeadingFour } from '../../atom/Text/Text.atom';
import { CardAction, CardContent, CardHeader } from '../../molecule';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';

// #region STYLED COMPONENT
const Container = tw.div`flex flex-col gap-5 width[720px] mx-auto `;
const ContainerHeader = tw.div`flex gap-2.5 items-center -mb-2`;
const DriverCardContent = tw(CardContent)`grid gap-2`;
const SectionTitle = tw.div`pt-5 px-5`;
const SectionDivider = tw(Divider)`mx-5 w-auto`;
const FormCardContent = tw.div`mx-5 pt-5 gap-2 flex flex-col`;
const AlertContainer = tw.div`border border-status-fuel-anomaly rounded-lg bg-blue-five p-2.5 flex gap-2`;
const AlertIconContainer = tw.div`mt-1`;
const AlertText = tw(BodyFourteen)`whitespace-pre-wrap`;
// #endregion

// #region INTERFACE
type Props = {
  translator: UseTranslator;
  driverAddForm: UseJODriverAddFormHookObj;
  handleBack(): void;
};
// #endregion

export default function JODriverForm({
  translator,
  driverAddForm,
  handleBack,
}: Props) {
  const personalInformationFormData = useMemo(
    () =>
      driverAddForm.personalInformationFormData.map((item) => (
        <ComposedFormField
          key={`personal-information-form-jo-driver-add-${item.id}`}
          // type already described inside component
          {...item}
        />
      )),
    [driverAddForm.personalInformationFormData],
  );

  const loginInformationFormData = useMemo(
    () =>
      driverAddForm.loginInformationFormData.map((item) => (
        // type already described inside component
        <ComposedFormField
          key={`login-information-form-${item.id}`}
          {...item}
        />
      )),
    [driverAddForm.loginInformationFormData],
  );

  return (
    <Container>
      <ContainerHeader>
        <IconButton onClick={handleBack}>
          <ArrowBack fill={theme`colors.orange`} />
        </IconButton>
        <HeadingFour>{translator.translate('Add Driver')}</HeadingFour>
      </ContainerHeader>

      <CardContainer>
        <CardHeader
          containerStyle={tw`px-6 py-4`}
          title={translator.translate('Driver Information')}
        />
        <SectionTitle>
          <HeadingFour>
            {translator.translate('Personal Information')}
          </HeadingFour>
        </SectionTitle>
        <DriverCardContent>{personalInformationFormData}</DriverCardContent>
        <SectionDivider />
        <FormCardContent>
          <HeadingFour>{translator.translate('Login Information')}</HeadingFour>
          <AlertContainer>
            <AlertIconContainer>
              <InfoOutlined
                width={22}
                fillPath={theme`colors.status.fuel-anomaly`}
              />
            </AlertIconContainer>
            <AlertText>
              {translator.translate(
                'This information will be used to log into the Driver App. Please ensure you save this information before completing the registration.',
              )}
            </AlertText>
          </AlertContainer>
        </FormCardContent>
        <DriverCardContent>{loginInformationFormData}</DriverCardContent>
        <CardAction
          isFormFooter
          isActionDisabled={
            !driverAddForm.driverForm.isValid ||
            driverAddForm.driverForm.isSubmitting
          }
          handleAction={() =>
            promiseToVoid([driverAddForm.driverForm.submitForm()])
          }
          additionalTitle={translator.translate('Cancel')}
          title={translator.translate('Add Driver')}
        />
      </CardContainer>
    </Container>
  );
}
