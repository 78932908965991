import { IOrganizationSetting } from '../model/Organization.model';

export enum SettingNavigationItemType {
  DISPLAY = 'DISPLAY',
  GEOFENCE = 'GEOFENCE',
  DRIVER_MANAGEMENT = 'DRIVER_MANAGEMENT',
  SHIPPER_ORDER = 'SHIPPER_ORDER',
  ADVANCED_SETTINGS = 'ADVANCED_SETTINGS',
}

export interface SettingNavigationItem {
  path: SettingNavigationItemType;
  label: string;
  testID: string;
}

export const SettingMenuWithForm = [
  SettingNavigationItemType.DISPLAY,
  SettingNavigationItemType.DRIVER_MANAGEMENT,
  SettingNavigationItemType.GEOFENCE,
  SettingNavigationItemType.SHIPPER_ORDER,
];

export const settingSidebarItems: SettingNavigationItem[] = [
  {
    path: SettingNavigationItemType.DISPLAY,
    label: 'Display',
    testID: 'SettingDisplay',
  },
  {
    path: SettingNavigationItemType.GEOFENCE,
    label: 'Site Marker',
    testID: 'SettingGeofence',
  },
  {
    path: SettingNavigationItemType.DRIVER_MANAGEMENT,
    label: 'Driver Management',
    testID: 'SettingDriverManagement',
  },
  {
    path: SettingNavigationItemType.SHIPPER_ORDER,
    label: 'Shipper Order',
    testID: 'SettingShipperOrder',
  },
  {
    path: SettingNavigationItemType.ADVANCED_SETTINGS,
    label: 'Advanced Settings',
    testID: 'SettingAdvancedSettings',
  },
];

export const settingInitialValues: IOrganizationSetting = {
  isProofOfActivityRequired: false,
  isPhotoGalleryAllowed: true,
  isGeofenceStatusEnabled: true,
  isAlwaysUseTransitForm: false,
};
