import React from 'react';
import tw, { styled } from 'twin.macro';
import { SOStatus } from '../../../constant';
import {
  JobOrderDeliveryStatus,
  JobOrderExpenseStatus,
} from '../../../model/JobOrder.model';
import { Text } from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region INTERFACES
type StatusProps = {
  expenseStatus?: JobOrderExpenseStatus;
  status?: SOStatus;
  deliveryStatus?: JobOrderDeliveryStatus;
};
type Props = StatusProps & {
  statusLabel: string;
  containerStyle?: ExtraStyle | React.CSSProperties | null;
};
// #endregion

// #region STYLED
type GetColorType = StatusProps & {
  isDark?: boolean;
};
const getBgStyle = ({
  expenseStatus,
  deliveryStatus,
  status,
  isDark,
}: GetColorType) => {
  if (expenseStatus) {
    const expenseBg = {
      [JobOrderExpenseStatus.DRAFT]: isDark
        ? tw`bg-draft-dark`
        : tw`bg-draft-light`,
      [JobOrderExpenseStatus.PENDING]: isDark
        ? tw`bg-in-process-dark`
        : tw`bg-in-process-light`,
      [JobOrderExpenseStatus.APPROVED]: isDark
        ? tw`bg-delivered-dark`
        : tw`bg-delivered-light`,
      [JobOrderExpenseStatus.REJECTED]: isDark
        ? tw`bg-deleted-dark`
        : tw`bg-deleted-light`,
    };

    return expenseBg[expenseStatus];
  }

  if (deliveryStatus) {
    const deliveryBg = {
      [JobOrderDeliveryStatus.COMPLETED]: isDark
        ? tw`bg-delivered-dark`
        : tw`bg-delivered-light`,
      [JobOrderDeliveryStatus.ON_GOING]: isDark
        ? tw`bg-delivering-dark`
        : tw`bg-delivering-light`,
      [JobOrderDeliveryStatus.ASSIGNED]: isDark
        ? tw`bg-assigned-dark`
        : tw`bg-assigned-light`,
      [JobOrderDeliveryStatus.DRAFT]: isDark
        ? tw`bg-draft-dark`
        : tw`bg-draft-light`,
    };

    return deliveryBg[deliveryStatus];
  }

  if (status) {
    const soBg = {
      [SOStatus.ASSIGNED]: isDark
        ? tw`bg-assigned-dark`
        : tw`bg-assigned-light`,
      [SOStatus.TRANSITING]: isDark
        ? tw`bg-transiting-light`
        : tw`bg-transiting-dark`, // based on mockup
      [SOStatus.IN_TRANSIT]: isDark
        ? tw`bg-delivering-light`
        : tw`bg-delivering-dark`, // based on mockup
      [SOStatus.RESERVED]: isDark
        ? tw`bg-reserved-light`
        : tw`bg-reserved-dark`, // based on mockup
      [SOStatus.DELETED]: isDark
        ? tw`bg-status-alert`
        : tw`bg-status-alert-light`,
      [SOStatus.DELIVERED]: isDark
        ? tw`bg-delivered-dark`
        : tw`bg-delivered-light`,
      [SOStatus.DELIVERING]: isDark
        ? tw`bg-delivering-dark`
        : tw`bg-delivering-light`,
      [SOStatus.IN_PROCESS]: isDark
        ? tw`bg-in-process-dark`
        : tw`bg-in-process-light`,
    };

    return soBg[status];
  }

  return tw`bg-assigned-light`;
};
const getTextStyle = ({
  expenseStatus,
  deliveryStatus,
  status,
}: GetColorType) => {
  if (expenseStatus) {
    const expenseBg = {
      [JobOrderExpenseStatus.DRAFT]: tw`text-draft-dark`,
      [JobOrderExpenseStatus.PENDING]: tw`text-in-process-dark`,
      [JobOrderExpenseStatus.APPROVED]: tw`text-delivered-dark`,
      [JobOrderExpenseStatus.REJECTED]: tw`text-deleted-dark`,
    };

    return expenseBg[expenseStatus];
  }

  if (deliveryStatus) {
    const deliveryBg = {
      [JobOrderDeliveryStatus.COMPLETED]: tw`text-delivered-dark`,
      [JobOrderDeliveryStatus.ON_GOING]: tw`text-delivering-dark`,
      [JobOrderDeliveryStatus.ASSIGNED]: tw`text-assigned-dark`,
      [JobOrderDeliveryStatus.DRAFT]: tw`text-draft-dark`,
    };

    return deliveryBg[deliveryStatus];
  }

  if (status) {
    const soBg = {
      [SOStatus.ASSIGNED]: tw`text-assigned-dark`,
      [SOStatus.TRANSITING]: tw`text-transiting-light`,
      [SOStatus.IN_TRANSIT]: tw`text-delivering-light`,
      [SOStatus.RESERVED]: tw`text-assigned-light`,
      [SOStatus.DELETED]: tw`text-status-alert`,
      [SOStatus.DELIVERED]: tw`text-delivered-dark`,
      [SOStatus.DELIVERING]: tw`text-delivering-dark`,
      [SOStatus.IN_PROCESS]: tw`text-in-process-dark`,
    };

    return soBg[status];
  }

  return tw`bg-assigned-light`;
};
const StatusContainer = styled.div(
  ({ status, deliveryStatus, expenseStatus }: StatusProps) => [
    tw`px-2 py-0.5 inline-block font-bold rounded-md`,
    getBgStyle({ deliveryStatus, status, expenseStatus }),
  ],
);

const SquareIcon = styled.div(
  ({ status, deliveryStatus, expenseStatus }: StatusProps) => [
    tw`inline-block rounded-sm w-[10px] h-[10px] mr-2`,
    getBgStyle({ status, deliveryStatus, expenseStatus, isDark: true }),
  ],
);
// #endregion

// TODO: Refactor this to generic component maybe with more generic name, since now hauling job order also use this component
function ShipperOrderStatus({
  statusLabel,
  containerStyle,
  status,
  deliveryStatus,
  expenseStatus,
}: Props) {
  return (
    <StatusContainer
      status={status}
      deliveryStatus={deliveryStatus}
      expenseStatus={expenseStatus}
      css={containerStyle}
    >
      <SquareIcon
        status={status}
        deliveryStatus={deliveryStatus}
        expenseStatus={expenseStatus}
      />

      <Text.BodyTwo
        css={[getTextStyle({ deliveryStatus, status, expenseStatus })]}
      >
        {statusLabel}
      </Text.BodyTwo>
    </StatusContainer>
  );
}

export default ShipperOrderStatus;
