import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterOrderManagementHORouteParam = DefaultRouteParam;

export const helpCenterOrderManagementHORoute: RouteConfig = {
  name: 'Help Center Order Management HO',
  Component: React.lazy(() => import('./HelpCenterOrderManagementHO.view')),
  path: '/help-center/order-management/hauling-order',
  isPrivate: false,
  breadcrumb: 'Help Center Order Management HO',
  options: {
    label: 'Hauling Order',
    testID: 'MenuHelpCenterOrderManagementHO',
  },
};
