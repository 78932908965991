import React from 'react';
import { Icon } from '../../component/atom';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type DeliveryLocationRouteParam = DefaultRouteParam;

export const deliveryLocationRoute: RouteConfig = {
  name: 'Delivery Location',
  Component: React.lazy(() => import('./DeliveryLocation.view')),
  path: '/delivery-location',
  isPrivate: true,

  breadcrumb: 'Delivery Location',

  options: {
    icon: <Icon.DeliveryLocation />,
    label: 'Delivery Location',
    testID: 'MenuDeliveryLocation',
  },
};
