import addMonths from 'date-fns/addMonths';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import isToday from 'date-fns/isToday';
import isWithinInterval from 'date-fns/isWithinInterval';
import setMonth from 'date-fns/setMonth';
import setYear from 'date-fns/setYear';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfToday from 'date-fns/startOfToday';
import startOfWeek from 'date-fns/startOfWeek';
import subMonths from 'date-fns/subMonths';
import React, { useEffect, useMemo, useState } from 'react';
import tw, { css, styled, theme } from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { getMonthList } from '../../../util/date.util';
import { Divider as BaseDivider, Icon, Text } from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region STYLED
const Root = tw.section`min-w-[284px] rounded border border-beige-lines`;
const Header = tw.header`px-4 py-2.5 flex items-center justify-between border-b border-beige-lines bg-beige-bg`;
const MonthStepper = tw.button`flex flex-none items-center justify-center`;
const ScreenReader = tw.span`sr-only`;
const CalendarMonthAndYearButton = tw.button`flex items-center`;
const IconPlaceholder = tw.div`h-6 w-6`;

// Day Picker
const WeekList = tw.ul`px-4 pt-4 grid grid-cols-7 text-center`;
const Week = styled(Text.BodyFourteen)(
  ({ isHoliweekday }: { isHoliweekday: boolean }) => [
    tw`w-9 h-9 flex items-center justify-center`,
    isHoliweekday && tw`text-status-alert`,
  ],
);
const DayList = tw.ul`grid grid-cols-7 gap-y-1 mt-2 px-4 pb-4`;
const Day = styled.button(
  ({
    isWithinIntervals,
    isTodays,
    isDayEqualToSelectedDay,
    isDaySameMonthAsCurrentMonth,
    isWeekday,
    disableAction,
  }: {
    isWithinIntervals: boolean;
    isTodays: boolean;
    isDayEqualToSelectedDay: boolean;
    isDaySameMonthAsCurrentMonth: boolean;
    isWeekday: boolean;
    disableAction: boolean;
  }) => [
    tw`relative w-9 h-9 flex items-center justify-center text-sm leading-6 text-grey-two disabled:(opacity-40 cursor-default)`,

    !isTodays &&
      !isDayEqualToSelectedDay &&
      !isDaySameMonthAsCurrentMonth &&
      tw`opacity-40`,
    isWeekday && tw`text-status-alert`,
    isWithinIntervals && tw`bg-neutral-50`,
    !isWithinIntervals &&
      isDayEqualToSelectedDay &&
      tw`bg-orange text-white rounded-full`,
    isDayEqualToSelectedDay && tw`font-semibold`,
    !isDayEqualToSelectedDay && !disableAction && tw`hover:bg-orange-hover`,
    disableAction && tw`font-normal cursor-default`,

    css`
      &[data-is-today='true']:not(&[data-is-within-daterange='true']):not(&[data-is-selected-highlight-within-interval='true']) {
        ${tw`before:(absolute inset-0 rounded-full border-2 border-black)`}
      }

      &[data-is-start-highlight='true']:not(&[data-is-end-highlight='true']) {
        ${tw`border-r-0 border-top-left-radius[999px] border-bottom-left-radius[999px] w-full`}
      }
      &[data-is-within-highlight='true'][data-is-start-highlight='true'][data-is-end-highlight='true'] {
        ${tw`rounded-full`}
      }
      &[data-is-within-highlight='true']:not(&[data-is-start-highlight='true']):not(&[data-is-end-highlight='true']) {
        ${tw`border-l-0 border-r-0 w-full`}
      }
      &[data-is-end-highlight='true']:not(&[data-is-start-highlight='true']) {
        ${tw`border-l-0 border-top-right-radius[999px] border-bottom-right-radius[999px] w-full`}
      }
      &:not(&[data-is-within-highlight='true']) {
        ${tw`hover:rounded-full`}
      }
      &[data-is-selected-highlight-within-interval='true'] {
        ${tw`border border-orange bg-orange-hover`}
      }
    `,
  ],
);

// Month Picker
const Divider = tw(BaseDivider)`w-full my-3.5`;
const MonthList = tw.ul`p-5 pb-2.5 flex flex-wrap justify-between`;
const Month = tw.button`h-9 w-1/4 flex items-center justify-center rounded-full text-black hover:bg-orange-hover`;

// Year Picker
const YearList = tw.ul`p-5 pb-2.5 max-h-[340px] flex flex-wrap justify-between overflow-y-auto`;
const Year = tw.button`h-9 w-1/4 flex items-center justify-center rounded-full text-black hover:bg-orange-hover`;
// #endregion

// #region TYPES
export type NativeCalendarYearRange = {
  from: number;
  to: number;
};
export type NativeCalendarChoosePicker = 'day' | 'month' | 'year';
export type NativeCalendarHoliweekday = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type NativeCalendarHighlight = {
  id: string;
  start: Date;
  end: Date;
};

type NativeCalendarYearPickerProps = {
  monthAndYear: Date;
  handleClickChangeYear: (monthIdx: number) => void;

  yearRange?: NativeCalendarYearRange;
};

type NativeCalendarMonthPickerProps = {
  monthAndYear: Date;
  handleClickChangeMonth: (monthIdx: number) => void;
};

type NativeCalendarDayPickerProps = {
  day: Date | undefined;
  monthAndYear: Date;
  handleClickDay: (_day: Date) => void;

  disableAction?: boolean;
  disabledDays?: Date[];
  holiweekdays?: NativeCalendarHoliweekday[];
  highlights?: NativeCalendarHighlight[];
  selectedHighlight?: NativeCalendarHighlight;
};

export type NativeCalendarProps = Omit<
  NativeCalendarDayPickerProps,
  'day' | 'monthAndYear' | 'handleClickDay'
> &
  Pick<NativeCalendarYearPickerProps, 'yearRange'> & {
    date?: Date;
    month?: Date;
    rootStyle?: ExtraStyle;
    handleClickPreviousMonth?: (_prevMonth: Date) => void;
    handleClickNextMonth?: (_nextMonth: Date) => void;
    /**
     * this is triggered before `disableAction` checker, so it will always triggered even if we pass in `disableAction`
     */
    handleClickDay?: (_day: Date) => void;
  };
// #endregion

const colStartClasses = [
  tw``,
  tw`col-start-2`,
  tw`col-start-3`,
  tw`col-start-4`,
  tw`col-start-5`,
  tw`col-start-6`,
  tw`col-start-7`,
];

// #region YEAR PICKER
function YearPicker({
  monthAndYear,
  handleClickChangeYear,
  yearRange,
}: NativeCalendarYearPickerProps) {
  const yearOptions = useMemo(() => {
    if (yearRange) {
      const { from, to } = yearRange;
      return Array.from(Array(to - from + 1).keys()).map((i) => i + from);
    }

    // Default year range 25 years to the past and 25 years to the future
    return Array.from(Array(50).keys()).map(
      (i) => i + new Date().getFullYear() - 25,
    );
  }, [yearRange]);

  return (
    <YearList data-testid="NativeCalendar:Year">
      {yearOptions.map((_year, idx) => (
        <React.Fragment key={`NativeCalendar:Year:${_year}`}>
          <Year
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              handleClickChangeYear(_year);
            }}
            css={[
              _year === monthAndYear.getFullYear() &&
                tw`bg-orange text-white hover:bg-orange`,
            ]}
            data-year={_year}
          >
            {_year}
          </Year>

          {idx !== 0 && idx % 4 === 3 && <Divider />}
        </React.Fragment>
      ))}
    </YearList>
  );
}
// #endregion

// #region MONTH PICKER
function MonthPicker({
  monthAndYear,
  handleClickChangeMonth,
}: NativeCalendarMonthPickerProps) {
  const translator = useTranslator();
  const listMonth = useMemo(
    () => getMonthList(translator.currentLanguage),
    [translator.currentLanguage],
  );

  return (
    <MonthList data-testid="NativeCalendar:Month">
      {listMonth.map((_month, idx) => (
        <React.Fragment key={`NativeCalendar:Month:${_month}`}>
          <Month
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              handleClickChangeMonth(idx); // idx starts at 0, which equals to "January"
            }}
            css={[
              idx === monthAndYear.getMonth() &&
                tw`bg-orange text-white hover:bg-orange`,
            ]}
            data-month={_month}
          >
            {_month.slice(0, 3)}
          </Month>

          {(idx === 3 || idx === 7) && <Divider />}
        </React.Fragment>
      ))}
    </MonthList>
  );
}
// #endregion

// #region DAY PICKER
function DayPicker({
  day,
  monthAndYear,
  disableAction = false,
  disabledDays,
  holiweekdays = [],
  highlights = [],
  selectedHighlight,
  handleClickDay,
}: NativeCalendarDayPickerProps) {
  const translator = useTranslator();
  const startMonthOfSelectedFrom = startOfMonth(monthAndYear);
  // NOTE: to show additional date overflow, wrap it in `startOfWeek` to `start` OR `endOfWeek` to `end` with `{ weekStartsOn: 0 }`
  const days = eachDayOfInterval({
    start: startOfWeek(startMonthOfSelectedFrom, { weekStartsOn: 0 }),
    end: endOfWeek(endOfMonth(startMonthOfSelectedFrom), { weekStartsOn: 0 }),
  });

  const checkIsHoliweekday = (weekday: NativeCalendarHoliweekday) =>
    holiweekdays.some((_weekday) => _weekday === weekday);

  return (
    <>
      <WeekList>
        <Week isHoliweekday={checkIsHoliweekday(0)}>
          {translator.translate('Sun').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(1)}>
          {translator.translate('Mon').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(2)}>
          {translator.translate('Tue').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(3)}>
          {translator.translate('Wed').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(4)}>
          {translator.translate('Thu').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(5)}>
          {translator.translate('Fri').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(6)}>
          {translator.translate('Sat').slice(0, 1)}
        </Week>
      </WeekList>

      <DayList>
        {days.map((_day, _dayIdx) => (
          <Day
            type="button"
            key={_day.toString()}
            disabled={disabledDays?.some((_disabledDay) =>
              isSameDay(_day, _disabledDay),
            )}
            onClick={() => handleClickDay(_day)}
            disableAction={disableAction}
            isWithinIntervals={highlights.some((_highlight) =>
              isWithinInterval(_day, _highlight),
            )}
            isTodays={isToday(_day)}
            isDayEqualToSelectedDay={!!day && isSameDay(_day, day)}
            isDaySameMonthAsCurrentMonth={isSameMonth(
              _day,
              startMonthOfSelectedFrom,
            )}
            isWeekday={checkIsHoliweekday(getDay(_day))}
            css={[_dayIdx === 0 && colStartClasses[getDay(_day)]]}
            // <--------- custom data attributes --------->
            data-day={format(_day, 'yyyy-MM-dd')}
            data-is-today={isToday(_day)}
            data-is-first-column={_dayIdx % 7 === 0}
            data-is-last-column={_dayIdx !== 0 && _dayIdx % 7 === 6}
            data-is-start-highlight={highlights.some(
              (_highlight) =>
                isWithinInterval(_day, _highlight) &&
                isSameDay(_day, startOfDay(_highlight.start)),
            )}
            data-is-within-highlight={highlights.some((_highlight) =>
              isWithinInterval(_day, _highlight),
            )}
            data-is-end-highlight={highlights.some(
              (_highlight) =>
                isWithinInterval(_day, _highlight) &&
                isSameDay(_day, startOfDay(_highlight.end)),
            )}
            data-is-selected-highlight-within-interval={
              !!selectedHighlight && isWithinInterval(_day, selectedHighlight)
            }
          >
            {format(_day, 'd')}
          </Day>
        ))}
      </DayList>
    </>
  );
}
// #endregion

export default function NativeCalendar({
  date,
  month,
  rootStyle,
  handleClickPreviousMonth,
  handleClickNextMonth,
  handleClickDay,

  disableAction = false,
  disabledDays,
  holiweekdays = [],
  highlights = [],
  selectedHighlight,

  yearRange,
}: NativeCalendarProps) {
  // #region VALUES
  const translator = useTranslator();
  const [choosePicker, setChoosePicker] =
    useState<NativeCalendarChoosePicker>('day');
  const [day, setDay] = useState(date);
  const [monthAndYear, setMonthAndYear] = useState(
    month ?? day ?? startOfToday(),
  );
  // #endregion

  // #region HANDLERS
  const onClickPreviousMonth = () => {
    const prevMonth = subMonths(monthAndYear, 1);
    setMonthAndYear(prevMonth);
    handleClickPreviousMonth?.(prevMonth);
  };
  const onClickPicker = () => {
    if (choosePicker === 'day') setChoosePicker('month');
    if (choosePicker === 'month') setChoosePicker('year');
    if (choosePicker === 'year') setChoosePicker('day');
  };
  const onClickNextMonth = () => {
    const nextMonth = addMonths(monthAndYear, 1);
    setMonthAndYear(nextMonth);
    handleClickNextMonth?.(nextMonth);
  };
  const handleClickChangeMonth = (monthIdx: number) => {
    setMonthAndYear(setMonth(monthAndYear, monthIdx));
    setChoosePicker('day'); // back to day picker
  };
  const handleClickChangeYear = (year: number) => {
    setMonthAndYear(setYear(monthAndYear, year));
    setChoosePicker('day'); // back to day picker
  };
  const onClickDay = (_day: Date) => {
    handleClickDay?.(_day);

    if (disableAction) return;
    setDay(_day);
  };
  // #endregion

  // sync state from props
  useEffect(() => {
    setDay(date);
    if (date) setMonthAndYear(date);
  }, [date]);
  useEffect(() => {
    setMonthAndYear(month ?? startOfToday());
  }, [month]);

  return (
    <Root data-testid="NativeCalendar:Root" css={rootStyle}>
      <Header>
        {!handleClickPreviousMonth ? (
          <IconPlaceholder />
        ) : (
          <MonthStepper type="button" onClick={onClickPreviousMonth}>
            <ScreenReader>
              {translator.translate('Previous month')}
            </ScreenReader>
            <Icon.ChevronSharp
              style={{ transform: 'rotate(180deg)' }}
              stroke={theme`colors.orange.DEFAULT`}
              aria-hidden="true"
            />
          </MonthStepper>
        )}

        <CalendarMonthAndYearButton
          type="button"
          css={[
            !handleClickPreviousMonth &&
              !handleClickNextMonth &&
              tw`w-full justify-center`,
            disableAction && tw`cursor-default`,
          ]}
          disabled={disableAction}
          onClick={onClickPicker}
        >
          <Text.HeadingFive>
            {format(
              monthAndYear,
              choosePicker === 'day' ? 'MMMM yyyy' : 'yyyy',
            )}
          </Text.HeadingFive>
          {!disableAction && <Icon.Triangle />}
        </CalendarMonthAndYearButton>

        {!handleClickNextMonth ? (
          <IconPlaceholder />
        ) : (
          <MonthStepper type="button" onClick={onClickNextMonth}>
            <ScreenReader>{translator.translate('Next month')}</ScreenReader>
            <Icon.ChevronSharp
              stroke={theme`colors.orange.DEFAULT`}
              aria-hidden="true"
            />
          </MonthStepper>
        )}
      </Header>

      {choosePicker === 'day' && (
        <DayPicker
          day={day}
          monthAndYear={monthAndYear}
          disableAction={disableAction}
          disabledDays={disabledDays}
          holiweekdays={holiweekdays}
          highlights={highlights}
          selectedHighlight={selectedHighlight}
          handleClickDay={onClickDay}
        />
      )}

      {choosePicker === 'month' && (
        <MonthPicker
          monthAndYear={monthAndYear}
          handleClickChangeMonth={handleClickChangeMonth}
        />
      )}

      {choosePicker === 'year' && (
        <YearPicker
          monthAndYear={monthAndYear}
          handleClickChangeYear={handleClickChangeYear}
          yearRange={yearRange}
        />
      )}
    </Root>
  );
}
