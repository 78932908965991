import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export default function CircleCheck2({
  width = 35,
  height = 32,
  viewBox = '0 0 35 32',
  fill = 'none',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="32" height="32" rx="16" fill="#32C08D" />
      <path
        d="M10.5 13.8511L16.6489 20L33.5 3"
        stroke="white"
        strokeWidth="4"
      />
    </svg>
  );
}
