import { Locale } from './locale.config';

const createShipperOrderLocaleConfig: Locale[] = [
  {
    en: 'Shipper Order Date',
    id: 'Tanggal Shipper Order',
  },
  {
    en: 'Order Number',
    id: 'Nomor Order',
  },
  {
    en: 'Reference Number',
    id: 'Nomor Referensi',
  },
  {
    en: 'Notes',
    id: 'Catatan',
  },
  {
    en: 'Next',
    id: 'Berikutnya',
  },
  {
    en: 'Delivery Cost',
    id: 'Biaya Kirim',
  },
  {
    en: 'Goods Delivery Cost',
    id: 'Biaya Kirim Barang',
  },
  {
    en: 'Tax',
    id: 'Pajak',
  },
  {
    en: 'Insurance',
    id: 'Asuransi',
  },
  {
    en: 'Activity',
    id: 'Aktivitas',
  },
  {
    en: 'Use Template',
    id: 'Gunakan Templat',
  },
  {
    en: 'You can only use Activity Location with the type of Shipper that owned by the Shipper you chose on the Header section.',
    id: 'Anda hanya dapat menggunakan Templat Pengiriman dari Pengirim yang Anda pilih pada bagian Informasi Umum.',
  },
  {
    en: 'Standby',
    id: 'Standby',
  },
  {
    en: 'Pickup',
    id: 'Pickup',
  },
  {
    en: 'Are you sure you want to Start From Scratch?',
    id: 'Apakah anda yakin ingin memulai dari awal?',
  },
  {
    en: 'Are you sure you want to Use Last Mile Form?',
    id: 'Apakah anda yakin ingin menggunakan Form Last Mile?',
  },
  {
    en: 'Are you sure you want to Use Transit Form?',
    id: 'Apakah anda yakin ingin menggunakan Form Transit?',
  },
  {
    en: 'Public',
    id: 'Umum',
  },
  {
    en: 'Select Shipper',
    id: 'Pilih Pengirim',
  },
  {
    en: 'Shipper date is required.',
    id: 'Tanggal pengiriman wajib diisi.',
  },
  {
    en: 'Order number is required.',
    id: 'Nomor wajib diisi.',
  },
  {
    en: 'Shipper name is required.',
    id: 'Nama Pengirim wajib diisi.',
  },
  {
    en: 'Use Template',
    id: 'Gunakan Templat',
  },
  {
    en: 'Dropoff',
    id: 'Dropoff',
  },
  {
    en: 'Standby Location',
    id: 'Lokasi Standby',
  },
  {
    en: 'Add Other Activity',
    id: 'Tambah Aktivitas',
  },
  {
    en: 'Expected Time',
    id: 'Perkiraan Waktu',
  },
  {
    en: 'Dropoff Time',
    id: 'Waktu Dropoff',
  },
  {
    en: 'Pickup Time',
    id: 'Waktu Pickup',
  },
  {
    en: 'Save as New Activity Template',
    id: 'Simpan sebagai Templat Pengiriman baru',
  },
  {
    en: 'Assign Driver & Vehicle',
    id: 'Tugaskan Sopir & Kendaraan',
  },
  {
    en: 'Assign Delivery Location',
    id: 'Atur Lokasi Pengiriman',
  },
  {
    en: 'Create Shipper Order',
    id: 'Buat Shipper Order',
  },
  {
    en: 'Choose How to Input',
    id: 'Pilih Metode Input',
  },
  {
    en: 'The transit form is selected as the default format.',
    id: 'Formulir transit dipilih sebagai setelan standar',
  },
  {
    en: 'Type is required.',
    id: 'Tipe wajib diisi.',
  },
  {
    en: 'Choose Activity Template',
    id: 'Pilih Templat Pengiriman',
  },
  {
    en: 'Are you sure you want to change the Activity Template?',
    id: 'Apakah Anda yakin ingin mengubah Template Pengiriman?',
  },
  {
    en: 'Submit',
    id: 'Kirim',
  },
  {
    en: 'Browse',
    id: 'Cari',
  },
  {
    en: 'Edit',
    id: 'Ubah',
  },
  {
    en: 'Cancel',
    id: 'Batal',
  },
  {
    en: 'Browse Activity Template',
    id: 'Cari Templat Pengiriman',
  },
  {
    en: 'Assign Driver and Vehicle',
    id: 'Tugaskan Sopir dan Kendaraan',
  },
  {
    en: 'Are you sure you want to Use Template?',
    id: 'Apakah Anda yakin ingin Menggunakan Template?',
  },
  {
    en: 'The created activities will be deleted. This action cannot be undone.',
    id: 'Aktivitas yang dibuat akan dihapus. Tindakan ini tidak bisa dibatalkan.',
  },
  {
    en: 'Changing the input method to Use Template will deleted all the activities you have created. This action cannot be undone.',
    id: 'Mengubah metode input ke Gunakan Templat akan menghapus semua Aktivitas yang telah Anda buat. Tindakan ini tidak bisa dibatalkan.',
  },
  {
    en: 'Create Shipper Order and Job Order',
    id: 'Buat Shipper Order dan Perintah Kerja',
  },
  {
    en: 'Shipper name must be at least 3 characters.',
    id: 'Nama Pengirim minimal 3 karakter.',
  },
  {
    en: 'Shipper name must be at most 255 characters.',
    id: 'Nama Pengirim maksimal 255 karakter.',
  },
  {
    en: 'Email format is invalid.',
    id: 'Format email tidak sesuai.',
  },
  {
    en: 'Mobile number is invalid.',
    id: 'Nomor HP tidak sesuai.',
  },
  {
    en: 'Telephone number is invalid.',
    id: 'Nomor Telepon tidak sesuai.',
  },
  {
    en: 'Shipper order successfully created',
    id: 'Shipper order berhasil dibuat',
  },
  {
    en: 'Shipper order has been created but failed to create and assigned JO',
    id: 'Shipper order berhasil dibuat. Tetapi, gagal membuat dan menugaskan Perintah Kerja',
  },
  {
    en: 'Shipper order has been created and assigned',
    id: 'Shipper order berhasil dibuat dan ditugaskan',
  },
  {
    en: 'Shipper order and Job order has been created but failed to assigned',
    id: 'Shipper order dan Perintah Kerja berhasil dibuat. Tetapi, gagal menugaskan Perintah Kerja',
  },
  {
    en: 'Quantity and Unit',
    id: 'Jumlah dan Satuan',
  },
  {
    en: 'Edit Goods Type',
    id: 'Ubah Tipe Barang',
  },
  {
    en: 'Select Type',
    id: 'Pilih Tipe',
  },
  {
    en: 'Weight & Volume',
    id: 'Berat & Volume',
  },
  {
    en: 'Weight (kg)',
    id: 'Berat (kg)',
  },
  {
    en: 'Volume (m3)',
    id: 'Volume (m3)',
  },
  {
    en: 'Quantity',
    id: 'Jumlah',
  },
  {
    en: 'To set transit form as default format, go to',
    id: 'Untuk memilih formulir transit menjadi default, pergi ke',
  },
  {
    en: 'Prefilled data is not allowed to be empty.',
    id: 'Data yang terisi sebelumnya tidak boleh kosong.',
  },
  {
    en: 'Manual SO number cannot be started with char "SH-"',
    id: 'Nomor SO tidak dapat diawali dengan “SH-”',
  },
  {
    en: 'Tracking Number',
    id: 'Nomor Resi',
  },
  {
    en: 'Your Shipper Order tracking number:',
    id: 'Nomor Resi Shipper Order anda:',
  },
  {
    en: 'Auto-generated Tracking number will be used if you leave this field empty.',
    id: 'Nomor Resi yang dibuat secara otomatis akan digunakan jika Anda membiarkan kolom ini kosong.',
  },

  {
    en: 'Successfully created Shipper Order. Use this tracking number to track your order from',
    id: 'Berhasil membuat Shipper Order. Gunakan nomor Resi ini untuk melacak order anda di',
  },
  {
    en: 'Shipper Order Detail',
    id: 'Detail Shipper Order',
  },
  {
    en: 'Tracking Page',
    id: 'Halaman Tracking',
  },
  {
    en: 'Tracking Number copied to clipboard',
    id: 'Nomor Resi disalin ke clipboard',
  },
  {
    en: 'Use this number to track your order from',
    id: 'Gunakan nomor ini untuk melacak order anda di',
  },
  {
    en: 'Use this number to see more information in',
    id: 'Gunakan nomor ini untuk melihat informasi lebih di',
  },
  {
    en: 'Manual Tracking number cannot be started with char "TRCK-","trck-","HTRCK-" or "htrck-"',
    id: 'Nomor resi tidak dapat diawali dengan "TRCK-","trck-","HTRCK-" atau "htrck-"',
  },
  {
    en: 'Shipper Order and Job Order Successfully Created',
    id: 'Shipper Order dan Perintah Kerja Berhasil Dibuat',
  },
];

export default createShipperOrderLocaleConfig;
