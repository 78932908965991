import React from 'react';
import {
  JODetailSpendingMeta,
  JOSpendingTab,
} from '../../../constant/JobOrder.constant';
import { JobOrderExpenseEventDTO } from '../../../model/JobOrder.model';
import JODetailSpendingInfo from './JODetailSpendingInfo';
import JODetailSpendingTimeline from './JODetailSpendingTimeline';

type Props = {
  tab: JOSpendingTab;
  timelines?: JobOrderExpenseEventDTO[];
  detailContent: JODetailSpendingMeta[];
};

export default function JODetailSpendingContent({
  tab,
  timelines,
  detailContent,
}: Props) {
  if (tab === JOSpendingTab.DETAILS)
    return <JODetailSpendingInfo detailContent={detailContent} />;
  return <JODetailSpendingTimeline timelines={timelines} />;
}
