import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';
import ActivityTemplateDeleteView from './ActivityTemplateDelete.view';

export type ActivityTemplateDeleteRouteParam = DefaultRouteParam;

export const activityTemplateDeleteRoute: RouteConfig = {
  name: 'Delete',
  Component: React.lazy(() => import('./ActivityTemplateDelete.view')),
  NormalComponent: <ActivityTemplateDeleteView />,
  path: '/activity-template/delete',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
