import React from 'react';
import tw, { theme } from 'twin.macro';
import { UseHaulingOrderFilterObj } from '../../../hook/haulingOrder/useHaulingOrderFilter.hook';
import { UseGetShippersAutocompleteObj } from '../../../hook/useGetShippersAutocomplete.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region INTERFACES
type Props = {
  haulingOrderFilter: UseHaulingOrderFilterObj;
  shippersAutocomplete: UseGetShippersAutocompleteObj;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
const DatePickerContainer = tw.div`w-full flex items-center justify-between space-x-2`;
// #endregion

function HaulingOrderFilter({
  haulingOrderFilter,
  shippersAutocomplete,
}: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <Text.HeadingFour
          tw="flex-1 ml-2"
          data-testid={`${haulingOrderFilter.testID}Filter:Title`}
        >
          {haulingOrderFilter.titleLabel}
        </Text.HeadingFour>

        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          data-testid={`${haulingOrderFilter.testID}Filter:ResetAction`}
          onClick={haulingOrderFilter.onClickResetFilter}
        >
          {haulingOrderFilter.resetButtonLabel}
        </Button.Outlined>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading
            data-testid={`${
              haulingOrderFilter.testID || ''
            }Filter:OrderByLabel`}
          >
            {haulingOrderFilter.orderByLabel}
          </InputHeading>

          <ChipContainer>
            {haulingOrderFilter.filterOrderByValues.map((el, i) => {
              const selected =
                haulingOrderFilter.filterForm.values.orderBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      haulingOrderFilter.onClickOrderByChipFilter(el.value)
                    }
                    testID={`${
                      haulingOrderFilter.testID || ''
                    }Filter:OrderByOption:${i}`}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${haulingOrderFilter.testID}Filter:StatusLabel`}
            >
              {haulingOrderFilter.statusLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                haulingOrderFilter.isQueryStatusAll
                  ? haulingOrderFilter.onClickUnselectAllStatus
                  : haulingOrderFilter.onClickSelectAllStatus
              }
            >
              {haulingOrderFilter.isQueryStatusAll
                ? haulingOrderFilter.unselectAllLabel
                : haulingOrderFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {haulingOrderFilter.filterStatusValues.map((el, i) => {
              const selected =
                haulingOrderFilter.filterForm.values.status.includes(el.value);

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      haulingOrderFilter.onClickStatusChipFilter(el.value)
                    }
                    testID={`${haulingOrderFilter.testID}Filter:StatusOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {haulingOrderFilter.isQueryStatusEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {haulingOrderFilter.errorStatusLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${haulingOrderFilter.testID}Filter:ShipperLabel`}
          >
            {haulingOrderFilter.shipperLabel}
          </InputHeading>

          <AutoComplete
            tw="bg-orange-dark-lines"
            value={haulingOrderFilter.filterForm.values.shipperId}
            datas={shippersAutocomplete.shipperOptions}
            selectedOption={haulingOrderFilter.queryShipperOption}
            placeholder={haulingOrderFilter.shipperPlaceholder}
            loading={shippersAutocomplete.shipperListFetchLoading}
            changeData={haulingOrderFilter.onChangeShipperFilter}
            changeInputText={shippersAutocomplete.onChangeShipperAutotext}
            onFetchMore={shippersAutocomplete.onFetchMoreShipper}
            onRemoveData={haulingOrderFilter.onRemoveShipperFilter}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${haulingOrderFilter.testID}Filter:DateRangeLabel`}
          >
            {haulingOrderFilter.dateRangeLabel}
          </InputHeading>

          <DatePickerContainer>
            <DateRangePickerInput
              popoverPlacement="right"
              sdLabel={haulingOrderFilter.sdLabel}
              edLabel={haulingOrderFilter.edLabel}
              sdDateValue={haulingOrderFilter.filterForm.values.startDate}
              sdOnChangeDateValue={haulingOrderFilter.onChangeStartDateFilter}
              edDateValue={haulingOrderFilter.filterForm.values.endDate}
              edOnChangeDateValue={haulingOrderFilter.onChangeEndDateFilter}
              onClickApplyDateRange={haulingOrderFilter.onClickApplyDateRange}
              outsideAlerterStyle={{ width: '100%' }}
              definedRangeOptions={
                haulingOrderFilter.dateRangeDefinedRangeOptions
              }
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={
            haulingOrderFilter.isQueryStatusEmpty ||
            haulingOrderFilter.filterLocalIsFetching
          }
          onClick={haulingOrderFilter.onClickSubmitFilter}
          data-testid={`${haulingOrderFilter.testID}Filter:SubmitButton`}
        >
          {haulingOrderFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default HaulingOrderFilter;
