import { Placement } from '@popperjs/core';
import React, { ReactNode, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import tw, { TwStyle } from 'twin.macro';
import { Tooltip } from '..';
import { Text } from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region INTERFACES
export type CustomTableRowWithPopoverProps = {
  primaryLabel: string;
  secondaryLabel?: string;
  primaryLabelStyle?: ExtraStyle;
  linkStyle?: ExtraStyle;
  containerStyle?: ExtraStyle;
  tooltipContainerStyle?: TwStyle;
  tooltipContentStyle?: ExtraStyle;
  onClickPrimary?: () => void;
  customTooltip?: ReactNode;
  tooltipPlacement?: Placement;
  tooltipWithPortal?: boolean;
  openAsNewTab?: boolean;
  path?: string;
  state?: Record<string, unknown>;
};

interface IRenderPrimaryLabelProps {
  primaryLabel: string;
  tooltipRef?: React.MutableRefObject<null>;
  primaryLabelStyle?: ExtraStyle;
  onClickPrimary?: () => void;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
}
interface IRenderLabelProps extends IRenderPrimaryLabelProps {
  openAsNewTab?: boolean;
  path?: string;
  linkStyle?: ExtraStyle;
  state?: Record<string, unknown>;
}

// #endregion

// #region STYLED
const Container = tw.div`flex place-items-center`;
const CustomTableRowWithPopoverContainer = tw.div`flex flex-col justify-center max-w-xl`;
// #endregion

function PrimaryLabel({
  tooltipRef,
  primaryLabel,
  primaryLabelStyle,
  onClickPrimary,
  handleMouseEnter,
  handleMouseLeave,
}: IRenderPrimaryLabelProps) {
  return (
    <Text.Label
      ref={tooltipRef}
      onClick={onClickPrimary}
      css={
        primaryLabelStyle || [
          !!onClickPrimary && tw`cursor-pointer text-orange`,
        ]
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      tw="truncate"
    >
      {primaryLabel}
    </Text.Label>
  );
}

function LabelComponent({
  openAsNewTab,
  path,
  state,
  tooltipRef,
  primaryLabel,
  primaryLabelStyle,
  linkStyle,
  onClickPrimary,
  handleMouseEnter,
  handleMouseLeave,
}: IRenderLabelProps) {
  if (path)
    return (
      <Link
        to={path}
        state={state}
        css={linkStyle}
        ref={tooltipRef}
        target={openAsNewTab ? '_blank' : undefined}
        rel={openAsNewTab ? 'noreferrer' : undefined}
      >
        <PrimaryLabel
          primaryLabel={primaryLabel}
          primaryLabelStyle={primaryLabelStyle}
          onClickPrimary={onClickPrimary}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      </Link>
    );
  return (
    <PrimaryLabel
      tooltipRef={tooltipRef}
      primaryLabel={primaryLabel}
      primaryLabelStyle={primaryLabelStyle}
      onClickPrimary={onClickPrimary}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
    />
  );
}

function CustomTableRowWithPopover({
  primaryLabel,
  secondaryLabel,
  containerStyle,
  tooltipContainerStyle,
  tooltipContentStyle,
  onClickPrimary,
  customTooltip,
  tooltipPlacement = 'bottom',
  tooltipWithPortal = false,
  primaryLabelStyle,
  linkStyle,
  openAsNewTab,
  path,
  state,
}: CustomTableRowWithPopoverProps) {
  const tooltipRef = useRef(null);
  const [visible, setVisible] = useState(false);

  if (!secondaryLabel)
    return (
      <Container css={containerStyle}>
        <LabelComponent
          primaryLabel={primaryLabel}
          tooltipRef={tooltipRef}
          onClickPrimary={onClickPrimary}
          openAsNewTab={openAsNewTab}
          path={path}
          state={state}
          primaryLabelStyle={primaryLabelStyle}
          linkStyle={linkStyle}
          handleMouseEnter={() => setVisible(true)}
          handleMouseLeave={() => setVisible(false)}
        />
        <Tooltip
          targetRef={tooltipRef}
          visible={visible}
          variant="light"
          placement={tooltipPlacement}
          withPortal={tooltipWithPortal}
          contentStyle={tooltipContentStyle}
        >
          {customTooltip || (
            <Text.Label tw="whitespace-normal break-words">
              {primaryLabel}
            </Text.Label>
          )}
        </Tooltip>
      </Container>
    );

  return (
    <>
      <CustomTableRowWithPopoverContainer
        ref={tooltipRef}
        css={containerStyle}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <LabelComponent
          primaryLabel={primaryLabel}
          tooltipRef={tooltipRef}
          onClickPrimary={onClickPrimary}
          openAsNewTab={openAsNewTab}
          path={path}
          primaryLabelStyle={primaryLabelStyle}
          linkStyle={linkStyle}
          handleMouseEnter={() => setVisible(true)}
          handleMouseLeave={() => setVisible(false)}
        />

        <Text.Label
          css={[
            onClickPrimary
              ? tw`-webkit-line-clamp[2] whitespace-normal display[-webkit-box] -webkit-box-orient[vertical]`
              : tw`whitespace-nowrap`,
          ]}
          tw="overflow-hidden overflow-ellipsis text-grey-three font-size[13px]"
        >
          {secondaryLabel}
        </Text.Label>
      </CustomTableRowWithPopoverContainer>

      <Tooltip
        targetRef={tooltipRef}
        visible={visible}
        variant="light"
        placement={tooltipPlacement}
        withPortal={tooltipWithPortal}
        contentStyle={tooltipContentStyle}
      >
        <CustomTableRowWithPopoverContainer css={tooltipContainerStyle}>
          {customTooltip || (
            <>
              <Text.Label tw="whitespace-normal break-words">
                {primaryLabel}
              </Text.Label>
              <Text.Label tw="text-grey-three font-size[13px] whitespace-normal break-words">
                {secondaryLabel}
              </Text.Label>
            </>
          )}
        </CustomTableRowWithPopoverContainer>
      </Tooltip>
    </>
  );
}

export default CustomTableRowWithPopover;
