import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { DrivingContestNavigationState } from '../../model/DrivingContest.model';

export type DrivingContestRegisterRouteParam =
  | {
      searchParamsFromDrivingContestRegisterList?: DrivingContestNavigationState;
    }
  | undefined;

export const drivingContestRegisterRoute: RouteConfig = {
  name: 'Register Driver Contest Participant',
  Component: React.lazy(() => import('./DrivingContestRegister.view')),
  path: '/driver-contest/register',
  isPrivate: true,
};
