import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterDriverContestRegisterRouteParam = DefaultRouteParam;

export const helpCenterDriverContestRegisterRoute: RouteConfig = {
  name: 'Help Center Driver Contest Register',
  Component: React.lazy(() => import('./HelpCenterDriverContestRegister.view')),
  path: '/help-center/driver-contest/register',
  isPrivate: false,
  breadcrumb: 'Help Center Driver Contest Register',
  options: {
    label: 'Pendaftaran',
    testID: 'MenuHelpCenterDriverContestRegister',
  },
};
