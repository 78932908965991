// #region INTERFACES
export type DetailsState = {
  addShipper: boolean;
  addDeliveryLocation: boolean;
  createShipperOrder: boolean;
  addDriver: boolean;
  createJobOrder: boolean;
};
// #endregion

export const featureFlag = {
  addShipper: false,
  addDeliveryLocation: false,
  addShipperOrder: false,
  addDriver: false,
  addJobOrder: false,
};

export const detailsInitialState: DetailsState = {
  addShipper: false,
  addDeliveryLocation: false,
  createShipperOrder: false,
  addDriver: false,
  createJobOrder: false,
};
