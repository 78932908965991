import React from 'react';
import tw from 'twin.macro';
import { UseShipperOrderPaymentAdd } from '../../../view/ShipperOrder/hooks/useShipperOrderPaymentAdd.hook';
import { UseShipperOrderTable } from '../../../view/ShipperOrder/hooks/useShipperOrderTable.hook';
import { Button, Divider, Icon, LoadingIndicator, Text } from '../../atom';
import { DatePickerInput, TextArea, TextField } from '../../molecule';

// #region TYPES
type ShipperOrderPaymentAddProps = {
  shipperOrderTable: UseShipperOrderTable;
  shipperOrderPaymentAdd: UseShipperOrderPaymentAdd;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const ListContainer = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-4`;
// #endregion

export default function ShipperOrderPaymentAdd({
  shipperOrderTable,
  shipperOrderPaymentAdd,
}: ShipperOrderPaymentAddProps) {
  return (
    <Container>
      <Header>
        <Button.Text
          tw="mr-3 rotate-180"
          onClick={shipperOrderTable.onClickOpenPaymentHistory}
          data-testid={`${shipperOrderPaymentAdd.testID}:BackButton`}
        >
          <Icon.ChevronSharp />
        </Button.Text>

        <Text.HeadingFour
          tw="flex-1"
          data-testid={`${shipperOrderPaymentAdd.testID || ''}:DrawerTitle`}
        >
          {shipperOrderPaymentAdd.titleLabel}
        </Text.HeadingFour>
      </Header>

      <Divider />

      <ListContainer>
        <TextField
          readOnly
          disabled
          containerStyle={tw`flex-none`}
          inputStyle={tw`!text-black`}
          id={shipperOrderPaymentAdd.soNumberId}
          name={shipperOrderPaymentAdd.soNumberId}
          label={shipperOrderPaymentAdd.soNumberLabel}
          value={shipperOrderPaymentAdd.soNumberValue}
          testID={`${shipperOrderPaymentAdd.testID}:SONumberInput`}
        />

        <TextField
          readOnly
          disabled
          containerStyle={tw`flex-none`}
          inputStyle={tw`!text-black`}
          id={shipperOrderPaymentAdd.remainingCreditId}
          name={shipperOrderPaymentAdd.remainingCreditId}
          label={shipperOrderPaymentAdd.remainingCreditLabel}
          value={shipperOrderPaymentAdd.remainingCreditValue}
          testID={`${shipperOrderPaymentAdd.testID}:RemainingCreditInput`}
        />

        <DatePickerInput
          alwaysShowCalendarIcon
          id={shipperOrderPaymentAdd.dateId}
          name={shipperOrderPaymentAdd.dateId}
          label={shipperOrderPaymentAdd.dateLabel}
          dateValue={shipperOrderPaymentAdd.addPaymentForm.values.paymentDate}
          changeData={shipperOrderPaymentAdd.onChangePaymentDate}
          testID={`${shipperOrderPaymentAdd.testID || ''}:PaymentDateInput`}
        />

        <TextField
          containerStyle={tw`flex-none`}
          id={shipperOrderPaymentAdd.amountId}
          name={shipperOrderPaymentAdd.amountId}
          label={shipperOrderPaymentAdd.amountLabel}
          value={shipperOrderPaymentAdd.addPaymentForm.values.paymentAmount}
          error={shipperOrderPaymentAdd.addPaymentForm.errors.paymentAmount}
          onChange={shipperOrderPaymentAdd.onChangePaymentAmount}
          testID={`${shipperOrderPaymentAdd.testID}:PaymentAmountInput`}
        />

        <TextArea
          autoSize
          addHeight={10}
          id={shipperOrderPaymentAdd.noteId}
          name={shipperOrderPaymentAdd.noteId}
          label={shipperOrderPaymentAdd.noteLabel}
          value={shipperOrderPaymentAdd.addPaymentForm.values.note}
          error={shipperOrderPaymentAdd.addPaymentForm.errors.note}
          onChange={shipperOrderPaymentAdd.onChangePaymentNote}
        />
      </ListContainer>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={
            !shipperOrderPaymentAdd.addPaymentForm.isValid ||
            shipperOrderPaymentAdd.addPaymentForm.isSubmitting
          }
          onClick={shipperOrderPaymentAdd.onClickSubmitAddPayment}
          data-testid={`${shipperOrderPaymentAdd.testID || ''}:SubmitAction`}
        >
          {shipperOrderPaymentAdd.addPaymentForm.isSubmitting ? (
            <div tw="flex items-center gap-2">
              <LoadingIndicator size="small" color="white" />
              <Text.HeadingFive tw="text-white">Loading...</Text.HeadingFive>
            </div>
          ) : (
            shipperOrderPaymentAdd.submitButtonLabel
          )}
        </Button.Solid>
      </Footer>
    </Container>
  );
}
