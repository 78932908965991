import React from 'react';
import { Icon } from '../../component/atom';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type ActivityTemplateRouteParam = DefaultRouteParam;

export const activityTemplateRoute: RouteConfig = {
  name: 'Activity Template',
  path: '/activity-template',
  Component: React.lazy(() => import('./ActivityTemplate.view')),
  isPrivate: true,

  breadcrumb: 'Activity Template',

  options: {
    icon: <Icon.NavActivityTemplate />,
    label: 'Activity Template',
    testID: 'MenuActivityTemplate',
  },
};
