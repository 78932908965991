import React from 'react';
import tw, { theme } from 'twin.macro';
import {
  reportJobOrderDateRangeFilterInitialValues,
  reportJobOrderMaxDateRangeInDays,
} from '../../../constant/ReportJobOrder.constant';
import { UseReportJobOrderFilterObj } from '../../../hook/report/jobOrder/useReportJobOrderFilter.hook';
import { UseGetDriversAutocompleteObj } from '../../../hook/useGetDriversAutocomplete.hook';
import { UseGetVehiclesAutocompleteObj } from '../../../hook/useGetVehiclesAutocomplete.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region INTERFACES
type Props = {
  reportJobOrderFilter: UseReportJobOrderFilterObj;
  driversAutocomplete: UseGetDriversAutocompleteObj;
  vehiclesAutocomplete: UseGetVehiclesAutocompleteObj;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
const DatePickerContainer = tw.div`w-full flex items-center justify-between space-x-2`;
// #endregion

function ReportJOFilter({
  reportJobOrderFilter,
  driversAutocomplete,
  vehiclesAutocomplete,
}: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <Text.HeadingFour
          tw="flex-1 ml-2"
          data-testid={`${reportJobOrderFilter.testID}Filter:Title`}
        >
          {reportJobOrderFilter.titleLabel}
        </Text.HeadingFour>

        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          data-testid={`${reportJobOrderFilter.testID}Filter:ResetAction`}
          onClick={reportJobOrderFilter.onClickReset}
        >
          {reportJobOrderFilter.resetButtonLabel}
        </Button.Outlined>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading
            data-testid={`${
              reportJobOrderFilter.testID || ''
            }Filter:OrderByLabel`}
          >
            {reportJobOrderFilter.orderByLabel}
          </InputHeading>

          <ChipContainer>
            {reportJobOrderFilter.filterOrderByValues.map((el, i) => {
              const selected =
                reportJobOrderFilter.filterForm.values.orderBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      reportJobOrderFilter.onClickOrderByChip(el.value)
                    }
                    testID={`${
                      reportJobOrderFilter.testID || ''
                    }Filter:OrderByOption:${i}`}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportJobOrderFilter.testID}Filter:ShowDeliveryLabel`}
          >
            {reportJobOrderFilter.showDeliveryLabel}
          </InputHeading>

          <ChipContainer>
            {reportJobOrderFilter.filterShowDeliveryValues.map((el, i) => {
              const selected =
                reportJobOrderFilter.filterForm.values.showDelivery ===
                el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      reportJobOrderFilter.onClickShowDelivery(el.value)
                    }
                    testID={`${reportJobOrderFilter.testID}Filter:StatusOption:${i}`}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${reportJobOrderFilter.testID}Filter:StatusLabel`}
            >
              {reportJobOrderFilter.statusLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                reportJobOrderFilter.isQueryStatusAll
                  ? reportJobOrderFilter.onClickUnselectAllStatus
                  : reportJobOrderFilter.onClickSelectAllStatus
              }
            >
              {reportJobOrderFilter.isQueryStatusAll
                ? reportJobOrderFilter.unselectAllLabel
                : reportJobOrderFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {reportJobOrderFilter.filterStatusValues.map((el, i) => {
              const selected =
                reportJobOrderFilter.filterForm.values.status.includes(
                  el.value,
                );

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      reportJobOrderFilter.onClickStatusChip(el.value)
                    }
                    testID={`${reportJobOrderFilter.testID}Filter:StatusOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {reportJobOrderFilter.isQueryStatusEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {reportJobOrderFilter.errorStatusLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${reportJobOrderFilter.testID}Filter:FinishingMethodLabel`}
            >
              {reportJobOrderFilter.finishingMethodLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                reportJobOrderFilter.isQueryFinishingMethodAll
                  ? reportJobOrderFilter.onClickUnselectAllFinishingMethod
                  : reportJobOrderFilter.onClickSelectAllFinishingMethod
              }
            >
              {reportJobOrderFilter.isQueryFinishingMethodAll
                ? reportJobOrderFilter.unselectAllLabel
                : reportJobOrderFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {reportJobOrderFilter.filterFinishingMethodValues.map((el, i) => {
              const selected =
                reportJobOrderFilter.filterForm.values.finishingMethod.includes(
                  el.value,
                );

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      reportJobOrderFilter.onClickFinishingMethodChip(el.value)
                    }
                    testID={`${reportJobOrderFilter.testID}Filter:FinishingMethodOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {reportJobOrderFilter.isQueryFinishingMethodEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {reportJobOrderFilter.errorFinishingMethodLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportJobOrderFilter.testID}Filter:DriverLabel`}
          >
            {reportJobOrderFilter.driverLabel}
          </InputHeading>

          <AutoComplete
            tw="bg-orange-dark-lines"
            value={reportJobOrderFilter.filterForm.values.driverId}
            datas={driversAutocomplete.driverOptions}
            selectedOption={reportJobOrderFilter.queryDriverOption}
            placeholder={reportJobOrderFilter.driverPlaceholder}
            loading={driversAutocomplete.driverListFetchLoading}
            changeData={reportJobOrderFilter.onChangeDriver}
            changeInputText={driversAutocomplete.onChangeDriverAutotext}
            onFetchMore={driversAutocomplete.onFetchMoreDriver}
            onRemoveData={reportJobOrderFilter.onRemoveDriver}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportJobOrderFilter.testID}Filter:VehicleLabel`}
          >
            {reportJobOrderFilter.vehicleLabel}
          </InputHeading>

          <AutoComplete
            hasExtraLabel
            labelKey="extraLabel"
            tw="bg-orange-dark-lines"
            value={reportJobOrderFilter.filterForm.values.vehicleId}
            datas={vehiclesAutocomplete.vehicleOptions}
            selectedOption={reportJobOrderFilter.queryVehicleOption}
            placeholder={reportJobOrderFilter.vehiclePlaceholder}
            loading={vehiclesAutocomplete.vehicleListFetchLoading}
            changeData={reportJobOrderFilter.onChangeVehicle}
            changeInputText={vehiclesAutocomplete.onChangeVehicleAutotext}
            onFetchMore={vehiclesAutocomplete.onFetchMoreVehicle}
            onRemoveData={reportJobOrderFilter.onRemoveVehicle}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportJobOrderFilter.testID}Filter:DateRangeLabel`}
          >
            {reportJobOrderFilter.dateRangeLabel}
          </InputHeading>

          <DatePickerContainer>
            <DateRangePickerInput
              popoverPlacement="right"
              sdLabel={reportJobOrderFilter.sdLabel}
              edLabel={reportJobOrderFilter.edLabel}
              sdDateValue={reportJobOrderFilter.filterForm.values.startDate}
              sdOnChangeDateValue={reportJobOrderFilter.onChangeStartDate}
              edDateValue={reportJobOrderFilter.filterForm.values.endDate}
              edOnChangeDateValue={reportJobOrderFilter.onChangeEndDate}
              onClickApplyDateRange={reportJobOrderFilter.onClickApplyDateRange}
              outsideAlerterStyle={{ width: '100%' }}
              definedRangeOptions={
                reportJobOrderFilter.dateRangeDefinedRangeOptions
              }
              disabledDays={reportJobOrderFilter.dateRangeDisabledDays}
              defaultDateRange={reportJobOrderDateRangeFilterInitialValues}
              maxDateRangeInDays={reportJobOrderMaxDateRangeInDays}
              notes={reportJobOrderFilter.dateRangeNotes}
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={
            reportJobOrderFilter.isQueryStatusEmpty ||
            reportJobOrderFilter.isQueryFinishingMethodEmpty ||
            reportJobOrderFilter.filterLocalIsFetching
          }
          onClick={reportJobOrderFilter.onClickSubmit}
          data-testid={`${reportJobOrderFilter.testID}Filter:SubmitButton`}
        >
          {reportJobOrderFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default ReportJOFilter;
