import React from 'react';

export default function LastMile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={175}
      height={148}
      fill="none"
    >
      <rect width={173.333} height={18.122} x={1} fill="#F3532E" rx={6} />
      <rect width={174} height={18} x={0.667} y={23} fill="#E4E0CC" rx={6} />
      <rect
        width={171.333}
        height={16.122}
        x={2}
        y={46.306}
        stroke="#E4E0CC"
        strokeWidth={2}
        rx={5}
      />
      <rect
        width={173.333}
        height={18.122}
        x={1}
        y={67.959}
        fill="#F3532E"
        rx={6}
      />
      <rect
        width={171.333}
        height={55}
        x={2}
        y={92}
        stroke="#E4E0CC"
        strokeWidth={2}
        rx={5}
      />
    </svg>
  );
}
