import { Locale } from './locale.config';

const bulkActionLocaleConfig: Locale[] = [
  {
    en: 'Make sure the list below is the driver list that you want to deactivate from your organization. Inactive drivers cannot be used on Shipper Orders and Job Orders.',
    id: 'Pastikan daftar di bawah ini adalah daftar Sopir yang akan Anda nonaktifkan dari organisasi Anda. Sopir Nonaktif tidak dapat digunakan pada Shipper Order dan Perintah Kerja.',
  },
  {
    en: 'Make sure the list below is the expense list that you want to approve from your organization.',
    id: 'Pastikan daftar di bawah ini adalah daftar pengeluaran yang akan Anda setujui dari organisasi Anda.',
  },
  {
    en: 'to start the deactivation process.',
    id: 'untuk memulai proses penonaktifan.',
  },
  {
    en: 'to start the removal process.',
    id: 'untuk memulai proses penghapusan.',
  },
  {
    en: 'to start the deletion process.',
    id: 'untuk memulai proses penghapusan.',
  },
  {
    en: 'Confirm Registration',
    id: 'Konfirmasi Pendaftaran',
  },
  {
    en: 'to start the completion process.',
    id: 'untuk memulai proses penyelesaian.',
  },
  {
    en: 'to start the registration process.',
    id: 'untuk memulai proses pendaftaran.',
  },
  {
    en: 'to start the import process.',
    id: 'untuk memulai proses pendaftaran.',
  },
  {
    en: 'to start the approval process.',
    id: 'untuk memulai proses persetujuan.',
  },
  {
    en: 'Make sure the list below is the shipper list that you want to deactivate from your organization. Inactive shippers cannot be used on Shipper Orders and Job Orders.',
    id: 'Pastikan daftar di bawah ini adalah daftar Pengirim yang akan Anda nonaktifkan dari organisasi Anda. Pengirim Nonaktif tidak dapat digunakan pada Shipper Order dan Perintah Kerja.',
  },
  {
    en: 'Make sure the list below is the shipper list that you want to deactivate from your organization. Inactive shippers cannot be used on Shipper Orders and Job Orders.',
    id: 'Pastikan daftar di bawah ini adalah daftar Pengirim yang akan Anda nonaktifkan dari organisasi Anda. Pengirim Nonaktif tidak dapat digunakan pada Shipper Order dan Perintah Kerja.',
  },
  {
    en: 'Make sure the list below is the participant list that you want to remove as Driver Contest participant. These drivers will be no longer participate in Driver Contest.',
    id: 'Pastikan daftar di bawah ini adalah daftar peserta yang ingin Anda hapus sebagai peserta Driver Contest. Sopir berikut tidak ikut berpartisipasi dalam Driver Contest.',
  },
  {
    en: 'Make sure the list below is the location list that you want to delete from your organization. Deleted locations cannot be used on your next Shipper Orders.',
    id: 'Pastikan daftar di bawah ini adalah daftar lokasi yang ingin Anda hapus dari organisasi Anda. Lokasi yang dihapus tidak dapat digunakan pada Shipper Order berikutnya.',
  },
  {
    en: 'Make sure the list below is the Activity Template list that you want to delete from your organization. Deleted template cannot be used on your next Shipper Orders.',
    id: 'Pastikan daftar di bawah ini adalah daftar Templat Pengiriman yang ingin Anda hapus dari organisasi Anda. Templat yang dihapus tidak dapat digunakan pada Shipper Order berikutnya.',
  },
  {
    en: 'Make sure the list below is the activity list that you want to force complete. Assigned driver will no longer able to complete the activity on the Driver Apps.',
    id: 'Pastikan daftar di bawah ini adalah daftar aktivitas yang akan diselesaikan paksa. Sopir yang ditugaskan tidak lagi dapat menyelesaikan aktivitas menggunakan Aplikasi Sopir.',
  },
  {
    en: 'Make sure the list below is the Hauling Order list that you want to force complete. You can no longer create a Hauling Job Order for a completed Hauling Order.',
    id: 'Pastikan daftar di bawah ini adalah daftar Hauling Order yang akan diselesaikan paksa. Anda tidak dapat membuat Perintah Kerja untuk Hauling Order yang sudah selesai.',
  },
  {
    en: 'Make sure the list below is the driver list that you want to register to your organization. Incompatible data formats will not be registered into the system.',
    id: 'Pastikan daftar di bawah ini adalah daftar Sopir yang ingin Anda daftarkan ke organisasi Anda. Data dengan format yang tidak sesuai tidak dapat didaftarkan ke dalam sistem.',
  },
  {
    en: 'Make sure the list below is the driver list that you want to register to Driver Contest. After registration, drivers should be paired with a vehicle on the Scheduling menu.',
    id: 'Pastikan daftar di bawah ini adalah daftar Sopir yang akan didaftarkan ke Driver Contest. Setelah registrasi selesai, Sopir perlu dipasangkan dengan kendaraan pada menu Penjadwalan.',
  },
  {
    en: 'Make sure the list below is the schedule list that you want to remove. Driver and vehicle will be available at the removed schedule dates.',
    id: 'Pastikan daftar di bawah ini adalah daftar jadwal yang ingin Anda hapus. Sopir dan kendaraan akan kembali tersedia pada tanggal jadwal yang dihapus.',
  },
];

export default bulkActionLocaleConfig;
