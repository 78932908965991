import { Vehicle } from '../model/Vehicle.model';

// #region INTERFACES
export enum ScheduleFilterOrderBy {
  DRIVER_NAME_ASC = 'DRIVER_NAME_ASC',
  DRIVER_NAME_DESC = 'DRIVER_NAME_DESC',
  VEHICLE_NAME_ASC = 'VEHICLE_NAME_ASC',
  VEHICLE_NAME_DESC = 'VEHICLE_NAME_DESC',
}

export type ScheduleFilterOrderByValue = {
  label: string;
  value: ScheduleFilterOrderBy;
};

export type ScheduleFilterInitialValues = {
  orderBy: ScheduleFilterOrderBy;
  driverId?: string;
  driverName?: string;
  vehicleName?: string;
  vehicleExtraLabel?: string;
  vehicleId?: string;
  startDate?: Date;
  endDate?: Date;
};

export interface ScheduleInfoProps {
  from?: Date;
  to?: Date;
  vehicle?: Vehicle;
}
// #endregion

export const scheduleFilterInitialValues: ScheduleFilterInitialValues = {
  orderBy: ScheduleFilterOrderBy.DRIVER_NAME_ASC,
  driverId: undefined,
  driverName: undefined,
  vehicleId: undefined,
  vehicleName: undefined,
  vehicleExtraLabel: undefined,
  startDate: undefined,
  endDate: undefined,
};
