import addMonths from 'date-fns/addMonths';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import endOfMonth from 'date-fns/endOfMonth';
import getUnixTime from 'date-fns/getUnixTime';
import isEqual from 'date-fns/isEqual';
import startOfMonth from 'date-fns/startOfMonth';
import subMonths from 'date-fns/subMonths';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NativeCalendarRangeHandleChangeDateRangeParams } from '../../component/molecule/NativeCalendarRange/NativeCalendarRange.molecule';
import useDriverScheduleList from './useDriverScheduleList.hook';

export const driverScheduleCalendarDateRangeInitialState = {
  currentSelection: 'start',
  start: undefined,
  end: undefined,
};

type Props = {
  id?: string;
  initialMonth?: Date;
  onChangeDate?: () => void;
};

export default function useDriverScheduleCalendar({
  id,
  initialMonth = startOfMonth(new Date()),
  onChangeDate = () => {},
}: Props) {
  const initMonth = useRef(initialMonth);
  const [selectedMonth, setSelectedMonth] = useState(initialMonth);
  const [selectedHighlightId, setSelectedHighlightId] = useState<
    string | undefined
  >(undefined);
  const [selectedDateRange, setSelectedDateRange] =
    useState<NativeCalendarRangeHandleChangeDateRangeParams>(
      driverScheduleCalendarDateRangeInitialState,
    );
  const [isDateRangeOverlapHighlights, setIsDateRangeOverlapHighlights] =
    useState(false);

  const nextMonth = useMemo(
    () => addMonths(endOfMonth(selectedMonth), 1),
    [selectedMonth],
  );

  const { query } = useDriverScheduleList({
    driverId: id,
    startDate: getUnixTime(selectedMonth),
    endDate: getUnixTime(nextMonth),
  });

  useEffect(() => {
    if (id) return;
    setSelectedHighlightId(undefined);
  }, [id]);

  const selectedHighlight = useMemo(
    () =>
      selectedHighlightId !== undefined
        ? query.highlights.find((item) => item.id === selectedHighlightId)
        : undefined, // nothing is selected for the first time
    [query.highlights, selectedHighlightId],
  );

  useEffect(() => {
    if (!isEqual(initialMonth, initMonth.current)) {
      setSelectedMonth(initialMonth);
      initMonth.current = initialMonth;
    }
  }, [initialMonth]);

  const handleSelectScheduleItem = (_id: string) => {
    // unselect if user click the same item
    setSelectedHighlightId((prev) => (prev === _id ? undefined : _id));
  };

  const handleClickNextMonth = useCallback(() => {
    setSelectedMonth((v) => addMonths(v, 1));
  }, []);
  const handleClickPrevMonth = useCallback(() => {
    setSelectedMonth((v) => subMonths(v, 1));
  }, []);

  const handleChangeDateRange = useCallback(
    (newState: NativeCalendarRangeHandleChangeDateRangeParams) => {
      let isOverlap = isDateRangeOverlapHighlights;

      // check if date range is overlaps with highlights, only when currentSelection is "start"
      if (newState.currentSelection === 'start' && !!newState.end) {
        const hasOverlap = query.highlights.some((highlight) =>
          areIntervalsOverlapping(
            highlight,
            {
              start: selectedDateRange.start as Date,
              end: newState.end as Date,
            },
            { inclusive: true }, // means -> if `highlight.end === selectedDateRange.start`, then it should return true
          ),
        );

        isOverlap = hasOverlap;
      }
      onChangeDate();
      setSelectedDateRange((prev) => ({ ...prev, ...newState }));
      setIsDateRangeOverlapHighlights(
        newState.currentSelection === 'end' ? false : isOverlap,
      ); // clear overlap when on "end" currentSelection
    },
    [
      isDateRangeOverlapHighlights,
      onChangeDate,
      query.highlights,
      selectedDateRange.start,
    ],
  );

  const handleReset = useCallback(() => {
    setSelectedMonth(startOfMonth(initialMonth));
    setSelectedDateRange(driverScheduleCalendarDateRangeInitialState);
    setSelectedHighlightId(undefined);
    setIsDateRangeOverlapHighlights(false);
  }, [initialMonth]);

  return {
    selectedMonth,
    nextMonth,
    selectedHighlight,
    selectedHighlightId,
    isLoading: query.listIsFetching,
    queryFulfilledTimeStamp: id ? query.fulfilledTimeStamp : undefined,
    ongoingSchedules: id ? query.listOngoingData : [],
    ongoingHighlights: id ? query.ongoingHighlights : [],
    highlights: id ? query.highlights : [],
    schedules: id ? query.listData : [],
    disabledDays: id ? query.disabledDays : [],
    selectedDateRange,
    isDateRangeOverlapHighlights,
    handleReset,
    handleRefetch: () => query.refetch(),
    handleSelectScheduleItem,
    handleClickNextMonth,
    handleClickPrevMonth,
    handleChangeDateRange,
  };
}

export type UseDriverScheduleCalendar = ReturnType<
  typeof useDriverScheduleCalendar
>;
