import React from 'react';
import tw, { theme } from 'twin.macro';
import { UseDrivingContestFilterHookObj } from '../../../view/DrivingContest/hooks/useDrivingContestFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChipContainer = tw.div`mb-2 mr-2`;
const TitleLabel = tw(Text.HeadingFour)`flex-1 ml-2`;
const ResetButton = tw(Button.Outlined)`px-4 bg-white text-grey-two`;
const FilterChip = tw(Chip.Small)`text-grey-two`;
// #endregion

// #region INTERFACES
type Props = {
  drivingContestFilter: UseDrivingContestFilterHookObj;
};
// #endregion

function DrivingContestFilter({ drivingContestFilter }: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <TitleLabel>{drivingContestFilter.titleLabel}</TitleLabel>

        <ResetButton small onClick={drivingContestFilter.onClickReset}>
          {drivingContestFilter.resetButtonLabel}
        </ResetButton>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading>{drivingContestFilter.orderByLabel}</InputHeading>

          <ChipContainer>
            {drivingContestFilter.filterOrderByValues.map((el) => {
              const selected =
                drivingContestFilter.form.values.orderBy === el.value;

              return (
                <FilterChipContainer key={`Filter_Category_${el.value}`}>
                  <FilterChip
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      drivingContestFilter.onClickOrderByChip(el.value)
                    }
                  />
                </FilterChipContainer>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>
        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${
                drivingContestFilter.testID || ''
              }Filter:DrivingContestStatusLabel`}
            >
              {drivingContestFilter.drivingContestFilterLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                drivingContestFilter.isQueryStatusAll
                  ? drivingContestFilter.onClickUnselectAllDrivingContestStatus
                  : drivingContestFilter.onClickSelectAllDrivingContestStatus
              }
            >
              {drivingContestFilter.isQueryStatusAll
                ? drivingContestFilter.unselectAllLabel
                : drivingContestFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {drivingContestFilter.filterStatusValues.map((el, i) => {
              const selected = drivingContestFilter.form.values.status.includes(
                el.value,
              );

              return (
                <FilterChipContainer key={`Filter_Category_${el.value}`}>
                  <FilterChip
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() => {
                      drivingContestFilter.onClickDrivingContestStatusChipFilter(
                        el.value,
                      );
                    }}
                    testID={`${
                      drivingContestFilter.testID || ''
                    }Filter:PaymentStatusOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChipContainer>
              );
            })}
          </ChipContainer>

          {drivingContestFilter.isQueryStatusEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {drivingContestFilter.errorStatusLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={
            drivingContestFilter.isQueryStatusEmpty ||
            drivingContestFilter.filterLocalIsFetching
          }
          onClick={drivingContestFilter.onClickSubmit}
        >
          {drivingContestFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default DrivingContestFilter;
