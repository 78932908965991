import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BulkActionStatusEnum } from '../../../constant';
import { Schedule } from '../../../model/Schedule.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { errorCodeToLabel } from '../../../util/error.util';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';
import { RemoveScheduleEntity } from './useScheduleRemoveTable.hook';

export type UseScheduleRemoveLogic = ReturnType<typeof useScheduleRemoveLogic>;

export default function useScheduleRemoveLogic() {
  // #region VALUES
  const navigate = useNavigate();
  const location = useLocation();
  const { handleVerifyAuth } = useVerifyAuth();

  const [showStatus, setShowStatus] = useState(false);
  // get all schedules in schedules reducer
  const schedulesStore = useMemo(
    () => (location?.state || []) as Schedule[],
    [location?.state],
  );

  const [schedulesWithStatusDetail, setSchedulesWithStatusDetail] = useState<
    RemoveScheduleEntity[]
  >(
    schedulesStore.map((v) => ({
      ...v,
      _actionStatus: BulkActionStatusEnum.WAITING,
      _actionDetail: 'Waiting for deletion',
    })),
  );
  const [showDoneBtn, setShowDoneBtn] = useState<boolean>(false);
  const [mutationsIsLoading, setMutationsIsLoading] = useState<boolean>(false); // to disable submit button when mutations is still ongoing
  const [doRemoveSchedule] = api.useRemoveScheduleMutation();
  // #endregion

  // #region HANDLERS
  const onRemove = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    setShowStatus(true);
    const onRemovePromise = async () => {
      // track loading state
      setMutationsIsLoading(true);

      // synchronous call deactivate schedule mutation
      for (let i = 0; i < schedulesWithStatusDetail.length; i += 1) {
        const { id, driver } = schedulesWithStatusDetail[i];

        try {
          // NOTE: currently our backend can not receive excessive amount of requests in parallel, that is why we used for loop instead of `Promise.allSettled`
          await doRemoveSchedule({
            driverId: driver.id,
            assignmentId: id,
          }).unwrap();

          setSchedulesWithStatusDetail((_schedules) =>
            _schedules.map((_schedule) =>
              _schedule.id === id
                ? {
                    ..._schedule,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Removed from schedule',
                  }
                : _schedule,
            ),
          );
        } catch (err) {
          const errorQuery = (err as FetchBaseQueryError)
            ?.data as ApiErrorResponse;

          setSchedulesWithStatusDetail((_schedules) =>
            _schedules.map((_schedule) =>
              _schedule.id === id
                ? {
                    ..._schedule,
                    _actionStatus: BulkActionStatusEnum.FAILED,
                    _actionDetail: errorCodeToLabel(errorQuery?.error?.code),
                  }
                : _schedule,
            ),
          );
        }
      }

      // on done
      setShowDoneBtn(true);
      setMutationsIsLoading(false);
    };

    await onRemovePromise();
  }, [doRemoveSchedule, handleVerifyAuth, schedulesWithStatusDetail]);

  const onCancel = useCallback(() => {
    navigate('/schedule');
  }, [navigate]);

  const onDone = useCallback(() => {
    navigate('/schedule');
  }, [navigate]);
  // #endregion

  return {
    showStatus,
    schedulesWithStatusDetail,
    showDoneBtn,
    mutationsIsLoading,
    onRemove,
    onCancel,
    onDone,
  };
}
