import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { AutocompleteType } from '../../hook/useAutocomplete.hook';
import { ShipperOrderDetailRouteParam } from '../ShipperOrderDetail/ShipperOrderDetail.route';

export type SODDeliveryLocationAddRouteParam =
  | (ShipperOrderDetailRouteParam & {
      originPath?: string;
      activityIndex?: number;
      shipper?: AutocompleteType | undefined;
    })
  | undefined;

export const soDetailDeliveryLocationAddRoute: RouteConfig = {
  name: 'Add New Location',
  Component: React.lazy(() => import('./SODDeliveryLocationAdd.view')),
  path: '/shipper-order/:id/add-new-location',
  isPrivate: true,
  breadcrumb: 'Add New Location',
  props: { isUnclickable: true },
};
