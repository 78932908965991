import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BulkActionStatusEnum,
  DeleteDeliveryLocationEntity,
} from '../../../constant';
import { Location } from '../../../model/Location.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { errorCodeToLabel } from '../../../util/error.util';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';

// #region TYPES
export type UseDeliveryLocationDeleteLogic = ReturnType<
  typeof useDeliveryLocationDeleteLogic
>;
// #endregion

export default function useDeliveryLocationDeleteLogic() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { handleVerifyAuth } = useVerifyAuth();

  const [showStatus, setShowStatus] = useState(false);

  // get all locations from navigation state
  const locationsFromNavState = (state || []) as Location[];

  const [locationsWithStatusDetail, setLocationsWithStatusDetail] = useState<
    DeleteDeliveryLocationEntity[]
  >(
    locationsFromNavState.map((v) => ({
      ...v,
      _actionStatus: BulkActionStatusEnum.WAITING,
      _actionDetail: 'Waiting for deletion',
    })),
  );
  const [showDoneBtn, setShowDoneBtn] = useState<boolean>(false);
  const [mutationsIsLoading, setMutationsIsLoading] = useState<boolean>(false); // to disable submit button when mutations is still ongoing
  const [doDeleteLocation] = api.useDeleteLocationMutation();
  // #endregion

  // #region HANDLERS
  const onDelete = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    const onDeletePromise = async () => {
      // track loading state
      setMutationsIsLoading(true);

      // synchronous call delete location mutation
      for (let i = 0; i < locationsWithStatusDetail.length; i += 1) {
        const { id } = locationsWithStatusDetail[i];

        try {
          // NOTE: currently our backend can not receive excessive amount of requests in parallel, that is why we used for loop instead of `Promise.allSettled`
          await doDeleteLocation({ id }).unwrap();

          setLocationsWithStatusDetail((_locations) =>
            _locations.map((_location) =>
              _location.id === id
                ? {
                    ..._location,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Removed from delivery location',
                  }
                : _location,
            ),
          );
        } catch (err) {
          const errorQuery = (err as FetchBaseQueryError)
            ?.data as ApiErrorResponse;

          setLocationsWithStatusDetail((_locations) =>
            _locations.map((_location) =>
              _location.id === id
                ? {
                    ..._location,
                    _actionStatus: BulkActionStatusEnum.FAILED,
                    _actionDetail: errorCodeToLabel(errorQuery?.error?.code),
                  }
                : _location,
            ),
          );
        }
      }

      // on done
      setShowDoneBtn(true);
      setMutationsIsLoading(false);
    };
    setShowStatus(true);
    await onDeletePromise();
  }, [doDeleteLocation, handleVerifyAuth, locationsWithStatusDetail]);

  const onCancelOrDone = useCallback(() => {
    navigate('/delivery-location');
  }, [navigate]);

  return {
    locationsWithStatusDetail,
    showDoneBtn,
    showStatus,
    mutationsIsLoading,
    onDelete,
    onCancelOrDone,
  };
}
