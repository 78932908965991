import React from 'react';
import { Icon } from '../../component/atom';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type ProfileRouteParam = DefaultRouteParam;

export const profileRoute: RouteConfig = {
  name: 'Profile',
  path: '/profile',
  Component: React.lazy(() => import('./Profile.view')),
  isPrivate: true,
  options: {
    icon: <Icon.Customer />,
    label: 'Profile',
    testID: 'MenuProfile',
  },
};
