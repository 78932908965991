import { Locale } from './locale.config';

const helpCenterLocaleConfig: Locale[] = [
  {
    en: 'Register ',
    id: 'Pendaftaran',
  },
  {
    en: 'Removal',
    id: 'Penghapusan',
  },
  {
    en: 'Back to Dashboard',
    id: 'Kembali ke Dashboard',
  },
];

export default helpCenterLocaleConfig;
