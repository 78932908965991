import {
  HaulingOrderDeliveryTargetUnitEnum,
  HOTrackingDeliveriesItem,
  HOTrackingGoods,
} from './HaulingOrder.model';
import { BaseTimeline, TimelinePhoto } from './ShipperOrder.model';

export type HaulingOrderTracking = {
  deliveries: HOTrackingDeliveriesItem[];
  completedPercentage: number;
  trackingCode: string;
  totalGoodsDelivered: HOTrackingGoods[];
  totalGoodsTarget: HOTrackingGoods[];
  status: number;
  hoDate: number;
  completedAt?: number;
  goods: string;
  timeline: HOTimeline[];
  shipperName: string;
  pickupLocationName: string;
  deliveryLocationName: string[];
  organizationName: string;
  podNotes?: string;
};

export type HOIndexedTimeline = {
  index: number;
} & HOTimeline;

export type HOTimelineDataPhoto = {
  type: 'ho';
  createdAt: number;
  photos: HOTimelinePhoto[];
  caption?: string;
};

export type HOTimelinePhoto = TimelinePhoto & {
  type: 'ho';
};

export enum HOTimelineType {
  IN_PROCESS = 'IN_PROCESS',
  DELIVERING = 'DELIVERING',
  DELIVERY_ASSIGNMENT_COMPLETED = 'DELIVERY_ASSIGNMENT_COMPLETED',
  DELIVERY_COMPLETED = 'DELIVERY_COMPLETED',
  COMPLETED = 'COMPLETED',
  PROOF_OF_DELIVERY_SUBMITTED = 'PROOF_OF_DELIVERY_SUBMITTED',
}
export type HOTimeline =
  | HOTimelineInProcess
  | HOTimelineDelivering
  | HOTimelineDeliveryAssignmentCompleted
  | HOTimelineDeliveryCompleted
  | HOTimelineCompleted
  | HOTimelinePODSubmitted;

type HOTimelineInProcess = BaseTimeline<HOTimelineType.IN_PROCESS, null>;
type HOTimelineDelivering = BaseTimeline<HOTimelineType.DELIVERING, null>;
type HOTimelineDeliveryAssignmentCompleted = BaseTimeline<
  HOTimelineType.DELIVERY_ASSIGNMENT_COMPLETED,
  {
    hoDeliveryId: string;
    causedBy: string;
    driverId: string;
    goodsOut: number;
    goodsUnit: HaulingOrderDeliveryTargetUnitEnum;
    hoDeliveryAssignmentId: string;
    photos: HOTimelinePhoto[];
    podNotes?: string;
    locationName: string;
    driverName: string;
  }
>;
type HOTimelineDeliveryCompleted = BaseTimeline<
  HOTimelineType.DELIVERY_COMPLETED,
  {
    goodsOut: number;
    goodsUnit: HaulingOrderDeliveryTargetUnitEnum;
    hoDeliveryId: string;
    locationName: string;
    target: number;
  }
>;
type HOTimelineCompleted = BaseTimeline<HOTimelineType.COMPLETED, null>;
type HOTimelinePODSubmitted = BaseTimeline<
  HOTimelineType.PROOF_OF_DELIVERY_SUBMITTED,
  null
>;
