import React, { useState } from 'react';
import tw, { styled, theme } from 'twin.macro';
import {
  detailsInitialState,
  DetailsState,
} from '../../../constant/Onboarding.constant';
import { Button, Icon, Input, Text } from '../../atom';

// #region STYLED
const Details = styled.div<{ isOpen: boolean }>`
  ${tw`py-5 px-6 w-full cursor-pointer border-b border-beige-lines hover:bg-orange-hover`}

  & div ~ * {
    ${tw`animate-slide-in-top`};
  }

  ${({ isOpen }) => isOpen && tw`border-r-2 border-r-orange`}
`;
const OnboardingDetailSummary = styled.div`
  ${tw`flex items-center justify-between`}
`;
const OnboardingDetailHeader = styled.div<{ isOpen: boolean }>`
  ${tw`flex items-center space-x-3`}

  > svg {
    ${tw`transition duration-300`}
    ${({ isOpen }) => (isOpen ? tw`-rotate-90` : tw`rotate-90`)}
  }
`;
const OnboardingDetailContent = tw.div`w-full flex items-center justify-between`;
const InputCheckbox = tw(
  Input,
)`p-1.5 rounded-full bg-white border-2 focus:ring-status-success checked:bg-status-success checked:focus:bg-status-success`;
const OnboardingSubtitle = tw(
  Text.BodySixteen,
)`overflow-hidden text-left break-words whitespace-pre-wrap`;
// #endregion

// #region INTERFACES
type AccordionProps = {
  identity: keyof DetailsState;
  details: DetailsState;
  handleClickAccordion: (_details: DetailsState) => void;
  title: string;
  subtitle: string;
  checked: boolean;
  btnLabel: string;
  btnOnClick: () => void;
  btnDisabled?: boolean;
  btnDisabledLabel?: string;
  svg?: React.ReactNode;
};
type CollapsibleProps = Omit<AccordionProps, 'handleClickAccordion'>;
// #endregion

const componentName = (_identity: string) => `OnboardingDetails:${_identity}`;
const componentSection = {
  summarySection: (_identity: string) =>
    `${componentName(_identity)}:SummarySection`,
  contentSection: (_identity: string) =>
    `${componentName(_identity)}:ContentSection`,
};
const testIds = {
  root: (_identity: string) => `${componentName(_identity)}:Root`,
  summarySection: componentSection.summarySection,
  summarySectionCheckbox: (_identity: string) =>
    `${componentSection.summarySection(_identity)}:Checkbox`,
  contentSection: componentSection.contentSection,
  summarySectionButton: (_identity: string) =>
    `${componentSection.summarySection(_identity)}:Button`,
};

/**
 * This component can ONLY expand one item at a time
 */
export function Accordion({
  identity,
  details,
  handleClickAccordion,
  title,
  subtitle,
  checked,
  btnLabel,
  btnOnClick,
  btnDisabled,
  btnDisabledLabel,
  svg,
}: AccordionProps) {
  const detailIsOpen = details[identity];

  return (
    <button
      type="button"
      tw="py-5 px-6 w-full cursor-pointer border-b border-beige-lines hover:bg-orange-hover"
      css={[detailIsOpen && tw`border-r-2 border-r-orange`]}
      data-testid={testIds.root(identity)}
      onClick={() =>
        !detailIsOpen
          ? handleClickAccordion({
              ...detailsInitialState,
              ...{ [identity]: true },
            })
          : handleClickAccordion(detailsInitialState)
      }
    >
      <div
        tw="flex items-center justify-between"
        data-testid={testIds.summarySection(identity)}
      >
        <OnboardingDetailHeader isOpen={detailIsOpen}>
          <Icon.ChevronSharp
            height={20}
            width={20}
            strokeWidth={2}
            stroke={theme`colors.orange.DEFAULT`}
          />
          <Text.HeadingFour>{title}</Text.HeadingFour>
        </OnboardingDetailHeader>

        <InputCheckbox
          disabled
          type="checkbox"
          checked={checked}
          data-testid={testIds.summarySectionCheckbox(identity)}
        />
      </div>

      {detailIsOpen && (
        <OnboardingDetailContent
          tw="animate-slide-in-top"
          css={[!svg && tw`mt-3`]}
          data-testid={testIds.contentSection(identity)}
        >
          <div tw="w-3/4 flex flex-col space-y-3" css={[!svg && tw`w-full`]}>
            <OnboardingSubtitle>{subtitle}</OnboardingSubtitle>

            <div tw="flex items-center space-x-3">
              <Button.Solid
                data-testid={testIds.summarySectionButton(identity)}
                tw="width[fit-content]"
                disabled={!!btnDisabled}
                onClick={btnOnClick}
              >
                {btnLabel}
              </Button.Solid>

              {btnDisabled && (
                <Text.BodySixteen tw="text-grey-two">
                  {btnDisabledLabel}
                </Text.BodySixteen>
              )}
            </div>
          </div>

          {svg}
        </OnboardingDetailContent>
      )}
    </button>
  );
}

/**
 * This component can expand all item at a time
 */
export function Collapsible({
  identity,
  details,
  title,
  subtitle,
  checked,
  btnLabel,
  btnOnClick,
  btnDisabled,
  btnDisabledLabel,
  svg,
}: CollapsibleProps) {
  // state is necessary to trigger change of details styles
  const [isOpen, setIsOpen] = useState(details[identity]);

  return (
    <Details
      isOpen={isOpen}
      onClick={() => setIsOpen((v) => !v)}
      data-testid={testIds.root(identity)}
    >
      <OnboardingDetailSummary data-testid={testIds.summarySection(identity)}>
        <OnboardingDetailHeader isOpen={isOpen}>
          <Icon.ChevronSharp
            height={20}
            width={20}
            strokeWidth={2}
            stroke={theme`colors.orange.DEFAULT`}
          />
          <Text.HeadingFour>{title}</Text.HeadingFour>
        </OnboardingDetailHeader>

        <InputCheckbox
          disabled
          type="checkbox"
          checked={checked}
          data-testid={testIds.summarySectionCheckbox(identity)}
        />
      </OnboardingDetailSummary>

      {isOpen && (
        <OnboardingDetailContent
          css={[!svg && tw`mt-3`]}
          data-testid={testIds.contentSection(identity)}
        >
          <div tw="w-3/4 flex flex-col space-y-3" css={[!svg && tw`w-full`]}>
            <OnboardingSubtitle>{subtitle}</OnboardingSubtitle>

            <div tw="flex items-center space-x-3">
              <Button.Solid
                data-testid={testIds.summarySectionButton(identity)}
                tw="width[fit-content]"
                disabled={!!btnDisabled}
                onClick={btnOnClick}
              >
                {btnLabel}
              </Button.Solid>

              {btnDisabled && (
                <Text.BodySixteen tw="text-grey-two">
                  {btnDisabledLabel}
                </Text.BodySixteen>
              )}
            </div>
          </div>

          {svg}
        </OnboardingDetailContent>
      )}
    </Details>
  );
}
