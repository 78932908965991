import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../../component/molecule';
import {
  BulkActionStatusEnum,
  DeleteDeliveryLocationEntity,
} from '../../../constant';
import { LocationType } from '../../../constant/Location.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { Location } from '../../../model/Location.model';
import { mapBulkActionStatusToLabel } from '../../../util/helper.util';
import { UseDeliveryLocationDeleteLogic } from './useDeliveryLocationDeleteLogic.hook';

// #region INTERFACES
type Props = {
  deliveryLocationDeleteLogic: UseDeliveryLocationDeleteLogic;
};
export type UseDeliveryLocationDeleteTableObj = ReturnType<
  typeof useDeliveryLocationDeleteTable
>;
// #endregion

export default function useDeliveryLocationDeleteTable({
  deliveryLocationDeleteLogic,
}: Props) {
  const translator = useTranslator();

  const columns = useMemo<ColumnDef<DeleteDeliveryLocationEntity>[]>(
    () => [
      {
        size: 200,
        header: translator.translate('Location Name'),
        accessorKey: 'name',
        cell: ({ getValue }) => (
          <CustomTableRowWithPopover
            primaryLabel={getValue() as string}
            containerStyle={tw`max-width[200px]`}
            primaryLabelStyle={tw`font-bold`}
          />
        ),
      },
      {
        size: 150,
        header: translator.translate('Address'),
        accessorKey: 'address',
        cell: ({ getValue }) => (
          <CustomTableRowWithPopover
            primaryLabel={getValue() as string}
            containerStyle={tw`max-width[150px]`}
          />
        ),
      },
      {
        size: 150,
        header: translator.translate('Shipper Name'),
        accessorFn: (loc) => loc,
        cell: ({ getValue }) => {
          const location = getValue() as Location;

          return (
            <CustomTableRowWithPopover
              primaryLabel={
                location.type === LocationType.SHIPPER
                  ? location?.shipper?.name ?? ''
                  : translator.translate(capitalize(location.type))
              }
              containerStyle={tw`max-width[240px]`}
            />
          );
        },
      },
      ...(deliveryLocationDeleteLogic.showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({ getValue }: { getValue: () => string }) => (
                <CustomTableRowWithPopover
                  primaryLabel={translator.translate(getValue())}
                  containerStyle={tw`max-width[240px]`}
                  primaryLabelStyle={tw`animate-slide-in-left`}
                />
              ),
            },
          ]
        : []),
    ],
    [deliveryLocationDeleteLogic.showStatus, translator],
  );

  const table = useReactTable({
    data: deliveryLocationDeleteLogic.locationsWithStatusDetail,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
