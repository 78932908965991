import React from 'react';
import { RouteConfig } from '../../../config/router/router.config';

export const reportJobOrderRoute: RouteConfig = {
  name: 'Job Order',
  path: '/report/job-order',
  Component: React.lazy(() => import('./ReportJobOrder.view')),
  isPrivate: true,

  breadcrumb: 'Job Order',

  options: {
    icon: <div />,
    label: 'Job Order',
    testID: 'MenuReportJobOrder',
  },
};

export default {};
