import React from 'react';

export default function Search({
  fill = 'currentColor',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  style,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.5529 19.105C12.4506 19.1046 14.2936 18.4694 15.7884 17.3004L20.4883 22L22 20.4883L17.3002 15.7888C18.4698 14.2938 19.1054 12.4505 19.1059 10.5525C19.1059 5.83686 15.2688 2 10.5529 2C5.83707 2 2 5.83686 2 10.5525C2 15.2681 5.83707 19.105 10.5529 19.105ZM10.5529 4.13812C14.0907 4.13812 16.9677 7.01497 16.9677 10.5525C16.9677 14.09 14.0907 16.9669 10.5529 16.9669C7.01523 16.9669 4.13824 14.09 4.13824 10.5525C4.13824 7.01497 7.01523 4.13812 10.5529 4.13812Z" />
    </svg>
  );
}
