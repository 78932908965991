import { Locale } from './locale.config';

const goodsLocaleConfig: Locale[] = [
  {
    en: 'Type',
    id: 'Tipe',
  },
  {
    en: 'Description',
    id: 'Deskripsi',
  },
  {
    en: 'Qty',
    id: 'Jml',
  },
  {
    en: 'Unit',
    id: 'Satuan',
  },
  {
    en: 'Weight',
    id: 'Berat',
  },
  {
    en: 'Volume',
    id: 'Volume',
  },
  {
    en: 'Goods Type',
    id: 'Tipe Barang',
  },
  {
    en: 'Add Other Goods',
    id: 'Tambah Barang',
  },
  {
    en: 'Select',
    id: 'Pilih',
  },
  {
    en: 'Clothes',
    id: 'Pakaian',
  },
  {
    en: 'Document',
    id: 'Dokumen',
  },
  {
    en: 'Electronic',
    id: 'Elektronik',
  },
  {
    en: 'Food',
    id: 'Makanan',
  },
  {
    en: 'Fragile',
    id: 'Barang Pecah Belah',
  },
  {
    en: 'Others',
    id: 'Lain-lain',
  },
  {
    en: 'Total Weight',
    id: 'Berat Total',
  },
  {
    en: 'Total Volume',
    id: 'Volume Total',
  },
  {
    en: 'Delete',
    id: 'Hapus',
  },
  {
    en: 'Save',
    id: 'Simpan',
  },
  {
    en: 'Goods type is required.',
    id: 'Tipe barang wajib diisi.',
  },
  {
    en: 'Description must be at most 1000 characters.',
    id: 'Deskripsi maksimal 1000 karakter.',
  },
  {
    en: 'Maximum value is 999.999.999.',
    id: 'Nilai maksimal 999.999.999.',
  },
  {
    en: 'Minimum value is 1.',
    id: 'Nilai minimal 1.',
  },
  {
    en: 'Maximum 50 characters.',
    id: 'Maksimal 50 karakter.',
  },
];

export default goodsLocaleConfig;
