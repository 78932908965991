import React, { PropsWithChildren } from 'react';
import tw from 'twin.macro';
import { SnackbarAdapter } from '../../component/molecule';

const Container = tw.div`min-h-screen min-w-min bg-beige-bg flex items-stretch`;
const ContentWrapper = tw.div`flex-1 relative w-[calc(100vw - 320px)] overflow-x-auto whitespace-nowrap`;

const ContentArea = tw.div`w-full h-full mx-auto`;

type Props = PropsWithChildren<Record<string, unknown>>;

export default function WrapperPublicView({ children }: Props) {
  return (
    <Container id="rootContainer">
      <ContentWrapper id="contentWrapper">
        <ContentArea id="contentArea">{children}</ContentArea>
        <SnackbarAdapter />
      </ContentWrapper>
    </Container>
  );
}
