import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BulkActionEntity, BulkActionStatusEnum } from '../../constant';
import { JobOrder } from '../../model/JobOrder.model';
import { ApiErrorResponse } from '../../service/api.endpoint';
import api from '../../service/api.service';
import { DeleteJobOrderApiRequest } from '../../service/endpoint/jobOrder/jobOrder.endpoint';
import { mapCustomJODeleteError } from '../../util/jobOrder.util';
import { jobOrderRoute } from '../../view/JobOrder/JobOrder.route';
import useVerifyAuth from '../../view/Wrapper/hooks/useVerifyAuth.hook';

// #region INTERFACES
export type DeleteBulkJobOrderEntity = BulkActionEntity<JobOrder>;
export type UseJobOrderDeleteBulkLogic = ReturnType<
  typeof useJobOrderDeleteBulkLogic
>;
// #endregion

const mapStoreToState = (jobOrder: JobOrder[]) =>
  jobOrder.map((v) => ({
    ...v,
    _actionStatus: BulkActionStatusEnum.WAITING,
    _actionDetail: 'Waiting for deletion',
  }));

const mapStoreToDto = (
  entity: DeleteBulkJobOrderEntity,
): DeleteJobOrderApiRequest => ({
  id: entity.id,
});

export default function useJobOrderDeleteBulkLogic() {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleVerifyAuth } = useVerifyAuth();

  const [showStatus, setShowStatus] = useState(false);

  const jobsOrderStore = useMemo(
    () => (location?.state || []) as JobOrder[],
    [location?.state],
  );

  const [jobOrderWithStatusDetail, setJobOrderWithStatusDetail] = useState<
    DeleteBulkJobOrderEntity[]
  >(mapStoreToState(jobsOrderStore));
  const [showDoneBtn, setShowDoneBtn] = useState(false);
  const [mutationsIsLoading, setMutationsIsLoading] = useState(false);
  const [doDeleteJobOrder] = api.useDeleteJobOrderMutation();
  // #endregion

  // #region HANDLERS
  const handleDeleteBulk = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    setShowStatus(true);
    const promises = jobOrderWithStatusDetail.map((so) =>
      doDeleteJobOrder(mapStoreToDto(so)).unwrap(),
    );

    // track loading state
    setMutationsIsLoading(true);

    // NOTE: backend said this endpoint is safe to the parallel mutation
    await Promise.allSettled(promises)
      .then((results) => {
        for (const [idx, result] of results.entries()) {
          // on rejected
          if (result.status === 'rejected') {
            const errorQuery = (result.reason as FetchBaseQueryError)
              ?.data as ApiErrorResponse;
            const errorCode = errorQuery?.error?.code;

            setJobOrderWithStatusDetail((_jo) =>
              _jo.map((jo) =>
                jo.id === jobOrderWithStatusDetail[idx].id
                  ? {
                      ...jo,
                      _actionStatus: BulkActionStatusEnum.FAILED,
                      _actionDetail: mapCustomJODeleteError(errorCode, jo),
                    }
                  : jo,
              ),
            );
            continue;
          }

          // on fulfilled
          setJobOrderWithStatusDetail((_jo) =>
            _jo.map((jo) =>
              jo.id === jobOrderWithStatusDetail[idx].id
                ? {
                    ...jo,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Job Order deleted successfully.',
                  }
                : jo,
            ),
          );
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        // on done
        setShowDoneBtn(true);
        setMutationsIsLoading(false);
      });
  }, [doDeleteJobOrder, handleVerifyAuth, jobOrderWithStatusDetail]);

  const handleDone = useCallback(() => {
    navigate(jobOrderRoute.path);
  }, [navigate]);

  return {
    showDoneBtn,
    jobOrderWithStatusDetail,
    mutationsIsLoading,
    showStatus,
    handleDeleteBulk,
    handleDone,
  };
}
