import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import tw, { styled } from 'twin.macro';
import { Button, Paper, Portal } from '../../atom';
import { HeadingFour } from '../../atom/Text/Text.atom';
import ActivityItem, {
  ActivityItemProps,
} from '../ActivityItem/ActivityItem.organism';

type Props = React.ComponentProps<'div'> & {
  title: string;
  show: boolean;
  callAction?: {
    label: string;
    action: (data?: unknown) => void;
  };
  cancelAction?: {
    label: string;
    action: () => void;
  };
};

const ModalContainer = styled.div<{ show?: boolean }>(({ show }) => [
  show ? tw`fixed` : tw`hidden`,
  tw`z-20`,
]);

const Container = tw(Paper)` box-border width[720px]`;
const ActionContainer = tw.div`p-5 bg-beige-bg`;
const ActionButtons = tw.div`flex justify-end space-x-5`;

export default function Regular({
  title,
  show,
  callAction,
  cancelAction,
}: Props) {
  const activityListData: ActivityItemProps[] = [
    {
      title: 'Activity 1 · Standby ',
      activityDate: '01 Jan 2022, 08:00 WIB · Unilever Semarang',
      status: 'standby',
      data: [],
    },
    {
      title: 'Activity 2 · Pickup · 2 Goods',
      activityDate: '01 Jan 2022, 08:00 WIB · Unilever Semarang',
      status: 'pickup',
      data: [
        {
          type: 'Pakaian',
          description: 'Baju, Celana',
          qty: 999999,
          unit: 'Colly',
          weight: 999999,
          volume: 999999,
        },
        {
          type: 'Makanan',
          description: 'Snack Chitato',
          qty: 5,
          unit: 'Palet',
          weight: 3,
          volume: 4,
        },
      ],
    },
    {
      title: 'Activity 3 · Dropoff · 3 Goods',
      activityDate: '01 Jan 2022, 08:00 WIB · Unilever Semarang',
      status: 'dropoff',
      data: [
        {
          type: 'Pakaian',
          description: 'Baju, Celana',
          qty: 999999,
          unit: 'Colly',
          weight: 999999,
          volume: 999999,
        },
        {
          type: 'Makanan',
          description: 'Snack Chitato',
          qty: 5,
          unit: 'Palet',
          weight: 3,
          volume: 4,
        },
        {
          type: 'Minuman',
          description: 'Cairan Pembersih',
          qty: 2,
          unit: 'Box',
          weight: 4,
          volume: 5,
        },
      ],
    },
  ];
  return (
    <ModalContainer show={show}>
      <Portal>
        <Container>
          <ActionContainer>
            <HeadingFour tw="mb-4">{title}</HeadingFour>
          </ActionContainer>
          <Scrollbars style={{ height: '620px' }}>
            {activityListData.map((item) => (
              <ActivityItem
                status={item.status}
                key={item.title}
                title={item.title}
                activityDate={item.activityDate}
                data={item.data}
              />
            ))}
          </Scrollbars>
          <ActionContainer>
            <ActionButtons>
              {!!cancelAction && (
                <Button.Outlined type="button" onClick={cancelAction.action}>
                  {cancelAction.label}
                </Button.Outlined>
              )}
              {!!callAction && (
                <Button.Solid type="button" onClick={callAction.action}>
                  {callAction.label}
                </Button.Solid>
              )}
            </ActionButtons>
          </ActionContainer>
        </Container>
      </Portal>
    </ModalContainer>
  );
}
