import { Locale } from './locale.config';

const schedulingListLocaleConfig: Locale[] = [
  {
    en: 'Driver / Phone Number',
    id: 'Sopir / No HP',
  },
  {
    en: 'Vehicle',
    id: 'Kendaraan',
  },
  {
    en: 'Start Date',
    id: 'Tanggal Mulai',
  },
  {
    en: 'End Date',
    id: 'Tanggal Selesai',
  },
  {
    en: 'Driver or Vehicle',
    id: 'Sopir atau Kendaraan',
  },
  {
    en: 'Create Schedule',
    id: 'Buat Jadwal',
  },
  {
    en: "You don't have any selected Schedules that you want to remove",
    id: 'Anda tidak memiliki daftar Penjadwalan yang ingin dihapus',
  },
  {
    en: 'Try to select some Schedules from Schedule page',
    id: 'Cobalah untuk memilih beberapa Penjadwalan di halaman Penjadwalan',
  },
  {
    en: 'Go back to Schedule page',
    id: 'Kembali ke halaman Penjadwalan',
  },
  {
    en: 'Driver will not be displayed if they already have a schedule in the selected date range.',
    id: 'Sopir tidak akan ditampilkan jika sudah memiliki jadwal pada rentang tanggal yang dipilih.',
  },
  {
    en: 'Vehicle will not be displayed if they already have a schedule in the selected date range.',
    id: 'Kendaraan tidak akan ditampilkan jika sudah memiliki jadwal pada rentang tanggal yang dipilih.',
  },
  {
    en: 'You can add schedule in',
    id: 'Anda bisa menambah jadwal di',
  },
  {
    en: 'Try to add schedule by clicking the button below:',
    id: 'Cobalah menambah jadwal dengan cara menekan tombol dibawah ini:',
  },
  {
    en: 'Scheduling Page',
    id: 'Halaman Penjadwalan',
  },
  {
    en: 'Schedules',
    id: 'Jadwal',
  },
];

export default schedulingListLocaleConfig;
