import { setDefaultOptions } from 'date-fns';
import { enUS, id } from 'date-fns/locale';
import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';
import { RootState } from '../../../app/store/store.app';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import useTranslator from '../../../hook/useTranslator.hook';
import { settingAction } from '../../../store/setting.store';
import { FormItem } from '../../../types/input.type';

type SettingDisplayForm = {
  currentLanguage: string;
};

export function useSettingDisplay() {
  // #region GENERAL
  const { translate } = useTranslator();
  const dispatch = useDispatch();

  const { currentLanguage } = useSelector((state: RootState) => state.setting);
  // #endregion

  // #region SETTING DISPLAY FORM
  const handleSubmit = useCallback(
    (values: SettingDisplayForm) => {
      setDefaultOptions({
        locale: values.currentLanguage === SupportedLanguage.en ? enUS : id,
      });
      dispatch(
        settingAction.changeLanguage(
          values.currentLanguage as SupportedLanguage,
        ),
      );
    },
    [dispatch],
  );

  const displayForm = useFormik({
    initialValues: { currentLanguage },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const formData = useMemo(
    (): FormItem[] => [
      {
        id: 'type',
        label: translate('Language'),
        type: 'choice',
        containerStyle: tw`flex-1 justify-between`,
        wrapperStyle: tw`flex flex-1 pr-2`,
        labelStyle: tw`text-black`,
        choiceItems: [
          {
            label: translate('English'),
            selected:
              displayForm.values.currentLanguage === SupportedLanguage.en,
            onClick: () =>
              void displayForm.setFieldValue(
                'currentLanguage',
                SupportedLanguage.en,
              ),
          },
          {
            label: translate('Indonesian'),
            selected:
              displayForm.values.currentLanguage === SupportedLanguage.id,
            onClick: () =>
              void displayForm.setFieldValue(
                'currentLanguage',
                SupportedLanguage.id,
              ),
          },
        ],
        error: displayForm.errors?.currentLanguage,
      },
    ],
    [displayForm, translate],
  );

  const handleReset = useCallback(async () => {
    await displayForm.setFieldValue('currentLanguage', SupportedLanguage.en);
  }, [displayForm]);
  // #endregion

  return {
    formData,
    handleReset,
    handleSave: () => displayForm.submitForm(),
  };
  // #endregion
}

export type UseSettingDisplayHookObj = ReturnType<typeof useSettingDisplay>;
