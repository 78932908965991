import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterDataManagementLocationRouteParam = DefaultRouteParam;

export const helpCenterDataManagementLocationRoute: RouteConfig = {
  name: 'Help Center Data Management Location',
  Component: React.lazy(
    () => import('./HelpCenterDataManagementLocation.view'),
  ),
  path: '/help-center/data-management/location',
  isPrivate: false,
  breadcrumb: 'Help Center Data Management Location',
  options: {
    label: 'Lokasi Pengiriman',
    testID: 'MenuHelpCenterDataManagementLocation',
  },
};
