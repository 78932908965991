import addDays from 'date-fns/addDays';
import startOfToday from 'date-fns/startOfToday';
import { RDBVehicleFilterOrderByEnum } from '../model/ReportDrivingBehaviorByVehicle.model';

// #region INTERFACES
export type RDBVehicleFilterOrderByValue = {
  label: string;
  value: RDBVehicleFilterOrderByEnum;
};

export type RDBByVehicleFormInitialValues = {
  orderBy: RDBVehicleFilterOrderByEnum;
  startDate: Date;
  endDate: Date;
  vehicleId?: string;
};
// #endregion

export const rdbByVehicleDateRangeInitialValues: Pick<
  RDBByVehicleFormInitialValues,
  'startDate' | 'endDate'
> = {
  startDate: addDays(startOfToday(), -6),
  endDate: startOfToday(),
};

export const rdbByVehicleFormInitialValues: RDBByVehicleFormInitialValues = {
  ...rdbByVehicleDateRangeInitialValues,
  orderBy: RDBVehicleFilterOrderByEnum.NAME_ASC,
  vehicleId: undefined,
};
