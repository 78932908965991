import React from 'react';
import { Icon } from '../../component/atom';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type ShipperRouteParam = DefaultRouteParam;

export const shipperRoute: RouteConfig = {
  name: 'Shipper',
  Component: React.lazy(() => import('./Shipper.view')),
  path: '/shipper',
  isPrivate: true,

  options: {
    icon: <Icon.Shipper />,
    label: 'Shipper',
    testID: 'MenuShipper',
  },
};
