import React from 'react';
import { ExtraStyle } from '../../../Type.component';

type Props = React.SVGProps<SVGSVGElement> & {
  customStyle?: ExtraStyle;
};

export default function ChevronSharp({
  fill = 'none',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  strokeWidth = '2',
  stroke = 'currentColor',
  style,
  customStyle,
}: Props) {
  return (
    <svg
      css={customStyle}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      strokeWidth={strokeWidth}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.5 18.5L15.5 11.5L8.5 4.5" stroke={stroke} />
    </svg>
  );
}
