import React from 'react';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import JobOrderDetailDesktopView from './JobOrderDetail.desktop.view';
import JobOrderDetailMobileView from './JobOrderDetail.mobile.view';

export default function JobOrderDetailView() {
  const { isMobile } = useScreenDetection();

  if (isMobile) {
    return <JobOrderDetailMobileView />;
  }

  return <JobOrderDetailDesktopView />;
}
