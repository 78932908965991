import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { Text } from '../../../component/atom';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../../component/molecule';
import { BulkActionEntity, BulkActionStatusEnum } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { JobOrderExpense } from '../../../model/JobOrder.model';
import { numberFormatter } from '../../../util/formatter.util';
import { mapBulkActionStatusToLabel } from '../../../util/helper.util';
import { UseJobOrderDetailExpenseApproveLogic } from './useJobOrderDetailExpenseApproveLogic.hook';

// #region INTERFACES
type ApproveExpenseEntity = BulkActionEntity<JobOrderExpense>;
type Props = {
  logic: UseJobOrderDetailExpenseApproveLogic;
};
export type UseJobOrderDetailExpenseApproveTable = ReturnType<
  typeof useJobOrderDetailExpenseApproveTable
>;
// #endregion

export default function useJobOrderDetailExpenseApproveTable({ logic }: Props) {
  const translator = useTranslator();

  const columns = useMemo<ColumnDef<ApproveExpenseEntity>[]>(
    () => [
      {
        id: 'number',
        size: 150,
        header: translator.translate('Expense Number'),
        accessorKey: 'number',
        cell: ({ getValue }) => (
          <CustomTableRowWithPopover
            primaryLabel={getValue() as string}
            containerStyle={tw`max-w-[150px]`}
            primaryLabelStyle={tw`font-bold`}
          />
        ),
      },
      {
        id: 'description',
        size: logic.showStatus ? 150 : 220,
        header: translator.translate('Descriptions'),
        accessorKey: 'description',
        cell: ({ getValue }) => (
          <CustomTableRowWithPopover
            primaryLabel={getValue() ?? '-'}
            containerStyle={[
              logic.showStatus ? tw`max-w-[150px]` : tw`max-w-[220px]`,
            ]}
          />
        ),
      },
      {
        id: 'amount',
        size: 150,
        header: translator.translate('Amount'),
        accessorKey: 'amount',
        cell: ({ getValue }) => (
          <Text.Paragraph>Rp{numberFormatter(getValue())}</Text.Paragraph>
        ),
      },
      ...(logic.showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({ getValue }: { getValue: () => string }) => (
                <CustomTableRowWithPopover
                  primaryLabel={translator.translate(getValue())}
                  tooltipContentStyle={tw`max-w-[250px]`}
                  primaryLabelStyle={tw`animate-slide-in-left`}
                />
              ),
            },
          ]
        : []),
    ],
    [logic.showStatus, translator],
  );

  const table = useReactTable({
    columns,
    data: logic.expensesWithStatusDetail,
    getCoreRowModel: getCoreRowModel(),
  });

  return table;
}
