import { skipToken } from '@reduxjs/toolkit/dist/query';
import api from '../../service/api.service';

interface Props {
  id?: string;
}

function useSpendingInfo({ id }: Props) {
  const {
    spendingData,
    timelines,
    spendingInfoError,
    isFetchingSpending,
    isLoadingSpending,
  } = api.useGetJobOrdersExpensesInfoQuery(id ? { id } : skipToken, {
    selectFromResult: ({ data, error, isFetching, isLoading }) => ({
      spendingData: data?.jobOrderExpense,
      spendingInfoError: error,
      timelines: data?.jobOrderExpense?.events,
      isFetchingSpending: isFetching,
      isLoadingSpending: isLoading,
    }),
    refetchOnMountOrArgChange: true,
  });

  return {
    spendingData,
    timelines,
    spendingInfoError,
    isFetchingSpending,
    isLoadingSpending,
  };
}
export type UseSpendingInfoHookObj = ReturnType<typeof useSpendingInfo>;
export default useSpendingInfo;
