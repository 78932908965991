import React, { useMemo } from 'react';
import tw from 'twin.macro';
import {
  reportJobOrderDateRangeFilterInitialValues,
  reportJobOrderMaxDateRangeInDays,
} from '../../../constant/ReportJobOrder.constant';
import { UseGetDriversAutocompleteObj } from '../../../hook/useGetDriversAutocomplete.hook';
import { UseGetVehiclesAutocompleteObj } from '../../../hook/useGetVehiclesAutocomplete.hook';
import { UseReportFinanceFilter } from '../../../view/Report/Finance/hooks/useReportFinanceFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region INTERFACES
type Props = {
  driversAutocomplete: UseGetDriversAutocompleteObj;
  vehiclesAutocomplete: UseGetVehiclesAutocompleteObj;
  reportFinanceFilter: UseReportFinanceFilter;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Title = tw(Text.HeadingFour)`flex-1 ml-2`;
const ResetButton = tw(Button.Outlined)`px-4 bg-white text-grey-two`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
const FilterOrderByChip = tw(Chip.Small)`text-grey-two`;
const DatePickerContainer = tw.div`w-full flex items-center justify-between space-x-2`;
// #endregion

export default function ReportFinanceFilter({
  driversAutocomplete,
  vehiclesAutocomplete,
  reportFinanceFilter,
}: Props) {
  const orderByChips = useMemo(
    () =>
      reportFinanceFilter.filterOrderByValues.map((el, i) => {
        const selected =
          reportFinanceFilter.filterForm.values.orderBy === el.value;

        return (
          <FilterChip key={`Filter_Category_${el.value}`}>
            <FilterOrderByChip
              css={[selected && tw`border-white text-orange`]}
              label={el.label}
              selected={selected}
              onClick={() => reportFinanceFilter.onClickOrderByChip(el.value)}
              testID={`${
                reportFinanceFilter.testID || ''
              }Filter:OrderByOption:${i}`}
            />
          </FilterChip>
        );
      }),
    [reportFinanceFilter],
  );

  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <Title data-testid={`${reportFinanceFilter.testID}Filter:Title`}>
          {reportFinanceFilter.labels.title}
        </Title>

        <ResetButton
          small
          data-testid={`${reportFinanceFilter.testID}Filter:ResetAction`}
          onClick={reportFinanceFilter.onClickReset}
        >
          {reportFinanceFilter.labels.resetButton}
        </ResetButton>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <Text.HeadingFive
            data-testid={`${
              reportFinanceFilter.testID || ''
            }Filter:OrderByLabel`}
          >
            {reportFinanceFilter.labels.orderBy}
          </Text.HeadingFive>

          <ChipContainer>{orderByChips}</ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <Text.HeadingFive
            data-testid={`${reportFinanceFilter.testID}Filter:DriverLabel`}
          >
            {reportFinanceFilter.labels.driver}
          </Text.HeadingFive>

          <AutoComplete
            value={reportFinanceFilter.filterForm.values.driverId}
            datas={driversAutocomplete.driverOptions}
            selectedOption={reportFinanceFilter.queryDriverOption}
            placeholder={reportFinanceFilter.labels.driverPlaceholder}
            loading={driversAutocomplete.driverListFetchLoading}
            changeData={reportFinanceFilter.onChangeDriver}
            changeInputText={driversAutocomplete.onChangeDriverAutotext}
            onFetchMore={driversAutocomplete.onFetchMoreDriver}
            onRemoveData={reportFinanceFilter.onRemoveDriver}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <Text.HeadingFive
            data-testid={`${reportFinanceFilter.testID}Filter:VehicleLabel`}
          >
            {reportFinanceFilter.labels.vehicle}
          </Text.HeadingFive>

          <AutoComplete
            hasExtraLabel
            labelKey="extraLabel"
            value={reportFinanceFilter.filterForm.values.vehicleId}
            datas={vehiclesAutocomplete.vehicleOptions}
            selectedOption={reportFinanceFilter.queryVehicleOption}
            placeholder={reportFinanceFilter.labels.vehiclePlaceholder}
            loading={vehiclesAutocomplete.vehicleListFetchLoading}
            changeData={reportFinanceFilter.onChangeVehicle}
            changeInputText={vehiclesAutocomplete.onChangeVehicleAutotext}
            onFetchMore={vehiclesAutocomplete.onFetchMoreVehicle}
            onRemoveData={reportFinanceFilter.onRemoveVehicle}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <Text.HeadingFive
            data-testid={`${reportFinanceFilter.testID}Filter:DateRangeLabel`}
          >
            {reportFinanceFilter.labels.dateRange}
          </Text.HeadingFive>

          <DatePickerContainer>
            <DateRangePickerInput
              popoverPlacement="right"
              sdLabel={reportFinanceFilter.labels.sd}
              edLabel={reportFinanceFilter.labels.ed}
              sdDateValue={reportFinanceFilter.filterForm.values.startDate}
              sdOnChangeDateValue={reportFinanceFilter.onChangeStartDate}
              edDateValue={reportFinanceFilter.filterForm.values.endDate}
              edOnChangeDateValue={reportFinanceFilter.onChangeEndDate}
              onClickApplyDateRange={reportFinanceFilter.onClickApplyDateRange}
              outsideAlerterStyle={{ width: '100%' }}
              definedRangeOptions={
                reportFinanceFilter.dateRangeDefinedRangeOptions
              }
              disabledDays={reportFinanceFilter.dateRangeDisabledDays}
              defaultDateRange={reportJobOrderDateRangeFilterInitialValues}
              maxDateRangeInDays={reportJobOrderMaxDateRangeInDays}
              notes={reportFinanceFilter.labels.dateRangeNotes}
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          onClick={reportFinanceFilter.onClickSubmit}
          data-testid={`${reportFinanceFilter.testID}Filter:SubmitButton`}
          disabled={reportFinanceFilter.countQuery.isFetching}
        >
          {reportFinanceFilter.labels.submitButton}
        </Button.Solid>
      </Footer>
    </Container>
  );
}
