import React from 'react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { ProfileCardLegalItemProps } from '../../../hook/useProfile/userProfile.hook';
import { BodyTwelve } from '../../atom/Text/Text.atom';

const ProfileDisclaimer = styled.div(({ isPage }: { isPage?: boolean }) => [
  tw`py-3.5 px-4 flex flex-row flex-1 justify-between`,
  isPage && tw`w-full gap-1`,
]);
const ProfileDisclaimerButton = tw.div`cursor-pointer border-b border-b-white hover:border-b-orange`;
const ProfileDisclaimerItem = styled.div(({ isPage }: { isPage?: boolean }) => [
  tw`flex flex-row items-center gap-0.5`,
  isPage && tw`gap-1`,
]);
const LinkText = tw(BodyTwelve)`text-grey-three font-semibold`;

type Props = {
  isPage?: boolean;
  profileCardLegalItems: ProfileCardLegalItemProps[];
  handleProfileClick?: () => void;
};

export default function ProfileCardLegalSection({
  isPage,
  profileCardLegalItems,
  handleProfileClick = () => {},
}: Props) {
  return (
    <ProfileDisclaimer isPage={isPage}>
      {profileCardLegalItems.map((item, idx, arr) => (
        <ProfileDisclaimerItem key={item.title}>
          <ProfileDisclaimerButton
            onClick={() => {
              handleProfileClick();
            }}
          >
            <Link to={item.path}>
              <LinkText>{item.title}</LinkText>
            </Link>
          </ProfileDisclaimerButton>
          {idx !== arr.length - 1 && <LinkText> • </LinkText>}
        </ProfileDisclaimerItem>
      ))}
    </ProfileDisclaimer>
  );
}
