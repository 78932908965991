import React from 'react';

export default function DrivingSchedule() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5 0a1 1 0 00-1 1h-.79c-.885 0-1.735.217-2.357.828C.226 2.442 0 3.285 0 4.167V12.833c0 .882.226 1.726.853 2.34.622.61 1.472.827 2.358.827h4.011v-2H3.211c-.588 0-.844-.144-.958-.256-.11-.108-.253-.348-.253-.91V7h14V4.167c0-.882-.226-1.725-.853-2.34C14.525 1.218 13.675 1 12.79 1H12a1 1 0 10-2 0H6a1 1 0 00-1-1zM3.21 3h9.58c.587 0 .843.144.957.256.11.108.253.348.253.91V5H2V4.167c0-.563.142-.803.253-.911.114-.112.37-.256.958-.256zM14.5 20a5.5 5.5 0 100-11 5.5 5.5 0 000 11zm-1-4.84v2.695a3.502 3.502 0 01-2.477-3.757l2.477 1.061zm1-4.16c-1.08 0-2.047.49-2.69 1.26l2.69 1.152 2.69-1.152A3.493 3.493 0 0014.5 11zm1 4.16l2.477-1.062a3.502 3.502 0 01-2.477 3.757V15.16z"
        clipRule="evenodd"
      />
    </svg>
  );
}
