import React from 'react';
import tw, { styled } from 'twin.macro';
import { JOAssignmentBy } from '../../../constant/JOAssignment.constant';
import { Schedule } from '../../../model/Schedule.model';
import { FormItem } from '../../../types/input.type';
import { LoadingIndicator } from '../../atom';
import { CardContent, NativeCalendar } from '../../molecule';
import { NativeCalendarHighlight } from '../../molecule/NativeCalendar/NativeCalendar.molecule';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';
import SchedulesSection from '../SchedulesSection/SchedulesSection.organism';

type Props = {
  title: string;
  type: JOAssignmentBy;
  isLoading?: boolean;
  emptyTitle?: string;
  emptySubtitle?: string;
  formData: FormItem;
  nextMonth: Date;
  selectedMonth?: Date;
  selectedHighlight?: NativeCalendarHighlight;
  selectedHighlightId?: string;
  highlights?: NativeCalendarHighlight[];
  schedules: Schedule[];
  handleSelectScheduleItem: (item: Schedule) => void;
  handleClickDay: (_day: Date) => void;
};

const Container = tw.div`flex flex-col gap-6`;
const CalendarLoadingOverlay = tw.div`bg-white w-[calc(100% - 2px)] h-[calc(100% - 47px)] absolute z-10 top-[46px] opacity-[85%] left-[1px] rounded`;
const LoadingContainer = tw.div`flex h-full items-center justify-center`;
const CalendarSection = styled.section(
  ({ isDateRangeOverlap }: { isDateRangeOverlap?: boolean }) => [
    tw`w-full flex mb-6 relative`,
    isDateRangeOverlap && tw`mb-1`,
  ],
);
const FirstNativeCalendarStyle = tw`border-r-0 rounded-tr-none rounded-br-none w-full`;
const SecondNativeCalendarStyle = tw`rounded-tl-none rounded-bl-none w-full`;
const CardContentStyle = tw`p-0 flex flex-col -mb-6`;

export default function JOBaseAssignmentForm({
  type = JOAssignmentBy.VEHICLE,
  title,
  isLoading,
  emptyTitle,
  emptySubtitle,
  formData,
  nextMonth,
  selectedMonth,
  selectedHighlight,
  selectedHighlightId,
  highlights,
  schedules,
  handleSelectScheduleItem,
  handleClickDay,
}: Props) {
  return (
    <Container>
      {/* type already described inside component */}
      <ComposedFormField {...formData} />
      <CardContent containerStyle={CardContentStyle}>
        <CalendarSection>
          {isLoading && (
            <CalendarLoadingOverlay>
              <LoadingContainer>
                <LoadingIndicator size="small" />
              </LoadingContainer>
            </CalendarLoadingOverlay>
          )}
          <NativeCalendar
            disableAction
            month={selectedMonth}
            selectedHighlight={selectedHighlight}
            highlights={highlights}
            rootStyle={FirstNativeCalendarStyle}
            handleClickDay={handleClickDay}
          />
          <NativeCalendar
            disableAction
            selectedHighlight={selectedHighlight}
            highlights={highlights}
            month={nextMonth}
            rootStyle={SecondNativeCalendarStyle}
            handleClickDay={handleClickDay}
          />
        </CalendarSection>
      </CardContent>
      <SchedulesSection
        isLoading={isLoading}
        type={type}
        selectedHighlightId={selectedHighlightId}
        title={title}
        emptyTitle={emptyTitle}
        emptySubtitle={emptySubtitle}
        handleClickScheduleItem={handleSelectScheduleItem}
        schedules={schedules}
      />
    </Container>
  );
}
