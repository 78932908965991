import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store/store.app';
import api from '../../service/api.service';
import { settingAction } from '../../store/setting.store';
import { snackbarAction } from '../../store/snackbar.store';
import useTranslator from '../useTranslator.hook';
import useOrganizationOrientation from './useOrganizationOrientation.hook';

export type UseOrganizationInfoObj = ReturnType<typeof useOrganizationInfo>;

export default function useOrganizationInfo() {
  const dispatch = useDispatch();
  const { translate } = useTranslator();
  const token = useSelector((state: RootState) => state.session.token);
  const { isOrientationCompleted } = useOrganizationOrientation();
  const [fetchOrganizationMe, queryOrganizationInfo] =
    api.useLazyGetOrganizationMeQuery();

  const fetchHandler = useCallback(async () => {
    if (isOrientationCompleted) return;
    try {
      await fetchOrganizationMe({ token });
    } catch (err) {
      dispatch(
        snackbarAction.show({
          message: translate('Failed to Fetch Organization Info'),
        }),
      );
    }
  }, [dispatch, fetchOrganizationMe, isOrientationCompleted, token, translate]);

  useEffect(() => {
    if (isOrientationCompleted) return;
    void fetchHandler();
  }, [fetchHandler, isOrientationCompleted]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    if (queryOrganizationInfo?.data?.organization?.orientation) {
      dispatch(
        settingAction.changeOrientationSetting(
          queryOrganizationInfo.data.organization.orientation,
        ),
      );
    }
  }, [dispatch, isOrientationCompleted, queryOrganizationInfo]);

  const selectOrganizationInfo = useMemo(
    () => api.endpoints.getOrganizationMe.select({ token }),
    [token],
  );
  const organizationMe = useSelector(selectOrganizationInfo);

  const organizationInfo = useMemo(() => {
    if (isOrientationCompleted) return organizationMe;
    return queryOrganizationInfo;
  }, [isOrientationCompleted, organizationMe, queryOrganizationInfo]);
  return organizationInfo;
}
