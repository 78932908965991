import React from 'react';
import tw from 'twin.macro';
import { ListItem, Text } from '..';
import { SelectOption } from '../../Type.component';

export type OptGroup = {
  groupName: string;
  items: {
    id: string;
    name: string;
    value: string;
  }[];
};
export type Props = React.ComponentProps<'div'> &
  React.ComponentProps<'ul'> & {
    options: OptGroup[];
    withIcon?: boolean;
    cascading?: boolean;
    onClickItem?: (option: SelectOption) => void;
  };

const Container = tw.ul`bg-white rounded-sm shadow flex p-0 m-0`;
const MainColumn = tw.div`py-5 flex-1 px-5`;

export function Regular({ options, onClickItem }: Props) {
  return (
    <Container>
      <MainColumn>
        {options.map((option) => (
          <>
            <Text.Label key={option.groupName}>{option.groupName}</Text.Label>
            {option.items.map((optionItem) => (
              <ListItem.Regular
                key={optionItem.name}
                label={optionItem.name}
                onClick={() =>
                  onClickItem?.({
                    label: optionItem.name,
                    value: `${option.groupName} > ${optionItem.value}`,
                  })
                }
                {...option}
              />
            ))}
          </>
        ))}
      </MainColumn>
    </Container>
  );
}
