import { flexRender, Row, RowData, Table } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, {
  createRef,
  Fragment,
  LegacyRef,
  MutableRefObject,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactPaginate from 'react-paginate';
import tw, { css, styled, TwStyle } from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { isOldSafari } from '../../../util/helper.util';
import { Icon, List, LoadingIndicator, Popover, Text } from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region INTERFACES
export type ColumnMeta =
  | {
      rowSpan?: number;
      headerStyle?: TwStyle;
      cellStyle?: TwStyle;
      specificCellStyle?: (id: string) => TwStyle | false;
    }
  | undefined;

export type FooterItem = {
  id: string;
  node: React.ReactNode;
  cellStyle?: React.CSSProperties;
};

export type FooterOptions = {
  ref: LegacyRef<HTMLDivElement>;
  data: FooterItem[];
  containerStyle?: React.CSSProperties;
  onScrollTableContainer?: (ev: React.UIEvent<HTMLDivElement, UIEvent>) => void;
};

export type InfiniteScrollingOptions = React.CSSProperties & {
  onScrollTableContainer: (ev: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  useVirtualizer: boolean;
};

export type RenderSubComponentProps<RD extends RowData> = {
  row: Row<RD>;
};

type Props<RD extends RowData> = {
  table: Table<RD>;
  forceLang?: SupportedLanguage;
  onPageLimitClick?: (limit: number) => void;
  onPageClick?: (page: number) => void;
  renderSubComponent?: (props: RenderSubComponentProps<RD>) => React.ReactNode;
  noDataPage?: React.ReactNode;
  stickyHeader?: boolean;
  stickyHeaderPositionTop?: number;
  stickyColumn?: boolean;
  stickyColumnNumber?: number;
  fullWidth?: boolean;
  displayHeaderBorder?: boolean;
  limitOptions?: string[];
  trProps?:
    | ((row: Row<RD>) => React.ComponentPropsWithoutRef<'tr'>)
    | React.ComponentPropsWithoutRef<'tr'>;
  expandedPage?: React.ReactNode;
  rootStyle?: ExtraStyle;
  tableContainerStyle?: ExtraStyle;
  paginationContainerStyle?: ExtraStyle;
  expandedRowStyle?: ExtraStyle;
  expandedRowWidthFitContent?: boolean;
  infiniteScrollingOptions?: InfiniteScrollingOptions;
  footerOptions?: FooterOptions;
  testID?: string;
  bottomActionElement?: React.ReactNode;
};
// #endregion

// #region STYLED
const Container = tw.div`rounded-lg shadow-card bg-white`;
const TableContainer = styled.div<{ stickyHeader?: boolean }>`
  ${tw`w-full rounded-lg overflow-x-auto`}
  ${({ stickyHeader }) => (stickyHeader ? tw`height[600px]` : '')}
  ::-webkit-scrollbar {
    ${tw`width[7px] height[7px] border-radius[10px]`}
  }
  ::-webkit-scrollbar-track {
    ${tw`border-radius[10px]`}
  }
  ::-webkit-scrollbar-thumb {
    ${tw`border-radius[10px] bg-grey-four`}
  }
`;
const HTMLTable = styled.table(
  ({
    stickyHeader,
    stickyHeaderPositionTop,
    stickyColumn,
    stickyColumnNumber = 2,
    fullWidth,
  }: {
    stickyHeader?: boolean;
    stickyHeaderPositionTop?: number;
    stickyColumn?: boolean;
    stickyColumnNumber?: number;
    fullWidth?: boolean;
  }) => [
    tw`font-sans font-size[14px] border-separate table-fixed whitespace-nowrap border-spacing[0]`,
    fullWidth && tw`w-full`,
    stickyHeader &&
      `& thead tr th {
        position: sticky !important;
        top: 0;
        z-index: 1;
      }
      & thead tr:not(:first-of-type) ${isOldSafari() ? 'th' : ''}  {
        position: sticky !important;
        top: 49px;
        z-index: 2;
      }`,
    stickyColumn &&
      `& thead th {
        position: sticky;
        top: ${stickyHeaderPositionTop ? `${stickyHeaderPositionTop}px` : '0'};
        z-index: 2;
      }
      & tbody th {
        position: sticky;
        top: 0;
        z-index: 2;
      }
      & thead th:first-of-type {
        position: sticky;
        left: 0;
        z-index: 3;
      }
      & thead th:nth-of-type(${String(stickyColumnNumber)}) {
        position: sticky;
        z-index: 3;
        left: ${stickyColumnNumber && stickyColumnNumber > 1 ? '50px' : '0px'}
      }
      & thead th:nth-of-type(${String(stickyColumnNumber)})::after {
        content: ' ';
        width: 11px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -11px;
        background: linear-gradient(to right, rgba(49, 49, 49, 0.1), transparent);
      }
      & tr td:first-of-type {
        position: sticky;
        left: 0;
        z-index: 1;
      }
      & tr td:nth-of-type(${String(stickyColumnNumber)}) {
        position: sticky;
        z-index: 1;
        left: ${stickyColumnNumber && stickyColumnNumber > 1 ? '50px' : '0px'}
      }
      & tr td:nth-of-type(${String(stickyColumnNumber)})::after {
        content: ' ';
        width: 11px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -11px;
        background: linear-gradient(to right, rgba(49, 49, 49, 0.1), transparent);
      }`,
  ],
);
const HeaderCol = styled.th(
  ({
    displayNone,
    headerGroupIdx,
    colSpan,
    displayBorder,
  }: {
    displayNone: boolean;
    headerGroupIdx: number;
    colSpan: number;
    displayBorder?: boolean;
  }) => [
    tw`border-0 font-semibold px-5 py-4 bg-orange text-white text-left relative leading-4`,
    displayBorder && tw`border-b border-r border-orange-three`,
    displayNone && tw`hidden`,
    headerGroupIdx === 0 &&
      tw`first-of-type:border-top-left-radius[6px] last-of-type:border-top-right-radius[6px]`,
    colSpan > 1 && tw`text-center`,
  ],
);
const Cell = styled.td(
  (props: { isExpanded?: boolean; isExpandedAndFirstColumn?: boolean }) => [
    tw`align-top px-5 py-4 bg-white duration-200 border-b border-l border-b-beige-lines border-l-transparent group-hover:bg-orange-hover`,
    props.isExpanded && tw`border-b-0`,
    props.isExpandedAndFirstColumn && tw`border-l-orange`,
  ],
);
const ExpandedCell = styled.tr((props: { isExpanded: boolean }) => [
  tw`px-5 py-4 h-full width[1000px] flex flex-auto flex-col bg-white peer-hover:bg-orange-hover animate-[slide-in-top 0.25s ease-in-out both]`,
  props.isExpanded &&
    tw`border-l border-l-orange border-b border-b-beige-lines`,
]);
const ExpandedCellColumn = tw.td`flex flex-col h-full w-full`;
const PaginationContainer = tw.div`rounded-b-lg flex flex-row items-center justify-between w-auto px-4 py-4 bg-white text-grey-three border-t border-beige-lines`;

const StyledReactPaginate = styled.div`
  ${tw`flex items-center`}
  & .pagination {
    ${tw`flex flex-row`}
  }
  & .break-me {
    ${tw`cursor-default`}
  }
  & .active {
    ${tw`border-radius[3px] border-transparent bg-orange text-white`}
  }
  & a[role='button'] {
    ${tw`my-0 mx-2`}
  }
`;
const PaginationDropdown = tw.div`flex items-center space-x-1`;
const IconWrapper = styled.div(
  (props: { expanded?: boolean; disabled?: boolean }) => [
    tw`items-center p-1 -mx-1 rounded-full transform[rotate(90deg)] duration-200 text-current`,
    !props.disabled && tw`hover:bg-orange-hover`,
    props.expanded && tw`transform[rotate(-90deg)]`,
  ],
);
// #endregion

function TanstackTable<RD extends RowData>({
  table,
  onPageLimitClick,
  onPageClick,
  noDataPage,
  stickyHeader,
  stickyHeaderPositionTop,
  stickyColumn,
  stickyColumnNumber = 2,
  fullWidth = true,
  displayHeaderBorder = false,
  limitOptions = ['10', '25', '50', '100'],
  trProps,
  expandedPage,
  renderSubComponent,
  rootStyle,
  tableContainerStyle,
  paginationContainerStyle,
  expandedRowStyle,
  expandedRowWidthFitContent = false,
  infiniteScrollingOptions,
  footerOptions,
  testID,
  forceLang,
  bottomActionElement,
}: Props<RD>) {
  // #region VALUES
  const headerGroups = table.getHeaderGroups();
  const { rows } = table.getRowModel();
  const pageLimit = table.getState().pagination.pageSize || DEFAULT_PAGE_SIZE;
  const pageCurrent = table.getState().pagination.pageIndex || DEFAULT_PAGE;
  const dataTotal = table.getPageCount();
  const pageTotal = Math.ceil(dataTotal / pageLimit);
  const hasNextPage = rows.length < dataTotal;

  const translator = useTranslator();
  let totalWidthRow: number | string = 0;
  const tableContainerRef = useRef<HTMLDivElement>();
  const paginationRef = createRef<HTMLDivElement>();
  const [paginationDropdown, setPaginationDropdown] = useState<boolean>(false);
  const virtualizer = useVirtualizer({
    count: hasNextPage ? rows.length + 1 : rows.length,
    getScrollElement: () => tableContainerRef.current as HTMLDivElement,
    estimateSize: () =>
      infiniteScrollingOptions ? Number(infiniteScrollingOptions.height) : 0,
  });

  const paginationOptions = useMemo(
    () =>
      limitOptions.map((option) => ({
        label: option,
        value: option,
      })),
    [limitOptions],
  );

  const showingLeft = (pageCurrent - 1) * pageLimit + 1;
  const showingRight =
    pageCurrent * pageLimit < dataTotal ? pageCurrent * pageLimit : dataTotal;
  // #endregion

  return (
    <Container css={rootStyle}>
      <TableContainer
        ref={tableContainerRef as LegacyRef<HTMLDivElement>}
        stickyHeader={stickyHeader}
        onScroll={
          infiniteScrollingOptions?.onScrollTableContainer ??
          footerOptions?.onScrollTableContainer
        }
        css={tableContainerStyle}
      >
        <HTMLTable
          stickyHeader={stickyHeader}
          stickyHeaderPositionTop={stickyHeaderPositionTop}
          stickyColumn={stickyColumn}
          stickyColumnNumber={stickyColumnNumber}
          fullWidth={fullWidth}
        >
          <thead>
            {headerGroups.map((headerGroup, headerGroupIdx) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const headerColumnMeta = header.column.columnDef
                    .meta as ColumnMeta;

                  return (
                    <HeaderCol
                      style={{ width: header.getSize() }}
                      css={[
                        headerColumnMeta?.cellStyle,
                        headerColumnMeta?.headerStyle,
                      ]}
                      key={header.id}
                      displayBorder={displayHeaderBorder}
                      displayNone={
                        !!headerColumnMeta?.rowSpan && headerGroupIdx !== 0
                      }
                      headerGroupIdx={headerGroupIdx}
                      colSpan={header.colSpan}
                      rowSpan={headerColumnMeta?.rowSpan ?? 1}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </HeaderCol>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody
            style={
              infiniteScrollingOptions?.useVirtualizer
                ? {
                    height: `${virtualizer.getTotalSize()}px`,
                    width: '100%',
                    position: 'relative',
                    display: 'block',
                  }
                : undefined
            }
          >
            {rows.length > 0 && infiniteScrollingOptions?.useVirtualizer
              ? virtualizer.getVirtualItems().map((virtualRow) => {
                  const isLoaderRow = virtualRow.index > rows.length - 1;
                  const item = rows[virtualRow.index];

                  if (!totalWidthRow) {
                    totalWidthRow = item
                      .getVisibleCells()
                      .reduce(
                        (prev, acc) =>
                          prev + (acc.column.getSize() + (fullWidth ? 0 : 40)),
                        0,
                      );
                  }

                  const isLoaderRowAndHasNextPage = hasNextPage ? (
                    <td
                      style={{
                        width: totalWidthRow,
                        height: `${virtualRow.size + 25}px`,
                      }}
                      colSpan={100}
                    >
                      <div style={{ display: 'grid', placeItems: 'center' }}>
                        <LoadingIndicator size="small" />
                      </div>
                    </td>
                  ) : (
                    <td
                      style={{
                        width: totalWidthRow,
                        height: `${virtualRow.size}px`,
                      }}
                      colSpan={100}
                    >
                      <div style={{ display: 'grid', placeItems: 'center' }}>
                        Nothing more to load
                      </div>
                    </td>
                  );

                  return (
                    <tr
                      key={virtualRow.index}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: totalWidthRow,
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start}px)`,
                      }}
                      className={trProps ? undefined : 'group'}
                      {...(typeof trProps === 'function'
                        ? trProps(item)
                        : trProps)}
                    >
                      {isLoaderRow
                        ? isLoaderRowAndHasNextPage
                        : item.getVisibleCells().map((cell) => {
                            const cellWidth = cell.column.getSize();

                            return (
                              <Cell
                                key={cell.id}
                                style={{ width: cellWidth }}
                                css={[
                                  (cell.column.columnDef.meta as ColumnMeta)
                                    ?.cellStyle,
                                  (
                                    cell.column.columnDef.meta as ColumnMeta
                                  )?.specificCellStyle?.(cell.row.id),
                                ]}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Cell>
                            );
                          })}
                    </tr>
                  );
                })
              : rows.map((row) => {
                  if (!totalWidthRow) {
                    totalWidthRow = row
                      .getVisibleCells()
                      .reduce(
                        (prev, acc) =>
                          prev + (acc.column.getSize() + (fullWidth ? 0 : 40)),
                        0,
                      );
                  }

                  return (
                    <Fragment key={row.id}>
                      <tr
                        key={row.id}
                        css={[
                          ((renderSubComponent && row.getIsExpanded()) ||
                            (!!expandedPage && row.getIsExpanded())) &&
                            css`
                              &:hover + tr {
                                ${tw`bg-orange-hover`}
                              }
                            `,
                        ]}
                        className={trProps ? undefined : 'group'}
                        {...(typeof trProps === 'function'
                          ? trProps(row)
                          : trProps)}
                      >
                        {row.getVisibleCells().map((cell, idx) => (
                          <Cell
                            key={cell.id}
                            isExpanded={row.getIsExpanded()}
                            isExpandedAndFirstColumn={
                              row.getIsExpanded() && idx === 0
                            }
                            css={[
                              (cell.column.columnDef.meta as ColumnMeta)
                                ?.cellStyle,
                              (
                                cell.column.columnDef.meta as ColumnMeta
                              )?.specificCellStyle?.(cell.row.id),
                            ]}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Cell>
                        ))}
                      </tr>

                      {expandedPage && row.getIsExpanded() && (
                        <ExpandedCell isExpanded css={expandedRowStyle}>
                          {expandedPage}
                        </ExpandedCell>
                      )}

                      {renderSubComponent && row.getIsExpanded() && (
                        <ExpandedCell
                          isExpanded
                          style={{
                            width: expandedRowWidthFitContent
                              ? 'fit-content'
                              : totalWidthRow,
                          }}
                          css={expandedRowStyle}
                        >
                          <ExpandedCellColumn>
                            {renderSubComponent({ row })}
                          </ExpandedCellColumn>
                        </ExpandedCell>
                      )}
                    </Fragment>
                  );
                })}
          </tbody>
        </HTMLTable>

        {rows.length < 1 && noDataPage}
      </TableContainer>

      {footerOptions && (
        <TableContainer
          ref={footerOptions.ref}
          tw="w-full flex overflow-x-hidden rounded-t-none pointer-events-none"
          style={{
            height: `${
              footerOptions.containerStyle?.height as number | string
            }px`,
          }}
        >
          {footerOptions.data.map(({ id, node, cellStyle }) => (
            <div
              key={id}
              style={cellStyle}
              tw="px-5 py-4 bg-white border-t border-beige-lines"
            >
              {node}
            </div>
          ))}
        </TableContainer>
      )}

      {rows && rows.length > 0 && onPageClick && onPageLimitClick && (
        <PaginationContainer css={paginationContainerStyle}>
          <PaginationDropdown ref={paginationRef} tw="flex">
            <Text.BodyTwo tw="font-semibold text-grey-three">
              {translator.translate('Show', forceLang)}
              {': '}
              {pageLimit}
            </Text.BodyTwo>
            <IconWrapper
              data-testid={`${testID || ''}Table:ToggleNumberPerPage`}
              expanded={paginationDropdown}
              onClick={(e) => {
                e.stopPropagation();
                setPaginationDropdown(!paginationDropdown);
              }}
            >
              <Icon.ChevronSharp height={18} width={18} strokeWidth={2.5} />
            </IconWrapper>
            <Popover
              visible={paginationDropdown}
              targetRef={paginationRef as MutableRefObject<null>}
              style={{ zIndex: 10 }}
            >
              <List.Small
                tw="w-full mx-4"
                options={paginationOptions}
                onClickItem={(option) => {
                  onPageLimitClick(parseInt(option.value, 10));
                  setPaginationDropdown(false);
                }}
                testID={`${testID || ''}Table:PageLimitOptions:`}
              />
            </Popover>
          </PaginationDropdown>

          <StyledReactPaginate>
            <Text.Label>{`${showingLeft} - ${showingRight} ${translator.translate(
              'of',
              forceLang,
            )} ${dataTotal}`}</Text.Label>

            <ReactPaginate
              onPageChange={(e: { selected: number }) => {
                onPageClick(e.selected + 1);
                setPaginationDropdown(false);
              }}
              forcePage={pageCurrent - 1}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={pageTotal || 0}
              previousLabel={null}
              nextLabel={null}
              breakClassName="break-me"
              containerClassName="pagination"
              activeClassName="active"
            />
          </StyledReactPaginate>
        </PaginationContainer>
      )}

      {bottomActionElement && (
        <PaginationContainer css={paginationContainerStyle}>
          {bottomActionElement}
        </PaginationContainer>
      )}
    </Container>
  );
}

export default TanstackTable;
