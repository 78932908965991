import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  pathFill?: string;
};

export default function Triangle({
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  fill = 'none',
  pathFill = 'black',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0998 7L6.89955 7C6.82666 7.00019 6.7552 7.01698 6.69288 7.04855C6.63056 7.08012 6.57973 7.12528 6.54587 7.17917C6.512 7.23306 6.49639 7.29364 6.5007 7.35439C6.50502 7.41514 6.52909 7.47376 6.57035 7.52394L10.1704 11.865C10.3197 12.045 10.6789 12.045 10.8285 11.865L14.4286 7.52393C14.4702 7.47386 14.4947 7.41522 14.4992 7.35437C14.5038 7.29352 14.4883 7.23279 14.4544 7.17878C14.4205 7.12478 14.3695 7.07956 14.307 7.04804C14.2445 7.01652 14.1728 6.9999 14.0998 7Z"
        fill={pathFill}
      />
    </svg>
  );
}
