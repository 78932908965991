import React from 'react';
import tw, { theme } from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { FormItem } from '../../../types/input.type';
import { promiseToVoid } from '../../../util/helper.util';
import { UseDriverAddFormHookObj } from '../../../view/DriverAdd/hooks/useDriverAddForm.hook';
import { UseDriverAddScheduleFormHookObj } from '../../../view/DriverAdd/hooks/useDriverAddScheduleForm.hook';
import { CardContainer, Divider, IconButton } from '../../atom';
import { ArrowBack, InfoOutlined } from '../../atom/Icon/Icon.atom';
import { BodyFourteen, HeadingFour } from '../../atom/Text/Text.atom';
import { CardAction, CardContent, CardHeader } from '../../molecule';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';

type Props = {
  translator: UseTranslator;
  driverAddForm: UseDriverAddFormHookObj;
  driverSchedule: UseDriverAddScheduleFormHookObj;
  assignForm: FormItem[];
  isEventInProgress?: boolean;
  handleBack(): void;
};

const Container = tw.div`flex flex-col gap-5 width[720px] mx-auto `;
const ContainerHeader = tw.div`flex gap-2.5 items-center -mb-2`;
const DriverCardContent = tw(CardContent)`grid gap-2`;
const SectionTitle = tw.div`pt-5 px-5`;
const SectionDivider = tw(Divider)`mx-5 w-auto`;
const FormCardContent = tw.div`mx-5 pt-5 gap-2 flex flex-col`;
const AlertContainer = tw.div`border border-status-fuel-anomaly rounded-lg bg-blue-five p-2.5 flex gap-2`;
const AlertIconContainer = tw.div`mt-1`;
const AlertText = tw(BodyFourteen)`whitespace-pre-wrap`;

export default function DriverForm({
  translator,
  driverAddForm,
  driverSchedule,
  assignForm,
  isEventInProgress,
  handleBack,
}: Props) {
  return (
    <Container>
      <ContainerHeader>
        <IconButton onClick={handleBack}>
          <ArrowBack fill="#F3532E" />
        </IconButton>
        <HeadingFour>{translator.translate('Add Driver')}</HeadingFour>
      </ContainerHeader>

      <CardContainer>
        <CardHeader
          containerStyle={tw`px-6 py-4`}
          title={`${
            driverSchedule.showScheduleForm ? '(1/2)' : ''
          } ${translator.translate('Driver Information')}`}
        />
        <SectionTitle>
          <HeadingFour>
            {translator.translate('Personal Information')}
          </HeadingFour>
        </SectionTitle>
        <DriverCardContent>
          {driverAddForm.personalInformationFormData.map((item) => (
            <ComposedFormField
              key={`personal-information-form-${item.id}`}
              // type already described inside component
              {...item}
            />
          ))}
        </DriverCardContent>
        <SectionDivider />
        <FormCardContent>
          <HeadingFour>{translator.translate('Login Information')}</HeadingFour>
          <AlertContainer>
            <AlertIconContainer>
              <InfoOutlined
                width={22}
                fillPath={theme`colors.status.fuel-anomaly`}
              />
            </AlertIconContainer>
            <AlertText>
              {translator.translate(
                'This information will be used to log into the Driver App. Please ensure you save this information before completing the registration.',
              )}
            </AlertText>
          </AlertContainer>
        </FormCardContent>
        <DriverCardContent>
          {driverAddForm.loginInformationFormData.map((item) => (
            // type already described inside component
            <ComposedFormField
              key={`login-information-form-${item.id}`}
              {...item}
            />
          ))}
        </DriverCardContent>
        {isEventInProgress ? (
          <>
            <SectionDivider />
            <FormCardContent>
              <HeadingFour>
                {translator.translate('Additional Information')}
              </HeadingFour>
              {assignForm.map((item) => (
                <ComposedFormField
                  key={`additional-information-form-${item.id}`}
                  // type already described inside component
                  {...item}
                />
              ))}
            </FormCardContent>
          </>
        ) : (
          <div />
        )}
        {!driverSchedule.showScheduleForm ? (
          <CardAction
            isFormFooter
            isAdditionalActionDisabled={!driverAddForm.driverForm.isValid}
            isActionDisabled={
              !driverAddForm.driverForm.isValid ||
              driverAddForm.driverForm.isSubmitting
            }
            handleAction={() => {
              void driverAddForm.driverForm.submitForm();
            }}
            handleAdditionalAction={driverSchedule.handleToggleScheduleForm}
            additionalTitle={translator.translate('Add Schedule')}
            title={translator.translate('Add Driver')}
          />
        ) : (
          <div />
        )}
      </CardContainer>
      {driverSchedule.showScheduleForm && (
        <CardContainer>
          <CardHeader
            containerStyle={tw`px-6 py-4`}
            title={`(2/2) ${translator.translate('Schedule')}`}
          />
          <DriverCardContent>
            {driverSchedule.scheduleFormData.map((item) => (
              <ComposedFormField
                key={`schedule-form-${item.id}`}
                // type already described inside component
                {...item}
              />
            ))}
          </DriverCardContent>
          <CardAction
            isFormFooter
            isActionDisabled={
              !(
                driverAddForm.driverForm.isValid &&
                driverSchedule.scheduleForm.isValid
              ) || driverAddForm.driverForm.isSubmitting
            }
            handleAction={() =>
              promiseToVoid([driverAddForm.driverForm.submitForm()])
            }
            handleAdditionalAction={driverSchedule.handleToggleScheduleForm}
            additionalTitle={translator.translate('Cancel')}
            title={translator.translate('Add Driver & Schedule')}
          />
        </CardContainer>
      )}
    </Container>
  );
}
