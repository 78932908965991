import { Locale } from './locale.config';

const haulingOrderLocaleConfig: Locale[] = [
  {
    en: 'HO Number',
    id: 'Nomor HO',
  },
  {
    en: 'HJO Number',
    id: 'Nomor HJO',
  },
  {
    en: 'Hauling Order Number',
    id: 'Nomor Hauling Order',
  },
  {
    en: 'HO Date',
    id: 'Tanggal HO',
  },
  {
    en: 'Goods Target',
    id: 'Target Barang',
  },
  {
    en: 'Job Order finished',
    id: 'Perintah Kerja selesai',
  },
  {
    en: 'Completed',
    id: 'Selesai',
  },
  {
    en: 'You can go to Job Order detail page to Finish a Job Order and add Job Order by clicking this text',
    id: 'Anda dapat membuka halaman detail Perintah Kerja untuk menyelesaikan Perintah Kerja dan menambahkan Perintah Kerja baru dengan mengklik tautan dibawah ini',
  },
  {
    en: 'You can search by HO Number, Shipper, or Location',
    id: 'Cari menggunakan Nomor HO, Nama Pengirim, atau Lokasi',
  },
  {
    en: 'You can search by HJO Number, Driver, or Vehicle',
    id: 'Cari menggunakan Nomor HJO, Nama Sopir, atau Kendaraan',
  },
  {
    en: 'Hide Hauling Job Order',
    id: 'Sembunyikan Hauling Job Order',
  },
  {
    en: 'Show Hauling Job Order',
    id: 'Tunjukkan Hauling Job Order',
  },
  {
    en: '%s • Proof of Delivery',
    id: '%s • Bukti Pengiriman',
  },
  {
    en: 'Proof of Delivery',
    id: 'Bukti Pengiriman',
  },
  {
    en: 'Assignee',
    id: 'Penerimaan Tugas',
  },
  {
    en: 'By Schedule',
    id: 'Dari Jadwal',
  },
  {
    en: 'Add Driver & Vehicle',
    id: 'Tambah Sopir & Kendaraan',
  },
  {
    en: 'Back to Hauling Order List',
    id: 'Kembali ke Daftar Hauling Order',
  },
  {
    en: 'Your Hauling Order tracking number:',
    id: 'Nomor Resi Hauling Order Anda:',
  },
  {
    en: 'Successfully created Hauling Order. Use this tracking number to track your order from',
    id: 'Berhasil membuat Hauling Order. Gunakan nomor resi ini untuk melacak order Anda di',
  },
  {
    en: 'Hauling Order Detail',
    id: 'Detail Hauling Order',
  },
  {
    en: 'General Information',
    id: 'Informasi Umum',
  },
  {
    en: 'Summary of Hauling Order',
    id: 'Ringkasan dari Hauling Order',
  },
  {
    en: 'Pickup location information',
    id: 'Informasi lokasi pickup',
  },
  {
    en: 'Order Date',
    id: 'Tanggal Order',
  },
  {
    en: '%s Completed',
    id: '%s Selesai',
  },
  {
    en: 'Deliveries',
    id: 'Pengiriman',
  },
  {
    en: 'Detail',
    id: 'Rincian',
  },
  {
    en: 'Order Received',
    id: 'Order Diterima',
  },
  {
    en: 'Goods Fulfillment',
    id: 'Pencapaian Target',
  },
  {
    en: 'Delivery has not been made',
    id: 'Pengiriman belum dibuat',
  },
  {
    en: 'There are no deliveries to this location yet',
    id: 'Belum ada pengiriman ke lokasi ini',
  },
  {
    en: 'Assignee',
    id: 'Penerima Tugas',
  },
  {
    en: 'Completed At',
    id: 'Selesai Pada',
  },
  {
    en: 'Summary',
    id: 'Ringkasan',
  },
  {
    en: 'Total Goods Target',
    id: 'Total Target Barang',
  },
  {
    en: 'Order Completed at',
    id: 'Selesai Pada',
  },
  {
    en: 'Total Goods Delivered',
    id: 'Total Target Terkirim',
  },
  {
    en: 'Achieved Target',
    id: 'Target Tercapai',
  },
  {
    en: 'Goods Fulfillment',
    id: 'Pencapaian Target',
  },
  {
    en: 'Delivered Goods',
    id: 'Barang Terkirim',
  },
  {
    en: '%s of %s %s',
    id: '%s dari %s %s',
  },
  {
    en: 'Goods Delivered',
    id: 'Barang Terkirim',
  },
  { en: 'Delivery order is started', id: 'Pengiriman order dimulai' },
  {
    en: 'Driver: %s has finished delivering %s to %s',
    id: 'Sopir: %s telah menyelesaikan pengiriman %s ke %s',
  },
  {
    en: 'Finish dropping off %s of total %s to %s',
    id: 'Selesai mengantar %s dari total %s ke %s',
  },
  { en: 'Proof of delivery submitted', id: 'Bukti pengiriman dikirim' },
  { en: 'Order Received', id: 'Order Diterima' },
  { en: 'Order Completed at', id: 'Order Selesai pada' },
  { en: 'Total Goods Target', id: 'Total Target Barang' },
  { en: 'Total Goods Delivered', id: 'Total Barang Terkirim' },
  { en: 'Gallery', id: 'Galeri' },
];

export default haulingOrderLocaleConfig;
