import React, { createRef, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import tw, { styled } from 'twin.macro';
import { Modal } from '..';
import { Button, Icon, Image, LoadingIndicator, Text } from '../../atom';
import { Banner } from '../Banner/Banner.molecule';

type Props = React.ComponentProps<'div'> & {
  onFilesChange?(file: File | undefined): void;
  upload?(): void;
  onLoading: boolean;
  cancelUpload?(): void;
  modal?: React.ReactNode;
  showModal?: boolean;
  onFinish?(): void;
  errorText?: string;
  file?: File;
};

const Container = tw.div`flex flex-auto box-border h-full bg-white rounded  mx-auto `;
const Boxcontainer = styled.div((props: { dragOver: boolean }) => [
  tw`relative border border-grey-three border-dashed rounded cursor-pointer`,
  props.dragOver
    ? tw`border-orange ring-4 ring-inset ring-orange`
    : tw`border-grey-three ring-0 ring-offset-0`,
]);
const ContentContainer = tw.div`relative flex flex-col rounded w-full`;
const FileContainer = tw.div`height[124px] flex flex-col items-center justify-center text-center p-4 text-grey-three`;
const UploadImage = styled(Image)(() => [
  tw`object-contain`,
  isMobile ? tw`height[120px] width[120px]` : tw`height[200px] width[200px]`,
]);

export function XLS({
  onFilesChange,
  upload,
  onLoading = false,
  cancelUpload,
  modal,
  showModal,
  onFinish,
  errorText,
  ...props
}: Props) {
  const [files, setFiles] = useState<File | undefined>(undefined);
  const [dragOver, setDragOver] = useState<boolean>(false);

  // #region HANDLE IMPORT
  const handleImportFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event?.currentTarget?.files) {
      const importFile = event.currentTarget.files[0];
      if (onFilesChange) onFilesChange(importFile);
      setFiles(importFile);
    }
  };
  const inputRef = createRef<HTMLInputElement>();
  // //#endregion

  return (
    <Container {...props}>
      <ContentContainer>
        <Boxcontainer dragOver={dragOver && !onLoading}>
          {!onLoading && (
            <input
              accept="*"
              type="file"
              css={[
                tw`absolute inset-0 w-full h-full p-0 m-0 opacity-0 cursor-pointer`,
              ]}
              ref={inputRef}
              onChange={handleImportFile}
              onDragOver={() => setDragOver(true)}
              onDragLeave={() => setDragOver(false)}
              onDrop={() => setDragOver(false)}
            />
          )}
          <FileContainer>
            {onLoading ? (
              <LoadingIndicator />
            ) : files ? (
              <Icon.File width={32} height={32} />
            ) : (
              <Icon.Upload width={32} height={32} />
            )}
            <Text.HeadingFive tw="mt-3 text-current">
              {onLoading
                ? 'Processing...'
                : files
                  ? 'File name:'
                  : 'Click to upload or drag & dropfile .XLSX'}
            </Text.HeadingFive>
            {files && (
              <Text.HeadingFive tw="text-current">
                {files.name}
              </Text.HeadingFive>
            )}
          </FileContainer>
        </Boxcontainer>
        {files && onLoading && (
          <ContentContainer tw="flex-row mt-4">
            <Button.Outlined tw="flex-auto" onClick={() => cancelUpload?.()}>
              Canceling import data
            </Button.Outlined>
          </ContentContainer>
        )}
        {files && !onLoading && (
          <ContentContainer tw="flex-row mt-4 space-x-4">
            <Button.Outlined
              tw="flex-auto"
              onClick={() => {
                cancelUpload?.();
                if (inputRef.current) inputRef.current.value = '';
                setFiles(undefined);
              }}
            >
              Cancel
            </Button.Outlined>
            <Button.Solid tw="flex-auto" onClick={() => upload?.()}>
              Upload
            </Button.Solid>
          </ContentContainer>
        )}
        {errorText && <Banner tw="mt-2" body={errorText} color="red" />}
      </ContentContainer>
      {showModal && (
        <Modal.Regular
          tw="w-auto"
          title="Impor Data Selesai"
          callAction={{
            label: 'Selesai',
            action: () => {
              onFinish?.();
              setFiles(undefined);
            },
          }}
        >
          {modal}
        </Modal.Regular>
      )}
    </Container>
  );
}

export function ImageUpload({
  onFilesChange,
  upload,
  onLoading = false,
  cancelUpload,
  modal,
  showModal,
  onFinish,
  errorText,
  file,
  ...props
}: Props) {
  const [files, setFiles] = useState<File | undefined>(undefined);
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [dragOver, setDragOver] = useState<boolean>(false);

  // #region HANDLE IMPORT
  const handleImportFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event?.currentTarget?.files) {
      const importFile = event.currentTarget.files[0];
      if (importFile) {
        const reader = new FileReader();
        reader.addEventListener('load', (e) => {
          setImageSrc(e.target?.result?.toString() || '');
        });
        reader.readAsDataURL(importFile);
        if (onFilesChange) onFilesChange(importFile);
        setFiles(importFile);
      }
    }
  };
  const inputRef = createRef<HTMLInputElement>();
  // //#endregion
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        setImageSrc(event.target?.result?.toString() || '');
      });
      reader.readAsDataURL(file);
      setFiles(file);
    }
  }, [file]);

  return (
    <Container {...props}>
      <ContentContainer>
        <Boxcontainer dragOver={dragOver && !onLoading}>
          {!onLoading && (
            <input
              accept="image/png, image/jpg, image/jpeg"
              type="file"
              css={[
                tw`absolute inset-0 w-full h-full p-0 m-0 opacity-0 cursor-pointer`,
              ]}
              ref={inputRef}
              onChange={handleImportFile}
              onDragOver={() => setDragOver(true)}
              onDragLeave={() => setDragOver(false)}
              onDrop={() => setDragOver(false)}
            />
          )}
          <FileContainer tw="h-auto py-0" css={[!files && tw`p-4`]}>
            {onLoading ? (
              <LoadingIndicator />
            ) : files ? (
              <UploadImage src={imageSrc} />
            ) : (
              <Icon.ImageAdd width={32} height={32} tw="text-grey-three" />
            )}
            {!files && (
              <>
                <Text.HeadingFive tw="mt-3 text-grey-three">
                  Upload foto produk...
                </Text.HeadingFive>
                <Text.HeadingFive tw=" text-grey-three">
                  File JPG, PNG max 5mb
                </Text.HeadingFive>
              </>
            )}
            {files && (
              <Button.Text
                small
                tw="text-orange whitespace-nowrap md:(absolute bottom-5 right-5 z-40)"
                onClick={() => {
                  inputRef.current?.click();
                }}
              >
                <Icon.Edit width={16} height={16} tw="mr-2" />
                Ubah foto
              </Button.Text>
            )}
          </FileContainer>
        </Boxcontainer>
        {errorText && <Banner tw="mt-2" body={errorText} color="red" />}
      </ContentContainer>
    </Container>
  );
}
