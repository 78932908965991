// #region IMPORT
// Package
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import envConfig from '../../config/env/env.config';

// Config

// #endregion

/**
 *
 */
export const initAnalytics = (): void => {
  ReactGA.initialize(envConfig.gaTrackingID, {
    gaOptions: {
      debug_mode: envConfig.mode === 'development',
      cookieDomain: 'auto',
    },
  });
};

export type GATracker = {
  user_id: string;
  organization_id: string;
};
/**
 *
 */
export const setGATracker = ({ organization_id }: GATracker): void => {
  ReactGA.set({ user_properties: { organization_id } });
  ReactGA.gtag('config', envConfig.gaTrackingID, {
    user_id: organization_id,
  });
};

/**
 *
 * @param category e.g job order, shipper order
 * @param action e.g submit shipper order
 *
 * @example
 * ```
 * ReactGA.event({
 *    category: 'Shipper Order',
 *    action: 'Add shipper order',
 *  });
 * ```
 * other examples please check: https://github.com/codler/react-ga4#example
 */
export const logEvent = (action: string, category = 'Common'): void => {
  ReactGA.event({
    category,
    action,
  });
};

/**
 * Following google convention for event parameters in snake case
 * The prior method produce the first letter of action name in Capital
 * In order to fix this issue is to change the implementation based on this thread: [Issue #39](https://github.com/codler/react-ga4/issues/39)
 *
 * @param params
 */
export const logEventGA = (
  params: Omit<UaEventOptions, 'category' | 'label'> & {
    event_category?: string;
    event_label?: string;
  } & Record<string, string>,
): void => {
  const { action, ...rest } = params;
  ReactGA.event(action, rest);
};
