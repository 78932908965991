import React from 'react';
import { RouteConfig } from '../../../../../config/router/router.config';

export type ReportDrivingBehaviorByVehicleRouteParam = string;

export type ReportDrivingBehaviorByVehicleRouteSearchParam = {
  page?: string; // number
  pageSize?: string; // number
  search?: string;
  orderBy?: string; // RDBVehicleFilterOrderByEnum
  vehicleId?: string;
  vehicleName?: string;
  filterBy?: string; // RDBVehicleFilterByEnum
  startDate?: string; // number in milliseconds
  endDate?: string; // number in milliseconds
};

export type ReportDrivingBehaviorByVehicleRouteNavigationState = undefined;

export const reportDrivingBehaviorByVehicleRoute: RouteConfig = {
  name: 'Driving Behavior by Vehicle',
  path: '/report/driving-behavior-by-vehicle',
  Component: React.lazy(() => import('./ReportDrivingBehaviorByVehicle.view')),
  isPrivate: true,

  breadcrumb: 'Driving Behavior by Vehicle',

  options: {
    icon: <div />,
    label: 'Driving Behavior by Vehicle',
    testID: 'MenuReportDrivingBehaviorByVehicle',
  },
};
