import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { Goods, GoodsFormValues } from '../model/ShipperOrder.model';
import {
  convertGoodsFormValues,
  getGoodsFormTotal,
  getGoodsValidationSchema,
} from '../util/shipperOrderCreate.util';
import { UseTranslator } from './useTranslator.hook';

interface UseGoodsFormProps {
  translator: UseTranslator;
  prevGoods?: Partial<Goods>[];
  onSubmit: (goods: Goods[]) => unknown | Promise<boolean>;
}

function useGoodsForm({ translator, onSubmit, prevGoods }: UseGoodsFormProps) {
  const GoodsFormValidation = yup.object().shape({
    goods: yup.array().of(getGoodsValidationSchema(translator, prevGoods)),
  });

  const { errors, setFieldValue, values, handleSubmit } = useFormik<{
    goods: Partial<Goods>[];
  }>({
    initialValues: { goods: [] },
    validationSchema: GoodsFormValidation,
    onSubmit: (formValues) => {
      onSubmit(convertGoodsFormValues(formValues.goods));
    },
  });

  const setInitialGoodsValue = (goods: Partial<Goods>[]) => {
    void setFieldValue('goods', goods);
  };

  const goodsFormIsInvalid = useMemo(() => {
    try {
      const goodsObj = errors.goods as Record<keyof GoodsFormValues, string>[];

      return goodsObj.length > 0;
    } catch (error) {
      return false;
    }
  }, [errors.goods]);
  const getGoodsFormErrors = (
    goodIndex: number,
    key: keyof GoodsFormValues,
  ) => {
    try {
      const goodsObj = errors.goods as unknown as Record<
        keyof GoodsFormValues,
        string
      >[];

      return goodsObj[goodIndex][key] || '';
    } catch (error) {
      return '';
    }
  };

  const onAddGoodHandler = useCallback(() => {
    void setFieldValue('goods', [
      ...values.goods,
      {
        quantity: undefined,
        volume: undefined,
        weight: undefined,
        type: undefined,
        description: undefined,
        uom: undefined,
      },
    ]);
  }, [setFieldValue, values.goods]);
  const onRemoveGoodHandler = useCallback(
    (index: number) => {
      void setFieldValue(
        'goods',
        values.goods.filter((g, _index) => _index !== index),
      );
    },
    [setFieldValue, values.goods],
  );

  const onInputChangeHandler = useCallback(
    (key: keyof Goods, value: string, index: number) => {
      const item = values.goods[index];
      if (item) {
        void setFieldValue(
          'goods',
          values.goods.map((v, i) => {
            if (i === index) return { ...v, [key]: value || null };
            return v;
          }),
        );
      }
    },
    [setFieldValue, values.goods],
  );

  const total = {
    weight: getGoodsFormTotal(values.goods, 'weight'),
    volume: getGoodsFormTotal(values.goods, 'volume'),
  };

  return {
    values,
    onAddGoodHandler,
    onRemoveGoodHandler,
    onInputChangeHandler,
    setInitialGoodsValue,
    goodsFormIsInvalid,
    getGoodsFormErrors,
    setFieldValue,
    handleSubmit,
    total,
  };
}

export default useGoodsForm;
