import React, { createRef, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { Icon, InputArea } from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region PROPS
type Props = React.ComponentProps<'textarea'> & {
  label?: string;
  error?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
  autoSize?: boolean;
  addHeight?: number;
};
// #endregion

const Container = tw.div`flex flex-col`;
const WithIconContainer = tw.div`flex items-center relative`;
const IconContainer = tw.span`absolute left-3.5 flex items-center`;
const ErrorIconContainer = styled.span((props: { iconRight?: boolean }) => [
  tw`absolute right-3.5 flex items-center`,
  props.iconRight && tw`right-12`,
]);
const IconContainerRight = tw.span`absolute right-3.5 flex items-center`;
const ErrorMessage = tw.div`font-sans font-size[12px] line-height[20px] letter-spacing[0.2px] mt-0.5 mx-4 text-status-alert`;

const Underline = styled.div(
  (props: { disabled?: boolean; error?: boolean; filled?: boolean }) => [
    tw`absolute bottom-0 inset-x-px h-0.5 bg-grey-four`,
    !props.disabled &&
      tw`peer-hover:bg-orange peer-focus:bg-orange group-hover:bg-orange`,
    !props.disabled && props.filled && tw`bg-black`,
    props.error && tw`bg-status-alert!`,
  ],
);

const Label = styled.label(
  (props: {
    disabled?: boolean;
    filled?: boolean;
    extraStyle?: ExtraStyle;
  }) => [
    tw`text-grey-three duration-200 font-sans leading-6 tracking-wider select-none absolute top-4 left-4 ml-px`,
    !props.disabled &&
      tw`cursor-text peer-hover:(font-size[12px] leading-5 top-1.5) peer-focus:(font-size[12px] leading-5 top-1.5) group-hover:(font-size[12px] leading-5 top-1.5)`,
    props.filled && tw`font-size[12px] leading-5 top-1.5`,
    props.disabled && props.filled && tw`(font-size[12px] top-2)`,
    props.extraStyle,
  ],
);

// #region MAIN COMPONENT
export function TextArea({
  id,
  value,
  label,
  placeholder,
  error,
  left,
  right,
  disabled,
  autoSize,
  addHeight,
  ...props
}: Props) {
  const inputRef = createRef<HTMLTextAreaElement>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    if (autoSize && inputRef && inputRef.current) {
      inputRef.current.style.height = '0px';
      const { scrollHeight } = inputRef.current;
      inputRef.current.style.height = `${scrollHeight + (addHeight ?? 0)}px`;
    }
  }, [addHeight, autoSize, inputRef, value]);

  return (
    <Container className="group" onClick={() => inputRef.current?.focus()}>
      <WithIconContainer>
        {left && <IconContainer>{left}</IconContainer>}
        <InputArea
          id={id}
          required
          ref={inputRef}
          className="peer"
          css={[
            tw`px-4 padding-top[22px] pb-2 w-full rounded-b-none overflow-hidden`,
            error && tw`pr-12`,
          ]}
          disabled={disabled}
          value={value}
          placeholder={!label ? placeholder : undefined}
          {...props}
        />
        <Label
          htmlFor={id}
          css={[!!left && tw`pl-8`, !!right && tw`pr-8`]}
          disabled={disabled}
          filled={!!value}
        >
          {label}
        </Label>
        {!!error && (
          <ErrorIconContainer tw="text-status-alert" iconRight={!!right}>
            <Icon.Error />
          </ErrorIconContainer>
        )}
        {!!right && <IconContainerRight>{right}</IconContainerRight>}
        {!disabled && (
          <Underline disabled={disabled} error={!!error} filled={!!value} />
        )}
      </WithIconContainer>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
}

export default TextArea;
// #endregion
