import React from 'react';
import tw from 'twin.macro';
import { UseJOFormAssignmentObj } from '../../../hook/jobOrderForm/useJOFormAssignment.hook';
import { CardContainer } from '../../atom';
import {
  BlockerModal,
  CardContent,
  CardHeader,
  DrawerPortal,
} from '../../molecule';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';
import JOAssignmentForm from '../JOAssignmentForm/JOAssignmentForm.organism';
import JobOrderHeaderAssignmentForm from '../JOHeaderAssignmentForm/JOHeaderAssignmentForm.organism';

const Container = tw.div`w-[720px] mx-auto py-10`;
const ContainerForm = tw.div``;
const DrawerContainerStyle = tw`md:w-[616px]`;

type Props = {
  assignment: UseJOFormAssignmentObj;
};

export default function JOFormAssignment({
  assignment: {
    title,
    joId,
    formData,
    isUseAssignment,
    vehicleDriverFormData,
    selectedSchedule,
    isScheduleFieldDisplayed,
    isVehicleDriverFormDisplayed,
    scheduleAssignment,
    isErrorVisible,
    setIsErrorVisible,
    handleChangeAssignmentType,
    handleBackHeader,
  },
}: Props) {
  return (
    <Container>
      <CardContainer>
        <CardHeader handleBack={handleBackHeader} title={title} />
        <CardContent>
          <JobOrderHeaderAssignmentForm
            isEdit={!!joId}
            isErrorVisible={isErrorVisible}
            isUseAssignment={isUseAssignment}
            formData={vehicleDriverFormData}
            selectedSchedule={selectedSchedule}
            isScheduleFieldDisplayed={isScheduleFieldDisplayed}
            isVehicleDriverFormDisplayed={isVehicleDriverFormDisplayed}
            handleClickSelectionSchedule={() => {
              scheduleAssignment.setSidebarDisplayed((v) => !v);
            }}
            handleSelectAssignmentType={handleChangeAssignmentType}
          />
          {formData.map((item) => (
            <ContainerForm key={`JO-header-form-${item.id}`}>
              <ComposedFormField {...item} />
            </ContainerForm>
          ))}
        </CardContent>
      </CardContainer>
      <DrawerPortal
        isOpen={scheduleAssignment.isSidebarDisplayed}
        handleClose={() => {
          scheduleAssignment.handleSetSidebarDisplayed(false);
          setIsErrorVisible(true);
        }}
        contentContainerStyle={DrawerContainerStyle}
      >
        <JOAssignmentForm joAssignmentHookObj={scheduleAssignment} />
      </DrawerPortal>

      {scheduleAssignment.blocker.isModalVisible && (
        <BlockerModal blocker={scheduleAssignment.blocker} />
      )}
    </Container>
  );
}
