import React, { useCallback, useMemo } from 'react';
import tw from 'twin.macro';
import { JOAssignmentBy } from '../../../constant/JOAssignment.constant';
import { UseJOAssignmentFormHookObj } from '../../../hook/joAssignmentForm/useJOAssignmentForm.hook';
import { mapJOAssignmentByToLabel } from '../../../util/joAssignment.util';
import { Chip, Divider } from '../../atom';
import { Solid } from '../../atom/Button/Button.atom';
import AssignmentForm from '../AssignmentForm/AssignmentForm.organism';

// #region JOAssignment
const Container = tw.div`w-full h-full max-h-screen flex flex-col bg-white`;
const Header = tw.header`px-5 py-2 min-h-[68px] flex justify-between items-center`;

const ChipList = tw.div`flex gap-2.5`;

const Body = tw.div`p-6 flex flex-col w-full overflow-auto h-[calc(100vh - 145px)] `;
const Footer = tw.footer`flex items-center justify-end mt-auto py-4 px-5`;
const FooterAddSchedule = tw(Solid)`flex`;

type Props = {
  joAssignmentHookObj: UseJOAssignmentFormHookObj;
};

export default function JOAssignmentForm({ joAssignmentHookObj }: Props) {
  const renderChipLabel = useCallback(
    (item: string) => (
      <Chip.Regular
        key={item}
        selected={item === joAssignmentHookObj.tab}
        onClick={() => {
          joAssignmentHookObj.handleChangeTab(item as JOAssignmentBy);
        }}
        label={joAssignmentHookObj.translate(
          mapJOAssignmentByToLabel(item as JOAssignmentBy),
        )}
      />
    ),
    [joAssignmentHookObj],
  );
  const chips = useMemo(
    () => Object.keys(JOAssignmentBy).map(renderChipLabel),
    [renderChipLabel],
  );

  return (
    <Container>
      <Header>
        <ChipList>{chips}</ChipList>
      </Header>

      <Divider />

      <Body>
        <AssignmentForm
          tab={joAssignmentHookObj.tab}
          joDateAssignmentForm={joAssignmentHookObj.dateAssignmentForm}
          joDriverAssignmentForm={joAssignmentHookObj.driverAssignmentForm}
          joVehicleAssignmentForm={joAssignmentHookObj.vehicleAssignmentForm}
        />
      </Body>

      <Divider />

      <Footer>
        <FooterAddSchedule
          type="button"
          disabled={!joAssignmentHookObj.isFormValid}
          onClick={() => joAssignmentHookObj.handleSelectSchedule()}
        >
          {joAssignmentHookObj.submitLabel}
        </FooterAddSchedule>
      </Footer>
    </Container>
  );
}
// #endregion
