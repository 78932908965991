// #region INTERFACE
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import { DriverStatus } from '../../../model/Driver.model';
import {
  DrivingContest,
  DrivingContestOrderBy,
  DrivingContestRegisterOrderBy,
  DrivingContestStatus,
} from '../../../model/DrivingContest.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, MD, QD } from '../../api.baseQuery';
import {
  ApiResponse,
  CommonApiListParams,
  CommonApiMetadata,
  CommonApiResponse,
} from '../../api.endpoint';
// #endregion

// #region INTERFACE
export type GetDrivingContestApiRequest =
  | (CommonApiListParams & {
      orderBy?: DrivingContestOrderBy;
      'status[]'?: DriverStatus;
      'contestStatus[]'?: DrivingContestStatus;
    })
  | undefined;
export type GetDrivingContestApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    drivers: DrivingContest[];
  };

export type GetDrivingContestCountApiRequest = {
  'status[]'?: DriverStatus[];
  'contestStatus[]'?: DrivingContestStatus[];
};

export type GetDrivingContestCountApiResponse = CommonApiResponse & {
  drivers: {
    totalCount: number;
  };
};

export type GetDrivingContestRegisterListApiRequest =
  | (CommonApiListParams & {
      orderBy?: DrivingContestRegisterOrderBy;
    })
  | undefined;
export type GetDrivingContestRegisterListApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    drivers: DrivingContest[];
  };

export type DeactivateDrivingContestRequest = {
  driverId: string;
};
export type DeactivateDrivingContestResponse = ApiResponse;

export type DrivingContestAssignRequest = {
  driverId: string;
};
export type DrivingContestAssignResponse = ApiResponse;

export type DrivingContestEndpoint = {
  getDrivingContestList: QD<
    GetDrivingContestApiRequest | string,
    GetDrivingContestApiResponse
  >;
  getDrivingContestCount: QD<
    GetDrivingContestCountApiRequest | string,
    GetDrivingContestCountApiResponse
  >;
  getDrivingContestRegisterList: QD<
    GetDrivingContestRegisterListApiRequest,
    GetDrivingContestRegisterListApiResponse
  >;
  removeDrivingContest: MD<
    DeactivateDrivingContestRequest,
    DeactivateDrivingContestResponse
  >;
  assignDrivingContest: MD<
    DrivingContestAssignRequest,
    DrivingContestAssignResponse
  >;
};
// #endregion

// #region ENDPOINT
export const drivingContestTagTypes: EndpointTagTypes<DrivingContestEndpoint> =
  {
    getDrivingContestCount: 'drivingContestCount',
    getDrivingContestList: 'drivingContest',
    getDrivingContestRegisterList: 'drivingContestRegister',
    removeDrivingContest: '',
    assignDrivingContest: '',
  };

export const drivingContestEndpoint = (
  builder: Builder,
): DrivingContestEndpoint => ({
  getDrivingContestCount: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/drivers.driving-contests.count?${param}`
          : urlcat('/v1/drivers.driving-contests.count', toSnakeCase(param)),
    }),
    providesTags: [drivingContestTagTypes.getDrivingContestCount],
  }),
  getDrivingContestList: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/drivers.driving-contests.list?${param}`
          : urlcat('/v1/drivers.driving-contests.list', toSnakeCase(param)),
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.drivers.map(({ id }) => ({
              type: drivingContestTagTypes.getDrivingContestList,
              id,
            })),
            drivingContestTagTypes.getDrivingContestList,
          ]
        : [drivingContestTagTypes.getDrivingContestList],
  }),
  removeDrivingContest: builder.mutation({
    query: (body) => ({
      url: '/v1/drivers.driving-contests.remove',
      body,
      method: 'post',
    }),
    invalidatesTags: [drivingContestTagTypes.getDrivingContestList],
  }),
  getDrivingContestRegisterList: builder.query({
    query: (param) =>
      urlcat(
        '/v1/drivers.driving-contests.unassigned.list',
        toSnakeCase(param),
      ),
    providesTags: (result) =>
      result
        ? [
            ...result.drivers.map(({ id }) => ({
              type: drivingContestTagTypes.getDrivingContestRegisterList,
              id,
            })),
            drivingContestTagTypes.getDrivingContestRegisterList,
          ]
        : [drivingContestTagTypes.getDrivingContestRegisterList],
  }),
  assignDrivingContest: builder.mutation({
    query: (body) => ({
      url: '/v1/drivers.driving-contests.assign',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      drivingContestTagTypes.getDrivingContestList,
      drivingContestTagTypes.getDrivingContestRegisterList,
    ],
  }),
});
// #endregion
