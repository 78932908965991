import React, { useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import {
  Button as BaseButton,
  Icon,
  IconButton as BaseIconButton,
} from '../../atom';

// #region INTERFACES
export enum SnackbarTheme {
  dark = 'dark',
  light = 'light',
  warning = 'warning',
  success = 'success',
}

type Props = React.ComponentProps<'div'> & {
  body: string;
  onClickClose: () => void;
  theme?: SnackbarTheme;
  autoHide?: boolean;
  withClose?: boolean;
  buttonLabel?: string;
  buttonAction?: () => void;
};
// #endregion

const AUTOHIDE_DURATION = 3000;

// #region STYLED
const Container = styled.div((props: { theme: SnackbarTheme }) => [
  tw`fixed z-10 rounded bg-black text-white box-border flex`,
  tw`inset-x-4 bottom-4 sm:(max-width[400px] bottom-10 mx-auto) whitespace-normal`,
  props.theme === 'warning' && tw`bg-status-alert-light text-status-alert`,
]);
const Content = tw.span`
font-sans font-weight[400] text-black font-size[16px] line-height[26px] letter-spacing[0.2px] text-current p-4 flex-1`;
const HorizontalDivider = tw.div`w-px bg-current opacity-30`;
const IconButton = tw(BaseIconButton)`
  text-current mr-2 self-center relative
  hover:(bg-transparent after:(content-[' '] absolute inset-0 bg-current rounded-full opacity-10))
`;
const ActionButton = tw(BaseButton.Solid)`
  bg-transparent relative px-4 py-3 font-size[14px] text-current
  hover:(bg-transparent after:(content-[' '] absolute inset-0 bg-current rounded-r opacity-10))
`;
// #endregion

export function Snackbar({
  body,
  onClickClose,
  theme = SnackbarTheme.dark,
  autoHide = true,
  withClose,
  buttonLabel,
  buttonAction,
}: Props) {
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (autoHide) {
      timeoutId = setTimeout(() => {
        onClickClose();
      }, AUTOHIDE_DURATION);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [autoHide, onClickClose]);

  return (
    <Container theme={theme}>
      <Content>{body}</Content>

      {withClose && !buttonAction && (
        <IconButton
          onClick={(ev) => {
            ev.stopPropagation();
            onClickClose();
          }}
        >
          <Icon.Close height={28} width={28} />
        </IconButton>
      )}

      {!!buttonAction && (
        <>
          <HorizontalDivider />
          <ActionButton onClick={buttonAction}>{buttonLabel}</ActionButton>
        </>
      )}
    </Container>
  );
}

export default Snackbar;
