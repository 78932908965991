import { Locale } from './locale.config';

const listLocaleConfig: Locale[] = [
  {
    en: 'of',
    id: 'dari',
  },
  {
    en: 'Filter',
    id: 'Filter',
  },
  {
    en: 'All',
    id: 'Semua',
  },
];

export default listLocaleConfig;
