import { LocationType } from '../constant/Location.constant';
import {
  ApiResponse,
  CommonApiMetadata,
  CommonApiResponse,
} from '../service/api.endpoint';
import { Shipper } from './Shipper.model';

export enum LocationOrderBy {
  NAME_ASC = 'NAME_ASC', // Location Name A-Z (default)
  NAME_DESC = 'NAME_DESC', // Location Name Z-A
  CREATED_AT_DESC = 'CREATED_AT_DESC', // Recently Added
}

export interface LocationForm {
  name: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  contactName: string;
  contactNumber: string;
  radius?: number;
  type?: string;
  shipperId?: string;
}
export interface Location {
  id: string;
  shipperId: string | null;
  shipper?: Shipper;
  name: string;
  address: string;
  radius?: number;
  latitude: number;
  longitude: number;
  type: LocationType;
  contactName: string | null;
  contactNumber: string | null;
  createdAt: number;
  updatedAt: number;
}

// #region API
export type GetLocationApiRequest = {
  page?: number;
  pageSize?: number;
  search?: string;
  searchBy?: 'NAME' | 'ADDRESS' | 'ALL';
  orderBy?: LocationOrderBy;
  shipperId?: string;
  'type[]'?: LocationType;
};

export type GetLocationApiResponse = CommonApiResponse &
  CommonApiMetadata & { locations: Location[] };

export type GetLocationCountApiRequest = Pick<
  Exclude<GetLocationApiRequest, void>,
  'search' | 'searchBy' | 'orderBy' | 'type[]' | 'shipperId'
>;

export type GetLocationCountApiResponse = ApiResponse & {
  locations: { totalCount: number };
};

export type GetLocationShipperApiRequest = {
  page?: number;
  pageSize?: number;
  search?: string;
  shipperId?: string;
  searchBy?: 'NAME' | 'ADDRESS' | 'ALL';
};

export type GetLocationShipperApiResponse = CommonApiResponse &
  CommonApiMetadata & { locations: Location[] };

export interface InfoLocationParams {
  id: string;
}

export type InfoLocationResponse = CommonApiResponse & { location: Location };

export interface UpdateLocationParams {
  id: string;
  radius?: number;
  address?: string;
  latitude?: number;
  longitude?: number;
  contactName?: string | null;
  contactNumber?: string | null;
}

export type UpdateLocationResponse = CommonApiResponse & {
  location: { id: string };
};

export interface DeleteLocationParams {
  id: string;
}

export interface PostLocationParams {
  name: string;
  address: string;
  radius?: number;
  latitude?: number;
  longitude?: number;
  type: string;
  contactName?: string;
  contactNumber?: string;
  shipperId?: string;
  shipperName?: string;
}

export type PostLocationResponse = CommonApiResponse & {
  location: { id: string };
};

export type ExportLocationApiRequest = Omit<GetLocationApiRequest, 'type[]'> & {
  type?: LocationType[];
};
export type ExportLocationApiResponse = CommonApiResponse & {
  location: {
    fileUrl: string;
  };
};
// #endregion

export type LocationSearchParam = {
  page?: string;
  pageSize?: string;
  search?: string;
  sortBy?: string;
  orderBy?: string;
  shipperId?: string;
  type?: string;
};
