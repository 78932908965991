import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import DriverAddBulkView from './DriverAddBulk.view';

export type DriverAddBulk = {
  'Nama Lengkap Sopir': string;
  'Nomor SIM': string;
  'Tanggal Lahir': string;
  Alamat: string;
  'Nomor Telepon': string;
  PIN: string;
  id?: string;
};
export type DriverAddBulkRouteParam = {
  filename: string;
  drivers: DriverAddBulk[];
};

export const driverAddBulkRoute: RouteConfig = {
  name: 'Import Driver',
  Component: React.lazy(() => import('./DriverAddBulk.view')),
  NormalComponent: <DriverAddBulkView />,
  path: '/driver/add-bulk',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
