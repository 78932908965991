import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from '../../../app/store/store.app';
import { ErrorCodes } from '../../../constant';
import { settingInitialValues } from '../../../constant/Setting.constant';
import useRouteCheck from '../../../hook/useRouteCheck/useRouteCheck.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import {
  IOrganizationOrientation,
  IOrganizationSetting,
} from '../../../model/Organization.model';
import { setGATracker } from '../../../service/analytic/analytic.service';
import { setMonitoringContext } from '../../../service/errorMonitoring/errorMonitoring.service';
import {
  LogoutReason,
  sessionAction,
  SessionStore,
} from '../../../store/session.store';
import { settingAction } from '../../../store/setting.store';
import { visaAction } from '../../../store/visa.store';
import { errorCodeToLabel, getAuthErrorTitle } from '../../../util/error.util';
import { removeGSIDInParam } from '../../../util/wrapper.util';
import { organizationResetRoute } from '../../OrganizationReset/OrganizationReset.route';
import useGetOrganization from './useGetOrganization.hook';
import usePendingRedirection from './usePendingRedirection.hook';
import useRefreshToken from './useRefreshToken.hook';
import useVerifyAuth from './useVerifyAuth.hook';
import useVerifyToken from './useVerifyToken.hook';

export type UseSessionHookObj = ReturnType<typeof useSession>;
export type NavigationInitialLoad = { isInitialLoad?: boolean };
export type ClientAuthenticationResponse<T = unknown> =
  | {
      ok: true;
      data?: T;
    }
  | {
      ok: false;
      code?: string;
    }
  | undefined;

function useSession() {
  const [params] = useSearchParams();
  /**
   * `ssoToken` is one time token from initial Visa response
   * This token available when login only
   */
  const ssoToken = params.get('ssoToken');

  /**
   * `gsid` is global shared token used across runner services
   * This token only available when user come from other runner's services
   * for instance, user redirect from service A to Job Order service and vice versa
   */
  const gsid = params.get('gsid');

  const location = useLocation();
  const translator = useTranslator();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routeCheck = useRouteCheck();
  const { handleVerifyToken } = useVerifyToken();
  const { handleRefreshToken } = useRefreshToken();
  const { handleGetOrganization } = useGetOrganization();
  const pendingRedirection = usePendingRedirection();

  const session = useSelector(
    (state: RootState) => state.session,
    shallowEqual,
  );
  const rehydrated = useSelector(
    (state: RootState) => state._persist.rehydrated,
    shallowEqual,
  );

  const [authErrorCode, setAuthErrorCode] = useState<string | undefined>(
    undefined,
  );
  const [verifyingUser, setVerifyingUser] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [authCompleted, setAuthCompleted] = useState(false);

  const errorPageTitleText = useMemo(
    () => translator.translate(getAuthErrorTitle(authErrorCode)),
    [authErrorCode, translator],
  );

  const errorPageBodyText = useMemo(
    () => translator.translate(errorCodeToLabel(authErrorCode)),
    [authErrorCode, translator],
  );
  const errorPageActionText = useMemo(
    () => translator.translate('Back to Login Page'),
    [translator],
  );
  const authError = useMemo(() => !!authErrorCode, [authErrorCode]);

  // #region EVENT HANDLER

  const isVisaError = useCallback((code?: string) => {
    if (!code) return false;
    const visaErrors = [
      ErrorCodes.SSO_REFRESH_TOKEN_FAILED,
      ErrorCodes.SSO_VISA_CREDENTIAL_FAILED,
      ErrorCodes.SSO_FORBIDDEN_REQUEST,
    ];

    return visaErrors.includes(code as ErrorCodes);
  }, []);

  const isUserUnauthorizeError = useCallback((code?: string) => {
    if (!code) return false;
    const visaErrors = [ErrorCodes.SSO_FORBIDDEN_REQUEST];
    return visaErrors.includes(code as ErrorCodes);
  }, []);

  const handleLogout = useCallback(
    (type: LogoutReason = LogoutReason.REVOKE) => {
      dispatch(
        sessionAction.logout({
          type,
          token: session.globalSessionID,
        }),
      );
    },
    [dispatch, session.globalSessionID],
  );

  const handleAuthenticationFailed = useCallback(
    (errorCode?: string) => {
      const visaError = isVisaError(errorCode);
      const userUnauthorizedError = isUserUnauthorizeError(errorCode);
      if (visaError && !userUnauthorizedError) {
        handleLogout(LogoutReason.CLEAR);
      } else {
        setAuthCompleted(true);
        setAuthLoading(false);
        setVerifyingUser(false);
        navigate('/');
      }
    },
    [handleLogout, isUserUnauthorizeError, isVisaError, navigate],
  );

  const handleAuthenticationSuccess = useCallback(
    ({
      orgSettings,
      sessionData,
      initialAuthentication,
    }: {
      orgSettings?: {
        organizationName?: string;
        orientation?: IOrganizationOrientation;
        organization?: IOrganizationSetting;
        isResetting?: boolean;
      };
      sessionData: Omit<SessionStore, 'isLoggedIn' | 'organizationName'>;
      initialAuthentication?: boolean;
    }) => {
      setAuthLoading(false);
      setAuthCompleted(true);
      setMonitoringContext({
        username: sessionData.username,
        organizationId: sessionData.organizationId,
      });

      setGATracker({
        organization_id: sessionData.organizationId,
        user_id: sessionData.userId,
      });
      dispatch(sessionAction.login(sessionData));

      dispatch(
        settingAction.changeOrganizationSetting(
          orgSettings?.organization ?? settingInitialValues,
        ),
      );

      dispatch(
        sessionAction.changeOrganizationName(
          orgSettings?.organizationName || '',
        ),
      );
      dispatch(
        settingAction.changeOrientationSetting(orgSettings?.orientation),
      );
      dispatch(visaAction.toggleVerified());

      const redirection = pendingRedirection.get;

      if (orgSettings?.isResetting) {
        pendingRedirection.clear();
        dispatch(settingAction.changeIsResetSetting(true));
        navigate(organizationResetRoute.path, {
          replace: true,
          state: {
            isResetting: true,
          },
        });
      }

      if (initialAuthentication && redirection) {
        pendingRedirection.clear();
        navigate(redirection.pathname + redirection.search);
      }
    },
    [dispatch, navigate, pendingRedirection],
  );

  const handleGetVisaCredential = useCallback(async (): Promise<
    ClientAuthenticationResponse<
      Omit<SessionStore, 'isLoggedIn' | 'organizationName'>
    >
  > => {
    try {
      if (ssoToken) {
        const verifyTokenResponse = await handleVerifyToken(ssoToken);
        return verifyTokenResponse;
      }

      if (session.isLoggedIn && session.globalSessionID) {
        const refreshTokenResponse = await handleRefreshToken(
          session.globalSessionID,
          session.token,
        );
        return refreshTokenResponse;
      }
      throw new Error('SSO Visa Credential Failed');
    } catch (_err: unknown) {
      return { ok: false, code: ErrorCodes.SSO_VISA_CREDENTIAL_FAILED };
    }
  }, [
    handleRefreshToken,
    handleVerifyToken,
    session.isLoggedIn,
    session.globalSessionID,
    session.token,
    ssoToken,
  ]);

  const handleErrorPageButtonClick = useCallback(() => {
    const actions: Record<string, () => void> = {
      // If ORGANIZATION_NOT_FOUND error occur, we want redirect user to default available service (Runner)
      [ErrorCodes.ORGANIZATION_NOT_FOUND]: () =>
        handleLogout(LogoutReason.USER_UNAUTHORIZED),
      [ErrorCodes.SSO_FORBIDDEN_REQUEST]: () =>
        handleLogout(LogoutReason.FORBIDDEN_REQUEST),
      // Otherwise force user to logout
      DEFAULT: () => handleLogout(LogoutReason.REVOKE),
    };

    if (authErrorCode && actions[authErrorCode]) {
      actions[authErrorCode]();
    } else {
      actions.DEFAULT();
    }
  }, [authErrorCode, handleLogout]);

  // #endregion

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    if (
      authCompleted ||
      verifyingUser ||
      !!gsid ||
      !rehydrated ||
      routeCheck.isPublic
    )
      return;
    void (async () => {
      setVerifyingUser(true);
      const visaResponse = await handleGetVisaCredential();
      if (!visaResponse?.ok) {
        setAuthErrorCode(visaResponse?.code);
        handleAuthenticationFailed(visaResponse?.code);
        return;
      }

      if (!visaResponse.data) return;

      const getOrgResponse = await handleGetOrganization(
        visaResponse.data.token,
      );

      if (!getOrgResponse?.ok) {
        setAuthErrorCode(getOrgResponse?.code);
        handleAuthenticationFailed(getOrgResponse?.code);
        return;
      }

      if (!getOrgResponse.data) return;

      const orgSettings = getOrgResponse.data;
      const sessionData = visaResponse.data;

      handleAuthenticationSuccess({
        orgSettings,
        sessionData,
        initialAuthentication: !!ssoToken,
      });
    })();
  }, [
    handleAuthenticationFailed,
    handleAuthenticationSuccess,
    handleGetOrganization,
    handleGetVisaCredential,
    authCompleted,
    session.isLoggedIn,
    ssoToken,
    verifyingUser,
    gsid,
    rehydrated,
    routeCheck.isPublic,
    isVisaError,
  ]);

  const [verifyingGSID, setVerifyingGSID] = useState(false);

  const { handleVerifyAuth } = useVerifyAuth();

  useEffect(() => {
    if (!gsid || verifyingGSID) return;
    setVerifyingGSID(true);
    const incomingTokenIsNotEqual =
      !!session.globalSessionID && gsid !== session.globalSessionID;
    const incomingTokenIsEqual =
      !!session.globalSessionID && gsid === session.globalSessionID;
    const path = location.pathname.slice(1);

    const hasExternalServiceRedirection =
      !!path || (!!location.search && !location.search.includes('ssoToken')); //

    /**
     *
     * In this lifecycle we just make sure that several things related to redirection with GSID meet some criteria and force logout based on condition.
     * Visa does not have mechanism to validate an active session from Runner to JO, so in order to get same session from Runner we have to clear old JO session by force logout with `LogoutReason.CLEAR`
     * and redirect user to Visa and Visa will handle the rest so that JO will receive same active session as other service(s)
     *
     * Below are the cases I found related to GSID from Runner services respectively.
     * - First both apps signed with the same session then do repeat login on Runner, now Runner has different session.
     * Redirect from JO list on Runner map. When it opened the JO, new session will compared to the old session and it should trigger the `incomingTokenIsNotEqual` and `hasExternalServiceRedirection`
     * store the url params to the local storage then logout by revoke the old session. Automatically visa will know that they have existing new session and will
     * redirect to JO. When the authentication process completed then proceeed the `pendingRedirection` so the user will be redirected to the desired page
     * - Both apps are signed with different session, open JO from
     * - Both apps are signed with the same session, so let they go to where ever they want
     * - Login on the Runner side and but JO is not yet logged in, then redirect to JO from runner.
     * This menu will open new window without additional parameters only GSID when it come to JO it will just logout. then visa will handle the rest
     */
    if (incomingTokenIsNotEqual && hasExternalServiceRedirection) {
      pendingRedirection.set({
        pathname: location.pathname,
        search: location.search ? removeGSIDInParam(location.search) : '',
      });

      handleLogout(LogoutReason.CLEAR);
      return;
    }

    if (incomingTokenIsNotEqual) {
      handleLogout(LogoutReason.CLEAR);
      return;
    }

    if (incomingTokenIsEqual) {
      navigate(
        location.pathname +
          (location.search ? removeGSIDInParam(location.search) : ''),
      );
      return;
    }

    if (hasExternalServiceRedirection) {
      pendingRedirection.set({
        pathname: location.pathname,
        search: location.search ? removeGSIDInParam(location.search) : '',
      });

      handleLogout(LogoutReason.CLEAR);
      return;
    }

    handleLogout(LogoutReason.CLEAR);
  }, [
    gsid,
    handleLogout,
    location.pathname,
    location.search,
    navigate,
    pendingRedirection,
    session.globalSessionID,
    verifyingGSID,
  ]);

  // #region HANDLE PUBLIC ROUTE
  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    if (routeCheck.isPublic) {
      setAuthLoading(false);
    }
  }, [routeCheck.isPublic, location.pathname]);

  // #endregion

  // #region ROUTE LISTENER
  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    if (authLoading) return;
    setAuthCompleted(false);
    (async () => {
      setVerifyingUser(true);
      await handleVerifyAuth(handleAuthenticationFailed);
    })();
  }, [location.pathname]);
  // #endregion

  return {
    handleErrorPageButtonClick,
    authLoading,
    errorPageTitleText,
    errorPageBodyText,
    errorPageActionText,
    authError,
    ...session,
  };
}

export default useSession;
