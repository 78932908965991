import React from 'react';
import tw, { styled } from 'twin.macro';
import { AutocompleteFormItem, TextFormItem } from '../../../types/input.type';
import { ErrorFormContainer, LabelInput, Row } from '../../atom';
import { AutoComplete, TextField } from '../../molecule';
import { ExtraStyle } from '../../Type.component';

interface Props {
  label?: string;
  isRequired?: boolean;
  primaryInput: TextFormItem;
  hasDivider?: boolean;
  secondaryInput: AutocompleteFormItem;
  wrapperStyle?: ExtraStyle;
}

const Container = styled.div((props: { hasDivider?: boolean }) => [
  tw`flex flex-col min-h-[56px]`,
  props.hasDivider && tw`border-b pb-2.5 border-beige-lines`,
]);
const InputContainer = tw.div`flex flex-1`;
const LeftInputContainer = tw.div`flex-1`;
const RightInputContainer = tw.div`w-[140px] ml-[-1px]`;
const ErrorContainer = tw.div`-mt-2.5`;

export default function ComboInput({
  label,
  wrapperStyle,
  isRequired,
  hasDivider,
  primaryInput,
  secondaryInput,
}: Props) {
  return (
    <Container hasDivider={hasDivider} css={wrapperStyle}>
      <Row>
        {label && <LabelInput label={label} isRequired={isRequired} />}
        <InputContainer>
          <LeftInputContainer>
            <TextField
              type="search"
              isNumber={primaryInput.isNumber}
              decimalScale={primaryInput.decimalScale}
              inputStyle={tw`py-3 focus:(z-index[1] border-b border-b-orange) border-top-right-radius[0px]`}
              disabled={primaryInput.disabled}
              placeholder={primaryInput.placeholder}
              id={primaryInput.id}
              left={primaryInput.left}
              right={primaryInput.right}
              name={primaryInput.id}
              maxLength={primaryInput.maxLength}
              value={primaryInput?.values || ''}
              error={
                primaryInput.error && !secondaryInput.error
                  ? primaryInput.error
                  : ''
              }
              onChange={(e) => primaryInput.onChange(e.target.value)}
              onBlur={(e) => {
                if (primaryInput.onBlur) primaryInput.onBlur(e.target.value);
              }}
            />
          </LeftInputContainer>
          <RightInputContainer>
            <AutoComplete
              inputStyle={tw`border-top-left-radius[0px]`}
              tw="bg-orange-dark-lines"
              selectedOption={secondaryInput.selectedOption}
              disabled={secondaryInput.disabled}
              id={secondaryInput.id}
              name={secondaryInput.id}
              value={secondaryInput.values}
              hideCloseButton={secondaryInput.hideCloseButton}
              hideIcon={secondaryInput.hideIcon}
              error={
                secondaryInput.error && !primaryInput.error
                  ? secondaryInput.error
                  : ''
              }
              datas={secondaryInput.options}
              hasExtraLabel={secondaryInput.hasExtraLabel}
              placeholder={secondaryInput.placeholder}
              loading={secondaryInput.loading}
              labelKey={secondaryInput.labelKey}
              changeData={secondaryInput.onChange}
              changeInputText={secondaryInput.onChangeAutoText}
              onFetchMore={secondaryInput.onFetchMore}
              onRemoveData={secondaryInput.onRemoveData}
              onBlurAutocomplete={secondaryInput.onBlur}
            />
          </RightInputContainer>
        </InputContainer>
      </Row>
      {primaryInput.error && secondaryInput.error && (
        <Row>
          <ErrorContainer>
            <ErrorFormContainer>{primaryInput.error}</ErrorFormContainer>
            <ErrorFormContainer>{secondaryInput.error}</ErrorFormContainer>
          </ErrorContainer>
        </Row>
      )}
    </Container>
  );
}
