import debounce from 'lodash/debounce';
import React, {
  createRef,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FixedSizeList as List, ListOnScrollProps } from 'react-window';
import tw, { theme, TwStyle } from 'twin.macro';
import { Icon, IconButton, LoadingIndicator } from '../component/atom';

export interface AutocompleteType {
  label: string;
  value: string;
  extraLabel?: string;
}

export interface AutocompleteHeight {
  list: number;
  item: number;
}

export interface AutocompleteProps extends React.ComponentProps<'input'> {
  placeholder?: string;
  error?: string;
  hideIcon?: boolean;
  hideCloseButton?: boolean;
  loading?: boolean;
  labelKey?: 'label' | 'extraLabel';
  alwaysOpen?: boolean;
  titleSearch?: boolean;
  datas?: AutocompleteType[];
  selectedOption?: AutocompleteType;
  hasExtraLabel?: boolean;
  inputStyle?: TwStyle;
  insideLabel?: string;
  onBlurAutocomplete?(e?: FocusEvent<HTMLInputElement>): void;
  onRemoveData?(): void;
  onFetchMore?(): void;
  changeInputText?(data?: string): void;
  changeData?(data?: AutocompleteType): void;
}

const normalListHeight = 264;
const normalItemHeight = 44;

const extraListHeight = 272;
const extraItemHeight = 68;

export default function useAutocomplete({
  value,
  hideIcon,
  loading,
  alwaysOpen,
  hideCloseButton,
  disabled,
  datas,
  selectedOption,
  hasExtraLabel,
  labelKey = 'label',
  onBlurAutocomplete = () => {},
  onRemoveData = () => {},
  onFetchMore = () => {},
  changeInputText = () => {},
  changeData = () => {},
}: AutocompleteProps) {
  // #region GENERAL
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [selectedAutocompleteOption, setSelectedAutocompleteOption] = useState<
    AutocompleteType | undefined
  >(selectedOption || undefined);

  const debounceFetchMore = debounce(onFetchMore, 1000, { maxWait: 3000 });

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    if (
      selectedOption &&
      selectedOption !== selectedAutocompleteOption &&
      !openDropdown
    ) {
      setSelectedAutocompleteOption(selectedOption);
    }
  }, [openDropdown, selectedAutocompleteOption, selectedOption, value]);

  const isOpenSearch = !!openSearch || !value || !!alwaysOpen;
  const autocompleteHeight: AutocompleteHeight = useMemo(() => {
    if (hasExtraLabel) {
      return { list: extraListHeight, item: extraItemHeight };
    }
    return { list: normalListHeight, item: normalItemHeight };
  }, [hasExtraLabel]);

  const itemHeight = autocompleteHeight.item;

  const listHeight = useMemo(() => {
    if (!datas) return normalItemHeight;
    return autocompleteHeight.item * datas.length > autocompleteHeight.list
      ? autocompleteHeight.list
      : autocompleteHeight.item * datas.length || autocompleteHeight.item;
  }, [autocompleteHeight, datas]);

  const inputRef = createRef<HTMLInputElement>();
  const listRef = useRef<List>(null);

  // #endregion

  const handleFocus = useCallback(() => {
    if (!disabled) {
      setOpenSearch(true);
    }
    setOpenDropdown(true);
  }, [disabled]);

  const handleClickTextField = useCallback(() => {
    if (!disabled) setOpenSearch(true);
  }, [disabled]);

  const handleSelect = useCallback(
    (option: AutocompleteType) => {
      setSearchValue(option[labelKey]);
      changeData(option);
      onBlurAutocomplete();
      setSelectedAutocompleteOption(option);
    },
    [changeData, labelKey, onBlurAutocomplete],
  );
  const handleClickAway = useCallback((): void => {
    if (!openDropdown) return;
    if (selectedAutocompleteOption && value) {
      setSearchValue(selectedAutocompleteOption[labelKey]);
    }
    setOpenDropdown(false);
    onBlurAutocomplete();
    setOpenSearch(false);
  }, [
    onBlurAutocomplete,
    labelKey,
    value,
    selectedAutocompleteOption,
    openDropdown,
  ]);

  const handleChangeTextField = useCallback(
    (data?: string) => {
      setSearchValue(data);
      changeInputText(data);
    },
    [changeInputText],
  );

  useEffect(() => {
    if (!selectedAutocompleteOption || !value) return;

    setSearchValue(selectedAutocompleteOption[labelKey]);
  }, [labelKey, selectedAutocompleteOption, value]);

  useEffect(() => {
    if (!value) {
      setSearchValue(undefined);
    }
  }, [value]);

  const renderIcon = useCallback(() => {
    if (loading) return <LoadingIndicator size="small" />;
    if (hideIcon || disabled) return undefined;
    if (!hideCloseButton && value)
      return (
        <IconButton
          tw="-mr-2"
          disabled={disabled}
          onClick={(e) => {
            if (!disabled) {
              e.stopPropagation();
              handleChangeTextField(undefined);
              setOpenDropdown(false);
              onBlurAutocomplete();
              onRemoveData();
              setSelectedAutocompleteOption(undefined);
            }
          }}
        >
          <Icon.Close tw="text-grey-two" />
        </IconButton>
      );
    return (
      <div
        tw="z-10 rotate-90 mr-[3px]"
        css={[!!openDropdown && tw`-rotate-90`]}
      >
        <Icon.ChevronRounded stroke={theme`colors.grey.two`} />
      </div>
    );
  }, [
    disabled,
    handleChangeTextField,
    hideIcon,
    hideCloseButton,
    loading,
    onBlurAutocomplete,
    onRemoveData,
    openDropdown,
    value,
  ]);

  const handleOnScrollList = useCallback(
    ({ scrollOffset, scrollDirection }: ListOnScrollProps) => {
      if (!datas?.length) return;
      const offset = itemHeight * (datas.length - 6) - 50;
      if (scrollOffset > offset && scrollDirection === 'forward' && !loading) {
        debounceFetchMore();
      }
    },
    [datas?.length, debounceFetchMore, itemHeight, loading],
  );
  return {
    openDropdown,
    searchValue,
    isOpenSearch,
    inputRef,
    listHeight,
    listRef,
    itemHeight,
    debounceFetchMore,
    setOpenDropdown,
    handleOnScrollList,
    handleChangeTextField,
    handleFocus,
    handleClickAway,
    handleClickTextField,
    handleSelect,
    renderIcon,
  };
}
