import { ReactNode, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { createWrapperAndAppendToBody } from '../../../util/helper.util';

// #region INTERFACES
type Props = {
  children: ReactNode;
  wrapperId?: string;
  wrapperClassName?: string;
};
// #endregion

function RootPortal({
  children,
  wrapperId = 'react-portal-wrapper',
  wrapperClassName,
}: Props) {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(
    null,
  );

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;

    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId, wrapperClassName);
    }

    setWrapperElement(element);

    return () => {
      // delete the programatically created element
      if (systemCreated && element && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperClassName, wrapperId]);

  // wrapperElement state will be null on the very first render.
  if (wrapperElement === null) {
    return null;
  }

  return createPortal(children, wrapperElement);
}

export default RootPortal;
