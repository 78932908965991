import React from 'react';
import tw, { styled } from 'twin.macro';
import { BulkActionStatusEnum } from '../../../constant';
import { Text } from '../../atom';

// #region INTERFACES
type StatusProps = { status: BulkActionStatusEnum };
type Props = {
  status: BulkActionStatusEnum;
  statusLabel: string;
};
// #endregion

// #region STYLED
const StatusContainer = styled.div(({ status }: StatusProps) => [
  tw`px-2 py-0.5 inline-block font-bold rounded-md animate-slide-in-left`,
  status === BulkActionStatusEnum.WAITING && tw`bg-grey-six`,
  status === BulkActionStatusEnum.SUCCEED && tw`bg-delivered-light`,
  status === BulkActionStatusEnum.FAILED && tw`bg-deleted-light`,
]);
const SquareIcon = styled.div(({ status }: StatusProps) => [
  tw`inline-block rounded-sm w-2.5 h-2.5 mr-2`,
  status === BulkActionStatusEnum.WAITING && tw`bg-grey-three`,
  status === BulkActionStatusEnum.SUCCEED && tw`bg-delivered-dark`,
  status === BulkActionStatusEnum.FAILED && tw`bg-deleted-dark`,
]);
// #endregion

function BulkActionStatus({ status, statusLabel }: Props) {
  return (
    <StatusContainer status={status}>
      <SquareIcon status={status} />

      <Text.BodyTwo
        css={[
          status === BulkActionStatusEnum.WAITING && tw`text-grey-three`,
          status === BulkActionStatusEnum.SUCCEED && tw`text-delivered-dark`,
          status === BulkActionStatusEnum.FAILED && tw`text-deleted-dark`,
        ]}
      >
        {statusLabel}
      </Text.BodyTwo>
    </StatusContainer>
  );
}

export default BulkActionStatus;
