import React from 'react';

export default function ShipperOrder({
  fill = 'currentColor',
  width = '20',
  height = '20',
  viewBox = '0 0 20 20',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M12 12v-2H4v2h8zM5 14v2h6v-2H5zM8 8c1.657 0 3-1.12 3-2.5S9.657 3 8 3 5 4.12 5 5.5 6.343 8 8 8z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M4 0c-.493 0-1.211.14-1.834.588C1.492 1.074 1 1.874 1 3v15.001C1 19.108 1.897 20 3 20h10a2 2 0 002-2v-7h2a2 2 0 002-2V3c0-.493-.14-1.211-.588-1.834C17.925.492 17.125 0 16 0H4zm13 3v6h-2V2.967c0-.165.059-.436.207-.639.111-.152.312-.328.793-.328.474 0 .674.175.788.334.152.21.212.493.212.666zM4 2h9.16c-.117.354-.16.694-.16.967V18H3V3c0-.474.175-.674.334-.788C3.544 2.06 3.827 2 4 2z"
        clipRule="evenodd"
      />
    </svg>
  );
}
