import { ReactNode } from 'react';
import { LatLng, Position } from '../../model/Map.model';
import useScreenDetection from '../useScreenDetection/useScreenDetection.hook';
import useTranslator, { UseTranslator } from '../useTranslator.hook';
import useMapAutocomplete, {
  UseMapAutocompleteHookObj,
} from './useMapAutocomplete.hook';
import useMapLoader, { UseMapLoaderHookObj } from './useMapLoader.hook';
import useMapTypeController, {
  UseMapTypeControllerHookObj,
} from './useMapTypeController.hook';

export interface MapProps {
  children?: ReactNode;
  disableDragMap?: boolean;
  disableOnclickTarget?: boolean;
  initMap?: boolean;
  mapsCenter?: LatLng;
  showAutocomplete?: boolean;
  showButtonCurrentLocation?: boolean;
  showMarkerCurrentLocation?: boolean;
  showTargetRadius?: boolean;
  targetLocations?: LatLng[]; // use for bounds
  targetCoord?: Partial<LatLng>; // use for geocode position
  zoomLevel?: number;
  zoomMaps?: boolean;
  radius?: number;
  toggleZoomMaps?(): void;
  onMapInit?(): void;
  onGetTargetAddress?(pos: Position): void;
  onGetCurrentLocation?(): void;
  onGetAutocompleteInputRef?(
    autocompleteinputRef: HTMLInputElement | null,
  ): void;
}

export interface UseMapHookObj {
  translator: UseTranslator;
  mapLoader: UseMapLoaderHookObj;
  mapAutocomplete: UseMapAutocompleteHookObj;
  mapTypeController: UseMapTypeControllerHookObj;
}

export default function useMap({
  initMap,
  targetCoord,
  disableOnclickTarget,
  zoomMaps,
  mapsCenter,
  targetLocations,
  radius,
  toggleZoomMaps = () => {},
  onMapInit,
  onGetTargetAddress,
  onGetAutocompleteInputRef,
  onGetCurrentLocation,
}: MapProps) {
  const translator = useTranslator();

  const { isMobile } = useScreenDetection();

  const mapLoader = useMapLoader({
    initMap,
    disableOnclickTarget,
    targetCoord,
    targetLocations,
    zoomMaps,
    mapsCenter,
    isMobile,
    radius,
    onMapInit,
    toggleZoomMaps,
    onGetTargetAddress,
    onGetCurrentLocation,
  });

  const mapAutocomplete = useMapAutocomplete({
    map: mapLoader.map,
    setTarget: mapLoader.setTarget,
    onGetAutocompleteInputRef,
    onGetTargetAddress,
  });

  const mapTypeController = useMapTypeController({
    map: mapLoader.map,
    isMobile,
  });

  return {
    translator,
    mapLoader,
    mapAutocomplete,
    mapTypeController,
    isMobile,
  };
}
