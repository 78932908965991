import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { JobOrderExpense } from '../../model/JobOrder.model';
import JobOrderDetailExpenseApproveView from './JobOrderDetailExpenseApprove.view';

export type JobOrderDetailExpenseApproveRouteState = {
  breadcrumb: string;
  expenses: JobOrderExpense[];
};

export const jobOrderDetailExpenseApproveRoute: RouteConfig = {
  name: 'Approve Expenses',
  Component: React.lazy(() => import('./JobOrderDetailExpenseApprove.view')),
  NormalComponent: <JobOrderDetailExpenseApproveView />,
  path: '/job-order/:id/approve-expenses',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
