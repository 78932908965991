import { HaulingOrderFilterOrderByEnum } from '../model/HaulingOrder.model';

export enum HOFilterStatusEnum {
  IN_PROCESS = 'IN_PROCESS',
  ASSIGNED = 'ASSIGNED',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
}

export type HOFilterOrderByValue = {
  label: string;
  value: HaulingOrderFilterOrderByEnum;
};
export type HOFilterStatusValue = {
  label: string;
  value: HOFilterStatusEnum;
};

export type HOFilterInitialValues = {
  orderBy: HaulingOrderFilterOrderByEnum;
  status: HOFilterStatusEnum[];
  shipperId?: string;
  startDate?: Date;
  endDate?: Date;
};
