import React, { ReactNode, Ref, useCallback, useRef } from 'react';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { RefHandles, SpringEvent } from 'react-spring-bottom-sheet/dist/types';
import tw, { css, styled } from 'twin.macro';
import { MobileTwelve } from '../../atom/Text/Text.atom';

export type MobileBottomBarBottomSheetProps = {
  title: string;
  icon: ReactNode;
  handleClick: () => void;
};

type Props = {
  isVisible?: boolean;
  menuList: MobileBottomBarBottomSheetProps[];
  handleDismiss: () => void;
};

const NavigationText = styled(MobileTwelve)(
  ({ isActive }: { isActive?: boolean }) => [
    tw`font-bold letter-spacing[-0.4px] text-center`,
    isActive && tw`text-orange`,
  ],
);
const ButtonNav = tw.div`flex flex-col items-center justify-center gap-2 w-[70px]`;
const IconContainer = tw.div` p-3.5 rounded-xl bg-white shadow mt-1`;
const Grid = tw.div`px-2 grid grid-template-columns[repeat(4,1fr)] gap-2 items-start justify-items-center `;
const BottomTabNav = styled(BottomSheet)(() => [
  css`
    [data-rsbs-overlay] {
      ${tw`bg-beige-bg`}
    }
    [data-rsbs-header]::before {
      ${tw`bg-beige-lines w-[68px]`}
    }
  `,
]);

const sheetSnapPoints = [130, 0];

function MenuItem({
  icon,
  title,
  handleClick,
}: MobileBottomBarBottomSheetProps) {
  return (
    <ButtonNav onClick={handleClick}>
      <IconContainer>{icon}</IconContainer>
      <NavigationText>{title}</NavigationText>
    </ButtonNav>
  );
}

export default function MobileBottomBarBottomSheet({
  isVisible,
  menuList,
  handleDismiss,
}: Props) {
  const bottomSheetRef = useRef<BottomSheetRef>();
  const handleDismissBottomSheet = useCallback(
    (e: SpringEvent) => {
      requestAnimationFrame(() => {
        if (e.type === 'OPEN') return;
        handleDismiss();
      });
    },
    [handleDismiss],
  );

  return (
    <BottomTabNav
      ref={bottomSheetRef as unknown as Ref<RefHandles>}
      open={!!isVisible}
      scrollLocking
      onDismiss={handleDismiss}
      onSpringStart={handleDismissBottomSheet}
      defaultSnap={sheetSnapPoints[0]}
      snapPoints={() => sheetSnapPoints}
    >
      <Grid>
        {menuList.map((item) => (
          <MenuItem
            key={item.title}
            icon={item.icon}
            title={item.title}
            handleClick={() => {
              item.handleClick?.();
              handleDismiss();
            }}
          />
        ))}
      </Grid>
    </BottomTabNav>
  );
}
