import React, { useCallback, useEffect, useState } from 'react';
import tw from 'twin.macro';
import { UseJOFormDeliveryLocationModalHookObj } from '../../../hook/jobOrderForm/useJOFormDeliveryLocationModal.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { Button, Text } from '../../atom';
import { Modal } from '../../molecule';

// #region INTERFACES
type Props = {
  locationModalController: UseJOFormDeliveryLocationModalHookObj;
};
// #endregion

export default function JOFormSOSelectionLocationErrorModal({
  locationModalController: { deliveryLocationSameAsCurrentLocationList },
}: Props) {
  // NOTE: moving the state and effect closer to the component to avoid unnecessary rerender
  const { translate } = useTranslator();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  // sync `showModal` with `deliveryLocationSameAsCurrentLocationList`
  useEffect(() => {
    setShowModal(!!deliveryLocationSameAsCurrentLocationList.length);
  }, [deliveryLocationSameAsCurrentLocationList.length]);

  if (!showModal) return null;

  return (
    <Modal.Regular
      css={tw`p-[30px] w-[760px]`}
      onOverlayClick={handleCloseModal}
    >
      <section tw="flex flex-col">
        <Text.HeadingThree tw="mb-2.5 font-semibold">
          {deliveryLocationSameAsCurrentLocationList.length}{' '}
          {translate("Delivery Location can't be updated")}
        </Text.HeadingThree>

        <Text.BodySixteen tw="mb-5">
          {translate(
            'The Delivery Location must be different than Current Location. Please choose another Delivery Location.',
          )}
        </Text.BodySixteen>

        <Button.Solid tw="w-[fit-content] self-end" onClick={handleCloseModal}>
          {translate('Close')}
        </Button.Solid>
      </section>
    </Modal.Regular>
  );
}
