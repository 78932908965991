import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { SOActivityFormProps } from '../../model/ShipperOrder.model';
import { ShipperOrderCreateNavigateParams } from '../ShipperOrderCreate/ShipperOrderCreate.route';

export type SODeliveryLocationAddRouteParam =
  | (ShipperOrderCreateNavigateParams & {
      activityIndex?: number;
      soActivityState?: SOActivityFormProps;
    })
  | undefined;

export const soDeliveryLocationAddRoute: RouteConfig = {
  name: 'Add New Location',
  Component: React.lazy(() => import('./SODeliveryLocationAdd.view')),
  path: '/shipper-order/create/add-new-location',
  isPrivate: true,
  breadcrumb: 'Add New Location',
};
