import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { Text } from '../../../component/atom';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../../component/molecule';
import { BulkActionEntity, BulkActionStatusEnum } from '../../../constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { DrivingContest } from '../../../model/DrivingContest.model';
import { mapBulkActionStatusToLabel } from '../../../util/helper.util';
import { UseDrivingContestRemoveLogicHook } from './useDrivingContestRemoveLogic.hook';

// #region INTERFACES
export type RemoveDrivingContestEntity = BulkActionEntity<DrivingContest>;
export type UseDrivingContestRemoveConfirmationTableParams = {
  translator: UseTranslator;
  logic: UseDrivingContestRemoveLogicHook;
};
export type UseDrivingContestRemoveConfirmationTableHook = ReturnType<
  typeof useDrivingContestRemoveConfirmationTable
>;
// #endregion

export default function useDrivingContestRemoveConfirmationTable({
  translator,
  logic,
}: UseDrivingContestRemoveConfirmationTableParams) {
  const columns = useMemo<ColumnDef<RemoveDrivingContestEntity>[]>(
    () => [
      {
        size: 200,
        header: translator.translate('Full Name'),
        accessorKey: 'fullName',
        cell: ({ getValue }) => (
          <CustomTableRowWithPopover
            primaryLabel={getValue() as string}
            primaryLabelStyle={tw`font-semibold text-grey-two`}
            containerStyle={tw`max-width[240px]`}
          />
        ),
      },
      {
        size: 150,
        header: translator.translate('Phone Number'),
        accessorKey: 'phoneNumber',
        cell: ({ getValue }) => (
          <Text.TableParagraph tw="leading-5">{getValue()}</Text.TableParagraph>
        ),
      },
      ...(logic.showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({ getValue }: { getValue: () => string }) => (
                <Text.Paragraph tw="truncate animate-slide-in-left">
                  {translator.translate(getValue())}
                </Text.Paragraph>
              ),
            },
          ]
        : []),
    ],
    [logic.showStatus, translator],
  );

  const table = useReactTable({
    columns,
    data: logic.drivingContestsWithStatusDetail,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
