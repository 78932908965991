// #region IMPORT
import urlcat from 'urlcat';
import envConfig from '../../../config/env/env.config';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import { Builder, MD, QD } from '../../api.baseQuery';
import { ApiResponse } from '../../api.endpoint';
// #endregion

// #region INTERFACES

type Credential = {
  data: {
    response: number;
    error: string;
    appid: string;
    svcid: string;
    controller: string;
    action: string;
    result: {
      isactive: number;
      isorgadmin: number;
      userId: string;
      username: string;
      organizationId: string;
      email: string;
      name: string;
      userAccess: string[];
      token: string;
      realmId: string;
    };
  };
  uid: string;
  globalSessionID: string;
};

export type VerifyTokenParam = { ssoToken: string };
export type VerifyTokenResponse = {
  response: Credential;
};
export type CheckAuthParam = { ssoToken: string };
export type CheckAuthResponse = Credential & {
  auth: string;
};

export type RefreshTokenParam = { ssoToken: string; runnerToken: string };
export type RefreshTokenResponse = Credential & {
  auth: string;
};

export type AuthTestParam = { token: string };
export type AuthTestResponse = ApiResponse;

export type AuthEndpoint = {
  authRevoke: MD<void, void>;
  verifyToken: QD<VerifyTokenParam, VerifyTokenResponse>;
  checkAuth: QD<CheckAuthParam, CheckAuthResponse>;
  refreshToken: MD<RefreshTokenParam, RefreshTokenResponse>;
  authTest: MD<AuthTestParam, AuthTestResponse>;
};
// #endregion

// #region ENDPOINT
export const authTagTypes: EndpointTagTypes<AuthEndpoint> = {
  authRevoke: '',
  authTest: '',
  checkAuth: '',
  verifyToken: '',
  refreshToken: '',
};

export const authEndpoint = (builder: Builder): AuthEndpoint => ({
  verifyToken: builder.query({
    query: ({ ssoToken }) => ({
      url: urlcat(`${envConfig.ktbVisaUrl}/verifytoken`, { ssoToken }),
    }),
  }),
  checkAuth: builder.query({
    query: ({ ssoToken }) => ({
      url: urlcat(`${envConfig.ktbVisaUrl}/checkAuth`, { ssoToken }),
    }),
  }),
  refreshToken: builder.mutation({
    query: (body) => ({
      url: `${envConfig.ktbVisaUrl}/refresh_token`,
      method: 'post',
      body,
    }),
  }),
  authTest: builder.mutation({
    query: ({ token }) => {
      return {
        url: '/v1/auth.test?',
        method: 'post',
        headers: { Authorization: `Bearer ${token}` },
      };
    },
  }),
  authRevoke: builder.mutation({
    query: () => ({ url: '/v1/auth.revoke', method: 'post' }),
  }),
});
// #endregion
