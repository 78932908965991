import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';
import DrivingContestRemoveView from './DrivingContestRemove.view';

export type DrivingContestRemoveRouteParam = DefaultRouteParam;

export const drivingContestRemoveRoute: RouteConfig = {
  name: 'Remove Driver as Participant',
  Component: React.lazy(() => import('./DrivingContestRemove.view')),
  NormalComponent: <DrivingContestRemoveView />,
  path: '/driver-contest/remove',
  isPrivate: true,
  props: {
    isUnclickable: true,
  },
};
