import React from 'react';
import { useToggle } from 'react-use';
import { Navigation, Zoom } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/zoom';
import { Swiper, SwiperSlide } from 'swiper/react';
import tw, { styled, theme } from 'twin.macro';
import { SelectedActivityId } from '../../../hook/jobOrderDetail/useJODetailSheet.hook';
import { JOActivityInfo } from '../../../model/JobOrder.model';
import { Icon, Text } from '../../atom';

// #region STYLED
const Container = styled.div`
  ${tw`relative h-full flex flex-col items-center justify-center bg-black`}

  > .mySwiper {
    --swiper-navigation-color: white;
    --swiper-navigation-size: 2rem;
    --swiper-navigation-sides-offset: 1rem;
  }
`;
// #endregion

// #region INTERFACES
type Props = {
  selectedActivityId: SelectedActivityId;
  renderedSelectedSOActivity: JOActivityInfo;
  handleChangePhoto: (idx: number) => void;
};
// #endregion

export default function JOSODetailMobileActivityPOD({
  selectedActivityId,
  renderedSelectedSOActivity,
  handleChangePhoto,
}: Props) {
  const [isNoteOpened, toggleNote] = useToggle(false);

  return (
    <Container data-testid="JOSODetailMobileActivityPOD:Container">
      <Swiper
        className="mySwiper"
        zoom
        navigation
        modules={[Zoom, Navigation]}
        onSlideChange={(swiper) => handleChangePhoto(swiper.activeIndex)}
        onAfterInit={(swiper) => swiper.slideTo(selectedActivityId.idx)}
      >
        {renderedSelectedSOActivity.photos.map((item) => (
          <SwiperSlide key={`${item.photo.id}-${item.photoId || ''}`}>
            <div className="swiper-zoom-container">
              <img
                loading="lazy"
                src={item.photo.accessUrl}
                alt={item.photo.fileName}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {!!renderedSelectedSOActivity.poaNotes && (
        <div tw="absolute bottom-0 w-full z-10 animate-slide-in-bottom">
          <div tw="w-full flex justify-end pb-4 pr-4">
            <button
              type="button"
              tw="bg-white p-1 rounded-full"
              onClick={() => toggleNote()}
            >
              {isNoteOpened ? (
                <Icon.InfoOutlined
                  fill={theme`colors.orange.DEFAULT`}
                  width={20}
                  height={20}
                />
              ) : (
                <Icon.Close fill={theme`colors.white`} width={16} height={16} />
              )}
            </button>
          </div>

          {!isNoteOpened && (
            <div tw="bg-white p-4">
              <Text.BodySixteen>
                {renderedSelectedSOActivity.poaNotes}
              </Text.BodySixteen>
            </div>
          )}
        </div>
      )}
    </Container>
  );
}
