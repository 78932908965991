import endOfMonth from 'date-fns/endOfMonth';
import endOfYear from 'date-fns/endOfYear';
import startOfMonth from 'date-fns/startOfMonth';
import startOfToday from 'date-fns/startOfToday';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';
import startOfYesterday from 'date-fns/startOfYesterday';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';
import subYears from 'date-fns/subYears';

export const startofyesterday = startOfYesterday();
export const startoftoday = startOfToday();

export const thisWeekOnSunday = startOfWeek(startoftoday, { weekStartsOn: 0 }); // week starts on Sunday
export const thisWeek = startOfWeek(startoftoday, { weekStartsOn: 1 }); // week starts on Monday
export const thisMonth = startOfMonth(startoftoday);
export const thisYear = startOfYear(startoftoday);

export const startOfLastWeek = subWeeks(thisWeek, 1);
export const startOfLastMonth = startOfMonth(subMonths(startoftoday, 1));
export const startOfLastYear = startOfYear(subYears(startoftoday, 1));
export const endOfLastWeek = subDays(thisWeek, 1); // the same as `thisWeekOnSunday`
export const endOfLastMonth = endOfMonth(subMonths(startoftoday, 1));
export const endOfLastYear = endOfYear(subYears(startoftoday, 1));

export const lastSevenDays = subDays(startoftoday, 6);
export const lastFourteenDays = subDays(startoftoday, 13);
export const lastNinetyDays = subDays(startoftoday, 89);
export const lastOneWeekDate = subWeeks(startoftoday, 1);
export const lastTwoWeeksDate = subWeeks(startoftoday, 2);
export const lastOneMonthDate = subMonths(startoftoday, 1);
export const lastThreeMonthsDate = subMonths(startoftoday, 3);
export const lastSixMonthsDate = subMonths(startoftoday, 6);
export const lastOneYearDate = subYears(startoftoday, 1);
