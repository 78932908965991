import React from 'react';
import { Icon, TableNoData } from '../../component/atom';
import { BulkActionLayout } from '../../component/template';
import useShipperOrderDeleteBulkViewModel from './ShipperOrderDeleteBulk.viewModel';

export default function ShipperOrderDeleteBulkView() {
  const { translator, bulkLogic, bulkTable } =
    useShipperOrderDeleteBulkViewModel();

  return (
    <BulkActionLayout
      actionLabel={translator.translate('to start the deletion process.')}
      descriptionLabel={translator.translate(
        'Make sure the list below is the shipper order list that you want to delete from your organization. Deleted Shipper Order still can be accessed in Shipper Order List page.',
      )}
      cancelLabel={translator.translate('Cancel')}
      submitLabel={translator.translate('Delete')}
      doneLabel={translator.translate('Done')}
      table={bulkTable.table}
      entityLength={bulkLogic.shipperOrderWithStatusDetail.length}
      showDoneBtn={bulkLogic.showDoneBtn}
      isLoading={bulkLogic.mutationsIsLoading}
      noDataPage={
        <TableNoData
          icon={<Icon.NoDataFound />}
          titleLabel={translator.translate(
            "You don't have any Shipper Order that you want to delete",
          )}
          descriptionLabel={translator.translate(
            'Try to select some Shippers from Shipper page',
          )}
          actionLabel={translator.translate('Go back to Shipper Order page')}
          onClickAction={bulkLogic.handleDone}
        />
      }
      onCancel={bulkLogic.handleDone}
      onSubmit={bulkLogic.handleDeleteBulk}
      onDone={bulkLogic.handleDone}
    />
  );
}
