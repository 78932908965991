import React from 'react';
import tw from 'twin.macro';
import { rdbByDriverDateRangeInitialValues } from '../../../constant/ReportDrivingBehaviorByDriver.constant';
import { UseGetDriversAutocompleteObj } from '../../../hook/useGetDriversAutocomplete.hook';
import { UseRDBByJOFilterObj } from '../../../view/Report/DrivingBehavior/hook/JobOrder/useRDBByJOFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region STYLED
const Container = tw.div`w-full h-full max-h-screen bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const InputHeading = tw(Text.HeadingFive)`mb-2`;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChipContainer = tw.div`mb-2 mr-2`;
const FilterChip = tw(Chip.Small)`text-grey-two`;
const DatePickerContainer = tw.div`mt-3 flex items-center justify-between space-x-2`;
const TitleLabel = tw(Text.HeadingFour)`flex-1 ml-2`;
const ResetButton = tw(Button.Outlined)`px-4 bg-white text-grey-two`;
const DriverAutocomplete = tw(AutoComplete)`bg-orange-dark-lines`;
// #endregion

// #region INTERFACES
type Props = {
  rdbDriverFilter: UseRDBByJOFilterObj;
  driversAutocomplete: UseGetDriversAutocompleteObj;
};
// #endregion

function ReportDrivingBehaviorByDriverFilter({
  rdbDriverFilter,
  driversAutocomplete,
}: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <TitleLabel>{rdbDriverFilter.titleLabel}</TitleLabel>

        <ResetButton small onClick={rdbDriverFilter.onClickReset}>
          {rdbDriverFilter.resetButtonLabel}
        </ResetButton>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading
            data-testid={`${rdbDriverFilter.testID || ''}Filter:OrderByLabel`}
          >
            {rdbDriverFilter.orderByLabel}
          </InputHeading>

          <ChipContainer>
            {rdbDriverFilter.filterOrderByValues.map((el, i) => {
              const selected = rdbDriverFilter.form.values.orderBy === el.value;

              return (
                <FilterChipContainer key={`Filter_Category_${el.value}`}>
                  <FilterChip
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() => rdbDriverFilter.onClickOrderByChip(el.value)}
                    testID={`${
                      rdbDriverFilter.testID || ''
                    }Filter:OrderByOption:${i}`}
                  />
                </FilterChipContainer>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading>{rdbDriverFilter.driverLabel}</InputHeading>

          <DriverAutocomplete
            value={rdbDriverFilter.form.values.driverId}
            datas={driversAutocomplete.driverOptions}
            selectedOption={rdbDriverFilter.queryDriverOption}
            placeholder={rdbDriverFilter.driverPlaceholder}
            loading={driversAutocomplete.driverListFetchLoading}
            changeData={rdbDriverFilter.onChangeDriver}
            changeInputText={driversAutocomplete.onChangeDriverAutotext}
            onFetchMore={driversAutocomplete.onFetchMoreDriver}
            onRemoveData={rdbDriverFilter.onRemoveDriver}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${rdbDriverFilter.testID || ''}Filter:DateRangeLabel`}
          >
            {rdbDriverFilter.dateLabel}
          </InputHeading>

          <DatePickerContainer>
            <DateRangePickerInput
              sdLabel={rdbDriverFilter.sdLabel}
              edLabel={rdbDriverFilter.edLabel}
              sdDateValue={rdbDriverFilter.form.values.startDate}
              sdOnChangeDateValue={rdbDriverFilter.onChangeStartDate}
              edDateValue={rdbDriverFilter.form.values.endDate}
              edOnChangeDateValue={rdbDriverFilter.onChangeEndDate}
              onClickApplyDateRange={rdbDriverFilter.onClickApplyDateRange}
              maxDateRangeInDays={31}
              outsideAlerterStyle={{ width: '100%' }}
              definedRangeOptions={rdbDriverFilter.dateRangeDefinedRangeOptions}
              disabledDays={rdbDriverFilter.dateRangeDisabledDays}
              notes={rdbDriverFilter.dateNoteLabel}
              defaultDateRange={rdbByDriverDateRangeInitialValues}
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={rdbDriverFilter.filterLocalIsFetching}
          onClick={rdbDriverFilter.onClickSubmit}
        >
          {rdbDriverFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default ReportDrivingBehaviorByDriverFilter;
