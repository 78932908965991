import useDriverUploadModal from '../../hook/driver/useDriverUploadModal.hook';
import useTranslator from '../../hook/useTranslator.hook';
import useDriverAddBulkLogic from './hooks/useDriverAddBulkLogic.hook';
import useDriverAddBulkTable from './hooks/useDriverAddBulkTable.hook';

export default function useDriverAddBulkViewModel() {
  const translator = useTranslator();

  const uploadModal = useDriverUploadModal();
  const bulkLogic = useDriverAddBulkLogic();
  const bulkTable = useDriverAddBulkTable({ bulkLogic });

  return {
    translator,
    uploadModal,
    bulkLogic,
    bulkTable,
  };
}
