import { Locale } from './locale.config';

const viewJOLocaleConfig: Locale[] = [
  {
    en: 'Vehicle',
    id: 'Kendaraan',
  },
  {
    en: 'Seal Number',
    id: 'Nomor Segel',
  },
  {
    en: 'Last Mile/Transit',
    id: 'Last Mile/Transit',
  },
  {
    en: 'Internal/External',
    id: 'Internal/Eksternal',
  },
  {
    en: 'External',
    id: 'Eksternal',
  },
  {
    en: 'Vehicle',
    id: 'Kendaraan',
  },
  {
    en: 'Shipper Order List',
    id: 'Daftar Shipper Order',
  },
  {
    en: 'Pickup Location',
    id: 'Lokasi Pickup',
  },
  {
    en: 'Activity',
    id: 'Aktivitas',
  },
  {
    en: 'Expected at:',
    id: 'Perkiraan Waktu Tiba:',
  },
  {
    en: 'Goods',
    id: 'Barang',
  },
  {
    en: 'Started the activity',
    id: 'Perjalanan dimulai',
  },
  {
    en: 'Finished the activity',
    id: 'Perjalanan selesai',
  },
  {
    en: 'Real Time Maps',
    id: 'Peta Real-time',
  },
  {
    en: 'Reset Settings',
    id: 'Reset Pengaturan',
  },
  {
    en: 'You currently hide both the Truck and the Driver.',
    id: 'Saat ini anda menyembunyikan Truk dan Sopir',
  },
  {
    en: 'Departed from',
    id: 'Berangkat dari',
  },
  {
    en: 'Arrived at',
    id: 'Tiba di',
  },
  {
    en: 'See more...',
    id: 'Lihat lebih banyak',
  },
  {
    en: 'See less...',
    id: 'Lihat lebih sedikit',
  },
  {
    en: 'Failed to complete %s',
    id: 'Gagal menyelesaikan %s',
  },
  {
    en: 'Job Order with “Draft” status is limited to view only mode in mobile device. Go to desktop version to edit this Job Order.',
    id: 'Perintah Kerja dengan status "Draf" tidak dapat diubah pada mode seluler, Buka versi dekstop untuk mengubah Perintah Kerja ini.',
  },
];

export default viewJOLocaleConfig;
