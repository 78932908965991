import React from 'react';
import { Icon } from '../../component/atom';
import { RouteConfig } from '../../config/router/router.config';
import { SOActivitySelectionType } from '../../constant';
import { JobOrderForm } from '../../model/JobOrder.model';
import {
  ShipperOrderFormValues,
  ShipperOrderSearchParam,
} from '../../model/ShipperOrder.model';

export type ShipperOrderCreateNavigateParams =
  | {
      originPath: string;
      formStep?: number;
      soState?: ShipperOrderFormValues;
      joState?: JobOrderForm;
      showActivitySelectionMethod?: boolean;
      activityTypeSelected?: SOActivitySelectionType;
      searchParamsFromSOList?: ShipperOrderSearchParam;
    }
  | undefined;

export const createShipperOrderRoute: RouteConfig = {
  name: 'Create Shipper Order',
  path: '/shipper-order/create',
  Component: React.lazy(() => import('./ShipperOrderCreate.view')),
  isPrivate: true,
  breadcrumb: 'Create Shipper Order',
  options: {
    icon: <Icon.ShipperOrder />,
    label: 'Create Shipper Order',
    testID: 'MenuCreateShipperOrder',
  },
};
