import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import tw from 'twin.macro';
import { RootState } from '../../../app/store/store.app';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import { RouteConfig } from '../../../config/router/router.config';
import useTranslator from '../../../hook/useTranslator.hook';
import MobileHelpCenterSidebarItem from '../../molecule/MobileHelpCenterSidebarItem/MobileHelpCenterSidebarItem.molecule';

type Props = {
  items: RouteConfig[];
  currentPath: string;
  onClickItem: () => void;
};

const Container = tw.div`py-2 pr-1 z-20`;

export default function MobileHelpCenterSidebar({
  items,
  currentPath,
  onClickItem,
}: Props) {
  const translator = useTranslator();
  const { isLoggedIn } = useSelector(
    (state: RootState) => state.session,
    shallowEqual,
  );
  const forceLang = !isLoggedIn ? SupportedLanguage.id : undefined;

  const generateSubMenu = useCallback(
    (subItems: RouteConfig[]) =>
      subItems.map((item: RouteConfig) => ({
        path: item.path,
        icon: item.options?.icon,
        label: translator.translate(item.options?.label || '', forceLang),
        testID: item.options?.testID,
        isSelected: currentPath.includes(item.path),
        hasDivider: item.options?.hasDivider,
      })),
    [translator, forceLang, currentPath],
  );

  const checkIsSelected = (item: RouteConfig) => {
    if ((!!item.customNavigation && !item.path) || !item.path) return false;
    const currentPathArr = currentPath.split('/');
    const itemPathArr = item.path.split('/');

    return item.activePath
      ? item.activePath?.includes(currentPath)
      : currentPathArr[2] === itemPathArr[2];
  };

  return (
    <Container>
      {items.map((item: RouteConfig) => {
        if (!item.options) return null;

        return (
          <MobileHelpCenterSidebarItem
            key={`SidebarItem-${item.options.testID}`}
            path={item.path}
            label={translator.translate(item.options.label, forceLang)}
            icon={item.options.icon}
            testID={item.options.testID}
            event={item.options.event}
            hasSub={
              item.options?.hasSub && generateSubMenu(item.options.hasSub)
            }
            isSelected={checkIsSelected(item)}
            expandedSidebar
            onClickItem={onClickItem}
          />
        );
      })}
    </Container>
  );
}
