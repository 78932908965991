import React from 'react';
import tw from 'twin.macro';
import { FormItem } from '../../../types/input.type';
import {
  AutocompleteInput,
  CheckboxInput,
  ChoiceInput,
  DateInput,
  SelectInput,
  TextAreaInput,
  TextInput,
} from '../../molecule';
import AutocompleteVehicleInput from '../../molecule/AutocompleteVehicleInput/AutocompleteVehicleInput.molecule';
import DateRangeFormInput from '../../molecule/DateRangeFormInput/DateRangeFormInput.molecule';
import SwitchInput from '../../molecule/SwitchInput/SwitchInput.molecule';
import ComboInput from '../ComboInput/ComboInput.organism';
import RunnerMap from '../Map/Map.organism';

type Props = FormItem;

const MapContainer = tw.div`my-3.5 h-[500px] bg-assigned-light`;
export default function ComposedFormField(props: Props) {
  const {
    type,
    isRequired,
    label,
    error,
    disabled,
    id,
    placeholder,
    hasDivider,
    children,
  } = props;
  switch (type) {
    case 'combo': {
      const { primaryInput, secondaryInput } = props;
      return (
        <ComboInput
          label={label}
          isRequired={isRequired}
          primaryInput={primaryInput}
          secondaryInput={secondaryInput}
          hasDivider={hasDivider}
        />
      );
    }
    case 'choice': {
      const {
        choiceItems,
        hasCustomOption,
        containerStyle,
        labelStyle,
        description,
        wrapperStyle,
        customInput,
        isFormPage,
        isValueCustom,
      } = props;
      return (
        <ChoiceInput
          isValueCustom={isValueCustom}
          isFormPage={isFormPage}
          hasCustomOption={hasCustomOption}
          customInput={customInput}
          description={description}
          isRequired={isRequired}
          label={label}
          labelStyle={labelStyle}
          error={error}
          choiceItems={choiceItems}
          wrapperStyle={wrapperStyle}
          containerStyle={containerStyle}
        >
          {children}
        </ChoiceInput>
      );
    }
    case 'switch': {
      const {
        containerStyle,
        wrapperStyle,
        labelStyle,
        descriptionLabelStyle,
        tooltipText,
        values,
        description,
        onChange,
      } = props;
      return (
        <SwitchInput
          description={description}
          isRequired={isRequired}
          label={label}
          disabled={disabled}
          error={error}
          onChange={onChange}
          tooltipText={tooltipText}
          values={values}
          labelStyle={labelStyle}
          descriptionLabelStyle={descriptionLabelStyle}
          wrapperStyle={wrapperStyle}
          containerStyle={containerStyle}
        />
      );
    }
    case 'select': {
      const { options, values, onChange } = props;
      return (
        <SelectInput
          disabled={disabled}
          placeholder={placeholder}
          error={error}
          isRequired={isRequired}
          label={label}
          options={options}
          id={id}
          values={values}
          onChange={onChange}
        />
      );
    }
    case 'date': {
      const {
        disableTimePicker,
        disabledDays,
        onChange,
        values,
        hideIcon,
        rangeYear,
        isTimepickerValidationDisabled,
      } = props;
      return (
        <DateInput
          disabled={disabled}
          placeholder={placeholder}
          disableTimePicker={disableTimePicker}
          isTimepickerValidationDisabled={isTimepickerValidationDisabled}
          disabledDays={disabledDays}
          isRequired={isRequired}
          label={label}
          id={id}
          hideIcon={hideIcon}
          error={error}
          values={values}
          onChange={onChange}
          rangeYear={rangeYear}
        />
      );
    }
    case 'text-area': {
      const { values, onChange, onBlur, autoSize } = props;
      return (
        <TextAreaInput
          placeholder={placeholder}
          error={error}
          isRequired={isRequired}
          label={label}
          id={id}
          onBlur={onBlur}
          values={values}
          onChange={onChange}
          autoSize={autoSize}
          disabled={disabled}
        />
      );
    }
    case 'map': {
      const {
        initMap,
        zoomMaps,
        targetCoord,
        showTargetRadius,
        radius,
        toggleZoomMaps,
        onMapInit,
        onChange,
        handleGetAutocompleteInputRef,
      } = props;
      return (
        <MapContainer>
          <RunnerMap
            zoomMaps={zoomMaps}
            radius={radius}
            showTargetRadius={showTargetRadius}
            showButtonCurrentLocation
            initMap={initMap}
            toggleZoomMaps={toggleZoomMaps}
            onMapInit={onMapInit}
            onGetTargetAddress={onChange}
            showAutocomplete
            targetCoord={targetCoord}
            onGetAutocompleteInputRef={handleGetAutocompleteInputRef}
          />
        </MapContainer>
      );
    }
    case 'autocomplete': {
      const {
        values,
        options,
        loading,
        selectedOption,
        hasExtraLabel,
        insideLabel,
        onChange,
        onChangeAutoText,
        onFetchMore,
        onRemoveData,
        onBlur,
        tooltipText,
        labelKey,
      } = props;
      return (
        <AutocompleteInput
          insideLabel={insideLabel}
          hasExtraLabel={hasExtraLabel}
          isRequired={isRequired}
          disabled={disabled}
          id={id}
          hasDivider={hasDivider}
          values={values}
          error={error}
          options={options}
          placeholder={placeholder}
          label={label}
          labelKey={labelKey}
          loading={loading}
          selectedOption={selectedOption}
          onSelect={onChange}
          onChange={onChangeAutoText}
          onFetchMore={onFetchMore}
          onRemoveData={onRemoveData}
          onBlur={onBlur}
          tooltipText={tooltipText}
        >
          {children}
        </AutocompleteInput>
      );
    }
    case 'autocomplete-vehicle': {
      const {
        values,
        options,
        loading,
        selectedOption,
        insideLabel,
        refetch,
        onChange,
        onChangeAutoText,
        onFetchMore,
        onRemoveData,
        onBlur,
        tooltipText,
      } = props;
      return (
        <AutocompleteVehicleInput
          insideLabel={insideLabel}
          isRequired={isRequired}
          disabled={disabled}
          hasDivider={hasDivider}
          values={values}
          error={error}
          options={options}
          placeholder={placeholder}
          label={label}
          loading={loading}
          selectedOption={selectedOption}
          refetch={refetch}
          onSelect={onChange}
          onChange={onChangeAutoText}
          onFetchMore={onFetchMore}
          onRemoveData={onRemoveData}
          onBlur={onBlur}
          tooltipText={tooltipText}
        >
          {children}
        </AutocompleteVehicleInput>
      );
    }
    case 'date-range': {
      const {
        startValues,
        startId,
        startName,
        startLabel,
        startDisabled,
        startError,
        endValues,
        endId,
        endName,
        endLabel,
        endDisabled,
        endError,
        definedRangeOptions,
        maxDateRangeInDays,
        rangeYear,
        disabledDays,
        outsideAlerterStyle,
        popoverPlacement,
        textFieldInputBgBeige,
        notes,
        disableDefinedRange,
        onChangeStart,
        onChangeEnd,
        onClickApplyDateRange,
      } = props;

      return (
        <DateRangeFormInput
          label={label}
          startValues={startValues}
          startId={startId}
          startName={startName}
          startLabel={startLabel}
          startDisabled={startDisabled}
          startError={startError}
          onChangeStart={onChangeStart}
          endValues={endValues}
          endId={endId}
          endName={endName}
          endLabel={endLabel}
          endDisabled={endDisabled}
          endError={endError}
          onChangeEnd={onChangeEnd}
          onClickApplyDateRange={onClickApplyDateRange}
          definedRangeOptions={definedRangeOptions}
          maxDateRangeInDays={maxDateRangeInDays}
          rangeYear={rangeYear}
          disabledDays={disabledDays}
          outsideAlerterStyle={outsideAlerterStyle}
          popoverPlacement={popoverPlacement}
          textFieldInputBgBeige={textFieldInputBgBeige}
          notes={notes}
          disableDefinedRange={disableDefinedRange}
        />
      );
    }
    case 'checkbox': {
      const {
        containerStyle,
        wrapperStyle,
        labelStyle,
        descriptionLabelStyle,
        tooltipText,
        values,
        description,
        onChange,
      } = props;
      return (
        <CheckboxInput
          containerStyle={containerStyle}
          wrapperStyle={wrapperStyle}
          labelStyle={labelStyle}
          descriptionLabelStyle={descriptionLabelStyle}
          tooltipText={tooltipText}
          isRequired={isRequired}
          description={description}
          label={label}
          values={values}
          onChange={onChange}
        />
      );
    }
    default: {
      const {
        left,
        right,
        action,
        values,
        maxLength,
        decimalScale,
        isNumber,
        inputStyle,
        errorBold,
        onChange,
        onBlur,
      } = props;
      return (
        <TextInput
          disabled={disabled}
          placeholder={placeholder}
          error={error}
          errorBold={errorBold}
          inputStyle={inputStyle}
          isRequired={isRequired}
          label={label}
          id={id}
          left={left}
          right={right}
          maxLength={maxLength}
          decimalScale={decimalScale}
          isNumber={isNumber}
          action={action}
          values={values || ''}
          hasDivider={hasDivider}
          onChange={onChange}
          onBlur={onBlur}
        >
          {children}
        </TextInput>
      );
    }
  }
}
