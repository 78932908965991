import React, { Fragment } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import tw, { styled } from 'twin.macro';
import { SOActivityType } from '../../../constant';
import { UseReportJobOrderSOActivityLocationModalObj } from '../../../hook/report/jobOrder/useReportJobOrderSOActivityLocationModal.hook';
import { Text } from '../../atom';
import { HeadingThree } from '../../atom/Text/Text.atom';
import { ActivityTableBody, ActivityTableHeader, Modal } from '../../molecule';

// #region INTERFACES
export type ReportJOSOActivityLocationModalProps = {
  reportJOSOActivityLocationModal: UseReportJobOrderSOActivityLocationModalObj;
};
// #endregion

// #region STYLED
const ScroolbarBodyContainer = tw.div`flex flex-col space-y-5 py-5 px-6`;
const ActivityTimeContainer = tw.div`border px-5 py-2.5 my-2.5 w-full items-center rounded-md whitespace-pre-wrap overflow-wrap[anywhere]`;
const ActivityGoodsContainer = styled.div<{ type: SOActivityType }>(
  ({ type }) => [
    tw`py-2.5 px-5 rounded-md border-2 animate-slide-in-left`,
    type === SOActivityType.PICK_UP && tw`border-ocean-text`,
    type === SOActivityType.DROP_OFF && tw`border-twilight-text`,
  ],
);
const ActivityTable = tw.table`w-full table border-spacing[12px]`;
// #endregion

function ReportJOSOActivityLocationModal({
  reportJOSOActivityLocationModal,
}: ReportJOSOActivityLocationModalProps) {
  return (
    <Modal.WithHeader
      css={tw`p-0 width[760px]`}
      title={reportJOSOActivityLocationModal.modalTitle}
      onClose={reportJOSOActivityLocationModal.onCloseModal}
      onOverlayClick={reportJOSOActivityLocationModal.onCloseModal}
    >
      <Scrollbars tw="max-h-[620px] min-h-[450px] 2xl:(min-h-[472px])">
        <ScroolbarBodyContainer>
          {reportJOSOActivityLocationModal.activites?.map((activity) => (
            <Fragment
              key={
                activity.locationAddress +
                (activity.locationName ?? activity.totalQuantity.toString())
              }
            >
              <HeadingThree>
                {reportJOSOActivityLocationModal.getActivityTitle(activity)}
              </HeadingThree>

              <ActivityTimeContainer
                css={[
                  activity.isPickup
                    ? tw`bg-ocean-bg border-ocean-text`
                    : tw`bg-blue-light border-twilight-text`,
                ]}
              >
                <Text.HeadingFive
                  css={[
                    activity.isPickup
                      ? tw`text-ocean-text`
                      : tw`text-twilight-text`,
                  ]}
                >
                  {reportJOSOActivityLocationModal.getActivityTime(activity)}
                </Text.HeadingFive>
              </ActivityTimeContainer>

              <ActivityGoodsContainer
                type={
                  activity.isPickup
                    ? SOActivityType.PICK_UP
                    : SOActivityType.DROP_OFF
                }
              >
                <ActivityTable>
                  <ActivityTableHeader
                    status={
                      activity.isPickup
                        ? SOActivityType.PICK_UP
                        : SOActivityType.DROP_OFF
                    }
                    items={reportJOSOActivityLocationModal.modalHeaders}
                  />

                  <ActivityTableBody
                    status={
                      activity.isPickup
                        ? SOActivityType.PICK_UP
                        : SOActivityType.DROP_OFF
                    }
                    items={reportJOSOActivityLocationModal.mapActivityItems(
                      activity.goods,
                    )}
                  />
                </ActivityTable>
              </ActivityGoodsContainer>
            </Fragment>
          ))}
        </ScroolbarBodyContainer>
      </Scrollbars>
    </Modal.WithHeader>
  );
}

export default ReportJOSOActivityLocationModal;
