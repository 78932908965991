import { nanoid } from '@reduxjs/toolkit';
import fromUnixTime from 'date-fns/fromUnixTime';
import React from 'react';
import tw, { styled } from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { getDateFormat, getFullDateTimeFormat } from '../../../util/date.util';
import { Text } from '../../atom';

//#region COMPONENTS
const Container = tw.div`flex`;
const Row = tw.div`flex flex-row`;
const Column = tw.div`flex flex-col`;
const UL = tw.ul`p-0 pl-5 list-disc`;
const LI = tw.li`ml-3`;
const ULContainer = tw.div``;
const TextGreyTwo = styled(Text.BodyFourteen)([tw`text-grey-two`]);

const styles = {
  headCol: tw`px-5 py-4`,
  commonCol: tw`px-5 py-3`,
  borderRight: tw`border-r border-r-beige-lines`,
  borderBottom: tw`border-b-beige-lines border-b`,
  flexOne: tw`flex-1`,
};
//#endregion
export type TrackingHoSummaryData = {
  totalGoodsTarget: string[];
  totalGoodsDelivered: string[];
  goods: string;
  orderCompleted?: number;
  orderReceived: number;
};
type Props = {
  translator: UseTranslator;
  forceLang?: SupportedLanguage;
  data: TrackingHoSummaryData;
};

function TrackingHOSummary({ translator, forceLang, data }: Props) {
  return (
    <Container>
      <Column css={[styles.flexOne]}>
        <Row>
          <Column css={[styles.flexOne]}>
            <Text.HeadingFive>
              {translator.translate('Order Received', forceLang)}
            </Text.HeadingFive>
            <TextGreyTwo>
              {getDateFormat(fromUnixTime(data.orderReceived))}
            </TextGreyTwo>
          </Column>
          <Column css={[styles.flexOne]}>
            <Text.HeadingFive>
              {translator.translate('Order Completed at', forceLang)}
            </Text.HeadingFive>
            <TextGreyTwo tw="whitespace-normal">
              {data.orderCompleted
                ? getFullDateTimeFormat(fromUnixTime(data.orderCompleted), true)
                : '-'}
            </TextGreyTwo>
          </Column>
          <Column css={[styles.flexOne]}>
            <Text.HeadingFive>
              {translator.translate('Total Goods Target', forceLang)}
            </Text.HeadingFive>
            {!data.totalGoodsTarget.length ? (
              '-'
            ) : (
              <ULContainer>
                <UL>
                  {data.totalGoodsTarget.map((v) => (
                    <LI key={nanoid()}>
                      <TextGreyTwo>{v}</TextGreyTwo>
                    </LI>
                  ))}
                </UL>
              </ULContainer>
            )}
          </Column>
          <Column css={[styles.flexOne]}>
            <Text.HeadingFive>
              {translator.translate('Total Goods Delivered', forceLang)}
            </Text.HeadingFive>
            {!data.totalGoodsDelivered.length ? (
              '-'
            ) : (
              <ULContainer>
                <UL>
                  {data.totalGoodsDelivered.map((v) => (
                    <LI key={nanoid()}>
                      <TextGreyTwo>{v}</TextGreyTwo>
                    </LI>
                  ))}
                </UL>
              </ULContainer>
            )}
          </Column>
        </Row>
        <Row>
          <Column css={[styles.flexOne]}>
            <Text.HeadingFive>
              {translator.translate('Goods List', forceLang)}
            </Text.HeadingFive>
            <TextGreyTwo tw="whitespace-normal">
              {data.goods || '-'}
            </TextGreyTwo>
          </Column>
          <Column css={[styles.flexOne]} />
        </Row>
      </Column>
    </Container>
  );
}

export default TrackingHOSummary;
