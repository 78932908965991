import { Locale } from './locale.config';

const othersLocaleConfig: Locale[] = [
  {
    en: 'Search',
    id: 'Cari',
  },
  {
    en: 'Click',
    id: 'Klik',
  },
  {
    en: 'Continue',
    id: 'Lanjutkan',
  },
  {
    en: 'View more',
    id: 'Lihat lebih',
  },
  {
    en: 'View less',
    id: 'Lihat sedikit',
  },
  {
    en: 'Do not show this again',
    id: 'Jangan tunjukkan lagi',
  },
  {
    en: 'Got It',
    id: 'Mengerti',
  },
  {
    en: 'Information',
    id: 'Informasi',
  },
  {
    en: 'Loading',
    id: 'Memuat',
  },
  {
    en: 'until',
    id: 'hingga',
  },
  {
    en: 'Loading...',
    id: 'Memuat...',
  },
  {
    en: 'Done',
    id: 'Selesai',
  },
  {
    en: 'Profile',
    id: 'Profil',
  },
  {
    en: 'Duplicate',
    id: 'Duplikat',
  },
  {
    en: 'Succeed',
    id: 'Berhasil',
  },
  {
    en: 'Failed',
    id: 'Gagal',
  },
  {
    en: 'Leave',
    id: 'Tinggalkan',
  },
  {
    en: 'Waiting',
    id: 'Menunggu',
  },
  {
    en: 'Cancel',
    id: 'Batal',
  },
  {
    en: 'Remove',
    id: 'Hapus',
  },
  {
    en: 'Deactivate',
    id: 'Nonaktifkan',
  },
  {
    en: 'add',
    id: 'Tambahkan',
  },
  {
    en: 'No',
    id: 'Tidak ada',
  },
  {
    en: 'Date',
    id: 'Tanggal',
  },
  {
    en: 'Show',
    id: 'Tampilkan',
  },
  {
    en: 'Custom',
    id: 'Rentang',
  },
  {
    en: 'Page Not Found',
    id: 'Halaman Tidak Ditemukan',
  },
  {
    en: "Either the URL you requested was not found on this server or you don't have permission to access this page.",
    id: 'URL yang Anda minta tidak ditemukan di server ini atau Anda tidak memiliki izin untuk mengakses halaman ini.',
  },
  {
    en: 'Back to Job Order List',
    id: 'Kembali ke List Perintah Kerja',
  },
  {
    en: 'Force Complete',
    id: 'Selesaikan',
  },
  {
    en: 'status will be set to delivered. You can not undone this process.',
    id: 'status akan diatur ke terkirim. Anda tidak dapat membatalkan proses ini.',
  },
  {
    en: 'Are you sure you want to force complete this Job Order?',
    id: 'Anda yakin akan menyelesaikan paksa Perintah Kerja ini?',
  },
  {
    en: 'Are you sure you want to force complete this Shipper Order?',
    id: 'Anda yakin akan menyelesaikan paksa Shipper Order ini?',
  },
  {
    en: 'Start from Scratch',
    id: 'Mulai dari Awal',
  },
  {
    en: 'Use Last Mile Form',
    id: 'Gunakan Form Last Mile',
  },
  {
    en: 'Use Transit Form',
    id: 'Gunakan Form Transit',
  },
  {
    en: 'Finished the activity',
    id: 'Aktivitas selesai',
  },
  {
    en: 'Started the activity',
    id: 'Aktivitas dimulai',
  },
  {
    en: 'Uploaded the proof of activity',
    id: 'Bukti Aktivitas diunggah',
  },
  {
    en: 'Unknown activity',
    id: 'Aktivitas tidak diketahui',
  },
  {
    en: "Selected shipper doesn't have Activity Template",
    id: 'Shipper yang dipilih tidak memiliki Templat Pengiriman',
  },
  {
    en: "You don't have any Shipper Order on Selected Shipper Order",
    id: 'Anda belum memilih Shipper Order',
  },
  {
    en: "You don't have any Shipper Order on Shipper Order Candidate",
    id: 'Anda belum memiliki Shipper Order pada Calon Shipper Order',
  },
  {
    en: 'Try create Shipper Order by clicking the button below:',
    id: 'Buat Shipper Order dengan menekan button dibawah:',
  },
  {
    en: 'Try adding Shipper Order Candidate by clicking the button on the top right, or you can click the button below:',
    id: 'Tambahkan Calon Shipper Order dengan mengklik tombol di kanan atas, atau Anda dapat mengklik tombol di bawah ini:',
  },
  {
    en: 'Waiting for deletion',
    id: 'Menunggu penghapusan',
  },
  {
    en: 'Waiting for removal',
    id: 'Menunggu penghapusan',
  },
  {
    en: 'Removed from schedule',
    id: 'Terhapus dari penjadwalan',
  },
  {
    en: 'Removed from delivery location',
    id: 'Terhapus dari lokasi pengiriman',
  },
  {
    en: 'Removed from activity template',
    id: 'Terhapus dari templat pengiriman',
  },
  {
    en: 'Schedule not found',
    id: 'Penjadwalan tidak ditemukan',
  },
  {
    en: 'Waiting for force complete',
    id: 'Menunggu penyelesaian',
  },
  {
    en: 'Waiting for deactivation',
    id: 'Menunggu penonaktifan',
  },
  {
    en: 'Waiting for approvement',
    id: 'Menunggu persetujuan',
  },
  {
    en: 'Set to Inactive',
    id: 'Berhasil dinonaktifkan',
  },
  {
    en: 'Set to Delivered',
    id: 'Berhasil diselesaikan',
  },
  {
    en: 'Removal Succeeded',
    id: 'Penghapusan Berhasil',
  },
  {
    en: "You don't have any selected Hauling Order that you want to force complete",
    id: 'Anda tidak memiliki daftar Hauling Order yang ingin diselesaikan',
  },
  {
    en: 'Try to select some Hauling Orders from Hauling Order page',
    id: 'Cobalah untuk memilih beberapa Hauling Order di halaman Hauling Order',
  },
  {
    en: 'Try to select some Hauling Orders from Hauling Order page',
    id: 'Cobalah untuk memilih beberapa Hauling Order di halaman Hauling Order',
  },
  {
    en: 'Go back to Hauling Order page',
    id: 'Kembali ke halaman Hauling Order',
  },
  {
    en: 'Previous Month',
    id: 'Bulan Lalu',
  },
  {
    en: 'This Year',
    id: 'Tahun Ini',
  },
  {
    en: 'Last Year',
    id: 'Tahun Lalu',
  },
  {
    en: 'Shipper has been added.',
    id: 'Pengirim berhasil ditambahkan.',
  },
  {
    en: 'Driver has been added',
    id: 'Sopir berhasil ditambahkan',
  },
  {
    en: 'Schedule successfully created',
    id: 'Jadwal berhasil ditambahkan',
  },
  {
    en: 'thousand',
    id: 'ribu',
  },
  {
    en: 'million',
    id: 'juta',
  },
  {
    en: 'billion',
    id: 'miliar',
  },
  {
    en: 'trillion',
    id: 'triliun',
  },
  {
    en: 'k',
    id: 'rb',
  },
  {
    en: 'm',
    id: 'jt',
  },
  {
    en: 'b',
    id: 'm',
  },
  {
    en: 't',
    id: 't',
  },
  {
    en: 'km/h',
    id: 'km/j',
  },
  {
    en: 'Number',
    id: 'Nomor',
  },
  {
    en: 'Number',
    id: 'Nomor',
  },
  {
    en: 'Search Number',
    id: 'Cari Nomor',
  },
  {
    en: 'Status',
    id: 'Status',
  },
  {
    en: 'Stay on Page',
    id: 'Tetap di halaman',
  },
  {
    en: 'Download Process Failed',
    id: 'Gagal Mengunduh Data',
  },
  {
    en: 'of',
    id: 'dari',
  },
  {
    en: 'or',
    id: 'atau',
  },
  {
    en: 'and',
    id: 'dan',
  },
  {
    en: 'Copy to clipboard',
    id: 'Salin ke clipboard',
  },
  {
    en: 'Copied to clipboard',
    id: 'Disalin ke clipboard',
  },
  {
    en: 'Download All Pages',
    id: 'Unduh Semua Halaman',
  },
  {
    en: 'Download Current Page',
    id: 'Unduh Halaman Ini',
  },
  {
    en: 'Downloading...',
    id: 'Mengunduh...',
  },
  {
    en: 'Click here',
    id: 'Klik disini',
  },
  {
    en: 'for more information',
    id: 'untuk informasi lebih lanjut',
  },
  {
    en: 'Import XLS',
    id: 'Unggah XLS',
  },
  {
    en: 'Upload',
    id: 'Unggah',
  },
  {
    en: 'Upload a XLS file to import data',
    id: 'Unggah file XLS untuk mengimport data',
  },
  {
    en: 'Drag & drop .xls file or',
    id: 'Seret & lepas file .xls atau',
  },
  {
    en: 'Browse',
    id: 'Telusuri',
  },
  {
    en: 'Download a',
    id: 'Unduh',
  },
  {
    en: 'sample XLS template',
    id: 'contoh templat XLS',
  },
  {
    en: 'to see an example of the format required',
    id: 'untuk melihat format yang dibutuhkan',
  },
  {
    en: 'Re-upload',
    id: 'Unggah Ulang',
  },
  {
    en: 'Are you sure to leave this process?',
    id: 'Apakah Anda yakin untuk meninggalkan proses ini?',
  },
  {
    en: `This process won't be saved, and will be lost unless you continue.`,
    id: 'Proses ini tidak akan tersimpan dan akan hilang jika Anda tidak melanjutkan.',
  },
  {
    en: 'Refreshing...',
    id: 'Memuat Ulang...',
  },
  {
    en: 'Last Week',
    id: 'Minggu Lalu',
  },
  {
    en: 'Last Month',
    id: 'Bulan Lalu',
  },
  {
    en: 'Sun',
    id: 'Min',
  },
  {
    en: 'Mon',
    id: 'Sen',
  },
  {
    en: 'Tue',
    id: 'Sel',
  },
  {
    en: 'Wed',
    id: 'Rab',
  },
  {
    en: 'Thu',
    id: 'Kam',
  },
  {
    en: 'Fri',
    id: 'Jum',
  },
  {
    en: 'Sat',
    id: 'Sab',
  },
  {
    en: 'From',
    id: 'Dari',
  },
  {
    en: 'To',
    id: 'Ke',
  },
  {
    en: ' to',
    id: ' ke',
  },
  {
    en: 'Start',
    id: 'Mulai',
  },
  {
    en: 'End',
    id: 'Selesai',
  },
  {
    en: 'Duration',
    id: 'Durasi',
  },
  {
    en: 'Days',
    id: 'Hari',
  },
  {
    en: 'days',
    id: 'hari',
  },
  {
    en: 'Hours',
    id: 'Jam',
  },
  {
    en: 'hours',
    id: 'jam',
  },
  {
    en: 'Minutes',
    id: 'Menit',
  },
  {
    en: 'minutes',
    id: 'menit',
  },
  {
    en: 'Seconds',
    id: 'Detik',
  },
  {
    en: 'seconds',
    id: 'detik',
  },
  {
    en: 'Confirm Delete',
    id: 'Konfirmasi Hapus',
  },
  {
    en: 'Download',
    id: 'Unduh',
  },
  {
    en: 'See More',
    id: 'Lihat Selengkapnya',
  },
];

export default othersLocaleConfig;
