import React, { ReactChild, ReactChildren } from 'react';
import tw, { styled } from 'twin.macro';
import { Button } from '../../atom';

const ContainerWithChild = tw.div`rounded-b-md  border-t-2 border-t-beige-lines px-4 py-6`;
const Container = styled.div((props: { isFormFooter?: boolean }) => [
  tw`rounded-b-md flex flex-row  flex-1 justify-end`,
  props.isFormFooter && tw`border-t-2 px-4 py-5 border-t-beige-lines`,
]);
interface Props {
  children?: ReactChild | ReactChildren;
  title: string;
  actionButtonType?: 'button' | 'submit' | 'reset';
  additionalTitle?: string;
  isActionDisabled?: boolean;
  isAdditionalActionDisabled?: boolean;
  isFormFooter?: boolean;
  handleAction?(): void;
  handleAdditionalAction?(): void;
  buttonTestID?: string;
}

export default function CardAction({
  children,
  title,
  additionalTitle,
  isActionDisabled,
  isAdditionalActionDisabled,
  actionButtonType,
  isFormFooter,
  handleAction,
  handleAdditionalAction,
  buttonTestID,
}: Props) {
  if (children) <ContainerWithChild>{children}</ContainerWithChild>;

  return (
    <Container
      isFormFooter={isFormFooter}
      css={[handleAdditionalAction && tw`justify-between`]}
    >
      {handleAdditionalAction && (
        <Button.Outlined
          disabled={isAdditionalActionDisabled}
          onClick={handleAdditionalAction}
        >
          {additionalTitle}
        </Button.Outlined>
      )}
      <Button.Solid
        id={buttonTestID}
        type={actionButtonType}
        disabled={isActionDisabled}
        onClick={handleAction}
        testID={buttonTestID}
      >
        {title}
      </Button.Solid>
    </Container>
  );
}
