import endOfToday from 'date-fns/endOfToday';
import endOfYear from 'date-fns/endOfYear';
import getUnixTime from 'date-fns/getUnixTime';
import startOfToday from 'date-fns/startOfToday';
import startOfYear from 'date-fns/startOfYear';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../app/store/store.app';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../constant';
import { RunnerEventName } from '../../constant/RunnerEvent.constant';
import api from '../../service/api.service';
import { driverRoute } from '../../view/Driver/driver.route';
import { drivingContestRoute } from '../../view/DrivingContest/drivingContest.route';
import { homeRoute } from '../../view/Home/Home.route';

// #region INTERFACES
export type UseRunnerEventListHook = ReturnType<typeof useRunnerEventList>;
// #endregion

export default function useRunnerEventList(includeAll?: boolean) {
  const location = useLocation();
  const session = useSelector(
    (state: RootState) => state.session,
    shallowEqual,
  );

  const start = getUnixTime(startOfToday());
  const end = getUnixTime(endOfToday());
  const startAll = getUnixTime(startOfYear(new Date()));
  const endAll = getUnixTime(endOfYear(new Date()));

  /**
   * get runner events based on current day
   */
  const query = api.useGetRunnerEventListQuery(
    {
      end,
      start,
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    {
      // don't fetch when `includeAll` passed
      skip: !session.isLoggedIn,
      selectFromResult: ({ data, ...rest }) => ({
        data,
        isDrivingContestInProgress: (data?.runnerEvent ?? []).some(
          (item) => item.name === RunnerEventName.DRIVING_CONTEST,
        ),
        isDrivingContestRegistrationInProgress: (data?.runnerEvent ?? []).some(
          (item) => item.name === RunnerEventName.DRIVING_CONTEST_REGISTRATION,
        ),
        isDrivingContestAssessmentInProgress: (data?.runnerEvent ?? []).some(
          (item) => item.name === RunnerEventName.DRIVING_CONTEST_ASSESSMENT,
        ),
        registerEvent: data?.runnerEvent?.find(
          (item) => item.name === RunnerEventName.DRIVING_CONTEST_REGISTRATION,
        ),
        drivingContestEvent: data?.runnerEvent?.find(
          (item) => item.name === RunnerEventName.DRIVING_CONTEST,
        ),
        ...rest,
      }),
    },
  );

  /**
   * get all events
   */
  const allQuery = api.useGetRunnerEventListQuery(
    {
      start: startAll,
      end: endAll,
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    {
      // don't fetch when `includeAll` NOT passed
      skip: !session.isLoggedIn || !includeAll,
      selectFromResult: ({ data, ...rest }) => ({
        data,
        contestEvent: data?.runnerEvent?.find(
          (item) => item.name === RunnerEventName.DRIVING_CONTEST,
        ),
        assessmentEvent: data?.runnerEvent?.find(
          (item) => item.name === RunnerEventName.DRIVING_CONTEST_ASSESSMENT,
        ),
        registerEvent: data?.runnerEvent?.find(
          (item) => item.name === RunnerEventName.DRIVING_CONTEST_REGISTRATION,
        ),
        ...rest,
      }),
    },
  );

  const showDrivingContestBanner = useMemo(
    () =>
      [homeRoute.path, drivingContestRoute.path, driverRoute.path].includes(
        location.pathname,
      ) && query.isDrivingContestInProgress,
    [location.pathname, query.isDrivingContestInProgress],
  );

  const drivingContestYear = useMemo(() => {
    if (!query.drivingContestEvent?.start) return undefined;
    return new Date(query.drivingContestEvent?.start * 1000).getFullYear();
  }, [query.drivingContestEvent?.start]);

  return { ...query, allQuery, drivingContestYear, showDrivingContestBanner };
}
