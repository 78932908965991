import { useMemo } from 'react';
import useJODetailController from '../../hook/jobOrderDetail/useJODetailController.hook';
import useJODetailErrorPlaceholder from '../../hook/jobOrderDetail/useJODetailErrorPlaceholder.hook';
import useJODetailMaps from '../../hook/jobOrderDetail/useJODetailMaps.hook';
import useJODetailModalForceJO from '../../hook/jobOrderDetail/useJODetailModalForceJO';
import useJODetailSheet from '../../hook/jobOrderDetail/useJODetailSheet.hook';
import useSelectedShipperOrder from '../../hook/jobOrderDetail/useSelectedShipperOrder.hook';
import useForceCompleteJO from '../../hook/useForceCompleteJO/useForceCompleteJO.hook';
import useForceCompleteSO from '../../hook/useForceCompleteSO/useForceCompleteSO.hook';
import useJobOrderDetailPolling from '../../hook/useJobOrderDetailPolling/useJobOrderDetailPolling.hook';
import useJobOrderInfo from '../../hook/useJobOrderInfo/useJobOrderInfo.hook';
import useTranslator from '../../hook/useTranslator.hook';

export default function useJODetailMobileViewModel() {
  const translator = useTranslator();

  const jobOrderInfo = useJobOrderInfo();

  const joDetailErrorPlaceholder = useJODetailErrorPlaceholder();

  const joDetailController = useJODetailController({
    jobOrderInfo,
    isMobile: true,
  });

  const joDetailSheet = useJODetailSheet({
    joInfoData: jobOrderInfo.joInfoData,
    refetchJOInfo: jobOrderInfo.refetchJOInfo,
  });
  const selectedDeliveryId = useMemo(
    () => joDetailSheet.selectedSOId,
    [joDetailSheet.selectedSOId],
  );

  const joDetailMaps = useJODetailMaps({ joInfoData: jobOrderInfo.joInfoData });
  const forceCompleteJO = useForceCompleteJO({ jobOrderInfo });
  const selectedShipperOrder = useSelectedShipperOrder({
    jobOrderInfo,
    selectedDeliveryId,
  });
  const forceCompleteSO = useForceCompleteSO({
    forceCompleteJO,
    jobOrderInfo,
    selectedShipperOrder,
  });
  const joDetailModalForceJO = useJODetailModalForceJO({
    joDetailController,
    selectedDeliveryId,
    jobOrderInfo,
    forceCompleteSO,
    forceCompleteJO,
    selectedShipperOrder,
  });
  const joDetailPolling = useJobOrderDetailPolling({ jobOrderInfo });

  return {
    translator,
    jobOrderInfo,
    joDetailErrorPlaceholder,
    joDetailController,
    joDetailMaps,
    joDetailPolling,
    joDetailModalForceJO,
    joDetailSheet,
  };
}

export type UseJODetailMobileViewModelHookObj = ReturnType<
  typeof useJODetailMobileViewModel
>;
