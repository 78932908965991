import fromUnixTime from 'date-fns/fromUnixTime';
import React from 'react';
import tw from 'twin.macro';
import soActivityColumns from '../../../constant/Goods.constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { SOActivityFormProps } from '../../../model/ShipperOrder.model';
import { getTimeOffset } from '../../../util/date.util';
import { numberFormatter } from '../../../util/formatter.util';
import { promiseToVoid } from '../../../util/helper.util';
import {
  getActivityLocationOption,
  getGoodsTableTotal,
  getSODropoffTransitExpectedTimeDate,
} from '../../../util/shipperOrderCreate.util';
import { Button, Icon } from '../../atom';
import {
  AutoComplete,
  CollapsiblePanel,
  DatePickerInput,
  InputGroup,
  SOActivityTable,
} from '../../molecule';

export interface Props {
  translator: UseTranslator;
  soActivityFormData: SOActivityFormProps;
}

export default function SOActivityTransitFormItem({
  translator,
  soActivityFormData,
}: Props) {
  const {
    activities,
    isLocationListFetchLoading,
    locationOptions,
    soDate,
    handleChangeDataLocation,
    handleChangeDateActivity,
    handleChangeLocationAutotext,
    handleClickEditActivity,
    handleFetchMoreLocation,
    handleRemoveLocation,
    handleAddNewLocation,
    getActivityFormErrors,
  } = soActivityFormData;
  return (
    <div tw="grid gap-3">
      <InputGroup
        hasDivider
        required
        containerStyle={tw`items-start`}
        labelContainerStyle={tw`mt-2.5 min-w-[140px]`}
        label={translator.translate('From')}
        input={
          <div>
            <AutoComplete
              changeData={(data) =>
                promiseToVoid([handleChangeDataLocation(0, data)])
              }
              placeholder={translator.translate('Origin Location')}
              loading={isLocationListFetchLoading}
              changeInputText={handleChangeLocationAutotext}
              id="locationId"
              onFetchMore={handleFetchMoreLocation}
              datas={locationOptions}
              name="locationId"
              selectedOption={getActivityLocationOption(activities, 0)}
              onRemoveData={() => handleRemoveLocation(0)}
              tw="bg-orange-dark-lines"
              value={activities[0]?.locationId}
              error={translator.translate(
                getActivityFormErrors(0, 'locationId'),
              )}
            />
            <Button.Icon
              containerStyle={tw`mt-2`}
              icon={<Icon.Add />}
              label={translator.translate('Add New Location')}
              action={() => handleAddNewLocation(0)}
            />
          </div>
        }
      />
      <InputGroup
        hasDivider
        required
        containerStyle={tw`items-start`}
        labelContainerStyle={tw`mt-2.5 min-w-[140px]`}
        label={translator.translate('To')}
        input={
          <div>
            <AutoComplete
              changeData={(data) =>
                promiseToVoid([handleChangeDataLocation(1, data)])
              }
              placeholder={translator.translate('Destination Location')}
              loading={isLocationListFetchLoading}
              changeInputText={handleChangeLocationAutotext}
              id="locationId"
              onFetchMore={handleFetchMoreLocation}
              datas={locationOptions}
              name="locationId"
              selectedOption={getActivityLocationOption(activities, 1)}
              onRemoveData={() => handleRemoveLocation(1)}
              tw="bg-orange-dark-lines"
              value={activities[1]?.locationId}
              error={translator.translate(
                getActivityFormErrors(1, 'locationId'),
              )}
            />
            <Button.Icon
              containerStyle={tw`mt-2`}
              icon={<Icon.Add />}
              label={translator.translate('Add New Location')}
              action={() => handleAddNewLocation(1)}
            />
          </div>
        }
      />

      <InputGroup
        hasDivider
        labelContainerStyle={tw`min-w-[140px]`}
        label={translator.translate('Expected Time')}
        input={
          <DatePickerInput
            placeholder={translator.translate('Pickup Time')}
            dateValue={
              activities[0]?.expectedFinishAt
                ? fromUnixTime(activities[0]?.expectedFinishAt)
                : undefined
            }
            dateFormat={`dd MMM yyyy, HH:mm '${getTimeOffset()}'`}
            changeData={(date) => {
              promiseToVoid([handleChangeDateActivity(0, date, true)]);
            }}
            disableTimePicker={false}
            disabledDays={{
              before: soDate ? new Date(soDate * 1000) : new Date(),
            }}
          />
        }
        secondaryInput={
          <DatePickerInput
            placeholder={translator.translate('Dropoff Time')}
            dateValue={
              activities[1]?.expectedFinishAt
                ? fromUnixTime(activities[1]?.expectedFinishAt)
                : undefined
            }
            dateFormat={`dd MMM yyyy, HH:mm '${getTimeOffset()}'`}
            changeData={(date) => {
              promiseToVoid([handleChangeDateActivity(1, date)]);
            }}
            isTimepickerValidationDisabled={false}
            disableTimePicker={false}
            disabledDays={{
              before: getSODropoffTransitExpectedTimeDate(
                soDate,
                activities[0]?.expectedFinishAt,
              ),
            }}
          />
        }
      />

      <CollapsiblePanel
        action={{
          click: () => handleClickEditActivity(0),
          icon: <Icon.EditOutlined />,
          text: translator.translate('Edit'),
        }}
        title={translator.translate('Goods')}
      >
        <SOActivityTable
          columns={soActivityColumns}
          rows={activities[0]?.goods || []}
          total={{
            label: translator.translate('Total'),
            volume: numberFormatter(
              getGoodsTableTotal('volume', activities[0]?.goods),
            ),
            weight: numberFormatter(
              getGoodsTableTotal('weight', activities[0]?.goods),
            ),
          }}
          translator={translator}
        />
      </CollapsiblePanel>
    </div>
  );
}
