import React, { PropsWithChildren } from 'react';
import tw from 'twin.macro';
import { Button, Text } from '..';
import { ExtraStyle } from '../../Type.component';

// #region INTERFACES
type Props = PropsWithChildren<{
  icon: JSX.Element;
  titleLabel: string;
  actionLabel?: string;
  descriptionLabel?: string;
  testID?: string;
  rootStyle?: ExtraStyle;
  descriptionStyle?: ExtraStyle;
  onClickAction?(): void;
}>;
// #endregion

// #region STYLED
const TableNoDataContainer = tw.div`h-[65vh] flex flex-auto flex-col items-center justify-center bg-white w-full rounded-b-md`;
// #endregion

export default function TableNoData({
  children,
  icon,
  titleLabel,
  descriptionLabel,
  actionLabel,
  onClickAction,
  testID = '',
  rootStyle,
  descriptionStyle,
}: Props) {
  return (
    <TableNoDataContainer css={rootStyle}>
      {icon}

      <Text.HeadingFour tw="pt-2" data-testid={`${testID}NoData:Title`}>
        {titleLabel}
      </Text.HeadingFour>

      {descriptionLabel && (
        <Text.Label
          tw="text-center py-4"
          css={descriptionStyle}
          data-testid={`${testID}NoData:Description`}
        >
          {descriptionLabel}
        </Text.Label>
      )}

      {actionLabel && onClickAction && (
        <Button.Solid
          tw="bg-black text-white hover:bg-grey-two"
          onClick={onClickAction}
          data-testid={`${testID}NoData:Action`}
        >
          {actionLabel}
        </Button.Solid>
      )}

      {children}
    </TableNoDataContainer>
  );
}
