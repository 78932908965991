import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import ShipperAddBulkView from './ShipperAddBulk.view';

export type ShipperAddBulk = {
  'Nama Pengirim': string;
  'Nomor Telepon Pengirim': string;
  'Alamat Email': string;
  id?: string;
};

export type ShipperAddBulkRouteParam = {
  filename: string;
  shippers: ShipperAddBulk[];
};

export const shipperAddBulkRoute: RouteConfig = {
  name: 'Import Shipper',
  Component: React.lazy(() => import('./ShipperAddBulk.view')),
  NormalComponent: <ShipperAddBulkView />,
  path: '/shipper/add-bulk',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
