import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw, { styled, theme } from 'twin.macro';
import { RootState } from '../../../app/store/store.app';
import JOLogo from '../../../assets/images/JOLogo.png';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { homeRoute } from '../../../view/Home/Home.route';
import { Icon, IconButton, Image } from '../../atom';
import { Arrow, ArrowBack } from '../../atom/Icon/Icon.atom';
import { HeadingFive, HeadingFour } from '../../atom/Text/Text.atom';

// #region INTERFACES
// #endregion

// #region STYLED
const Root = styled.div(
  ({ isSidebarVisible }: { isSidebarVisible?: boolean }) => [
    tw`bg-white px-4 shadow-card flex py-3 fixed top-0 z-50 h-16 w-screen sm:(w-full justify-between px-[28px])`,
    isSidebarVisible && tw`w-[75%]`,
  ],
);
const NavLogoSection = tw.div`flex items-center gap-2`;
const NavBackButton = styled.div(({ show }: { show?: boolean }) => [
  tw`hidden sm:(cursor-pointer flex items-center gap-1)`,
  show && tw`flex -rotate-180 items-center ml-auto`,
]);
const BurgerMenu = styled(IconButton)(() => [
  tw`cursor-pointer flex -ml-1.5 items-center sm:(hidden)`,
]);
const Divider = tw.div`h-full w-0.5 bg-beige-lines ml-2 mr-3 sm:(hidden)`;
// #endregion
type Props = {
  onBurgerMenuClick?: () => void;
  isSidebarVisible?: boolean;
};

function Action({
  isMobile,
  isSidebarVisible,
}: { isMobile?: boolean; isSidebarVisible?: boolean }) {
  const translator = useTranslator();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(
    (state: RootState) => state.session,
    shallowEqual,
  );

  const forceLang = !isLoggedIn ? SupportedLanguage.id : undefined;
  const handleClickToHome = useCallback(() => {
    navigate(homeRoute.path);
  }, [navigate]);
  if (!isMobile)
    return (
      <NavBackButton onClick={handleClickToHome}>
        <HeadingFive tw="text-orange">
          {translator.translate('Kembali ke Dashboard', forceLang)}
        </HeadingFive>
        <Arrow width={20} pathStroke={theme`colors.orange.DEFAULT`} />
      </NavBackButton>
    );
  if (!isSidebarVisible)
    return (
      <IconButton tw="ml-auto rotate-180" onClick={handleClickToHome}>
        <ArrowBack width={20} height={20} fill={theme`colors.orange`} />
      </IconButton>
    );
  return null;
}
export default function HelpCenterTopBar({
  onBurgerMenuClick,
  isSidebarVisible,
}: Props) {
  const location = useLocation();
  const isTitleJOApp = location.pathname.includes('mobile-help-center');
  const { isMobile } = useScreenDetection();

  return (
    <Root isSidebarVisible={!!isSidebarVisible}>
      {onBurgerMenuClick && (
        <>
          <BurgerMenu onClick={onBurgerMenuClick}>
            <Icon.BurgerMenu />
          </BurgerMenu>
          <Divider />
        </>
      )}
      <NavLogoSection>
        <Image width={24} src={JOLogo} alt="JOLOGO" loading="lazy" />
        <HeadingFour tw=" text-grey-two">
          {isTitleJOApp ? 'Job Order App' : 'Job Management'}
        </HeadingFour>
        <HeadingFour tw=" px-2 py-1 rounded-md bg-orange-hover text-orange">
          DOCS
        </HeadingFour>
      </NavLogoSection>
      <Action isMobile={isMobile} isSidebarVisible={isSidebarVisible} />
    </Root>
  );
}
