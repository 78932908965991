import useTranslator from '../../hook/useTranslator.hook';
import useDriverDeactivateLogic from './hooks/useDriverDeactivateLogic.hook';
import useDriverDeactivateTable from './hooks/useDriverDeactivateTable.hook';

export default function useDriverDeactivateViewModel() {
  const translator = useTranslator();

  const logic = useDriverDeactivateLogic();
  const { table } = useDriverDeactivateTable({ logic });

  return {
    translator,
    logic,
    table,
  };
}
