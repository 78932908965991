import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../../component/molecule';
import { BulkActionStatusEnum } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { ErrorDetail } from '../../../service/api.endpoint';
import {
  mapBulkActionStatusToLabel,
  mapErrorMessages,
} from '../../../util/helper.util';
import {
  DeleteBulkShipperOrderEntity,
  UseShipperOrderDeleteBulkLogic,
} from './useShipperOrderDeleteBulkLogic.hook';

// #region TYPES
type Props = {
  bulkLogic: UseShipperOrderDeleteBulkLogic;
};
// #endregion

export default function useShipperOrderDeleteBulkTable({
  bulkLogic: { shipperOrderWithStatusDetail, showStatus },
}: Props) {
  const translator = useTranslator();

  const columns = useMemo<ColumnDef<DeleteBulkShipperOrderEntity>[]>(
    () => [
      {
        size: 200,
        header: translator.translate('SO Number'),
        accessorKey: 'number',
        cell: ({ getValue }) => {
          const number = getValue() as string;

          return (
            <CustomTableRowWithPopover
              primaryLabel={number ?? '-'}
              containerStyle={tw`max-w-[240px]`}
            />
          );
        },
      },

      {
        size: 150,
        header: translator.translate('Ref Number'),
        accessorKey: 'referenceNumber',
        cell: ({ getValue }) => {
          const referenceNumber = getValue() as string;

          return (
            <CustomTableRowWithPopover
              primaryLabel={referenceNumber ?? '-'}
              containerStyle={tw`max-w-[150px]`}
            />
          );
        },
      },

      {
        size: 150,
        header: translator.translate('Shipper Name'),
        accessorFn: (shipperOrder) => shipperOrder.shipper.name,
        cell: ({ getValue }) => {
          const shipperName = getValue() as string;

          return (
            <CustomTableRowWithPopover
              primaryLabel={shipperName ?? '-'}
              containerStyle={tw`max-w-[150px]`}
            />
          );
        },
      },

      ...(showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({
                getValue,
              }: { getValue: () => string | ErrorDetail[] }) => {
                const accessor = getValue();
                const label =
                  typeof accessor === 'string'
                    ? translator.translate(accessor)
                    : mapErrorMessages(translator, accessor);

                return (
                  <CustomTableRowWithPopover
                    primaryLabel={label}
                    containerStyle={tw`max-w-[290px]`}
                  />
                );
              },
            },
          ]
        : []),
    ],
    [showStatus, translator],
  );

  const table = useReactTable({
    data: shipperOrderWithStatusDetail,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
