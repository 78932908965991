import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  isSmall?: boolean;
  rectStroke?: string;
  rectStrokeWidth?: string;
  rectX?: string;
  rectY?: string;
  rectRX?: string;
  rectFill?: string;
  rectWidth?: string;
  rectHeight?: string;
  pathStroke?: string;
  pathStrokeWidth?: string;
};
export default function CircleCheck({
  rectFill = '#32C08D',
  width = 88,
  height = 88,
  fill = 'none',
  rectX = '4',
  rectY = '4',
  rectRX = '40',
  rectStrokeWidth = '8',
  rectWidth = '80',
  rectHeight = '80',
  rectStroke = '#E9F9EE',
  pathStroke = '#fff',
  pathStrokeWidth = '8',
  isSmall,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x={isSmall ? undefined : rectX}
        y={isSmall ? undefined : rectY}
        width={rectWidth}
        height={rectHeight}
        rx={rectRX}
        fill={rectFill}
        stroke={rectStroke}
        strokeWidth={isSmall ? undefined : rectStrokeWidth}
      />
      <path
        d={
          isSmall
            ? 'M10 13.851L16.149 20 33 3'
            : 'M30.5 39.165 44.335 53 82.25 14.75'
        }
        stroke={pathStroke}
        strokeWidth={pathStrokeWidth}
      />
    </svg>
  );
}
