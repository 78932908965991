import { Placement } from '@popperjs/core';
import React, { CSSProperties } from 'react';
import { Modifier } from 'react-day-picker';
import tw, { styled } from 'twin.macro';
import { LabelInput, Row } from '../../atom';
import { RangeYear } from '../DatePicker/DatePicker.molecule';
import { DefinedRangeOption } from '../DateRangePicker/DateRangePicker.molecule';
import DateRangePickerInput from '../DateRangePickerInput/DateRangePickerInput.molecule';

type Props = {
  label?: string;
  isRequired?: boolean;
  startValues?: Date;
  startId?: string;
  startName?: string;
  startLabel?: string;
  startDisabled?: boolean;
  startError?: string;
  endValues?: Date;
  endId?: string;
  endName?: string;
  endLabel?: string;
  endDisabled?: boolean;
  endError?: string;
  definedRangeOptions?: DefinedRangeOption[];
  maxDateRangeInDays?: number;
  rangeYear?: RangeYear;
  disabledDays?: Modifier;
  outsideAlerterStyle?: CSSProperties;
  popoverPlacement?: Placement;
  textFieldInputBgBeige?: boolean;
  notes?: string;
  disableDefinedRange?: boolean;
  onChangeStart?: (date?: Date) => void;
  onChangeEnd?: (date?: Date) => void;
  onClickApplyDateRange?: (_startDate: Date, _endDate: Date) => void;
};

const Container = styled.div(({ hasLabel }: { hasLabel?: boolean }) => [
  tw`w-full`,
  hasLabel && tw`w-[calc(100% - 200px)]`,
]);

export default function DateRangeFormInput({
  label,
  isRequired,
  startValues,
  startId,
  startName,
  startLabel,
  startDisabled,
  startError,
  endValues,
  endId,
  endName,
  endLabel,
  endDisabled,
  endError,
  definedRangeOptions,
  maxDateRangeInDays,
  rangeYear,
  disabledDays,
  outsideAlerterStyle,
  popoverPlacement,
  textFieldInputBgBeige,
  notes,
  disableDefinedRange,
  onChangeStart,
  onChangeEnd,
  onClickApplyDateRange,
}: Props) {
  return (
    <Row>
      {!!label && <LabelInput label={label} isRequired={isRequired} />}
      <Container hasLabel={!!label}>
        <DateRangePickerInput
          sdDateValue={startValues}
          sdId={startId}
          sdName={startName}
          sdLabel={startLabel}
          sdDisabled={startDisabled}
          sdError={startError}
          sdOnChangeDateValue={onChangeStart}
          edDateValue={endValues}
          edId={endId}
          edName={endName}
          edLabel={endLabel}
          edDisabled={endDisabled}
          edError={endError}
          edOnChangeDateValue={onChangeEnd}
          onClickApplyDateRange={onClickApplyDateRange}
          definedRangeOptions={definedRangeOptions}
          maxDateRangeInDays={maxDateRangeInDays}
          rangeYear={rangeYear}
          disabledDays={disabledDays}
          outsideAlerterStyle={outsideAlerterStyle}
          popoverPlacement={popoverPlacement}
          textFieldInputBgBeige={textFieldInputBgBeige}
          notes={notes}
          disableDefinedRange={disableDefinedRange}
        />
      </Container>
    </Row>
  );
}
