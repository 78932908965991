import 'twin.macro';
import { SnackbarTheme } from '../component/molecule/Snackbar/Snackbar.molecule';
import { ErrorCodes, SelectionSO, SOActivityType } from '../constant';
import {
  JOBOORDERTYPE,
  JOFilterOrderBy,
  JOFilterOrderByValue,
  JOFilterStatus,
  JOFilterStatusValue,
  JOFormStep,
  JOSpendingTab,
} from '../constant/JobOrder.constant';
import { DELIVERYLOCATIONTYPE } from '../constant/Location.constant';
import { DeleteBulkJobOrderEntity } from '../hook/jobOrderDeleteBulk/useJobOrderDeleteBulkLogic.hook';
import { JOSubmissionModalProps } from '../hook/jobOrderForm/useJOFormSubmissionModal.hook';
import { AutocompleteType } from '../hook/useAutocomplete.hook';
import { UseTranslator } from '../hook/useTranslator.hook';
import { Photo } from '../model/Common.model';
import {
  JOActivityInfo,
  JobOrderForm,
  JobOrderInfoDeliverySO,
  JobOrderInfoDeliverySOActivity,
  JOStatus,
} from '../model/JobOrder.model';
import { Location } from '../model/Location.model';
import { Goods } from '../model/ShipperOrder.model';
import { Vehicle } from '../model/Vehicle.model';
import {
  JobOrderUpdateParams,
  PostJobOrderCreateParams,
} from '../service/endpoint/jobOrder/jobOrder.endpoint';
import { snackbarAction } from '../store/snackbar.store';
import { errorCodeToLabel } from './error.util';
import { numberFormatter } from './formatter.util';
import { doDownload } from './helper.util';
import { getLocationIdJoDraft, updateDeliveries } from './jobOrderCreate.util';

/**
 * get JO status number from query string
 */
export function getJOFilterStatuses(statuses: JOFilterStatus[]): JOStatus[] {
  return statuses.map((status) => {
    const literal: Record<JOFilterStatus, JOStatus> = {
      [JOFilterStatus.DRAFT]: JOStatus[status],
      [JOFilterStatus.DELETED]: JOStatus[status],
      [JOFilterStatus.ASSIGNED]: JOStatus[status],
      [JOFilterStatus.DELIVERING]: JOStatus[status],
      [JOFilterStatus.DELIVERED]: JOStatus[status],
    };

    return literal[status];
  });
}

/**
 * @param  {JOStatus} status
 * @param  {UseTranslator} translator
 * @returns string
 *
 * @summary map through `JOStatus` enum to get the `status` label
 */
export function mapJOStatusToLabel(status?: JOStatus): string {
  const mapper = {
    [JOStatus.ASSIGNED]: 'Assigned',
    [JOStatus.CANCELLED]: 'Cancelled',
    [JOStatus.DELETED]: 'Deleted',
    [JOStatus.DELIVERED]: 'Delivered',
    [JOStatus.DELIVERING]: 'Delivering',
    [JOStatus.DRAFT]: 'Draft',
  };

  return status ? mapper[status] : mapper[JOStatus.DRAFT];
}

/**
 * map through `keyof typeof JOStatus` to `JOStatus` enum
 */
export function mapJOStatusLabelToEnum(status: keyof typeof JOStatus) {
  const mapper = {
    ASSIGNED: JOStatus.ASSIGNED,
    CANCELLED: JOStatus.CANCELLED,
    DELETED: JOStatus.DELETED,
    DELIVERED: JOStatus.DELIVERED,
    DELIVERING: JOStatus.DELIVERING,
    DRAFT: JOStatus.DRAFT,
  };

  return mapper[status];
}

/**
 * map JO order statuses to translated labels
 */
export function mapJOFilterStatusesToLabels(
  statuses: JOFilterStatus[],
  translator: UseTranslator,
): string[] {
  const literal: Record<JOFilterStatus, string> = {
    [JOFilterStatus.DRAFT]: translator.translate('Draft'),
    [JOFilterStatus.DELETED]: translator.translate('Deleted'),
    [JOFilterStatus.ASSIGNED]: translator.translate('Assigned'),
    [JOFilterStatus.DELIVERING]: translator.translate('Delivering'),
    [JOFilterStatus.DELIVERED]: translator.translate('Delivered'),
  };

  return statuses.map((status) => literal[status]);
}

/**
 * map through `orderBy` query params to get the string label
 */
export function mapJOFilterOrderByToLabel(
  orderBy: JOFilterOrderBy,
  translator: UseTranslator,
): string {
  const literal: Record<JOFilterOrderBy, string> = {
    [JOFilterOrderBy.UPDATED_AT_DESC]: translator.translate('Newest Updates'),
    [JOFilterOrderBy.UPDATED_AT_ASC]: translator.translate('Oldest Updates'),
    [JOFilterOrderBy.NUMBER_ASC]: translator.translate('JO Number A-Z'),
    [JOFilterOrderBy.NUMBER_DESC]: translator.translate('JO Number Z-A'),
    [JOFilterOrderBy.DATE_ASC]: translator.translate('JO Date A-Z'),
    [JOFilterOrderBy.DATE_DESC]: translator.translate('JO Date Z-A'),
  };

  return literal[orderBy];
}

/**
 * @param  {JOBOORDERTYPE} type
 * @param  {UseTranslator} translator
 * @returns string
 *
 * @summary map through `JOBOORDERTYPE` enum to get the `type` label
 */
export function mapJODetailTypeToLabel(type: JOBOORDERTYPE): string {
  return type === JOBOORDERTYPE.INTERNAL ? 'Internal' : 'External';
}

/**
 * @param  {DELIVERYLOCATIONTYPE} locationType
 * @param  {UseTranslator} translator
 * @returns string
 *
 * @summary map through `DELIVERYLOCATIONTYPE` enum to get the `locationType` label
 */
export function mapJODetailLocationTypeToLabel(
  locationType: DELIVERYLOCATIONTYPE,
): string {
  return locationType === DELIVERYLOCATIONTYPE.TRANSIT
    ? 'Transit'
    : 'Last Mile';
}

/**
 * @param  {number|null} travelExpense
 * @returns string
 *
 * @summary render IDR money label or just '-'
 */
export function getJODetailTravelExpense(travelExpense: number | null): string {
  if (typeof travelExpense === 'number')
    return `Rp${numberFormatter(travelExpense)}`;
  return '-';
}

/**
 * @param  {Vehicle} vehicle
 * @returns string
 *
 * @summary render detail vehicle label from `Vehicle` object
 */
export function getJODetailVehicle(vehicle: Vehicle): string {
  return `${vehicle.modelName} • ${vehicle.name || vehicle.chassisNumber}`;
}

/**
 * @param  {JobOrderInfoDeliverySOActivity[]} activities
 *
 * @summary filter through activities to get pickUp, dropOff, and standBy from `JobOrderInfoDeliverySOActivity[]` array
 */
export function getJOSODetailActivities(
  activities: JobOrderInfoDeliverySOActivity[],
) {
  const pickUpActivities = activities.filter(
    (activity) => activity.type === SOActivityType.PICK_UP,
  );
  const dropOffActivities = activities.filter(
    (activity) => activity.type === SOActivityType.DROP_OFF,
  );
  const standByActivities = activities.filter(
    (activity) => activity.type === SOActivityType.STAND_BY,
  );

  return {
    pickUpActivities,
    dropOffActivities,
    standByActivities,
  };
}
/**
 * @param  {JobOrderInfoDeliverySOActivity[]} activities
 *
 * @summary filter through activities to get pickUp, dropOff, and standBy from `JobOrderInfoDeliverySOActivity[]` array
 */
export function getJODetailActivities(activities: JOActivityInfo[]) {
  const pickUpActivities = activities.filter(
    (activity) =>
      (activity.type || activity.soActivity?.type) === SOActivityType.PICK_UP,
  );
  const dropOffActivities = activities.filter(
    (activity) =>
      (activity.type || activity.soActivity?.type) === SOActivityType.DROP_OFF,
  );
  const standByActivities = activities.filter(
    (activity) =>
      (activity.type || activity.soActivity?.type) === SOActivityType.STAND_BY,
  );

  return {
    pickUpActivities,
    dropOffActivities,
    standByActivities,
  };
}

/**
 * @param  {SOActivityType} activityType
 * @param  {UseTranslator} translator
 * @returns string
 *
 * @summary map through `SOActivityType` enum to get the `type` label
 */
export function mapJODetailSOActivityTypeToLabel({
  activityType,
  translator,
}: {
  activityType?: SOActivityType;
  translator: UseTranslator;
}): string {
  switch (activityType) {
    case SOActivityType.PICK_UP:
      return translator.translate('Pickup');
    case SOActivityType.DROP_OFF:
      return translator.translate('Dropoff');
    default:
      return translator.translate('Standby');
  }
}

/**
 * @param  {UseTranslator} translator
 * @returns JOFilterOrderByValue[]
 *
 * * @summary get JO filterOrderBy values
 */
export function getFilterOrderByValues(
  translator: UseTranslator,
): JOFilterOrderByValue[] {
  return [
    {
      label: translator.translate('Newest Updates'),
      value: JOFilterOrderBy.UPDATED_AT_DESC,
    },
    {
      label: translator.translate('Oldest Updates'),
      value: JOFilterOrderBy.UPDATED_AT_ASC,
    },
    {
      label: translator.translate('JO Number A-Z'),
      value: JOFilterOrderBy.NUMBER_ASC,
    },
    {
      label: translator.translate('JO Number Z-A'),
      value: JOFilterOrderBy.NUMBER_DESC,
    },
    {
      label: translator.translate('JO Date A-Z'),
      value: JOFilterOrderBy.DATE_ASC,
    },
    {
      label: translator.translate('JO Date Z-A'),
      value: JOFilterOrderBy.DATE_DESC,
    },
  ];
}

/**
 * @param  {UseTranslator} translator
 * @returns JOFilterStatusValue[]
 *
 * * @summary get JO filterStatus values
 */
export function getFilterStatusValues(
  translator: UseTranslator,
): JOFilterStatusValue[] {
  return [
    {
      label: translator.translate('Draft'),
      value: JOFilterStatus.DRAFT,
    },
    {
      label: translator.translate('Assigned'),
      value: JOFilterStatus.ASSIGNED,
    },
    {
      label: translator.translate('Delivering'),
      value: JOFilterStatus.DELIVERING,
    },
    {
      label: translator.translate('Delivered'),
      value: JOFilterStatus.DELIVERED,
    },
    {
      label: translator.translate('Deleted'),
      value: JOFilterStatus.DELETED,
    },
  ];
}

export function doDownloadAllPhotos(
  photos: Photo[],
  translator: UseTranslator,
) {
  if (!window.navigator.onLine) {
    snackbarAction.show({
      type: SnackbarTheme.warning,
      message: translator.translate('No internet connection'),
    });
    return;
  }
  if (!photos.length) return;

  for (const item of photos) {
    doDownload(item.accessUrl);
  }
}

/**
 * map JO order statuses to translated labels
 */
export function mapJOSpendingTabToLabel(tab: JOSpendingTab): string {
  const label: Record<JOSpendingTab, string> = {
    [JOSpendingTab.DETAILS]: 'Details',
    [JOSpendingTab.TIMELINE]: 'Status Timeline',
  };

  return label[tab];
}

/**
 *
 * map custom error message JO delete
 * @param errorCodes
 * @returns
 */
export function mapCustomJODeleteError(
  errorCodes: string,
  jo?: DeleteBulkJobOrderEntity,
): string {
  if (jo?.status === JOStatus.DELETED) return 'Job Order already deleted.';
  if (jo?.status === JOStatus.DELIVERED) return 'Job Order already delivered.';
  if (errorCodes === ErrorCodes.JO_OPERATION_NOT_PERMITTED)
    return 'Job Order already assigned.';
  if (errorCodes === ErrorCodes.JO_NOT_FOUND)
    return 'Job Order already deleted.';

  return errorCodeToLabel(errorCodes);
}

/**
 * @param {JOFormStep} step
 * @summary map label JO Form Step enum
 * @returns string
 */
export function mapJOFormStepToLabel(step: JOFormStep): string {
  const literal: Record<JOFormStep, string> = {
    [JOFormStep.HEADER]: 'Header',
    [JOFormStep.ASSIGNMENT]: 'Assign Driver & Vehicle',
    [JOFormStep.SO_SELECTION]: 'Assign Delivery Location',
  };
  return literal[step];
}

export function getTransitLocation(
  activity: JOActivityInfo,
  shipperOrder: JobOrderInfoDeliverySO,
  index: number,
): Location {
  if (shipperOrder.isTransitable)
    return shipperOrder.activities[index].location;
  return (activity.soActivity?.location || activity.location) as Location;
}

/**
 *
 * @param {JOActivityInfo} activity
 * @summary map SO from Activity List
 * @returns SOListActivity[]
 */
export function mapSOFromActivities(
  activity: JOActivityInfo,
  shipperOrder: JobOrderInfoDeliverySO,
  index: number,
) {
  return {
    id: activity.id,
    type: (activity.soActivity?.type || activity.type) as SOActivityType,
    expectedFinishAt: activity.soActivity?.expectedFinishAt,
    locationId: getLocationIdJoDraft(activity),
    location: getTransitLocation(activity, shipperOrder, index),
    goods: (activity.soActivity?.goods || activity.goods) as Goods[],
    goodsOut: (activity.soActivity?.goodsOut || activity.goodsOut) as Goods[],
    notes: activity.notes,
    completedAt: activity.completedAt as number,
    status: activity.status,
    statusText: activity.statusText,
    events: [],
    index: activity.index,
  };
}

/**
 *
 * @param {JobOrderInfoDeliverySO} shipperOrder
 * @param {JOActivityInfo[]} activities
 * @summary map Activity list from delivery to Shipper Order List
 * @returns SelectionSO[]
 */
export function mapSOActivitiesFromDeliveries({
  shipperOrder,
  activities,
  isResolved,
}: {
  isResolved?: boolean;
  shipperOrder: JobOrderInfoDeliverySO;
  activities: JOActivityInfo[];
}): SelectionSO {
  const formattedActivities = activities.map((item, idx) =>
    mapSOFromActivities(item, shipperOrder, idx),
  );
  const transitDeliveryLocation = activities.find((activity) =>
    [activity.type, activity.soActivity?.type].includes(
      SOActivityType.DROP_OFF,
    ),
  );
  const transitCurrentLocation = activities.find((activity) =>
    [activity.type, activity.soActivity?.type].includes(SOActivityType.PICK_UP),
  );
  return {
    id: shipperOrder.id,
    date: shipperOrder.date,
    number: shipperOrder.number,
    shipper: shipperOrder.shipper,
    status: shipperOrder.status,
    statusText: shipperOrder.statusText,
    referenceNumber: shipperOrder.referenceNumber,
    notes: shipperOrder.notes,
    isTransitable: shipperOrder.isTransitable,
    cost: { deliveryFee: 0, insurance: 0, tax: 0 },
    activities: formattedActivities,
    deliveries: shipperOrder.deliveries,
    createdAt: shipperOrder.createdAt,
    updatedAt: shipperOrder.updatedAt,
    totalPayment: shipperOrder.totalPayment,
    trackingCode: shipperOrder?.trackingCode ?? '-',
    paymentStatus: shipperOrder.paymentStatus,
    paymentStatusText: shipperOrder.paymentStatusText,
    ...(shipperOrder.isTransitable && {
      // For transitable SO, the activities value with type dropoff is always a `deliveryLocation`
      ...(isResolved && {
        deliveryLocation:
          transitDeliveryLocation?.location ||
          transitDeliveryLocation?.soActivity?.location,
      }),
      currentLocation:
        transitCurrentLocation?.location ||
        transitCurrentLocation?.soActivity?.location,
    }),
  };
}

/**
 *
 * @param {JobOrderForm} values
 * @param {SelectionSO} selectionSOList
 * @summary format JobOrderForm Value to PostJobOrderCreateParams
 * @returns PostJobOrderCreateParams
 */
export function formatJobOrderFormToCreateParams({
  values,
  selectionSOList,
}: {
  values: JobOrderForm;
  selectionSOList: SelectionSO[];
}): PostJobOrderCreateParams &
  Partial<{
    driverOption: AutocompleteType;
    vehicleOption: Vehicle;
  }> {
  const deliveries = updateDeliveries(selectionSOList, values?.deliveries);
  return {
    joDate: values?.joDate ?? 0,
    isUseAssignment: values.isUseAssignment,
    ...(values.joNumber && { joNumber: values.joNumber }),
    ...(!!deliveries?.length && { deliveries }),
    ...(values.vehicleId && { vehicleId: values.vehicleId }),
    ...(values.driverId && { driverId: values.driverId }),
    ...(values.sealNumber && { sealNumber: values.sealNumber }),
    ...(values.travelExpenses && { travelExpense: values.travelExpenses }),
    ...(values.driverOption && { driverOption: values.driverOption }),
    ...(values.vehicleOption && { vehicleOption: values.vehicleOption }),
  };
}

/**
 *
 * @param {JobOrderForm} values
 * @summary format JobOrderForm Value to SubmissionModal
 * @returns JOSubmissionModalProps
 */
export function formatJobOrderFormToSubmissionModalProps({
  values,
}: {
  values: JobOrderForm;
}): JOSubmissionModalProps {
  return {
    joNumber: values.joNumber || '',
    ...(values.driverOption && { driverName: values.driverOption.label }),
    ...(values.vehicleOption && {
      vehicleName:
        values.vehicleOption.name ?? values.vehicleOption.chassisNumber,
    }),
  };
}

/**
 *
 * @param {PostJobOrderCreateParams} values
 * @param {string?} joId
 * @summary format PostJobOrderCreateParams Value to JobOrderUpdateParams
 * @returns JobOrderUpdateParams
 */
export function formatCreateParamsToUpdateParams({
  values,
  joId,
}: {
  values: PostJobOrderCreateParams;
  joId?: string;
}): JobOrderUpdateParams {
  return {
    id: joId ?? '',
    isUseAssignment: values.isUseAssignment,
    deliveries: values.deliveries ?? [],
    driverId: values.driverId ?? null,
    vehicleId: values.vehicleId ?? null,
    joDate: values?.joDate ?? null,
    sealNumber: values.sealNumber ?? null,
    travelExpense: values.travelExpense ?? null,
    joNumber: values.joNumber ?? null,
  };
}
