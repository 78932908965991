import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import {
  JODetailSpendingReportFilterInitialValues,
  JODetailTab,
} from '../../constant/JobOrderDetail.constant';
import { JobOrderSearchParam, JOStatus } from '../../model/JobOrder.model';
import JobOrderDetailView from './JobOrderDetail.view';

export type JobOrderDetailSearchParam =
  Partial<JODetailSpendingReportFilterInitialValues> & {
    tab?: JODetailTab;
    page?: number;
    pageSize?: number;
  };

export type JobOrderDetailRouteParam = {
  status?: JOStatus;
  searchParamsFromJOList?: JobOrderSearchParam;
};

export const jobOrderDetailRoute: RouteConfig = {
  name: 'Detail',
  Component: React.lazy(() => import('./JobOrderDetail.view')),
  NormalComponent: <JobOrderDetailView />,
  path: '/job-order/:id',
  isPrivate: true,
};
