import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';
import JobOrderDeleteBulkView from './JobOrderDeleteBulk.view';

export type JobOrderDeleteBulkRouteParam = DefaultRouteParam;

export const jobOrderDeleteBulkRoute: RouteConfig = {
  name: 'Delete Job Order',
  Component: React.lazy(() => import('./JobOrderDeleteBulk.view')),
  NormalComponent: <JobOrderDeleteBulkView />,
  path: '/job-order/delete',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
