import React from 'react';
import tw, { styled } from 'twin.macro';
import { Checkbox, Radio, Switch } from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region PROPS
export type Props = React.ComponentProps<'input'> & {
  label?: string;
  indeterminate?: boolean;
  containerStyle?: ExtraStyle;
  labelStyle?: ExtraStyle;
  control: 'radio' | 'checkbox' | 'switch';
  rtl?: boolean;
};
// #endregion

// #region STYLED COMPONENTS
const Container = styled.label((props: { disabled?: boolean }) => [
  tw`relative flex items-center`,
  !props.disabled && tw`cursor-pointer`,
]);

const Label = styled.h1((props: { labelStyle?: ExtraStyle; rtl?: boolean }) => [
  tw`flex-1`,
  props.rtl && tw`mr-3`,
  !props.rtl && tw`ml-3`,
  props.labelStyle,
]);
// #endregion

export function FormControl({
  label,
  containerStyle,
  labelStyle,
  control,
  rtl,
  id,
  disabled,
  ...props
}: Props) {
  return (
    <Container
      htmlFor={id}
      className="group"
      disabled={disabled}
      css={containerStyle}
    >
      {control === 'checkbox' && !rtl && (
        <Checkbox id={id} disabled={disabled} {...props} />
      )}
      {control === 'radio' && !rtl && (
        <Radio id={id} disabled={disabled} {...props} />
      )}
      {control === 'switch' && !rtl && (
        <Switch id={id} disabled={disabled} {...props} />
      )}
      <Label labelStyle={labelStyle} rtl={rtl}>
        {label}
      </Label>
      {control === 'checkbox' && rtl && (
        <Checkbox id={id} disabled={disabled} {...props} />
      )}
      {control === 'radio' && rtl && (
        <Radio id={id} disabled={disabled} {...props} />
      )}
      {control === 'switch' && rtl && (
        <Switch id={id} disabled={disabled} {...props} />
      )}
    </Container>
  );
}

export default FormControl;
