import React, { CSSProperties, PropsWithChildren, useCallback } from 'react';
import tw, { styled, TwStyle } from 'twin.macro';
import useClickOutside from '../../../hook/useClickOutside.hook';
import useEventListener from '../../../hook/useEventListener.hook';
import { Icon, IconButton, RootPortal } from '../../atom';

// #region
interface Props {
  isOpen: boolean;
  handleClose(): void;
  contentContainerStyle?: TwStyle | CSSProperties;
  wrapperId?: string;
}
// #endregion

// #region STYLED
const DrawerContainer = tw.div`
  background[rgba(0,0,0,0.5)]
  fixed
  w-full
  h-full
  top-0
  right-0
  bottom-0
  flex
  flex-col
  overflow-hidden
  z-index[999]
  transition-all
  animate-fade-in
`;
const DrawerContentContainer = tw.div`
  bg-white
  opacity[1 !important]
  absolute
  h-full
  w-full md:w-1/2 lg:width[27.5%]
  top-0
  right-0
  bottom-0
  flex
  flex-col
  transition-all
  animate-slide-in-right
`;

const CloseContainer = styled.div`
  & svg {
    width: 20px;
    height: 20px;

    & path {
      stroke-width: 4;
    }
  }
  position: absolute;
  z-index: 999;
  margin-left: -36px;
  margin-top: 16px;
`;

// #endregion

function DrawerPortal({
  children,
  isOpen,
  handleClose,
  contentContainerStyle,
  wrapperId = 'shipper-order-filter-drawer-portal',
}: PropsWithChildren<Props>) {
  const closeOnEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') handleClose();
    },
    [handleClose],
  );

  useEventListener('keydown', closeOnEscapeKey);

  const ref = useClickOutside<HTMLDivElement>(() => {
    handleClose();
  });

  if (!isOpen) return null;

  return (
    <RootPortal wrapperId={wrapperId}>
      <DrawerContainer data-testid="drawer-overlay">
        <DrawerContentContainer
          ref={ref}
          css={contentContainerStyle}
          data-testid="drawer-content"
        >
          <CloseContainer>
            <IconButton tw="bg-white padding[2px]" onClick={handleClose}>
              <Icon.Close tw="text-grey-two " />
            </IconButton>
          </CloseContainer>
          {children}
        </DrawerContentContainer>
      </DrawerContainer>
    </RootPortal>
  );
}

export default DrawerPortal;
