import React from 'react';

export default function CircleInactive() {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={8} cy={8} r={8} fill="#C9C9C9" />
      <circle cx={8} cy={8} r={5.5} fill="#898989" />
    </svg>
  );
}
