import React from 'react';

export default function Duplicate({
  fill,
  stroke,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x={1}
        y={1}
        width={18}
        height={18}
        rx={5}
        fill={fill || '#fff'}
        stroke={stroke || '#F3532E'}
        strokeWidth={2}
      />
      <rect
        x={5}
        y={5}
        width={18}
        height={18}
        rx={5}
        fill={fill || '#fff'}
        stroke={stroke || '#F3532E'}
        strokeWidth={2}
      />
      <path
        d="M14.5 10.5v2h-1v-2h1Zm-1 3h1v1h-1v-1Zm2 1v-1h2v1h-2Zm-2 1h1v2h-1v-2Zm-1-2v1h-2v-1h2Z"
        fill={fill || '#fff'}
        stroke={stroke || '#F3532E'}
      />
    </svg>
  );
}
