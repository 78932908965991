import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterDataManagementAdditionalFunctionRouteParam =
  DefaultRouteParam;

export const helpCenterDataManagementAdditionalFunctionRoute: RouteConfig = {
  name: 'Help Center Data Management Additional Function',
  Component: React.lazy(
    () => import('./HelpCenterDataManagementAdditionalFunction.view'),
  ),
  path: '/help-center/data-management/additional-function',
  isPrivate: false,
  breadcrumb: 'Help Center Data Management Additional Function',
  options: {
    label: 'Fungsionalitas Tambahan',
    testID: 'MenuHelpCenterDataManagementAdditionalFunction',
  },
};
