import React, { PropsWithChildren } from 'react';
import tw from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { FormItem } from '../../../types/input.type';
import { CardContainer } from '../../atom';
import { CardAction, CardContent, CardHeader } from '../../molecule';
import { ExtraStyle } from '../../Type.component';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';

type Props = PropsWithChildren<{
  title: string;
  formId?: string;
  isSubmitDisabled?: boolean;
  formData: FormItem[];
  containerStyle?: ExtraStyle;
  headerStyle?: ExtraStyle;
  actionSubmitText?: string;
  isAdditionalActionDisabled?: boolean;
  additionalActionText?: string;
  submitTestID?: string;
  isFooterVisible?: boolean;
  handleBack?(): void;
  handleAdditionalAction?(): void;
  handleSubmit?(): void;
}>;

const Container = tw.div`w-[720px] mx-auto`;
const ContainerForm = tw.div`pb-2`;

export default function FormSection({
  containerStyle,
  title,
  formId,
  formData,
  headerStyle,
  isSubmitDisabled,
  actionSubmitText,
  additionalActionText,
  isAdditionalActionDisabled,
  submitTestID,
  isFooterVisible = true,
  children,
  handleBack,
  handleSubmit,
  handleAdditionalAction,
}: Props) {
  const translator = useTranslator();
  return (
    <Container css={containerStyle}>
      <CardContainer>
        <>
          <CardHeader
            containerStyle={headerStyle}
            handleBack={handleBack}
            title={title}
          />
          <CardContent>
            {children}
            {formData.map((item) => (
              <ContainerForm key={`${formId || ''}-${item.id}`}>
                <ComposedFormField {...item} />
              </ContainerForm>
            ))}
          </CardContent>
          {!!isFooterVisible && (
            <CardAction
              isFormFooter
              isAdditionalActionDisabled={isAdditionalActionDisabled}
              handleAdditionalAction={handleAdditionalAction}
              additionalTitle={additionalActionText}
              isActionDisabled={isSubmitDisabled}
              handleAction={handleSubmit}
              title={translator.translate(actionSubmitText || 'Next')}
              buttonTestID={submitTestID}
            />
          )}
        </>
      </CardContainer>
    </Container>
  );
}
