import React from 'react';

export default function Plus({
  fill = 'currentColor',
  width = 12,
  height = 12,
  viewBox = '0 0 12 12',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#F3532E"
        d="M0 5.996c0 .357.298.647.647.647h4.706v4.703c0 .349.29.647.647.647a.66.66 0 00.655-.647V6.643h4.698c.349 0 .647-.29.647-.647a.66.66 0 00-.647-.654H6.655V.647A.66.66 0 006 0a.653.653 0 00-.647.647v4.695H.647A.66.66 0 000 5.996z"
      />
    </svg>
  );
}
