import { useLocalStorage } from 'react-use';

export type Redirection = {
  pathname: string;
  search: string;
};
function usePendingRedirection() {
  const [get, set, clear] = useLocalStorage<Redirection>('redirection');

  return { set, get, clear };
}

export default usePendingRedirection;
