import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  rectWidth?: string;
  rectHeight?: string;
  rectX?: string;
  rectY?: string;
  rectFill?: string;
  rectStroke?: string;
  rectStrokeWidth?: string;
  rectRx?: string;
  pathStroke?: string;
  pathStrokeWidth?: string;
};

export default function CloseCircle({
  width = '81',
  height = '81',
  fill = 'none',
  viewBox = '0 0 81 81',
  rectWidth = '64',
  rectHeight = '64',
  rectX = '8.5',
  rectY = '8.5',
  rectFill = '#D11',
  rectStroke = '#FFE8E8',
  rectStrokeWidth = '16',
  rectRx = '32',
  pathStroke = '#fff',
  pathStrokeWidth = '4',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <g>
        <rect
          width={rectWidth}
          height={rectHeight}
          x={rectX}
          y={rectY}
          fill={rectFill}
          stroke={rectStroke}
          strokeWidth={rectStrokeWidth}
          rx={rectRx}
        />
        <path
          stroke={pathStroke}
          strokeWidth={pathStrokeWidth}
          d="M30.5 51.167l20-20m0 20l-20-20"
        />
      </g>
    </svg>
  );
}
