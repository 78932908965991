import isEqual from 'lodash/isEqual';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../component/atom';
import { Add } from '../../component/atom/Icon/Icon.atom';
import VehicleSummaryInfo from '../../component/molecule/VehicleSummaryInfo/VehicleSummaryInfo.molecule';
import {
  JOAssignmentFormInitialValues,
  JOFormStep,
} from '../../constant/JobOrder.constant';
import { FormikHookProps } from '../../constant/Types.constant';
import {
  JobOrderForm,
  JOScheduleAssignmentType,
} from '../../model/JobOrder.model';
import { Vehicle, VehicleOrderBy } from '../../model/Vehicle.model';
import { logEvent } from '../../service/analytic/analytic.service';
import { navigationParamAction } from '../../store/param.store';
import { FormItem } from '../../types/input.type';
import { numberFormatter } from '../../util/formatter.util';
import { getNumericValue, removeLeadingZeros } from '../../util/helper.util';
import { joScheduleAssignmentTypeFormatter } from '../../util/jobOrderCreate.util';
import { jobOrderCreateRoute } from '../../view/JobOrderCreate/JobOrderCreate.route';
import { jobOrderDetailRoute } from '../../view/JobOrderDetail/JobOrderDetail.route';
import { joDriverAddRoute } from '../../view/JODriverAdd/JODriverAdd.route';
import { joeDriverAddRoute } from '../../view/JOEDriverAdd/JOEDriverAdd.route';
import useJOAssignmentForm from '../joAssignmentForm/useJOAssignmentForm.hook';
import { AutocompleteType } from '../useAutocomplete.hook';
import useGetDriversAutocomplete from '../useGetDriversAutocomplete.hook';
import useGetVehiclesAutocomplete from '../useGetVehiclesAutocomplete.hook';
import useTranslator from '../useTranslator.hook';
import { UseJOFormControllerHookObj } from './useJOFormController.hook';
import { UseJOFormInitializationHookObj } from './useJOFormInitialization.hook';

type Props = {
  joFormik: FormikHookProps<JobOrderForm>;
  controller: UseJOFormControllerHookObj;
  initialization: UseJOFormInitializationHookObj;
  hasUnsavedChanges?: boolean;
  vehicleDriverTempValue: JOScheduleAssignmentType;
  setVehicleDriverTempValue: (state: JOScheduleAssignmentType) => void;
  setHasUnsavedChanges: (val: boolean, callback?: () => void) => void;
};

const componentName = 'JOForm';
const eventCategory = 'JobOrder';
const events = {
  addDriver: `KTB_${componentName}_Button_AddDriver`,
};

export default function useJOFormAssignment({
  initialization: { infoInitValues, selectedSOListState },
  hasUnsavedChanges,
  vehicleDriverTempValue,
  controller: { deliveryDimensionTotal, handleChangeFormState },
  joFormik: {
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    setFieldTouched,
    setTouched,
  },
  setHasUnsavedChanges,
  setVehicleDriverTempValue,
}: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useTranslator();

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const joId = params?.id ?? '';
  const title = useMemo(
    () => translate('Assign Driver & Vehicle'),
    [translate],
  );

  // #region FETCH DRIVER LIST
  const {
    driverOptions,
    driverListFetchLoading,
    onChangeDriverAutotext: handleChangeDriverAutotext,
    onFetchMoreDriver: handleFetchMoreDriver,
  } = useGetDriversAutocomplete(true);

  const handleChangeDriverId = useCallback(
    async (data?: AutocompleteType) => {
      await setValues({
        ...values,
        driverId: data?.value,
        driverOption: data,
      });
      setTouched({
        ...touched,
        driverId: true,
        driverOption: true,
      });
      handleChangeDriverAutotext(undefined);
    },
    [handleChangeDriverAutotext, setValues, setTouched, touched, values],
  );

  const handleRemoveDriverId = useCallback(async () => {
    await setValues({
      ...values,
      driverId: undefined,
      driverOption: undefined,
    });
  }, [setValues, values]);
  // #endregion

  // #region FETCH VEHICLE LIST

  const {
    onChangeVehicleAutotext: handleChangeVehicleAutotext,
    onFetchMoreVehicle: handleFetchMoreVehicle,
    vehicleListFetchLoading,
    vehicleRows: vehicleOptions,
    onRefetchVehicle: handleRefetchVehicleList,
  } = useGetVehiclesAutocomplete({
    orderBy: VehicleOrderBy.RECOMMENDED,
    orderVolume: deliveryDimensionTotal.volume,
    orderWeight: deliveryDimensionTotal.weight,
  });

  const handleChangeVehicleId = useCallback(
    async (data?: Vehicle) => {
      await setValues({ ...values, vehicleId: data?.id, vehicleOption: data });
      setTouched({
        ...touched,
        vehicleId: true,
        vehicleOption: true,
      });
    },
    [setValues, touched, values, setTouched],
  );

  const handleRemoveVehicleId = useCallback(async () => {
    await setValues({
      ...values,
      vehicleId: undefined,
      vehicleOption: undefined,
    });
  }, [setValues, values]);
  // #endregion

  // #region Add Driver
  const handleAddNewDriver = useCallback(() => {
    logEvent(events.addDriver, eventCategory);
    const joPath = () => {
      if (!joId) return jobOrderCreateRoute.path;
      return jobOrderDetailRoute.path.replace(':id', joId);
    };

    dispatch(
      navigationParamAction.changeJobOrderDriverAddParams({
        formValues: values,
        formStep: JOFormStep.ASSIGNMENT,
        originPath: joPath(),
        ...(selectedSOListState && { selectedSO: selectedSOListState }),
      }),
    );
    if (setHasUnsavedChanges && hasUnsavedChanges) {
      setHasUnsavedChanges(false, () => {
        navigate(
          joId
            ? joeDriverAddRoute.path.replace(':id', joId)
            : joDriverAddRoute.path,
        );
      });
      return;
    }
    navigate(
      joId
        ? joeDriverAddRoute.path.replace(':id', joId)
        : joDriverAddRoute.path,
    );
  }, [
    dispatch,
    values,
    selectedSOListState,
    setHasUnsavedChanges,
    hasUnsavedChanges,
    navigate,
    joId,
  ]);
  // #endregion

  // #region VEHICLE DRIVER FORM VALUE
  const vehicleDriverFormValue = useCallback(
    (val: boolean) => {
      if (
        !val &&
        !isEqual(vehicleDriverTempValue, JOAssignmentFormInitialValues)
      ) {
        return vehicleDriverTempValue;
      }

      if (val && vehicleDriverTempValue.schedule) {
        return joScheduleAssignmentTypeFormatter(vehicleDriverTempValue);
      }
      return JOAssignmentFormInitialValues;
    },
    [vehicleDriverTempValue],
  );

  const handleChangeAssignmentType = useCallback(
    async (val: boolean) => {
      if (val === values.isUseAssignment) return;
      setIsErrorVisible(false);
      setVehicleDriverTempValue(joScheduleAssignmentTypeFormatter(values));
      const sanitizedData = vehicleDriverFormValue(val);
      await setValues({
        ...values,
        isUseAssignment: val,
        ...sanitizedData,
      });
      setTouched({
        ...touched,
        vehicleId: false,
        driverId: false,
        schedule: false,
      });
    },
    [
      values,
      touched,
      setValues,
      setTouched,
      vehicleDriverFormValue,
      setVehicleDriverTempValue,
    ],
  );
  // #endregion

  // #region Form
  const vehicleDriverFormData: FormItem[] = useMemo(
    () => [
      {
        id: 'driverId',
        insideLabel: values.isUseAssignment ? translate('Driver') : undefined,
        values: values.driverId,
        isRequired: true,
        placeholder: translate('Select Driver'),
        type: 'autocomplete',
        error:
          touched.driverId && errors.driverId
            ? translate(errors?.driverId)
            : '',
        selectedOption: values.driverOption,
        options: driverOptions,
        loading: driverListFetchLoading,
        disabled: !!values.isUseAssignment,
        children: !values.isUseAssignment ? (
          <Button.Icon
            icon={<Add />}
            label={translate('Add New Driver')}
            action={handleAddNewDriver}
          />
        ) : undefined,
        onBlur: () => {
          setFieldTouched('driverId');
        },
        onChange: handleChangeDriverId,
        onChangeAutoText: handleChangeDriverAutotext,
        onFetchMore: handleFetchMoreDriver,
        onRemoveData: handleRemoveDriverId,
      },
      {
        id: 'vehicleId',
        insideLabel: values.isUseAssignment ? translate('Vehicle') : undefined,
        values: values.vehicleId,
        placeholder: translate('Select Vehicle'),
        isRequired: true,
        disabled: !!values.isUseAssignment,
        type: 'autocomplete-vehicle',
        selectedOption: values.vehicleOption,
        error:
          touched.vehicleId && errors.vehicleId
            ? translate(errors?.vehicleId)
            : '',
        options: vehicleOptions,
        children: <VehicleSummaryInfo vehicle={values.vehicleOption} />,
        refetch: handleRefetchVehicleList,
        onChange: handleChangeVehicleId,
        onChangeAutoText: handleChangeVehicleAutotext,
        onFetchMore: handleFetchMoreVehicle,
        onRemoveData: handleRemoveVehicleId,
        onBlur: async () => {
          setFieldTouched('vehicleId');
        },
        loading: vehicleListFetchLoading,
      },
    ],
    [
      driverListFetchLoading,
      driverOptions,
      errors?.driverId,
      errors?.vehicleId,
      handleAddNewDriver,
      handleChangeDriverAutotext,
      handleChangeDriverId,
      handleChangeVehicleAutotext,
      handleChangeVehicleId,
      handleFetchMoreDriver,
      handleFetchMoreVehicle,
      handleRefetchVehicleList,
      handleRemoveDriverId,
      handleRemoveVehicleId,
      translate,
      setFieldTouched,
      touched.driverId,
      touched.vehicleId,
      values.driverId,
      values.driverOption,
      values.isUseAssignment,
      values.vehicleId,
      values.vehicleOption,
      vehicleListFetchLoading,
      vehicleOptions,
    ],
  );

  const formData: FormItem[] = useMemo(
    () => [
      {
        id: 'travelExpenses',
        values: values.travelExpenses
          ? numberFormatter(String(values.travelExpenses))
          : '',
        placeholder: translate('Rp'),
        label: translate('Travel Budget'),
        type: 'text',
        onChange: async (travelExpenses) => {
          const data = removeLeadingZeros(
            getNumericValue(travelExpenses as string),
          );
          await setFieldValue(
            'travelExpenses',
            infoInitValues ? data || null : data,
          );
          setFieldTouched('travelExpenses', true);
        },
        errorBold:
          (values?.travelExpenses || 0) <
          (infoInitValues?.approvedTravelBudget || 0)
            ? `Rp${numberFormatter(infoInitValues?.approvedTravelBudget || 0, {
                maximumFractionDigits: 0,
              })}`
            : '',
        error:
          touched.travelExpenses && errors.travelExpenses
            ? translate(errors?.travelExpenses)
            : '',
      },
    ],
    [
      touched.travelExpenses,
      errors?.travelExpenses,
      infoInitValues,
      setFieldValue,
      setFieldTouched,
      translate,
      values.travelExpenses,
    ],
  );
  // #endregion

  const handleBackHeader = useCallback(() => {
    handleChangeFormState(JOFormStep.SO_SELECTION);
  }, [handleChangeFormState]);

  const isVehicleDriverFormDisplayed = useMemo(() => {
    if (!values.isUseAssignment) return true;
    return !!values.isUseAssignment && !!values.schedule;
  }, [values.isUseAssignment, values.schedule]);

  const isFormValid = useMemo(
    () => !!values.driverId && !!values.vehicleId,
    [values.driverId, values.vehicleId],
  );

  const scheduleAssignment = useJOAssignmentForm({
    values,
    setValues,
    vehicleAutocompleteParams: {
      orderBy: VehicleOrderBy.RECOMMENDED,
      orderVolume: deliveryDimensionTotal.volume,
      orderWeight: deliveryDimensionTotal.weight,
    },
  });

  return {
    joId,
    title,
    formData,
    isUseAssignment: values.isUseAssignment,
    isFormValid,
    scheduleAssignment,
    vehicleDriverFormData,
    isVehicleDriverFormDisplayed,
    isScheduleFieldDisplayed:
      !!values.driverId && !!values.vehicleId && !!values.isUseAssignment,
    selectedSchedule: values.scheduleOption?.label,
    isErrorVisible,
    setIsErrorVisible,
    handleChangeAssignmentType,
    handleBackHeader,
  };
}

export type UseJOFormAssignmentObj = ReturnType<typeof useJOFormAssignment>;
