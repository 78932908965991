// #region IMPORT
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  OgranizationRevenue,
  OgranizationRevenueTotal,
  Summary,
  SummaryTotal,
  TopReceivableAge,
  TopReceivableAmount,
} from '../../../model/Summary.model';
import { Builder, QD } from '../../api.baseQuery';
import {
  ApiErrorResponse,
  ApiSuccessResponse,
  CommonApiResponse,
} from '../../api.endpoint';
// #endregion

// #region INTERFACE
// #region TOP RECEIVABLE AMOUNT
type DashboardTopAmountSuccessResponse = CommonApiResponse &
  ApiSuccessResponse & {
    topReceivableAmount: TopReceivableAmount[];
  };
type DashboardTopAmountErrorResponse = ApiErrorResponse;
export type DashboardTopAmountResponse =
  | DashboardTopAmountErrorResponse
  | DashboardTopAmountSuccessResponse;
// #endregion

// #region TOP RECEIVABLE AGE

type DashboardTopAgeSuccessResponse = ApiSuccessResponse & {
  topReceivableAge: TopReceivableAge[];
};
type DashboardTopAgeErrorResponse = ApiErrorResponse;
export type DashboardTopAgeResponse =
  | DashboardTopAgeSuccessResponse
  | DashboardTopAgeErrorResponse;
// #endregion

// #region ORGANIZATION REVENUE

type DashboardOrganizationRevenueSuccessResponse = ApiSuccessResponse & {
  revenues: OgranizationRevenue[];
};
type DashboardOrganizationRevenueErrorResponse = ApiErrorResponse;

export type DashboardOrganizationRevenueParam = {
  from: number;
  to: number;
  interval: SummaryIntervalParamType;
};

export type DashboardOrgnaizationRevenueResponse =
  | DashboardOrganizationRevenueSuccessResponse
  | DashboardOrganizationRevenueErrorResponse;
// #endregion

// #region ORGANIZATION REVENUE TOTAL

type DashboardOrganizationRevenueTotalSuccessResponse = ApiSuccessResponse & {
  total: OgranizationRevenueTotal;
};
type DashboardOrganizationRevenueTotalErrorResponse = ApiErrorResponse;
export type DashboardOrganizationRevenueTotalParam = {
  from: number;
  to: number;
};

export type DashboardOrgnaizationRevenueTotalResponse =
  | DashboardOrganizationRevenueTotalSuccessResponse
  | DashboardOrganizationRevenueTotalErrorResponse;
// #endregion

// #region ORGANIZATION SUMMARY
export enum SummaryIntervalParamType {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}

export type OrganizationSummaryParam = {
  from: number;
  to: number;
  interval: SummaryIntervalParamType;
};
type OgranizationSummarySuccessResponse = ApiSuccessResponse & {
  summary: Summary[];
};
type OgranizationSummaryErrorResponse = ApiErrorResponse;
export type OrganizationSummaryResponse =
  | OgranizationSummarySuccessResponse
  | OgranizationSummaryErrorResponse;
// #endregion

// #region ORGANIZATION SUMMARY TOTAL
export type OrganizationSummaryTotalParam = {
  from: number;
  to: number;
};
type OgranizationSummaryTotalSuccessResponse = ApiSuccessResponse & {
  total: SummaryTotal;
};
type OgranizationSummaryTotalErrorResponse = ApiErrorResponse;
export type OrganizationSummaryTotalResponse =
  | OgranizationSummaryTotalSuccessResponse
  | OgranizationSummaryTotalErrorResponse;
// #endregion

export type DashboardEndpoint = {
  getDashboardRevenueTopAmount: QD<void, DashboardTopAmountResponse>;
  getDashboardRevenueTopAge: QD<void, DashboardTopAgeResponse>;
  getDashboardOrganizationRevenue: QD<
    DashboardOrganizationRevenueParam,
    DashboardOrgnaizationRevenueResponse
  >;
  // #region SUMMARY
  // TODO: invalidate on force shipper order complete
  getOrganizationSummary: QD<
    OrganizationSummaryParam,
    OrganizationSummaryResponse
  >;
  getSummaryTotal: QD<void, OrganizationSummaryTotalResponse>;
  // #endregion
};
// #endregion

// #region ENDPOINT

export const dashboardTagTypes: EndpointTagTypes<DashboardEndpoint> = {
  getDashboardOrganizationRevenue: 'organization.revenue',
  getDashboardRevenueTopAge: 'revenue.top.age',
  getDashboardRevenueTopAmount: 'revenue.top.amount',
  getOrganizationSummary: 'organization.summary',
  getSummaryTotal: 'organization.summary.total',
};
export const financialTags = [
  dashboardTagTypes.getDashboardRevenueTopAge,
  dashboardTagTypes.getDashboardRevenueTopAmount,
  dashboardTagTypes.getDashboardOrganizationRevenue,
];
export const summaryTags = [
  dashboardTagTypes.getOrganizationSummary,
  dashboardTagTypes.getSummaryTotal,
];

export const dashboardEndpoint = (builder: Builder): DashboardEndpoint => ({
  getDashboardRevenueTopAmount: builder.query({
    query: () => '/v1/shipper.revenue.top.amount',
    providesTags: [dashboardTagTypes.getDashboardRevenueTopAmount],
  }),
  getDashboardRevenueTopAge: builder.query({
    query: () => '/v1/shipper.revenue.top.age',
    providesTags: [dashboardTagTypes.getDashboardRevenueTopAge],
  }),
  getDashboardOrganizationRevenue: builder.query({
    query: (param) => ({ url: urlcat('/v1/organizations.revenue', param) }),
    providesTags: [dashboardTagTypes.getDashboardOrganizationRevenue],
  }),
  getOrganizationSummary: builder.query({
    query: (param) => ({ url: urlcat('/v1/organizations.summary', param) }),
    providesTags: [dashboardTagTypes.getOrganizationSummary],
  }),
  getSummaryTotal: builder.query({
    query: () => '/v1/organizations.summary.total',
    providesTags: [dashboardTagTypes.getSummaryTotal],
  }),
});

// #endregion
