import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarTheme } from '../component/molecule/Snackbar/Snackbar.molecule';
import { SupportedLanguage } from '../config/locale/locale.config';
import { snackbarAction } from '../store/snackbar.store';
import { copyToClipboard } from '../util/helper.util';
import useSOShareDisclaimer, {
  SOTrackingDisclaimerType,
} from '../view/ShipperOrderDetail/hooks/useSOShareDisclaimer.hook';
import useScreenDetection from './useScreenDetection/useScreenDetection.hook';
import useTranslator from './useTranslator.hook';

export default function useCopyToClipboard({
  soId,
  valueToCopy,
}: { valueToCopy: string; soId?: string }) {
  const translator = useTranslator();
  const dispatch = useDispatch();
  const tooltipRef = useRef(null);

  const { disclaimerType, handleApproveDisclaimer, setDisclaimerType } =
    useSOShareDisclaimer({ soId: soId || '' });

  const { isMobile } = useScreenDetection();

  const [isCopied, setIsCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCloseDisclaimer = useCallback(() => {
    setDisclaimerType(undefined);
  }, [setDisclaimerType]);

  const handleCopy = useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.stopPropagation();
      copyToClipboard(valueToCopy);
      handleCloseDisclaimer();
      if (!soId) {
        setIsCopied(true);
      }
      if (isMobile || !!soId) {
        dispatch(
          snackbarAction.show({
            type: SnackbarTheme.dark,
            message: translator.translate(
              'Successfully copy tracking number',
              !soId ? SupportedLanguage.id : undefined,
            ),
          }),
        );
      }
    },
    [dispatch, handleCloseDisclaimer, translator, isMobile, soId, valueToCopy],
  );

  const handleClickCopy = useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!soId) return handleCopy(e);

      setDisclaimerType(SOTrackingDisclaimerType.COPY);
    },
    [setDisclaimerType, handleCopy, soId],
  );

  const handleDisclaimerClick = useCallback(() => {
    handleApproveDisclaimer({ onSuccess: handleCopy });
  }, [handleApproveDisclaimer, handleCopy]);

  const handleMouseEnter = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
    setIsCopied(false);
  }, []);

  return {
    translator,
    tooltipRef,
    disclaimerType,
    isMobile,
    isCopied,
    showTooltip,
    handleClickCopy,
    handleMouseEnter,
    handleMouseLeave,
    handleDisclaimerClick,
    handleCloseDisclaimer,
  };
}
