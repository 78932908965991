import { SettingNavigationItemType } from '../../../constant/Setting.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import useSettingResetData from './useSettingResetData.hook';

type Props = {
  currentMenu: SettingNavigationItemType;
  toggleModal: (menu?: SettingNavigationItemType) => void;
};
export default function useSettingAdvanced({
  currentMenu,
  toggleModal,
}: Props) {
  // #region GENERAL
  const { translate } = useTranslator();
  // #endregion

  // #region LABEL
  const label = translate('Reset All Organization Data');
  const description = translate(
    'This action will delete all data on current organization.',
  );
  const resetLabel = translate('Reset Data');
  // #endregion

  const resetData = useSettingResetData({ currentMenu, toggleModal });
  return {
    label,
    description,
    resetLabel,
    resetData,
  };
}
export type UseSettingAdvancedObj = ReturnType<typeof useSettingAdvanced>;
