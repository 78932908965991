import React, { cloneElement, ReactElement } from 'react';
import tw, { styled } from 'twin.macro';
import { ExtraStyle } from '../../Type.component';
import { HeadingFive, HeadingFour } from '../Text/Text.atom';

// #region INTERFACES
type Props = React.ComponentProps<'div'> & {
  label?: string;
  left?: ReactElement;
  right?: ReactElement;
  optOutRightDefaultSize?: boolean;
  numberLeft?: number | string;
  numberRight?: number | string;
  selected?: boolean;
  disabled?: boolean;
  title?: string;
  testID?: string;
  id?: string;
  labelStyle?: ExtraStyle;
};
// #endregion

// #region STYLED
const Container = styled.div(
  (props: { selected?: boolean; small?: boolean; disabled?: boolean }) => [
    tw`rounded-full cursor-pointer py-2 px-4 flex flex-row items-center space-x-2 duration-200 border border-solid  border-beige-lines text-grey-two hover:bg-orange-hover`,
    props.disabled &&
      tw`cursor-default border border-solid  border-grey-six hover:bg-grey-six bg-grey-six text-grey-three`,
    props.small && tw`py-1 px-3 `,
    props.selected &&
      tw`bg-orange-hover border border-solid  border-orange-hover text-orange`,
  ],
);
const NumberText = tw(HeadingFour)`bg-orange px-2 rounded-full text-white`;
const NumberSmall = tw(
  HeadingFive,
)`flex justify-center items-center w-5 h-5 rounded-full bg-orange text-white`;
// #endregion

export function Regular({
  optOutRightDefaultSize = false,
  left,
  right,
  label,
  numberLeft,
  numberRight,
  selected,
  onClick,
  disabled,
  className,
  title,
  testID,
  id,
  labelStyle,
}: Props) {
  return (
    <Container
      id={id}
      selected={selected}
      disabled={disabled}
      className={className}
      onClick={disabled ? undefined : onClick}
      data-testid={testID}
      title={title}
    >
      {!!numberLeft && <NumberText>{numberLeft}</NumberText>}
      {left && cloneElement(left, { width: 24, height: 24 })}
      {!!label && (
        <HeadingFour
          tw="text-current whitespace-nowrap overflow-hidden overflow-ellipsis"
          css={labelStyle}
        >
          {label}
        </HeadingFour>
      )}
      {right &&
        cloneElement(
          right,
          optOutRightDefaultSize ? undefined : { width: 24, height: 24 },
        )}
      {!!numberRight && <NumberText>{numberRight}</NumberText>}
    </Container>
  );
}

export function Small({
  optOutRightDefaultSize = false,
  left,
  right,
  label,
  numberLeft,
  numberRight,
  selected,
  className,
  disabled,
  onClick,
  title,
  testID,
  id,
  labelStyle,
}: Props) {
  return (
    <Container
      id={id}
      disabled={disabled}
      selected={selected}
      small
      className={className}
      onClick={disabled ? undefined : onClick}
      data-testid={testID}
      title={title}
    >
      {!!numberLeft && <NumberSmall>{numberLeft}</NumberSmall>}
      {left && cloneElement(left, { width: 20, height: 20 })}
      {!!label && (
        <HeadingFive
          tw="text-current whitespace-nowrap overflow-hidden overflow-ellipsis"
          css={labelStyle}
        >
          {label}
        </HeadingFive>
      )}
      {right &&
        cloneElement(
          right,
          optOutRightDefaultSize ? undefined : { width: 20, height: 20 },
        )}
      {!!numberRight && <NumberSmall>{numberRight}</NumberSmall>}
    </Container>
  );
}
