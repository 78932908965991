import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { HaulingOrderSearchParam } from '../../model/HaulingOrder.model';

export type HaulingJORouteParam =
  | {
      searchParamsFromHOList?: HaulingOrderSearchParam;
    }
  | undefined;

export const haulingJORoute: RouteConfig = {
  name: 'Hauling Order Assignment',
  Component: React.lazy(() => import('./HaulingJO.view')),
  path: '/hauling-order/:hoId/assignment/:deliveryId',
  isPrivate: true,

  breadcrumb: 'Hauling Order',
};
