import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export type DispatchWithCallback<A, S> = (
  value: A,
  callback?: Callback<S>,
) => void;

type Callback<S> = (state: S) => unknown | (() => unknown | undefined);

export default function useStateWithCallbackLazy<S>(
  initialValue: S,
): [S, DispatchWithCallback<S, SetStateAction<S>>] {
  const callbackRef = useRef<((state: S) => void) | null | undefined>(null);

  const [value, setValue] = useState<S>(initialValue);

  useEffect(() => {
    if (!callbackRef.current) return;
    callbackRef.current(value);
    callbackRef.current = null;
  }, [value]);

  const setValueWithCallback = useCallback(
    (newValue: S, callback?: Callback<S>) => {
      callbackRef.current = callback;
      setValue(newValue);
    },
    [],
  );

  return [value, setValueWithCallback];
}
