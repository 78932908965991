import React from 'react';

export default function Customer({
  fill = 'currentColor',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M4.323 2.267a10 10 0 0112.325 15.705A9.969 9.969 0 0110 20.5 10 10 0 014.324 2.267zm1.142 14.825A7.966 7.966 0 009.999 18.5h.003a7.97 7.97 0 004.534-1.408 6.486 6.486 0 00-4.535-1.842H10a6.488 6.488 0 00-4.535 1.842zm10.566-1.334A8.002 8.002 0 0010 2.5a8 8 0 00-6.03 13.258A8.489 8.489 0 0110 13.25a8.487 8.487 0 016.03 2.508zM10 13.25v1l-.001-1zm0-7a2 2 0 100 4 2 2 0 000-4zM7.171 5.42a4 4 0 115.657 5.657 4 4 0 01-5.657-5.657z"
        clipRule="evenodd"
      />
    </svg>
  );
}
