import React from 'react';
import tw, { styled, theme } from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { Button, Icon, Text } from '../../atom';

// #region STYLED
const ErrorFallbackContainer = styled.div`
  ${tw`bg-beige-bg min-h-screen min-w-full flex flex-col justify-center items-center space-y-5`}

  pre code {
    ${tw`block max-w-full min-width[100px] bg-beige-lines p-5 rounded-lg border-l-4 border-l-deleted-dark whitespace-pre overflow-x-auto scrollbar-width[thin]`}
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar {
      ${tw`width[7px] height[7px] border-radius[10px]`}
    }
    ::-webkit-scrollbar-track {
      ${tw`border-radius[10px]`}
    }
    ::-webkit-scrollbar-thumb {
      ${tw`border-radius[10px] bg-orange-four`}
    }
  }
`;
// #endregion

export default function ErrorBoundaryFallback() {
  const translator = useTranslator();

  const resetApp = () => window.location.assign(window.location.origin);

  return (
    <ErrorFallbackContainer role="alert">
      <Icon.Error height={48} width={48} fill={theme`colors.deleted.dark`} />

      <Text.BodyFourteen tw="text-grey-three text-center">
        {translator.translate('Sorry, we have encountered a problem.')}
      </Text.BodyFourteen>

      <Button.Solid tw="mt-10" onClick={resetApp}>
        {translator.translate('Reload App')}
      </Button.Solid>
    </ErrorFallbackContainer>
  );
}
