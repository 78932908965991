import useTranslator from '../../hook/useTranslator.hook';
import useDeliveryLocationDeleteLogic from './hooks/useDeliveryLocationDeleteLogic.hook';
import useDeliveryLocationDeleteTable from './hooks/useDeliveryLocationDeleteTable.hook';

export default function useDriverDeactivateViewModel() {
  const translator = useTranslator();

  const deliveryLocationDeleteLogic = useDeliveryLocationDeleteLogic();
  const deliveryLocationDeleteTable = useDeliveryLocationDeleteTable({
    deliveryLocationDeleteLogic,
  });

  return {
    translator,
    deliveryLocationDeleteLogic,
    deliveryLocationDeleteTable,
  };
}
