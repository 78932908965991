import React from 'react';
import tw, { theme } from 'twin.macro';
import { UseGetShippersAutocompleteObj } from '../../../hook/useGetShippersAutocomplete.hook';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import { UseShipperOrderFilter } from '../../../view/ShipperOrder/hooks/useShipperOrderFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region INTERFACES
type ShipperOrderFilterProps = {
  shipperOrderFilter: UseShipperOrderFilter;
  shippersAutocomplete: UseGetShippersAutocompleteObj;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow sticky bottom-0 overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
const DatePickerContainer = tw.div`mt-3 flex items-center justify-between space-x-2`;
// #endregion

function ShipperOrderFilter({
  shipperOrderFilter,
  shippersAutocomplete,
}: ShipperOrderFilterProps) {
  const { isMobile } = useScreenDetection();

  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <Text.HeadingFour
          tw="flex-1 ml-2"
          data-testid={`${shipperOrderFilter.testID || ''}Filter:Title`}
        >
          {shipperOrderFilter.titleLabel}
        </Text.HeadingFour>

        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          data-testid={`${shipperOrderFilter.testID || ''}Filter:ResetAction`}
          onClick={shipperOrderFilter.onClickResetFilter}
        >
          {shipperOrderFilter.resetButtonLabel}
        </Button.Outlined>
      </Header>
      <Divider />
      <Filters>
        <FilterItemContainer>
          <InputHeading
            data-testid={`${
              shipperOrderFilter.testID || ''
            }Filter:OrderByLabel`}
          >
            {shipperOrderFilter.orderByLabel}
          </InputHeading>

          <ChipContainer>
            {shipperOrderFilter.filterOrderByValues.map((el, i) => {
              const selected =
                shipperOrderFilter.filterForm.values.orderBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      shipperOrderFilter.onClickOrderByChipFilter(el.value)
                    }
                    testID={`${
                      shipperOrderFilter.testID || ''
                    }Filter:OrderByOption:${i}`}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${
                shipperOrderFilter.testID || ''
              }Filter:OrderStatusLabel`}
            >
              {shipperOrderFilter.orderStatusLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                shipperOrderFilter.isQueryStatusAll
                  ? shipperOrderFilter.onClickUnselectAllStatus
                  : shipperOrderFilter.onClickSelectAllStatus
              }
            >
              {shipperOrderFilter.isQueryStatusAll
                ? shipperOrderFilter.unselectAllLabel
                : shipperOrderFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {shipperOrderFilter.filterOrderStatusValues.map((el, i) => {
              const selected =
                shipperOrderFilter.filterForm.values.status.includes(el.value);

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      shipperOrderFilter.onClickStatusChipFilter(el.value)
                    }
                    testID={`${
                      shipperOrderFilter.testID || ''
                    }Filter:OrderStatusOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {shipperOrderFilter.isQueryStatusEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {shipperOrderFilter.errorOrderStatusLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>

        {!isMobile && (
          <FilterItemContainer>
            <FilterItemHeader>
              <InputHeading
                data-testid={`${
                  shipperOrderFilter.testID || ''
                }Filter:PaymentStatusLabel`}
              >
                {shipperOrderFilter.paymentStatusLabel}
              </InputHeading>

              <Button.Text
                small
                onClick={
                  shipperOrderFilter.isQueryPaymentStatusAll
                    ? shipperOrderFilter.onClickUnselectAllPaymentStatus
                    : shipperOrderFilter.onClickSelectAllPaymentStatus
                }
              >
                {shipperOrderFilter.isQueryPaymentStatusAll
                  ? shipperOrderFilter.unselectAllLabel
                  : shipperOrderFilter.selectAllLabel}
              </Button.Text>
            </FilterItemHeader>

            <ChipContainer>
              {shipperOrderFilter.filterPaymentStatusValues.map((el, i) => {
                const selected =
                  shipperOrderFilter.filterForm.values.paymentStatus.includes(
                    el.value,
                  );

                return (
                  <FilterChip key={`Filter_Category_${el.value}`}>
                    <Chip.Small
                      tw="text-grey-two"
                      css={[selected && tw`border-white text-orange`]}
                      label={el.label}
                      selected={selected}
                      onClick={() =>
                        shipperOrderFilter.onClickPaymentStatusChipFilter(
                          el.value,
                        )
                      }
                      testID={`${
                        shipperOrderFilter.testID || ''
                      }Filter:PaymentStatusOption:${i}`}
                      right={
                        selected ? (
                          <Icon.CheckCircle
                            height={20}
                            width={20}
                            viewBox="0 0 28 28"
                            fill={theme`colors.orange.DEFAULT`}
                          />
                        ) : (
                          <Icon.Plus
                            height={12}
                            width={12}
                            viewBox="0 0 12 12"
                          />
                        )
                      }
                      optOutRightDefaultSize
                    />
                  </FilterChip>
                );
              })}
            </ChipContainer>

            {shipperOrderFilter.isQueryPaymentStatusEmpty && (
              <div tw="flex items-center gap-2 animate-fade-in">
                <Icon.CloseOutlined
                  height="1em"
                  width="1em"
                  viewBox="0 0 36 36"
                />
                <Text.BodySmallTwelve tw="text-semantic-error">
                  {shipperOrderFilter.errorPaymentStatusLabel}
                </Text.BodySmallTwelve>
              </div>
            )}
          </FilterItemContainer>
        )}

        <FilterItemContainer>
          <InputHeading
            data-testid={`${
              shipperOrderFilter.testID || ''
            }Filter:ShipperLabel`}
          >
            {shipperOrderFilter.shipperLabel}
          </InputHeading>

          <AutoComplete
            tw="bg-orange-dark-lines"
            value={shipperOrderFilter.filterForm.values.shipperId}
            datas={shippersAutocomplete.shipperOptions}
            selectedOption={shipperOrderFilter.queryShipperOption}
            placeholder={shipperOrderFilter.shipperPlaceholder}
            loading={shippersAutocomplete.shipperListFetchLoading}
            changeData={shipperOrderFilter.onChangeShipperFilter}
            changeInputText={shippersAutocomplete.onChangeShipperAutotext}
            onFetchMore={shippersAutocomplete.onFetchMoreShipper}
            onRemoveData={shipperOrderFilter.onRemoveShipperFilter}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${
              shipperOrderFilter.testID || ''
            }Filter:DateRangeLabel`}
          >
            {shipperOrderFilter.dateLabel}
          </InputHeading>

          <DatePickerContainer>
            <DateRangePickerInput
              sdLabel={shipperOrderFilter.sdLabel}
              edLabel={shipperOrderFilter.edLabel}
              sdDateValue={shipperOrderFilter.filterForm.values.startDate}
              sdOnChangeDateValue={shipperOrderFilter.onChangeStartDateFilter}
              edDateValue={shipperOrderFilter.filterForm.values.endDate}
              edOnChangeDateValue={shipperOrderFilter.onChangeEndDateFilter}
              onClickApplyDateRange={shipperOrderFilter.onClickApplyDateRange}
              definedRangeOptions={
                shipperOrderFilter.dateRangeDefinedRangeOptions
              }
              outsideAlerterStyle={{ width: '100%' }}
              allowUndefinedDateRange
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={
            shipperOrderFilter.isQueryStatusEmpty ||
            shipperOrderFilter.isQueryPaymentStatusEmpty ||
            shipperOrderFilter.filterLocalIsFetching
          }
          onClick={shipperOrderFilter.onClickSubmitFilter}
          data-testid={`${shipperOrderFilter.testID || ''}Filter:SubmitButton`}
        >
          {shipperOrderFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default ShipperOrderFilter;
