import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// #region INTERFACES
export type NavbarStore = {
  isExpanded: boolean;
  isHovered: boolean;
};
// #endregion

const initialState: NavbarStore = {
  isExpanded: true,
  isHovered: false,
};

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setExpandNavbar: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isExpanded: action.payload,
    }),
    setHoverNavbar: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isHovered: action.payload,
    }),
  },
});

export const navbarAction = navbarSlice.actions;
export const navbarReducer = navbarSlice.reducer;
