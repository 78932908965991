import { LegalNavSection } from '../view/Legal/Legal.viewModel';

export function isElementVisible(el: HTMLDivElement | null): boolean {
  if (!el) return false;
  const rect = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight;
  const efp = (x: number, y: number) => document.elementFromPoint(x, y);

  // Return false if it's not in the viewport
  if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  )
    return false;

  // Return true if any of its four corners are visible
  return (
    el.contains(efp(rect.left, rect.top)) ||
    el.contains(efp(rect.right, rect.top)) ||
    el.contains(efp(rect.right, rect.bottom)) ||
    el.contains(efp(rect.left, rect.bottom))
  );
}

/**
 * @param  {LegalNavSection} status
 * @returns string
 *
 * @summary map through `LegalNavSection` enum to get the `status` label
 */
export function mapLegalNavSectionToLabel(status?: LegalNavSection): string {
  switch (status) {
    case LegalNavSection.DISCLAIMER:
      return 'Disclaimer';
    case LegalNavSection.PRIVACY:
      return 'Privacy Policy';
    case LegalNavSection.TERMS:
      return 'Terms of Use';
    default:
      return 'Disclaimer';
  }
}

/**
 *
 * @param {number} offsetTop
 * @returns void
 *
 * @summary scroll to offset top
 */
export const handleScrollIntoViewLegal = ({
  offsetTop = 0,
}: { offsetTop?: number }) => {
  window.scrollTo({ top: offsetTop });
};
