import { FormikErrors } from 'formik';
import { useMemo } from 'react';
import tw from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { IOrganizationSetting } from '../../../model/Organization.model';
import { FormItem } from '../../../types/input.type';

interface Props {
  values: IOrganizationSetting;
  setFieldValue: (
    field: string,
    value?: boolean,
  ) => Promise<void> | Promise<FormikErrors<IOrganizationSetting>>;
}

export type UseSettingDriverManagementHookObj = ReturnType<
  typeof useSettingDriverManagement
>;

export default function useSettingDriverManagement({
  values,
  setFieldValue,
}: Props) {
  // #region GENERAL
  const { translate } = useTranslator();
  // #endregion

  // #region FORM

  const formData = useMemo(
    (): FormItem[] => [
      {
        id: 'isProofOfActivityRequired',
        label: translate('Required Proof of Activity'),
        type: 'switch',
        description: translate(
          'The driver must upload picture(s) with notes as proof of activity in order to complete the job order. This will be applied to newly created Job Order.',
        ),
        values: values.isProofOfActivityRequired,
        onChange: (val) => {
          void setFieldValue('isProofOfActivityRequired', val);
        },
        containerStyle: tw`flex-1 justify-between`,
        wrapperStyle: tw`flex flex-1 pr-2`,
        labelStyle: tw`text-black`,
        descriptionLabelStyle: tw`text-grey-two`,
      },
      {
        id: 'isPhotoGalleryAllowed',
        label: translate("Allows upload photo from driver's gallery"),
        type: 'switch',
        description: translate(
          'Driver can take photo of proof of activity from phone gallery.',
        ),
        values: values.isPhotoGalleryAllowed,
        onChange: (val) => {
          void setFieldValue('isPhotoGalleryAllowed', val);
        },
        containerStyle: tw`flex-1 justify-between`,
        wrapperStyle: tw`flex flex-1 pr-2`,
        labelStyle: tw`text-black`,
        descriptionLabelStyle: tw`text-grey-two`,
      },
    ],
    [
      translate,
      values.isProofOfActivityRequired,
      values.isPhotoGalleryAllowed,
      setFieldValue,
    ],
  );

  // #endregion

  return {
    formData,
  };
}
