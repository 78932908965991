import fromUnixTime from 'date-fns/fromUnixTime';
import React, { useCallback } from 'react';
import tw, { styled, theme } from 'twin.macro';
import { Icon, Input, Text } from '../../component/atom';
import {
  CustomTableRowWithPopover,
  ShipperOrderStatus,
} from '../../component/molecule';
import { SelectionSO, SOActivityType } from '../../constant';
import { JobOrderForm } from '../../model/JobOrder.model';
import { getDateFormat } from '../../util/date.util';
import {
  getJOSOSelectionCurrentLocationAddress,
  getJOSOSelectionCurrentLocationLabel,
  getLocationAddress,
  getLocationLabel,
} from '../../util/jobOrderCreate.util';
import { mapSOFilterStatusToLabel } from '../../util/shipperOrder.util';
import useTranslator from '../useTranslator.hook';
import { UseJOFormControllerHookObj } from './useJOFormController.hook';
import { UseJOFormDeliveryLocationModalHookObj } from './useJOFormDeliveryLocationModal.hook';

// #region STYLED
const ShipperContainer = tw.div`w-[85px] whitespace-pre-wrap overflow-hidden text-overflow[ellipsis] display[-webkit-box] -webkit-line-clamp[3] -webkit-box-orient[vertical]`;
const InputCheckbox = tw(
  Input,
)`p-2.5 ml-0.5 cursor-pointer hover:bg-orange focus:ring-orange checked:bg-orange checked:focus:bg-orange checked:hover:bg-orange`;
const SOStatusContainer = tw.div`truncate w-[140px]`;
const Underline = styled.div((props: { filled: boolean; error: boolean }) => [
  tw`absolute bottom-0 w-full h-0.5 bg-grey-two peer-hover:bg-orange peer-focus:bg-orange group-hover:bg-orange`,
  props.filled && tw`bg-black`,
  props.error &&
    tw`bg-status-alert peer-hover:bg-status-alert peer-focus:bg-status-alert group-hover:bg-status-alert`,
]);
const DeliveryLocationRoot = tw.div`h-11 w-[280px] grid relative border rounded-tl rounded-tr border-beige-lines`;
const DeliveryLocationInput = tw.input`flex w-full h-full p-3 pr-12 outline-none cursor-default rounded disabled:(bg-grey-six text-grey-three)`;
const DeliveryLocationButton = tw.button`absolute z-10 rotate-90 top-0 right-0 p-2`;
const DeliveryLocationErrorLabel = tw(Text.BodyTwelve)`pl-3 text-status-alert`;
const SODateLabel = tw(Text.Label)`w-[85px]`;
const RowContainerStyle = tw`w-[240px] min-height[48px]`;
// #endregion

// #region INTERFACES
type Props = {
  values: JobOrderForm;
  controller: UseJOFormControllerHookObj;
  locationModalController: UseJOFormDeliveryLocationModalHookObj;
};
// #endregion

export default function useJOFormSORenderRow({
  values,
  controller: {
    soController: { selectedSOIds, handleClickCheckbox },
    handleOpenActivityModal,
  },
  locationModalController: { handleOpenDeliveryLocationModal, handleSelectSO },
}: Props) {
  const translator = useTranslator();

  const formatRenderRows = useCallback(
    (SOList: SelectionSO[], isCandidate?: boolean) => {
      if (!SOList.length) return [];

      return SOList.map((so) => {
        const isCheckedForm = values.deliveries?.some((v) => v.soId === so.id);
        const checkedCheckbox =
          selectedSOIds.includes(so.id) || (!!isCheckedForm && isCandidate);
        const isLastMile = !so.isTransitable;
        const disabled = checkedCheckbox || isLastMile;

        const pickups = so.activities.filter(
          (_activity) => _activity.type === SOActivityType.PICK_UP,
        );
        const showDeliveryLocationError =
          !isLastMile &&
          !!so.deliveryLocation &&
          (so?.transitLocation
            ? so.deliveryLocation.id === so.transitLocation.id
            : so.deliveryLocation.id === pickups[0].location.id); // transitable SO always has 1 pickup & 1 dropoff

        return {
          id: so.id,
          check: (
            <InputCheckbox
              type="checkbox"
              id={so.id}
              name={so.id}
              value={so.id}
              disabled={isCandidate && isCheckedForm}
              checked={checkedCheckbox}
              onChange={() => {
                handleClickCheckbox(so, isCandidate);
              }}
            />
          ),
          number: (
            <CustomTableRowWithPopover
              tooltipWithPortal
              tooltipContainerStyle={tw`max-w-[200px]`}
              containerStyle={tw`w-[130px] min-height[48px]`}
              primaryLabelStyle={tw`font-bold whitespace-normal -webkit-line-clamp[2] display[-webkit-box] -webkit-box-orient[vertical]`}
              primaryLabel={so.number}
              secondaryLabel={so.referenceNumber ?? '-'}
            />
          ),
          pickupLocation: (
            <CustomTableRowWithPopover
              tooltipWithPortal
              containerStyle={RowContainerStyle}
              onClickPrimary={() => handleOpenActivityModal(so.id)}
              primaryLabel={getLocationLabel(
                so.activities,
                SOActivityType.PICK_UP,
                translator,
              )}
              secondaryLabel={getLocationAddress(
                so.activities,
                SOActivityType.PICK_UP,
              )}
            />
          ),
          dropoffLocation: (
            <CustomTableRowWithPopover
              tooltipWithPortal
              onClickPrimary={() => handleOpenActivityModal(so.id)}
              containerStyle={RowContainerStyle}
              primaryLabel={getLocationLabel(
                so.activities,
                SOActivityType.DROP_OFF,
                translator,
              )}
              secondaryLabel={getLocationAddress(
                so.activities,
                SOActivityType.DROP_OFF,
              )}
            />
          ),
          currentLocation: (
            <CustomTableRowWithPopover
              tooltipWithPortal
              onClickPrimary={() => handleOpenActivityModal(so.id)}
              containerStyle={RowContainerStyle}
              primaryLabel={getJOSOSelectionCurrentLocationLabel(
                so,
                translator,
              )}
              secondaryLabel={getJOSOSelectionCurrentLocationAddress(so)}
            />
          ),
          deliveryLocation: (
            <>
              <DeliveryLocationRoot css={[disabled && tw`border-0`]}>
                <DeliveryLocationInput
                  readOnly
                  disabled={disabled}
                  placeholder={
                    so.deliveryLocation
                      ? so.deliveryLocation.name
                      : translator.translate(
                          isLastMile ? 'Last Mile' : 'Select Delivery Location',
                        )
                  }
                  onClick={() => {
                    handleOpenDeliveryLocationModal();
                    handleSelectSO(so);
                  }}
                />
                {!disabled && (
                  <>
                    <DeliveryLocationButton
                      type="button"
                      css={[showDeliveryLocationError && tw`rotate-0`]}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDeliveryLocationModal();
                        handleSelectSO(so);
                      }}
                    >
                      {showDeliveryLocationError ? (
                        <Icon.Error fill={theme`colors.status.alert.DEFAULT`} />
                      ) : (
                        <Icon.ChevronRounded stroke={theme`colors.grey.two`} />
                      )}
                    </DeliveryLocationButton>
                    <Underline filled error={showDeliveryLocationError} />
                  </>
                )}
              </DeliveryLocationRoot>

              {showDeliveryLocationError && (
                <DeliveryLocationErrorLabel>
                  {translator.translate(
                    "Can't be the same as Current Location",
                  )}
                </DeliveryLocationErrorLabel>
              )}
            </>
          ),
          status: (
            <SOStatusContainer>
              <ShipperOrderStatus
                status={so.status}
                statusLabel={translator.translate(
                  mapSOFilterStatusToLabel(so.status),
                )}
              />
            </SOStatusContainer>
          ),
          soDate: (
            <SODateLabel>{getDateFormat(fromUnixTime(so.date))}</SODateLabel>
          ),
          shipper: (
            <ShipperContainer>
              <Text.Label>{so?.shipper?.name}</Text.Label>
            </ShipperContainer>
          ),
        };
      });
    },
    [
      handleClickCheckbox,
      handleOpenActivityModal,
      handleOpenDeliveryLocationModal,
      handleSelectSO,
      selectedSOIds,
      translator,
      values.deliveries,
    ],
  );

  return { formatRenderRows };
}

export type UseJOFormSORenderRowHookObj = ReturnType<
  typeof useJOFormSORenderRow
>;
