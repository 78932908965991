import { useWindowSize } from 'react-use';

const useScreenDetection = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 576;

  return {
    windowSize,
    isMobile,
  };
};

export type UseScreenDetectionObj = ReturnType<typeof useScreenDetection>;
export default useScreenDetection;
