import React from 'react';
import { Icon } from '../../component/atom';
import { RouteConfig } from '../../config/router/router.config';
import { LocationSearchParam } from '../../model/Location.model';
import { SOActivityFormProps } from '../../model/ShipperOrder.model';
import { ShipperOrderCreateNavigateParams } from '../ShipperOrderCreate/ShipperOrderCreate.route';

export type DeliveryLocationAddRouteParam =
  | (ShipperOrderCreateNavigateParams & {
      activityIndex?: number;
      soActivityState?: SOActivityFormProps;
      searchParamsFromLocationList?: LocationSearchParam;
    })
  | undefined;

export const deliveryLocationAddRoute: RouteConfig = {
  name: 'Add Delivery Location',
  Component: React.lazy(() => import('./DeliveryLocationAdd.view')),
  path: '/delivery-location/add',
  isPrivate: true,
  breadcrumb: 'Add Delivery Location',
  options: {
    icon: <Icon.DeliveryLocation />,
    label: 'Delivery Location',
    testID: 'MenuDeliveryLocationAdd',
  },
};
