import { Locale } from './locale.config';

const filterScheduleLocaleConfig: Locale[] = [
  {
    en: 'Driver Name A-Z',
    id: 'Nama Sopir A-Z',
  },
  {
    en: 'Driver Name Z-A',
    id: 'Nama Sopir Z-A',
  },
  {
    en: 'Vehicle A-Z',
    id: 'Kendaraan A-Z',
  },
  {
    en: 'Vehicle Z-A',
    id: 'Kendaraan Z-A',
  },
  {
    en: 'Filter Schedule',
    id: 'Filter Jadwal',
  },
  {
    en: 'Add Schedule',
    id: 'Tambah Jadwal',
  },
  {
    en: 'Schedule',
    id: 'Jadwal',
  },
  {
    en: 'Scheduling',
    id: 'Penjadwalan',
  },
  {
    en: 'Apply',
    id: 'Terapkan',
  },
  {
    en: 'Maximum 31 days period including the start date',
    id: 'Rentang tanggal maksimal 31 hari termasuk tanggal mulai',
  },
  {
    en: 'Please make sure that the start date is below the end date to display the correct data',
    id: 'Pastikan tanggal mulai dibawah tanggal akhir untuk menampilkan data yang benar',
  },
  {
    en: 'Please make sure that the date range is within',
    id: 'Pastikan rentang waktu dalam',
  },
  {
    en: 'days to display the correct data',
    id: 'hari untuk menampilkan data yang benar',
  },
  {
    en: 'You can search by Driver Name or Vehicle',
    id: 'Cari menggunakan Nama Sopir atau Kendaraan',
  },
];

export default filterScheduleLocaleConfig;
