import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fillPath?: string;
};
export default function Help({
  fill = 'none',
  width = 21,
  height = 20,
  viewBox = '0 0 21 20',
  fillPath = '#616161',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <path
        fill={fillPath}
        d="M8.293 8h-2v-.05c0-.484-.002-1.473.517-2.34C7.415 4.603 8.55 4 10.293 4c1.744 0 2.877.602 3.482 1.61.52.867.519 1.856.518 2.34V8c0 .932-.237 1.678-.62 2.293-.368.595-.847 1.023-1.24 1.35l-.273.225c-.281.23-.478.391-.639.58-.165.192-.228.344-.228.552h-2c0-.792.312-1.39.71-1.854.288-.337.664-.643.964-.887l.186-.152c.357-.298.628-.558.822-.869.18-.291.318-.67.318-1.238 0-.667-.4-2-2-2s-2 1.333-2 2zM11.293 14h-2v2h2v-2z"
      />
      <path
        fill={fillPath}
        fillRule="evenodd"
        d="M10.293 0c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm-8 10a8 8 0 1016 0 8 8 0 00-16 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}
