import { Locale } from './locale.config';

const drivingContestLocaleConfig: Locale[] = [
  {
    en: 'Register',
    id: 'Daftar',
  },
  {
    en: 'to',
    id: 'hingga',
  },
  {
    en: 'Register Drivers',
    id: 'Daftarkan Sopir',
  },
  {
    en: 'Visit ',
    id: 'Kunjungi halaman ',
  },
  {
    en: 'to see driver performance.',
    id: 'untuk melihat performa sopir.',
  },
  {
    en: 'The Driver Contest event will take place from %s. These are the schedule for the Driver Contest event:',
    id: 'Event Driver Contest akan berlangsung pada tanggal %s. Berikut susunan jadwal event Driver Contest:',
  },
  {
    en: 'Registration Period: %s',
    id: 'Periode Registrasi: %s',
  },
  {
    en: 'Assessment Period: %s',
    id: 'Periode Penilaian: %s',
  },
  {
    en: 'You can also see driver performance on',
    id: 'Anda juga bisa melihat performa sopir pada halaman',
  },
  {
    en: 'Driving Behavior by Schedule page.',
    id: 'Perilaku Mengemudi (Jadwal).',
  },
  {
    en: 'to add a Schedule.',
    id: 'untuk menambah Jadwal.',
  },
  {
    en: 'Drivers do not have a schedule yet during the Driver Contest period.',
    id: 'Sopir belum memiliki penjadwalan pada periode Driver Contest.',
  },
  {
    en: 'Drivers do not have a schedule yet during the Driver Contest period. Click',
    id: 'Sopir belum memiliki penjadwalan pada periode Driver Contest. Klik',
  },
  {
    en: 'Driver Contest %s has been started!',
    id: 'Driver Contest %s telah dimulai!',
  },
  {
    en: 'Driver Contest %s has been started! Access this page using your desktop to find out more.',
    id: 'Driver Contest %s telah dimulai! Akses melalui desktop Anda untuk info lebih lanjut.',
  },
  {
    en: 'Full Name A-Z',
    id: 'Nama Lengkap A-Z',
  },
  {
    en: 'Full Name Z-A',
    id: 'Nama Lengkap Z-A',
  },
  {
    en: 'Show %s Driver',
    id: 'Tampilkan %s Sopir',
  },
  {
    en: 'Try a different search terms so we can show you some available Driver Contest',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Driver Contest yang tersedia',
  },
  {
    en: 'There is no Available Driver',
    id: 'Sopir tidak tersedia',
  },
  {
    en: 'View Schedule',
    id: 'Lihat Jadwal',
  },
  {
    en: 'Driver Contest Status',
    id: 'Status Driver Contest',
  },
  {
    en: 'Add driver by clicking the button below',
    id: 'Tambahkan Sopir dengan menekan tombol dibawah ini',
  },
  {
    en: 'Driver Contest %s has been started!',
    id: 'Driver Contest %s telah dimulai!',
  },
  {
    en: 'Remove Driver as Participant',
    id: 'Hapus Sopir sebagai Peserta',
  },
  {
    en: 'Ready',
    id: 'Siap',
  },
  {
    en: 'Not Ready',
    id: 'Belum Siap',
  },
  {
    en: 'Schedule Date',
    id: 'Tanggal Jadwal',
  },
  {
    en: 'Cannot select overlapping schedule',
    id: 'Tidak dapat memilih jadwal yang tumpang tindih',
  },
  {
    en: 'You can search by Driver Name, or Phone Number',
    id: 'Cari menggunakan Nama Sopir atau Nomor Telepon',
  },
];

export default drivingContestLocaleConfig;
