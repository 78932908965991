import React from 'react';
import tw, { theme } from 'twin.macro';
import { Tooltip } from '..';
import useCopyToClipboard from '../../../hook/useCopyToClipboard.hook';
import { Icon, IconButton, Text } from '../../atom';
import { BodyFourteen } from '../../atom/Text/Text.atom';
import { ExtraStyle } from '../../Type.component';
import SOTrackingDisclaimerModal from '../SOTrackingDisclaimerModal/SOTrackingDisclaimerModal.molecule';

// #region INTERFACES
type Props = {
  label?: string;
  valueToCopy: string;
  labelStyle?: ExtraStyle;
  buttonStyle?: ExtraStyle;
  iconColor?: string;
  soId?: string;
};
// #endregion

export default function CopyToClipboard({
  label,
  valueToCopy,
  labelStyle,
  iconColor,
  buttonStyle,
  soId,
}: Props) {
  const {
    handleCloseDisclaimer,
    disclaimerType,
    tooltipRef,
    handleClickCopy,
    handleDisclaimerClick,
    isCopied,
    isMobile,
    showTooltip,
    translator,
    handleMouseEnter,
    handleMouseLeave,
  } = useCopyToClipboard({ soId, valueToCopy });

  return (
    <>
      <IconButton
        tw="-mr-2 origin-center"
        css={buttonStyle}
        ref={tooltipRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClickCopy}
      >
        <Icon.Copy fill={iconColor} />
        {label && <BodyFourteen css={labelStyle}>{label}</BodyFourteen>}
      </IconButton>

      {!isMobile && (
        <Tooltip
          addArrow
          withPortal
          variant="light"
          placement="top-start"
          targetRef={tooltipRef}
          visible={showTooltip}
          containerStyle={tw`z-[1000]`}
        >
          {isCopied ? (
            <div tw="flex items-center space-x-2 animate-fade-in">
              <Icon.CheckCircle
                width={20}
                height={20}
                fill={theme`textColor.status.success.DEFAULT`}
              />
              <Text.BodyFourteen tw="whitespace-normal break-words">
                {translator.translate('Copied to clipboard')}
              </Text.BodyFourteen>
            </div>
          ) : (
            <Text.BodyFourteen tw="whitespace-normal break-words">
              {translator.translate('Copy to clipboard')}
            </Text.BodyFourteen>
          )}
        </Tooltip>
      )}
      {!!disclaimerType && (
        <SOTrackingDisclaimerModal
          handleCloseDisclaimer={handleCloseDisclaimer}
          handleDisclaimerClick={handleDisclaimerClick}
        />
      )}
    </>
  );
}
