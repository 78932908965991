import React from 'react';
import tw from 'twin.macro';
import { CustomMapTypeItem } from '../../../model/Map.model';
import MapTypeControllerItem from '../MapTypeControllerItem/MapTypeControllerItem.molecule';

const Wrapper = tw.div`relative`;
const Container = tw.div`display[-webkit-box] w-[72px]h-[70px] p-1  absolute left-2.5 bottom-7  overflow-hidden  place-items-center bg-white shadow rounded-md hover:w-[208px]`;
const MobileContainer = tw.div`display[-webkit-box] w-11 h-11 p-0.5  absolute right-2 top-2  overflow-hidden  place-items-center bg-white shadow rounded-md `;

const MobileContentWrapper = tw.div`absolute right-1 gap-1.5 flex flex-col top-[60px]`;
interface Props {
  showMapTypes: boolean;
  selectedOption: CustomMapTypeItem;
  mapTypeItems: CustomMapTypeItem[];
  mapTypeMobileItems: CustomMapTypeItem[];
  isMobile?: boolean;
  handleOpenMapTypesMobile?: () => void;
}
export default function MapTypeController({
  showMapTypes,
  selectedOption,
  mapTypeItems,
  mapTypeMobileItems,
  isMobile,
  handleOpenMapTypesMobile,
}: Props) {
  if (isMobile)
    return (
      <Wrapper>
        <MobileContainer>
          {selectedOption && (
            <MapTypeControllerItem
              handleClickMobile={handleOpenMapTypesMobile}
              isMobile={isMobile}
              containerStyle={tw`group-hover:(border-2 border-orange rounded-md)`}
              item={selectedOption}
            />
          )}
        </MobileContainer>
        {showMapTypes && (
          <MobileContentWrapper>
            {mapTypeMobileItems.map((data) => (
              <MapTypeControllerItem
                key={data.label}
                item={data}
                isSelected={data.type === selectedOption.type}
              />
            ))}
          </MobileContentWrapper>
        )}
      </Wrapper>
    );
  return (
    <Container className="group">
      {selectedOption && (
        <MapTypeControllerItem
          containerStyle={tw`group-hover:(border-2 border-orange rounded-md)`}
          item={selectedOption}
        />
      )}
      {mapTypeItems.map((data) => (
        <MapTypeControllerItem key={data.label} item={data} />
      ))}
    </Container>
  );
}
