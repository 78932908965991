import { LocationOrderBy } from '../model/Location.model';

// #region INTERFACES
export enum DELIVERYLOCATIONTYPE {
  LAST_MILE = 'LASTMILE',
  TRANSIT = 'TRANSIT',
}

export enum LocationType {
  LOCATION_TYPE = 'LOCATION_TYPE',
  TRANSPORTER = 'TRANSPORTER',
  SHIPPER = 'SHIPPER',
  PUBLIC = 'PUBLIC',
}

export type LocationOrderByValue = {
  label: string;
  value: LocationOrderBy;
};

export type LocationTypeValue = {
  label: string;
  value: LocationType;
};

export type LocationFilterInitialValues = {
  orderBy: LocationOrderBy;
  type: LocationType[];
  shipperId?: string;
};
// #endregion

export const locationFilterInitialValues: LocationFilterInitialValues = {
  orderBy: LocationOrderBy.NAME_ASC,
  type: Object.values(LocationType).filter(
    (type) => type !== LocationType.LOCATION_TYPE,
  ),
  shipperId: undefined,
};

export const maxLocationRadius = 999999;

export const locationInitialValues = {
  id: '',
  shipperId: '',
  contactName: '',
  contactNumber: '',
  name: '',
  address: '',
  latitude: 0,
  longitude: 0,
  type: LocationType.PUBLIC,
  createdAt: 0,
  updatedAt: 0,
};
