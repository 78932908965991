import { Placement } from '@popperjs/core';
import React, { MutableRefObject } from 'react';
import tw, { theme } from 'twin.macro';
import { Popover } from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region INTERFACES
export type TooltipProps = {
  targetRef: MutableRefObject<null>;
  placement?: Placement;
  visible?: boolean;
  variant?: 'dark' | 'light';
  children?: React.ReactNode;
  offset?: [number, number];
  withPortal?: boolean;
  addArrow?: boolean;
  containerStyle?: React.CSSProperties | ExtraStyle | null | undefined;
  contentStyle?: ExtraStyle;
};
// #endregion

// #region STYLE
const Content = tw.div`max-width[600px] rounded shadow-card py-3 px-4 font-sans`;
// #endregion

const styles = {
  dark: {
    backgroundColor: theme`colors.black`,
    color: theme`colors.white`,
  },
  light: {
    backgroundColor: theme`colors.white`,
    color: theme`colors.black`,
  },
};

const componentId = 'Tooltip';
const testIds = {
  container: {
    content: `${componentId}:Container:Content`,
  },
} as const;

export default function Tooltip({
  visible = false,
  targetRef,
  placement = 'top',
  variant = 'dark',
  children,
  offset,
  withPortal = false,
  addArrow = true,
  containerStyle,
  contentStyle,
}: TooltipProps) {
  return (
    <Popover
      tw="z-10"
      addArrow={addArrow}
      visible={visible}
      targetRef={targetRef}
      placement={placement}
      offset={offset ?? [0, 20]}
      css={containerStyle}
      arrowColor={styles[variant].backgroundColor}
      withPortal={withPortal}
    >
      <Content
        style={styles[variant]}
        css={contentStyle}
        data-testid={testIds.container.content}
      >
        {children}
      </Content>
    </Popover>
  );
}
