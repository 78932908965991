import { useMemo, useRef, useState } from 'react';
import { BottomSheetRef } from 'react-spring-bottom-sheet';
import {
  JOActivityInfo,
  JobOrderDeliveryStatus,
  JobOrderInfo,
  JobOrderInfoDelivery,
  JOStatus,
} from '../../model/JobOrder.model';
import { Goods } from '../../model/ShipperOrder.model';
import { mapJOStatusToLabel } from '../../util/jobOrder.util';
import {
  generateJOMobileContentItem,
  generateSOListMobileContentItem,
} from '../../util/jobOrderDetail/jobOrderDetail.util';
import { mapSOFilterStatusToLabel } from '../../util/shipperOrder.util';
import useScreenDetection from '../useScreenDetection/useScreenDetection.hook';
import useTranslator from '../useTranslator.hook';

export interface IJOContentData {
  label: string;
  value: string;
}

export interface SelectedActivityId {
  idx: number;
  activityId: string;
  totalPhotos: number;
}

export interface IJOSOListData {
  id: string;
  number: string;
  isSOTransit?: boolean;
  transitGoods?: Goods[];
  shipperName: string;
  status: JobOrderDeliveryStatus;
  statusLabel: string;
  activities: {
    pickUpActivities: JOActivityInfo[];
    dropOffActivities: JOActivityInfo[];
    standByActivities: JOActivityInfo[];
  };
  handleClickTitle: () => void;
  handleClickActivities: () => void;
}

export enum SheetContent {
  OVERVIEW = 'OVERVIEW',
  SO = 'SO',
}

interface Props {
  joInfoData?: JobOrderInfo;
  refetchJOInfo: () => void;
}

export default function useJODetailSheet({ joInfoData, refetchJOInfo }: Props) {
  const translator = useTranslator();
  const bottomSheetRef = useRef<BottomSheetRef>();

  const { windowSize } = useScreenDetection();

  const [showContent, setShowContent] = useState<SheetContent>(
    SheetContent.OVERVIEW,
  );
  const [selectedSOId, setSelectedSOId] = useState<string | undefined>(
    undefined,
  );
  const [selectedActivityId, setSelectedActivityId] = useState<
    SelectedActivityId | undefined
  >(undefined);
  const [showJONav, setShowJONav] = useState(false);

  const sheetSnapPoints = {
    top: windowSize.height,
    center: windowSize.height * 0.4,
    min: 64,
  };

  const renderedSelectedDelivery = useMemo<
    JobOrderInfoDelivery | undefined
  >(() => {
    if (!selectedSOId || !joInfoData) return undefined;
    return joInfoData.deliveries.find(
      (item) => item.shipperOrder.id === selectedSOId,
    );
  }, [joInfoData, selectedSOId]);

  const renderedSelectedSOActivity = useMemo<JOActivityInfo | undefined>(
    () =>
      renderedSelectedDelivery
        ? renderedSelectedDelivery.activities.find(
            (_activity) => _activity.id === selectedActivityId?.activityId,
          )
        : undefined,
    [renderedSelectedDelivery, selectedActivityId?.activityId],
  );

  const hideMap = useMemo(
    () =>
      joInfoData?.status &&
      [JOStatus.DRAFT, JOStatus.DELIVERED].includes(joInfoData?.status),
    [joInfoData?.status],
  );
  const showHeaderBackButton = useMemo(
    () => hideMap || !!selectedSOId,
    [hideMap, selectedSOId],
  );
  const headerStatusLabel = translator.translate(
    renderedSelectedDelivery
      ? mapSOFilterStatusToLabel(renderedSelectedDelivery.shipperOrder.status)
      : mapJOStatusToLabel(joInfoData?.status),
  );
  const headerTitle = useMemo(
    () =>
      (selectedActivityId && 'Proof of Activity') ||
      renderedSelectedDelivery?.shipperOrder.number ||
      joInfoData?.number ||
      'JO Number',
    [
      joInfoData?.number,
      renderedSelectedDelivery?.shipperOrder.number,
      selectedActivityId,
    ],
  );

  const isJODraft = useMemo(
    () => joInfoData?.status === JOStatus.DRAFT,
    [joInfoData?.status],
  );

  const contentMenu = useMemo(
    () => [
      {
        label: translator.translate('Overview'),
        testID: 'JODetailMobileView:Overview',
        onClick: () => {
          if (showContent !== SheetContent.OVERVIEW)
            setShowContent(SheetContent.OVERVIEW);
        },
        selected: showContent === SheetContent.OVERVIEW,
      },
      {
        label: translator.translate('Shipper Order List'),
        testID: 'JODetailMobileView:ShipperOrderList',
        onClick: () => {
          if (showContent !== SheetContent.SO) setShowContent(SheetContent.SO);
        },
        selected: showContent === SheetContent.SO,
      },
    ],
    [showContent, translator],
  );

  const handleSpringStart = () => {
    requestAnimationFrame(() => {
      setShowJONav(bottomSheetRef.current?.height === sheetSnapPoints.top);
    });
  };

  const joContentData = generateJOMobileContentItem(translator, joInfoData);

  const soListContentData = generateSOListMobileContentItem(
    refetchJOInfo,
    setSelectedSOId,
    translator,
    joInfoData,
  );

  const handleDismissHeader = () => {
    bottomSheetRef.current?.snapTo(sheetSnapPoints.center);
  };

  const handleBackSODetail = () => {
    setSelectedSOId(undefined);
  };

  const handleBackFromActivityPhoto = () => {
    setSelectedActivityId(undefined);
  };

  const handleClickActivityPhoto = (
    _selectedActivityId: SelectedActivityId,
  ) => {
    setSelectedActivityId(_selectedActivityId);
  };

  const handleChangePhoto = (idx: number) => {
    if (selectedActivityId)
      setSelectedActivityId({ ...selectedActivityId, idx });
  };

  return {
    showContent,
    isJODraft,
    hideMap,
    contentMenu,
    joContentData,
    soListContentData,
    bottomSheetRef,
    showJONav,
    selectedSOId,
    selectedActivityId,
    showHeaderBackButton,
    headerStatusLabel,
    headerTitle,
    renderedSelectedSO: renderedSelectedDelivery,
    renderedSelectedSOActivity,
    sheetSnapPoints,
    setShowJONav,
    handleBackSODetail,
    handleDismissHeader,
    handleSpringStart,
    handleBackFromActivityPhoto,
    handleClickActivityPhoto,
    handleChangePhoto,
  };
}

export type UseJODetailSheetHookObj = ReturnType<typeof useJODetailSheet>;
