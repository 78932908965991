import { Dictionary } from 'lodash';
import groupBy from 'lodash/groupBy';
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import tw, { styled, theme, TwStyle } from 'twin.macro';
import useClickOutside from '../../../hook/useClickOutside.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { Icon, Popover, Text } from '../../atom';

export interface OptionType {
  label: string;
  value: string;
  customEl?: (option: OptionType) => React.ReactNode;
}

interface Props {
  css?: TwStyle;
  options: OptionType[];
  disabled?: boolean;
  error?: string;
  value?: string;
  emptyLabel?: string;
  placeholder?: string;
  onChange(value: string): void;
  groupByLabel?: boolean;
  name?: string;
  id?: string;
}
const ErrorMessage = tw.div`font-sans font-size[12px] line-height[20px] letter-spacing[0.2px] mt-0.5 mx-4 text-status-alert`;

const Underline = styled.div(
  (props: { disabled?: boolean; error?: boolean; filled?: boolean }) => [
    tw`absolute bottom-0 w-full h-0.5 bg-grey-two`,
    !props.disabled &&
      tw`peer-hover:bg-orange peer-focus:bg-orange group-hover:bg-orange`,
    !props.disabled && props.filled && tw`bg-black`,
    props.error && tw`bg-status-alert!`,
  ],
);
const Container = tw.div``;

interface OptionWrapperProps {
  onEnter?(): void;
  onClick?(): void;
}
function OptionWrapper({
  onEnter,
  onClick,
  children,
}: PropsWithChildren<OptionWrapperProps>) {
  return (
    <div
      onKeyDown={(e) => {
        if (e.code === 'Enter' && onEnter) {
          onEnter();
        }
      }}
      role="option"
      aria-selected
      tabIndex={0}
      tw="relative p-3 hover:(bg-orange-hover) cursor-pointer"
      onClick={onClick}
    >
      {children}
    </div>
  );
}
/**
 * By default, this options will show regular option. You also need to convert the values into `Option` type.
 * To group option by label pass `groupByLabel` prop.
 *
 * You also able to supply custom option element by passing the `customEl` prop in the `options` prop:
 * @example
 * ...
 * const options = users.map(({fullName, id, age})=> ({
 *  label: fullName,
 *  value: id,
 *  cell: (v?: OptionType)=> {
 *    // render custom element
 *    return (
 *    <div>
 *      <div>{name}</div>
 *      <div>{age}</div>
 *    </div>)
 *  }
 * }))
 *
 * return <Select options={options} ... />
 * ...
 *
 * @info in case of bugs, customization or adjustment feel free to create MR.
 */
export default function Select({
  css,
  options,
  disabled,
  error,
  value,
  onChange,
  groupByLabel,
  emptyLabel,
  placeholder,
  id,
  name,
}: Props): JSX.Element {
  const translator = useTranslator();
  const targetRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const outsideRef = useClickOutside<HTMLDivElement>(() => {
    setVisible(false);
  });

  const data = useMemo(() => {
    if (groupByLabel) {
      return groupBy(
        options.sort((prev, next) => {
          const nameA = prev.label.toLowerCase();
          const nameB = next.label.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
        ({ label }) => label.toLowerCase()[0],
      );
    }

    return options;
  }, [options, groupByLabel]);

  const renderOptions = useCallback(() => {
    if (options.length > 0) {
      if (groupByLabel) {
        return Object.keys(data)
          .map((key) => ({
            key,
            values: (data as Dictionary<OptionType[]>)[key],
          }))
          .map(({ key, values }) => (
            <div key={`group-${key}`}>
              <div tw="font-bold p-3">{key.toUpperCase()}</div>
              {values.map((v) => (
                <OptionWrapper
                  key={v.value}
                  onEnter={() => {
                    onChange(v.value);
                    setVisible(false);
                  }}
                  onClick={() => {
                    onChange(v.value);
                    setVisible(false);
                  }}
                >
                  {v.customEl ? (
                    v.customEl(v)
                  ) : (
                    <Text.BodyOne>{translator.translate(v.label)}</Text.BodyOne>
                  )}
                </OptionWrapper>
              ))}
            </div>
          ));
      }

      return (data as OptionType[]).map((v) => (
        <OptionWrapper
          key={v.value}
          onEnter={() => {
            onChange(v.value);
            setVisible(false);
          }}
          onClick={() => {
            onChange(v.value);
            setVisible(false);
          }}
        >
          {v.customEl ? (
            v.customEl(v)
          ) : (
            <Text.BodyOne>{translator.translate(v.label)}</Text.BodyOne>
          )}
        </OptionWrapper>
      ));
    }

    return (
      <OptionWrapper
        onClick={() => {
          setVisible(false);
        }}
      >
        <Text.BodyOne>
          {translator.translate(emptyLabel || 'No option')}
        </Text.BodyOne>
      </OptionWrapper>
    );
  }, [data, emptyLabel, groupByLabel, onChange, options.length, translator]);

  return (
    <Container css={css} ref={outsideRef} className="group">
      <div
        css={[
          tw`grid relative border rounded-tl rounded-tr border-beige-lines h-14`,
          disabled && tw`border-0`,
        ]}
      >
        <div>
          <input
            disabled={disabled}
            id={id}
            name={name}
            readOnly
            tw="flex w-full h-full p-4 pr-8 outline-none cursor-default disabled:(bg-grey-six text-grey-three) rounded"
            placeholder={placeholder || 'Select'}
            value={translator.translate(
              options.find((o) => o.value === value)?.label || '',
            )}
            onClick={() => {
              setVisible((v) => !v);
            }}
          />
          {!disabled && (
            <>
              <div
                role="button"
                tabIndex={0}
                // Add empty function to handle eslint
                onKeyDown={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                  setVisible((v) => !v);
                }}
                tw="absolute z-10 rotate-90 top-0 right-0 p-4"
                css={[visible && tw`-rotate-90`]}
              >
                <Icon.ChevronRounded stroke={theme`colors.grey.two`} />
              </div>
              <Underline disabled={disabled} error={!!error} filled={!!value} />
            </>
          )}
        </div>
        <div tw="absolute top-14 w-full">
          <Popover tw="min-w-full z-20" targetRef={targetRef} visible={visible}>
            <div tw="bg-white shadow overflow-y-auto rounded max-h-48">
              {renderOptions()}
            </div>
          </Popover>
        </div>
      </div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
}
