import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export default function Add({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  fill = 'currentColor',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8 2.4H7.2C4.54903 2.4 2.4 4.54903 2.4 7.2V16.8C2.4 19.451 4.54903 21.6 7.2 21.6H16.8C19.451 21.6 21.6 19.451 21.6 16.8V7.2C21.6 4.54903 19.451 2.4 16.8 2.4ZM7.2 0C3.22355 0 0 3.22355 0 7.2V16.8C0 20.7764 3.22355 24 7.2 24H16.8C20.7764 24 24 20.7764 24 16.8V7.2C24 3.22355 20.7764 0 16.8 0H7.2Z"
      />
      <path d="M10.8 7.2H13.2V16.8H10.8V7.2Z" />
      <path d="M16.8 10.8V13.2H7.2V10.8H16.8Z" />
    </svg>
  );
}
