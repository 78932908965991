import endOfToday from 'date-fns/endOfToday';
import startOfToday from 'date-fns/startOfToday';
import subDays from 'date-fns/subDays';
import { date, InferType, object } from 'yup';
import { startoftoday, startofyesterday } from '../Date.constant';

export type DriverDetailTimesheetFilterInitialValues = InferType<
  typeof driverDetailTimesheetFilterSchema
>;

export const driverDetailTimesheetFilterSchema = object({
  startDate: date().default(() => subDays(startOfToday(), 6)),
  endDate: date().default(() => endOfToday()),
});

export const driverDetailTimesheetFilterInitialValues =
  driverDetailTimesheetFilterSchema.getDefault();

export const driverDetailTimesheetFilterMaxDateRangeInDays = 31;

export const driverDetailEvents = {
  timesheet: {
    resetFilter: 'driverDetail.timesheet.resetFilterForm',
    resetClock: 'driverDetail.timesheet.resetClock',
  },
} as const;

export const addTimesheetDateDisabledDays = {
  before: subDays(startoftoday, 31),
  after: startofyesterday,
};
