import tw, { styled } from 'twin.macro';

export type TextAreaProps = {
  disabled?: boolean;
};

const InputArea = styled.textarea(({ disabled }: TextAreaProps) => [
  tw`m-0 p-4 rounded border border-solid border-beige-lines font-size[16px] leading-5 font-sans`,
  tw`focus:ring-opacity-10 focus:outline-none`,
  disabled && tw`text-grey-three bg-grey-six border-grey-six`,
]);

export default InputArea;
