import React from 'react';
import { Navigate } from 'react-router-dom';
import { Icon, TableNoData } from '../../component/atom';
import { BulkActionLayout } from '../../component/template';
import { homeRoute } from '../Home/Home.route';
import useDrivingContestRemoveViewModel from './DrivingContestRegisterConfirmation.viewModel';

export default function DrivingContestRegisterConfirmView() {
  const {
    translator: { translate },
    table,
    logic,
    runnerEvent,
  } = useDrivingContestRemoveViewModel();

  if (!runnerEvent.isDrivingContestRegistrationInProgress) {
    return <Navigate to={homeRoute.path} replace />;
  }

  return (
    <BulkActionLayout
      headerLabel={translate('Confirm Registration')}
      onBack={logic.onCancel}
      actionLabel={translate('to start the registration process.')}
      descriptionLabel={translate(
        'Make sure the list below is the driver list that you want to register to Driver Contest. After registration, drivers should be paired with a vehicle on the Scheduling menu.',
      )}
      cancelLabel={translate('Cancel')}
      submitLabel={translate('Register')}
      doneLabel={translate('Done')}
      table={table.table}
      entityLength={logic.drivingContestsWithStatusDetail.length}
      showDoneBtn={logic.showDoneBtn}
      isLoading={logic.mutationsIsLoading}
      noDataPage={
        <TableNoData
          icon={<Icon.NoDataFound />}
          titleLabel={translate(
            "You don't have any selected Drivers that you want to register",
          )}
          descriptionLabel={translate(
            'Try to select some Drivers from Driver Contest Register page',
          )}
          actionLabel={translate('Go back to Driver Contest Register page')}
          onClickAction={logic.onDone}
        />
      }
      onCancel={logic.onCancel}
      onSubmit={logic.onRemove}
      onDone={logic.onDone}
    />
  );
}
