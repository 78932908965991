import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import useTranslator from '../../../hook/useTranslator.hook';
import api from '../../../service/api.service';
import { snackbarAction } from '../../../store/snackbar.store';

export enum SOTrackingDisclaimerType {
  COPY = 'COPY',
  SHARE = 'SHARE',
  WA = 'WA',
}

export type UseSOShareDisclaimerProps = {
  soId: string;
};

export default function useSOShareDisclaimer({
  soId,
}: UseSOShareDisclaimerProps) {
  const { translate } = useTranslator();
  const dispatch = useDispatch();
  const [disclaimerType, setDisclaimerType] = useState<
    SOTrackingDisclaimerType | undefined
  >(undefined);

  const [postCreateShipper, responseCreateShipper] =
    api.useSendSODisclaimerMutation();

  const handleApproveDisclaimer = useCallback(
    async ({ onSuccess }: { onSuccess?: VoidFunction }) => {
      try {
        await postCreateShipper({ soId });
        onSuccess?.();
      } catch (err) {
        dispatch(
          snackbarAction.show({
            type: SnackbarTheme.warning,
            message: translate('Failed to send log disclaimer'),
          }),
        );
      } finally {
        responseCreateShipper.reset();
      }
    },
    [dispatch, translate, postCreateShipper, responseCreateShipper, soId],
  );

  return {
    disclaimerType,
    setDisclaimerType,
    handleApproveDisclaimer,
  };
}
