import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  pathStroke?: string;
  pathFill?: string;
};

export default function Arrow({
  fill = 'currentColor',
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
  strokeWidth = '2',
  pathStroke = 'currentColor',
  pathFill,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth={strokeWidth}
      fill={fill}
    >
      <path
        d="M2 12H22M22 12L18.0412 8M22 12L18.0412 16"
        stroke={pathStroke}
        fill={pathFill}
      />
    </svg>
  );
}
