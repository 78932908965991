import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type CompleteActivityRouteParam = DefaultRouteParam;

export const completeActivityConfirmRoute: RouteConfig = {
  name: 'Force Complete Confirm',
  Component: React.lazy(() => import('./CompleteActivityConfirm.view')),
  path: '/job-order/:id/delivery-confirmation',
  isPrivate: true,
  props: {
    isUnclickable: true,
  },
};
