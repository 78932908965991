import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { SOActivityType } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { numberFormatter } from '../../../util/formatter.util';
import { BodyFourteen, HeadingFive } from '../../atom/Text/Text.atom';

const ActivityTableData = tw.td`py-2.5`;
const TableBody = tw.tbody``;
const TableRow = tw.tr``;
const Container = tw.div``;

export type ActivityItemType = {
  id?: string;
  locationName?: string;
  expectedFinishedAt?: string;
  completedAt?: string;
  isBadgeVisible?: boolean;
  isLate?: boolean;
  type?: string | null;
  description?: string | null;
  qty?: number | null;
  unit?: string | null;
  weight?: number | null;
  volume?: number | null;
};

type Props = {
  status: string;
  items: ActivityItemType[];
  hideFooter?: boolean;
};

type ActivityCellProps = {
  title: string | number | null;
  isNumber?: boolean;
  status?: string;
  noBorder?: boolean;
  hideValue?: boolean;
};

type ActivityCellWithBadgeProps = ActivityCellProps & {
  isBadgeVisible?: boolean;
  isLate?: boolean;
};

type ActivityTableProps = {
  colSpan: number;
  totalWeight: string;
  totalVolume: string;
};

type ActivityTableItemProps = ActivityItemType & {
  status: string;
  noBorder?: boolean;
  hideValue?: boolean;
  isLastItem?: boolean;
};

function ActivityCellWithBadge({
  title = '',
  isNumber,
  status,
  isBadgeVisible,
  isLate,
  noBorder = false,
  hideValue = false,
}: ActivityCellWithBadgeProps) {
  const { translate } = useTranslator();
  return (
    <ActivityTableData
      tw="max-w-[50px] not-last:pr-2.5 border-b flex-wrap"
      css={[
        status === SOActivityType.STAND_BY && tw`border-b-lime-border`,
        status === SOActivityType.PICK_UP && tw`border-b-ocean-border-light`,
        status === SOActivityType.DROP_OFF &&
          tw`border-b-twilight-border-light`,
        noBorder && tw`border-b-0`,
      ]}
    >
      <Container
        css={[isNumber && tw`justify-end`, tw`flex gap-2.5 items-center`]}
      >
        <BodyFourteen tw="overflow-wrap[anywhere] whitespace-normal">
          {!hideValue && title}
        </BodyFourteen>
        {isBadgeVisible && (
          <div
            css={[
              tw`items-center px-1 rounded`,
              isLate
                ? tw`bg-semantic-error-muted `
                : tw`bg-semantic-success-muted`,
            ]}
          >
            <BodyFourteen
              css={[
                tw`font-bold`,
                isLate ? tw`text-semantic-error` : tw`text-semantic-success`,
              ]}
            >
              {translate(isLate ? 'Late' : 'On time')}
            </BodyFourteen>
          </div>
        )}
      </Container>
    </ActivityTableData>
  );
}
function ActivityCell({
  title = '',
  isNumber,
  status,
  noBorder = false,
  hideValue = false,
}: ActivityCellProps) {
  return (
    <ActivityTableData
      tw="max-w-[50px] not-last:pr-2.5 border-b flex-wrap"
      css={[
        status === SOActivityType.STAND_BY && tw`border-b-lime-border`,
        status === SOActivityType.PICK_UP && tw`border-b-ocean-border-light`,
        status === SOActivityType.DROP_OFF &&
          tw`border-b-twilight-border-light`,
        noBorder && tw`border-b-0`,
      ]}
    >
      <Container css={[isNumber && tw`justify-end`, tw`flex`]}>
        <BodyFourteen tw="overflow-wrap[anywhere] whitespace-normal">
          {!hideValue && title}
        </BodyFourteen>
      </Container>
    </ActivityTableData>
  );
}

function ActivityTableItem({
  id,
  locationName,
  expectedFinishedAt,
  completedAt,
  type,
  description,
  qty,
  unit,
  weight,
  volume,
  isBadgeVisible,
  isLate,
  status,
  noBorder = false,
  hideValue = false,
  isLastItem,
}: ActivityTableItemProps) {
  return (
    <TableRow
      key={`${type ?? ''} ${description ?? locationName ?? ''} ${id ?? ''}`}
    >
      {typeof locationName === 'string' && (
        <ActivityCell
          status={status}
          title={locationName}
          noBorder={isLastItem || noBorder}
          hideValue={hideValue}
        />
      )}
      {typeof expectedFinishedAt === 'string' && (
        <ActivityCell
          status={status}
          title={expectedFinishedAt}
          noBorder={isLastItem}
        />
      )}
      {typeof completedAt === 'string' && (
        <ActivityCellWithBadge
          isLate={isLate}
          isBadgeVisible={isBadgeVisible}
          status={status}
          title={completedAt}
          noBorder={isLastItem}
        />
      )}
      {(typeof type === 'string' || type === null) && (
        <ActivityCell status={status} title={type ?? '-'} />
      )}
      {(typeof description === 'string' || description === null) && (
        <ActivityCell status={status} title={description ?? '-'} />
      )}
      {(typeof qty === 'number' || qty === null) && (
        <ActivityCell
          status={status}
          title={qty ? numberFormatter(qty) : '-'}
          isNumber
        />
      )}
      {(typeof unit === 'string' || unit === null) && (
        <ActivityCell status={status} title={unit ?? '-'} />
      )}
      {(typeof weight === 'number' || weight === null) && (
        <ActivityCell
          status={status}
          title={weight ? numberFormatter(weight) : '-'}
          isNumber
        />
      )}
      {(typeof volume === 'number' || volume === null) && (
        <ActivityCell
          status={status}
          title={volume ? numberFormatter(volume) : '-'}
          isNumber
        />
      )}
    </TableRow>
  );
}

function ActivityTableTotal({
  colSpan,
  totalWeight,
  totalVolume,
}: ActivityTableProps) {
  return (
    <TableRow>
      <ActivityTableData tw="pr-2.5" colSpan={colSpan}>
        <HeadingFive>Total</HeadingFive>
      </ActivityTableData>
      <ActivityTableData>
        <Container tw="flex justify-end pr-2.5">
          <HeadingFive>{totalWeight}</HeadingFive>
        </Container>
      </ActivityTableData>
      <ActivityTableData>
        <Container tw="flex justify-end ">
          <HeadingFive>{totalVolume}</HeadingFive>
        </Container>
      </ActivityTableData>
    </TableRow>
  );
}

export default function ActivityTableBody({
  items,
  status,
  hideFooter = false,
}: Props) {
  const colSpan = useMemo(() => {
    if (!items.length) return 4;

    const whenItemExist = items.find((item) => item.id)
      ? Object.keys(items[0]).length - 3
      : Object.keys(items[0]).length - 2;

    return items.length > 0 ? whenItemExist : 4;
  }, [items]);
  const totalWeight = useMemo(
    () => items.reduce((acc, data) => acc + Number(data?.weight), 0) || 0,
    [items],
  );
  const totalVolume = useMemo(
    () => items.reduce((acc, data) => acc + Number(data?.volume), 0) || 0,
    [items],
  );

  return (
    <TableBody>
      {items.map((item, idx) => (
        <ActivityTableItem
          key={`${item?.type || ''} ${
            item?.description || item?.locationName || ''
          } ${item?.id || ''} ${idx}`}
          status={status}
          type={item.type}
          description={item.description}
          qty={item.qty}
          unit={item.unit}
          weight={item.weight}
          volume={item.volume}
          locationName={item.locationName}
          isBadgeVisible={item.isBadgeVisible}
          isLate={item.isLate}
          expectedFinishedAt={item.expectedFinishedAt}
          completedAt={item.completedAt}
          noBorder={
            item?.expectedFinishedAt || item?.completedAt
              ? false
              : (item?.locationName === items[idx - 1]?.locationName &&
                  !items[idx - 1]) ||
                item?.locationName === items[idx + 1]?.locationName
          }
          hideValue={
            item.locationName === items[idx - 1]?.locationName &&
            (!item?.expectedFinishedAt || !item?.completedAt)
          }
          isLastItem={items.length - 1 === idx && hideFooter}
        />
      ))}

      {!hideFooter && (
        <ActivityTableTotal
          colSpan={colSpan}
          totalWeight={numberFormatter(totalWeight)}
          totalVolume={numberFormatter(totalVolume)}
        />
      )}
    </TableBody>
  );
}
