import { useCallback, useMemo, useState } from 'react';
import { SOStatus } from '../../constant';
import { MAX_POLLING_ATTEMPT } from '../../constant/JobOrderDetail.constant';
import { JOStatus } from '../../model/JobOrder.model';
import useInterval from '../useInterval.hook';
import { UseJobOrderInfoHookObj } from '../useJobOrderInfo/useJobOrderInfo.hook';

type Props = { jobOrderInfo: UseJobOrderInfoHookObj };

export interface UseJobOrderDetailPollingHookObj {
  startPolling: boolean;
  checkForceLoadingSo: (
    joStatus: JOStatus,
    soStatus: SOStatus | number,
  ) => boolean;
}

function useJobOrderDetailPolling({
  jobOrderInfo: { joInfoData, refetchJOInfo },
}: Props): UseJobOrderDetailPollingHookObj {
  const [pollingAttempt, setPollingAttempt] = useState(0);
  const checkForceLoadingSo = useCallback(
    (joStatus: JOStatus, soStatus: SOStatus | number) => {
      if (pollingAttempt === MAX_POLLING_ATTEMPT) return false;
      const joMapping: Record<string, () => boolean> = {
        [JOStatus.DELIVERED]: () =>
          ![
            SOStatus.DELIVERED,
            SOStatus.TRANSITING,
            SOStatus.IN_TRANSIT,
          ].includes(soStatus),
        [JOStatus.ASSIGNED]: () => ![SOStatus.ASSIGNED].includes(soStatus),
        [JOStatus.DELIVERING]: () =>
          ![
            SOStatus.DELIVERING,
            SOStatus.DELIVERED,
            SOStatus.IN_TRANSIT,
            SOStatus.TRANSITING,
            SOStatus.ASSIGNED,
          ].includes(soStatus),
        DEFAULT: () => false,
      };

      return (joMapping[joStatus] ?? joMapping.DEFAULT)();
    },
    [pollingAttempt],
  );

  const startPolling = useMemo(() => {
    if (!joInfoData || pollingAttempt === MAX_POLLING_ATTEMPT) return false;

    const { status, deliveries } = joInfoData;

    return (
      deliveries.filter((delivery) =>
        checkForceLoadingSo(status, delivery.shipperOrder.status),
      ).length > 0
    );
  }, [checkForceLoadingSo, joInfoData, pollingAttempt]);

  useInterval(
    () => {
      refetchJOInfo();
      setPollingAttempt((v) => {
        if (v < 3) return v + 1;
        return v;
      });
    },
    startPolling ? 5000 : null,
  );

  return { startPolling, checkForceLoadingSo };
}

export default useJobOrderDetailPolling;
