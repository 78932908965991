import { nanoid } from '@reduxjs/toolkit';
import React from 'react';

/**
 * Replace dot based number to comma based number
 * @param num
 * @returns
 */
export const numberDecimalFormatter = (num?: number | string): string =>
  num?.toString().replace('.', ',') || '';

/**
 * Replace comma based number to num based number
 * @param str
 * @returns
 */
export const stringToNumberDecimalFormatter = (str?: string): number =>
  Number(str?.replace(',', '.'));

/**
 * Format number with dot separator
 * @param number
 * @param digits
 * @returns
 */
export function numberFormatter(
  number: string | number,
  options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
  },
): string {
  const numberFormat = new Intl.NumberFormat('id-ID', options);

  return numberFormat.format(Number(number)).replace(/\D00(?=\D*$)/, '');
}

/**
 * Format number to Indonesian currency.
 * This will handle negative values automatically (ex. `-Rp10.000` )
 */
export function currencyFormatter(
  number: string | number,
  options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 2,
  },
): string {
  const numberFormat = new Intl.NumberFormat('id-ID', options);

  return numberFormat
    .format(Number(number))
    .replace(/\D00(?=\D*$)/, '')
    .replace(/\s/g, ''); // remove whitespace
}

/**
 * Format number to shortened Indonesian currency
 * @param num
 * @param digits
 * @param isShort
 * @returns
 */
export const amountValueFormatter = (
  num: number,
  digits?: number,
  isShort?: boolean,
  isAlternativeSymbol?: boolean,
): { value: string; symbol: string; additionalSymbol?: string } => {
  const lookup = [
    { value: 1, symbol: '', shortSymbol: '' },
    { value: 1e3, symbol: 'thousand', shortSymbol: 'k', additionalSymbol: 'k' },
    {
      value: 1e6,
      symbol: 'million',
      shortSymbol: 'm',
      additionalSymbol: 'mio',
    },
    {
      value: 1e9,
      symbol: 'billion',
      shortSymbol: 'b',
      additionalSymbol: 'bio',
    },
    {
      value: 1e12,
      symbol: 'trillion',
      shortSymbol: 't',
      additionalSymbol: 't',
    },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  let numToFormat = num;
  let isNegative = false;

  if (num < 0) {
    numToFormat = -num;
    isNegative = true;
  }

  const item = lookup
    .slice()
    .reverse()
    .find(({ value }) => numToFormat >= value);

  let symbol = '';

  if (isAlternativeSymbol) {
    symbol = item?.additionalSymbol || '';
  } else if (isShort) {
    symbol = item?.shortSymbol || '';
  } else {
    symbol = item?.symbol || '';
  }

  const formattedValue = item
    ? numberDecimalFormatter(
        (numToFormat / item.value).toFixed(digits || 1).replace(rx, '$1'),
      )
    : '0';

  return {
    value: isNegative ? `-${formattedValue}` : formattedValue,
    symbol,
  };
};

/**
 * Used for get errormessage from commonErrorResponse
 * @param code
 * @returns
 */
export const getErrorMessage = (code: string) => {
  const errors: Record<string, string> = {
    DEFAULT: 'Unknown Error',
    'location/duplicate-entry': 'Location name already exist',
  };

  return errors[code] || errors.DEFAULT;
};

/**
 * parse formatted money in number from string that has 'Rp' and '.' in it
 * @param string Formatted money
 * @returns number
 */
export const parseFormattedMoney = (money: string) =>
  Number(money.replace('Rp', '').replace(/\./g, ''));

export const getShortLabel = (label?: string) => {
  if (!label) return '';
  return label.length > 25 ? `${label.slice(0, 25)}...` : label;
};

export const shortenText = (text?: string) => {
  if (!text) return '';
  if (text.length > 15) {
    const head = text.substring(0, 7);
    const tail = text.substring(text.length - 8);
    return formatText('%s__%s', head, tail);
  }

  return text;
};

export const padNumber = (num: number) => num.toString().padStart(2, '0');

export const formatTextWithElementTag = (
  text: string,
  ...replacements: React.ReactNode[]
) => {
  return text.split(/(%s)/i).map((fragment, idx) => {
    if (fragment === '%s') {
      return (
        <React.Fragment key={nanoid()}>{replacements.shift()}</React.Fragment>
      );
    }

    return <React.Fragment key={nanoid()}>{fragment}</React.Fragment>;
  });
};

/**
 * Format string with arguments, param `replacements` will replace occurence(s) of %s in the `text` in order
 * @example
 * formatText("%s is barking", "Dog")
 * // Dog is barking
 *
 * formatText("%s is barking, %s is meowing", "Dog", "Cat")
 * // Dog is barking, Cat is meowing
 * @param text
 * @param replacements
 * @returns
 */
export const formatText = (
  text: string,
  ...replacements: (string | number)[]
) => {
  const stringifiedReplacements = replacements.map((v) =>
    typeof v === 'number' ? v.toString() : v,
  );
  if (stringifiedReplacements.length > 0)
    return text.replace(/%s/g, () => stringifiedReplacements.shift() || '%s');

  return text;
};
