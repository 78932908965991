import {
  Circle,
  GoogleMap,
  Marker,
  TrafficLayer,
} from '@react-google-maps/api';
import React, { useCallback } from 'react';
import tw from 'twin.macro';
import MarkerDestination from '../../../assets/images/MapPointer.svg';
import {
  defaultMapsCenter,
  defaultZoomLevel,
  mapDefaultOption,
  mapDefaultRadius,
  mapsDefaultContainerStyle,
  radiusDefaultOption,
} from '../../../constant/Map.constant';
import useMap, { MapProps } from '../../../hook/map/useMap.hook';
import { CrosshairGPS } from '../../atom/Icon/Icon.atom';
import { MapsAutocomplete } from '../../molecule';
import MapTypeController from '../../molecule/MapTypeController/MapTypeController.molecule';

const CurrentLocationButton = tw.button`w-10 h-10 absolute right-2.5 bottom-[114px] flex justify-center place-items-center
bg-white shadow rounded-sm svg:fill-grey-two svg:hover:fill-black

`;
export default function RunnerMap({
  initMap,
  showAutocomplete,
  showMarkerCurrentLocation,
  showButtonCurrentLocation,
  disableDragMap,
  showTargetRadius,
  disableOnclickTarget,
  targetCoord,
  zoomLevel = defaultZoomLevel,
  zoomMaps,
  mapsCenter = defaultMapsCenter,
  targetLocations,
  radius = mapDefaultRadius,
  toggleZoomMaps,
  onMapInit,
  onGetTargetAddress,
  onGetAutocompleteInputRef,
  onGetCurrentLocation,
  children,
}: MapProps) {
  const {
    translator,
    mapAutocomplete,
    mapLoader,
    mapTypeController,
    isMobile,
  } = useMap({
    initMap,
    showAutocomplete,
    disableOnclickTarget,
    showMarkerCurrentLocation,
    showButtonCurrentLocation,
    targetCoord,
    mapsCenter,
    radius,
    zoomMaps,
    targetLocations,
    toggleZoomMaps,
    onMapInit,
    onGetTargetAddress,
    onGetAutocompleteInputRef,
    onGetCurrentLocation,
  });
  const renderMap = useCallback(
    () => (
      <GoogleMap
        mapContainerClassName={isMobile ? 'override-maps-jo' : undefined}
        mapContainerStyle={mapsDefaultContainerStyle}
        options={{
          ...mapDefaultOption,
          zoomControl: !isMobile,
          ...(disableDragMap && {
            gestureHandling: isMobile ? 'cooperative' : 'none',
          }),
        }}
        onClick={mapLoader.handleClickMap}
        center={mapLoader.center}
        zoom={zoomLevel}
        onLoad={mapLoader.handleLoad}
      >
        {showAutocomplete && (
          <MapsAutocomplete
            onLoadAutocomplete={mapAutocomplete.handleLoadAutocomplete}
            onPlaceChanged={mapAutocomplete.handlePlaceChanged}
            onSetInputRef={mapAutocomplete.handleSetInputRef}
          />
        )}
        {showButtonCurrentLocation && (
          <CurrentLocationButton onClick={mapLoader.handleGetCurrentLocation}>
            <CrosshairGPS />
          </CurrentLocationButton>
        )}
        <MapTypeController
          handleOpenMapTypesMobile={mapTypeController.handleOpenMapTypesMobile}
          showMapTypes={mapTypeController.showMapTypes}
          mapTypeMobileItems={mapTypeController.mapTypeItems}
          isMobile={isMobile}
          mapTypeItems={mapTypeController.renderedItems}
          selectedOption={mapTypeController.currentMapTypeItem}
        />
        {mapTypeController.showTrafficLayer && <TrafficLayer />}
        {children}
        {showMarkerCurrentLocation && mapLoader.currentLocation && (
          <Marker position={mapLoader.currentLocation} />
        )}
        {mapLoader.target && (
          <>
            {showTargetRadius && (
              <Circle
                center={mapLoader.target}
                options={{ ...radiusDefaultOption, radius }}
              />
            )}
            <Marker position={mapLoader.target} icon={MarkerDestination} />
          </>
        )}
      </GoogleMap>
    ),
    [
      isMobile,
      disableDragMap,
      mapLoader.handleClickMap,
      mapLoader.center,
      mapLoader.handleLoad,
      mapLoader.handleGetCurrentLocation,
      mapLoader.currentLocation,
      mapLoader.target,
      zoomLevel,
      showAutocomplete,
      mapAutocomplete.handleLoadAutocomplete,
      mapAutocomplete.handlePlaceChanged,
      mapAutocomplete.handleSetInputRef,
      showButtonCurrentLocation,
      mapTypeController.handleOpenMapTypesMobile,
      mapTypeController.showMapTypes,
      mapTypeController.mapTypeItems,
      mapTypeController.renderedItems,
      mapTypeController.currentMapTypeItem,
      mapTypeController.showTrafficLayer,
      children,
      showMarkerCurrentLocation,
      showTargetRadius,
      radius,
    ],
  );

  if (mapLoader.loadError) {
    return (
      <div>
        {translator.translate('Map cannot be loaded right now, sorry.')}
      </div>
    );
  }

  return mapLoader.isLoaded ? (
    renderMap()
  ) : (
    <div>{translator.translate('Loading...')}</div>
  );
}
