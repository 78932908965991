import React, { PropsWithChildren } from 'react';
import tw from 'twin.macro';
import { RootPortal } from '../../atom';
import { ExtraStyle } from '../../Type.component';

type Props = PropsWithChildren<{
  containerStyle?: ExtraStyle;
  handleClose: () => void;
}>;

const MobileContainer = tw.div`box-border fixed bottom-4 ml-auto left-2/4 -translate-x-2/4  width[calc(100vw - 32px) ]`;
const Container = tw.div`w-screen h-screen fixed top-0 left-0 z-50 overflow-hidden`;
const Overlay = tw.div`bg-black  bg-opacity-75 w-screen h-screen top-0 left-0 overflow-hidden`;
export default function DialogMobile({
  children,
  containerStyle,
  handleClose,
}: Props) {
  return (
    <RootPortal wrapperId="root-dialog-mobile">
      <Container css={containerStyle}>
        <MobileContainer>{children}</MobileContainer>
        <Overlay onClick={handleClose} />
      </Container>
    </RootPortal>
  );
}
