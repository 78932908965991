import React from 'react';
import tw, { theme } from 'twin.macro';
import { reportHaulingOrderMaxDateRangeInDays } from '../../../constant/Report/ReportHaulingOrder.constant';
import { UseGetShippersAutocompleteObj } from '../../../hook/useGetShippersAutocomplete.hook';
import { UseReportHaulingOrderFilterHook } from '../../../view/Report/HaulingOrder/hooks/useReportHaulingOrderFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region INTERFACES
type Props = {
  shippersAutocomplete: UseGetShippersAutocompleteObj;
  reportHaulingOrderFilter: UseReportHaulingOrderFilterHook;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const HeaderTitle = tw(Text.HeadingFour)`flex-1 ml-2`;
const HeaderResetButton = tw(Button.Outlined)`bg-white text-grey-two px-4`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChipContainer = tw.div`mb-2 mr-2`;
const FilterChip = tw(Chip.Small)`text-grey-two`;
const DatePickerContainer = tw.div`w-full flex items-center justify-between space-x-2`;
// #endregion

export default function ReportHaulingOrderFilter({
  shippersAutocomplete,
  reportHaulingOrderFilter,
}: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <HeaderTitle
          data-testid={`${reportHaulingOrderFilter.testID}Filter:Title`}
        >
          {reportHaulingOrderFilter.titleLabel}
        </HeaderTitle>

        <HeaderResetButton
          small
          data-testid={`${reportHaulingOrderFilter.testID}Filter:ResetAction`}
          onClick={reportHaulingOrderFilter.onClickResetFilter}
        >
          {reportHaulingOrderFilter.resetButtonLabel}
        </HeaderResetButton>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading
            data-testid={`${
              reportHaulingOrderFilter.testID || ''
            }Filter:OrderByLabel`}
          >
            {reportHaulingOrderFilter.orderByLabel}
          </InputHeading>

          <ChipContainer>
            {reportHaulingOrderFilter.filterOrderByValues.map((el, i) => {
              const selected =
                reportHaulingOrderFilter.filterForm.values.orderBy === el.value;

              return (
                <FilterChipContainer key={`Filter_Category_${el.value}`}>
                  <FilterChip
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      reportHaulingOrderFilter.onClickOrderByChipFilter(
                        el.value,
                      )
                    }
                    testID={`${
                      reportHaulingOrderFilter.testID || ''
                    }Filter:OrderByOption:${i}`}
                  />
                </FilterChipContainer>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportHaulingOrderFilter.testID}Filter:ShowDeliveryLabel`}
          >
            {reportHaulingOrderFilter.showDeliveryLabel}
          </InputHeading>

          <ChipContainer>
            {reportHaulingOrderFilter.filterShowDeliveryValues.map((el, i) => {
              const selected =
                reportHaulingOrderFilter.filterForm.values.showDelivery ===
                el.value;

              return (
                <FilterChipContainer key={`Filter_Category_${el.value}`}>
                  <FilterChip
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      reportHaulingOrderFilter.onClickShowDelivery(el.value)
                    }
                    testID={`${reportHaulingOrderFilter.testID}Filter:ShowDeliveryOption:${i}`}
                  />
                </FilterChipContainer>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${reportHaulingOrderFilter.testID}Filter:StatusLabel`}
            >
              {reportHaulingOrderFilter.statusLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                reportHaulingOrderFilter.isQueryStatusAll
                  ? reportHaulingOrderFilter.onClickUnselectAllStatus
                  : reportHaulingOrderFilter.onClickSelectAllStatus
              }
            >
              {reportHaulingOrderFilter.isQueryStatusAll
                ? reportHaulingOrderFilter.unselectAllLabel
                : reportHaulingOrderFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {reportHaulingOrderFilter.filterStatusValues.map((el, i) => {
              const selected =
                reportHaulingOrderFilter.filterForm.values.status.includes(
                  el.value,
                );

              return (
                <FilterChipContainer key={`Filter_Category_${el.value}`}>
                  <FilterChip
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      reportHaulingOrderFilter.onClickStatusChipFilter(el.value)
                    }
                    testID={`${reportHaulingOrderFilter.testID}Filter:StatusOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChipContainer>
              );
            })}
          </ChipContainer>

          {reportHaulingOrderFilter.isQueryStatusEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {reportHaulingOrderFilter.errorStatusLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportHaulingOrderFilter.testID}Filter:ShipperLabel`}
          >
            {reportHaulingOrderFilter.shipperLabel}
          </InputHeading>

          <AutoComplete
            value={reportHaulingOrderFilter.filterForm.values.shipperId}
            datas={shippersAutocomplete.shipperOptions}
            selectedOption={reportHaulingOrderFilter.queryShipperOption}
            placeholder={reportHaulingOrderFilter.shipperPlaceholder}
            loading={shippersAutocomplete.shipperListFetchLoading}
            changeData={reportHaulingOrderFilter.onChangeShipperFilter}
            changeInputText={shippersAutocomplete.onChangeShipperAutotext}
            onFetchMore={shippersAutocomplete.onFetchMoreShipper}
            onRemoveData={reportHaulingOrderFilter.onRemoveShipperFilter}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportHaulingOrderFilter.testID}Filter:DateRangeLabel`}
          >
            {reportHaulingOrderFilter.dateRangeLabel}
          </InputHeading>

          <DatePickerContainer>
            <DateRangePickerInput
              popoverPlacement="right"
              sdLabel={reportHaulingOrderFilter.sdLabel}
              edLabel={reportHaulingOrderFilter.edLabel}
              sdDateValue={reportHaulingOrderFilter.filterForm.values.startDate}
              sdOnChangeDateValue={
                reportHaulingOrderFilter.onChangeStartDateFilter
              }
              edDateValue={reportHaulingOrderFilter.filterForm.values.endDate}
              edOnChangeDateValue={
                reportHaulingOrderFilter.onChangeEndDateFilter
              }
              onClickApplyDateRange={
                reportHaulingOrderFilter.onClickApplyDateRange
              }
              outsideAlerterStyle={{ width: '100%' }}
              definedRangeOptions={
                reportHaulingOrderFilter.dateRangeDefinedRangeOptions
              }
              maxDateRangeInDays={reportHaulingOrderMaxDateRangeInDays}
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={
            reportHaulingOrderFilter.isQueryStatusEmpty ||
            reportHaulingOrderFilter.filterLocalIsFetching
          }
          onClick={reportHaulingOrderFilter.onClickSubmitFilter}
          data-testid={`${reportHaulingOrderFilter.testID}Filter:SubmitButton`}
        >
          {reportHaulingOrderFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}
