import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';
import HaulingOrderForceCompleteView from './HaulingOrderForceComplete.view';

export type HaulingOrderForceCompleteRouteParam = DefaultRouteParam;

export const haulingOrderForceCompleteRoute: RouteConfig = {
  name: 'Force Complete',
  Component: React.lazy(() => import('./HaulingOrderForceComplete.view')),
  NormalComponent: <HaulingOrderForceCompleteView />,
  path: '/hauling-order/force-complete',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
