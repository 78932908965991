import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import 'twin.macro';
import * as yup from 'yup';
import { ShipperOrderPaymentStatus } from '../../../component/molecule';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import { ShipperOrderDetailTab, SOStatus } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import {
  ShipperOrderInfo,
  SOCostUpdateParam,
  SOCostUpdateResponse,
  SOHeaderUpdateParam,
} from '../../../model/ShipperOrder.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { snackbarAction } from '../../../store/snackbar.store';
import { FormItem } from '../../../types/input.type';
import { errorCodeToLabel } from '../../../util/error.util';
import { numberFormatter } from '../../../util/formatter.util';
import {
  getNumericValue,
  isFormChanged,
  removeLeadingZeros,
} from '../../../util/helper.util';
import { mapSOPaymentStatusToLabel } from '../../../util/shipperOrder.util';
import { getSOCostSectionScheme } from '../../../util/shipperOrderCreate.util';
import { isSODetailEditable } from '../../../util/shipperOrderDetail.util';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';

type Props = {
  soInfo?: ShipperOrderInfo;
  showConfirmationModal?: ShipperOrderDetailTab;
  setIsLoading: (val: boolean) => void;
  setIsCurrentSectionChanged: (val?: ShipperOrderDetailTab) => void;
  handleConfirmChangeSection: (val: ShipperOrderDetailTab) => void;
};
export type UseSODetailCost = ReturnType<typeof useSODetailCost>;

export default function useSODetailCost({
  soInfo,
  showConfirmationModal,
  setIsLoading,
  setIsCurrentSectionChanged,
  handleConfirmChangeSection,
}: Props) {
  // #region GENERAL
  const translator = useTranslator();
  const dispatch = useDispatch();
  const { handleVerifyAuth } = useVerifyAuth();

  const paymentStatus = useMemo(
    () => soInfo?.paymentStatus,
    [soInfo?.paymentStatus],
  );
  const paymentTotal = useMemo(
    () => soInfo?.totalPayment || 0,
    [soInfo?.totalPayment],
  );
  const paymentCost = useMemo(
    () =>
      (soInfo?.cost?.deliveryFee || 0) +
      (soInfo?.cost?.insurance || 0) +
      (soInfo?.cost?.tax || 0),
    [soInfo?.cost?.deliveryFee, soInfo?.cost?.insurance, soInfo?.cost?.tax],
  );
  const isFormEditable = useMemo(
    () =>
      soInfo?.status &&
      [SOStatus.IN_PROCESS, SOStatus.RESERVED].includes(soInfo?.status) &&
      soInfo?.deliveries.length <= 1 &&
      (Number(paymentTotal) === 0 ||
        (Number(paymentTotal) !== 0 && Number(paymentTotal) < paymentCost)),
    [paymentCost, paymentTotal, soInfo?.deliveries.length, soInfo?.status],
  );
  const title = translator.translate('Delivery Cost');

  // #endregion

  // #region API CALL
  const [updateSOCost, updateSOCostResponse] = api.useUpdateSOCostMutation();
  // #endregion

  // #region ACTION HANDLER
  const handleError = useCallback(
    (error: FetchBaseQueryError | SerializedError) => {
      if (!error) return;
      const data = (error as FetchBaseQueryError).data as ApiErrorResponse;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translator.translate(errorCodeToLabel(data.error.code)),
        }),
      );
    },
    [dispatch, translator],
  );
  const handleSuccessSOCost = useCallback(
    (response: SOCostUpdateResponse) => {
      if (!response || (response && !response.ok)) return;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.light,
          message: translator.translate('SO Successfully Updated.'),
        }),
      );
      if (showConfirmationModal)
        handleConfirmChangeSection(showConfirmationModal);
    },
    [dispatch, handleConfirmChangeSection, showConfirmationModal, translator],
  );
  // #endregion
  // #region FORM
  const initialValues: SOHeaderUpdateParam = useMemo(
    () => ({
      soId: soInfo?.id || '',
      deliveryFee: soInfo?.cost.deliveryFee || 0,
      insurance: soInfo?.cost.insurance || 0,
      tax: soInfo?.cost.tax || 0,
    }),
    [
      soInfo?.cost.deliveryFee,
      soInfo?.cost.insurance,
      soInfo?.cost.tax,
      soInfo?.id,
    ],
  );
  const handleSubmitCost = useCallback(
    async (values: SOCostUpdateParam) => {
      try {
        const authRes = await handleVerifyAuth();
        if (!authRes) return;
        setIsLoading(true);
        const formattedValues: SOCostUpdateParam = {
          soId: values.soId,
          deliveryFee: values.deliveryFee || 0,
          insurance: values.insurance || 0,
          tax: values.tax || 0,
        };
        const res = await updateSOCost(formattedValues).unwrap();
        handleSuccessSOCost(res);
      } catch (err) {
        handleError(err as FetchBaseQueryError);
        setIsLoading(false);
      } finally {
        updateSOCostResponse.reset();
        setIsLoading(false);
      }
    },
    [
      handleError,
      handleVerifyAuth,
      handleSuccessSOCost,
      setIsLoading,
      updateSOCost,
      updateSOCostResponse,
    ],
  );

  const soCostForm = useFormik<SOCostUpdateParam>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmitCost,
    validationSchema: yup.object().shape(getSOCostSectionScheme(translator)),
  });

  const isCostFormChanged = useMemo(
    () => isFormChanged<SOCostUpdateParam>(soCostForm.values, initialValues),
    [initialValues, soCostForm.values],
  );

  useEffect(() => {
    setIsCurrentSectionChanged(
      isCostFormChanged ? ShipperOrderDetailTab.DELIVERY_COST : undefined,
    );
  }, [isCostFormChanged, setIsCurrentSectionChanged]);

  // #endregion

  // #region FORM ITEM
  const costFormData = useMemo(
    (): FormItem[] => [
      {
        id: 'deliveryFee',
        label: translator.translate('Goods Delivery Cost'),
        placeholder: !isFormEditable
          ? translator.translate('12.000')
          : translator.translate('Rp'),
        type: 'text',
        values: isSODetailEditable(
          soCostForm.values.deliveryFee,
          isFormEditable,
          true,
        ),
        error:
          soCostForm.errors?.deliveryFee &&
          translator.translate(soCostForm.errors?.deliveryFee),
        onChange: (deliveryFee) => {
          void soCostForm.setFieldValue(
            'deliveryFee',
            removeLeadingZeros(getNumericValue(String(deliveryFee || 0))),
          );
        },
        disabled: !isFormEditable,
      },
      {
        id: 'tax',
        label: translator.translate('Tax'),
        placeholder: !isFormEditable
          ? translator.translate('25.000')
          : translator.translate('Rp'),
        type: 'text',
        values: isSODetailEditable(
          soCostForm?.values.tax,
          isFormEditable,
          true,
        ),
        error:
          soCostForm.errors?.tax &&
          translator.translate(soCostForm.errors?.tax),
        onChange: (tax) => {
          void soCostForm.setFieldValue(
            'tax',
            removeLeadingZeros(getNumericValue(String(tax || 0))),
          );
        },
        disabled: !isFormEditable,
      },
      {
        id: 'insurance',
        label: translator.translate('Insurance'),
        placeholder: !isFormEditable
          ? translator.translate('13.000')
          : translator.translate('Rp'),
        type: 'text',
        error:
          soCostForm.errors?.insurance &&
          translator.translate(soCostForm.errors?.insurance),
        values: isSODetailEditable(
          soCostForm?.values.insurance,
          isFormEditable,
          true,
        ),
        onChange: (insurance) => {
          void soCostForm.setFieldValue(
            'insurance',
            removeLeadingZeros(getNumericValue(String(insurance || 0))),
          );
        },
        disabled: !isFormEditable,
      },
      {
        id: 'Total',
        label: translator.translate('Total'),
        placeholder: translator.translate('50.000'),
        type: 'text',
        values:
          String(
            numberFormatter(
              (soCostForm?.values.deliveryFee
                ? Number(soCostForm?.values.deliveryFee)
                : 0) +
                (soCostForm?.values.insurance
                  ? Number(soCostForm?.values.insurance)
                  : 0) +
                (soCostForm?.values.tax ? Number(soCostForm?.values.tax) : 0),
            ),
          ) || '-',
        onChange: () => {},
        disabled: true,
      },
    ],
    [isFormEditable, soCostForm, translator],
  );
  // #endregion

  const renderCostBadge: React.ReactNode | undefined = useMemo(
    () =>
      paymentStatus ? (
        <ShipperOrderPaymentStatus
          status={paymentStatus}
          statusLabel={translator.translate(
            mapSOPaymentStatusToLabel(paymentStatus),
          )}
        />
      ) : undefined,
    [paymentStatus, translator],
  );

  return {
    title,
    isFormEditable,
    isCostFormChanged,
    soCostForm,
    costFormData,
    renderCostBadge,
  };
}
