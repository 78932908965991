import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BulkActionEntity, BulkActionStatusEnum } from '../../../constant';
import { DrivingContest } from '../../../model/DrivingContest.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { errorCodeToLabel } from '../../../util/error.util';
import { drivingContestRoute } from '../../DrivingContest/drivingContest.route';
import { drivingContestRegisterRoute } from '../../DrivingContestRegister/drivingContestRegister.route';

// #region INTERFACES
export type DrivingContestRegisterConfirmationEntity =
  BulkActionEntity<DrivingContest>;
export type UseDrivingContestRegisterConfirmationHookObj = ReturnType<
  typeof useDrivingContestRegisterConfirmation
>;
// #endregion

export default function useDrivingContestRegisterConfirmation() {
  const navigate = useNavigate();
  const location = useLocation();

  const [showStatus, setShowStatus] = useState(false);

  // get all drivers in location state
  const drivingContestRegistrantStore = useMemo(
    () => (location?.state || []) as DrivingContest[],
    [location?.state],
  );

  const [drivingContestsWithStatusDetail, setDrivingContestsWithStatusDetail] =
    useState<DrivingContestRegisterConfirmationEntity[]>(
      drivingContestRegistrantStore.map((v) => ({
        ...v,
        _actionStatus: BulkActionStatusEnum.WAITING,
        _actionDetail: 'Waiting for registration',
      })),
    );
  const [showDoneBtn, setShowDoneBtn] = useState<boolean>(false);
  const [mutationsIsLoading, setMutationsIsLoading] = useState<boolean>(false); // to disable submit button when mutations is still ongoing
  const [doAssignDrivingContestRegistrant] =
    api.useAssignDrivingContestMutation();
  // #endregion

  // #region HANDLERS
  const onAssign = useCallback(() => {
    setShowStatus(true);

    const promises = drivingContestsWithStatusDetail.map((driver) =>
      doAssignDrivingContestRegistrant({ driverId: driver.id }).unwrap(),
    );

    setMutationsIsLoading(true);
    Promise.allSettled(promises)
      .then((results) => {
        for (const [idx, result] of results.entries()) {
          // on rejected
          if (result.status === 'rejected') {
            const errorQuery = (result.reason as FetchBaseQueryError)
              ?.data as ApiErrorResponse;

            setDrivingContestsWithStatusDetail((_drivers) =>
              _drivers.map((_driver) =>
                _driver.id === drivingContestsWithStatusDetail[idx].id
                  ? {
                      ..._driver,
                      _actionStatus: BulkActionStatusEnum.FAILED,
                      _actionDetail: errorCodeToLabel(errorQuery?.error?.code),
                    }
                  : _driver,
              ),
            );
            continue;
          }

          // on fulfilled
          setDrivingContestsWithStatusDetail((_drivers) =>
            _drivers.map((_driver) =>
              _driver.id === drivingContestsWithStatusDetail[idx].id
                ? {
                    ..._driver,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Registration Succeeded',
                  }
                : _driver,
            ),
          );
        }

        // on done
        setShowDoneBtn(true);
        setMutationsIsLoading(false);
      })
      .catch((err) => {
        throw err;
      });
  }, [doAssignDrivingContestRegistrant, drivingContestsWithStatusDetail]);

  const onCancel = useCallback(() => {
    navigate(drivingContestRegisterRoute.path);
  }, [navigate]);

  const onDone = useCallback(() => {
    navigate(drivingContestRoute.path);
  }, [navigate]);

  return {
    drivingContestsWithStatusDetail,
    showDoneBtn,
    mutationsIsLoading,
    showStatus,
    onRemove: onAssign,
    onCancel,
    onDone,
  };
}
