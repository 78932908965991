import { FormikErrors } from 'formik';
import { useState } from 'react';
import {
  Goods,
  ShipperOrderFormValues,
  SOActivitiesUpdateParam,
} from '../../model/ShipperOrder.model';
import useGoodsForm from '../useGoodsForm.hook';
import useTranslator from '../useTranslator.hook';

export interface SOCreateGoodsParams {
  setFieldValue: (
    field: string,
    value?: string | number | Goods[],
  ) =>
    | Promise<void>
    | Promise<FormikErrors<ShipperOrderFormValues | SOActivitiesUpdateParam>>;
}

function useSOCreateGoods({ setFieldValue }: SOCreateGoodsParams) {
  // #region GENERAL
  const translator = useTranslator();

  const [goodsByActivityIndex, setGoodsByActivityIndex] = useState<
    number | undefined
  >(undefined);
  // #endregion

  // #region FORM ITEM
  const goodsForm = useGoodsForm({
    translator,
    onSubmit: (goods) => {
      if (typeof goodsByActivityIndex === 'number') {
        void setFieldValue(`activities.[${goodsByActivityIndex}].goods`, goods);
        setGoodsByActivityIndex(undefined);
      }
    },
  });
  // #endregion

  return {
    goodsForm,
    goodsByActivityIndex,
    setGoodsByActivityIndex,
  };
}

export type UseSOCreateGoodsHookObj = ReturnType<typeof useSOCreateGoods>;
export default useSOCreateGoods;
