import React, { ReactElement } from 'react';
import tw, { styled, theme } from 'twin.macro';
import { Text } from '../../atom';
import CircleRadioSelected from '../../atom/Icon/SVG/CircleRadioSelected';

const Container = styled.button(({ isSelected }: { isSelected?: boolean }) => [
  tw`py-2 px-4 w-full flex hover:bg-orange-hover gap-2.5`,
  isSelected && tw`bg-orange-hover`,
]);
const Circle = styled.div(({ isSelected }: { isSelected?: boolean }) => [
  tw`border-[3px] mt-0.5 rounded-3xl border-grey-two w-5 h-5 mr-1 flex items-center`,
  isSelected && tw`border-orange`,
]);
const Row = styled.div(({ isAlignRight }: { isAlignRight?: boolean }) => [
  tw`flex justify-between items-end`,
  isAlignRight && tw`justify-end`,
]);
const Column = tw.div`flex flex-col flex-1`;
const Title = tw(Text.BodyFourteen)`max-w-[250px] truncate`;
const Subtitle = tw(Text.BodyFourteen)`text-neutral-400 max-w-[250px] truncate`;
const SubContentContainer = tw.div`flex flex-col text-right items-end `;

type Props = {
  isSelected?: boolean;
  labelTitle?: string;
  labelSubtitle?: string;
  valueTitle?: string;
  subContent?: ReactElement;
  showSelection?: boolean;
  onClickScheduleItem: () => void;
};

export default function BaseScheduleItem({
  isSelected,
  labelTitle,
  labelSubtitle,
  valueTitle,
  subContent,
  showSelection,
  onClickScheduleItem,
}: Props) {
  return (
    <Container
      type="button"
      isSelected={isSelected}
      onClick={onClickScheduleItem}
    >
      {showSelection && (
        <Circle isSelected={isSelected}>
          {isSelected && (
            <CircleRadioSelected
              innerCircleR="5"
              strokeWidth={2}
              stroke={theme`colors.orange`}
            />
          )}
        </Circle>
      )}
      <Column>
        <Row>
          {!!labelTitle && <Title>{labelTitle}</Title>}
          {!!valueTitle && <Title>{valueTitle}</Title>}
        </Row>

        <Row isAlignRight={!labelSubtitle}>
          {!!labelSubtitle && <Subtitle>{labelSubtitle}</Subtitle>}
          <SubContentContainer>{subContent}</SubContentContainer>
        </Row>
      </Column>
    </Container>
  );
}
