import React from 'react';
import tw, { theme } from 'twin.macro';
import {
  reportSOFormDateRangeInitialValues,
  reportSOMaxDateRangeInDays,
} from '../../../constant/ReportShipperOrder.constant';
import { UseGetDriversAutocompleteObj } from '../../../hook/useGetDriversAutocomplete.hook';
import { UseGetShippersAutocompleteObj } from '../../../hook/useGetShippersAutocomplete.hook';
import { UseGetVehiclesAutocompleteObj } from '../../../hook/useGetVehiclesAutocomplete.hook';
import { UseReportShipperOrderFilterHookObj } from '../../../view/Report/ShipperOrder/hook/useReportShipperOrderFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region INTERFACES
type Props = {
  reportSOFilter: UseReportShipperOrderFilterHookObj;
  shippersAutocomplete: UseGetShippersAutocompleteObj;
  driversAutocomplete: UseGetDriversAutocompleteObj;
  vehiclesAutocomplete: UseGetVehiclesAutocompleteObj;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
const DatePickerContainer = tw.div`w-full flex items-center justify-between space-x-2`;
// #endregion

function ReportSOFilter({
  reportSOFilter,
  shippersAutocomplete,
  driversAutocomplete,
  vehiclesAutocomplete,
}: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <Text.HeadingFour
          tw="flex-1 ml-2"
          data-testid={`${reportSOFilter.testID || ''}Filter:Title`}
        >
          {reportSOFilter.titleLabel}
        </Text.HeadingFour>

        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          data-testid={`${reportSOFilter.testID || ''}Filter:ResetAction`}
          onClick={reportSOFilter.onClickReset}
        >
          {reportSOFilter.resetBtnLabel}
        </Button.Outlined>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportSOFilter.testID || ''}Filter:OrderByLabel`}
          >
            {reportSOFilter.orderByLabel}
          </InputHeading>

          <ChipContainer>
            {reportSOFilter.filterOrderByValues.map((el, i) => {
              const selected = reportSOFilter.form.values.orderBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() => reportSOFilter.onClickOrderByChip(el.value)}
                    testID={`${
                      reportSOFilter.testID || ''
                    }Filter:OrderByOption:${i}`}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${reportSOFilter.testID || ''}Filter:StatusLabel`}
            >
              {reportSOFilter.statusLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                reportSOFilter.isQueryStatusAll
                  ? reportSOFilter.onClickUnselectAllStatus
                  : reportSOFilter.onClickSelectAllStatus
              }
            >
              {reportSOFilter.isQueryStatusAll
                ? reportSOFilter.unselectAllLabel
                : reportSOFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {reportSOFilter.filterStatusValues.map((el, i) => {
              const selected = reportSOFilter.form.values.status.includes(
                el.value,
              );

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() => reportSOFilter.onClickStatusChip(el.value)}
                    testID={`${
                      reportSOFilter.testID || ''
                    }Filter:StatusOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {reportSOFilter.isQueryStatusEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {reportSOFilter.errorStatusLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportSOFilter.testID || ''}Filter:ShipperLabel`}
          >
            {reportSOFilter.shipperLabel}
          </InputHeading>

          <AutoComplete
            tw="bg-orange-dark-lines"
            value={reportSOFilter.form.values.shipperId}
            datas={shippersAutocomplete.shipperOptions}
            selectedOption={reportSOFilter.queryShipperOption}
            placeholder={reportSOFilter.shipperPlaceholder}
            loading={shippersAutocomplete.shipperListFetchLoading}
            changeData={reportSOFilter.onChangeShipper}
            changeInputText={shippersAutocomplete.onChangeShipperAutotext}
            onFetchMore={shippersAutocomplete.onFetchMoreShipper}
            onRemoveData={reportSOFilter.onRemoveShipper}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportSOFilter.testID || ''}Filter:DriverLabel`}
          >
            {reportSOFilter.driverLabel}
          </InputHeading>

          <AutoComplete
            tw="bg-orange-dark-lines"
            value={reportSOFilter.form.values.driverId}
            datas={driversAutocomplete.driverOptions}
            selectedOption={reportSOFilter.queryDriverOption}
            placeholder={reportSOFilter.driverPlaceholder}
            loading={driversAutocomplete.driverListFetchLoading}
            changeData={reportSOFilter.onChangeDriver}
            changeInputText={driversAutocomplete.onChangeDriverAutotext}
            onFetchMore={driversAutocomplete.onFetchMoreDriver}
            onRemoveData={reportSOFilter.onRemoveDriver}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportSOFilter.testID || ''}Filter:VehicleLabel`}
          >
            {reportSOFilter.vehicleLabel}
          </InputHeading>

          <AutoComplete
            hasExtraLabel
            labelKey="extraLabel"
            tw="bg-orange-dark-lines"
            value={reportSOFilter.form.values.vehicleId}
            datas={vehiclesAutocomplete.vehicleOptions}
            selectedOption={reportSOFilter.queryVehicleOption}
            placeholder={reportSOFilter.vehiclePlaceholder}
            loading={vehiclesAutocomplete.vehicleListFetchLoading}
            changeData={reportSOFilter.onChangeVehicle}
            changeInputText={vehiclesAutocomplete.onChangeVehicleAutotext}
            onFetchMore={vehiclesAutocomplete.onFetchMoreVehicle}
            onRemoveData={reportSOFilter.onRemoveVehicle}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${reportSOFilter.testID || ''}Filter:DateRangeLabel`}
          >
            {reportSOFilter.dateRangeLabel}
          </InputHeading>

          <DatePickerContainer>
            <DateRangePickerInput
              sdLabel={reportSOFilter.sdLabel}
              edLabel={reportSOFilter.edLabel}
              sdDateValue={reportSOFilter.form.values.startDate}
              sdOnChangeDateValue={reportSOFilter.onChangeStartDate}
              edDateValue={reportSOFilter.form.values.endDate}
              edOnChangeDateValue={reportSOFilter.onChangeEndDate}
              onClickApplyDateRange={reportSOFilter.onClickApplyDateRange}
              outsideAlerterStyle={{ width: '100%' }}
              definedRangeOptions={reportSOFilter.dateRangeDefinedRangeOptions}
              disabledDays={reportSOFilter.dateRangeDisabledDays}
              defaultDateRange={reportSOFormDateRangeInitialValues}
              maxDateRangeInDays={reportSOMaxDateRangeInDays}
              notes={reportSOFilter.dateRangeNotes}
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={
            reportSOFilter.isQueryStatusEmpty ||
            reportSOFilter.filterLocalIsFetching
          }
          onClick={reportSOFilter.onClickSubmit}
          data-testid={`${reportSOFilter.testID || ''}Filter:SubmitButton`}
        >
          {reportSOFilter.submitBtnLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default ReportSOFilter;
