import React from 'react';
import tw from 'twin.macro';
import { rdbByVehicleDateRangeInitialValues } from '../../../constant/ReportDrivingBehaviorByVehicle.constant';
import { UseGetVehiclesAutocompleteObj } from '../../../hook/useGetVehiclesAutocomplete.hook';
import { UseRDBByVehicleFilterObj } from '../../../view/Report/DrivingBehavior/hook/Vehicle/useRDBByVehicleFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChipContainer = tw.div`mb-2 mr-2`;
const FilterChip = tw(Chip.Small)`text-grey-two`;
const DatePickerContainer = tw.div`mt-3 flex items-center justify-between space-x-2`;
const TitleLabel = tw(Text.HeadingFour)`flex-1 ml-2`;
const ResetButton = tw(Button.Outlined)`px-4 bg-white text-grey-two`;
const VehicleAutocomplete = tw(AutoComplete)`bg-orange-dark-lines`;
// #endregion

// #region INTERFACES
type Props = {
  rdbVehicleFilter: UseRDBByVehicleFilterObj;
  vehiclesAutocomplete: UseGetVehiclesAutocompleteObj;
};
// #endregion

function RDBByVehicleFilter({ rdbVehicleFilter, vehiclesAutocomplete }: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <TitleLabel data-testid={`${rdbVehicleFilter.testID}Filter:Title`}>
          {rdbVehicleFilter.titleLabel}
        </TitleLabel>

        <ResetButton
          small
          data-testid={`${rdbVehicleFilter.testID}Filter:ResetAction`}
          onClick={rdbVehicleFilter.onClickReset}
        >
          {rdbVehicleFilter.resetButtonLabel}
        </ResetButton>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading
            data-testid={`${rdbVehicleFilter.testID || ''}Filter:OrderByLabel`}
          >
            {rdbVehicleFilter.orderByLabel}
          </InputHeading>

          <ChipContainer>
            {rdbVehicleFilter.filterOrderByValues.map((el, i) => {
              const selected =
                rdbVehicleFilter.form.values.orderBy === el.value;

              return (
                <FilterChipContainer key={`Filter_Category_${el.value}`}>
                  <FilterChip
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      rdbVehicleFilter.onClickOrderByChip(el.value)
                    }
                    testID={`${
                      rdbVehicleFilter.testID || ''
                    }Filter:OrderByOption:${i}`}
                  />
                </FilterChipContainer>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${rdbVehicleFilter.testID}Filter:VehicleLabel`}
          >
            {rdbVehicleFilter.vehicleLabel}
          </InputHeading>

          <VehicleAutocomplete
            hasExtraLabel
            labelKey="extraLabel"
            value={rdbVehicleFilter.form.values.vehicleId}
            datas={vehiclesAutocomplete.vehicleOptions}
            selectedOption={rdbVehicleFilter.queryVehicleOption}
            placeholder={rdbVehicleFilter.vehiclePlaceholder}
            loading={vehiclesAutocomplete.vehicleListFetchLoading}
            changeData={rdbVehicleFilter.onChangeVehicle}
            changeInputText={vehiclesAutocomplete.onChangeVehicleAutotext}
            onFetchMore={vehiclesAutocomplete.onFetchMoreVehicle}
            onRemoveData={rdbVehicleFilter.onRemoveVehicle}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${
              rdbVehicleFilter.testID || ''
            }Filter:DateRangeLabel`}
          >
            {rdbVehicleFilter.dateLabel}
          </InputHeading>

          <DatePickerContainer>
            <DateRangePickerInput
              sdLabel={rdbVehicleFilter.sdLabel}
              edLabel={rdbVehicleFilter.edLabel}
              sdDateValue={rdbVehicleFilter.form.values.startDate}
              sdOnChangeDateValue={rdbVehicleFilter.onChangeStartDate}
              edDateValue={rdbVehicleFilter.form.values.endDate}
              edOnChangeDateValue={rdbVehicleFilter.onChangeEndDate}
              onClickApplyDateRange={rdbVehicleFilter.onClickApplyDateRange}
              maxDateRangeInDays={31}
              outsideAlerterStyle={{ width: '100%' }}
              definedRangeOptions={
                rdbVehicleFilter.dateRangeDefinedRangeOptions
              }
              disabledDays={rdbVehicleFilter.dateRangeDisabledDays}
              notes={rdbVehicleFilter.dateNoteLabel}
              defaultDateRange={rdbByVehicleDateRangeInitialValues}
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={rdbVehicleFilter.filterLocalIsFetching}
          onClick={rdbVehicleFilter.onClickSubmit}
          data-testid={`${rdbVehicleFilter.testID}Filter:SubmitButton`}
        >
          {rdbVehicleFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default RDBByVehicleFilter;
