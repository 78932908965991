import differenceInMinutes from 'date-fns/differenceInMinutes';
import fromUnixTime from 'date-fns/fromUnixTime';
import { FormikProps } from 'formik';
import { MAX_ALLOWED_TIME, SOActivityType, SOStatus } from '../constant';
import { UseTranslator } from '../hook/useTranslator.hook';
import {
  ShipperOrderInfo,
  SOActivitiesUpdateParam,
  SOActivityDetail,
  SOActivityFormProps,
} from '../model/ShipperOrder.model';
import { UseSODetailActivity } from '../view/ShipperOrderDetail/hooks/useSODetailActivity.hook';
import { UseSODetailCreateAT } from '../view/ShipperOrderDetail/hooks/useSODetailCreateAT.hook';
import { numberFormatter } from './formatter.util';
/**
 * Check if the activity has `goods out`
 * @param activity
 * @returns
 */
export const hasGoodsOut = (activity: SOActivityDetail) =>
  activity.goodsOut.length > 0;

/**
 *
 * @param soStatus
 * @returns
 */
export const isAllowedStatus = (soStatus: SOStatus) =>
  [SOStatus.DELIVERING, SOStatus.DELIVERED].includes(soStatus);

/**
 * Check if selected goods is within allowed time to edit (max <= 72hrs)
 * @param soInfo
 * @returns
 */
export const isWithin72Hours = (completedAt?: number) => {
  if (!completedAt) return true;

  return (
    differenceInMinutes(new Date(), fromUnixTime(completedAt)) <
    MAX_ALLOWED_TIME * 60
  );
};

/**
 * Check if goods is editable, by default this helper check for `goods in` within an activity,
 * to check `goods out` pass false to 3rd parameter
 * @param activity
 * @param soStatus
 * @param isGoodsIn Default value is `true`
 * @returns
 */
export const isGoodsEditable = (
  activity: SOActivityDetail,
  soInfo: ShipperOrderInfo,
  isGoodsIn = true,
) => {
  const isAllowed = isAllowedStatus(soInfo.status);

  if (!isGoodsIn) {
    if (soInfo.status === SOStatus.DELIVERED)
      return isWithin72Hours(soInfo.completedAt);
    return isAllowed;
  }

  const isGoodsInEditAllowed =
    activity.goods.length > 0 &&
    activity.type === SOActivityType.DROP_OFF &&
    isAllowed &&
    !hasGoodsOut(activity);
  if (soInfo.status === SOStatus.DELIVERED)
    return isWithin72Hours(soInfo?.completedAt) && isGoodsInEditAllowed;

  return isGoodsInEditAllowed;
};

/**
 * Check if form is editable,
 * @param formValue
 * @param isFormEditable
 * @param isNumber
 * @returns
 */
export const isSODetailEditable = (
  formValue?: string | number | null,
  isFormEditable?: boolean,
  isNumber?: boolean,
) => {
  let value = '';
  if (!isFormEditable) {
    value = formValue ? formValue.toString() : '-';
  } else {
    value = formValue ? formValue.toString() : '';
  }

  if (isNumber && value !== '-') return String(numberFormatter(value));
  return value;
};

export const generateSODetailActivityFormData = ({
  translator,
  formState,
  isLoading,
  soDetailActivity,
  soDetailCreateAT,
  soDate,
}: {
  translator: UseTranslator;
  formState: FormikProps<SOActivitiesUpdateParam>;
  isLoading?: boolean;
  soDetailActivity: UseSODetailActivity;
  soDetailCreateAT: UseSODetailCreateAT;
  soDate?: number;
}): SOActivityFormProps => ({
  soDate,
  title: translator.translate('Activity'),
  actionLabel: translator.translate('Save Changes'),
  activities: formState.values.activities,
  activitiesError: formState.errors?.activities,
  isActionDisabled:
    isLoading || !soDetailActivity.isActivityFormChanged || !formState.isValid,
  isFooterVisible: soDetailActivity.isFormEditable,
  isLocationListFetchLoading: soDetailCreateAT.locationListFetchLoading,
  locationOptions: soDetailCreateAT.locationOptions,
  handleAction: formState.handleSubmit,
  handleAddNewLocation: soDetailCreateAT.handleAddNewLocation,
  handleAddMoreActivity: soDetailActivity.handleAddMoreActivity,
  handleChangeDataLocation: soDetailCreateAT.handleChangeDataLocation,
  handleChangeDateActivity: soDetailActivity.handleChangeDateActivity,
  handleChangeLocationAutotext: soDetailCreateAT.handleChangeLocationAutotext,
  handleClickChoice: soDetailActivity.handleClickChoice,
  handleClickEditActivity: soDetailCreateAT.onActivityEditClick,
  handleDuplicateActivity: (e) => soDetailActivity.handleDuplicateActivity(e),
  handleFetchMoreLocation: soDetailCreateAT.handleFetchMoreLocation,
  handleRemoveActivity: (e) => soDetailActivity.handleRemoveActivity(e),
  handleRemoveLocation: soDetailActivity.handleRemoveLocation,
  getActivityFormErrors: soDetailCreateAT.getActivityFormErrors,
});
