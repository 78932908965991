import React from 'react';
import { RouteConfig } from '../../config/router/router.config';

export type OrganizationResetRouteParam =
  | {
      isResetting?: boolean;
    }
  | undefined;
export const organizationResetRoute: RouteConfig = {
  name: 'Organization Reset',
  path: '/reset',
  Component: React.lazy(() => import('./OrganizationReset.view')),
  isPrivate: true,
  options: {
    label: 'OrganizationReset',
    testID: 'MenuOrganizationReset',
  },
};
