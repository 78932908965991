import React, { ReactNode } from 'react';
import tw from 'twin.macro';
import { SOActivityType } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { HeadingFive } from '../../atom/Text/Text.atom';

const ActivityTableData = tw.td`py-2.5`;
const TableHead = tw.thead``;
const TableRow = tw.tr``;
const Container = tw.div``;

export type TableHeaderItem = {
  title: string;
  customText?: ReactNode;
  width?: string;
  isNumber?: boolean;
};
type Props = {
  items: TableHeaderItem[];
  status: string;
};

export default function ActivityTableHeader({ items, status }: Props) {
  const translator = useTranslator();
  return (
    <TableHead>
      <TableRow>
        {items.map((item) => (
          <ActivityTableData
            width={item?.width}
            key={item.title}
            css={[
              status === SOActivityType.STAND_BY && tw`border-b-lime-border`,
              status === SOActivityType.PICK_UP &&
                tw`border-b-ocean-border-light`,
              status === SOActivityType.DROP_OFF &&
                tw`border-b-twilight-border-light`,
            ]}
            tw="not-last:pr-3 border-b"
          >
            <Container css={[item.isNumber && tw`justify-end`, tw`flex`]}>
              {item.customText ? (
                item.customText
              ) : (
                <HeadingFive>{translator.translate(item.title)}</HeadingFive>
              )}
            </Container>
          </ActivityTableData>
        ))}
      </TableRow>
    </TableHead>
  );
}
