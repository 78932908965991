import React from 'react';
import { Icon } from '../../component/atom';
import { RouteConfig } from '../../config/router/router.config';

export const haulingJobOrderCreateRoute: RouteConfig = {
  name: 'Create Hauling Job Order',
  Component: React.lazy(() => import('./HaulingJobOrderCreate.view')),
  path: '/hauling-order/:hoId/assignment/:deliveryId/create',
  isPrivate: true,
  breadcrumb: 'Create Hauling Job Order',
  options: {
    icon: <Icon.NavHaulingOrder />,
    label: 'Create Hauling Job Order',
    testID: 'MenuHaulingOrder',
  },
  props: {
    isUnclickable: true,
  },
};

export default { haulingJobOrderCreateRoute };
