import _isNaN from 'lodash/isNaN';
import * as yup from 'yup';
import { ErrorCodes } from '../constant';
import {
  LocationOrderByValue,
  LocationType,
  LocationTypeValue,
} from '../constant/Location.constant';
import { mapDefaultRadius } from '../constant/Map.constant';
import { UseTranslator } from '../hook/useTranslator.hook';
import {
  LocationForm,
  LocationOrderBy,
  PostLocationParams,
} from '../model/Location.model';
import { ErrorDetail } from '../service/api.endpoint';
import { errorCodeToLabel } from './error.util';
import {
  snakeCasedToReadableFormat,
  stringDecimalFormatter,
  template,
  validateContactNumber,
} from './helper.util';

const literalLocationUploadLabel: Record<string, string> = {
  radius: 'Site Radius',
  name: 'Location Name',
  'Contact Number': 'Phone Number',
};

export const initialDeliveryLocationFormValues: LocationForm = {
  name: '',
  address: '',
  latitude: undefined,
  longitude: undefined,
  contactName: '',
  contactNumber: '',
  type: undefined,
  shipperId: undefined,
  radius: mapDefaultRadius,
};

export const getTestNumberValue = (val: string | undefined) =>
  stringDecimalFormatter(val) !== 0 || _isNaN(stringDecimalFormatter(val));

export const getTestMinimumNumberValue = (val: string | undefined) =>
  stringDecimalFormatter(val) >= 1 || !val;

export const getTestMaximumNumberValue = (val: string | undefined) =>
  stringDecimalFormatter(val) <= 999999 || !val;

export const DLAFormValidationSchema = yup.object().shape({
  name: yup
    .string()
    .max(255, 'Location name must be at most 255 characters.')
    .required('Location name is required.'),
  radius: yup
    .string()
    .test('radius', 'Radius is required.', getTestNumberValue)
    .test('radius', 'Minimum radius is 1 m.', getTestMinimumNumberValue)
    .test('radius', 'Maximum radius is 999.999 m.', getTestMaximumNumberValue),
  address: yup.string().required('Address is required.'),
  latitude: yup
    .number()
    .min(
      -10.5742220783,
      'Please make sure to enter a valid Indonesian area latitude.',
    )
    .max(
      6.8391696263,
      'Please make sure to enter a valid Indonesian area latitude.',
    )
    .required('Latitude is required.')
    .typeError('Latitude must be a number.'),
  longitude: yup
    .number()
    .min(
      94.482421875,
      'Please make sure to enter a valid Indonesian area longitude.',
    )
    .max(
      141.064453125,
      'Please make sure to enter a valid Indonesian area longitude.',
    )
    .required('Longitude is required.')
    .typeError('Longitude must be a number.'),
  contactName: yup
    .string()
    .max(50, 'Contact name must be at most 50 characters.'),
  contactNumber: yup
    .string()
    .test('contactNumber', {}, validateContactNumber('contactNumber'))
    .nullable(),
  type: yup.string().required('Type is required.'),
  shipperId: yup.string().when('type', {
    is: (val: string) => val === LocationType.SHIPPER,
    then: yup.string().required('Shipper Name is required.'),
    otherwise: yup.string().notRequired(),
  }),
});

export const getShipperPlaceholderMapper = (type?: string): string => {
  if (!type) return 'My Location';
  const placeHolderValue: Record<string, string> = {
    [LocationType.SHIPPER]: 'Select Shipper',
    [LocationType.PUBLIC]: 'Public',
    [LocationType.TRANSPORTER]: 'My Location',
  };
  return placeHolderValue[type];
};

export const getPostLocationParams = (
  values: LocationForm,
): PostLocationParams => ({
  address: values.address || '',
  latitude: values.latitude || 0,
  longitude: values.longitude || 0,
  name: values.name,
  type: values.type || LocationType.PUBLIC,
  ...(values.contactName ? { contactName: values.contactName } : {}),
  ...(values.contactNumber
    ? { contactNumber: `+62${values.contactNumber}` }
    : {}),
  ...(values.shipperId && values.type === LocationType.SHIPPER
    ? { shipperId: values.shipperId }
    : {}),
});

export const getIsFormChanged = (
  values: LocationForm,
  locationInfoFormData?: LocationForm,
) => {
  if (!locationInfoFormData) return false;
  return !Object.keys(values)
    .map((v) => {
      const key = v as keyof LocationForm;
      return values[key]?.toString() === locationInfoFormData[key]?.toString();
    })
    .every((v) => !!v);
};

/**
 * map thru delivery location filter orderBy to label
 */
export function mapLocationFilterOrderByToLabel(
  orderBy: LocationOrderBy,
): string {
  const literal: Record<LocationOrderBy, string> = {
    [LocationOrderBy.NAME_ASC]: 'Location Name A-Z',
    [LocationOrderBy.NAME_DESC]: 'Location Name Z-A',
    [LocationOrderBy.CREATED_AT_DESC]: 'Recently Added',
  };

  return literal[orderBy];
}

/**
 * get delivery location filter orderBy values for chips
 */
export function getLocationFilterOrderByValues(
  translator: UseTranslator,
): LocationOrderByValue[] {
  return [
    {
      label: translator.translate('Location Name A-Z'),
      value: LocationOrderBy.NAME_ASC,
    },
    {
      label: translator.translate('Location Name Z-A'),
      value: LocationOrderBy.NAME_DESC,
    },
    {
      label: translator.translate('Recently Added'),
      value: LocationOrderBy.CREATED_AT_DESC,
    },
  ];
}

/**
 * map delivery location filter type[] to translated labels
 */
export function mapLocationFilterTypesToLabels(
  types: LocationType[],
  translator: UseTranslator,
): string[] {
  return types.map((type) => {
    const literal: Record<LocationType, string> = {
      [LocationType.TRANSPORTER]: translator.translate('Transporter'),
      [LocationType.SHIPPER]: translator.translate('Shipper'),
      [LocationType.PUBLIC]: translator.translate('Public'),
      [LocationType.LOCATION_TYPE]: translator.translate('Location Type'),
    };

    return literal[type];
  });
}

/**
 * get delivery location filter type values for chips
 */
export function getLocationFilterTypeValues(
  translator: UseTranslator,
): LocationTypeValue[] {
  return [
    {
      label: translator.translate('Transporter'),
      value: LocationType.TRANSPORTER,
    },
    {
      label: translator.translate('Shipper'),
      value: LocationType.SHIPPER,
    },
    {
      label: translator.translate('Public'),
      value: LocationType.PUBLIC,
    },
  ];
}

/**
 * check bulk location add excel data validity
 */
export function checkLocationAddExcelDataValidity(
  columns: unknown[],
  data: unknown[],
) {
  const commonErrorLabel =
    'has invalid format. Please use provided XLS template and re-upload';
  const exceeded100ErrorLabel =
    'exceeded the maximum data allowed. Please reduce the data amount to a maximum of 100 rows';

  // check at least 1 data / data columns to be exactly 9
  if (!data.length || columns.length !== 9) return commonErrorLabel;
  // check max 100 data
  if (data.length > 100) return exceeded100ErrorLabel;

  return '' as const;
}

function mapCustomLocationErrorMessage(label: string) {
  return literalLocationUploadLabel[label];
}

/**
 * Map error messages to string
 */
export function mapLocationErrorMessages(
  translator: UseTranslator,
  errorDetails: ErrorDetail[],
) {
  const errorMessages: string[] = [
    ...new Map(errorDetails.map((item) => [item.key, item])).values(),
  ].map(({ key, message }) => {
    if (key === 'shipper_id') return '';
    const labelMessage = 'Invalid {{label}}';
    const isCustomLabel = mapCustomLocationErrorMessage(key as string);
    // NOTE: Disable eslint this exact message should be customized
    if (message === `\"shipper_name\" is not allowed`)
      return translator.translate('Shipper Name is not Allowed');

    const templated = template(labelMessage, {
      label: isCustomLabel ?? snakeCasedToReadableFormat(key as string),
    });
    return translator.translate(templated);
  });

  return `${errorMessages.filter((item) => !!item).join(', ')}.`;
}

/**
 * Get delivery location custom error message
 * @param location
 * @param errorCode
 * @param errorDetails
 * @returns
 */
export const getLocationCustomErrorMessage = (
  errorCode?: string,
  errorDetails?: ErrorDetail[],
): string | ErrorDetail[] => {
  if (errorCode === ErrorCodes.REQUEST_INVALID_ARGUMENT)
    return errorDetails || '';
  return errorCodeToLabel(errorCode);
};
