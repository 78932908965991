import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback } from 'react';
import { ErrorCodes } from '../../../constant';
import api from '../../../service/api.service';
import { SessionStore } from '../../../store/session.store';
import useAuthRevoke from './useAuthRevoke.hook';
import { ClientAuthenticationResponse } from './useSession.hook';

function useVerifyToken() {
  const [verifyToken] = api.useLazyVerifyTokenQuery();
  const [authTest] = api.useAuthTestMutation();
  const { handleAuthRevoke } = useAuthRevoke();

  const handleVerifyToken = useCallback(
    async (
      ssoToken: string,
    ): Promise<
      ClientAuthenticationResponse<
        Omit<SessionStore, 'isLoggedIn' | 'organizationName'>
      >
    > => {
      try {
        const verifyTokenResponse = await verifyToken({ ssoToken }).unwrap();
        if (!verifyTokenResponse?.response?.data?.result) {
          return {
            ok: false,
            code: ErrorCodes.SSO_VERIFY_AUTH_FAILED,
          };
        }
        const authTestRes = await authTest({
          token: verifyTokenResponse.response.data.result.token,
        }).unwrap();
        if (!authTestRes.ok) {
          handleAuthRevoke(verifyTokenResponse.response.globalSessionID);
        }
        return {
          ok: true,
          data: {
            token: verifyTokenResponse.response.data.result.token,
            globalSessionID: verifyTokenResponse.response.globalSessionID,
            username: verifyTokenResponse.response.data.result.username,
            organizationId:
              verifyTokenResponse.response.data.result.organizationId,
            userId: verifyTokenResponse.response.data.result.userId,
          },
        };
      } catch (error: unknown) {
        const err = error as FetchBaseQueryError;

        if (err.status === 403) {
          return {
            ok: false,
            code: ErrorCodes.SSO_FORBIDDEN_REQUEST,
          };
        }

        return {
          ok: false,
          code: ErrorCodes.SSO_VERIFY_AUTH_FAILED,
        };
      }
    },
    [verifyToken, handleAuthRevoke, authTest],
  );

  return { handleVerifyToken };
}

export default useVerifyToken;
