import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterDriverAppAddExpenseRouteParam = DefaultRouteParam;

export const helpCenterDriverAppAddExpenseRoute: RouteConfig = {
  name: 'Help Center Driver App Add Expense',
  Component: React.lazy(() => import('./HelpCenterDriverAppAddExpense.view')),
  path: '/help-center/driver-app/add-expense',
  isPrivate: false,
  breadcrumb: 'Help Center Driver App Add Expense',
  options: {
    label: 'Mengajukan Pengeluaran',
    testID: 'MenuHelpCenterDriverAppAddExpense',
  },
};
