import React from 'react';
import tw, { styled } from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { JOStatus } from '../../../model/JobOrder.model';
import { Button, Icon, IconButton, LoadingIndicator, Text } from '../../atom';
import { JobOrderStatus } from '../../molecule';

// #region INTERFACES
type HeaderContentItemProps = {
  label: string;
  value: string;
};

type Props = {
  number: string | React.ReactNode;
  status: JOStatus;
  statusLabel: string;
  headerContentItems: HeaderContentItemProps[];
  onForceCompleteJOClicked?(): void;
  onClickBack?(): void;
  translator: UseTranslator;
  forceLoading?: boolean;
  singleHeaderColumn?: boolean;
  showEditButton?: boolean;
  onEditHeader?: () => void;
};
// #endregion

// #region STYLED
const HeaderContainer = tw.section`flex flex-col shadow-card rounded-md bg-white`;
const HeaderTopContainer = tw.div`flex flex-row justify-between items-center w-full p-4 rounded-t-md border-b-2 border-b-beige-lines`;
const HeaderTopNavContainer = tw.div`flex flex-row justify-between items-center`;
const HeaderContentContainer = styled.div(
  ({ singleColumn }: { singleColumn?: boolean }) => [
    tw`grid grid-cols-1 lg:grid-cols-2 gap-y-1 gap-x-12 p-5`,
    singleColumn && tw`lg:grid-cols-1`,
  ],
);
const HeaderContentItemContainer = styled.div(
  ({ singleColumn }: { singleColumn?: boolean }) => [
    tw`flex flex-row gap-8 py-2 border-b-2 border-b-beige-lines animate-slide-in-left last-of-type:border-0`,
    !singleColumn && tw`lg:[&:nth-last-of-type(2)]:border-0`,
  ],
);
const FlexGrow = tw.div`flex flex-grow`;
const ForceCompleteButton = tw(
  Button.Outlined,
)`px-2 py-0.5 inline-block font-bold rounded-md text-assigned-dark h-7 text-xs mr-5`;
// #endregion

export default function JODetailHeader({
  statusLabel,
  number,
  status,
  headerContentItems,
  onForceCompleteJOClicked,
  onClickBack,
  translator,
  forceLoading,
  singleHeaderColumn,
  onEditHeader,
  showEditButton,
}: Props) {
  return (
    <HeaderContainer>
      <HeaderTopContainer>
        <HeaderTopNavContainer>
          {onClickBack && (
            <IconButton onClick={onClickBack}>
              <Icon.ArrowBack fill="#F3532E" />
            </IconButton>
          )}
          {typeof number === 'string' ? (
            <Text.HeadingFour tw="ml-2.5">{number}</Text.HeadingFour>
          ) : (
            number
          )}
        </HeaderTopNavContainer>
        <FlexGrow />
        {(status === JOStatus.ASSIGNED || status === JOStatus.DELIVERING) &&
          onForceCompleteJOClicked && (
            <ForceCompleteButton
              onClick={onForceCompleteJOClicked}
              css={[showEditButton && tw`mr-2.5`]}
            >
              {translator.translate('Force Complete')}
            </ForceCompleteButton>
          )}
        {status === JOStatus.ASSIGNED && showEditButton && (
          <ForceCompleteButton onClick={onEditHeader}>
            {translator.translate('Edit Header')}
          </ForceCompleteButton>
        )}
        {forceLoading ? (
          <LoadingIndicator size="small" />
        ) : (
          <JobOrderStatus status={status} statusLabel={statusLabel} />
        )}
      </HeaderTopContainer>

      <HeaderContentContainer singleColumn={singleHeaderColumn}>
        {headerContentItems.map(({ label, value }) => (
          <HeaderContentItemContainer
            singleColumn={singleHeaderColumn}
            key={`${label} ${value}`}
          >
            <Text.Label tw="w-40 min-w-[8rem] text-grey-three">
              {label}
            </Text.Label>
            <Text.BodyOne tw="truncate w-72">{value}</Text.BodyOne>
          </HeaderContentItemContainer>
        ))}
      </HeaderContentContainer>
    </HeaderContainer>
  );
}
