import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import { FormikErrors, useFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  JOAssignmentBy,
  JODriverAssignmentFormType,
} from '../../constant/JOAssignment.constant';
import {
  Driver,
  GetDriverApiRequest,
  GetDriverApiResponse,
} from '../../model/Driver.model';
import { JobOrderForm } from '../../model/JobOrder.model';
import { Schedule } from '../../model/Schedule.model';
import api from '../../service/api.service';
import { FormItem } from '../../types/input.type';
import { isFormChanged } from '../../util/helper.util';
import {
  joAssignmentToJOFormFormatter,
  JODriverAssignmentFormValidationSchema,
  joDriverAssignmentInitialValues,
} from '../../util/joAssignment.util';
import useDriverScheduleCalendar from '../driver/useDriverScheduleCalendar.hook';
import { AutocompleteType } from '../useAutocomplete.hook';
import useFetchMoreAutocomplete, {
  AutocompleteOptionsData,
} from '../useFetchMoreAutocomplete.hook';
import useTranslator from '../useTranslator.hook';

type Props = {
  joFormValues: JobOrderForm;
  setJOFormValues: (
    values: React.SetStateAction<JobOrderForm>,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<JobOrderForm>>;
};
export type UseJODriverAssignmentFormHookObj = ReturnType<
  typeof useJODriverAssignmentForm
>;

export default function useJODriverAssignmentForm({
  joFormValues,
  setJOFormValues,
}: Props) {
  const translator = useTranslator();
  const fullfilledTimestampRef = useRef<number | undefined>(undefined);
  const refId = useRef<string | undefined>(undefined);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const initialMonth = useMemo(() => {
    if (!joFormValues.joDate) return startOfToday();
    return startOfDay((joFormValues.joDate || 0) * 1000);
  }, [joFormValues.joDate]);

  const joInitialValues: JODriverAssignmentFormType = useMemo(() => {
    if (!joFormValues.schedule) return joDriverAssignmentInitialValues;
    return {
      schedule: joFormValues.schedule,
      scheduleId: joFormValues?.schedule?.id,
      driverId: joFormValues.driverId,
      vehicleOption: joFormValues.vehicleOption,
    };
  }, [joFormValues]);

  const title = translator.translate('Schedules');
  const emptyTitle = translator.translate('There is no schedule.');
  const emptySubtitle = translator.translate(
    'Please select Driver to show schedule list.',
  );

  const handleSubmitDriverForm = useCallback(
    async (val: JODriverAssignmentFormType) => {
      const sanitizedData = joAssignmentToJOFormFormatter({
        joFormValues,
        schedule: val.schedule,
      });
      await setJOFormValues({
        ...sanitizedData,
        assignmentType: JOAssignmentBy.DRIVER,
      });
      setHasUnsavedChanges(false);
    },
    [joFormValues, setJOFormValues],
  );

  const { values, errors, isValid, resetForm, setValues, handleSubmit } =
    useFormik<JODriverAssignmentFormType>({
      initialValues: joDriverAssignmentInitialValues,
      isInitialValid: false,
      validationSchema: JODriverAssignmentFormValidationSchema(translator),
      onSubmit: handleSubmitDriverForm,
    });

  const driverAssignment = useDriverScheduleCalendar({
    id: values.driverId,
    initialMonth,
  });

  // #region FETCH DRIVER LIST
  const driverListOption: AutocompleteOptionsData<Driver> = {
    dataKey: 'drivers',
    labelKey: 'fullName',
    valueKey: 'id',
  };

  const {
    dataOptions: driverOptions,
    handleChangeAutotext: handleChangeDriverAutotext,
    handleFetchMore: handleFetchMoreDriver,
    isFetching: driverListFetchLoading,
  } = useFetchMoreAutocomplete<
    Driver,
    GetDriverApiRequest,
    GetDriverApiResponse
  >({
    options: driverListOption,
    api: api.useLazyGetDriverListQuery,
  });

  const handleChangeDriverId = useCallback(
    (data?: AutocompleteType) => {
      void setValues({
        driverId: data?.value,
        driverOption: data,
        schedule: undefined,
        scheduleId: undefined,
      });
      handleChangeDriverAutotext(undefined);
    },
    [handleChangeDriverAutotext, setValues],
  );

  const handleRemoveDriverId = useCallback(() => {
    void setValues(joDriverAssignmentInitialValues);
    driverAssignment.handleReset();
  }, [driverAssignment, setValues]);
  // #endregion

  const handleSelectScheduleItem = useCallback(
    (item: Schedule) => {
      let selectedData: Schedule | undefined = item;
      if (isEqual(selectedData, values.schedule)) {
        selectedData = undefined;
      }

      driverAssignment.handleSelectScheduleItem(item.id);
      void setValues({
        ...values,
        schedule: selectedData,
        scheduleId: selectedData ? selectedData.id : undefined,
      });
    },
    [setValues, values, driverAssignment],
  );

  const handleClickDay = useCallback(
    (_day: Date) => {
      const schedule = driverAssignment.ongoingSchedules.find((_schedule) =>
        isWithinInterval(_day, {
          start: _schedule.from * 1_000,
          end: _schedule.to * 1_000,
        }),
      );

      if (!schedule) return;
      handleSelectScheduleItem(schedule);
    },
    [handleSelectScheduleItem, driverAssignment.ongoingSchedules],
  );

  const handleReset = useCallback(() => {
    resetForm();
    refId.current = undefined;
    driverAssignment.handleReset();
  }, [driverAssignment, resetForm]);

  const formData: FormItem = useMemo(
    () => ({
      id: 'driverId',
      values: values.driverId,
      labelKey: 'label',
      placeholder: translator.translate('Select Driver'),
      isRequired: true,
      type: 'autocomplete',
      selectedOption: values.driverOption,
      error: errors?.driverId && translator.translate(errors?.driverId),
      options: driverOptions,
      onChange: handleChangeDriverId,
      onChangeAutoText: handleChangeDriverAutotext,
      onFetchMore: handleFetchMoreDriver,
      onRemoveData: handleRemoveDriverId,
    }),
    [
      errors?.driverId,
      handleChangeDriverAutotext,
      handleChangeDriverId,
      handleFetchMoreDriver,
      handleRemoveDriverId,
      translator,
      values.driverId,
      values.driverOption,
      driverOptions,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    let selectedSchedule: Schedule | undefined;

    if (refId.current === values.driverId) return;

    if (!driverAssignment.queryFulfilledTimeStamp) return;

    if (
      (fullfilledTimestampRef.current || 0) >=
      driverAssignment.queryFulfilledTimeStamp
    )
      return;

    if (values.schedule) {
      selectedSchedule = values.schedule;
    }
    if (driverAssignment.ongoingSchedules.length && !values.schedule) {
      [selectedSchedule] = driverAssignment.ongoingSchedules;
    }

    refId.current = values.driverId;
    driverAssignment.handleSelectScheduleItem(selectedSchedule?.id || '');
    void setValues({
      ...values,
      schedule: selectedSchedule,
      scheduleId: selectedSchedule?.id || '',
    });
  }, [
    setValues,
    values,
    values.driverId,
    driverAssignment,
    joFormValues.schedule,
  ]);

  // #region FORM LISTENER
  useEffect(() => {
    setHasUnsavedChanges(
      isFormChanged<JODriverAssignmentFormType>(values, joInitialValues),
    );
  }, [joInitialValues, values]);

  // #endregion

  return {
    errors,
    isValid,
    values,
    formData,
    driverAssignment,
    driverListFetchLoading,
    title,
    emptyTitle,
    emptySubtitle,
    hasUnsavedChanges,
    setValues,
    handleReset,
    handleSubmit,
    handleSelectScheduleItem,
    handleClickDay,
  };
}
