import React from 'react';

export default function NavHaulingOrder({
  fill = 'none',
  width = 20,
  height = 20,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="currentColor"
        fillRule="evenodd"
        clipPath="url(#clip0_8094_873)"
        clipRule="evenodd"
      >
        <path d="M3 2a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3h-2a1 1 0 01-1 1H3a1 1 0 01-1-1V5a1 1 0 011-1V2z" />
        <path d="M6 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H6zm1 8a1 1 0 100 2 1 1 0 000-2zm-1 5a1 1 0 112 0 1 1 0 01-2 0zM18 2H6v4h12V2zm-8 6h8v2h-8V8zm8 4h-8v2h8v-2z" />
      </g>
      <defs>
        <clipPath id="clip0_8094_873">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
