import useShipperAddBulkLogic from '../../hook/shipper/useShipperAddBulkLogic.hook';
import useShipperAddBulkTable from '../../hook/shipper/useShipperAddBulkTable.hook';
import useShipperUploadModal from '../../hook/shipper/useShipperUploadModal.hook';
import useTranslator from '../../hook/useTranslator.hook';

export default function useShipperAddBulkViewModel() {
  const translator = useTranslator();

  const uploadModal = useShipperUploadModal();
  const bulkLogic = useShipperAddBulkLogic();
  const bulkTable = useShipperAddBulkTable({ bulkLogic });

  return {
    translator,
    uploadModal,
    bulkLogic,
    bulkTable,
  };
}
