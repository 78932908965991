import React from 'react';

export default function FilterV2({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#252525" d="M14 4h6v2h-6zM0 4h12v2H0z" />
      <path fill="#252525" d="M14 0h2v10h-2zM8 14h12v2H8zM0 14h6v2H0z" />
      <path fill="#252525" d="M4 10h2v10H4z" />
    </svg>
  );
}
