import React, { useEffect, useRef } from 'react';
import tw from 'twin.macro';
import { ExtraStyle } from '../../Type.component';
import { Input } from '../index';

// #region INTERFACES
type Props = React.ComponentProps<'input'> & {
  indeterminate?: boolean;
  testID?: string;
  rootStyle?: ExtraStyle;
};
// #endregion

// #region STYLED
const InputCheckbox = tw(
  Input,
)`p-2.5 cursor-pointer hover:bg-orange focus:ring-orange checked:bg-orange checked:focus:bg-orange checked:hover:bg-orange indeterminate:bg-orange indeterminate:focus:bg-orange indeterminate:hover:bg-orange`;
// #endregion

export default function IndeterminateCheckbox({
  indeterminate = false,
  id = '',
  name = '',
  value = '',
  checked = false,
  disabled = false,
  onChange,
  onClick,
  testID,
  rootStyle,
}: Props) {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current && indeterminate) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = true;
    } else if (checkboxRef.current && checked) {
      checkboxRef.current.checked = true;
      checkboxRef.current.indeterminate = false;
    } else if (checkboxRef.current) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = false;
    }
  }, [checked, indeterminate]);

  return (
    <InputCheckbox
      onClick={onClick}
      type="checkbox"
      ref={checkboxRef}
      id={id}
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      data-testid={testID}
      css={rootStyle}
    />
  );
}
