import React from 'react';
import { Icon } from '../../component/atom';
import { RouteConfig } from '../../config/router/router.config';

export const haulingJobOrderCompleteAltRoute: RouteConfig = {
  name: 'Complete Assignment',
  Component: React.lazy(() => import('./HaulingJobOrderComplete.view')),
  path: '/hauling-order/:hoId/complete/:id',
  isPrivate: true,
  breadcrumb: 'Complete Assignment',
  options: {
    icon: <Icon.NavHaulingOrder />,
    label: 'Complete Assignment',
    testID: 'MenuHaulingOrder',
  },
  props: {
    isUnclickable: true,
  },
};

export default {
  haulingJobOrderCompleteAltRoute,
};
