import React from 'react';
import tw, { theme } from 'twin.macro';
import { Icon, Text } from '../../atom';

const BannerContainer = tw.div`px-4 py-3 flex items-center rounded`;

type Props = React.ComponentProps<'div'> & {
  body: string;
  color?: 'green' | 'red' | 'grey';
};

export function Banner({ body, color = 'grey', ...props }: Props) {
  const backgroundColors = {
    grey: theme`colors.grey.six`,
    red: theme`colors.status.alert.light`,
    green: theme`colors.status.success.light`,
  };

  return (
    <BannerContainer
      style={{ backgroundColor: backgroundColors[color] }}
      {...props}
    >
      <div tw="flex-none mr-4">
        {color === 'red' && <Icon.Error tw="text-status-alert" />}
        {color === 'green' && <Icon.CheckCircle tw="text-status-success" />}
        {color === 'grey' && <Icon.Info tw="text-grey-two" />}
      </div>
      <Text.BodyTwo tw="text-black">{body}</Text.BodyTwo>
    </BannerContainer>
  );
}

export default Banner;
