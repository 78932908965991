import React, { ReactNode, useEffect, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { BodyFourteen } from '../../atom/Text/Text.atom';

type Props = {
  isBreakWord?: boolean;
  maxLine?: number;
  showExpandButton?: boolean;
  children: ReactNode;
};

const Container = styled.div<{ isBreakWord?: boolean }>(({ isBreakWord }) => [
  tw`overflow-hidden break-all display[-webkit-box] -webkit-box-orient[vertical] whitespace-pre-wrap`,
  isBreakWord && tw`word-break[break-word]`,
]);

export default function WrapTextContainer({
  isBreakWord,
  maxLine = 2,
  showExpandButton,
  children,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const translator = useTranslator();

  const [showTrigger, setShowTrigger] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    const { offsetHeight, offsetWidth, scrollHeight, scrollWidth } =
      containerRef?.current as unknown as HTMLDivElement;

    setShowTrigger(offsetHeight < scrollHeight || offsetWidth < scrollWidth);
  }, []);
  return (
    <>
      <Container
        isBreakWord={isBreakWord}
        css={isExpanded ? undefined : { WebkitLineClamp: maxLine }}
        ref={containerRef}
      >
        {children}
      </Container>
      {showExpandButton && showTrigger && (
        <BodyFourteen
          tw="cursor-pointer text-orange"
          onClick={() => setExpanded((v) => !v)}
        >
          {translator.translate(isExpanded ? 'See less...' : 'See more...')}
        </BodyFourteen>
      )}
    </>
  );
}
