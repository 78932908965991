import { useCallback, useMemo, useState } from 'react';
import BasemapDefault from '../../assets/images/Basemap-Default.png';
import BasemapSatellite from '../../assets/images/Basemap-Satellite.png';
import BasemapTraffic from '../../assets/images/Basemap-Traffic.png';
import { CustomMapTypes } from '../../constant/Map.constant';
import { CustomMapTypeItem } from '../../model/Map.model';
import useTranslator from '../useTranslator.hook';

interface Props {
  isMobile?: boolean;
  map?: google.maps.Map | null;
}

export default function useMapTypeController({ map, isMobile }: Props) {
  // #region GENERAL
  const translator = useTranslator();
  const [showMapTypes, setShowMapTypes] = useState(false);
  const [selectedMapTypes, setSelectedMapTypes] = useState<CustomMapTypes>(
    CustomMapTypes.DEFAULT,
  );
  const [showTrafficLayer, setShowTrafficLayer] = useState(false);
  // #endregion

  // #region ACTION
  const handleClickDefault = useCallback(() => {
    setSelectedMapTypes(CustomMapTypes.DEFAULT);
    setShowTrafficLayer(false);
    map?.setMapTypeId(google.maps.MapTypeId.TERRAIN);
    if (isMobile) {
      setShowMapTypes(false);
    }
  }, [map, isMobile]);

  const handleClickSatellite = useCallback(() => {
    setSelectedMapTypes(CustomMapTypes.SATELLITE);
    setShowTrafficLayer(false);
    map?.setMapTypeId(google.maps.MapTypeId.HYBRID);
    if (isMobile) {
      setShowMapTypes(false);
    }
  }, [map, isMobile]);

  const handleClickTraffic = useCallback(() => {
    setSelectedMapTypes(CustomMapTypes.TRAFFIC);
    setShowTrafficLayer(true);
    map?.setMapTypeId(google.maps.MapTypeId.TERRAIN);
    if (isMobile) {
      setShowMapTypes(false);
    }
  }, [map, isMobile]);
  // #endregion

  // #region MAPTYPEITEMS
  const mapTypeItems: CustomMapTypeItem[] = useMemo(
    () => [
      {
        type: CustomMapTypes.DEFAULT,
        label: translator.translate('Map View'),
        img: BasemapDefault,
        action: handleClickDefault,
      },
      {
        type: CustomMapTypes.SATELLITE,
        label: translator.translate('Satellite'),
        img: BasemapSatellite,
        action: handleClickSatellite,
      },
      {
        type: CustomMapTypes.TRAFFIC,
        label: translator.translate('Traffic Condition'),
        img: BasemapTraffic,
        action: handleClickTraffic,
      },
    ],
    [handleClickDefault, handleClickSatellite, handleClickTraffic, translator],
  );
  // #endregion

  const currentMapTypeItem = useMemo(
    () =>
      mapTypeItems?.find((item) => item.type === selectedMapTypes) ||
      mapTypeItems[0],
    [mapTypeItems, selectedMapTypes],
  );

  const renderedItems = useMemo(
    () => mapTypeItems.filter((item) => item.type !== selectedMapTypes),
    [mapTypeItems, selectedMapTypes],
  );

  return {
    currentMapTypeItem,
    mapTypeItems,
    renderedItems,
    selectedMapTypes,
    showTrafficLayer,
    showMapTypes,
    handleOpenMapTypesMobile: () => setShowMapTypes((v) => !v),
  };
}

export type UseMapTypeControllerHookObj = ReturnType<
  typeof useMapTypeController
>;
