import { VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import fromUnixTime from 'date-fns/fromUnixTime';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import tw, { theme } from 'twin.macro';
import {
  SOActivityType,
  SOOrderStatus,
  SOPaymentStatusLabel,
} from '../../../constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { ShipperOrder } from '../../../model/ShipperOrder.model';
import { getDateFormat } from '../../../util/date.util';
import { getShortLabel } from '../../../util/formatter.util';
import { mapSOFilterStatusToLabel } from '../../../util/shipperOrder.util';
import {
  shipperOrderDetailRoute,
  ShipperOrderDetailRouteParam,
} from '../../../view/ShipperOrderDetail/ShipperOrderDetail.route';
import { Icon, Text } from '../../atom';
import { ShipperOrderStatus, VirtualItemLoaderRow } from '../../molecule';

// #region STYLED
const VirtualizerRoot = tw.div`flex flex-col w-full p-4 rounded-md bg-white absolute top-0 left-0 shadow-card`;
const ItemContainer = tw.div``;
const Header = tw.div`flex justify-between items-center`;
const SONumberContainer = tw.div`flex flex-col space-y-0.5 max-w-[50%]`;
const NumberLinkText = tw(Text.HeadingFour)`text-orange truncate w-full block`;
const TrackingCodeText = tw(Text.BodyTwelve)`text-grey-three truncate`;
const StatusContainer = tw.div`flex flex-col space-y-0.5 min-w-[fit-content] max-w-[50%]`;
const DateText = tw(Text.BodyTwelve)`text-grey-three text-right`;
const Divider = tw.hr`text-grey-six my-2`;
const NameContainer = tw.div`flex flex-col mb-2`;
const NameTitleText = tw(Text.BodyTwelve)`text-grey-three`;
const NameDescriptionText = tw(Text.BodySixteen)`max-w-[40vw] truncate`;
const ActivityContainer = tw.div`flex space-x-1 items-center`;
// #endregion

// #region INTERFACES
type Props = {
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  virtualItem: VirtualItem;
  hasNextPage: boolean;
  list: (ShipperOrder & { itemHeight: number })[];
  translator: UseTranslator;
};
// #endregion

export default function ShipperOrderVirtualItem({
  virtualizer,
  virtualItem,
  hasNextPage,
  list,
  translator,
}: Props) {
  // #region LOGIC
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  const isLoaderRow = virtualItem.index > list.length - 1;
  const so = list[virtualItem.index];
  const prevSo = list[virtualItem.index - 1]; // might be `undefined` when `virtualItem.index === 0`

  const pickupActivitiesLength = useMemo(
    () =>
      so
        ? so.activities.filter(
            (activity) => activity.type === SOActivityType.PICK_UP,
          ).length
        : 0,
    [so],
  );
  const dropoffActivitiesLength = useMemo(
    () =>
      so
        ? so.activities.filter(
            (activity) => activity.type === SOActivityType.DROP_OFF,
          ).length
        : 0,
    [so],
  );

  const height = so?.itemHeight ?? 190; // in static `virtualItem.size`
  const start = useMemo(
    () =>
      prevSo
        ? list
            .slice(0, virtualItem.index)
            .reduce((prev, curr) => prev + curr.itemHeight, 0)
        : 0,
    [list, prevSo, virtualItem.index],
  );
  const translateY =
    virtualItem.index === 0 ? start : start + virtualItem.index * 12; // in static `virtualItem.start`

  const {
    page,
    pageSize,
    search,
    shipperId,
    orderBy,
    shipperName,
    startDate,
    endDate,
  } = Object.fromEntries(queryParams);
  const orderStatuses = queryParams.getAll('status') as SOOrderStatus[];
  const paymentStatuses = queryParams.getAll(
    'paymentStatus',
  ) as SOPaymentStatusLabel[];

  const navigationState: ShipperOrderDetailRouteParam = useMemo(
    () => ({
      searchParamsFromSOList: {
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
        ...(orderBy && { orderBy }),
        ...(orderStatuses.length && { status: orderStatuses.join(',') }),
        ...(paymentStatuses.length && {
          paymentStatus: paymentStatuses.join(','),
        }),
        ...(shipperId && { shipperId }),
        ...(shipperName && { shipperName }),
        ...(search && { search }),
        ...(page && { page }),
        ...(pageSize && { pageSize }),
      },
    }),
    [
      endDate,
      orderBy,
      orderStatuses,
      page,
      pageSize,
      paymentStatuses,
      search,
      shipperId,
      shipperName,
      startDate,
    ],
  );

  const handleClickCard = useCallback(() => {
    navigate(
      `${shipperOrderDetailRoute.path.replace(
        ':id',
        so?.id,
      )}?breadcrumb=${getShortLabel(so?.number)}`,
      { state: navigationState },
    );
  }, [so?.id, so?.number, navigationState, navigate]);
  // #endregion

  return (
    <VirtualizerRoot
      ref={virtualizer.measureElement}
      data-index={virtualItem.index}
      key={virtualItem.key}
      style={{
        height: `${height}px`,
        transform: `translateY(${translateY}px)`,
      }}
    >
      {isLoaderRow ? (
        <VirtualItemLoaderRow
          hasNextPage={hasNextPage}
          size={virtualItem.size}
          translator={translator}
        />
      ) : (
        <ItemContainer onClick={handleClickCard}>
          <Header>
            <SONumberContainer>
              <NumberLinkText>{so.number}</NumberLinkText>
              <TrackingCodeText>{so?.trackingCode ?? '-'}</TrackingCodeText>
            </SONumberContainer>

            <StatusContainer>
              <ShipperOrderStatus
                status={so.status}
                statusLabel={translator.translate(
                  mapSOFilterStatusToLabel(so.status),
                )}
              />
              <DateText>{getDateFormat(fromUnixTime(so.date))}</DateText>
            </StatusContainer>
          </Header>

          <Divider />

          <NameContainer>
            <NameTitleText>
              {translator.translate('Shipper Name')}
            </NameTitleText>
            <NameDescriptionText>{so.shipper.name}</NameDescriptionText>
          </NameContainer>

          {!!pickupActivitiesLength && (
            <ActivityContainer>
              <Icon.NavMaps height={13} width={13} />
              <Text.BodyTwelve>
                {pickupActivitiesLength}{' '}
                {translator.translate('Pickup Location')}
              </Text.BodyTwelve>
            </ActivityContainer>
          )}

          {!!dropoffActivitiesLength && (
            <ActivityContainer>
              <Icon.NavMaps
                height={13}
                width={13}
                fill={theme`colors.status.alert.DEFAULT`}
              />
              <Text.BodyTwelve>
                {dropoffActivitiesLength}{' '}
                {translator.translate('Dropoff Location')}
              </Text.BodyTwelve>
            </ActivityContainer>
          )}
        </ItemContainer>
      )}
    </VirtualizerRoot>
  );
}
