import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import tw from 'twin.macro';
import { LoadingIndicator } from '../../component/atom';
import { ErrorBoundaryFallback } from '../../component/organism';
import { captureError } from '../../service/errorMonitoring/errorMonitoring.service';
import WrapperView from '../../view/Wrapper/Wrapper.view';
import Router from '../Router/Router.app';
import store, { persistor } from '../store/store.app';
import ErrorBoundary from './ErrorBoundary.app';

// #region STYLED
const SuspenseFallbackContainer = tw.div`bg-beige-bg flex justify-center items-center min-h-screen min-w-full`;
// #endregion

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Suspense
            fallback={
              <SuspenseFallbackContainer>
                <LoadingIndicator size="large" />
              </SuspenseFallbackContainer>
            }
          >
            <ErrorBoundary
              FallbackComponent={ErrorBoundaryFallback}
              onError={(error, info) => {
                captureError(
                  `Uncaught error: ${error.name}`,
                  {},
                  { error, info },
                );
              }}
            >
              <WrapperView>
                <Router />
              </WrapperView>
            </ErrorBoundary>
          </Suspense>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
