// #region IMPORT
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  IOrganization,
  OrganizationResetHistory,
} from '../../../model/Organization.model';
import {
  SettingDriverManagementParams,
  SettingDriverManagementResponse,
} from '../../../model/Setting.model';
import { Builder, MD, QD } from '../../api.baseQuery';
import { CommonApiResponse } from '../../api.endpoint';

// #endregion

// #region INTERFACES
type GetOrganizationParam = { token: string };
type GetOrganizationResponse = CommonApiResponse & {
  organization: IOrganization;
};
type GenerateTokenResetOrganizationParam = { confirmationKey: string };
export type GenerateTokenResetOrganizationResponse = CommonApiResponse & {
  organization: {
    resetToken: string;
  };
};
type PostResetOrganizationParam = { resetToken: string };

type PostResetOrganizationResponse = CommonApiResponse;
type GetOrganizationResetHistoryInfoResponse = {
  resetHistory?: OrganizationResetHistory | null;
};

export type OrganizationEndpoint = {
  getOrganizationMe: QD<GetOrganizationParam, GetOrganizationResponse>;
  updateSettingOrganization: MD<
    SettingDriverManagementParams,
    SettingDriverManagementResponse
  >;
  generateTokenResetOrganization: MD<
    GenerateTokenResetOrganizationParam,
    GenerateTokenResetOrganizationResponse
  >;
  postResetOrganization: MD<
    PostResetOrganizationParam,
    PostResetOrganizationResponse
  >;
  getOrganizationResetHistoryInfo: QD<
    void,
    GetOrganizationResetHistoryInfoResponse
  >;
};

// #endregion

// #region ENDPOINT
export const organizationTagTypes: EndpointTagTypes<OrganizationEndpoint> = {
  getOrganizationMe: 'getOrganizationMe',
  updateSettingOrganization: '',
  generateTokenResetOrganization: '',
  postResetOrganization: '',
  getOrganizationResetHistoryInfo: 'getOrganizationResetHistoryInfo',
};

export const organizationEndpoint = (
  builder: Builder,
): OrganizationEndpoint => ({
  getOrganizationMe: builder.query({
    query: ({ token }) => ({
      url: '/v1/organizations.me',
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
    }),
    providesTags: [organizationTagTypes.getOrganizationMe],
  }),
  updateSettingOrganization: builder.mutation({
    query: (body) => ({
      url: '/v1/organizations.settings.update',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      organizationTagTypes.getOrganizationMe,
      organizationTagTypes.getOrganizationResetHistoryInfo,
    ],
  }),
  generateTokenResetOrganization: builder.mutation({
    query: (body) => ({
      url: '/v1/organizations.reset-token.generate',
      body,
      method: 'post',
    }),
  }),
  postResetOrganization: builder.mutation({
    query: (body) => ({ url: '/v1/organizations.reset', body, method: 'post' }),
  }),
  getOrganizationResetHistoryInfo: builder.query({
    query: () => ({
      url: '/v1/organizations.reset-history.info',
      method: 'get',
    }),
    providesTags: [organizationTagTypes.getOrganizationResetHistoryInfo],
  }),
});
// #endregion
