import React from 'react';
import { Icon } from '../../component/atom';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type ShipperOrderRouteParam = DefaultRouteParam;

export const shipperOrderRoute: RouteConfig = {
  name: 'Shipper Order',
  Component: React.lazy(() => import('./ShipperOrder.view')),
  path: '/shipper-order',
  isPrivate: true,

  breadcrumb: 'Shipper Order',

  options: {
    icon: <Icon.ShipperOrder />,
    label: 'Shipper Order',
    testID: 'MenuShipperOrder',
  },
};
