import { ReactNode } from 'react';
import { DriverOrderBy, DriverPINInfoProps } from '../model/Driver.model';
import { ScheduleForm } from '../model/Schedule.model';
import { Vehicle } from '../model/Vehicle.model';
import { ScheduleInfoProps } from './Schedule.constant';

// #region INTERFACES
export enum DriverStatusFilter {
  ON_DUTY = 'ON_DUTY',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum DriverDetailSectionEnum {
  INFORMATION = 'INFORMATION',
  SCHEDULE = 'SCHEDULE',
  TIMESHEET = 'TIMESHEET',
}

export type DriverDetailSidebarItem = {
  title: string;
  icon: React.ReactNode;
  isSelected?: boolean;
  onClick: () => void;
};
export type DriverDetailInformationCardItemProps = {
  label: string;
  value?: string;
  maxLines?: number;
};

export type DriverDetailInformationCardProps = {
  title: string;
  subHeader?: ReactNode;
  items: DriverDetailInformationCardItemProps[];
};

export type DriverSortByValue = {
  label: string;
  value: DriverOrderBy;
};

export type DriverStatusValue = {
  label: string;
  value: DriverStatusFilter;
};

export type DriverFilterInitialValues = {
  sortBy: DriverOrderBy;
  status: DriverStatusFilter[];
};

export type DriverSubmissionModalProps = {
  driverPinInfo?: DriverPINInfoProps;
  scheduleInfo?: ScheduleInfoProps;
};

export type DriverScheduleForm = Partial<ScheduleForm> & {
  vehicle?: Vehicle;
};

// #endregion

export const driverScheduleInitialValues: DriverScheduleForm = {
  from: undefined,
  to: undefined,
  vehicleId: undefined,
  vehicle: undefined,
};

export const driverFilterInitialValues: DriverFilterInitialValues = {
  sortBy: DriverOrderBy.STATUS_ASC,
  status: Object.values(DriverStatusFilter),
};
