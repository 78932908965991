import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import useTranslator from '../../../hook/useTranslator.hook';
import { logEvent } from '../../../service/analytic/analytic.service';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { ApproveJOExpensePayload } from '../../../service/endpoint/jobOrder/jobOrder.endpoint';
import { snackbarAction } from '../../../store/snackbar.store';
import { errorCodeToLabel } from '../../../util/error.util';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';
import { UseJODetailSpendingReportController } from './useJODetailSpendingController.hook';
import { UseJODetailSpendingReportTable } from './useJODetailSpendingReportTable.hook';

type Props = {
  spendingReportController: UseJODetailSpendingReportController;
  spendingReportTable: UseJODetailSpendingReportTable;
};
export type UseJODetailSpendingApprove = ReturnType<
  typeof useJODetailSpendingApprove
>;

export default function useJODetailSpendingApprove({
  spendingReportController: { showSpendingDetail, setShowSpendingDetail },
  spendingReportTable: { checkedItems, setCheckedItems },
}: Props) {
  const translator = useTranslator();
  const dispatch = useDispatch();
  const { handleVerifyAuth } = useVerifyAuth();

  const [approveExpenseMutation, approveExpenseMutationResponse] =
    api.useApproveJOExpenseMutation();

  const handleApprove = useCallback(
    async (
      expenses: ApproveJOExpensePayload[],
      expenseNumber?: string,
      onSuccessCallback?: () => void,
    ) => {
      try {
        const authRes = await handleVerifyAuth();
        if (!authRes) return;
        logEvent('JobOrderDetail:ApproveExpense');
        const response = await approveExpenseMutation({ expenses }).unwrap();

        if (!response.ok) return;
        if (response?.result?.errors?.length) {
          dispatch(
            snackbarAction.show({
              type: SnackbarTheme.warning,
              message: translator.translate(
                errorCodeToLabel(response.result.errors[0]?.code),
              ),
            }),
          );
          return;
        }
        const message = expenseNumber
          ? `${translator.translate(
              'Successfully Approved',
            )} ${expenseNumber} !`
          : translator.translate('Expense Successfully Approved');
        dispatch(
          snackbarAction.show({
            message,
          }),
        );
        onSuccessCallback?.();
      } catch (err) {
        const data = (err as FetchBaseQueryError).data as ApiErrorResponse;
        dispatch(
          snackbarAction.show({
            type: SnackbarTheme.warning,
            message: translator.translate(errorCodeToLabel(data.error.code)),
          }),
        );
      } finally {
        approveExpenseMutationResponse.reset();
        // clear table selection if there's any
        if (checkedItems.length) setCheckedItems([]);
      }
    },
    [
      dispatch,
      approveExpenseMutation,
      setCheckedItems,
      handleVerifyAuth,
      checkedItems.length,
      approveExpenseMutationResponse,
      translator,
    ],
  );

  const handleApproveExpense = useCallback(() => {
    if (!showSpendingDetail) return;
    const expenses = [
      {
        joId: showSpendingDetail.joId,
        id: showSpendingDetail.id,
      },
    ];
    void handleApprove(expenses, showSpendingDetail.number, () => {
      setShowSpendingDetail(undefined);
    });
  }, [handleApprove, setShowSpendingDetail, showSpendingDetail]);

  return {
    translator,
    isLoading: approveExpenseMutationResponse.isLoading,
    handleApprove,
    handleApproveExpense,
  };
}
