import React from 'react';
import { Modifier } from 'react-day-picker';
import tw from 'twin.macro';
import { LabelInput, Row } from '../../atom';
import { RangeYear } from '../DatePicker/DatePicker.molecule';
import DatePickerInput from '../DatePickerInput/DatePickerInput.molecule';

type Props = {
  label?: string;
  isRequired?: boolean;
  id: string;
  error?: string;
  values?: Date;
  hideIcon?: boolean;
  placeholder?: string;
  disabled?: boolean;
  disabledDays?: Modifier | Modifier[];
  disableTimePicker?: boolean;
  isTimepickerValidationDisabled?: boolean;
  rangeYear?: RangeYear;
  onChange: (date?: Date) => void;
};

const Container = tw.div`width[calc(100% - 200px)]`;
export default function DateInput({
  label,
  disabled,
  disabledDays,
  disableTimePicker,
  hideIcon,
  isRequired,
  id,
  error,
  values,
  placeholder,
  isTimepickerValidationDisabled,
  rangeYear,
  onChange,
}: Props) {
  return (
    <Row>
      {label && <LabelInput label={label} isRequired={isRequired} />}
      <Container>
        <DatePickerInput
          isTimepickerValidationDisabled={isTimepickerValidationDisabled}
          hideIcon={hideIcon}
          disabled={disabled}
          disableTimePicker={disableTimePicker}
          disabledDays={disabledDays}
          placeholder={placeholder}
          id={id}
          name={id}
          dateValue={values}
          error={error}
          changeData={onChange}
          rangeYear={rangeYear}
        />
      </Container>
    </Row>
  );
}
