import React from 'react';
import tw from 'twin.macro';
import { UseJOFormActionObj } from '../../../hook/jobOrderForm/useJOFormAction.hook';
import { UseJOFormHeaderObj } from '../../../hook/jobOrderForm/useJOFormHeader.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { CardContainer } from '../../atom';
import { Delete } from '../../atom/Icon/Icon.atom';
import { BodyFourteen, BodyOne } from '../../atom/Text/Text.atom';
import { CardContent, CardHeader, Modal } from '../../molecule';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';

const Container = tw.div`w-[720px] mx-auto py-10`;
const ButtonDelete = tw.button`flex items-center space-x-2 text-orange`;
const ButtonDeleteText = tw(BodyFourteen)`font-semibold text-orange`;
const ModalBodyContainer = tw.div`flex flex-col py-5`;
const ContainerForm = tw.div``;

type Props = {
  headerForm: UseJOFormHeaderObj;
  actionForm: UseJOFormActionObj;
};

export default function JOFormHeader({
  headerForm: { title, formData, handleBackHeader },
  actionForm: {
    joId,
    deleteJOResponse,
    isDeleteConfirmationVisible,
    handleCloseConfirmationDelete,
    handleConfirmDelete,
    handleOpenConfirmationDelete,
  },
}: Props) {
  const { translate } = useTranslator();
  return (
    <Container>
      <CardContainer>
        <CardHeader
          handleBack={handleBackHeader}
          title={title}
          subHeader={
            joId ? (
              <ButtonDelete
                type="button"
                onClick={handleOpenConfirmationDelete}
              >
                <Delete width={20} height={20} viewBox="0 0 24 24" />
                <ButtonDeleteText>{translate('Delete')}</ButtonDeleteText>
              </ButtonDelete>
            ) : undefined
          }
        />
        <CardContent>
          {formData.map((item) => (
            <ContainerForm key={`JO-header-form-${item.id}`}>
              {/* type already described inside component */}
              <ComposedFormField {...item} />
            </ContainerForm>
          ))}
        </CardContent>
      </CardContainer>
      {isDeleteConfirmationVisible && (
        <Modal.WithClose
          css={tw`p-8 w-[760px]`}
          actionButtonContainer={tw`justify-between`}
          title={translate('Are you sure to delete this job order?')}
          onClose={handleCloseConfirmationDelete}
          onOverlayClick={handleCloseConfirmationDelete}
          ctaStyle={tw`justify-between`}
          callAction={{
            label: translate(
              deleteJOResponse.isLoading ? 'Loading...' : 'Delete',
            ),
            action: handleConfirmDelete,
            disabled: deleteJOResponse.isLoading,
          }}
          cancelAction={{
            label: translate('Cancel'),
            action: handleCloseConfirmationDelete,
            disabled: deleteJOResponse.isLoading,
          }}
        >
          <ModalBodyContainer>
            <BodyOne tw="w-[650px]">
              {translate('Once deleted, this job order cannot be restored')}
            </BodyOne>
          </ModalBodyContainer>
        </Modal.WithClose>
      )}
    </Container>
  );
}
