import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  pathFill?: string;
};

export default function BottomMenuExplore({
  width = '20',
  height = '20',
  fill = 'none',
  viewBox = '0 0 20 20',
  pathFill = '#616161',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <path
        fill={pathFill}
        fillRule="evenodd"
        d="M0 3a3 3 0 013-3h3a3 3 0 013 3v3a3 3 0 01-3 3H3a3 3 0 01-3-3V3zm3-1a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V3a1 1 0 00-1-1H3zM0 14a3 3 0 013-3h3a3 3 0 013 3v3a3 3 0 01-3 3H3a3 3 0 01-3-3v-3zm3-1a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1H3zM11 14a3 3 0 013-3h3a3 3 0 013 3v3a3 3 0 01-3 3h-3a3 3 0 01-3-3v-3zm3-1a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1h-3zM11 3a3 3 0 013-3h3a3 3 0 013 3v3a3 3 0 01-3 3h-3a3 3 0 01-3-3V3zm3-1a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V3a1 1 0 00-1-1h-3z"
        clipRule="evenodd"
      />
    </svg>
  );
}
