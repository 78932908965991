import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import { FormikErrors, useFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  JOAssignmentBy,
  JODateAssignmentFormType,
} from '../../constant/JOAssignment.constant';
import { JobOrderForm } from '../../model/JobOrder.model';
import { Schedule } from '../../model/Schedule.model';
import { FormItem } from '../../types/input.type';
import { isFormChanged } from '../../util/helper.util';
import {
  joAssignmentToJOFormFormatter,
  JODateAssignmentFormValidationSchema,
  joDateAssignmentInitialValues,
} from '../../util/joAssignment.util';
import useTranslator from '../useTranslator.hook';
import useJODateAssignmentScheduleList from './useJODateAssignmentScheduleList.hook';

type Props = {
  joFormValues: JobOrderForm;
  setJOFormValues: (
    values: React.SetStateAction<JobOrderForm>,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<JobOrderForm>>;
};

export default function useJODateAssignmentForm({
  joFormValues,
  setJOFormValues,
}: Props) {
  const translator = useTranslator();
  const refFromTo = useRef<{ from?: Date; to?: Date } | undefined>(undefined);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const joDate = useMemo(() => {
    if (joFormValues.joDate) return startOfDay(joFormValues.joDate * 1000);
    return startOfToday();
  }, [joFormValues.joDate]);

  const joInitialValues = useMemo((): JODateAssignmentFormType => {
    if (!joFormValues.schedule) return joDateAssignmentInitialValues;
    return {
      schedule: joFormValues.schedule,
      scheduleId: joFormValues?.schedule?.id,
      from: new Date((joFormValues?.schedule?.from || 0) * 1000),
      to: new Date((joFormValues?.schedule?.to || 0) * 1000),
    };
  }, [joFormValues]);

  const title = translator.translate('Schedules');
  const emptyTitle = translator.translate('There is no schedule.');
  const emptySubtitle = translator.translate(
    'Please select Date to show schedule list.',
  );

  const handleSubmitDateForm = useCallback(
    async (val: JODateAssignmentFormType) => {
      const sanitizedData = joAssignmentToJOFormFormatter({
        joFormValues,
        schedule: val.schedule,
      });
      setHasUnsavedChanges(false);
      await setJOFormValues({
        ...sanitizedData,
        assignmentType: JOAssignmentBy.DATE,
      });
    },
    [joFormValues, setJOFormValues],
  );

  const { values, errors, isValid, resetForm, setValues, handleSubmit } =
    useFormik<JODateAssignmentFormType>({
      initialValues: joDateAssignmentInitialValues,
      isInitialValid: false,
      validationSchema: JODateAssignmentFormValidationSchema(translator),
      onSubmit: handleSubmitDateForm,
    });
  const dateAssignment = useJODateAssignmentScheduleList({ values });

  const handleApplyDate = useCallback(
    (from: Date, to: Date) => {
      void setValues({ schedule: undefined, scheduleId: undefined, from, to });
    },
    [setValues],
  );

  const handleReset = useCallback(() => {
    resetForm();
    refFromTo.current = undefined;
    dateAssignment.handleReset();
  }, [dateAssignment, resetForm]);

  const handleSelectScheduleItem = useCallback(
    (item: Schedule) => {
      let selectedData: Schedule | undefined = item;
      if (isEqual(selectedData, values.schedule)) {
        selectedData = undefined;
      }

      dateAssignment.handleSelectScheduleItem(item.id);
      void setValues({
        ...values,
        schedule: selectedData,
        scheduleId: selectedData ? selectedData.id : undefined,
      });
    },
    [setValues, values, dateAssignment],
  );

  const formData: FormItem = useMemo(
    () => ({
      type: 'date-range',
      id: 'dateRange',
      startValues: values?.from,
      startId: 'from',
      startName: 'from',
      startLabel: translator.translate('Start Date'),
      startError: errors?.from,
      endValues: values?.to,
      endId: 'to',
      endName: 'to',
      disabledDays: {
        before: joDate,
        after: addDays(joDate, 62),
      },
      endLabel: translator.translate('End Date'),
      endError: errors?.to,
      textFieldInputBgBeige: true,
      disableDefinedRange: true,
      onClickApplyDateRange: handleApplyDate,
    }),
    [
      values?.from,
      values?.to,
      translator,
      errors?.from,
      errors?.to,
      joDate,
      handleApplyDate,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    let selectedSchedule: Schedule | undefined;
    if (
      refFromTo.current?.from === values.from &&
      refFromTo.current?.to === values.to
    )
      return;
    if (dateAssignment.queryStatus !== 'fulfilled') return;
    if (values.schedule) {
      selectedSchedule = values.schedule;
    }
    refFromTo.current = { from: values.from, to: values.to };
    dateAssignment.handleSelectScheduleItem(selectedSchedule?.id || '');
    void setValues({
      ...values,
      schedule: selectedSchedule,
      scheduleId: selectedSchedule?.id || '',
    });
  }, [setValues, values, joFormValues.schedule, dateAssignment]);

  // #region FORM LISTENER
  useEffect(() => {
    setHasUnsavedChanges(
      isFormChanged<JODateAssignmentFormType>(values, joInitialValues),
    );
  }, [joInitialValues, values]);

  // #endregion

  return {
    errors,
    isValid,
    values,
    formData,
    dateAssignment,
    title,
    emptyTitle,
    emptySubtitle,
    hasUnsavedChanges,
    setValues,
    handleSubmit,
    handleReset,
    handleSelectScheduleItem,
  };
}

export type UseJODateAssignmentFormHookObj = ReturnType<
  typeof useJODateAssignmentForm
>;
