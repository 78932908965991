import { Locale } from './locale.config';

const trackingLocaleConfig: Locale[] = [
  {
    en: 'Track Your Delivery',
    id: 'Lacak Pengiriman Anda',
  },
  {
    en: 'Receipt Number',
    id: 'Nomor Resi',
  },
  {
    en: 'Track Delivery',
    id: 'Lacak Pengiriman',
  },
  {
    en: 'Trip History',
    id: 'Riwayat Perjalanan',
  },
  {
    en: 'Show More',
    id: 'Tampilkan Lebih Banyak',
  },
  {
    en: 'Show Less',
    id: 'Tampilkan Lebih Sedikit',
  },
  {
    en: 'Successfully copy tracking link',
    id: 'Berhasil menyalin tautan tracking',
  },
  {
    en: 'Successfully copy tracking number',
    id: 'Berhasil menyalin nomor resi',
  },
  {
    en: 'Copy Link',
    id: 'Salin Tautan',
  },
  {
    en: 'There is no Delivery Assignment',
    id: 'Pengiriman belum dilakukan',
  },
  {
    en: 'Share tracking number',
    id: 'Bagikan nomor resi',
  },
  {
    en: 'I Understand',
    id: 'Saya Mengerti',
  },
  {
    en: 'If you share this tracking number, anyone can see your Job Order details.',
    id: 'Jika Anda membagikan nomor resi ini, orang lain dapat melihat detail Perintah Kerja Anda.',
  },
  {
    en: 'Try adding Assignment in the Desktop version',
    id: 'Belum terdapat pengiriman ke lokasi ini.',
  },
  {
    en: 'Receipt number is not valid or already delivered. Please check the tracking number You entered.',
    id: 'Nomor resi yang dimasukkan tidak valid atau pengiriman sudah terkirim.  Periksa kembali nomor resi yang Anda masukkan.',
  },
];

export default trackingLocaleConfig;
