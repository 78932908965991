import React from 'react';
import tw from 'twin.macro';
import { UseDrivingContestRegisterFilterHookObj } from '../../../view/DrivingContestRegister/hook/useDrivingContestRegisterFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChipContainer = tw.div`mb-2 mr-2`;
const FilterTitle = tw(Text.HeadingFour)`flex-1 ml-2`;
const ResetButton = tw(Button.Outlined)`bg-white text-grey-two px-4`;
const FiterChip = tw(Chip.Small)`text-grey-two`;
// #endregion

// #region INTERFACES
type Props = {
  drivingContestRegisterFilter: UseDrivingContestRegisterFilterHookObj;
};
// #endregion

function DrivingContestRegisterFilter({ drivingContestRegisterFilter }: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />
        <FilterTitle>{drivingContestRegisterFilter.titleLabel}</FilterTitle>
        <ResetButton small onClick={drivingContestRegisterFilter.onClickReset}>
          {drivingContestRegisterFilter.resetButtonLabel}
        </ResetButton>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading>
            {drivingContestRegisterFilter.orderByLabel}
          </InputHeading>

          <ChipContainer>
            {drivingContestRegisterFilter.filterOrderByValues.map((el) => {
              const selected =
                drivingContestRegisterFilter.form.values.orderBy === el.value;

              return (
                <FilterChipContainer key={`Filter_Category_${el.value}`}>
                  <FiterChip
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      drivingContestRegisterFilter.onClickOrderByChip(el.value)
                    }
                  />
                </FilterChipContainer>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          onClick={drivingContestRegisterFilter.onClickSubmit}
        >
          {drivingContestRegisterFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default DrivingContestRegisterFilter;
