import { useCallback, useMemo } from 'react';
import { UseForceCompleteJOHookObj } from '../useForceCompleteJO/useForceCompleteJO.hook';
import { UseForceCompleteSOHookObj } from '../useForceCompleteSO/useForceCompleteSO.hook';
import { UseJobOrderInfoHookObj } from '../useJobOrderInfo/useJobOrderInfo.hook';
import useScreenDetection from '../useScreenDetection/useScreenDetection.hook';
import useTranslator from '../useTranslator.hook';
import { UseJODetailControllerHookObj } from './useJODetailController.hook';
import { UseSelectedShipperOrderHookObj } from './useSelectedShipperOrder.hook';

interface Props {
  selectedDeliveryId?: string;
  jobOrderInfo: UseJobOrderInfoHookObj;
  forceCompleteSO: UseForceCompleteSOHookObj;
  forceCompleteJO: UseForceCompleteJOHookObj;
  selectedShipperOrder: UseSelectedShipperOrderHookObj;
  joDetailController: UseJODetailControllerHookObj;
}

export default function useJODetailModalForceJO({
  joDetailController,
  selectedDeliveryId,
  jobOrderInfo,
  forceCompleteSO,
  forceCompleteJO,
  selectedShipperOrder,
}: Props) {
  // #region GENERAL
  const { isMobile } = useScreenDetection();
  const translator = useTranslator();

  // #endregion

  const handleForceComplete = useCallback(() => {
    if (!jobOrderInfo.joInfoData) return;
    if (joDetailController.isForceCompleteJO) {
      void forceCompleteJO.handleRequest({ id: jobOrderInfo.joInfoData.id });
    } else if (selectedShipperOrder?.data) {
      const deliveryId = jobOrderInfo?.joInfoData.deliveries
        ? jobOrderInfo?.joInfoData.deliveries.find(
            (item) => item.shipperOrder.id === selectedShipperOrder?.data?.id,
          )?.id || ''
        : '';

      void forceCompleteSO.handleRequest({
        joId: jobOrderInfo?.joInfoData.id,
        id: deliveryId,
      });
    }
  }, [
    forceCompleteJO,
    forceCompleteSO,
    joDetailController.isForceCompleteJO,
    jobOrderInfo.joInfoData,
    selectedShipperOrder,
  ]);

  // #region MODAL
  const title = useMemo(
    () =>
      translator.translate(
        `Are you sure you want to force complete this ${
          joDetailController.isForceCompleteJO ? 'Job Order' : 'Shipper Order'
        }?`,
      ),
    [joDetailController.isForceCompleteJO, translator],
  );
  const actionLabel = translator.translate('Force Complete');

  const contentHeader = useMemo(() => {
    if (joDetailController.isForceCompleteJO)
      return jobOrderInfo.joInfoData?.number;
    if (isMobile)
      return jobOrderInfo.joInfoData?.deliveries.find(
        (delivery) => delivery.shipperOrder.id === selectedDeliveryId,
      )?.shipperOrder.number;

    return (
      jobOrderInfo.joInfoData?.deliveries.find(
        (delivery) => delivery.id === selectedDeliveryId,
      )?.shipperOrder.number ||
      jobOrderInfo.joInfoData?.deliveries[0].shipperOrder.number
    );
  }, [
    joDetailController.isForceCompleteJO,
    isMobile,
    jobOrderInfo.joInfoData?.deliveries,
    jobOrderInfo.joInfoData?.number,
    selectedDeliveryId,
  ]);

  const contentDescription = translator.translate(
    'status will be set to delivered. You can not undone this process.',
  );

  const handleModalAction = useCallback(() => {
    handleForceComplete();
    joDetailController.toggleModalConfirmation();
  }, [joDetailController, handleForceComplete]);
  // #endregion

  return {
    title,
    actionLabel,
    isForceCompleteJO: joDetailController.isForceCompleteJO,
    contentHeader,
    contentDescription,
    handleForceComplete,
    handleModalAction,
  };
}

export type UseJODetailModalForceJOHookObj = ReturnType<
  typeof useJODetailModalForceJO
>;
