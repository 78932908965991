import { OverlayView } from '@react-google-maps/api';
import React from 'react';
import tw from 'twin.macro';
import { LatLng } from '../../../model/Map.model';
import CustomVehicleMarker from '../../atom/Icon/SVG/CustomVehicleMarker';

const Container = tw.div`-ml-2.5 -mt-6 cursor-pointer`;

type Props = { position: LatLng; rotation?: number; handleClick?: () => void };

export default function MarkerVehicle({
  position,
  rotation,
  handleClick = () => {},
}: Props) {
  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Container onClick={handleClick}>
        {/* Not using tailwind due to dynamic value */}
        <div style={{ transform: `rotate(${rotation || 0}deg)` }}>
          <CustomVehicleMarker />
        </div>
      </Container>
    </OverlayView>
  );
}
