import React from 'react';

export default function NavHome({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 19.9995V9.25186L12 4.00051L6 9.25186V19.9995H18ZM4.68299 7.74632C4.24896 8.1262 4 8.67499 4 9.25186V22H20V9.25186C20 8.67499 19.751 8.1262 19.317 7.74632L13.317 2.49497C12.563 1.83501 11.437 1.83501 10.683 2.49497L4.68299 7.74632Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 13.9979H11V19.9995H13V13.9979ZM9 11.9974V22H15V11.9974H9Z"
      />
    </svg>
  );
}
