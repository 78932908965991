import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type NotFoundRouteParam = DefaultRouteParam;

export const notFoundRoute: RouteConfig = {
  name: 'NotFound',
  Component: React.lazy(() => import('./NotFound.view')),
  path: '/not-found',
  isPrivate: true,
};
