import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { JobOrderFormNavigateParams } from '../../constant/JobOrder.constant';

export type JOLocationAddRouteParam = JobOrderFormNavigateParams | undefined;

export const joCreateLocationAddRoute: RouteConfig = {
  name: 'Add New Location',
  Component: React.lazy(() => import('../JOLocationAdd/JOLocationAdd.view')),
  path: '/job-order/create/add-new-location',
  isPrivate: true,
  breadcrumb: 'Add New Location',
};
