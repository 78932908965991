import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterDriverContestRemovalRouteParam = DefaultRouteParam;

export const helpCenterDriverContestRemovalRoute: RouteConfig = {
  name: 'Help Center Driver Contest Removal',
  Component: React.lazy(() => import('./HelpCenterDriverContestRemoval.view')),
  path: '/help-center/driver-contest/removal',
  isPrivate: false,
  breadcrumb: 'Help Center Driver Contest Removal',
  options: {
    label: 'Penghapusan',
    testID: 'MenuHelpCenterDriverContestRemoval',
  },
};
