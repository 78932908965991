import tw, { styled } from 'twin.macro';

const IconButton = styled.div(
  (props: { disabled?: boolean; squared?: boolean; isLink?: boolean }) => [
    tw`text-black flex items-center p-2 rounded-full duration-200 cursor-pointer hover:(bg-orange-four bg-opacity-25)`,
    props.disabled && tw`opacity-50 hover:(cursor-default bg-opacity-0)`,
    props.squared && tw`rounded`,
    props.isLink && tw`p-0`,
  ],
);

export default IconButton;
