import React from 'react';
import tw, { theme } from 'twin.macro';
import { UseDriverFilter } from '../../../view/Driver/hooks/useDriverFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';

// #region INTERFACES
type Props = {
  driverFilter: UseDriverFilter;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
// #endregion

export default function DriverFilter({ driverFilter }: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <Text.HeadingFour tw="flex-1 ml-2">
          {driverFilter.titleLabel}
        </Text.HeadingFour>

        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          onClick={driverFilter.onClickReset}
        >
          {driverFilter.resetButtonLabel}
        </Button.Outlined>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading>{driverFilter.sortByLabel}</InputHeading>

          <ChipContainer>
            {driverFilter.filterSortByValues.map((el) => {
              const selected = driverFilter.form.values.sortBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() => driverFilter.onClickSortByChip(el.value)}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading>{driverFilter.statusLabel}</InputHeading>

            <Button.Text
              small
              onClick={
                driverFilter.isQueryStatusAll
                  ? driverFilter.onClickUnselectAllStatus
                  : driverFilter.onClickSelectAllStatus
              }
            >
              {driverFilter.isQueryStatusAll
                ? driverFilter.unselectAllLabel
                : driverFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {driverFilter.filterStatusValues.map((el) => {
              const selected = driverFilter.form.values.status.includes(
                el.value,
              );

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() => driverFilter.onClickStatusChip(el.value)}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {driverFilter.isQueryStatusEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {driverFilter.errorStatusLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={
            driverFilter.isQueryStatusEmpty ||
            driverFilter.filterLocalIsFetching
          }
          onClick={driverFilter.onClickSubmit}
        >
          {driverFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}
