import React from 'react';

export default function CrossCircle({
  fill = 'currentColor',
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect width={width} height={height} fill={fill} rx="8" />
        <path
          stroke="#fff"
          strokeWidth="2"
          d="M4.667 11.556l6.666-6.667m0 6.667L4.667 4.889"
        />
      </g>
    </svg>
  );
}
