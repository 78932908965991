export default {
  mode: process.env.REACT_APP_MODE as string,
  name: process.env.REACT_APP_NAME as string,
  version: process.env.REACT_APP_VERSION as string,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL as string,
  ktbVisaUrl: process.env.REACT_APP_KTB_VISA_URL as string,
  ktbJOServiceUrl: process.env.REACT_APP_KTB_JO_SERVICE_URL as string,
  ktbRunnerServiceUrl: process.env.REACT_APP_KTB_RUNNER_SERVICE_URL as string,
  ktbVisaBearerToken: process.env.REACT_APP_KTB_VISA_BEARER_TOKEN as string,
  apiGoogleMap: process.env.REACT_APP_GCP_API as string,
  gaTrackingID: process.env.REACT_APP_GA_TRACKING_ID as string,
  assetsUrl: process.env.REACT_APP_ASSETS_URL as string,
  templatesUrl: process.env.REACT_APP_TEMPLATES_URL as string,
  sentryClientKey: process.env.REACT_APP_SENTRY_CLIENT_KEY as string,
};
