import React, { ChangeEventHandler, DragEventHandler, useRef } from 'react';
import tw, { css, styled } from 'twin.macro';
import { xlsFileType, xlsxFileType } from '../../../constant/Common.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { Button, Icon, LoadingIndicator, Text } from '../../atom';
import { Modal } from '../../molecule';

const accept = [xlsFileType, xlsxFileType].join(', ');

// #region STYLED
const Body = tw.div`flex flex-col p-4`;
const DescriptionLink = tw.a`font-bold text-orange hover:opacity-75`;
const FileForm = tw.form`w-full my-4 relative flex flex-col justify-center items-center rounded-md transition duration-300 animate-fade-in border border-dashed border-orange`;
const FileInput = tw.input`hidden`;
const FileLabel = tw.label`w-full flex flex-col justify-center items-center border border-dashed border-orange`;
const FileLabelContainer = tw.div`w-full flex flex-col items-center justify-center py-10`;
const FileBrowseButton = tw.button`font-semibold text-orange hover:opacity-75`;
const FileDragDropLabel = tw(Text.HeadingFour)`mt-4 mb-2`;
const FileRequiredLabel = tw(
  Text.BodyTwelve,
)`text-grey-two italic before:content['* '] before:text-orange`;
const FileDragDropContainer = tw.div`absolute inset-0`;
const LoadingContainer = tw.div`py-28 flex items-center justify-center space-x-3 transition duration-300 animate-fade-in`;
const LoadingDot = styled.span(() => [
  css`
    @keyframes blink {
      50% {
        color: transparent;
      }
    }
    &:nth-of-type(2) {
      ${tw`delay-300`};
    }
    &:nth-of-type(3) {
      ${tw`delay-700`};
    }
  `,
  tw`animation[blink 1s infinite]`,
]);
const ErrorContainer = tw.div`p-5 flex flex-col justify-center items-center transition duration-300 animate-fade-in`;
const ErrorIconContainer = tw.div`p-2 mb-4 rounded-full bg-status-alert-light`;
const ErrorTitle = tw(Text.HeadingThree)`mb-1`;
const ErrorSubtitle = tw(Text.BodyOne)`text-center text-grey-two`;
const ErrorFilename = tw.span`font-semibold text-black`;
const ErrorReuploadButton = tw(Button.Solid)`mt-6`;
// #endregion

// #region TYPES
type ErrorDisplayProps = {
  filename: string;
  error: string;
  handleReupload: () => void;
};
type DragDropFileProps = {
  filename: string;
  error: string;
  dragActive: boolean;
  sampleLink: string;
  requiredLabels: string[];
  isLoading: boolean;
  handleReupload(): void;
  handleDrag: DragEventHandler<HTMLElement>;
  handleDrop: DragEventHandler<HTMLDivElement>;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  subtitleLabel?: string;
  dragDropLabel?: string;
  browseLabel?: string;
  descriptionPrefixLabel?: string;
  descriptionLinkLabel?: string;
  descriptionPostfixLabel?: string;
};

type UploadFileModalProps = DragDropFileProps & {
  onClose(): void;
  title?: string;
};
// #endregion

function LoadingDisplay() {
  const translator = useTranslator();

  const loadingLabel = translator.translate('Loading');

  return (
    <LoadingContainer>
      <LoadingIndicator size="small" />
      <Text.HeadingThree>
        {loadingLabel}
        <LoadingDot>.</LoadingDot>
        <LoadingDot>.</LoadingDot>
        <LoadingDot>.</LoadingDot>
      </Text.HeadingThree>
    </LoadingContainer>
  );
}

function ErrorDisplay({ filename, error, handleReupload }: ErrorDisplayProps) {
  const translator = useTranslator();

  const errorTitleLabel = translator.translate('Failed to Upload');
  const errorButtonLabel = translator.translate('Re-upload');

  return (
    <ErrorContainer>
      <ErrorIconContainer>
        <Icon.CloseOutlined width={72} height={72} />
      </ErrorIconContainer>

      <ErrorTitle>{errorTitleLabel}</ErrorTitle>
      <ErrorSubtitle>
        <ErrorFilename>{filename}</ErrorFilename> {translator.translate(error)}
      </ErrorSubtitle>

      <ErrorReuploadButton type="button" onClick={handleReupload}>
        {errorButtonLabel}
      </ErrorReuploadButton>
    </ErrorContainer>
  );
}

function DragDropFile({
  filename,
  error,
  dragActive,
  requiredLabels,
  sampleLink,
  isLoading,
  handleReupload,
  handleDrag,
  handleDrop,
  handleChange,
  subtitleLabel = 'Upload a XLS file to import data',
  dragDropLabel = 'Drag & drop .xls file or',
  browseLabel = 'Browse',
  descriptionPrefixLabel = 'Download a',
  descriptionLinkLabel = 'sample XLS template',
  descriptionPostfixLabel = 'to see an example of the format required',
}: DragDropFileProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const translator = useTranslator();

  // triggers the input when the button is clicked
  const handleClickBrowse = () => {
    inputRef.current?.click();
  };

  if (isLoading) return <LoadingDisplay />;
  if (error)
    return (
      <ErrorDisplay
        filename={filename}
        error={error}
        handleReupload={handleReupload}
      />
    );

  return (
    <Body>
      <Text.BodyFourteen>
        {translator.translate(subtitleLabel)}
      </Text.BodyFourteen>

      <FileForm
        id="file-form"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <FileInput
          type="file"
          id="file-input"
          name="file-input"
          accept={accept}
          ref={inputRef}
          onChange={handleChange}
        />

        <FileLabel id="file-label" htmlFor="file-input">
          <FileLabelContainer css={[dragActive && tw`bg-orange-hover`]}>
            <Icon.UploadExcel />

            <FileDragDropLabel>
              {translator.translate(dragDropLabel)}{' '}
              <FileBrowseButton type="button" onClick={handleClickBrowse}>
                {translator.translate(browseLabel)}
              </FileBrowseButton>{' '}
            </FileDragDropLabel>

            {requiredLabels.map((label) => (
              <FileRequiredLabel key={label}>
                {translator.translate(label)}
              </FileRequiredLabel>
            ))}
          </FileLabelContainer>
        </FileLabel>

        {dragActive && (
          <FileDragDropContainer
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </FileForm>

      <Text.BodyFourteen>
        {translator.translate(descriptionPrefixLabel)}{' '}
        <DescriptionLink href={sampleLink} download>
          {translator.translate(descriptionLinkLabel)}
        </DescriptionLink>{' '}
        {translator.translate(descriptionPostfixLabel)}
      </Text.BodyFourteen>
    </Body>
  );
}

function UploadFileModal({
  filename,
  error,
  dragActive,
  isLoading,
  requiredLabels,
  sampleLink,
  onClose,
  handleReupload,
  handleDrag,
  handleDrop,
  handleChange,
  title = 'Upload',
  subtitleLabel = 'Upload a XLS file to import data',
  dragDropLabel = 'Drag & drop .xls file or',
  browseLabel = 'Browse',
  descriptionPrefixLabel = 'Download a',
  descriptionLinkLabel = 'sample XLS template',
  descriptionPostfixLabel = 'to see an example of the format required',
}: UploadFileModalProps) {
  const translator = useTranslator();

  return (
    <Modal.WithHeader
      title={translator.translate(title)}
      onClose={isLoading ? undefined : onClose}
      onOverlayClick={isLoading ? undefined : onClose}
      containerStyle={tw`p-0 width[720px]`}
      headerContainerStyle={tw`p-4`}
    >
      <DragDropFile
        filename={filename}
        error={error}
        dragActive={dragActive}
        requiredLabels={requiredLabels}
        sampleLink={sampleLink}
        isLoading={isLoading}
        handleReupload={handleReupload}
        handleDrag={handleDrag}
        handleDrop={handleDrop}
        handleChange={handleChange}
        subtitleLabel={subtitleLabel}
        dragDropLabel={dragDropLabel}
        browseLabel={browseLabel}
        descriptionPrefixLabel={descriptionPrefixLabel}
        descriptionLinkLabel={descriptionLinkLabel}
        descriptionPostfixLabel={descriptionPostfixLabel}
      />
    </Modal.WithHeader>
  );
}

export default UploadFileModal;
