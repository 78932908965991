import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store/store.app';
import api from '../../service/api.service';

export type UseOrganizationSelectorHookObj = ReturnType<
  typeof useOrganizationSelector
>;

export default function useOrganizationSelector() {
  const token = useSelector((state: RootState) => state.session.token);
  const selectOrganizationInfo = useMemo(
    () => api.endpoints.getOrganizationMe.select({ token }),
    [token],
  );
  const organizationInfo = useSelector(selectOrganizationInfo);

  return organizationInfo;
}
