import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../config/router/router.config';
import { helpCenterGettingStartedTerminologyRoute } from './Terminology/HelpCenterGettingStartedTerminology.route';

export type HelpCenterGettingStartedRouteParam = DefaultRouteParam;

export const helpCenterGettingStartedRoute: RouteConfig = {
  name: 'Help Center',
  Component: React.lazy(() => import('./HelpCenterGettingStarted.view')),
  path: '/help-center/getting-started',
  isPrivate: false,
  breadcrumb: 'Help Center',
  children: [
    {
      ...helpCenterGettingStartedTerminologyRoute,
      path: 'terminology',
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    label: 'Memulai',
    testID: 'MenuHelpCenterGettingStarted',
    hasDivider: true,
    hasSub: [helpCenterGettingStartedTerminologyRoute],
  },
};
