import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store/store.app';
import api from '../../service/api.service';
import { isOrganizationOrientationCompleted } from '../../util/setting/setting.util';

export default function useOrganizationOrientation() {
  const { orientation } = useSelector((state: RootState) => state.setting);
  const isOrientationCompleted = useMemo(
    () => isOrganizationOrientationCompleted(orientation),
    [orientation],
  );
  const token = useSelector((state: RootState) => state.session.token);

  const [fetchOrganizationData] = api.useLazyGetOrganizationMeQuery();

  const handleFetchOrganizationData = useCallback(() => {
    if (isOrientationCompleted) return;
    void fetchOrganizationData({ token });
  }, [fetchOrganizationData, isOrientationCompleted, token]);

  return { isOrientationCompleted, handleFetchOrganizationData };
}
