import React from 'react';

export default function CustomDriverMarker({
  fill,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={48} height={55} fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="a" fill={fill || '#fff'}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.259 47.623C39.482 45.613 48 35.801 48 24 48 10.745 37.255 0 24 0S0 10.745 0 24c0 11.801 8.518 21.613 19.741 23.623L24 55l4.259-7.377Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.259 47.623C39.482 45.613 48 35.801 48 24 48 10.745 37.255 0 24 0S0 10.745 0 24c0 11.801 8.518 21.613 19.741 23.623L24 55l4.259-7.377Z"
        fill={fill || '#fff'}
      />
      <path
        d="m28.259 47.623-.353-1.968-.914.163-.465.805 1.732 1Zm-8.518 0 1.732-1-.465-.805-.914-.163-.353 1.968ZM24 55l-1.732 1L24 59l1.732-3L24 55Zm22-31c0 10.816-7.807 19.812-18.094 21.655l.706 3.937C40.772 47.414 50 36.787 50 24h-4ZM24 2c12.15 0 22 9.85 22 22h4C50 9.64 38.36-2 24-2v4ZM2 24C2 11.85 11.85 2 24 2v-4C9.64-2-2 9.64-2 24h4Zm18.094 21.655C9.807 43.812 2 34.815 2 24h-4c0 12.787 9.229 23.414 21.389 25.592l.705-3.937ZM25.732 54l-4.259-7.377-3.464 2L22.268 56l3.464-2Zm.795-7.377L22.268 54l3.464 2 4.259-7.377-3.464-2Z"
        fill="#F3532E"
        mask="url(#a)"
      />
    </svg>
  );
}
