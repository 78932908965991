import React from 'react';
import { Icon } from '../../component/atom';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type ScheduleRouteParam = DefaultRouteParam;

export const scheduleRoute: RouteConfig = {
  name: 'Scheduling',
  Component: React.lazy(() => import('./Schedule.view')),
  path: '/schedule',
  isPrivate: true,
  breadcrumb: 'Schedule',
  options: {
    icon: <Icon.DrivingSchedule />,
    label: 'Scheduling',
    testID: 'MenuScheduling',
  },
};
