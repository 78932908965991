import React from 'react';
import tw, { theme } from 'twin.macro';
import { SelectedActivityId } from '../../../hook/jobOrderDetail/useJODetailSheet.hook';
import {
  JobOrderDeliveryStatus,
  JOStatus,
} from '../../../model/JobOrder.model';
import { getJODeliveryStatusLabel } from '../../../util/shipperOrder.util';
import { Icon } from '../../atom';
import { ChevronRounded } from '../../atom/Icon/Icon.atom';
import { HeadingFive, HeadingThree } from '../../atom/Text/Text.atom';
import { JobOrderStatus, ShipperOrderStatus } from '../../molecule';

// #region STYLED
const Title = tw(HeadingFive)`text-xl truncate flex-1 w-24 mr-2 text-left`;
const Container = tw.div`flex justify-between pb-2`;
const NavContainer = tw.div`flex flex-1`;
const NavAction = tw.div`text-orange`;
// #endregion

// #region INTERFACES

type Props = {
  headerTitle: string;
  status: JOStatus;
  soStatus?: JobOrderDeliveryStatus;
  statusLabel: string;
  showNavigation?: boolean;
  showBackButton?: boolean;
  selectedActivityId?: SelectedActivityId;
  handleDismiss(): void;
  handleBack(): void;
};
// #endregion

type NavProps = {
  showNavigation?: boolean;
  showBackButton?: boolean;
  handleDismiss(): void;
  handleBack(): void;
};

type StatusProps = {
  status: JOStatus;
  soStatus?: JobOrderDeliveryStatus;
  statusLabel: string;
};

function HeaderNavigation({
  showBackButton,
  showNavigation,
  handleDismiss,
  handleBack,
}: NavProps) {
  if (!showNavigation && !showBackButton) return null;
  if (showBackButton)
    return (
      <NavAction tw="mr-3" onClick={handleBack}>
        <Icon.ArrowBack fill={theme`colors.orange.DEFAULT`} />
      </NavAction>
    );
  return (
    <NavAction tw="rotate-90 mr-3" onClick={handleDismiss}>
      <ChevronRounded />
    </NavAction>
  );
}

function HeaderStatus({ status, soStatus, statusLabel }: StatusProps) {
  if (soStatus)
    return (
      <ShipperOrderStatus
        deliveryStatus={soStatus}
        statusLabel={getJODeliveryStatusLabel(soStatus)}
      />
    );
  return <JobOrderStatus status={status} statusLabel={statusLabel} />;
}

export default function JODetailMobileHeader({
  statusLabel,
  headerTitle,
  status,
  soStatus,
  showNavigation,
  showBackButton,
  selectedActivityId,
  handleDismiss,
  handleBack,
}: Props) {
  return (
    <Container>
      <NavContainer>
        <HeaderNavigation
          showBackButton={showBackButton}
          showNavigation={showNavigation}
          handleBack={handleBack}
          handleDismiss={handleDismiss}
        />
        <Title>{headerTitle}</Title>
      </NavContainer>

      {selectedActivityId ? (
        <HeadingThree>
          {selectedActivityId.idx + 1}/{selectedActivityId.totalPhotos}
        </HeadingThree>
      ) : (
        <HeaderStatus
          status={status}
          statusLabel={statusLabel}
          soStatus={soStatus}
        />
      )}
    </Container>
  );
}
