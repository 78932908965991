import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { theme } from 'twin.macro';
import { SnackbarTheme } from '../../component/molecule/Snackbar/Snackbar.molecule';
import { MAX_ZOOM, MIN_ZOOM, STEP } from '../../constant/Gallery.constant';
import useTranslator from '../../hook/useTranslator.hook';
import { snackbarAction } from '../../store/snackbar.store';
import { doDownload } from '../../util/helper.util';
import { UseImageGalleryControllerHookObj } from './hooks/useImageGalleryController.hook';

export type UseGalleryViewModelProps = {
  testID?: string;
  title?: string;
  showDownload?: boolean;
  hideFooter?: boolean;
  enablePan?: boolean;
  disableZoom?: boolean;
  controller: UseImageGalleryControllerHookObj;
};
function useImageGalleryViewModel({
  disableZoom,
  controller,
  title: galleryTitle,
}: Omit<UseGalleryViewModelProps, 'handleGalleryClose'>) {
  const [zoom, setZoom] = useState(MIN_ZOOM);
  const [dx, setDx] = useState(0);
  const [dy, setDy] = useState(0);
  const [imageIndex, setImageIndex] = useState(
    controller.gallery?.startIndex ?? 0,
  );
  const sliderRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const translator = useTranslator();
  const showPrev = useMemo(() => imageIndex > 0, [imageIndex]);
  const showNext = useMemo(() => {
    const images = controller.gallery?.images;
    if (!images) return false;

    return imageIndex < images.length - 1;
  }, [imageIndex, controller.gallery?.images]);
  const selectedImage = useMemo(
    () => controller.gallery?.images[imageIndex],
    [imageIndex, controller.gallery?.images],
  );
  const title = useMemo(
    () =>
      selectedImage?.title || galleryTitle || translator.translate('Gallery'),
    [selectedImage?.title, galleryTitle, translator],
  );
  // #region Event handler
  const handleResetZoom = useCallback(() => {
    setZoom(MIN_ZOOM);
    setDx(0);
    setDy(0);
  }, []);
  const handlePrev = useCallback(() => {
    handleResetZoom();
    setImageIndex((v) => (v > 0 ? v - 1 : v));
  }, [handleResetZoom]);

  const handleNext = useCallback(() => {
    handleResetZoom();
    setImageIndex((v) => {
      const images = controller.gallery?.images;
      if (!images) return v;

      return v < images.length - 1 ? v + 1 : v;
    });
  }, [controller.gallery?.images, handleResetZoom]);
  const handlePanning = useCallback((_dx: number, _dy: number) => {
    setDx(_dx);
    setDy(_dy);
  }, []);
  const handleScrollZoom = useCallback(
    (v: number) => {
      if (disableZoom) return;
      if (v > MIN_ZOOM && v < MAX_ZOOM) {
        setZoom(v > zoom ? v + 0.4 : v - 0.4);
      }
    },
    [zoom, disableZoom],
  );
  const handleZoomIn = useCallback(
    () => setZoom((v) => (v < 4 ? v + STEP : v)),
    [],
  );
  const handleZoomOut = useCallback(
    () => setZoom((v) => (v > MIN_ZOOM ? v - STEP : v)),
    [],
  );
  const handleZoomToFit = useCallback(
    () => handleResetZoom(),
    [handleResetZoom],
  );
  const handleZoomSliderChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setZoom(Number(e.target.value)),
    [],
  );
  const handleDownload = useCallback(() => {
    if (!window.navigator.onLine) {
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translator.translate('No internet connection'),
        }),
      );
      return;
    }
    if (selectedImage) doDownload(selectedImage.source);
  }, [dispatch, selectedImage, translator]);
  // #endregion

  /**
   * Reset zoom cycle
   * When zoom value is less than or equal to minimal zoom value and image coordinate is not equal to default value
   * then set the image to initial position, dx and dy are image coordinates when users move an image
   */
  useEffect(() => {
    if (zoom <= MIN_ZOOM && dx !== 0 && dy !== 0) {
      handleResetZoom();
    }
  }, [dx, dy, handleResetZoom, zoom]);

  useEffect(() => {
    if (!sliderRef.current) return;
    const target = sliderRef.current;
    target.style.background = `linear-gradient(to right, ${theme`colors.orange`} 0%, ${theme`colors.orange`} ${
      ((Number(zoom) - Number(target.min)) /
        (Number(target.max) - Number(target.min))) *
      100
    }%, ${theme`colors.grey.four`} ${
      ((Number(zoom) - Number(target.min)) /
        (Number(target.max) - Number(target.min))) *
      100
    }%, ${theme`colors.grey.four`} 100%)`;
  }, [zoom]);

  useEffect(() => {
    setImageIndex(controller.gallery?.startIndex ?? 0);
  }, [controller.gallery?.startIndex]);

  return {
    handlePanning,
    handleScrollZoom,
    showPrev,
    showNext,
    handlePrev,
    handleNext,
    selectedImage,
    zoom,
    dx,
    dy,
    handleZoomIn,
    handleZoomOut,
    handleZoomToFit,
    handleZoomSliderChange,
    sliderRef,
    handleDownload,
    title,
  };
}

export default useImageGalleryViewModel;
