import { useFormik } from 'formik';
import difference from 'lodash/difference';
import { useCallback, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGE } from '../../../constant';
import {
  joDetailSpendingReportFilterAllStatus,
  JODetailSpendingReportFilterInitialValues,
  joDetailSpendingReportFilterInitialValues,
  JODetailSpendingReportFilterStatus,
} from '../../../constant/JobOrderDetail.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import {
  JobOrderExpenseOrderBy,
  JobOrderExpenseType,
} from '../../../model/JobOrder.model';
import api from '../../../service/api.service';
import {
  getJODetailSpendingReportFilterCategoryValues,
  getJODetailSpendingReportFilterOrderByValues,
  getJODetailSpendingReportFilterStatusValues,
  mapJODetailSpendingReportFilterOrderByToLabel,
  mapJODetailSpendingReportFilterStatuses,
} from '../../../util/jobOrderDetail/jobOrderDetail.util';

export type UseJODetailSpendingReportFilter = ReturnType<
  typeof useJODetailSpendingReportFilter
>;

export default function useJODetailSpendingReportFilter() {
  // #region VALUES
  const translator = useTranslator();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { orderBy } = Object.fromEntries(searchParams);
  const categories = searchParams.getAll('category') as JobOrderExpenseType[];
  const statuses = searchParams.getAll(
    'status',
  ) as JODetailSpendingReportFilterStatus[];

  const [showFilter, setShowFilter] = useState(false);

  const form = useFormik<JODetailSpendingReportFilterInitialValues>({
    enableReinitialize: true,
    initialValues: {
      orderBy:
        (orderBy as JobOrderExpenseOrderBy) ??
        joDetailSpendingReportFilterInitialValues.orderBy,
      category: categories.length
        ? categories
        : joDetailSpendingReportFilterInitialValues.category,
      status: statuses.length
        ? statuses
        : joDetailSpendingReportFilterInitialValues.status,
    },
    onSubmit: (values) => {
      setShowFilter(false);

      searchParams.set('page', String(DEFAULT_PAGE));
      searchParams.set('orderBy', values.orderBy);
      searchParams.delete('category');
      if (values.category.length) {
        for (const _category of values.category) {
          searchParams.append('category', _category);
        }
      }
      searchParams.delete('status');
      if (values.status.length) {
        for (const _status of values.status) {
          searchParams.append('status', _status);
        }
      }

      setSearchParams(searchParams);
    },
  });

  const toggleSortLabel = translator.translate(
    mapJODetailSpendingReportFilterOrderByToLabel(
      orderBy as JobOrderExpenseOrderBy,
    ),
  );
  const orderByValues =
    getJODetailSpendingReportFilterOrderByValues(translator);
  const categoryValues =
    getJODetailSpendingReportFilterCategoryValues(translator);
  const statusValues = getJODetailSpendingReportFilterStatusValues(translator);
  const statusNumbers = mapJODetailSpendingReportFilterStatuses(
    form.values.status,
  );

  const isQueryCategoryEmpty = form.values.category.length === 0;
  const isQueryCategoryAll =
    difference(
      joDetailSpendingReportFilterInitialValues.category,
      form.values.category,
    ).length === 0;
  const isQueryStatusEmpty = form.values.status.length === 0;
  const isQueryStatusAll =
    difference(joDetailSpendingReportFilterAllStatus, form.values.status)
      .length === 0;
  const filterCategoryIsActive = !!(categories.length && !isQueryCategoryAll);
  const filterStatusIsActive = !isQueryStatusAll;

  const activeQueries = useMemo<string[]>(() => {
    const queries: string[] = [];
    const isQueryCategoryParamsAll =
      difference(joDetailSpendingReportFilterInitialValues.category, categories)
        .length === 0;
    const filterCategoryParamsIsActive =
      categories.length && !isQueryCategoryParamsAll;
    const isQueryStatusParamsAll =
      difference(joDetailSpendingReportFilterAllStatus, statuses).length === 0;
    const filterStatusParamsIsActive = !isQueryStatusParamsAll;

    if (filterCategoryParamsIsActive) {
      queries.push(`${translator.translate('Category')}:${categories.length}`);
    }
    if (filterStatusParamsIsActive) {
      queries.push(
        `${translator.translate('Status')}:${
          statuses.length ? statuses.length : form.values.status.length
        }`,
      );
    }

    return queries;
  }, [categories, form.values.status.length, statuses, translator]);

  // this is necessary because we need to query multiple `status[]` params
  const countParams = useMemo(() => {
    const params = new URLSearchParams('');

    params.append('jo_id', String(id));
    if (form.values.category.length) {
      for (const _category of form.values.category) {
        params.append('type[]', String(_category));
      }
    }
    if (statusNumbers.length) {
      for (const _status of statusNumbers) {
        params.append('status[]', String(_status));
      }
    }

    return params.toString();
  }, [form.values.category, id, statusNumbers]);

  // get jo total count based on current applied filter
  const { filterLocalTotalCount, filterLocalIsFetching } =
    api.useGetJobOrderSpendingReportCountQuery(countParams, {
      skip: !showFilter || isQueryCategoryEmpty || isQueryStatusEmpty,
      selectFromResult: ({ data, isFetching }) => ({
        filterLocalTotalCount: data?.expenses.totalCount,
        filterLocalIsFetching: isFetching,
      }),
    });

  const testID = 'JobOrderDetailSpendingReport';
  const errorCategoryLabel = translator.translate(
    'Requires at least 1 selected category',
  );
  const errorStatusLabel = translator.translate(
    'Requires at least 1 selected status',
  );
  const titleLabel = translator.translate('Filters');
  const resetButtonLabel = translator.translate('Reset');
  const orderByLabel = translator.translate('Sort by');
  const selectAllLabel = translator.translate('Select All');
  const unselectAllLabel = translator.translate('Unselect All');
  const categoryLabel = translator.translate('Category');
  const filterCategoryLabel = filterCategoryIsActive
    ? `${translator.translate('Category')}:${categories.length}`
    : categoryLabel;
  const statusLabel = translator.translate('Status');
  const filterStatusLabel = filterStatusIsActive
    ? `${translator.translate('Status')}:${form.values.status.length}`
    : statusLabel;
  const submitButtonLabel = filterLocalIsFetching
    ? translator.translate('Loading...')
    : `${translator.translate('Show')} ${String(
        filterLocalTotalCount,
      )} ${translator.translate('Expenses')}`;
  // #endregion

  // #region HANDLERS
  const onOpenFilter = useCallback(() => {
    setShowFilter(true);
  }, []);

  const onCloseDrawerPortal = useCallback(() => {
    setShowFilter(false);

    // set filter state to initial query params from before
    void form.setValues((prev) => ({
      orderBy: (orderBy as JobOrderExpenseOrderBy) ?? prev.orderBy,
      category: categories.length ? categories : prev.category,
      status: statuses.length ? statuses : prev.status,
    }));
  }, [form, orderBy, categories, statuses]);

  const onClickResetFilter = useCallback(() => {
    // set filter state to initial values
    void form.setValues(joDetailSpendingReportFilterInitialValues);
  }, [form]);

  const onClickOrderByChipFilter = useCallback(
    (_orderBy: JobOrderExpenseOrderBy) => {
      void form.setFieldValue('orderBy', _orderBy, false);
    },
    [form],
  );

  const onClickCategoryChipFilter = useCallback(
    (_category: JobOrderExpenseType) => {
      const ps = form.values.category;
      const logic = ps.includes(_category)
        ? ps.filter((stat) => stat !== _category)
        : ps.concat([_category]);

      void form.setFieldValue('category', logic, false);
    },
    [form],
  );
  const onClickSelectAllCategory = useCallback(() => {
    void form.setFieldValue(
      'category',
      joDetailSpendingReportFilterInitialValues.category,
      false,
    );
  }, [form]);
  const onClickUnselectAllCategory = useCallback(() => {
    void form.setFieldValue('category', [], false);
  }, [form]);

  const onClickStatusChipFilter = useCallback(
    (_status: JODetailSpendingReportFilterStatus) => {
      const ps = form.values.status;
      const logic = ps.includes(_status)
        ? ps.filter((stat) => stat !== _status)
        : ps.concat([_status]);

      void form.setFieldValue('status', logic, false);
    },
    [form],
  );
  const onClickSelectAllStatus = useCallback(() => {
    void form.setFieldValue(
      'status',
      joDetailSpendingReportFilterAllStatus,
      false,
    );
  }, [form]);
  const onClickUnselectAllStatus = useCallback(() => {
    void form.setFieldValue('status', [], false);
  }, [form]);

  const onClickSubmitFilter = useCallback(() => form.handleSubmit(), [form]);
  // #endregion

  return {
    showFilter,
    form,
    activeQueries,
    toggleSortLabel,
    orderByValues,
    categoryValues,
    statusValues,
    statusNumbers,
    isQueryCategoryAll,
    isQueryStatusAll,
    filterLocalIsFetching,
    testID,
    titleLabel,
    resetButtonLabel,
    orderByLabel,
    selectAllLabel,
    categoryLabel,
    filterCategoryLabel,
    statusLabel,
    filterStatusLabel,
    submitButtonLabel,
    onOpenFilter,
    onCloseDrawerPortal,
    onClickResetFilter,
    onClickOrderByChipFilter,
    onClickCategoryChipFilter,
    onClickSelectAllCategory,
    onClickStatusChipFilter,
    onClickSelectAllStatus,
    onClickSubmitFilter,
    isQueryCategoryEmpty,
    isQueryStatusEmpty,
    errorCategoryLabel,
    errorStatusLabel,
    unselectAllLabel,
    onClickUnselectAllCategory,
    onClickUnselectAllStatus,
  };
}
