import React from 'react';
import tw, { TwStyle } from 'twin.macro';
import { SOActivityType } from '../../../constant';
import { HeadingThree } from '../../atom/Text/Text.atom';
import { Alert } from '../../molecule';
import ActivityTableBody, {
  ActivityItemType,
} from '../../molecule/ActivityTableBody/ActivityTableBody.molecule';
import ActivityTableHeader, {
  TableHeaderItem,
} from '../../molecule/ActivityTableHeader/ActivityTableHeader.molecule';

const Container = tw.div`width[720px] py-[25px] px-[20px] mx-auto`;

const ActivityTable = tw.table`w-[640px] table-cell border-spacing[12px]`;

export type ActivityItemProps = {
  title: string;
  activityDate: string;
  status: string;
  data: ActivityItemType[];
};

type Props = ActivityItemProps;

export default function ActivityItem({
  title,
  activityDate,
  data,
  status,
}: Props) {
  const theadItem: TableHeaderItem[] = [
    {
      title: 'Type',
      width: '132px',
    },
    {
      title: 'Description',
      width: '202px',
    },
    {
      title: 'Qty',
      width: '72px',
      isNumber: true,
    },
    {
      title: 'Unit',
      width: '52px',
    },
    {
      title: 'Weight (kg)',
      width: '92px',
      isNumber: true,
    },
    {
      title: 'Volume (m³)',
      width: '102px',
      isNumber: true,
    },
  ];
  const activityTypes: Record<string, TwStyle> = {
    [SOActivityType.STAND_BY]: tw`border bg-lime-bg border-lime-border`,
    [SOActivityType.PICK_UP]: tw`border bg-ocean-bg border-ocean-text`,
    [SOActivityType.DROP_OFF]: tw`border bg-blue-light border-twilight-text`,
  };

  const activityTitleStyle: Record<string, TwStyle> = {
    [SOActivityType.STAND_BY]: tw`text-lime-border`,
    [SOActivityType.PICK_UP]: tw`text-ocean-text`,
    [SOActivityType.DROP_OFF]: tw`text-twilight-text`,
  };

  return (
    <Container>
      <HeadingThree>{title}</HeadingThree>
      <Alert
        text={activityDate}
        containerStyle={[
          activityTypes[status],
          tw` my-2.5 w-[680px] whitespace-pre-wrap overflow-wrap[anywhere]`,
        ]}
        titleStyle={[activityTitleStyle[status]]}
      />
      {!!data.length && (
        <Alert containerStyle={tw`border border-ocean-text bg-white`}>
          <ActivityTable>
            <ActivityTableHeader items={theadItem} status={status} />
            <ActivityTableBody items={data} status={status} />
          </ActivityTable>
        </Alert>
      )}
    </Container>
  );
}
