import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';
import DrivingContestRegisterConfirm from './DrivingContestRegisterConfirmation.view';

export type DrivingContestRegisterConfirmationRouteParam = DefaultRouteParam;

export const drivingContestRegisterConfirmationRoute: RouteConfig = {
  name: 'Confirm Registration',
  Component: React.lazy(
    () => import('./DrivingContestRegisterConfirmation.view'),
  ),
  NormalComponent: <DrivingContestRegisterConfirm />,
  path: '/driver-contest/register/confirmation',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
