import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterGettingStartedTerminologyRouteParam = DefaultRouteParam;

export const helpCenterGettingStartedTerminologyRoute: RouteConfig = {
  name: 'Help Center Getting Started Terminology',
  Component: React.lazy(
    () => import('./HelpCenterGettingStartedTerminology.view'),
  ),
  path: '/help-center/getting-started/terminology',
  isPrivate: false,
  breadcrumb: 'Help Center Getting Started Terminology',
  options: {
    label: 'Istilah di Job Management',
    testID: 'MenuHelpCenterGettingStartedTerminology',
  },
};
