import { Builder } from './api.baseQuery';
import {
  activityTemplateEndpoint,
  ActivityTemplateEndpoint,
} from './endpoint/activityTemplate/activityTemplate.endpoint';
import { authEndpoint, AuthEndpoint } from './endpoint/auth/auth.endpoint';
import {
  callCenterEndpoint,
  CallCenterEndpoint,
} from './endpoint/callCenter/callCenter.endpoint';
import {
  dashboardEndpoint,
  DashboardEndpoint,
} from './endpoint/dashboard/dashboard.endpoint';
import {
  DriverEndpoint,
  driverEndpoint,
} from './endpoint/driver/driver.endpoint';
import {
  drivingContestEndpoint,
  DrivingContestEndpoint,
} from './endpoint/drivingContest/drivingContest.endpoint';
import {
  haulingJobOrderEndpoint,
  HaulingJobOrderEndpoint,
} from './endpoint/haulingJobOrder/haulingJobOrder.endpoint';
import {
  haulingOrderEndpoint,
  HaulingOrderEndpoint,
} from './endpoint/haulingOrder/haulingOrder.endpoint';
import {
  JobOrderEndpoint,
  jobOrderEndpoint,
} from './endpoint/jobOrder/jobOrder.endpoint';
import {
  deliveryLocationEndpoint,
  DeliveryLocationEndpoint,
} from './endpoint/location/location.endpoint';
import {
  organizationEndpoint,
  OrganizationEndpoint,
} from './endpoint/organization/organization.endpoint';
import {
  ReportEndpoint,
  reportEndpoint,
} from './endpoint/report/report.endpoint';
import {
  runnerEventEndpoint,
  RunnerEventEndpoint,
} from './endpoint/runnerEvent/runnerEvent.endpoint';
import {
  scheduleEndpoint,
  ScheduleEndpoint,
} from './endpoint/schedule/schedule.endpoint';
import {
  shipperEndpoint,
  ShipperEndpoint,
} from './endpoint/shipper/shipper.endpoint';
import {
  ShipperOrderEndpoint,
  shipperOrderEndpoint,
} from './endpoint/shipperOrder/shipperOrder.endpoint';
import {
  vehicleEndpoint,
  VehicleEndpoint,
} from './endpoint/vehicle/vehicle.endpoint';

// #region INTERFACES
export type ErrorDetail = {
  key?: string;
  rule: string;
  message: string;
};

export interface ErrorResponse {
  code: string;
  details: ErrorDetail[];
}
export interface IApiResponse {
  ok: boolean;
}

export interface ApiSuccessResponse extends IApiResponse {
  ok: true;
}

export interface ApiErrorResponse extends IApiResponse {
  ok: false;
  error: ErrorResponse;
}

export type ApiResponse = ApiSuccessResponse | ApiErrorResponse;

export type SSOErrorResponse = { message: string };

// NOTE: we may need to replace all commonApiResponse implementations and use ApiResponse
export type CommonApiResponse = {
  ok: true;
  error?: {
    code: string;
    details: string[];
  };
};

export type CommonApiMetadata = {
  metadata: {
    page: number;
    pageSize: number;
    totalCount: number;
  };
};

export type CommonApiListParams = {
  page?: number;
  pageSize?: number;
  search?: string;
};

export type CommonErrorResponse = {
  statusCode: number;
  message: string;
};

export type CommonErrorObject = {
  data: Partial<CommonApiResponse>;
};

// #endregion

export type Endpoint = DriverEndpoint &
  JobOrderEndpoint &
  HaulingOrderEndpoint &
  ShipperOrderEndpoint &
  ReportEndpoint &
  DeliveryLocationEndpoint &
  DashboardEndpoint &
  ScheduleEndpoint &
  VehicleEndpoint &
  ShipperEndpoint &
  AuthEndpoint &
  ActivityTemplateEndpoint &
  OrganizationEndpoint &
  CallCenterEndpoint &
  HaulingJobOrderEndpoint &
  HaulingOrderEndpoint &
  RunnerEventEndpoint &
  DrivingContestEndpoint;

const endpoint = (builder: Builder): Endpoint => ({
  ...driverEndpoint(builder),
  ...jobOrderEndpoint(builder),
  ...haulingOrderEndpoint(builder),
  ...shipperOrderEndpoint(builder),
  ...reportEndpoint(builder),
  ...deliveryLocationEndpoint(builder),
  ...dashboardEndpoint(builder),
  ...scheduleEndpoint(builder),
  ...vehicleEndpoint(builder),
  ...shipperEndpoint(builder),
  ...authEndpoint(builder),
  ...activityTemplateEndpoint(builder),
  ...organizationEndpoint(builder),
  ...callCenterEndpoint(builder),
  ...haulingJobOrderEndpoint(builder),
  ...haulingOrderEndpoint(builder),
  ...runnerEventEndpoint(builder),
  ...drivingContestEndpoint(builder),
});

export default endpoint;
