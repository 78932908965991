import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { ProgressBar, Text } from '../../component/atom';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../component/molecule';
import {
  BulkActionStatusEnum,
  ForceCompleteHaulingOrderEntity,
} from '../../constant';
import { HaulingOrder } from '../../model/HaulingOrder.model';
import { getHOTotalTargetPercentage } from '../../util/haulingOrder.util';
import { mapBulkActionStatusToLabel } from '../../util/helper.util';
import { UseTranslator } from '../useTranslator.hook';
import { UseHaulingOrderForceCompleteLogicObj } from './useHaulingOrderForceCompleteLogic.hook';

// #region INTERFACES
export type UseHaulingOrderForceCompleteTableParams = {
  translator: UseTranslator;
  haulingOrderForceCompleteLogic: UseHaulingOrderForceCompleteLogicObj;
};
// #endregion

const ParagraphWithOnly2Lines = styled.p`
  ${tw`font-sans font-weight[400] text-sm leading-6 text-grey-two whitespace-pre-line text-overflow[ellipsis] overflow-hidden`}
  & {
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const useHaulingOrderForceCompleteTable = ({
  translator,
  haulingOrderForceCompleteLogic,
}: UseHaulingOrderForceCompleteTableParams) => {
  const columns = useMemo<ColumnDef<ForceCompleteHaulingOrderEntity>[]>(
    () => [
      {
        size: 135,
        header: translator.translate('HO Number'),
        accessorKey: 'hoNumber',
        cell: ({ getValue }) => (
          <CustomTableRowWithPopover
            primaryLabel={getValue() as string}
            containerStyle={tw`max-width[180px]`}
            primaryLabelStyle={tw`font-bold`}
          />
        ),
      },
      {
        size: 140,
        header: () => (
          <div tw="width[180px]">{translator.translate('Shipper Name')}</div>
        ),
        accessorKey: 'shipper',
        cell: ({ getValue }) => {
          const { name } = getValue() as HaulingOrder['shipper'];

          return <ParagraphWithOnly2Lines>{name}</ParagraphWithOnly2Lines>;
        },
      },
      {
        size: 170,
        header: () => (
          <div tw="text-right">{translator.translate('Goods Target')}</div>
        ),
        accessorKey: 'deliveries',
        cell: ({ getValue }) => {
          const deliveries = getValue() as HaulingOrder['deliveries'];

          const totalPercentage = getHOTotalTargetPercentage(deliveries);

          return (
            <section tw="flex flex-col w-full">
              <Text.Paragraph tw="text-right">
                {totalPercentage.toFixed(2).replace('.', ',')}%{' '}
                {translator.translate('Completed')}
              </Text.Paragraph>

              <ProgressBar max={100} value={totalPercentage}>
                {totalPercentage.toFixed(2)}%
              </ProgressBar>
            </section>
          );
        },
      },
      ...(haulingOrderForceCompleteLogic.showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 220,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({ getValue }: { getValue: () => string }) => (
                <CustomTableRowWithPopover
                  primaryLabel={translator.translate(getValue())}
                  containerStyle={tw`max-width[240px]`}
                  primaryLabelStyle={tw`animate-slide-in-left`}
                />
              ),
            },
          ]
        : []),
    ],
    [haulingOrderForceCompleteLogic.showStatus, translator],
  );

  const table = useReactTable({
    columns,
    data: haulingOrderForceCompleteLogic.haulingOrdersWithStatusDetail,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
};

export type UseHaulingOrderForceCompleteTableObj = ReturnType<
  typeof useHaulingOrderForceCompleteTable
>;
export default useHaulingOrderForceCompleteTable;
