import React, { LegacyRef, MutableRefObject, useMemo } from 'react';
import ReactJoyride from 'react-joyride';
import { Link } from 'react-router-dom';
import tw, { styled, theme } from 'twin.macro';
import { ProfileCardLegalItemProps } from '../../../hook/useProfile/userProfile.hook';
import { UseTopBarHookObj } from '../../../hook/useTopBar/useTopBar.hook';
import { UseTopbarOnboardingHookObj } from '../../../hook/useTopbarOnboarding/useTopbarOnboarding.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { logEvent } from '../../../service/analytic/analytic.service';
import { replacePlusSign } from '../../../util/helper.util';
import { homeRoute } from '../../../view/Home/Home.route';
import { UseSessionHookObj } from '../../../view/Wrapper/hooks/useSession.hook';
import {
  Button,
  Icon,
  IconButton as BaseIconButton,
  OutsideAlerter,
  Popover,
  Text,
} from '../../atom';
import OnboardingPopup from '../OnboardingPopup/OnboardingPopup.organism';
import ProfileCard from '../ProfileCard/ProfileCard.organism';

// #region INTERFACES
type Props = {
  onboarding: UseTopbarOnboardingHookObj;
  topBar: UseTopBarHookObj;
  session: UseSessionHookObj;
  translator: UseTranslator;
  left: React.ReactNode;
  right: React.ReactNode;
  isLoggedIn?: boolean;
  profileCardLegalItems: ProfileCardLegalItemProps[];
  onClickHelp(): void;
  onClickMenu(): void;
  onClickSetting(): void;
};
// #endregion

// #region STYLED
const Root = tw.div`bg-white shadow-card flex justify-between px-4 py-[9px] fixed top-0 w-full z-50 h-16`;
const Section = tw.div`flex items-center space-x-2`;
const IconButton = tw(BaseIconButton)`text-grey-two relative`;
const VerticalDivider = tw.div`self-stretch bg-beige-lines bg-opacity-50 w-px`;
const BreadcrumbsContainer = tw.div`flex flex-row items-center`;
const Breadcrumbs = tw.div`flex items-center pl-4 ml-6`;
const ProfileContainer = tw.div``;
const Avatar = styled.div`
  & svg {
    width: 30px;
    height: 30px;
  }
`;
// #endregion

const componentName = 'TopBar';
const componentSection = {
  breadcrumbSection: `${componentName}:BreadcrumbSection`,
  iconSection: `${componentName}:IconSection`,
};
const testIds = {
  navMenu: `${componentSection.breadcrumbSection}:NavMenu`,
  homeMenu: `${componentSection.breadcrumbSection}:HomeNav`,
  homeMenuId: 'topBarHomeNav',

  breadcrumb: (idx: number) =>
    `${componentSection.breadcrumbSection}:Breadcrumb:${idx}`,
  onboardingMenu: `${componentSection.iconSection}:OnboardingNav`,
  onboardingMenuId: 'topBarRocket',
  helpMenu: `${componentSection.iconSection}:HelpNav`,
  settingMenu: `${componentSection.iconSection}:SettingNav`,
  profileMenu: `${componentSection.iconSection}:ProfileNav`,
};

const topBarEvent = {
  toggleOnBoarding: `KTB_${componentName}_Button_ToggleOnBoarding`,
};

function BreadcrumbItem({
  text,
  disabled,
  onClick,
  testId,
}: {
  disabled?: boolean;
  onClick?: () => void;
  text: string;
  testId?: string;
}) {
  if (disabled)
    return (
      <Text.Paragraph tw="px-2 font-bold text-base" data-testid={testId}>
        {text}
      </Text.Paragraph>
    );

  return (
    <Button.Solid
      data-testid={testId}
      tw="px-2 text-black font-bold bg-transparent hover:(bg-orange-hover)"
      onClick={onClick}
    >
      {text}
    </Button.Solid>
  );
}

export default function TopBar({
  onboarding,
  topBar,
  session,
  right,
  isLoggedIn,
  translator,
  left,
  profileCardLegalItems,
  onClickMenu,
  onClickSetting,
  onClickHelp,
}: Props) {
  const breadcrumbs = useMemo(
    () => topBar.breadcrumbs.filter((item) => !!item.name),
    [topBar.breadcrumbs],
  );

  const breadcrumbList = useMemo(
    () =>
      breadcrumbs.map((item, i) => (
        <BreadcrumbsContainer key={item.name}>
          <Icon.ChevronSharp
            fill="none"
            width={20}
            height={20}
            stroke="black"
          />
          <BreadcrumbItem
            text={decodeURIComponent(
              replacePlusSign(
                translator.translate(
                  typeof item.customName === 'string' && !!item.customName
                    ? item.customName
                    : item.name,
                ),
              ),
            )}
            disabled={item.isUnclickable || i === breadcrumbs.length - 1}
            onClick={() => topBar.onBreadcrumbClick(item.customUrl || item.url)}
            testId={testIds.breadcrumb(i)}
          />
        </BreadcrumbsContainer>
      )),
    [breadcrumbs, topBar, translator],
  );
  return (
    <>
      <Root>
        <Section>
          {isLoggedIn && (
            <>
              <IconButton
                data-testid={testIds.navMenu}
                squared
                tw="margin-left[3px]"
                onClick={onClickMenu}
              >
                <Icon.NavMenu />
              </IconButton>
              <VerticalDivider />
            </>
          )}
          <Breadcrumbs>
            <IconButton
              onClick={() => topBar.onBreadcrumbClick(homeRoute.path)}
              id={testIds.homeMenuId}
              data-testid={testIds.homeMenu}
            >
              <Link to={homeRoute.path}>
                <Icon.NavHome />
              </Link>
            </IconButton>
            {breadcrumbList}
          </Breadcrumbs>
        </Section>

        <Section>
          {isLoggedIn && (
            <>
              {!(
                onboarding.homeOnboarding.isActiveOnboarding &&
                onboarding.isHomeRoute
              ) && (
                <ProfileContainer>
                  <IconButton
                    tw="p-3"
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      topBar.handleCloseProfile();
                      onboarding.handleToggleOnboardingPopup();
                      logEvent(topBarEvent.toggleOnBoarding, 'TopBar');
                    }}
                    id={testIds.onboardingMenuId}
                    data-testid={testIds.onboardingMenu}
                    ref={onboarding.onboardingRef as LegacyRef<HTMLDivElement>}
                  >
                    <Icon.Rocket />
                  </IconButton>
                  <Popover
                    visible={onboarding.showOnboardingPopup}
                    targetRef={
                      onboarding.onboardingRef as unknown as MutableRefObject<null>
                    }
                    style={{ zIndex: 10, inset: '1rem auto auto -0.5rem' }}
                  >
                    <OutsideAlerter
                      onClickAway={onboarding.handleToggleOnboardingPopup}
                    >
                      <OnboardingPopup topbarOnboarding={onboarding} />
                    </OutsideAlerter>
                  </Popover>
                </ProfileContainer>
              )}

              <IconButton
                onClick={onClickHelp}
                data-testid={testIds.settingMenu}
              >
                <Icon.Help />
              </IconButton>
              <IconButton
                onClick={onClickSetting}
                data-testid={testIds.settingMenu}
              >
                <Icon.Settings />
              </IconButton>
              {left}
              <ProfileContainer>
                <IconButton
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    onboarding.handleCloseOnboardingPopup();
                    topBar.handleProfileClick();
                  }}
                  data-testid={testIds.profileMenu}
                  ref={topBar.profileRef}
                >
                  <Avatar>
                    <Icon.AvatarPlaceholder />
                  </Avatar>
                </IconButton>
                <Popover
                  visible={topBar.showProfileDropdown}
                  targetRef={topBar.profileRef as MutableRefObject<null>}
                  style={{ zIndex: 10, inset: '1rem auto auto -0.5rem' }}
                >
                  <OutsideAlerter onClickAway={topBar.handleProfileClick}>
                    <ProfileCard
                      onProfileClick={topBar.handleProfileClick}
                      profileCardLegalItems={profileCardLegalItems}
                      translator={translator}
                      username={topBar.userInfo?.username || session.username}
                      organizationName={
                        topBar.userInfo?.organizationName ||
                        session.organizationName
                      }
                      onLogoutClick={() =>
                        topBar.handleLogoutClick({
                          username: session.username,
                          organizationName: session.organizationName,
                        })
                      }
                    />
                  </OutsideAlerter>
                </Popover>
              </ProfileContainer>
              {right}
            </>
          )}
        </Section>
      </Root>

      <ReactJoyride
        continuous
        disableOverlayClose
        hideCloseButton
        hideBackButton
        run={onboarding.homeOnboarding.state.topbar.run}
        stepIndex={onboarding.homeOnboarding.state.topbar.stepIndex}
        locale={{
          next: translator.translate(
            onboarding.homeOnboarding.state.topbar.stepIndex === 0
              ? 'Next'
              : 'Continue',
          ),
        }}
        steps={[
          {
            target: '#topBarRocket',
            disableBeacon: true,
            spotlightPadding: 1.8,
            content: (
              <div tw="flex flex-col items-start space-y-1">
                <div tw="w-full flex items-center justify-between">
                  <Text.HeadingFive>
                    {translator.translate('Onboarding Progress Tracker')}
                  </Text.HeadingFive>
                  <Text.HeadingFive>
                    {onboarding.homeOnboarding.state.topbar.stepIndex + 1}/2
                  </Text.HeadingFive>
                </div>

                <Text.BodyTwelve>
                  {translator.translate(
                    'You can always check your Onboarding progress by clicking this button to show what steps are remained',
                  )}
                </Text.BodyTwelve>
              </div>
            ),
          },
          {
            target: '#topBarHomeNav',
            disableBeacon: true,
            spotlightPadding: 4,
            content: (
              <div tw="flex flex-col items-start space-y-1">
                <div tw="w-full flex items-center justify-between">
                  <Text.HeadingFive>
                    {translator.translate('Onboarding Main Page')}
                  </Text.HeadingFive>
                  <Text.HeadingFive>
                    {onboarding.homeOnboarding.state.topbar.stepIndex + 1}/2
                  </Text.HeadingFive>
                </div>

                <Text.BodyTwelve>
                  {translator.translate(
                    'Click this button to go back to the onboarding main page',
                  )}
                </Text.BodyTwelve>
              </div>
            ),
          },
          // third index is unused / never rendered, but required so that the "Next" button is displayed
          {
            target: '#unused',
            disableBeacon: true,
            content: <div />,
          },
        ]}
        callback={({ action, type, index }) => {
          if (action === 'close') {
            onboarding.handleStopOnboarding();
          } else if (
            action === 'next' &&
            type === 'step:after' &&
            index === 1
          ) {
            onboarding.handleFinishOnboarding();
          } else if (action === 'next' && type === 'step:after') {
            onboarding.handleContinueOnboarding();
          }
        }}
        styles={{
          tooltipContainer: {
            textAlign: 'left',
          },
          tooltipContent: {
            padding: 0,
          },
          buttonNext: {
            backgroundColor: theme`colors.orange.DEFAULT`,
            color: 'white',
            padding: '14px 16px',
          },
        }}
      />
    </>
  );
}
