import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../constant';
import {
  JODetailSpendingReportFilterStatus,
  joDetailSpendingReportStatusFilterInitialValues,
} from '../../../constant/JobOrderDetail.constant';
import { JobOrderExpenseType } from '../../../model/JobOrder.model';
import api from '../../../service/api.service';
import { mapJODetailSpendingReportFilterStatuses } from '../../../util/jobOrderDetail/jobOrderDetail.util';

export type UseJODetailSpendingReportList = ReturnType<
  typeof useJODetailSpendingReportList
>;

export default function useJODetailSpendingReportList() {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { page, pageSize, orderBy } = Object.fromEntries(searchParams);
  const categories = searchParams.getAll('category') as JobOrderExpenseType[];
  const statuses = searchParams.getAll(
    'status',
  ) as JODetailSpendingReportFilterStatus[];

  const statusNumbers = mapJODetailSpendingReportFilterStatuses(statuses);

  const queryparams = useMemo(() => {
    const params = new URLSearchParams('');

    params.set('jo_id', String(id));
    params.set('page', page ?? String(DEFAULT_PAGE));
    params.set('page_size', pageSize ?? String(DEFAULT_PAGE_SIZE));

    for (const status of statusNumbers.length
      ? statusNumbers
      : joDetailSpendingReportStatusFilterInitialValues) {
      params.append('status[]', String(status));
    }

    if (orderBy) params.set('order_by', orderBy);
    if (categories.length) {
      for (const category of categories) {
        params.append('type[]', String(category));
      }
    }

    return params.toString();
  }, [id, page, pageSize, statusNumbers, orderBy, categories]);

  const query = api.useGetJobOrderSpendingReportListQuery(queryparams, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data, isFetching }) => ({
      isFetching,
      list: data?.expenses ?? [],
      metadata: data?.metadata,
    }),
  });

  return { query };
}
