import React, { LegacyRef, MutableRefObject, useState } from 'react';
import tw, { styled, TwStyle } from 'twin.macro';
import { TextField } from '..';
import { Icon, LoadingIndicator, OutsideAlerter } from '../../atom';

// #region INTERFACES
type Props = React.ComponentProps<'input'> & {
  placeholder?: string;
  changeData?(data: string): void;
  onClearSearch?(): void;
  onClickAway?(): void;
  error?: string;
  hideIcon?: boolean;
  loading?: boolean;
  alwaysOpen?: boolean;
  titleSearch?: boolean;
  underline?: boolean;
  inputStyle?: TwStyle;
  isPasteProhibited?: boolean;
  tooltipRef?: MutableRefObject<null>;
};
// #endregion

// #region STYLED
const Container = styled.div(
  (props: { open: boolean; titleSearch: boolean }) => [
    `input[type='search']::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    input[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }`,
    props.open && tw`w-full`,
    props.open && props.titleSearch && tw`width[calc(100vw - 72px)]`,
  ],
);
const CloseButton = tw.div`text-white bg-black bg-opacity-20 rounded-full p-1 cursor-pointer`;
// #endregion

export function Search({
  disabled,
  value,
  children,
  changeData,
  onClearSearch,
  onClickAway,
  hideIcon,
  inputStyle,
  loading = false,
  alwaysOpen = false,
  titleSearch = false,
  underline = true,
  onFocus,
  onBlur,
  tooltipRef,
  isPasteProhibited,
  error,
  ...props
}: Props) {
  const [openSearch, setOpenSearch] = useState<boolean>(false);

  return (
    <OutsideAlerter
      onClickAway={() => {
        setOpenSearch((prev) => !prev);
        onClickAway?.();
      }}
    >
      <Container
        className="search-container"
        titleSearch={titleSearch}
        open={openSearch || !!value || alwaysOpen}
        ref={tooltipRef as LegacyRef<HTMLDivElement>}
      >
        <TextField
          onFocus={(ev) => {
            if (disabled) setOpenSearch(true);
            if (onFocus) onFocus(ev);
          }}
          onBlur={(ev) => {
            if (onBlur) onBlur(ev);
          }}
          onPaste={(e) => {
            if (isPasteProhibited) {
              e.preventDefault();
              return false;
            }
            return true;
          }}
          error={error}
          onClick={() => (disabled ? null : setOpenSearch(true))}
          inputStyle={inputStyle}
          disabled={disabled}
          underline={underline}
          value={value}
          type="search"
          left={
            !hideIcon && (
              <Icon.Search
                tw="h-5 w-5 text-grey-two"
                onClick={() => {
                  setOpenSearch(true);
                }}
              />
            )
          }
          right={
            loading && openSearch ? (
              <LoadingIndicator size="small" />
            ) : (
              !!value && (
                <CloseButton
                  onClick={(e) => {
                    if (!disabled) {
                      e.stopPropagation();
                      changeData?.('');

                      onClearSearch?.();
                    }
                  }}
                >
                  <Icon.Close width={15} height={15} />
                </CloseButton>
              )
            )
          }
          contentEditable={false}
          {...props}
          onChange={(e) => {
            changeData?.(e.target.value);
          }}
        />
      </Container>
    </OutsideAlerter>
  );
}

export default Search;
