import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';
import ShippeOrderDeleteBulkView from './ShipperOrderDeleteBulk.view';

export type ShipperOrderDeleteBulkRouteParam = DefaultRouteParam;

export const shipperOrderDeleteBulkRoute: RouteConfig = {
  name: 'Delete Shipper Order',
  Component: React.lazy(() => import('./ShipperOrderDeleteBulk.view')),
  NormalComponent: <ShippeOrderDeleteBulkView />,
  path: '/shipper-order/delete',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
