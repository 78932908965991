import { useCallback, useState } from 'react';
import { Photo } from '../../../model/Common.model';

export type Image = {
  source: string;
  caption: string;
  /**
   * Title based on each image, will overwrite the `title` of ImageGallery if provided
   */
  title?: string;
};

export type ImageGallery = {
  images: Image[];
  startIndex: number;
};

export type GalleryThumbnailClickEvent = (
  photos: Photo[],
  index: number,
  activityIndex: number,
  customTitle?: string | string[],
) => void;

function useImageGalleryController() {
  const [gallery, setGallery] = useState<ImageGallery | undefined>(undefined);
  const handleGalleryThumbnailsClick = useCallback(
    ({ images, startIndex }: ImageGallery) => {
      setGallery({
        images,
        startIndex,
      });
    },
    [],
  );

  const handleGalleryClose = useCallback(() => {
    setGallery(undefined);
  }, []);

  return {
    handleGalleryClose,
    handleGalleryThumbnailsClick,
    gallery,
  };
}

export default useImageGalleryController;
export type UseImageGalleryControllerHookObj = ReturnType<
  typeof useImageGalleryController
>;
