import { nanoid } from '@reduxjs/toolkit';
import { fromUnixTime } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import tw from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { TrackingDataType } from '../../../model/TrackingOrder.model';
import { getFullDateTimeFormat } from '../../../util/date.util';
import {
  getSOTimelineCustomGalleryTitle,
  getTimelinePhotos,
  getTimelineTitle,
  getTimelineTitleReplacements,
  remapHOTimelines,
  remapSOTimelines,
} from '../../../util/tracking/trackingTimeline.util';
import { Text } from '../../atom';
import TimelineContentItem from '../TimelineContentItem/TimelineContentItem.molecule';
import {
  OnTimelineContentImageClick,
  TimelineContentModifiedPhoto,
} from '../TimelineContentItem/TimelineContentItem.type';
import TimelineHeaderItem from '../TimelineHeaderItem/TimelineHeaderItem.molecule';

//#region COMPONENTS
const Container = tw.div``;
const ContentContainer = tw.div`flex flex-col mt-5`;
const Divider = tw.div`my-1`;
const Group = tw.div``;
const TitleContainer = tw.div``;
const SubtitleContainer = tw.div`flex flex-col`;
const HeaderContainer = tw.div`grid grid-cols-2 gap-4`;
const TimelineContainer = tw.div``;
const TimelineHeaderContainer = tw.div`mb-2`;
const TimelineContentContainer = tw.div`border border-beige-lines px-5 py-4 rounded-md whitespace-normal`;
//#endregion

type Props = {
  translator: UseTranslator;
  data: TrackingDataType;
  forceLang?: SupportedLanguage;
  onImageClick?: OnTimelineContentImageClick;
};

function TrackingTimeline({
  translator,
  data,
  onImageClick,
  forceLang,
}: Props) {
  const timelines = useMemo(() => {
    if (data.type === 'so' && data.timelines) {
      return remapSOTimelines(data.timelines);
    }
    if (data.type === 'ho' && data.timeline) {
      return remapHOTimelines(data.timeline);
    }

    return [];
  }, [data]);

  const hasLocation = useMemo(() => {
    if (data.type === 'so') {
      return data.pickupLocation.length + data.dropoffLocation.length > 0;
    }
    return !!data.pickupLocationName;
  }, [data]);

  const orgNameText = useMemo(() => {
    if (data.type === 'so') return data.orgName;
    return data.organizationName;
  }, [data]);

  const timlineTitleLabel = useMemo(() => {
    return translator.translate(
      data.type === 'so' ? 'Timeline' : 'Trip History',
      forceLang,
    );
  }, [translator, forceLang, data]);

  const onContentItemImageClick = useCallback<OnTimelineContentImageClick>(
    (
      photos: TimelineContentModifiedPhoto[],
      clickedIndex: number,
      customTitles?: string[],
    ) => {
      if (!onImageClick) return;
      if (data.type === 'so') {
        onImageClick(
          photos,
          clickedIndex,
          customTitles?.map(
            getSOTimelineCustomGalleryTitle({
              translator,
              forceLang,
              activities: data.activities,
            }),
          ),
        );
      } else {
        onImageClick(photos, clickedIndex);
      }
    },
    [translator, onImageClick, forceLang, data],
  );

  const renderHeader = useCallback(() => {
    const pickupLocations =
      data.type === 'so'
        ? data.pickupLocation.map(({ name }) => ({ name }))
        : [{ name: data.pickupLocationName }];
    const dropoffLocations =
      data.type === 'so'
        ? data.dropoffLocation.map(({ name }) => ({ name }))
        : data.deliveries.map(({ location }) => ({ name: location.name }));
    return (
      <>
        {pickupLocations.length > 0 && (
          <>
            <TimelineHeaderItem
              forceLang={forceLang}
              title={translator.translate(
                'Pickup Location',
                forceLang || undefined,
              )}
              values={pickupLocations.map((pl) => pl.name)}
              showFootprint={dropoffLocations.length > 0}
              isLastItem={!(dropoffLocations.length > 0)}
              translator={translator}
              isPickup
            />
            <Divider />
          </>
        )}
        {dropoffLocations.length > 0 && (
          <>
            <TimelineHeaderItem
              forceLang={forceLang}
              title={translator.translate(
                'Dropoff Location',
                forceLang || undefined,
              )}
              values={dropoffLocations.map((pl) => pl.name)}
              isLastItem={!(pickupLocations.length > 0)}
              translator={translator}
            />
            <Divider />
          </>
        )}
      </>
    );
  }, [data, forceLang, translator]);

  const renderContent = useCallback(
    () => (
      <TimelineContainer>
        <TimelineHeaderContainer>
          <Text.HeadingFive>{timlineTitleLabel}</Text.HeadingFive>
        </TimelineHeaderContainer>
        <TimelineContentContainer>
          {timelines.map((timeline, i) => {
            const timelinePlaceholderReplacements =
              getTimelineTitleReplacements({ data, timeline });
            const timelinePhotos = getTimelinePhotos({ data, timeline });
            const timelineTitle = getTimelineTitle({
              data,
              timeline,
              translator,
              forceLang,
              replacementWords:
                data.type === 'so'
                  ? [timelinePlaceholderReplacements as string]
                  : timelinePlaceholderReplacements,
            });

            return (
              <React.Fragment key={nanoid()}>
                <TimelineContentItem
                  title={timelineTitle}
                  value={getFullDateTimeFormat(
                    fromUnixTime(timeline.time),
                    true,
                  )}
                  isLastItem={i === timelines.length - 1}
                  showFootprint={i < timelines.length - 1}
                  isFirstItem={i === 0}
                  onImageClick={onContentItemImageClick}
                  data={timelinePhotos}
                />
                <Divider />
              </React.Fragment>
            );
          })}
        </TimelineContentContainer>
      </TimelineContainer>
    ),
    [
      translator,
      forceLang,
      timelines,
      onContentItemImageClick,
      data,
      timlineTitleLabel,
    ],
  );

  return (
    <Container>
      <HeaderContainer>
        {hasLocation && (
          <Group>
            <TitleContainer>
              <Text.HeadingFive>
                {translator.translate('Destination', forceLang || undefined)}
              </Text.HeadingFive>
            </TitleContainer>
            <SubtitleContainer>{renderHeader()}</SubtitleContainer>
          </Group>
        )}

        <Group>
          <TitleContainer>
            <Text.HeadingFive>
              {translator.translate('Organization', forceLang || undefined)}
            </Text.HeadingFive>
          </TitleContainer>
          <SubtitleContainer>
            <Text.BodyFourteen tw="text-grey-two whitespace-nowrap overflow-hidden overflow-ellipsis w-full">
              {orgNameText}
            </Text.BodyFourteen>
          </SubtitleContainer>
        </Group>
      </HeaderContainer>
      {timelines.length > 0 && (
        <ContentContainer>{renderContent()}</ContentContainer>
      )}
    </Container>
  );
}

export default TrackingTimeline;
