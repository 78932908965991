import tw, { styled } from 'twin.macro';

const ContentContainer = styled.div<{ fullWidth?: boolean }>`
  ${tw`py-10 mx-auto`}
  ${({ fullWidth }) => (fullWidth ? tw`px-4` : '')}
  @media (max-width: 854px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (min-width: 855px) {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '800px')};
  }
  @media (min-width: 1055px) {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '1000px')};
  }
  @media (min-width: 1255px) {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '1200px')};
  }
  @media (min-width: 1555px) {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '1500px')};
  }
`;

export default ContentContainer;
