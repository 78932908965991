import { Shipper } from '../model/Shipper.model';
import api from '../service/api.service';
import useFetchMoreAutocomplete, {
  AutocompleteOptionsData,
} from './useFetchMoreAutocomplete.hook';

const useGetShippersAutocomplete = (onlyShowActiveData = false) => {
  const shipperListOption: AutocompleteOptionsData<Shipper> = {
    dataKey: 'shippers',
    labelKey: 'name',
    valueKey: 'id',
  };

  const {
    dataOptions: shipperOptions,
    handleChangeAutotext: onChangeShipperAutotext,
    handleFetchMore: onFetchMoreShipper,
    isFetching: shipperListFetchLoading,
  } = useFetchMoreAutocomplete({
    options: shipperListOption,
    api: onlyShowActiveData
      ? api.useLazyGetActiveShippersQuery
      : api.useLazyGetShippersQuery,
  });

  return {
    shipperOptions,
    shipperListFetchLoading,
    onChangeShipperAutotext,
    onFetchMoreShipper,
  };
};

export type UseGetShippersAutocompleteObj = ReturnType<
  typeof useGetShippersAutocomplete
>;
export default useGetShippersAutocomplete;
