import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../config/router/router.config';
import { helpCenterDriverAppAddExpenseRoute } from './HelpCenterDriverAppAddExpense/HelpCenterDriverAppAddExpense.route';
import { helpCenterDriverAppCompleteHJORoute } from './HelpCenterDriverAppCompleteHJO/HelpCenterDriverAppCompleteHJO.route';
import { helpCenterDriverAppCompleteJORoute } from './HelpCenterDriverAppCompleteJO/HelpCenterDriverAppCompleteJO.route';
import { helpCenterDriverAppPerformanceReportRoute } from './HelpCenterDriverAppPerformanceReport/HelpCenterDriverAppPerformanceReport.route';

export type HelpCenterRouteParam = DefaultRouteParam;

export const helpCenterDriverAppRoute: RouteConfig = {
  name: 'Aplikasi Sopir',
  Component: React.lazy(() => import('./HelpCenterDriverApp.view')),
  path: '/help-center/driver-app',
  isPrivate: false,
  breadcrumb: 'Aplikasi Sopir',
  children: [
    {
      ...helpCenterDriverAppAddExpenseRoute,
      path: 'add-expense',
    },
    {
      ...helpCenterDriverAppCompleteHJORoute,
      path: 'complete-hjo',
    },
    {
      ...helpCenterDriverAppCompleteJORoute,
      path: 'complete-jo',
    },
    {
      ...helpCenterDriverAppPerformanceReportRoute,
      path: 'performance-report',
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    label: 'Aplikasi Sopir',
    testID: 'MenuHelpCenterDriverApp',
    hasDivider: true,
    hasSub: [
      helpCenterDriverAppAddExpenseRoute,
      helpCenterDriverAppCompleteHJORoute,
      helpCenterDriverAppCompleteJORoute,
      helpCenterDriverAppPerformanceReportRoute,
    ],
  },
};
