import { nanoid } from '@reduxjs/toolkit';
import React, { ReactElement } from 'react';
import tw, { theme } from 'twin.macro';
import { Icon } from '../../atom';
import { ExtraStyle } from '../../Type.component';

const Container = tw.div`p-2.5 border rounded-lg border-status-fuel-anomaly bg-blue-five flex gap-2 whitespace-pre-wrap`;
const IconContainer = tw.div`h-[24px] flex items-center mt-[-1px]`;
const DescriptionContainer = tw.div`flex flex-col`;
const RuleList = tw.ul`ml-4`;
const RuleItem = tw.li`list-disc`;

type Props = {
  title?: ReactElement;
  rules?: ReactElement[];
  description?: ReactElement;
  rootStyle?: ExtraStyle;
  icon?: ReactElement;
};

export default function InfoAlert({
  title,
  rules,
  icon = <Icon.InfoOutlined fillPath={theme`colors.status.fuel-anomaly`} />,
  description,
  rootStyle,
}: Props) {
  return (
    <Container css={rootStyle}>
      <IconContainer>{icon}</IconContainer>
      <DescriptionContainer>
        {title}
        {rules?.length && (
          <RuleList>
            {rules?.map((item) => (
              <RuleItem key={`rule-item-${nanoid()}`}>{item}</RuleItem>
            ))}
          </RuleList>
        )}
        {description}
      </DescriptionContainer>
    </Container>
  );
}
