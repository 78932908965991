import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import { ErrorCodes, SOActivityListData } from '../../../constant';
import useGoodsForm from '../../../hook/useGoodsForm.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { Goods, ShipperOrderInfo } from '../../../model/ShipperOrder.model';
import {
  ApiErrorResponse,
  CommonApiResponse,
} from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { snackbarAction } from '../../../store/snackbar.store';
import { errorCodeToLabel } from '../../../util/error.util';
import { isWithin72Hours } from '../../../util/shipperOrderDetail.util';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';

type Props = {
  soInfo?: ShipperOrderInfo;
  activityFormData?: SOActivityListData[];
};
export type UseSODetailEditGoodsForm = ReturnType<
  typeof useSODetailEditGoodsForm
>;

export default function useSODetailEditGoodsForm({ soInfo }: Props) {
  // #region GENERAL
  const translator = useTranslator();
  const dispatch = useDispatch();
  const { handleVerifyAuth } = useVerifyAuth();

  const [selectedActivityIndex, setSelectedActivityIndex] = useState<
    number | undefined
  >(undefined);
  const [isGoodsDeadlineExceeded, setIsGoodsDeadlineExceeded] = useState(false);

  const prev = useMemo(() => soInfo?.activities, [soInfo]);

  const selectedActivity = useMemo(
    () => prev?.find((_, i) => i === selectedActivityIndex),
    [prev, selectedActivityIndex],
  );
  const isEditable = useMemo(() => {
    if (!soInfo) return false;
    if (!soInfo.completedAt) return true;
    return isWithin72Hours(soInfo.completedAt);
  }, [soInfo]);

  const prevGoods = useMemo(() => {
    if (!selectedActivity) return undefined;

    return selectedActivity.soActivity?.goods;
  }, [selectedActivity]);

  // #endregion

  // #region API CALL
  const [goodsUpdate, goodsUpdateResult] = api.useGoodsUpdateMutation();

  // #endregion

  // #region ACTION HANDLER
  const handleError = useCallback(
    (error: FetchBaseQueryError | SerializedError) => {
      if (!error) return;
      const data = (error as FetchBaseQueryError).data as ApiErrorResponse;
      setIsGoodsDeadlineExceeded(
        (v) => v || data.error.code === ErrorCodes.SO_GOODS_DEADLINE_EXCEEDED,
      );
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.dark,
          message: translator.translate(errorCodeToLabel(data.error.code)),
        }),
      );
    },
    [dispatch, translator],
  );
  const handleSuccess = useCallback(
    (response: CommonApiResponse, hasGoodsOut?: boolean) => {
      if (!response || (response && !response.ok)) return;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.dark,
          message: translator.translate(
            hasGoodsOut
              ? 'Successfully edited Delivered by Driver'
              : 'Successfully added Delivered by Driver',
          ),
        }),
      );
    },
    [dispatch, translator],
  );
  // #endregion

  // #region ACTION
  const handleResetActivityIndex = useCallback(() => {
    setSelectedActivityIndex(undefined);
  }, []);

  // #endregion

  // #region FORM
  const handleSubmitForm = useCallback(
    async (values: Goods[]) => {
      const authRes = await handleVerifyAuth();
      if (!authRes) return;
      if (!soInfo || typeof selectedActivityIndex !== 'number') return false;
      const soId = soInfo.id;
      const soActivityId = soInfo?.activities[selectedActivityIndex].id;
      const hasGoodsOut =
        !!soInfo?.activities[selectedActivityIndex].soActivity?.goodsOut
          ?.length;
      try {
        const res = await goodsUpdate({
          soId,
          soActivityId,
          goods: values,
        }).unwrap();
        handleResetActivityIndex();
        handleSuccess(res, hasGoodsOut);
        return true;
      } catch (error) {
        handleError(error as FetchBaseQueryError);
        return true;
      } finally {
        goodsUpdateResult.reset();
      }
    },
    [
      goodsUpdate,
      handleVerifyAuth,
      goodsUpdateResult,
      handleError,
      handleSuccess,
      handleResetActivityIndex,
      selectedActivityIndex,
      soInfo,
    ],
  );

  const goodsForm = useGoodsForm({
    translator,
    prevGoods,
    onSubmit: handleSubmitForm,
  });
  // #endregion

  return {
    isGoodsDeadlineExceeded,
    isEditable,
    goodsForm,
    selectedActivity,
    selectedActivityIndex,
    setSelectedActivityIndex,
    handleResetActivityIndex,
  };
}
