import React from 'react';
import tw from 'twin.macro';
import { UseJODetailSheetHookObj } from '../../../hook/jobOrderDetail/useJODetailSheet.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { Chip, Icon, LoadingIndicator } from '../../atom';
import { BodyFourteen } from '../../atom/Text/Text.atom';
import JODetailMobileContent from '../JODetailMobileContent/JODetailMobileContent.organism';
import JOSODetailMobileActivityPOD from '../JOSODetailMobileActivityPOD/JOSODetailMobileActivityPOD.organism';
import JOSODetailMobileContent from '../JOSODetailMobileContent/JOSODetailMobileContent.organism';

// #region STYLED
const TabContainer = tw.div`flex gap-2 pt-4 px-4 pb-2`;
const ContentWrapper = tw.div`pt-2 pb-4 px-4`;
const AnnounceWrapper = tw.div`flex items-center bg-orange-hover p-3 mt-4 rounded-md mx-4`;
const LoadingContainer = tw.div`self-center flex h-[80vh] items-center`;
// #endregion

// #region INTERFACES

type Props = {
  translator: UseTranslator;
  joDetailSheet: UseJODetailSheetHookObj;
  isLoading?: boolean;
};

type AnnounceProps = {
  translator: UseTranslator;
};

// #endregion

function JODetailDraftAnnounce({ translator }: AnnounceProps) {
  return (
    <AnnounceWrapper>
      <div tw="pr-3">
        <Icon.InfoOutlined />
      </div>
      <div>
        <BodyFourteen>
          {translator.translate(
            'Job Order with “Draft” status is limited to view only mode in mobile device. Go to desktop version to edit this Job Order.',
          )}
        </BodyFourteen>
      </div>
    </AnnounceWrapper>
  );
}

export default function JODetailMobileContentWrapper({
  translator,
  joDetailSheet,
  isLoading,
}: Props) {
  if (isLoading)
    return (
      <LoadingContainer>
        <LoadingIndicator size="small" />
      </LoadingContainer>
    );
  if (
    joDetailSheet.renderedSelectedSOActivity &&
    joDetailSheet.selectedActivityId
  )
    return (
      <JOSODetailMobileActivityPOD
        selectedActivityId={joDetailSheet.selectedActivityId}
        renderedSelectedSOActivity={joDetailSheet.renderedSelectedSOActivity}
        handleChangePhoto={joDetailSheet.handleChangePhoto}
      />
    );
  if (joDetailSheet.renderedSelectedSO)
    return (
      <JOSODetailMobileContent
        translator={translator}
        selectedSO={joDetailSheet.renderedSelectedSO}
        handleClickActivityPhoto={joDetailSheet.handleClickActivityPhoto}
      />
    );

  return (
    <>
      {joDetailSheet.isJODraft && (
        <JODetailDraftAnnounce translator={translator} />
      )}
      <TabContainer>
        {joDetailSheet.contentMenu.map((item) => (
          <Chip.Regular
            key={item.label}
            label={item.label}
            testID={item.testID}
            onClick={item.onClick}
            selected={item.selected}
          />
        ))}
      </TabContainer>
      <ContentWrapper>
        <JODetailMobileContent
          translator={translator}
          soListContentData={joDetailSheet.soListContentData}
          joContentData={joDetailSheet.joContentData}
          showContent={joDetailSheet.showContent}
        />
      </ContentWrapper>
    </>
  );
}
