// #region IMPORT
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import { Builder, QD } from '../../api.baseQuery';
import { CommonApiMetadata, CommonApiResponse } from '../../api.endpoint';
// #endregion

// #region INTERFACES
export type RunnerEvent = {
  name: string;
  description: string;
  start: number;
  end: number;
};

export type GetRunnerEventListParams = {
  start: number;
  end: number;
  page?: number;
  pageSize?: number;
};

export type GetRunnerEventListResponse = CommonApiResponse &
  CommonApiMetadata & { runnerEvent: RunnerEvent[] };

export type RunnerEventEndpoint = {
  getRunnerEventList: QD<GetRunnerEventListParams, GetRunnerEventListResponse>;
};
// #endregion

// #region ENDPOINT
export const runnerEventTagTypes: EndpointTagTypes<RunnerEventEndpoint> = {
  getRunnerEventList: 'runnerEvent',
};

export const runnerEventEndpoint = (builder: Builder): RunnerEventEndpoint => ({
  getRunnerEventList: builder.query({
    query: (params) => ({
      url: urlcat('/v1/runner-events.list', params),
    }),
  }),
});
// #endregion
