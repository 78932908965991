import useRunnerEventList from '../../hook/runnerEvent/useRunnerEventList.hook';
import useTranslator from '../../hook/useTranslator.hook';
import useDrivingContestRegisterConfirmation from './hook/useDrivingContestRegisterConfirmation.hook';
import useDrivingContestRegisterConfirmationTable from './hook/useDrivingContestRegisterConfirmationTable.hook';

export default function useDrivingContestRegisterConfirmationViewModel() {
  const translator = useTranslator();
  const runnerEvent = useRunnerEventList();

  const logic = useDrivingContestRegisterConfirmation();
  const table = useDrivingContestRegisterConfirmationTable({
    translator,
    logic,
  });

  return {
    translator,
    logic,
    table,
    runnerEvent,
  };
}
