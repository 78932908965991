import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../component/molecule';
import { BulkActionEntity, BulkActionStatusEnum } from '../../constant';
import { ErrorDetail } from '../../service/api.endpoint';
import { mapBulkActionStatusToLabel } from '../../util/helper.util';
import { mapShipperErrorMessages } from '../../util/shipper/shipper.util';
import { ShipperAddBulk } from '../../view/ShipperAddBulk/ShipperAddBulk.route';
import useTranslator from '../useTranslator.hook';
import { UseShipperAddBulkLogic } from './useShipperAddBulkLogic.hook';

// #region INTERFACES
export type AddBulkShipperEntity = BulkActionEntity<ShipperAddBulk>;
export type Props = {
  bulkLogic: UseShipperAddBulkLogic;
};
// #endregion

export default function useShipperAddBulkTable({
  bulkLogic: { shippersWithStatusDetail, showStatus },
}: Props) {
  const translator = useTranslator();

  const columns = useMemo<ColumnDef<AddBulkShipperEntity>[]>(
    () => [
      {
        size: 200,
        header: translator.translate('Shipper Name'),
        accessorKey: 'Nama Pengirim',
        cell: ({ getValue }) => {
          const fullName = getValue() as string;

          return (
            <CustomTableRowWithPopover
              primaryLabel={fullName ?? '-'}
              containerStyle={tw`max-w-[200px]`}
            />
          );
        },
      },
      {
        size: 150,
        header: translator.translate('Phone Number'),
        accessorKey: 'Nomor Telepon Pengirim',
        cell: ({ getValue }) => {
          const phoneNumber = getValue() as string;
          return (
            <CustomTableRowWithPopover
              primaryLabel={phoneNumber ?? '-'}
              containerStyle={tw`max-w-[150px]`}
            />
          );
        },
      },
      {
        size: 150,
        header: translator.translate('Email'),
        accessorKey: 'Alamat Email',
        cell: ({ getValue }) => {
          const email = getValue() as string;
          return (
            <CustomTableRowWithPopover
              primaryLabel={email ?? '-'}
              containerStyle={tw`max-w-[150px]`}
            />
          );
        },
      },
      ...(showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({
                getValue,
              }: { getValue: () => string | ErrorDetail[] }) => {
                const accessor = getValue();
                const label =
                  typeof accessor === 'string'
                    ? translator.translate(accessor)
                    : mapShipperErrorMessages(translator, accessor);

                return (
                  <CustomTableRowWithPopover
                    primaryLabel={label}
                    containerStyle={tw`max-w-[290px]`}
                  />
                );
              },
            },
          ]
        : []),
    ],
    [showStatus, translator],
  );

  const table = useReactTable({
    data: shippersWithStatusDetail,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
