// #region IMPORT
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  GetVehicleApiRequest,
  GetVehicleApiResponse,
  GetVehicleUnassignedApiRequest,
  GetVehicleUnassignedApiResponse,
  VehicleLastPosition,
} from '../../../model/Vehicle.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, QD } from '../../api.baseQuery';
import { CommonApiResponse } from '../../api.endpoint';
// #endregion

// #region INTERFACES
export type VehicleEndpoint = {
  getVehicleList: QD<GetVehicleApiRequest, GetVehicleApiResponse>;
  getVehicleUnassignedList: QD<
    GetVehicleUnassignedApiRequest,
    GetVehicleUnassignedApiResponse
  >;
  getVehicleLastPosition: QD<
    { vehicleId: string },
    CommonApiResponse & { lastPosition: VehicleLastPosition | null }
  >;
};
// #endregion

// #region ENDPOINT
export const vehicleTagTypes: EndpointTagTypes<VehicleEndpoint> = {
  getVehicleList: '',
  getVehicleUnassignedList: '',
  getVehicleLastPosition: '',
};

export const vehicleEndpoint = (builder: Builder): VehicleEndpoint => ({
  getVehicleList: builder.query({
    query: (param) => urlcat('/v1/vehicles.list', toSnakeCase(param)),
  }),
  getVehicleUnassignedList: builder.query({
    query: (param) =>
      urlcat('/v1/vehicles.unassigned.list', toSnakeCase(param)),
  }),
  getVehicleLastPosition: builder.query({
    query: (param) => ({
      url: urlcat('/v1/vehicles.last-position', toSnakeCase(param)),
    }),
  }),
});
// #endregion
