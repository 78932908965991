import React from 'react';
import tw, { styled, theme } from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { FormItem } from '../../../types/input.type';
import { ErrorFormContainer, LabelInput, Row } from '../../atom';
import { Search } from '../../atom/Icon/Icon.atom';
import CircleRadioSelected from '../../atom/Icon/SVG/CircleRadioSelected';
import { HeadingFour } from '../../atom/Text/Text.atom';
import { TextField } from '../../molecule';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';

const Container = tw.div``;
const Circle = styled.div(({ isSelected }: { isSelected?: boolean }) => [
  tw`border-[3px] rounded-3xl border-grey-two w-5 h-5 mr-1 flex items-center`,
  isSelected && tw`border-orange`,
]);
const ContainerForm = tw.div`w-[calc(100% - 200px)] flex flex-col gap-2`;
const SelectionFormContainer = tw.div`flex gap-4 mb-3`;
const AssignmentSelectionStyle = tw``;
const ScheduleContainer = tw.div``;

const ItemContainer = styled.div(({ isSelected }: { isSelected?: boolean }) => [
  tw`flex flex-1 px-4 py-[9px] border border-beige-lines items-center rounded gap-2 cursor-pointer hover:(border-orange-hover bg-orange-hover)`,
  isSelected && tw`border-orange-hover bg-orange-hover`,
]);

type Props = {
  isEdit?: boolean;
  formData: FormItem[];
  selectedSchedule?: string;
  isUseAssignment?: boolean;
  isScheduleFieldDisplayed?: boolean;
  isVehicleDriverFormDisplayed?: boolean;
  isErrorVisible?: boolean;
  handleSelectAssignmentType: (val: boolean) => void;
  handleClickSelectionSchedule: () => void;
};

type SelectionItemProps = {
  title: string;
  isSelected?: boolean;
  handleClick: () => void;
};

function SelectionItem({ title, isSelected, handleClick }: SelectionItemProps) {
  return (
    <ItemContainer isSelected={isSelected} onClick={handleClick}>
      <Circle isSelected={isSelected}>
        {isSelected && (
          <CircleRadioSelected
            innerCircleR="5.25"
            strokeWidth={2}
            stroke={theme`colors.orange`}
          />
        )}
      </Circle>
      <HeadingFour>{title}</HeadingFour>
    </ItemContainer>
  );
}

export default function JobOrderHeaderAssignmentForm({
  isEdit,
  formData,
  isUseAssignment,
  selectedSchedule,
  isVehicleDriverFormDisplayed,
  isScheduleFieldDisplayed,
  isErrorVisible = true,
  handleSelectAssignmentType,
  handleClickSelectionSchedule,
}: Props) {
  const { translate } = useTranslator();
  return (
    <Container>
      <Row>
        <LabelInput label={translate('Select Driver & Vehicle')} isRequired />
        <ContainerForm>
          <SelectionFormContainer>
            <SelectionItem
              title={translate('Select by Schedule')}
              isSelected={!!isUseAssignment}
              handleClick={() => handleSelectAssignmentType(true)}
            />
            <SelectionItem
              title={translate('Select Manually')}
              isSelected={!isUseAssignment}
              handleClick={() => handleSelectAssignmentType(false)}
            />
          </SelectionFormContainer>
          {!!isUseAssignment && (
            <ScheduleContainer>
              <TextField
                readOnly
                containerStyle={AssignmentSelectionStyle}
                placeholder={translate('Search Schedule')}
                id="assignment-selection"
                left={<Search fill={theme`colors.neutral.600`} />}
                name="assignment-selection"
                onClick={handleClickSelectionSchedule}
              />
              {isErrorVisible && !selectedSchedule && !isEdit && (
                <ErrorFormContainer>
                  {translate('Schedule is required.')}
                </ErrorFormContainer>
              )}
            </ScheduleContainer>
          )}
          {isVehicleDriverFormDisplayed &&
            formData.map((item) => (
              <ComposedFormField
                {...item}
                key={`JO-header-assignment-form-${item.id}`}
              />
            ))}
          {!!isUseAssignment && isScheduleFieldDisplayed && (
            <TextField
              readOnly
              disabled
              label={translate('Schedule')}
              containerStyle={AssignmentSelectionStyle}
              placeholder={translate('Schedule')}
              id="schedule-id"
              name="schedule-id"
              value={selectedSchedule ?? '-'}
            />
          )}
        </ContainerForm>
      </Row>
    </Container>
  );
}
