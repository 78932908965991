import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  JOAssignmentFormInitialValues,
  JOFormStep,
} from '../../constant/JobOrder.constant';
import { StepperItem } from '../../model/Common.model';
import {
  JobOrderForm,
  JOScheduleAssignmentType,
} from '../../model/JobOrder.model';
import { isFormChanged } from '../../util/helper.util';
import {
  JOFormValidationSchema,
  joInitialValues,
} from '../../util/jobOrderCreate.util';
import useStateWithCallbackLazy from '../useStateWithCallbackLazy';
import useJOFormAction from './useJOFormAction.hook';
import useJOFormAssignment from './useJOFormAssignment.hook';
import useJOFormBlocker from './useJOFormBlocker.hook';
import { UseJOFormControllerHookObj } from './useJOFormController.hook';
import useJOFormDeliveryLocationModal from './useJOFormDeliveryLocationModal.hook';
import useJOFormHeader from './useJOFormHeader.hook';
import { UseJOFormInitializationHookObj } from './useJOFormInitialization.hook';
import useJOFormSOCandidate from './useJOFormSOCandidate.hook';
import useJOFormSORenderRow from './useJOFormSORenderRow.hook';
import useJOFormSOSelection from './useJOFormSOSelection.hook';

type Props = {
  initialization: UseJOFormInitializationHookObj;
  controller: UseJOFormControllerHookObj;
};

export default function useJOFormState({ initialization, controller }: Props) {
  const [hasUnsavedChanges, setHasUnsavedChanges] =
    useStateWithCallbackLazy(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSOCandidateFormDisplayed, setIsSOCandidateFormDisplayed] =
    useState(false);

  const [vehicleDriverTempValue, setVehicleDriverTempValue] =
    useState<JOScheduleAssignmentType>(JOAssignmentFormInitialValues);

  useEffect(() => {
    if (controller.formStep !== JOFormStep.SO_SELECTION) {
      setIsSOCandidateFormDisplayed(false);
    }
  }, [controller.formStep]);

  // #region FORMIK
  const joFormik = useFormik<JobOrderForm>({
    initialValues: initialization.initValues,
    onSubmit: () => {},
    isInitialValid: false,
    enableReinitialize: initialization.isFormInitialValid,
    validationSchema: JOFormValidationSchema({
      isEdit: !!initialization.joInfo?.id,
      initialValues: initialization.initValues,
    }),
  });

  // #endregion

  const headerForm = useJOFormHeader({
    number: initialization?.joInfo?.number,
    joFormik,
    controller,
    previousValue: initialization.infoInitValues,
  });

  const locationModalController = useJOFormDeliveryLocationModal({
    joId: initialization?.joInfo?.id,
    joNumber: initialization.joInfo?.number,
    values: joFormik.values,
    controller,
    hasUnsavedChanges,
    setHasUnsavedChanges,
  });

  const { formatRenderRows } = useJOFormSORenderRow({
    values: joFormik.values,
    controller,
    locationModalController,
  });

  const soSelectionForm = useJOFormSOSelection({
    joFormik,
    controller,
    locationModalController,
    formatRenderRows,
    setVehicleDriverTempValue,
    setIsSOCandidateFormDisplayed,
  });

  const soCandidateForm = useJOFormSOCandidate({
    joFormik,
    initialization,
    controller,
    formatRenderRows,
    setVehicleDriverTempValue,
    handleBackToSelection: () => setIsSOCandidateFormDisplayed(false),
  });

  const assignmentForm = useJOFormAssignment({
    joFormik,
    controller,
    hasUnsavedChanges,
    vehicleDriverTempValue,
    initialization,
    setVehicleDriverTempValue,
    setHasUnsavedChanges,
  });

  //#region VALIDATOR
  const handleValidate = useCallback(async () => {
    const isFormHasErrors = !isEmpty(
      await joFormik.validateForm(joFormik.values),
    );
    const isValid = !isFormHasErrors && !soSelectionForm.isDisabled;
    if (isFormHasErrors) {
      assignmentForm.setIsErrorVisible(true);
    }
    joFormik.setTouched({
      driverId: true,
      vehicleId: true,
      travelExpenses: true,
    });

    return isValid;
  }, [joFormik, assignmentForm, soSelectionForm]);
  //#endregion

  const actionForm = useJOFormAction({
    joFormik,
    hasUnsavedChanges,
    number: initialization.joInfo?.number,
    selectionSOList: controller.soController.selectionSOList,
    setIsLoading,
    setHasUnsavedChanges,
    handleBack: controller.handleBackHeader,
    setSelectionSOList: controller.soController.setSelectionSOList,
    handleValidate,
  });

  // #region BLOCKER

  useEffect(() => {
    const initFormValues = initialization?.joInfo?.id
      ? initialization.infoInitValues
      : joInitialValues;
    setHasUnsavedChanges(
      isFormChanged<JobOrderForm>(joFormik.values, initFormValues),
      () => {},
    );
  }, [
    initialization?.joInfo?.id,
    initialization?.infoInitValues,
    joFormik.values,
    setHasUnsavedChanges,
  ]);

  const blocker = useJOFormBlocker({
    isFormValid: joFormik.isValid || soSelectionForm.isDisabled,
    setHasUnsavedChanges,
    redirectTo: controller.handleBackHeader,
    handleSubmit: () => actionForm.handleSubmitForm({ isDraft: true }),
  });
  // #endregion

  const steps = useMemo(
    (): StepperItem[] => [
      {
        label: JOFormStep.HEADER,
      },
      {
        label: JOFormStep.SO_SELECTION,
        isValid: !soSelectionForm.isDisabled,
      },
      {
        label: JOFormStep.ASSIGNMENT,
      },
    ],
    [soSelectionForm.isDisabled],
  );

  return {
    joId: initialization?.joInfo?.id,
    steps,
    blocker,
    joFormik,
    isLoading,
    headerForm,
    actionForm,
    assignmentForm,
    soSelectionForm,
    soCandidateForm,
    hasUnsavedChanges,
    locationModalController,
    isSOCandidateFormDisplayed,
    setHasUnsavedChanges,
    handleValidate,
  };
  // #endregion
}
export type UseJOFormStateObj = ReturnType<typeof useJOFormState>;
