import React from 'react';
import tw, { styled } from 'twin.macro';
import { HelpCenterItemProps } from '../../../constant/HelpCenter.constant';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import { Image } from '../../atom';
import { BodyFourteen } from '../../atom/Text/Text.atom';
import { InfoAlert } from '../../organism';

const Container = tw.div`flex flex-col gap-1`;
const Index = tw(BodyFourteen)`ml-2`;
const Content = tw.div`ml-6 flex flex-col gap-4`;
const DescriptionContainer = tw(BodyFourteen)`whitespace-pre-wrap`;
const ImageSection = styled.div(
  ({ isImageContainerFlexCol }: { isImageContainerFlexCol?: boolean }) => [
    tw`flex gap-4 `,
    isImageContainerFlexCol && tw`flex-col`,
  ],
);
const ImageContainer = styled(Image)(
  ({
    isMobile,
    isImageContainerFlexCol,
  }: { isMobile?: boolean; isImageContainerFlexCol?: boolean }) => [
    tw`flex flex-1 max-h-[360px] border border-beige-lines rounded object-contain bg-neutral-50`,
    isMobile && tw`max-h-[171px] w-2.5`,
    isImageContainerFlexCol && tw`w-full`,
  ],
);
const LabelContainer = tw.div`flex gap-2`;

type Props = HelpCenterItemProps & {
  idx: number;
  testID?: string;
};

export default function HelpCenterDataLayout({
  idx,
  testID,
  description,
  alert,
  isImageContainerFlexCol,
  images,
}: Props) {
  const { isMobile } = useScreenDetection();
  return (
    <Container>
      <LabelContainer>
        <Index>{idx}. </Index>
        <DescriptionContainer>{description}</DescriptionContainer>
      </LabelContainer>
      <Content>
        {alert && (!alert?.position || alert?.position === 'start') && (
          <InfoAlert
            rootStyle={alert.style}
            title={alert?.title}
            description={alert.description}
            rules={alert?.ruleItems}
          />
        )}
        {!!images?.length && (
          <ImageSection isImageContainerFlexCol={isImageContainerFlexCol}>
            {images.map((item, index) => (
              <ImageContainer
                isImageContainerFlexCol={isImageContainerFlexCol}
                onClick={() => item.onClick?.(item.url)}
                isMobile={isMobile}
                css={item.style}
                key={`help-center-image-data-layout-${testID || ''}-${
                  item.url || index
                }`}
                src={item.url}
              />
            ))}
          </ImageSection>
        )}

        {alert && alert?.position === 'end' && (
          <InfoAlert
            rootStyle={alert.style}
            title={alert?.title}
            description={alert?.description}
            rules={alert?.ruleItems}
          />
        )}
      </Content>
    </Container>
  );
}
