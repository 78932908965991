import React from 'react';
import tw, { TwStyle } from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { Text } from '../../atom';
import Tooltip, { TooltipProps } from '../Tooltip/Tooltip.molecule';

const componentId = 'ExportListPopup';
const testIds = {
  button: {
    downloadAll: `${componentId}:Button:DownloadAll`,
    downloadCurrent: `${componentId}:Button:DownloadCurrent`,
  },
  label: {
    downloadAll: `${componentId}:Label:DownloadAll`,
    downloadCurrent: `${componentId}:Label:DownloadCurrent`,
  },
} as const;

// #region STYLED
const DownloadButton = tw.button`py-1.5 text-left hover:bg-orange-hover disabled:bg-white disabled:cursor-not-allowed`;
const DownloadLabel = tw(Text.HeadingFive)`w-full flex px-6 text-grey-two`;
// #endregion

// #region INTERFACES
type ExportListPopupProps = Pick<
  TooltipProps,
  'children' | 'visible' | 'targetRef'
> & {
  isLoading: boolean;
  contentStyle?: TwStyle;
  onClickExportAllPages: () => Promise<void>;
  onClickExportCurrentPage: () => Promise<void>;
};
// #endregion

export default function ExportListPopup({
  children,
  targetRef,
  visible,
  contentStyle,
  isLoading,
  onClickExportAllPages,
  onClickExportCurrentPage,
}: ExportListPopupProps) {
  const translator = useTranslator();

  return (
    <Tooltip
      withPortal
      addArrow={false}
      variant="light"
      placement="bottom-end"
      offset={[0, 10]}
      targetRef={targetRef}
      visible={visible}
      contentStyle={[
        tw`px-0 py-2.5 max-w-[220px] flex flex-col rounded-md animate-slide-in-top duration-300 transition shadow-card`,
        contentStyle,
      ]}
    >
      {children || (
        <>
          <DownloadButton
            type="button"
            data-testid={testIds.button.downloadCurrent}
            disabled={isLoading}
            onClick={isLoading ? undefined : onClickExportCurrentPage}
          >
            <DownloadLabel data-testid={testIds.label.downloadCurrent}>
              {translator.translate('Download Current Page')}
            </DownloadLabel>
          </DownloadButton>
          <DownloadButton
            type="button"
            data-testid={testIds.button.downloadAll}
            disabled={isLoading}
            onClick={isLoading ? undefined : onClickExportAllPages}
          >
            <DownloadLabel data-testid={testIds.label.downloadAll}>
              {translator.translate('Download All Pages')}
            </DownloadLabel>
          </DownloadButton>
        </>
      )}
    </Tooltip>
  );
}
