import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import tw, { css, styled, theme } from 'twin.macro';
import useMobileTopBar from '../../../hook/useMobileTopBar/useMobileTopBar.hook';
import { Icon, IconButton, Text } from '../../atom';
import { AvatarPlaceholder, LogoRunner } from '../../atom/Icon/Icon.atom';

const Container = tw.div`flex justify-between px-4 py-3 bg-white`;
const HomeIcon = tw.div`flex flex-1`;
const InfoButtonContainer = tw.div`flex mr-2.5`;
const InfoContainer = tw.div`flex p-4`;
const RightActionContainer = tw.div`flex`;
const InfoColumn = tw.div`flex justify-center`;
const InfoText = styled(Text.BodyFourteen)(() => [
  tw`text-text-color-text-secondary`,
]);
const Avatar = styled.div`
  & svg {
    width: 30px;
    height: 30px;
  }
`;
const InfoBottomSheet = styled(BottomSheet)(() => [
  css`
    [data-rsbs-header]::before {
      ${tw`bg-beige-lines w-[60px]`}
    }
  `,
]);
const componentName = 'MobileTopBar';
const componentSection = {
  iconSection: `${componentName}:IconSection`,
};

const testIds = {
  profileMenu: `${componentSection.iconSection}:ProfileNav`,
};

export default function MobileTopBar() {
  const {
    isTopBarVisible,
    handleClickProfile,
    handleClickHome,
    handleToggleInfo,
    showInfo,
    infoText,
  } = useMobileTopBar();

  if (!isTopBarVisible) return null;
  return (
    <>
      <Container>
        <HomeIcon onClick={handleClickHome}>
          <LogoRunner />
        </HomeIcon>
        <RightActionContainer>
          <InfoButtonContainer>
            <IconButton onClick={handleToggleInfo}>
              <Icon.InfoOutlined
                width={20}
                height={20}
                fillPath={theme`colors.text[color-text-secondary]`}
              />
            </IconButton>
          </InfoButtonContainer>
          <Avatar
            data-testid={testIds.profileMenu}
            onClick={handleClickProfile}
          >
            <AvatarPlaceholder width={30} height={30} />
          </Avatar>
        </RightActionContainer>
      </Container>

      <InfoBottomSheet
        onDismiss={handleToggleInfo}
        open={showInfo}
        scrollLocking
      >
        <InfoContainer>
          <InfoColumn tw="mr-2.5 pt-1 ">
            <Icon.InfoOutlined
              tw="w-5 h-5"
              fillPath={theme`colors.text[color-text-secondary]`}
            />
          </InfoColumn>
          <InfoColumn>
            <InfoText>{infoText}</InfoText>
          </InfoColumn>
        </InfoContainer>
      </InfoBottomSheet>
    </>
  );
}
