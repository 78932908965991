import React from 'react';
import ReactPaginate from 'react-paginate';
import { styled } from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { Text } from '../../atom';

type Props = {
  page: number;
  numItems: number;
  itemsPerPage: number;
  onChangePage: (page: number) => void;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  .pagination {
    display: flex;
    flex-direction: row;
  }
  .break-me {
    cursor: default;
  }
  .active {
    border-radius: 3px;
    border-color: transparent;
    background-color: #f3532e;
    color: white;
  }
  a[role='button'] {
    margin: 0 0.5rem;
  }
`;

export function Pagination({
  page,
  numItems,
  itemsPerPage,
  onChangePage,
}: Props) {
  const numPages = Math.ceil(numItems / itemsPerPage);

  const translator = useTranslator();
  return (
    <Container>
      <Text.Label>
        {`${page > 1 ? itemsPerPage * (page - 1) + 1 : page} - ${
          page === numPages ? numItems : page * itemsPerPage
        } ${`${translator.translate('of')}`} ${numItems}`}
      </Text.Label>
      <ReactPaginate
        onPageChange={(e) => {
          onChangePage?.(e.selected + 1);
        }}
        forcePage={page - 1}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={numPages | 0}
        previousLabel={null}
        nextLabel={null}
        breakClassName="break-me"
        containerClassName="pagination"
        activeClassName="active"
      />
    </Container>
  );
}

export default Pagination;
