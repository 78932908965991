// #region IMPORT
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  GetHOByTrackingCodeApiRequest,
  GetHOByTrackingCodeApiResponse,
  GetHODeliveryAssignmentByTrackingCodeApiRequest,
  GetHODeliveryAssignmentByTrackingCodeApiResponse,
  HaulingOrder,
  HaulingOrderCreateParam,
  HaulingOrderCreateResponse,
  HaulingOrderDeliveryInfo,
  HaulingOrderFilterOrderByEnum,
  HaulingOrderStatusEnum,
} from '../../../model/HaulingOrder.model';
import { HaulingOrderTracking } from '../../../model/HaulingOrderTracking.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, MD, QD } from '../../api.baseQuery';
import {
  ApiResponse,
  CommonApiMetadata,
  CommonApiResponse,
} from '../../api.endpoint';
// #endregion

// #region INTERFACE
export type GetHOListCountApiRequest = {
  from?: number;
  to?: number;
  shipperId?: string;
  'status[]'?: HaulingOrderStatusEnum;
};
export type GetHOCountListApiResponse = CommonApiResponse & {
  haulingOrders: {
    totalCount: number;
  };
};

export type GetHOListApiRequest = {
  from?: number;
  to?: number;
  orderBy?: HaulingOrderFilterOrderByEnum;
  search?: string;
  shipperId?: string;
  page?: number;
  pageSize?: number;
  'status[]'?: HaulingOrderStatusEnum;
};
export type GetHOListApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    haulingOrders: HaulingOrder[];
  };

export type ExportHOListApiRequest = Omit<GetHOListApiRequest, 'status[]'> & {
  status?: HaulingOrderStatusEnum[];
};
export type ExportHOListApiResponse = CommonApiResponse & {
  haulingOrder: {
    fileUrl: string;
  };
};

export type HaulingOrderTimelineListResponse = CommonApiResponse & {
  tracking: HaulingOrderTracking;
};
// #endregion

// #region INTERFACES
export type HaulingOrderInfoResponse = CommonApiResponse & {
  haulingOrder: HaulingOrder;
};
export type HaulingOrderDeliveryInfoResponse = CommonApiResponse & {
  haulingOrderDelivery: HaulingOrderDeliveryInfo;
};

export type HaulingOrderEndpoint = {
  getHaulingOrderListCount: QD<
    GetHOListCountApiRequest | string,
    GetHOCountListApiResponse
  >;
  getHaulingOrderTimelineList: QD<
    { id?: string },
    HaulingOrderTimelineListResponse
  >;
  getHaulingOrderList: QD<GetHOListApiRequest | string, GetHOListApiResponse>;
  getHaulingOrderInfo: QD<string, HaulingOrderInfoResponse>;
  getHaulingOrderDeliveryInfo: QD<string, HaulingOrderDeliveryInfoResponse>;
  getHOByTrackingCode: QD<
    GetHOByTrackingCodeApiRequest,
    GetHOByTrackingCodeApiResponse
  >;
  getHODeliveryAssignmentByTrackingCode: QD<
    GetHODeliveryAssignmentByTrackingCodeApiRequest,
    GetHODeliveryAssignmentByTrackingCodeApiResponse
  >;

  forceCompleteHaulingOrder: MD<
    ForceCompleteHaulingOrderRequest,
    ForceCompleteHaulingOrderResponse
  >;
  createHaulingOrder: MD<HaulingOrderCreateParam, HaulingOrderCreateResponse>;
  exportHaulingOrder: MD<ExportHOListApiRequest, ExportHOListApiResponse>;
};
// #endregion

// #region ENDPOINT

export type ForceCompleteHaulingOrderRequest = { hoId: string };
export type ForceCompleteHaulingOrderResponse = ApiResponse;

// #endregion

// #region ENDPOINT

export const haulingOrderTagTypes: EndpointTagTypes<HaulingOrderEndpoint> = {
  getHaulingOrderListCount: '',
  getHaulingOrderList: 'haulingOrder',
  forceCompleteHaulingOrder: '',
  getHaulingOrderInfo: 'haulingOrderInfo',
  getHaulingOrderDeliveryInfo: 'haulingOrderDeliveryInfo',
  createHaulingOrder: '',
  exportHaulingOrder: '',
  getHOByTrackingCode: 'hauling-orders.track',
  getHODeliveryAssignmentByTrackingCode:
    'hauling-orders.delivery.assignments.track',
  getHaulingOrderTimelineList: 'getHaulingOrderTimelineList',
};

export const haulingOrderEndpoint = (
  builder: Builder,
): HaulingOrderEndpoint => ({
  createHaulingOrder: builder.mutation({
    query: (body) => ({
      url: '/v1/hauling-orders.create',
      body,
      method: 'post',
    }),
    invalidatesTags: () => [haulingOrderTagTypes.getHaulingOrderList],
  }),
  getHaulingOrderListCount: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/hauling-orders.count?${param}`
          : urlcat('/v1/hauling-orders.count', toSnakeCase(param)),
    }),
    providesTags: [haulingOrderTagTypes.getHaulingOrderList],
  }),
  getHaulingOrderList: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/hauling-orders.list?${param}`
          : urlcat('/v1/hauling-orders.list', toSnakeCase(param)),
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.haulingOrders.map(({ id }) => ({
              type: haulingOrderTagTypes.getHaulingOrderList,
              id,
            })),
            haulingOrderTagTypes.getHaulingOrderList,
          ]
        : [haulingOrderTagTypes.getHaulingOrderList],
  }),
  getHaulingOrderInfo: builder.query({
    query: (param) => ({
      url: urlcat('v1/hauling-orders.info', { id: param }),
    }),
    providesTags: [haulingOrderTagTypes.getHaulingOrderInfo],
  }),
  getHaulingOrderDeliveryInfo: builder.query({
    query: (param) => ({
      url: urlcat('v1/hauling-orders.delivery.info', { id: param }),
    }),
    providesTags: [haulingOrderTagTypes.getHaulingOrderInfo],
  }),
  getHOByTrackingCode: builder.query({
    query: (param) => ({
      url: urlcat('/v1/hauling-orders.track', toSnakeCase(param)),
    }),
    keepUnusedDataFor: 0,
    providesTags: [haulingOrderTagTypes.getHOByTrackingCode],
  }),
  getHODeliveryAssignmentByTrackingCode: builder.query({
    query: (param) => ({
      url: urlcat(
        '/v1/hauling-orders.deliveries.assignments.track',
        toSnakeCase(param),
      ),
    }),
    keepUnusedDataFor: 0,
    providesTags: [haulingOrderTagTypes.getHODeliveryAssignmentByTrackingCode],
  }),
  forceCompleteHaulingOrder: builder.mutation({
    query: (body) => ({
      url: '/v1/hauling-orders.complete',
      body,
      method: 'post',
    }),
    invalidatesTags: [haulingOrderTagTypes.getHaulingOrderList],
  }),
  exportHaulingOrder: builder.mutation({
    query: (body) => ({
      url: '/v1/hauling-orders.export',
      body,
      method: 'post',
    }),
  }),
  getHaulingOrderTimelineList: builder.query({
    query: (param) => ({
      url: urlcat('/v1/hauling-orders.timeline.list', param),
    }),
    providesTags: [haulingOrderTagTypes.getHaulingOrderTimelineList],
  }),
});
// #endregion
