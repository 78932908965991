import fromUnixTime from 'date-fns/fromUnixTime';
import React from 'react';
import tw, { styled } from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import useTranslator, { UseTranslator } from '../../../hook/useTranslator.hook';
import { Photo } from '../../../model/Common.model';
import { SOTracking } from '../../../model/ShipperOrder.model';
import { getFullDateTimeFormat } from '../../../util/date.util';
import {
  formatText,
  getSOTimelineLocationName,
  getTrackingTimelineLabel,
  remapSOTimelines,
} from '../../../util/tracking/trackingTimeline.util';
import { Text } from '../../atom';
import {
  CopyToClipboard,
  TrackingGoodsMobile,
  TrackingHeader,
  TrackingTimelineMobile,
} from '../../molecule';
import GalleryMobile, {
  GalleryMobileProps,
} from '../GalleryMobile/GalleryMobile.organism';
import TrackingSODeliveryMobile from '../TrackingSODeliveryInfoMobile/TrackingSODeliveryInfoMobile.organism';

// #region STYLED
const Container = tw.div`flex flex-col gap-2 bg-grey-six`;
const Section = tw.div`bg-white p-4 flex flex-col gap-2`;
const CopyOnlyContainer = styled.div(() => [
  tw`ml-1`,
  `
  & svg {
    width:14px;
    height:14px;
  }
`,
]);
// #endregion

// #region INTERFACES
interface Props {
  soId?: string;
  translator: UseTranslator;
  tracking: SOTracking;
  soNumber?: string;
  trackingCode?: string;
  forceLang?: SupportedLanguage;
  showTripHistory: boolean;
  showGallery?: GalleryMobileProps;
  onImageClick?: (
    photos: Photo[],
    clickedIndex: number,
    customTitle?: string[],
  ) => void;
  handleOpenTripHistory: () => void;
  handleShowFullContent: () => void;
}
// #endregion

function TripHistory({
  tracking,
  forceLang,
  openTripHistory,
}: {
  tracking: SOTracking;
  forceLang: SupportedLanguage | undefined;
  openTripHistory: () => void;
}) {
  const translator = useTranslator();
  const timelines = remapSOTimelines(tracking.timelines);
  const lastTimeline = timelines[0];
  const timelineLocationName = getSOTimelineLocationName(lastTimeline);
  const timelineTitle = getTrackingTimelineLabel(
    {
      forceLang,
      type: lastTimeline.type,
      translate: translator.translate,
    },
    ...(timelineLocationName ? [timelineLocationName] : []),
  );

  return (
    <button
      type="button"
      tw="flex flex-col gap-2 bg-white"
      onClick={openTripHistory}
    >
      <div tw="w-full flex justify-between p-4 pb-2">
        <Text.HeadingFour>
          {translator.translate('Trip History', forceLang)}
        </Text.HeadingFour>
        <Text.ButtonTwelve tw="text-orange">
          {translator.translate('See More', forceLang)}
        </Text.ButtonTwelve>
      </div>

      <ul tw="w-full relative pl-6">
        <li tw="relative flex flex-col gap-2 pb-4 border-l-2 border-dashed border-grey-five before:(left-[-8.5px] top-[-3px] w-[15px] h-[15px] rounded-full absolute bg-status-success)">
          <div tw="flex flex-col items-start -mt-2 ml-5">
            <Text.BodyFourteen tw="max-w-[calc(100% - 25px)] whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold">
              {timelineTitle}
            </Text.BodyFourteen>
            <Text.BodySmallTwelve tw="text-grey-two">
              {getFullDateTimeFormat(fromUnixTime(lastTimeline.time), true)}
            </Text.BodySmallTwelve>
          </div>
        </li>
      </ul>
    </button>
  );
}

export default function SODMobileSheetContent({
  tracking,
  translator,
  soNumber,
  trackingCode = '-',
  forceLang,
  showTripHistory,
  showGallery,
  soId,
  onImageClick,
  handleShowFullContent,
  handleOpenTripHistory,
}: Props) {
  if (showGallery)
    return (
      <GalleryMobile
        currentIndex={showGallery.currentIndex}
        photos={showGallery.photos}
        handleChangePhoto={showGallery.handleChangePhoto}
      />
    );
  if (showTripHistory)
    return (
      <Container>
        <TrackingTimelineMobile
          handleShowFullContent={handleShowFullContent}
          data={tracking}
          showTripHistory
          translator={translator}
          forceLang={forceLang}
          onImageClick={onImageClick}
        />
      </Container>
    );

  return (
    <Container>
      <Section>
        {!soId && (
          <>
            {soNumber && <Text.HeadingFour>{soNumber}</Text.HeadingFour>}
            <div
              css={[
                tw`flex items-center -mt-1`,
                !soId ? tw`max-w-[550px]` : tw`max-w-[640px]`,
              ]}
            >
              <Text.HeadingFour tw="text-sm truncate">
                {formatText(
                  translator.translate('Tracking Number: %s', forceLang),
                  trackingCode,
                )}
              </Text.HeadingFour>
              <CopyOnlyContainer>
                <CopyToClipboard soId={soId} valueToCopy={trackingCode} />
              </CopyOnlyContainer>
            </div>
          </>
        )}
        <TrackingHeader
          type="so"
          status={tracking.status}
          translator={translator}
          forceLang={forceLang}
        />
      </Section>
      <TripHistory
        tracking={tracking}
        forceLang={forceLang}
        openTripHistory={handleOpenTripHistory}
      />
      <TrackingTimelineMobile
        data={tracking}
        translator={translator}
        forceLang={forceLang}
        handleShowFullContent={handleShowFullContent}
      />
      <Section>
        <Text.HeadingFour>
          {translator.translate('Organization', forceLang)}
        </Text.HeadingFour>
        <Text.BodyFourteen>{tracking.orgName}</Text.BodyFourteen>
      </Section>
      <TrackingGoodsMobile
        translator={translator}
        data={tracking}
        forceLang={forceLang}
      />
      {!!tracking.deliveries?.length && (
        <TrackingSODeliveryMobile
          deliveries={tracking.deliveries}
          forceLang={forceLang}
        />
      )}
    </Container>
  );
}
