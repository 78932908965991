import { nanoid } from '@reduxjs/toolkit';
import * as echarts from 'echarts';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from 'twin.macro';
import { LineChartSerie } from '../../../model/Summary.model';
import { currencyFormatter } from '../../../util/formatter.util';
import {
  generateChartTooltip,
  getProfitPercentageTextColor,
} from '../../../util/home/home.util';
import { formatText } from '../../../util/tracking/trackingTimeline.util';

type Props = {
  data: LineChartSerie[];
  filterLabel: string;
};

function RevenueLineChart({ data, filterLabel }: Props) {
  const [lastLabel, setLastLabel] = useState<string | undefined>(undefined);
  const chartDom = useRef<HTMLDivElement>(null);

  const dataZoom = useMemo(() => {
    const baseZoomConfig = {
      handleSize: 0,
      handleStyle: { opacity: 0 },
      height: 0,
      moveHandleSize: 12,
      showDetail: false,
      left: 0,
      right: 0,
      bottom: 20,
    };

    if (data.length > 12) {
      return [
        {
          ...baseZoomConfig,
          // `end` is determine how many data should be in container viewport
          // the value is in percentage, below calculation ensure to render max 12 data
          // no matter how many data we received
          end: (12 / data.length) * 100,
        },
      ];
    }

    return undefined;
  }, [data.length]);

  const tooltip = useMemo(
    (): echarts.EChartsOption['tooltip'] => ({
      trigger: 'axis',
      formatter: (params: echarts.TooltipComponentFormatterCallbackParams) => {
        const [revenue, netIncome] =
          params as echarts.DefaultLabelFormatterCallbackParams[];
        const element = data.find((_, idx) => idx === revenue.dataIndex);

        if (!element) return '';
        const { month, profitPercentage, day } = element;

        const title = day
          ? formatText('%s %s', day, month.toString())
          : month.toString();

        return generateChartTooltip({
          title,
          data: [
            {
              id: nanoid(),
              withBullet: true,
              bulletStyle: theme`colors.status.success`,
              value: currencyFormatter(Number(revenue.data)),
            },
            {
              id: nanoid(),
              withBullet: true,
              bulletStyle: theme`colors.orange`,
              value: currencyFormatter(Number(netIncome.data)),
            },
            {
              id: nanoid(),
              withBullet: false,
              valueStyle: getProfitPercentageTextColor(profitPercentage),
              value: formatText('(%s%)', profitPercentage.toString()),
            },
          ],
        });
      },
    }),
    [data],
  );

  const grid = useMemo(() => {
    const baseConfig = {
      left: 5,
      right: 0,
      containLabel: true,
    };

    if (data.length > 12) return { ...baseConfig, bottom: 30 };

    return {
      ...baseConfig,
      bottom: 0,
    };
  }, [data.length]);

  const option = useMemo(
    (): echarts.EChartsOption => ({
      grid,
      animation: false,
      tooltip,
      xAxis: {
        type: 'category',
        axisLine: { lineStyle: { opacity: 0 } },
        data: data.map((v) => {
          if (v.day) return v.day;
          const [month] = v.month.split(' ');
          return month;
        }),
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        type: 'value',
        min: 5,
        axisLabel: {
          formatter: (value: number) =>
            currencyFormatter(value, {
              style: 'currency',
              currency: 'IDR',
              currencySign: 'accounting',
              notation: 'compact',
              compactDisplay: 'short',
              minimumSignificantDigits: value !== 0 ? 2 : undefined,
              maximumSignificantDigits: 3,
              maximumFractionDigits: 0,
            }).replace('Rp', 'Rp '),
        },
      },
      series: [
        {
          type: 'line',
          data: data.map((v) => v.revenue),
          color: theme`colors.status.success`,
          symbolSize: 12,
        },
        {
          type: 'line',
          data: data.map((v) => v.netIncome),
          color: theme`colors.orange`,
          symbolSize: 12,
        },
      ],
      dataZoom,
    }),
    [data, dataZoom, tooltip, grid],
  );

  useEffect(() => {
    const myChart = echarts.init(chartDom.current, null, {
      renderer: 'canvas',
      useDirtyRect: false,
    });

    const chartResizeCallback = () => myChart.resize();

    if (lastLabel !== filterLabel) {
      setLastLabel(filterLabel);
    }
    myChart.clear();
    myChart.setOption(option);
    window.addEventListener('resize', chartResizeCallback);

    return () => {
      window.removeEventListener('resize', chartResizeCallback);
    };
  }, [filterLabel, lastLabel, option]);

  return <div ref={chartDom} id="line-chart-container" tw="w-full h-full" />;
}

export default RevenueLineChart;
