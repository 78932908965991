import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import tw, { css, styled } from 'twin.macro';
import { RootState } from '../../../app/store/store.app';
import useTranslator from '../../../hook/useTranslator.hook';
import { StepperItem } from '../../../model/Common.model';
import { Check } from '../../atom/Icon/Icon.atom';
import { HeadingFive } from '../../atom/Text/Text.atom';

type Props<T> = PropsWithChildren<{
  steps: StepperItem[];
  currentStep: T;
  footer?: ReactElement;
  isHeaderVisible?: boolean;
  isFooterVisible?: boolean;
  enumLabelMapper: (step: T) => string;
  onClickStep: (step: T) => void;
}>;

type HeaderItemProps = {
  label: string;
  currentIndex: number;
  index: number;
  isValid?: boolean;
  handleClick: () => void;
};

const Wrapper = tw.div`relative overflow-hidden `;
const Header = styled.div(
  ({ isNavbarExpanded }: { isNavbarExpanded?: boolean }) => [
    tw`flex items-center justify-center gap-2 bg-white px-6 pt-[88px] pb-4 top-0  fixed border-b border-t border-t-beige-lines border-b-beige-lines z-[3] w-[calc(100% - 84px)] `,
    isNavbarExpanded && tw`w-[calc(100% - 240px)]`,
  ],
);
const Content = styled.div(
  ({ isHeaderVisible }: { isHeaderVisible?: boolean }) => [
    tw`h-[calc(100vh - 120px)] pt-[68px] overflow-auto`,
    !isHeaderVisible && tw`h-[calc(100vh - 141px)] `,
  ],
);
const Footer = styled.div(
  ({ isNavbarExpanded }: { isNavbarExpanded?: boolean }) => [
    tw`flex items-center justify-between gap-4 bg-white px-6 py-4 bottom-0 fixed border-t border-t-beige-lines w-[calc(100% - 84px)] min-h-[58px] z-[3]`,
    isNavbarExpanded && tw`w-[calc(100% - 240px)]`,
  ],
);

const HeaderItemContainer = styled.div(() => [
  tw`flex items-center gap-2 `,
  css`
    &:last-of-type .header-item-separator {
      ${tw`hidden`}
    }
  `,
]);
const HeaderIndex = styled(HeadingFive)(
  ({
    currentIndex,
    index,
    isValid,
  }: { currentIndex: number; index: number; isValid: boolean }) => [
    tw`rounded-2xl p-1 leading-4 w-6 text-center bg-grey-six text-grey-three`,
    currentIndex > index && isValid && tw`bg-orange-hover text-orange`,
    currentIndex === index && tw`text-white bg-orange`,
  ],
);
const HeaderLabel = styled(HeadingFive)(
  ({
    currentIndex,
    index,
    isValid,
  }: { currentIndex: number; index: number; isValid: boolean }) => [
    !isValid && tw`text-grey-two`,
    currentIndex < index && tw`font-normal text-grey-two`,
    currentIndex === index && tw`text-orange`,
  ],
);
const HeaderItemContent = styled.div(
  ({ isNextStep }: { isNextStep?: boolean }) => [
    tw`flex items-center gap-2 cursor-pointer`,
    !isNextStep && tw`cursor-default`,
  ],
);
const HeaderItemSeparator = tw.div`h-[1px] w-9 bg-beige-lines cursor-default`;

function HeaderItem({
  label,
  currentIndex,
  index,
  isValid = true,
  handleClick,
}: HeaderItemProps) {
  const isCurrentStepValid = useMemo(
    () => currentIndex > index && isValid,
    [currentIndex, index, isValid],
  );
  return (
    <HeaderItemContainer>
      <HeaderItemContent isNextStep={isCurrentStepValid} onClick={handleClick}>
        <HeaderIndex
          currentIndex={currentIndex}
          index={index}
          isValid={isValid}
        >
          {isCurrentStepValid ? <Check width={16} height={16} /> : index + 1}
        </HeaderIndex>
        <HeaderLabel
          currentIndex={currentIndex}
          index={index}
          isValid={isValid}
        >
          {label}
        </HeaderLabel>
      </HeaderItemContent>
      <HeaderItemSeparator className="header-item-separator" />
    </HeaderItemContainer>
  );
}

export default function StepperSection<T>({
  steps,
  currentStep,
  children,
  footer,
  isHeaderVisible = true,
  isFooterVisible = true,
  onClickStep,
  enumLabelMapper,
}: Props<T>) {
  const { isHovered, isExpanded } = useSelector(
    (state: RootState) => state.navbar,
  );
  const { translate } = useTranslator();
  const isNavbarExpanded = isHovered || isExpanded;
  const currentIndex = steps.findIndex(
    (item) => item.label === (currentStep as unknown as string),
  );
  const handleClick = useCallback(
    (idx, targetIdx, _step) => {
      if (targetIdx >= idx) return;
      onClickStep(_step as unknown as T);
    },
    [onClickStep],
  );

  return (
    <Wrapper>
      {isHeaderVisible && (
        <Header isNavbarExpanded={isNavbarExpanded}>
          {steps.map((item, idx) => (
            <HeaderItem
              handleClick={() => handleClick(currentIndex, idx, item.label)}
              key={item.label}
              isValid={item.isValid}
              label={translate(enumLabelMapper(item.label as unknown as T))}
              index={idx}
              currentIndex={currentIndex}
            />
          ))}
        </Header>
      )}
      <Content isHeaderVisible={isHeaderVisible}>{children}</Content>
      {isFooterVisible && (
        <Footer isNavbarExpanded={isNavbarExpanded}>{footer}</Footer>
      )}
    </Wrapper>
  );
}
