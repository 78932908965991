import useDeliveryLocationUploadModal from '../../hook/deliveryLocation/useDeliveryLocationUploadModal.hook';
import useTranslator from '../../hook/useTranslator.hook';
import useDeliveryLocationAddBulkLogic from './hooks/useDeliveryLocationAddBulkLogic.hook';
import useDeliveryLocationAddBulkTable from './hooks/useDeliveryLocationAddBulkTable.hook';

export default function useDeliveryLocationAddBulkViewModel() {
  const translator = useTranslator();

  const uploadModal = useDeliveryLocationUploadModal();
  const bulkLogic = useDeliveryLocationAddBulkLogic();
  const bulkTable = useDeliveryLocationAddBulkTable({ bulkLogic });

  return {
    translator,
    uploadModal,
    bulkLogic,
    bulkTable,
  };
}
