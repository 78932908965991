import { nanoid } from '@reduxjs/toolkit';
import React, { useMemo, useState } from 'react';
import { theme } from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { Button, Icon, Text } from '../../atom';

function TimelineHeaderItem({
  title,
  values,
  showFootprint,
  isLastItem,
  translator,
  forceLang,
  isPickup,
}: {
  title: string;
  values: string[];
  forceLang?: SupportedLanguage;
  showFootprint?: boolean;
  isLastItem?: boolean;
  isPickup?: boolean;
  translator: UseTranslator;
}) {
  const [showMore, setShowMore] = useState(false);
  const buttonText = useMemo(() => {
    const text = showMore ? 'Show Less' : 'Show More';
    return `${translator.translate(text, forceLang)}...`;
  }, [forceLang, showMore, translator]);

  const slicedLocations = useMemo(() => {
    if (values.length > 3 && showMore) return values;
    if (values.length > 3) return values.slice(0, 3);
    return values;
  }, [showMore, values]);
  return (
    <div tw="grid grid-template-columns[32px minmax(128px,100%)]">
      <div tw="w-4 justify-center flex pt-1 mr-2.5 relative">
        <div>
          <Icon.MapMarker fill={!isPickup ? theme`colors.deleted.dark` : ''} />
        </div>
        {showFootprint && !isLastItem && (
          <div tw="border-l absolute top-[18px] left-[8px] h-full border-dashed border-grey-four" />
        )}
      </div>
      <div tw="flex flex-col">
        <div tw="flex items-center">
          <Text.BodyTwelve tw="text-grey-two">{`${
            values.length > 1 ? `${values.length} ` : ''
          }${title}`}</Text.BodyTwelve>
        </div>
        <div tw="flex items-start w-full flex-col overflow-hidden">
          {slicedLocations.map((v) => (
            <Text.HeadingFive
              key={`location-${nanoid()}`}
              tw="whitespace-nowrap overflow-hidden overflow-ellipsis w-full"
            >
              {v}
            </Text.HeadingFive>
          ))}
          {values.length > 3 && (
            <Button.Text tw="text-xs" onClick={() => setShowMore((v) => !v)}>
              {buttonText}
            </Button.Text>
          )}
        </div>
      </div>
    </div>
  );
}

export default TimelineHeaderItem;
