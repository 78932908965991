import React from 'react';
import tw from 'twin.macro';
import {
  SettingMenuWithForm,
  SettingNavigationItemType,
} from '../../../constant/Setting.constant';
import { FormItem } from '../../../types/input.type';
import { UseSettingAdvancedObj } from '../../../view/Setting/hook/useSettingAdvanced.hook';
import { Outlined } from '../../atom/Button/Button.atom';
import { BodyTwelve, HeadingFive } from '../../atom/Text/Text.atom';
import SettingsForm from './SettingsForm.organism';

type Props = {
  settingAdvanced: UseSettingAdvancedObj;
  currentMenu: SettingNavigationItemType;
  formData?: FormItem[];
};

const Container = tw.div`flex flex-row flex-1 justify-between h-[fit-content]`;
const Label = tw.div`flex flex-col`;

function SettingsAdvancedContent({
  settingAdvanced: { label, description, resetData, resetLabel },
}: {
  settingAdvanced: UseSettingAdvancedObj;
}) {
  return (
    <Container>
      <Label>
        <HeadingFive>{label}</HeadingFive>
        <BodyTwelve tw="text-grey-two">{description}</BodyTwelve>
      </Label>
      <Outlined
        onClick={resetData.handleOpenModal}
        tw="border-red-500 text-red-500"
      >
        {resetLabel}
      </Outlined>
    </Container>
  );
}

export default function SettingsContent({
  currentMenu,
  formData = [],
  settingAdvanced,
}: Props) {
  if (SettingMenuWithForm.includes(currentMenu))
    return <SettingsForm formData={formData} />;
  return <SettingsAdvancedContent settingAdvanced={settingAdvanced} />;
}
