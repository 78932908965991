import {
  Action,
  CaseReducer,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import urlcat from 'urlcat';
import envConfig from '../config/env/env.config';
import { doRedirectUrl } from '../util/helper.util';

// #region INTERFACES
export interface SessionStore {
  isLoggedIn: boolean;
  token: string;
  globalSessionID: string;
  username: string;
  organizationId: string;
  organizationName: string;
  userId: string;
}

export enum LogoutReason {
  REVOKE = 'REVOKE',
  CLEAR = 'CLEAR',
  USER_UNAUTHORIZED = 'USER_UNAUTHORIZED',
  FORBIDDEN_REQUEST = 'FORBIDDEN',
}

type LoginActionPayload = PayloadAction<
  Omit<SessionStore, 'isLoggedIn' | 'organizationName'>
>;

type LogoutActionPayload = PayloadAction<{
  type: LogoutReason;
  token?: string;
}>;
// #endregion

const initialState: SessionStore = {
  isLoggedIn: false,
  token: '',
  globalSessionID: '',
  username: '',
  organizationId: '',
  organizationName: '',
  userId: '',
};

const logout: CaseReducer<SessionStore, LogoutActionPayload> = () => ({
  ...initialState,
});

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    changeToken: (state, action: PayloadAction<string>) => ({
      ...state,
      token: action.payload,
    }),
    changeOrganizationName: (state, action: PayloadAction<string>) => ({
      ...state,
      organizationName: action.payload,
    }),
    login: (state, action: LoginActionPayload) => ({
      ...state,
      isLoggedIn: true,
      ...action.payload,
    }),
    logout,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action: Action) => String(action.type).endsWith('/logout'),
      (_state, action: LogoutActionPayload) => {
        setTimeout(() => {
          // in local development env the registered URL in SSO is http://127.0.0.1:3000/ otherwise it wont work
          // In tracking order we have copy text feature which include the above URL and tracking route path
          // since the envConfig.ktbServiceUrl previously contains `/` it caused duplicated symbol rendered on the copy text feature in tracking order page
          // as workaround I omit the `/` symbol in development env file and add manually here for the authentication purpose so in tracking page will work as expected
          const serviceURL =
            envConfig.mode === 'development'
              ? `${envConfig.ktbJOServiceUrl}/`
              : envConfig.ktbJOServiceUrl;

          // NOTE: the reason why I didn't take global session id from state parameter is at this point, the state is already empty
          // as workaround I adjust the logout action to receive a string parameter
          if (action.payload.type === LogoutReason.REVOKE) {
            const { token } = action.payload;
            const baseUrl = `${envConfig.ktbVisaUrl}/logout`;
            doRedirectUrl({
              url: urlcat(baseUrl, {
                ...(String(token) !== '' && { ssoToken: token }),
                serviceURL,
              }),
            });
          }

          if (action.payload.type === LogoutReason.USER_UNAUTHORIZED) {
            if (window.opener !== null) {
              window.close();
            } else {
              doRedirectUrl({
                url: urlcat(`${envConfig.ktbVisaUrl}/login`, {
                  serviceURL: envConfig.ktbRunnerServiceUrl,
                }),
              });
            }
          }

          if (action.payload.type === LogoutReason.FORBIDDEN_REQUEST) {
            doRedirectUrl({ url: envConfig.ktbVisaUrl });
          }

          if (action.payload.type === LogoutReason.CLEAR) {
            doRedirectUrl({
              url: urlcat(`${envConfig.ktbVisaUrl}/login`, {
                serviceURL,
              }),
            });
          }
        }, 0);
      },
    );
  },
});

export const sessionAction = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;
