import React, { useCallback, useRef } from 'react';
import useEventListener from './useEventListener.hook';

/**
 * Adds handler to run when there's a mouse click outside the component area
 *
 * @param {() => void} onClick - handler function when the mouse is clicked outside the ref
 */
const useClickOutside = <T extends HTMLElement>(
  onClick: () => void,
): React.RefObject<T> => {
  const node: React.RefObject<T> = useRef<T>(null);

  const handleClick = useCallback(
    (e: MouseEvent): void => {
      const attrValue = (e.target as Element).getAttribute('data-testid');
      const ignoredTestIDs = [
        'ShipperOrderPaymentHistory:SuccessToast:CloseButton',
        'ShipperOrderPaymentHistory:SuccessToast:UndoButton',
        'HomeCallCenter:ToggleButton',
      ];

      // ignored case
      if (
        !node.current ||
        node.current.contains(e.target as Node) ||
        (attrValue && ignoredTestIDs.includes(attrValue))
      ) {
        return;
      }

      e.stopPropagation();
      onClick();
    },
    [onClick],
  );

  useEventListener('mousedown', handleClick);

  return node;
};

export default useClickOutside;
