import React, { PropsWithChildren } from 'react';
import tw from 'twin.macro';
import { Text } from '../../atom';

// #region INTERFACES
type DashboardCardProps = PropsWithChildren<{
  title: string;
  suffix?: React.ReactNode;
  isMobile?: boolean;
  testID?: string;
}>;
// #endregion

function DashboardCard({
  title,
  suffix: subtitle,
  children,
  testID,
  isMobile,
}: DashboardCardProps) {
  return (
    <div tw="bg-white rounded-md shadow-card p-5 relative min-height[106px] flex flex-col justify-between">
      <Text.HeadingFour
        data-testid={testID}
        tw="whitespace-normal overflow-wrap[anywhere] justify-center"
      >
        {title}
        {subtitle}
      </Text.HeadingFour>
      <div
        tw="flex mt-2.5 self-start"
        css={[
          isMobile &&
            tw`flex-col overflow-hidden whitespace-normal overflow-ellipsis self-start max-w-full`,
        ]}
      >
        {children}
      </div>
    </div>
  );
}

export default DashboardCard;
