import format from 'date-fns/format';
import React, {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  useCallback,
} from 'react';
import NumberFormat from 'react-number-format';
import TimeKeeper, { TimeInput, TimeOutput } from 'react-timekeeper';
import tw, { styled } from 'twin.macro';
import { driverDetailEvents } from '../../../constant/Driver/DriverDetail.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { getTimeFromMaskInput } from '../../../util/driver.util';
import { replaceCharacter } from '../../../util/helper.util';
import { Button, Icon, IconButton, Text } from '../../atom';
import { TextField } from '../../molecule';
import { ExtraStyle } from '../../Type.component';
import {
  ClockPickerAction,
  ClockPickerContext,
  ClockPickerInitialState,
  createClockPickerContext,
  useClockPicker,
} from './useClockPicker.hook';

// #region TYPES
type ProviderProps = {
  children:
    | React.ReactNode
    | ((
        context: readonly [ClockPickerInitialState, ClockPickerAction],
      ) => JSX.Element);
  initialState?: ClockPickerInitialState;
};
type InputFieldProps = Omit<
  ComponentPropsWithoutRef<typeof TextField>,
  'readOnly' | 'contentEditable' | 'onFocus' | 'onClick' | 'right'
> & {
  time: TimeInput;
  onClickCloseIcon: () => void;
  hideIcon?: boolean;
};
type InputFieldV2Props = PropsWithChildren<
  Omit<
    ComponentPropsWithoutRef<typeof NumberFormat>,
    'format' | 'placeholder' | 'mask' | 'value' | 'onChange'
  > & {
    label: string;
    onChange?: (timeInput: string | undefined) => void; // timeInput -> e.g "01:09" or undefined
    onChangeValidationError?: (error: {
      value: string;
      type: 'partially-filled';
    }) => void;
    rootCss?: ExtraStyle;
    inputWrapperCss?: ExtraStyle;
  }
>;
type PickerHeaderProps = {
  date?: Date;
  rootStyle?: ExtraStyle;
  onClickClose?: () => void;
};
type PickerContainerProps = PropsWithChildren<{
  rootStyle?: ExtraStyle;
}>;
type ClockPickerProps = Omit<
  React.ComponentPropsWithoutRef<typeof TimeKeeper>,
  'time' | 'onChange'
> & {
  onChangeTime?: (timeOutput: TimeOutput) => void;
  testID?: string;
};
type PickerFooterProps = {
  onClickSubmit: (time: string) => void;
  onClickCancel?: () => void;
  cancelDisabled?: boolean;
  submitDisabled?: boolean;
  cancelLabel?: string;
  submitLabel?: string;
  testID?: string;
  rootStyle?: ExtraStyle;
};
// #endregion

const ClockContainer = styled.div`
  ${tw`p-5 pb-2.5 w-full flex justify-center items-center transition-opacity animate-fade-in`}

  > .react-timekeeper {
    ${tw`shadow-none w-full`}

    > .react-timekeeper__top-bar {
      ${tw`hidden`}
    }

    > .react-timekeeper__clock-wrapper {
      ${tw`bg-white p-0`}

      > .react-timekeeper__clock {
        ${tw`bg-grey-six`}

        > .react-timekeeper__clock-hours > span[data-testid="number_hour_outer"] {
          ${tw`text-black`}
        }

        > .react-timekeeper__clock-hours > span[data-testid="number_hour_inner"] {
          ${tw`text-grey-three`}
        }

        > .react-timekeeper__clock-minutes > span[data-testid="number_minute"] {
          ${tw`text-black`}
        }

        > svg > g {
          > circle {
            ${tw`fill-orange`}
          }

          > line {
            ${tw`stroke-orange`}
          }
        }
      }
    }
  }
`;

/**
 * always wrap your time picker with this first to make the context works
 */
export function Provider({ initialState, children }: ProviderProps) {
  const value = createClockPickerContext(initialState);

  // side effect to listen for reset clock event
  // this is necessary for grandparent component to be able to access local context
  React.useEffect(() => {
    const callback = () => {
      value[1].resetTime();
      value[1].resetInput();
      value[1].closePickerInput();
    };

    window.addEventListener(driverDetailEvents.timesheet.resetClock, callback);

    return () => {
      window.removeEventListener(
        driverDetailEvents.timesheet.resetClock,
        callback,
      );
    };
  }, [value]);

  return (
    <ClockPickerContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </ClockPickerContext.Provider>
  );
}

/**
 * this component renders a time input that capable of editing time picker by typing with masking "__:__"
 */
export function InputFieldV2({
  children,
  label,
  rootCss,
  inputWrapperCss,
  onChange,
  onChangeValidationError,
  ...props
}: InputFieldV2Props) {
  const [{ input }, { setInput, setTime }] = useClockPicker();

  return (
    <section
      id="InputFieldV2"
      tw="flex justify-between border border-b-[3px] rounded duration-300 border-beige-lines border-b-grey-four hover:border-b-orange focus:border-b-orange"
      css={rootCss}
    >
      <div tw="flex flex-col px-3 py-1" css={inputWrapperCss}>
        <label
          htmlFor={props.id ?? props.name}
          tw="font-sans tracking-wider select-none truncate text-xs leading-6 text-grey-three"
        >
          {label}
        </label>

        <NumberFormat
          {...props}
          mask="_"
          placeholder="00:00"
          format="##:##"
          value={input}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            /**
             * `value` could be:
             * '' -> empty
             * '1_:__' -> partially filled
             * '10:10' -> fully filled
             */
            const { value } = evt.target;

            // do not set the time picker when input is partially filled (e.g '1_:__')
            if (value.includes('_')) {
              onChangeValidationError?.({ value, type: 'partially-filled' });
              return;
            }

            let timeInput: string | undefined = '__:__';

            if (value) {
              // when user fully filled the input field
              const { hour, minute } = getTimeFromMaskInput(value);

              timeInput = replaceCharacter(
                timeInput,
                0,
                +hour > 23 ? '23' : hour,
              );
              timeInput = replaceCharacter(
                timeInput,
                3,
                +minute > 59 ? '59' : minute,
              );
            } else {
              // when user clear the input field manually
              timeInput = undefined;
            }

            setInput(
              !timeInput || timeInput === '__:__'
                ? ''
                : timeInput.replace(':', ''),
            );
            setTime(timeInput ? timeInput : '00:00');
            onChange?.(timeInput);
          }}
          css={[
            tw`border-0 p-0 placeholder:text-grey-two focus:(border-0 ring-0 outline-[0] bg-white) active:(border-0 ring-0 outline-[0] bg-white)`,
          ]}
        />
      </div>

      {children}
    </section>
  );
}

/**
 * this component renders a time input, when clicked it opens the time picker modal.
 *
 * @deprecated use InputFieldV2 to support change time by typing
 */
export function InputField({
  time,
  onClickCloseIcon,
  hideIcon,
  ...props
}: InputFieldProps) {
  const [, { openPickerInput, togglePickerInput }] = useClockPicker();

  const onFocusOrClickTextField = useCallback(() => {
    if (props?.disabled) return;
    openPickerInput();
  }, [props?.disabled, openPickerInput]);

  const onClickClockIcon = useCallback(
    (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (props?.disabled) return;
      evt.stopPropagation();
      togglePickerInput();
    },
    [props?.disabled, togglePickerInput],
  );

  const onClickCloseIconBtn = useCallback(
    (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (props?.disabled) return;
      evt.stopPropagation();
      onClickCloseIcon();
    },
    [props?.disabled, onClickCloseIcon],
  );

  return (
    <TextField
      {...props}
      readOnly
      contentEditable={false}
      onFocus={onFocusOrClickTextField}
      onClick={onFocusOrClickTextField}
      right={
        <>
          {!hideIcon && !time && (
            <IconButton
              tw="-mr-2"
              disabled={props?.disabled}
              onClick={onClickClockIcon}
            >
              <Icon.Clock />
            </IconButton>
          )}

          {!hideIcon && !!time && !props?.disabled && (
            <IconButton
              tw="-mr-2"
              disabled={props?.disabled}
              onClick={onClickCloseIconBtn}
            >
              <Icon.Close />
            </IconButton>
          )}
        </>
      }
    />
  );
}

/**
 * this component will show header that includes current applied time and close button
 */
export function PickerHeader({
  date,
  rootStyle,
  onClickClose,
}: PickerHeaderProps) {
  const [state, { closePickerInput }] = useClockPicker();

  return (
    <header
      tw="p-2 flex items-center justify-between rounded-t-lg bg-orange"
      css={rootStyle}
    >
      <span tw="w-6 h-6" css={[date && tw`w-8 h-8`]} />

      <div tw="flex flex-col items-center">
        {date && (
          <Text.HeadingFour tw="text-white opacity-60">
            {format(date, 'dd MMM yyyy')}
          </Text.HeadingFour>
        )}
        <Text.Paragraph tw="text-white">
          {(state.time as string).padStart(5, '0')}
        </Text.Paragraph>
      </div>

      <button
        type="button"
        tw="flex items-center justify-center h-6 w-6 rounded-full text-white bg-orange hover:(bg-white bg-opacity-20)"
        css={[date && tw`w-8 h-8`]}
        onClick={() => {
          closePickerInput();
          onClickClose?.();
        }}
      >
        <Icon.Close width={date ? 20 : 18} height={date ? 20 : 18} />
      </button>
    </header>
  );
}

/**
 * wrap the `Picker` with this to apply our custom clock styling purposes
 */
export function PickerContainer({ children, rootStyle }: PickerContainerProps) {
  return <ClockContainer css={rootStyle}>{children}</ClockContainer>;
}

/**
 * the actual `TimeKeeper` component library
 *
 * @remarks
 *
 * There's a bug with the `disabledTimeRange` props. Prefer to use validation using the `onChangeTime` props.
 */
export function Picker({
  onChangeTime,
  testID = '',
  ...rest
}: ClockPickerProps) {
  const [state, { setTime }] = useClockPicker();

  const handleChange = useCallback(
    (timeOutput: TimeOutput) => {
      setTime(timeOutput.formatted24);
      onChangeTime?.(timeOutput);
    },
    [setTime, onChangeTime],
  );

  return (
    <TimeKeeper
      hour24Mode
      switchToMinuteOnHourSelect
      {...rest}
      time={state.time}
      onChange={handleChange}
      data-testid={`${testID}:ClockPicker:Picker`}
    />
  );
}

/**
 * this component will show footer that includes cancel and submit button
 */
export function PickerFooter({
  onClickSubmit,
  onClickCancel,
  cancelDisabled,
  submitDisabled,
  cancelLabel = 'Cancel',
  submitLabel = 'Apply',
  testID,
  rootStyle,
}: PickerFooterProps) {
  const translator = useTranslator();
  const [state, { closePickerInput }] = useClockPicker();

  const handleCancel = useCallback(() => {
    closePickerInput();
    onClickCancel?.();
  }, [closePickerInput, onClickCancel]);

  const handleSubmit = useCallback(() => {
    closePickerInput();
    onClickSubmit(state.time as string);
  }, [closePickerInput, onClickSubmit, state.time]);

  return (
    <footer
      tw="flex items-center px-5 py-2.5 justify-between rounded-b-lg border-t border-t-grey-five bg-white"
      css={rootStyle}
    >
      <Button.Outlined
        data-testid={`${testID}:ClockPicker:PickerFooter:Cancel`}
        disabled={cancelDisabled}
        onClick={handleCancel}
      >
        {translator.translate(cancelLabel)}
      </Button.Outlined>

      <Button.Solid
        data-testid={`${testID}:ClockPicker:PickerFooter:Submit`}
        disabled={submitDisabled}
        onClick={handleSubmit}
      >
        {translator.translate(submitLabel)}
      </Button.Solid>
    </footer>
  );
}
