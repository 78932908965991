import React from 'react';
import tw, { styled, theme } from 'twin.macro';
import { SOActivitySelectionType } from '../../../constant';
import { SOSelectionItemProps } from '../../../constant/ShipperOrder.constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import {
  getSOActivitySelectionTypeIllustration,
  getSOActivitySelectionTypeLabel,
} from '../../../util/shipperOrderCreate.util';
import { CheckCircle } from '../../atom/Icon/Icon.atom';
import { BodyFourteen, HeadingFour } from '../../atom/Text/Text.atom';

export interface SOActivityMethodSelectionProps {
  description: string;
  translator: UseTranslator;
  selected?: SOActivitySelectionType;
  handleSelect(type: SOActivitySelectionType): void;
}
const Container = tw.div`mx-auto`;

const CardSelectionContainer = tw.div`flex justify-between pb-5 gap-5`;
const CircleContainer = styled.div(({ selected }: { selected?: boolean }) => [
  tw`rounded-3xl w-4 h-4 mx-auto`,
  !selected && tw`border-2 border-beige-lines`,
]);
const Card = styled.div((props: { selected?: boolean }) => [
  tw`
  grid gap-5 cursor-pointer
  width[212px] border-beige-lines border-[1px] rounded p-5 items-center hover:(bg-orange-hover) text-center `,
  props?.selected && tw`bg-orange-hover`,
]);
const DescriptionContainer = tw.div`text-center w-[500px] mx-auto`;

function SOActivityMethodSelectionItem({
  selected,
  image,
  title,
  onClick,
}: SOSelectionItemProps) {
  return (
    <Card selected={selected} onClick={onClick}>
      {image}
      <HeadingFour>{title}</HeadingFour>
      <CircleContainer selected={selected}>
        {selected && (
          <CheckCircle
            width={16}
            height={16}
            fill={theme`colors.orange.DEFAULT`}
          />
        )}
      </CircleContainer>
    </Card>
  );
}

export default function SOActivityMethodSelection({
  description,
  translator,
  selected,
  handleSelect,
}: SOActivityMethodSelectionProps) {
  return (
    <Container>
      <CardSelectionContainer>
        {Object.values(SOActivitySelectionType).map((item) => {
          if (item === SOActivitySelectionType.CHANGE_TEMPLATE) return null;
          return (
            <SOActivityMethodSelectionItem
              key={item}
              image={getSOActivitySelectionTypeIllustration(item)}
              selected={item === selected}
              title={translator.translate(
                getSOActivitySelectionTypeLabel(item),
              )}
              onClick={() => handleSelect(item)}
            />
          );
        })}
      </CardSelectionContainer>
      <DescriptionContainer>
        <BodyFourteen tw="whitespace-normal">{description}</BodyFourteen>
      </DescriptionContainer>
    </Container>
  );
}
