import React from 'react';
import { RouteConfig } from '../../../config/router/router.config';

export type ReportFinanceSearchParams = {
  orderBy?: string; // ReportFinanceOrderBy
  driverId?: string;
  vehicleId?: string;
  startDate?: string; // milliseconds
  endDate?: string; // milliseconds
  page?: string;
  pageSize?: string;
  search?: string;
};

export const reportFinanceRoute: RouteConfig = {
  name: 'Net Income',
  path: '/report/net-income',
  Component: React.lazy(() => import('./ReportFinance.view')),
  isPrivate: true,

  breadcrumb: 'Net Income',

  options: {
    icon: <div />,
    label: 'Net Income',
    testID: 'MenuReportNetIncome',
  },
};
