import sortBy from 'lodash/sortBy';
import React, { Fragment } from 'react';
import tw from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { ActivityTemplate } from '../../../model/ActivityTemplate.model';
import { Icon, Text } from '../../atom';
import { CustomTableRowWithPopover } from '../../molecule';

// #region STYLED
const Container = tw.td`flex flex-col space-y-3 padding-left[100px]`;
const Section = tw.div`flex flex-col`;
const SectionLocationContainer = tw.div`flex flex-wrap items-center`;
const IconWrapper = tw.div`flex items-center justify-center mx-2 mb-2`;
const SectionLocation = tw.div`mb-2 py-1 px-2 max-w-full bg-beige-bg rounded-t-md border-b-2 border-b-grey-two`;
// #endregion

// #region INTERFACES
type Props = {
  expandedData: ActivityTemplate;
  translate: UseTranslator['translate'];
};
// #endregion

export default function ActivityTemplateExpandedSection({
  expandedData,
  translate,
}: Props) {
  return (
    <Container>
      <Section>
        <Text.Paragraph tw="text-grey-three">
          {translate('Activity Template Type')}
        </Text.Paragraph>
        <Text.Paragraph tw="whitespace-normal break-words">
          {expandedData.shipper
            ? `${translate('Shipper')}: ${expandedData.shipper.name}`
            : translate('Public')}
        </Text.Paragraph>
      </Section>

      <Section>
        <Text.Paragraph tw="text-grey-three">
          {translate('Description')}
        </Text.Paragraph>
        <Text.Paragraph tw="whitespace-normal break-words">
          {expandedData.description ?? '-'}
        </Text.Paragraph>
      </Section>

      <Section>
        <Text.Paragraph tw="text-grey-three">
          {translate('Location Details')}
        </Text.Paragraph>

        <SectionLocationContainer>
          {sortBy(expandedData.items, ['index']).map((item, idx) => (
            <Fragment key={item.id}>
              {idx !== 0 && (
                <IconWrapper>
                  <Icon.ChevronSharp height={18} width={18} strokeWidth={2} />
                </IconWrapper>
              )}

              <SectionLocation>
                <CustomTableRowWithPopover
                  primaryLabel={`${item.index + 1}. ${item.location.name}`}
                  tooltipPlacement="top"
                  customTooltip={
                    <Text.Paragraph tw="whitespace-normal break-words max-w-xl">
                      {item.location.address}
                    </Text.Paragraph>
                  }
                />
              </SectionLocation>
            </Fragment>
          ))}
        </SectionLocationContainer>
      </Section>
    </Container>
  );
}
