import { useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { SelectionSO } from '../../constant';
import { JobOrderFormNavigateParams } from '../../constant/JobOrder.constant';
import { ShipperOrder } from '../../model/ShipperOrder.model';
import { mapSOActivitiesFromDeliveries } from '../../util/jobOrder.util';
import { joInitialValues } from '../../util/jobOrderCreate.util';
import { generateJOFormValues } from '../../util/jobOrderDetail/jobOrderDetail.util';
import useJobOrderInfo from '../useJobOrderInfo/useJobOrderInfo.hook';

export default function useJOFormInitialization() {
  const locationState = useLocation().state as
    | JobOrderFormNavigateParams
    | undefined;
  const params = useParams();
  const navigationStateRef = useRef(locationState);
  const { joInfoData: joInfo } = useJobOrderInfo();

  const [selectedSOListState, setSelectedSOListState] = useState<
    ShipperOrder[] | undefined
  >(undefined);

  const formStepNav = useMemo(() => navigationStateRef.current?.formStep, []);
  const formValuesNav = useMemo(
    () => navigationStateRef.current?.formValues,
    [],
  );
  const infoInitValues = useMemo(() => generateJOFormValues(joInfo), [joInfo]);
  const initValues = useMemo(
    () => formValuesNav || infoInitValues || joInitialValues,
    [formValuesNav, infoInitValues],
  );

  const initSelectedSO = useMemo((): SelectionSO[] => {
    const selectedSOList = navigationStateRef.current?.selectedSO;
    if (selectedSOList?.length) return selectedSOList;
    if (joInfo) return joInfo.deliveries.map(mapSOActivitiesFromDeliveries);
    return [];
  }, [joInfo]);

  const isFormInitialValid = useMemo(() => !!params?.id, [params?.id]);

  const joDeliveryDimensionTotal = useMemo(
    () => ({
      weight: joInfo?.totalGoodsWeight || 0,
      volume: joInfo?.totalGoodsVolume || 0,
    }),
    [joInfo?.totalGoodsVolume, joInfo?.totalGoodsWeight],
  );
  return {
    joInfo,
    formStepNav,
    navigationStateRef,
    infoInitValues,
    initValues,
    initSelectedSO,
    selectedSOListState,
    isFormInitialValid,
    joDeliveryDimensionTotal,
    setSelectedSOListState,
  };
}

export type UseJOFormInitializationHookObj = ReturnType<
  typeof useJOFormInitialization
>;
