import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store/store.app';
import localeConfig, {
  SupportedLanguage,
} from '../config/locale/locale.config';
import { template } from '../util/helper.util';

export type UseTranslator = {
  currentLanguage: SupportedLanguage;
  /**
   * By default you dont need to add second argument
   * Currently it used only for public tracking page
   */
  translate: (key: string, lang?: SupportedLanguage) => string;
  transpolate: (key: string, payload: Record<string, string>) => string;
};

const useTranslator = (): UseTranslator => {
  // #region REQUIRED STATE
  const currentLanguage = useSelector(
    (state: RootState) => state.setting.currentLanguage,
  );
  // #endregion

  // #region TRANSLATE
  const translate = useCallback(
    (key: string, lang?: SupportedLanguage): string => {
      const translationIndex = localeConfig.findIndex(
        (find) => find.en === key,
      );

      if (
        translationIndex >= 0 &&
        localeConfig[translationIndex][lang || currentLanguage] !== null
      ) {
        return localeConfig[translationIndex][lang || currentLanguage] || key;
      }

      return key;
    },
    [currentLanguage],
  );

  const transpolate = useCallback(
    (
      key: string,
      payload: Record<string, string>,
      templateRegex = /"(.*?)"/g,
    ): string => {
      // let's say `key` => "full_name" is required
      // let's say `payload` => { full_name: 'Full Name' }
      const templated = template(key, payload, templateRegex as RegExp); // output => Full Name is required
      const translationIndex = localeConfig.findIndex(
        (find) => find.en === templated,
      );

      if (
        translationIndex >= 0 &&
        localeConfig[translationIndex][currentLanguage] !== null
      ) {
        const translated =
          localeConfig[translationIndex][currentLanguage] || templated; // output => Nama Lengkap wajib diisi
        return translated;
      }

      return templated;
    },
    [currentLanguage],
  );
  // #endregion

  return {
    currentLanguage,
    translate,
    transpolate,
  };
};

export default useTranslator;
