import React from 'react';
import tw from 'twin.macro';
import { Button, Text } from '../../atom';
import { BodySixteen } from '../../atom/Text/Text.atom';

const Container = tw.div`flex items-center`;
const LabelContainer = tw.div`w-[180px] mr-3`;
const InfoContainer = tw.div`flex`;
const MetaContainer = tw.div`flex items-center flex-1 rounded  bg-grey-six truncate py-3 px-5 width[400px]`;

interface Props {
  label: string;
  activityName?: string;
  buttonLabel: string;
  onClickAction?: () => void;
}

export default function SOCActivityInfo({
  label,
  activityName,
  buttonLabel,
  onClickAction = () => {},
}: Props) {
  return (
    <Container>
      <LabelContainer>
        <Text.Label tw="text-grey-three">{label}</Text.Label>
      </LabelContainer>
      <InfoContainer>
        <MetaContainer>
          <BodySixteen tw="truncate text-grey-three">
            {activityName}
          </BodySixteen>
        </MetaContainer>
        <Button.Solid tw="min-w-[90px]" type="button" onClick={onClickAction}>
          {buttonLabel}
        </Button.Solid>
      </InfoContainer>
    </Container>
  );
}
