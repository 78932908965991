import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JOFormStep } from '../../constant/JobOrder.constant';
import { logEvent } from '../../service/analytic/analytic.service';
import { jobOrderRoute } from '../../view/JobOrder/JobOrder.route';
import { UseJOFormInitializationHookObj } from './useJOFormInitialization.hook';
import useJOFormSOController from './useJOFormSOController.hook';

type Props = {
  initialization: UseJOFormInitializationHookObj;
};

const analyticsMapper = {
  [JOFormStep.HEADER]: 'Header',
  [JOFormStep.SO_SELECTION]: 'SOSelection',
  [JOFormStep.ASSIGNMENT]: 'Assignment',
};

export default function useJOFormController({
  initialization: { formStepNav, initSelectedSO, joDeliveryDimensionTotal },
}: Props) {
  const navigate = useNavigate();

  const [formStep, setFormStep] = useState<JOFormStep>(JOFormStep.HEADER);
  const [showActivityModal, setShowActivityModal] = useState<
    string | undefined
  >(undefined);

  const soController = useJOFormSOController({ initSelectedSO });

  useEffect(() => {
    if (formStepNav) setFormStep(formStepNav);
    logEvent(
      `JobOrder:Create:ChangeSectionTo${
        analyticsMapper[formStepNav || JOFormStep.HEADER]
      }`,
      'JobOrder',
    );
  }, [formStepNav]);

  // #region CONTROLLER
  const handleBackHeader = () => {
    navigate({ pathname: jobOrderRoute.path }, { replace: true });
  };
  const handleChangeFormState = (state: JOFormStep) => {
    logEvent(
      `JobOrder:Create:ChangeSectionTo${analyticsMapper[state]}`,
      'JobOrder',
    );
    setFormStep(state);
    soController.handleClearSelectedSO();
  };
  const handleOpenActivityModal = (id: string) => {
    setShowActivityModal(id);
  };
  const handleCloseActivityModal = () => {
    setShowActivityModal(undefined);
  };
  // #endregion

  const deliveryDimensionTotal = useMemo(
    () => ({
      volume:
        joDeliveryDimensionTotal.volume +
        soController.selectionSODimensionTotal.volume,
      weight:
        joDeliveryDimensionTotal.weight +
        soController.selectionSODimensionTotal.weight,
    }),
    [joDeliveryDimensionTotal, soController.selectionSODimensionTotal],
  );

  return {
    formStep,
    showActivityModal,
    soController,
    deliveryDimensionTotal,
    handleBackHeader,
    handleChangeFormState,
    handleOpenActivityModal,
    handleCloseActivityModal,
  };
}

export type UseJOFormControllerHookObj = ReturnType<typeof useJOFormController>;
