import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store/store.app';
import { sidebarHelpCenterConfig } from '../../../config/router/router.config';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import { LogoutReason, sessionAction } from '../../../store/session.store';

export default function useWrapperHelpCenterViewModel() {
  const dispatch = useDispatch();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { isMobile } = useScreenDetection();

  const { isLoggedIn } = useSelector(
    (state: RootState) => state.session,
    shallowEqual,
  );

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(
        sessionAction.logout({
          type: LogoutReason.CLEAR,
        }),
      );
    }
  }, [dispatch, isLoggedIn]);

  const handleCloseMobileMenu = useCallback(() => {
    setShowMobileMenu(false);
  }, []);

  const handleOpenMobileMenu = useMemo(() => {
    if (!isMobile) return undefined;
    return () => setShowMobileMenu((v) => !v);
  }, [isMobile]);

  return {
    isLoggedIn,
    isMobile,
    sidebarItems: sidebarHelpCenterConfig,
    showMobileMenu,
    handleOpenMobileMenu,
    handleCloseMobileMenu,
  };
}
