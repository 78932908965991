import React from 'react';
import tw from 'twin.macro';
import useOnboardingPopup from '../../../hook/home/useOnboardingPopup.hook';
import { UseTopbarOnboardingHookObj } from '../../../hook/useTopbarOnboarding/useTopbarOnboarding.hook';
import { Icon, Text } from '../../atom';
import { OnboardingDetails } from '../../molecule';

const RootContainer = tw.div`flex flex-col shadow-card rounded-md bg-white width[600px] animate-slide-in-top`;

type OnboardingPopupProps = {
  topbarOnboarding: UseTopbarOnboardingHookObj;
};

export default function OnboardingPopup({
  topbarOnboarding,
}: OnboardingPopupProps) {
  const onboarding = useOnboardingPopup();

  return (
    <RootContainer>
      <div tw="flex items-center justify-between py-3 px-5 border-b border-beige-lines">
        <Text.HeadingFour>{onboarding.progressLabel}</Text.HeadingFour>

        <button
          type="button"
          tw="w-5 h-5 rounded-full bg-beige-bg hover:bg-beige-lines"
          onClick={topbarOnboarding.handleToggleOnboardingPopup}
        >
          <Icon.Close width={18} height={18} viewBox="0 0 24 24" />
        </button>
      </div>

      <div tw="flex justify-between items-center py-5 px-6 border-b border-beige-lines">
        <Text.HeadingThree>
          {onboarding.homeOnboarding.firstSectionTitleLabel}
        </Text.HeadingThree>

        <div tw="flex space-x-1">
          <span
            tw="w-12 h-2.5 rounded-l-full bg-grey-five"
            css={[
              onboarding.homeOnboarding.firstSectionProgress >= 1 &&
                tw`bg-status-success`,
            ]}
          />
          <span
            tw="w-12 h-2.5 bg-grey-five"
            css={[
              onboarding.homeOnboarding.firstSectionProgress >= 2 &&
                tw`bg-status-success`,
            ]}
          />
          <span
            tw="w-12 h-2.5 rounded-r-full bg-grey-five"
            css={[
              onboarding.homeOnboarding.firstSectionProgress === 3 &&
                tw`bg-status-success`,
            ]}
          />
        </div>
      </div>

      <OnboardingDetails.Accordion
        identity="addShipper"
        details={topbarOnboarding.details}
        handleClickAccordion={topbarOnboarding.handleClickAccordion}
        title={`1. ${onboarding.homeOnboarding.addShipperTitleLabel}`}
        subtitle={onboarding.homeOnboarding.addShipperSubtitleLabel}
        checked={onboarding.homeOnboarding.isAddShipperChecked}
        btnLabel={onboarding.homeOnboarding.addShipperBtnLabel}
        btnOnClick={() => {
          topbarOnboarding.handleCloseOnboardingPopup();
          onboarding.homeOnboarding.handleClickAddShipper();
        }}
      />

      <OnboardingDetails.Accordion
        identity="addDeliveryLocation"
        details={topbarOnboarding.details}
        handleClickAccordion={topbarOnboarding.handleClickAccordion}
        title={`2. ${onboarding.homeOnboarding.addDeliveryLocationTitleLabel}`}
        subtitle={onboarding.homeOnboarding.addDeliveryLocationSubtitleLabel}
        checked={onboarding.homeOnboarding.isAddDeliveryLocationChecked}
        btnLabel={onboarding.homeOnboarding.addDeliveryLocationBtnLabel}
        btnOnClick={() => {
          topbarOnboarding.handleCloseOnboardingPopup();
          onboarding.homeOnboarding.handleClickAddLocation();
        }}
      />

      <OnboardingDetails.Accordion
        identity="createShipperOrder"
        details={topbarOnboarding.details}
        handleClickAccordion={topbarOnboarding.handleClickAccordion}
        title={`3. ${onboarding.homeOnboarding.createShipperOrderTitleLabel}`}
        subtitle={onboarding.homeOnboarding.createShipperOrderSubtitleLabel}
        checked={onboarding.homeOnboarding.isCreateShipperOrderChecked}
        btnLabel={onboarding.homeOnboarding.createShipperOrderBtnLabel}
        btnDisabled={onboarding.homeOnboarding.isCreateShipperOrderBtnDisabled}
        btnDisabledLabel={
          onboarding.homeOnboarding.createShipperOrderDisabledLabel
        }
        btnOnClick={() => {
          topbarOnboarding.handleCloseOnboardingPopup();
          onboarding.homeOnboarding.handleClickCreateShipperOrder();
        }}
      />

      <div tw="flex justify-between items-center py-5 px-6 border-b border-beige-lines">
        <Text.HeadingThree>
          {onboarding.homeOnboarding.secondSectionTitleLabel}
        </Text.HeadingThree>

        <div tw="flex space-x-1">
          <span
            tw="w-12 h-2.5 rounded-l-full bg-grey-five"
            css={[
              onboarding.homeOnboarding.secondSectionProgress >= 1 &&
                tw`bg-status-success`,
            ]}
          />
          <span
            tw="w-12 h-2.5 rounded-r-full bg-grey-five"
            css={[
              onboarding.homeOnboarding.secondSectionProgress === 2 &&
                tw`bg-status-success`,
            ]}
          />
        </div>
      </div>

      <OnboardingDetails.Accordion
        identity="addDriver"
        details={topbarOnboarding.details}
        handleClickAccordion={topbarOnboarding.handleClickAccordion}
        title={`1. ${onboarding.homeOnboarding.addDriverTitleLabel}`}
        subtitle={onboarding.homeOnboarding.addDriverSubtitleLabel}
        checked={onboarding.homeOnboarding.isAddDriverChecked}
        btnLabel={onboarding.homeOnboarding.addDriverBtnLabel}
        btnOnClick={() => {
          topbarOnboarding.handleCloseOnboardingPopup();
          onboarding.homeOnboarding.handleClickAddDriver();
        }}
      />

      <OnboardingDetails.Accordion
        identity="createJobOrder"
        details={topbarOnboarding.details}
        handleClickAccordion={topbarOnboarding.handleClickAccordion}
        title={`2. ${onboarding.homeOnboarding.createJobOrderTitleLabel}`}
        subtitle={onboarding.homeOnboarding.createJobOrderSubtitleLabel}
        checked={onboarding.homeOnboarding.isCreateJobOrderChecked}
        btnLabel={onboarding.homeOnboarding.createJobOrderBtnLabel}
        btnDisabled={onboarding.homeOnboarding.isCreateJobOrderBtnDisabled}
        btnDisabledLabel={onboarding.homeOnboarding.createJobOrderDisabledLabel}
        btnOnClick={() => {
          topbarOnboarding.handleCloseOnboardingPopup();
          onboarding.homeOnboarding.handleClickCreateJobOrder();
        }}
      />
    </RootContainer>
  );
}
