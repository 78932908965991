import { useCallback } from 'react';
import { ErrorCodes } from '../../../constant';
import api from '../../../service/api.service';
import { SessionStore } from '../../../store/session.store';
import useAuthRevoke from './useAuthRevoke.hook';
import { ClientAuthenticationResponse } from './useSession.hook';

/**
 * Same as it successor `checkAuth`, `refreshToken` ONLY executed when
 * user already logged-in (page refresh, page re-visit)
 * @returns
 */
function useRefreshToken() {
  const [refreshToken] = api.useRefreshTokenMutation();
  const [authTest] = api.useAuthTestMutation();
  const { handleAuthRevoke } = useAuthRevoke();

  const handleRefreshToken = useCallback(
    async (
      ssoToken: string,
      runnerToken: string,
    ): Promise<
      ClientAuthenticationResponse<
        Omit<SessionStore, 'isLoggedIn' | 'organizationName'>
      >
    > => {
      try {
        const refreshTokenResponse = await refreshToken({
          ssoToken,
          runnerToken,
        }).unwrap();

        if (refreshTokenResponse.auth !== 'true') {
          return {
            ok: false,
            code: ErrorCodes.SSO_REFRESH_TOKEN_FAILED,
          };
        }

        const authTestRes = await authTest({
          token: refreshTokenResponse.data.result.token,
        }).unwrap();
        if (!authTestRes.ok) {
          handleAuthRevoke(refreshTokenResponse.globalSessionID);
        }
        return {
          ok: true,
          data: {
            token: refreshTokenResponse.data.result.token,
            globalSessionID: refreshTokenResponse.globalSessionID,
            username: refreshTokenResponse.data.result.username,
            organizationId: refreshTokenResponse.data.result.organizationId,
            userId: refreshTokenResponse.data.result.userId,
          },
        };
      } catch (error: unknown) {
        return {
          ok: false,
          code: ErrorCodes.SSO_REFRESH_TOKEN_FAILED,
        };
      }
    },
    [handleAuthRevoke, refreshToken, authTest],
  );

  return { handleRefreshToken };
}

export default useRefreshToken;
