import fromUnixTime from 'date-fns/fromUnixTime';
import React, { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import tw from 'twin.macro';
import { Image } from '../../../component/atom';
import { BodySixteen } from '../../../component/atom/Text/Text.atom';
import {
  JODetailSpendingMeta,
  JOSpendingTab,
} from '../../../constant/JobOrder.constant';
import useSpendingInfo from '../../../hook/useSpendingInfo/useSpendingInfo.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { Photo } from '../../../model/Common.model';
import { logEvent } from '../../../service/analytic/analytic.service';
import { numberFormatter } from '../../../util/formatter.util';
import { getShortFormatDate } from '../../../util/helper.util';
import { getExpenseLabelByType } from '../../../util/jobOrderDetail/jobOrderDetail.util';

type Props = {
  id?: string;
  handleClickThumbnail: (photos: Photo[], index: number) => void;
};

const analyticsMapper = {
  [JOSpendingTab.DETAILS]: 'ExpenseDetail',
  [JOSpendingTab.TIMELINE]: 'ExpenseTimeline',
};
const ValueLabel = tw(BodySixteen)`overflow-wrap[anywhere]`;
const ImageList = tw.div`grid-cols-5 grid gap-4`;
const ImageContainer = tw.div`w-[98px] h-[98px] overflow-hidden rounded cursor-pointer`;
const ImageComponent = tw(Image)`w-[98px] h-[98px]`;

export default function useJODetailSpendingDetail({
  id,
  handleClickThumbnail,
}: Props) {
  const translator = useTranslator();
  const [tab, setTab] = useState(JOSpendingTab.DETAILS);

  useMount(() => {
    logEvent('JobOrder:Detail:ExpenseDetail', 'JobOrder');
  });

  useEffect(() => {
    logEvent(
      `JobOrder:Detail:ExpenseDetai:ChangeTabTo${analyticsMapper[tab]}`,
      'JobOrder',
    );
  }, [tab]);

  const spendingInfo = useSpendingInfo({ id });

  const detailContent: JODetailSpendingMeta[] = [
    {
      title: translator.translate('Category'),
      content: (
        <ValueLabel>
          {translator.translate(
            getExpenseLabelByType(spendingInfo.spendingData?.type),
          )}
        </ValueLabel>
      ),
    },
    {
      title: translator.translate('Date'),
      content: (
        <ValueLabel>
          {spendingInfo.spendingData?.date
            ? getShortFormatDate(
                fromUnixTime(spendingInfo.spendingData?.date),
                translator.currentLanguage,
              )
            : '-'}
        </ValueLabel>
      ),
    },
    {
      title: translator.translate('Amount'),
      content: (
        <ValueLabel>
          Rp{numberFormatter(spendingInfo.spendingData?.amount || 0)}
        </ValueLabel>
      ),
    },
    {
      title: translator.translate('Descriptions'),
      content: (
        <ValueLabel>{spendingInfo.spendingData?.description || '-'}</ValueLabel>
      ),
    },
    {
      title: translator.translate('Photo(s)'),
      content: (
        <ImageList>
          {spendingInfo.spendingData?.photos.length
            ? spendingInfo.spendingData?.photos?.map(
                ({ id: photoId, accessUrl }, idx, arr) => (
                  <ImageContainer
                    key={photoId}
                    onClick={() => handleClickThumbnail(arr, idx)}
                  >
                    <ImageComponent src={accessUrl} />
                  </ImageContainer>
                ),
              )
            : '-'}
        </ImageList>
      ),
    },
  ];

  return { tab, translator, spendingInfo, detailContent, setTab };
}
