import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { delay } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarTheme } from '../../component/molecule/Snackbar/Snackbar.molecule';
import { SelectionSO } from '../../constant';
import {
  ApiErrorResponse,
  CommonApiResponse,
} from '../../service/api.endpoint';
import api from '../../service/api.service';
import {
  JobOrderUpdateResponse,
  PostJobOrderCreateResponse,
} from '../../service/endpoint/jobOrder/jobOrder.endpoint';
import { snackbarAction } from '../../store/snackbar.store';
import { errorCodeToLabel } from '../../util/error.util';
import useOrganizationOrientation from '../organization/useOrganizationOrientation.hook';
import useTranslator from '../useTranslator.hook';
import { JOSubmissionModalProps } from './useJOFormSubmissionModal.hook';

type Props = {
  setIsLoading: (val: boolean) => void;
  setHasUnsavedChanges: (val: boolean) => void;
  setJOSubmissionModalProps: (val?: {
    joNumber: string;
    vehicleName?: string;
    driverName?: string;
  }) => void;
  setSelectionSOList: Dispatch<SetStateAction<SelectionSO[]>>;
};

export default function useJOFormHandler({
  setIsLoading,
  setHasUnsavedChanges,
  setSelectionSOList,
  setJOSubmissionModalProps,
}: Props) {
  const { translate } = useTranslator();
  const dispatch = useDispatch();
  const { handleFetchOrganizationData } = useOrganizationOrientation();

  const submitTimerId = useRef<number | undefined>(undefined);

  // #region API CALL
  const [assignJOMutation, assignJOMutationResponse] =
    api.useAssignJobOrdersMutation();
  // #endregion

  // #region HANDLER
  const handleError = useCallback(
    (error: FetchBaseQueryError | SerializedError) => {
      if (!error) return;
      const data = (error as FetchBaseQueryError).data as ApiErrorResponse;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translate(errorCodeToLabel(data?.error?.code)),
        }),
      );
      setIsLoading(false);
    },
    [dispatch, setIsLoading, translate],
  );

  const handleSuccessJOAssign = useCallback(
    (response: CommonApiResponse, submission?: JOSubmissionModalProps) => {
      if (!response || (response && !response.ok)) return;
      setHasUnsavedChanges(false);
      submitTimerId.current = delay(() => {
        dispatch(
          snackbarAction.show({
            message: translate('Job Order Successfully Assigned'),
          }),
        );
        setJOSubmissionModalProps(submission);
      }, 1000);
    },
    [dispatch, setHasUnsavedChanges, setJOSubmissionModalProps, translate],
  );

  const handleSuccessJOCreate = useCallback(
    ({
      submission,
      response,
      isDraft,
    }: {
      submission?: JOSubmissionModalProps;
      response: PostJobOrderCreateResponse;
      isDraft?: boolean;
    }) => {
      if (!response || (response && !response.ok)) return;
      submitTimerId.current = delay(async () => {
        try {
          handleFetchOrganizationData();
          if (isDraft) {
            setHasUnsavedChanges(false);
            dispatch(
              snackbarAction.show({
                message: translate(
                  'Job order has been created and saved as Draft',
                ),
              }),
            );
            setJOSubmissionModalProps(submission);
            return;
          }
          const res = await assignJOMutation({
            id: response.jobOrder.id,
          }).unwrap();
          handleSuccessJOAssign(res, {
            ...submission,
            joNumber: response.jobOrder.number,
          });
        } catch (err) {
          handleError(err as FetchBaseQueryError);
        } finally {
          assignJOMutationResponse.reset();
        }
      }, 1000);
    },
    [
      handleFetchOrganizationData,
      assignJOMutation,
      handleSuccessJOAssign,
      setHasUnsavedChanges,
      setJOSubmissionModalProps,
      dispatch,
      translate,
      handleError,
      assignJOMutationResponse,
    ],
  );

  const handleSuccessJOEdit = useCallback(
    ({
      id,
      submission,
      response,
      isDraft,
    }: {
      id: string;
      isDraft?: boolean;
      submission?: JOSubmissionModalProps;
      response: JobOrderUpdateResponse;
    }) => {
      if (!response || (response && !response.ok)) return;
      submitTimerId.current = delay(async () => {
        try {
          if (isDraft) {
            setHasUnsavedChanges(false);
            dispatch(
              snackbarAction.show({
                message: translate('Job order has been updated'),
              }),
            );
            setJOSubmissionModalProps(submission);

            return;
          }
          const res = await assignJOMutation({ id }).unwrap();
          handleSuccessJOAssign(res, submission);
        } catch (err) {
          handleError(err as FetchBaseQueryError);
        } finally {
          assignJOMutationResponse.reset();
        }
      }, 1000);
    },
    [
      dispatch,
      translate,
      handleError,
      assignJOMutation,
      handleSuccessJOAssign,
      setHasUnsavedChanges,
      setJOSubmissionModalProps,
      assignJOMutationResponse,
    ],
  );

  const handleDeliveryError = useCallback(
    (deliveryLocationSameAsCurrentLocationList: SelectionSO[]) => {
      // trigger error alert
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translate(
            "The delivery location can't be the same as current location. Please select another delivery location.",
          ),
        }),
      );

      // reset `deliveryLocation` back to original state for that list
      setSelectionSOList((prev) =>
        prev.map((_selectionSO) => {
          const affectedSO = deliveryLocationSameAsCurrentLocationList.find(
            (_so) => _so.id === _selectionSO.id,
          );
          return affectedSO
            ? { ...affectedSO, deliveryLocation: undefined }
            : _selectionSO;
        }),
      );
    },
    [dispatch, setSelectionSOList, translate],
  );
  // #endregion

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(
    () => () => {
      clearTimeout(submitTimerId.current);
    },
    [submitTimerId],
  );

  return {
    handleError,
    handleSuccessJOAssign,
    handleSuccessJOCreate,
    handleSuccessJOEdit,
    handleDeliveryError,
    assignJOMutationResponse,
  };
  // #endregion
}
