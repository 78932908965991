import React from 'react';
import { Icon } from '../../component/atom';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type DriverRouteParam = DefaultRouteParam;

export const driverRoute: RouteConfig = {
  name: 'Driver',
  Component: React.lazy(() => import('./Driver.view')),
  path: '/driver',
  isPrivate: true,

  options: {
    icon: <Icon.Driver />,
    label: 'Driver',
    testID: 'MenuDriver',
  },
};
