import React from 'react';
import tw, { styled, theme } from 'twin.macro';
import { ExtraStyle } from '../../Type.component';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator.atom';
import { HeadingFive } from '../Text/Text.atom';

type Props = {
  testID?: string;
  disabled?: boolean;
  hasOutline?: boolean;
  small?: boolean;
  feature?: 'flat' | 'rounded';
};

type IconButtonProps = {
  isLoading?: boolean;
  label: string;
  icon: JSX.Element;
  action: () => void;
  isDisabled?: boolean;
  hasOutline?: boolean;
  containerStyle?: ExtraStyle;
  labelStyle?: ExtraStyle;
};

const baseButton = tw`rounded font-semibold items-center justify-center inline-flex space-x-2 select-none cursor-pointer duration-200`;
const solidButton = tw`border-0 text-white bg-orange hover:bg-orange-hover-button active:bg-orange-hover-button`;
const outlinedButton = tw`text-black box-border border border-solid border-beige-lines bg-transparent hover:(bg-orange-hover)   active:(border-beige-lines bg-orange-hover)`;
const textButton = tw`text-orange border-none bg-transparent hover:opacity-70`;
const solidButtonDisabled = tw`bg-grey-four cursor-default text-white`;
const outlinedButtonDisabled = tw`box-border border border-solid border-grey-four text-grey-four cursor-default`;
const textButtonDisabled = tw`text-grey-four cursor-default`;
const LoadingContainer = tw.div`px-5 ml-2 flex`;
const IconButtonWrapper = styled.div(({ disabled, hasOutline }: Props) => [
  baseButton,
  !disabled && hasOutline && outlinedButton,
  disabled && hasOutline && outlinedButtonDisabled,
]);
const IconButtonContainer = tw.div`inline-grid grid-template-columns[26px 1fr] gap-2 w-max`;
const IconContainer = styled.div`
  & svg {
    width: 20px;
    height: 20px;
  }
`;

const buttonSizes = {
  medium: tw`font-size[16px] line-height[24px] py-2.5 px-4`,
  small: tw`font-size[14px] line-height[24px] py-1 px-4`,
};

export const Solid = styled.button(({ small, disabled }: Props) => [
  baseButton,
  small ? buttonSizes.small : buttonSizes.medium,
  !disabled && solidButton,
  disabled && solidButtonDisabled,
]);

export const Outlined = styled.button(({ small, disabled }: Props) => [
  baseButton,
  small ? buttonSizes.small : buttonSizes.medium,
  !disabled && outlinedButton,
  disabled && outlinedButtonDisabled,
]);

export const Text = styled.button(({ small, disabled }: Props) => [
  baseButton,
  small ? buttonSizes.small : buttonSizes.medium,
  tw`p-0`,
  !disabled && textButton,
  disabled && textButtonDisabled,
]);

export function Icon({
  label,
  icon,
  action,
  isDisabled,
  hasOutline,
  containerStyle,
  isLoading,
  labelStyle,
}: IconButtonProps) {
  return (
    <IconButtonWrapper
      disabled={isDisabled}
      hasOutline={hasOutline}
      css={containerStyle}
      onClick={!isDisabled && !isLoading ? action : undefined}
    >
      {!isLoading ? (
        <Text disabled={isDisabled} tw="text-orange">
          <IconButtonContainer>
            <IconContainer
              css={[tw`flex items-center`, isDisabled && tw`text-grey-four`]}
            >
              {icon}
            </IconContainer>
            <HeadingFive
              css={
                labelStyle || [
                  tw`text-orange`,
                  isDisabled && tw`text-grey-four`,
                ]
              }
            >
              {label}
            </HeadingFive>
          </IconButtonContainer>
        </Text>
      ) : (
        <LoadingContainer>
          <LoadingIndicator size="small" color={theme`colors.white`} />
        </LoadingContainer>
      )}
    </IconButtonWrapper>
  );
}
