import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { ShipperOrderDetailTab } from '../../constant';
import {
  ShipperOrderSearchParam,
  SOUpdateActivitiesProps,
} from '../../model/ShipperOrder.model';
import ShipperOrderDetailView from './ShipperOrderDetail.view';

export type ShipperOrderDetailRouteParam = {
  tab?: ShipperOrderDetailTab;
  activityState?: SOUpdateActivitiesProps[];
  searchParamsFromSOList?: ShipperOrderSearchParam;
};

export const shipperOrderDetailRoute: RouteConfig = {
  name: 'Shipper Order Detail',
  Component: React.lazy(() => import('./ShipperOrderDetail.view')),
  NormalComponent: <ShipperOrderDetailView />,
  path: '/shipper-order/:id',
  isPrivate: true,
  breadcrumb: 'Shipper Order Detail',
  options: {
    label: 'Shipper Order Detail',
    testID: 'ShipperOrderDetail',
  },
  props: {
    isUnclickable: true,
  },
};
