import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fillPath?: string;
};

export default function TruckTime({
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  fill = 'none',
  fillPath,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <path
        fill={fillPath}
        fillRule="evenodd"
        d="M14.667 3H2.222v2.667H4v-.89h8.889v7.264a2.665 2.665 0 00-1.625 1.626h-.153v1.777h.153a2.666 2.666 0 005.028 0H20v-5.333c0-.346-.082-.718-.173-1.036a12.513 12.513 0 00-.659-1.711c-.17-.368-.362-.704-.65-.993-.32-.319-.866-.704-1.63-.704h-2.221V3zm1.625 10.667h1.93v-1.778h-2.666V10.11h2.666c0-.4-.173-.824-.326-1.2a7.318 7.318 0 01-.128-.331c-.165-.45-.415-1.136-.88-1.136h-2.221v4.597a2.668 2.668 0 011.625 1.626zm-2.514 0a.889.889 0 100 1.777.889.889 0 000-1.777z"
        clipRule="evenodd"
      />
      <path
        fill={fillPath}
        d="M5.333 9.222c.491 0 .89.398.89.89v1.328l.664.747a.889.889 0 11-1.33 1.181l-.888-1a.889.889 0 01-.225-.59V10.11c0-.49.398-.889.89-.889z"
      />
      <path
        fill={fillPath}
        fillRule="evenodd"
        d="M10.667 11.889a5.333 5.333 0 11-10.667 0 5.333 5.333 0 0110.667 0zm-5.334 3.555a3.555 3.555 0 100-7.11 3.555 3.555 0 000 7.11z"
        clipRule="evenodd"
      />
    </svg>
  );
}
