import React from 'react';
import tw, { styled } from 'twin.macro';
import { SOActivityType } from '../../../constant';
import { JOFormDeliveryLocationModalSelectedRadio } from '../../../constant/JobOrder.constant';
import { UseJOFormDeliveryLocationModalHookObj } from '../../../hook/jobOrderForm/useJOFormDeliveryLocationModal.hook';
import { Input, Text } from '../../atom';
import { Add } from '../../atom/Icon/Icon.atom';
import { Label } from '../../atom/Text/Text.atom';
import { Modal, SearchInput } from '../../molecule';

// #region INTERFACES
interface Props {
  locationModalController: UseJOFormDeliveryLocationModalHookObj;
}
// #endregion

// #region STYLED
const ActionButton = tw.div`flex gap-2 text-orange cursor-pointer`;
const ScrollbarContainer = styled.div`
  ${tw`flex flex-col max-h-[100px] overflow-y-auto scrollbar-width[thin]`}
  ::-webkit-scrollbar {
    ${tw`w-[7px] h-[7px] rounded-[10px]`}
  }
  ::-webkit-scrollbar-track {
    ${tw`rounded-[10px]`}
  }
  ::-webkit-scrollbar-thumb {
    ${tw`rounded-[10px] bg-grey-four`}
  }
`;
// #endregion

export default function JOFormSOSelectionLocationModal({
  locationModalController: {
    selectedSOFromRow,
    modalTitle,
    modalCallAction,
    transitSelectedSOList,
    modalSearchPlaceholder,
    search,
    locationsQuery,
    selectedDeliveryLocation,
    isSelectedRadioSearch,
    isSelectedRadioLastMile,
    toLabel,
    radioSearchTitleLabel,
    radioSearchSubitleLabel,
    radioLastMileTitleLabel,
    radioLastMileSubitleLabel,
    addLocationLabel,
    isAddNewLocationVisible,
    handleChangeRadio,
    handleAddNewLocation,
    handleSearchLocation,
    handleFetchMoreLocations,
    handleSelectDeliveryLocation,
    handleCloseDeliveryLocationModalAndClearSelectedSO,
  },
}: Props) {
  return (
    <Modal.WithHeader
      css={tw`p-0 w-[785px]`}
      title={modalTitle}
      onOverlayClick={handleCloseDeliveryLocationModalAndClearSelectedSO}
      onClose={handleCloseDeliveryLocationModalAndClearSelectedSO}
      callAction={modalCallAction}
    >
      <div tw="p-6">
        <ScrollbarContainer tw="max-h-[524px]">
          <section tw="w-full mb-5 flex space-x-4">
            <button
              type="button"
              className="group"
              tw="p-4 w-full flex space-x-2 rounded border border-beige-lines hover:(bg-orange-hover border-orange-hover)"
              css={[
                isSelectedRadioSearch &&
                  tw`bg-orange-hover border-orange-hover`,
              ]}
              onClick={() =>
                handleChangeRadio(
                  JOFormDeliveryLocationModalSelectedRadio.SEARCH,
                )
              }
            >
              <Input
                type="radio"
                tw="rounded-full p-2 checked:bg-orange focus:(ring-opacity-0 bg-orange)"
                checked={isSelectedRadioSearch}
                onChange={() =>
                  handleChangeRadio(
                    JOFormDeliveryLocationModalSelectedRadio.SEARCH,
                  )
                }
              />

              <div tw="flex flex-col space-y-2">
                <Text.HeadingFour tw="line-height[18px] text-left group-hover:text-orange">
                  {radioSearchTitleLabel}
                </Text.HeadingFour>
                <Text.BodyFourteen tw="text-left">
                  {radioSearchSubitleLabel}
                </Text.BodyFourteen>
              </div>
            </button>

            <button
              type="button"
              className="group"
              tw="p-4 w-full flex space-x-2 rounded border border-beige-lines hover:(bg-orange-hover border-orange-hover)"
              css={[
                isSelectedRadioLastMile &&
                  tw`bg-orange-hover border-orange-hover`,
              ]}
              onClick={() =>
                handleChangeRadio(
                  JOFormDeliveryLocationModalSelectedRadio.LAST_MILE,
                )
              }
            >
              <Input
                type="radio"
                tw="rounded-full p-2 checked:bg-orange focus:(ring-opacity-0 bg-orange)"
                checked={isSelectedRadioLastMile}
                onChange={() =>
                  handleChangeRadio(
                    JOFormDeliveryLocationModalSelectedRadio.LAST_MILE,
                  )
                }
              />

              <div tw="flex flex-col space-y-2">
                <Text.HeadingFour tw="line-height[18px] text-left group-hover:text-orange">
                  {radioLastMileTitleLabel}
                </Text.HeadingFour>
                <Text.BodyFourteen tw="text-left">
                  {radioLastMileSubitleLabel}
                </Text.BodyFourteen>
              </div>
            </button>
          </section>

          {isSelectedRadioSearch ? (
            <div tw="animate-fade-in">
              <SearchInput
                placeholder={modalSearchPlaceholder}
                value={search}
                loading={locationsQuery.isFetching}
                changeData={handleSearchLocation}
              />

              <ScrollbarContainer
                tw="max-h-[320px] border border-beige-lines"
                onScroll={handleFetchMoreLocations}
              >
                {locationsQuery.list.map((loc) => (
                  <button
                    key={loc.id}
                    type="button"
                    className="group"
                    tw="px-4 py-2 flex flex-col space-y-2 hover:cursor-pointer hover:bg-orange-hover"
                    css={[
                      selectedDeliveryLocation?.id === loc.id &&
                        tw`bg-orange-hover`,
                    ]}
                    onClick={() => handleSelectDeliveryLocation(loc)}
                  >
                    <div tw="flex space-x-2 items-center">
                      <Text.HeadingFive
                        tw="max-w-[500px] truncate group-hover:text-orange"
                        css={[
                          selectedDeliveryLocation?.id === loc.id &&
                            tw`text-orange`,
                        ]}
                      >
                        {loc.name}
                      </Text.HeadingFive>
                    </div>

                    <Text.BodyFourteen tw="text-left text-grey-two">
                      {loc.address}
                    </Text.BodyFourteen>
                  </button>
                ))}
              </ScrollbarContainer>
            </div>
          ) : (
            <ScrollbarContainer tw="max-h-[320px] animate-fade-in">
              {(selectedSOFromRow
                ? [selectedSOFromRow]
                : transitSelectedSOList
              ).map((so) => (
                <div
                  key={so.id}
                  tw="py-2 flex space-x-4 border-b border-beige-lines"
                >
                  <Text.HeadingFive tw="w-[120px] overflow-hidden overflow-ellipsis -webkit-line-clamp[2] whitespace-normal display[-webkit-box] -webkit-box-orient[vertical]">
                    {so.number}
                  </Text.HeadingFive>

                  <Text.BodyFourteen>{toLabel}</Text.BodyFourteen>

                  <div tw="flex flex-col space-y-1">
                    <Text.HeadingFive tw="max-w-[500px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                      {
                        so.activities.filter(
                          (_activity) =>
                            _activity.type === SOActivityType.DROP_OFF,
                        )[0].location.name
                      }
                    </Text.HeadingFive>
                    <Text.BodyFourteen tw="max-w-[500px]">
                      {
                        so.activities.filter(
                          (_activity) =>
                            _activity.type === SOActivityType.DROP_OFF,
                        )[0].location.address
                      }
                    </Text.BodyFourteen>
                  </div>
                </div>
              ))}
            </ScrollbarContainer>
          )}
        </ScrollbarContainer>

        {isAddNewLocationVisible && (
          <div tw="w-full border border-t-0 rounded-b p-5 border-beige-lines animate-fade-in">
            <ActionButton onClick={handleAddNewLocation}>
              <Add />
              <Label tw="text-orange font-bold">{addLocationLabel}</Label>
            </ActionButton>
          </div>
        )}
      </div>
    </Modal.WithHeader>
  );
}
