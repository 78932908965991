import React, { ReactNode, useState } from 'react';
import tw, { theme } from 'twin.macro';
import { SOActivitySelectionType } from '../../../constant';
import { SettingNavigationItemType } from '../../../constant/Setting.constant';
import useToggleSettingModal from '../../../hook/useToggleSettingModal.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { SOActivityFormProps } from '../../../model/ShipperOrder.model';
import { promiseToVoid } from '../../../util/helper.util';
import { UseSOATCreateForm } from '../../../view/ShipperOrderCreate/hooks/useSOATCreateForm.hook';
import { UseSOCreateForm } from '../../../view/ShipperOrderCreate/hooks/useSOCreateForm.hook';
import { Button, Text } from '../../atom';
import { InfoOutlined, Settings } from '../../atom/Icon/Icon.atom';
import { BodyFourteen, HeadingFour } from '../../atom/Text/Text.atom';
import { SOSection } from '../../molecule';
import SOActivityForm from '../SOActivityForm/SOActivityForm.organism';
import SOActivityMethodSelection from '../SOActivityMethodSelection/SOActivityMethodSelection.organism';
import SOActivityTransitForm from '../SOActivityTransitForm/SOActivityTransitForm.organism';

const HeaderContainer = tw.div`flex justify-between`;
const HeaderAction = tw.div`cursor-pointer`;
interface Props {
  translator: UseTranslator;
  soCreateForm: UseSOCreateForm;
  soActivityTemplateCreateForm: UseSOATCreateForm;
  soActivityFormData: SOActivityFormProps;
}

interface FooterProps {
  title: string;
  secondaryAction: ReactNode;
  buttonTestID?: string;
  isActionDisabled?: boolean;
  handleAction?(): void;
}

const FooterContainer = tw.div`rounded-b-md flex flex-row flex-1 justify-between items-center`;
const SecondaryActionContainer = tw.div`flex gap-1 items-center`;
const SecondaryActionButton = tw.div`flex ml-1 gap-1 cursor-pointer items-center`;

function Footer({
  title,
  buttonTestID,
  isActionDisabled,
  secondaryAction,
  handleAction,
}: FooterProps) {
  return (
    <FooterContainer>
      <div>{secondaryAction}</div>
      <Button.Solid
        id={buttonTestID}
        disabled={isActionDisabled}
        onClick={handleAction}
        testID={buttonTestID}
      >
        {title}
      </Button.Solid>
    </FooterContainer>
  );
}

export default function SOCreateActivitySection({
  translator,
  soCreateForm,
  soActivityTemplateCreateForm,
  soActivityFormData,
}: Props) {
  const [activitySelectionType, setActivitySelectionType] = useState<
    SOActivitySelectionType | undefined
  >(soActivityTemplateCreateForm.activityTypeSelected);
  const { toggleModal: toggleModalSetting } = useToggleSettingModal();

  if (soActivityTemplateCreateForm.showActivitySelectionMethod) {
    return (
      <SOSection
        header={
          <HeaderContainer>
            <Text.HeadingFour>{soActivityFormData.title}</Text.HeadingFour>
            {!!soActivityTemplateCreateForm.activityTypeSelected &&
              !!soCreateForm.soForm.values?.activities?.length && (
                <HeaderAction>
                  <Text.HeadingFive
                    tw="text-orange"
                    onClick={
                      soActivityTemplateCreateForm.handleCancelActivitySelectionMethod
                    }
                  >
                    {translator.translate('Cancel')}
                  </Text.HeadingFive>
                </HeaderAction>
              )}
          </HeaderContainer>
        }
        footer={
          <Footer
            secondaryAction={
              <SecondaryActionContainer>
                <InfoOutlined
                  width={20}
                  height={20}
                  fillPath={theme`colors.grey.two`}
                />
                <BodyFourteen>
                  {translator.translate(
                    'To set transit form as default format, go to',
                  )}
                </BodyFourteen>
                <SecondaryActionButton
                  onClick={() =>
                    toggleModalSetting(SettingNavigationItemType.DISPLAY)
                  }
                >
                  <Settings fill={theme`colors.orange.DEFAULT`} />
                  <HeadingFour tw="text-orange">
                    {translator.translate('Settings')}
                  </HeadingFour>
                </SecondaryActionButton>
              </SecondaryActionContainer>
            }
            isActionDisabled={!activitySelectionType}
            handleAction={() => {
              if (!activitySelectionType) return;
              promiseToVoid([
                soActivityTemplateCreateForm.handleSelectConfirmationType(
                  activitySelectionType,
                ),
              ]);
            }}
            title={translator.translate('Next')}
            buttonTestID="selectActivitySelection"
          />
        }
      >
        <SOActivityMethodSelection
          translator={translator}
          selected={activitySelectionType}
          description={translator.translate(
            'You can only use Activity Location with the type of Shipper that owned by the Shipper you chose on the Header section.',
          )}
          handleSelect={setActivitySelectionType}
        />
      </SOSection>
    );
  }

  if (soCreateForm.soForm.values.isTransitable)
    return (
      <SOActivityTransitForm
        translator={translator}
        isDefaultFormTransit={soActivityTemplateCreateForm.isDefaultFormTransit}
        soActivityFormData={soActivityFormData}
      />
    );

  return (
    <SOActivityForm
      translator={translator}
      soActivityFormData={soActivityFormData}
    />
  );
}
