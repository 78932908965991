import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { LatLng, Position } from '../../model/Map.model';

interface Props {
  map?: google.maps.Map | null;
  setTarget: Dispatch<SetStateAction<LatLng | undefined>>;
  onGetTargetAddress?(pos: Position): void;
  onGetAutocompleteInputRef?(
    autocompleteinputRef: HTMLInputElement | null,
  ): void;
}

export default function useMapAutocomplete({
  map,
  setTarget,
  onGetAutocompleteInputRef,
  onGetTargetAddress,
}: Props) {
  // #region GENERAL
  const [ACValue, setACValue] =
    useState<google.maps.places.Autocomplete | null>(null);
  // #endregion

  // #region HANDLER
  const handleSetInputRef = (autocompleteInputRef: HTMLInputElement | null) => {
    if (onGetAutocompleteInputRef)
      onGetAutocompleteInputRef(autocompleteInputRef);
  };

  const handleLoadAutocomplete = (
    autocomplete: google.maps.places.Autocomplete,
  ) => {
    setACValue(autocomplete);
  };

  const handlePlaceChanged = useCallback(() => {
    if (!ACValue) return;
    const pos = ACValue.getPlace();
    if (pos?.formatted_address && onGetTargetAddress) {
      const selectedPos: Position = {
        address: pos.formatted_address,
        lat: pos?.geometry?.location?.lat() || 0,
        lng: pos?.geometry?.location?.lng() || 0,
      };
      onGetTargetAddress({
        address: selectedPos.address,
        lat: selectedPos.lat,
        lng: selectedPos.lng,
      });
      map?.panTo({ lat: selectedPos.lat, lng: selectedPos.lng });
      map?.setZoom(15);

      setTarget({ lat: selectedPos.lat, lng: selectedPos.lng });
    }
  }, [ACValue, map, onGetTargetAddress, setTarget]);

  // #endregion

  return {
    handleSetInputRef,
    handleLoadAutocomplete,
    handlePlaceChanged,
  };
}

export type UseMapAutocompleteHookObj = ReturnType<typeof useMapAutocomplete>;
