import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';
import ScheduleRemoveView from './ScheduleRemove.view';

export type ScheduleRemoveRouteParam = DefaultRouteParam;

export const scheduleRemoveRoute: RouteConfig = {
  name: 'Remove',
  Component: React.lazy(() => import('./ScheduleRemove.view')),
  NormalComponent: <ScheduleRemoveView />,
  path: '/schedule/remove',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
