import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Location } from 'react-router-dom';
import { RootState } from '../../app/store/store.app';
import { PUBLIC_ROUTE } from '../../constant/Common.constant';

export type UseContent = ReturnType<typeof useContent>;
/**
 * ADD MORE CONDITION TO PREVENT FULLWIDTH ON HAULING ORDER PATH
 * @param location
 * @returns
 */
function renderHOFull(location: Location) {
  return (
    ['/hauling-order/'].findIndex((v) => location.pathname.includes(v)) > -1 &&
    !['/detail/', '/complete/', '/update/', '/assignment/', '/create/'].some(
      (v) => location.pathname.indexOf(v) > -1,
    )
  );
}

/**
 * ADD MORE CONDITION TO PREVENT FULLWIDTH ON SHIPPER ORDER PATH
 * @param location
 * @returns
 */
function renderSOFull(location: Location) {
  return (
    ['/shipper-order/'].findIndex((v) => location.pathname.includes(v)) > -1 &&
    location.search.includes('?breadcrumb')
  );
}

function renderCommonFull(location: Location) {
  return (
    ['/home', '/job-order/create', '/legal', '/track', '/reset'].indexOf(
      location.pathname,
    ) > -1
  );
}

function useContent(location: Location) {
  const { isLoggedIn } = useSelector(
    (state: RootState) => state.session,
    shallowEqual,
  );

  const isFullwidth = useMemo(
    () =>
      renderCommonFull(location) ||
      renderSOFull(location) ||
      renderHOFull(location) ||
      // NOTES: ADD MORE CONDITION TO PREVENT FULLWIDTH ON PUBLIC PATH
      (PUBLIC_ROUTE.findIndex((v) => location.pathname.includes(v)) > -1 &&
        !isLoggedIn),
    [isLoggedIn, location.pathname, location.search, location],
  );

  return {
    isFullwidth,
  };
}

export default useContent;
