import { combineReducers } from '@reduxjs/toolkit';
import api from '../../service/api.service';
import { haulingJOTooltipReducer } from '../../store/haulingJOTooltip.store';
import { navbarReducer } from '../../store/navbar.store';
import { onboardingReducer } from '../../store/onboarding.store';
import { navigationParamReducer } from '../../store/param.store';
import { redirectionReducer } from '../../store/redirection.store';
import { sessionReducer } from '../../store/session.store';
import { settingReducer } from '../../store/setting.store';
import { snackbarReducer } from '../../store/snackbar.store';
import { visaReducer } from '../../store/visa.store';

export const reducers = {
  setting: settingReducer,
  session: sessionReducer,
  snackbar: snackbarReducer,
  redirection: redirectionReducer,
  navigationParam: navigationParamReducer,
  haulingJOTooltip: haulingJOTooltipReducer,
  onboarding: onboardingReducer,
  visa: visaReducer,
  navbar: navbarReducer,
  [api.reducerPath]: api.reducer,
};

const reducerConfig = combineReducers(reducers);

export default reducerConfig;
