import { Locale } from './locale.config';

const privacyPolicyLocaleConfig: Locale[] = [
  {
    en: `Job Order and Driver App Management as part of 'Runner' are providers and managers of work order logistics services, hereinafter referred to as `,
    id: 'Order dan Management Driver App sebagai bagian dari ‘Runner’ adalah penyedia dan pengelola Layanan perintah kerja logistik yang selanjutnya disebut sebagai ',
  },
  {
    en: '"Runner Drivers"',
    id: '"Runner Driver"',
  },
  {
    en: `. Runner Driver is managed by PT Krama Yudha Tiga Berlian Motors ('`,
    id: `. Runner Driver di manage oleh PT Krama Yudha Tiga Berlian Motors ('`,
  },
  {
    en: `'). We believe you should be aware of our Privacy Policy regarding the requirements and procedures for managing your personal data in accordance with the principles of national law regarding Confidentiality.`,
    id: '’) Kami percaya anda harus mengetahui Kebijakan Kerahasiaan Kami mengenai persyaratan dan tata cara pengelolaan data pribadi anda yang sesuai dengan prinsip-prinsip hukum nasional mengenai Kerahasiaan.',
  },
  {
    en: 'Definition',
    id: 'Definisi',
  },
  {
    en: 'Data is ',
    id: 'Data adalah ',
  },
  {
    en: `a collection consisting of facts to provide a broad picture related to a person's situation.`,
    id: 'suatu kumpulan yang terdiri dari fakta-fakta untuk memberikan gambaran yang luas terkait dengan suatu keadaan seseorang.',
  },
  {
    en: 'Confidentiality incidents are ',
    id: 'Insiden kerahasiaan adalah ',
  },
  {
    en: 'all incidents where there is a reasonable suspicion that personal data is obtained, collected, modified, reproduced, transferred or used illegally, which can be carried out by third parties.',
    id: 'semua kejadian dimana terdapat kecurigaan yang beralasan mengenai suatu data pribadi didapatkan, dikumpulkan, dimodifikasi, diperbanyak, dialihkan atau digunakan secara tidak sah, yang dapat dilakukan oleh pihak ketiga.',
  },
  {
    en: 'The data subject in this Privacy Policy is ',
    id: 'Subjek data dalam Kebijakan Kerahasiaan ini adalah ',
  },
  {
    en: 'any person whose data can be processed, including legal entities.',
    id: 'setiap orang yang data-datanya dapat diolah, termasuk badan hukum.',
  },
  {
    en: 'Personal data is ',
    id: 'Data pribadi adalah ',
  },
  {
    en: 'all information about an individual or can be identified as an individual. A person can be identified directly if personal matters can be carried out using a combination of information.',
    id: 'seluruh informasi tentang individu atau dapat diidentifikasi sebagai suatu individu. Seseorang dapat diidentifikasi secara langsung jika hal-hal terkait pribadinya dapat dilakukan menggunakan kombinasi dari informasi.',
  },
  {
    en: 'Processing of personal data is ',
    id: 'Pengolahan data pribadi adalah ',
  },
  {
    en: 'any process, with or without the use of an automated processing system, to obtain, store, process, maintain, modify, use, forward, transfer, disseminate or combine and compare data. Including removing, deleting and blocking data and data storage media.',
    id: 'setiap proses, dengan atau tanpa penggunaan sistem pengolahan otomatis, untuk memperoleh, menyimpan, mengolah, mempertahankan, memodifikasi, menggunakan, meneruskan, mengalihkan, menyebarluaskan atau menggabungkan dan membandingkan data. Termasuk membuang, menghapus dan menghalangi data dan media penyimpanan data.',
  },
  {
    en: 'The data manager is ',
    id: 'Pengelola data adalah ',
  },
  {
    en: 'a certain organisation in the KTB or KTB Related Party that has a special function in charge of initiating or relating to data processing actions.',
    id: 'organisasi tertentu di KTB atau Pihak Terkait KTB yang memiliki fungsi khusus yang bertugas memulai atau berhubungan dengan tindakan pengolahan data.',
  },
  {
    en: 'KTB Related Companies are ',
    id: 'Perusahaan Terkait KTB adalah ',
  },
  {
    en: 'corporations, limited liability companies, partnerships, joint ventures, associations or other legal entities whose shares are owned by KTB and/or shareholders, KTB principals.',
    id: 'korporasi, perseroan terbatas, kerja sama, usaha patungan, asosiasi atau badan hukum lainnya yang kepemilikan atas sahamnya dimiliki oleh KTB dan/atau pemegang saham, prinsipal KTB.',
  },
  {
    en: 'The service is ',
    id: 'Layanan adalah ',
  },
  {
    en: 'a telematic service in the form of location-based vehicle tracking using the ',
    id: 'layanan telematik berupa pelacakan kendaraan berdasarkan lokasi dengan menggunakan ',
  },
  {
    en: 'Global Positioning System.',
    id: 'Global Positioning System.',
  },
  {
    en: 'Purpose of the Privacy Policy',
    id: 'Tujuan Kebijakan Kerahasiaan',
  },
  {
    en: `KTB is committed to implementing the basic principles of data protection and ensuring data protection as the foundation for business relationships and KTB's good name on the basis of trust.`,
    id: 'KTB berkomitmen untuk menerapkan prinsip-prinsip dasar perlindungan data dan memastikan perlindungan data sebagai pondasi bagi hubungan bisnis dan nama baik KTB dengan dasar kepercayaan.',
  },
  {
    en: 'Scope of Confidentiality',
    id: 'Ruang Lingkup Kebijakan Kerahasiaan',
  },
  {
    en: 'Policy This Privacy Policy applies to KTB, KTB Related Companies (for certain cases) and their employees, including you as a Service user.',
    id: 'Kebijakan Kerahasiaan ini berlaku terhadap KTB, Perusahaan Terkait KTB (untuk kasus tertentu) dan para karyawannya, termasuk juga terhadap anda sebagai pengguna Layanan.',
  },
  {
    en: 'Applicability of National Laws',
    id: 'Keberlakuan Hukum Nasional',
  },
  {
    en: 'This Privacy Policy comprises generally accepted principles of data confidentiality without superseding applicable national laws. National law remains a reference in the event that there are differences or regulates more stringent provisions of this Privacy Policy.',
    id: 'Kebijakan Kerahasiaan ini terdiri atas prinsip-prinsip yang diterima secara umum tentang kerahasiaan data tanpa menggantikan hukum nasional yang berlaku. Hukum nasional tetap menjadi acuan dalam hal terdapat perbedaan atau mengatur ketentuan yang lebih ketat dari Kebijakan Kerahasiaan ini.',
  },
  {
    en: 'Principles of Processing of Personal Data',
    id: 'Prinsip-prinsip Pengolahan Data Pribadi',
  },
  {
    en: 'Honest and Legal Compliance',
    id: 'Jujur dan Kepatuhan terhadap Hukum',
  },
  {
    en: 'The individual rights of data subjects must be protected and collected and processed honestly and in compliance with the law.',
    id: 'Hak individu dari subyek data harus dilindungi dan dikumpulkan serta diolah secara jujur dan mematuhi hukum.',
  },
  {
    en: 'Transparency',
    id: 'Transparansi',
  },
  {
    en: 'Data subjects should be informed about how their data is handled.',
    id: 'Subyek data harus diinformasikan mengenai bagaimana data mereka ditangani.',
  },
  {
    en: 'Strict and Limited Interests',
    id: 'Kepentingan yang Ketat dan Terbatas',
  },

  {
    en: 'Personal data will only be processed for purposes that have been determined and informed prior to collection, and based on the consent of the data subject.',
    id: 'Data pribadi hanya akan diolah untuk kepentingan yang telah ditentukan dan diinformasikan sebelum dikumpulkan, serta berdasarkan persetujuan dari subyek data.',
  },

  {
    en: 'Deletion',
    id: 'Penghapusan',
  },

  {
    en: 'Personal data will be kept as long as needed by taking into account the provisions of this Privacy Policy regarding data storage and processing. Deletion will be carried out in compliance with the data retention retention period requirements stipulated by the relevant applicable national laws.',
    id: 'Data pribadi akan tetap disimpan sepanjang dibutuhkan dengan memperhatikan ketentuan Kebijakan Kerahasiaan ini tentang penyimpanan dan pengolahan data. Penghapusan akan dilakukan dengan memenuhi keharusan masa retensi penyimpanan data yang ditentukan oleh hukum nasional terkait yang berlaku.',
  },

  {
    en: 'Factual Accuracy, Novelty',
    id: 'Akurasi yang Faktual, Kebaruan',
  },

  {
    en: 'Personal data stored must be correct, complete and -if needed- updated.',
    id: 'Data pribadi yang disimpan haruslah benar, lengkap dan -jika dibutuhkan- diperbaharui.',
  },

  {
    en: 'Confidentiality and Data Security',
    id: 'Kerahasiaan dan Keamanan Data',
  },

  {
    en: 'Personal data is subject to confidentiality protection.',
    id: 'Data pribadi tunduk pada perlindungan kerahasiaan.',
  },

  {
    en: 'Data Processing Reliability',
    id: 'Kehandalan Pengolahan Data',
  },

  {
    en: 'Data Processing for Cooperation based on Contracts',
    id: 'Pengolahan Data untuk Kerjasama berdasarkan Kontrak',
  },

  {
    en: 'Personal data can be processed for the purposes of making, executing or terminating a contract for the benefit of the data subject.',
    id: 'Data pribadi dapat diolah untuk keperluan pembuatan, pelaksanaan atau pengakhiran kontrak untuk kepentingan subyek data tersebut.',
  },

  {
    en: 'Data Processing for Promotional Purposes',
    id: 'Pengolahan Data untuk Kepentingan Promosi',
  },

  {
    en: 'If the data subject contacts KTB or a KTB-related company to request information (for example: requesting information material related to a product), fulfillment of that information is permissible.',
    id: 'Jika subyek data menghubungi KTB atau Perusahaan Terkait KTB untuk meminta informasi (misalnya: meminta bahan informasi terkait suatu produk), pemenuhan informasi tersebut adalah diperbolehkan.',
  },

  {
    en: 'Consent to Data Processing',
    id: 'Persetujuan atas Pengolahan Data',
  },

  {
    en: 'Before giving consent, data subjects must know this Privacy Policy. Data subject consent must be expressly obtained either in writing or electronically.',
    id: 'Sebelum memberikan persetujuan, subyek data harus mengetahui Kebijakan Kerahasiaan ini. Persetujuan subyek data harus secara tegas diperoleh baik secara tertulis atau elektronik.',
  },

  {
    en: 'Processing of Data in accordance with Legal Authorization',
    id: 'Pengolahan Data sesuai Otorisasi yang Sah',
  },

  {
    en: 'Processing of personal data is also permitted when requested, required or permitted by national laws.',
    id: 'Pengolahan data pribadi juga diperbolehkan jika diminta, dipersyaratkan atau diperbolehkan oleh hukum nasional.',
  },

  {
    en: 'Data Processing for Legitimate Interests',
    id: 'Pengolahan Data untuk Kepentingan yang Sah',
  },

  {
    en: 'Utilisation of data is limited to legitimate needs for the benefit of KTB and KTB-related companies (for certain cases).',
    id: 'Pemanfaatan data adalah terbatas pada keperluan yang sah untuk kepentingan KTB dan Perusahaan Terkait KTB (untuk kasus tertentu).',
  },
  {
    en: 'User Data and Internet',
    id: 'Data Pengguna dan Internet',
  },

  {
    en: 'If personal data is collected, processed and used in websites or internet-based applications, the data subject must understand that user cache and cookies are required to run the Service.',
    id: 'Jika data pribadi dikumpulkan, diolah dan digunakan dalam website atau aplikasi berbasis internet, subyek data harus memahami bahwa cache dan cookies pengguna diperlukan untuk menjalankan Layanan.',
  },

  {
    en: 'Information Sharing to Meet Your Needs',
    id: 'Pembagian Informasi untuk Memenuhi Kebutuhan Anda',
  },
  {
    en: 'We may share information, including personal data between KTB Related Companies to help us identify you, to communicate or provide you with information, advice, solutions or services related to KTB products or KTB Related Parties that you use. Such sharing of personal data is subject to the authorization requirements for processing of personal data under Article V.',
    id: 'Kami dapat membagi informasi, termasuk data pribadi diantara Perusahaan Terkait KTB untuk membantu Kami mengidentifikasi anda, untuk berkomunikasi atau menyediakan anda informasi, saran, solusi atau layanan yang terkait dengan produk KTB atau Pihak Terkait KTB yang anda gunakan. Pembagian data pribadi yang demikian adalah tunduk pada persyaratan otorisasi untuk pengolahan data pribadi berdasarkan Butir V.',
  },
  {
    en: 'In certain circumstances, we may provide personal data to third parties only for the purposes agreed upon and subject to strict confidentiality provisions and the obligation to comply with this Privacy Policy. Such third parties include:',
    id: 'Dalam keadaan tertentu, Kami dapat memberikan data pribadi kepada pihak ketiga hanya untuk keperluan sebagaimana disepakati dan tunduk pada ketentuan kerahasiaan yang ketat dan kewajiban mematuhi Kebijakan Kerahasiaan ini. Pihak ketiga tersebut termasuk:',
  },
  {
    en: 'We assign third parties to provide online registration services, payment withdrawal/processing services, personal data processing, order fulfillment, mail and ',
    id: 'Pihak ketiga yang Kami tugaskan untuk memberikan layanan registrasi online, layanan penarikan/pemrosesan pembayaran, pengolah data pribadi, pemenuhan pesanan, pengiriman surat dan',
  },
  {
    en: 'e-mail delivery, ',
    id: ' e-mail, ',
  },
  {
    en: 'data analysis, ',
    id: 'analisis data, ',
  },
  {
    en: 'marketing assistance, ',
    id: 'bantuan marketing, ',
  },
  {
    en: 'installation/maintenance services, emergency services, and other consumer services to the extent the information needed to perform their functions.',
    id: 'layanan pemasangan/perawatan, layanan darurat, dan layanan konsumen lainnya sejauh informasi itu dibutuhan untuk menjalankan fungsi mereka.',
  },
  {
    en: 'Third parties required on behalf of the law to:',
    id: 'Pihak ketiga yang diperlukan atas nama hukum untuk:',
  },
  {
    en: 'protect us from legal liability;',
    id: 'melindungi Kami dari tanggung jawab hukum;',
  },
  {
    en: 'protect and safeguard our rights or property, including but not limited to exchanging information with other companies or organizations for fraud protection or investigations;',
    id: 'melindungi dan menjaga hak atau milik Kami, termasuk namun tidak terbatas pada bertukar informasi dengan perusahaan atau organisasi lain untuk perlindungan penipuan atau investigasi;',
  },
  {
    en: 'subject to legal obligations; or',
    id: 'tunduk pada kewajiban hukum; atau',
  },
  {
    en: 'action in urgent circumstances to protect personal safety or other service users.',
    id: 'tindakan pada keadaan mendesak untuk melindungi keselamatan personal atau pengguna layanan lainnya.',
  },
  {
    en: 'Personal Data Collection Details',
    id: 'Detail Pengumpulan Data Pribadi',
  },
  {
    en: 'Driver registration via web application with data including but not limited to the following:',
    id: 'Pendaftaran sopir melalui aplikasi web dengan data termasuk namun tidak terbatas sebagai berikut:',
  },
  {
    en: 'Cell phone number',
    id: 'Nomor telepon seluler',
  },
  {
    en: `Registration of customers using the company's services along with delivery locations with data including but not limited to the following:`,
    id: 'Pendaftaran nasabah pengguna jasa perusahaan beserta lokasi pengiriman dengan data termasuk namun tidak terbatas sebagai berikut:',
  },
  {
    en: 'Customer name',
    id: 'Nama nasabah',
  },
  {
    en: `Company's address`,
    id: 'Alamat perusahaan',
  },
  {
    en: 'Shipping address',
    id: 'Alamat pengiriman',
  },
  {
    en: 'Creation of work orders with data including but not limited to the following:',
    id: 'Pembuatan perintah kerja dengan data termasuk namun tidak terbatas sebagai berikut:',
  },
  {
    en: 'Execution of work orders by the driver both when the application is running in the foreground or background even when the application is closed when delivery mode is activated with data included but not limited to the following:',
    id: 'Pelaksanaan perintah kerja oleh sopir baik pada saat aplikasi berjalan pada latar depan ataupun belakang bahkan saat aplikasi ditutup ketika mode pengantaran di aktifkan dengan data termasuk namun tidak terbatas sebagai berikut:',
  },
  {
    en: 'Global Positioning System (GPS) geographic location of driver and fleet',
    id: 'Lokasi geografis Global Positioning System (GPS) sopir dan armada',
  },
  {
    en: 'Driver driving speed',
    id: 'Kecepatan sopir berkendara',
  },
  {
    en: 'Process Confidentiality',
    id: 'Kerahasiaan Pengolahan',
  },
  {
    en: 'We use the information that has been collected about you in a manner consistent with our Privacy Policy, Terms of Use and ',
    id: 'Kami menggunakan informasi tentang anda yang telah dikumpulkan dengan cara yang sejalan dengan Kebijakan Kerahasiaan, Ketentuan Penggunaan dan ',
  },
  {
    en: 'Disclaimer. ',
    id: 'Disclaimer. ',
  },
  {
    en: 'This information is used, among others, to provide services, respond to requests and questions, communicate with you for the purpose of improving the content and functionality of our ',
    id: 'Informasi tersebut antara lain digunakan untuk memberikan layanan, merespon permintaan dan pertanyaan, berkomunikasi dengan anda untuk tujuan memperbaiki konten dan fungsionalitas ',
  },
  {
    en: 'website, apps & software, ',
    id: 'website, apps & software Kami, ',
  },
  {
    en: 'solving problems and helping us understand you for the benefit of providing services or related to other business purposes (including but not limited to research for the development of certain services/products, reading marketing trends, determining sales and marketing strategies, etc.) in accordance with applicable national laws.',
    id: 'menyelesaikan masalah dan membantu kami memahami anda untuk kepentingan pemberian Layanan maupun terkait tujuan bisnis lainnya (termasuk namun tidak terbatas pada riset untuk pengembangan layanan/produk tertentu, membaca tren pemasaran, menentukan strategy penjualan dan pemasaran, dll) sesuai dengan hukum nasional yang berlaku.',
  },
  {
    en: 'Except for the purposes expressly stated in this Privacy Policy, data collected from or about you can only be used in an aggregate form, in this case statistical, and anonymous, i.e. not in a form that other parties can identify as you, and not it will also hurt you.',
    id: 'Kecuali untuk keperluan yang telah secara tegas disebutkan dalam Kebijakan Kerahasiaan ini, data yang dikumpulkan dari atau mengenai anda hanya dapat digunakan dalam bentuk agregat, dalam hal ini berupa statistik, dan anonim, yaitu bukan dalam bentuk yang dapat pihak lain identifikasi sebagai anda, dan tidak juga akan merugikan anda.',
  },
  {
    en: 'Processing Security',
    id: 'Keamanan Pengolahan',
  },
  {
    en: 'We are committed to protecting your personal data as a Service user. We implement appropriate technical and organizational measures to help protect the security of your personal data, but the internet and electronic networks can be breached and are not completely secure or error-free. We cannot guarantee or guarantee the security of your personal data by ourselves, therefore ',
    id: 'Kami berkomitmen untuk melindungi data pribadi anda sebagai pengguna Layanan. Kami menerapkan langkah-langkah teknis dan organisasional yang sepatutnya untuk membantu melindungi keamanan data pribadi anda, namun jaringan internet dan elektronik dapat saja dilanggar dan tidak sepenuhnya aman atau bebas dari kesalahan. Kami tidak dapat memberikan garansi atau menjamin keamanan data pribadi anda oleh kami sendiri, maka dari itu ',
  },
  {
    en: 'PERSONAL DATA MUST BE PROTECTED TOGETHER BY YOU AND US FROM UNAUTHORIZED ACCESS AND UNLAWFUL PROCESSING OR DISCLOSURE, INCLUDING ACCIDENT LOSS, MODIFICATION OR DAMAGE.',
    id: 'DATA PRIBADI HARUS DILINDUNGI BERSAMA OLEH ANDA DAN KAMI DARI AKSES YANG TIDAK SAH DAN PENGOLAHAN ATAU PENGUNGKAPAN YANG MELANGGAR HUKUM, TERMASUK JUGA KEHILANGAN, MODIFIKASI ATAU KERUSAKAN YANG TIDAK DISENGAJA.',
  },
  {
    en: 'Privacy Controls',
    id: 'Kontrol Kerahasiaan',
  },
  {
    en: 'Compliance with this Privacy Policy and other relevant national laws will be periodically checked by means of data protection audits and other related controls.',
    id: 'Kepatuhan atas Kebijakan Kerahasiaan ini dan hukum nasional terkait lainnya akan secara berkala diperiksa dengan cara audit perlindungan data dan kontrol terkait lainnya.',
  },
  {
    en: 'Incidents of Confidentiality',
    id: 'Insiden Kerahasiaan',
  },
  {
    en: 'Any Incidents of Confidentiality must be reported immediately to the Data Manager.',
    id: 'Setiap Insiden Kerahasiaan harus segera dilaporkan ke Pengelola Data.',
  },
  {
    en: 'Responsibilities and Sanctions',
    id: 'Tanggung jawab dan Sanksi',
  },
  {
    en: 'KTB and Companies Related to KTB are responsible for data processing within their respective areas of responsibility.',
    id: 'KTB maupun Perusahaan Terkait KTB bertanggung jawab atas pengolahan data yang berada pada wilayah tanggung jawabnya masing-masing.',
  },
  {
    en: 'Privacy Policy Changes',
    id: 'Perubahan Kebijakan Kerahasiaan',
  },
  {
    en: 'We may amend this Privacy Statement as changes to our business needs or the law. Any changes to this Privacy Statement will be updated on the website or application of this service, so please visit them periodically to ensure that you have our most up-to-date Privacy Statement.',
    id: 'Kami mungkin akan memperbaiki Pernyataan Kerahasiaan ini sejalan dengan perubahan pada kebutuhan bisnis kami atau Undang-Undang. Setiap perubahan pada Pernyataan Kerahasiaan ini akan dimutakhirkan pada website atau aplikasi layanan ini, jadi silakan mengunjunginya secara berkala untuk memastikan bahwa Anda mempunyai Pernyataan Kerahasiaan kami yang terkini.',
  },
];

export default privacyPolicyLocaleConfig;
