import React, { Ref } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { RefHandles } from 'react-spring-bottom-sheet/dist/types';
import tw, { css, styled } from 'twin.macro';
import useSODetailTrackingSheet from '../../../hook/tracking/useSODetailTrackingSheet.hook';
import { SOTracking } from '../../../model/ShipperOrder.model';
import { HeadingThree } from '../../atom/Text/Text.atom';
import { DialogMobile } from '../../molecule';
import SOTrackingDisclaimerModal from '../../molecule/SOTrackingDisclaimerModal/SOTrackingDisclaimerModal.molecule';
import { ExtraStyle } from '../../Type.component';
import SODetailMobileHeader from '../SODetailMobileHeader/SODetailMobileHeader.organism';
import SODMobileSheetContent from '../SODMobileSheetContent/SODMobileSheetContent.organism';

const AdjustedBottomSheet = styled(BottomSheet)(() => [
  css`
    [data-rsbs-header]::before {
      ${tw`bg-beige-lines w-[60px]`}
    }
  `,
]);

// #region INTERFACES

interface Props {
  soId?: string;
  headerTitle?: string;
  shipperPhoneNumber?: string | null;
  trackingCode?: string;
  tracking: SOTracking;
  handleCopyClick: () => void;
}

interface DialogItemProps {
  label: string;
  containerStyle?: ExtraStyle;
  labelStyle?: ExtraStyle;
  handleClick: () => void;
}
// #endregion

function DialogItem({
  label,
  containerStyle,
  labelStyle,
  handleClick,
}: DialogItemProps) {
  return (
    <button
      type="button"
      tw="bg-white p-4 text-center"
      css={containerStyle}
      onClick={handleClick}
    >
      <HeadingThree tw="text-base" css={labelStyle}>
        {label}
      </HeadingThree>
    </button>
  );
}

export default function SODetailMobileBottomSheet({
  soId,
  headerTitle = '',
  shipperPhoneNumber,
  trackingCode,
  tracking,
  handleCopyClick,
}: Props) {
  const soDetailTrackingSheetMV = useSODetailTrackingSheet({
    trackingCode,
    soId,
    shipperPhoneNumber,
    headerTitle,
    tracking,
    handleCopyClick,
  });

  return (
    <>
      <AdjustedBottomSheet
        className={`override-bottom-sheet-so-tracking${
          soDetailTrackingSheetMV.showSONav ||
          soDetailTrackingSheetMV.showHeaderBackButton
            ? ''
            : '-collapsed'
        } ${
          soDetailTrackingSheetMV.showGallery ? 'override-bottom-sheet-so' : ''
        }`}
        ref={
          soDetailTrackingSheetMV.bottomSheetRef as unknown as Ref<RefHandles>
        }
        blocking={
          soDetailTrackingSheetMV.hideMap &&
          !soDetailTrackingSheetMV.showShareDialog
        }
        open
        header={
          soDetailTrackingSheetMV.bottomSheetRef.current?.height ===
          soDetailTrackingSheetMV.sheetSnapPoints.top ? (
            <SODetailMobileHeader
              showGallery={soDetailTrackingSheetMV.showGallery}
              showBackButton={soDetailTrackingSheetMV.showHeaderBackButton}
              showNavigation={soDetailTrackingSheetMV.showSONav}
              showTripHistory={soDetailTrackingSheetMV.showTripHistory}
              headerTitle={soDetailTrackingSheetMV.title}
              handleOpenShare={soDetailTrackingSheetMV.handleOpenDialog}
              handleBack={soDetailTrackingSheetMV.handleBack}
              handleDismiss={soDetailTrackingSheetMV.handleDismissHeader}
            />
          ) : undefined
        }
        onSpringStart={soDetailTrackingSheetMV.handleSpringStart}
        onSpringEnd={soDetailTrackingSheetMV.handleSpringStart}
        defaultSnap={
          soDetailTrackingSheetMV.hideMap
            ? soDetailTrackingSheetMV.sheetSnapPoints.top
            : soDetailTrackingSheetMV.sheetSnapPoints.center
        }
        snapPoints={() =>
          soDetailTrackingSheetMV.hideMap
            ? soDetailTrackingSheetMV.sheetSnapPoints.top
            : [
                soDetailTrackingSheetMV.sheetSnapPoints.min,
                soDetailTrackingSheetMV.sheetSnapPoints.center,
                soDetailTrackingSheetMV.sheetSnapPoints.top,
              ]
        }
      >
        <div
          tw="h-full flex flex-col"
          data-testid="SODetailMobileBottomSheet:SheetContainer"
          className="override-bottom-sheet-content"
        >
          <SODMobileSheetContent
            soId={soId}
            handleShowFullContent={
              soDetailTrackingSheetMV.handleShowFullContent
            }
            showGallery={soDetailTrackingSheetMV.showGallery}
            onImageClick={soDetailTrackingSheetMV.handleOpenGallery}
            forceLang={soDetailTrackingSheetMV.forceLang}
            tracking={tracking}
            showTripHistory={soDetailTrackingSheetMV.showTripHistory}
            soNumber={
              soDetailTrackingSheetMV.bottomSheetRef.current?.height ===
              soDetailTrackingSheetMV.sheetSnapPoints.top
                ? undefined
                : soDetailTrackingSheetMV.title
            }
            trackingCode={trackingCode}
            translator={soDetailTrackingSheetMV.translator}
            handleOpenTripHistory={
              soDetailTrackingSheetMV.handleOpenTripHistory
            }
          />
        </div>
      </AdjustedBottomSheet>

      {soDetailTrackingSheetMV.showShareDialog && (
        <DialogMobile handleClose={soDetailTrackingSheetMV.handleCloseDialog}>
          <div tw="flex flex-col gap-2">
            <div tw="flex flex-col">
              {!!soDetailTrackingSheetMV.isValidPhoneNumber && (
                <DialogItem
                  label={soDetailTrackingSheetMV.translator.translate(
                    'Whatsapp',
                  )}
                  labelStyle={tw`text-orange`}
                  containerStyle={tw`rounded-t border-b border-b-beige-lines`}
                  handleClick={soDetailTrackingSheetMV.handleClickWA}
                />
              )}
              <DialogItem
                label={soDetailTrackingSheetMV.translator.translate(
                  'Copy Link',
                  soDetailTrackingSheetMV.forceLang,
                )}
                labelStyle={tw`text-orange`}
                containerStyle={
                  !soDetailTrackingSheetMV.isValidPhoneNumber
                    ? tw`rounded`
                    : tw`rounded-b`
                }
                handleClick={soDetailTrackingSheetMV.handleClickShare}
              />
            </div>
            <DialogItem
              label={soDetailTrackingSheetMV.translator.translate(
                'Cancel',
                soDetailTrackingSheetMV.forceLang,
              )}
              containerStyle={tw`rounded-md`}
              handleClick={soDetailTrackingSheetMV.handleCloseDialog}
            />
          </div>
        </DialogMobile>
      )}

      {!!soDetailTrackingSheetMV.disclaimerType && (
        <SOTrackingDisclaimerModal
          handleCloseDisclaimer={soDetailTrackingSheetMV.handleCloseDisclaimer}
          handleDisclaimerClick={
            soDetailTrackingSheetMV.handleClickApproveDisclaimer
          }
        />
      )}
    </>
  );
}
