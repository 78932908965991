import React from 'react';
import tw, { styled } from 'twin.macro';
import { HelpCenterMenuItemProps } from '../../../constant/HelpCenter.constant';
import { HeadingFive, HeadingFour } from '../../atom/Text/Text.atom';

const Container = tw.div`fixed ml-[778px]`;
const Content = tw.div`p-4 bg-beige-bg rounded-md w-[300px] gap-1 flex flex-col`;
const Title = tw(HeadingFour)``;
const List = tw.div`flex flex-col gap-1`;
const SubList = tw.div`ml-4 flex flex-col gap-1`;
const Item = styled(HeadingFive)(({ selected }: { selected?: boolean }) => [
  tw`cursor-pointer w-[fit-content] font-normal hover:(text-orange font-bold)`,
  selected && tw`text-orange font-bold`,
]);

type Props = {
  title?: string;
  menu?: HelpCenterMenuItemProps[];
};

export default function HelpCenterMenu({ title = 'Daftar Isi', menu }: Props) {
  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <List>
          {menu?.map((item, index) => (
            <div key={`help-center-menu-item-${item.label || index}`}>
              <Item onClick={() => item.onClick()} selected={item.isSelected}>
                {item.label}
              </Item>

              {!!item?.subMenu?.length && (
                <SubList>
                  {item.subMenu.map((sub, idx) => (
                    <Item
                      key={`help-center-sub-menu-${sub.label || idx}`}
                      onClick={() => {
                        sub.onClick();
                      }}
                      selected={sub.isSelected}
                    >
                      {sub.label}
                    </Item>
                  ))}
                </SubList>
              )}
            </div>
          ))}
        </List>
      </Content>
    </Container>
  );
}
