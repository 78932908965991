import React from 'react';

export default function Share({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.379 1.8L16.2 8.446l-6.821 6.277v-4.061C4.074 10.662 1.8 16.2 1.8 16.2c0-6.277 1.895-10.338 7.579-10.338V1.8z"
        stroke={fill || 'currentColor'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
