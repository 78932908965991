import React from 'react';
import tw from 'twin.macro';
import { ExtraStyle } from '../../Type.component';

interface Props {
  children: React.ReactNode;
  containerStyle?: ExtraStyle;
  id?: string;
}

const Content = tw.div`width[1032px] mx-auto px-4`;

export default function Container({ children, containerStyle, id }: Props) {
  return (
    <Content css={containerStyle} id={id}>
      {children}
    </Content>
  );
}
