// #region IMPORT
import urlcat from 'urlcat';
import { HaulingOrderStatusEnum } from '../../../model/HaulingOrder.model';
import { JOStatus } from '../../../model/JobOrder.model';
import {
  RDBDriverFilterOrderByEnum,
  ReportDrivingBehaviorByDriver,
} from '../../../model/ReportDrivingBehaviorByDriver.model';
import {
  RDBBySchedule,
  RDBScheduleFilterOrderByEnum,
} from '../../../model/ReportDrivingBehaviorBySchedule.model';
import {
  RDBVehicleFilterOrderByEnum,
  ReportDrivingBehaviorByVehicle,
  ReportDrivingBehaviorSummary,
  ReportDrivingBehaviorTopDriver,
  ReportDrivingBehaviorTopVehicle,
} from '../../../model/ReportDrivingBehaviorByVehicle.model';
import {
  ReportFinance,
  ReportFinanceOrderBy,
  ReportFinanceSummary,
} from '../../../model/ReportFinance.model';
import {
  ReportHaulingOrder,
  ReportHaulingOrderFilterOrderByEnum,
} from '../../../model/ReportHaulingOrder.model';
import { ReportJobOrder } from '../../../model/ReportJobOrder.model';
import {
  ExportReportRevenueApiRequest,
  ExportReportRevenueApiResponse,
  GetReportRevenueApiRequest,
  GetReportRevenueApiResponse,
  GetReportRevenueCountApiRequest,
  GetReportRevenueCountApiResponse,
  GetReportRevenueTotalApiResponse,
} from '../../../model/ReportRevenue.model';
import {
  ExportReportShipperOrderApiRequest,
  ExportReportShipperOrderApiResponse,
  GetReportShipperOrderApiRequest,
  GetReportShipperOrderApiResponse,
  GetReportShipperOrderCountApiRequest,
  GetReportShipperOrderCountApiResponse,
  GetReportShipperOrderSummaryApiRequest,
  GetReportShipperOrderSummaryApiResponse,
} from '../../../model/ReportShipperOrder.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, MD, QD } from '../../api.baseQuery';
import {
  ApiResponse,
  CommonApiMetadata,
  CommonApiResponse,
} from '../../api.endpoint';
import { GetHOListApiRequest } from '../haulingOrder/haulingOrder.endpoint';
// #endregion

// #region INTERFACE
type GetReportCountApiResponse = ApiResponse & {
  reports: {
    totalCount: number;
  };
};

export type GetReportDrivingBehaviorSummaryApiRequest = {
  from: number; // in seconds
  to: number; // in seconds
  id?: string;
};

export type GetReportDrivingBehaviorSummaryApiResponse = CommonApiResponse & {
  summary: ReportDrivingBehaviorSummary;
};

export type GetReportDrivingBehaviorTopDriverApiRequest = {
  from: number; // in seconds
  to: number; // in seconds
  id?: string;
};

export type GetReportDrivingBehaviorTopDriverApiResponse = CommonApiResponse & {
  reports: ReportDrivingBehaviorTopDriver;
};
export type GetReportDrivingBehaviorTopVehicleApiResponse =
  CommonApiResponse & {
    reports: ReportDrivingBehaviorTopVehicle;
  };

export type GetReportDrivingBehaviorByVehicleApiRequest = {
  from: number; // in seconds
  to: number; // in seconds
  orderBy?: RDBVehicleFilterOrderByEnum;
  vehicleId?: string;
  search?: string;
  page?: number;
  pageSize?: number;
};
export type GetReportDrivingBehaviorByVehicleApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    reports: ReportDrivingBehaviorByVehicle[];
  };

export type GetReportDrivingBehaviorByVehicleCountApiRequest = Pick<
  GetReportDrivingBehaviorByVehicleApiRequest,
  'from' | 'to' | 'vehicleId' | 'search'
>;

export type ExportReportDrivingBehaviorByVehicleApiRequest = Omit<
  GetReportDrivingBehaviorByVehicleApiRequest,
  'search'
> & { filter?: string[] };
export type ExportReportDrivingBehaviorByVehicleApiResponse =
  CommonApiResponse & {
    reports: {
      fileUrl: string;
    };
  };

export type GetReportDrivingBehaviorByDriverApiRequest = {
  from: number; // in seconds
  to: number; // in seconds
  page?: number;
  pageSize?: number;
  search?: string;
  orderBy?: RDBDriverFilterOrderByEnum;
  driverId?: string;
};
export type GetReportDrivingBehaviorByDriverApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    reports: ReportDrivingBehaviorByDriver[];
  };

export type GetReportDrivingBehaviorByDriverCountApiRequest = Pick<
  GetReportDrivingBehaviorByDriverApiRequest,
  'from' | 'to' | 'driverId' | 'search'
>;

export type ExportReportDrivingBehaviorByDriverApiRequest = Omit<
  GetReportDrivingBehaviorByDriverApiRequest,
  'search'
> & { filter?: string[] };

export type ExportReportDrivingBehaviorByDriverApiResponse =
  CommonApiResponse & {
    reports: {
      fileUrl: string;
    };
  };

export type GetRDBByScheduleApiRequest = {
  from: number; // in seconds
  to: number; // in seconds
  page?: number;
  pageSize?: number;
  search?: string;
  orderBy?: RDBScheduleFilterOrderByEnum;
  isParticipant?: boolean;
  driverId?: string;
};
export type GetRDBByScheduleApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    reports: RDBBySchedule[];
  };

export type GetRDBByScheduleCountApiRequest = Pick<
  GetRDBByScheduleApiRequest,
  'from' | 'to' | 'driverId' | 'search' | 'isParticipant'
>;

export type ExportRDBByScheduleApiRequest = Omit<
  GetRDBByScheduleApiRequest,
  'search'
> & { filter?: string[] };
export type ExportRDBByScheduleApiResponse = CommonApiResponse & {
  reports: {
    fileUrl: string;
  };
};

export type GetReportJobOrderApiRequest = {
  from: number; // in seconds
  to: number; // in seconds
  page?: number;
  pageSize?: number;
  includeSo?: boolean;
  driverId?: string;
  vehicleId?: string;
  'status[]'?: JOStatus;
};
export type GetReportJobOrderApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    jobOrderReports: ReportJobOrder[];
  };

export type GetReportJobOrderCountApiRequest = Omit<
  GetReportJobOrderApiRequest,
  'page' | 'pageSize' | 'includeSo'
>;
export type GetReportJobOrderCountApiResponse = CommonApiResponse & {
  jobOrderReports: {
    totalCount: number;
  };
};

export type ExportReportJobOrderApiRequest = Omit<
  GetReportJobOrderApiRequest,
  'includeSo' | 'status[]'
> & {
  status?: JOStatus[];
};
export type ExportReportJobOrderApiResponse = ExportReportRevenueApiResponse;

export type GetReportHOListApiRequest = Omit<
  GetHOListApiRequest,
  'orderBy' | 'search'
> & {
  orderBy?: ReportHaulingOrderFilterOrderByEnum;
};
export type GetReportHOListApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    haulingOrderReports: ReportHaulingOrder[];
  };

export type GetReportHOListCountApiRequest = Pick<
  GetReportHOListApiRequest,
  'from' | 'to' | 'shipperId' | 'status[]'
>;
export type GetReportHOListCountApiResponse = CommonApiResponse & {
  haulingOrderReports: {
    totalCount: number;
  };
};

export type ExportReportHOListApiRequest = Omit<
  GetReportHOListApiRequest,
  'status[]'
> & {
  status?: HaulingOrderStatusEnum[];
};
export type ExportReportHOListApiResponse = CommonApiResponse & {
  report: {
    fileUrl: string;
  };
};

export type GetReportJobOrderIncomeListApiRequest = {
  from: number; // in seconds
  to: number; // in seconds
  page?: number;
  pageSize?: number;
  search?: string;
  orderBy?: ReportFinanceOrderBy;
  driverId?: string;
  vehicleId?: string;
};
export type GetReportJobOrderIncomeListApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    jobOrderIncome: ReportFinance[];
  };

export type GetReportJobOrderIncomeListCountApiRequest = Pick<
  GetReportJobOrderIncomeListApiRequest,
  'from' | 'to' | 'driverId' | 'vehicleId'
>;
export type GetReportJobOrderIncomeListCountApiResponse = CommonApiResponse & {
  jobOrderIncome: {
    totalCount: number;
  };
};

export type GetReportJobOrderIncomeSummaryApiRequest = {
  from: number; // in seconds
  to: number; // in seconds
  driverId?: string;
  vehicleId?: string;
};
export type GetReportJobOrderIncomeSummaryApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    jobOrderIncomeSummary: ReportFinanceSummary;
  };

export type ExportReportJobOrderIncomeApiRequest = Omit<
  GetReportJobOrderIncomeListApiRequest,
  'search'
>;
export type ExportReportJobOrderIncomeApiResponse = CommonApiResponse & {
  report: {
    fileUrl: string;
  };
};

export type ReportEndpoint = {
  getReportDrivingBehaviorByVehicleList: QD<
    GetReportDrivingBehaviorByVehicleApiRequest | string,
    GetReportDrivingBehaviorByVehicleApiResponse
  >;
  getReportDrivingBehaviorByVehicleCount: QD<
    GetReportDrivingBehaviorByVehicleCountApiRequest,
    GetReportCountApiResponse
  >;
  getReportDrivingBehaviorByVehicleSummary: QD<
    GetReportDrivingBehaviorSummaryApiRequest,
    GetReportDrivingBehaviorSummaryApiResponse
  >;
  getReportDrivingBehaviorByVehicleTopVehicle: QD<
    GetReportDrivingBehaviorTopDriverApiRequest,
    GetReportDrivingBehaviorTopVehicleApiResponse
  >;
  exportReportDrivingBehaviorByVehicle: MD<
    ExportReportDrivingBehaviorByVehicleApiRequest,
    ExportReportDrivingBehaviorByVehicleApiResponse
  >;
  getReportDrivingBehaviorByDriverList: QD<
    GetReportDrivingBehaviorByDriverApiRequest | string,
    GetReportDrivingBehaviorByDriverApiResponse
  >;
  getReportDrivingBehaviorByDriverCount: QD<
    GetReportDrivingBehaviorByDriverCountApiRequest,
    GetReportCountApiResponse
  >;
  getReportDrivingBehaviorByDriverSummary: QD<
    GetReportDrivingBehaviorSummaryApiRequest,
    GetReportDrivingBehaviorSummaryApiResponse
  >;
  getReportDrivingBehaviorByDriverTopDriver: QD<
    GetReportDrivingBehaviorTopDriverApiRequest,
    GetReportDrivingBehaviorTopDriverApiResponse
  >;

  exportReportDrivingBehaviorByDriver: MD<
    ExportReportDrivingBehaviorByDriverApiRequest,
    ExportReportDrivingBehaviorByDriverApiResponse
  >;
  getReportDrivingBehaviorByScheduleList: QD<
    GetRDBByScheduleApiRequest | string,
    GetRDBByScheduleApiResponse
  >;
  getReportDrivingBehaviorByScheduleCount: QD<
    GetRDBByScheduleCountApiRequest,
    GetReportCountApiResponse
  >;
  getReportDrivingBehaviorByScheduleSummary: QD<
    GetReportDrivingBehaviorSummaryApiRequest,
    GetReportDrivingBehaviorSummaryApiResponse
  >;
  getReportDrivingBehaviorByScheduleTopDriver: QD<
    GetReportDrivingBehaviorTopDriverApiRequest,
    GetReportDrivingBehaviorTopDriverApiResponse
  >;
  exportReportDrivingBehaviorBySchedule: MD<
    ExportRDBByScheduleApiRequest,
    ExportRDBByScheduleApiResponse
  >;
  getReportShipperOrderList: QD<
    GetReportShipperOrderApiRequest | string,
    GetReportShipperOrderApiResponse
  >;
  getReportShipperOrderSummary: QD<
    GetReportShipperOrderSummaryApiRequest,
    GetReportShipperOrderSummaryApiResponse
  >;
  getReportShipperOrderListCount: QD<
    GetReportShipperOrderCountApiRequest | string,
    GetReportShipperOrderCountApiResponse
  >;
  exportReportShipperOrder: MD<
    ExportReportShipperOrderApiRequest,
    ExportReportShipperOrderApiResponse
  >;
  getReportRevenueList: QD<
    GetReportRevenueApiRequest,
    GetReportRevenueApiResponse
  >;
  getReportRevenueListCount: QD<
    GetReportRevenueCountApiRequest,
    GetReportRevenueCountApiResponse
  >;
  getReportRevenueListTotal: QD<
    GetReportRevenueCountApiRequest,
    GetReportRevenueTotalApiResponse
  >;
  exportReportRevenue: MD<
    ExportReportRevenueApiRequest,
    ExportReportRevenueApiResponse
  >;
  getReportJobOrderList: QD<
    GetReportJobOrderApiRequest | string,
    GetReportJobOrderApiResponse
  >;
  getReportJobOrderListCount: QD<
    GetReportJobOrderCountApiRequest | string,
    GetReportJobOrderCountApiResponse
  >;
  exportReportJobOrder: MD<
    ExportReportJobOrderApiRequest,
    ExportReportJobOrderApiResponse
  >;
  getReportHaulingOrderList: QD<
    GetReportHOListApiRequest | string,
    GetReportHOListApiResponse
  >;
  getReportHaulingOrderListCount: QD<
    GetReportHOListCountApiRequest | string,
    GetReportHOListCountApiResponse
  >;
  exportReportHaulingOrder: MD<
    ExportReportHOListApiRequest,
    ExportReportHOListApiResponse
  >;
  getReportJobOrderIncomeList: QD<
    GetReportJobOrderIncomeListApiRequest,
    GetReportJobOrderIncomeListApiResponse
  >;
  getReportJobOrderIncomeListCount: QD<
    GetReportJobOrderIncomeListCountApiRequest,
    GetReportJobOrderIncomeListCountApiResponse
  >;
  getReportJobOrderIncomeSummary: QD<
    GetReportJobOrderIncomeSummaryApiRequest,
    GetReportJobOrderIncomeSummaryApiResponse
  >;
  exportReportJobOrderIncome: MD<
    ExportReportJobOrderIncomeApiRequest,
    ExportReportJobOrderIncomeApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const reportEndpoint = (builder: Builder): ReportEndpoint => ({
  getReportDrivingBehaviorByVehicleList: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/reports.vehicles.driving-behavior.list?${param}`
          : urlcat(
              '/v1/reports.vehicles.driving-behavior.list',
              toSnakeCase(param),
            ),
    }),
  }),
  getReportDrivingBehaviorByVehicleCount: builder.query({
    query: (param) =>
      urlcat('/v1/reports.vehicles.driving-behavior.count', toSnakeCase(param)),
  }),
  exportReportDrivingBehaviorByVehicle: builder.mutation({
    query: (body) => ({
      body,
      method: 'post',
      url: '/v1/reports.vehicles.driving-behavior.export',
    }),
  }),
  getReportDrivingBehaviorByVehicleSummary: builder.query({
    query: (param) =>
      urlcat(
        '/v1/reports.vehicles.driving-behavior.summary',
        toSnakeCase(param),
      ),
  }),
  getReportDrivingBehaviorByVehicleTopVehicle: builder.query({
    query: (param) =>
      urlcat(
        '/v1/reports.vehicles.driving-behavior.top-vehicle',
        toSnakeCase(param),
      ),
  }),
  getReportDrivingBehaviorByDriverList: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/reports.drivers.driving-behavior.list?${param}`
          : urlcat(
              '/v1/reports.drivers.driving-behavior.list',
              toSnakeCase(param),
            ),
    }),
  }),
  getReportDrivingBehaviorByDriverCount: builder.query({
    query: (param) =>
      urlcat('/v1/reports.drivers.driving-behavior.count', toSnakeCase(param)),
  }),
  exportReportDrivingBehaviorByDriver: builder.mutation({
    query: (body) => ({
      body,
      method: 'post',
      url: '/v1/reports.drivers.driving-behavior.export',
    }),
  }),
  getReportDrivingBehaviorByDriverSummary: builder.query({
    query: (param) =>
      urlcat(
        '/v1/reports.drivers.driving-behavior.summary',
        toSnakeCase(param),
      ),
  }),
  getReportDrivingBehaviorByDriverTopDriver: builder.query({
    query: (param) =>
      urlcat(
        '/v1/reports.drivers.driving-behavior.top-driver',
        toSnakeCase(param),
      ),
  }),
  getReportDrivingBehaviorByScheduleList: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/reports.drivers.driving-behavior.by-schedule.list?${param}`
          : urlcat(
              '/v1/reports.drivers.driving-behavior.by-schedule.list',
              toSnakeCase(param),
            ),
    }),
  }),
  getReportDrivingBehaviorByScheduleCount: builder.query({
    query: (param) =>
      urlcat(
        '/v1/reports.drivers.driving-behavior.by-schedule.count',
        toSnakeCase(param),
      ),
  }),
  exportReportDrivingBehaviorBySchedule: builder.mutation({
    query: (body) => ({
      body,
      method: 'post',
      url: '/v1/reports.drivers.driving-behavior.by-schedule.export',
    }),
  }),

  getReportDrivingBehaviorByScheduleSummary: builder.query({
    query: (param) =>
      urlcat(
        '/v1/reports.drivers.driving-behavior.by-schedule.summary',
        toSnakeCase(param),
      ),
  }),
  getReportDrivingBehaviorByScheduleTopDriver: builder.query({
    query: (param) =>
      urlcat(
        '/v1/reports.drivers.driving-behavior.by-schedule.top-driver',
        toSnakeCase(param),
      ),
  }),
  getReportShipperOrderList: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/reports.shipper-orders?${param}`
        : urlcat('/v1/reports.shipper-orders', toSnakeCase(param)),
  }),
  getReportShipperOrderSummary: builder.query({
    query: (param) =>
      urlcat('/v1/reports.shipper-orders.summary', toSnakeCase(param)),
  }),
  getReportShipperOrderListCount: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/reports.shipper-orders.count?${param}`
        : urlcat('/v1/reports.shipper-orders.count', toSnakeCase(param)),
  }),
  exportReportShipperOrder: builder.mutation({
    query: (body) => ({
      url: '/v1/reports.shipper-orders.export',
      body,
      method: 'post',
    }),
  }),
  getReportRevenueList: builder.query({
    query: (param) =>
      urlcat('/v1/reports.shippers.revenue.list', toSnakeCase(param)),
  }),
  getReportRevenueListCount: builder.query({
    query: (param) =>
      urlcat('/v1/reports.shippers.revenue.count', toSnakeCase(param)),
  }),
  getReportRevenueListTotal: builder.query({
    query: (param) =>
      urlcat('/v1/reports.shippers.revenue.total', toSnakeCase(param)),
  }),
  exportReportRevenue: builder.mutation({
    query: (body) => ({
      url: '/v1/reports.shippers.revenue.export',
      body,
      method: 'post',
    }),
  }),
  getReportJobOrderList: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/reports.job-orders?${param}`
        : urlcat('/v1/reports.job-orders', toSnakeCase(param)),
  }),
  getReportJobOrderListCount: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/reports.job-orders.count?${param}`
        : urlcat('/v1/reports.job-orders.count', toSnakeCase(param)),
  }),
  exportReportJobOrder: builder.mutation({
    query: (body) => ({
      url: '/v1/reports.job-orders.export',
      body,
      method: 'post',
    }),
  }),
  getReportHaulingOrderList: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/reports.hauling-orders?${param}`
        : urlcat('/v1/reports.hauling-orders', toSnakeCase(param)),
  }),
  getReportHaulingOrderListCount: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/reports.hauling-orders.count?${param}`
        : urlcat('/v1/reports.hauling-orders.count', toSnakeCase(param)),
  }),
  exportReportHaulingOrder: builder.mutation({
    query: (body) => ({
      url: '/v1/reports.hauling-orders.export',
      body,
      method: 'post',
    }),
  }),
  getReportJobOrderIncomeList: builder.query({
    query: (param) =>
      urlcat('/v1/reports.job-orders.income.list', toSnakeCase(param)),
  }),
  getReportJobOrderIncomeListCount: builder.query({
    query: (param) =>
      urlcat('/v1/reports.job-orders.income.count', toSnakeCase(param)),
  }),
  getReportJobOrderIncomeSummary: builder.query({
    query: (param) =>
      urlcat('/v1/reports.job-orders.income.summary', toSnakeCase(param)),
  }),
  exportReportJobOrderIncome: builder.mutation({
    query: (body) => ({
      body,
      method: 'post',
      url: '/v1/reports.job-orders.income.export',
    }),
  }),
});
// #endregion
