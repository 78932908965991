import React from 'react';
import tw, { theme } from 'twin.macro';
import { UseGetShippersAutocompleteObj } from '../../../hook/useGetShippersAutocomplete.hook';
import { UseActivityTemplateFilterVMHookObj } from '../../../view/ActivityTemplate/hooks/useActivityTemplateFilterVM.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete } from '../../molecule';

// #region INTERFACES
type Props = {
  activityTemplateFilter: UseActivityTemplateFilterVMHookObj;
  shippersAutocomplete: UseGetShippersAutocompleteObj;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full max-h-screen bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)`mb-2`;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
// #endregion

function ActivityTemplateFilter({
  activityTemplateFilter,
  shippersAutocomplete,
}: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />
        <Text.HeadingFour tw="flex-1 ml-2">
          {activityTemplateFilter.titleLabel}
        </Text.HeadingFour>
        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          onClick={activityTemplateFilter.onClickReset}
        >
          {activityTemplateFilter.resetButtonLabel}
        </Button.Outlined>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading>{activityTemplateFilter.orderByLabel}</InputHeading>
          <ChipContainer>
            {activityTemplateFilter.filterOrderByValues.map((el) => {
              const selected =
                activityTemplateFilter.form.values.orderBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      activityTemplateFilter.onClickOrderByChip(el.value)
                    }
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${
                activityTemplateFilter.testID || ''
              }Filter:TypeLabel`}
            >
              {activityTemplateFilter.typeLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                activityTemplateFilter.isQueryTypeAll
                  ? activityTemplateFilter.onClickUnselectAllType
                  : activityTemplateFilter.onClickSelectAllType
              }
            >
              {activityTemplateFilter.isQueryTypeAll
                ? activityTemplateFilter.unselectAllLabel
                : activityTemplateFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {activityTemplateFilter.filterTypeValues.map((el, i) => {
              const selected = activityTemplateFilter.form.values.type.includes(
                el.value,
              );

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      activityTemplateFilter.onClickTypeChip(el.value)
                    }
                    testID={`${
                      activityTemplateFilter.testID || ''
                    }Filter:TypeOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {activityTemplateFilter.isQueryTypeEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {activityTemplateFilter.errorTypeLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${
              activityTemplateFilter.testID || ''
            }Filter:ShipperLabel`}
          >
            {activityTemplateFilter.shipperLabel}
          </InputHeading>

          <AutoComplete
            tw="bg-orange-dark-lines"
            disabled={activityTemplateFilter.shipperIsDisabled}
            value={activityTemplateFilter.form.values.shipperId}
            datas={shippersAutocomplete.shipperOptions}
            selectedOption={activityTemplateFilter.queryShipperOption}
            placeholder={activityTemplateFilter.shipperPlaceholder}
            loading={shippersAutocomplete.shipperListFetchLoading}
            changeData={activityTemplateFilter.onChangeShipper}
            changeInputText={shippersAutocomplete.onChangeShipperAutotext}
            onFetchMore={shippersAutocomplete.onFetchMoreShipper}
            onRemoveData={activityTemplateFilter.onRemoveShipper}
          />
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={
            activityTemplateFilter.isQueryTypeEmpty ||
            activityTemplateFilter.filterLocalIsFetching
          }
          onClick={activityTemplateFilter.onClickSubmit}
        >
          {activityTemplateFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default ActivityTemplateFilter;
