import { useLoadScript } from '@react-google-maps/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  googleMapsApiKey,
  language,
  mapFetchGeocodeUrl,
} from '../../constant/Map.constant';
import { GeocodeResponse, LatLng, Position } from '../../model/Map.model';

export interface Props {
  initMap?: boolean;
  disableOnclickTarget?: boolean;
  targetLocations?: LatLng[]; // use for bounds
  targetCoord?: Partial<LatLng>; // use for geocode position
  zoomMaps?: boolean;
  mapsCenter?: LatLng;
  radius?: number;
  isMobile?: boolean;
  onMapInit?(): void;
  toggleZoomMaps?(): void;
  onGetCurrentLocation?(): void;
  onGetTargetAddress?(pos: Position): void;
}

export default function useMapLoader({
  initMap,
  disableOnclickTarget,
  targetLocations,
  targetCoord,
  isMobile,
  zoomMaps,
  mapsCenter,
  radius,
  onMapInit,
  toggleZoomMaps = () => {},
  onGetTargetAddress: handleGetTargetAddress,
  onGetCurrentLocation,
}: Props) {
  // #region GENERAL
  const [libraries] = useState<
    ('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[]
  >(['places']);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [currentLocation, setCurrentLocation] = useState<LatLng | undefined>();
  const [target, setTarget] = useState<LatLng | undefined>(undefined);
  // cannot rerender const if using usememo
  const bounds = window.google
    ? new window.google.maps.LatLngBounds()
    : undefined;
  // cannot rerender const if using usememo
  const circle = window.google ? new window.google.maps.Circle() : undefined;
  const isMobileInnerHeightMini = isMobile && window.innerHeight < 760;
  const mapsInnerOffset = useMemo(() => {
    if (isMobileInnerHeightMini) {
      return 80;
    }
    return window.innerHeight * 0.4;
  }, [isMobileInnerHeightMini]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
    language,
  });

  const center = useMemo(
    () => target || currentLocation || mapsCenter,
    [currentLocation, mapsCenter, target],
  );
  // #endregion

  // #region BOUNDS
  useEffect(() => {
    if (targetLocations && bounds) {
      for (let i = 0; i < targetLocations.length; i += 1) {
        const location = new window.google.maps.LatLng(
          targetLocations[i].lat,
          targetLocations[i].lng,
        );
        bounds.extend(location);
      }
    }
  }, [bounds, targetLocations]);

  useEffect(() => {
    if (bounds && map && targetLocations) {
      if (targetLocations.length === 1) {
        map?.setCenter({
          lat: targetLocations[0].lat,
          lng: targetLocations[0].lng,
        });
        map?.setZoom(16);
        return;
      }
      map?.fitBounds(
        bounds,
        isMobile
          ? {
              left: 40,
              right: 40,
              top: 80,
              bottom: mapsInnerOffset + 20,
            }
          : undefined,
      );
      map?.setZoom((map?.getZoom() || 16) - 2);
    }
  }, [isMobile, bounds, map, targetLocations, mapsInnerOffset]);
  // #endregion

  // // #region ZOOM MAPS
  useEffect(() => {
    if (zoomMaps) {
      map?.setZoom(15);
      void toggleZoomMaps();
    }
  }, [map, toggleZoomMaps, zoomMaps]);
  // #endregion

  // #region HANDLER
  const handleGetCurrentLocation = useCallback(() => {
    if (onGetCurrentLocation && bounds && targetLocations && map) {
      onGetCurrentLocation();
      map?.fitBounds(bounds);
      if (targetLocations.length === 1) {
        map?.setZoom(16);
      }
      return;
    }
    const geo = navigator.geolocation;
    if (!geo && map) return; // 'geo location not initialized'

    geo.getCurrentPosition((pos) => {
      const currentPos: LatLng = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      };
      setCurrentLocation(currentPos);
      map?.panTo(currentPos);
      map?.setZoom(15);
    });
  }, [bounds, map, onGetCurrentLocation, targetLocations]);

  const handleGetMapPos = useCallback(
    async (latLng: LatLng) => {
      const req = await fetch(
        mapFetchGeocodeUrl
          .replace('{latitude}', latLng.lat.toString())
          .replace('{longitude}', latLng.lng.toString()),
      );
      const jsonReq = (await (
        req as unknown as Response
      )?.json()) as unknown as GeocodeResponse;
      if (jsonReq.results?.length && handleGetTargetAddress) {
        handleGetTargetAddress({
          ...(jsonReq && { address: jsonReq?.results[0]?.formatted_address }),
          lat: Number(latLng.lat?.toFixed(7)),
          lng: Number(latLng.lng?.toFixed(7)),
        });
      }
      setTarget(latLng);
      map?.panTo(latLng);
    },
    [handleGetTargetAddress, map],
  );

  const handleFocusRadius = useCallback(
    (selectedLatLng?: LatLng, rad?: number) => {
      if (!rad || !selectedLatLng) return;
      circle?.setRadius(rad);
      circle?.setCenter(selectedLatLng);
      map?.setCenter(selectedLatLng);
      setCurrentLocation(undefined);
      if (circle?.getBounds()) {
        map?.fitBounds(circle?.getBounds() as google.maps.LatLngBounds);
      }
    },
    [circle, map],
  );

  const handleClickMap = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (disableOnclickTarget) return;
      if (!e.latLng) return;
      const selectedLatLng: LatLng = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      void handleGetMapPos(selectedLatLng);
      void handleFocusRadius(selectedLatLng, radius);
    },
    [disableOnclickTarget, handleFocusRadius, handleGetMapPos, radius],
  );

  const handleLoad = useCallback((mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  }, []);

  // #endregion
  // #region HANDLER RADIUS
  useEffect(() => {
    if (!!targetCoord?.lat && !!targetCoord.lng && !currentLocation) {
      handleFocusRadius(
        { lat: targetCoord.lat, lng: targetCoord?.lng },
        radius,
      );
    }
  }, [radius, handleFocusRadius, targetCoord, currentLocation]);

  // #endregion
  useEffect(() => {
    if (isLoaded && targetCoord?.lat && targetCoord?.lng && initMap) {
      void handleGetMapPos({ lat: targetCoord.lat, lng: targetCoord.lng });
      if (onMapInit) onMapInit();
    }
  }, [handleGetMapPos, initMap, isLoaded, onMapInit, targetCoord]);

  return {
    map,
    isLoaded,
    loadError,
    target,
    currentLocation,
    center,
    setTarget,
    handleLoad,
    handleClickMap,
    handleGetCurrentLocation,
  };
}

export type UseMapLoaderHookObj = ReturnType<typeof useMapLoader>;
