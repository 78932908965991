import { getUnixTime, startOfToday } from 'date-fns';
import capitalize from 'lodash/capitalize';
import * as yup from 'yup';
import { SelectionSO, SOActivityType } from '../constant';
import {
  JOBOORDERTYPE,
  JOSOSelectionGroupBy,
} from '../constant/JobOrder.constant';
import {
  JOSOCandidateFilterFormStatusValue,
  JOSOCandidateFilterSortByByValue,
} from '../constant/JobOrderCreate.constant';
import { DELIVERYLOCATIONTYPE } from '../constant/Location.constant';
import { UseTranslator } from '../hook/useTranslator.hook';
import {
  JOActivityInfo,
  JobOrderForm,
  JOScheduleAssignmentType,
  TransitDelivery,
} from '../model/JobOrder.model';
import { Location } from '../model/Location.model';
import {
  JOSOCandidateFilterFormSortBy,
  JOSOCandidateFilterFormStatus,
  SOListActivity,
} from '../model/ShipperOrder.model';

export const joInitialValues: JobOrderForm = {
  joNumber: '',
  joDate: getUnixTime(startOfToday()),
  driverId: undefined,
  vehicleId: undefined,
  travelExpenses: undefined,
  sealNumber: '',
  type: JOBOORDERTYPE.EXTERNAL,
  deliveryLocationType: DELIVERYLOCATIONTYPE.LAST_MILE,
  deliveries: [],
  isUseAssignment: true,
};

export const getLocationLabel = (
  activities: SOListActivity[],
  type: SOActivityType,
  translator: UseTranslator,
): string => {
  const location = activities.filter((data) => data.type === type);

  if (location.length === 1) {
    return location[0].location?.name || '';
  }

  const typeLabel = type === SOActivityType.DROP_OFF ? 'Drop-Off' : 'Pickup';
  const lengthLabel = !location.length
    ? translator.translate('No')
    : location.length.toString();

  return `${lengthLabel} ${translator.translate(`${typeLabel} Activity`)}`;
};

export const getLocationAddress = (
  activities: SOListActivity[],
  type: SOActivityType,
): string | undefined => {
  const filteredActivities = activities.filter((data) => data.type === type);

  if (filteredActivities.length === 1) {
    return filteredActivities[0].location?.address || '';
  }

  return undefined;
};

export const getJOSOSelectionCurrentLocationLabel = (
  so: SelectionSO,
  translator: UseTranslator,
) => {
  const noDataLabel = `${translator.translate('No')} ${translator.translate(
    'Pickup Activity',
  )}`;
  const label = getLocationLabel(
    so.activities,
    SOActivityType.PICK_UP,
    translator,
  );
  if (label === noDataLabel) return 'N/A';

  if (!so.isTransitable) return label;

  return so.currentLocation?.name ?? so.transitLocation?.name ?? label;
};

export const getJOSOSelectionCurrentLocationAddress = (
  so: SelectionSO,
): string | undefined => {
  const label = getLocationAddress(so.activities, SOActivityType.PICK_UP);

  if (!so.isTransitable) return label;

  return so.currentLocation?.address ?? so.transitLocation?.address ?? label;
};

export function validateJobOrder(
  fieldName: string,
  skip?: boolean,
  isEdit?: boolean,
) {
  return (value?: string) => {
    if (isEdit && !value)
      return new yup.ValidationError(
        'Job Order number is required.',
        value,
        fieldName,
      );
    if (!value || skip) return true;
    if (/^JO-/g.test(value)) {
      return new yup.ValidationError(
        'Manual JO number cannot be started with char "JO-"',
        value,
        fieldName,
      );
    }
    return true;
  };
}

export const JOFormHeaderSectionSchema = (options?: {
  isEdit?: boolean;
  initialValues?: JobOrderForm;
}) => {
  const skipJoNumberValidation = options?.initialValues?.joNumber
    ? /^JO-/gi.test(options.initialValues.joNumber)
    : false;
  return {
    joNumber: yup
      .string()
      .test(
        'joNumber',
        {},
        validateJobOrder('joNumber', skipJoNumberValidation, options?.isEdit),
      )
      .max(50, 'Max 50 characters.'),
    joDate: yup.date().nullable().required('Order Date is Required.'),
    sealNumber: yup.string().max(255, 'Max 255 characters.').nullable(),
  };
};

export const JOFormAssignmentSectionSchema = (options?: {
  initialValues?: JobOrderForm;
}) => ({
  driverId: yup.string().required('Driver is required.'),
  vehicleId: yup.string().required('Vehicle is required.'),
  travelExpenses: yup.number().when({
    is: (v: string | number | null) => typeof v === 'number',
    then: yup.number().when({
      is: () => !!options?.initialValues?.approvedTravelBudget,
      then: yup
        .number()
        .min(
          options?.initialValues?.approvedTravelBudget || 0,
          "Travel Budget can't be set lower than total expenses which are",
        )
        .max(999999999, 'Max 999,999,999'),
      otherwise: yup.number().max(999999999, 'Max 999,999,999'),
    }),
    otherwise: yup.number().transform((v?: null | number) => {
      if (!v) {
        return 0;
      }
      return v;
    }),
  }),
});

export const getJOFormValidationSchema = (
  currentStepIdx: number,
  options?: {
    isEdit?: boolean;
    initialValues?: JobOrderForm;
  },
) =>
  yup.object().shape({
    ...(currentStepIdx >= 1 && JOFormHeaderSectionSchema(options)),
    ...(currentStepIdx >= 3 && JOFormAssignmentSectionSchema(options)),
  });

export const JOFormValidationSchema = (options?: {
  isEdit?: boolean;
  initialValues?: JobOrderForm;
}) => {
  const skipJoNumberValidation = options?.initialValues?.joNumber
    ? /^JO-/gi.test(options.initialValues.joNumber)
    : false;
  return yup.object().shape({
    joNumber: yup
      .string()
      .test(
        'joNumber',
        {},
        validateJobOrder('joNumber', skipJoNumberValidation, options?.isEdit),
      )
      .max(50, 'Max 50 characters.'),
    joDate: yup.date().nullable().required('Order Date is Required.'),
    driverId: yup.string().required('Driver is required.'),
    vehicleId: yup.string().required('Vehicle is required.'),
    travelExpenses: yup.number().when({
      is: (v: string | number | null) => typeof v === 'number',
      then: yup.number().when({
        is: () => !!options?.initialValues?.approvedTravelBudget,
        then: yup
          .number()
          .min(
            options?.initialValues?.approvedTravelBudget || 0,
            "Travel Budget can't be set lower than total expenses which are",
          )
          .max(999999999, 'Max 999,999,999'),
        otherwise: yup.number().max(999999999, 'Max 999,999,999'),
      }),
      otherwise: yup.number().transform((v?: null | number) => {
        if (!v) {
          return 0;
        }
        return v;
      }),
    }),
    sealNumber: yup.string().max(255, 'Max 255 characters.').nullable(),
  });
};

/**
 * @param  {UseTranslator} translator
 * @returns JOSOCandidateFilterSortByByValue[]
 *
 * * @summary get JO create SO candidate filterSortBy values
 */
export function getJOSOCandidateFilterSortByValues(
  translator: UseTranslator,
): JOSOCandidateFilterSortByByValue[] {
  return [
    {
      label: translator.translate('Newest Updates'),
      value: JOSOCandidateFilterFormSortBy.UPDATED_AT_DESC,
    },
    {
      label: translator.translate('Oldest Updates'),
      value: JOSOCandidateFilterFormSortBy.UPDATED_AT_ASC,
    },
    {
      label: translator.translate('Newest Date'),
      value: JOSOCandidateFilterFormSortBy.DATE_DESC,
    },
    {
      label: translator.translate('Oldest Date'),
      value: JOSOCandidateFilterFormSortBy.DATE_ASC,
    },
    {
      label: translator.translate('Shipper name A-Z'),
      value: JOSOCandidateFilterFormSortBy.SHIPPER_NAME_ASC,
    },
    {
      label: translator.translate('Shipper name Z-A'),
      value: JOSOCandidateFilterFormSortBy.SHIPPER_NAME_DESC,
    },
    {
      label: translator.translate('Highest Delivery Cost'),
      value: JOSOCandidateFilterFormSortBy.DELIVERY_COST_DESC,
    },
  ];
}

/**
 * @param {JOSOCandidateFilterFormSortBy} filterSortBy
 * @param {UseTranslator} translator
 * @returns string
 *
 * @summary map through `JOSOCandidateFilterFormSortBy` enum to get the `filterSortBy` label
 */
export function mapJOSOCandidateFilterSortByToLabel(
  filterSortBy: JOSOCandidateFilterFormSortBy,
): string {
  const returnedLabel: Record<
    JOSOCandidateFilterFormSortBy | 'default',
    string
  > = {
    [JOSOCandidateFilterFormSortBy.UPDATED_AT_DESC]: 'Newest Updates',
    [JOSOCandidateFilterFormSortBy.UPDATED_AT_ASC]: 'Oldest Updates',
    [JOSOCandidateFilterFormSortBy.DATE_DESC]: 'Newest Date',
    [JOSOCandidateFilterFormSortBy.DATE_ASC]: 'Oldest Date',
    [JOSOCandidateFilterFormSortBy.SHIPPER_NAME_ASC]: 'Shipper name A-Z',
    [JOSOCandidateFilterFormSortBy.SHIPPER_NAME_DESC]: 'Shipper name Z-A',
    [JOSOCandidateFilterFormSortBy.DELIVERY_COST_DESC]: 'Highest Delivery Cost',
    default: 'Newest Updates',
  };

  return returnedLabel[filterSortBy] || returnedLabel.default;
}

/**
 * get JO create SO candidate filter status values
 */
export function getJOSOCandidateFilterStatusValues(
  translator: UseTranslator,
): JOSOCandidateFilterFormStatusValue[] {
  return [
    {
      label: translator.translate('In Transit'),
      value: JOSOCandidateFilterFormStatus.IN_TRANSIT,
    },
    {
      label: translator.translate('In Process'),
      value: JOSOCandidateFilterFormStatus.IN_PROCESS,
    },
  ];
}

/**
 * map through `JOSOCandidateFilterFormStatusValue` enum to get the `filterStatus` labels
 */
export function mapJOSOCandidateFilterStatusesToLabels(
  statuses: JOSOCandidateFilterFormStatus[],
  translator: UseTranslator,
): string[] {
  const label: Record<JOSOCandidateFilterFormStatus, string> = {
    [JOSOCandidateFilterFormStatus.IN_TRANSIT]:
      translator.translate('In Transit'),
    [JOSOCandidateFilterFormStatus.IN_PROCESS]:
      translator.translate('In Process'),
  };

  return statuses.map((status) => label[status]);
}

/**
 * map through `SelectionSO` list and group it by `JOSOSelectionGroupBy`
 */
export function getJOSOSelectionGroupByKey(
  soList: SelectionSO[],
  groupBy: JOSOSelectionGroupBy,
): Record<string, SelectionSO[]> {
  // get transit and last mile SO
  const transitList = soList.filter(
    (so) => !so.deliveryLocation && so.isTransitable,
  );
  const lastMileList = soList.filter(
    (so) => so.deliveryLocation || !so.isTransitable,
  );

  const currentLocationMap: Map<string, SelectionSO[]> = new Map();
  const deliveryLocationMap: Map<string, SelectionSO[]> = new Map();

  function commonOperation(
    locationMap: Map<string, SelectionSO[]>,
    key: string,
    so: SelectionSO,
  ) {
    // if the previous list is exists
    if (locationMap.has(key)) {
      // get previous list based on the key `key`
      const previousList = locationMap.get(key) as SelectionSO[];

      // append new list item to the previous list ONLY if there are no duplicate SO in the key `key`
      if (!previousList.find((_prev) => _prev.id === so.id))
        locationMap.set(key, previousList.concat([so]));
    } else {
      // if the previous list is NOT exists
      locationMap.set(key, [so]);
    }
  }

  const map =
    groupBy === JOSOSelectionGroupBy.CURRENT_LOCATION
      ? currentLocationMap
      : deliveryLocationMap;

  for (const _so of soList) {
    const pickupActivities = _so.activities.filter(
      (_act) => _act.type === SOActivityType.PICK_UP,
    );

    for (const _activity of _so.activities) {
      // for current location selection
      if (groupBy === JOSOSelectionGroupBy.CURRENT_LOCATION) {
        // all transitable SO ONLY have 1 pickup & 1 dropoff activity
        if (_so.isTransitable) {
          // for transitable SO that have `transitLocation`
          if (_so.transitLocation) {
            commonOperation(map, _so.transitLocation.name, _so);

            // continue to next iteration
            continue;
          }

          // for transitable SO that does NOT have `transitLocation`
          // only applied to pickup activity
          if (_activity.type === SOActivityType.PICK_UP) {
            commonOperation(map, _activity.location.name, _so);

            // continue to next iteration
            continue;
          }
        }

        // code below is last mile SO implementation
        // for 'N/A' groups -> stand by activity OR so with ONLY drop off, without any pickup activity
        if (
          _activity.type === SOActivityType.STAND_BY ||
          _activity.type === SOActivityType.DROP_OFF
        ) {
          commonOperation(map, 'N/A', _so);

          // continue to next iteration
          continue;
        }

        // for 'Multiple Locations' -> multiple pickup activities
        if (pickupActivities.length > 1) {
          commonOperation(map, 'Multiple Locations', _so);

          // continue to next iteration
          continue;
        }

        if (_activity.type === SOActivityType.PICK_UP)
          // for dynamic location name groups -> pickup activity
          commonOperation(map, _activity.location.name, _so);
      }

      // for delivery location selection
      if (groupBy === JOSOSelectionGroupBy.DELIVERY_LOCATION) {
        // for "Last Mile" groups -> NOT isTransitable
        if (!_so.isTransitable) {
          commonOperation(map, 'Last Mile', _so);

          // continue to next iteration
          continue;
        }

        // for "Unassigned" groups -> `deliveryLocation` NOT exists
        if (!_so.deliveryLocation) {
          commonOperation(map, 'unassigned', _so);

          // continue to next iteration
          continue;
        }

        // for dynamic location name groups -> `deliveryLocation` exists
        // all transitable SO only have 1 pickup & 1 dropoff activity
        if (_activity.type === SOActivityType.DROP_OFF)
          commonOperation(
            map,
            // group to "Last Mile" if the selected `deliveryLocation.id` equals to `_activity.location.id`
            _activity.location.id === _so.deliveryLocation.id
              ? 'Last Mile'
              : (_so.deliveryLocation.name as string),
            _so,
          );
      }
    }
  }

  // sort the "Current Location" group keys ascending alphabetically
  const multipleLocationList = currentLocationMap.get('Multiple Locations');
  const currentLocationNAList = currentLocationMap.get('N/A');
  const currentLocationNAListWithoutDuplicatePickups = (
    currentLocationNAList ?? []
  ).filter(
    (_so) =>
      !_so.activities.find((_act) => _act.type === SOActivityType.PICK_UP),
  );

  // sorted dynamic location name group
  const sortedDynamicCurrentLocations = [...currentLocationMap]
    .filter(([key]) => key !== 'N/A')
    .map(
      ([key, list]) =>
        [
          key,
          list
            .slice()
            .sort((a, b) =>
              a.number.localeCompare(b.number, 'id', { sensitivity: 'base' }),
            ),
        ] as const,
    )
    .sort(([aKey], [bKey]) =>
      aKey.localeCompare(bKey, 'id', { sensitivity: 'base' }),
    );
  // sorted 'Multiple Locations' group
  const currentMultipleLocation = (multipleLocationList && [
    'Multiple Locations',
    multipleLocationList
      .slice()
      .sort((a, b) =>
        a.number.localeCompare(b.number, 'id', { sensitivity: 'base' }),
      ),
  ]) as [string, SelectionSO[]];
  // sorted 'N/A' group
  const currentNALocation =
    (!!currentLocationNAListWithoutDuplicatePickups.length && [
      'N/A',
      currentLocationNAListWithoutDuplicatePickups.sort((a, b) =>
        a.number.localeCompare(b.number, 'id', { sensitivity: 'base' }),
      ),
    ]) as [string, SelectionSO[]];

  // filter out empty groups
  const sortedCurrentLocation = new Map(
    [
      ...sortedDynamicCurrentLocations,
      currentMultipleLocation,
      currentNALocation,
    ].filter(Boolean),
  );

  // sort the "Delivery Location" group keys ascending alphabetically
  const sortedDynamicDeliveryLocations = [...deliveryLocationMap]
    .filter(([key]) => key !== 'unassigned' && key !== 'Last Mile')
    .map(
      ([key, list]) =>
        [
          key,
          list
            .slice()
            .sort((a, b) =>
              a.number.localeCompare(b.number, 'id', { sensitivity: 'base' }),
            ),
        ] as const,
    )
    .sort(([aKey], [bKey]) =>
      aKey.localeCompare(bKey, 'id', { sensitivity: 'base' }),
    );
  const deliveryLocationUnassignedList = deliveryLocationMap.get('unassigned');
  const deliveryLocationLastMileList = deliveryLocationMap.get('Last Mile');
  const sortedDeliveryLocation = new Map([
    // sorted 'unassigned' group
    [
      'unassigned',
      (deliveryLocationUnassignedList ?? [])
        .slice()
        .sort((a, b) =>
          a.number.localeCompare(b.number, 'id', { sensitivity: 'base' }),
        ),
    ],
    ...sortedDynamicDeliveryLocations,
    // sorted 'Last Mile' group
    [
      'Last Mile',
      (deliveryLocationLastMileList ?? [])
        .slice()
        .sort((a, b) =>
          a.number.localeCompare(b.number, 'id', { sensitivity: 'base' }),
        ),
    ],
  ]);

  // object literal to determine which is picked based on `groupBy`
  const key: Record<JOSOSelectionGroupBy, Record<string, SelectionSO[]>> = {
    [JOSOSelectionGroupBy.CURRENT_LOCATION]: Object.fromEntries(
      sortedCurrentLocation,
    ),
    [JOSOSelectionGroupBy.DELIVERY_LOCATION]: Object.fromEntries(
      sortedDeliveryLocation,
    ),
    [JOSOSelectionGroupBy.ASSIGNMENT]: {
      unassigned: transitList,
      assigned: lastMileList,
    },
  };

  return key[groupBy];
}

/**
 * capitalize assignment key
 */
export function capitalizeAssignmentKey(key: string): string {
  if (key === 'unassigned' || key === 'assigned') {
    return capitalize(key);
  }

  return key;
}

/**
 * get selection SO `isExpandedList` initial state based on `selectedGroupBy`
 */
export function getSelectionSOIsExpandedListState(
  groupedSelectionDatas: Record<string, SelectionSO[]>,
  selectedGroupBy: JOSOSelectionGroupBy,
): string[] {
  const selectionEntries = Object.entries(groupedSelectionDatas);

  // default all expanded when `selectedGroupBy` is delivery location & assignment
  if (
    selectedGroupBy === JOSOSelectionGroupBy.DELIVERY_LOCATION ||
    selectedGroupBy === JOSOSelectionGroupBy.ASSIGNMENT
  ) {
    return selectionEntries.map(([key]) => key);
  }

  return selectionEntries
    .map(([key, soList]) => {
      const transitableList = soList.filter((so) => so.isTransitable);
      // if there is at least 1 SO in `soList` is transitable, then default to expanded
      const isExpanded = transitableList.length > 0;

      return isExpanded ? key : '';
    })
    .filter((_key) => _key !== '');
}

const isDeliveryLocationEqualToDropoff = (
  deliveryLocation?: Partial<Location>,
  activities?: SOListActivity[],
) => {
  const dropoffActivity = activities?.find(
    (activity) => activity.type === SOActivityType.DROP_OFF,
  );
  if (!deliveryLocation || !dropoffActivity) return false;

  return deliveryLocation.id === dropoffActivity.locationId;
};

/**
 * Update deliveries by adding transit location based on certain condition, dont hesitate to check me if something is not right
 * @param selectionSOList
 * @param deliveries
 * @returns
 */
export const updateDeliveries = (
  selectionSOList: SelectionSO[],
  deliveries?: TransitDelivery[],
) => {
  if (!deliveries) return undefined;

  return deliveries.map((delivery) => {
    const selectedSO = selectionSOList.find((so) => so.id === delivery.soId);

    const hasTransitLocation = !!selectedSO?.deliveryLocation;
    if (hasTransitLocation) {
      if (
        !isDeliveryLocationEqualToDropoff(
          selectedSO.deliveryLocation,
          selectedSO.activities,
        )
      )
        return {
          ...delivery,
          transitLocationId: selectedSO.deliveryLocation?.id,
        };

      return delivery;
    }

    return delivery;
  });
};

export const checkIsDeliveryLocationSameAsCurrentLocation = ({
  selectionSOList = [],
}: {
  selectionSOList?: SelectionSO[];
}) => {
  // get only transit so
  const transitableSOList = selectionSOList.filter((_so) => _so.isTransitable);

  const deliveryLocationSameAsCurrentLocationList = transitableSOList?.filter(
    (_so) =>
      _so.transitLocation
        ? // for so transit that has transitted before -> compare _so.deliveryLocation to transit location
          _so?.transitLocation?.id === (_so.deliveryLocation as Location)?.id
        : // for so transit that never transitted -> compare _so.deliveryLocation to pickup location
          !!_so.activities
            .filter((_activity) => _activity?.type === SOActivityType.PICK_UP)
            .find(
              (_activity) =>
                _activity?.location?.id ===
                (_so.deliveryLocation as Location)?.id,
            ),
  );

  if (deliveryLocationSameAsCurrentLocationList?.length)
    return { isSame: true, deliveryLocationSameAsCurrentLocationList } as const;

  return {
    isSame: false,
    deliveryLocationSameAsCurrentLocationList: undefined,
  } as const;
};

export const getLocationIdJoDraft = (_activity: JOActivityInfo) => {
  // If _activity.location not exist the location object always located inside
  // _activity.soActivity and the value will be available

  if (_activity.soActivity) return String(_activity.soActivity?.locationId);

  return String(_activity.locationId);
};

export const joScheduleAssignmentTypeFormatter = (
  val: JOScheduleAssignmentType,
): JOScheduleAssignmentType => ({
  driverId: val.driverId,
  driverOption: val.driverOption,
  vehicleId: val.vehicleId,
  vehicleOption: val.vehicleOption,
  schedule: val.schedule,
  scheduleOption: val.scheduleOption,
});
