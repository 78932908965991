import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fillPath?: string;
};

export default function WeightOutline({
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  fill = 'none',
  fillPath = 'currentColor',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <g>
        <path
          fill={fillPath}
          d="M4 12.667h8L11.05 6h-6.1L4 12.667zm4-8a.646.646 0 00.475-.192A.642.642 0 008.667 4a.646.646 0 00-.192-.475A.643.643 0 008 3.333a.646.646 0 00-.475.192.643.643 0 00-.192.475c0 .189.064.347.192.475A.643.643 0 008 4.667zm1.883 0h1.167c.333 0 .622.11.867.333.244.222.394.494.45.817l.95 6.666c.055.4-.048.753-.309 1.059A1.264 1.264 0 0112 14H4c-.411 0-.747-.153-1.009-.459a1.285 1.285 0 01-.308-1.058l.95-6.666c.056-.323.206-.595.45-.817.245-.222.534-.333.867-.333h1.167a4.017 4.017 0 01-.084-.325A1.657 1.657 0 016 4c0-.556.194-1.028.583-1.417A1.929 1.929 0 018 2c.556 0 1.028.194 1.417.583.389.39.583.861.583 1.417 0 .122-.011.236-.033.342-.023.106-.05.214-.084.325z"
        />
      </g>
    </svg>
  );
}
