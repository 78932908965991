import React from 'react';

export default function NavMaps({
  fill = 'currentColor',
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1102 13.1738C17.7013 11.9518 18 10.8688 18 10C18 6.68629 15.3137 4 12 4C8.68629 4 6 6.68629 6 10C6 10.8688 6.29869 11.9518 6.88975 13.1738C7.47139 14.3764 8.2772 15.5927 9.14241 16.6908C10.0067 17.7878 10.894 18.721 11.6058 19.3616C11.7499 19.4913 11.8817 19.6044 12 19.7014C12.1183 19.6044 12.2501 19.4913 12.3942 19.3616C13.106 18.721 13.9933 17.7878 14.8576 16.6908C15.7228 15.5927 16.5286 14.3764 17.1102 13.1738ZM12 22C13.1429 22 20 15.1429 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 15.1429 10.8571 22 12 22Z"
      />
      <path d="M15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z" />
    </svg>
  );
}
