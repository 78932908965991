import { SOActivityType, SOStatus } from '../constant';
import { JOAssignmentBy } from '../constant/JOAssignment.constant';
import { AutocompleteType } from '../hook/useAutocomplete.hook';
import { Photo } from './Common.model';
import { Delivery } from './Delivery.model';
import { Driver } from './Driver.model';
import { Location } from './Location.model';
import { Schedule } from './Schedule.model';
import { Shipper } from './Shipper.model';
import {
  Goods,
  ShipperOrderActivity,
  ShipperOrderEvent,
  SOActivityDetail,
  SOActivityDetailNote,
  SOPaymentStatus,
} from './ShipperOrder.model';
import { Vehicle } from './Vehicle.model';

export interface JobOrder {
  completedAt: number | null; // null for JO status deleted
  createdAt: number;
  date: number;
  driver?: Driver;
  id: string;
  isProofOfActivityRequired?: boolean;
  isUseAssignment: boolean;
  sealNumber?: string | null;
  number: string;
  status: JOStatus;
  statusText: keyof typeof JOStatus;
  totalGoodsVolume: number | null;
  totalGoodsWeight: number | null;
  travelExpense: number | null;
  updatedAt: number;
  url: string;
  vehicle?: Vehicle;
}

export interface TransitDelivery {
  soId: string;
  isResolve?: boolean;
  transitLocationId?: string;
}
export interface JOHeader {
  joNumber: string;
  joDate: number;
  driverId: string;
  vehicleId: string;
  travelExpenses: number | null;
  sealNumber: string | null;
  type: string;
  isUseAssignment?: boolean;
  deliveryLocationType: string;
  deliveries: TransitDelivery[];
  approvedTravelBudget?: number | null;
}

export interface LocationItem {
  id: string;
  name: string;
  address: string;
}
export interface SOItemType {
  soId: string;
  date: string;
  shipper: string;
  pickupLocation: LocationItem[];
  dropLocation: LocationItem[];
}

export type JOScheduleAssignmentType = Partial<{
  driverId: string;
  vehicleId: string;
  driverOption: AutocompleteType;
  vehicleOption: Vehicle;
  scheduleOption: AutocompleteType;
  schedule: Schedule;
  assignmentType?: JOAssignmentBy;
}>;
export type JobOrderForm = Partial<JOHeader & JOScheduleAssignmentType>;

export enum JOStatus {
  DRAFT = 101,
  DELETED = 102,
  ASSIGNED = 201,
  CANCELLED = 202,
  DELIVERING = 301,
  DELIVERED = 401,
}

export type ExpenseSummary = {
  totalApprovedExpense: number;
  totalExpense: number;
  totalPendingExpense: number;
};

export interface JobOrderInfo extends JobOrder {
  deliveries: JobOrderInfoDelivery[];
  expenseSummary: ExpenseSummary;
}
export interface PhotoObj {
  photo: Photo;
  photoId?: string;
}

export interface JOActivityInfo {
  id: string;
  events: ShipperOrderEvent[];
  completedAt: number | null;
  index: number;
  location?: Location | null;
  locationId: string | null;
  notes: SOActivityDetailNote[];
  photos: PhotoObj[];
  goods?: Goods[];
  goodsOut?: Goods[];
  expectedFinishAt?: number;
  poaNotes: string;
  soActivityId: string | null;
  soActivity?: ShipperOrderActivity;
  status: SOStatus;
  statusText: string;
  type: SOActivityType | null;
}

export enum JobOrderDeliveryStatus {
  DRAFT = 101,
  ASSIGNED = 201,
  ON_GOING = 301,
  COMPLETED = 401,
}

export interface JobOrderInfoDelivery {
  id: string;
  activities: JOActivityInfo[];
  shipperOrder: JobOrderInfoDeliverySO;
  status: JobOrderDeliveryStatus;
  isResolved?: boolean;
}

export interface JobOrderInfoDeliverySO {
  id: string;
  date: number;
  number: string;
  shipper: Shipper;
  status: SOStatus;
  isTransitable: boolean;
  statusText: keyof typeof SOStatus;
  referenceNumber: string | null;
  notes: string | null;
  activities: JobOrderInfoDeliverySOActivity[];
  createdAt: number;
  updatedAt: number;
  deliveries: Delivery[];
  totalPayment: number;
  trackingCode: string;
  paymentStatus: SOPaymentStatus;
  paymentStatusText: keyof typeof SOPaymentStatus;
}

export enum JobOrderInfoDeliverySOActivityStatus {
  IN_PROCESS = 101,
  DELIVERED = 201,
}

export interface JobOrderInfoDeliverySOActivity extends SOActivityDetail {
  status: JobOrderInfoDeliverySOActivityStatus;
  statusText: keyof typeof JobOrderInfoDeliverySOActivityStatus;
  events: ShipperOrderEvent[];
  photos: Photo[];
  poaNotes: string;
  soActivity?: SOActivityDetail;
}

export type JOSORowProps = {
  check: JSX.Element;
  number: JSX.Element;
  soDate: JSX.Element;
  shipper: JSX.Element;
  pickupLocation: JSX.Element;
  dropoffLocation: JSX.Element;
};

export type JODetailMapAction = {
  label: string;
  selected: boolean;
  disabled?: boolean;
  action: () => void;
};

export enum JobOrderExpenseType {
  FUELS = 'FUELS',
  TOLLS = 'TOLLS',
  PARKING_FEES = 'PARKING_FEES',
  OTHERS = 'OTHERS',
}

export enum JobOrderExpenseEventType {
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  RE_SUBMITTED = 'RE_SUBMITTED',
}

export enum JobOrderExpenseOrderBy {
  NUMBER_DESC = 'NUMBER_DESC', // Expense Number Z-A
  NUMBER_ASC = 'NUMBER_ASC', // Expense Number A-Z
  SUBMITTED_AT_DESC = 'SUBMITTED_AT_DESC', // Newest Submission
  SUBMITTED_AT_ASC = 'SUBMITTED_AT_ASC', // Oldest Submission
}

export enum JobOrderExpenseStatus {
  DRAFT = 101,
  PENDING = 201,
  APPROVED = 301,
  REJECTED = 302,
}

export type JobOrderExpenseEventDTO = {
  id: string;
  joExpenseId: string;
  eventType: JobOrderExpenseEventType;
  eventData?: { notes: string };
  eventTime: number; // in seconds
};

export type JobOrderExpense = {
  id: string;
  joId: string;
  date: number; // in seconds
  number: string;
  amount: number;
  type: JobOrderExpenseType;
  status: JobOrderExpenseStatus;
  statusText: keyof typeof JobOrderExpenseStatus;
  description: string | null;
  rejectionNote: string | null;
  submittedAt: number | null;
  createdAt: number;
  events: JobOrderExpenseEventDTO[];
  photos: Photo[];
};

export type JobOrderSearchParam = {
  startDate?: string; // milliseconds
  endDate?: string; // milliseconds
  orderBy?: string; // JOFilterOrderBy
  status?: string; // string of JOStatus collection => 'CANCELLED,DRAFT,DELETED'
  search?: string;
  driverId?: string;
  vehicleId?: string;
  page?: string;
  pageSize?: string;
};

export type EventInitialCurrent<T> = {
  initial: T;
  current: T;
};
export type JobOrderEventDelivered = {
  eventType: 'DELIVERED';
  eventTime: number; // in seconds
  eventData: {
    causedBy: 'user' | 'driver' | 'geofence';
    status: EventInitialCurrent<keyof typeof JOStatus>;
  };
};
export type JobOrderTravelExpenseUpdated = {
  eventType: 'TRAVEL_EXPENSE_UPDATED';
  eventTime: number; // in seconds
  eventData: {
    travelExpense: EventInitialCurrent<number>;
  };
};
export type JobOrderEventUpdated = {
  eventType: 'UPDATED';
  eventTime: number; // in seconds
  eventData: {
    vehicle?: EventInitialCurrent<Pick<Vehicle, 'id' | 'name'>>;
    driver?: EventInitialCurrent<Pick<Driver, 'id' | 'fullName'>>;
    sealNumber?: EventInitialCurrent<string | null>;
    travelExpense: EventInitialCurrent<number>;
  };
};
export type JobOrderEventDelivering = {
  eventType: 'DELIVERING';
  eventTime: number; // in seconds
  eventData: {
    causedBy: 'user' | 'driver' | 'geofence';
    status: EventInitialCurrent<keyof typeof JOStatus>;
  };
};
export type JobOrderEventAssigned = {
  eventType: 'ASSIGNED';
  eventTime: number; // in seconds
  eventData: {
    status: EventInitialCurrent<keyof typeof JOStatus>;
  };
};
export type JobOrderEventCreated = {
  eventType: 'CREATED';
  eventTime: number; // in seconds
  eventData: null;
};
export type JobOrderEvent =
  | JobOrderEventDelivered
  | JobOrderTravelExpenseUpdated
  | JobOrderEventUpdated
  | JobOrderEventDelivering
  | JobOrderEventAssigned
  | JobOrderEventCreated;
