import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../service/api.service';

// #region INTERFACES
type Joyride = {
  tourActive: boolean;
  run: boolean;
  stepIndex: number;
};
type TopbarJoyride = Joyride & { done: boolean };

export type OnboardingStore = {
  topbar: TopbarJoyride;
  addShipper: Joyride;
  addLocation: Joyride;
  createShipperOrder: Joyride;
  addDriver: Joyride;
  createJobOrder: Joyride;
};
// #endregion

const initialState: Joyride = {
  tourActive: false,
  run: false,
  stepIndex: 0,
};
const topbarInitialState: TopbarJoyride = {
  ...initialState,
  tourActive: true,
  done: false,
};

const onboardingInitialState: OnboardingStore = {
  topbar: topbarInitialState,
  addShipper: initialState,
  addLocation: initialState,
  createShipperOrder: initialState,
  addDriver: initialState,
  createJobOrder: initialState,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: onboardingInitialState,
  reducers: {
    resetAll: () => onboardingInitialState,
    resetTopbar: (state) => ({
      ...state,
      topbar: topbarInitialState,
    }),
    resetAddShipper: (state) => ({
      ...state,
      addShipper: initialState,
    }),
    resetAddLocation: (state) => ({
      ...state,
      addLocation: initialState,
    }),
    resetCreateShipperOrder: (state) => ({
      ...state,
      createShipperOrder: initialState,
    }),
    resetAddDriver: (state) => ({
      ...state,
      addDriver: initialState,
    }),
    resetCreateJobOrder: (state) => ({
      ...state,
      createJobOrder: initialState,
    }),
    changeTopbar: (state, action: PayloadAction<Partial<TopbarJoyride>>) => ({
      ...state,
      topbar: {
        ...state.topbar,
        ...action.payload,
      },
    }),
    changeAddShipper: (state, action: PayloadAction<Partial<Joyride>>) => ({
      ...state,
      addShipper: {
        ...state.addShipper,
        ...action.payload,
      },
    }),
    changeAddLocation: (state, action: PayloadAction<Partial<Joyride>>) => ({
      ...state,
      addLocation: {
        ...state.addLocation,
        ...action.payload,
      },
    }),
    changeCreateShipperOrder: (
      state,
      action: PayloadAction<Partial<Joyride>>,
    ) => ({
      ...state,
      createShipperOrder: {
        ...state.createShipperOrder,
        ...action.payload,
      },
    }),
    changeAddDriver: (state, action: PayloadAction<Partial<Joyride>>) => ({
      ...state,
      addDriver: {
        ...state.addDriver,
        ...action.payload,
      },
    }),
    changeCreateJobOrder: (state, action: PayloadAction<Partial<Joyride>>) => ({
      ...state,
      createJobOrder: {
        ...state.createJobOrder,
        ...action.payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getOrganizationMe.matchFulfilled,
      (state, { payload }) => {
        // don't activate tour in topbar when orientation is already completed
        if (payload.organization.orientation?.isJobOrderCompleted) {
          return {
            ...state,
            topbar: {
              ...state.topbar,
              tourActive: false,
              done: true,
            },
          };
        }

        return state;
      },
    );
  },
});

export const onboardingAction = onboardingSlice.actions;
export const onboardingReducer = onboardingSlice.reducer;
