import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';
import ShipperDeactivateView from './ShipperDeactivate.view';

export type ShipperDeactivateRouteParam = DefaultRouteParam;

export const shipperDeactivateRoute: RouteConfig = {
  name: 'Deactivate',
  Component: React.lazy(() => import('./ShipperDeactivate.view')),
  NormalComponent: <ShipperDeactivateView />,
  path: '/shipper/deactivate',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
