import React from 'react';
import tw from 'twin.macro';
import { JODetailMapAction } from '../../../model/JobOrder.model';
import { Chip, Icon } from '../../atom';

const Container = tw.div`absolute flex flex-row justify-between items-center w-[calc( 100% - 68px)]  h-[60px] top-0 left-2 z-index[2]`;
const ChipContainer = tw.div`flex flex-row`;
const NavigationContainer = tw.div`mr-2 `;
interface Props {
  mapsAction: JODetailMapAction[];
  handleBack(): void;
}

function JODetailMobileMapsHeader({ mapsAction, handleBack }: Props) {
  return (
    <Container>
      <NavigationContainer>
        <Chip.Small
          left={<Icon.ArrowBack fill="#252525" />}
          tw="h-11 rounded bg-white"
          onClick={handleBack}
        />
      </NavigationContainer>

      <ChipContainer>
        {mapsAction.map((item, index, arr) => {
          const isLast = index === arr.length - 1;

          return (
            <Chip.Small
              disabled={item.disabled}
              right={
                <Icon.Target
                  fill={item.selected && !item.disabled ? '#F3532E' : undefined}
                />
              }
              key={item.label}
              tw="mr-0 h-11 rounded bg-white"
              css={[
                !isLast && tw`mr-2`,
                !item.selected && tw`hover:bg-white`,
                item.selected &&
                  !item.disabled &&
                  tw`bg-orange-hover
              `,
              ]}
              label={item.label}
              selected={item.selected && !item.disabled}
              onClick={item.action}
            />
          );
        })}
      </ChipContainer>
    </Container>
  );
}

export default JODetailMobileMapsHeader;
