import React from 'react';

export default function UseTemplate() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={174}
      height={148}
      fill="none"
    >
      <rect width={173.333} height={18.122} x={0.667} fill="#F3532E" rx={6} />
      <path
        fill="#F3532E"
        fillRule="evenodd"
        d="m16.135 30.511-4.342 4.342a2.422 2.422 0 0 1-3.424 0L4.027 30.51 5.739 28.8l4.342 4.342 4.342-4.342 1.712 1.712Z"
        clipRule="evenodd"
      />
      <circle
        cx={30.243}
        cy={32.081}
        r={5.054}
        stroke="#F3532E"
        strokeWidth={2}
      />
      <rect
        width={130.676}
        height={18}
        x={43.324}
        y={23}
        fill="#E4E0CC"
        rx={6}
      />
      <rect
        width={171.333}
        height={16.122}
        x={1.667}
        y={46.306}
        stroke="#E4E0CC"
        strokeWidth={2}
        rx={5}
      />
      <rect
        width={168.333}
        height={13.122}
        x={3.167}
        y={70.459}
        fill="#E4E0CC"
        stroke="#E4E0CC"
        strokeWidth={5}
        rx={3.5}
      />
      <rect
        width={171.333}
        height={55}
        x={1.667}
        y={92}
        stroke="#E4E0CC"
        strokeWidth={2}
        rx={5}
      />
    </svg>
  );
}
