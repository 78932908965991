import React from 'react';

export default function Rocket({
  fill = 'none',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="#616161" strokeWidth="2" clipPath="url(#clip0_9670_132097)">
        <path d="M11.23 15.424c-1.023.512-2.387.683-3.069.512L4.07 11.838c.059-.293.149-.65.259-1.025m6.902 4.61c1.294-.647 8.696-4.61 7.673-14.344-2.768-.292-8.062.001-11.99 4.844m4.317 9.5c-.409-1.229-4.262-4.952-6.138-6.66m0 0c-.27.544-.554 1.331-.764 2.05m.764-2.05a15.43 15.43 0 011.82-2.84m-2.584 4.89C3.218 10.813 1 10.608 1 9.788s1.364-2.732 2.046-3.586c.361-.357 1.64-.913 3.866-.28" />
        <path d="M14 13.5c.167.833.4 2.7 0 3.5-.5 1-4 2-4.5 2-.4 0-.5-2-.5-3" />
        <circle cx="13" cy="7" r="2" />
        <path strokeLinecap="round" d="M2.5 14l-1 1M4 16l-3 3m5-1.5l-1 1" />
      </g>
      <defs>
        <clipPath id="clip0_9670_132097">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
