import React from 'react';
import tw from 'twin.macro';
import { UseGetDriversAutocompleteObj } from '../../../hook/useGetDriversAutocomplete.hook';
import { UseGetVehiclesAutocompleteObj } from '../../../hook/useGetVehiclesAutocomplete.hook';
import { UseScheduleFilter } from '../../../view/Schedule/hooks/useScheduleFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region INTERFACES
type Props = {
  scheduleFilter: UseScheduleFilter;
  driversAutocomplete: UseGetDriversAutocompleteObj;
  vehiclesAutocomplete: UseGetVehiclesAutocompleteObj;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full max-h-screen bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const InputHeading = tw(Text.HeadingFive)`mb-2`;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
const DatePickerContainer = tw.div`mt-3 flex items-center justify-between space-x-2`;
// #endregion

function ScheduleFilter({
  scheduleFilter,
  driversAutocomplete,
  vehiclesAutocomplete,
}: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />
        <Text.HeadingFour tw="flex-1 ml-2">
          {scheduleFilter.titleLabel}
        </Text.HeadingFour>
        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          onClick={scheduleFilter.onClickReset}
        >
          {scheduleFilter.resetButtonLabel}
        </Button.Outlined>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading>{scheduleFilter.orderByLabel}</InputHeading>
          <ChipContainer>
            {scheduleFilter.filterOrderByValues.map((el) => {
              const selected = scheduleFilter.form.values.orderBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() => scheduleFilter.onClickOrderByChip(el.value)}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${scheduleFilter.testID || ''}Filter:DriverLabel`}
          >
            {scheduleFilter.driverLabel}
          </InputHeading>

          <AutoComplete
            tw="bg-orange-dark-lines"
            value={scheduleFilter.form.values.driverId}
            datas={driversAutocomplete.driverOptions}
            selectedOption={scheduleFilter.queryDriverOption}
            placeholder={scheduleFilter.driverPlaceholder}
            loading={driversAutocomplete.driverListFetchLoading}
            changeData={scheduleFilter.onChangeDriver}
            changeInputText={driversAutocomplete.onChangeDriverAutotext}
            onFetchMore={driversAutocomplete.onFetchMoreDriver}
            onRemoveData={scheduleFilter.onRemoveDriver}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${scheduleFilter.testID || ''}Filter:VehicleLabel`}
          >
            {scheduleFilter.vehicleLabel}
          </InputHeading>

          <AutoComplete
            hasExtraLabel
            labelKey="extraLabel"
            tw="bg-orange-dark-lines"
            value={scheduleFilter.form.values.vehicleId}
            datas={vehiclesAutocomplete.vehicleOptions}
            selectedOption={scheduleFilter.queryVehicleOption}
            placeholder={scheduleFilter.vehiclePlaceholder}
            loading={vehiclesAutocomplete.vehicleListFetchLoading}
            changeData={scheduleFilter.onChangeVehicle}
            changeInputText={vehiclesAutocomplete.onChangeVehicleAutotext}
            onFetchMore={vehiclesAutocomplete.onFetchMoreVehicle}
            onRemoveData={scheduleFilter.onRemoveVehicle}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${scheduleFilter.testID || ''}Filter:DateRangeLabel`}
          >
            {scheduleFilter.dateLabel}
          </InputHeading>

          <DatePickerContainer
            data-testid={`${
              scheduleFilter.testID || ''
            }Filter:DateRangeContainer`}
          >
            <DateRangePickerInput
              sdLabel={scheduleFilter.sdLabel}
              edLabel={scheduleFilter.edLabel}
              sdDateValue={scheduleFilter.form.values.startDate}
              sdOnChangeDateValue={scheduleFilter.onChangeStartDate}
              edDateValue={scheduleFilter.form.values.endDate}
              edOnChangeDateValue={scheduleFilter.onChangeEndDate}
              onClickApplyDateRange={scheduleFilter.onClickApplyDateRange}
              definedRangeOptions={scheduleFilter.dateRangeDefinedRangeOptions}
              outsideAlerterStyle={{ width: '100%' }}
              allowUndefinedDateRange
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={scheduleFilter.filterLocalIsFetching}
          onClick={scheduleFilter.onClickSubmit}
        >
          {scheduleFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default ScheduleFilter;
