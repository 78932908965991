import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import useTranslator from '../../../hook/useTranslator.hook';
import { HOTrackingType } from '../../../model/TrackingOrder.model';
import { formatText } from '../../../util/formatter.util';
import { getHOGoodsTotalValue } from '../../../util/haulingOrder.util';
import { ProgressBar, Text } from '../../atom';
import { CollapsiblePanel, TrackingHOSummary } from '../../molecule';
import { TrackingHoSummaryData } from '../../molecule/TrackingHOSummary/TrackingHOSummary.molecule';

const panelStyle = {
  header: tw`bg-white`,
  content: tw`bg-white`,
  container: tw`shadow rounded-md overflow-hidden bg-white`,
};
const Container = tw.div`max-w-2xl min-w-[720px] flex flex-col gap-5`;
const ProgressBarContainer = tw.div`p-2 flex flex-col w-full flex-1`;

type Props = {
  data?: HOTrackingType;
  forceLang?: SupportedLanguage;
  hoDelivery?: React.ReactNode;
  timeline?: React.ReactNode;
};

// #endregion
export default function TrackingHO({
  data,
  forceLang,
  hoDelivery,
  timeline,
}: Props) {
  const translator = useTranslator();

  //#region HO
  const hoSummary = useMemo((): TrackingHoSummaryData | undefined => {
    if (!data) return undefined;
    const {
      totalGoodsDelivered: tgd,
      totalGoodsTarget: tgt,
      goods,
      completedAt,
      hoDate,
    } = data;
    const totalGoodsTarget = getHOGoodsTotalValue(tgt);
    const totalGoodsDelivered = getHOGoodsTotalValue(tgd);
    return {
      totalGoodsTarget,
      totalGoodsDelivered,
      goods,
      orderCompleted: completedAt,
      orderReceived: hoDate,
    };
  }, [data]);
  //#endregion

  return (
    <Container>
      {hoSummary && (
        <CollapsiblePanel
          title={translator.translate('Summary', forceLang)}
          contentStyle={panelStyle.content}
          headerStyle={panelStyle.header}
          containerStyle={panelStyle.container}
        >
          <TrackingHOSummary
            translator={translator}
            forceLang={forceLang}
            data={hoSummary}
          />
        </CollapsiblePanel>
      )}

      {timeline}

      {hoDelivery && (
        <CollapsiblePanel
          title={translator.translate('Deliveries', forceLang)}
          contentStyle={panelStyle.content}
          headerStyle={panelStyle.header}
          containerStyle={panelStyle.container}
          customActionContainerStyle={tw`w-[200px]`}
          customAction={
            <ProgressBarContainer tw="items-end flex">
              <Text.HeadingFive>
                {formatText(
                  translator.translate('%s Completed', forceLang),
                  `${data?.completedPercentage?.toFixed(2) ?? 0}%`,
                )}
              </Text.HeadingFive>
              <ProgressBar
                max={100}
                value={data?.completedPercentage ?? 0}
                tw="w-[200px]"
              />
            </ProgressBarContainer>
          }
        >
          {hoDelivery}
        </CollapsiblePanel>
      )}
    </Container>
  );
}
