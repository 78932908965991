import React from 'react';
import tw, { theme } from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { ChevronSharp, NavReports } from '../../atom/Icon/Icon.atom';
import { HeadingFive, HeadingThree } from '../../atom/Text/Text.atom';
import { Modal } from '../../molecule';

const Container = tw.div`flex flex-col flex-1`;

const ModalContainerStyle = tw`w-[calc(100% - 60px)] p-0 border-2 border-beige-lines`;
const ModalContentStyle = tw`p-0`;

const Header = tw.div`px-5 py-3.5 bg-beige-bg rounded-t border-b-2 border-beige-lines flex gap-4 items-center`;
const Content = tw.div`px-4`;

const ItemContainer = tw.div`flex justify-between py-4 not-last-of-type:(border-b border-b-beige-lines)`;

export type ReportMenuItemProps = {
  label: string;
  handleClick: () => void;
};

type Props = {
  isVisible?: boolean;
  menuItems?: ReportMenuItemProps[];
  handleOverlayClick: () => void;
};

function Item({ label, handleClick }: ReportMenuItemProps) {
  return (
    <ItemContainer onClick={handleClick}>
      <HeadingFive tw="text-grey-two">{label}</HeadingFive>
      <ChevronSharp stroke={theme`colors.orange`} />
    </ItemContainer>
  );
}

export default function MobileBottomBarReportMenuList({
  isVisible,
  menuItems,
  handleOverlayClick,
}: Props) {
  const { translate } = useTranslator();
  if (!isVisible) return null;
  return (
    <Modal.Regular
      onOverlayClick={handleOverlayClick}
      containerStyle={ModalContainerStyle}
      contentStyle={ModalContentStyle}
    >
      <Container>
        <Header>
          <NavReports />
          <HeadingThree>{translate('Report')}</HeadingThree>
        </Header>
        <Content>
          {menuItems?.map((item) => (
            <Item
              key={item.label}
              label={item.label}
              handleClick={item.handleClick}
            />
          ))}
        </Content>
      </Container>
    </Modal.Regular>
  );
}
