import useJOFormController from '../../hook/jobOrderForm/useJOFormController.hook';
import useJOFormInitialization from '../../hook/jobOrderForm/useJOFormInitialization.hook';
import useJOFormState from '../../hook/jobOrderForm/useJOFormState.hook';

export default function useJobOrderEditDraftViewModel() {
  const initialization = useJOFormInitialization();
  const controller = useJOFormController({ initialization });
  const state = useJOFormState({
    controller,
    initialization,
  });
  return { initialization, controller, state };
}
