import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../config/router/router.config';
import { helpCenterOrderManagementHORoute } from './Hauling/HelpCenterOrderManagementHO.route';
import { helpCenterOrderManagementJORoute } from './JobOrder/HelpCenterOrderManagementJO.route';
import { helpCenterOrderManagementSORoute } from './ShipperOrder/HelpCenterOrderManagementSO.route';

export type HelpCenterRouteParam = DefaultRouteParam;

export const helpCenterOrderManagementRoute: RouteConfig = {
  name: 'Order Management',
  Component: React.lazy(() => import('./HelpCenterOrderManagement.view')),
  path: '/help-center/order-management',
  isPrivate: false,
  breadcrumb: 'Order Management',
  children: [
    {
      ...helpCenterOrderManagementSORoute,
      path: 'shipper-order',
    },
    {
      ...helpCenterOrderManagementJORoute,
      path: 'job-order',
    },
    {
      ...helpCenterOrderManagementHORoute,
      path: 'hauling-order',
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    label: 'Manajemen Pesanan',
    testID: 'MenuHelpCenterOrderManagement',
    hasDivider: true,
    hasSub: [
      helpCenterOrderManagementSORoute,
      helpCenterOrderManagementJORoute,
      helpCenterOrderManagementHORoute,
    ],
  },
};
