import React from 'react';
import tw from 'twin.macro';
import { Modal } from '..';
import useBlockRoute, {
  UseBlockRouteProps,
} from '../../../hook/useBlockRoute/useBlockRoute.hook';
import { Text } from '../../atom';

const ModalContainer = tw`p-8 width[760px]`;
const ModalActionButtonContainer = tw`justify-between`;
const ModalBodyContainer = tw.div`flex flex-col py-5`;

type Props = UseBlockRouteProps & {
  title: string;
  actionLabel: string;
  cancelActionLabel: string;
  description: string;
  isActionValid?: boolean;
  isSecondaryValid?: boolean;
  onLeavePage?(): void;
};

export default function UnsavedChangesAlertModal({
  title,
  actionLabel,
  cancelActionLabel,
  description,
  hasUnsavedChanges,
  isActionValid = true,
  isSecondaryValid = true,
  isStayOnSubmit,
  onLeavePage,
  onActionClick,
  onCancelActionClick,
  onActionClickPromise,
}: Props) {
  const { displayAlert, leavePage, stayOnPage, handleSecondaryAction } =
    useBlockRoute({
      hasUnsavedChanges,
      isStayOnSubmit,
      onActionClick,
      onCancelActionClick,
      onActionClickPromise,
    });

  if (displayAlert)
    return (
      <Modal.WithClose
        css={ModalContainer}
        title={title}
        onClose={stayOnPage}
        onOverlayClick={stayOnPage}
        ctaStyle={tw`justify-between`}
        callAction={{
          disabled: !isActionValid,
          label: actionLabel,
          action: onLeavePage || leavePage,
        }}
        cancelAction={{
          label: cancelActionLabel,
          action: isSecondaryValid ? handleSecondaryAction : stayOnPage,
        }}
        actionButtonContainer={ModalActionButtonContainer}
      >
        <ModalBodyContainer>
          <Text.BodyOne tw="width[650px]">{description}</Text.BodyOne>
        </ModalBodyContainer>
      </Modal.WithClose>
    );

  return <div id="UnsavedChangesAlertModal" />;
}
