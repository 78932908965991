import { useMemo } from 'react';
import { JobOrderInfoDeliverySO } from '../../model/JobOrder.model';
import { UseJobOrderInfoHookObj } from '../useJobOrderInfo/useJobOrderInfo.hook';
import useScreenDetection from '../useScreenDetection/useScreenDetection.hook';

type Props = {
  jobOrderInfo: UseJobOrderInfoHookObj;
  selectedDeliveryId?: string;
};

/**
 * This hook usage is only in job order detail force complete (JO & SO)
 * @param param0
 * @returns
 */
function useSelectedShipperOrder({ jobOrderInfo, selectedDeliveryId }: Props) {
  const { isMobile } = useScreenDetection();

  const data: JobOrderInfoDeliverySO | undefined = useMemo(() => {
    const deliveries = jobOrderInfo.joInfoData?.deliveries;
    if (!deliveries) return undefined;
    if (!selectedDeliveryId) {
      return deliveries[0]?.shipperOrder;
    }
    if (isMobile) {
      return deliveries.find(
        (delivery) => delivery.shipperOrder.id === selectedDeliveryId,
      )?.shipperOrder;
    }

    return deliveries.find((delivery) => delivery.id === selectedDeliveryId)
      ?.shipperOrder;
  }, [isMobile, jobOrderInfo.joInfoData?.deliveries, selectedDeliveryId]);

  return { data };
}

export type UseSelectedShipperOrderHookObj = ReturnType<
  typeof useSelectedShipperOrder
>;

export default useSelectedShipperOrder;
