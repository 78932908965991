import React from 'react';

export default function Shipper() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.634 9.25a5 5 0 10-5.209.036c-1.188.277-2.056.731-2.682 1.267a4.613 4.613 0 00-.083.072 3.726 3.726 0 00-1.523-1.243 4 4 0 10-4.081.115 3.717 3.717 0 00-1.205 1.017A4.196 4.196 0 000 13v3a2 2 0 002 2h4.696a2 2 0 001.733 1H18a2 2 0 002-2v-3.894c0-.128-.011-.296-.063-.479-.346-1.233-1.65-2.78-4.303-3.376zM13 7.98a2.98 2.98 0 110-5.96 2.98 2.98 0 010 5.96zM4 8a2 2 0 110-4 2 2 0 010 4zm2.429 5v3H2v-3a2.2 2.2 0 01.435-1.264c.285-.37.791-.736 1.78-.736.987 0 1.493.366 1.779.736A2.2 2.2 0 016.429 13zm2 4v-4c0 .005 0 .003.002-.006.013-.065.1-.483.612-.922C9.61 11.586 10.799 11 13.214 11c3.558 0 4.609 1.594 4.786 2.13V17H8.429z"
        clipRule="evenodd"
      />
    </svg>
  );
}
