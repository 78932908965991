import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../config/router/router.config';
import { helpCenterDataManagementAdditionalFunctionRoute } from './AdditionalFunction/HelpCenterDataManagementAdditionalFunction.route';
import { helpCenterDataManagementDriverRoute } from './Driver/HelpCenterDataManagementDriver.route';
import { helpCenterDataManagementLocationRoute } from './Location/HelpCenterDataManagementLocation.route';
import { helpCenterDataManagementScheduleRoute } from './Schedule/HelpCenterDataManagementSchedule.route';

export type HelpCenterDataManagementRouteParam = DefaultRouteParam;

export const helpCenterDataManagementRoute: RouteConfig = {
  name: 'Data Management',
  Component: React.lazy(() => import('./HelpCenterDataManagement.view')),
  path: '/help-center/data-management',
  isPrivate: false,
  breadcrumb: 'Data Management',
  children: [
    {
      ...helpCenterDataManagementDriverRoute,
      path: 'driver',
    },
    {
      ...helpCenterDataManagementLocationRoute,
      path: 'location',
    },
    {
      ...helpCenterDataManagementScheduleRoute,
      path: 'schedule',
    },
    {
      ...helpCenterDataManagementAdditionalFunctionRoute,
      path: 'additional-function',
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    label: 'Manajemen Data',
    testID: 'MenuHelpCenterDataManagement',
    hasDivider: true,
    hasSub: [
      helpCenterDataManagementDriverRoute,
      helpCenterDataManagementLocationRoute,
      helpCenterDataManagementScheduleRoute,
      helpCenterDataManagementAdditionalFunctionRoute,
    ],
  },
};
