import { Interval } from 'date-fns';
import addMonths from 'date-fns/addMonths';
import differenceInDays from 'date-fns/differenceInDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import isToday from 'date-fns/isToday';
import isWithinInterval from 'date-fns/isWithinInterval';
import setMonth from 'date-fns/setMonth';
import setYear from 'date-fns/setYear';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfToday from 'date-fns/startOfToday';
import startOfWeek from 'date-fns/startOfWeek';
import subMonths from 'date-fns/subMonths';
import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import tw, { css, styled, theme } from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { getMonthList } from '../../../util/date.util';
import { Button, Divider as BaseDivider, Icon, Text } from '../../atom';

// #region STYLED
const Root = tw.div`rounded w-[fit-content] outline[1px solid #e4e0cc]`;
const RootHeader = tw.header`flex items-center justify-between rounded-t bg-orange`;
const RootHeaderPlaceholder = tw.span`w-11 h-11`;
const RootHeaderDate = tw.div`space-x-6 flex items-center`;
const RootHeaderDateButton = tw.button`bg-orange`;
const RootHeaderDateButtonText = tw(Text.HeadingFour)`text-white`;
const RootHeaderCloseButton = tw.button`mr-6 rounded-t text-white bg-orange`;
const RootBody = tw.section`flex`;
const DefinedRangeContainer = tw.div`py-2.5 w-[150px] flex flex-col border-r border-beige-lines`;
const DefinedRangeButton = styled.button((props: { isSelected?: boolean }) => [
  tw`py-2.5 pl-5 pr-5 text-sm text-left font-semibold rounded-md hover:(bg-orange-hover text-orange)`,
  props.isSelected && tw`text-orange`,
]);
const CalendarStart = tw.div`min-w-[277px] border-r border-beige-lines`;
const CalendarEnd = tw.div`min-w-[276px]`;
const CalendarHeader = tw.header`px-4 py-3 flex items-center justify-between border-b border-b-beige-lines bg-beige-bg`;
const MonthStepper = tw.button`flex flex-none items-center justify-center`;
const MonthStepperPlaceholder = tw.button`w-[18px] h-full`;
const ScreenReader = tw.span`sr-only`;
const CalendarMonthAndYearButton = tw.button`flex items-center disabled:cursor-auto`;
const RootFooter = tw.div`px-5 py-2.5 flex items-center justify-between border-t border-beige-lines`;
const RootFooterNoteSection = tw.section`flex items-center space-x-2.5`;
const RootFooterNoteText = tw(
  Text.Paragraph,
)`text-xs word-wrap[normal] whitespace-pre-wrap text-black`;
const RootFooterActionSection = tw.section`flex items-center space-x-4`;

// Day Picker
const WeekList = tw.ul`px-4 pt-4 grid grid-cols-7 text-center`;
const Week = styled(Text.BodyFourteen)(
  ({ isHoliweekday }: { isHoliweekday: boolean }) => [
    tw`w-9 h-9 flex justify-center items-center`,
    isHoliweekday && tw`text-status-alert`,
  ],
);
const DayList = tw.ul`grid grid-cols-7 gap-y-1 mt-2 px-4 pb-4`;
const Day = styled.button(() => [
  css`
    ${tw`w-9 h-9 disabled:cursor-default`}

    &[data-is-start-daterange="true"]:not(&[data-is-end-daterange="true"]) {
      ${tw`border-r-0 border-top-left-radius[999px] border-bottom-left-radius[999px] bg-orange-hover`}
    }
    &[data-is-within-daterange='true']:not(&[data-is-start-daterange='true']):not(&[data-is-end-daterange='true']):not(&[data-is-within-highlight='true']) {
      ${tw`border-l-0 border-r-0 bg-orange-hover`}
    }
    &[data-is-within-daterange='true'][data-is-within-highlight='true'] {
      ${tw`bg-orange-hover`}
    }
    &[data-is-end-daterange='true']:not(&[data-is-start-daterange='true']):not(&[data-is-within-highlight='true']) {
      ${tw`border-l-0 border-top-right-radius[999px] border-bottom-right-radius[999px] bg-orange-hover`}
    }
    &[data-is-equal-daterange='true'],
    &[data-is-end-daterange='true'][data-is-end-highlight='true'] {
      ${tw`bg-transparent`}
    }

    &[data-is-within-hovereddates='true'] {
      ${tw`bg-orange-hover`}
    }
    &[data-is-end-hovereddates='true']:not(&[data-is-start-hovereddates='true']):not(&[data-is-within-highlight='true']) {
      ${tw`border-l-0 border-top-right-radius[999px] border-bottom-right-radius[999px] bg-orange-hover`}
    }
  `,
]);
const DayTime = styled.time(
  ({
    isHighlightsWithinIntervals,
    isDayEqualToStartDateOrEndDate,
    isDaySameMonthAsCurrentMonth,
    isWeekday,
  }: {
    isHighlightsWithinIntervals: boolean;
    isDayEqualToStartDateOrEndDate: boolean;
    isDaySameMonthAsCurrentMonth: boolean;
    isWeekday: boolean;
  }) => [
    tw`w-9 h-9 relative flex items-center justify-center text-sm leading-6 text-grey-two`,

    isHighlightsWithinIntervals && tw`bg-neutral-50`,
    !isHighlightsWithinIntervals &&
      isDayEqualToStartDateOrEndDate &&
      tw`bg-orange text-white rounded-full`,
    !isHighlightsWithinIntervals &&
      !isDayEqualToStartDateOrEndDate &&
      tw`hover:(rounded-full bg-orange-hover)`,
    !isDaySameMonthAsCurrentMonth && tw`opacity-40`, // dates outside month
    isWeekday && tw`text-status-alert`,

    css`
      &[data-is-today='true']:not(&[data-is-within-daterange='true']):not(&[data-is-selected-highlight-within-interval='true']):not(&[data-is-start-daterange='true']) {
        ${tw`before:(absolute inset-0 rounded-full border-2 border-black)`}
      }

      &[data-is-start-highlight='true']:not(&[data-is-end-highlight='true']) {
        ${tw`border-r-0 border-top-left-radius[999px] border-bottom-left-radius[999px]`}
      }
      &[data-is-within-highlight='true'][data-is-start-highlight='true'][data-is-end-highlight='true'] {
        ${tw`rounded-full`}
      }
      &[data-is-within-highlight='true']:not(&[data-is-start-highlight='true']):not(&[data-is-end-highlight='true']) {
        ${tw`border-l-0 border-r-0`}
      }
      &[data-is-end-highlight='true']:not(&[data-is-start-highlight='true']) {
        ${tw`border-l-0 border-top-right-radius[999px] border-bottom-right-radius[999px]`}
      }
      &[data-is-selected-highlight-within-interval='true'] {
        ${tw`border border-orange bg-orange-hover`}
      }
    `,
  ],
);

// Month Picker
const Divider = tw(BaseDivider)`w-full my-3.5`;
const MonthList = tw.ul`px-4 pt-4 min-w-[277px] flex flex-wrap justify-between`;
const Month = tw.button`h-9 w-1/4 flex items-center justify-center rounded-full text-black hover:bg-orange-hover`;

// Year Picker
const YearList = tw.ul`px-4 pt-4 min-w-[277px] max-h-[330px] flex flex-wrap justify-between overflow-y-auto`;
const Year = tw.button`h-9 w-1/4 flex items-center justify-center rounded-full text-black hover:bg-orange-hover`;
// #endregion

// #region TYPES
export type NativeCalendarRangeYearRange = {
  from: number;
  to: number;
};
export type NativeCalendarRangeChoosePicker = 'day' | 'month' | 'year';
export type NativeCalendarRangeCurrentSelection = 'start' | 'end';
export type NativeCalendarRangeDefinedRangeOption = {
  label: string;
  startRange: Date;
  endRange: Date;
};
export type NativeCalendarRangeHoliweekday = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type NativeCalendarRangeHighlight = {
  id: string;
  start: Date;
  end: Date;
};
export type NativeCalendarRangeHandleChangeDateRangeParams = {
  currentSelection: string; // 'start' | 'end'
  start?: Date | undefined;
  end?: Date | undefined;
};
type NativeCalendarRangeState = {
  startPicker: NativeCalendarRangeChoosePicker;
  endPicker: NativeCalendarRangeChoosePicker;
  startMonth: Date;
  endMonth: Date;
  currentSelection: NativeCalendarRangeCurrentSelection;
  start: Date | undefined;
  end: Date | undefined;
  hoverInterval: Interval | undefined;
};

type NativeCalendarRangeYearPickerProps = {
  monthAndYear: Date;
  handleClickChangeYear: (monthIdx: number) => void;

  yearRange?: NativeCalendarRangeYearRange;
};

type NativeCalendarRangeMonthPickerProps = {
  monthAndYear: Date;
  handleClickChangeMonth: (monthIdx: number) => void;
};

type NativeCalendarRangeDayPickerProps = {
  state: NativeCalendarRangeState;
  monthAndYear: Date;
  handleClickDay: (_day: Date) => void;
  handleMouseOverDay: (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _day: Date,
  ) => void;

  disabledDays?: Date[];
  holiweekdays?: NativeCalendarRangeHoliweekday[];
  highlights?: NativeCalendarRangeHighlight[];
  selectedHighlight?: NativeCalendarRangeHighlight;
};

export type NativeCalendarRangeProps = Omit<
  NativeCalendarRangeDayPickerProps,
  'state' | 'monthAndYear' | 'handleClickDay' | 'handleMouseOverDay'
> &
  Pick<NativeCalendarRangeYearPickerProps, 'yearRange'> & {
    startDate: Date | undefined;
    endDate: Date | undefined;

    handleClickNextMonth?: (
      state: Pick<NativeCalendarRangeState, 'startMonth' | 'endMonth'>,
    ) => void;
    handleClickPrevMonth?: (
      state: Pick<NativeCalendarRangeState, 'startMonth' | 'endMonth'>,
    ) => void;
    handleChangeDateRange?: (
      state: NativeCalendarRangeHandleChangeDateRangeParams,
    ) => void;
    handleClickCancel?: () => void;
    handleClickSubmit?: (dateRange: { start: Date; end: Date }) => void;
    defaultStartMonth?: Date;
    defaultEndMonth?: Date;
    showHeader?: boolean;
    showPreviousMonthBtn?: boolean;
    showNextMonthBtn?: boolean;
    disableMonthAndYearPicker?: boolean;
    allowUndefinedDateRange?: boolean;
    disableDefinedRange?: boolean;
    definedRangeOptions?: NativeCalendarRangeDefinedRangeOption[];
    notes?: string;
  };
// #endregion

const colStartClasses = [
  tw``,
  tw`col-start-2`,
  tw`col-start-3`,
  tw`col-start-4`,
  tw`col-start-5`,
  tw`col-start-6`,
  tw`col-start-7`,
];

// #region YEAR PICKER
function YearPicker({
  monthAndYear,
  handleClickChangeYear,
  yearRange,
}: NativeCalendarRangeYearPickerProps) {
  const yearOptions = useMemo(() => {
    if (yearRange) {
      const { from, to } = yearRange;
      return Array.from(Array(to - from + 1).keys()).map((i) => i + from);
    }

    // Default year range 25 years to the past and 25 years to the future
    return Array.from(Array(50).keys()).map(
      (i) => i + new Date().getFullYear() - 25,
    );
  }, [yearRange]);

  return (
    <YearList data-testid="NativeCalendarRange:Year">
      {yearOptions.map((_year, idx) => (
        <React.Fragment key={`NativeCalendarRange:Year:${_year}`}>
          <Year
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              handleClickChangeYear(_year);
            }}
            css={[
              _year === monthAndYear.getFullYear() &&
                tw`bg-orange text-white hover:bg-orange`,
            ]}
            data-year={_year}
          >
            {_year}
          </Year>

          {idx !== 0 && idx % 4 === 3 && <Divider />}
        </React.Fragment>
      ))}
    </YearList>
  );
}
// #endregion

// #region MONTH PICKER
function MonthPicker({
  monthAndYear,
  handleClickChangeMonth,
}: NativeCalendarRangeMonthPickerProps) {
  const translator = useTranslator();
  const listMonth = useMemo(
    () => getMonthList(translator.currentLanguage),
    [translator.currentLanguage],
  );

  return (
    <MonthList data-testid="NativeCalendarRange:Month">
      {listMonth.map((_month, idx) => (
        <React.Fragment key={`NativeCalendarRange:Month:${_month}`}>
          <Month
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              handleClickChangeMonth(idx); // idx starts at 0, which equals to "January"
            }}
            css={[
              idx === monthAndYear.getMonth() &&
                tw`bg-orange text-white hover:bg-orange`,
            ]}
            data-month={_month}
          >
            {_month.slice(0, 3)}
          </Month>

          {(idx === 3 || idx === 7) && <Divider />}
        </React.Fragment>
      ))}
    </MonthList>
  );
}
// #endregion

// #region DAY PICKER
function DayPicker({
  state,
  monthAndYear,
  disabledDays,
  holiweekdays = [],
  highlights = [],
  selectedHighlight,
  handleClickDay,
  handleMouseOverDay,
}: NativeCalendarRangeDayPickerProps) {
  const translator = useTranslator();
  const startMonthOfSelectedFrom = startOfMonth(monthAndYear);

  // NOTE: to show additional date overflow, wrap it in `startOfWeek` to `start` OR `endOfWeek` to `end` with `{ weekStartsOn: 0 }`
  const days = eachDayOfInterval({
    start: startOfWeek(startMonthOfSelectedFrom, { weekStartsOn: 0 }),
    end: endOfWeek(endOfMonth(startMonthOfSelectedFrom), { weekStartsOn: 0 }),
  });

  const checkIsHoliweekday = (weekday: NativeCalendarRangeHoliweekday) =>
    holiweekdays.some((_weekday) => _weekday === weekday);
  const checkIsDaySameMonthAsCurrentMonth = (_day: Date) =>
    isSameMonth(_day, startMonthOfSelectedFrom);
  const checkIsHighlightsWithinIntervals = (_day: Date) =>
    highlights.some((_highlight) => isWithinInterval(_day, _highlight));

  const getCustomDataAttrs = useCallback(
    (_day: Date, _dayIdx: number) => ({
      'data-is-today': isToday(_day),
      // ----------- COLUMN -----------
      'data-is-first-column': _dayIdx % 7 === 0,
      'data-is-last-column': _dayIdx !== 0 && _dayIdx % 7 === 6,
      // ----------- DATERANGE -----------
      'data-is-equal-daterange':
        !!state.start && !!state.end && isSameDay(state.start, state.end),
      'data-is-start-daterange': !!state.start && isSameDay(_day, state.start),
      'data-is-within-daterange':
        !!state.start &&
        !!state.end &&
        isWithinInterval(_day, { start: state.start, end: state.end }),
      'data-is-end-daterange': !!state.end && isSameDay(_day, state.end),
      // ----------- HOVEREDDATES -----------
      'data-is-start-hovereddates':
        state.hoverInterval && isSameDay(_day, state.hoverInterval.start),
      'data-is-within-hovereddates':
        state.hoverInterval && isWithinInterval(_day, state.hoverInterval),
      'data-is-end-hovereddates':
        state.hoverInterval && isSameDay(_day, state.hoverInterval.end),
      // ----------- HIGHLIGHT -----------
      'data-is-start-highlight': highlights.some(
        (_highlight) =>
          isWithinInterval(_day, _highlight) &&
          isSameDay(_day, startOfDay(_highlight.start)),
      ),
      'data-is-within-highlight': highlights.some((_highlight) =>
        isWithinInterval(_day, _highlight),
      ),
      'data-is-end-highlight': highlights.some(
        (_highlight) =>
          isWithinInterval(_day, _highlight) &&
          isSameDay(_day, startOfDay(_highlight.end)),
      ),
      'data-is-selected-highlight-within-interval':
        !!selectedHighlight && isWithinInterval(_day, selectedHighlight),
    }),
    [
      highlights,
      selectedHighlight,
      state.end,
      state.hoverInterval,
      state.start,
    ],
  );

  return (
    <>
      <WeekList>
        <Week isHoliweekday={checkIsHoliweekday(0)}>
          {translator.translate('Sun').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(1)}>
          {translator.translate('Mon').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(2)}>
          {translator.translate('Tue').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(3)}>
          {translator.translate('Wed').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(4)}>
          {translator.translate('Thu').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(5)}>
          {translator.translate('Fri').slice(0, 1)}
        </Week>
        <Week isHoliweekday={checkIsHoliweekday(6)}>
          {translator.translate('Sat').slice(0, 1)}
        </Week>
      </WeekList>

      <DayList>
        {days.map((_day, _dayIdx) => {
          const customAttrs = getCustomDataAttrs(_day, _dayIdx);

          return (
            <Day
              type="button"
              key={_day.toString()}
              disabled={disabledDays?.some((_disabledDay) =>
                isSameDay(_day, _disabledDay),
              )}
              onClick={() => handleClickDay(_day)}
              onMouseOver={(evt) => handleMouseOverDay(evt, _day)}
              css={[_dayIdx === 0 && colStartClasses[getDay(_day)]]}
              style={
                !checkIsDaySameMonthAsCurrentMonth(_day)
                  ? ({ '--tw-bg-opacity': '0.4' } as CSSProperties)
                  : undefined
              }
              {...customAttrs}
            >
              <DayTime
                dateTime={format(_day, 'yyyy-MM-dd')}
                isHighlightsWithinIntervals={checkIsHighlightsWithinIntervals(
                  _day,
                )}
                isDayEqualToStartDateOrEndDate={
                  (!!state.start && isSameDay(_day, state.start)) ||
                  (!!state.end && isSameDay(_day, state.end))
                }
                isDaySameMonthAsCurrentMonth={isSameMonth(
                  _day,
                  startMonthOfSelectedFrom,
                )}
                isWeekday={checkIsHoliweekday(getDay(_day))}
                {...customAttrs}
              >
                {format(_day, 'd')}
              </DayTime>
            </Day>
          );
        })}
      </DayList>
    </>
  );
}
// #endregion

export default function NativeCalendarRange({
  startDate,
  endDate,
  handleChangeDateRange,
  handleClickCancel,
  handleClickNextMonth,
  handleClickPrevMonth,
  handleClickSubmit,
  defaultStartMonth,
  defaultEndMonth,
  showHeader = false,
  showPreviousMonthBtn = false,
  showNextMonthBtn = false,
  disableMonthAndYearPicker = false,
  allowUndefinedDateRange = false,
  disableDefinedRange = false,
  definedRangeOptions,
  notes,

  disabledDays,
  holiweekdays,
  highlights,
  selectedHighlight,

  yearRange,
}: NativeCalendarRangeProps) {
  // #region VALUES
  const today = startOfToday();
  const [state, setState] = useState<NativeCalendarRangeState>({
    startPicker: 'day',
    endPicker: 'day',
    startMonth: defaultStartMonth ?? startDate ?? today,
    endMonth: defaultEndMonth ?? endDate ?? addMonths(today, 1),
    currentSelection: 'start',
    start: startDate,
    end: endDate,
    hoverInterval: undefined,
  });
  const translator = useTranslator();

  const dateRangeDifferenceInDays =
    state.start && state.end && differenceInDays(state.end, state.start);
  // #endregion

  // #region HANDLERS
  const onClickPicker = (picker: 'startPicker' | 'endPicker') => {
    if (state[picker] === 'day')
      setState((prev) => ({ ...prev, [picker]: 'month' }));
    if (state[picker] === 'month')
      setState((prev) => ({ ...prev, [picker]: 'year' }));
    if (state[picker] === 'year')
      setState((prev) => ({ ...prev, [picker]: 'day' }));
  };
  const onClickPreviousMonth = () => {
    const newState: Pick<NativeCalendarRangeState, 'startMonth' | 'endMonth'> =
      {
        startMonth: subMonths(state.startMonth, 1),
        endMonth: subMonths(state.endMonth, 1),
      };

    handleClickPrevMonth?.(newState);
    setState((prev) => ({
      ...prev,
      ...newState,
    }));
  };
  const onClickNextMonth = () => {
    const newState: Pick<NativeCalendarRangeState, 'startMonth' | 'endMonth'> =
      {
        startMonth: addMonths(state.startMonth, 1),
        endMonth: addMonths(state.endMonth, 1),
      };
    handleClickNextMonth?.(newState);
    setState((prev) => ({
      ...prev,
      ...newState,
    }));
  };
  const onClickAll = () => {
    setState((prev) => ({ ...prev, start: undefined, end: undefined }));
  };
  const onClickDefinedRange = (_startRange: Date, _endRange: Date) => {
    setState((prev) => ({
      ...prev,
      start: startOfDay(_startRange),
      end: endOfDay(_endRange),
    }));
  };
  const onMouseOverDay = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _day: Date,
  ) => {
    const isBeforeStartDateWhenInEndSelection =
      state.currentSelection === 'end' &&
      !!state.start &&
      isBefore(_day, state.start);

    if (
      state.currentSelection === 'start' ||
      isBeforeStartDateWhenInEndSelection
    )
      return;

    // days within start date and hovered date
    const hoverInterval = {
      start: state.start as Date,
      end: _day,
    };

    setState((prev) => ({
      ...prev,
      hoverInterval,
    }));
  };
  const onClickDay = (_day: Date) => {
    let newState = {
      currentSelection: state.currentSelection === 'start' ? 'end' : 'start',
      ...(state.currentSelection === 'start' && { start: _day }),
      ...(state.currentSelection === 'end' && { end: _day }),
    };

    const isBeforeStartDateWhenInEndSelection =
      state.currentSelection === 'end' &&
      !!state.start &&
      isBefore(_day, state.start);

    if (isBeforeStartDateWhenInEndSelection)
      newState = { currentSelection: 'end', start: _day };
    if (state.start && state.end && state.currentSelection === 'start')
      newState = { ...newState, start: _day, end: undefined };

    setState((prev) => ({
      ...prev,
      ...(newState as NativeCalendarRangeState),
      hoverInterval: undefined,
    }));

    handleChangeDateRange?.(newState);
  };
  const onClickChangeMonth = (
    selection: NativeCalendarRangeCurrentSelection,
    monthIdx: number,
  ) => {
    setState((prev) => ({
      ...prev,
      [`${selection}Month`]: setMonth(prev[`${selection}Month`], monthIdx),
      [`${selection}Picker`]: 'day', // back to day picker
    }));
  };
  const onClickChangeYear = (
    selection: NativeCalendarRangeCurrentSelection,
    year: number,
  ) => {
    setState((prev) => ({
      ...prev,
      [`${selection}Month`]: setYear(prev[`${selection}Month`], year),
      [`${selection}Picker`]: 'day', // back to day picker
    }));
  };

  const checkDefinedRangeIsSelected = (
    _startRange: Date,
    _endRange: Date,
  ): boolean => {
    if (state.start && state.end)
      return (
        isSameDay(state.start, _startRange) && isSameDay(state.end, _endRange)
      );

    return false;
  };
  const checkCustomDefinedRangeIsSelected = (): boolean => {
    let isSelected = false;

    if (state.start && state.end && definedRangeOptions) {
      const listOfDefinedRangeIsSelected = definedRangeOptions.map(
        ({ startRange, endRange }) =>
          checkDefinedRangeIsSelected(startRange, endRange),
      );

      // inactive when there even 1 option is "true"
      isSelected = !listOfDefinedRangeIsSelected.some(Boolean);
    }

    return isSelected;
  };
  // #endregion

  return (
    <Root data-testid="NativeCalendarRange:Root">
      {showHeader && (
        <RootHeader>
          <RootHeaderPlaceholder />

          <RootHeaderDate>
            <RootHeaderDateButton type="button">
              <RootHeaderDateButtonText>
                {state.start
                  ? format(state.start, 'dd MMM yyyy')
                  : 'Start Date'}
              </RootHeaderDateButtonText>
            </RootHeaderDateButton>

            <Icon.Arrow pathStroke={theme`colors.white`} />

            <RootHeaderDateButton type="button">
              <RootHeaderDateButtonText>
                {state.end ? format(state.end, 'dd MMM yyyy') : 'End Date'}
              </RootHeaderDateButtonText>
            </RootHeaderDateButton>
          </RootHeaderDate>

          <RootHeaderCloseButton type="button" onClick={handleClickCancel}>
            <Icon.Close />
          </RootHeaderCloseButton>
        </RootHeader>
      )}

      <RootBody>
        {!disableDefinedRange && (
          <DefinedRangeContainer>
            {allowUndefinedDateRange && (
              <DefinedRangeButton
                isSelected={!dateRangeDifferenceInDays}
                onClick={onClickAll}
              >
                {translator.translate('All')}
              </DefinedRangeButton>
            )}

            {definedRangeOptions?.map((option) => (
              <DefinedRangeButton
                key={`DefinedRangeOption-${option.label}`}
                isSelected={checkDefinedRangeIsSelected(
                  option.startRange,
                  option.endRange,
                )}
                onClick={() =>
                  onClickDefinedRange(option.startRange, option.endRange)
                }
              >
                {translator.translate(option.label)}
              </DefinedRangeButton>
            ))}

            <DefinedRangeButton
              isSelected={checkCustomDefinedRangeIsSelected()}
              onClick={() => {}}
            >
              {translator.translate('Custom')}
            </DefinedRangeButton>
          </DefinedRangeContainer>
        )}

        <CalendarStart>
          <CalendarHeader>
            {showPreviousMonthBtn ? (
              <MonthStepper type="button" onClick={onClickPreviousMonth}>
                <ScreenReader>
                  {translator.translate('Previous month')}
                </ScreenReader>
                <Icon.ChevronSharp
                  style={{ transform: 'rotate(180deg)' }}
                  stroke={theme`colors.orange.DEFAULT`}
                  width={18}
                  height={18}
                />
              </MonthStepper>
            ) : (
              <MonthStepperPlaceholder type="button">
                <ScreenReader>
                  {translator.translate('Previous month')}
                </ScreenReader>
              </MonthStepperPlaceholder>
            )}

            <CalendarMonthAndYearButton
              type="button"
              disabled={disableMonthAndYearPicker}
              onClick={
                disableMonthAndYearPicker
                  ? undefined
                  : () => onClickPicker('startPicker')
              }
            >
              <Text.HeadingFive>
                {format(
                  state.startMonth,
                  state.startPicker === 'day' ? 'MMMM yyyy' : 'yyyy',
                )}
              </Text.HeadingFive>
              {!disableMonthAndYearPicker && <Icon.Triangle />}
            </CalendarMonthAndYearButton>

            <MonthStepperPlaceholder type="button">
              <ScreenReader>{translator.translate('Next month')}</ScreenReader>
            </MonthStepperPlaceholder>
          </CalendarHeader>

          {state.startPicker === 'day' && (
            <DayPicker
              state={state}
              monthAndYear={state.startMonth}
              disabledDays={disabledDays}
              holiweekdays={holiweekdays}
              highlights={highlights}
              selectedHighlight={selectedHighlight}
              handleClickDay={onClickDay}
              handleMouseOverDay={onMouseOverDay}
            />
          )}

          {state.startPicker === 'month' && (
            <MonthPicker
              monthAndYear={state.startMonth}
              handleClickChangeMonth={(_monthIdx) =>
                onClickChangeMonth('start', _monthIdx)
              }
            />
          )}

          {state.startPicker === 'year' && (
            <YearPicker
              monthAndYear={state.endMonth}
              handleClickChangeYear={(_year) =>
                onClickChangeYear('start', _year)
              }
              yearRange={yearRange}
            />
          )}
        </CalendarStart>

        <CalendarEnd>
          <CalendarHeader>
            <MonthStepperPlaceholder type="button">
              <ScreenReader>
                {translator.translate('Previous month')}
              </ScreenReader>
            </MonthStepperPlaceholder>

            <CalendarMonthAndYearButton
              type="button"
              disabled={disableMonthAndYearPicker}
              onClick={
                disableMonthAndYearPicker
                  ? undefined
                  : () => onClickPicker('endPicker')
              }
            >
              <Text.HeadingFive>
                {format(
                  state.endMonth,
                  state.endPicker === 'day' ? 'MMMM yyyy' : 'yyyy',
                )}
              </Text.HeadingFive>
              {!disableMonthAndYearPicker && <Icon.Triangle />}
            </CalendarMonthAndYearButton>

            {showNextMonthBtn ? (
              <MonthStepper type="button" onClick={onClickNextMonth}>
                <ScreenReader>
                  {translator.translate('Next month')}
                </ScreenReader>
                <Icon.ChevronSharp
                  stroke={theme`colors.orange.DEFAULT`}
                  width={18}
                  height={18}
                />
              </MonthStepper>
            ) : (
              <MonthStepperPlaceholder type="button">
                <ScreenReader>
                  {translator.translate('Next month')}
                </ScreenReader>
              </MonthStepperPlaceholder>
            )}
          </CalendarHeader>

          {state.endPicker === 'day' && (
            <DayPicker
              state={state}
              monthAndYear={state.endMonth}
              disabledDays={disabledDays}
              holiweekdays={holiweekdays}
              highlights={highlights}
              selectedHighlight={selectedHighlight}
              handleClickDay={onClickDay}
              handleMouseOverDay={onMouseOverDay}
            />
          )}

          {state.endPicker === 'month' && (
            <MonthPicker
              monthAndYear={state.endMonth}
              handleClickChangeMonth={(_monthIdx) =>
                onClickChangeMonth('end', _monthIdx)
              }
            />
          )}

          {state.endPicker === 'year' && (
            <YearPicker
              monthAndYear={state.endMonth}
              handleClickChangeYear={(_year) => onClickChangeYear('end', _year)}
              yearRange={yearRange}
            />
          )}
        </CalendarEnd>
      </RootBody>

      {handleClickCancel && handleClickSubmit && (
        <RootFooter>
          <RootFooterNoteSection>
            {notes && (
              <>
                <Icon.InfoOutlined fillPath="black" />
                <RootFooterNoteText>{notes}</RootFooterNoteText>
              </>
            )}
          </RootFooterNoteSection>

          <RootFooterActionSection>
            <Button.Outlined type="button" onClick={handleClickCancel}>
              {translator.translate('Cancel')}
            </Button.Outlined>

            <Button.Solid
              type="button"
              disabled={!state.start || !state.end}
              onClick={() =>
                handleClickSubmit({
                  start: state.start as Date,
                  end: state.end as Date,
                })
              }
            >
              {translator.translate('Apply')}
            </Button.Solid>
          </RootFooterActionSection>
        </RootFooter>
      )}
    </Root>
  );
}
