import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  pathStroke?: string;
};

export default function Clock({
  width = 21,
  height = 20,
  viewBox = '0 0 21 20',
  fill = 'none',
  pathStroke = '#000',
  ...svgProps
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <circle cx={10.5} cy={10} r={9} stroke={pathStroke} strokeWidth={2} />
      <path
        d="M10.5 6V11H14.5"
        stroke={pathStroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
