import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  widthRect?: string;
  heightRect?: string;
  fillRect?: string;
  rxRect?: string;
  strokePath?: string;
  strokeWidthPath?: string;
  isSmall?: boolean;
};
export default function CloseOutlined({
  fill = 'none',
  width = 32,
  height = 32,
  viewBox = '0 0 32 32',
  widthRect = '32',
  heightRect = '32',
  fillRect = '#D11',
  rxRect = '16',
  strokePath = '#fff',
  strokeWidthPath = '4',
  isSmall,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <rect width={widthRect} height={heightRect} fill={fillRect} rx={rxRect} />
      <path
        stroke={strokePath}
        strokeWidth={strokeWidthPath}
        d={
          isSmall
            ? 'M4.667 11.556l6.666-6.667m0 6.667L4.667 4.889'
            : 'M9.333 23.111L22.667 9.778m0 13.333L9.333 9.778'
        }
      />
    </svg>
  );
}
