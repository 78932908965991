import { Locale } from './locale.config';

const shipperListLocaleConfig: Locale[] = [
  {
    en: 'Shipper Name A-Z',
    id: 'Nama A-Z',
  },
  {
    en: 'Shipper Name Z-A',
    id: 'Nama Z-A',
  },
  {
    en: 'Status Ascending',
    id: 'Status Meningkat',
  },
  {
    en: 'Status Descending',
    id: 'Status Menurun',
  },
  {
    en: 'Filter Shipper',
    id: 'Filter Pengirim',
  },
  {
    en: 'Phone Number',
    id: 'Nomor Telepon',
  },
  {
    en: 'Email',
    id: 'Email',
  },
  {
    en: 'Shipper not found.',
    id: 'Pengirim tidak ditemukan.',
  },
  {
    en: 'Shipper already deactivated',
    id: 'Pengirim telah dinonaktifkan',
  },
  {
    en: "You don't have any selected Shippers that you want to deactivate",
    id: 'Anda tidak memiliki daftar Pengirim yang ingin dinonaktifkan',
  },
  {
    en: 'Try to select some Shippers from Shipper page',
    id: 'Cobalah untuk memilih beberapa Pengirim di halaman Pengirim',
  },
  {
    en: 'Go back to Shipper page',
    id: 'Kembali ke halaman Pengirim',
  },
  {
    en: 'Payment amount must be at least Rp1',
    id: 'Jumlah pembayaran minimal Rp1',
  },
  {
    en: 'Payment amount is required',
    id: 'Jumlah pembayaran wajib diisi',
  },
  {
    en: 'You can search by Shipper Name, Phone Number or Email',
    id: 'Cari menggunakan Pengirim, Nomor Telepon atau Email',
  },
];

export default shipperListLocaleConfig;
