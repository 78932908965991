import { Locale } from './locale.config';

const shipperOrderDetailLocale: Locale[] = [
  {
    en: 'Page Not Found',
    id: 'Halaman Tidak Ditemukan',
  },
  {
    en: 'Back to Shipper Order List',
    id: 'Kembali ke Daftar Shipper Order',
  },
  {
    en: 'Header',
    id: 'Informasi Umum',
  },
  {
    en: 'Delivery Cost',
    id: 'Biaya Pengiriman',
  },
  {
    en: 'Activity',
    id: 'Aktivitas',
  },
  {
    en: 'Shipper Order Activity Detail',
    id: 'Rincian Aktivitas Shipper Order',
  },
  {
    en: 'Currently shipper order information is not available',
    id: 'Informasi shipper order saat ini belum ada',
  },
  {
    en: 'Job Order Detail',
    id: 'Rincian Perintah Kerja',
  },
  {
    en: 'Goods Delivery Cost',
    id: 'Biaya Pengiriman Barang',
  },
  {
    en: 'Tax',
    id: 'Pajak',
  },
  {
    en: 'Insurance',
    id: 'Asuransi',
  },
  {
    en: 'Total',
    id: 'Total',
  },
  {
    en: 'Shipper Order Date*',
    id: 'Tanggal Shipper Order*',
  },
  {
    en: 'Reference Number',
    id: 'Nomor Referensi',
  },
  {
    en: 'Shipper Order Number',
    id: 'Nomor Shipper Order',
  },
  {
    en: 'Shipper Name*',
    id: 'Nama Pengirim*',
  },
  {
    en: 'Notes',
    id: 'Catatan',
  },
  {
    en: 'Goods Received by Driver',
    id: 'Barang Diterima oleh Sopir',
  },
  {
    en: 'Goods Delivered by Driver',
    id: 'Barang Diserahkan oleh Sopir',
  },
  {
    en: 'Edit Goods',
    id: 'Ubah Barang',
  },
  {
    en: 'Update',
    id: 'Update',
  },
  {
    en: 'Vehicle',
    id: 'Kendaraan',
  },
  {
    en: 'Seal Number',
    id: 'Nomor Segel',
  },
  {
    en: 'Job Order Date',
    id: 'Tanggal Perintah Kerja',
  },
  {
    en: 'Edit Goods Delivered by Driver',
    id: 'Ubah Barang Diserahkan oleh Driver',
  },
  {
    en: 'Add Goods Delivered by Driver',
    id: 'Tambah Barang Diserahkan oleh Driver',
  },
  {
    en: 'Successfully edited Delivered by Driver',
    id: 'Berhasil ubah barang diserahkan oleh Driver',
  },
  {
    en: 'Successfully added Delivered by Driver',
    id: 'Berhasil tambah barang diserahkan oleh Driver',
  },
  {
    en: 'Unsaved changes',
    id: 'Perubahan belum tersimpan',
  },
  {
    en: 'You have unsaved changes, do you want to cancel this updates?',
    id: 'Anda memiliki perubahan yang belum tersimpan, anda yakin ingin membatalkan pembaruan ini?',
  },
  {
    en: 'You have unsaved changes, do you want to cancel this updates?',
    id: 'Anda memiliki perubahan yang belum tersimpan, anda yakin ingin membatalkan pembaruan ini?',
  },
  {
    en: 'Weight & Volume',
    id: 'Volume & Berat',
  },
  {
    en: 'Submitted goods is not equal to the data in the database',
    id: 'Daftar barang terkirim tidak sesuai dengan data di database',
  },
  {
    en: 'Unable to update the goods more than 72 hours on delivered shipper order',
    id: 'Tidak bisa mengubah data barang pada shipper order dengan status terkirim lebih dari 72 jam',
  },
  {
    en: "You're not allowed do this process",
    id: 'Anda tidak diperbolehkan melakukan proses ini',
  },
  {
    en: 'Only dropoff activity is allowed to be updated',
    id: 'Hanya aktivitas dropoff yang dapat diubah',
  },
  {
    en: 'Activity goods is not found',
    id: 'Barang pada aktivitas tidak ditemukan',
  },
  {
    en: 'Goods is empty',
    id: 'Daftar barang kosong',
  },
  {
    en: 'Information is not yet available',
    id: 'Informasi belum tersedia',
  },
  {
    en: 'You can update and edit the goods delivered by driver up to',
    id: 'Anda bisa ubah dan perbarui barang diserahkan oleh driver sampai',
  },
  {
    en: '72 hours',
    id: '72 jam',
  },
  {
    en: 'after the Shipper Order status changes to',
    id: 'setelah status shipper order berubah menjadi',
  },
  {
    en: 'Proof of Activity',
    id: 'Bukti Kirim',
  },
  {
    en: 'SO Successfully Updated.',
    id: 'Shipper Order berhasil diubah.',
  },
  {
    en: 'Add New Location',
    id: 'Tambah Lokasi Baru',
  },
  {
    en: 'Add New Shipper',
    id: 'Tambah Pengirim Baru',
  },
  {
    en: 'Save and Use Shipper',
    id: 'Simpan dan Gunakan Pengirim',
  },
  {
    en: 'Ongoing',
    id: 'Berlangsung',
  },
  {
    en: 'Origin Location',
    id: 'Lokasi Awal',
  },
  {
    en: 'Destination Location',
    id: 'Lokasi Akhir',
  },
  {
    en: 'Are you sure to delete this Shipper Order?',
    id: 'Anda yakin ingin menghapus Shipper Order ini?',
  },
  {
    en: 'Once deleted, this Shipper Order cannot be restored',
    id: 'Setelah dihapus, Shipper Order ini tidak dapat dikembalikan',
  },
  {
    en: 'Successfully deleted %s',
    id: '%s berhasil dihapus',
  },
];

export default shipperOrderDetailLocale;
