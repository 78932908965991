import { useCallback, useMemo } from 'react';
import { OnTimelineContentImageClick } from '../../../component/molecule/TimelineContentItem/TimelineContentItem.type';
import { ShipperOrderDetailTab } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { Photo } from '../../../model/Common.model';
import { generateCommonGalleryCaption } from '../../../util/helper.util';
import { formatText } from '../../../util/tracking/trackingTimeline.util';
import { UseImageGalleryControllerHookObj } from '../../ImageGallery/hooks/useImageGalleryController.hook';
import { UseSODetailActivity } from './useSODetailActivity.hook';
import { UseSODetailController } from './useSODetailController.hook';
import { UseSODetailCost } from './useSODetailCost.hook';
import { UseSODetailHeader } from './useSODetailHeader.hook';

type Props = {
  soDetailController: UseSODetailController;
  soDetailHeader: UseSODetailHeader;
  soDetailCost: UseSODetailCost;
  soDetailActivity: UseSODetailActivity;
  imageGalleryController: UseImageGalleryControllerHookObj;
};
export type UseSODetailConfirmation = ReturnType<
  typeof useSODetailConfirmation
>;

export default function useSODetailConfirmation({
  soDetailController,
  soDetailHeader,
  soDetailCost,
  soDetailActivity,
  imageGalleryController,
}: Props) {
  const translator = useTranslator();

  const isFormValid = useCallback(
    (val?: ShipperOrderDetailTab) => {
      if (!val) return false;
      const mapper = {
        [ShipperOrderDetailTab.HEADER]: soDetailHeader.soHeaderForm.isValid,
        [ShipperOrderDetailTab.DELIVERY_COST]: soDetailCost.soCostForm.isValid,
        [ShipperOrderDetailTab.ACTIVITY]:
          soDetailActivity.soActivityForm.isValid,
        [ShipperOrderDetailTab.JO_INFO]: false,
        [ShipperOrderDetailTab.TRACKING_ORDER]: false,
      };
      return mapper[val];
    },
    [
      soDetailActivity.soActivityForm.isValid,
      soDetailCost.soCostForm.isValid,
      soDetailHeader.soHeaderForm.isValid,
    ],
  );

  const handleSubmitConfirmation = useCallback(
    (val?: ShipperOrderDetailTab) => {
      if (!val) return undefined;
      const submitMapper = {
        [ShipperOrderDetailTab.HEADER]:
          soDetailHeader.soHeaderForm.handleSubmit,
        [ShipperOrderDetailTab.DELIVERY_COST]:
          soDetailCost.soCostForm.handleSubmit,
        [ShipperOrderDetailTab.ACTIVITY]:
          soDetailActivity.soActivityForm.handleSubmit,
        [ShipperOrderDetailTab.JO_INFO]: () => {},
        [ShipperOrderDetailTab.TRACKING_ORDER]: () => {},
      };
      return submitMapper[val]();
    },
    [
      soDetailActivity.soActivityForm.handleSubmit,
      soDetailCost.soCostForm.handleSubmit,
      soDetailHeader.soHeaderForm.handleSubmit,
    ],
  );

  const handleDiscardChanges = useCallback(
    (val?: ShipperOrderDetailTab, nextTab?: ShipperOrderDetailTab) => {
      const submitMapper = {
        [ShipperOrderDetailTab.HEADER]: soDetailHeader.soHeaderForm.resetForm,
        [ShipperOrderDetailTab.DELIVERY_COST]:
          soDetailCost.soCostForm.resetForm,
        [ShipperOrderDetailTab.ACTIVITY]:
          soDetailActivity.soActivityForm.resetForm,
        [ShipperOrderDetailTab.JO_INFO]: () => {},
        [ShipperOrderDetailTab.TRACKING_ORDER]: () => {},
      };

      if (val) {
        submitMapper[val]();
      } else {
        soDetailHeader.soHeaderForm.resetForm();
        soDetailCost.soCostForm.resetForm();
        soDetailActivity.soActivityForm.resetForm();
      }
      if (nextTab) {
        soDetailController.handleConfirmChangeSection(nextTab);
      }
    },
    [
      soDetailActivity.soActivityForm,
      soDetailController,
      soDetailCost.soCostForm,
      soDetailHeader.soHeaderForm,
    ],
  );

  const confirmationObj = useMemo(
    () => ({
      title: translator.translate('Are you sure you want to leave?'),
      actionLabel: translator.translate(
        isFormValid(soDetailController.isCurrentSectionChanged)
          ? 'Save Changes'
          : 'Discard Changes',
      ),
      cancelLabel: translator.translate(
        isFormValid(soDetailController.isCurrentSectionChanged)
          ? 'Discard Changes'
          : 'Stay on Page',
      ),
      description: translator.translate(
        'It looks like you are in the middle of writing something and you have not saved the changes.  Unsaved changes will be lost',
      ),
      isSecondaryValid: isFormValid(soDetailController.isCurrentSectionChanged),
      handleConfirmAction: () => {
        if (
          !soDetailController.showConfirmationModal ||
          !soDetailController.isCurrentSectionChanged
        )
          return;
        if (isFormValid(soDetailController.isCurrentSectionChanged)) {
          handleSubmitConfirmation(soDetailController.isCurrentSectionChanged);
          return;
        }
        handleDiscardChanges(
          soDetailController.isCurrentSectionChanged,
          soDetailController.showConfirmationModal,
        );
      },
      handleCancelAction: () => {
        if (
          !soDetailController.showConfirmationModal ||
          !soDetailController.isCurrentSectionChanged
        )
          return;
        if (isFormValid(soDetailController.isCurrentSectionChanged)) {
          handleDiscardChanges(
            soDetailController.isCurrentSectionChanged,
            soDetailController.showConfirmationModal,
          );
          return;
        }
        soDetailController.handleDismissChangeSection();
      },
    }),
    [
      handleDiscardChanges,
      handleSubmitConfirmation,
      isFormValid,
      soDetailController,
      translator,
    ],
  );

  const handleTrackingImageClick = useCallback<OnTimelineContentImageClick>(
    (photos: Photo[], startIndex: number, customTitles?: string[]) => {
      imageGalleryController.handleGalleryThumbnailsClick({
        images: photos.map(({ accessUrl, fileName, createdAt }, idx) => ({
          source: accessUrl,
          caption: generateCommonGalleryCaption(fileName, createdAt),
          title: customTitles ? customTitles[idx] : undefined,
        })),
        startIndex,
      });
    },
    [imageGalleryController],
  );

  const handleJOImageClick = useCallback(
    (photos: Photo[], startIndex: number, activityIndex: number) =>
      imageGalleryController.handleGalleryThumbnailsClick({
        images: photos.map(({ accessUrl, createdAt, fileName }) => ({
          source: accessUrl,
          caption: generateCommonGalleryCaption(fileName, createdAt),
          title: formatText(
            translator.translate('Activity %s • Proof of Activity'),
            activityIndex.toString(),
          ),
        })),
        startIndex,
      }),
    [imageGalleryController, translator],
  );

  return {
    isFormValid,
    confirmationObj,
    handleTrackingImageClick,
    handleJOImageClick,
    handleSubmitConfirmation,
    handleDiscardChanges,
  };
}
