import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store/store.app';
import { SettingNavigationItemType } from '../constant/Setting.constant';
import { settingAction } from '../store/setting.store';

/**
 * Common toggle state function.
 * @param initialState initial toggle value
 */
export default function useToggleSettingModal() {
  const dispatch = useDispatch();
  const isModalSettingShown = useSelector(
    (state: RootState) => !!state.setting.showModal,
  );

  const toggleModal = (state?: SettingNavigationItemType) => {
    dispatch(settingAction.toggleModal(state));
  };

  return { isModalSettingShown, toggleModal };
}
