import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jobOrderRoute } from '../../view/JobOrder/JobOrder.route';
import useTranslator from '../useTranslator.hook';

export default function useJODetailErrorPlaceholder() {
  // #region GENERAL
  const translator = useTranslator();
  const navigate = useNavigate();

  const title = translator.translate('Page Not Found');
  const body = translator.translate(
    "Either the URL you requested was not found on this server or you don't have permission to access this page.",
  );
  const actionLabel = translator.translate('Back to Job Order List');
  // #endregion

  const handleAction = useCallback(() => {
    navigate(jobOrderRoute.path);
  }, [navigate]);

  return {
    title,
    body,
    actionLabel,
    handleAction,
  };
}

export type UseJODetailErrorPlaceholderHookObj = ReturnType<
  typeof useJODetailErrorPlaceholder
>;
