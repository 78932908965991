import React from 'react';

export default function Sort({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#a)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill || 'currentColor'}
      >
        <path d="M9 0v19a1 1 0 0 1-1.736.677l-7-7.6 1.472-1.354L7 16.438V0h2ZM11 20V1a1 1 0 0 1 1.736-.677l7 7.6-1.471 1.354L13 3.562V20h-2Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
