import { Locale } from './locale.config';

const templateListLocaleConfig: Locale[] = [
  {
    en: 'Activity Template Name',
    id: 'Nama Templat Pengiriman',
  },
  {
    en: 'Total Activity',
    id: 'Jumlah Aktivitas',
  },
  {
    en: 'First Location',
    id: 'Lokasi Awal',
  },
  {
    en: 'Last Location',
    id: 'Lokasi Akhir',
  },
  {
    en: 'Name or Location',
    id: 'Nama atau Lokasi',
  },
  {
    en: 'Activity Template Type',
    id: 'Tipe Templat Pengiriman',
  },
  {
    en: 'Location Details',
    id: 'Detil lokasi',
  },
  {
    en: 'Description of the Activity Template',
    id: 'Deskripsi Templat Pengiriman',
  },
  {
    en: "You don't have any selected Activity Templates that you want to remove",
    id: 'Anda tidak memiliki daftar Templat Pengiriman yang ingin dihapus',
  },
  {
    en: 'Try to select some Activity Templates from Activity Template page',
    id: 'Cobalah untuk memilih beberapa Templat Pengiriman di halaman Templat Pengiriman',
  },
  {
    en: 'Go back to Activity Template page',
    id: 'Kembali ke halaman Templat Pengiriman',
  },
  {
    en: 'You can search by Activity Template Name, Location Name, or Shipper',
    id: 'Cari menggunakan Nama Template Pengiriman, Nama Lokasi, atau Pengirim',
  },
];

export default templateListLocaleConfig;
