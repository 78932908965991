import { Locale } from './locale.config';

const shipperLocaleConfig: Locale[] = [
  {
    en: 'Shipper name are mandatory',
    id: 'Nama pengirim wajib diisi',
  },
  {
    en: 'Shipper Name',
    id: 'Nama Pengirim',
  },
  {
    en: 'Import Shipper',
    id: 'Unggah Pengirim',
  },

  {
    en: 'Email must be valid',
    id: 'Email harus valid',
  },
  {
    en: 'Invalid Email Address',
    id: 'Alamat Email Salah',
  },
  {
    en: 'Shipper registered successfully.',
    id: 'Pengirim berhasil didaftarkan.',
  },
  {
    en: 'Shipper successfully created.',
    id: 'Pengirim berhasil didaftarkan.',
  },
  {
    en: `Phone number must start with apostrophe mark. For example : “'+6281234567890“`,
    id: `Nomor Telepon harus dimulai dengan tanda petik satu. Contoh : “'+6281234567890“`,
  },
  {
    en: 'Upload a XLS file to import shipper data',
    id: 'Unggah file XLS untuk mendaftarkan data pengirim',
  },
  {
    en: 'Try to import some Shipper by clicking the Re-upload button, or you can click the button below',
    id: 'Unggah ulang Pengirim dari halaman Pengirim, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: "You don't have any imported Shipper that you want to register",
    id: 'Anda tidak memiliki Pengirim yang diunggah untuk didaftarkan',
  },
  {
    en: 'Make sure the list below is the shipper list that you want to register to your organization. Incompatible data formats will not be registered into the system.',
    id: 'Pastikan daftar di bawah ini adalah daftar Pengirim yang ingin Anda daftarkan ke organisasi Anda. Data dengan format yang tidak sesuai tidak dapat didaftarkan ke dalam sistem.',
  },
];

export default shipperLocaleConfig;
