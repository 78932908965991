import React from 'react';
import tw, { theme } from 'twin.macro';
import { Button, Chip, Divider, Icon, Text } from '../../../component/atom';
import { UseJODetailSpendingReportFilter } from '../hooks/useJODetailSpendingReportFilter.hook';

// #region TYPES
type Props = {
  spendingReportFilter: UseJODetailSpendingReportFilter;
};
// #endregion

// #region STYLED
const Container = tw.div`relative w-full h-full max-h-screen bg-white flex flex-col`;
const Header = tw.header`flex-none p-4 flex items-center`;
const Title = tw(Text.HeadingFour)`flex-1 ml-2`;
const ResetButton = tw(Button.Outlined)`bg-white text-grey-two px-4`;
const Footer = tw.div`w-full sticky bottom-0 flex-none p-4 flex flex-col border-t border-t-grey-five bg-white`;
const Filters = tw.div`overflow-auto py-5 px-4 flex flex-col space-y-6 h-full`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)`mb-2`;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
const ChipSmall = tw(Chip.Small)`text-grey-two`;
// #endregion

export default function JODetailSpendingReportFilter({
  spendingReportFilter,
}: Props) {
  return (
    <Container>
      <Header data-testid={`${spendingReportFilter.testID}Filter:Header`}>
        <Icon.FilterV2 />

        <Title data-testid={`${spendingReportFilter.testID}Filter:Title`}>
          {spendingReportFilter.titleLabel}
        </Title>

        <ResetButton
          small
          data-testid={`${spendingReportFilter.testID}Filter:ResetAction`}
          onClick={spendingReportFilter.onClickResetFilter}
        >
          {spendingReportFilter.resetButtonLabel}
        </ResetButton>
      </Header>

      <Divider />

      <Filters data-testid={`${spendingReportFilter.testID}Filter:Contents`}>
        <FilterItemContainer>
          <InputHeading
            data-testid={`${spendingReportFilter.testID}Filter:OrderByLabel`}
          >
            {spendingReportFilter.orderByLabel}
          </InputHeading>

          <ChipContainer>
            {spendingReportFilter.orderByValues.map((el, i) => {
              const selected =
                spendingReportFilter.form.values.orderBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <ChipSmall
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      spendingReportFilter.onClickOrderByChipFilter(el.value)
                    }
                    testID={`${spendingReportFilter.testID}Filter:OrderByOption:${i}`}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${spendingReportFilter.testID}Filter:CategoryLabel`}
            >
              {spendingReportFilter.categoryLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                spendingReportFilter.isQueryCategoryAll
                  ? spendingReportFilter.onClickUnselectAllCategory
                  : spendingReportFilter.onClickSelectAllCategory
              }
            >
              {spendingReportFilter.isQueryCategoryAll
                ? spendingReportFilter.unselectAllLabel
                : spendingReportFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {spendingReportFilter.categoryValues.map((el, i) => {
              const selected =
                spendingReportFilter.form.values.category.includes(el.value);

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <ChipSmall
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      spendingReportFilter.onClickCategoryChipFilter(el.value)
                    }
                    testID={`${spendingReportFilter.testID}Filter:CategoryOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {spendingReportFilter.isQueryCategoryEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {spendingReportFilter.errorCategoryLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${spendingReportFilter.testID}Filter:StatusLabel`}
            >
              {spendingReportFilter.statusLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                spendingReportFilter.isQueryStatusAll
                  ? spendingReportFilter.onClickUnselectAllStatus
                  : spendingReportFilter.onClickSelectAllStatus
              }
            >
              {spendingReportFilter.isQueryStatusAll
                ? spendingReportFilter.unselectAllLabel
                : spendingReportFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {spendingReportFilter.statusValues.map((el, i) => {
              const selected = spendingReportFilter.form.values.status.includes(
                el.value,
              );

              return (
                <FilterChip key={`Filter_Status_${el.value}`}>
                  <ChipSmall
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      spendingReportFilter.onClickStatusChipFilter(el.value)
                    }
                    testID={`${spendingReportFilter.testID}Filter:StatusOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {spendingReportFilter.isQueryStatusEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {spendingReportFilter.errorStatusLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer data-testid={`${spendingReportFilter.testID}Filter:Footer`}>
        <Button.Solid
          type="submit"
          disabled={
            spendingReportFilter.isQueryCategoryEmpty ||
            spendingReportFilter.isQueryStatusEmpty ||
            spendingReportFilter.filterLocalIsFetching
          }
          onClick={spendingReportFilter.onClickSubmitFilter}
          data-testid={`${spendingReportFilter.testID}Filter:SubmitButton`}
        >
          {spendingReportFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}
