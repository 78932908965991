import React from 'react';

export default function Copy({
  fill,
  width = 20,
  height = 20,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V7Zm3-1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H3Z"
        fill={fill || '#F3532E'}
      />
      <path
        d="M8 0a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H8Z"
        fill={fill || '#F3532E'}
      />
    </svg>
  );
}
