import { getUnixTime } from 'date-fns';
import { useEffect } from 'react';
import { intervalDelay } from '../../constant/JobOrderDetail.constant';
import { JobOrderInfo, JOStatus } from '../../model/JobOrder.model';
import api from '../../service/api.service';
import useInterval from '../useInterval.hook';

type Props = {
  joInfo?: JobOrderInfo;
};
function useWatchDriverPosition({ joInfo }: Props) {
  const showDriverLocation =
    joInfo?.driver?.id &&
    [JOStatus.ASSIGNED, JOStatus.DELIVERING].includes(joInfo.status);
  const [
    fetchDriverPositionData,
    { driverLastPosition, driverLastLatLng, isDriverOnline },
  ] = api.useLazyGetDriverLastPositionQuery({
    selectFromResult: ({ data }) => ({
      driverLastPosition: data?.gpsEvent,
      driverLastLatLng:
        data?.gpsEvent?.lat && data?.gpsEvent?.lon
          ? {
              lat: data.gpsEvent.lat,
              lng: data.gpsEvent.lon,
            }
          : undefined,
      isDriverOnline: data?.gpsEvent
        ? data.gpsEvent.eventTimestamp + 30 * 60 > getUnixTime(new Date())
        : false,
    }),
  });

  useEffect(() => {
    if (showDriverLocation && joInfo?.driver?.id) {
      void fetchDriverPositionData({ id: joInfo.driver.id });
    }
  }, [fetchDriverPositionData, joInfo, showDriverLocation]);

  useInterval(
    () => {
      if (showDriverLocation && joInfo?.driver?.id) {
        void fetchDriverPositionData({ id: joInfo.driver.id });
      }
    },
    !window.navigator.onLine ? null : intervalDelay,
  );

  return { driverLastPosition, driverLastLatLng, isDriverOnline };
}

export default useWatchDriverPosition;
