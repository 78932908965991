import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import startOfDay from 'date-fns/startOfDay';
import { NativeCalendarHighlight } from '../../component/molecule/NativeCalendar/NativeCalendar.molecule';
import { ScheduleOrderBy } from '../../model/Schedule.model';
import api from '../../service/api.service';

type Props = {
  driverId?: string;
  orderBy?: ScheduleOrderBy;
  startDate?: number;
  endDate?: number;
};

export default function useDriverScheduleList({
  driverId = '',
  orderBy = ScheduleOrderBy.FROM_DATE_ASC,
  startDate,
  endDate,
}: Props) {
  const query = api.useGetScheduleListQuery(
    {
      pageSize: 1_000,
      driverId,
      orderBy,
      from: startDate,
      to: endDate,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !driverId,
      selectFromResult: ({ data, isFetching, fulfilledTimeStamp }) => ({
        fulfilledTimeStamp,
        listData: data?.driverVehicles ?? [],
        listOngoingData: (data?.driverVehicles ?? []).filter(
          (item) => startDate && item.to >= startDate,
        ),
        listMetadata: data?.metadata,
        listIsFetching: isFetching,
        highlights: (data?.driverVehicles ?? []).map(
          ({ from, to, id }) =>
            ({
              id,
              start: startOfDay(from * 1000),
              end: startOfDay(to * 1000),
            }) as NativeCalendarHighlight,
        ),
        ongoingHighlights: (data?.driverVehicles ?? [])
          .filter((item) => startDate && item.to >= startDate)
          .map(
            ({ from, to, id }) =>
              ({
                id,
                start: startOfDay(from * 1000),
                end: startOfDay(to * 1000),
              }) as NativeCalendarHighlight,
          ),
        disabledDays: (data?.driverVehicles ?? []).flatMap(({ from, to }) =>
          eachDayOfInterval({
            start: startOfDay(from * 1000),
            end: startOfDay(to * 1000),
          }),
        ),
      }),
    },
  );

  return { query };
}

export type UseDriverScheduleList = ReturnType<typeof useDriverScheduleList>;
