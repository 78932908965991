import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import { Text } from '../../component/atom';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../component/molecule';
import { BulkActionEntity, BulkActionStatusEnum } from '../../constant';
import useTranslator, { UseTranslator } from '../../hook/useTranslator.hook';
import { Shipper } from '../../model/Shipper.model';
import { ApiErrorResponse } from '../../service/api.endpoint';
import api from '../../service/api.service';
import { errorCodeToLabel } from '../../util/error.util';
import {
  mapBulkActionStatusToLabel,
  promiseToVoid,
} from '../../util/helper.util';

// #region INTERFACES
export type DeactivateShipperEntity = BulkActionEntity<Shipper>;
// #endregion

function useShipperDeactivateTable(
  translator: UseTranslator,
  shippers: DeactivateShipperEntity[],
  showStatus: boolean,
) {
  const columns = useMemo<ColumnDef<DeactivateShipperEntity>[]>(
    () => [
      {
        id: 'name',
        size: 240,
        header: translator.translate('Shipper Name'),
        accessorKey: 'name',
        cell: ({ getValue }) => (
          <CustomTableRowWithPopover
            primaryLabel={getValue() as string}
            containerStyle={tw`max-width[240px]`}
          />
        ),
      },
      {
        id: 'phoneNumber',
        size: 240,
        header: translator.translate('Phone Number'),
        accessorKey: 'phoneNumber',
        cell: ({ getValue }) => (
          <Text.Paragraph tw="truncate">{getValue() || '-'}</Text.Paragraph>
        ),
      },
      ...(showStatus
        ? [
            {
              id: '_actionStatus',
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              id: '_actionDetail',
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({ getValue }: { getValue: () => string }) => (
                <Text.Paragraph tw="truncate animate-slide-in-left">
                  {translator.translate(getValue())}
                </Text.Paragraph>
              ),
            },
          ]
        : []),
    ],
    [showStatus, translator],
  );

  const table = useReactTable({
    data: shippers,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}

export default function useShipperDeactivateViewModel() {
  // #region VALUES
  const translator = useTranslator();
  const navigate = useNavigate();
  const location = useLocation();

  const [showStatus, setShowStatus] = useState(false);

  // get all shippers in shippers reducer + deactivate status detail -> pending, success, or failed
  const shippersStore = useMemo(
    () => (location?.state || []) as Shipper[],
    [location?.state],
  );

  const [shippersWithStatusDetail, setShippersWithStatusDetail] = useState<
    DeactivateShipperEntity[]
  >(
    shippersStore.map((v) => ({
      ...v,
      _actionStatus: BulkActionStatusEnum.WAITING,
      _actionDetail: 'Waiting for deactivation',
    })),
  );
  const [showDoneBtn, setShowDoneBtn] = useState<boolean>(false);
  const [mutationsIsLoading, setMutationsIsLoading] = useState<boolean>(false); // to disable submit button when mutations is still ongoing
  const [doDeactivateShipper] = api.useDeactivateShipperMutation();
  // #endregion

  // #region HANDLERS
  const onDeactivate = useCallback(() => {
    setShowStatus(true);
    const onDeactivatePromise = async () => {
      // track loading state
      setMutationsIsLoading(true);

      // synchronous call deactivate shipper mutation
      for (let i = 0; i < shippersWithStatusDetail.length; i += 1) {
        const { id } = shippersWithStatusDetail[i];

        try {
          // NOTE: currently our backend can not receive excessive amount of requests in parallel, that is why we used for loop instead of `Promise.allSettled`
          await doDeactivateShipper({ id }).unwrap();

          setShippersWithStatusDetail((_shippers) =>
            _shippers.map((_shipper) =>
              _shipper.id === id
                ? {
                    ..._shipper,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Set to Inactive',
                  }
                : _shipper,
            ),
          );
        } catch (err) {
          const errorQuery = (err as FetchBaseQueryError)
            ?.data as ApiErrorResponse;

          setShippersWithStatusDetail((_shippers) =>
            _shippers.map((_shipper) =>
              _shipper.id === id
                ? {
                    ..._shipper,
                    _actionStatus: BulkActionStatusEnum.FAILED,
                    _actionDetail: errorCodeToLabel(errorQuery?.error?.code),
                  }
                : _shipper,
            ),
          );
        }
      }

      // on done
      setShowDoneBtn(true);
      setMutationsIsLoading(false);
    };

    promiseToVoid([onDeactivatePromise()]);
  }, [doDeactivateShipper, shippersWithStatusDetail]);

  const onCancel = useCallback(() => {
    navigate('/shipper');
  }, [navigate]);

  const onDone = useCallback(() => {
    navigate('/shipper');
  }, [navigate]);
  // #endregion

  const { table } = useShipperDeactivateTable(
    translator,
    shippersWithStatusDetail,
    showStatus,
  );

  return {
    translator,
    table,
    shippersWithStatusDetail,
    showDoneBtn,
    mutationsIsLoading,
    onDeactivate,
    onCancel,
    onDone,
  };
}
