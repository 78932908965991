import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { Text } from '../../../component/atom';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../../component/molecule';
import { BulkActionStatusEnum } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { mapBulkActionStatusToLabel } from '../../../util/helper.util';
import {
  DeactivateDriverEntity,
  UseDriverDeactivateLogic,
} from './useDriverDeactivateLogic.hook';

// #region TYPES
type Props = {
  logic: UseDriverDeactivateLogic;
};
// #endregion

export default function useDriverDeactivateTable({
  logic: { showStatus, driversWithStatusDetail },
}: Props) {
  const translator = useTranslator();

  const columns = useMemo<ColumnDef<DeactivateDriverEntity>[]>(
    () => [
      {
        size: 200,
        header: translator.translate('Name'),
        accessorKey: 'fullName',
        cell: ({ getValue }) => (
          <CustomTableRowWithPopover
            primaryLabel={getValue() as string}
            containerStyle={tw`max-w-[240px]`}
          />
        ),
      },
      {
        size: 150,
        header: translator.translate('Phone Number'),
        accessorKey: 'phoneNumber',
        cell: ({ getValue }) => <Text.Paragraph>{getValue()}</Text.Paragraph>,
      },
      ...(showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({ getValue }: { getValue: () => string }) => (
                <Text.Paragraph tw="truncate animate-slide-in-left">
                  {translator.translate(getValue())}
                </Text.Paragraph>
              ),
            },
          ]
        : []),
    ],
    [showStatus, translator],
  );

  const table = useReactTable({
    columns,
    data: driversWithStatusDetail,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
