import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { Divider, Icon, ListItem } from '..';
import { SelectOption } from '../../Type.component';

export type Props = React.ComponentProps<'div'> &
  React.ComponentProps<'ul'> & {
    options: (SelectOption | '-')[];
    withIcon?: boolean;
    cascading?: boolean;
    testID?: string;
    onClickItem?: (option: SelectOption) => void;
  };

const Container = tw.ul`bg-white rounded-sm shadow flex p-0 m-0`;
const Column = tw.div`flex-1`;
const CascadeColumn = styled.div(({ show }: { show?: boolean }) => [
  tw`py-5 width[51%] border-solid border-0 border-l border-l-grey-five hidden`,
  show && tw`block`,
]);
const SmallContainer = tw.ul`bg-white rounded shadow-card flex p-0 m-0`;

export function Regular({
  options,
  withIcon,
  cascading,
  onClickItem,
  testID,
  ...props
}: Props) {
  const [hover, setHover] = useState<string>();

  return (
    <Container {...props}>
      <Column>
        {options.map((option, i) => {
          if (option !== '-') {
            return (
              <ListItem.Regular
                key={option.label}
                data-testid={`${testID || ''}RegularList:Option:${i}`}
                left={withIcon ? option.icon : undefined}
                right={
                  cascading && option.children ? (
                    <Icon.ChevronRounded height={18} width={18} />
                  ) : undefined
                }
                onMouseEnter={
                  cascading ? () => setHover(option.value) : undefined
                }
                onClick={
                  !cascading || !option.children
                    ? () => onClickItem?.(option)
                    : undefined
                }
                {...option}
              />
            );
          }
          return <Divider key={`List-Divider=${i}`} tw="my-5" />;
        })}
      </Column>
      {cascading && <CascadeColumn show={!hover} />}
      {cascading &&
        (options.filter((option) => option !== '-') as SelectOption[]).map(
          ({ value, children }, i) => (
            <CascadeColumn key={`children-${value}`} show={hover === value}>
              {children?.map((childOption: SelectOption) => (
                <ListItem.Regular
                  data-testid={`${testID || ''}RegularList:CascadeOption:${i}`}
                  key={childOption.label}
                  left={withIcon ? childOption.icon : undefined}
                  selected={value === childOption.value}
                  {...childOption}
                  onClick={() => {
                    onClickItem?.(childOption);
                  }}
                />
              ))}
            </CascadeColumn>
          ),
        )}
    </Container>
  );
}

export function Small({ options, onClickItem, testID, ...props }: Props) {
  return (
    <SmallContainer {...props}>
      <Column>
        {options.map((option, i) => {
          if (option !== '-') {
            return (
              <ListItem.Small
                data-testid={`${testID || ''}SmallList:Option:${i}`}
                key={option.label}
                onClick={() => {
                  onClickItem?.(option);
                }}
                {...option}
              />
            );
          }
          return <Divider key={`List-Divider=${i}`} />;
        })}
      </Column>
    </SmallContainer>
  );
}
