import { Locale } from './locale.config';

const joListLocaleConfig: Locale[] = [
  {
    en: 'JO Number',
    id: 'Nomor JO',
  },
  {
    en: 'JO Date',
    id: 'Tanggal JO',
  },
  {
    en: 'Driver Name',
    id: 'Nama Sopir',
  },
  {
    en: 'HJO Driver Name',
    id: 'Nama Sopir',
  },
  {
    en: 'Travel Expense',
    id: 'Uang Jalan',
  },
  {
    en: 'Vehicle',
    id: 'Kendaraan',
  },
  {
    en: 'JO Status',
    id: 'Status JO',
  },
  {
    en: 'Search JO Number',
    id: 'Cari Nomor JO',
  },
  {
    en: 'Create Job Order',
    id: 'Buat Perintah Kerja',
  },
  {
    en: 'No Pickup Activity',
    id: 'Tidak ada aktivitas pickup',
  },
  {
    en: 'No Drop-Off Activity',
    id: 'Tidak ada aktivitas Drop Off',
  },
  {
    en: 'Drop Off Locations',
    id: 'Lokasi Drop Off',
  },
  {
    en: 'Pickup Activity',
    id: 'Aktivitas Pickup',
  },
  {
    en: 'Drop-Off Activity',
    id: 'Aktivitas Drop Off',
  },
  {
    en: 'Job Order List',
    id: 'Daftar Perintah Kerja',
  },
  {
    en: 'You can search by JO Number, Driver, or Vehicle',
    id: 'Cari menggunakan Nomor JO, Nama Sopir, atau Kendaraan',
  },
  {
    en: 'Try adding Job Order in the Desktop version',
    id: 'Tambahkan Perintah Kerja melalui versi Desktop',
  },
  {
    en: 'Select Activities',
    id: 'Pilih Aktivitas',
  },
  {
    en: 'Delete Job Order',
    id: 'Hapus Perintah Kerja',
  },
  {
    en: 'Confirm Force Complete',
    id: 'Konfirmasi Selesai Paksa',
  },
  {
    en: 'Create JO',
    id: 'Buat Perintah Kerja',
  },
  {
    en: 'Update JO',
    id: 'Ubah Perintah Kerja',
  },
];

export default joListLocaleConfig;
