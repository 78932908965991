import React from 'react';
import tw from 'twin.macro';
import JOForm from '../../component/organism/JOForm/JOForm.organism';
import useJobOrderEditDraftViewModel from './JobOrderEditDraft.viewModel';

const Container = tw.div`-my-10`;
export default function JobOrderEditDraftView() {
  const { controller, state } = useJobOrderEditDraftViewModel();

  return (
    <Container>
      <JOForm controller={controller} state={state} />
    </Container>
  );
}
