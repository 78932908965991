import React from 'react';
import tw, { theme } from 'twin.macro';
import { dateJOSOCandidateFilterRangeDefinedRangeOptions } from '../../../constant/JobOrder.constant';
import { UseJOFormSOCandidateFilterHookObj } from '../../../hook/jobOrderForm/useJOFormSOCandidateFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region INTERFACES
type Props = { filter: UseJOFormSOCandidateFilterHookObj };
// #endregion

// #region STYLED
const AutoCompleteComponent = tw(AutoComplete)`bg-orange-dark-lines`;
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
const DatePickerContainer = tw.div`w-full flex items-center justify-between space-x-2`;
// #endregion

function JOFormSOCandidateFilter({ filter }: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <Text.HeadingFour
          tw="flex-1 ml-2"
          data-testid={`${filter.testID || ''}Filter:Title`}
        >
          {filter.titleLabel}
        </Text.HeadingFour>

        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          data-testid={`${filter.testID || ''}Filter:ResetAction`}
          onClick={filter.onClickResetFilter}
        >
          {filter.resetButtonLabel}
        </Button.Outlined>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading
            data-testid={`${filter.testID || ''}Filter:SortByLabel`}
          >
            {filter.sortByLabel}
          </InputHeading>

          <ChipContainer>
            {filter.sortByValues.map((el, i) => {
              const selected = filter.filterForm.values.sortBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() => filter.onClickSortByChipFilter(el.value)}
                    testID={`${filter.testID || ''}Filter:SortByOption:${i}`}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${filter.testID || ''}Filter:StatusLabel`}
            >
              {filter.statusLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                filter.isQueryStatusAll
                  ? filter.onClickUnselectAllStatus
                  : filter.onClickSelectAllStatus
              }
            >
              {filter.isQueryStatusAll
                ? filter.unselectAllLabel
                : filter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {filter.statusValues.map((el, i) => {
              const selected = filter.filterForm.values.status.includes(
                el.value,
              );

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() => filter.onClickStatusChipFilter(el.value)}
                    testID={`${filter.testID || ''}Filter:StatusOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {filter.isQueryStatusEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {filter.errorStatusLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading>{filter.shipperLabel}</InputHeading>

          <AutoCompleteComponent
            value={filter.filterForm.values.shipperId}
            datas={filter.shipperOptions}
            selectedOption={filter.selectedOptionShipper}
            placeholder={filter.shipperPlaceholderLabel}
            loading={filter.shipperListFetchLoading}
            changeData={filter.onChangeShipperFilter}
            changeInputText={filter.onChangeShipperAutotext}
            onFetchMore={filter.onFetchMoreShipper}
            onRemoveData={filter.onRemoveShipperFilter}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading>{filter.locationLabel}</InputHeading>

          <AutoCompleteComponent
            value={filter.filterForm.values.locationId}
            datas={filter.locationOptions}
            selectedOption={filter.selectedOptionLocation}
            placeholder={filter.locationPlaceholderLabel}
            loading={filter.locationListFetchLoading}
            changeData={filter.onChangeLocationFilter}
            changeInputText={filter.onChangeLocationAutotext}
            onFetchMore={filter.onFetchMoreLocation}
            onRemoveData={filter.onRemoveLocationFilter}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${filter.testID || ''}Filter:DateRangeLabel`}
          >
            {filter.dateRangeLabel}
          </InputHeading>

          <DatePickerContainer>
            <DateRangePickerInput
              sdLabel={filter.sdLabel}
              edLabel={filter.edLabel}
              sdDateValue={filter.filterForm.values.startDate}
              sdOnChangeDateValue={filter.onChangeStartDateFilter}
              edDateValue={filter.filterForm.values.endDate}
              edOnChangeDateValue={filter.onChangeEndDateFilter}
              onClickApplyDateRange={filter.onClickApplyDateRange}
              outsideAlerterStyle={{ width: '100%' }}
              allowUndefinedDateRange
              definedRangeOptions={
                dateJOSOCandidateFilterRangeDefinedRangeOptions
              }
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={filter.isQueryStatusEmpty || filter.filterLocalIsFetching}
          onClick={filter.onClickSubmitFilter}
          data-testid={`${filter.testID || ''}Filter:SubmitButton`}
        >
          {filter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default JOFormSOCandidateFilter;
