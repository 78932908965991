import { Locale } from './locale.config';

const shipperOrderLocaleConfig: Locale[] = [
  {
    en: 'Make sure the list below is the shipper order list that you want to delete from your organization. Deleted Shipper Order still can be accessed in Shipper Order List page.',
    id: 'Pastikan daftar di bawah ini adalah daftar shipper order yang akan Anda hapus dari organisasi Anda. Shipper Order yang telah Dihapus masih dapat diakses pada halaman Daftar Shipper Order.',
  },
  {
    en: 'Shipper Order deleted successfully',
    id: 'Shipper Order berhasil dihapus',
  },
  {
    en: 'Waiting for deletion',
    id: 'Menunggu proses menghapus',
  },
  {
    en: 'Delete Shipper Order',
    id: 'Hapus Shipper Order',
  },
  {
    en: 'Shipper Order already assigned.',
    id: 'Shipper Order sudah ditugaskan.',
  },
  {
    en: 'Shipper Order already reserved.',
    id: 'Shipper Order sudah diatur.',
  },
  {
    en: 'Shipper Order already delivered.',
    id: 'Shipper Order sudah terkirim.',
  },
  {
    en: 'Shipper Order already in transit.',
    id: 'Shipper Order sedang dalam transit.',
  },
  {
    en: 'Shipper Order already transiting.',
    id: 'Shipper Order sedang dalam perjalanan transit.',
  },
  {
    en: 'Shipper Order already started.',
    id: 'Shipper Order sudah dalam perjalanan.',
  },
  {
    en: 'Shipper Order has been deleted',
    id: 'Shipper Order sudah pernah dihapus',
  },
  {
    en: 'Shipper Order need to be removed from related Job Order',
    id: 'Shipper Order harus dihapus dari Perintah Kerja yang terhubung',
  },
  {
    en: 'Shipper Order is on delivery',
    id: 'Shipper Order sudah dalam pengiriman',
  },
];

export default shipperOrderLocaleConfig;
