import envConfig from '../config/env/env.config';

export enum CustomMapTypes {
  DEFAULT = 'DEFAULT',
  TRAFFIC = 'TRAFFIC',
  SATELLITE = 'SATELLITE',
}

export const language = 'id';

export const googleMapsApiKey = envConfig.apiGoogleMap;

export const defaultZoomLevel = 5;

export const defaultMapsCenter = { lat: -0.4, lng: 114 };

export const mapsAutocompleteOption = {
  componentRestrictions: { country: 'id' },
};

export const mapsDefaultContainerStyle = {
  height: '100%',
};

export const mapDefaultRadius = 500;

export const mapDefaultOption = {
  keyboardShortcuts: false,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
};

export const radiusDefaultOption = {
  strokeColor: '#F3532E',
  strokeOpacity: 0,
  strokeWeight: 0,
  fillColor: '#F3532E',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

export const mapFetchGeocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng={latitude},{longitude}&sensor=true&key=${googleMapsApiKey}`;
