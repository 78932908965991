import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import { FormikErrors, useFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import VehicleSummaryInfo from '../../component/molecule/VehicleSummaryInfo/VehicleSummaryInfo.molecule';
import {
  JOAssignmentBy,
  JOVehicleAssignmentFormType,
} from '../../constant/JOAssignment.constant';
import { JobOrderForm } from '../../model/JobOrder.model';
import { Schedule } from '../../model/Schedule.model';
import {
  GetVehicleOrderBy,
  Vehicle,
  VehicleOrderBy,
} from '../../model/Vehicle.model';
import { FormItem } from '../../types/input.type';
import { isFormChanged } from '../../util/helper.util';
import {
  joAssignmentToJOFormFormatter,
  JOVehicleAssignmentFormValidationSchema,
  joVehicleAssignmentInitialValues,
} from '../../util/joAssignment.util';
import useGetVehiclesAutocomplete from '../useGetVehiclesAutocomplete.hook';
import useTranslator from '../useTranslator.hook';
import useVehicleScheduleCalendar from '../vehicle/useVehicleScheduleCalendar.hook';

type Props = {
  joFormValues: JobOrderForm;
  vehicleAutocompleteParams?: GetVehicleOrderBy;
  setJOFormValues: (
    values: React.SetStateAction<JobOrderForm>,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<JobOrderForm>>;
};
export type UseJOVehicleAssignmentFormHookObj = ReturnType<
  typeof useJOVehicleAssignmentForm
>;

export default function useJOVehicleAssignmentForm({
  joFormValues,
  vehicleAutocompleteParams = {
    orderBy: VehicleOrderBy.RECOMMENDED,
    orderVolume: 0,
    orderWeight: 0,
  },
  setJOFormValues,
}: Props) {
  const translator = useTranslator();
  const initialMonth = useMemo(() => {
    if (!joFormValues.joDate) return startOfToday();
    return startOfDay((joFormValues.joDate || 0) * 1000);
  }, [joFormValues.joDate]);

  const joInitialValues = useMemo((): JOVehicleAssignmentFormType => {
    if (!joFormValues.schedule) return joVehicleAssignmentInitialValues;
    return {
      schedule: joFormValues.schedule,
      scheduleId: joFormValues?.schedule?.id,
      vehicleId: joFormValues.vehicleId,
      vehicleOption: joFormValues.vehicleOption,
    };
  }, [joFormValues]);

  const fullfilledTimestampRef = useRef<number | undefined>(undefined);
  const refId = useRef<string | undefined>(undefined);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const title = translator.translate('Schedules');
  const emptyTitle = translator.translate('There is no schedule.');
  const emptySubtitle = translator.translate(
    'Please select Vehicle to show schedule list.',
  );

  const handleSubmitVehicleForm = useCallback(
    async (val: JOVehicleAssignmentFormType) => {
      const sanitizedData = joAssignmentToJOFormFormatter({
        joFormValues,
        schedule: val.schedule,
      });
      setHasUnsavedChanges(false);
      await setJOFormValues({
        ...sanitizedData,
        assignmentType: JOAssignmentBy.VEHICLE,
      });
    },
    [joFormValues, setJOFormValues],
  );

  const { values, errors, isValid, resetForm, setValues, handleSubmit } =
    useFormik<JOVehicleAssignmentFormType>({
      initialValues: joVehicleAssignmentInitialValues,
      isInitialValid: false,
      validationSchema: JOVehicleAssignmentFormValidationSchema(translator),
      onSubmit: handleSubmitVehicleForm,
    });

  const vehicleAssignment = useVehicleScheduleCalendar({
    id: values.vehicleId,
    initialMonth,
  });

  // #region FETCH VEHICLE LIST
  const {
    onChangeVehicleAutotext: handleChangeVehicleAutotext,
    onFetchMoreVehicle: handleFetchMoreVehicle,
    vehicleListFetchLoading,
    vehicleRows: vehicleOptions,
  } = useGetVehiclesAutocomplete(vehicleAutocompleteParams);

  const handleChangeVehicleId = useCallback(
    (data?: Vehicle) => {
      void setValues({
        vehicleId: data?.id,
        vehicleOption: data,
        schedule: undefined,
        scheduleId: undefined,
      });
      handleChangeVehicleAutotext(undefined);
    },
    [handleChangeVehicleAutotext, setValues],
  );

  const handleRemoveVehicleId = useCallback(() => {
    void setValues(joVehicleAssignmentInitialValues);
    vehicleAssignment.handleReset();
  }, [setValues, vehicleAssignment]);
  // #endregion

  const handleSelectScheduleItem = useCallback(
    (item: Schedule) => {
      let selectedData: Schedule | undefined = item;
      if (isEqual(selectedData, values.schedule)) {
        selectedData = undefined;
      }
      vehicleAssignment.handleSelectScheduleItem(item.id);
      void setValues({
        ...values,
        schedule: selectedData,
        scheduleId: selectedData ? selectedData.id : undefined,
      });
    },
    [setValues, values, vehicleAssignment],
  );

  const handleClickDay = useCallback(
    (_day: Date) => {
      const schedule = vehicleAssignment.ongoingSchedules.find((_schedule) =>
        isWithinInterval(_day, {
          start: _schedule.from * 1_000,
          end: _schedule.to * 1_000,
        }),
      );

      if (!schedule) return;
      handleSelectScheduleItem(schedule);
    },
    [handleSelectScheduleItem, vehicleAssignment.ongoingSchedules],
  );

  const handleReset = useCallback(() => {
    resetForm();
    refId.current = undefined;
    vehicleAssignment.handleReset();
  }, [vehicleAssignment, resetForm]);

  const formData: FormItem = useMemo(
    () => ({
      id: 'vehicleId',
      values: values.vehicleId,
      placeholder: translator.translate('Select Vehicle'),
      isRequired: true,
      type: 'autocomplete-vehicle',
      selectedOption: values.vehicleOption,
      error: errors?.vehicleId && translator.translate(errors?.vehicleId),
      options: vehicleOptions,
      isChildInRow: true,
      children: <VehicleSummaryInfo vehicle={values.vehicleOption} />,
      onChange: handleChangeVehicleId,
      onChangeAutoText: handleChangeVehicleAutotext,
      onFetchMore: handleFetchMoreVehicle,
      onRemoveData: handleRemoveVehicleId,
    }),
    [
      errors?.vehicleId,
      handleChangeVehicleAutotext,
      handleChangeVehicleId,
      handleFetchMoreVehicle,
      handleRemoveVehicleId,
      translator,
      values.vehicleId,
      values.vehicleOption,
      vehicleOptions,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    let selectedSchedule: Schedule | undefined;

    if (refId.current === values.vehicleId) return;

    if (!vehicleAssignment.queryFulfilledTimeStamp) return;

    if (
      (fullfilledTimestampRef.current || 0) >=
      vehicleAssignment.queryFulfilledTimeStamp
    )
      return;

    if (values.schedule) {
      selectedSchedule = values.schedule;
    }

    if (vehicleAssignment.ongoingSchedules.length && !values.schedule) {
      [selectedSchedule] = vehicleAssignment.ongoingSchedules;
    }
    fullfilledTimestampRef.current = vehicleAssignment.queryFulfilledTimeStamp;
    refId.current = values.vehicleId;
    vehicleAssignment.handleSelectScheduleItem(selectedSchedule?.id || '');
    void setValues({
      ...values,
      schedule: selectedSchedule,
      scheduleId: selectedSchedule?.id || '',
    });
  }, [
    values,
    values.vehicleId,
    vehicleAssignment,
    joFormValues.schedule,
    setValues,
  ]);

  // #region FORM LISTENER
  useEffect(() => {
    setHasUnsavedChanges(
      isFormChanged<JOVehicleAssignmentFormType>(values, joInitialValues),
    );
  }, [joInitialValues, values]);

  // #endregion

  return {
    errors,
    isValid,
    values,
    formData,
    vehicleAssignment,
    vehicleListFetchLoading,
    title,
    emptyTitle,
    emptySubtitle,
    hasUnsavedChanges,
    setValues,
    handleSubmit,
    handleReset,
    handleSelectScheduleItem,
    handleClickDay,
  };
}
