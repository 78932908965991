import React from 'react';
import { Navigation, Zoom } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/zoom';
import { Swiper, SwiperSlide } from 'swiper/react';
import tw, { styled } from 'twin.macro';
import { Photo } from '../../../model/Common.model';
import { ExtraStyle } from '../../Type.component';

// #region STYLED
const Container = styled.div`
  ${tw`relative h-full flex flex-col items-center justify-center bg-black`}

  > .mySwiper {
    --swiper-navigation-color: white;
    --swiper-navigation-size: 2rem;
    --swiper-navigation-sides-offset: 1rem;
  }
`;
// #endregion

// #region INTERFACES
export type GalleryMobileProps = {
  photos: Photo[];
  currentIndex: number;
  containerStyle?: ExtraStyle;
  handleChangePhoto: (idx: number) => void;
};
// #endregion

export default function GalleryMobile({
  photos,
  currentIndex,
  containerStyle,
  handleChangePhoto,
}: GalleryMobileProps) {
  return (
    <Container css={containerStyle} data-testid="GalleryMobile:Container">
      <Swiper
        className="mySwiper"
        zoom
        navigation
        modules={[Zoom, Navigation]}
        onSlideChange={(swiper) => handleChangePhoto(swiper.activeIndex)}
        initialSlide={currentIndex}
        onAfterInit={(swiper) => swiper.slideTo(currentIndex)}
      >
        {photos.map((item, idx) => (
          <SwiperSlide key={`${item.id}-${item.fileName || ''}-${idx}`}>
            <div className="swiper-zoom-container">
              <img loading="lazy" src={item.accessUrl} alt={item.fileName} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
}
