import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  stroke?: string;
};

export default function Check({
  fill = 'none',
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
  stroke = 'currentColor',
  strokeWidth = '2',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth={strokeWidth}
    >
      <path d="M5 12L9.5 16.5L19 7" stroke={stroke} />
    </svg>
  );
}
