import React from 'react';
import tw from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { FormItem } from '../../../types/input.type';
import SwitchInput from '../../molecule/SwitchInput/SwitchInput.molecule';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';

interface Props {
  translator: UseTranslator;
  isTemplateRequireSave?: boolean;
  soActivityTemplateFormData: FormItem[];
  isTemplateRequireSaveDisabled?: boolean;
  handleChangeSaveTemplate(value: boolean): void;
}
const Container = tw.div`flex flex-col`;
const ContainerForm = tw.div`pb-2`;

export default function SOCreateATInfoForm({
  translator,
  isTemplateRequireSave,
  soActivityTemplateFormData,
  isTemplateRequireSaveDisabled,
  handleChangeSaveTemplate,
}: Props) {
  return (
    <Container>
      <SwitchInput
        disabled={isTemplateRequireSaveDisabled}
        label={translator.translate('Save as New Activity Template')}
        values={isTemplateRequireSave}
        onChange={(v) => handleChangeSaveTemplate(!!v)}
      />
      {isTemplateRequireSave && (
        <div>
          {soActivityTemplateFormData.map((item) => (
            <ContainerForm key={`SOActivityTemplateForm-${item.id}`}>
              {/* type already described inside component */}
              <ComposedFormField {...item} />
            </ContainerForm>
          ))}
        </div>
      )}
    </Container>
  );
}
