import React from 'react';
import tw from 'twin.macro';
import { CircleCheck } from '../../atom/Icon/Icon.atom';
import {
  BodySixteen,
  HeadingFour,
  HeadingOne,
  HeadingThree,
} from '../../atom/Text/Text.atom';
import { CopyToClipboard } from '../../molecule';

// #region STYLING
const Container = tw.div`flex justify-center`;
const Content = tw.div`w-[650px] py-6 flex flex-col text-center items-center`;
const NumberContainer = tw.div`py-3 flex flex-col gap-3 items-center`;
const PinCopyIconContainer = tw.div`pl-3 self-center`;
const HeaderIcon = tw.div`pb-6`;

// #endregion

// #region INTERFACE
type JOSubmissionModalProps = {
  joNumber: string;
  vehicleName?: string;
  driverName?: string;
};

type Props = {
  joSubmissionModalProps: JOSubmissionModalProps;
  joNumberLabel: string;
  assignedLabel: string;
  descriptionLabel: string;
};

// #endregion

export default function JOSubmissionModal({
  joSubmissionModalProps,
  joNumberLabel,
  assignedLabel,
  descriptionLabel,
}: Props) {
  return (
    <Container>
      <Content>
        <HeaderIcon>
          <CircleCheck />
        </HeaderIcon>

        <HeadingThree>{joNumberLabel}</HeadingThree>

        <NumberContainer>
          <div tw="flex">
            <HeadingOne>{joSubmissionModalProps.joNumber}</HeadingOne>
            <PinCopyIconContainer>
              <CopyToClipboard valueToCopy={joSubmissionModalProps.joNumber} />
            </PinCopyIconContainer>
          </div>
          {!!joSubmissionModalProps.driverName &&
            !!joSubmissionModalProps.vehicleName && (
              <div tw="flex flex-col gap-2">
                <BodySixteen>{assignedLabel}</BodySixteen>
                <div tw="flex gap-2 justify-center">
                  <HeadingFour tw="max-w-[250px] font-semibold truncate">
                    {joSubmissionModalProps.driverName}
                  </HeadingFour>
                  <HeadingFour tw="font-semibold -mt-1">.</HeadingFour>
                  <HeadingFour tw="max-w-[250px] font-semibold truncate">
                    {joSubmissionModalProps.vehicleName}
                  </HeadingFour>
                </div>
              </div>
            )}
        </NumberContainer>

        <BodySixteen tw="text-grey-two italic">{descriptionLabel}</BodySixteen>
      </Content>
    </Container>
  );
}
