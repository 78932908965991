import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useClickAway, useToggle } from 'react-use';
import tw from 'twin.macro';
import { Tooltip } from '..';
import useTranslator from '../../../hook/useTranslator.hook';
import { Icon, Text } from '../../atom';

const componentId = 'ButtonLinkPopup';
const testIds = {
  link: {
    manualInput: `${componentId}:Link:ManualInput`,
  },
  button: {
    importXLS: `${componentId}:Button:ImportXLS`,
  },
  label: {
    manualInput: `${componentId}:Label:ManualInput`,
    importXLS: `${componentId}:Label:ImportXLS`,
  },
} as const;

// #region STYLED
const Root = tw.div`flex items-center rounded-md text-white bg-black`;
const Navigate = tw(
  Link,
)`h-full px-3 flex items-center rounded-md hover:bg-grey-two`;
const Label = tw(Text.Label)`ml-2 text-white`;
const Dropdown = tw.button`h-full px-1.5 flex items-center rounded-r-md border-l border-l-beige-lines hover:bg-grey-two`;
const DropdownIcon = tw.div`transition rotate-90`;
const PopupLink = tw(Link)`py-1.5 text-left hover:bg-orange-hover`;
const PopupLabel = tw(
  Text.HeadingFive,
)`w-full flex px-6 text-grey-two hover:text-orange`;
const PopupButton = tw.button`py-1.5 text-left hover:bg-orange-hover`;
// #endregion

// #region TYPES
type Props = {
  path: string;
  state: unknown;
  icon: React.ReactNode;
  label: string;
  onClickImport: () => void;
};
// #endregion

export default function ButtonLinkPopup({
  path,
  state,
  icon,
  label,
  onClickImport,
}: Props) {
  const ref = useRef(null);
  const [isExpanded, toggleExpanded] = useToggle(false);
  const translator = useTranslator();

  const handleClickImport = () => {
    toggleExpanded();
    onClickImport();
  };

  useClickAway(ref, (ev: MouseEvent) => {
    const attrValue = (ev.target as Element).getAttribute('data-testid');
    const ignoredTestIDs = [
      'Tooltip:Container:Content',
      testIds.link.manualInput,
      testIds.button.importXLS,
      testIds.label.manualInput,
      testIds.label.importXLS,
    ];

    // do NOT close popup whenever user click inside popup
    if (attrValue && ignoredTestIDs.includes(attrValue)) return;

    // close popup whenever user click outside bulk button
    toggleExpanded(false);
  });

  return (
    <>
      <Root ref={ref}>
        <Navigate to={path} state={state}>
          {icon}
          <Label>{label}</Label>
        </Navigate>

        <Dropdown
          type="button"
          css={[isExpanded && tw`bg-grey-two`]}
          onClick={toggleExpanded}
        >
          <DropdownIcon css={[isExpanded && tw`-rotate-90`]}>
            <Icon.ChevronSharp
              height={16}
              width={16}
              strokeWidth={3}
              stroke="white"
            />
          </DropdownIcon>
        </Dropdown>
      </Root>

      <Tooltip
        withPortal
        addArrow={false}
        offset={[0, 10]}
        variant="light"
        placement="bottom-end"
        targetRef={ref}
        visible={isExpanded}
        contentStyle={tw`px-0 py-2.5 max-width[220px] flex flex-col rounded-md animate-slide-in-top duration-300 transition shadow-card`}
      >
        <PopupLink
          data-testid={testIds.link.manualInput}
          to={path}
          state={state}
        >
          <PopupLabel data-testid={testIds.label.manualInput}>
            {translator.translate('Manual Input')}
          </PopupLabel>
        </PopupLink>

        <PopupButton
          type="button"
          data-testid={testIds.button.importXLS}
          onClick={handleClickImport}
        >
          <PopupLabel data-testid={testIds.label.importXLS}>
            {translator.translate('Import XLS')}
          </PopupLabel>
        </PopupButton>
      </Tooltip>
    </>
  );
}
