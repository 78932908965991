import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fillPath?: string;
};

export default function TruckOutline({
  width = 12,
  height = 12,
  viewBox = '0 0 12 12',
  fill = 'none',
  fillPath = 'currentColor',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <g>
        <path
          fill={fillPath}
          fillRule="evenodd"
          d="M8.4 1.2H0v7.87l.001.001a1.8 1.8 0 003.496.529h2.606a1.8 1.8 0 003.394 0H12V6c0-.234-.056-.485-.117-.7a8.451 8.451 0 00-.444-1.155 2.237 2.237 0 00-.44-.67A1.55 1.55 0 009.9 3H8.4V1.2zm1.097 7.2H10.8V7.2H9V6h1.8c0-.303-.147-.627-.257-.902-.11-.275-.28-.898-.643-.898H8.4v3.103A1.8 1.8 0 019.497 8.4zM7.2 7.303V2.4h-6v4.903A1.8 1.8 0 013.497 8.4h2.606A1.8 1.8 0 017.2 7.303zM1.376 8.575a.6.6 0 11.848.849.6.6 0 01-.848-.849zm6 0a.6.6 0 11.848.849.6.6 0 01-.848-.849z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  );
}
