import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../../component/molecule';
import { BulkActionEntity, BulkActionStatusEnum } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { ErrorDetail } from '../../../service/api.endpoint';
import { mapLocationErrorMessages } from '../../../util/deliveryLocation.util';
import { mapBulkActionStatusToLabel } from '../../../util/helper.util';
import { DeliveryLocationAddBulk } from '../DeliveryLocationAddBulk.route';
import { UseDeliveryLocationAddBulkLogic } from './useDeliveryLocationAddBulkLogic.hook';

// #region TYPES
export type AddBulkLocationEntity = BulkActionEntity<DeliveryLocationAddBulk>;
export type Props = {
  bulkLogic: UseDeliveryLocationAddBulkLogic;
};
// #endregion

export default function useDeliveryLocationAddBulkTable({
  bulkLogic: { locationsWithStatusDetail, showStatus },
}: Props) {
  const translator = useTranslator();

  const columns = useMemo<ColumnDef<AddBulkLocationEntity>[]>(
    () => [
      {
        size: 200,
        header: translator.translate('Location Name'),
        accessorKey: 'Nama Lokasi',
        cell: ({ getValue }) => {
          const fullName = getValue() as string;

          return (
            <CustomTableRowWithPopover
              primaryLabel={fullName ?? '-'}
              containerStyle={tw`max-w-[240px]`}
            />
          );
        },
      },
      {
        size: 150,
        header: translator.translate('Address'),
        accessorKey: 'Alamat',
        cell: ({ getValue }) => {
          const address = getValue() as string;
          return <CustomTableRowWithPopover primaryLabel={address ?? '-'} />;
        },
      },
      {
        size: 150,
        header: translator.translate('Type'),
        accessorKey: 'Tipe',
        cell: ({ getValue }) => {
          const type = getValue() as string;
          return <CustomTableRowWithPopover primaryLabel={type ?? '-'} />;
        },
      },
      ...(showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({
                getValue,
              }: { getValue: () => string | ErrorDetail[] }) => {
                const accessor = getValue();
                const label =
                  typeof accessor === 'string'
                    ? translator.translate(accessor)
                    : mapLocationErrorMessages(translator, accessor);

                return (
                  <CustomTableRowWithPopover
                    primaryLabel={label}
                    containerStyle={tw`max-w-[290px]`}
                  />
                );
              },
            },
          ]
        : []),
    ],
    [showStatus, translator],
  );

  const table = useReactTable({
    data: locationsWithStatusDetail,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
