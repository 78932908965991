import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import sortBy from 'lodash/sortBy';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { Text } from '../../../component/atom';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../../component/molecule';
import {
  BulkActionStatusEnum,
  DeleteActivityTemplateEntity,
} from '../../../constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { mapBulkActionStatusToLabel } from '../../../util/helper.util';
import { UseActivityTemplateDeleteLogicObj } from './useActivityTemplateDeleteLogic.hook';

// #region INTERFACES
type UseActivityTemplateDeleteTableParams = {
  translator: UseTranslator;
  activityTemplateDeleteLogic: UseActivityTemplateDeleteLogicObj;
};
export type UseActivityTemplateDeleteTableObj = ReturnType<
  typeof useActivityTemplateDeleteTable
>;
// #endregion

export default function useActivityTemplateDeleteTable({
  translator,
  activityTemplateDeleteLogic,
}: UseActivityTemplateDeleteTableParams) {
  const columns = useMemo<ColumnDef<DeleteActivityTemplateEntity>[]>(
    () => [
      {
        size: 200,
        header: translator.translate('Activity Template Name'),
        accessorKey: 'name',
        cell: ({ getValue }) => (
          <CustomTableRowWithPopover
            primaryLabel={getValue() as string}
            containerStyle={tw`max-width[200px]`}
            primaryLabelStyle={tw`font-bold`}
          />
        ),
      },
      {
        size: 150,
        header: translator.translate('Total Activity'),
        accessorKey: 'items',
        meta: {
          cellStyle: tw`text-right`,
        },
        cell: ({ getValue }) => (
          <Text.Paragraph tw="text-right">
            {(getValue() as DeleteActivityTemplateEntity['items']).length}
          </Text.Paragraph>
        ),
      },
      {
        size: 150,
        header: translator.translate('First Location'),
        accessorKey: 'items',
        cell: ({ getValue }) => {
          const locations = sortBy(getValue(), [
            'index',
          ]) as DeleteActivityTemplateEntity['items'];

          return (
            <CustomTableRowWithPopover
              primaryLabel={locations[0].location.name}
              secondaryLabel={locations[0].location.address}
              containerStyle={tw`max-width[240px]`}
            />
          );
        },
      },
      {
        size: 150,
        header: translator.translate('Last Location'),
        accessorKey: 'items',
        cell: ({ getValue }) => {
          const locations = sortBy(getValue(), [
            'index',
          ]) as DeleteActivityTemplateEntity['items'];
          const lastItem =
            locations.length === 1 ? '-' : locations[locations.length - 1];

          if (typeof lastItem === 'string')
            return <Text.Paragraph>{lastItem}</Text.Paragraph>;

          return (
            <CustomTableRowWithPopover
              primaryLabel={lastItem.location.name}
              secondaryLabel={lastItem.location.address}
              containerStyle={tw`max-width[240px]`}
            />
          );
        },
      },
      ...(activityTemplateDeleteLogic.showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 220,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <CustomTableRowWithPopover
                  primaryLabel={translator.translate(getValue() as string)}
                  containerStyle={tw`max-width[240px]`}
                  primaryLabelStyle={tw`animate-slide-in-left`}
                />
              ),
            },
          ]
        : []),
    ],
    [activityTemplateDeleteLogic.showStatus, translator],
  );

  const table = useReactTable({
    data: activityTemplateDeleteLogic.activityTemplatesWithStatusDetail,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
