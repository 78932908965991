import getUnixTime from 'date-fns/getUnixTime';
import { useFormik } from 'formik';
import difference from 'lodash/difference';
import { useCallback, useMemo, useState } from 'react';
import {
  JOSOCandidateFilterFormInitialValues,
  joSOCandidateFilterFormInitialValues,
} from '../../constant/JobOrderCreate.constant';
import {
  JOSOCandidateFilterFormSortBy,
  JOSOCandidateFilterFormStatus,
} from '../../model/ShipperOrder.model';
import api from '../../service/api.service';
import { getDateFormat } from '../../util/date.util';
import {
  getJOSOCandidateFilterSortByValues,
  getJOSOCandidateFilterStatusValues,
  mapJOSOCandidateFilterSortByToLabel,
  mapJOSOCandidateFilterStatusesToLabels,
} from '../../util/jobOrderCreate.util';
import { getSOUnassignedStatuses } from '../../util/shipperOrder.util';
import { AutocompleteType } from '../useAutocomplete.hook';
import useGetLocationsAutocomplete from '../useGetLocationsAutocomplete.hook';
import useGetShippersAutocomplete from '../useGetShippersAutocomplete.hook';
import useTranslator from '../useTranslator.hook';

type Props = {
  currentFilterFormSOCandidate: JOSOCandidateFilterFormInitialValues;
  handleDeselectAllCandidate: () => void;
  handleFilterFormCandidate: (
    filterForm: JOSOCandidateFilterFormInitialValues,
  ) => void;
};
export type UseJOFormSOCandidateFilterHookObj = ReturnType<
  typeof useJOFormSOCandidateFilter
>;

export default function useJOFormSOCandidateFilter({
  currentFilterFormSOCandidate,
  handleDeselectAllCandidate,
  handleFilterFormCandidate,
}: Props) {
  const translator = useTranslator();

  // #region VALUES
  const testID = 'JobOrderCreate:SOCandidate';
  const errorStatusLabel = translator.translate(
    'Requires at least 1 selected status',
  );
  const titleLabel = translator.translate('Filter');
  const resetButtonLabel = translator.translate('Reset');
  const sortByLabel = translator.translate('Sort by');
  const sortByValues = getJOSOCandidateFilterSortByValues(translator);
  const selectAllLabel = translator.translate('Select All');
  const unselectAllLabel = translator.translate('Unselect All');
  const statusLabel = translator.translate('Status');
  const statusValues = getJOSOCandidateFilterStatusValues(translator);
  const shipperLabel = translator.translate('Shipper');
  const shipperPlaceholderLabel = translator.translate('Choose Shipper');
  const locationLabel = translator.translate('Location Name');
  const locationPlaceholderLabel = translator.translate('Choose Location');
  const dateRangeLabel = translator.translate('Date');
  const sdLabel = translator.translate('Start Date');
  const edLabel = translator.translate('End Date');
  const searchPlaceholderLabel = translator.translate('SO, Ref, Location');

  const {
    shipperOptions,
    shipperListFetchLoading,
    onChangeShipperAutotext,
    onFetchMoreShipper,
  } = useGetShippersAutocomplete();

  const {
    locationOptions,
    locationListFetchLoading,
    onChangeLocationAutotext,
    onFetchMoreLocation,
  } = useGetLocationsAutocomplete();

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const filterForm = useFormik<JOSOCandidateFilterFormInitialValues>({
    enableReinitialize: true,
    initialValues: currentFilterFormSOCandidate,
    onSubmit: (values) => {
      setShowFilter(false);

      // deselect all so candidate list
      handleDeselectAllCandidate();

      // set state to trigger refetch so unassigned list based on applied filter
      handleFilterFormCandidate({
        sortBy: values.sortBy,
        status: values.status,
        shipperId: values.shipperId,
        locationId: values.locationId,
        startDate: values.startDate,
        endDate: values.endDate,
      });
    },
  });

  const statusNumbers = getSOUnassignedStatuses(filterForm.values.status);
  const sortByChipLabel = translator.translate(
    mapJOSOCandidateFilterSortByToLabel(filterForm.values.sortBy),
  );
  const statusChipLabel = mapJOSOCandidateFilterStatusesToLabels(
    filterForm.values.status,
    translator,
  );

  const selectedOptionShipper = shipperOptions.find(
    (option) => option.value === filterForm.values.shipperId,
  );
  const selectedShipperName = selectedOptionShipper?.label;
  const selectedOptionLocation = locationOptions.find(
    (option) => option.value === filterForm.values.locationId,
  );
  const selectedLocationName = selectedOptionLocation?.label;

  const isQueryStatusEmpty = filterForm.values.status.length === 0;
  const isQueryStatusAll =
    difference(
      joSOCandidateFilterFormInitialValues.status,
      filterForm.values.status,
    ).length === 0;

  const activeFilters = useMemo(() => {
    const returnedLabels: string[] = [];
    const filterOrderStatusIsActive =
      filterForm.values.status.length && !isQueryStatusAll;

    if (filterOrderStatusIsActive)
      returnedLabels.push(`Status:${filterForm.values.status.length}`);
    if (selectedShipperName) returnedLabels.push(selectedShipperName);
    if (selectedLocationName) returnedLabels.push(selectedLocationName);
    if (filterForm.values.startDate && filterForm.values.endDate)
      returnedLabels.push(
        `${getDateFormat(filterForm.values.startDate)} - ${getDateFormat(
          filterForm.values.endDate,
        )}`,
      );

    return returnedLabels;
  }, [
    filterForm.values.endDate,
    filterForm.values.startDate,
    filterForm.values.status.length,
    isQueryStatusAll,
    selectedLocationName,
    selectedShipperName,
  ]);

  // this is necessary because we need to query multiple `status[]` params
  const searchParams = useMemo(() => {
    const params = new URLSearchParams('');

    params.set('page_size', '1');
    if (statusNumbers.length) {
      for (const _status of statusNumbers) {
        params.append('status[]', _status.toString());
      }
    }
    if (filterForm.values.shipperId)
      params.set('shipper_id', filterForm.values.shipperId);
    if (filterForm.values.locationId)
      params.set('location_id', filterForm.values.locationId);
    if (filterForm.values.startDate)
      params.set('from', getUnixTime(filterForm.values.startDate).toString()); // in seconds
    if (filterForm.values.endDate)
      params.set('to', getUnixTime(filterForm.values.endDate).toString()); // in seconds

    return params.toString();
  }, [
    filterForm.values.locationId,
    filterForm.values.shipperId,
    filterForm.values.endDate,
    filterForm.values.startDate,
    statusNumbers,
  ]);

  // get so unassigned list total count based on current applied filter
  // currently there are no count API, so we use list API
  const { filterLocalTotalCount, filterLocalIsFetching } =
    api.useGetSOUnassignedListQuery(searchParams, {
      skip: !showFilter || isQueryStatusEmpty,
      selectFromResult: ({ data, isFetching }) => ({
        filterLocalTotalCount: data?.metadata.totalCount,
        filterLocalIsFetching: isFetching,
      }),
    });

  const submitButtonLabel = filterLocalIsFetching
    ? translator.translate('Loading...')
    : `${translator.translate('Show')} ${String(
        filterLocalTotalCount,
      )} ${translator.translate('Shipper Order')}`;
  // #endregion

  // #region HANDLERS
  const onOpenFilter = useCallback(() => {
    setShowFilter(true);
  }, []);

  const onCloseDrawerPortal = useCallback(() => {
    setShowFilter(false);
    onChangeShipperAutotext('');
    onChangeLocationAutotext('');

    // reset filter state
    void filterForm.resetForm();
  }, [filterForm, onChangeLocationAutotext, onChangeShipperAutotext]);

  const onClickResetFilter = useCallback(() => {
    onChangeShipperAutotext('');
    onChangeLocationAutotext('');

    // set filter state to initial values
    void filterForm.setValues(joSOCandidateFilterFormInitialValues);
  }, [filterForm, onChangeLocationAutotext, onChangeShipperAutotext]);

  const onClickSortByChipFilter = useCallback(
    (_sortBy: JOSOCandidateFilterFormSortBy) => {
      void filterForm.setFieldValue('sortBy', _sortBy, false);
    },
    [filterForm],
  );

  const onClickStatusChipFilter = useCallback(
    (_status: JOSOCandidateFilterFormStatus) => {
      const ps = filterForm.values.status;
      const logic = ps.includes(_status)
        ? ps.filter((_stat) => _stat !== _status)
        : ps.concat([_status]);

      void filterForm.setFieldValue('status', logic, false);
    },
    [filterForm],
  );
  const onClickSelectAllStatus = useCallback(() => {
    void filterForm.setFieldValue(
      'status',
      joSOCandidateFilterFormInitialValues.status,
      false,
    );
  }, [filterForm]);
  const onClickUnselectAllStatus = useCallback(() => {
    void filterForm.setFieldValue('status', [], false);
  }, [filterForm]);

  const onChangeShipperFilter = useCallback(
    (shipperData?: AutocompleteType) => {
      void filterForm.setValues((prev) => ({
        ...prev,
        shipperId: shipperData?.value,
      }));
    },
    [filterForm],
  );

  const onRemoveShipperFilter = useCallback(() => {
    void filterForm.setFieldValue('shipperId', undefined, false);
  }, [filterForm]);

  const onChangeLocationFilter = useCallback(
    (locationData?: AutocompleteType) => {
      void filterForm.setValues((prev) => ({
        ...prev,
        locationId: locationData?.value,
      }));
    },
    [filterForm],
  );

  const onRemoveLocationFilter = useCallback(() => {
    void filterForm.setFieldValue('locationId', undefined, false);
  }, [filterForm]);

  const onChangeStartDateFilter = useCallback(
    (_startDate: Date | undefined) => {
      void filterForm.setFieldValue('startDate', _startDate, false);
    },
    [filterForm],
  );

  const onChangeEndDateFilter = useCallback(
    (_endDate: Date | undefined) => {
      void filterForm.setFieldValue('endDate', _endDate, false);
    },
    [filterForm],
  );

  const onClickApplyDateRange = useCallback(
    (startDate: Date | undefined, endDate: Date | undefined) => {
      void filterForm.setValues((prev) => ({
        ...prev,
        startDate,
        endDate,
      }));
    },
    [filterForm],
  );

  const onClickSubmitFilter = useCallback(
    () => filterForm.handleSubmit(),
    [filterForm],
  );
  // #endregion

  return {
    testID,
    titleLabel,
    resetButtonLabel,
    sortByLabel,
    sortByValues,
    selectAllLabel,
    statusLabel,
    statusValues,
    shipperLabel,
    shipperPlaceholderLabel,
    locationLabel,
    locationPlaceholderLabel,
    dateRangeLabel,
    sdLabel,
    edLabel,
    searchPlaceholderLabel,
    shipperOptions,
    shipperListFetchLoading,
    onChangeShipperAutotext,
    onFetchMoreShipper,
    locationOptions,
    locationListFetchLoading,
    onChangeLocationAutotext,
    onFetchMoreLocation,
    showFilter,
    filterForm,
    filterLocalIsFetching,
    filterLocalTotalCount,
    onOpenFilter,
    onCloseDrawerPortal,
    onClickResetFilter,
    onClickSortByChipFilter,
    onClickStatusChipFilter,
    onClickSelectAllStatus,
    onClickSubmitFilter,
    onChangeShipperFilter,
    onRemoveShipperFilter,
    onChangeLocationFilter,
    onRemoveLocationFilter,
    onChangeStartDateFilter,
    onChangeEndDateFilter,
    onClickApplyDateRange,
    sortByChipLabel,
    statusChipLabel,
    selectedOptionShipper,
    selectedShipperName,
    selectedOptionLocation,
    selectedLocationName,
    activeFilters,
    submitButtonLabel,
    isQueryStatusAll,
    errorStatusLabel,
    unselectAllLabel,
    isQueryStatusEmpty,
    onClickUnselectAllStatus,
  };
}
