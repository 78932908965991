import React, { ReactNode } from 'react';
import tw from 'twin.macro';
import { Button, Icon, Text } from '../../atom';

const Container = tw.div`height[65vh] flex flex-auto flex-col items-center justify-center bg-white w-full rounded-bl-lg rounded-br-lg`;

// #region INTERFACES
export type EmptyDataProps = {
  showButtonIcon?: boolean;
  icon?: ReactNode;
  description?: string;
  subDescription?: string;
  buttonLabel?: string;
  onClick?: () => void;
};
// #endregion

function EmptyData({
  showButtonIcon = true,
  icon,
  description,
  subDescription,
  buttonLabel,
  onClick,
}: EmptyDataProps) {
  return (
    <Container>
      {icon}
      {description && (
        <Text.HeadingThree tw="py-2.5">{description}</Text.HeadingThree>
      )}
      {subDescription && (
        <Text.BodyFourteen tw="py-2.5">{subDescription}</Text.BodyFourteen>
      )}

      {onClick && buttonLabel && (
        <Button.Solid tw="bg-black mt-5 rounded-md" onClick={onClick}>
          {showButtonIcon && <Icon.Add fill="white" tw="w-5 h-5" />}
          <Text.HeadingFour tw="text-beige-bg">{buttonLabel}</Text.HeadingFour>
        </Button.Solid>
      )}
    </Container>
  );
}

export default EmptyData;
