import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { DriverSearchParam } from '../../model/Driver.model';

export type DriverAddRouteParam =
  | {
      searchParamsFromDriverList?: DriverSearchParam;
    }
  | undefined;

export const driverAddRoute: RouteConfig = {
  name: 'Add Driver',
  Component: React.lazy(() => import('./DriverAdd.view')),
  path: '/driver/add',
  isPrivate: true,
};
