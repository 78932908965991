import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useRunnerEventList from '../../hook/runnerEvent/useRunnerEventList.hook';
import useContent from '../../hook/useContent/useContent.hook';
import useDocumentTitleUpdate from '../../hook/useDocumentTitleUpdate/useDocumentTitleUpdate.hook';
import useLocaleUpdate from '../../hook/useLocaleUpdate.hook';
import useProfile from '../../hook/useProfile/userProfile.hook';
import useRouteCheck from '../../hook/useRouteCheck/useRouteCheck.hook';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import useSidebar from '../../hook/useSidebar/useSidebar.hook';
import useToggleSettingModal from '../../hook/useToggleSettingModal.hook';
import useTopBar from '../../hook/useTopBar/useTopBar.hook';
import useTopbarOnboarding from '../../hook/useTopbarOnboarding/useTopbarOnboarding.hook';
import useTranslator from '../../hook/useTranslator.hook';
import { homeRoute } from '../Home/Home.route';
import useSession from './hooks/useSession.hook';

export default function useWrapperViewModel() {
  // #region REQUIRED DATA
  const translator = useTranslator();
  const location = useLocation();
  const { toggleModal: toggleModalSetting } = useToggleSettingModal();
  const runnerEventQuery = useRunnerEventList();
  // #endregion

  // #region LOCALE
  useLocaleUpdate();
  // #endregion

  // #region Document
  useDocumentTitleUpdate(translator, location);
  // #endregion

  // #region SESSION
  const session = useSession();
  // #endregion

  // #region SIDEBAR
  const sidebar = useSidebar();
  // #endregion

  // #region CONTENT
  const content = useContent(location);
  // #endregion

  // #region TOP BAR
  const topBar = useTopBar();
  // #endregion

  // #region PROFILE
  const profile = useProfile();
  // #endregion

  // #region ONBOARDING
  const onboarding = useTopbarOnboarding({ translator });
  // #endregion

  const { isMobile } = useScreenDetection();

  const { isPublicFullWidth, isRouteHelpCenter } = useRouteCheck();

  const showDrivingContestBanner = useMemo(() => {
    if (!isMobile) return runnerEventQuery.showDrivingContestBanner;
    return (
      runnerEventQuery.showDrivingContestBanner &&
      !![homeRoute.path].includes(location.pathname)
    );
  }, [isMobile, location.pathname, runnerEventQuery.showDrivingContestBanner]);

  return {
    runnerEventQuery,
    sidebar,
    content,
    topBar,
    location,
    session,
    profile,
    onboarding,
    translator,
    isPublicFullWidth,
    isRouteHelpCenter,
    isMobile,
    showDrivingContestBanner,
    toggleModalSetting,
  };
}
