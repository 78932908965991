import React from 'react';
import tw from 'twin.macro';
import { JOSOCandidateColumns } from '../../../constant/JobOrderCreate.constant';
import { UseJOFormSOCandidateHookObj } from '../../../hook/jobOrderForm/useJOFormSOCandidate.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { DrawerPortal, Table } from '../../molecule';
import ContainerHeader from '../../molecule/ContainerHeader/ContainerHeader.molecule';
import EmptyData from '../../molecule/EmptyData/EmptyData.molecule';
import JOFormSOCandidateFilter from './JOFormSOCandidateFilter.organism';

// #region INTERFACES
type Props = {
  soCandidate: UseJOFormSOCandidateHookObj;
};
// #endregion

// #region STYLED
const Container = tw.div`w-[1032px] mx-auto pb-6 -mt-10`;
const TableWrapper = tw.div`rounded-lg bg-white shadow-card`;

const TableStyle = tw`h-[60vh] overflow-x-auto`;
const TableEmptyStyle = tw`overflow-x-auto`;

// #endregion

export default function JOFormSOCandidateList({
  soCandidate: {
    filter,
    search,
    debouncedSearch,
    isAllCandidateSelected,
    isIndeterminateCandidateSelected,
    soListFetchLoading,
    soListIsRefetching,
    candidateRowRender,
    emptyData,
    candidateDataTotal,
    candidatePageTotal,
    currentLimitSOCandidate,
    currentPageSOCandidate,
    refetchSoList,
    handleSearch,
    handleLimitCandidate,
    handlePageCandidate,
    handleDeselectAllCandidate,
    handleSelectAllCandidate,
    handleBack,
  },
}: Props) {
  const { translate } = useTranslator();
  return (
    <Container>
      <ContainerHeader
        onBack={handleBack}
        title={translate('Shipper Order Candidate')}
      />
      <ContainerHeader
        isIndeterminateChecked={isIndeterminateCandidateSelected}
        isAllChecked={isAllCandidateSelected}
        title={translate('All')}
        handleChangeCheckbox={
          isAllCandidateSelected || isIndeterminateCandidateSelected
            ? handleDeselectAllCandidate
            : handleSelectAllCandidate
        }
        showFilter={filter.showFilter}
        filterChipLabel={filter.titleLabel}
        handleOpenFilter={filter.onOpenFilter}
        sortByChipLabel={filter.sortByChipLabel}
        activeFilters={filter.activeFilters}
        searchPlaceholder={filter.searchPlaceholderLabel}
        search={search}
        debouncedSearch={debouncedSearch}
        handleSearch={handleSearch}
        handleRefresh={refetchSoList}
        isRefreshing={soListIsRefetching || soListFetchLoading}
        refreshStyle={tw`ml-auto mr-2 h-11 w-11`}
      />
      <TableWrapper>
        <Table
          containerStyle={
            candidateRowRender.length ? TableStyle : TableEmptyStyle
          }
          stickyColumn={!!candidateRowRender.length}
          columns={JOSOCandidateColumns(translate, true)}
          rows={candidateRowRender}
          noDataPage={EmptyData(emptyData)}
          pageLimit={currentLimitSOCandidate}
          pageCurrent={currentPageSOCandidate}
          pageTotal={candidatePageTotal}
          onPageLimitClick={handleLimitCandidate}
          onPageClick={handlePageCandidate}
          dataTotal={candidateDataTotal}
        />
      </TableWrapper>

      <DrawerPortal
        isOpen={filter.showFilter}
        handleClose={filter.onCloseDrawerPortal}
      >
        <JOFormSOCandidateFilter filter={filter} />
      </DrawerPortal>
    </Container>
  );
}
