import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  version: 1,
  key: 'root',
  storage,
  whitelist: [
    'session',
    'redirection',
    'setting',
    'haulingJOTooltip',
    'onboarding',
  ],
};

export default persistConfig;
