import startOfToday from 'date-fns/startOfToday';
import React, { ReactElement } from 'react';
import { Text } from '../component/atom';
import { TableHeaderItem } from '../component/molecule/ActivityTableHeader/ActivityTableHeader.molecule';
import { DefinedRangeOption } from '../component/molecule/DateRangePicker/DateRangePicker.molecule';
import { UseJOAssignmentFormHookObj } from '../hook/joAssignmentForm/useJOAssignmentForm.hook';
import { UseJOHeaderFormObj } from '../hook/jobOrderCreate/useJOHeaderForm.hook';
import {
  JobOrderForm,
  JobOrderSearchParam,
  JOScheduleAssignmentType,
} from '../model/JobOrder.model';
import {
  lastOneMonthDate,
  lastOneYearDate,
  lastSixMonthsDate,
  lastThreeMonthsDate,
  lastTwoWeeksDate,
} from './Date.constant';
import { SelectionSO } from './ShipperOrder.constant';

// #region INTERFACES
export enum JOSOSelectionGroupBy {
  CURRENT_LOCATION = 'CURRENT_LOCATION',
  DELIVERY_LOCATION = 'DELIVERY_LOCATION',
  ASSIGNMENT = 'ASSIGNMENT',
}

export enum JOFormStep {
  HEADER = 'HEADER',
  SO_SELECTION = 'SO_SELECTION',
  ASSIGNMENT = 'ASSIGNMENT',
}

export enum JOFormDeliveryLocationModalSelectedRadio {
  SEARCH = 'SEARCH',
  LAST_MILE = 'LAST_MILE',
}

export type JobOrderFormNavigateParams =
  | {
      id?: string;
      formStep?: JOFormStep;
      formValues?: JobOrderForm;
      selectedSO?: SelectionSO[];
      checkedSO?: string[];
      joNumber?: string;
      searchParamsFromJOList?: JobOrderSearchParam;
    }
  | undefined;

export type JOFormFormatRenderRowsReturnKey =
  | 'id'
  | 'check'
  | 'number'
  | 'pickupLocation'
  | 'dropoffLocation'
  | 'currentLocation'
  | 'deliveryLocation'
  | 'status'
  | 'soDate'
  | 'shipper';

export type JOFormFormatRenderGroupByReturnKey =
  | JOFormFormatRenderRowsReturnKey
  | 'soList'
  | 'isExpanded';

export interface JOSOSelectionGroupByChip {
  id: JOSOSelectionGroupBy;
  label: string;
  selected: boolean;
  onClick: () => void;
}

export type JOHeaderFormProps = {
  isEdit?: boolean;
  joHeader: UseJOHeaderFormObj;
  joAssignmentHookObj: UseJOAssignmentFormHookObj;
  submitText?: string;
  formTitle?: string;
  subHeader?: React.ReactNode;
  handleBack: () => void;
  handleSubmit?: () => void;
};

export enum JOBOORDERTYPE {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum JOFilterOrderBy {
  UPDATED_AT_DESC = 'UPDATED_AT_DESC', // Newest Updates
  UPDATED_AT_ASC = 'UPDATED_AT_ASC', // Oldest Updates
  NUMBER_ASC = 'NUMBER_ASC', // JO Number A-Z
  NUMBER_DESC = 'NUMBER_DESC', // JO Number Z-A
  DATE_ASC = 'DATE_ASC', // JO Date A-Z
  DATE_DESC = 'DATE_DESC', // JO Date Z-A
}

export enum JOFilterStatus {
  DRAFT = 'DRAFT',
  DELETED = 'DELETED',
  ASSIGNED = 'ASSIGNED',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
}

export type JOFilterOrderByValue = {
  label: string;
  value: JOFilterOrderBy;
};
export type JOFilterStatusValue = {
  label: string;
  value: JOFilterStatus;
};

export type JOFilterInitialValues = {
  orderBy: JOFilterOrderBy;
  status: JOFilterStatus[];
  startDate?: Date;
  endDate?: Date;
  driverId?: string;
  vehicleId?: string;
};

export type JOFilterQueryParams = {
  startDate: string | null;
  endDate: string | null;
  orderBy: string | null;
  status: string | null;
  search: string | null;
  driverId: string | null;
  vehicleId: string | null;
  page: string | null;
  pageSize: string | null;
};

export enum JOSpendingTab {
  DETAILS = 'DETAILS',
  TIMELINE = 'TIMELINE',
}

export type JODetailSpendingMeta = {
  title: string;
  content: ReactElement;
};
// #endregion

export const allJOFilterStatus = Object.values(JOFilterStatus);

export const joFilterInitialValues: JOFilterInitialValues = {
  orderBy: JOFilterOrderBy.UPDATED_AT_DESC,
  status: allJOFilterStatus.filter(
    (status) => status !== JOFilterStatus.DELETED,
  ),
  startDate: undefined,
  endDate: undefined,
  driverId: undefined,
  vehicleId: undefined,
};

export const JO_INFO_SO_ACTIVITY_TABLE_HEADERS: TableHeaderItem[] = [
  {
    title: 'Type',
    width: '120px',
  },
  {
    title: 'Description',
    width: '130px',
  },
  {
    title: 'Qty',
    width: '60px',
    isNumber: true,
  },
  {
    title: 'Unit',
    width: '40px',
  },
  {
    title: 'Weight (kg)',
    width: '80px',
    isNumber: true,
  },
  {
    title: 'Volume (m³)',
    customText: (
      <Text.HeadingFive>
        Volume (m<sup>3</sup>)
      </Text.HeadingFive>
    ),
    width: '90px',
    isNumber: true,
  },
];

export const JOAssignmentFormInitialValues: JOScheduleAssignmentType = {
  driverId: undefined,
  vehicleId: undefined,
  driverOption: undefined,
  vehicleOption: undefined,
  schedule: undefined,
  scheduleOption: undefined,
};

export const dateJOSOCandidateFilterRangeDefinedRangeOptions: DefinedRangeOption[] =
  [
    {
      label: 'Last 2 Weeks',
      startRange: lastTwoWeeksDate,
      endRange: startOfToday(),
    },
    {
      label: 'Last 1 Month',
      startRange: lastOneMonthDate,
      endRange: startOfToday(),
    },
    {
      label: 'Last 3 Months',
      startRange: lastThreeMonthsDate,
      endRange: startOfToday(),
    },
    {
      label: 'Last 6 Months',
      startRange: lastSixMonthsDate,
      endRange: startOfToday(),
    },
    {
      label: 'Last 1 Year',
      startRange: lastOneYearDate,
      endRange: startOfToday(),
    },
  ];
