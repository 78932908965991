import React from 'react';
import { RouteConfig } from '../../../config/router/router.config';

export const reportHaulingOrderRoute: RouteConfig = {
  name: 'Hauling Order',
  path: '/report/hauling-order',
  Component: React.lazy(() => import('./ReportHaulingOrder.view')),
  isPrivate: true,

  breadcrumb: 'Hauling Order',

  options: {
    icon: <div />,
    label: 'Hauling Order',
    testID: 'MenuReportHaulingOrder',
  },
};

export default { reportHaulingOrderRoute };
