import React, { createRef } from 'react';
import NumberFormat from 'react-number-format';
import tw, { styled, TwStyle } from 'twin.macro';
import { Icon, Input } from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region PROPS
type Props = React.ComponentProps<'input'> & {
  testID?: string;
  containerStyle?: TwStyle;
  inputStyle?: TwStyle;
  label?: string;
  leftContainerStyle?: TwStyle;
  labelStyle?: ExtraStyle;
  maxLength?: number;
  error?: string;
  errorBold?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
  underline?: boolean;
  required?: boolean;
  inputBgBeige?: boolean;
  isNumber?: boolean;
  decimalScale?: number;
  errorContainerStyle?: TwStyle;
};
// #endregion

const Container = tw.div`flex flex-col flex-auto`;
const WithIconContainer = tw.div`flex items-center relative`;
const IconContainer = tw.span`absolute left-3.5 flex items-center`;
const IconContainerRight = tw.span`absolute right-3.5 flex items-center`;
const ErrorMessage = tw.span`font-sans font-size[12px] line-height[20px] letter-spacing[0.2px] mt-0.5  text-status-alert whitespace-pre-wrap`;
const BoldLabel = tw.span`font-semibold`;
const ErrorIconContainer = styled.span((props: { iconRight?: boolean }) => [
  tw`absolute right-3.5 flex items-center`,
  props.iconRight && tw`right-12`,
]);

const Underline = styled.div(
  (props: { disabled?: boolean; error?: boolean; filled?: boolean }) => [
    tw`absolute bottom-0 inset-x-px h-0.5 bg-grey-four`,
    !props.disabled &&
      tw`peer-hover:bg-orange peer-focus:bg-orange group-hover:bg-orange`,
    !props.disabled && props.filled && tw`bg-black`,
    props.error && tw`bg-status-alert!`,
  ],
);

const Label = styled.label(
  (props: { disabled?: boolean; filled?: boolean; extraStyle?: TwStyle }) => [
    tw`text-grey-three duration-200 font-sans leading-6 tracking-wider select-none absolute top-4 left-4 right-4 truncate ml-px`,
    !props.disabled &&
      tw`cursor-text peer-hover:(font-size[12px] leading-5 top-1.5) peer-focus:(font-size[12px] leading-5 top-1.5) group-hover:(font-size[12px] leading-5 top-1.5)`,
    props.disabled && props.filled && tw`(font-size[12px] top-1.5)`,
    props.filled && tw`(font-size[12px] leading-5 top-1.5)`,
    props.extraStyle,
  ],
);

// #region MAIN COMPONENT
function TextField({
  id,
  value,
  label,
  containerStyle,
  leftContainerStyle,
  inputStyle,
  labelStyle,
  placeholder,
  error,
  left,
  right,
  disabled,
  inputBgBeige,
  maxLength,
  underline = true,
  required = true,
  testID,
  isNumber,
  decimalScale = 0,
  errorContainerStyle,
  errorBold,
  ...props
}: Props) {
  const inputRef = createRef<HTMLInputElement>();

  return (
    <Container
      className="group"
      css={containerStyle}
      onClick={() => inputRef.current?.focus()}
    >
      <WithIconContainer>
        {left && <IconContainer css={leftContainerStyle}>{left}</IconContainer>}
        {isNumber ? (
          <NumberFormat
            maxLength={maxLength}
            disabled={disabled}
            placeholder={placeholder}
            decimalSeparator=","
            thousandSeparator="."
            value={value as unknown as number | undefined}
            decimalScale={decimalScale}
            css={[
              tw`
              m-0 p-4 rounded border border-solid border-beige-lines text-base leading-5 font-sans
              px-5 py-3 w-full rounded-b-none focus:ring-opacity-10 focus:outline-none`,
              !!left && tw`pl-12`,
              ((!error && !!right) || (!!error && !right)) && tw`pr-12`,
              !!error && !!right && tw`pr-20`,
              props.type === 'search' && tw`pt-3 pb-2.5`,
              label && tw`px-3 pt-6 pb-3 text-sm`,
              inputBgBeige && tw`bg-beige-bg`,
              disabled && tw`text-grey-three bg-grey-six border-grey-six`,
              inputStyle,
            ]}
            onBlur={props.onBlur}
            onChange={props.onChange}
            data-testid={`${testID || ''}TextField:NumberInput`}
          />
        ) : (
          <Input
            data-testid={`${testID || ''}TextField:Input`}
            id={id}
            maxLength={maxLength}
            required={required}
            ref={inputRef}
            className="peer"
            css={[
              tw`px-5 py-3 w-full rounded-b-none`,
              !!left && tw`pl-12`,
              ((!error && !!right) || (!!error && !right)) && tw`pr-12`,
              !!error && !!right && tw`pr-20`,
              props.type === 'search' && tw`pt-3 pb-2.5`,
              label && tw`px-3 pt-6 pb-3 text-sm`,
              inputBgBeige && tw`bg-beige-bg`,
              inputStyle,
            ]}
            disabled={disabled}
            value={value}
            placeholder={!label ? placeholder : undefined}
            {...props}
          />
        )}
        <Label
          htmlFor={id}
          css={[
            !!left && tw`pl-8`,
            ((!error && !!right) || (!!error && !right)) && tw`pr-8`,
            !!error && !!right && tw`pr-16`,
            label && tw`left-3 right-3`,
            ...(Array.isArray(labelStyle) ? labelStyle : []),
            !Array.isArray(labelStyle) && labelStyle,
          ]}
          data-testid={`${testID || ''}TextField:Label`}
          disabled={disabled}
          filled={!!value}
        >
          {label}
        </Label>
        {!!error && (
          <ErrorIconContainer
            css={errorContainerStyle}
            iconRight={!!right}
            tw="text-status-alert"
          >
            <Icon.Error />
          </ErrorIconContainer>
        )}
        {!!right && <IconContainerRight>{right}</IconContainerRight>}
        {underline && !disabled && (
          <Underline error={!!error} filled={!!value} />
        )}
      </WithIconContainer>
      {!!error && (
        <div>
          <ErrorMessage>
            {error}
            {!!errorBold && <BoldLabel> {errorBold}</BoldLabel>}
          </ErrorMessage>
        </div>
      )}
    </Container>
  );
}

export default TextField;
// #endregion
