import { startOfDay } from 'date-fns';
import endOfMonth from 'date-fns/endOfMonth';
import getUnixTime from 'date-fns/getUnixTime';
import startOfMonth from 'date-fns/startOfMonth';
import startOfToday from 'date-fns/startOfToday';
import subMonths from 'date-fns/subMonths';
import { theme } from 'twin.macro';
import { DateRangeOption } from '../..';
import { ChartBarLegend, ChartLineLegend } from '../model/Summary.model';

export enum HomeDateRangeOptionLabel {
  ONE_YEAR = 'Last 1 year',
  SIX_MONTHS = 'Last 6 months',
  THREE_MONTHS = 'Last 3 months',
  THIS_MONTH = 'This month',
  LAST_MONTH = 'Last month',
}
export const homeDefaultDateRangeOption = {
  label: HomeDateRangeOptionLabel.SIX_MONTHS,
  value: {
    from: getUnixTime(startOfMonth(subMonths(startOfToday(), 5))),
    to: getUnixTime(endOfMonth(startOfToday())),
  },
};

export const homeDefaultMobileDateRangeOption = {
  label: HomeDateRangeOptionLabel.ONE_YEAR,
  value: {
    from: getUnixTime(startOfMonth(subMonths(startOfToday(), 11))),
    to: getUnixTime(endOfMonth(startOfToday())),
  },
};

export const homeDateRangeOptions: DateRangeOption[] = [
  {
    label: HomeDateRangeOptionLabel.LAST_MONTH,
    value: {
      from: getUnixTime(startOfMonth(subMonths(startOfToday(), 1))),
      to: getUnixTime(endOfMonth(subMonths(startOfToday(), 1))),
    },
  },
  {
    label: HomeDateRangeOptionLabel.THIS_MONTH,
    value: {
      from: getUnixTime(startOfMonth(startOfDay(startOfToday()))),
      to: getUnixTime(endOfMonth(startOfDay(startOfToday()))),
    },
  },
  {
    label: HomeDateRangeOptionLabel.THREE_MONTHS,
    value: {
      from: getUnixTime(startOfMonth(subMonths(startOfToday(), 2))),
      to: getUnixTime(endOfMonth(startOfToday())),
    },
  },
  homeDefaultDateRangeOption,
  homeDefaultMobileDateRangeOption,
];

export const getBarChartLegends = (): ChartBarLegend[] => [
  {
    label: 'Shipper Order Delivered',
    id: 'shipperOrder',
    color: theme`colors.status.success`,
  },
  {
    label: 'Job Order Delivered',
    id: 'jobOrder',
    color: theme`colors.twilight.text`,
  },
];

export const getLineChartLegends = (): ChartLineLegend[] => [
  {
    label: 'Revenue',
    color: theme`colors.status.success.DEFAULT`,
  },
  {
    label: 'Net Income',
    color: theme`colors.orange.DEFAULT`,
  },
];

export const apiCallExtraOptions = {
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
};
