import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import tw, { theme } from 'twin.macro';
import {
  BottomMenuExplore,
  DrivingContest,
  NavDashboard,
  NavJobOrder,
  NavMaps,
  NavReports,
  ShipperOrder,
} from '../../component/atom/Icon/Icon.atom';
import { MobileBottomBarBottomSheetProps } from '../../component/organism/MobileBottomBar/MobileBottomBarBottomSheet.organism';
import { ReportMenuItemProps } from '../../component/organism/MobileBottomBar/MobileBottomBarReportMenuList.organism';
import { mobileExplorePath } from '../../constant/Common.constant';
import { drivingContestRoute } from '../../view/DrivingContest/drivingContest.route';
import { homeRoute } from '../../view/Home/Home.route';
import { jobOrderRoute } from '../../view/JobOrder/JobOrder.route';
import { reportDrivingBehaviorByJobOrderRoute } from '../../view/Report/DrivingBehavior/page/JobOrder/reportDrivingBehaviorByJobOrder.route';
import { reportDrivingBehaviorByScheduleRoute } from '../../view/Report/DrivingBehavior/page/Schedule/reportDrivingBehaviorBySchedule.route';
import { reportDrivingBehaviorByVehicleRoute } from '../../view/Report/DrivingBehavior/page/Vehicle/reportDrivingBehaviorByVehicle.route';
import { getRunnerMapUrl } from '../../view/Runner/Runner.route';
import { shipperOrderRoute } from '../../view/ShipperOrder/ShipperOrder.route';
import useRunnerEventList from '../runnerEvent/useRunnerEventList.hook';
import useTranslator from '../useTranslator.hook';

export interface MobileBottomBarItemProps {
  title: string;
  icon: ReactNode;
  url?: string;
  isActive?: boolean;
  isExternalLink?: boolean;
  handleClick?: () => void;
}

const IconContainer = tw.div`p-0.5`;
const isRouteActive = (routes: string[], currentPathname: string) =>
  routes.includes(currentPathname);

export default function useMobileBottomBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { translate } = useTranslator();
  const { isDrivingContestInProgress } = useRunnerEventList();

  const isDashboardRoute = useMemo(
    () => isRouteActive([homeRoute.path], location.pathname),
    [location.pathname],
  );
  const isExploreRoute = useMemo(
    () => isRouteActive(mobileExplorePath, location.pathname),
    [location.pathname],
  );

  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
  const [isDialogReportVisible, setIsDialogReportVisible] = useState(false);

  // #region ACTION

  const handleDismissBottomSheet = () => {
    setIsBottomSheetVisible(false);
  };

  const handleCloseReportDialog = useCallback(() => {
    setIsDialogReportVisible(false);
  }, []);

  const handleDismissReportDialog = useCallback(() => {
    handleCloseReportDialog();
    setIsBottomSheetVisible(true);
  }, [handleCloseReportDialog]);

  const handleSelectDialogItem = useCallback(
    (url: string) => {
      handleCloseReportDialog();
      navigate(url);
    },
    [handleCloseReportDialog, navigate],
  );

  // #endregion

  // #region LIST
  const menuList = useMemo(
    (): MobileBottomBarItemProps[] => [
      {
        title: translate('Dashboard'),
        url: homeRoute.path,
        isActive: isDashboardRoute,
        icon: (
          <NavDashboard
            fill={isDashboardRoute ? theme`colors.orange.DEFAULT` : undefined}
          />
        ),
      },
      {
        title: translate('Explore'),
        isActive: isExploreRoute,
        handleClick: () => {
          setIsBottomSheetVisible(true);
        },
        icon: (
          <div tw="w-6 h-6 flex items-center justify-center">
            <BottomMenuExplore
              pathFill={
                isExploreRoute ? theme`colors.orange.DEFAULT` : undefined
              }
            />
          </div>
        ),
      },
      {
        title: translate('Maps'),
        url: getRunnerMapUrl(),
        icon: <NavMaps fill={theme`colors.grey.two`} />,
        isExternalLink: true,
      },
    ],
    [isDashboardRoute, isExploreRoute, translate],
  );

  const bottomSheetMenuList = useMemo(
    (): MobileBottomBarBottomSheetProps[] => [
      {
        title: translate('Shipper Order'),
        handleClick: () => navigate(shipperOrderRoute.path),
        icon: (
          <IconContainer>
            <ShipperOrder fill={theme`colors.grey.two`} />
          </IconContainer>
        ),
      },
      {
        title: translate('Report'),
        icon: <NavReports fill={theme`colors.grey.two`} />,
        handleClick: () => {
          setIsDialogReportVisible(true);
          setIsBottomSheetVisible(false);
        },
      },
      {
        title: translate('Job Order'),
        handleClick: () => navigate(jobOrderRoute.path),
        icon: <NavJobOrder fill={theme`colors.grey.two`} />,
      },
      ...(isDrivingContestInProgress
        ? [
            {
              title: translate('Driver Contest'),
              handleClick: () => navigate(drivingContestRoute.path),
              icon: (
                <IconContainer>
                  <DrivingContest
                    width={20}
                    height={20}
                    fillPath={theme`colors.grey.two`}
                    isExpanded
                  />
                </IconContainer>
              ),
            },
          ]
        : []),
    ],
    [navigate, isDrivingContestInProgress, translate],
  );

  const reportDialogMenuList = useMemo(
    (): ReportMenuItemProps[] => [
      {
        label: translate('Driving Behaviour by Vehicle'),
        handleClick: () => {
          navigate(reportDrivingBehaviorByVehicleRoute.path);
          handleCloseReportDialog();
        },
      },
      {
        label: translate('Driving Behaviour by Job Order'),
        handleClick: () => {
          navigate(reportDrivingBehaviorByJobOrderRoute.path);
          handleCloseReportDialog();
        },
      },
      {
        label: translate('Driving Behaviour by Schedule'),
        handleClick: () => {
          navigate(reportDrivingBehaviorByScheduleRoute.path);
          handleCloseReportDialog();
        },
      },
    ],
    [navigate, handleCloseReportDialog, translate],
  );

  // #endregion

  return {
    menuList,
    isDashboardRoute,
    bottomSheetMenuList,
    reportDialogMenuList,
    isBottomSheetVisible,
    isDialogReportVisible,
    handleSelectDialogItem,
    handleCloseReportDialog,
    handleDismissBottomSheet,
    handleDismissReportDialog,
  };
}
