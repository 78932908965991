import React from 'react';
import { Icon } from '../../component/atom';
import { RouteConfig } from '../../config/router/router.config';
import { LocationSearchParam } from '../../model/Location.model';

export type DeliveryLocationEditRouteParam =
  | { searchParamsFromLocationList?: LocationSearchParam }
  | undefined;

export const deliveryLocationEditRoute: RouteConfig = {
  name: 'Edit Delivery Location',
  Component: React.lazy(() => import('./DeliveryLocationEdit.view')),
  path: '/delivery-location/edit/:id',
  isPrivate: true,
  breadcrumb: 'Edit Delivery Location',
  options: {
    icon: <Icon.DeliveryLocation />,
    label: 'Delivery Location',
    testID: 'MenuDeliveryLocationEdit',
  },
};
