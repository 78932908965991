import { Locale } from './locale.config';

const editJobOrderLocaleConfig: Locale[] = [
  {
    en: 'Job Order number is required.',
    id: 'Nomor JO wajib diisi',
  },
  {
    en: 'Job order has been updated',
    id: 'Perintah kerja berhasil diubah',
  },
  {
    en: 'Edit Header',
    id: 'Ubah Informasi Umum',
  },
  {
    en: 'Job Order already delivering',
    id: 'Perintah Kerja sudah dijalankan.',
  },
  {
    en: 'Job Order Assigned',
    id: 'Perintah Kerja Ditugaskan',
  },
  {
    en: 'Job Order Saved as a Draft',
    id: 'Perintah Kerja Disimpan sebagai Draft',
  },
  {
    en: 'Assigned Driver & Vehicle :',
    id: 'Sopir dan Kendaraan yang ditugaskan :',
  },
  {
    en: 'Your Job Order Number is',
    id: 'Nomor Perintah Kerja Anda',
  },
  {
    en: 'Successfully saved Job Order as a draft. Use the Job Order Number to see the detail of your order. You can return to this Job Order and complete the assignment later.',
    id: 'Berhasil menyimpan Perintah Kerja sebagai draf. Gunakan Nomor Perintah Kerja diatas untuk melihat rincian order Anda. Anda dapat kembali ke Perintah Kerja ini dan menyelesaikan penugasan di lain waktu.',
  },
  {
    en: 'Successfully assign Job Order. Use the Job Order Number to see the detail of your order.',
    id: 'Berhasil menugaskan Perintah Kerja. Gunakan Nomor Perintah Kerja ini untuk melihat rincian dari order Anda.',
  },
];

export default editJobOrderLocaleConfig;
