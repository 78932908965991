import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import 'twin.macro';
import MarkerDelivery from '../../component/molecule/MarkerDelivery/MarkerDelivery.molecule';
import { Map as RunnerMap } from '../../component/organism';
import MarkerVehicle from '../../component/organism/MarkerVehicle/MarkerVehicle.organism';
import SODetailMobileMapsHeader from '../../component/organism/SODetailMobileMapsHeader/SODetailMobileMapsHeader.organism';
import { SupportedLanguage } from '../../config/locale/locale.config';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import { LatLng } from '../../model/Map.model';
import { SOTracking, SOTrackingLocation } from '../../model/ShipperOrder.model';
import useTrackingMapViewModel from './TrackingMap.viewModel';

type Props = {
  forceLang?: SupportedLanguage;
  tracking?: SOTracking;
  runInterval?: boolean;
  trackingCode?: string;
};

interface TrackingMapProps {
  mapDeliveryLocations: {
    pickupLocations: SOTrackingLocation[];
    dropoffLocations: SOTrackingLocation[];
  };
  dropoffMarkerLabel?: string;
  pickupMarkerLabel?: string;
  soTrackPosition: {
    latestPosition: LatLng | undefined;
  };
  mapTargetLocations: LatLng[];
  isMobile?: boolean;
}

function TrackingMap({
  mapDeliveryLocations,
  dropoffMarkerLabel = '',
  pickupMarkerLabel = '',
  soTrackPosition,
  mapTargetLocations,
  isMobile,
}: TrackingMapProps) {
  return (
    <RunnerMap
      targetLocations={mapTargetLocations}
      disableOnclickTarget
      disableDragMap
      showButtonCurrentLocation={!isMobile}
    >
      {mapDeliveryLocations.pickupLocations.map(
        ({ latitude, longitude, name }) => (
          <MarkerDelivery
            key={nanoid()}
            title={pickupMarkerLabel}
            subtitle={name}
            position={{ lat: latitude, lng: longitude }}
          />
        ),
      )}
      {mapDeliveryLocations.dropoffLocations.map(
        ({ latitude, longitude, name }) => (
          <MarkerDelivery
            key={nanoid()}
            title={dropoffMarkerLabel}
            subtitle={name}
            position={{ lat: latitude, lng: longitude }}
            tw="bg-white"
            isDropoff
          />
        ),
      )}
      {soTrackPosition.latestPosition && (
        <MarkerVehicle
          position={{
            lat: soTrackPosition.latestPosition.lat,
            lng: soTrackPosition.latestPosition.lng,
          }}
        />
      )}
    </RunnerMap>
  );
}

function TrackingMapView({
  tracking,
  forceLang,
  runInterval,
  trackingCode,
}: Props) {
  const { isMobile } = useScreenDetection();
  const {
    mapDeliveryLocations,
    dropoffMarkerLabel,
    pickupMarkerLabel,
    soTrackPosition,
    mapTargetLocations,
    handleBack,
  } = useTrackingMapViewModel({
    tracking,
    forceLang,
    runInterval,
    trackingCode,
  });

  if (isMobile)
    return (
      <>
        <SODetailMobileMapsHeader handleBack={handleBack} />
        <TrackingMap
          isMobile
          mapDeliveryLocations={mapDeliveryLocations}
          dropoffMarkerLabel={dropoffMarkerLabel}
          pickupMarkerLabel={pickupMarkerLabel}
          soTrackPosition={soTrackPosition}
          mapTargetLocations={mapTargetLocations}
        />
      </>
    );
  return (
    <div tw="h-[365px] relative ">
      <TrackingMap
        mapDeliveryLocations={mapDeliveryLocations}
        dropoffMarkerLabel={dropoffMarkerLabel}
        pickupMarkerLabel={pickupMarkerLabel}
        soTrackPosition={soTrackPosition}
        mapTargetLocations={mapTargetLocations}
      />
    </div>
  );
}

export default TrackingMapView;
