import { VehicleStatus } from '../model/Vehicle.model';
import { numberFormatter } from '../util/formatter.util';

export enum VehicleStatusTheme {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
}

export const getVehicleStatus = (status?: VehicleStatus) => {
  if (!status) return undefined;
  const label: Record<VehicleStatus | string, VehicleStatusTheme> = {
    [VehicleStatus.STANDBY]: VehicleStatusTheme.GREEN,
    [VehicleStatus.ASSIGNED]: VehicleStatusTheme.YELLOW,
    [VehicleStatus.ON_DUTY]: VehicleStatusTheme.RED,
  };
  return label[status];
};

export const getVehicleMeasurementStatus = (
  measurement?: number,
  measurementUsed?: number,
) => {
  if (!measurement || !measurementUsed) return VehicleStatusTheme.GREEN;
  if (measurement <= measurementUsed) return VehicleStatusTheme.RED;

  const percentageDifference = 1 - measurementUsed / measurement;
  if (percentageDifference <= 0.1 && percentageDifference > 0)
    return VehicleStatusTheme.YELLOW;
  return VehicleStatusTheme.GREEN;
};

export const getVehicleMeasurementValue = ({
  measurement,
  measurementUsed,
}: {
  measurement?: number;
  measurementUsed?: number;
}) => {
  if (!measurement) return '-';
  return numberFormatter(
    measurement - (measurementUsed || 0), //volume
    {
      maximumFractionDigits: 2,
    },
  );
};

export const vehicleStatusLabelMapper = (status?: VehicleStatusTheme) => {
  if (!status) return '-';
  const label: Record<VehicleStatusTheme | string, string> = {
    [VehicleStatusTheme.GREEN]: 'Available',
    [VehicleStatusTheme.YELLOW]: 'Loaded',
    [VehicleStatusTheme.RED]: 'Delivering ',
    DEFAULT: '',
  };
  return label[status];
};

export const vehicleWeightLabelMapper = (status: VehicleStatusTheme) => {
  const label: Record<VehicleStatusTheme | string, string> = {
    [VehicleStatusTheme.RED]: 'Overweight',
    [VehicleStatusTheme.YELLOW]: 'Weight <= 10%',
  };
  return label[status];
};

export const vehicleVolumeLabelMapper = (status: VehicleStatusTheme) => {
  const label: Record<VehicleStatusTheme | string, string> = {
    [VehicleStatusTheme.RED]: 'Overvolume',
    [VehicleStatusTheme.YELLOW]: 'Volume <= 10%',
  };
  return label[status];
};
