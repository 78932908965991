import { Locale } from './locale.config';

const noDataLocaleConfig: Locale[] = [
  {
    en: 'No Payment Entry',
    id: 'Tidak ada entri pembayaran',
  },
  {
    en: 'Click on the button below to add payment',
    id: 'Klik tombol di bawah ini untuk menambahkan pembayaran',
  },
  {
    en: 'No Result Found',
    id: 'Hasil Tidak Ditemukan',
  },
  {
    en: 'There is no Shipper Order',
    id: 'Tidak ada Shipper Order',
  },
  {
    en: 'There is no Job Order',
    id: 'Tidak ada Perintah Kerja',
  },
  {
    en: 'There is no Hauling Order',
    id: 'Tidak ada Hauling Order',
  },
  {
    en: 'There is no Hauling Job Order',
    id: 'Tidak ada Hauling Job Order',
  },
  {
    en: 'There is no Shipper',
    id: 'Tidak ada Pengirim',
  },
  {
    en: 'There is no Driver',
    id: 'Tidak ada Sopir',
  },
  {
    en: 'There is no Schedule',
    id: 'Tidak ada Jadwal',
  },
  {
    en: 'There is no Activity Template',
    id: 'Tidak ada Templat Pengiriman',
  },
  {
    en: 'There is no Delivery Location',
    id: 'Tidak ada Lokasi Pengiriman',
  },
  {
    en: 'There is no Time Entry',
    id: 'Tidak ada Catatan Waktu',
  },
  {
    en: 'There are no submitted expenses',
    id: 'Tidak ada pengeluaran yang dikirim',
  },
  {
    en: "You don't have any selected Expenses that you want to approve",
    id: 'Anda tidak memiliki daftar Pengeluaran yang yang ingin disetujui',
  },
  {
    en: 'Try to select some Expenses from Job Order Detail page',
    id: 'Cobalah untuk memilih beberapa Pengeluaran di halaman Rincian Job Order',
  },
  {
    en: 'Go back to Job Order List page',
    id: 'Kembali ke halaman Daftar Job Order',
  },
  {
    en: "You don't have any selected Drivers that you want to remove",
    id: 'Anda tidak memiliki daftar Sopir yang yang ingin dihapus',
  },
  {
    en: 'Try to select some Drivers from Driver Contest page',
    id: 'Cobalah untuk memilih beberapa Sopir di halaman Driver Contest',
  },
  {
    en: 'Go back to Driver Contest page',
    id: 'Kembali ke halaman Driver Contest',
  },
  {
    en: "You don't have any Driver registered for Driver Contest",
    id: 'Anda tidak memiliki Sopir yang terdaftar di Driver Contest',
  },
  {
    en: "You don't have any imported Drivers that you want to register",
    id: 'Anda tidak memiliki Sopir yang diunggah untuk didaftarkan',
  },
  {
    en: 'Try to import some Drivers by clicking the Re-upload button, or you can click the button below',
    id: 'Unggah ulang Sopir dari halaman Sopir, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: 'Try registering Drivers by clicking the button on the top right, or you can click the button below',
    id: 'Daftarkan Sopir dengan mengklik tombol di kanan atas, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: 'Try a different search terms so we can show you some available Shipper Order',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Shipper Order yang tersedia',
  },
  {
    en: 'Try a different search terms so we can show you some available Shipper Order Candidate',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Calon Shipper Order yang tersedia',
  },
  {
    en: 'Try a different search terms so we can show you some available Job Order',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Perintah Kerja yang tersedia',
  },
  {
    en: 'Try a different search terms so we can show you some available Hauling Order',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Hauling Order yang tersedia',
  },
  {
    en: 'Try a different search terms so we can show you some available Hauling Job Order',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Hauling Job Order yang tersedia',
  },
  {
    en: 'Try a different search terms so we can show you some available Shipper',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Pengirim yang tersedia',
  },
  {
    en: 'Try a different search terms so we can show you some available Driver Contest',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Driver Contest yang tersedia',
  },
  {
    en: 'Try a different search terms so we can show you some available Driver',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Sopir yang tersedia',
  },
  {
    en: 'Try a different search terms so we can show you some available Schedule',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Jadwal yang tersedia',
  },
  {
    en: 'Try a different search terms so we can show you some available Delivery Location',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Lokasi Pengiriman yang tersedia',
  },
  {
    en: 'Try a different search terms so we can show you some available Activity Template',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Templat Pengiriman yang tersedia',
  },
  {
    en: 'Try a different search terms so we can show you some available Expenses',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Pengeluaran yang tersedia',
  },
  {
    en: 'Try a different filter terms so we can show you some available Delivery Time Record',
    id: 'Gunakan ketentuan yang lain untuk menampilkan Lembar Kerja Pengiriman yang tersedia',
  },
  {
    en: 'Try adding Shipper Order by clicking the button on the top right, or you can click the button below',
    id: 'Tambahkan Shipper Order dengan mengklik tombol di kanan atas, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: 'Try adding Job Order by clicking the button on the top right, or you can click the button below',
    id: 'Tambahkan Perintah Kerja dengan mengklik tombol di kanan atas, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: 'Try adding Hauling Order by clicking the button on the top right, or you can click the button below',
    id: 'Tambahkan Hauling Order dengan mengklik tombol di kanan atas, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: 'Try adding Shipper by clicking the button on the top right, or you can click the button below',
    id: 'Tambahkan Pengirim dengan mengklik tombol di kanan atas, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: 'Try adding Driver by clicking the button on the top right, or you can click the button below',
    id: 'Tambahkan Sopir dengan mengklik tombol di kanan atas, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: 'Try adding Schedule by clicking the button on the top right, or you can click the button below',
    id: 'Tambahkan Jadwal dengan mengklik tombol di kanan atas, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: 'Try adding Delivery Location by clicking the button on the top right, or you can click the button below',
    id: 'Tambahkan Lokasi Pengiriman dengan mengklik tombol di kanan atas, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: 'Try adding Activity Template by clicking the button on the top right, or you can click the button below',
    id: 'Tambahkan Templat Pengiriman dengan mengklik tombol di kanan atas, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: 'Try adding Time Entry by clicking the button on the top right, or you can click the button below',
    id: 'Tambahkan Catatan Waktu dengan mengklik tombol di kanan atas, atau Anda dapat mengklik tombol di bawah ini',
  },
  {
    en: 'The list will be updated after Driver submitted their expenses',
    id: 'Daftar akan diperbarui setelah Sopir mengirimkan pengeluarannya',
  },
  {
    en: 'Set Back to Default List',
    id: 'Atur Ulang ke List Awal',
  },
  {
    en: 'Create Shipper Order',
    id: 'Buat Shipper Order',
  },
  {
    en: 'Create Hauling Order',
    id: 'Buat Hauling Order',
  },
  {
    en: 'Create Hauling Job Order',
    id: 'Buat Hauling Job Order',
  },
  {
    en: 'Nothing more to load',
    id: 'Tidak ada lagi yang bisa dimuat',
  },
  {
    en: "This Driver don't have any Schedule",
    id: 'Sopir ini tidak memiliki Jadwal',
  },
  {
    en: "This Driver don't have any Schedule for Driver Contest",
    id: 'Sopir ini tidak memiliki Jadwal untuk Driver Contest',
  },
  {
    en: "This driver hasn't made any delivery",
    id: 'Sopir ini belum melakukan pengiriman apapun',
  },
  {
    en: 'The delivery time will appear here after driver finish delivering',
    id: 'Waktu pengiriman akan muncul di sini setelah sopir selesai melakukan pengiriman',
  },
];

export default noDataLocaleConfig;
