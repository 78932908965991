import { GoodsCell } from '../component/molecule';
import { SOGoodsColumnType } from '../component/molecule/SOActivityTable/SOActivityTable.molecule';
import { GoodsFormValues } from '../model/ShipperOrder.model';

const soActivityColumns: SOGoodsColumnType<GoodsFormValues>[] = [
  {
    label: 'Type',
    accessor: 'type',
    cell: GoodsCell.Type,
  },
  {
    label: 'Description',
    accessor: 'description',
    cell: GoodsCell.Description,
  },
  {
    label: 'Qty',
    accessor: 'quantity',
    align: 'right',
    cell: GoodsCell.Qty,
  },
  {
    label: 'Unit',
    accessor: 'uom',
    cell: GoodsCell.Uom,
  },
  {
    label: 'Weight (kg)',
    accessor: 'weight',
    align: 'right',
    cell: GoodsCell.Weight,
  },
  {
    label: 'Volume (m3)',
    accessor: 'volume',
    align: 'right',
    cell: GoodsCell.Volume,
  },
];

export default soActivityColumns;
