import React from 'react';
import tw from 'twin.macro';
import { Modal } from '..';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { Button, RootPortal } from '../../atom';
import {
  BodyFourteen,
  BodySixteen,
  HeadingFour,
} from '../../atom/Text/Text.atom';

const MobileContainer = tw.div`box-border fixed ml-auto left-2/4 -translate-x-2/4  top-[42%] max-w-[350px] w-[calc(100vw - 32px) ] bg-white rounded p-4`;
const Container = tw.div`w-screen h-screen fixed top-0 left-0 z-50 overflow-hidden`;
const Overlay = tw.div`bg-black  bg-opacity-75 w-screen h-screen top-0 left-0 overflow-hidden`;

type Props = {
  handleDisclaimerClick: VoidFunction;
  handleCloseDisclaimer: VoidFunction;
};

export default function SOTrackingDisclaimerModal({
  handleCloseDisclaimer,
  handleDisclaimerClick,
}: Props) {
  const translator = useTranslator();
  const { isMobile } = useScreenDetection();

  if (isMobile) {
    return (
      <RootPortal wrapperId="so-disclaimer-dialog-mobile">
        <Container>
          <MobileContainer>
            <div tw="flex flex-col gap-8">
              <div tw="flex flex-col gap-4">
                <HeadingFour>
                  {translator.translate('Share tracking number')}
                </HeadingFour>
                <BodyFourteen tw="text-grey-two">
                  {translator.translate(
                    'If you share this tracking number, anyone can see your Job Order details.',
                  )}
                </BodyFourteen>
              </div>
              <div tw="flex gap-4 ml-auto">
                <Button.Outlined onClick={handleCloseDisclaimer}>
                  {translator.translate('Back')}
                </Button.Outlined>
                <Button.Solid onClick={handleDisclaimerClick}>
                  {translator.translate('I Understand')}
                </Button.Solid>
              </div>
            </div>
          </MobileContainer>
          <Overlay onClick={handleCloseDisclaimer} />
        </Container>
      </RootPortal>
    );
  }

  return (
    <Modal.Regular
      onOverlayClick={handleCloseDisclaimer}
      title={translator.translate('Share tracking number')}
      callAction={{
        action: handleDisclaimerClick,
        label: translator.translate('I Understand'),
      }}
      cancelAction={{
        action: handleCloseDisclaimer,
        label: translator.translate('Back'),
      }}
    >
      <div tw="my-4 ">
        <BodySixteen>
          {translator.translate(
            'If you share this tracking number, anyone can see your Job Order details.',
          )}
        </BodySixteen>
      </div>
    </Modal.Regular>
  );
}
