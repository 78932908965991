import { FormikErrors } from 'formik';
import { useMemo } from 'react';
import tw from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { IOrganizationSetting } from '../../../model/Organization.model';
import { FormItem } from '../../../types/input.type';

interface Props {
  values: IOrganizationSetting;
  setFieldValue: (
    field: string,
    value?: boolean,
  ) => Promise<void> | Promise<FormikErrors<IOrganizationSetting>>;
}

export type UseSettingGeofenceHookObj = ReturnType<typeof useSettingGeofence>;

export default function useSettingGeofence({ values, setFieldValue }: Props) {
  const { translate } = useTranslator();
  // #region FORM
  const formData = useMemo(
    (): FormItem[] => [
      {
        id: 'isGeofenceStatusEnabled',
        label: translate('Status based on Site Marker'),
        type: 'switch',
        description: translate(
          'Site Marker helps your organization to automatically change status of your Shipper Order based on site radius. This will applied to newly created Job Order.',
        ),
        values: values.isGeofenceStatusEnabled,
        onChange: (val) => {
          void setFieldValue('isGeofenceStatusEnabled', val);
        },
        containerStyle: tw`flex-1 justify-between`,
        labelStyle: tw`text-black`,
        descriptionLabelStyle: tw`text-grey-two`,
        wrapperStyle: tw`flex flex-1 pr-2`,
      },
    ],
    [values.isGeofenceStatusEnabled, setFieldValue, translate],
  );
  // #endregion

  return {
    formData,
  };
}
