import React from 'react';
import tw from 'twin.macro';
import { FormItem } from '../../../types/input.type';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';

interface Props {
  formData: FormItem[];
}

const Container = tw.div`flex-1`;
const ContainerForm = tw.div`flex-1 flex border-b-[1px] border-b-beige-lines`;

export default function SettingsForm({ formData }: Props) {
  return (
    <Container>
      {formData.map((item) => (
        <ContainerForm key={`settingForm-${item.id}`}>
          {/* // type already described inside component */}
          <ComposedFormField {...item} />
        </ContainerForm>
      ))}
    </Container>
  );
}
