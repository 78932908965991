import { coreModuleName } from '@reduxjs/toolkit/dist/query/core/module';
import { reactHooksModuleName } from '@reduxjs/toolkit/dist/query/react/module';
import { Api, createApi } from '@reduxjs/toolkit/query/react';
import { generateTagsArray } from '../util/helper.util';
import baseQuery, { BaseQuery } from './api.baseQuery';
import endpoint, { Endpoint } from './api.endpoint';
import { activityTemplateTagTypes } from './endpoint/activityTemplate/activityTemplate.endpoint';
import { authTagTypes } from './endpoint/auth/auth.endpoint';
import { callCenterTagTypes } from './endpoint/callCenter/callCenter.endpoint';
import { dashboardTagTypes } from './endpoint/dashboard/dashboard.endpoint';
import { driverTagTypes } from './endpoint/driver/driver.endpoint';
import { drivingContestTagTypes } from './endpoint/drivingContest/drivingContest.endpoint';
import { haulingJobOrderTagTypes } from './endpoint/haulingJobOrder/haulingJobOrder.endpoint';
import { haulingOrderTagTypes } from './endpoint/haulingOrder/haulingOrder.endpoint';
import { jobOrderTagTypes } from './endpoint/jobOrder/jobOrder.endpoint';
import { deliveryLocationTagTypes } from './endpoint/location/location.endpoint';
import { organizationTagTypes } from './endpoint/organization/organization.endpoint';
import { runnerEventTagTypes } from './endpoint/runnerEvent/runnerEvent.endpoint';
import { scheduleTagTypes } from './endpoint/schedule/schedule.endpoint';
import { shipperTagTypes } from './endpoint/shipper/shipper.endpoint';
import { shipperOrderTagTypes } from './endpoint/shipperOrder/shipperOrder.endpoint';
import { vehicleTagTypes } from './endpoint/vehicle/vehicle.endpoint';

type ApiModule = Api<
  BaseQuery,
  Endpoint,
  'api',
  string,
  typeof coreModuleName | typeof reactHooksModuleName
>;

const api: ApiModule = createApi({
  reducerPath: 'api',
  tagTypes: generateTagsArray(
    dashboardTagTypes,
    scheduleTagTypes,
    vehicleTagTypes,
    shipperTagTypes,
    authTagTypes,
    activityTemplateTagTypes,
    organizationTagTypes,
    callCenterTagTypes,
    deliveryLocationTagTypes,
    driverTagTypes,
    shipperOrderTagTypes,
    jobOrderTagTypes,
    haulingJobOrderTagTypes,
    haulingOrderTagTypes,
    runnerEventTagTypes,
    drivingContestTagTypes,
  ),
  baseQuery,
  endpoints: endpoint,
});

export default api;
