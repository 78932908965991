import React from 'react';
import tw from 'twin.macro';
import { UseJOAssignmentFormHookObj } from '../../../hook/joAssignmentForm/useJOAssignmentForm.hook';
import { UseJOHeaderFormObj } from '../../../hook/jobOrderCreate/useJOHeaderForm.hook';
import { CardContainer } from '../../atom';
import {
  BlockerModal,
  CardAction,
  CardContent,
  CardHeader,
  DrawerPortal,
} from '../../molecule';
import ComposedFormField from '../ComposedFormField/ComposedFormField.organism';
import JOAssignmentForm from '../JOAssignmentForm/JOAssignmentForm.organism';
import JobOrderHeaderAssignmentForm from '../JOHeaderAssignmentForm/JOHeaderAssignmentForm.organism';

const DrawerContainerStyle = tw`md:w-[616px]`;
const Container = tw.div`w-[720px] mx-auto py-10`;
const ContainerForm = tw.div``;

type Props = {
  isEdit?: boolean;
  joHeader: UseJOHeaderFormObj;
  joAssignmentHookObj: UseJOAssignmentFormHookObj;
  submitText?: string;
  formTitle?: string;
  subHeader?: React.ReactNode;
  translate: (key: string) => string;
  handleBack(): void;
  handleSubmit?(): void;
};

export default function JobOrderHeaderForm({
  isEdit,
  joHeader,
  joAssignmentHookObj,
  submitText,
  formTitle,
  subHeader,
  translate,
  handleBack,
  handleSubmit,
}: Props) {
  return (
    <>
      <Container>
        <CardContainer>
          <CardHeader
            handleBack={handleBack}
            title={translate(formTitle || 'Create Job Order')}
            subHeader={subHeader}
          />
          <CardContent>
            {joHeader.joDateNumberFormData.map((item) => (
              <ContainerForm key={`JO-header-form-${item.id}`}>
                {/* type already described inside component */}
                <ComposedFormField {...item} />
              </ContainerForm>
            ))}
            <JobOrderHeaderAssignmentForm
              isEdit={isEdit}
              isUseAssignment={joHeader.isUseAssignment}
              formData={joHeader.vehicleDriverFormData}
              selectedSchedule={joHeader.selectedSchedule}
              isScheduleFieldDisplayed={joHeader.isScheduleFieldDisplayed}
              isVehicleDriverFormDisplayed={
                joHeader.isVehicleDriverFormDisplayed
              }
              handleClickSelectionSchedule={() =>
                joAssignmentHookObj.setSidebarDisplayed((v) => !v)
              }
              handleSelectAssignmentType={joHeader.handleChangeAssignmentType}
            />
            {joHeader.travelExpenseSealNumberFormData.map((item) => (
              <ContainerForm key={`JO-header-form-${item.id}`}>
                {/* type already described inside component */}
                <ComposedFormField {...item} />
              </ContainerForm>
            ))}
          </CardContent>
          <CardAction
            isFormFooter
            isActionDisabled={!handleSubmit || joHeader.disableSubmit}
            handleAction={handleSubmit}
            title={translate(submitText || 'Next')}
          />
        </CardContainer>
      </Container>

      <DrawerPortal
        isOpen={joAssignmentHookObj.isSidebarDisplayed}
        handleClose={() => {
          joAssignmentHookObj.handleSetSidebarDisplayed(false);
        }}
        contentContainerStyle={DrawerContainerStyle}
      >
        <JOAssignmentForm joAssignmentHookObj={joAssignmentHookObj} />
      </DrawerPortal>

      {joAssignmentHookObj.blocker.isModalVisible && (
        <BlockerModal blocker={joAssignmentHookObj.blocker} />
      )}
    </>
  );
}
