import format from 'date-fns/format';
import * as yup from 'yup';
import {
  JOAssignmentBy,
  JODateAssignmentFormType,
  JODriverAssignmentFormType,
  JOVehicleAssignmentFormType,
} from '../constant/JOAssignment.constant';
import { UseTranslator } from '../hook/useTranslator.hook';
import { JobOrderForm } from '../model/JobOrder.model';
import { Schedule } from '../model/Schedule.model';

export const joVehicleAssignmentInitialValues: JOVehicleAssignmentFormType = {
  vehicleId: undefined,
  vehicleOption: undefined,
  schedule: undefined,
  scheduleId: undefined,
};
export const joDriverAssignmentInitialValues: JODriverAssignmentFormType = {
  driverId: undefined,
  driverOption: undefined,
  schedule: undefined,
  scheduleId: undefined,
};
export const joDateAssignmentInitialValues: JODateAssignmentFormType = {
  from: undefined,
  to: undefined,
  schedule: undefined,
  scheduleId: undefined,
};

export const JOVehicleAssignmentFormValidationSchema = (
  translator: UseTranslator,
) =>
  yup.object().shape({
    vehicleId: yup
      .string()
      .required(translator.translate('Vehicle is required.')),
    scheduleId: yup
      .string()
      .required(translator.translate('Schedule is required.')),
  });

export const JODriverAssignmentFormValidationSchema = (
  translator: UseTranslator,
) =>
  yup.object().shape({
    driverId: yup
      .string()
      .required(translator.translate('Driver is required.')),
    scheduleId: yup
      .string()
      .required(translator.translate('Schedule is required.')),
  });

export const JODateAssignmentFormValidationSchema = (
  translator: UseTranslator,
) =>
  yup.object().shape({
    from: yup
      .date()
      .nullable()
      .required(translator.translate('Start Date is Required.')),
    to: yup
      .date()
      .nullable()
      .required(translator.translate('End Date is Required.')),
    scheduleId: yup
      .string()
      .required(translator.translate('Schedule is required.')),
  });

export const joAssignmentToJOFormFormatter = ({
  joFormValues,
  schedule,
}: {
  joFormValues: JobOrderForm;
  schedule?: Schedule;
}): JobOrderForm => {
  const start = format((schedule?.from || 0) * 1000, 'dd MMM yyyy');
  const end = format((schedule?.to || 0) * 1000, 'dd MMM yyyy');

  return {
    ...joFormValues,
    scheduleOption: { label: `${start} - ${end}`, value: '' },
    schedule,
    driverId: schedule?.driver.id,
    vehicleId: schedule?.vehicle.id,
    driverOption: {
      label: schedule?.driver.fullName || '-',
      value: schedule?.driver.id || '-',
    },
    vehicleOption: schedule?.vehicle,
  };
};

/**
 * @param  {JOAssignmentBy} assignmentBy
 * @returns string
 *
 * @summary map through `JOAssignmentBy` enum to get the `assignmentBy` label
 */
export function mapJOAssignmentByToLabel(
  assignmentBy?: JOAssignmentBy,
): string {
  switch (assignmentBy) {
    case JOAssignmentBy.DATE:
      return 'Schedule by Date';
    case JOAssignmentBy.DRIVER:
      return 'Schedule by Driver';
    default:
      return 'Schedule by Vehicle';
  }
}
