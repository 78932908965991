import { Locale } from './locale.config';

const statusLocaleConfig: Locale[] = [
  {
    en: 'In Process',
    id: 'Dalam Proses',
  },
  {
    en: 'In Transit',
    id: 'Sedang Transit',
  },
  {
    en: 'Transiting',
    id: 'Perjalanan Transit',
  },
  {
    en: 'Assigned',
    id: 'Ditugaskan',
  },
  {
    en: 'Deleted',
    id: 'Dihapus',
  },
  {
    en: 'Reserved',
    id: 'Diatur',
  },
  {
    en: 'Draft',
    id: 'Draf',
  },
  {
    en: 'Delivering',
    id: 'Dalam Perjalanan',
  },
  {
    en: 'Delivered',
    id: 'Terkirim',
  },
  {
    en: 'Cancelled',
    id: 'Dibatalkan',
  },
];

export default statusLocaleConfig;
