import React from 'react';
import tw from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { ActivityTemplate } from '../../../model/ActivityTemplate.model';
import { promiseToVoid } from '../../../util/helper.util';
import { UseActivityTemplateList } from '../../../view/ShipperOrderCreate/hooks/useSOCreateActivityTemplateList.hook';
import { UseSOCreateActivityTemplateTable } from '../../../view/ShipperOrderCreate/hooks/useSOCreateActivityTemplateTable.hook';
import { Button, Container, Icon, TableNoData } from '../../atom';
import { TanstackTable } from '../../molecule';
import ContainerHeader from '../../molecule/ContainerHeader/ContainerHeader.molecule';
import ActivityTemplateExpandedSection from '../ActivityTemplateExpandedSection/ActivityTemplateExpandedSection.organism';

export interface SOActivitySelectionProps {
  translator: UseTranslator;
  soATList: UseActivityTemplateList;
  soActivityTemplateTable: UseSOCreateActivityTemplateTable;
}

const ActionContainer = tw.div`bg-white p-5 rounded-b-lg border-t-2 border-t-beige-lines`;
const ActionButtons = tw.div`flex justify-end space-x-5`;

export default function SOActivitySelection({
  translator,
  soATList,
  soActivityTemplateTable,
}: SOActivitySelectionProps) {
  return (
    <Container>
      <ContainerHeader
        search={soATList.search}
        debouncedSearch={soATList.debouncedSearchData}
        searchPlaceholder={soATList.searchPlaceholder}
        title={soATList.title}
        handleSearch={(data?: string) => soATList.setSearch(data || '')}
        onBack={soActivityTemplateTable.handleBackActivityTemplate}
      />
      <TanstackTable<ActivityTemplate>
        table={soActivityTemplateTable.table}
        onPageLimitClick={soActivityTemplateTable.handlePageLimitClick}
        onPageClick={soActivityTemplateTable.handlePageClick}
        noDataPage={
          <TableNoData
            icon={
              soATList.noResultFound ? (
                <Icon.NoResultFound />
              ) : (
                <Icon.NoDataFound />
              )
            }
            titleLabel={translator.translate(
              soATList.noResultFound
                ? 'No Result Found'
                : 'There is no Activity Template',
            )}
            descriptionLabel={translator.translate(
              soATList.noResultFound
                ? 'Try a different search terms so we can show you some available Activity Template'
                : "Selected shipper doesn't have Activity Template",
            )}
            actionLabel={translator.translate(
              soATList.noResultFound
                ? 'Clear search'
                : 'Create Activity Template',
            )}
            onClickAction={soATList.handleAddAT}
          />
        }
        expandedPage={
          <ActivityTemplateExpandedSection
            expandedData={
              soActivityTemplateTable.expandedData as ActivityTemplate
            }
            translate={(key) => translator.translate(key)}
          />
        }
      />
      {soActivityTemplateTable.selectedDataRadio && (
        <ActionContainer>
          <ActionButtons>
            <Button.Solid
              type="button"
              onClick={() =>
                promiseToVoid([
                  soActivityTemplateTable.handleSubmitActivityTemplate(),
                ])
              }
            >
              {translator.translate('Submit')}
            </Button.Solid>
          </ActionButtons>
        </ActionContainer>
      )}
    </Container>
  );
}
