import React from 'react';

export default function ChevronRounded({
  fill = 'none',
  stroke = 'currentColor',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  style,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2"
    >
      <path
        d="M9 19L14.5918 14.1072C15.8667 12.9917 15.8667 11.0083 14.5918 9.89279L9 5"
        stroke={stroke}
      />
    </svg>
  );
}
