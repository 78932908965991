import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
  QueryDefinition,
} from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { RootState } from '../app/store/store.app';
import { SnackbarTheme } from '../component/molecule/Snackbar/Snackbar.molecule';
import envConfig from '../config/env/env.config';
import { ErrorCodes } from '../constant';
import { LogoutReason, sessionAction } from '../store/session.store';
import { settingAction } from '../store/setting.store';
import { snackbarAction } from '../store/snackbar.store';
import { toCamelCase, toSnakeCase, toTranslate } from '../util/helper.util';
import { ApiErrorResponse, ApiResponse } from './api.endpoint';
import { captureError } from './errorMonitoring/errorMonitoring.service';

export type BaseQuery = BaseQueryFn<
  string | FetchArgs | undefined,
  unknown,
  FetchBaseQueryError
>;
export type QD<R, S> = QueryDefinition<R, BaseQuery, string, S, 'api'>;
export type MD<R, S> = MutationDefinition<R, BaseQuery, string, S, 'api'>;
export type Builder = EndpointBuilder<BaseQuery, string, 'api'>;

/**
 * HTTP headers must be adjusted based on the API requirements.
 * @param headers
 * @param api
 * @returns
 */
export const prepareHeaders = (
  headers: Headers,
  api: Pick<
    BaseQueryApi,
    'getState' | 'extra' | 'endpoint' | 'type' | 'forced'
  >,
): Headers => {
  const isNeedCustomToken = ['checkAuth', 'verifyToken'].includes(api.endpoint);
  const { token } = (api.getState() as RootState).session;
  if (api.endpoint === 'authTest') return headers;
  if (token && !isNeedCustomToken) {
    headers.set('authorization', `Bearer ${token}`);
  }
  if (isNeedCustomToken) {
    headers.set('authorization', `Bearer ${envConfig.ktbVisaBearerToken}`);
  }
  return headers;
};

/**
 * Adjust API_BASE_URL in environment variable file
 */
export const base = fetchBaseQuery({
  baseUrl: envConfig.apiBaseUrl,
  prepareHeaders,
});

/**
 * Base query of the API service.
 * If response is 401 It will try to get new token and refetch the query.
 * To avoid multiple failed request, it used mutex to lock the process and unlock it after renew token completed.
 * @param args
 * @param api
 * @param extraOptions
 * @returns
 */
async function pathNameListener(
  onPathnameChange: (pathname: string) => Promise<void> | void,
) {
  const handlePopState = async () => {
    const name = window.location.pathname;
    await onPathnameChange(name);
  };
  window.addEventListener('popstate', handlePopState);

  await handlePopState();

  return () => {
    window.removeEventListener('popstate', handlePopState);
  };
}

const mutex = new Mutex();
const baseQuery: BaseQuery = async (args, api, extraOptions) => {
  const requestArgs: string | FetchArgs = ['refreshToken'].includes(
    api.endpoint,
  )
    ? (args as string | FetchArgs)
    : toSnakeCase(args);

  let result = await base(requestArgs, api, extraOptions);

  if (!window.navigator.onLine) {
    const { currentLanguage } = (api.getState() as RootState).setting;

    api.dispatch(
      snackbarAction.show({
        type: SnackbarTheme.warning,
        message: toTranslate('No internet connection', currentLanguage),
      }),
    );
  }

  if (result.error && result?.error?.status !== 401) {
    captureError(`API error: ${api.endpoint}`, args, result);
    await pathNameListener(async (pathname) => {
      if (
        (result?.error?.data as ApiErrorResponse)?.error?.code ===
          ErrorCodes.ORGANIZATION_IN_RESETTING &&
        pathname !== '/reset'
      ) {
        if (mutex.isLocked()) {
          await mutex.waitForUnlock();
          result = await base(args || '', api, extraOptions);
        } else {
          const release = await mutex.acquire();
          try {
            api.dispatch(settingAction.changeIsResetSetting(true));
            window.location.replace('/reset');
          } finally {
            release();
          }
        }
      }
    });
  }

  if (
    result?.error?.status === 400 &&
    (result?.error?.data as ApiErrorResponse)?.error?.code ===
      ErrorCodes.AUTH_SESSION_NOT_FOUND
  ) {
    if (mutex.isLocked()) {
      await mutex.waitForUnlock();
      result = await base(requestArgs, api, extraOptions);
    } else {
      const release = await mutex.acquire();
      try {
        const token = (api.getState() as RootState).session.token;

        const req = await fetch(`${envConfig.apiBaseUrl}/v1/auth.test`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const reqJSON: ApiResponse = toCamelCase(await req.json());

        if (reqJSON.ok) {
          result = await base(requestArgs, api, extraOptions);
        } else {
          const gsid = (api.getState() as RootState).session.globalSessionID;
          await fetch(`${envConfig.apiBaseUrl}/v1/auth.revoke`, {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          });
          api.dispatch(
            sessionAction.logout({
              type: LogoutReason.REVOKE,
              token: gsid,
            }),
          );
        }
      } finally {
        release();
      }
    }
  }

  if (result?.error?.status === 401) {
    if (mutex.isLocked()) {
      await mutex.waitForUnlock();
      result = await base(args || '', api, extraOptions);
    } else {
      const release = await mutex.acquire();
      try {
        const gsid = (api.getState() as RootState).session.globalSessionID;

        api.dispatch(
          sessionAction.logout({ type: LogoutReason.REVOKE, token: gsid }),
        );
      } finally {
        release();
      }
    }
  }

  return toCamelCase(result);
};

export default baseQuery;
