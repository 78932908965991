import React from 'react';

export default function OnboardingDriver({
  fill = 'none',
  width = 138,
  height = 138,
  viewBox = '0 0 138 138',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#EBEBEB"
        d="M138 105.542H0v.069h138v-.069zM47.809 107.303H41.41v.069h6.398v-.069zM126.62 108.783h-15.641v.069h15.641v-.069zM27.431 108.783h-15.64v.069h15.64v-.069zM65.412 93.233H12.119a1.576 1.576 0 01-1.573-1.576V16.742a1.576 1.576 0 011.573-1.562h53.293a1.576 1.576 0 011.576 1.576v74.901a1.576 1.576 0 01-1.576 1.576zM12.119 15.235a1.507 1.507 0 00-1.504 1.507v74.915a1.507 1.507 0 001.504 1.507h53.293a1.513 1.513 0 001.507-1.507V16.742a1.513 1.513 0 00-1.507-1.507H12.119zM125.114 93.233H71.818a1.582 1.582 0 01-1.576-1.576V16.742a1.578 1.578 0 011.576-1.562h53.296a1.577 1.577 0 011.57 1.562v74.915c.001.207-.04.411-.119.602a1.568 1.568 0 01-1.451.974zM71.818 15.235a1.512 1.512 0 00-1.507 1.507v74.915a1.512 1.512 0 001.507 1.507h53.296a1.514 1.514 0 001.507-1.507V16.742a1.516 1.516 0 00-1.507-1.507H71.818z"
      />
      <path
        fill="#F5F5F5"
        d="M27.241 35.993h22.991a1.757 1.757 0 011.761 1.761v67.725H25.497V37.751a1.76 1.76 0 011.744-1.758z"
      />
      <path
        fill="#EBEBEB"
        d="M31.1 40.098h-3.417v7.606h3.416v-7.606zM49.785 40.098h-3.417v7.606h3.417v-7.606zM37.326 40.098H33.91v7.606h3.417v-7.606zM43.556 40.098h-3.417v7.606h3.417v-7.606zM31.1 52.106h-3.417v7.607h3.416v-7.607zM49.785 52.106h-3.417v7.607h3.417v-7.607zM37.326 52.106H33.91v7.607h3.417v-7.607zM43.556 52.106h-3.417v7.607h3.417v-7.607zM31.1 64.112h-3.417v7.607h3.416v-7.607zM49.785 64.112h-3.417v7.607h3.417v-7.607zM37.326 64.112H33.91v7.607h3.417v-7.607zM43.556 64.112h-3.417v7.607h3.417v-7.607zM31.1 76.121h-3.417v7.607h3.416V76.12zM49.785 76.121h-3.417v7.607h3.417V76.12zM37.326 76.121H33.91v7.607h3.417V76.12zM43.556 76.121h-3.417v7.607h3.417V76.12zM31.1 88.127h-3.417v7.607h3.416v-7.607zM49.785 88.127h-3.417v7.607h3.417v-7.607zM37.326 88.127H33.91v7.607h3.417v-7.607zM43.556 88.127h-3.417v7.607h3.417v-7.607z"
      />
      <path
        fill="#F5F5F5"
        d="M108.727 42.306h16.461a1.435 1.435 0 011.432 1.432v61.741H107.3V43.741a1.433 1.433 0 011.427-1.435z"
      />
      <path
        fill="#EBEBEB"
        d="M112.862 46.04h-3.105v6.913h3.105V46.04zM118.525 46.04h-3.105v6.913h3.105V46.04zM124.186 46.04h-3.105v6.913h3.105V46.04zM112.862 56.956h-3.105v6.913h3.105v-6.913zM118.525 56.956h-3.105v6.913h3.105v-6.913zM124.186 56.956h-3.105v6.913h3.105v-6.913zM112.862 67.874h-3.105v6.914h3.105v-6.914zM118.525 67.874h-3.105v6.914h3.105v-6.914zM124.186 67.874h-3.105v6.914h3.105v-6.914zM112.862 78.787h-3.105v6.914h3.105v-6.914zM118.525 78.787h-3.105v6.914h3.105v-6.914zM124.186 78.787h-3.105v6.914h3.105v-6.914zM112.862 89.706h-3.105v6.913h3.105v-6.913zM118.525 89.706h-3.105v6.913h3.105v-6.913zM124.186 89.706h-3.105v6.913h3.105v-6.913z"
      />
      <path
        fill="#F5F5F5"
        d="M71.017 24.636h26.74a2.048 2.048 0 012.047 2.048v78.795H68.972V26.684a2.048 2.048 0 012.045-2.048z"
      />
      <path
        fill="#EBEBEB"
        d="M75.505 29.414h-3.974v8.848h3.974v-8.848zM97.243 29.414h-3.974v8.848h3.974v-8.848zM82.75 29.414h-3.974v8.848h3.974v-8.848zM89.998 29.414h-3.975v8.848h3.975v-8.848zM75.505 43.385h-3.974v8.848h3.974v-8.848zM97.243 43.385h-3.974v8.848h3.974v-8.848zM82.75 43.385h-3.974v8.848h3.974v-8.848zM89.998 43.385h-3.975v8.848h3.975v-8.848zM75.505 57.356h-3.974v8.848h3.974v-8.848zM97.243 57.356h-3.974v8.848h3.974v-8.848zM82.75 57.356h-3.974v8.848h3.974v-8.848zM89.998 57.356h-3.975v8.848h3.975v-8.848zM75.505 71.324h-3.974v8.849h3.974v-8.849zM97.243 71.324h-3.974v8.849h3.974v-8.849zM82.75 71.324h-3.974v8.849h3.974v-8.849zM89.998 71.324h-3.975v8.849h3.975v-8.849zM75.505 85.293h-3.974v8.848h3.974v-8.849zM97.243 85.293h-3.974v8.848h3.974v-8.849zM82.75 85.293h-3.974v8.848h3.974v-8.849zM89.998 85.293h-3.975v8.848h3.975v-8.849z"
      />
      <path
        fill="#E6E6E6"
        d="M68.97 53.591H51.983v51.891H68.97v-51.89zM107.312 59.147h-7.505v46.332h7.505V59.147zM25.48 105.479H10.615V83.816l14.865-5.029v26.692z"
      />
      <path
        fill="#F5F5F5"
        d="M69 118.007c29.555 0 53.514-1.964 53.514-4.386s-23.96-4.386-53.514-4.386c-29.555 0-53.514 1.964-53.514 4.386s23.96 4.386 53.514 4.386z"
      />
      <path
        fill="#263238"
        d="M82.16 96.302h-4.692c-2.443 0-4.416 3.54-4.416 7.91 0 4.369 1.979 7.907 4.416 7.907h4.692c2.442 0 4.416-3.541 4.416-7.907s-1.974-7.91-4.416-7.91z"
      />
      <mask
        id="mask0_9779_9287"
        style={{ maskType: 'luminance' }}
        width="14"
        height="17"
        x="73"
        y="96"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M82.16 96.302h-4.692c-2.443 0-4.416 3.54-4.416 7.91 0 4.369 1.979 7.907 4.416 7.907h4.692c2.442 0 4.416-3.541 4.416-7.907s-1.974-7.91-4.416-7.91z"
        />
      </mask>
      <g mask="url(#mask0_9779_9287)">
        <path
          fill="#fff"
          d="M87.68 96.302h-4.692c-2.443 0-4.416 3.54-4.416 7.91 0 4.369 1.979 7.907 4.416 7.907h4.692c2.442 0 4.416-3.541 4.416-7.907s-1.974-7.91-4.416-7.91z"
          opacity="0.05"
        />
      </g>
      <path
        fill="#EECD4A"
        d="M120.168 97.996l-82.163-.058V45.457h82.163v52.54z"
      />
      <path
        fill="#263238"
        d="M120.168 45.457H64.482v52.537h55.686V45.457z"
        opacity="0.3"
      />
      <path
        fill="#fff"
        d="M64.482 45.457H38.005v53.574h26.477V45.457z"
        opacity="0.2"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M38.204 47.897h81.964M38.204 50.169h81.964M38.204 52.437h81.964M38.204 54.706h81.964M38.204 56.977h81.964M38.204 59.246h81.964M38.204 61.515h81.964M38.204 63.783h81.964M38.204 66.055h81.964M38.204 68.324h81.964M38.204 70.592h81.964M38.204 72.864h81.964M38.204 75.132h81.964M38.204 77.401h81.964M38.204 79.67h81.964M38.204 81.941h81.964M38.204 84.21h81.964M38.204 86.479h81.964M38.204 88.75h81.964M38.204 91.019h81.964M38.204 93.288h81.964M38.204 95.56h81.964"
        opacity="0.1"
      />
      <path
        fill="#263238"
        d="M22.928 97.334h-4.342c-2.263 0-4.096 3.279-4.096 7.325s1.833 7.322 4.096 7.322h4.342c2.263 0 4.095-3.279 4.095-7.322s-1.832-7.325-4.095-7.325z"
      />
      <path
        fill="#fff"
        d="M28.45 97.334h-4.344c-2.263 0-4.096 3.279-4.096 7.325s1.833 7.322 4.096 7.322h4.344c2.26 0 4.096-3.279 4.096-7.322s-1.835-7.325-4.096-7.325z"
        opacity="0.05"
      />
      <path
        fill="#263238"
        d="M20.512 83.029H18.46c-.403.014-.734-.417-.742-.963a.89.89 0 010-.171l.552-6.202c.069-.638.463-1.104.938-1.134h2.073c.4 0 .731.422.74.968v.166l-.553 6.202c-.082.629-.49 1.118-.955 1.134z"
      />
      <path
        fill="#EECD4A"
        d="M22.566 62.544l-3.96 21.36 43.119 16.891-.3-42.096-9.484-.276H25.964c-1.563-.013-2.94 1.665-3.398 4.121z"
      />
      <path
        fill="#EECD4A"
        d="M54.648 65.98c-1.609-.071-3.052 1.248-3.524 3.216L47.86 82.863h-6.278l-.343 18.031h20.653"
      />
      <path
        fill="#263238"
        d="M23.184 81.715c-.632 0-1.143-.812-1.143-1.81 0-.175.017-.349.05-.52l2.371-12.54c.199-1.041.806-1.754 1.493-1.75h22.682c.245 0 .447.314.447.706 0 .07-.006.139-.02.207-.496 2.583-2.11 11.142-2.729 14.415-.144.768-.59 1.292-1.104 1.292H23.184z"
      />
      <path
        fill="#EECD4A"
        d="M44.055 87.274c0 .22-.016.44-.047.657l-.118.809-.07.477-.507 3.489-.828 5.671-.23 1.577H10.16v-1.576l.022-5.672.03-3.392v-1.38c-.276-1.822.604-3.588 1.946-3.955a1.8 1.8 0 01.483-.064h28.941c1.367-.01 2.473 1.501 2.473 3.359z"
      />
      <path
        fill="#263238"
        d="M60.37 66.792v13.444c0 .814-.417 1.476-.933 1.479h-7.844c-.516 0-.933-.663-.933-1.48 0-.133.011-.266.033-.397l2.418-13.745c.116-.66.502-1.104.933-1.079l5.426.298c.502.03.9.685.9 1.48z"
      />
      <path
        fill="#fff"
        d="M36.415 65.094h-4.333l-4.976 16.621h4.336l4.973-16.62zM39.788 65.094h-2.07l-4.973 16.621h2.067l4.976-16.62z"
        opacity="0.1"
      />
      <path fill="#000" d="M52.371 92.706v5.671H10.165l.022-5.671h42.184z" />
      <path
        fill="#fff"
        d="M46.492 92.706H42.59l-7.02 4.123v1.548h16.8v-5.671h-5.878zM17.44 96.829v1.548h-7.275l.022-5.671h.238l7.015 4.123z"
      />
      <path
        fill="#263238"
        d="M61.744 100.894l-.32-42.194-5.796-.276c-1.446-.072-3.116 1.609-3.538 3.557l-4.201 21.923h-1.637c-1.838 0-3.097 1.339-3.094 2.76l.025 14.236h18.561"
        opacity="0.3"
      />
      <path
        fill="#fff"
        d="M43.947 88.938a.373.373 0 01-.127.276.362.362 0 01-.248.097h-33.36v-.75H43.58a.374.374 0 01.318.176.38.38 0 01.05.201z"
      />
      <path
        fill="#263238"
        d="M16.096 88.563v.75h-5.895l.003-.75h5.892zM43.948 88.938a.373.373 0 01-.127.276.362.362 0 01-.249.097H35.48v-.75h8.092a.374.374 0 01.376.377zM121.642 99.36v.149a1.38 1.38 0 01-1.38 1.38H10.634a1.379 1.379 0 01-1.38-1.38v-.149a1.38 1.38 0 011.38-1.38h109.628a1.38 1.38 0 011.38 1.38z"
      />
      <path fill="#000" d="M34.945 99.114h-16.34v.798h16.34v-.798z" />
      <path
        fill="#fff"
        d="M14.258 99.512a.456.456 0 11-.911 0 .456.456 0 01.912 0zM38.847 99.512a.456.456 0 11-.911 0 .456.456 0 01.911 0z"
      />
      <path
        fill="#000"
        d="M52.051 104.033c0-4.392 1.99-7.952 4.446-7.952h-4.716c-2.454 0-4.444 3.56-4.444 7.952 0 4.391 1.99 7.948 4.444 7.948h4.716c-2.456.009-4.446-3.549-4.446-7.948z"
      />
      <path
        fill="#263238"
        d="M52.051 104.033c0-4.392 1.99-7.952 4.446-7.952h-4.716c-2.454 0-4.444 3.56-4.444 7.952 0 4.391 1.99 7.948 4.444 7.948h4.716c-2.456.009-4.446-3.549-4.446-7.948z"
        opacity="0.7"
      />
      <path
        fill="#263238"
        d="M56.497 111.992c2.456 0 4.447-3.56 4.447-7.951 0-4.392-1.991-7.952-4.447-7.952s-4.446 3.56-4.446 7.952c0 4.391 1.99 7.951 4.446 7.951z"
      />
      <path
        fill="#fff"
        d="M56.854 110.24c1.74 0 3.151-2.776 3.151-6.199 0-3.424-1.41-6.2-3.151-6.2-1.741 0-3.152 2.776-3.152 6.2 0 3.423 1.41 6.199 3.152 6.199z"
      />
      <path
        fill="#263238"
        d="M56.566 109.406c1.666 0 3.017-2.402 3.017-5.365 0-2.964-1.35-5.366-3.017-5.366-1.666 0-3.016 2.402-3.016 5.366 0 2.963 1.35 5.365 3.017 5.365z"
      />
      <path
        fill="#000"
        d="M55.904 107.957c1.216 0 2.203-1.753 2.203-3.916 0-2.163-.986-3.916-2.203-3.916-1.216 0-2.202 1.753-2.202 3.916 0 2.163.986 3.916 2.202 3.916z"
      />
      <path
        fill="#000"
        d="M121.642 99.36v.149a1.38 1.38 0 01-1.38 1.38h-55.78V97.98h55.78a1.38 1.38 0 011.38 1.38z"
        opacity="0.5"
      />
      <path
        fill="#000"
        d="M104.306 104.033c0-4.392 1.99-7.952 4.444-7.952h-4.714c-2.457 0-4.447 3.56-4.447 7.952 0 4.391 1.99 7.948 4.447 7.948h4.708c-2.448.009-4.438-3.549-4.438-7.948z"
      />
      <path
        fill="#263238"
        d="M104.306 104.033c0-4.392 1.99-7.952 4.444-7.952h-4.714c-2.457 0-4.447 3.56-4.447 7.952 0 4.391 1.99 7.948 4.447 7.948h4.708c-2.448.009-4.438-3.549-4.438-7.948z"
        opacity="0.7"
      />
      <path
        fill="#263238"
        d="M108.75 111.992c2.455 0 4.446-3.56 4.446-7.951 0-4.392-1.991-7.952-4.446-7.952-2.456 0-4.447 3.56-4.447 7.952 0 4.391 1.991 7.951 4.447 7.951z"
      />
      <path
        fill="#fff"
        d="M109.108 110.24c1.741 0 3.152-2.776 3.152-6.199 0-3.424-1.411-6.2-3.152-6.2-1.74 0-3.151 2.776-3.151 6.2 0 3.423 1.411 6.199 3.151 6.199z"
      />
      <path
        fill="#263238"
        d="M108.818 109.406c1.667 0 3.017-2.402 3.017-5.365 0-2.964-1.35-5.366-3.017-5.366-1.666 0-3.016 2.402-3.016 5.366 0 2.963 1.35 5.365 3.016 5.365z"
      />
      <path
        fill="#000"
        d="M108.156 107.957c1.216 0 2.203-1.753 2.203-3.916 0-2.163-.987-3.916-2.203-3.916-1.216 0-2.202 1.753-2.202 3.916 0 2.163.986 3.916 2.202 3.916z"
      />
      <path
        fill="#CE7A63"
        d="M90.765 65.136a32.068 32.068 0 01-.31 3.135 17.493 17.493 0 01-.706 3.182 5.38 5.38 0 01-.394.873c-.11.187-.243.36-.398.513l-.077.072-.044.033-.022.02-.094.063-.185.13c-.469.3-.962.561-1.474.78-.972.428-1.982.762-3.017 1l-.358-.983c.877-.486 1.777-.983 2.597-1.499.4-.245.786-.516 1.153-.808l.122-.103.06-.052s.025-.041.056-.108c.08-.178.147-.363.198-.552.125-.454.224-.914.296-1.38.085-.48.154-.968.212-1.465.114-.986.21-1.996.276-2.978l2.109.127z"
      />
      <path
        fill="#CE7A63"
        d="M84.34 73.968l-1.695-.525.317 2.051s1.104-.138 1.656-.756l-.278-.77zM80.592 74.763l.094.276a.745.745 0 00.742.5l.864-.04-.32-2.056-.95.373a.75.75 0 00-.43.947z"
      />
      <path
        fill="#263238"
        d="M92.77 63.146c0 .64-.31 1.904-.575 2.854-.196.682-.359 1.2-.359 1.2l-.389-.096-3.207-.811c-.05-1.657.014-3.459 1.656-4.54 1.027-.68 2.865.253 2.873 1.393z"
      />
      <path
        fill="#000"
        d="M92.766 63.256c-.21-.22-.684-.26-1.72.07-1.481.468-1.895 2.091-1.915 3.192l2.316.585.199.053.19.044s.08-.26.196-.654c.047-.16.105-.348.16-.552.276-.893.469-1.81.574-2.738z"
        opacity="0.3"
      />
      <path
        fill="#CE7A63"
        d="M86.186 114.669l-1.957-.684 1.231-4.239 1.955.687-1.229 4.236zM112.332 109.646l-1.593 1.187-3.26-3.303 1.593-1.187 3.26 3.303z"
      />
      <path
        fill="#263238"
        d="M110.088 110.35l1.719-1.421a.162.162 0 01.21 0l1.319 1.198a.319.319 0 01.094.225.315.315 0 01-.094.225l-.027.024c-.607.486-.928.69-1.684 1.314-.464.384-1.438 1.303-2.081 1.833s-1.2-.075-.98-.354c.986-1.247 1.215-2.07 1.331-2.743a.528.528 0 01.193-.301zM84.097 113.373l2.175.908a.199.199 0 01.092.082c.02.037.029.08.024.122l-.246 1.918a.293.293 0 01-.408.232c-.77-.312-1.132-.467-2.087-.867l-2.931-1.228c-.795-.334-.624-1.206-.276-1.143 1.609.293 2.647.196 3.282-.044a.5.5 0 01.375.02z"
      />
      <path
        fill="#000"
        d="M85.46 109.749l-.631 2.183 1.904.861.684-2.36-1.956-.684zM109.075 106.343l-1.596 1.187 1.681 1.702 1.593-1.186-1.678-1.703z"
        opacity="0.2"
      />
      <path
        fill="#263238"
        d="M98.786 73.507l-8.06.116c-1.103-6.24-1.38-9.219-1.38-10.651a1.535 1.535 0 011.243-1.513 31.931 31.931 0 011.72-.292c.209-.028.421-.055.631-.077a25.71 25.71 0 013.66-.053c.182 0 .362.017.552.028a24.03 24.03 0 012.122.24.74.74 0 01.613.847.205.205 0 010 .042 83.545 83.545 0 00-.585 2.956c-.246 1.38-.4 2.506-.491 3.488a30.496 30.496 0 00-.025 4.869z"
      />
      <path
        fill="#000"
        d="M98.808 68.646c-.406-1.217-.384-3.742.052-4.56l.444 1.069a40.59 40.59 0 00-.496 3.491z"
        opacity="0.1"
      />
      <path
        fill="#F3532E"
        d="M90.727 73.623s-1.38 11.733-2.625 19.044c-.986 5.724-3.475 17.639-3.475 17.639l3.232 1.145s3.701-12.42 4.35-17.724c2.608-7.648 4.485-20.162 4.485-20.162l-5.967.058z"
      />
      <path
        fill="#263238"
        d="M84.103 111.107l3.74 1.316.499-1.2-4.057-1.552-.182 1.436z"
      />
      <path
        fill="#000"
        d="M94.811 77.109l-1.208 2.315c-.442 1.745-.337 5.99.223 8.672 1.005-4.043 1.808-8.299 2.308-11.156l-1.323.169z"
        opacity="0.1"
      />
      <path
        fill="#F3532E"
        d="M92.477 73.623s1.443 13.132 4.14 20.424c1.352 3.657 11.385 14.498 11.385 14.498l2.263-1.81s-6.362-10.938-9.144-13.8c-1.134-6.348.292-15.865-2.343-19.425l-6.301.113z"
      />
      <path
        fill="#263238"
        d="M108.192 109.321l2.655-2.175-.718-1.179-2.906 2.531.969.823zM98.913 73.035l.372.715c.028.055-.036.116-.13.116l-8.456.121c-.075 0-.133-.036-.14-.08l-.081-.72c0-.05.058-.094.135-.094l8.164-.119a.16.16 0 01.136.061z"
      />
      <path
        fill="#F3532E"
        d="M97.817 73.968h.223c.042 0 .075-.025.072-.05l-.118-.939c0-.027-.04-.046-.083-.046h-.207c-.044 0-.078.024-.072.05l.116.935c-.014.022.027.05.069.05zM91.306 74.056h.22c.045 0 .075-.025.073-.052l-.114-.936c0-.027-.044-.05-.088-.05h-.22c-.045 0-.078.025-.075.053l.116.936c.005.027.044.05.088.05zM94.563 74.004h.22c.045 0 .078-.025.075-.053l-.116-.935c0-.03-.041-.053-.085-.05h-.221c-.044 0-.075.025-.075.053l.116.935c-.002.033.042.05.086.05z"
      />
      <path
        fill="#F3532E"
        d="M79.94 65.362l11.962-1.642 1.656 11.743-11.746 1.612-1.872-11.713z"
      />
      <path
        fill="#fff"
        d="M79.94 65.362l11.962-1.642 1.656 11.743-11.746 1.612-1.872-11.713z"
        opacity="0.8"
      />
      <path
        fill="#F3532E"
        d="M92.066 63.153l-3.194.452 1.713 12.115 3.195-.452-1.714-12.115z"
        opacity="0.2"
      />
      <path
        fill="#CE7A63"
        d="M95.294 73.984l-1.656-.648.163 2.07s1.104-.055 1.697-.632l-.204-.79z"
      />
      <path
        fill="#CE7A63"
        d="M100.502 63.679a42.207 42.207 0 011.154 3.753c.166.638.32 1.278.444 1.932.061.331.114.668.152 1.019.045.366.06.735.047 1.104-.008.24-.045.478-.11.71a2.054 2.054 0 01-.445.764 3.523 3.523 0 01-1.035.764 9.184 9.184 0 01-1.998.698 21 21 0 01-3.933.528l-.13-1.041a47.907 47.907 0 001.825-.472c.602-.168 1.2-.356 1.775-.566a8.957 8.957 0 001.578-.72 1.83 1.83 0 00.48-.406c.036-.055.036-.069.031-.069a2.014 2.014 0 000-.246 5.933 5.933 0 00-.086-.797 16.65 16.65 0 00-.171-.889 36.672 36.672 0 00-.466-1.8 95.15 95.15 0 00-1.124-3.587l2.012-.68zM92.112 74.357l.13.48a.731.731 0 00.687.552l.889.025-.163-2.067-.905.102a.736.736 0 00-.657.806c.004.034.01.068.02.102z"
      />
      <path
        fill="#263238"
        d="M99.561 61.366c.928.378 1.687 3.425 1.687 3.425l-2.562 2.001s-1.816-2.843-1.446-4.076c.315-1.044 1.14-1.828 2.321-1.35zM109.461 111.211h-.033a.275.275 0 01-.223-.143.156.156 0 01-.029-.08.16.16 0 01.015-.083c.113-.221.828-.309.905-.317a.042.042 0 01.027.003.041.041 0 01.02.019.05.05 0 01.008.026.05.05 0 01-.008.026c-.149.202-.439.533-.682.549zm.539-.516c-.276.042-.666.133-.721.254a.074.074 0 00-.01.035c0 .012.004.024.01.034a.164.164 0 00.06.07.165.165 0 00.086.03c.138 0 .359-.149.58-.423H110z"
      />
      <path
        fill="#263238"
        d="M110.063 110.676c-.24 0-.72-.086-.828-.276a.166.166 0 01.039-.202.254.254 0 01.193-.08c.29 0 .657.445.668.47a.04.04 0 01.006.022.043.043 0 01-.006.022.049.049 0 01-.014.021.043.043 0 01-.022.009l-.036.014zm-.613-.458a.164.164 0 00-.102.049c-.041.045-.033.072-.022.094.063.122.442.221.684.221a1.093 1.093 0 00-.538-.367l-.022.003zM82.985 113.328a.776.776 0 01-.384-.276.305.305 0 01-.022-.254.142.142 0 01.105-.096c.254-.047.853.596.969.726a.05.05 0 010 .055.046.046 0 01-.05.022 3.166 3.166 0 01-.618-.177zm-.19-.519a.178.178 0 00-.092-.016s-.025 0-.036.033a.197.197 0 00.017.177c.085.149.38.289.8.38a2.909 2.909 0 00-.684-.574h-.006z"
      />
      <path
        fill="#263238"
        d="M83.6 113.499h-.017c-.201-.201-.53-.806-.416-.999.025-.041.077-.085.196-.052.444.129.309.99.3 1.026a.038.038 0 01-.009.025.035.035 0 01-.024.011.032.032 0 01-.016-.001.027.027 0 01-.014-.01zm-.22-.963h-.028c-.045-.014-.078 0-.089 0-.066.113.147.569.34.811-.003-.212.025-.701-.224-.811zM91.93 52.78s-1.104 1.846-.276 2.654c.828.81 1.07-2.058 1.07-2.058l-.794-.597z"
      />
      <path
        fill="#263238"
        d="M97.058 57.112s1.38-2.158.806-4.302c-.574-2.145-3.257-.76-4.389-1.347a6.472 6.472 0 00-1.316-.61.503.503 0 00-.439.074c-.276.243.204.699.353.87a1.524 1.524 0 00-.314-.276c-.147-.097-.343-.105-.276.14.113.264.317.48.574.608-.108-.061-.746-.384-.79-.182a.198.198 0 00.036.138c.414.772 1.248 1.744 2.208 1.763 1.157.017 2.26 3.27 3.547 3.124z"
      />
      <path
        fill="#CE7A63"
        d="M97.546 57.209c-.071.729-1.153.737-1.153.737a2.341 2.341 0 01-.847 1.027c-.034.44-.019.882.044 1.319.182.687.414.925.703 1.079l-.231.23a3.036 3.036 0 01-1.458.405l-.303-.177-.398.064c-1.104-.362-.828-1.104-.828-1.104a2.233 2.233 0 00.03-1.576c-1.06-.331-1.493-1.253-1.642-2.804-.149-1.552.724-3.036.724-3.036 1.197 1.156 3.571.414 3.571.414-.102 2.174.913 2.978.913 2.978.334-.298.944-.282.875.444z"
      />
      <path
        fill="#263238"
        d="M93.694 57.408c.284-.08.53-.26.692-.508a.09.09 0 00-.088-.14.087.087 0 00-.058.035.896.896 0 01-.969.442.089.089 0 00-.104.126c.01.021.03.037.052.045.155.04.319.04.475 0zM94.008 55.509c0 .14-.055.262-.149.276-.094.014-.18-.102-.19-.243-.011-.14.058-.276.151-.276.094 0 .177.1.188.243zM92.319 55.437c0 .141-.055.263-.15.276-.093.014-.176-.102-.187-.242-.01-.141.055-.263.15-.276.093-.014.179.099.187.242z"
      />
      <path
        fill="#BA4D3C"
        d="M92.766 56.803l-.02-1.02-.507.86a.635.635 0 00.527.16z"
      />
      <path
        fill="#263238"
        d="M94.778 54.648a.094.094 0 00.033-.017.115.115 0 00.056-.12.114.114 0 00-.017-.043.958.958 0 00-.902-.463.115.115 0 00-.08.044.114.114 0 00-.022.088.12.12 0 00.132.102.729.729 0 01.67.354.118.118 0 00.13.055zM91.784 54.535a.124.124 0 00.074-.055.463.463 0 01.464-.202.116.116 0 00.138-.094.119.119 0 00-.094-.138.69.69 0 00-.712.315.122.122 0 00.041.163.124.124 0 00.089.01z"
      />
      <path
        fill="#fff"
        d="M96.743 61.468l-.593.58-1.057 1.037-.398-.632-.394-.623 1.38-1.645 1.004 1.214.058.069zM94.3 61.83l-.306.51-.45.745-1.032-1.863.66-.775L94.3 61.83z"
      />
    </svg>
  );
}
