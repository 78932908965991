import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BottomSheetRef } from 'react-spring-bottom-sheet';
import { SnackbarTheme } from '../../component/molecule/Snackbar/Snackbar.molecule';
import { GalleryMobileProps } from '../../component/organism/GalleryMobile/GalleryMobile.organism';
import envConfig from '../../config/env/env.config';
import { SupportedLanguage } from '../../config/locale/locale.config';
import { RedirectUrlTarget, SOStatus } from '../../constant';
import { Photo } from '../../model/Common.model';
import { SOTracking } from '../../model/ShipperOrder.model';
import api from '../../service/api.service';
import { snackbarAction } from '../../store/snackbar.store';
import { doRedirectUrl } from '../../util/helper.util';
import { formatText } from '../../util/tracking/trackingTimeline.util';
import useSOShareDisclaimer, {
  SOTrackingDisclaimerType,
} from '../../view/ShipperOrderDetail/hooks/useSOShareDisclaimer.hook';
import { trackingRoute } from '../../view/Tracking/Tracking.route';
import useScreenDetection from '../useScreenDetection/useScreenDetection.hook';
import useTranslator from '../useTranslator.hook';

interface Props {
  soId?: string;
  trackingCode?: string;
  shipperPhoneNumber?: string | null;
  headerTitle?: string;
  tracking: SOTracking;
  handleCopyClick: () => void;
}
export default function useSODetailTrackingSheet({
  soId,
  shipperPhoneNumber = '',
  trackingCode = '',
  headerTitle = '',
  tracking,
  handleCopyClick,
}: Props) {
  const isPublic = !soId;
  const bottomSheetRef = useRef<BottomSheetRef>();
  const translator = useTranslator();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forceLang = isPublic ? SupportedLanguage.id : undefined;

  const { windowSize } = useScreenDetection();

  const [showShareDialog, setShowShareDialog] = useState(false);

  const [showSONav, setShowSONav] = useState(false);
  const [showTripHistory, setShowTripHistory] = useState(false);
  const [showGallery, setShowGallery] = useState<
    GalleryMobileProps | undefined
  >(undefined);
  const sheetSnapPoints = {
    top: windowSize.height,
    center: windowSize.height * 0.4,
    min: isPublic ? 128 : 160,
  };

  const vehiclePositionRes = useSelector(
    api.endpoints.getSOTrackPosition.select({ trackingCode }),
  );

  const hasVehiclePositionData =
    tracking.status === SOStatus.DELIVERING &&
    !!vehiclePositionRes.data?.latestPosition?.latitude &&
    !!vehiclePositionRes.data?.latestPosition?.longitude;

  const hasVehiclePosition =
    !vehiclePositionRes.isUninitialized || hasVehiclePositionData;

  const isValidPhoneNumber = useMemo(() => {
    if (!shipperPhoneNumber) return false;
    return !!shipperPhoneNumber?.slice(3)?.startsWith('8');
  }, [shipperPhoneNumber]);

  // #region GALLERY
  const handleChangePhoto = useCallback((idx: number) => {
    setShowGallery((v) => {
      if (!v) return undefined;
      return { ...v, currentIndex: idx };
    });
  }, []);

  const handleOpenGallery = useCallback(
    (photos: Photo[], currentIndex: number) => {
      const galleryProps: GalleryMobileProps = {
        photos,
        currentIndex,
        handleChangePhoto,
      };
      setShowGallery(galleryProps);
    },
    [handleChangePhoto],
  );
  const handleCloseGallery = () => {
    setShowGallery(undefined);
  };
  // #endregion

  const handleSpringStart = () => {
    requestAnimationFrame(() => {
      setShowSONav(bottomSheetRef.current?.height === sheetSnapPoints.top);
    });
  };

  const handleDismissHeader = () => {
    bottomSheetRef.current?.snapTo(sheetSnapPoints.center);
  };

  const handleShowFullContent = () => {
    bottomSheetRef.current?.snapTo(sheetSnapPoints.top);
  };

  const handleOpenTripHistory = () => {
    handleShowFullContent();
    setShowTripHistory(true);
  };

  const handleBack = () => {
    if (showGallery) return setShowGallery(undefined);
    if (showTripHistory) return setShowTripHistory(false);
    return navigate(-1);
  };

  // #region Share dialog

  const { disclaimerType, handleApproveDisclaimer, setDisclaimerType } =
    useSOShareDisclaimer({ soId: soId || '' });

  const handleCloseDisclaimer = useCallback(() => {
    setDisclaimerType(undefined);
  }, [setDisclaimerType]);

  const handleOpenDialog = useCallback(() => {
    setShowShareDialog(true);
  }, []);
  const handleCloseDialog = useCallback(() => {
    setShowShareDialog(false);
  }, []);

  const handleShareWA = useCallback(() => {
    doRedirectUrl({
      url: `https://wa.me/${shipperPhoneNumber || ''}?text=${formatText(
        translator.translate(
          'Thank you for choosing our delivery service! Your tracking number is %s. You can track your order with the link: %s%s?receipt=%s',
          SupportedLanguage.id,
        ),
        trackingCode,
        envConfig.ktbJOServiceUrl,
        trackingRoute.path,
        trackingCode,
      )}`,
      target: RedirectUrlTarget.BLANK,
    });

    handleCloseDisclaimer();
    handleCloseDialog();
  }, [
    handleCloseDialog,
    handleCloseDisclaimer,
    shipperPhoneNumber,
    trackingCode,
    translator,
  ]);

  const handleCopy = useCallback(() => {
    handleCopyClick();
    handleCloseDisclaimer();
    handleCloseDialog();
    dispatch(
      snackbarAction.show({
        type: SnackbarTheme.dark,
        message: translator.translate(
          'Successfully copy tracking link',
          isPublic ? SupportedLanguage.id : undefined,
        ),
      }),
    );
  }, [
    dispatch,
    handleCloseDialog,
    handleCloseDisclaimer,
    handleCopyClick,
    isPublic,
    translator,
  ]);

  const handleClickWA = useCallback(() => {
    if (!soId) {
      handleShareWA();
      return;
    }

    handleCloseDialog();
    setDisclaimerType(SOTrackingDisclaimerType.WA);
  }, [soId, handleShareWA, handleCloseDialog, setDisclaimerType]);

  const handleClickShare = useCallback(() => {
    if (!soId) {
      handleCopy();
      return;
    }

    handleCloseDialog();
    setDisclaimerType(SOTrackingDisclaimerType.COPY);
  }, [soId, handleCopy, handleCloseDialog, setDisclaimerType]);

  const handleSuccessDisclaimer = useCallback(() => {
    if (!disclaimerType) return;

    if (disclaimerType === SOTrackingDisclaimerType.WA) {
      return handleShareWA();
    }

    return handleCopy();
  }, [disclaimerType, handleCopy, handleShareWA]);

  const handleClickApproveDisclaimer = useCallback(() => {
    handleApproveDisclaimer({ onSuccess: handleSuccessDisclaimer });
  }, [handleSuccessDisclaimer, handleApproveDisclaimer]);

  // #endregion

  const hideMap =
    showTripHistory ||
    tracking.status !== SOStatus.DELIVERING ||
    !hasVehiclePosition;
  const showHeaderBackButton = useMemo(
    () => hideMap || showTripHistory,
    [hideMap, showTripHistory],
  );

  const title = useMemo(() => {
    if (showGallery)
      return translator.translate('Proof of Activity', forceLang);
    if (showTripHistory) return translator.translate('Trip History', forceLang);
    return headerTitle;
  }, [forceLang, headerTitle, showGallery, showTripHistory, translator]);

  return {
    disclaimerType,
    showSONav,
    bottomSheetRef,
    hideMap,
    showTripHistory,
    showHeaderBackButton,
    sheetSnapPoints,
    showShareDialog,
    translator,
    forceLang,
    title,
    showGallery,
    isValidPhoneNumber,
    setDisclaimerType,
    handleClickWA,
    handleShareWA,
    handleCopy,
    handleShowFullContent,
    handleCloseDialog,
    handleBack,
    handleOpenDialog,
    handleDismissHeader,
    handleSpringStart,
    handleOpenTripHistory,
    handleOpenGallery,
    handleCloseGallery,
    handleCloseDisclaimer,
    handleClickShare,
    handleClickApproveDisclaimer,
  };
}
