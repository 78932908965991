import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';

// #region PROPS
export type CheckboxProps = React.ComponentProps<'input'>;
// #endregion

// #region STYLED COMPONENTS
const Control = tw.span`relative p-6 -mx-1 -my-3 flex-none`;
const HiddenInput = tw.input`w-12 h-12 absolute m-0 inset-y-0 -inset-x-0 opacity-0 cursor-pointer z-index[3]`;
const SwitchSlot = styled.div(
  (props: { checked?: boolean; disabled?: boolean }) => [
    tw`w-10 h-4 absolute inset-y-4 inset-x-1 z-index[1] rounded-full bg-grey-five duration-200`,
    props.checked && tw`bg-orange-four`,
    props.disabled && tw`bg-grey-six`,
  ],
);
const SwitchHover = styled.div(
  (props: { checked?: boolean; disabled?: boolean }) => [
    tw`w-12 h-12 rounded-full absolute z-0 inset-y-0 left-2 bg-orange-four bg-opacity-0 duration-200 group-hover:bg-opacity-40 peer-focus:peer-hover:bg-opacity-80 peer-checked:peer-focus:peer-hover:bg-opacity-40`,
    props.checked && tw`-left-2`,
    props.disabled && tw`bg-opacity-0`,
  ],
);
const SwitchButton = styled.div(
  (props: { checked?: boolean; disabled?: boolean }) => [
    tw`w-6 h-6 rounded-full absolute inset-y-3 z-index[2] -left-0 shadow duration-200 bg-white`,
    props.checked && tw`bg-orange left-6`,
    props.disabled && tw`bg-grey-five`,
    props.checked && props.disabled && tw`bg-grey-four`,
  ],
);
// #endregion

export function Switch(props: CheckboxProps) {
  const { checked, disabled } = props;
  const [isChecked, setChecked] = useState<boolean>(checked ?? false);
  const [isDisabled, setDisabled] = useState<boolean>(disabled ?? false);

  useEffect(() => {
    setChecked(checked ?? false);
  }, [checked]);

  useEffect(() => {
    setDisabled(disabled ?? false);
  }, [disabled]);
  return (
    <Control>
      <HiddenInput type="checkbox" className="peer" {...props} />
      <SwitchHover checked={isChecked} disabled={isDisabled} />
      <SwitchSlot checked={isChecked} disabled={isDisabled} />
      <SwitchButton role="switch" checked={isChecked} disabled={isDisabled} />
    </Control>
  );
}

export default Switch;
