import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SnackbarTheme } from '../../component/molecule/Snackbar/Snackbar.molecule';
import { SupportedLanguage } from '../../config/locale/locale.config';
import { intervalDelay } from '../../constant/JobOrderDetail.constant';
import useInterval from '../../hook/useInterval.hook';
import useTranslator from '../../hook/useTranslator.hook';
import { LatLng } from '../../model/Map.model';
import { SOTracking, SOTrackingLocation } from '../../model/ShipperOrder.model';
import { ApiErrorResponse } from '../../service/api.endpoint';
import api from '../../service/api.service';
import { GetSOTrackPositionResponse } from '../../service/endpoint/shipperOrder/shipperOrder.endpoint';
import { snackbarAction } from '../../store/snackbar.store';
import { errorCodeToLabel } from '../../util/error.util';

function useSOTrackPosition({
  trackingCode,
  runInterval,
}: {
  trackingCode?: string;
  runInterval?: boolean;
}) {
  const dispatch = useDispatch();
  const translator = useTranslator();
  const [soTrackPositionRequest] = api.useLazyGetSOTrackPositionQuery();
  const [latestPosition, setLatestPosition] = useState<LatLng | undefined>(
    undefined,
  );

  const handleSuccess = useCallback((response: GetSOTrackPositionResponse) => {
    if (response.latestPosition?.latitude && response.latestPosition?.longitude)
      setLatestPosition({
        lat: response.latestPosition?.latitude,
        lng: response.latestPosition?.longitude,
      });
  }, []);

  const handleError = useCallback(
    (error?: FetchBaseQueryError | SerializedError) => {
      if (!error) return;
      const data = (error as FetchBaseQueryError).data as ApiErrorResponse;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translator.translate(errorCodeToLabel(data.error.code)),
        }),
      );
    },
    [dispatch, translator],
  );
  const [init, setInit] = useState(false);
  const request = useCallback(async () => {
    if (!trackingCode) return;
    try {
      const soTrackPositionResponse = await soTrackPositionRequest({
        trackingCode,
      }).unwrap();
      handleSuccess(soTrackPositionResponse);
    } catch (error) {
      handleError(error as FetchBaseQueryError);
    }
  }, [handleError, handleSuccess, soTrackPositionRequest, trackingCode]);

  useInterval(() => void request(), runInterval && init ? intervalDelay : null);
  useEffect(() => {
    if (init) return;
    void request();
    setInit(true);
  }, [init, request]);
  return { latestPosition };
}

function useTrackingMapViewModel({
  forceLang,
  tracking,
  trackingCode,
  runInterval,
}: {
  forceLang?: SupportedLanguage;
  tracking?: SOTracking;
  trackingCode?: string;
  runInterval?: boolean;
}) {
  const translator = useTranslator();
  const navigate = useNavigate();

  const pickupMarkerLabel = translator.translate(
    'Pickup location',
    forceLang || undefined,
  );
  const dropoffMarkerLabel = translator.translate(
    'Dropoff location',
    forceLang || undefined,
  );
  const coordinateSelector = useCallback(
    (v: SOTrackingLocation) => ({ lat: v.latitude, lng: v.longitude }),
    [],
  );
  const soTrackPosition = useSOTrackPosition({ trackingCode, runInterval });

  const mapVehicleLocations = useMemo(() => {
    if (!soTrackPosition.latestPosition) return [];

    return [soTrackPosition.latestPosition];
  }, [soTrackPosition.latestPosition]);

  const mapDeliveryLocations = useMemo(() => {
    if (!tracking) return { pickupLocations: [], dropoffLocations: [] };

    return {
      pickupLocations: tracking.pickupLocation,
      dropoffLocations: tracking.dropoffLocation,
    };
  }, [tracking]);

  const mapTargetLocations = useMemo(
    () => [
      ...mapDeliveryLocations.dropoffLocations.map(coordinateSelector),
      ...mapDeliveryLocations.pickupLocations.map(coordinateSelector),
      ...mapVehicleLocations,
    ],
    [
      coordinateSelector,
      mapDeliveryLocations.dropoffLocations,
      mapDeliveryLocations.pickupLocations,
      mapVehicleLocations,
    ],
  );
  const handleBack = () => {
    navigate(-1);
  };

  return {
    pickupMarkerLabel,
    dropoffMarkerLabel,
    mapDeliveryLocations,
    soTrackPosition,
    mapTargetLocations,
    handleBack,
  };
}

export default useTrackingMapViewModel;
