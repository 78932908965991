import React from 'react';
import tw, { TwStyle } from 'twin.macro';
import { Text } from '../../atom';

interface Props {
  css?: TwStyle;
  color: string;
  label: string;
}

const Container = tw.div`grid gap-2 auto-cols-auto grid-flow-col items-center mr-4`;
const LegendIcon = tw.div`h-3 w-3 bg-status-off rounded-sm`;

export default function Legend({ css, color, label }: Props) {
  return (
    <Container css={css}>
      <LegendIcon css={{ backgroundColor: color }} />
      <Text.BodyOne>{label}</Text.BodyOne>
    </Container>
  );
}
