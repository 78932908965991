import useTranslator from '../../hook/useTranslator.hook';
import useShipperOrderDeleteBulkLogic from './hooks/useShipperOrderDeleteBulkLogic.hook';
import useShipperOrderDeleteBulkTable from './hooks/useShipperOrderDeleteBulkTable.hook';

export default function useShipperOrderDeleteBulkViewModel() {
  const translator = useTranslator();

  const bulkLogic = useShipperOrderDeleteBulkLogic();
  const bulkTable = useShipperOrderDeleteBulkTable({ bulkLogic });

  return {
    translator,
    bulkLogic,
    bulkTable,
  };
}
