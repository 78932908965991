import React, { useRef, useState } from 'react';
import tw, { styled, theme, TwStyle } from 'twin.macro';
import { Icon, Row, Text } from '../../atom';
import IndeterminateCheckbox from '../../atom/Checkbox/IndeterminateCheckbox.atom';
import { BodyFourteen, BodyTwelve } from '../../atom/Text/Text.atom';
import { ExtraStyle } from '../../Type.component';
import Tooltip from '../Tooltip/Tooltip.molecule';

type Props = {
  label?: string;
  isRequired?: boolean;
  values?: boolean;
  error?: string;
  disabled?: boolean;
  tooltipText?: string;
  labelStyle?: false | '' | TwStyle;
  descriptionLabelStyle?: false | '' | TwStyle;
  containerStyle?: ExtraStyle;
  wrapperStyle?: ExtraStyle;
  description?: string;
  onChange: (value?: boolean) => void;
};

const ErrorMessage = tw.div`font-sans ml-[216px] text-xs leading-5 letter-spacing[0.2px] py-1  text-status-alert`;
const Container = tw.div`flex flex-col min-h-[56px]`;
const DescriptionContainer = tw(Row)`mr-14 pt-0`;
const DescriptionLabel = tw(BodyTwelve)`text-justify`;
const LabelFormContainerText = tw(BodyFourteen)`text-grey-three`;
const LabelFormContainer = styled.div<{ tooltipText?: string }>(
  ({ tooltipText }) => [
    tw`min-width[180px] mr-5`,
    !!tooltipText && tw`flex flex-row`,
  ],
);

export default function CheckboxInput({
  label,
  isRequired,
  error,
  values,
  disabled,
  tooltipText,
  containerStyle,
  labelStyle,
  descriptionLabelStyle,
  wrapperStyle,
  description,
  onChange,
}: Props) {
  const tooltipRef = useRef(null);
  const [visible, setVisible] = useState(false);
  return (
    <Container css={wrapperStyle}>
      <Row css={containerStyle}>
        {label && (
          <LabelFormContainer tooltipText={tooltipText}>
            <LabelFormContainerText css={[labelStyle]}>
              {`${label} ${isRequired ? '*' : ''}`}
            </LabelFormContainerText>
            {tooltipText && (
              <>
                <Icon.InfoOutlined
                  svgRef={tooltipRef}
                  svgStyle={[tw`ml-1 cursor-default`]}
                  fillPath={theme`colors.grey.three`}
                  onMouseEnter={() => setVisible(true)}
                  onMouseLeave={() => setVisible(false)}
                />
                <Tooltip
                  targetRef={tooltipRef}
                  visible={visible}
                  variant="light"
                  placement="top-start"
                  offset={[-20, 20]}
                >
                  <Text.Label>{tooltipText}</Text.Label>
                </Tooltip>
              </>
            )}
          </LabelFormContainer>
        )}
        <IndeterminateCheckbox
          disabled={disabled}
          checked={values}
          onChange={(e) => onChange(!e.target.checked)}
        />
      </Row>
      {!!description && (
        <DescriptionContainer>
          <DescriptionLabel css={[descriptionLabelStyle]}>
            {description}
          </DescriptionLabel>
        </DescriptionContainer>
      )}
      {!!error && (
        <Row>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
    </Container>
  );
}
