// #region IMPORT
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  HaulingJobOrderInfo,
  HaulingJOFilterOrderByEnum,
} from '../../../model/HaulingJobOrder.model';
import {
  HaulingOrderAssignmentCommandProps,
  HaulingOrderDeliveryAssignment,
  HaulingOrderDeliveryAssignmentStatusEnum,
  HaulingOrderDeliveryTargetUnitEnum,
} from '../../../model/HaulingOrder.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, MD, QD } from '../../api.baseQuery';
import { CommonApiMetadata, CommonApiResponse } from '../../api.endpoint';
import { haulingOrderTagTypes } from '../haulingOrder/haulingOrder.endpoint';
// #endregion

// #region INTERFACES
export type GetHODeliveryAssignmentListApiRequest = {
  page?: number;
  pageSize?: number;
  search?: string;
  orderBy?: HaulingJOFilterOrderByEnum;
  driverId?: string;
  vehicleId?: string;
  deliveryId?: string;
  'status[]'?: HaulingOrderDeliveryAssignmentStatusEnum;
};
export type GetHODeliveryAssignmentListApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    deliveryAssignments: HaulingOrderDeliveryAssignment[];
  };

export type GetHODeliveryAssignmentCountApiRequest = Pick<
  GetHODeliveryAssignmentListApiRequest,
  'search' | 'driverId' | 'vehicleId' | 'deliveryId' | 'status[]'
>;
export type GetHODeliveryAssignmentCountApiResponse = {
  deliveryAssignments: {
    totalCount: number;
  };
};

export type GetHODeliveryAssignmentListTotalApiRequest = {
  deliveryId?: string;
  'status[]'?: HaulingOrderDeliveryAssignmentStatusEnum;
};
export type GetHODeliveryAssignmentListTotalApiResponse = CommonApiResponse & {
  totalGoods: {
    totalGoodsIn?: number;
    totalGoodsOut?: number;
    totalGoodsUnit?: HaulingOrderDeliveryTargetUnitEnum;
  };
};

export type HaulingJobOrderInfoResponse = CommonApiResponse & {
  deliveryAssignment: HaulingJobOrderInfo;
};

export interface HaulingJobOrderCreateParam {
  hoId: string;
  hoDeliveryId: string;
  assignment: HaulingOrderAssignmentCommandProps;
}

export type HaulingJobOrderCreateResponse = CommonApiResponse;

export interface HaulingJobOrderCompleteParam {
  hoDeliveryAssignmentId: string;
  goodsIn?: number | string;
  goodsOut?: number | string;
  travelExpense?: number;
  notes?: string;
}

export type HaulingJobOrderCompleteResponse = CommonApiResponse;

export interface HaulingJobOrderUpdateParam {
  hoDeliveryAssignmentId: string;
  goodsIn?: number | null;
  goodsOut?: number | null;
  travelExpense?: number | null;
  notes?: string | null;
}

export type HaulingJobOrderUpdateResponse = CommonApiResponse;

export type HaulingJobOrderEndpoint = {
  getHaulingOrderDeliveryAssignmentCount: QD<
    GetHODeliveryAssignmentCountApiRequest | string,
    GetHODeliveryAssignmentCountApiResponse
  >;
  getHaulingOrderDeliveryAssignmentList: QD<
    GetHODeliveryAssignmentListApiRequest | string,
    GetHODeliveryAssignmentListApiResponse
  >;
  getHaulingOrderDeliveryAssignmentListTotal: QD<
    GetHODeliveryAssignmentListTotalApiRequest | string,
    GetHODeliveryAssignmentListTotalApiResponse
  >;
  createHaulingJobOrder: MD<
    HaulingJobOrderCreateParam,
    HaulingJobOrderCreateResponse
  >;
  getHaulingJobOrderInfo: QD<string, HaulingJobOrderInfoResponse>;
  completeHaulingJobOrder: MD<
    HaulingJobOrderCompleteParam,
    HaulingJobOrderCompleteResponse
  >;
  updateHaulingJobOrder: MD<
    HaulingJobOrderUpdateParam,
    HaulingJobOrderUpdateResponse
  >;
};
// #endregion

// #region ENDPOINT
export const haulingJobOrderTagTypes: EndpointTagTypes<HaulingJobOrderEndpoint> =
  {
    getHaulingOrderDeliveryAssignmentCount: '',
    getHaulingOrderDeliveryAssignmentList: 'haulingOrderDeliveryAssignment',
    getHaulingOrderDeliveryAssignmentListTotal: '',
    createHaulingJobOrder: '',
    getHaulingJobOrderInfo: 'haulingJobOrderInfo',
    completeHaulingJobOrder: '',
    updateHaulingJobOrder: '',
  };

export const haulingJobOrderEndpoint = (
  builder: Builder,
): HaulingJobOrderEndpoint => ({
  getHaulingOrderDeliveryAssignmentCount: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/hauling-orders.deliveries.assignments.count?${param}`
          : urlcat(
              '/v1/hauling-orders.deliveries.assignments.count',
              toSnakeCase(param),
            ),
    }),
    providesTags: [
      haulingJobOrderTagTypes.getHaulingOrderDeliveryAssignmentList,
    ],
  }),
  getHaulingOrderDeliveryAssignmentList: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/hauling-orders.deliveries.assignments.list?${param}`
          : urlcat(
              '/v1/hauling-orders.deliveries.assignments.list',
              toSnakeCase(param),
            ),
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.deliveryAssignments.map(({ id }) => ({
              type: haulingJobOrderTagTypes.getHaulingOrderDeliveryAssignmentList,
              id,
            })),
            haulingJobOrderTagTypes.getHaulingOrderDeliveryAssignmentList,
          ]
        : [haulingJobOrderTagTypes.getHaulingOrderDeliveryAssignmentList],
  }),
  getHaulingOrderDeliveryAssignmentListTotal: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/hauling-orders.deliveries.assignments.total?${param}`
          : urlcat(
              '/v1/hauling-orders.deliveries.assignments.total',
              toSnakeCase(param),
            ),
    }),
    providesTags: [
      haulingJobOrderTagTypes.getHaulingOrderDeliveryAssignmentList,
    ],
  }),
  createHaulingJobOrder: builder.mutation({
    query: (body) => ({
      url: '/v1/hauling-orders.deliveries.assignments.add',
      body,
      method: 'post',
    }),
    invalidatesTags: () => [
      haulingJobOrderTagTypes.getHaulingOrderDeliveryAssignmentList,
      haulingOrderTagTypes.getHaulingOrderList,
      haulingOrderTagTypes.getHaulingOrderInfo,
      haulingOrderTagTypes.getHaulingOrderTimelineList,
    ],
  }),
  getHaulingJobOrderInfo: builder.query({
    query: (param) => ({
      url: urlcat('v1/hauling-orders.deliveries.assignments.info', {
        id: param,
      }),
    }),
    providesTags: [haulingJobOrderTagTypes.getHaulingJobOrderInfo],
  }),
  completeHaulingJobOrder: builder.mutation({
    query: (body) => ({
      url: '/v1/hauling-orders.deliveries.assignments.complete',
      body,
      method: 'post',
    }),
    invalidatesTags: () => [
      haulingJobOrderTagTypes.getHaulingJobOrderInfo,
      haulingJobOrderTagTypes.getHaulingOrderDeliveryAssignmentList,
      haulingOrderTagTypes.getHaulingOrderList,
    ],
  }),
  updateHaulingJobOrder: builder.mutation({
    query: (body) => ({
      url: '/v1/hauling-orders.deliveries.assignments.update',
      body,
      method: 'post',
    }),
    invalidatesTags: () => [
      haulingJobOrderTagTypes.getHaulingJobOrderInfo,
      haulingJobOrderTagTypes.getHaulingOrderDeliveryAssignmentList,
      haulingOrderTagTypes.getHaulingOrderList,
      haulingOrderTagTypes.getHaulingOrderInfo,
      haulingOrderTagTypes.getHaulingOrderTimelineList,
    ],
  }),
});
