import 'twin.macro';
import { UseTranslator } from '../hook/useTranslator.hook';
import {
  HaulingOrderDelivery,
  HaulingOrderDeliveryTargetUnitEnum,
  HaulingOrderFilterOrderByEnum,
  HaulingOrderStatusEnum,
  HOTrackingGoods,
} from '../model/HaulingOrder.model';
import {
  HOFilterOrderByValue,
  HOFilterStatusEnum,
  HOFilterStatusValue,
} from '../types/haulingOrder.type';
import { numberFormatter } from './formatter.util';

/**
 * get HO status number from query string
 */
export function getHOFilterStatuses(
  statuses: HOFilterStatusEnum[],
): HaulingOrderStatusEnum[] {
  return statuses.map((status) => {
    const literal: Record<HOFilterStatusEnum, HaulingOrderStatusEnum> = {
      [HOFilterStatusEnum.IN_PROCESS]: HaulingOrderStatusEnum[status],
      [HOFilterStatusEnum.ASSIGNED]: HaulingOrderStatusEnum[status],
      [HOFilterStatusEnum.DELIVERING]: HaulingOrderStatusEnum[status],
      [HOFilterStatusEnum.DELIVERED]: HaulingOrderStatusEnum[status],
    };

    return literal[status];
  });
}

/**
 * @param  {HaulingOrderStatusEnum} status - from model
 * @returns string
 */
export function mapHOStatusToLabel(status: HaulingOrderStatusEnum): string {
  const label: Record<HaulingOrderStatusEnum, string> = {
    [HaulingOrderStatusEnum.IN_PROCESS]: 'In Process',
    [HaulingOrderStatusEnum.ASSIGNED]: 'Assigned',
    [HaulingOrderStatusEnum.DELIVERING]: 'Delivering',
    [HaulingOrderStatusEnum.DELIVERED]: 'Delivered',
  };

  return label[status];
}

/**
 * map HO statuses to translated labels
 */
export function mapHOFilterStatusesToLabels(
  statuses: HOFilterStatusEnum[],
  translator: UseTranslator,
): string[] {
  const literal: Record<HOFilterStatusEnum, string> = {
    [HOFilterStatusEnum.IN_PROCESS]: translator.translate('In Process'),
    [HOFilterStatusEnum.ASSIGNED]: translator.translate('Assigned'),
    [HOFilterStatusEnum.DELIVERING]: translator.translate('Delivering'),
    [HOFilterStatusEnum.DELIVERED]: translator.translate('Delivered'),
  };

  return statuses.map((status) => literal[status]);
}

/**
 * @param  {HaulingOrderFilterOrderByEnum | null} filterOrderBy - from query params
 * @returns string
 */
export function mapHOFilterOrderByToLabel(
  filterOrderBy: HaulingOrderFilterOrderByEnum | null,
): string {
  if (!filterOrderBy) return 'Newest Updates';

  const label: Record<HaulingOrderFilterOrderByEnum, string> = {
    UPDATED_AT_DESC: 'Newest Updates',
    UPDATED_AT_ASC: 'Oldest Updates',
    HAULING_ORDER_DATE_DESC: 'HO Date Z-A',
    HAULING_ORDER_DATE_ASC: 'HO Date A-Z',
    TARGET_FULLFILMENT_DESC: '% Fulfillment Z-A',
    TARGET_FULLFILMENT_ASC: '% Fulfillment A-Z',
  };

  return label[filterOrderBy];
}

/**
 * @param  {UseTranslator} translator
 * @returns HOFilterOrderByValue[]
 */
export function getHOFilterOrderByValues(
  translator: UseTranslator,
): HOFilterOrderByValue[] {
  return [
    {
      label: translator.translate('Newest Updates'),
      value: HaulingOrderFilterOrderByEnum.UPDATED_AT_DESC,
    },
    {
      label: translator.translate('Oldest Updates'),
      value: HaulingOrderFilterOrderByEnum.UPDATED_AT_ASC,
    },
    {
      label: translator.translate('HO Date A-Z'),
      value: HaulingOrderFilterOrderByEnum.HAULING_ORDER_DATE_ASC,
    },
    {
      label: translator.translate('HO Date Z-A'),
      value: HaulingOrderFilterOrderByEnum.HAULING_ORDER_DATE_DESC,
    },
    {
      label: translator.translate('% Fulfillment A-Z'),
      value: HaulingOrderFilterOrderByEnum.TARGET_FULLFILMENT_ASC,
    },
    {
      label: translator.translate('% Fulfillment Z-A'),
      value: HaulingOrderFilterOrderByEnum.TARGET_FULLFILMENT_DESC,
    },
  ];
}

/**
 * @param  {UseTranslator} translator
 * @returns JOFilterStatusValue[]
 */
export function getHOFilterStatusValues(
  translator: UseTranslator,
): HOFilterStatusValue[] {
  return [
    {
      label: translator.translate('In Process'),
      value: HOFilterStatusEnum.IN_PROCESS,
    },
    {
      label: translator.translate('Assigned'),
      value: HOFilterStatusEnum.ASSIGNED,
    },
    {
      label: translator.translate('Delivering'),
      value: HOFilterStatusEnum.DELIVERING,
    },
    {
      label: translator.translate('Delivered'),
      value: HOFilterStatusEnum.DELIVERED,
    },
  ];
}

/**
 * @param  {HaulingOrderDeliveryTargetUnitEnum} targetUnit
 * @returns ReactNode
 */
export function mapHOAssignmentTargetUnit(
  targetUnit: HaulingOrderDeliveryTargetUnitEnum,
): string {
  const label: Record<HaulingOrderDeliveryTargetUnitEnum, string> = {
    QUANTITY: 'Unit',
    WEIGHT: 'Kg',
    VOLUME: 'm³',
  };

  return label[targetUnit];
}

/**
 * @param  {HaulingOrderDelivery[]} deliveries - from ho.deliveries
 * @returns number
 */
export function getHOTotalTargetPercentage(
  deliveries: HaulingOrderDelivery[],
): number {
  const percentages = deliveries.map(({ currentTarget, target }) => {
    const percentage: number = (currentTarget / target) * 100;
    return percentage >= 100 ? 100 : percentage;
  });

  const sumOfPercentages = percentages.reduce((prev, curr) => prev + curr, 0);
  const totalPercentage = sumOfPercentages / percentages.length;

  return totalPercentage;
}

export const goodsReduceMapper =
  (unit: 'QUANTITY' | 'VOLUME' | 'WEIGHT') =>
  (acc: number, data: HOTrackingGoods) => {
    if (data.unit !== unit) return acc + 0;
    return acc + Number(data?.number);
  };

/**
 * @param  {HOTrackingGoods[]} trackingGoods
 * @returns HOTrackingGoods[]
 */
export function getHOGoodsTotalValue(
  trackingGoods?: HOTrackingGoods[],
): string[] {
  if (!trackingGoods?.length) return ['-'];
  const qty = trackingGoods.reduce(goodsReduceMapper('QUANTITY'), 0) || 0;
  const vol = trackingGoods.reduce(goodsReduceMapper('VOLUME'), 0) || 0;
  const weight = trackingGoods.reduce(goodsReduceMapper('WEIGHT'), 0) || 0;
  return [
    ...(!qty ? [] : [`${numberFormatter(qty)} Unit`]),
    ...(!vol ? [] : [`${numberFormatter(vol)} m³`]),
    ...(!weight ? [] : [`${numberFormatter(weight)} Kg`]),
  ];
}
