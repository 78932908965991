import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BulkActionStatusEnum,
  ForceCompleteHaulingOrderEntity,
} from '../../constant';
import { HaulingOrder } from '../../model/HaulingOrder.model';
import { ApiErrorResponse } from '../../service/api.endpoint';
import api from '../../service/api.service';
import { errorCodeToLabel } from '../../util/error.util';
import useVerifyAuth from '../../view/Wrapper/hooks/useVerifyAuth.hook';

const useHaulingOrderForceCompleteLogic = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { handleVerifyAuth } = useVerifyAuth();

  const [showStatus, setShowStatus] = useState(false);

  const haulingOrdersFromNavState = (state || []) as HaulingOrder[];

  const [haulingOrdersWithStatusDetail, setHaulingOrdersWithStatusDetail] =
    useState<ForceCompleteHaulingOrderEntity[]>(
      haulingOrdersFromNavState.map((v) => ({
        ...v,
        _actionStatus: BulkActionStatusEnum.WAITING,
        _actionDetail: 'Waiting for force complete',
      })),
    );
  const [showDoneBtn, setShowDoneBtn] = useState<boolean>(false);
  const [mutationsIsLoading, setMutationsIsLoading] = useState<boolean>(false);
  const [doForceCompleteHaulingOrder] =
    api.useForceCompleteHaulingOrderMutation();
  // #endregion

  // #region HANDLERS
  const onForceComplete = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    setShowStatus(true);
    const onForceCompletePromise = async () => {
      setMutationsIsLoading(true);

      // synchronous call deactivate haulingOrder mutation
      for (let i = 0; i < haulingOrdersWithStatusDetail.length; i += 1) {
        const { id } = haulingOrdersWithStatusDetail[i];

        try {
          // NOTE: currently our backend can not receive excessive amount of requests in parallel, that is why we used for loop instead of `Promise.allSettled`
          await doForceCompleteHaulingOrder({ hoId: id }).unwrap();

          setHaulingOrdersWithStatusDetail((_haulingOrders) =>
            _haulingOrders.map((_haulingOrder) =>
              _haulingOrder.id === id
                ? {
                    ..._haulingOrder,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Set to Delivered',
                  }
                : _haulingOrder,
            ),
          );
        } catch (err) {
          const errorQuery = (err as FetchBaseQueryError)
            ?.data as ApiErrorResponse;

          setHaulingOrdersWithStatusDetail((_haulingOrders) =>
            _haulingOrders.map((_haulingOrder) =>
              _haulingOrder.id === id
                ? {
                    ..._haulingOrder,
                    _actionStatus: BulkActionStatusEnum.FAILED,
                    _actionDetail: errorCodeToLabel(errorQuery?.error?.code),
                  }
                : _haulingOrder,
            ),
          );
        }
      }

      // on done
      setShowDoneBtn(true);
      setMutationsIsLoading(false);
    };

    await onForceCompletePromise();
  }, [
    doForceCompleteHaulingOrder,
    handleVerifyAuth,
    haulingOrdersWithStatusDetail,
  ]);

  const onCancelOrDone = useCallback(() => {
    navigate('/hauling-order');
  }, [navigate]);

  return {
    haulingOrdersWithStatusDetail,
    showDoneBtn,
    showStatus,
    mutationsIsLoading,
    onForceComplete,
    onCancelOrDone,
  };
};

export type UseHaulingOrderForceCompleteLogicObj = ReturnType<
  typeof useHaulingOrderForceCompleteLogic
>;
export default useHaulingOrderForceCompleteLogic;
