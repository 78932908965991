import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { SOActivityListData } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { Goods } from '../../../model/ShipperOrder.model';
import { shipperOrderRoute } from '../../ShipperOrder/ShipperOrder.route';
import { UseSODetailEditGoodsForm } from './useSODetailEditGoodsForm.hook';

type Props = {
  activityFormData?: SOActivityListData[];
  soDetailUpdateGoodsForm: UseSODetailEditGoodsForm;
};
export type UseSODEditGoodsController = ReturnType<
  typeof useSODEditGoodsController
>;

export default function useSODEditGoodsController({
  activityFormData,
  soDetailUpdateGoodsForm,
}: Props) {
  // #region GENERAL
  const translator = useTranslator();
  const navigate = useNavigate();

  const [showConfirmSubmit, toggleConfirmModal] = useToggle(false);
  // #endregion

  // #region MODAL UPDATE GOODS
  const hasUnsavedChanges = useMemo(
    () =>
      soDetailUpdateGoodsForm?.goodsForm.values?.goods.some((goodIn, i) =>
        Object.keys(goodIn).some((key) => {
          if (!soDetailUpdateGoodsForm.selectedActivity) return false;
          const formattedGoodsOut =
            soDetailUpdateGoodsForm.selectedActivity.soActivity?.goodsOut ||
            soDetailUpdateGoodsForm.selectedActivity.goodsOut;
          const formattedGoods =
            soDetailUpdateGoodsForm.selectedActivity.soActivity?.goods ||
            soDetailUpdateGoodsForm.selectedActivity.goods;

          const goods = !formattedGoodsOut?.length
            ? formattedGoods
            : formattedGoodsOut;
          if (!goods) return '';
          return (
            String(goodIn[key as keyof Goods]) !==
            String(goods[i][key as keyof Goods])
          );
        }),
      ),
    [
      soDetailUpdateGoodsForm.goodsForm.values?.goods,
      soDetailUpdateGoodsForm.selectedActivity,
    ],
  );

  const isGoodsOutUpdate = useMemo(() => {
    if (!soDetailUpdateGoodsForm.selectedActivity) return false;
    return !!soDetailUpdateGoodsForm.selectedActivity.soActivity?.goodsOut
      ?.length;
  }, [soDetailUpdateGoodsForm.selectedActivity]);

  const modalUpdateSubmitLabel = useMemo(
    () => translator.translate(isGoodsOutUpdate ? 'Update' : 'Save'),
    [isGoodsOutUpdate, translator],
  );

  const handleDiscardChanges = useCallback(
    () => navigate(shipperOrderRoute.path),
    [navigate],
  );

  const handleClose = useCallback(() => {
    if (hasUnsavedChanges && !soDetailUpdateGoodsForm.isGoodsDeadlineExceeded) {
      return toggleConfirmModal();
    }
    return soDetailUpdateGoodsForm.handleResetActivityIndex();
  }, [hasUnsavedChanges, soDetailUpdateGoodsForm, toggleConfirmModal]);

  const handleClickOverlay = useCallback(() => {
    if (hasUnsavedChanges && !soDetailUpdateGoodsForm.isGoodsDeadlineExceeded) {
      return toggleConfirmModal();
    }
    return soDetailUpdateGoodsForm.handleResetActivityIndex();
  }, [hasUnsavedChanges, soDetailUpdateGoodsForm, toggleConfirmModal]);
  // #endregion

  // #region MODAL GOODS FORM

  const isModalGoodsDisabled = useMemo(
    () =>
      !soDetailUpdateGoodsForm.isEditable ||
      soDetailUpdateGoodsForm.isGoodsDeadlineExceeded,
    [
      soDetailUpdateGoodsForm.isEditable,
      soDetailUpdateGoodsForm.isGoodsDeadlineExceeded,
    ],
  );
  const isSaveDisabled = useMemo(
    () =>
      soDetailUpdateGoodsForm.goodsForm.goodsFormIsInvalid ||
      !hasUnsavedChanges ||
      !soDetailUpdateGoodsForm.isEditable ||
      soDetailUpdateGoodsForm.isGoodsDeadlineExceeded,
    [
      hasUnsavedChanges,
      soDetailUpdateGoodsForm.goodsForm.goodsFormIsInvalid,
      soDetailUpdateGoodsForm.isEditable,
      soDetailUpdateGoodsForm.isGoodsDeadlineExceeded,
    ],
  );

  const modalGoodsFormTitle = useMemo(
    () =>
      translator.translate(
        isGoodsOutUpdate
          ? 'Edit Goods Delivered by Driver'
          : 'Add Goods Delivered by Driver',
      ),
    [isGoodsOutUpdate, translator],
  );
  // #endregion

  // #region UNSAVE CHANGES ALERT MODAL
  const alertTitle = translator.translate('Are you sure you want to leave?');
  const alertLabel = translator.translate('Save Changes');
  const alertCancelLabel = translator.translate('Discard Changes');
  const alertDescription = translator.translate(
    'It looks like you are in the middle of writing something and you have not saved the changes.  Unsaved changes will be lost',
  );
  const isUnsavedChangesAlert = useMemo(
    () =>
      !!hasUnsavedChanges && !soDetailUpdateGoodsForm.isGoodsDeadlineExceeded,
    [hasUnsavedChanges, soDetailUpdateGoodsForm.isGoodsDeadlineExceeded],
  );
  // #endregion

  // #region CONFIRM MODAL
  const isConfirmModalVisible = useMemo(
    () =>
      !!showConfirmSubmit && !soDetailUpdateGoodsForm.isGoodsDeadlineExceeded,
    [soDetailUpdateGoodsForm.isGoodsDeadlineExceeded, showConfirmSubmit],
  );
  const confirmTitle = translator.translate('Unsaved changes');
  const cancelActionLabel = translator.translate('Cancel');

  const isConfirmActionDisabled = useMemo(
    () =>
      soDetailUpdateGoodsForm?.goodsForm?.goodsFormIsInvalid ||
      soDetailUpdateGoodsForm.isGoodsDeadlineExceeded,
    [
      soDetailUpdateGoodsForm?.goodsForm?.goodsFormIsInvalid,
      soDetailUpdateGoodsForm.isGoodsDeadlineExceeded,
    ],
  );

  const handleConfirmAction = useCallback(() => {
    soDetailUpdateGoodsForm.goodsForm.handleSubmit();
    toggleConfirmModal();
  }, [soDetailUpdateGoodsForm.goodsForm, toggleConfirmModal]);

  const handleCancelAction = useCallback(() => {
    soDetailUpdateGoodsForm.handleResetActivityIndex();
    toggleConfirmModal();
  }, [soDetailUpdateGoodsForm, toggleConfirmModal]);
  // #endregion

  const handleChangeActivityIndex = useCallback(
    (index: number, isGoodsOut?: boolean) => {
      const activity = activityFormData?.find((_, i) => index === i);
      if (activity) {
        soDetailUpdateGoodsForm.goodsForm.setInitialGoodsValue(
          isGoodsOut && activity.goodsOut.length > 0
            ? activity.goodsOut
            : activity.goods,
        );
        soDetailUpdateGoodsForm.setSelectedActivityIndex(index);
      }
    },
    [activityFormData, soDetailUpdateGoodsForm],
  );

  return {
    alertCancelLabel,
    alertDescription,
    alertLabel,
    alertTitle,
    confirmTitle,
    isConfirmModalVisible,
    isGoodsOutUpdate,
    isModalGoodsDisabled,
    isSaveDisabled,
    isConfirmActionDisabled,
    isUnsavedChangesAlert,
    modalGoodsFormTitle,
    modalUpdateSubmitLabel,
    showConfirmSubmit,
    cancelActionLabel,
    toggleConfirmModal,
    handleClose,
    handleClickOverlay,
    handleChangeActivityIndex,
    handleDiscardChanges,
    handleResetActivityIndex: soDetailUpdateGoodsForm.handleResetActivityIndex,
    handleConfirmAction,
    handleCancelAction,
  };
}
