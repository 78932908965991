import React from 'react';

export default function PickUpOnTimeBG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="130"
      fill="none"
      viewBox="0 0 71 130"
    >
      <g stroke="#E4E0CC" clipPath="url(#clip0_1881_183650)">
        <circle cx="64.979" cy="71.979" r="35.871" strokeWidth="2.3" />
        <path strokeWidth="2.3" d="M64.754 34.957L64.754 22" />
        <path strokeWidth="2.3" d="M45.517 41.751L41.77 35.261" />
        <path strokeWidth="2.3" d="M53.601 21.317L76.085 21.317" />
        <path
          strokeLinecap="round"
          strokeWidth="2.4"
          d="M65.441 49.303v24.84a2 2 0 01-2 2H45.542"
        />
        <circle cx="36" cy="97" r="15.85" fill="#fff" strokeWidth="2.3" />
        <path
          strokeLinecap="round"
          strokeWidth="2.4"
          d="M29 97l4.693 5.631a.4.4 0 00.614 0L44 91"
        />
      </g>
      <defs>
        <clipPath id="clip0_1881_183650">
          <path fill="#fff" d="M0 0H70V130H0z" transform="translate(.667)" />
        </clipPath>
      </defs>
    </svg>
  );
}
