import { Locale } from './locale.config';

const driverEditLocaleConfig: Locale[] = [
  {
    en: 'Edit Driver',
    id: 'Ubah Sopir',
  },
  {
    en: 'Driver has been edited',
    id: 'Sopir berhasil diubah',
  },
  {
    en: "Reset Driver's PIN",
    id: 'Reset PIN Sopir',
  },
  {
    en: "Driver's PIN has been reset",
    id: 'PIN Sopir sudah di reset',
  },
];

export default driverEditLocaleConfig;
