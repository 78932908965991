import React from 'react';
import tw from 'twin.macro';
import { LabelInput, Row } from '../../atom';
import Select, { OptionType } from '../Select/Select.molecule';

type Props = {
  label?: string;
  isRequired?: boolean;
  id: string;
  values?: string;
  error?: string;
  placeholder?: string;
  options?: OptionType[];
  disabled?: boolean;
  onChange: (data?: string) => void;
};

const Container = tw.div`w-[calc(100% - 200px)]`;

export default function SelectInput({
  id,
  values = '',
  label,
  isRequired,
  error,
  disabled,
  placeholder,
  options,
  onChange,
}: Props) {
  return (
    <Row>
      {label && <LabelInput label={label} isRequired={isRequired} />}
      <Container>
        <Select
          placeholder={placeholder}
          id={id}
          name={id}
          css={tw`bg-orange-dark-lines`}
          tw="bg-orange-dark-lines"
          options={options || []}
          value={values}
          disabled={disabled}
          onChange={onChange}
          error={error || ''}
        />
      </Container>
    </Row>
  );
}
