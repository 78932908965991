import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { TableNoData } from '../../../component/atom';
import { NoDataFound } from '../../../component/atom/Icon/Icon.atom';
import {
  ButtonDropdownOutline,
  JODetailSOActivityItem,
  JODetailSOListItem,
} from '../../../component/molecule';
import { TableHeaderItem } from '../../../component/molecule/ActivityTableHeader/ActivityTableHeader.molecule';
import { ButtonDropdownAction } from '../../../component/molecule/ButtonDropdownOutline/ButtonDropdownOutline.molecule';
import { SOActivityType, SOStatus } from '../../../constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { Photo } from '../../../model/Common.model';
import {
  JOActivityInfo,
  JobOrderInfoDelivery,
  JOStatus,
} from '../../../model/JobOrder.model';
import { mapJODetailSOActivityTypeToLabel } from '../../../util/jobOrder.util';
import { getJODeliveryStatusLabel } from '../../../util/shipperOrder.util';

// #region INTERFACES
type Props = {
  joStatus: JOStatus;
  translator: UseTranslator;
  deliveries: JobOrderInfoDelivery[];
  selectedDelivery: string;
  selectedDeliveryItem?: JobOrderInfoDelivery;
  activityTableHeaders: TableHeaderItem[];
  forceCompleteSOLoading?: boolean;
  onSelectDeliveryItem(deliveryId: string): void;
  checkForceLoadingSo(joStatus: JOStatus, soStatus: SOStatus): boolean;
  onImagesClick(photos: Photo[], index: number, activityindex: number): void;
  onDownloadClick(photos: Photo[], fileName: string): void;
  forceCompleteActions: (
    delivery: JobOrderInfoDelivery,
  ) => ButtonDropdownAction[];
};
// #endregion

// #region STYLED
const Container = tw.div`grid grid-cols-3 relative`;
const ListContainer = tw.div`col-span-1 flex-col gap-2.5 p-5 border-r-2 border-r-beige-lines h-[710px] overflow-auto`;
const ActivityContainer = tw.div`col-span-2 flex-col p-5 h-[710px] overflow-auto pb-20`;
const FooterContainer = tw.footer`bg-white absolute bottom-2.5 right-0 -mb-2.5 border-t-2 border-t-beige-lines py-5 w-2/3 `;
const FooterColumn = tw.div`flex justify-end`;
// #endregion

export default function JODetailSO({
  translator,
  deliveries,
  selectedDelivery,
  selectedDeliveryItem,
  activityTableHeaders,
  onSelectDeliveryItem,
  joStatus,
  onDownloadClick,
  checkForceLoadingSo,
  onImagesClick,
  forceCompleteSOLoading,
  forceCompleteActions,
}: Props) {
  const isSOTransit = selectedDeliveryItem?.shipperOrder.isTransitable;
  const formattedArr = useMemo(() => {
    if (!selectedDeliveryItem?.activities.length) return [];
    return selectedDeliveryItem?.activities
      .slice()
      .sort((a, b) => a.index - b.index)
      .map((item, idx) => {
        if (!isSOTransit) return item;
        const goodsData =
          selectedDeliveryItem.shipperOrder.activities[idx].goods;
        const formattedActivity: JOActivityInfo = {
          ...item,
          soActivity: {
            index: idx,
            location: item.soActivity?.location,
            type: idx === 0 ? SOActivityType.PICK_UP : SOActivityType.DROP_OFF,
            goods: goodsData,
          },
        };
        return formattedActivity;
      });
  }, [
    isSOTransit,
    selectedDeliveryItem?.activities,
    selectedDeliveryItem?.shipperOrder.activities,
  ]);

  const hasDeliveries = !!deliveries.length;
  if (!hasDeliveries)
    return (
      <Container tw="flex items-center">
        <TableNoData
          descriptionStyle={tw`whitespace-normal`}
          icon={<NoDataFound width={470} />}
          titleLabel={translator.translate('There is no Shipper Order')}
          descriptionLabel={translator.translate(
            'No Shipper Order selected for this Job Order',
          )}
        />
      </Container>
    );
  return (
    <Container>
      <ListContainer>
        {deliveries.map((delivery) => (
          <JODetailSOListItem
            key={delivery.id}
            joStatus={joStatus}
            delivery={delivery}
            isSelected={delivery.id === selectedDelivery}
            soStatusLabel={translator.translate(
              getJODeliveryStatusLabel(delivery.status),
            )}
            onSelectDeliveryItem={() => onSelectDeliveryItem(delivery.id)}
            forceLoading={checkForceLoadingSo(
              joStatus,
              delivery.shipperOrder.status,
            )}
          />
        ))}
      </ListContainer>

      <ActivityContainer>
        {formattedArr.map((activity, index) => (
          <JODetailSOActivityItem
            onDownloadClick={(photos) =>
              onDownloadClick(
                photos,
                `${
                  selectedDeliveryItem?.shipperOrder?.number || ''
                } - activity-${index + 1}`,
              )
            }
            translator={translator}
            key={activity.id}
            index={index + 1}
            activity={activity}
            activityTypeLabel={mapJODetailSOActivityTypeToLabel({
              activityType: activity.type || activity.soActivity?.type,
              translator,
            })}
            activityTableHeaders={activityTableHeaders}
            onImagesClick={onImagesClick}
          />
        ))}
      </ActivityContainer>

      {selectedDeliveryItem &&
        [SOStatus.ASSIGNED, SOStatus.DELIVERING, SOStatus.TRANSITING].includes(
          selectedDeliveryItem?.shipperOrder.status,
        ) &&
        joStatus !== JOStatus.DELIVERED && (
          <FooterContainer>
            <FooterColumn>
              <ButtonDropdownOutline
                disabled={forceCompleteSOLoading}
                label={translator.translate('Force Complete')}
                actions={forceCompleteActions(selectedDeliveryItem)}
              />
            </FooterColumn>
          </FooterContainer>
        )}
    </Container>
  );
}
