import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { Location, useNavigate, useSearchParams } from 'react-router-dom';
import { useHash, useMount } from 'react-use';
import useIntersectionObserver from '../../../hook/useIntersectionObserver.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { doRedirectUrl, scrollToTop } from '../../../util/helper.util';
import { handleScrollIntoViewLegal } from '../../../util/legal.util';
import { legalRoute } from '../Legal.route';
import {
  LegalNavAnchor,
  LegalNavMenuProps,
  LegalNavSection,
} from '../Legal.viewModel';

export interface LegalRefProps {
  legalTopRef: MutableRefObject<HTMLDivElement | null>;
  ownershipRef: MutableRefObject<HTMLDivElement | null>;
  websiteRef: MutableRefObject<HTMLDivElement | null>;
  limitationRef: MutableRefObject<HTMLDivElement | null>;
  secrecyRef: MutableRefObject<HTMLDivElement | null>;
}

interface UseLegalMenuProps extends LegalRefProps {
  translator: UseTranslator;
  location: Location;
}

function useLegalMenu({
  translator,
  location: locationNav,
  ownershipRef,
  websiteRef,
  limitationRef,
  secrecyRef,
}: UseLegalMenuProps) {
  const [params] = useSearchParams();
  const hash = useHash();
  const legalNav = useMemo(
    () => locationNav.state || params.get('menu')?.toUpperCase(),
    [locationNav.state, params],
  );
  const isWebview = useMemo(() => {
    const ref = params.get('ref');
    return ref === 'webview';
  }, [params]);
  const navigate = useNavigate();
  const [legalState, setLegalState] = useState(
    legalNav || LegalNavSection.DISCLAIMER,
  );
  const ownershipIntersectionObserver = useIntersectionObserver(
    ownershipRef,
    {},
  );
  const websiteIntersectionObserver = useIntersectionObserver(websiteRef, {});
  const limitationIntersectionObserver = useIntersectionObserver(
    limitationRef,
    {},
  );
  const secrecyIntersectionObserver = useIntersectionObserver(secrecyRef, {});

  const hashToAnchor = (_hash?: string) => {
    const literal: Record<string, number | undefined> = {
      [LegalNavAnchor.SECRECY]: (secrecyRef?.current?.offsetTop || 0) - 120,
      [LegalNavAnchor.OWNERSHIP]: (ownershipRef?.current?.offsetTop || 0) - 100,
      [LegalNavAnchor.WEBSITE]: (websiteRef?.current?.offsetTop || 0) - 200,
      [LegalNavAnchor.LIMITATION]:
        (limitationRef?.current?.offsetTop || 0) - 500,
    };
    if (!_hash || !literal[_hash]) return scrollToTop();
    return handleScrollIntoViewLegal({ offsetTop: literal[_hash] });
  };

  useEffect(() => {
    if (legalState === legalNav || !legalNav) return;
    if (legalNav !== LegalNavSection.TERMS) {
      scrollToTop();
    }
    setLegalState(legalNav);
  }, [legalNav, legalState]);

  useMount(() => {
    if (legalState !== LegalNavSection.TERMS) return;
    if (!hash.length) return;
    hashToAnchor(hash[0] as LegalNavAnchor);
  });

  const legalNavMenuItems: LegalNavMenuProps[] = [
    {
      title: translator.translate('Disclaimer'),
      path: `${legalRoute.path}?menu=${LegalNavSection.DISCLAIMER}`,
      isSelected: legalState === LegalNavSection.DISCLAIMER,
      onClick: () => {
        scrollToTop();
        navigate(legalRoute.path, { state: LegalNavSection.DISCLAIMER });
      },
    },
    {
      title: translator.translate('Terms of Use'),
      path: `${legalRoute.path}?menu=${LegalNavSection.TERMS}`,
      isSelected: legalState === LegalNavSection.TERMS,
      onClick: () => {
        scrollToTop();
        navigate(legalRoute.path, { state: LegalNavSection.TERMS });
      },
      subMenu: [
        {
          title: translator.translate('Kerahasiaan'),
          isSelected:
            legalState === LegalNavSection.TERMS &&
            !!secrecyIntersectionObserver?.isIntersecting,
          onClick: () => {
            if (legalState === LegalNavSection.TERMS) {
              handleScrollIntoViewLegal({
                offsetTop: (secrecyRef?.current?.offsetTop || 0) - 72,
              });

              return;
            }
            doRedirectUrl({
              url: `${legalRoute.path}?menu=${LegalNavSection.TERMS}${LegalNavAnchor.SECRECY}`,
            });
          },
        },
        {
          title: translator.translate('Hak Kepemilikan'),
          isSelected:
            legalState === LegalNavSection.TERMS &&
            !!ownershipIntersectionObserver?.isIntersecting,
          onClick: () => {
            if (legalState === LegalNavSection.TERMS) {
              handleScrollIntoViewLegal({
                offsetTop: (ownershipRef?.current?.offsetTop || 0) - 72,
              });

              return;
            }
            doRedirectUrl({
              url: `${legalRoute.path}?menu=${LegalNavSection.TERMS}${LegalNavAnchor.OWNERSHIP}`,
            });
          },
        },
        {
          title: translator.translate('Penggunaa Website dan Software Layanan'),
          isSelected:
            legalState === LegalNavSection.TERMS &&
            !!websiteIntersectionObserver?.isIntersecting,
          onClick: () => {
            if (legalState === LegalNavSection.TERMS) {
              handleScrollIntoViewLegal({
                offsetTop: (websiteRef?.current?.offsetTop || 0) - 72,
              });

              return;
            }
            doRedirectUrl({
              url: `${legalRoute.path}?menu=${LegalNavSection.TERMS}${LegalNavAnchor.WEBSITE}`,
            });
          },
        },
        {
          title: translator.translate('Pembatasan Tanggung Jawab'),
          isSelected:
            legalState === LegalNavSection.TERMS &&
            !!limitationIntersectionObserver?.isIntersecting,
          onClick: () => {
            if (legalState === LegalNavSection.TERMS) {
              handleScrollIntoViewLegal({
                offsetTop: (limitationRef?.current?.offsetTop || 0) - 72,
              });

              return;
            }
            doRedirectUrl({
              url: `${legalRoute.path}?menu=${LegalNavSection.TERMS}${LegalNavAnchor.LIMITATION}`,
            });
          },
        },
      ],
    },
    {
      title: translator.translate('Privacy Policy'),
      path: `${legalRoute.path}?menu=${LegalNavSection.PRIVACY}`,
      isSelected: legalState === LegalNavSection.PRIVACY,
      onClick: () => {
        scrollToTop();
        navigate(legalRoute.path, { state: LegalNavSection.PRIVACY });
      },
    },
  ];
  return { legalState, legalNavMenuItems, isWebview };
}

export default useLegalMenu;
