import endOfToday from 'date-fns/endOfToday';
import startOfToday from 'date-fns/startOfToday';
import subDays from 'date-fns/subDays';

// #region INTERFACES
export enum ReportRevenueOrderByEnum {
  SHIPPER_NAME_ASC = 'SHIPPER_NAME_ASC',
  SHIPPER_NAME_DESC = 'SHIPPER_NAME_DESC',
  INVOICE_AMOUNT_DESC = 'INVOICE_AMOUNT_DESC',
  RECEIVABLE_AMOUNT_DESC = 'RECEIVABLE_AMOUNT_DESC',
  TOTAL_PAYMENT_DESC = 'TOTAL_PAYMENT_DESC',
  TOTAL_ORDER_ASC = 'TOTAL_ORDER_ASC',
  TOTAL_ORDER_DESC = 'TOTAL_ORDER_DESC',
}

export type ReportRevenueOrderByValue = {
  label: string;
  value: ReportRevenueOrderByEnum;
};

export type ReportRevenueFilterInitialValues = {
  orderBy: ReportRevenueOrderByEnum;
  startDate: Date;
  endDate: Date;
  shipperId?: string;
};
// #endregion

export const reportRevenueDateRangeFilterInitialValues: Pick<
  ReportRevenueFilterInitialValues,
  'startDate' | 'endDate'
> = {
  startDate: subDays(startOfToday(), 29),
  endDate: endOfToday(),
};

export const reportRevenueFilterInitialValues: ReportRevenueFilterInitialValues =
  {
    ...reportRevenueDateRangeFilterInitialValues,
    orderBy: ReportRevenueOrderByEnum.SHIPPER_NAME_ASC,
    shipperId: undefined,
  };

export const reportRevenueMaxDateRangeInDays = 366;
