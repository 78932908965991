import fromUnixTime from 'date-fns/fromUnixTime';
import React from 'react';
import tw from 'twin.macro';
import { SOActivityType, soListOfActivityTypes } from '../../../constant';
import soActivityColumns from '../../../constant/Goods.constant';
import { AutocompleteType } from '../../../hook/useAutocomplete.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { SOUpdateActivitiesProps } from '../../../model/ShipperOrder.model';
import { getTimeOffset } from '../../../util/date.util';
import { numberFormatter } from '../../../util/formatter.util';
import { promiseToVoid } from '../../../util/helper.util';
import {
  getActivityLocationLabelByType,
  getActivityLocationOption,
  getGoodsTableTotal,
} from '../../../util/shipperOrderCreate.util';
import { Button, Chip, Icon } from '../../atom';
import {
  AutoComplete,
  CollapsiblePanel,
  DatePickerInput,
  InputGroup,
  SOActivityTable,
} from '../../molecule';
import { CollapsiblePanelAction } from '../../molecule/CollapsiblePanel/CollapsiblePanel.molecule';
import { OptionType } from '../../molecule/Select/Select.molecule';

export interface Props {
  activities: SOUpdateActivitiesProps[];
  activityItem: SOUpdateActivitiesProps;
  index: number;
  isLocationListFetchLoading?: boolean;
  locationOptions?: AutocompleteType[];
  panelAction?: CollapsiblePanelAction;
  panelAdditionalAction?: CollapsiblePanelAction;
  soDate?: number;
  translator: UseTranslator;
  handleAddNewLocation: () => void;
  handleChangeDataLocation: (
    index: number,
    option?: OptionType,
  ) => Promise<void>;
  handleChangeDateActivity: (
    index: number,
    date?: Date | undefined,
  ) => Promise<void>;
  handleChangeLocationAutotext: (val?: string) => void;
  handleClickChoice: (
    index: number,
    currentChoice: SOActivityType,
  ) => Promise<void>;
  handleClickEditActivity: (index: number) => void;
  handleFetchMoreLocation: () => void;
  handleRemoveLocation: (index: number) => void;
  getActivityFormErrors: (
    index: number,
    key:
      | 'locationId'
      | 'goods'
      | 'expectedFinishAt'
      | 'index'
      | 'type'
      | 'location',
  ) => string;
}

export default function SOActivityFormItem({
  activities,
  activityItem,
  index,
  isLocationListFetchLoading,
  locationOptions,
  panelAction,
  panelAdditionalAction,
  soDate,
  translator,
  handleChangeDataLocation,
  handleChangeDateActivity,
  handleChangeLocationAutotext,
  handleClickChoice,
  handleClickEditActivity,
  handleFetchMoreLocation,
  handleRemoveLocation,
  handleAddNewLocation,
  getActivityFormErrors,
}: Props) {
  return (
    <CollapsiblePanel
      key={`activity-item-${activityItem.index}-${index}`}
      title={`${translator.translate('Activity')} ${index + 1}`}
      action={panelAction}
      additionalAction={panelAdditionalAction}
    >
      <div tw="grid gap-3">
        <InputGroup
          hasDivider
          label={translator.translate('Activity Type')}
          input={
            <div tw="flex">
              {soListOfActivityTypes.map((c) => (
                <Chip.Small
                  key={`activity-type-${c.label.toLowerCase()}`}
                  css={[
                    activityItem.type === c.id && tw`border-white text-orange`,
                    tw`w-max mr-2 last:mr-0`,
                  ]}
                  label={translator.translate(c.label)}
                  selected={activityItem.type === c.id}
                  onClick={() =>
                    promiseToVoid([handleClickChoice(index, c.id)])
                  }
                />
              ))}
            </div>
          }
        />

        <InputGroup
          hasDivider
          required
          containerStyle={tw`items-start`}
          labelContainerStyle={tw`min-w-[180px] mt-2.5`}
          label={translator.translate(
            getActivityLocationLabelByType(activityItem.type || ''),
          )}
          input={
            <div>
              <AutoComplete
                changeData={(data) =>
                  promiseToVoid([handleChangeDataLocation(index, data)])
                }
                placeholder={translator.translate(
                  getActivityLocationLabelByType(activityItem.type || ''),
                )}
                loading={isLocationListFetchLoading}
                changeInputText={handleChangeLocationAutotext}
                id="locationId"
                onFetchMore={handleFetchMoreLocation}
                datas={locationOptions}
                name="locationId"
                selectedOption={getActivityLocationOption(activities, index)}
                onRemoveData={() => handleRemoveLocation(index)}
                tw="bg-orange-dark-lines"
                value={activityItem.locationId}
                error={translator.translate(
                  getActivityFormErrors(index, 'locationId'),
                )}
              />
              <div tw="mt-2">
                <Button.Icon
                  icon={<Icon.Add />}
                  label={translator.translate('Add New Location')}
                  action={handleAddNewLocation}
                />
              </div>
            </div>
          }
        />
        <InputGroup
          hasDivider
          label={translator.translate('Expected Time')}
          input={
            <DatePickerInput
              placeholder={translator.translate('Expected Time')}
              dateValue={
                activityItem.expectedFinishAt
                  ? fromUnixTime(activityItem.expectedFinishAt)
                  : undefined
              }
              dateFormat={`dd MMM yyyy, HH:mm '${getTimeOffset()}'`}
              changeData={(date) => {
                promiseToVoid([handleChangeDateActivity(index, date)]);
              }}
              disableTimePicker={false}
              disabledDays={{
                before: soDate ? new Date(soDate * 1000) : new Date(),
              }}
            />
          }
        />

        {[SOActivityType.PICK_UP, SOActivityType.DROP_OFF].includes(
          activityItem.type,
        ) && (
          <CollapsiblePanel
            action={{
              click: () => handleClickEditActivity(index),
              icon: <Icon.EditOutlined />,
              text: translator.translate('Edit'),
            }}
            title={translator.translate('Goods')}
          >
            <SOActivityTable
              columns={soActivityColumns}
              rows={activityItem?.goods || []}
              total={{
                label: translator.translate('Total'),
                volume: numberFormatter(
                  getGoodsTableTotal('volume', activityItem.goods),
                ),
                weight: numberFormatter(
                  getGoodsTableTotal('weight', activityItem.goods),
                ),
              }}
              translator={translator}
            />
          </CollapsiblePanel>
        )}
      </div>
    </CollapsiblePanel>
  );
}
