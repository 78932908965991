import addShipperLocaleConfig from './addShipper.locale.config';
import addTemplatePageLocaleConfig from './addTemplatePage.locale.config';
import bulkActionLocaleConfig from './bulkAction.locale.config';
import createJobOrderLocaleConfig from './createJobOrder.locale.config';
import createShipperOrderLocaleConfig from './createShipperOrder.locale.config';
import detailJoOrderLocaleConfig from './detailJobOrder.locale.config';
import driverLocaleConfig from './driver.locale.config';
import driverEditLocaleConfig from './driverEdit.locale.config';
import driverListLocaleConfig from './driverList.locale.config';
import drivingContestLocaleConfig from './drivingContest.locale';
import editJobOrderLocaleConfig from './editJobOrder.locale.config';
import editLocationPageLocaleConfig from './EditLocationPage.locale.config';
import errorsLocaleConfig from './errors.locale.config';
import filterLocaleConfig from './filter.locale.config';
import filterScheduleLocaleConfig from './filterSchedule.locale.config';
import goodsLocaleConfig from './goods.locale.config';
import haulingOrderLocaleConfig from './haulingOrder.locale';
import helpCenterLocaleConfig from './helpCenter.locale.config';
import hoCreateLocaleConfig from './hoCreate.locale.config';
import homeLocaleConfig from './home.locale.config';
import joListLocaleConfig from './joList.locale.config';
import legalLocaleConfig from './legal.locale.config';
import listLocaleConfig from './list.locale.config';
import locationLocaleConfig from './location.locale.config';
import locationListLocaleConfig from './locationList.locale.config';
import mapLocaleConfig from './map.locale.config';
import noDataLocaleConfig from './noDataList.locale.config';
import othersLocaleConfig from './others.locale.config';
import privacyPolicyLocaleConfig from './privacyPolicy.locale.config';
import reportLocaleConfig from './report.locale.config';
import schedulingListLocaleConfig from './schedulingList.locale.config';
import settingLocaleConfig from './setting.locale.config';
import shipperLocaleConfig from './shipper.locale.config';
import shipperEditLocaleConfig from './shipperEdit.locale.config';
import shipperListLocaleConfig from './shipperList.locale.config';
import shipperOrderLocaleConfig from './shipperOrder.locale.config';
import shipperOrderDetailLocale from './shipperOrderDetail.locale';
import sidebarMenuLocaleConfig from './sidebarMenu.locale.config';
import soListLocaleConfig from './soList.locale.config';
import statusLocaleConfig from './status.locale.config';
import templateListLocaleConfig from './templateList.locale.config';
import trackingLocaleConfig from './tracking.locale.config';
import trackingOrderLocaleConfig from './trackingOrder.locale.config';
import vehicleLocaleConfig from './vehicle.locale.config';
import viewJOLocaleConfig from './viewJO.locale.config';

export enum SupportedLanguage {
  en = 'en',
  id = 'id',
}

export type Locale = {
  [key in SupportedLanguage]: string;
};

const localeConfig: Locale[] = [
  ...addShipperLocaleConfig,
  ...addTemplatePageLocaleConfig,
  ...createJobOrderLocaleConfig,
  ...createShipperOrderLocaleConfig,
  ...detailJoOrderLocaleConfig,
  ...driverEditLocaleConfig,
  ...driverListLocaleConfig,
  ...driverLocaleConfig,
  ...editLocationPageLocaleConfig,
  ...errorsLocaleConfig,
  ...filterLocaleConfig,
  ...filterScheduleLocaleConfig,
  ...goodsLocaleConfig,
  ...homeLocaleConfig,
  ...joListLocaleConfig,
  ...legalLocaleConfig,
  ...listLocaleConfig,
  ...locationListLocaleConfig,
  ...noDataLocaleConfig,
  ...othersLocaleConfig,
  ...othersLocaleConfig,
  ...reportLocaleConfig,
  ...schedulingListLocaleConfig,
  ...settingLocaleConfig,
  ...shipperEditLocaleConfig,
  ...shipperListLocaleConfig,
  ...shipperOrderDetailLocale,
  ...sidebarMenuLocaleConfig,
  ...soListLocaleConfig,
  ...statusLocaleConfig,
  ...templateListLocaleConfig,
  ...viewJOLocaleConfig,
  ...mapLocaleConfig,
  ...editJobOrderLocaleConfig,
  ...hoCreateLocaleConfig,
  ...haulingOrderLocaleConfig,
  ...privacyPolicyLocaleConfig,
  ...trackingOrderLocaleConfig,
  ...trackingLocaleConfig,
  ...drivingContestLocaleConfig,
  ...helpCenterLocaleConfig,
  ...bulkActionLocaleConfig,
  ...vehicleLocaleConfig,
  ...shipperLocaleConfig,
  ...locationLocaleConfig,
  ...shipperOrderLocaleConfig,
];

export default localeConfig;
