import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../app/store/store.app';
import {
  detailsInitialState,
  DetailsState,
} from '../../constant/Onboarding.constant';
import { onboardingAction } from '../../store/onboarding.store';
import { homeRoute } from '../../view/Home/Home.route';
import useHomeOnboarding from '../home/useHomeOnboarding.hook';
import { UseTranslator } from '../useTranslator.hook';

type UseTopbarOnboardingProps = {
  translator: UseTranslator;
};

function useTopbarOnboarding({ translator }: UseTopbarOnboardingProps) {
  const homeOnboarding = useHomeOnboarding({ translator });

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const session = useSelector((_state: RootState) => _state.session);
  const onboardingRef = useRef<HTMLDivElement>();
  const [showOnboardingPopup, setShowOnboardingPopup] = useState(false);
  // state is necessary to trigger change of details styles
  const [details, setDetails] = useState<DetailsState>(detailsInitialState);

  const isHomeRoute = pathname === homeRoute.path;

  const handleClickAccordion = useCallback((_details: DetailsState) => {
    setDetails(_details);
  }, []);
  const handleToggleOnboardingPopup = () => setShowOnboardingPopup((v) => !v);
  const handleCloseOnboardingPopup = () => setShowOnboardingPopup(false);

  const handleStopOnboarding = () => {
    dispatch(onboardingAction.resetTopbar());
  };

  const handleContinueOnboarding = () => {
    dispatch(
      onboardingAction.changeTopbar({
        stepIndex: Number(homeOnboarding.state.topbar.stepIndex) + 1,
      }),
    );
  };

  const handleFinishOnboarding = () => {
    dispatch(
      onboardingAction.changeTopbar({
        tourActive: false,
        run: false,
        stepIndex: 0,
        done: true,
      }),
    );
  };

  useEffect(() => {
    let id: NodeJS.Timeout;

    if (
      session.isLoggedIn &&
      pathname !== '/' &&
      pathname !== homeRoute.path &&
      !homeOnboarding.state.topbar.done &&
      homeOnboarding.state.topbar.tourActive
    ) {
      // NOTE: workaround because the react joyride animation/styling kinda stuck
      id = setTimeout(() => {
        dispatch(onboardingAction.changeTopbar({ run: true }));
      }, 500);
    }

    return () => clearTimeout(id);
  }, [
    dispatch,
    homeOnboarding.state.topbar.done,
    homeOnboarding.state.topbar.tourActive,
    pathname,
    session.isLoggedIn,
  ]);

  return {
    homeOnboarding,
    onboardingRef,
    showOnboardingPopup,
    details,
    isHomeRoute,
    handleClickAccordion,
    handleToggleOnboardingPopup,
    handleCloseOnboardingPopup,
    handleStopOnboarding,
    handleContinueOnboarding,
    handleFinishOnboarding,
  };
}

export type UseTopbarOnboardingHookObj = ReturnType<typeof useTopbarOnboarding>;
export default useTopbarOnboarding;
