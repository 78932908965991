import React from 'react';
import tw from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import { SOStatus } from '../../../constant';
import { UseTrackingSOByCodeHookObj } from '../../../hook/tracking/useTrackingSOByCode.hook';
import TrackingMapView from '../../../view/TrackingMap/TrackingMap.view';
import { LoadingIndicator } from '../../atom';
import SODetailMobileBottomSheet from '../SODetailMobileBottomSheet/SODetailMobileBottomSheet.organism';
import TrackingNumberNotFound from '../TrackingNotFound/TrackingNotFound.organism';

const MapContainer = tw.div`h-[100vh] w-full bg-assigned-light relative`;
const LoadingContainer = tw.div`h-[100vh] flex items-center justify-center`;

interface Props {
  soId?: string;
  headerTitle?: string;
  trackingSO: Omit<UseTrackingSOByCodeHookObj, 'handleGetSOByTrackingCode'>;
  trackingCode?: string;
  shipperPhoneNumber?: string | null;
  handleCopyClick: () => void;
}

export default function SODetailMobileTracking({
  soId,
  trackingSO,
  trackingCode,
  headerTitle,
  shipperPhoneNumber,
  handleCopyClick,
}: Props) {
  if (trackingSO.isLoading || trackingSO.isUninitialized)
    return (
      <LoadingContainer>
        <LoadingIndicator size="small" />
      </LoadingContainer>
    );
  if (!trackingSO.data?.tracking)
    return <TrackingNumberNotFound initialValue={trackingCode} />;
  return (
    <MapContainer>
      <TrackingMapView
        runInterval={trackingSO.data?.tracking.status === SOStatus.DELIVERING}
        tracking={trackingSO.data?.tracking}
        forceLang={!soId ? SupportedLanguage.id : undefined}
        trackingCode={trackingCode || ''}
      />
      <SODetailMobileBottomSheet
        soId={soId}
        shipperPhoneNumber={shipperPhoneNumber}
        headerTitle={headerTitle}
        tracking={trackingSO.data?.tracking}
        trackingCode={trackingCode}
        handleCopyClick={handleCopyClick}
      />
    </MapContainer>
  );
}
