import { createSlice } from '@reduxjs/toolkit';

// #region INTERFACE
export type VisaStore = {
  verified: boolean;
};

// #endregion

const initialState: VisaStore = {
  verified: false,
};

const visaSlice = createSlice({
  name: 'visa',
  initialState,
  reducers: {
    toggleVerified: (state) => ({
      ...state,
      verified: true,
    }),
  },
});

export const visaAction = visaSlice.actions;
export const visaReducer = visaSlice.reducer;
