import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PUBLIC_ROUTE } from '../../constant/Common.constant';
import { helpCenterRoute } from '../../view/HelpCenter/HelpCenter.route';
import { organizationResetRoute } from '../../view/OrganizationReset/OrganizationReset.route';
import { trackingRoute } from '../../view/Tracking/Tracking.route';

function useRouteCheck() {
  const location = useLocation();
  const routeHasParameter = useMemo(
    () => (location.pathname.match(/\//g) || []).length > 1,
    [location.pathname],
  );
  const isPublic = useMemo(() => {
    const pathname = routeHasParameter
      ? location.pathname.substring(0, location.pathname.lastIndexOf('/'))
      : location.pathname;
    return PUBLIC_ROUTE.includes(pathname);
  }, [routeHasParameter, location.pathname]);

  const isPublicFullWidth = useMemo(
    () =>
      [
        trackingRoute.path,
        helpCenterRoute.path,
        organizationResetRoute.path,
      ].includes(location.pathname),
    [location.pathname],
  );

  const isRouteHelpCenter = useMemo(() => {
    const data = location.pathname.split('/')?.at(1);

    return ['help-center', 'mobile-help-center'].includes(data as string);
  }, [location.pathname]);

  return { isPublic, isPublicFullWidth, isRouteHelpCenter };
}

export default useRouteCheck;
