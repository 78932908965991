import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;
export default function ArrowBack({
  fill,
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
  strokeWidth = '2',
  style = { transform: 'rotate(180deg)' },
}: Props) {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth={strokeWidth}
    >
      <path d="M2 12H22M22 12L18.0412 8M22 12L18.0412 16" stroke={fill} />
    </svg>
  );
}
