import { FormikErrors } from 'formik';
import { useMemo } from 'react';
import tw from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { IOrganizationSetting } from '../../../model/Organization.model';
import { FormItem } from '../../../types/input.type';

interface Props {
  values: IOrganizationSetting;
  setFieldValue: (
    field: string,
    value?: boolean,
  ) => Promise<void> | Promise<FormikErrors<IOrganizationSetting>>;
}

export type UseSettingShipperOrderHookObj = ReturnType<
  typeof useSettingShipperOrder
>;

export default function useSettingShipperOrder({
  values,
  setFieldValue,
}: Props) {
  // #region GENERAL
  const { translate } = useTranslator();
  // #endregion

  // #region FORM
  const formData = useMemo(
    (): FormItem[] => [
      {
        id: 'isAlwaysUseTransitForm',
        label: translate('Always Use Transit Form'),
        type: 'switch',
        description: translate(
          'By enabling this, every new Shipper Order will always use transit form by default.',
        ),
        values: values.isAlwaysUseTransitForm,
        onChange: (val) => {
          void setFieldValue('isAlwaysUseTransitForm', val);
        },
        containerStyle: tw`flex-1 justify-between`,
        labelStyle: tw`text-black`,
        descriptionLabelStyle: tw`text-grey-two`,
        wrapperStyle: tw`flex flex-1 pr-2`,
      },
    ],
    [translate, values.isAlwaysUseTransitForm, setFieldValue],
  );
  // #endregion

  return {
    formData,
  };
}
