import React, { useRef } from 'react';
import { useHoverDirty } from 'react-use';
import tw from 'twin.macro';
import { MAX_SO_CANDIDATE } from '../../../constant';
import { JOSOSelectionColumns } from '../../../constant/JobOrderCreate.constant';
import { UseJOFormControllerHookObj } from '../../../hook/jobOrderForm/useJOFormController.hook';
import { UseJOFormDeliveryLocationModalHookObj } from '../../../hook/jobOrderForm/useJOFormDeliveryLocationModal.hook';
import { UseJOFormSOSelectionHookObj } from '../../../hook/jobOrderForm/useJOFormSOSelection.hook';
import { UseJOFormStateObj } from '../../../hook/jobOrderForm/useJOFormState.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { Button, Chip, Icon, Text } from '../../atom';
import IndeterminateCheckbox from '../../atom/Checkbox/IndeterminateCheckbox.atom';
import { Table, Tooltip } from '../../molecule';
import ContainerHeader from '../../molecule/ContainerHeader/ContainerHeader.molecule';
import EmptyData from '../../molecule/EmptyData/EmptyData.molecule';

// #region  INTERFACES
type Props = {
  state: UseJOFormStateObj;
  controller: UseJOFormControllerHookObj;
};
type ActionButtonProps = {
  soSelection: UseJOFormSOSelectionHookObj;
  controller: UseJOFormControllerHookObj;
  locationModalController: UseJOFormDeliveryLocationModalHookObj;
};
// #endregion

// #region STYLED
const Container = tw.div`w-[1032px] mx-auto px-4 pb-12 pt-3 overflow-y-auto `;
const TableWrapper = tw.div`rounded-lg bg-white shadow-card overflow-hidden`;
const NumberContainer = tw.p`px-1 ml-2 bg-beige-lines text-grey-two rounded-md min-w-[25px]`;

const TableStyle = tw`max-h-[60vh] overflow-x-auto`;

// #endregion

function ActionButton({
  soSelection: {
    selectionDataTotal,
    disabledButtonHoveredLabel,
    actionButtonLabel,
    handleOpenCandidate,
  },
  controller: {
    soController: { selectedSOIds },
  },
  locationModalController: {
    lastMileSelectedSOList,
    handleOpenDeliveryLocationModal,
  },
}: ActionButtonProps) {
  // show tooltip when disabled button hovered
  const tooltipRef = useRef(null);
  const isHovered = useHoverDirty(tooltipRef);
  const isDisabledButton = selectedSOIds.length
    ? !!lastMileSelectedSOList.length
    : selectionDataTotal >= MAX_SO_CANDIDATE;

  return (
    <>
      <Button.Solid
        ref={tooltipRef}
        css={[
          tw`rounded-md pl-4 pr-1.5 py-[5px]`,
          !selectedSOIds.length && tw`p-3`,
          !selectedSOIds.length &&
            selectionDataTotal < MAX_SO_CANDIDATE &&
            tw`bg-black`,
          isDisabledButton &&
            tw`px-4 bg-grey-four cursor-default text-white hover:(bg-grey-four text-white)`,
        ]}
        onClick={() => {
          // we can't use native `disabled` attributes, because it will negates the activation of all event listeners/handlers
          // instead we guard it in the `onClick` handlers, and adjust styling based on `isDisabledButton` derived state
          if (isDisabledButton) return;

          if (!selectedSOIds.length) handleOpenCandidate();
          else handleOpenDeliveryLocationModal();
        }}
      >
        {!selectedSOIds.length && <Icon.Add fill="white" tw="w-5 h-5" />}
        <Text.HeadingFour tw="text-beige-bg text-sm leading-6">
          {actionButtonLabel}
        </Text.HeadingFour>
        {!!selectedSOIds.length && !lastMileSelectedSOList.length && (
          <NumberContainer tw="text-orange bg-white">
            {selectedSOIds.length}
          </NumberContainer>
        )}
      </Button.Solid>

      <Tooltip
        withPortal
        variant="light"
        placement="top"
        targetRef={tooltipRef}
        visible={isDisabledButton && isHovered}
      >
        <div tw="bg-white">
          <Text.BodyFourteen>{disabledButtonHoveredLabel}</Text.BodyFourteen>
        </div>
      </Tooltip>
    </>
  );
}

export default function JOFormSOSelectionList({
  controller,
  state: { soSelectionForm, locationModalController },
}: Props) {
  const { translate } = useTranslator();
  return (
    <Container>
      <ContainerHeader
        title={translate('Selected Shipper Order')}
        onBack={soSelectionForm.handleBack}
      />

      <div tw="flex flex-row pb-5 justify-between">
        <div tw="flex flex-row items-center pl-6">
          <IndeterminateCheckbox
            id="allCheckbox"
            name="allCheckbox"
            indeterminate={soSelectionForm.isIndeterminateSelectionSelected}
            disabled={!soSelectionForm.selectionByGroup.length}
            checked={soSelectionForm.isAllSelectionSelected}
            onChange={
              soSelectionForm.isAllSelectionSelected ||
              soSelectionForm.isIndeterminateSelectionSelected
                ? soSelectionForm.handleDeselectAllSelection
                : soSelectionForm.handleSelectAllSelection
            }
          />

          <Text.HeadingFour tw="ml-2.5">{translate('All')}</Text.HeadingFour>

          {soSelectionForm.groupByLabel && soSelectionForm.groupByChips && (
            <div tw="ml-7 pl-4 flex items-center space-x-3 border-l border-beige-lines animate-slide-in-left">
              <Text.BodyFourteen tw="text-grey-two">
                {soSelectionForm.groupByLabel}:
              </Text.BodyFourteen>

              {soSelectionForm.groupByChips.map((chip) => (
                <Chip.Small
                  key={chip.label}
                  tw="bg-white text-grey-two ml-5"
                  css={[
                    chip.selected && tw`bg-orange-hover text-orange border-0`,
                  ]}
                  label={chip.label}
                  onClick={chip.onClick}
                />
              ))}
            </div>
          )}
        </div>

        <div tw="flex items-center space-x-2">
          {!!controller.soController.selectedSOIds.length && (
            <Button.Outlined
              tw="rounded-md px-4 py-[5px]"
              onClick={soSelectionForm.handleDeselectAllSelection}
            >
              <Text.HeadingFour tw="text-sm leading-6">
                {soSelectionForm.cancelSelectionLabel}
              </Text.HeadingFour>
            </Button.Outlined>
          )}

          {!!controller.soController.selectedSOIds.length && (
            <Button.Outlined
              tw="rounded-md pl-4 pr-1.5 py-[5px]"
              onClick={soSelectionForm.handleRemoveSOSelection}
            >
              <Text.HeadingFour tw="text-sm leading-6">
                {soSelectionForm.deleteSelectionLabel}
              </Text.HeadingFour>
              {!!controller.soController.selectedSOIds.length && (
                <NumberContainer tw="text-grey-two bg-beige-lines">
                  {controller.soController.selectedSOIds.length}
                </NumberContainer>
              )}
            </Button.Outlined>
          )}

          <ActionButton
            soSelection={soSelectionForm}
            controller={controller}
            locationModalController={locationModalController}
          />
        </div>
      </div>

      <TableWrapper>
        <Table
          stickyColumn={!!soSelectionForm.selectionDataTotal}
          containerStyle={TableStyle}
          columns={JOSOSelectionColumns(translate)}
          rows={soSelectionForm.selectionByGroup}
          renderExpandedPage={soSelectionForm.renderExpandedPage}
          noDataPage={EmptyData(soSelectionForm.emptyData)}
        />
      </TableWrapper>
    </Container>
  );
}
