import { Placement } from '@popperjs/core';
import format from 'date-fns/format';
import React, { createRef, MutableRefObject, useMemo, useState } from 'react';
import { Modifier } from 'react-day-picker';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import tw, { styled } from 'twin.macro';
import { DatePicker, TextField } from '..';
import { inputDateValue } from '../../../util/date.util';
import { Icon, IconButton, OutsideAlerter, Popover, Portal } from '../../atom';
import { ExtraStyle } from '../../Type.component';
import { RangeYear } from '../DatePicker/DatePicker.molecule';

// #region INTERFACES
type Props = {
  readonly dateValue: Date | undefined;
  changeData?(date?: Date): void;
  readonly id?: string;
  readonly name?: string;
  readonly label?: string;
  readonly disabled?: boolean;
  readonly error?: string;
  readonly placeholder?: string;

  onClickApplyDate?(_date: Date): void;
  readonly rangeYear?: RangeYear;

  readonly disabledDays?: Modifier | Modifier[];
  readonly outsideAlerterStyle?: React.CSSProperties;
  readonly containerStyle?: ExtraStyle;
  readonly popoverPlacement?: Placement;
  readonly textFieldInputBgBeige?: boolean;

  readonly hideIcon?: boolean;
  readonly alwaysShowCalendarIcon?: boolean;
  readonly dateFormat?: string;
  readonly disableCancel?: boolean;
  readonly testID?: string;
  readonly disableTimePicker?: boolean;
  readonly isTimepickerValidationDisabled?: boolean;
};
// #endregion

const Container = styled.div(isMobile ? tw`relative` : tw``);
const CloseButtonWrapper = tw.div`absolute right-5 top-3 text-white z-10`;
const CloseButton = tw(
  IconButton,
)`text-white -mx-2 hover:(bg-white bg-opacity-20)`;
// #endregion

function DatePickerInput({
  dateValue = undefined,
  id = 'date',
  name = 'date',
  label,
  disabled = false,
  error,
  changeData = () => {},
  onClickApplyDate,
  rangeYear,
  disabledDays,
  outsideAlerterStyle,
  containerStyle,
  popoverPlacement = 'bottom',
  textFieldInputBgBeige = false,
  hideIcon,
  alwaysShowCalendarIcon = false,
  dateFormat,
  placeholder,
  disableCancel = false,
  disableTimePicker = true,
  isTimepickerValidationDisabled = true,
  testID = '',
}: Props) {
  const inputRef = createRef<HTMLInputElement>();
  const [openDatePickerInput, setOpenDatePickerInput] =
    useState<boolean>(false);

  const inputValue = useMemo(() => {
    if (!dateValue) return '';
    return dateFormat
      ? format(dateValue, dateFormat || 'dd MMM yyyy')
      : inputDateValue(dateValue);
  }, [dateFormat, dateValue]);

  return (
    <OutsideAlerter
      onClickAway={() => setOpenDatePickerInput(false)}
      style={outsideAlerterStyle}
    >
      <Container ref={inputRef} css={containerStyle}>
        <TextField
          readOnly
          value={inputValue}
          disabled={disabled}
          onFocus={() => (disabled ? null : setOpenDatePickerInput(true))}
          onClick={() => (disabled ? null : setOpenDatePickerInput(true))}
          testID={`${testID}DatePickerInput:TextField`}
          contentEditable={false}
          id={id}
          name={name}
          label={label}
          error={error}
          placeholder={placeholder}
          inputBgBeige={textFieldInputBgBeige}
          right={
            <>
              {((!hideIcon && !dateValue) || alwaysShowCalendarIcon) && (
                <IconButton
                  tw="-mr-2"
                  disabled={disabled}
                  data-testid={`${testID}DatePickerInput:ToggleCalendar`}
                  onClick={(e) => {
                    if (!disabled) {
                      e.stopPropagation();
                      setOpenDatePickerInput(!openDatePickerInput);
                    }
                  }}
                >
                  <Icon.Calendar tw="text-grey-two" />
                </IconButton>
              )}
              {!!dateValue &&
                !disableCancel &&
                !disabled &&
                !hideIcon &&
                !alwaysShowCalendarIcon && (
                  <IconButton
                    tw="-mr-2"
                    disabled={disabled}
                    data-testid={`${testID}DatePickerInput:Cancel`}
                    onClick={(e) => {
                      if (!disabled) {
                        e.stopPropagation();
                        changeData?.(undefined);
                      }
                    }}
                  >
                    <Icon.Close tw="text-grey-two" />
                  </IconButton>
                )}
              {disableCancel && !alwaysShowCalendarIcon && (
                <IconButton
                  tw="-mr-2 origin-center rotate-90"
                  data-testid={`${testID}DatePickerInput:ToggleCalendar`}
                  onClick={(e) => {
                    if (!disabled) {
                      e.stopPropagation();
                      setOpenDatePickerInput(!openDatePickerInput);
                    }
                  }}
                >
                  <Icon.ChevronSharp tw="w-3.5 h-3.5 text-grey-two" />
                </IconButton>
              )}
            </>
          }
        />

        <BrowserView>
          <Popover
            visible={openDatePickerInput}
            targetRef={inputRef as MutableRefObject<null>}
            placement={popoverPlacement}
            tw="z-20 transition-opacity animate-fade-in"
          >
            <CloseButtonWrapper css={[!disableTimePicker && tw`top-5`]}>
              <CloseButton
                onClick={() => setOpenDatePickerInput(false)}
                data-testid={`${testID}DatePickerInput:CalendarClose`}
              >
                <Icon.Close />
              </CloseButton>
            </CloseButtonWrapper>

            <DatePicker.Wrapper
              date={dateValue}
              onClickCancel={() => setOpenDatePickerInput(false)}
              onClickApply={(_date) => {
                changeData(_date);
                onClickApplyDate?.(_date);
                setOpenDatePickerInput(false);
              }}
              disabledDays={disabledDays}
              rangeYear={rangeYear}
              isTimepickerValidationDisabled={isTimepickerValidationDisabled}
              disableTimePicker={disableTimePicker}
              testID={testID}
            />
          </Popover>
        </BrowserView>

        <MobileView>
          {openDatePickerInput && (
            <Portal tw="transition-opacity animate-fade-in">
              <CloseButtonWrapper css={[!disableTimePicker && tw`top-5`]}>
                <CloseButton
                  onClick={() => setOpenDatePickerInput(false)}
                  data-testid={`${testID}DatePickerInput:CalendarClose`}
                >
                  <Icon.Close />
                </CloseButton>
              </CloseButtonWrapper>

              <DatePicker.Wrapper
                date={dateValue}
                onClickCancel={() => setOpenDatePickerInput(false)}
                onClickApply={(_date) => {
                  changeData(_date);
                  onClickApplyDate?.(_date);
                  setOpenDatePickerInput(false);
                }}
                disabledDays={disabledDays}
                rangeYear={rangeYear}
                isTimepickerValidationDisabled={isTimepickerValidationDisabled}
                disableTimePicker={disableTimePicker}
                testID={testID}
              />
            </Portal>
          )}
        </MobileView>
      </Container>
    </OutsideAlerter>
  );
}
export default DatePickerInput;
