import React from 'react';
import { RouteConfig } from '../../config/router/router.config';

export type JobOrderEditRouteParam = {
  searchParamsFromJODetail?: string; // typeof JobOrderDetailSearchParam;
};

export const jobOrderEditRoute: RouteConfig = {
  name: 'Edit Job Order',
  Component: React.lazy(() => import('./JobOrderEdit.view')),
  path: '/job-order/:id/edit',
  isPrivate: true,
  breadcrumb: 'Edit Job Order',
  props: { isUnclickable: true },
};
