import React, { useEffect, useState } from 'react';
import { styled } from 'twin.macro';
import { FormControlLabel } from '..';
import { Divider } from '../../atom';
import { ExtraStyle, SelectOption } from '../../Type.component';

type Props = React.ComponentProps<'div'> & {
  name: string;
  options: SelectOption[];
  selectedOption?: string;
  disabled?: boolean;
  labelExtraStyle?: ExtraStyle;
  optionExtraStyle?: ExtraStyle;
  onRadioChange?: (value: string) => void;
  rtl?: boolean;
  withDivider?: boolean;
};

const Container = styled.div``;

export function RadioGroup({
  name,
  options,
  selectedOption,
  disabled,
  optionExtraStyle,
  labelExtraStyle,
  onRadioChange,
  rtl,
  withDivider,
  ...props
}: Props) {
  const [selected, setSelected] = useState<string>('');

  const handleChange = (value: string) => {
    setSelected(value);
    onRadioChange?.(value);
  };

  useEffect(() => {
    if (selectedOption) setSelected(selectedOption);
  }, [selectedOption]);

  return (
    <Container {...props}>
      {options.map((option, i) => (
        <div key={`${option.value}-${i}`}>
          <FormControlLabel
            control="radio"
            key={option.label}
            id={option.value}
            name={name}
            label={option.label}
            value={option.value}
            containerStyle={optionExtraStyle}
            labelStyle={labelExtraStyle}
            disabled={disabled || option.disabled}
            checked={selected === option.value}
            onChange={() => handleChange(option.value)}
            rtl={rtl}
          />
          {withDivider && i !== options.length - 1 && <Divider />}
          {withDivider && i === options.length - 1 && <div />}
        </div>
      ))}
    </Container>
  );
}

export default RadioGroup;
