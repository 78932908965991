import React from 'react';
import store, { RootState } from '../../app/store/store.app';
import { Icon } from '../../component/atom';
import envConfig from '../../config/env/env.config';
import { RouteConfig } from '../../config/router/router.config';
import { RedirectUrlTarget } from '../../constant';
import { doRedirectUrl } from '../../util/helper.util';

/**
 * Get global session id from redux store
 * note: Previously this helper was located in helper.util.ts, somehow it caused unwanted crash,
 * since it usage is only for this file I decided to put it here
 * @returns string
 */
export const getRunnerMapUrl = () => {
  const state = store.getState() as RootState;
  return `${envConfig.ktbRunnerServiceUrl}/maps?gsid=${state.session.globalSessionID}`;
};
store.subscribe(getRunnerMapUrl);

const runnerRoute: RouteConfig = {
  name: 'Runner',
  Component: undefined,
  path: '',
  isPrivate: true,
  customNavigation: () =>
    doRedirectUrl({ url: getRunnerMapUrl(), target: RedirectUrlTarget.BLANK }),
  options: {
    label: 'Maps',
    testID: 'sidebarRunnerMaps',
    icon: <Icon.NavMaps />,
  },
};

export default runnerRoute;
