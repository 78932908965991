import { useCallback, useMemo, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store/store.app';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import useTranslator from '../../hook/useTranslator.hook';
import { mapLegalNavSectionToLabel } from '../../util/legal.util';
import useLegalContent from './hook/useLegalContent.hook';
import useLegalMenu from './hook/useLegalMenu.hook';

export enum LegalNavSection {
  DISCLAIMER = 'DISCLAIMER',
  TERMS = 'TERMS',
  PRIVACY = 'PRIVACY',
}

export enum LegalNavAnchor {
  WEBSITE = '#website',
  OWNERSHIP = '#ownership',
  LIMITATION = '#limitation',
  SECRECY = '#secrecy',
}

export interface LegalNavMenuItemProps {
  title: string;
  isSelected: boolean;
  onClick(): void;
}
export interface LegalNavMenuProps extends LegalNavMenuItemProps {
  path: string;
  subMenu?: LegalNavMenuItemProps[];
}

function useLegalViewModel() {
  const { isMobile } = useScreenDetection();
  const navigate = useNavigate();
  const translator = useTranslator();
  const location = useLocation();
  const scrollbar = useRef(null);
  const legalTopRef = useRef<HTMLDivElement | null>(null);
  const ownershipRef = useRef<HTMLDivElement | null>(null);
  const websiteRef = useRef<HTMLDivElement | null>(null);
  const limitationRef = useRef<HTMLDivElement | null>(null);
  const secrecyRef = useRef<HTMLDivElement | null>(null);
  const handleBack = () => {
    navigate(-1);
  };

  const menu = useLegalMenu({
    translator,
    location,
    legalTopRef,
    ownershipRef,
    websiteRef,
    limitationRef,
    secrecyRef,
  });

  const content = useLegalContent({
    translator,
    legalTopRef,
    ownershipRef,
    websiteRef,
    limitationRef,
    secrecyRef,
  });
  const isContentVisible = useCallback(
    (contentState: LegalNavSection) => contentState === menu.legalState,
    [menu.legalState],
  );

  const contentLabel = useMemo(
    () =>
      translator.translate(
        mapLegalNavSectionToLabel(menu.legalState as LegalNavSection),
      ),
    [menu.legalState, translator],
  );
  const { isLoggedIn } = useSelector(
    (state: RootState) => state.session,
    shallowEqual,
  );

  return {
    translator,
    scrollbar,
    legalTopRef,
    menu,
    content,
    isMobile,
    isLoggedIn,
    contentLabel,
    handleBack,
    isContentVisible,
  };
}

export default useLegalViewModel;
