import React, { PropsWithChildren } from 'react';
import { ToastContainer } from 'react-toastify';
import tw from 'twin.macro';
import {
  DrivingContestBanner,
  SnackbarAdapter,
} from '../../component/molecule';
import MobileScreenContainer from '../../component/template/MobileScreenContainer/MobileScreenContainer.template';

const Container = tw.div`min-h-screen min-w-min bg-beige-bg flex items-stretch`;
const ContentWrapper = tw.div`flex-1 pt-0 relative w-[calc(100vw - 320px)] overflow-x-auto whitespace-nowrap`;
const ContentArea = tw.div`w-full py-0`;

type Props = PropsWithChildren<Record<string, unknown>> & {
  showDrivingContestBanner: boolean;
  drivingContestYear?: number;
};

export default function WrapperMobileView({
  children,
  drivingContestYear,
  showDrivingContestBanner,
}: Props) {
  return (
    <Container id="rootContainer">
      <ToastContainer
        theme="light"
        position="bottom-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable
        hideProgressBar
      />
      <ContentWrapper id="contentWrapper">
        <ContentArea id="contentArea">
          <MobileScreenContainer>
            <DrivingContestBanner
              year={drivingContestYear}
              showBanner={showDrivingContestBanner}
            />
            {children}
          </MobileScreenContainer>
        </ContentArea>
        <SnackbarAdapter />
      </ContentWrapper>
    </Container>
  );
}
