import React, { useRef } from 'react';
import tw, { styled } from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { Goods } from '../../../model/ShipperOrder.model';
import { numberFormatter } from '../../../util/formatter.util';
import {
  getFloatingValue,
  getNumericValue,
  removeLeadingZeros,
  removeTrailingDot,
} from '../../../util/helper.util';
import { listOfGoods } from '../../../util/shipperOrder.util';
import { Button, Icon, Text } from '../../atom';
import {
  CollapsiblePanel,
  InputGroup,
  Select,
  TextArea,
  TextField,
} from '../../molecule';

const IconContainer = styled.div`
  & svg {
    ${tw`w-5 h-5`}
  }
`;

type Props = {
  title?: string;
  goods: Partial<Goods>[];
  disabledSave: boolean;
  disabled?: boolean;
  hasReachMaxLimit?: boolean;
  total: { weight: number; volume: number };
  emptyMessage?: string;
  onSave: () => void;
  onAddGood?: () => void;
  onDelete?: (goodIndex: number) => void;
  onInputChange: (key: keyof Goods, value: string, index: number) => void;
  onInputBlur: (key: keyof Goods, value: string, index: number) => void;
  getGoodsFormErrors: (goodIndex: number, key: keyof Goods) => string;
  translator: UseTranslator;
  isEdit?: boolean;
  submitLabel?: string;
};
function SOGoodsForm({
  goods,
  onSave,
  disabledSave,
  translator,
  onDelete,
  getGoodsFormErrors,
  hasReachMaxLimit,
  onInputChange,
  onInputBlur,
  onAddGood,
  total,
  title = translator.translate('Edit Goods'),
  emptyMessage = translator.translate('Goods is empty'),
  submitLabel = translator.translate('Save'),
  isEdit,
  disabled,
}: Props) {
  const goodsContentRef = useRef<HTMLDivElement>(null);
  const cardContentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        id="addGoodsHeader"
        tw="bg-beige-bg flex p-3 px-5 border-b rounded border-b-beige-lines items-center h-20"
      >
        <Text.HeadingFour>{title}</Text.HeadingFour>
        <Button.Solid
          tw="ml-auto"
          type="submit"
          onClick={onSave}
          disabled={disabledSave}
        >
          {submitLabel}
        </Button.Solid>
      </div>
      <div
        id="addGoodsContent"
        ref={goodsContentRef}
        tw="overflow-y-auto p-5 h-[calc(100% - 160px)]"
      >
        <div
          ref={cardContentRef}
          css={[tw`grid gap-4`, !goods.length && tw`h-full`]}
        >
          {!goods.length ? (
            <Text.HeadingThree tw="flex items-center justify-center">
              {emptyMessage}
            </Text.HeadingThree>
          ) : (
            goods.map((item, index) => (
              <CollapsiblePanel
                key={`created-goods-${item.id || index}`}
                collapsible
                action={
                  onDelete
                    ? {
                        click: () => {
                          onDelete(index);
                        },
                        icon: <Icon.Delete />,
                        text: translator.translate('Delete'),
                      }
                    : undefined
                }
                title={`${translator.translate('Goods')} ${index + 1}`}
              >
                <div tw="grid gap-5">
                  <InputGroup
                    required
                    label={translator.translate('Type')}
                    input={
                      <Select
                        options={listOfGoods}
                        value={item.type || ''}
                        placeholder={translator.translate('Select Type')}
                        error={translator.translate(
                          getGoodsFormErrors(index, 'type'),
                        )}
                        onChange={(id) => onInputChange('type', id, index)}
                        disabled={isEdit || disabled}
                      />
                    }
                  />
                  <InputGroup
                    label={translator.translate('Description')}
                    input={
                      <TextArea
                        value={item.description || ''}
                        error={translator.translate(
                          getGoodsFormErrors(index, 'description'),
                        )}
                        onChange={(e) =>
                          onInputChange('description', e.target.value, index)
                        }
                        placeholder={translator.translate('Description')}
                        disabled={isEdit || disabled}
                      />
                    }
                  />
                  <InputGroup
                    label={translator.translate('Quantity')}
                    input={
                      <div tw="grid grid-cols-2 gap-3">
                        <TextField
                          value={item.quantity || ''}
                          error={translator.translate(
                            getGoodsFormErrors(index, 'quantity'),
                          )}
                          onChange={(e) =>
                            onInputChange(
                              'quantity',
                              removeLeadingZeros(
                                getNumericValue(e.target.value),
                              ),
                              index,
                            )
                          }
                          placeholder={translator.translate('Quantity')}
                          disabled={disabled}
                        />
                        <TextField
                          value={item.uom || ''}
                          error={translator.translate(
                            getGoodsFormErrors(index, 'uom'),
                          )}
                          onChange={(e) =>
                            onInputChange('uom', e.target.value, index)
                          }
                          placeholder={translator.translate('Unit')}
                          disabled={disabled}
                        />
                      </div>
                    }
                  />
                  <InputGroup
                    label={translator.translate('Weight & Volume')}
                    input={
                      <div tw="grid grid-cols-2 gap-3">
                        <TextField
                          value={
                            item.weight
                              ? String(item.weight).replace('.', ',')
                              : ''
                          }
                          error={translator.translate(
                            getGoodsFormErrors(index, 'weight'),
                          )}
                          onChange={(e) =>
                            onInputChange(
                              'weight',
                              removeLeadingZeros(
                                getFloatingValue(e.target.value),
                              ),
                              index,
                            )
                          }
                          onBlur={(e) =>
                            onInputBlur(
                              'weight',
                              removeLeadingZeros(
                                removeTrailingDot(
                                  getFloatingValue(e.target.value),
                                ),
                              ),
                              index,
                            )
                          }
                          placeholder={translator.translate('Weight (kg)')}
                          disabled={disabled}
                        />
                        <TextField
                          value={
                            item.volume
                              ? String(item.volume).replace('.', ',')
                              : ''
                          }
                          error={translator.translate(
                            getGoodsFormErrors(index, 'volume'),
                          )}
                          onBlur={(e) =>
                            onInputBlur(
                              'volume',
                              removeLeadingZeros(
                                removeTrailingDot(
                                  getFloatingValue(e.target.value),
                                ),
                              ),
                              index,
                            )
                          }
                          onChange={(e) =>
                            onInputChange(
                              'volume',
                              removeLeadingZeros(
                                getFloatingValue(e.target.value),
                              ),
                              index,
                            )
                          }
                          placeholder={translator.translate('Volume (m³)')}
                          disabled={disabled}
                        />
                      </div>
                    }
                  />
                </div>
              </CollapsiblePanel>
            ))
          )}
        </div>
      </div>
      <div
        id="addGoodsFooter"
        tw="flex justify-between items-center p-4 border-t border-t-beige-lines mt-auto h-20"
      >
        {onAddGood && (
          <div>
            <Button.Text
              tw="ml-auto"
              type="button"
              disabled={hasReachMaxLimit}
              onClick={() => {
                onAddGood();
                setTimeout(() => {
                  if (goodsContentRef.current && cardContentRef.current) {
                    goodsContentRef.current.scrollTo(
                      0,
                      cardContentRef.current.clientHeight,
                    );
                  }
                }, 100);
              }}
            >
              <div tw="inline-grid grid-template-columns[26px 1fr] gap-2 w-max">
                <IconContainer tw="flex items-center">
                  <Icon.Add />
                </IconContainer>
                <span>
                  <Text.HeadingFive
                    tw="text-orange "
                    css={[hasReachMaxLimit && tw`text-grey-four`]}
                  >
                    {translator.translate(
                      !goods.length ? 'Add New Goods' : 'Add Other Goods',
                    )}
                  </Text.HeadingFive>
                </span>
              </div>
            </Button.Text>
          </div>
        )}

        <div css={[!onAddGood && tw`ml-auto`]}>
          <div tw="flex">
            <span tw="w-28 flex justify-end">
              <Text.BodyFourteen>{`${translator.translate(
                'Total Weight',
              )}:`}</Text.BodyFourteen>
            </span>
            <span tw="w-32 overflow-ellipsis overflow-hidden flex justify-end relative">
              <Text.HeadingFive
                tw='after:(content["kg"] absolute right-0 top-0 w-5) pr-6 relative max-width[124px] overflow-ellipsis overflow-hidden text-right whitespace-nowrap'
                title={numberFormatter(total.weight)}
              >
                {numberFormatter(total.weight)}
              </Text.HeadingFive>
            </span>
          </div>
          <div tw="flex">
            <span tw="w-28 flex justify-end">
              <Text.BodyFourteen>{`${translator.translate(
                'Total Volume',
              )}:`}</Text.BodyFourteen>
            </span>
            <span tw="w-32 flex justify-end">
              <Text.HeadingFive
                tw='after:(content["m3"] absolute right-0 w-5 top-0) pr-6 relative max-width[124px] overflow-ellipsis overflow-hidden text-right whitespace-nowrap'
                title={numberFormatter(total.volume)}
              >
                {numberFormatter(total.volume)}
              </Text.HeadingFive>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default SOGoodsForm;
