import React from 'react';
import tw from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { Button, LoadingIndicator } from '../../atom';
import { HeadingFour } from '../../atom/Text/Text.atom';

// #region STYLED
const Container = tw.div`flex`;
// #endregion

// #region INTERFACES

type Props = {
  translator: UseTranslator;
  isLoading?: boolean;
  disabled?: boolean;
  label: string;
  handleClick: () => void;
};
// #endregion

export default function JODetailMobileFooterAction({
  translator,
  isLoading,
  disabled,
  label,
  handleClick,
}: Props) {
  return (
    <Container>
      <Button.Outlined
        tw="flex-1 gap-1"
        disabled={isLoading || disabled}
        onClick={handleClick}
      >
        <HeadingFour tw="text-grey-three">
          {translator.translate(isLoading ? 'Loading...' : label)}
        </HeadingFour>
        {isLoading && <LoadingIndicator size="small" />}
      </Button.Outlined>
    </Container>
  );
}
