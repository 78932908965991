// #region IMPORT
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  DeleteLocationParams,
  ExportLocationApiRequest,
  ExportLocationApiResponse,
  GetLocationApiRequest,
  GetLocationApiResponse,
  GetLocationCountApiRequest,
  GetLocationCountApiResponse,
  GetLocationShipperApiRequest,
  GetLocationShipperApiResponse,
  InfoLocationParams,
  InfoLocationResponse,
  PostLocationParams,
  PostLocationResponse,
  UpdateLocationParams,
  UpdateLocationResponse,
} from '../../../model/Location.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, MD, QD } from '../../api.baseQuery';
import { CommonApiResponse } from '../../api.endpoint';
// #endregion

// #region INTERFACES
export type DeliveryLocationEndpoint = {
  getLocationInfo: QD<InfoLocationParams, InfoLocationResponse>;
  createLocation: MD<PostLocationParams, PostLocationResponse>;
  updateLocation: MD<UpdateLocationParams, UpdateLocationResponse>;
  deleteLocation: MD<DeleteLocationParams, CommonApiResponse>;
  getLocations: QD<GetLocationApiRequest | string, GetLocationApiResponse>;
  getLocationsCount: QD<
    GetLocationCountApiRequest | string,
    GetLocationCountApiResponse
  >;
  getLocationShipper: QD<
    GetLocationShipperApiRequest,
    GetLocationShipperApiResponse
  >;
  exportLocation: MD<ExportLocationApiRequest, ExportLocationApiResponse>;
};
// #endregion

// #region ENDPOINT
export const deliveryLocationTagTypes: EndpointTagTypes<DeliveryLocationEndpoint> =
  {
    getLocationInfo: 'locationInfo',
    getLocations: 'location',
    getLocationsCount: '',
    getLocationShipper: '',
    updateLocation: '',
    deleteLocation: '',
    createLocation: '',
    exportLocation: '',
  };

export const deliveryLocationEndpoint = (
  builder: Builder,
): DeliveryLocationEndpoint => ({
  getLocationInfo: builder.query({
    query: (param) => urlcat('/v1/locations.info', toSnakeCase(param)),
    providesTags: [deliveryLocationTagTypes.getLocationInfo],
  }),
  getLocations: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/locations.list?${param}`
        : urlcat('/v1/locations.list', toSnakeCase(param)),
    providesTags: (result) =>
      result
        ? [
            ...result.locations.map(({ id }) => ({
              type: deliveryLocationTagTypes.getLocations,
              id,
            })),
            deliveryLocationTagTypes.getLocations,
          ]
        : [deliveryLocationTagTypes.getLocations],
  }),
  getLocationsCount: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/locations.count?${param}`
        : urlcat('/v1/locations.count', toSnakeCase(param)),
    providesTags: [deliveryLocationTagTypes.getLocations],
  }),
  getLocationShipper: builder.query({
    query: (param) => urlcat('/v1/locations.shipper.list', toSnakeCase(param)),
  }),
  updateLocation: builder.mutation({
    query: (body) => ({
      url: '/v1/locations.update',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      deliveryLocationTagTypes.getLocations,
      deliveryLocationTagTypes.getLocationInfo,
    ],
  }),
  deleteLocation: builder.mutation({
    query: (body) => ({
      url: '/v1/locations.delete',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      deliveryLocationTagTypes.getLocations,
      deliveryLocationTagTypes.getLocationInfo,
    ],
  }),
  createLocation: builder.mutation({
    query: (body) => ({
      url: '/v1/locations.create',
      body,
      method: 'post',
    }),
    invalidatesTags: [deliveryLocationTagTypes.getLocations],
  }),
  exportLocation: builder.mutation({
    query: (body) => ({
      url: '/v1/locations.export',
      body,
      method: 'post',
    }),
  }),
});
// #endregion
