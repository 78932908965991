import React, { ReactElement, useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';
import {
  getVehicleMeasurementStatus,
  getVehicleStatus,
  vehicleStatusLabelMapper,
  VehicleStatusTheme,
  vehicleVolumeLabelMapper,
  vehicleWeightLabelMapper,
} from '../../../constant/Vehicle.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { Vehicle } from '../../../model/Vehicle.model';
import {
  CubeOutline,
  Info,
  TruckOutline,
  WeightOutline,
} from '../../atom/Icon/Icon.atom';
import { LabelTen } from '../../atom/Text/Text.atom';

type Props = {
  vehicle: Vehicle;
  isSelected?: boolean;
};

type ChipProps = {
  isInverted?: boolean;
  status?: VehicleStatusTheme;
  className?: string;
  label?: string;
  icon?: ReactElement;
  isStatus?: boolean;
};

const Container = styled.div(() => [
  tw`flex gap-2 h-6`,
  css`
    > .group-status:hover .group-label-text,
    .group-weight:hover .group-label-text,
    .group-volume:hover .group-label-text,
    .group-status-inverted:hover .group-label-text,
    .group-weight-inverted:hover .group-label-text,
    .group-volume-inverted:hover .group-label-text {
      ${tw`block`}
    }
    > .group-status:hover .group-badge,
    .group-weight:hover .group-badge,
    .group-volume:hover .group-badge,
    .group-status-inverted:hover .group-badge,
    .group-weight-inverted:hover .group-badge,
    .group-volume-inverted:hover .group-badge {
      ${tw`hidden`}
    }
  `,
]);

const Chip = styled.div(
  ({
    status,
    isInverted,
  }: { status?: VehicleStatusTheme; isInverted?: boolean }) => [
    tw`bg-neutral-50 min-w-[24px] min-h-[24px] p-1 rounded-xl relative gap-0.5 items-center justify-center  text-grey-two hover:(w-full transition-all justify-between pl-1.5 pr-2) hover:flex`,
    isInverted && tw`bg-grey-four`,
    status === VehicleStatusTheme.GREEN &&
      tw`hover:( bg-semantic-success-muted text-semantic-success)`,
    status === VehicleStatusTheme.YELLOW &&
      tw`hover:(bg-semantic-warning-muted text-semantic-warning)`,
    status === VehicleStatusTheme.RED &&
      tw`hover:(bg-semantic-error-muted text-semantic-error)`,
    status === VehicleStatusTheme.GREEN &&
      isInverted &&
      tw`hover:( bg-semantic-success)`,
    status === VehicleStatusTheme.YELLOW &&
      isInverted &&
      tw`hover:(bg-semantic-warning)`,
    status === VehicleStatusTheme.RED &&
      isInverted &&
      tw`hover:(bg-semantic-error)`,
  ],
);

const ChipBadge = styled.div(({ status }: { status?: VehicleStatusTheme }) => [
  tw`w-2 h-2 bottom-0 right-0 absolute rounded-xl border border-white`,
  status === VehicleStatusTheme.GREEN && tw`bg-semantic-success`,
  status === VehicleStatusTheme.YELLOW && tw`bg-semantic-warning`,
  status === VehicleStatusTheme.RED && tw`bg-semantic-error`,
]);

const ChipIcon = styled.div(({ isInverted }: { isInverted?: boolean }) => [
  tw`w-4 h-4 flex items-center justify-center`,
  isInverted && tw`text-white`,
]);

const ChipLabel = styled(LabelTen)(
  ({
    status,
    isInverted,
  }: { status?: VehicleStatusTheme; isInverted?: boolean }) => [
    tw`hidden text-grey-two`,
    status === VehicleStatusTheme.GREEN && tw`text-semantic-success`,
    status === VehicleStatusTheme.YELLOW && tw`text-grey-two`,
    status === VehicleStatusTheme.RED && tw`text-semantic-error`,
    isInverted && tw`text-white`,
  ],
);

const AlertContainer = styled.div(
  ({ status }: { status?: VehicleStatusTheme }) => [
    tw`w-2 h-2 bottom-0 right-0 absolute rounded-xl border border-white`,
    status === VehicleStatusTheme.GREEN && tw`hidden`,
    status === VehicleStatusTheme.YELLOW && tw`text-semantic-warning`,
    status === VehicleStatusTheme.RED && tw`text-semantic-error`,
  ],
);

function AlertIcon({
  status,
  className,
}: { status?: VehicleStatusTheme; className?: string }) {
  return (
    <AlertContainer className={className} status={status}>
      <Info width={8} height={8} />
    </AlertContainer>
  );
}

function ChipItem({
  status,
  className,
  label,
  icon,
  isStatus,
  isInverted,
}: ChipProps) {
  return (
    <Chip status={status} isInverted={isInverted} className={className}>
      {isStatus ? (
        <ChipBadge status={status} className="group-badge" />
      ) : (
        <AlertIcon status={status} className="group-badge" />
      )}
      <ChipIcon isInverted={isInverted} className="group-icon">
        {icon}
      </ChipIcon>
      <ChipLabel
        status={status}
        isInverted={isInverted}
        className="group-label-text"
      >
        {label}
      </ChipLabel>
    </Chip>
  );
}

export default function VehicleChipInfo({ vehicle, isSelected }: Props) {
  const { translate } = useTranslator();
  const vehicleStatus = useMemo(
    () => getVehicleStatus(vehicle.summary?.status),
    [vehicle.summary?.status],
  );
  const weightStatus = useMemo(
    () =>
      getVehicleMeasurementStatus(
        vehicle.weight,
        vehicle.summary?.totalWeightUsed,
      ),
    [vehicle.summary?.totalWeightUsed, vehicle.weight],
  );
  const volumeStatus = useMemo(
    () =>
      getVehicleMeasurementStatus(
        vehicle.volume,
        vehicle.summary?.totalVolumeUsed,
      ),
    [vehicle.summary?.totalVolumeUsed, vehicle.volume],
  );
  return (
    <Container>
      <ChipItem
        isStatus
        isInverted={isSelected}
        className={`group-status${isSelected ? '-inverted' : ''}`}
        status={vehicleStatus}
        label={translate(vehicleStatusLabelMapper(vehicleStatus))}
        icon={<TruckOutline />}
      />

      {weightStatus !== VehicleStatusTheme.GREEN && (
        <ChipItem
          isInverted={isSelected}
          className={`group-weight${isSelected ? '-inverted' : ''}`}
          status={weightStatus}
          label={translate(vehicleWeightLabelMapper(weightStatus))}
          icon={<WeightOutline />}
        />
      )}
      {volumeStatus !== VehicleStatusTheme.GREEN && (
        <ChipItem
          isInverted={isSelected}
          className={`group-volume${isSelected ? '-inverted' : ''}`}
          status={volumeStatus}
          label={translate(vehicleVolumeLabelMapper(volumeStatus))}
          icon={<CubeOutline />}
        />
      )}
    </Container>
  );
}
