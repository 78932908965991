import React, { PropsWithChildren } from 'react';
import 'react-spring-bottom-sheet/dist/style.css';
import 'react-toastify/dist/ReactToastify.min.css';
import tw from 'twin.macro';
import imagePlaceholder from '../../assets/images/jo_not_found.png';
import { LoadingIndicator } from '../../component/atom';
import { ErrorPlaceholder } from '../../component/organism';
import { SettingNavigationItemType } from '../../constant/Setting.constant';
import WrapperAuthenticatedView from './Wrapper.authenticated.view';
import WrapperHelpCenterView from './Wrapper.helpCenter.view';
import WrapperMobileView from './Wrapper.mobile.view';
import WrapperPublicView from './Wrapper.public.view';
import useWrapperViewModel from './Wrapper.viewModel';

// #region STYLED
const Container = tw.div`min-h-screen min-w-min bg-beige-bg flex items-stretch`;
const AuthenticationContainer = tw.div`flex items-center justify-center h-screen w-full relative flex-col`;
// #endregion

// #region INTERFACE
type Props = PropsWithChildren<Record<string, unknown>>;
// #endregion

export default function WrapperView({ children }: Props) {
  const {
    sidebar,
    content,
    location,
    topBar,
    session,
    profile,
    translator,
    isMobile,
    onboarding,
    runnerEventQuery,
    isPublicFullWidth,
    isRouteHelpCenter,
    showDrivingContestBanner,
    toggleModalSetting,
  } = useWrapperViewModel();

  if (session.authLoading) {
    return (
      <Container>
        <AuthenticationContainer>
          <LoadingIndicator />
        </AuthenticationContainer>
      </Container>
    );
  }

  if (session.authError) {
    return (
      <Container>
        <AuthenticationContainer>
          <ErrorPlaceholder
            imageHeader={imagePlaceholder}
            title={session.errorPageTitleText}
            body={session.errorPageBodyText}
            actionClick={session.handleErrorPageButtonClick}
            actionLabel={session.errorPageActionText}
          />
        </AuthenticationContainer>
      </Container>
    );
  }

  if (isRouteHelpCenter) {
    return (
      <WrapperHelpCenterView currentPath={location.pathname}>
        {children}
      </WrapperHelpCenterView>
    );
  }

  if (isMobile) {
    return (
      <WrapperMobileView
        drivingContestYear={runnerEventQuery.drivingContestYear}
        showDrivingContestBanner={showDrivingContestBanner}
      >
        {children}
      </WrapperMobileView>
    );
  }

  if (session.isLoggedIn && !isPublicFullWidth) {
    return (
      <WrapperAuthenticatedView
        translator={translator}
        onboarding={onboarding}
        topBar={topBar}
        session={session}
        drivingContestYear={runnerEventQuery.drivingContestYear}
        showDrivingContestBanner={showDrivingContestBanner}
        profileCardLegalItems={profile.profileCardLegalItems}
        sideBarExpanded={sidebar.isSidebarExpanded}
        currentPath={location.pathname}
        fullWidth={content.isFullwidth}
        onClickTopBarMenu={() =>
          sidebar.setExpandSidebar(!sidebar.isSidebarExpanded)
        }
        onClickSidebarItem={sidebar.onSidebarItemClick}
        onMouseEnterSidebar={() => sidebar.setHoverSidebar(true)}
        onMouseLeaveSidebar={() => sidebar.setHoverSidebar(false)}
        onToggleModalSetting={() =>
          toggleModalSetting(SettingNavigationItemType.DISPLAY)
        }
      >
        {children}
      </WrapperAuthenticatedView>
    );
  }

  return <WrapperPublicView>{children}</WrapperPublicView>;
}
