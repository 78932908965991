import { MutableRefObject } from 'react';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { LegalNavSection } from '../Legal.viewModel';
import { LegalRefProps } from './useLegalMenu.hook';

export interface LegalContentMetaSubItemProps {
  description: string;
  style?: 'bold' | 'numeric';
  isInline?: boolean;
}
export interface LegalContentMetaItemProps
  extends Pick<LegalContentMetaSubItemProps, 'description'> {
  subDescription?: LegalContentMetaSubItemProps[];
}

export interface LegalContentItemProps {
  title: string;
  meta: LegalContentMetaItemProps[];
  id?: string;
  ref?: MutableRefObject<HTMLDivElement | null>;
}

export interface LegalContentProps extends Omit<LegalContentItemProps, 'ref'> {
  state: LegalNavSection;
  subContent?: LegalContentItemProps[];
}

interface UseLegalContentProps extends LegalRefProps {
  translator: UseTranslator;
}

function useLegalContent({
  translator,
  limitationRef,
  ownershipRef,
  websiteRef,
  secrecyRef,
}: UseLegalContentProps) {
  const legalContentItems: LegalContentProps[] = [
    {
      title: translator.translate('Disclaimer'),
      state: LegalNavSection.DISCLAIMER,
      meta: [
        {
          description: translator.translate(
            'Telematik RUNNER ("Layanan") dilengkapi dengan fungsi yang mendukung bisnis Anda, antara lain Anda (pemilik kendaraan) akan dapat memonitor aktivitas kendaraan secara real time, sehingga bisnis Anda akan semakin Profesional.',
          ),
        },
        {
          description: translator.translate(
            'Kami percaya bahwa keamanan informasi merupakan perhatian utama Anda dan kepercayaan Pelanggan merupakan pondasi mendasar dalam bisnis kami. Oleh karenanya, sesuai dengan Kebijakan Kerahasiaan dan Ketentuan Penggunaan, kami  berkomitmen untuk menyediakan Layanan dengan iktikad baik dimana setiap informasi terkait Anda maupun kendaraan Anda akan diperlakukan secara tepat dengan sangat rahasia oleh KTB maupun Perusahaan Terkait KTB yaitu hanya dalam keperluan menjalankan Layanan untuk kepentingan Anda, maupun keperluan lainnya sebagaimana Anda telah setujui dengan memperhatikan hak privasi Anda.',
          ),
        },
        {
          description: translator.translate(
            'Untuk melaksanakan keperluan di atas, Kami membutuhkan persetujuan Anda secara elektronik (sebagaimana tersedia di bawah) untuk menandakan bahwa aktivasi dan langganan atas Layanan sudah berlaku efektif, dan  dimana kami memahami bahwa Anda:setuju untuk mengikatkan diri dengan Kebijakan Kerahasiaan, Ketentuan Penggunaan, dan Disclaimer; dan berhak dan berwenang untuk memberikan persetujuan atas nama Anda maupun perusahaan Anda (sebagaimana berlaku) sesuai dengan ketentuan hukum yang berlaku.',
          ),
        },
        {
          description: translator.translate(
            'Anda dapat memilih untuk tidak memanfaatkan Layanan yang tersedia dan tidak memberikan persetujuan secara elektronik apabila Anda tidak setuju pada isi dari Disclaimer, Ketentuan Penggunaan dan Kebijakan Kerahasiaan ini. Kami berhak untuk mengubah dan/atau memperbaharui Kebijakan Kerahasiaan, Ketentuan Penggunaan dan Disclaimer ini dengan merujuk kepada hukum dan peraturan yang berlaku.',
          ),
        },
      ],
    },
    {
      title: translator.translate('Terms of Use'),
      state: LegalNavSection.TERMS,
      meta: [
        {
          description: translator.translate(
            'Ketentuan Penggunaan ini efektif berlaku terhitung sejak tanggal ',
          ),
          subDescription: [
            {
              description: translator.translate('1 Agustus 2020.'),
              style: 'bold',
              isInline: true,
            },
          ],
        },
        {
          description: translator.translate(
            'Setiap akses terhadap dan penggunaan atas informasi yang tersedia pada Layanan, termasuk namun tidak terbatas pada website, software, aplikasi, dsb adalah tunduk pada Ketentuan Penggunaan ini.',
          ),
        },
        {
          description: translator.translate(
            'Kami berhak untuk memelihara Layanan, membuat perubahan atau memperbarui produk atau program untuk kemudahan dan kenyamanan anda. Penggunaan Layanan yang berkelanjutan oleh anda berikut perubahannya menandakan penerimaan anda atas perubahan Ketentuan Penggunaan seiring berjalannya waktu.Anda berkewajiban untuk meninjau Ketentuan Penggunaan ini secara berkala.',
          ),
        },
      ],
      subContent: [
        {
          title: translator.translate('Kerahasiaan'),
          meta: [
            {
              description: translator.translate(
                'MOHON MENJAGA KERAHASIAAN USERNAME, PASSWORD, ID LOGIN, PERSONAL IDENTIFICATION NUMBER (PIN), KODE UNIK ATAU SEJENISNYA TERKAIT LAYANAN, DAN/ATAU MEMBATASI MEDIA UNTUK MENGAKSES LAYANAN DALAM RANGKA MENCEGAH AKSES YANG TIDAK SAH TERHADAP LAYANAN YANG HANYA DISEDIAKAN UNTUK ANDA.',
              ),
            },
          ],
          id: 'secrecy',
          ref: secrecyRef,
        },
        {
          title: translator.translate('Hak Kepemilikan'),
          meta: [
            {
              description: translator.translate(
                'Kecuali dinyatakan sebaliknya, setiap informasi dan tampilan yang muncul pada website, software & aplikasi, termasuk gambar, grafis, ilustrasi,desain, ikon, logo, foto, video, data, teks, dokumen, layanan, desain website dan material lainnya yang merupakan bagian dari Layanan, serta pengaturannya, kecuali data pribadi pengguna (secara bersama disebut "Konten"), dilindungi oleh hak cipta, merek dagang, dan/atau hak kekayaan intelektual lainnya yang dimiliki, dikontrol atau dilisensikan kepada atau oleh kami dan/atau mitra bisnis kami sebagai pemilik yang sah dan digunakan hanya untuk rujukan Layanan. Semua hak yang tidak disebutkan secara eksplisit dalam Ketentuan Penggunaan ini dilindungi undang-undang.Kecuali jika dipersyaratkan sebaliknya atau dibatasi oleh hukum yang berlaku, setiap reproduksi, distribusi, modifikasi, pengiriman ulang, atau publikasi dari Konten dengan hak cipta adalah dilarang tanpa persetujuan tertulis yang tegas dari pemilik hak cipta atau pemberi lisensi. Tidak ada yang tercantum pada Layanan yang ditafsirkan sebagai pemberian lisensi kepada anda untuk menggunakan merek dagang, merek layanan, logo dan/atau hak kekayaan intelektual lainnya yang kami atau pihak ketiga miliki/kelola.',
              ),
            },
          ],
          id: 'ownership',
          ref: ownershipRef,
        },
        {
          title: translator.translate('Penggunaan Website & Software Layanan'),
          meta: [
            {
              description: translator.translate(
                'Dengan tunduk pada Ketentuan Penggunaan, anda berwenang untuk menggunakan website & software Layanan dengan ketentuan:',
              ),
              subDescription: [
                {
                  description: translator.translate(
                    'Anda tidak boleh menggandakan atau mendistribusikan bagian apapun dari Konten dengan sarana apapun tanpa persetujuan tertulis dari kami.',
                  ),
                  style: 'numeric',
                },
                {
                  description: translator.translate(
                    'Anda tidak dapat mengubah atau memodifikasi bagian apapun dari Konten kecuali ditentukan lain secara tegas, dan mencoba untuk mengubah bagian apapun dari Konten sebagaimana milik sendiri.',
                  ),
                  style: 'numeric',
                },
                {
                  description: translator.translate(
                    'Anda tidak dapat menggunakan, mereproduksi atau secara publik menampilkan atau menjalankan, atau mendistribusikan bagian apapun dari Konten, kecuali perwakilan kami yang berwenang telah memberikan persetujuan tertulis untuk melakukan hal tersebut.',
                  ),
                  style: 'numeric',
                },
                {
                  description: translator.translate(
                    'Anda harus mematuhi hukum Indonesia terkait dengan penggunaan Konten maupun Layanan secara keseluruhan, termasuk namun tidak terbatas pada UU No. 11 Tahun 2008 tentang lnformasi dan Transaksi Elektronik dan perubahannya (yang terakhir UU No.11Tahun 2016), revisi,tambahan maupun peraturan teknisnya.',
                  ),
                  style: 'numeric',
                },
              ],
            },
            {
              description: translator.translate(
                'Anda diharuskan juga menundukkan diri pada Ketentuan Penggunaan ini;',
              ),
              subDescription: [
                {
                  description: translator.translate(
                    'Anda dilarang untuk melanggar atau mencoba melanggar fitur keamanan dari website & software Layanan, termasuk namun tidak terbatas pada:Mengeksploitasi website & software untuk melakukan kegiatan ilegal, pidana, membahayakan, atau kegiatan sejenis lainnya yang dapat merugikan KTB, Pihak Terkait KTB atau orang lain;',
                  ),
                  style: 'numeric',
                },
                {
                  description: translator.translate(
                    'Mengakses Konten, fitur atau data yang tidak dimaksudkan untuk anda, atau masuk ke server atau akun yang anda akses secara tidak sah;',
                  ),
                  style: 'numeric',
                },
                {
                  description: translator.translate(
                    'Mencoba untuk menggali, memindai atau mencoba kerentanan dari website & software, atau setiap sistem atau jaringan terkait apapun, atau melanggar keamanan atau otentikasi tanpa otorisasi yang tepat;',
                  ),
                  style: 'numeric',
                },
                {
                  description: translator.translate(
                    'Mengganggu atau mencoba untuk mengganggu layanan kepada setiap pengguna, host, atau jaringan, termasuk namun tidak terbatas pada, dengan memasukkan virus, memberi muatan berlebih, "flooding," "spamming," "mailbombing," atau "crashing" ke website & software; atau',
                  ),
                  style: 'numeric',
                },
                {
                  description: translator.translate(
                    'Mencoba mengubah, merekayasa ulang, mengurai, membongkar, atau dengan cara lain mengurangi atau mencoba mengurangi setiap source code yang digunakan oleh kami untuk menyediakan website & software.',
                  ),
                  style: 'numeric',
                },
                {
                  description: translator.translate(
                    'Melalui website atau aplikasi atau cara lain yang sah, kami akan membuat software/apps tertentu yang dapat anda gunakan untuk mengakses Layanan. Software/apps tersebut, termasuk setiap file dan gambar yang terkandung di dalamnya dan data yang menyertainya adalah ditujukan untuk digunakan dengan semestinya oleh anda, untuk penggunaan personal atau komersil yang telah disepakati.Dengan diunduhnya software/aplikasi, bukan berarti kami mengalihkan hak milik atau hak kekayaan intelektual atas software/aplikasi tersebut. Kami tetap memegang hak milik secara penuh dan menyeluruh atas software/apps serta hak atas kekayaan intelektual di dalamnya. Anda tidak diperbolehkan menjual, mendistribusikan kembali, atau mereproduksi software/apps, juga mengurai, merekayasa ulang, membongkar, atau dengan cara lain mengubah bentuk software/apps.',
                  ),
                  style: 'numeric',
                },
                {
                  description: translator.translate(
                    'Kami berhak untuk memutus akses setiap pengguna atas kebijakan kami, termasuk namun tidak terbatas terhadap pengguna yang tidak mematuhi Ketentuan Penggunaan ini dan/atau pengguna yang melanggar atau menyalahi hak orang lain. Setiap pelanggaran atas sistem atau jaringan keamanan dapat dikenakan hukuman perdata dan/atau pidana.',
                  ),
                  style: 'numeric',
                },
              ],
            },
          ],
          id: 'website',
          ref: websiteRef,
        },
        {
          title: translator.translate('Pembatasan Tanggung Jawab'),
          meta: [
            {
              description: translator.translate(
                'LAYANAN INI BUKANLAH MERUPAKAN PERANGKAT KEAMANAN, DAN TIDAK ADA JAMINAN YANG TERSIRAT MAUPUN TERSURAT BAHWA   DENGAN   MENGGUNAKAN    LAYANAN   INI  ANDA DIPASTIKAN AKAN  TERHINDAR DARI RESIKO KERUSAKAN MAUPUN KEHILANGAN ATAS KENDARAAN MAUPUN ISINYA. ANDA BERTANGGUNG JAWAB ATAS KEAMANAN KENDARAAN MAUPUN ISINYA. LAYANAN INI HANYA DIDEDIKASIKAN UNTUK KENDARAAN MITSUBISHI FUSO.',
              ),
            },
            {
              description: translator.translate(
                'Sejauh yang dimungkinkan oleh hukum yang berlaku, dalam keadaan apapun KTB atau Perusahaan Terkait KTB tidak bertanggungjawab kepada anda atau orang, firma, perusahaan, entitas lain atas kerugian yang bukan disebabkan oleh kami dan tidak juga masuk akal untuk kami tanggung, antara lain: kehilangan  yang bersifat khusus, memberikan hukuman, berlebih, berkelanjutan, secara tidak langsung, atau kerugian atau kehilangan lainnya, termasuk namun tidak terbatas pada kerugian berupa kehilangan niat baik, atau keuntungan bisnis, kehilangan pendapatan, penghentian kerja, kehilangan data atau malfungsi komputer,atau kerugian/biaya alas hilangnya kesempatan atau biaya pengadaan atas hardware, software atau layanan pengganti.',
              ),
            },
            {
              description: translator.translate(
                'Meskipun demikian, kami akan memberikan usaha terbaik kami untuk menjaga keamanan dan kemudahan akses dari Layanan serta membantu anda sebaik mungkin saat anda menghadapi kesulitan saat menggunaan Layanan.Lain - lain Penyelesaikan setiap tuntutan atau sengketa yang timbul karena atau terkait dengan ketentuan penggunaan ini atau penggunaan layanan telematik dilakukan dengan musyawarah mufakat. Jika tuntutan atau sengketa tersebut tidak dapat diselesaikan dengan musyawarah mufakat, maka akan diselesaikan melalui Badan Arbitrase Nasional Indonesia (BANI) di Jakarta dengan mematuhi administrasi dan prosedur arbitrase BANI. Keputusan arbitrase tersebut bersifat final dan mengikat anda dan kami. Apabila bagian dari Ketentuan Penggunaan, Kebijakan Kerahasiaan dan Disclaimer ini tidak dapat diberlakukan, maka bagian lainnya akan tetap berlaku. Ketentuan Penggunaan, Kebijakan Kerahasiaan dan Disclaimer, merupakan keseluruhan kesepakatan antara anda dan kami sehubungan dengan penggunaan Layanan dan menggantikan semua pemahaman lisan dan tertulis sebelumnya, komunikasi dan kesepakatan mengenai penggunaan Layanan.',
              ),
            },
          ],
          id: 'limitation',
          ref: limitationRef,
        },
      ],
    },
    {
      title: translator.translate('Privacy Policy'),
      state: LegalNavSection.PRIVACY,
      meta: [
        {
          description: translator.translate(
            'The following terms and conditions apply in respect of any services, information or materials provided by Runner-KTBFuso.co.id ("RUNNER") via this website and the Internet (collectively "information"). By accessing this website, you agree to these terms and conditions. This website is for informational purposes only. While we endeavor to ensure the accuracy of all information, we do not represent that the information is correct, complete or up-to-date. RUNNER excludes to the extent lawfully permitted all liability (including for consequential loss) however arising (including due to negligence) that may be incurred in connection with any use of or reliance upon this information. We exclude all implied conditions and warranties except where to do so would cause any of these provisions to be void. RUNNER reserves the right to update any information on this website (including these terms and conditions) at any time, without prior notice. While we endeavor to ensure that all vehicle specifications, features and accessories are described and positioned as accurately as possible, all such information is indicative only. Your Mitsubishi dealer can confirm specific details and can provide you with an official quotation. Unless stated otherwise, RUNNER owns or licenses the copyright and all other proprietary rights in information on this website (including text and images). You may not use any copyrighted information or trade marks appearing on this website without the permission of RUNNER or as permitted by Indonesian law.',
          ),
        },
      ],
    },
  ];
  return {
    legalContentItems,
  };
}

export default useLegalContent;
