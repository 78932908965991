import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type LegalRouteParam = DefaultRouteParam;

export const legalRoute: RouteConfig = {
  name: 'Disclaimer, Terms of Use, and Privacy Policy',
  Component: React.lazy(() => import('./Legal.view')),
  path: '/privacy-policy',
  isPrivate: false,
  breadcrumb: 'Disclaimer, Terms of Use, and Privacy Policy',
};
