import React, { useRef, useState } from 'react';
import tw, { theme, TwStyle } from 'twin.macro';
import { ErrorFormContainer, Icon, Row, Switch, Text } from '../../atom';
import { BodyTwelve } from '../../atom/Text/Text.atom';
import { ExtraStyle } from '../../Type.component';
import Tooltip from '../Tooltip/Tooltip.molecule';

type Props = {
  label?: string;
  isRequired?: boolean;
  values?: boolean;
  error?: string;
  disabled?: boolean;
  tooltipText?: string;
  labelStyle?: false | '' | TwStyle;
  descriptionLabelStyle?: false | '' | TwStyle;
  containerStyle?: ExtraStyle;
  wrapperStyle?: ExtraStyle;
  description?: string;
  onChange: (value?: boolean) => void;
};

const Container = tw.div`flex flex-col min-h-[56px]`;
const LabelFormContainer = tw.div`min-width[180px] mr-5`;

export default function SwitchInput({
  label,
  isRequired,
  error,
  values,
  disabled,
  tooltipText,
  containerStyle,
  labelStyle,
  descriptionLabelStyle,
  wrapperStyle,
  description,
  onChange,
}: Props) {
  const tooltipRef = useRef(null);
  const [visible, setVisible] = useState(false);
  return (
    <Container css={wrapperStyle}>
      <Row css={containerStyle}>
        {label && (
          <LabelFormContainer css={[tooltipText && tw`flex flex-row`]}>
            <Text.BodyFourteen css={[tw`text-grey-three`, labelStyle]}>
              {`${label} ${isRequired ? '*' : ''}`}
            </Text.BodyFourteen>
            {tooltipText && (
              <>
                <Icon.InfoOutlined
                  svgRef={tooltipRef}
                  svgStyle={[tw`ml-1 cursor-default text-grey-three`]}
                  fillPath={theme`colors.grey.three`}
                  onMouseEnter={() => setVisible(true)}
                  onMouseLeave={() => setVisible(false)}
                />
                <Tooltip
                  targetRef={tooltipRef}
                  visible={visible}
                  variant="light"
                  placement="top-start"
                  offset={[-20, 20]}
                >
                  <Text.Label>{tooltipText}</Text.Label>
                </Tooltip>
              </>
            )}
          </LabelFormContainer>
        )}
        <Switch
          disabled={disabled}
          checked={values}
          onChange={(e) => onChange(e.target.checked)}
        />
      </Row>
      {!!description && (
        <Row tw="mr-14 pt-0">
          <BodyTwelve css={[tw`text-justify`, descriptionLabelStyle]}>
            {description}
          </BodyTwelve>
        </Row>
      )}
      {!!error && (
        <Row>
          <ErrorFormContainer hasLabel={!!label}>{error}</ErrorFormContainer>
        </Row>
      )}
    </Container>
  );
}
