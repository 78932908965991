import { useCallback, useMemo } from 'react';
import useTranslator from '../useTranslator.hook';

type Props = {
  isFormValid?: boolean;
  redirectTo: () => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  handleSubmit: () => void;
};

export default function useJOFormBlocker({
  isFormValid,
  redirectTo,
  setHasUnsavedChanges,
  handleSubmit,
}: Props) {
  const { translate } = useTranslator();
  // #region ACTION BLOCKER
  const handleDiscard = useCallback(() => {
    setHasUnsavedChanges(false);
    redirectTo();
  }, [redirectTo, setHasUnsavedChanges]);
  // #endregion
  // #region confirmation

  const confirmationObj = useMemo(
    () => ({
      title: translate('Are you sure you want to leave?'),
      actionLabel: translate(isFormValid ? 'Save Changes' : 'Discard Changes'),
      cancelLabel: translate(isFormValid ? 'Discard Changes' : 'Stay on Page'),
      description: translate(
        'It looks like you are in the middle of writing something and you have not saved the changes.  Unsaved changes will be lost',
      ),
      isSecondaryValid: isFormValid,
      handleConfirmAction: () => {
        if (isFormValid) {
          handleSubmit();
          return;
        }
        handleDiscard();
      },
      handleCancelAction: () => {
        if (isFormValid) {
          handleDiscard();
          return;
        }
        setHasUnsavedChanges(false);
      },
    }),
    [handleDiscard, handleSubmit, isFormValid, setHasUnsavedChanges, translate],
  );

  // #endregion

  return { confirmationObj, isFormValid, handleDiscard };
}

export type UseJOFormBlockerHookObj = ReturnType<typeof useJOFormBlocker>;
