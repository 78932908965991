import React from 'react';
import { RouteConfig } from '../../config/router/router.config';

export type TrackingRouteParam = {
  trackingNumber?: string;
};

export const trackingRoute: RouteConfig = {
  name: 'Tracking',
  Component: React.lazy(() => import('./Tracking.view')),
  path: '/track',
  isPrivate: false,
};
