import React from 'react';

export default function MapMarker({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={13} fill="none">
      <path
        fillRule="evenodd"
        d="M10.322 7.263c.384-.794.578-1.498.578-2.063a3.9 3.9 0 1 0-7.8 0c0 .565.194 1.269.578 2.063.378.782.902 1.572 1.465 2.286.561.713 1.138 1.32 1.6 1.736.094.084.18.158.257.22.077-.062.163-.136.256-.22A14.407 14.407 0 0 0 8.857 9.55c.563-.714 1.087-1.504 1.465-2.286ZM7 13c.743 0 5.2-4.457 5.2-7.8a5.2 5.2 0 1 0-10.4 0C1.8 8.543 6.257 13 7 13Z"
        clipRule="evenodd"
        fill={fill || 'currentColor'}
      />
      <path
        fill={fill || 'currentColor'}
        d="M8.95 5.2a1.95 1.95 0 1 1-3.9 0 1.95 1.95 0 0 1 3.9 0Z"
      />
    </svg>
  );
}
