import { nanoid } from '@reduxjs/toolkit';
import * as echarts from 'echarts';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from 'twin.macro';
import { BarChartSerie } from '../../../model/Summary.model';
import { generateChartTooltip } from '../../../util/home/home.util';
import { formatText } from '../../../util/tracking/trackingTimeline.util';

type Props = {
  data: BarChartSerie[];
  filterLabel: string;
  testId?: string;
};
const testIds = {
  chart: (parent?: string) =>
    formatText('%s:SummaryBarChart', parent || 'Molecule'),
};
function SummaryBarChart({ data, filterLabel, testId }: Props) {
  const [lastLabel, setLastLabel] = useState<string | undefined>(undefined);
  const chartDom = useRef<HTMLDivElement>(null);
  const dataZoom = useMemo((): echarts.EChartsOption['dataZoom'] => {
    const baseZoomConfig: echarts.EChartsOption['dataZoom'] = {
      handleSize: 0,
      handleStyle: { opacity: 0 },
      height: 0,
      moveHandleSize: 12,
      showDetail: false,
      left: 0,
      right: 0,
      bottom: 16,
    };

    if (data.length > 12) {
      return [
        {
          ...baseZoomConfig,
          // `end` is determine how many data should be in container viewport
          // the value is in percentage, below calculation ensure to render max 12 data
          // no matter how many data we received
          end: (12 / data.length) * 100,
        },
      ];
    }

    return undefined;
  }, [data.length]);

  const grid = useMemo(() => {
    const baseConfig = {
      left: 0,
      right: 0,
      containLabel: true,
    };

    if (data.length > 12) return { ...baseConfig, bottom: 24 };

    return {
      ...baseConfig,
      bottom: 0,
    };
  }, [data.length]);

  const tooltip = useMemo(
    (): echarts.EChartsOption['tooltip'] => ({
      trigger: 'axis',
      formatter: (params: echarts.TooltipComponentFormatterCallbackParams) => {
        const [so, jo] =
          params as echarts.DefaultLabelFormatterCallbackParams[];
        const element = data.find((_, idx) => idx === so.dataIndex);

        if (!element) return '';
        const month = element?.month;
        const title = element.day
          ? formatText('%s %s', element.day, month)
          : month;

        return generateChartTooltip({
          title,
          data: [
            {
              id: nanoid(),
              withBullet: true,
              bulletStyle: theme`colors.status.success`,
              value: so.data.toString(),
            },
            {
              id: nanoid(),
              withBullet: true,
              bulletStyle: theme`colors.status[fuel-anomaly]`,
              value: jo.data.toString(),
            },
          ],
        });
      },
    }),
    [data],
  );

  const option = useMemo(
    (): echarts.EChartsOption => ({
      grid,
      animation: false,
      tooltip,
      xAxis: {
        type: 'category',
        data: data.map((v) => {
          if (v.day) return v.day;
          const [month] = v.month.split(' '); // e.g: Jan 2023, Feb 2023 to Jan, Feb
          return month;
        }),
        axisTick: {
          alignWithLabel: true,
        },
        triggerEvent: true,
      },
      yAxis: { type: 'value', min: 5 },
      series: [
        {
          type: 'bar',
          data: data.map((v) => v.shipperOrder),
          name: 'Shipper Order Delivered',
          color: theme`colors.status.success.DEFAULT`,
        },
        {
          type: 'bar',
          data: data.map((v) => v.jobOrder),
          name: 'Job Order Delivered',
          color: theme`colors.status[fuel-anomaly]`,
        },
      ],

      dataZoom,
    }),
    [data, dataZoom, grid, tooltip],
  );
  useEffect(() => {
    const myChart = echarts.init(chartDom.current, null, {
      renderer: 'canvas',
      useDirtyRect: false,
    });

    const chartResizeCallback = () => myChart.resize();
    if (lastLabel !== filterLabel) {
      setLastLabel(filterLabel);
    }

    myChart.clear();
    myChart.setOption(option);
    window.addEventListener('resize', chartResizeCallback);
    return () => {
      window.removeEventListener('resize', chartResizeCallback);
    };
  }, [filterLabel, lastLabel, option]);

  return (
    <div
      ref={chartDom}
      id="chart-container"
      data-testid={testIds.chart(testId)}
      tw="w-full h-full overflow-hidden max-w-full"
    />
  );
}

export default SummaryBarChart;
