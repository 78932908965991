import React from 'react';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import ShipperOrderDetailDesktopView from './ShipperOrderDetail.desktop.view';
import ShipperOrderDetailMobileView from './ShipperOrderDetail.mobile.view';

export default function ShipperOrderDetailView() {
  const { isMobile } = useScreenDetection();

  if (isMobile) return <ShipperOrderDetailMobileView />;

  return <ShipperOrderDetailDesktopView />;
}
