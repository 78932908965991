import React from 'react';
import { Icon } from '../../component/atom';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../config/router/router.config';

export type HomeRouteParam = DefaultRouteParam;

export const homeRoute: RouteConfig = {
  name: 'Dashboard',
  path: '/dashboard',
  Component: React.lazy(() => import('./Home.view')),
  isPrivate: true,
  options: {
    icon: <Icon.NavDashboard />,
    label: 'Dashboard',
    testID: 'MenuDashboard',
  },
};
