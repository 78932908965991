import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { HaulingOrderCreateNavigateParams } from '../HaulingOrderCreate/HaulingOrderCreate.route';

export type HOLocationAddRouteParam =
  | (HaulingOrderCreateNavigateParams & {
      deliveryIndex?: number;
    })
  | undefined;

export const hoLocationAddRoute: RouteConfig = {
  name: 'Add New Location',
  Component: React.lazy(() => import('./HOLocationAdd.view')),
  path: '/hauling-order/create/add-new-location',
  isPrivate: true,
  breadcrumb: 'Add New Location',
};
