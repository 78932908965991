import {
  GetLocationApiRequest,
  GetLocationApiResponse,
  Location,
} from '../model/Location.model';
import api from '../service/api.service';
import useFetchMoreAutocomplete, {
  AutocompleteOptionsData,
  FetchMoreAutocompleteProps,
} from './useFetchMoreAutocomplete.hook';

// #region INTERFACES
type UseGetLocationsAutocompleteParams = {
  filterCallback: FetchMoreAutocompleteProps<
    Location,
    GetLocationApiRequest,
    GetLocationApiResponse
  >['filterCallback'];
};
// #endregion

export default function useGetLocationsAutocomplete(
  props?: UseGetLocationsAutocompleteParams,
) {
  const locationListOption: AutocompleteOptionsData<Location> = {
    dataKey: 'locations',
    labelKey: 'name',
    valueKey: 'id',
  };

  const {
    dataOptions: locationOptions,
    handleChangeAutotext: onChangeLocationAutotext,
    handleFetchMore: onFetchMoreLocation,
    isFetching: locationListFetchLoading,
  } = useFetchMoreAutocomplete({
    options: locationListOption,
    api: api.useLazyGetLocationsQuery,
    filterCallback: props?.filterCallback,
  });

  return {
    locationOptions,
    locationListFetchLoading,
    onChangeLocationAutotext,
    onFetchMoreLocation,
  };
}
