import add from 'date-fns/add';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import intervalToDuration from 'date-fns/intervalToDuration';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import toDate from 'date-fns/toDate';
import {
  DriverSortByValue,
  DriverStatusFilter,
  DriverStatusValue,
} from '../constant/Driver.constant';
import { driverDetailTimesheetFilterInitialValues } from '../constant/Driver/DriverDetail.constant';
import { UseTranslator } from '../hook/useTranslator.hook';
import {
  CreateDriverTimesheetRequest,
  DriverOrderBy,
  DriverPINInfoProps,
  DriverStatus,
  PostDriverCreateParams,
} from '../model/Driver.model';

/**
 * Map driver sortBy filter from URL query to labeled string
 * @param {DriverOrderBy} sortBy - The sortBy query
 * @returns {string} sort by label
 */
export function mapDriverFilterSortByToLabel(sortBy: DriverOrderBy): string {
  const literal: Record<DriverOrderBy, string> = {
    [DriverOrderBy.DRIVER_NAME_ASC]: 'Name A-Z',
    [DriverOrderBy.DRIVER_NAME_DESC]: 'Name Z-A',
    [DriverOrderBy.STATUS_ASC]: 'Status Ascending',
    [DriverOrderBy.STATUS_DESC]: 'Status Descending',
    [DriverOrderBy.CREATED_AT_DESC]: 'Recently Added',
  };

  return literal[sortBy];
}

/**
 * Map driver status number to labeled string
 * @param {DriverStatus} status - The model status number
 * @returns {string} status label
 */
export function mapDriverStatusToLabel(status: DriverStatus): string {
  const literal: Record<DriverStatus, string> = {
    [DriverStatus.ACTIVE]: 'Active',
    [DriverStatus.INACTIVE]: 'Inactive',
    [DriverStatus.ON_DUTY]: 'On Duty',
  };

  return literal[status];
}

/**
 * Map driver status[] filter from URL query to labeled string
 * @param {DriverStatusFilter} statuses - The status array from URL query
 * @param {UseTranslator} translator
 * @returns {string[]} status labels
 */
export function mapDriverFilterStatusesToLabels(
  statuses: DriverStatusFilter[],
  translator: UseTranslator,
): string[] {
  const literal: Record<DriverStatusFilter, string> = {
    [DriverStatusFilter.ACTIVE]: translator.translate('Active'),
    [DriverStatusFilter.INACTIVE]: translator.translate('Inactive'),
    [DriverStatusFilter.ON_DUTY]: translator.translate('On Duty'),
  };

  return statuses.map((status) => literal[status]);
}

/**
 * Get driver filter sort by label & value array
 * @param {UseTranslator} translator - The translator object
 * @returns {DriverSortByValue[]} sort by label & value array
 */
export function getDriverFilterSortByValues(
  translator: UseTranslator,
): DriverSortByValue[] {
  return [
    {
      label: translator.translate('Name A-Z'),
      value: DriverOrderBy.DRIVER_NAME_ASC,
    },
    {
      label: translator.translate('Name Z-A'),
      value: DriverOrderBy.DRIVER_NAME_DESC,
    },
    {
      label: translator.translate('Status Ascending'),
      value: DriverOrderBy.STATUS_ASC,
    },
    {
      label: translator.translate('Status Descending'),
      value: DriverOrderBy.STATUS_DESC,
    },
    {
      label: translator.translate('Recently Added'),
      value: DriverOrderBy.CREATED_AT_DESC,
    },
  ];
}

/**
 * Get driver filter status label & value array
 * @param {UseTranslator} translator - The translator object
 * @returns {DriverStatusValue[]} status label & value array
 */
export function getDriverFilterStatusValues(
  translator: UseTranslator,
): DriverStatusValue[] {
  return [
    {
      label: translator.translate('On Duty'),
      value: DriverStatusFilter.ON_DUTY,
    },
    {
      label: translator.translate('Active'),
      value: DriverStatusFilter.ACTIVE,
    },
    {
      label: translator.translate('Inactive'),
      value: DriverStatusFilter.INACTIVE,
    },
  ];
}

/**
 * Get driver status numbers from driver status[] filter query
 * @param {DriverStatusFilter[]} statuses - The status array from URL query
 * @returns {number[]} driver status -> 101 | 102 | 201
 */
export function getDriverStatusesFromFilter(
  statuses: DriverStatusFilter[],
): DriverStatus[] {
  return statuses.map((status) => {
    const literal: Record<DriverStatusFilter, DriverStatus> = {
      [DriverStatusFilter.INACTIVE]: DriverStatus[status],
      [DriverStatusFilter.ON_DUTY]: DriverStatus[status],
      [DriverStatusFilter.ACTIVE]: DriverStatus[status],
    };

    return literal[status];
  });
}

/**
 * Transform PostDriverCreate Params to DriverPinInfoProps
 * @param {PostDriverCreateParams} driverInfo - Driver info object
 * @param {string} driverId - The driver id
 * @returns {DriverPINInfoProps}
 */
export function formatDriverInfoToDriverPin(
  driverInfo?: PostDriverCreateParams,
  driverId?: string,
): DriverPINInfoProps {
  return {
    id: driverId || '',
    name: driverInfo?.fullName || '',
    phoneNumber: driverInfo?.phoneNumber || '',
    pin: driverInfo?.pin || '',
    expiredDate: format(addDays(startOfToday(), 7), 'dd MMM yyyy'),
  };
}

/**
 * check bulk driver add excel data validity
 */
export const checkDriverAddExcelDataValidity = (
  columns: unknown[],
  data: unknown[],
) => {
  const commonErrorLabel =
    'has invalid format. Please use provided XLS template and re-upload';
  const exceeded100ErrorLabel =
    'exceeded the maximum data allowed. Please reduce the data amount to a maximum of 100 rows';

  // check at least 1 data / data columns to be exactly 6
  if (!data.length || columns.length !== 6) return commonErrorLabel;
  // check max 100 data
  if (data.length > 100) return exceeded100ErrorLabel;

  return '' as const;
};

/**
 * transform/parse date range filter search params
 */
export function transformDateRangeParam(param: {
  startDate?: string;
  endDate?: string;
}) {
  const startDate = Number(param.startDate)
    ? toDate(Number(param.startDate))
    : driverDetailTimesheetFilterInitialValues.startDate;
  const endDate = Number(param.endDate)
    ? toDate(Number(param.endDate))
    : driverDetailTimesheetFilterInitialValues.endDate;

  return { startDate, endDate };
}

/**
 * given the input in seconds, format the duration in a custom format (e.g "02d 21h 59m 55s" or in bahasa "02h 21j 59m 55d")
 */
export function formatSecondsToCustomDuration(
  second: number,
  options: { translator: UseTranslator },
) {
  const duration = intervalToDuration({ start: 0, end: second * 1_000 });
  const { years, months, days, hours, minutes, seconds } = duration;
  const totalDays = (days ?? 0) + (months ?? 0) * 30 + (years ?? 0) * 365;
  const totalHours = hours ?? 0;
  const totalMinutes = minutes ?? 0;
  const totalSeconds = seconds ?? 0;

  const addPrefix = (digit: number) => String(digit).padStart(2, '0');
  const addSuffix = (word: string) =>
    options.translator.translate(word).charAt(0);

  const formattedDuration = [
    ...(totalDays > 0 ? [`${addPrefix(totalDays)}${addSuffix('days')}`] : []),
    ...(totalHours > 0
      ? [`${addPrefix(totalHours)}${addSuffix('hours')}`]
      : []),
    ...(totalMinutes > 0
      ? [`${addPrefix(totalMinutes)}${addSuffix('minutes')}`]
      : []),
    ...(totalSeconds > 0
      ? [`${addPrefix(totalSeconds)}${addSuffix('seconds')}`]
      : []),
  ].join(' ');

  return formattedDuration;
}

/**
 * @param time - mask format "__:__" (e.g "09:01")
 */
export function getTimeFromMaskInput(time: string) {
  return {
    hour: time.slice(0, 2),
    minute: time.slice(3, 5),
  };
}

/**
 * @param time - time in unix seconds (e.g 1330515905)
 * @returns clock picker mask input string (e.g "10:22")
 */
export function getMaskInputFromUnixTime(time: number) {
  const date = fromUnixTime(time);
  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${hour.toString().padStart(2, '0')}:${minute
    .toString()
    .padStart(2, '0')}`;
}

/**
 * format input into driver timesheet create DTO.
 * `startTime` & `endTime` mask format "__:__" (e.g "09:01")
 */
export function formatIntoDriverCreateTimesheetDTO({
  driverId,
  date,
  startTime,
  endTime,
}: {
  driverId: string;
  date: Date;
  startTime: string;
  endTime: string;
}): CreateDriverTimesheetRequest {
  const start = getTimeFromMaskInput(startTime);
  const end = getTimeFromMaskInput(endTime);

  return {
    driverId,
    startedAt: getUnixTime(
      add(startOfDay(date), {
        hours: +start.hour,
        minutes: +start.minute,
      }),
    ),
    stoppedAt: getUnixTime(
      add(startOfDay(date), {
        hours: +end.hour,
        minutes: +end.minute,
      }),
    ),
  };
}
