import { useCallback, useMemo, useState } from 'react';
import {
  JobOrderInfo,
  JODetailMapAction,
  JOStatus,
} from '../../model/JobOrder.model';
import useTranslator from '../useTranslator.hook';
import useWatchDriverPosition from '../useWatchDriverPosition/useWatchDriverPosition.hook';
import useWatchVehiclePosition from '../useWatchVehiclePosition/useWatchVehiclePosition.hook';

interface UseJODetailMapsProps {
  joInfoData?: JobOrderInfo;
}

export default function useJODetailMaps({ joInfoData }: UseJODetailMapsProps) {
  // #region GENERAL
  const translator = useTranslator();
  const [isFocusVehicleActive, setIsFocusVehicleActive] = useState(true);
  const [isFocusDriverActive, setIsFocusDriverActive] = useState(true);
  const [showVehicle, setShowVehicle] = useState(true);
  const [showDriver, setShowDriver] = useState(true);

  const isLocationHidden = useMemo(
    () => !showDriver && !showVehicle,
    [showDriver, showVehicle],
  );

  const showMapLocation =
    (joInfoData?.vehicle?.id || joInfoData?.driver?.id) &&
    [JOStatus.ASSIGNED, JOStatus.DELIVERING].includes(joInfoData.status);

  const driverName = useMemo(
    () => joInfoData?.driver?.fullName,
    [joInfoData?.driver?.fullName],
  );
  const driverPhoneNumber = useMemo(
    () => joInfoData?.driver?.phoneNumber,
    [joInfoData?.driver?.phoneNumber],
  );

  // #endregion

  // #region API CALL
  const { isDriverOnline, driverLastLatLng } = useWatchDriverPosition({
    joInfo: joInfoData,
  });

  const renderDriverPosition = useMemo(
    () => showDriver && driverLastLatLng,
    [showDriver, driverLastLatLng],
  );

  const { vehicleHeading, vehicleLastLatLng } = useWatchVehiclePosition({
    joInfo: joInfoData,
  });

  const renderVehiclePosition = useMemo(
    () => showVehicle && vehicleLastLatLng,
    [showVehicle, vehicleLastLatLng],
  );

  const targetLocations = useMemo(() => {
    if (!vehicleLastLatLng && !driverLastLatLng) return undefined;
    return [
      ...(vehicleLastLatLng && isFocusVehicleActive && showVehicle
        ? [vehicleLastLatLng]
        : []),
      ...(driverLastLatLng && isFocusDriverActive && showDriver
        ? [driverLastLatLng]
        : []),
    ];
  }, [
    driverLastLatLng,
    isFocusDriverActive,
    isFocusVehicleActive,
    showDriver,
    showVehicle,
    vehicleLastLatLng,
  ]);
  // #endregion

  // #region ACTION
  const mapActions: JODetailMapAction[] = useMemo(
    () => [
      {
        label: translator.translate('Driver'),
        selected: showDriver,
        disabled: !driverLastLatLng,
        action: () => {
          if (!driverLastLatLng) {
            return;
          }
          if (!isFocusVehicleActive) {
            setIsFocusVehicleActive(true);
          }
          setShowDriver((v) => !v);
          if (!vehicleLastLatLng) {
            setShowVehicle(!!vehicleLastLatLng);
          }
        },
      },
      {
        label: translator.translate('Truck'),
        selected: showVehicle,
        disabled: !vehicleLastLatLng,
        action: () => {
          if (!vehicleLastLatLng) return;
          if (!isFocusDriverActive) {
            setIsFocusDriverActive(true);
          }
          if (!driverLastLatLng) {
            setShowDriver(!!driverLastLatLng);
          }
          setShowVehicle((v) => !v);
        },
      },
    ],
    [
      driverLastLatLng,
      isFocusDriverActive,
      isFocusVehicleActive,
      showDriver,
      showVehicle,
      translator,
      vehicleLastLatLng,
    ],
  );

  const handleClickMarkerDriver = useCallback(() => {
    if (!vehicleLastLatLng) return;
    if (!isFocusDriverActive) {
      setIsFocusDriverActive(true);
    }
    if (!driverLastLatLng) {
      setShowDriver(!!driverLastLatLng);
    }
    setShowVehicle(false);
  }, [driverLastLatLng, isFocusDriverActive, vehicleLastLatLng]);

  const handleClickMarkerVehicle = useCallback(() => {
    if (!driverLastLatLng) {
      return;
    }
    if (!isFocusVehicleActive) {
      setIsFocusVehicleActive(true);
    }
    setShowDriver(false);
    if (!vehicleLastLatLng) {
      setShowVehicle(!!vehicleLastLatLng);
    }
  }, [driverLastLatLng, isFocusVehicleActive, vehicleLastLatLng]);

  const handleMapsCenter = useCallback(() => {
    if (vehicleLastLatLng) {
      setShowVehicle(true);
      setIsFocusVehicleActive(true);
    }
    if (driverLastLatLng) {
      setIsFocusDriverActive(true);
      setShowDriver(true);
    }
  }, [driverLastLatLng, vehicleLastLatLng]);
  // #endregion
  return {
    isLocationHidden,
    isDriverOnline,
    mapActions,
    targetLocations,
    driverName,
    driverPhoneNumber,
    vehicleHeading,
    renderDriverPosition,
    renderVehiclePosition,
    showMapLocation,
    handleMapsCenter,
    handleClickMarkerDriver,
    handleClickMarkerVehicle,
  };
}

export type UseJODetailMapsHookObj = ReturnType<typeof useJODetailMaps>;
