import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import tw, { styled } from 'twin.macro';
import { SOActivityType } from '../../../constant';
import {
  ReportSOSelectedModal,
  ReportSOSelectedModalExpectedTimeValue,
  ReportSOSelectedModalLocationValue,
  ReportSOSelectedModalValue,
} from '../../../constant/ReportShipperOrder.constant';
import { HeadingThree } from '../../atom/Text/Text.atom';
import { ActivityTableBody, ActivityTableHeader, Modal } from '../../molecule';

// #region INTERFACES
export type ReportSOActivityModalProps = {
  pickupTitle: string;
  pickupNoDataTitle: string;
  dropoffTitle: string;
  dropoffNoDataTitle: string;
  selectedSO: ReportSOSelectedModal;
  selectedModalValue: NonNullable<ReportSOSelectedModalValue>;
  onClose(): void;
};
// #endregion

// #region STYLED
const ScroolbarBodyContainer = tw.div`flex flex-col space-y-5 py-5 px-6`;
const ActivityGoodsContainer = styled.div<{ type: SOActivityType }>(
  ({ type }) => [
    tw`py-2.5 px-5 rounded-md border-2 animate-slide-in-left`,
    type === SOActivityType.PICK_UP && tw`border-ocean-text`,
    type === SOActivityType.DROP_OFF && tw`border-twilight-text`,
  ],
);
const ActivityTable = tw.table`w-full table border-spacing[12px]`;
// #endregion

function ReportSOActivityModal({
  pickupTitle,
  pickupNoDataTitle,
  dropoffTitle,
  dropoffNoDataTitle,
  selectedSO,
  selectedModalValue,
  onClose,
}: ReportSOActivityModalProps) {
  return (
    <Modal.WithHeader
      css={tw`p-0 w-[900px]`}
      title={selectedModalValue.modalTitle}
      onClose={onClose}
      onOverlayClick={onClose}
    >
      <Scrollbars tw="max-h-[620px] min-h-[450px] 2xl:(min-h-[472px])">
        <ScroolbarBodyContainer>
          <HeadingThree>{pickupTitle}</HeadingThree>

          {selectedSO.type === 'location' ? (
            <ActivityGoodsContainer type={SOActivityType.PICK_UP}>
              {(selectedModalValue as ReportSOSelectedModalLocationValue)
                .pickupGoods.length > 0 ? (
                <ActivityTable>
                  <ActivityTableHeader
                    status={SOActivityType.PICK_UP}
                    items={selectedModalValue.modalTableHeader}
                  />

                  <ActivityTableBody
                    status={SOActivityType.PICK_UP}
                    items={
                      (selectedModalValue as ReportSOSelectedModalLocationValue)
                        .pickupGoods
                    }
                  />
                </ActivityTable>
              ) : (
                pickupNoDataTitle
              )}
            </ActivityGoodsContainer>
          ) : (
            <ActivityGoodsContainer type={SOActivityType.PICK_UP}>
              {(selectedModalValue as ReportSOSelectedModalExpectedTimeValue)
                .pickupExpectedTimes.length > 0 ? (
                <ActivityTable>
                  <ActivityTableHeader
                    status={SOActivityType.PICK_UP}
                    items={selectedModalValue.modalTableHeader}
                  />

                  <ActivityTableBody
                    hideFooter
                    status={SOActivityType.PICK_UP}
                    items={
                      (
                        selectedModalValue as ReportSOSelectedModalExpectedTimeValue
                      ).pickupExpectedTimes
                    }
                  />
                </ActivityTable>
              ) : (
                pickupNoDataTitle
              )}
            </ActivityGoodsContainer>
          )}

          <HeadingThree>{dropoffTitle}</HeadingThree>

          {selectedSO.type === 'location' ? (
            <ActivityGoodsContainer type={SOActivityType.DROP_OFF}>
              {(selectedModalValue as ReportSOSelectedModalLocationValue)
                .dropoffGoods.length > 0 ? (
                <ActivityTable>
                  <ActivityTableHeader
                    status={SOActivityType.DROP_OFF}
                    items={selectedModalValue.modalTableHeader}
                  />

                  <ActivityTableBody
                    status={SOActivityType.DROP_OFF}
                    items={
                      (selectedModalValue as ReportSOSelectedModalLocationValue)
                        .dropoffGoods
                    }
                  />
                </ActivityTable>
              ) : (
                dropoffNoDataTitle
              )}
            </ActivityGoodsContainer>
          ) : (
            <ActivityGoodsContainer type={SOActivityType.DROP_OFF}>
              {(selectedModalValue as ReportSOSelectedModalExpectedTimeValue)
                .dropoffExpectedTimes.length > 0 ? (
                <ActivityTable>
                  <ActivityTableHeader
                    status={SOActivityType.DROP_OFF}
                    items={selectedModalValue.modalTableHeader}
                  />

                  <ActivityTableBody
                    hideFooter
                    status={SOActivityType.DROP_OFF}
                    items={
                      (
                        selectedModalValue as ReportSOSelectedModalExpectedTimeValue
                      ).dropoffExpectedTimes
                    }
                  />
                </ActivityTable>
              ) : (
                dropoffNoDataTitle
              )}
            </ActivityGoodsContainer>
          )}
        </ScroolbarBodyContainer>
      </Scrollbars>
    </Modal.WithHeader>
  );
}

export default ReportSOActivityModal;
