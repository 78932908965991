import {
  CommonApiListParams,
  CommonApiMetadata,
  CommonApiResponse,
} from '../service/api.endpoint';

export enum VehicleStatus {
  STANDBY = 101,
  ASSIGNED = 201,
  ON_DUTY = 301,
}

export type VehicleSummary = {
  status: VehicleStatus;
  statusText: string;
  totalVolumeUsed: number;
  totalWeightUsed: number;
};

export type Vehicle = {
  id: string;
  name: string;
  chassisNumber: string;
  modelName: string;
  createdAt: number;
  imei: string;
  number: string | null;
  summary?: VehicleSummary;
  volume?: number;
  weight?: number;
};

export type VehicleLastPosition = {
  gpsTimestamp: number;
  latitude: number;
  longitude: number;
  altitude: number;
  heading: number;
  speed: number;
  updatedAt: number;
};

export enum VehicleOrderBy {
  NAME_ASC = 'NAME_ASC', // Name A-Z
  RECOMMENDED = 'RECOMMENDED', // Recommended
  RECOMMENDED_VOLUME = 'RECOMMENDED_VOLUME', // Recommended Volume
  RECOMMENDED_WEIGHT = 'RECOMMENDED_WEIGHT', // Recommended Weight
}

// #region VEHICLE LIST
type GetVehicleOrderByName = {
  orderBy: VehicleOrderBy.NAME_ASC;
};
export type GetVehicleOrderByRecommended = {
  orderBy: VehicleOrderBy.RECOMMENDED;
  orderWeight?: number;
  orderVolume?: number;
};
type GetVehicleOrderByRecommendedVolume = {
  orderBy: VehicleOrderBy.RECOMMENDED_VOLUME;
  orderVolume?: number;
};
type GetVehicleOrderByRecommendedWeight = {
  orderBy: VehicleOrderBy.RECOMMENDED_WEIGHT;
  orderWeight?: number;
};
export type GetVehicleOrderBy =
  | GetVehicleOrderByName
  | GetVehicleOrderByRecommended
  | GetVehicleOrderByRecommendedVolume
  | GetVehicleOrderByRecommendedWeight;
export type GetVehicleApiRequest =
  | CommonApiListParams
  | (CommonApiListParams & GetVehicleOrderBy);

export type GetVehicleApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    vehicles: Vehicle[];
  };

// #endregion

// #region VEHICLE UNASSIGNED LIST
export type GetVehicleUnassignedApiRequest =
  | (CommonApiListParams & { from?: number; to?: number })
  | never;

export type GetVehicleUnassignedApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    vehicles: Vehicle[];
  };

// #endregion
