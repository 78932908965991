import React from 'react';

export default function NoDataFound({
  fill,
  width = 471,
  height = 120,
  viewBox = '0 0 471 120',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1584_10115"
        style={{ maskType: 'alpha' }}
        width="471"
        height="236"
        x="0"
        y="38"
        maskUnits="userSpaceOnUse"
      >
        <ellipse
          cx="235.443"
          cy="156.076"
          fill="#C4C4C4"
          rx="235.443"
          ry="117.342"
        />
      </mask>
      <g mask="url(#mask0_1584_10115)">
        <path
          fill="url(#paint0_linear_1584_10115)"
          d="M0 -30.38H470.886V145.823H0z"
        />
      </g>
      <path
        fill="#F3532E"
        d="M117.502 9.986a3.02 3.02 0 013.026 3.026v7.506c0 3.605 2.922 6.542 6.527 6.542h2.759V15.727a3.02 3.02 0 013.026-3.026 3.02 3.02 0 013.026 3.026V40.9h2.581c3.59 0 6.527-2.937 6.527-6.542v-7.506A3.02 3.02 0 01148 23.826a3.02 3.02 0 013.026 3.026v7.506c0 6.942-5.637 12.594-12.579 12.594h-2.581v31.863a3.02 3.02 0 01-3.026 3.026 3.02 3.02 0 01-3.026-3.026V62.172h-2.759c-6.942 0-12.579-5.652-12.579-12.594v-7.506a3.02 3.02 0 013.026-3.026 3.02 3.02 0 013.026 3.026v7.506c0 3.604 2.922 6.541 6.527 6.541h2.759V33.112h-2.759c-6.942 0-12.579-5.652-12.579-12.594v-7.506c-.015-1.676 1.35-3.026 3.026-3.026zM81.367 50.305a3.02 3.02 0 013.026 3.026v5.37c0 2.106 1.706 3.827 3.812 3.827h1.113V34.462a3.02 3.02 0 013.026-3.026 3.02 3.02 0 013.026 3.026v17.163h.979c2.106 0 3.812-1.72 3.812-3.827v-5.37a3.02 3.02 0 013.026-3.026 3.02 3.02 0 013.026 3.026v5.385c0 5.444-4.42 9.879-9.864 9.879h-.98v21.954a3.02 3.02 0 01-3.025 3.026 3.02 3.02 0 01-3.026-3.026V68.58h-1.113c-5.444 0-9.864-4.435-9.864-9.88v-5.37a3.02 3.02 0 013.026-3.025z"
      />
      <g clipPath="url(#clip1_1584_10115)">
        <path
          fill="#898989"
          d="M351.266 4.375c-2.447 0-4.431 1.958-4.431 4.374v13.123c0 2.418-1.983 4.374-4.43 4.374V4.375c0-2.416-1.983-4.375-4.43-4.375-2.447 0-4.431 1.959-4.431 4.375v30.62c-2.447 0-4.43-1.956-4.43-4.374V17.498c0-2.417-1.984-4.375-4.43-4.375-2.447 0-4.431 1.959-4.431 4.374V30.62c0 7.25 5.954 13.123 13.291 13.123v8.749h8.861V34.994c7.338 0 13.291-5.873 13.291-13.123V8.748c0-2.416-1.983-4.373-4.43-4.373z"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1584_10115"
          x1="231.646"
          x2="235.443"
          y1="26.582"
          y2="145.823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4E0CC" />
          <stop offset="0.796" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_1584_10115">
          <path fill="#fff" d="M0 0H470.886V120H0z" />
        </clipPath>
        <clipPath id="clip1_1584_10115">
          <path
            fill="#fff"
            d="M0 0H53.165V52.492H0z"
            transform="translate(311.392)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
