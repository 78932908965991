import React from 'react';

export default function Phone({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={11} height={10} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.502 1.204v-.002A.654.654 0 0 1 1.087.5h2.097a.27.27 0 0 1 .251.17l.002.003.584 1.437a.27.27 0 0 1-.06.29l-.001.002-.82.827-.181.184.045.254a4.104 4.104 0 0 0 3.32 3.33l.255.045.184-.183.833-.825a.27.27 0 0 1 .29-.054l.009.003 1.446.58a.27.27 0 0 1 .163.249V8.846a.654.654 0 0 1-.654.654h-.051c-3.605-.211-5.617-1.823-6.764-3.585C.87 4.125.575 2.14.502 1.204Z"
        stroke={fill || '#898989'}
      />
    </svg>
  );
}
