import addDays from 'date-fns/addDays';
import startOfToday from 'date-fns/startOfToday';
import { RDBDriverFilterOrderByEnum } from '../model/ReportDrivingBehaviorByDriver.model';

// #region INTERFACES
export type RDBDriverFilterOrderByValue = {
  label: string;
  value: RDBDriverFilterOrderByEnum;
};

export type RDBByDriverFormInitialValues = {
  orderBy: RDBDriverFilterOrderByEnum;
  driverId: string | undefined;
  startDate: Date;
  endDate: Date;
};

// #endregion

export const rdbByDriverDateRangeInitialValues: Pick<
  RDBByDriverFormInitialValues,
  'startDate' | 'endDate'
> = {
  startDate: addDays(startOfToday(), -6),
  endDate: startOfToday(),
};

export const rdbByDriverFormInitialValues: RDBByDriverFormInitialValues = {
  ...rdbByDriverDateRangeInitialValues,
  orderBy: RDBDriverFilterOrderByEnum.NAME_ASC,
  driverId: undefined,
};
