import React, { MutableRefObject } from 'react';
import { isMobile } from 'react-device-detect';
import { FixedSizeList as List } from 'react-window';
import tw, { styled, TwStyle } from 'twin.macro';
import useAutocomplete, {
  AutocompleteProps,
} from '../../../hook/useAutocomplete.hook';
import { OutsideAlerter, Popover, Text } from '../../atom';
import { BodySixteen, HeadingFour } from '../../atom/Text/Text.atom';
import TextField from '../TextField/TextField.molecule';

// #region STYLED
const PopOverContainer = tw.div`relative`;
const ListItemHeading = tw.div`whitespace-nowrap overflow-hidden overflow-ellipsis`;
const ListItemMeta = tw.div`whitespace-nowrap overflow-hidden overflow-ellipsis`;
const ListItemContainer = tw.div`relative p-3 hover:(bg-orange-hover) cursor-pointer whitespace-nowrap overflow-hidden overflow-ellipsis`;
const Container = styled.div(
  (props: { open: boolean; titleSearch: boolean }) => [
    `input[type='search']::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    input[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }`,
    props.open && tw`w-full`,
    props.open && props.titleSearch && tw`width[calc(100vw - 72px)]`,
  ],
);
// #endregion

type Props = AutocompleteProps & { rootStyle?: TwStyle };

export default function AutoComplete({
  placeholder,
  error,
  value,
  hideIcon,
  hideCloseButton,
  loading = false,
  alwaysOpen = false,
  titleSearch = false,
  disabled,
  datas,
  hasExtraLabel,
  selectedOption,
  labelKey = 'label',
  inputStyle,
  insideLabel,
  rootStyle,
  onBlurAutocomplete = () => {},
  onRemoveData = () => {},
  onFetchMore = () => {},
  changeInputText = () => {},
  changeData = () => {},
}: Props) {
  const {
    inputRef,
    listRef,
    listHeight,
    itemHeight,
    isOpenSearch,
    searchValue,
    openDropdown,
    setOpenDropdown,
    handleClickAway,
    handleFocus,
    handleClickTextField,
    handleOnScrollList,
    handleChangeTextField,
    handleSelect,
    renderIcon,
  } = useAutocomplete({
    placeholder,
    error,
    value,
    hideIcon,
    loading,
    alwaysOpen,
    hideCloseButton,
    titleSearch,
    disabled,
    datas,
    hasExtraLabel,
    selectedOption,
    labelKey,
    onBlurAutocomplete,
    onRemoveData,
    onFetchMore,
    changeInputText,
    changeData,
  });

  return (
    <OutsideAlerter
      css={[isMobile && tw`w-full`, rootStyle]}
      onClickAway={handleClickAway}
    >
      <Container
        className="search-container"
        titleSearch={titleSearch}
        open={isOpenSearch}
      >
        <TextField
          css={[
            tw`px-5 py-3 whitespace-nowrap overflow-hidden overflow-ellipsis `,
            !!value && tw`pr-10`,
            !!insideLabel && tw`pt-6 pl-3`,
          ]}
          label={insideLabel}
          inputStyle={inputStyle}
          onFocus={handleFocus}
          ref={inputRef}
          onClick={handleClickTextField}
          disabled={disabled}
          value={searchValue || ''}
          type="search"
          onChange={(e) => {
            handleChangeTextField(e?.target?.value);
          }}
          error={error}
          placeholder={placeholder}
          right={renderIcon()}
        />
        {datas?.length ? (
          <PopOverContainer tw="relative">
            <Popover
              visible={openDropdown}
              targetRef={inputRef as MutableRefObject<null>}
              tw="min-w-full z-20 overflow-visible bg-white shadow"
              onClick={() => setOpenDropdown(false)}
            >
              {datas && (
                <List
                  ref={listRef}
                  useIsScrolling
                  height={listHeight}
                  onScroll={handleOnScrollList}
                  itemCount={datas.length}
                  itemSize={itemHeight}
                  width="100%"
                  tw="flex-grow bg-white"
                >
                  {({ index, style }) => {
                    const currentData = datas[index];
                    return (
                      <ListItemContainer
                        role="option"
                        onClick={() => {
                          handleSelect(currentData);
                        }}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            handleSelect(currentData);
                          }
                        }}
                        aria-selected
                        tabIndex={0}
                        style={style}
                      >
                        {hasExtraLabel && (
                          <ListItemHeading>
                            <HeadingFour>
                              {currentData?.extraLabel ?? '-'}
                            </HeadingFour>
                          </ListItemHeading>
                        )}
                        <ListItemMeta>
                          <BodySixteen>{currentData?.label ?? '-'}</BodySixteen>
                        </ListItemMeta>
                      </ListItemContainer>
                    );
                  }}
                </List>
              )}
            </Popover>
          </PopOverContainer>
        ) : (
          <div tw="relative">
            <Popover
              visible={openDropdown}
              targetRef={inputRef as MutableRefObject<null>}
              tw="min-w-full z-20 overflow-visible bg-white shadow"
              onClick={() => setOpenDropdown(false)}
            >
              {!loading ? (
                <Text.BodyOne tw="bg-white text-status-alert rounded shadow-card flex px-4 py-2">
                  No Options
                </Text.BodyOne>
              ) : (
                <> </> // Hides the popover on mount
              )}
            </Popover>
          </div>
        )}
      </Container>
    </OutsideAlerter>
  );
}
