import useTranslator from '../../hook/useTranslator.hook';
import useActivityTemplateDeleteLogic from './hooks/useActivityTemplateDeleteLogic.hook';
import useActivityTemplateDeleteTable from './hooks/useActivityTemplateDeleteTable.hook';

export default function useActivityTemplateDeleteViewModel() {
  const translator = useTranslator();

  const activityTemplateDeleteLogic = useActivityTemplateDeleteLogic();

  const activityTemplateDeleteTable = useActivityTemplateDeleteTable({
    translator,
    activityTemplateDeleteLogic,
  });

  return {
    translator,
    activityTemplateDeleteLogic,
    activityTemplateDeleteTable,
  };
}
