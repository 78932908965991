import React, { PropsWithChildren, useMemo, useRef, useState } from 'react';
import tw, { styled, theme } from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { Photo } from '../../../model/Common.model';
import { TrackingDataType } from '../../../model/TrackingOrder.model';
import { formatText } from '../../../util/formatter.util';
import { Icon, LoadingIndicator, Text } from '../../atom';
import {
  CollapsiblePanel,
  Tooltip,
  TrackingHeader,
  TrackingTimeline,
} from '../../molecule';
import TrackingHO from '../TrackingHO/TrackingHO.organism';
import TrackingSO from '../TrackingSO/TrackingSO.organism';

const Container = tw.div`max-w-2xl min-w-[720px] flex flex-col gap-5`;
const ShareItem = styled.div(({ textOnly }: { textOnly?: boolean }) => [
  tw`flex items-center py-1`,
  !textOnly && tw`hover:(bg-orange-hover text-orange) px-6 py-2`,
  `
  &:hover {
    span{
      color: ${theme`colors.orange`};
    }
    svg {
      color: ${theme`colors.orange`};
      path {
        fill: ${theme`colors.orange`};
      }
    }
  }
  `,
]);

type Props = PropsWithChildren<{
  translator: UseTranslator;
  tracking?: TrackingDataType;
  soId?: string;
  onImageClick: (
    photos: Photo[],
    clickedIndex: number,
    customTitles?: string[],
  ) => void;
  onWhatsAppClick?: () => void;
  onCopyClick?: () => void;
  isShareCopyOnly?: boolean;
  isLoading?: boolean;
  emptyMessage?: React.ReactNode;
  showMap?: boolean;
  trackingCode?: string;
  forceLang?: SupportedLanguage;
  hoDelivery?: React.ReactNode;
}>;

// #endregion
function TrackingOrder({
  translator,
  soId,
  onImageClick,
  tracking: data,
  onWhatsAppClick,
  onCopyClick,
  isShareCopyOnly,
  isLoading,
  emptyMessage,
  showMap,
  trackingCode,
  forceLang,
  /**
   * Since ho tracking delivery contains table which the source comes from remote server
   * for it particular functionality the process, state and any related to the side effeect
   * should be run outside of this organism context. Therefore not like others tracking order components
   * I decided to make this a component composition.
   */
  hoDelivery,
  /** Currently for maps only since map need to rerender for certain intervals */
  children,
}: Props) {
  const tooltipRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const shareActions = useMemo(() => {
    if (!isShareCopyOnly)
      return [
        ...(onWhatsAppClick
          ? [
              {
                label: 'Whatsapp',
                icon: (
                  <div tw="w-4 h-4">
                    <Icon.LogoWhatsapp
                      width={16}
                      height={16}
                      pathFill={theme`colors.grey.two`}
                    />
                  </div>
                ),
                onClick: onWhatsAppClick,
              },
            ]
          : []),
        ...(onCopyClick
          ? [
              {
                label: 'Copy',
                icon: (
                  <Icon.Copy
                    width={16}
                    height={16}
                    fill={theme`colors.grey.two`}
                  />
                ),
                onClick: onCopyClick,
              },
            ]
          : []),
      ];

    return [
      {
        label: 'Copy',
        icon: (
          <Icon.Copy width={16} height={16} fill={theme`colors.grey.two`} />
        ),
        onClick: onCopyClick,
      },
    ];
  }, [isShareCopyOnly, onCopyClick, onWhatsAppClick]);

  const renderShareAction = useMemo(() => {
    if (!shareActions.length) return null;
    if (isShareCopyOnly) return null;

    return (
      <div
        tw="inline-grid grid-template-columns[26px 1fr] gap-1 w-max relative z-40 overflow-visible"
        className="group"
      >
        <div tw="flex items-center">
          <Icon.Share fill={theme`colors.grey.two`} />
        </div>
        <Text.HeadingFour tw="text-grey-two">
          {translator.translate('Share', forceLang)}
        </Text.HeadingFour>
        <div tw="absolute h-[max-content] hidden group-hover:flex right-0 transform">
          <div
            tw="flex-col shadow bg-white rounded-md z-50 py-2 my-2"
            css={[
              shareActions.length === 1 && tw`translate-y-1/3`,
              shareActions.length > 1 && tw`translate-y-[20%]`,
            ]}
          >
            {shareActions.map((v) => (
              <React.Fragment key={v.label}>
                <ShareItem
                  key={v.label}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => {
                    setShowTooltip(false);
                    setIsCopied(false);
                  }}
                  onClick={() => {
                    if (v.onClick) v.onClick();
                    setIsCopied(true);
                  }}
                  ref={tooltipRef}
                >
                  <div tw="h-4 w-4 overflow-hidden mr-2">{v.icon}</div>
                  <div>
                    <Text.HeadingFour tw="text-grey-two">
                      {v.label}
                    </Text.HeadingFour>
                  </div>
                </ShareItem>
                {isCopied && (
                  <Tooltip
                    addArrow
                    withPortal
                    variant="light"
                    placement="top-start"
                    targetRef={tooltipRef}
                    visible={showTooltip}
                    containerStyle={tw`z-50`}
                  >
                    <div tw="flex items-center space-x-2 animate-fade-in">
                      <Icon.CheckCircle
                        width={20}
                        height={20}
                        fill={theme`textColor.status.success.DEFAULT`}
                      />
                      <Text.BodyFourteen tw="whitespace-normal break-words">
                        {translator.translate('Copied to clipboard', forceLang)}
                      </Text.BodyFourteen>
                    </div>
                  </Tooltip>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  }, [
    forceLang,
    isCopied,
    isShareCopyOnly,
    shareActions,
    showTooltip,
    translator,
  ]);

  const timeline = useMemo(() => {
    return (
      <CollapsiblePanel
        title={translator.translate('Timeline', forceLang)}
        contentStyle={tw`bg-white`}
        headerStyle={tw`bg-white`}
        containerStyle={tw`shadow rounded-md overflow-hidden bg-white`}
      >
        {!!data && (
          <TrackingTimeline
            translator={translator}
            data={data}
            onImageClick={onImageClick}
            forceLang={forceLang}
          />
        )}
      </CollapsiblePanel>
    );
  }, [translator, forceLang, data, onImageClick]);

  if (isLoading)
    return (
      <div tw="flex justify-center items-center w-full height[calc(100vh - 144px)]">
        <LoadingIndicator />
      </div>
    );

  if (!data) {
    return (
      <div tw="flex justify-center items-center h-full">
        {emptyMessage || (
          <Text.HeadingFour>
            {translator.translate(
              'Tracking information is not available',
              forceLang,
            )}
          </Text.HeadingFour>
        )}
      </div>
    );
  }

  return (
    <Container>
      <CollapsiblePanel
        title={formatText(
          translator.translate('Tracking Number: %s', forceLang),
          trackingCode || '-',
        )}
        headerStyle={tw`rounded-t-md bg-white flex items-center`}
        contentStyle={tw`rounded-md`}
        containerStyle={tw`shadow rounded-md overflow-visible bg-white`}
        titleStyle={
          !shareActions.length || isShareCopyOnly
            ? tw`truncate max-w-[640px]`
            : tw`truncate max-w-[550px]`
        }
        customAction={renderShareAction}
        valueToCopy={trackingCode}
        soId={soId}
        customActionContainerStyle={isShareCopyOnly ? tw`ml-2` : undefined}
      >
        <TrackingHeader
          status={data.status}
          translator={translator}
          forceLang={forceLang}
          type={data.type}
        />
      </CollapsiblePanel>

      {data.type === 'so' ? (
        <TrackingSO
          data={data}
          forceLang={forceLang}
          showMap={showMap}
          soMap={children}
          timeline={timeline}
        />
      ) : (
        <TrackingHO
          forceLang={forceLang}
          hoDelivery={hoDelivery}
          data={data}
          timeline={timeline}
        />
      )}
    </Container>
  );
}

export default TrackingOrder;
