import React from 'react';
import { Icon } from '../../component/atom';
import { RouteConfig } from '../../config/router/router.config';
import reportDrivingBehaviorRoute from './DrivingBehavior/ReportDrivingBehavior.route';
import { reportFinanceRoute } from './Finance/reportFinance.route';
import { reportHaulingOrderRoute } from './HaulingOrder/reportHaulingOrder.route';
import { reportJobOrderRoute } from './JobOrder/reportJobOrder.route';
import { reportRevenueRoute } from './Revenue/reportRevenue.route';
import { reportShipperOrderRoute } from './ShipperOrder/reportShipperOrder.route';

export const reportRoute: RouteConfig = {
  name: 'Report',
  Component: React.lazy(
    () =>
      import(
        './DrivingBehavior/page/Vehicle/ReportDrivingBehaviorByVehicle.view'
      ),
  ),
  path: '/report',
  isPrivate: true,

  breadcrumb: 'Report',
  children: [
    {
      ...reportDrivingBehaviorRoute,
      path: 'driving-behavior',
    },

    {
      ...reportShipperOrderRoute,
      path: 'shipper-order',
    },
    {
      ...reportJobOrderRoute,
      path: 'job-order',
    },
    {
      ...reportHaulingOrderRoute,
      path: 'hauling-order',
    },
    {
      ...reportRevenueRoute,
      path: 'revenue',
    },
    {
      ...reportFinanceRoute,
      path: 'net-income',
    },
  ],
  props: {
    isUnclickable: true,
  },

  options: {
    icon: <Icon.NavReports />,
    label: 'Report',
    testID: 'MenuReport',
    hasDivider: true,
    hasSub: [
      reportDrivingBehaviorRoute,
      reportShipperOrderRoute,
      reportJobOrderRoute,
      reportHaulingOrderRoute,
      reportRevenueRoute,
      reportFinanceRoute,
    ],
  },
};

export default reportRoute;
