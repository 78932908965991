import React from 'react';
import tw from 'twin.macro';
import JoNotFoundImg from '../../assets/images/jo_not_found.png';
import { LoadingIndicator, Text } from '../../component/atom';
import { BodyOne } from '../../component/atom/Text/Text.atom';
import {
  InnerSidebar,
  Modal,
  UnsavedChangesAlertModal,
} from '../../component/molecule';
import SOTrackingDisclaimerModal from '../../component/molecule/SOTrackingDisclaimerModal/SOTrackingDisclaimerModal.molecule';
import {
  ErrorPlaceholder,
  SOActivityForm,
  SODActivitySection,
  SODHeaderForm,
  SODJobOrder,
  SOGoodsForm,
  TrackingOrder,
} from '../../component/organism';
import SOActivityTransitForm from '../../component/organism/SOActivityTransitForm/SOActivityTransitForm.organism';
import { MAX_SO_GOODS, ShipperOrderDetailTab, SOStatus } from '../../constant';
import { UseTranslator } from '../../hook/useTranslator.hook';
import { SOActivityFormProps } from '../../model/ShipperOrder.model';
import { doDownloadAndExtractAllPhotos } from '../../util/helper.util';
import { convertTrackingToSOTrackingType } from '../../util/tracking/trackingTimeline.util';
import ImageGallery from '../ImageGallery/ImageGallery.view';
import TrackingMapView from '../TrackingMap/TrackingMap.view';
import { SOTrackingDisclaimerType } from './hooks/useSOShareDisclaimer.hook';
import useShipperOrderDetailViewModel from './ShipperOrderDetail.viewModel';

const Container = tw.div`flex h-[calc(100vh - 64px)]`;
const WrpScroll = tw.div`flex pt-5 overflow-auto w-full`;
const LoadingContainer = tw.div`w-full height[80vh] flex justify-center items-center`;
const ModalBodyContainer = tw.div`flex flex-col py-5`;
const ErrorContainer = tw.div`w-full justify-center items-center flex h-full relative flex-col overflow-hidden`;
const ModalActionButtonContainer = tw`justify-between`;

const ModalContainer = tw`p-8 width[760px]`;

interface ActivityFormSectionProps {
  translator: UseTranslator;
  isTransitable?: boolean;
  soActivityFormData: SOActivityFormProps;
}

function SOActivityFormSection({
  translator,
  isTransitable,
  soActivityFormData,
}: ActivityFormSectionProps) {
  if (isTransitable)
    return (
      <SOActivityTransitForm
        translator={translator}
        soActivityFormData={soActivityFormData}
      />
    );
  return (
    <SOActivityForm
      translator={translator}
      soActivityFormData={soActivityFormData}
    />
  );
}

export default function ShipperOrderDetailDesktopView() {
  const {
    translator,
    soDetail,
    soDetailController,
    soDetailHeader,
    soDetailCost,
    soDetailActivity,
    soDetailUpdateGoodsForm,
    soDetailEditGoodsController,
    soActivityFormData,
    soDetailCreateGoods,
    trackingSO,
    imageGalleryController,
    soDetailConfirmation,
  } = useShipperOrderDetailViewModel();

  if (soDetail?.soInfoError)
    return (
      <Container>
        <ErrorContainer>
          <ErrorPlaceholder
            imageHeader={JoNotFoundImg}
            title={soDetailController.errorTitle}
            body={soDetailController.errorBody}
            actionClick={soDetailController.handleErrorAction}
            actionLabel={soDetailController.errorActionLabel}
          />
        </ErrorContainer>
      </Container>
    );

  if (!soDetail?.soInfo)
    return (
      <Container>
        <LoadingContainer>
          <LoadingIndicator size="large" />
        </LoadingContainer>
      </Container>
    );

  return (
    <Container>
      <InnerSidebar
        translator={translator}
        soInfo={soDetail.soInfo}
        selectedJOId={soDetailController.selectedJOId}
        onSelectJOId={soDetailController.setSelectedJOId}
        onClickBack={soDetailController.handleBackHeader}
        activeTab={soDetailController.tab}
        onTabChange={soDetailController.onTabChange}
        onDiscardConfirmation={soDetailConfirmation.handleDiscardChanges}
      />
      <WrpScroll>
        <div tw="mx-auto px-5">
          {soDetailController.tab === ShipperOrderDetailTab.HEADER && (
            <SODHeaderForm
              title={soDetailHeader.title}
              formData={soDetailHeader.headerFormData}
              submitTitle={soDetailHeader.submitLabel}
              handleSubmit={soDetailHeader.soHeaderForm.handleSubmit}
              isEditable={soDetailHeader.isFormEditable}
              isSubmitDisabled={
                soDetailController.isLoading ||
                !soDetailHeader.soHeaderForm.isValid ||
                !soDetailHeader.isHeaderFormChanged
              }
            />
          )}
          {soDetailController.tab === ShipperOrderDetailTab.DELIVERY_COST && (
            <SODHeaderForm
              title={soDetailCost.title}
              subHeader={soDetailCost.renderCostBadge}
              formData={soDetailCost.costFormData}
              submitTitle={soDetailHeader.submitLabel}
              handleSubmit={soDetailCost.soCostForm.handleSubmit}
              isEditable={soDetailCost.isFormEditable}
              isSubmitDisabled={
                soDetailController.isLoading ||
                !soDetailCost.soCostForm.isValid ||
                !soDetailCost.isCostFormChanged
              }
            />
          )}
          {soDetailController.tab === ShipperOrderDetailTab.ACTIVITY &&
            (soDetailActivity.isFormEditable ? (
              <div tw="w-[720px] pb-5">
                <SOActivityFormSection
                  translator={translator}
                  isTransitable={soDetail.soInfo.isTransitable}
                  soActivityFormData={soActivityFormData}
                />
              </div>
            ) : (
              <SODActivitySection
                translator={translator}
                soInfo={soDetail.soInfo}
                title={soDetailActivity.title}
                activities={soDetailActivity.activityFormData}
                onChangeActivityIndex={
                  soDetailEditGoodsController.handleChangeActivityIndex
                }
              />
            ))}
          {soDetailController.tab === ShipperOrderDetailTab.TRACKING_ORDER && (
            <TrackingOrder
              soId={soDetail.soInfo?.id}
              translator={translator}
              onImageClick={soDetailConfirmation.handleTrackingImageClick}
              tracking={
                trackingSO?.data?.tracking
                  ? convertTrackingToSOTrackingType(trackingSO?.data?.tracking)
                  : undefined
              }
              onWhatsAppClick={
                soDetail?.soInfo?.shipper.phoneNumber &&
                soDetailController.isValidPhoneNumber &&
                !!soDetail?.soInfo.trackingCode
                  ? () => {
                      soDetailController.setDisclaimerType(
                        SOTrackingDisclaimerType.WA,
                      );
                    }
                  : undefined
              }
              onCopyClick={
                soDetail?.soInfo.trackingCode
                  ? () => {
                      soDetailController.setDisclaimerType(
                        SOTrackingDisclaimerType.COPY,
                      );
                    }
                  : undefined
              }
              isLoading={trackingSO.isLoading}
              showMap={trackingSO.data?.tracking.status === SOStatus.DELIVERING}
              trackingCode={soDetail?.soInfo?.trackingCode || ''}
            >
              <TrackingMapView
                runInterval={
                  trackingSO.data?.tracking.status === SOStatus.DELIVERING
                }
                tracking={
                  !trackingSO.error ? trackingSO.data?.tracking : undefined
                }
                trackingCode={soDetail?.soInfo?.trackingCode || ''}
              />
            </TrackingOrder>
          )}
          {soDetailController.tab === ShipperOrderDetailTab.JO_INFO && (
            <SODJobOrder
              selectedJOId={soDetailController.selectedJOId}
              onDownloadClick={(photos, fileName) =>
                void doDownloadAndExtractAllPhotos(photos, translator, fileName)
              }
              onImagesClick={soDetailConfirmation.handleJOImageClick}
              translator={translator}
              soInfo={soDetail.soInfo}
            />
          )}
        </div>
      </WrpScroll>

      {soDetailController.showConfirmationModal && (
        <Modal.WithClose
          tw="p-0"
          css={ModalContainer}
          onClose={soDetailController.handleDismissChangeSection}
          onOverlayClick={soDetailController.handleDismissChangeSection}
          title={soDetailConfirmation.confirmationObj.title}
          ctaStyle={tw`justify-between`}
          callAction={{
            label: soDetailConfirmation.confirmationObj.actionLabel,
            action: () => {
              soDetailConfirmation.confirmationObj.handleConfirmAction();
            },
          }}
          cancelAction={{
            label: soDetailConfirmation.confirmationObj.cancelLabel,
            action: () => {
              soDetailConfirmation.confirmationObj.handleCancelAction();
            },
          }}
          actionButtonContainer={ModalActionButtonContainer}
        >
          <ModalBodyContainer>
            <BodyOne tw="whitespace-pre-wrap">
              {soDetailEditGoodsController.alertDescription}
            </BodyOne>
          </ModalBodyContainer>
        </Modal.WithClose>
      )}

      {typeof soDetailUpdateGoodsForm.selectedActivityIndex === 'number' && (
        <Modal.WithClose
          tw="p-0"
          onClose={soDetailEditGoodsController.handleClose}
          onOverlayClick={soDetailEditGoodsController.handleClickOverlay}
          containerStyle={tw`h-3/4`}
        >
          <SOGoodsForm
            title={soDetailEditGoodsController.modalGoodsFormTitle}
            total={soDetailUpdateGoodsForm.goodsForm.total}
            disabledSave={soDetailEditGoodsController.isSaveDisabled}
            disabled={soDetailEditGoodsController.isModalGoodsDisabled}
            getGoodsFormErrors={
              soDetailUpdateGoodsForm.goodsForm.getGoodsFormErrors
            }
            goods={soDetailUpdateGoodsForm.goodsForm.values.goods}
            onInputBlur={soDetailUpdateGoodsForm.goodsForm.onInputChangeHandler}
            onInputChange={
              soDetailUpdateGoodsForm.goodsForm.onInputChangeHandler
            }
            onSave={soDetailUpdateGoodsForm.goodsForm.handleSubmit}
            translator={translator}
            submitLabel={soDetailEditGoodsController.modalUpdateSubmitLabel}
            isEdit
          />
        </Modal.WithClose>
      )}

      <UnsavedChangesAlertModal
        actionLabel={soDetailEditGoodsController.alertLabel}
        cancelActionLabel={soDetailEditGoodsController.alertCancelLabel}
        description={soDetailEditGoodsController.alertDescription}
        hasUnsavedChanges={soDetailEditGoodsController.isUnsavedChangesAlert}
        onActionClick={() => {}}
        onActionClickPromise={
          soDetailUpdateGoodsForm.goodsForm
            .handleSubmit as () => Promise<boolean>
        }
        onCancelActionClick={soDetailEditGoodsController.handleDiscardChanges}
        title={soDetailEditGoodsController.alertTitle}
      />

      {soDetailEditGoodsController.isConfirmModalVisible && (
        <Modal.WithClose
          css={ModalContainer}
          title={soDetailEditGoodsController.confirmTitle}
          onClose={soDetailEditGoodsController.toggleConfirmModal}
          onOverlayClick={soDetailEditGoodsController.toggleConfirmModal}
          ctaStyle={tw`justify-between`}
          callAction={{
            label: soDetailEditGoodsController.alertLabel,
            action: soDetailEditGoodsController.handleConfirmAction,
            disabled: soDetailEditGoodsController.isConfirmActionDisabled,
          }}
          cancelAction={{
            label: soDetailEditGoodsController.cancelActionLabel,
            action: soDetailEditGoodsController.handleCancelAction,
          }}
          actionButtonContainer={ModalActionButtonContainer}
        >
          <ModalBodyContainer>
            <Text.BodyOne tw="width[650px]">
              {translator.translate(
                'You have unsaved changes, do you want to cancel this updates?',
              )}
            </Text.BodyOne>
          </ModalBodyContainer>
        </Modal.WithClose>
      )}

      <ImageGallery
        controller={imageGalleryController}
        testID="ShipperOrderDetail:ImageGallery"
      />

      <UnsavedChangesAlertModal
        title={soDetailConfirmation.confirmationObj.title}
        actionLabel={soDetailConfirmation.confirmationObj.actionLabel}
        cancelActionLabel={soDetailConfirmation.confirmationObj.cancelLabel}
        description={soDetailConfirmation.confirmationObj.description}
        isSecondaryValid={soDetailConfirmation.confirmationObj.isSecondaryValid}
        hasUnsavedChanges={!!soDetailController.isCurrentSectionChanged}
        onActionClick={() => {
          if (
            soDetailConfirmation.isFormValid(
              soDetailController.isCurrentSectionChanged,
            )
          ) {
            soDetailConfirmation.handleSubmitConfirmation(
              soDetailController.isCurrentSectionChanged,
            );
            return;
          }
          soDetailController.handleDismissChangeSection();
        }}
        onCancelActionClick={soDetailController.handleDismissChangeSection}
      />

      {typeof soDetailCreateGoods.goodsByActivityIndex === 'number' && (
        <Modal.WithClose
          tw="p-0"
          onClose={() => {
            soDetailCreateGoods.setGoodsByActivityIndex(undefined);
          }}
          onOverlayClick={() =>
            soDetailCreateGoods.setGoodsByActivityIndex(undefined)
          }
          containerStyle={tw`h-3/4`}
        >
          <SOGoodsForm
            total={soDetailCreateGoods.goodsForm.total}
            disabledSave={soDetailCreateGoods.goodsForm.goodsFormIsInvalid}
            getGoodsFormErrors={
              soDetailCreateGoods.goodsForm.getGoodsFormErrors
            }
            goods={soDetailCreateGoods.goodsForm.values.goods}
            hasReachMaxLimit={
              soDetailCreateGoods.goodsForm.values.goods.length >= MAX_SO_GOODS
            }
            onAddGood={soDetailCreateGoods.goodsForm.onAddGoodHandler}
            onDelete={soDetailCreateGoods.goodsForm.onRemoveGoodHandler}
            onInputBlur={soDetailCreateGoods.goodsForm.onInputChangeHandler}
            onInputChange={soDetailCreateGoods.goodsForm.onInputChangeHandler}
            onSave={soDetailCreateGoods.goodsForm.handleSubmit}
            translator={translator}
          />
        </Modal.WithClose>
      )}

      {soDetailController.disclaimerType && (
        <SOTrackingDisclaimerModal
          handleCloseDisclaimer={soDetailController.handleCloseDisclaimer}
          handleDisclaimerClick={
            soDetailController.handleClickApproveDisclaimer
          }
        />
      )}
    </Container>
  );
}
