import React, { useEffect, useState } from 'react';
import tw, { styled, TwStyle } from 'twin.macro';
import { TextFormItem } from '../../../types/input.type';
import {
  Chip,
  ChipContainer,
  ErrorFormContainer,
  LabelInput,
  Row,
} from '../../atom';
import { BodyTwelve } from '../../atom/Text/Text.atom';
import { ExtraStyle } from '../../Type.component';
import TextField from '../TextField/TextField.molecule';

export interface ChoiceItem {
  id?: string;
  label: string;
  onClick?: () => void;
  selected: boolean;
  disabled?: boolean;
}

type Props = {
  label?: string;
  isRequired?: boolean;
  isFormPage?: boolean;
  isValueCustom?: boolean;
  hasCustomOption?: boolean;
  customInput?: Omit<TextFormItem, 'label'>;
  choiceItems?: ChoiceItem[];
  description?: string;
  error?: string;
  labelStyle?: false | '' | TwStyle;
  containerStyle?: ExtraStyle;
  wrapperStyle?: ExtraStyle;
};

const Container = tw.div`flex flex-col min-h-[56px]`;
const MessageContainer = styled.div(
  ({ isFormPage }: { isFormPage?: boolean }) => [
    isFormPage && tw`ml-[200px] w-full`,
  ],
);
export default function ChoiceInput({
  label,
  isRequired,
  isFormPage,
  isValueCustom,
  hasCustomOption,
  description,
  choiceItems,
  containerStyle,
  labelStyle,
  wrapperStyle,
  customInput,
  error,
  children,
}: React.PropsWithChildren<Props>) {
  const [init, setInit] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  useEffect(() => {
    if (isValueCustom && !init) {
      setInit(true);
      setIsCustom(true);
    }
  }, [init, isValueCustom]);

  return (
    <Container css={wrapperStyle}>
      <Row tw="h-14" css={containerStyle}>
        {label && (
          <LabelInput
            labelStyle={labelStyle}
            label={label}
            isRequired={isRequired}
          />
        )}
        <ChipContainer>
          {choiceItems?.map((item: ChoiceItem) => (
            <Chip.Small
              id={item.id}
              disabled={item.disabled}
              key={item.label}
              label={item.label}
              selected={!isCustom && item.selected}
              onClick={() => {
                if (isCustom) setIsCustom(false);
                if (item.onClick) item.onClick();
              }}
            />
          ))}
          {hasCustomOption && (
            <Chip.Small
              id={`custom-chip-${label || ''}`}
              label="Custom"
              selected={isCustom}
              onClick={() => setIsCustom(true)}
            />
          )}
        </ChipContainer>
      </Row>
      {isCustom && customInput && (
        <Row>
          <MessageContainer isFormPage={isFormPage}>
            <TextField
              inputStyle={customInput.inputStyle}
              isNumber={customInput.isNumber}
              disabled={customInput.disabled}
              placeholder={customInput.placeholder}
              id={customInput.id}
              left={customInput.left}
              right={customInput.right}
              decimalScale={customInput.decimalScale}
              name={customInput.id}
              maxLength={customInput.maxLength}
              value={customInput.values || ''}
              error={customInput.error || ''}
              errorContainerStyle={tw`right-[70px]`}
              onChange={(e) => customInput.onChange(e.target.value)}
              onBlur={(e) => {
                if (customInput?.onBlur) customInput.onBlur(e.target.value);
              }}
            />
          </MessageContainer>
        </Row>
      )}
      {!!children && isCustom && (
        <Row tw="p-0">
          <MessageContainer isFormPage={isFormPage}>
            {children}
          </MessageContainer>
        </Row>
      )}
      {!!description && (
        <Row>
          <MessageContainer isFormPage={isFormPage}>
            <BodyTwelve tw="text-grey-two">{description}</BodyTwelve>
          </MessageContainer>
        </Row>
      )}
      {error && !isCustom && !customInput?.error && (
        <Row>
          <ErrorFormContainer hasLabel={!!label}>{error}</ErrorFormContainer>
        </Row>
      )}
    </Container>
  );
}
