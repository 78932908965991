import { Locale } from './locale.config';

const vehicleLocaleConfig: Locale[] = [
  {
    en: 'Available',
    id: 'Tersedia',
  },
  {
    en: 'Loaded',
    id: 'Bermuatan',
  },
  {
    en: 'Delivering ',
    id: 'Dalam Perjalanan',
  },
  {
    en: 'Overvolume',
    id: 'Volume Berlebih',
  },
  {
    en: 'Overweight',
    id: 'Berat Berlebih',
  },
  {
    en: 'Weight <= 10%',
    id: 'Berat <= 10%',
  },
  {
    en: 'Free Weight (kg)',
    id: 'Sisa Berat (kg)',
  },
  {
    en: 'Free Volume (m3)',
    id: 'Sisa Volume (m3)',
  },
];

export default vehicleLocaleConfig;
