import React from 'react';
import tw from 'twin.macro';
import JoNotFoundImg from '../../assets/images/jo_not_found.png';
import {
  Container,
  ContentContainer,
  LoadingIndicator,
  TableNoData,
  Text,
} from '../../component/atom';
import { NoDataFound } from '../../component/atom/Icon/Icon.atom';
import { Modal } from '../../component/molecule';
import { ErrorPlaceholder, JODetailHeader } from '../../component/organism';
import JODetailMaps from '../../component/organism/JODetailMaps/JODetailMaps.organism';
import { JO_INFO_SO_ACTIVITY_TABLE_HEADERS } from '../../constant/JobOrder.constant';
import useTranslator from '../../hook/useTranslator.hook';
import { JOStatus } from '../../model/JobOrder.model';
import { doDownloadAndExtractAllPhotos } from '../../util/helper.util';
import { mapJOStatusToLabel } from '../../util/jobOrder.util';
import { generateHeaderContentItem } from '../../util/jobOrderDetail/jobOrderDetail.util';
import ImageGallery from '../ImageGallery/ImageGallery.view';
import JobOrderEditDraftView from '../JobOrderEditDraft/JobOrderEditDraft.view';
import { JODetailEventHistory } from './components/JODetailEventHistory';
import JODetailSO from './components/JODetailSO';
import JODetailSpendingReport from './components/JODetailSpendingReport';
import JODetailTabs from './components/JODetailTabs';
import useJobOrderDetailViewModel from './JobOrderDetail.viewModel';

// #region STYLED
const LoadingContainer = tw.div`w-full h-[80vh] flex justify-center items-center`;
const RootContainer = tw(
  ContentContainer,
)`mx-0 flex flex-col pt-0 gap-8 w-full h-[calc(100vh - 144px)] bg-white items-center justify-center shadow-card rounded-md`;
const ModalContainer = tw`p-8 w-[760px]`;
const ModalBodyContainer = tw.div`flex flex-col py-5`;
const ModalActionButtonContainer = tw`justify-between`;
const ModalContentHeader = tw(Text.BodyOne)`font-bold block truncate`;
// #endregion

function EventHistoryContent({
  isDeleted,
  isFetching,
  list = [],
}: {
  isDeleted?: boolean;
  isFetching?: boolean;
  list?: JSX.Element[];
}) {
  const { translate } = useTranslator();
  if (isFetching)
    return (
      <>
        <JODetailEventHistory.ItemSkeleton />
        <JODetailEventHistory.ItemSkeleton />
        <JODetailEventHistory.ItemSkeleton />
      </>
    );
  if (isDeleted || !list.length)
    return (
      <TableNoData
        descriptionStyle={tw`whitespace-normal`}
        icon={<NoDataFound width={470} />}
        titleLabel={translate('There is no Change History')}
        descriptionLabel={translate(
          'No Changes have been made to this Job Order',
        )}
      />
    );

  return <>{list}</>;
}

export default function JobOrderDetailDesktopView() {
  const {
    translator,
    jobOrderInfo,
    joDetailErrorPlaceholder,
    joDetailController,
    joDetailSO,
    joDetailMaps,
    joDetailPolling,
    joDetailModalForceJO,
    forceCompleteSO,
    forceCompleteJO,
    joEventHistory,
    imageGalleryController,
    handlePhotoClick,
  } = useJobOrderDetailViewModel();

  if (jobOrderInfo.joInfoError) {
    return (
      <RootContainer>
        <ErrorPlaceholder
          imageHeader={JoNotFoundImg}
          title={joDetailErrorPlaceholder.title}
          body={joDetailErrorPlaceholder.body}
          actionClick={joDetailErrorPlaceholder.handleAction}
          actionLabel={joDetailErrorPlaceholder.actionLabel}
        />
      </RootContainer>
    );
  }

  if (!jobOrderInfo.joInfoData) {
    return (
      <LoadingContainer>
        <LoadingIndicator size="large" />
      </LoadingContainer>
    );
  }

  if (jobOrderInfo.joInfoData?.status === JOStatus.DRAFT)
    return <JobOrderEditDraftView />;

  return (
    <Container containerStyle={tw`grid flex-col gap-6`}>
      <JODetailHeader
        translator={translator}
        number={jobOrderInfo.joInfoData.number}
        status={jobOrderInfo.joInfoData.status}
        statusLabel={translator.translate(
          mapJOStatusToLabel(jobOrderInfo.joInfoData.status),
        )}
        headerContentItems={generateHeaderContentItem(
          translator,
          jobOrderInfo.joInfoData,
        )}
        onClickBack={joDetailController.handleClickBack}
        onForceCompleteJOClicked={() =>
          joDetailController.handleForceCompleteClicked(true)
        }
        forceLoading={joDetailPolling.startPolling}
        onEditHeader={joDetailController.handleClickEditHeader}
        showEditButton
      />

      <JODetailTabs
        translator={translator}
        joDetailController={joDetailController}
      >
        {joDetailController.tab === 'so-list' ? (
          <JODetailSO
            onDownloadClick={(photos, fileName) =>
              void doDownloadAndExtractAllPhotos(photos, translator, fileName)
            }
            translator={translator}
            joStatus={jobOrderInfo.joInfoData.status}
            deliveries={jobOrderInfo.joInfoData.deliveries}
            onImagesClick={handlePhotoClick}
            selectedDelivery={joDetailSO.renderSelectedDelivery}
            selectedDeliveryItem={joDetailSO.renderSelectedDeliveryItem}
            activityTableHeaders={JO_INFO_SO_ACTIVITY_TABLE_HEADERS}
            onSelectDeliveryItem={joDetailSO.handleSelectDeliveryItem}
            checkForceLoadingSo={joDetailPolling.checkForceLoadingSo}
            forceCompleteSOLoading={
              forceCompleteSO.isLoading ||
              forceCompleteJO.isLoading ||
              jobOrderInfo.isLoadingJO ||
              jobOrderInfo.isFetching
            }
            forceCompleteActions={joDetailController.soForceCompleteActions}
          />
        ) : joDetailController.tab === 'spending-report' ? (
          <JODetailSpendingReport jobOrderInfo={jobOrderInfo} />
        ) : (
          <JODetailEventHistory.Root>
            <EventHistoryContent
              isDeleted={jobOrderInfo.joInfoData.status === JOStatus.DELETED}
              isFetching={joEventHistory.query.isFetching}
              list={joEventHistory.eventHistoryList}
            />
          </JODetailEventHistory.Root>
        )}
      </JODetailTabs>

      {joDetailMaps.showMapLocation && (
        <JODetailMaps translator={translator} joDetailMaps={joDetailMaps} />
      )}

      {joDetailController.isModalConfirmationShown && (
        <Modal.WithClose
          css={ModalContainer}
          title={joDetailModalForceJO.title}
          onClose={joDetailController.toggleModalConfirmation}
          onOverlayClick={joDetailController.toggleModalConfirmation}
          ctaStyle={tw`justify-between`}
          callAction={{
            label: joDetailModalForceJO.actionLabel,
            action: joDetailModalForceJO.handleModalAction,
          }}
          cancelAction={{
            label: translator.translate('Cancel'),
            action: joDetailController.toggleModalConfirmation,
          }}
          actionButtonContainer={ModalActionButtonContainer}
        >
          <ModalBodyContainer>
            <Text.BodyOne>
              <ModalContentHeader>
                {joDetailModalForceJO.contentHeader}
              </ModalContentHeader>
              {` ${joDetailModalForceJO.contentDescription}`}
            </Text.BodyOne>
          </ModalBodyContainer>
        </Modal.WithClose>
      )}

      <ImageGallery
        testID="JobOrderDetail:Gallery"
        controller={imageGalleryController}
        showDownload
      />
    </Container>
  );
}
