import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { ShipperOrderCreateNavigateParams } from '../ShipperOrderCreate/ShipperOrderCreate.route';

export type SOShipperAddRouteParam =
  | ShipperOrderCreateNavigateParams
  | undefined;

export const soShipperAddRoute: RouteConfig = {
  name: 'Add New Shipper',
  Component: React.lazy(() => import('./SOShipperAdd.view')),
  path: '/shipper-order/create/add-new-shipper',
  isPrivate: true,
  breadcrumb: 'Add New Shipper',
  props: {
    isUnclickable: true,
  },
};
