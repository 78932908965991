import React from 'react';
import tw, { styled } from 'twin.macro';
import { BodyFourteen } from '../../../component/atom/Text/Text.atom';
import { JODetailSpendingMeta } from '../../../constant/JobOrder.constant';

const Container = tw.div`flex flex-col `;
const Content = tw.div`w-[calc(100% - 140px)]`;
const Row = styled.div(() => [
  tw`flex gap-5  py-2 not-last-of-type:( border-b border-b-beige-lines)`,
]);
const Title = tw(BodyFourteen)`w-[120px] text-grey-three`;

type Props = {
  detailContent: JODetailSpendingMeta[];
};

export default function JODetailSpendingInfo({ detailContent }: Props) {
  return (
    <Container>
      {detailContent.map((item) => (
        <Row key={item.title}>
          <Title>{item.title}</Title>
          <Content>{item.content}</Content>
        </Row>
      ))}
    </Container>
  );
}
