import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import tw from 'twin.macro';
import { UseReportJobOrderSOActivityNotesModalObj } from '../../../hook/report/jobOrder/useReportJobOrderSOActivityNotesModal.hook';
import { Text } from '../../atom';
import { Modal } from '../../molecule';
import { ExtraStyle } from '../../Type.component';

// #region INTERFACES
type ReportActivityNoteProps = {
  title: string;
  note: string;
  containerStyle?: ExtraStyle;
};

export type ReportJOSOActivityNotesModalProps = {
  reportJOSOActivityNotesModal: UseReportJobOrderSOActivityNotesModalObj;
};
// #endregion

// #region STYLED
const ScroolbarBodyContainer = tw.div`flex flex-col space-y-3`;
const ReportActivityNoteContainer = tw.div`py-4 mx-6 flex flex-col space-y-3 border-b border-b-beige-lines`;
// #endregion

function ReportActivityNote({
  title,
  note,
  containerStyle,
}: ReportActivityNoteProps) {
  return (
    <ReportActivityNoteContainer css={containerStyle}>
      <Text.HeadingFour>{title}</Text.HeadingFour>
      <Text.BodyFourteen tw="text-justify">{note}</Text.BodyFourteen>
    </ReportActivityNoteContainer>
  );
}

function ReportJOSOActivityNotesModal({
  reportJOSOActivityNotesModal,
}: ReportJOSOActivityNotesModalProps) {
  return (
    <Modal.WithHeader
      css={tw`p-0 width[760px]`}
      title={reportJOSOActivityNotesModal.modalTitle}
      onClose={reportJOSOActivityNotesModal.onCloseModal}
      onOverlayClick={reportJOSOActivityNotesModal.onCloseModal}
    >
      <Scrollbars tw="max-h-[620px] min-h-[450px] 2xl:(min-h-[472px])">
        <ScroolbarBodyContainer>
          {reportJOSOActivityNotesModal.activityNotes.map(
            ({ createdAt, note, index }, idx) => (
              <ReportActivityNote
                key={note + createdAt.toString()}
                title={reportJOSOActivityNotesModal.getActivityNoteTitle(
                  createdAt,
                  index + 1,
                )}
                note={note}
                containerStyle={[
                  idx + 1 ===
                    reportJOSOActivityNotesModal.activityNotes.length &&
                    tw`border-b-0`,
                ]}
              />
            ),
          )}
        </ScroolbarBodyContainer>
      </Scrollbars>
    </Modal.WithHeader>
  );
}

export default ReportJOSOActivityNotesModal;
