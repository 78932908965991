import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfDay from 'date-fns/endOfDay';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import { useCallback, useMemo, useState } from 'react';
import { NativeCalendarHighlight } from '../../component/molecule/NativeCalendar/NativeCalendar.molecule';
import { JODateAssignmentFormType } from '../../constant/JOAssignment.constant';
import { ScheduleOrderBy } from '../../model/Schedule.model';
import api from '../../service/api.service';

export const jODateAssignmentScheduleListDateRangeInitialState = {
  currentSelection: 'start',
  start: undefined,
  end: undefined,
};

type Props = {
  values?: JODateAssignmentFormType;
};

export default function useJODateAssignmentScheduleList({ values }: Props) {
  const hasValues = !!values?.from && !!values.to;

  const [selectedHighlightId, setSelectedHighlightId] = useState<
    string | undefined
  >(undefined);

  const query = api.useGetScheduleListQuery(
    {
      pageSize: 1_000,
      orderBy: ScheduleOrderBy.FROM_DATE_ASC,
      from: values?.from ? getUnixTime(startOfDay(values.from)) : undefined,
      to: values?.to ? getUnixTime(endOfDay(values.to)) : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !hasValues,
      selectFromResult: ({ data, isFetching, status }) => ({
        status,
        listData: data?.driverVehicles ?? [],
        listOngoingData: (data?.driverVehicles ?? []).filter(
          (item) => item.to >= getUnixTime(startOfToday()),
        ),
        listMetadata: data?.metadata,
        listIsFetching: isFetching,
        highlights: (data?.driverVehicles ?? []).map(
          ({ from, to, id }) =>
            ({
              id,
              start: startOfDay(from * 1000),
              end: startOfDay(to * 1000),
            }) as NativeCalendarHighlight,
        ),
        disabledDays: (data?.driverVehicles ?? []).flatMap(({ from, to }) =>
          eachDayOfInterval({
            start: startOfDay(from * 1000),
            end: startOfDay(to * 1000),
          }),
        ),
      }),
    },
  );

  const selectedHighlight = useMemo(
    () =>
      selectedHighlightId !== undefined
        ? query.highlights.find((item) => item.id === selectedHighlightId)
        : undefined, // nothing is selected for the first time
    [query.highlights, selectedHighlightId],
  );

  const handleSelectScheduleItem = (_id: string) => {
    // unselect if user click the same item
    setSelectedHighlightId((prev) => (prev === _id ? undefined : _id));
  };

  const handleReset = useCallback(() => {
    setSelectedHighlightId(undefined);
  }, []);

  return {
    selectedHighlight,
    selectedHighlightId,
    isLoading: query.listIsFetching,
    highlights: hasValues ? query.highlights : [],
    schedules: hasValues ? query.listData : [],
    ongoingSchedules: hasValues ? query.listOngoingData : [],
    disabledDays: hasValues ? query.disabledDays : [],
    queryStatus: hasValues ? query.status : undefined,
    handleReset,
    handleRefetch: () => query.refetch(),
    handleSelectScheduleItem,
  };
}

export type UseJODateAssignmentScheduleList = ReturnType<
  typeof useJODateAssignmentScheduleList
>;
