import { useCallback, useState } from 'react';
import { JODetailSpendingProps } from '../../../constant/JobOrderDetail.constant';
import { Photo } from '../../../model/Common.model';
import { imageWithoutTitleSelector } from '../../../util/helper.util';
import { UseImageGalleryControllerHookObj } from '../../ImageGallery/hooks/useImageGalleryController.hook';

type Props = {
  imageGalleryController: UseImageGalleryControllerHookObj;
};
export type UseJODetailSpendingReportController = ReturnType<
  typeof useJODetailSpendingReportController
>;

export default function useJODetailSpendingReportController({
  imageGalleryController,
}: Props) {
  const [spendingProps, setSpendingProps] = useState<
    JODetailSpendingProps | undefined
  >(undefined);
  const [showSpendingDetail, setShowSpendingDetail] = useState<
    JODetailSpendingProps | undefined
  >(undefined);

  const handleClickThumbnail = useCallback(
    (photos: Photo[], index: number) => {
      setShowSpendingDetail((v) => {
        setSpendingProps(v);
        return undefined;
      });
      imageGalleryController.handleGalleryThumbnailsClick({
        images: photos.map(imageWithoutTitleSelector),
        startIndex: index,
      });
    },
    [imageGalleryController],
  );

  const handleClickCloseGallery = useCallback(() => {
    setShowSpendingDetail(spendingProps);
    imageGalleryController.handleGalleryClose();
  }, [imageGalleryController, spendingProps]);

  const handleCloseDetailModal = useCallback(() => {
    setShowSpendingDetail(undefined);
  }, []);

  return {
    spendingProps,
    setSpendingProps,
    showSpendingDetail,
    setShowSpendingDetail,
    handleClickThumbnail,
    handleClickCloseGallery,
    handleCloseDetailModal,
  };
}
