import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import uniq from 'lodash/uniq';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import tw from 'twin.macro';
import { Text } from '../../../component/atom';
import IndeterminateCheckbox from '../../../component/atom/Checkbox/IndeterminateCheckbox.atom';
import { ShipperOrderStatus } from '../../../component/molecule';
import { AllCheckboxState } from '../../../constant';
import { JODetailSpendingReportFilterStatus } from '../../../constant/JobOrderDetail.constant';
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from '../../../constant/Table.constant';
import useTableCheckbox from '../../../hook/useTableCheckbox.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import {
  JobOrderExpense,
  JobOrderExpenseStatus,
  JobOrderExpenseType,
} from '../../../model/JobOrder.model';
import { logEvent } from '../../../service/analytic/analytic.service';
import { getFullDateTimeFormat } from '../../../util/date.util';
import { numberFormatter } from '../../../util/formatter.util';
import {
  mapJODetailExpenseCategoryToLabel,
  mapJODetailExpenseStatusNumberToLabel,
} from '../../../util/jobOrderDetail/jobOrderDetail.util';
import { UseJODetailSpendingReportController } from './useJODetailSpendingController.hook';
import { UseJODetailSpendingReportFilter } from './useJODetailSpendingReportFilter.hook';
import { UseJODetailSpendingReportList } from './useJODetailSpendingReportList.hook';

type Props = {
  spendingReportController: UseJODetailSpendingReportController;
  spendingReportList: UseJODetailSpendingReportList;
  spendingReportFilter: UseJODetailSpendingReportFilter;
};
export type UseJODetailSpendingReportTable = ReturnType<
  typeof useJODetailSpendingReportTable
>;

export default function useJODetailSpendingReportTable({
  spendingReportController: { setShowSpendingDetail },
  spendingReportList,
  spendingReportFilter,
}: Props) {
  const navigate = useNavigate();
  const translator = useTranslator();

  const [searchParams, setSearchParams] = useSearchParams();
  const { breadcrumb, page, pageSize } = Object.fromEntries(searchParams);
  const categories = searchParams.getAll('category') as JobOrderExpenseType[];
  const statuses = searchParams.getAll(
    'status',
  ) as JODetailSpendingReportFilterStatus[];

  const noResultFound =
    spendingReportList.query.metadata &&
    spendingReportList.query.metadata.totalCount < 1 &&
    !!(categories.length || statuses.length);

  // PAGINATION
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: Number(page) || DEFAULT_PAGE,
    pageSize: Number(pageSize) || DEFAULT_PAGE_SIZE,
  });

  // CHECKBOX
  const { checkedItems, setCheckedItems, allCheckbox } =
    useTableCheckbox<JobOrderExpense>(spendingReportList.query.list);

  const columns = useMemo<ColumnDef<JobOrderExpense>[]>(
    () => [
      {
        id: 'check',
        size: 55,
        header: () => <div tw="w-[55px]" />,
        accessorFn: (expense) => expense,
        cell: ({ getValue }) => {
          const expense = getValue() as JobOrderExpense;
          const isSelected = checkedItems.find(
            (checked) => checked.id === expense.id,
          );

          return (
            <IndeterminateCheckbox
              checked={!!isSelected}
              onChange={() => {
                setCheckedItems((prev) =>
                  isSelected
                    ? prev.filter((checked) => checked.id !== expense.id)
                    : uniq([...prev, expense]),
                );
              }}
            />
          );
        },
      },
      {
        meta: {
          cellStyle: tw`pl-2.5`,
        },
        id: 'id',
        size: 140,
        header: () => (
          <div tw="w-[140px]">{translator.translate('Expense Number')}</div>
        ),
        accessorFn: (expense) => expense,
        cell: ({ getValue }) => {
          const expense = getValue() as JobOrderExpense;

          return (
            <Text.HeadingFour
              onClick={() => {
                logEvent('JobOrder:Detail:ShowSpendingDetail', 'JobOrder');

                setShowSpendingDetail({
                  joId: expense.joId,
                  id: expense.id,
                  number: expense.number,
                });
              }}
              tw="w-[140px] font-semibold text-orange cursor-pointer"
            >
              {expense.number}
            </Text.HeadingFour>
          );
        },
      },
      {
        id: 'type',
        size: 110,
        header: () => (
          <div tw="w-[110px]">{translator.translate('Category')}</div>
        ),
        accessorFn: (expense) => expense.type,
        cell: ({ getValue }) => (
          <Text.Paragraph tw="w-[110px]">
            {translator.translate(
              mapJODetailExpenseCategoryToLabel(getValue()),
            )}
          </Text.Paragraph>
        ),
      },
      {
        id: 'status',
        size: 150,
        header: () => (
          <div tw="w-[150px]">{translator.translate('Status')}</div>
        ),
        accessorFn: (expense) => expense.status,
        cell: ({ getValue }) => {
          const status = getValue() as JobOrderExpense['status'];

          return (
            <ShipperOrderStatus
              expenseStatus={status}
              statusLabel={translator.translate(
                mapJODetailExpenseStatusNumberToLabel(status),
              )}
            />
          );
        },
      },
      {
        id: 'submittedAt',
        size: 160,
        header: () => (
          <div tw="w-[160px]">{translator.translate('Submitted At')}</div>
        ),
        accessorFn: (expense) => expense.submittedAt,
        cell: ({ getValue }) => {
          const submittedAt = getValue() as JobOrderExpense['submittedAt'];

          return (
            <Text.Paragraph tw="w-[160px]">
              {submittedAt
                ? getFullDateTimeFormat(submittedAt * 1_000, true)
                : '-'}
            </Text.Paragraph>
          );
        },
      },
      {
        id: 'amount',
        size: 160,
        header: () => (
          <div tw="w-[160px] text-right">
            {translator.translate('Expense Amount')}
          </div>
        ),
        accessorFn: (expense) => expense.amount,
        cell: ({ getValue }) => {
          const amount = getValue() as JobOrderExpense['amount'];

          return (
            <Text.Paragraph tw="w-[160px] text-right">{`Rp${numberFormatter(
              amount,
            )}`}</Text.Paragraph>
          );
        },
      },
    ],
    [checkedItems, setCheckedItems, setShowSpendingDetail, translator],
  );

  const table = useReactTable({
    columns,
    data: spendingReportList.query.list ?? [],
    pageCount: spendingReportList.query.metadata?.totalCount ?? 0,
    state: {
      pagination: paginationState,
    },
    manualPagination: true,
    onPaginationChange: setPaginationState,
    getCoreRowModel: getCoreRowModel(),
  });

  const isMultipleExpensesSelected = checkedItems.length > 1;
  const isExpenseOtherThanPendingSelected = checkedItems.some(
    (item) => item.status !== JobOrderExpenseStatus.PENDING,
  );
  const disableRejectButton =
    isMultipleExpensesSelected || isExpenseOtherThanPendingSelected;
  const rejectLabel = translator.translate('Reject');
  const approveLabel = translator.translate('Approve');
  const rejectTooltipLabel = translator.translate(
    isMultipleExpensesSelected
      ? 'This operation cannot be performed for multiple expenses'
      : 'This operation cannot be performed for approved/rejected expense',
  );

  // #region HANDLERS
  const onPageLimitClick = useCallback(
    (_limit: number) => {
      searchParams.set('pageSize', String(_limit));
      searchParams.set('page', String(DEFAULT_PAGE));
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const onPageClick = useCallback(
    (_page: number) => {
      searchParams.set('page', String(_page));
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const onChangeAllCheckbox = useCallback(() => {
    const { list } = spendingReportList.query;
    if (!list) return;

    setCheckedItems((prev) =>
      allCheckbox === AllCheckboxState.CHECKED ||
      allCheckbox === AllCheckboxState.INDETERMINATE
        ? prev.filter(
            (checked) =>
              !list.map((expense) => expense.id).includes(checked.id),
          )
        : uniq([...prev, ...list]),
    );
  }, [allCheckbox, setCheckedItems, spendingReportList.query]);

  const onTableNoDataActionClick = useCallback(() => {
    // set filter state to initial values
    table.setPageSize(DEFAULT_PAGE_SIZE);
    spendingReportFilter.form.resetForm();

    searchParams.delete('page');
    searchParams.delete('pageSize');
    searchParams.delete('orderBy');
    searchParams.delete('category');
    searchParams.delete('status');
    setSearchParams(searchParams);
  }, [table, spendingReportFilter.form, searchParams, setSearchParams]);

  const onNavigateToBulkApprove = useCallback(() => {
    logEvent('JobOrder:Detail:NavigateToBulkApprove', 'JobOrder');
    navigate('approve-expenses', {
      state: { breadcrumb, expenses: checkedItems },
    });
  }, [breadcrumb, checkedItems, navigate]);
  // #endregion

  // sync url with pagination state
  useEffect(() => {
    setPaginationState({
      pageIndex: Number(page) || DEFAULT_PAGE,
      pageSize: Number(pageSize) || DEFAULT_PAGE_SIZE,
    });
  }, [page, pageSize]);

  return {
    noResultFound,
    table,
    checkedItems,
    allCheckbox,
    isMultipleExpensesSelected,
    isExpenseOtherThanPendingSelected,
    disableRejectButton,
    rejectLabel,
    approveLabel,
    rejectTooltipLabel,
    setCheckedItems,
    onPageLimitClick,
    onPageClick,
    onChangeAllCheckbox,
    onTableNoDataActionClick,
    onNavigateToBulkApprove,
  };
}
