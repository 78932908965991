import tw, { styled } from 'twin.macro';

const ProgressBar = styled.progress`
  ${tw`w-[auto] h-1.5`}

  -webkit-appearance: none;

  ::-webkit-progress-bar {
    ${tw`bg-grey-five rounded-[10px]`}
  }

  ::-webkit-progress-value {
    ${tw`bg-status-success rounded-[10px] transition-all duration-500`}
  }
`;

export default ProgressBar;
