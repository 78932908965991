import { useMemo, useState } from 'react';
import { AllCheckboxState } from '../constant';

type WithId<T> = T & { id: string };

export default function useTableCheckbox<Entity>(
  listData: WithId<Entity>[] | undefined,
) {
  const [checkedItems, setCheckedItems] = useState<WithId<Entity>[]>([]);

  const allCheckbox = useMemo(() => {
    if (!listData) return AllCheckboxState.UNCHECKED;

    const intersected = listData.filter((jo) =>
      checkedItems.find((checkedJO) => checkedJO.id === jo.id),
    );

    if (intersected.length === 0) return AllCheckboxState.UNCHECKED;
    if (intersected.length === listData.length) return AllCheckboxState.CHECKED;

    return AllCheckboxState.INDETERMINATE;
  }, [checkedItems, listData]);

  return {
    checkedItems,
    setCheckedItems,
    allCheckbox,
  };
}
