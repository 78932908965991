import React from 'react';
import tw, { theme } from 'twin.macro';
import { Icon } from '../../atom';
import { ChevronRounded } from '../../atom/Icon/Icon.atom';
import { HeadingFive, HeadingFour } from '../../atom/Text/Text.atom';
import { GalleryMobileProps } from '../GalleryMobile/GalleryMobile.organism';

// #region STYLED
const Title = tw(HeadingFour)`truncate flex-1 w-24 mr-2 text-left`;
const Container = tw.div`flex justify-between pb-2`;
const NavContainer = tw.div`flex flex-1`;
const NavAction = tw.div`text-orange`;
// #endregion

// #region INTERFACES

type Props = {
  showNavigation?: boolean;
  showBackButton?: boolean;
  showTripHistory?: boolean;
  showGallery?: GalleryMobileProps;
  headerTitle: string;
  handleDismiss(): void;
  handleBack(): void;
  handleOpenShare(): void;
};
// #endregion

type NavProps = {
  showNavigation?: boolean;
  showBackButton?: boolean;
  handleDismiss(): void;
  handleBack(): void;
};

function HeaderNavigation({
  showBackButton,
  showNavigation,
  handleDismiss,
  handleBack,
}: NavProps) {
  if (!showNavigation && !showBackButton) return null;
  if (showBackButton)
    return (
      <NavAction tw="mr-3" onClick={handleBack}>
        <Icon.ArrowBack fill={theme`colors.orange.DEFAULT`} />
      </NavAction>
    );
  return (
    <NavAction tw="rotate-90 mr-3" onClick={handleDismiss}>
      <ChevronRounded />
    </NavAction>
  );
}

export default function SODetailMobileHeader({
  headerTitle,
  showBackButton,
  showNavigation,
  showGallery,
  showTripHistory,
  handleOpenShare,
  handleDismiss,
  handleBack,
}: Props) {
  return (
    <Container>
      <NavContainer>
        <HeaderNavigation
          showBackButton={showBackButton}
          showNavigation={showNavigation}
          handleBack={handleBack}
          handleDismiss={handleDismiss}
        />
        <Title>{headerTitle}</Title>
      </NavContainer>
      {showGallery && (
        <HeadingFive>
          {showGallery.currentIndex + 1}/{showGallery.photos.length}
        </HeadingFive>
      )}
      {showNavigation && !showTripHistory && !showGallery && (
        <NavAction onClick={handleOpenShare}>
          <Icon.Share fill={theme`colors.orange.DEFAULT`} />
        </NavAction>
      )}
    </Container>
  );
}
