// #region IMPORT
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  AddDriverTimesheetDetailRequest,
  AddDriverTimesheetDetailResponse,
  CreateDriverTimesheetRequest,
  CreateDriverTimesheetResponse,
  DeactivateDriverRequest,
  DeactivateDriverResponse,
  DriverLastPosition,
  ExportDriverApiRequest,
  ExportDriverApiResponse,
  ExportDriverTimesheetApiRequest,
  ExportDriverTimesheetApiResponse,
  ExportDriverTimesheetBulkApiRequest,
  ExportDriverTimesheetBulkApiResponse,
  GetDriverActiveApiRequest,
  GetDriverActiveApiResponse,
  GetDriverApiRequest,
  GetDriverApiResponse,
  GetDriverInfoApiRequest,
  GetDriverInfoApiResponse,
  GetDriversCountApiRequest,
  GetDriversCountApiResponse,
  GetDriverTimesheetDetailApiRequest,
  GetDriverTimesheetDetailApiResponse,
  GetDriverTimesheetEventListApiRequest,
  GetDriverTimesheetEventListApiResponse,
  GetDriverTimesheetListApiRequest,
  GetDriverTimesheetListApiResponse,
  GetDriverTimesheetTotalApiRequest,
  GetDriverTimesheetTotalApiResponse,
  GetDriverUnassignedApiRequest,
  GetDriverUnassignedApiResponse,
  PostDriverCreateParams,
  PostDriverCreateResponse,
  RemoveDriverTimesheetDetailRequest,
  RemoveDriverTimesheetDetailResponse,
  UpdateDriverPinRequest,
  UpdateDriverRequest,
  UpdateDriverResponse,
  UpdateDriverTimesheetDetailRequest,
  UpdateDriverTimesheetDetailResponse,
} from '../../../model/Driver.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, MD, QD } from '../../api.baseQuery';
import { CommonApiResponse } from '../../api.endpoint';
// #endregion

// #region INTERFACE
export type DriverEndpoint = {
  getDriverInfo: QD<GetDriverInfoApiRequest, GetDriverInfoApiResponse>;
  addDriver: MD<PostDriverCreateParams, PostDriverCreateResponse>;
  getDriverListCount: QD<
    GetDriversCountApiRequest | string,
    GetDriversCountApiResponse
  >;
  getDriverList: QD<GetDriverApiRequest | string, GetDriverApiResponse>;
  getDriverUnassignedList: QD<
    GetDriverUnassignedApiRequest,
    GetDriverUnassignedApiResponse
  >;
  getDriverActiveList: QD<
    GetDriverActiveApiRequest,
    GetDriverActiveApiResponse
  >;
  deactivateDriver: MD<DeactivateDriverRequest, DeactivateDriverResponse>;
  updateDriver: MD<UpdateDriverRequest, UpdateDriverResponse>;
  resetDriverPin: MD<UpdateDriverPinRequest, CommonApiResponse>;
  getDriverLastPosition: QD<
    { id: string },
    CommonApiResponse & { gpsEvent: DriverLastPosition | null }
  >;
  exportDriver: MD<ExportDriverApiRequest, ExportDriverApiResponse>;
  getDriverTimesheetList: QD<
    GetDriverTimesheetListApiRequest,
    GetDriverTimesheetListApiResponse
  >;
  getDriverTimesheetDetail: QD<
    GetDriverTimesheetDetailApiRequest,
    GetDriverTimesheetDetailApiResponse
  >;
  getDriverTimesheetEventList: QD<
    GetDriverTimesheetEventListApiRequest,
    GetDriverTimesheetEventListApiResponse
  >;
  getDriverTimesheetTotal: QD<
    GetDriverTimesheetTotalApiRequest,
    GetDriverTimesheetTotalApiResponse
  >;
  createDriverTimesheet: MD<
    CreateDriverTimesheetRequest,
    CreateDriverTimesheetResponse
  >;
  addDriverTimesheetDetail: MD<
    AddDriverTimesheetDetailRequest,
    AddDriverTimesheetDetailResponse
  >;
  updateDriverTimesheetDetail: MD<
    UpdateDriverTimesheetDetailRequest,
    UpdateDriverTimesheetDetailResponse
  >;
  removeDriverTimesheetDetail: MD<
    RemoveDriverTimesheetDetailRequest,
    RemoveDriverTimesheetDetailResponse
  >;
  exportDriverTimesheet: MD<
    ExportDriverTimesheetApiRequest,
    ExportDriverTimesheetApiResponse
  >;
  exportDriverTimesheetBulk: MD<
    ExportDriverTimesheetBulkApiRequest,
    ExportDriverTimesheetBulkApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const driverTagTypes: EndpointTagTypes<DriverEndpoint> = {
  getDriverInfo: '',
  getDriverListCount: '',
  getDriverList: 'driver',
  getDriverUnassignedList: '',
  getDriverActiveList: '',
  addDriver: '',
  deactivateDriver: '',
  updateDriver: '',
  resetDriverPin: '',
  getDriverLastPosition: '',
  exportDriver: '',
  getDriverTimesheetList: 'driver.timesheet.list',
  getDriverTimesheetDetail: 'driver.timesheet.detail',
  getDriverTimesheetEventList: 'driver.timesheet.event.list',
  getDriverTimesheetTotal: 'driver.timesheet.total',
  createDriverTimesheet: '',
  addDriverTimesheetDetail: '',
  updateDriverTimesheetDetail: '',
  removeDriverTimesheetDetail: '',
  exportDriverTimesheet: '',
  exportDriverTimesheetBulk: '',
};

export const driverEndpoint = (builder: Builder): DriverEndpoint => ({
  getDriverInfo: builder.query({
    query: (param) => urlcat('/v1/drivers.info', toSnakeCase(param)),
    providesTags: [driverTagTypes.getDriverInfo],
  }),
  getDriverListCount: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/drivers.count?${param}`
        : urlcat('/v1/drivers.count', toSnakeCase(param)),
    providesTags: [driverTagTypes.getDriverList],
  }),
  getDriverList: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/drivers.list?${param}`
        : urlcat('/v1/drivers.list', toSnakeCase(param)),
    providesTags: (result) =>
      result
        ? [
            ...result.drivers.map(({ id }) => ({
              type: driverTagTypes.getDriverList,
              id,
            })),
            driverTagTypes.getDriverList,
          ]
        : [driverTagTypes.getDriverList],
  }),
  getDriverUnassignedList: builder.query({
    query: (param) => urlcat('/v1/drivers.unassigned.list', toSnakeCase(param)),
  }),
  getDriverActiveList: builder.query({
    query: (param) => urlcat('/v1/drivers.active.list', toSnakeCase(param)),
  }),
  addDriver: builder.mutation({
    query: (body) => ({ url: '/v1/drivers.create', body, method: 'post' }),
    invalidatesTags: [driverTagTypes.getDriverList],
  }),
  deactivateDriver: builder.mutation({
    query: (body) => ({ url: '/v1/drivers.deactivate', body, method: 'post' }),
    invalidatesTags: [driverTagTypes.getDriverList],
  }),
  updateDriver: builder.mutation({
    query: (body) => ({
      url: '/v1/drivers.update',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      driverTagTypes.getDriverList,
      driverTagTypes.getDriverInfo,
    ],
  }),
  resetDriverPin: builder.mutation({
    query: (body) => ({
      url: '/v1/drivers.pin.reset',
      body,
      method: 'post',
    }),
    invalidatesTags: [driverTagTypes.getDriverInfo],
  }),
  getDriverLastPosition: builder.query({
    query: (param) => ({
      url: urlcat('v1/drivers.gps.locations.latest', toSnakeCase(param)),
    }),
  }),
  exportDriver: builder.mutation({
    query: (body) => ({ url: '/v1/drivers.export', body, method: 'post' }),
  }),
  getDriverTimesheetList: builder.query({
    query: (param) => urlcat('/v1/drivers.timesheets.list', toSnakeCase(param)),
    providesTags: (result) =>
      result
        ? [
            ...result.driverTimesheets.map(({ id }) => ({
              type: driverTagTypes.getDriverTimesheetList,
              id,
            })),
            driverTagTypes.getDriverTimesheetList,
          ]
        : [driverTagTypes.getDriverTimesheetList],
  }),
  getDriverTimesheetDetail: builder.query({
    query: (param) =>
      urlcat('/v1/drivers.timesheets.details.list', toSnakeCase(param)),
    providesTags: [driverTagTypes.getDriverTimesheetDetail],
  }),
  getDriverTimesheetEventList: builder.query({
    query: (param) =>
      urlcat('/v1/drivers.timesheets.events.list', toSnakeCase(param)),
    providesTags: (result) =>
      result
        ? [
            ...result.timesheetEvents.map(({ id }) => ({
              type: driverTagTypes.getDriverTimesheetEventList,
              id,
            })),
            driverTagTypes.getDriverTimesheetEventList,
          ]
        : [driverTagTypes.getDriverTimesheetEventList],
  }),
  getDriverTimesheetTotal: builder.query({
    query: (param) =>
      urlcat('/v1/drivers.timesheets.total', toSnakeCase(param)),
    providesTags: [driverTagTypes.getDriverTimesheetTotal],
  }),
  createDriverTimesheet: builder.mutation({
    query: (body) => ({
      body,
      method: 'post',
      url: '/v1/drivers.timesheets.create',
    }),
    invalidatesTags: [
      driverTagTypes.getDriverTimesheetList,
      driverTagTypes.getDriverTimesheetTotal,
      driverTagTypes.getDriverTimesheetDetail,
    ],
  }),
  addDriverTimesheetDetail: builder.mutation({
    query: (body) => ({
      body,
      method: 'post',
      url: '/v1/drivers.timesheets.details.add',
    }),
    invalidatesTags: [
      driverTagTypes.getDriverTimesheetList,
      driverTagTypes.getDriverTimesheetTotal,
      driverTagTypes.getDriverTimesheetDetail,
      driverTagTypes.getDriverTimesheetEventList,
    ],
  }),
  updateDriverTimesheetDetail: builder.mutation({
    query: (body) => ({
      body,
      method: 'post',
      url: '/v1/drivers.timesheets.details.update',
    }),
    invalidatesTags: [
      driverTagTypes.getDriverTimesheetList,
      driverTagTypes.getDriverTimesheetTotal,
      driverTagTypes.getDriverTimesheetDetail,
      driverTagTypes.getDriverTimesheetEventList,
    ],
  }),
  removeDriverTimesheetDetail: builder.mutation({
    query: (body) => ({
      body,
      method: 'post',
      url: '/v1/drivers.timesheets.details.remove',
    }),
    invalidatesTags: [
      driverTagTypes.getDriverTimesheetList,
      driverTagTypes.getDriverTimesheetTotal,
      driverTagTypes.getDriverTimesheetDetail,
      driverTagTypes.getDriverTimesheetEventList,
    ],
  }),
  exportDriverTimesheet: builder.mutation({
    query: (body) => ({
      body,
      method: 'post',
      url: '/v1/drivers.timesheets.export',
    }),
  }),
  exportDriverTimesheetBulk: builder.mutation({
    query: (body) => ({
      body,
      method: 'post',
      url: '/v1/drivers.timesheets.bulk-export',
    }),
  }),
});
// #endregion
