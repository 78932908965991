import React from 'react';

export default function EmptyIllustration() {
  const uniqueId = Math.floor(Math.random() * 10);
  return (
    <svg
      width={471}
      height={120}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#a${uniqueId})`}>
        <mask
          id={`b${uniqueId}`}
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={38}
          width={471}
          height={236}
        >
          <ellipse
            cx={235.5}
            cy={156.076}
            rx={235.443}
            ry={117.342}
            fill="#C4C4C4"
          />
        </mask>
        <g mask={`url(#b${uniqueId})`}>
          <path
            fill={`url(#c${uniqueId})`}
            d="M.057-30.38h470.886v176.203H.057z"
          />
        </g>
        <path
          d="M117.559 9.986a3.02 3.02 0 0 1 3.026 3.026v7.506c0 3.605 2.922 6.542 6.527 6.542h2.759V15.727a3.02 3.02 0 0 1 3.026-3.026 3.02 3.02 0 0 1 3.026 3.026V40.9h2.581c3.59 0 6.527-2.937 6.527-6.542v-7.506a3.02 3.02 0 0 1 3.026-3.026 3.02 3.02 0 0 1 3.026 3.026v7.506c0 6.942-5.637 12.594-12.579 12.594h-2.581v31.863a3.02 3.02 0 0 1-3.026 3.026 3.02 3.02 0 0 1-3.026-3.026V62.172h-2.759c-6.943 0-12.579-5.652-12.579-12.594v-7.506a3.02 3.02 0 0 1 3.026-3.026 3.02 3.02 0 0 1 3.026 3.026v7.506c0 3.604 2.922 6.541 6.527 6.541h2.759V33.112h-2.759c-6.943 0-12.579-5.652-12.579-12.594v-7.506c-.015-1.676 1.35-3.026 3.026-3.026ZM81.424 50.305a3.02 3.02 0 0 1 3.026 3.026v5.37c0 2.106 1.706 3.827 3.812 3.827h1.112V34.462a3.02 3.02 0 0 1 3.027-3.026 3.02 3.02 0 0 1 3.026 3.026v17.163h.979c2.106 0 3.812-1.72 3.812-3.827v-5.37a3.02 3.02 0 0 1 3.026-3.026 3.02 3.02 0 0 1 3.026 3.026v5.385c0 5.444-4.42 9.879-9.864 9.879h-.98v21.954a3.02 3.02 0 0 1-3.025 3.026 3.02 3.02 0 0 1-3.026-3.026V68.58h-1.113c-5.444 0-9.865-4.435-9.865-9.88v-5.37a3.02 3.02 0 0 1 3.026-3.025Z"
          fill="#F3532E"
        />
        <g clipPath={`url(#d${uniqueId})`}>
          <path
            d="M351.323 4.375c-2.447 0-4.431 1.958-4.431 4.374v13.123c0 2.418-1.983 4.374-4.43 4.374V4.375c0-2.416-1.984-4.375-4.43-4.375-2.447 0-4.431 1.959-4.431 4.375v30.62c-2.447 0-4.43-1.956-4.43-4.374V17.498c0-2.417-1.984-4.375-4.431-4.375s-4.43 1.959-4.43 4.374V30.62c0 7.25 5.954 13.123 13.291 13.123v8.749h8.861V34.994c7.338 0 13.291-5.873 13.291-13.123V8.748c0-2.416-1.983-4.373-4.43-4.373Z"
            fill="#898989"
          />
        </g>
      </g>
      <defs>
        <clipPath id={`a${uniqueId}`}>
          <path
            fill="#fff"
            transform="translate(.057)"
            d="M0 0h470.886v120H0z"
          />
        </clipPath>
        <clipPath id={`d${uniqueId}`}>
          <path
            fill="#fff"
            transform="translate(311.449)"
            d="M0 0h53.165v52.492H0z"
          />
        </clipPath>
        <linearGradient
          id={`c${uniqueId}`}
          x1={231.702}
          y1={26.582}
          x2={235.5}
          y2={145.823}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4E0CC" />
          <stop offset={0.796} stopColor="#C4C4C4" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
