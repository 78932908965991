import React from 'react';
import tw, { theme } from 'twin.macro';
import { UseJobOrderFilterObj } from '../../../hook/jobOrder/useJobOrderFilter.hook';
import { UseGetDriversAutocompleteObj } from '../../../hook/useGetDriversAutocomplete.hook';
import { UseGetVehiclesAutocompleteObj } from '../../../hook/useGetVehiclesAutocomplete.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';
import { AutoComplete, DateRangePickerInput } from '../../molecule';

// #region INTERFACES
type JobOrderFilterProps = {
  jobOrderFilter: UseJobOrderFilterObj;
  driversAutocomplete: UseGetDriversAutocompleteObj;
  vehiclesAutocomplete: UseGetVehiclesAutocompleteObj;
};
// #endregion

// #region STYLED
const Container = tw.div`relative w-full h-full max-h-screen bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`w-full sticky bottom-0 flex-none p-4 flex flex-col border-t border-t-grey-five bg-white`;
const Filters = tw.div`overflow-auto py-5 px-4 flex flex-col space-y-6 h-full`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)`mb-2`;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
const DatePickerContainer = tw.div`mt-3 flex items-center justify-between space-x-2`;
// #endregion

function JobOrderFilter({
  jobOrderFilter,
  driversAutocomplete,
  vehiclesAutocomplete,
}: JobOrderFilterProps) {
  return (
    <Container>
      <Header data-testid={`${jobOrderFilter.testID || ''}Filter:Header`}>
        <Icon.FilterV2 />

        <Text.HeadingFour
          tw="flex-1 ml-2"
          data-testid={`${jobOrderFilter.testID || ''}Filter:Title`}
        >
          {jobOrderFilter.titleLabel}
        </Text.HeadingFour>

        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          data-testid={`${jobOrderFilter.testID || ''}Filter:ResetAction`}
          onClick={jobOrderFilter.onClickResetFilter}
        >
          {jobOrderFilter.resetButtonLabel}
        </Button.Outlined>
      </Header>

      <Divider />

      <Filters data-testid={`${jobOrderFilter.testID || ''}Filter:Contents`}>
        <FilterItemContainer>
          <InputHeading
            data-testid={`${jobOrderFilter.testID || ''}Filter:OrderByLabel`}
          >
            {jobOrderFilter.orderByLabel}
          </InputHeading>

          <ChipContainer>
            {jobOrderFilter.filterOrderByValues.map((el, i) => {
              const selected =
                jobOrderFilter.filterForm.values.orderBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      jobOrderFilter.onClickOrderByChipFilter(el.value)
                    }
                    testID={`${
                      jobOrderFilter.testID || ''
                    }Filter:OrderByOption:${i}`}
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading
              data-testid={`${jobOrderFilter.testID || ''}Filter:StatusLabel`}
            >
              {jobOrderFilter.statusLabel}
            </InputHeading>

            <Button.Text
              small
              onClick={
                jobOrderFilter.isQueryStatusAll
                  ? jobOrderFilter.onClickUnselectAllStatus
                  : jobOrderFilter.onClickSelectAllStatus
              }
            >
              {jobOrderFilter.isQueryStatusAll
                ? jobOrderFilter.unselectAllLabel
                : jobOrderFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {jobOrderFilter.filterStatusValues.map((el, i) => {
              const selected = jobOrderFilter.filterForm.values.status.includes(
                el.value,
              );

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      jobOrderFilter.onClickStatusChipFilter(el.value)
                    }
                    testID={`${
                      jobOrderFilter.testID || ''
                    }Filter:StatusOption:${i}`}
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {jobOrderFilter.isQueryStatusEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {jobOrderFilter.errorStatusLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${jobOrderFilter.testID || ''}Filter:DriverLabel`}
          >
            {jobOrderFilter.driverLabel}
          </InputHeading>

          <AutoComplete
            tw="bg-orange-dark-lines"
            value={jobOrderFilter.filterForm.values.driverId}
            datas={driversAutocomplete.driverOptions}
            selectedOption={jobOrderFilter.queryDriverOption}
            placeholder={jobOrderFilter.driverPlaceholder}
            loading={driversAutocomplete.driverListFetchLoading}
            changeData={jobOrderFilter.onChangeDriverFilter}
            changeInputText={driversAutocomplete.onChangeDriverAutotext}
            onFetchMore={driversAutocomplete.onFetchMoreDriver}
            onRemoveData={jobOrderFilter.onRemoveDriverFilter}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${jobOrderFilter.testID || ''}Filter:VehicleLabel`}
          >
            {jobOrderFilter.vehicleLabel}
          </InputHeading>

          <AutoComplete
            hasExtraLabel
            labelKey="extraLabel"
            tw="bg-orange-dark-lines"
            value={jobOrderFilter.filterForm.values.vehicleId}
            datas={vehiclesAutocomplete.vehicleOptions}
            selectedOption={jobOrderFilter.queryVehicleOption}
            placeholder={jobOrderFilter.vehiclePlaceholder}
            loading={vehiclesAutocomplete.vehicleListFetchLoading}
            changeData={jobOrderFilter.onChangeVehicleFilter}
            changeInputText={vehiclesAutocomplete.onChangeVehicleAutotext}
            onFetchMore={vehiclesAutocomplete.onFetchMoreVehicle}
            onRemoveData={jobOrderFilter.onRemoveVehicleFilter}
          />
        </FilterItemContainer>

        <FilterItemContainer>
          <InputHeading
            data-testid={`${jobOrderFilter.testID || ''}Filter:DateRangeLabel`}
          >
            {jobOrderFilter.dateLabel}
          </InputHeading>

          <DatePickerContainer
            data-testid={`${
              jobOrderFilter.testID || ''
            }Filter:DateRangeContainer`}
          >
            <DateRangePickerInput
              sdLabel={jobOrderFilter.sdLabel}
              edLabel={jobOrderFilter.edLabel}
              sdDateValue={jobOrderFilter.filterForm.values.startDate}
              sdOnChangeDateValue={jobOrderFilter.onChangeStartDateFilter}
              edDateValue={jobOrderFilter.filterForm.values.endDate}
              edOnChangeDateValue={jobOrderFilter.onChangeEndDateFilter}
              onClickApplyDateRange={jobOrderFilter.onClickApplyDateRange}
              definedRangeOptions={jobOrderFilter.dateRangeDefinedRangeOptions}
              outsideAlerterStyle={{ width: '100%' }}
              allowUndefinedDateRange
            />
          </DatePickerContainer>
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer data-testid={`${jobOrderFilter.testID || ''}Filter:Footer`}>
        <Button.Solid
          type="submit"
          disabled={
            jobOrderFilter.isQueryStatusEmpty ||
            jobOrderFilter.filterLocalIsFetching
          }
          onClick={jobOrderFilter.onClickSubmitFilter}
          data-testid={`${jobOrderFilter.testID || ''}Filter:SubmitButton`}
        >
          {jobOrderFilter.submitButtonLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default JobOrderFilter;
