import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { HaulingOrderCreateNavigateParams } from '../HaulingOrderCreate/HaulingOrderCreate.route';

export type HOShipperAddRouteParam =
  | HaulingOrderCreateNavigateParams
  | undefined;

export const hoShipperAddRoute: RouteConfig = {
  name: 'Add New Shipper',
  Component: React.lazy(() => import('./HOShipperAdd.view')),
  path: '/hauling-order/create/add-new-shipper',
  isPrivate: true,
  breadcrumb: 'Add New Shipper',
  props: {
    isUnclickable: true,
  },
};
