import React from 'react';
import tw from 'twin.macro';
import { Chip, Icon } from '../../atom';

const Container = tw.div`absolute flex flex-row justify-between items-center w-[calc( 100% - 68px)]  h-[60px] top-0 left-2 z-index[2]`;
const NavigationContainer = tw.div`mr-2 `;

interface Props {
  handleBack(): void;
}

function SODetailMobileMapsHeader({ handleBack }: Props) {
  return (
    <Container>
      <NavigationContainer>
        <Chip.Small
          left={<Icon.ArrowBack fill="#252525" />}
          tw="h-11 rounded bg-white"
          onClick={handleBack}
        />
      </NavigationContainer>
    </Container>
  );
}

export default SODetailMobileMapsHeader;
