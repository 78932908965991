import getUnixTime from 'date-fns/getUnixTime';
import uniqBy from 'lodash/uniqBy';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '../../component/atom';
import { EmptyDataProps } from '../../component/molecule/EmptyData/EmptyData.molecule';
import { JOAssignmentFormInitialValues } from '../../constant/JobOrder.constant';
import {
  JOSOCandidateFilterFormInitialValues,
  joSOCandidateFilterFormInitialValues,
} from '../../constant/JobOrderCreate.constant';
import { FormikHookProps } from '../../constant/Types.constant';
import {
  JobOrderForm,
  JOScheduleAssignmentType,
} from '../../model/JobOrder.model';
import { ShipperOrder } from '../../model/ShipperOrder.model';
import api from '../../service/api.service';
import { getSOUnassignedStatuses } from '../../util/shipperOrder.util';
import { jobOrderCreateRoute } from '../../view/JobOrderCreate/JobOrderCreate.route';
import { createShipperOrderRoute } from '../../view/ShipperOrderCreate/ShipperOrderCreate.route';
import useDebounce from '../useDebounce.hook';
import useTranslator from '../useTranslator.hook';
import { UseJOFormControllerHookObj } from './useJOFormController.hook';
import { UseJOFormInitializationHookObj } from './useJOFormInitialization.hook';
import useJOFormSOCandidateFilter from './useJOFormSOCandidateFilter.hook';
import { UseJOFormSORenderRowHookObj } from './useJOFormSORenderRow.hook';

// #region INTERFACES
type Props = {
  joFormik: FormikHookProps<JobOrderForm>;
  controller: UseJOFormControllerHookObj;
  initialization: UseJOFormInitializationHookObj;
  formatRenderRows: UseJOFormSORenderRowHookObj['formatRenderRows'];
  setVehicleDriverTempValue: (state: JOScheduleAssignmentType) => void;
  handleBackToSelection: () => void;
};
// #endregion

export default function useJOFormSOCandidate({
  controller: {
    soController: {
      selectedSOIds,
      selectedSO,
      selectionSOList,
      setSelectionSOList,
      handleSelectAll,
      handleDeselectAll,
    },
  },
  joFormik: { values, setValues },
  initialization: { setSelectedSOListState },
  formatRenderRows,
  handleBackToSelection,
  setVehicleDriverTempValue,
}: Props) {
  const param = useParams();
  const navigate = useNavigate();
  const { translate } = useTranslator();

  const joId = param?.id ?? '';

  // #region MAIN
  const [currentLimitSOCandidate, setCurrentLimitSOCandidate] =
    useState<number>(10);
  const [currentPageSOCandidate, setCurrentPageSOCandidate] =
    useState<number>(1);
  const [currentFilterFormSOCandidate, setCurrentFilterFormSOCandidate] =
    useState<JOSOCandidateFilterFormInitialValues>(
      joSOCandidateFilterFormInitialValues,
    );
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const statusNumbers = getSOUnassignedStatuses(
    currentFilterFormSOCandidate.status,
  );

  // this is necessary because we need to query multiple `status[]` params
  const searchParams = useMemo(() => {
    const params = new URLSearchParams('');

    params.set('page', currentPageSOCandidate.toString());
    params.set('page_size', currentLimitSOCandidate.toString());
    params.set('order_by', currentFilterFormSOCandidate.sortBy);

    if (joId) params.set('jo_id', joId);
    if (statusNumbers.length === 1) {
      for (const _status of statusNumbers) {
        params.append('status[]', _status.toString());
      }
    }
    if (currentFilterFormSOCandidate.shipperId)
      params.set('shipper_id', currentFilterFormSOCandidate.shipperId);
    if (currentFilterFormSOCandidate.locationId)
      params.set('location_id', currentFilterFormSOCandidate.locationId);
    if (currentFilterFormSOCandidate.startDate)
      params.set(
        'from',
        getUnixTime(currentFilterFormSOCandidate.startDate).toString(),
      ); // in seconds
    if (currentFilterFormSOCandidate.endDate)
      params.set(
        'to',
        getUnixTime(currentFilterFormSOCandidate.endDate).toString(),
      ); // in seconds
    if (debouncedSearch) params.set('search', debouncedSearch);

    return params.toString();
  }, [
    currentFilterFormSOCandidate.endDate,
    currentFilterFormSOCandidate.locationId,
    currentFilterFormSOCandidate.shipperId,
    currentFilterFormSOCandidate.sortBy,
    currentFilterFormSOCandidate.startDate,
    currentLimitSOCandidate,
    currentPageSOCandidate,
    debouncedSearch,
    joId,
    statusNumbers,
  ]);

  const {
    data: soListData,
    isLoading: soListFetchLoading,
    isFetching: soListIsRefetching,
    refetch: refetchSoList,
  } = api.useGetSOUnassignedListQuery(searchParams, {
    refetchOnMountOrArgChange: true,
  });
  // #endregion

  // #region JO SO CANDIDATE SECTION
  const candidateDatas = useMemo((): ShipperOrder[] => {
    if (!soListData?.shipperOrders) return [];
    return soListData.shipperOrders;
  }, [soListData?.shipperOrders]);

  const candidateDataTotal = useMemo(
    () => soListData?.metadata?.totalCount || 0,
    [soListData?.metadata?.totalCount],
  );

  const candidatePageTotal = useMemo(
    () => Math.ceil(candidateDataTotal / currentLimitSOCandidate),
    [currentLimitSOCandidate, candidateDataTotal],
  );

  const isAllCandidateSelected: boolean = useMemo(
    () =>
      !!candidateDatas.length &&
      candidateDatas.every(
        (item) =>
          selectedSOIds.includes(item.id) ||
          (!!values.deliveries?.length &&
            values.deliveries?.some((v) => v.soId === item.id)),
      ),
    [candidateDatas, selectedSOIds, values.deliveries],
  );
  const isIndeterminateCandidateSelected: boolean = useMemo(
    () =>
      !isAllCandidateSelected &&
      candidateDatas.some(
        (item) =>
          selectedSOIds.includes(item.id) ||
          (!!values.deliveries?.length &&
            values.deliveries?.some((v) => v.soId === item.id)),
      ),
    [candidateDatas, selectedSOIds, isAllCandidateSelected, values.deliveries],
  );

  const candidateRowRender = useMemo(
    () => formatRenderRows(candidateDatas, true),
    [formatRenderRows, candidateDatas],
  );

  const noResultFound =
    soListData &&
    soListData.metadata.totalCount < 1 &&
    !!(
      debouncedSearch ||
      currentFilterFormSOCandidate.shipperId ||
      currentFilterFormSOCandidate.locationId ||
      (currentFilterFormSOCandidate.startDate &&
        currentFilterFormSOCandidate.endDate) ||
      currentFilterFormSOCandidate.status.length < 2
    );

  const emptyData = useMemo<EmptyDataProps>(
    () => ({
      showButtonIcon: false,
      icon: noResultFound ? <Icon.NoResultFound /> : <Icon.EmptyIllustration />,
      description: translate(
        noResultFound
          ? 'No Result Found'
          : "You don't have any Shipper Order on Shipper Order Candidate",
      ),
      subDescription: translate(
        noResultFound
          ? 'Try a different search terms so we can show you some available Shipper Order Candidate'
          : 'Try create Shipper Order by clicking the button below:',
      ),
      buttonLabel: translate(
        noResultFound ? 'Set Back to Default List' : 'Create Shipper Order',
      ),
      onClick: () => {
        if (noResultFound) {
          setCurrentLimitSOCandidate(10);
          setCurrentPageSOCandidate(1);
          setSearch('');
          setCurrentFilterFormSOCandidate(joSOCandidateFilterFormInitialValues);
        } else {
          navigate(createShipperOrderRoute.path, {
            state: {
              originPath: jobOrderCreateRoute.path,
              joState: values,
            },
          });
        }
      },
    }),
    [navigate, noResultFound, translate, values],
  );
  const handleSelectAllCandidate = useCallback(() => {
    const formattedArr = candidateDatas.filter(
      (item) => !values?.deliveries?.some((v) => v.soId === item.id),
    );
    handleSelectAll(formattedArr);
  }, [candidateDatas, handleSelectAll, values?.deliveries]);

  const handleDeselectAllCandidate = useCallback(() => {
    const formattedArr = candidateDatas.filter(
      (item) => !values?.deliveries?.some((v) => v.soId === item.id),
    );
    handleDeselectAll(formattedArr);
  }, [candidateDatas, handleDeselectAll, values?.deliveries]);

  const handleSearch = useCallback(
    (data?: string) => setSearch(data ?? ''),
    [],
  );

  const handleBack = useCallback(() => {
    setCurrentPageSOCandidate(1);
    handleDeselectAllCandidate();
    handleBackToSelection();
  }, [handleBackToSelection, handleDeselectAllCandidate]);

  // PAGINATION
  const handleLimitCandidate = (limit: number) => {
    setCurrentLimitSOCandidate(limit);
    setCurrentPageSOCandidate(1);
  };

  const handlePageCandidate = (page: number) => {
    setCurrentPageSOCandidate(page);
  };

  const handleFilterFormCandidate = (
    filterForm: JOSOCandidateFilterFormInitialValues,
  ) => {
    setCurrentFilterFormSOCandidate(filterForm);
  };

  const handleAddSOCandidate = useCallback(async () => {
    const formattedArr = [
      ...new Set([
        ...(values?.deliveries || []),
        ...selectedSOIds.map((v) => ({
          soId: v,
          isResolved: false,
        })),
      ]),
    ].slice(0, 100);

    const formattedArrObject = uniqBy(
      [...selectedSO, ...selectionSOList],
      'id',
    ).slice(0, 100);
    await setValues({
      ...values,
      deliveries: formattedArr,
      vehicleId: undefined,
      vehicleOption: undefined,
      schedule: undefined,
      scheduleOption: undefined,
      driverId: undefined,
      driverOption: undefined,
    });
    setVehicleDriverTempValue(JOAssignmentFormInitialValues);
    setSelectionSOList(formattedArrObject);
    if (!joId) setSelectedSOListState(formattedArrObject);
    handleBack();
  }, [
    joId,
    values,
    selectedSOIds,
    selectedSO,
    selectionSOList,
    setValues,
    setVehicleDriverTempValue,
    setSelectionSOList,
    setSelectedSOListState,
    handleBack,
  ]);
  // #endregion

  const filter = useJOFormSOCandidateFilter({
    currentFilterFormSOCandidate,
    handleDeselectAllCandidate,
    handleFilterFormCandidate,
  });

  return {
    candidatePageTotal,
    candidateDataTotal,
    candidateRowRender,
    currentPageSOCandidate,
    currentLimitSOCandidate,
    currentFilterFormSOCandidate,
    search,
    debouncedSearch,
    emptyData,
    isAllCandidateSelected,
    isIndeterminateCandidateSelected,
    soListFetchLoading,
    soListIsRefetching,
    filter,
    handleBack,
    handleSelectAllCandidate,
    handleDeselectAllCandidate,
    handleSearch,
    handleLimitCandidate,
    handlePageCandidate,
    handleFilterFormCandidate,
    handleAddSOCandidate,
    refetchSoList,
  };
}

export type UseJOFormSOCandidateHookObj = ReturnType<
  typeof useJOFormSOCandidate
>;
