import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarTheme } from '../../component/molecule/Snackbar/Snackbar.molecule';
import { CommonApiResponse } from '../../service/api.endpoint';
import api from '../../service/api.service';
import { CompleteShipperOrderParam } from '../../service/endpoint/jobOrder/jobOrder.endpoint';
import { snackbarAction } from '../../store/snackbar.store';
import { formatText } from '../../util/tracking/trackingTimeline.util';
import useVerifyAuth from '../../view/Wrapper/hooks/useVerifyAuth.hook';
import { UseSelectedShipperOrderHookObj } from '../jobOrderDetail/useSelectedShipperOrder.hook';
import { UseForceCompleteJOHookObj } from '../useForceCompleteJO/useForceCompleteJO.hook';
import { UseJobOrderInfoHookObj } from '../useJobOrderInfo/useJobOrderInfo.hook';
import useTranslator from '../useTranslator.hook';

type Props = {
  selectedShipperOrder?: UseSelectedShipperOrderHookObj;
  forceCompleteJO: UseForceCompleteJOHookObj;
  jobOrderInfo: UseJobOrderInfoHookObj;
};
function useForceCompleteSO({
  selectedShipperOrder,
  forceCompleteJO,
  jobOrderInfo,
}: Props) {
  // #region GENERAL
  const translator = useTranslator();
  const dispatch = useDispatch();
  const { handleVerifyAuth } = useVerifyAuth();
  // #endregion

  // #region API CALL
  const [request, { reset, isLoading }] =
    api.useForceCompleteShipperOrdersMutation();
  // #endregion

  const handleCompleteSOFailed = useCallback(() => {
    if (!jobOrderInfo.joInfoData || !selectedShipperOrder?.data) return;
    dispatch(
      snackbarAction.show({
        type: SnackbarTheme.warning,
        message: formatText(
          translator.translate('Failed to complete %s'),
          selectedShipperOrder.data.number,
        ),
      }),
    );
    reset();
    forceCompleteJO.reset();
  }, [
    dispatch,
    forceCompleteJO,
    jobOrderInfo.joInfoData,
    reset,
    selectedShipperOrder?.data,
    translator,
  ]);

  const handleCompleteSOSuccess = useCallback(
    (response: CommonApiResponse) => {
      if (
        !jobOrderInfo.joInfoData ||
        !selectedShipperOrder?.data ||
        !response?.ok
      )
        return;
      dispatch(
        snackbarAction.show({
          message: formatText(
            translator.translate('%s has been successfully completed'),
            selectedShipperOrder.data.number,
          ),
        }),
      );

      reset();
    },
    [dispatch, jobOrderInfo, reset, selectedShipperOrder?.data, translator],
  );

  const handleRequest = useCallback(
    async ({ id, joId }: CompleteShipperOrderParam) => {
      try {
        const authRes = await handleVerifyAuth();
        if (!authRes) return;
        const response = await request({ id, joId }).unwrap();
        void handleCompleteSOSuccess(response);
      } catch (error) {
        void handleCompleteSOFailed();
      }
    },
    [
      request,
      handleVerifyAuth,
      handleCompleteSOFailed,
      handleCompleteSOSuccess,
    ],
  );

  return { handleRequest, isLoading };
}
export type UseForceCompleteSOHookObj = ReturnType<typeof useForceCompleteSO>;
export default useForceCompleteSO;
