import { nanoid } from '@reduxjs/toolkit';
import capitalize from 'lodash/capitalize';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import { JO_INFO_SO_ACTIVITY_TABLE_HEADERS } from '../../../constant/JobOrder.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { SOTrackingActivityType } from '../../../model/ShipperOrder.model';
import { SOTrackingType } from '../../../model/TrackingOrder.model';
import { formatText } from '../../../util/formatter.util';
import { getGoodsDetailLabel } from '../../../util/tracking/trackingTimeline.util';
import { Text } from '../../atom';
import {
  ActivityTableBody,
  ActivityTableHeader,
  CollapsiblePanel,
} from '../../molecule';
import TrackingSODelivery from '../TrackingSODeliveryInfo/TrackingSODeliveryInfo.organism';

const panelStyle = {
  header: tw`bg-white`,
  content: tw`bg-white`,
  container: tw`shadow rounded-md overflow-hidden bg-white`,
  goodsDetailContainer: tw`overflow-hidden bg-white py-5`,
  mapsContainer: tw`bg-white p-0`,
};
const ActivityTable = tw.table`w-[calc(100% - 40px)] m-5`;
const Container = tw.div`max-w-2xl min-w-[720px] flex flex-col gap-5`;

type Props = {
  data?: SOTrackingType;
  showMap?: boolean;
  soMap?: React.ReactNode;
  timeline?: React.ReactNode;
  forceLang?: SupportedLanguage;
};

export default function TrackingSO({
  data,
  showMap,
  forceLang,
  timeline,
  soMap,
}: Props) {
  const translator = useTranslator();
  const activities = useMemo(() => {
    if (!data) return [];
    if (data?.isTransitable) return [data.activities[0]];

    return data.activities.filter(
      (v) => v.type !== SOTrackingActivityType.STAND_BY,
    );
  }, [data]);

  const activitiesHasGoods = useMemo(
    () => activities.flatMap((v) => v.goods).length > 0,
    [activities],
  );

  return (
    <Container>
      {showMap && (
        <CollapsiblePanel
          title={translator.translate('Map', forceLang)}
          contentStyle={panelStyle.mapsContainer}
          headerStyle={panelStyle.header}
          containerStyle={panelStyle.container}
        >
          {soMap}
        </CollapsiblePanel>
      )}

      {timeline}

      {activitiesHasGoods && (
        <CollapsiblePanel
          title={translator.translate('Goods Detail', forceLang)}
          contentStyle={panelStyle.goodsDetailContainer}
          headerStyle={panelStyle.header}
          containerStyle={panelStyle.container}
        >
          {activities.map((activity, i) => (
            <div
              key={`activity-${nanoid()}`}
              css={[
                activities.length > 1 &&
                  i !== activities.length - 1 &&
                  tw`mb-5`,
              ]}
            >
              {!data?.isTransitable && (
                <div tw="mb-2.5 w-[calc(100% - 40px)] flex overflow-hidden">
                  <Text.HeadingFive tw="whitespace-pre-wrap line-break[anywhere]">
                    {formatText(
                      translator.translate(
                        getGoodsDetailLabel(activity.type),
                        forceLang,
                      ),
                      activity.locationName,
                    )}
                  </Text.HeadingFive>
                </div>
              )}
              {activity.goods.length > 0 && (
                <div
                  tw="border border-beige-lines rounded-md"
                  key={`tracking-activity-${nanoid()}`}
                >
                  <ActivityTable tw="my-0">
                    <ActivityTableHeader
                      status={activity.type || ''}
                      items={JO_INFO_SO_ACTIVITY_TABLE_HEADERS}
                    />
                    <ActivityTableBody
                      status={activity.type || ''}
                      items={activity.goods.map((good) => ({
                        type: translator.translate(
                          capitalize(good.type),
                          forceLang,
                        ),
                        description: good.description,
                        qty: good.quantity,
                        unit: good.uom,
                        weight: good.weight,
                        volume: good.volume,
                      }))}
                    />
                  </ActivityTable>
                </div>
              )}
            </div>
          ))}
        </CollapsiblePanel>
      )}

      {!!data?.deliveries?.length && (
        <CollapsiblePanel
          title={translator.translate('Job Order List', forceLang)}
          contentStyle={tw`overflow-hidden bg-white p-0 flex`}
          containerStyle={tw`shadow rounded-md overflow-hidden bg-white mb-5`}
          headerStyle={panelStyle.header}
        >
          <TrackingSODelivery
            forceLang={forceLang}
            deliveries={data.deliveries}
          />
        </CollapsiblePanel>
      )}
    </Container>
  );
}
