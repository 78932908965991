import { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store/store.app';
import { featureFlag } from '../../constant/Onboarding.constant';
import { logEvent } from '../../service/analytic/analytic.service';
import { onboardingAction } from '../../store/onboarding.store';
import useOrganizationInfo from '../organization/useOrganizationInfo.hook';
import { UseTranslator } from '../useTranslator.hook';

// #region INTERFACES
type UseHomeOnboardingProps = {
  translator: UseTranslator;
};
// #endregion

const pageName = 'HomeOnBoarding';
const homeOnBoardingEvent = {
  addShipper: `KTB_${pageName}_Button_AddShipper`,
  addLocation: `KTB_${pageName}_Button_AddLocation`,
  addShipperOrder: `KTB_${pageName}_Button_AddShipperOrder`,
  addDriver: `KTB_${pageName}_Button_AddDriver`,
  jobOrder: `KTB_${pageName}_Button_AddJobOrder`,
};

function useHomeOnboarding({ translator }: UseHomeOnboardingProps) {
  // #region LABELS

  const firstSectionTitleLabel = translator.translate('Create Shipper Order');
  const firstSectionSubtitleLabel = translator.translate(
    'Add a Shipper and a Location to start creating a Shipper Order',
  );
  const addShipperTitleLabel = translator.translate('Add Shipper');
  const addShipperSubtitleLabel = translator.translate(
    'Shipper indicates the sender or the individual which place the shipment order to your organization.',
  );
  const addDeliveryLocationTitleLabel = translator.translate(
    'Add Delivery Location',
  );
  const addDeliveryLocationSubtitleLabel = translator.translate(
    'Delivery Location is the targeted address(es) to pickup or drop-off goods based on the shipment order.',
  );
  const createShipperOrderTitleLabel = translator.translate(
    'Create Shipper Order',
  );
  const createShipperOrderSubtitleLabel = translator.translate(
    'A Shipper Order contains activities which need to be done in a delivery locations by your organization (standby, pickup, or drop-off) to carry Shipper shipment order.',
  );

  const secondSectionTitleLabel = translator.translate('Create Job Order');
  const secondSectionSubtitleLabel = translator.translate(
    'Assign a Shipper Order to a Job Order that is assigned to a Driver',
  );
  const addDriverTitleLabel = translator.translate('Add Driver');
  const addDriverSubtitleLabel = translator.translate(
    'Driver is the individual which assigned by your organization to carry a set of Shipper Orders of an Job Order and perform its activities.',
  );
  const createJobOrderTitleLabel = translator.translate('Create Job Order');
  const createJobOrderSubtitleLabel = translator.translate(
    'A Job Order is a work order for a Driver that contains one or more Shipper Order(s) to be delivered by driver and vehicle assigned to complete the work order.',
  );
  // #endregion

  // #region VALUES
  // const routeCheck = useRouteCheck();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector(
    (_state: RootState) => _state.onboarding,
    shallowEqual,
  );

  const organization = useOrganizationInfo();
  const orientation = useMemo(
    () => organization.data?.organization.orientation,
    [organization.data?.organization.orientation],
  );
  const orientationIsFetching = useMemo(
    () => organization.isLoading,
    [organization.isLoading],
  );
  const isActiveOnboarding = useMemo(
    () => orientation && !orientation.isJobOrderCompleted,
    [orientation],
  );

  const details = {
    addShipper: !orientation?.isShipperCompleted,
    addDeliveryLocation: !orientation?.isLocationCompleted,
    createShipperOrder: !orientation?.isShipperOrderCompleted,
    addDriver: !orientation?.isDriverCompleted,
    createJobOrder: !orientation?.isJobOrderCompleted,
  };

  const isAddShipperChecked = !!orientation?.isShipperCompleted;
  const isAddDeliveryLocationChecked = !!orientation?.isLocationCompleted;
  const isCreateShipperOrderChecked = !!orientation?.isShipperOrderCompleted;
  const isCreateShipperOrderBtnDisabled =
    !isAddShipperChecked || !isAddDeliveryLocationChecked;
  const createShipperOrderDisabledLabel = translator.translate(
    'Need Shipper and Location',
  );

  const isAddDriverChecked = !!orientation?.isDriverCompleted;
  const isCreateJobOrderChecked = !!orientation?.isJobOrderCompleted;
  const isCreateJobOrderBtnDisabled =
    !isCreateShipperOrderChecked || !isAddDriverChecked;
  const createJobOrderDisabledLabel = translator.translate(
    'Need Driver and Shipper Order',
  );

  const addShipperBtnLabel = isAddShipperChecked
    ? translator.translate('View Shipper List')
    : addShipperTitleLabel;
  const addDeliveryLocationBtnLabel = isAddDeliveryLocationChecked
    ? translator.translate('View Delivery Location List')
    : addDeliveryLocationTitleLabel;
  const createShipperOrderBtnLabel = isCreateShipperOrderChecked
    ? translator.translate('View Shipper Order List')
    : createShipperOrderTitleLabel;

  const addDriverBtnLabel = isAddDriverChecked
    ? translator.translate('View Driver List')
    : addDriverTitleLabel;
  const createJobOrderBtnLabel = isCreateJobOrderChecked
    ? translator.translate('View Job Order List')
    : createJobOrderTitleLabel;

  const firstSectionProgress = useMemo(() => {
    let progress = 0;
    if (isAddShipperChecked) progress += 1;
    if (isAddDeliveryLocationChecked) progress += 1;
    if (isCreateShipperOrderChecked) progress += 1;
    return progress;
  }, [
    isAddDeliveryLocationChecked,
    isAddShipperChecked,
    isCreateShipperOrderChecked,
  ]);

  const secondSectionProgress = useMemo(() => {
    let progress = 0;
    if (isAddDriverChecked) progress += 1;
    if (isCreateJobOrderChecked) progress += 1;
    return progress;
  }, [isAddDriverChecked, isCreateJobOrderChecked]);
  // #endregion

  // #region HANDLERS
  const handleClickAddShipper = () => {
    if (!isAddShipperChecked && featureFlag.addShipper)
      dispatch(onboardingAction.changeAddShipper({ tourActive: true }));

    navigate(
      isAddShipperChecked || featureFlag.addShipper
        ? '/shipper'
        : '/shipper/add',
    );
    logEvent(homeOnBoardingEvent.addShipper, pageName);
  };
  const handleClickAddLocation = () => {
    if (!isAddDeliveryLocationChecked && featureFlag.addDeliveryLocation)
      dispatch(onboardingAction.changeAddLocation({ tourActive: true }));

    navigate(
      isAddDeliveryLocationChecked || featureFlag.addDeliveryLocation
        ? '/delivery-location'
        : '/delivery-location/add',
    );
    logEvent(homeOnBoardingEvent.addLocation, pageName);
  };
  const handleClickCreateShipperOrder = () => {
    if (!isCreateShipperOrderChecked && featureFlag.addShipperOrder)
      dispatch(onboardingAction.changeCreateShipperOrder({ tourActive: true }));

    navigate(
      isCreateShipperOrderChecked || featureFlag.addShipperOrder
        ? '/shipper-order'
        : '/shipper-order/create',
    );
    logEvent(homeOnBoardingEvent.addShipperOrder, pageName);
  };
  const handleClickAddDriver = () => {
    if (!isAddDriverChecked && featureFlag.addDriver)
      dispatch(onboardingAction.changeAddDriver({ tourActive: true }));

    navigate(
      isAddDriverChecked || featureFlag.addDriver ? '/driver' : '/driver/add',
    );
    logEvent(homeOnBoardingEvent.addDriver, pageName);
  };
  const handleClickCreateJobOrder = () => {
    if (!isCreateJobOrderChecked && featureFlag.addJobOrder)
      dispatch(onboardingAction.changeCreateJobOrder({ tourActive: true }));

    navigate(
      isCreateJobOrderChecked || featureFlag.addJobOrder
        ? '/job-order'
        : '/job-order/create',
    );
    logEvent(homeOnBoardingEvent.jobOrder, pageName);
  };
  // #endregion

  return {
    state,
    details,
    orientation,
    orientationIsFetching,
    isActiveOnboarding,
    isAddShipperChecked,
    isAddDeliveryLocationChecked,
    isCreateShipperOrderChecked,
    isCreateShipperOrderBtnDisabled,
    isAddDriverChecked,
    isCreateJobOrderChecked,
    isCreateJobOrderBtnDisabled,
    addShipperBtnLabel,
    addDeliveryLocationBtnLabel,
    createShipperOrderBtnLabel,
    addDriverBtnLabel,
    createJobOrderBtnLabel,
    firstSectionProgress,
    secondSectionProgress,
    firstSectionTitleLabel,
    firstSectionSubtitleLabel,
    addShipperTitleLabel,
    addShipperSubtitleLabel,
    addDeliveryLocationTitleLabel,
    addDeliveryLocationSubtitleLabel,
    createShipperOrderTitleLabel,
    createShipperOrderSubtitleLabel,
    createShipperOrderDisabledLabel,
    secondSectionTitleLabel,
    secondSectionSubtitleLabel,
    addDriverTitleLabel,
    addDriverSubtitleLabel,
    createJobOrderTitleLabel,
    createJobOrderSubtitleLabel,
    createJobOrderDisabledLabel,
    handleClickAddShipper,
    handleClickAddLocation,
    handleClickCreateShipperOrder,
    handleClickAddDriver,
    handleClickCreateJobOrder,
  };
}

export type UseHomeOnboardingHookObj = ReturnType<typeof useHomeOnboarding>;
export default useHomeOnboarding;
