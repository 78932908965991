import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../app/store/store.app';
import { BulkActionEntity, BulkActionStatusEnum } from '../../../constant';
import { LocationType } from '../../../constant/Location.constant';
import useOrganizationOrientation from '../../../hook/organization/useOrganizationOrientation.hook';
import { PostLocationParams } from '../../../model/Location.model';
import { logEvent } from '../../../service/analytic/analytic.service';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { navigationParamAction } from '../../../store/param.store';
import { getLocationCustomErrorMessage } from '../../../util/deliveryLocation.util';
import { deliveryLocationRoute } from '../../DeliveryLocation/deliveryLocation.route';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';
import { DeliveryLocationAddBulk } from '../DeliveryLocationAddBulk.route';
const literalType: Record<string, LocationType> = {
  Umum: LocationType.PUBLIC,
  'Milik Sendiri': LocationType.TRANSPORTER,
  Pengirim: LocationType.SHIPPER,
};

const mapStoreToState = (locations: DeliveryLocationAddBulk[]) =>
  locations.map((v) => ({
    ...v,
    _actionStatus: BulkActionStatusEnum.WAITING,
    _actionDetail: 'Waiting for registration',
  }));

const typeMapper = (type?: string) => {
  if (!type) return '';
  return literalType[type] ?? type;
};

const mapStoreToDto = (entity: AddBulkLocationEntity): PostLocationParams => ({
  name: entity['Nama Lokasi'],
  address: entity.Alamat,
  ...(entity['Titik Radius'] && {
    radius: parseInt(entity['Titik Radius'], 10),
  }),
  ...(entity['Koordinat Lintang'] && {
    latitude: parseFloat(entity['Koordinat Lintang']),
  }),
  ...(entity['Koordinat Bujur'] && {
    longitude: parseFloat(entity['Koordinat Bujur']),
  }),
  type: typeMapper(entity.Tipe),
  contactName: entity['Nama Kontak'],
  contactNumber: entity['Nomor Telepon'],
  shipperName: entity['Nama Pengirim'],
});

// #region TYPES
export type AddBulkLocationEntity = BulkActionEntity<DeliveryLocationAddBulk>;
export type UseDeliveryLocationAddBulkLogic = ReturnType<
  typeof useDeliveryLocationAddBulkLogic
>;
// #endregion

export default function useDeliveryLocationAddBulkLogic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleFetchOrganizationData } = useOrganizationOrientation();
  const { handleVerifyAuth } = useVerifyAuth();

  const [showStatus, setShowStatus] = useState(false);

  const [truncatedFilename, setTruncatedFilename] = useState('');

  const { filename, locations: locationsStore } = useSelector(
    (state: RootState) =>
      state.navigationParam.locationAddBulkParams ?? {
        filename: '',
        locations: [],
      },
  );

  useEffect(() => {
    if (filename.length < 30) {
      setTruncatedFilename(filename);
      return;
    }
    const [name, format] = filename.split('.');
    const truncatedName = name.slice(0, 25);
    setTruncatedFilename(`${truncatedName}...${format}`);
  }, [filename]);

  const [locationsWithStatusDetail, setLocationsWithStatusDetail] = useState<
    AddBulkLocationEntity[]
  >(mapStoreToState(locationsStore));
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(true);
  const [showDoneBtn, setShowDoneBtn] = useState(false);
  // to disable submit button when mutations is still ongoing
  const [mutationsIsLoading, setMutationsIsLoading] = useState(false);
  const [doAddLocation] = api.useCreateLocationMutation();
  // #endregion

  // #region HANDLERS
  const handleAddBulk = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    setShowStatus(true);
    logEvent('DeliveryLocation:AddBulk:Submit', 'Shipper');

    const promises = locationsWithStatusDetail.map((location) =>
      doAddLocation(mapStoreToDto(location)).unwrap(),
    );

    // track loading state
    setMutationsIsLoading(true);

    // NOTE: backend said this endpoint is safe to the parallel mutation
    await Promise.allSettled(promises)
      .then((results) => {
        for (const [idx, result] of results.entries()) {
          // on rejected
          if (result.status === 'rejected') {
            const errorQuery = (result.reason as FetchBaseQueryError)
              ?.data as ApiErrorResponse;
            const errorCode = errorQuery?.error?.code;
            const errorDetails = errorQuery?.error?.details;

            setLocationsWithStatusDetail((_locations) =>
              _locations.map((_location) =>
                _location.id === locationsWithStatusDetail[idx].id
                  ? {
                      ..._location,
                      _actionStatus: BulkActionStatusEnum.FAILED,
                      _actionDetail: getLocationCustomErrorMessage(
                        errorCode,
                        errorDetails,
                      ),
                    }
                  : _location,
              ),
            );
            continue;
          }

          // on fulfilled
          setLocationsWithStatusDetail((_locations) =>
            _locations.map((_location) =>
              _location.id === locationsWithStatusDetail[idx].id
                ? {
                    ..._location,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Location registered successfully',
                  }
                : _location,
            ),
          );
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        // on done
        setShowDoneBtn(true);
        setMutationsIsLoading(false);
        setHasUnsavedChanges(false);
      });
  }, [doAddLocation, handleVerifyAuth, locationsWithStatusDetail]);

  const handleDone = useCallback(() => {
    // clear store
    dispatch(navigationParamAction.clearLocationAddBulkParams());
    navigate(deliveryLocationRoute.path);
    handleFetchOrganizationData();
  }, [dispatch, handleFetchOrganizationData, navigate]);

  // sync `locationsStore` with `locationsWithStatusDetail`
  useEffect(() => {
    if (locationsStore.length > 0)
      setLocationsWithStatusDetail(mapStoreToState(locationsStore));
  }, [locationsStore]);

  return {
    hasUnsavedChanges,
    filename,
    truncatedFilename,
    locationsWithStatusDetail,
    showDoneBtn,
    mutationsIsLoading,
    showStatus,
    handleAddBulk,
    handleDone,
  };
}
