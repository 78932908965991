import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fillPath?: string;
};

export default function CubeOutline({
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  fill = 'none',
  fillPath = 'currentColor',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <g>
        <path
          fill={fillPath}
          d="M14 11a.66.66 0 01-.353.587l-5.267 2.96a.63.63 0 01-.38.12.63.63 0 01-.38-.12l-5.267-2.96A.66.66 0 012 11V5a.66.66 0 01.353-.587l5.267-2.96a.63.63 0 01.38-.12c.14 0 .273.04.38.12l5.267 2.96A.661.661 0 0114 5v6zM8 2.767L4.027 5 8 7.233 11.973 5 8 2.767zm-4.667 7.84l4 2.253V8.387l-4-2.247v4.467zm9.334 0V6.14l-4 2.247v4.473l4-2.253z"
        />
      </g>
    </svg>
  );
}
