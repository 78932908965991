import React from 'react';
import { Icon, TableNoData } from '../../component/atom';
import { BulkActionLayout } from '../../component/template';
import useJobOrderDetailExpenseApproveViewModel from './JobOrderDetailExpenseApprove.viewModel';

export default function JobOrderDetailExpenseApproveView() {
  const {
    translator: { translate },
    logic,
    table,
  } = useJobOrderDetailExpenseApproveViewModel();

  return (
    <BulkActionLayout
      actionLabel={translate('to start the approval process.')}
      descriptionLabel={translate(
        'Make sure the list below is the expense list that you want to approve from your organization.',
      )}
      cancelLabel={translate('Cancel')}
      submitLabel={translate('Approve')}
      doneLabel={translate('Done')}
      table={table}
      entityLength={logic.expensesWithStatusDetail.length}
      showDoneBtn={logic.showDoneBtn}
      isLoading={logic.mutationsIsLoading}
      noDataPage={
        <TableNoData
          icon={<Icon.NoDataFound />}
          titleLabel={translate(
            "You don't have any selected Expenses that you want to approve",
          )}
          descriptionLabel={translate(
            'Try to select some Expenses from Job Order Detail page',
          )}
          actionLabel={translate('Go back to Job Order List page')}
          onClickAction={logic.onCancelOrDone}
        />
      }
      onCancel={logic.onCancelOrDone}
      onSubmit={logic.onApprove}
      onDone={logic.onCancelOrDone}
    />
  );
}
