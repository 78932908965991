import { Driver } from '../model/Driver.model';
import api from '../service/api.service';
import useFetchMoreAutocomplete, {
  AutocompleteOptionsData,
} from './useFetchMoreAutocomplete.hook';

const useGetDriversAutocomplete = (onlyShowActiveData = false) => {
  const driverListOption: AutocompleteOptionsData<Driver> = {
    dataKey: 'drivers',
    labelKey: 'fullName',
    valueKey: 'id',
  };

  const {
    dataOptions: driverOptions,
    handleChangeAutotext: onChangeDriverAutotext,
    handleFetchMore: onFetchMoreDriver,
    isFetching: driverListFetchLoading,
    refetch,
  } = useFetchMoreAutocomplete({
    options: driverListOption,
    api: onlyShowActiveData
      ? api.useLazyGetDriverActiveListQuery
      : api.useLazyGetDriverListQuery,
  });

  return {
    driverOptions,
    driverListFetchLoading,
    refetch,
    onChangeDriverAutotext,
    onFetchMoreDriver,
  };
};

export type UseGetDriversAutocompleteObj = ReturnType<
  typeof useGetDriversAutocomplete
>;
export default useGetDriversAutocomplete;
