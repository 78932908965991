import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { JobOrderFormNavigateParams } from '../../constant/JobOrder.constant';

export type JODriverAddRouteParam =
  | (JobOrderFormNavigateParams & {
      originPath?: string;
    })
  | undefined;

export const joeDriverAddRoute: RouteConfig = {
  name: 'Add New Driver',
  Component: React.lazy(() => import('../JODriverAdd/JODriverAdd.view')),
  path: '/job-order/:id/add-new-driver',
  isPrivate: true,
  breadcrumb: 'Add New Driver',
  props: {
    isUnclickable: true,
  },
};
