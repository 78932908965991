import React, { MouseEventHandler, RefObject } from 'react';
import { theme } from 'twin.macro';
import { ExtraStyle } from '../../../Type.component';

type Props = React.SVGProps<SVGSVGElement> & {
  svgRef?: RefObject<SVGSVGElement>;
  svgStyle?: ExtraStyle;
  onMouseEnter?: MouseEventHandler<SVGSVGElement>;
  onMouseLeave?: MouseEventHandler<SVGSVGElement>;
  fillPath?: string;
};

export default function InfoOutlined({
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  fill = 'none',
  svgRef,
  svgStyle,
  onMouseEnter,
  onMouseLeave,
  fillPath = theme`colors.orange.DEFAULT`,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      css={svgStyle}
      ref={svgRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fillPath}
        d="M7.151 11.256V5.474H8.85v5.782H7.15zm.867-6.419c-.272 0-.496-.065-.672-.194-.177-.13-.266-.336-.266-.62 0-.318.089-.53.266-.636.176-.118.4-.177.672-.177.235 0 .442.06.619.177.188.106.283.318.283.637 0 .294-.095.507-.283.636a1.09 1.09 0 01-.62.177zM8 15.66c-1.061 0-2.057-.2-2.989-.601a7.761 7.761 0 01-2.44-1.627 8.02 8.02 0 01-1.645-2.44 7.675 7.675 0 01-.583-2.99c0-1.06.194-2.05.583-2.97.401-.932.95-1.745 1.645-2.44A7.618 7.618 0 015.01.946 7.479 7.479 0 018 .345c1.061 0 2.051.2 2.97.602.932.389 1.746.937 2.441 1.644a7.37 7.37 0 011.645 2.44c.4.92.601 1.91.601 2.972a7.48 7.48 0 01-.601 2.988 7.618 7.618 0 01-1.645 2.44 7.501 7.501 0 01-2.44 1.628c-.92.4-1.91.6-2.971.6zm0-1.574c.849 0 1.639-.153 2.37-.46a6.2 6.2 0 001.927-1.308 6.02 6.02 0 001.309-1.946 5.952 5.952 0 00.477-2.37 5.87 5.87 0 00-.477-2.37 5.824 5.824 0 00-1.309-1.927 5.777 5.777 0 00-1.945-1.308A5.826 5.826 0 008 1.919a5.87 5.87 0 00-2.37.478 6.02 6.02 0 00-1.945 1.308 6.144 6.144 0 00-1.309 1.946 6.02 6.02 0 00-.46 2.352c0 .848.154 1.638.46 2.37a6.42 6.42 0 001.309 1.945 6.42 6.42 0 001.945 1.308 6.15 6.15 0 002.37.46z"
      />
    </svg>
  );
}
