import React, { useRef, useState } from 'react';
import tw from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { SOActivityFormProps } from '../../../model/ShipperOrder.model';
import { Text } from '../../atom';
import { InfoOutlined } from '../../atom/Icon/Icon.atom';
import { BodyFourteen } from '../../atom/Text/Text.atom';
import { CardAction, SOSection, Tooltip } from '../../molecule';
import SOActivityTransitFormItem from '../SOActivityTransitFormItem/SOActivityTransitFormItem.organism';

const HeaderContainer = tw.div`flex justify-between`;
const HeaderAction = tw.div`cursor-pointer flex gap-[5px] items-center`;

interface Props {
  translator: UseTranslator;
  isDefaultFormTransit?: boolean;
  soActivityFormData: SOActivityFormProps;
}
export default function SOActivityTransitForm({
  translator,
  isDefaultFormTransit,
  soActivityFormData,
}: Props) {
  const tooltipRef = useRef(null);

  const [visible, setVisible] = useState(false);
  return (
    <SOSection
      header={
        <HeaderContainer>
          <Text.HeadingFour>{soActivityFormData.title}</Text.HeadingFour>
          {!!soActivityFormData.handleShowActivitySelectionMethod && (
            <HeaderAction
              onClick={soActivityFormData.handleShowActivitySelectionMethod}
            >
              {isDefaultFormTransit && (
                <>
                  <div
                    ref={tooltipRef}
                    onMouseEnter={() => setVisible(true)}
                    onMouseLeave={() => setVisible(false)}
                  >
                    <InfoOutlined width={20} height={20} />
                  </div>
                  <Tooltip
                    targetRef={tooltipRef}
                    visible={visible}
                    variant="light"
                    placement="top-end"
                  >
                    <BodyFourteen>
                      {translator.translate(
                        'The transit form is selected as the default format.',
                      )}
                    </BodyFourteen>
                  </Tooltip>
                </>
              )}
              <Text.HeadingFive tw="text-orange">
                {translator.translate('Choose How to Input')}
              </Text.HeadingFive>
            </HeaderAction>
          )}
        </HeaderContainer>
      }
      footer={
        soActivityFormData.isFooterVisible && (
          <CardAction
            title={soActivityFormData.actionLabel}
            additionalTitle={soActivityFormData.secondaryActionLabel}
            isActionDisabled={soActivityFormData.isActionDisabled}
            isAdditionalActionDisabled={
              soActivityFormData.isSecondaryActionDisabled
            }
            handleAction={soActivityFormData.handleAction}
            handleAdditionalAction={soActivityFormData.handleSecondaryAction}
          />
        )
      }
    >
      <div tw="grid gap-4">
        <SOActivityTransitFormItem
          translator={translator}
          soActivityFormData={soActivityFormData}
        />
      </div>
    </SOSection>
  );
}
