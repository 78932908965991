import * as TabsPrimitive from '@radix-ui/react-tabs';
import * as React from 'react';
import tw, { css } from 'twin.macro';

const Root = TabsPrimitive.Root;

const List = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>((props, ref) => (
  <TabsPrimitive.List
    ref={ref}
    tw="inline-flex items-center justify-center"
    {...props}
  />
));
List.displayName = TabsPrimitive.List.displayName;

const Trigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>((props, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    css={[
      tw`inline-flex items-center justify-center whitespace-nowrap transition-all focus-visible:(outline-none ring-1 ring-offset-1 ring-orange-hover) disabled:(pointer-events-none opacity-50)`,
      css`
        &[data-state='active'] {
          ${tw`border-b-2 border-orange`}
        }
        &[data-state='active'] > * {
          ${tw`text-orange`}
        }
      `,
    ]}
    {...props}
  />
));
Trigger.displayName = TabsPrimitive.Trigger.displayName;

const Content = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>((props, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    tw="animate-fade-in ring-offset-beige-bg focus-visible:(outline-none ring-1 ring-offset-1 ring-beige-lines)"
    {...props}
  />
));
Content.displayName = TabsPrimitive.Content.displayName;

export { Root, List, Trigger, Content };
