import { nanoid } from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ButtonDropdownAction } from '../../component/molecule/ButtonDropdownOutline/ButtonDropdownOutline.molecule';
import { JODetailTab } from '../../constant/JobOrderDetail.constant';
import { JobOrderInfoDelivery } from '../../model/JobOrder.model';
import { logEvent } from '../../service/analytic/analytic.service';
import api from '../../service/api.service';
import { jobOrderTagTypes } from '../../service/endpoint/jobOrder/jobOrder.endpoint';
import { generateArrayUrlParams } from '../../util/helper.util';
import { completeActivityRoute } from '../../view/CompleteActivity/CompleteActivity.route';
import { jobOrderRoute } from '../../view/JobOrder/JobOrder.route';
import { JobOrderDetailRouteParam } from '../../view/JobOrderDetail/JobOrderDetail.route';
import { jobOrderEditRoute } from '../../view/JobOrderEdit/JobOrderEdit.route';
import useEventListener from '../useEventListener.hook';
import { UseJobOrderInfoHookObj } from '../useJobOrderInfo/useJobOrderInfo.hook';
import useToggle from '../useToggle.hook';
import useTranslator from '../useTranslator.hook';

type Props = { jobOrderInfo?: UseJobOrderInfoHookObj; isMobile?: boolean };

const analyticsMapper = {
  [JODetailTab.EVENT_HISTORY]: 'EventHistory',
  [JODetailTab.SO_LIST]: 'ShipperOrderList',
  [JODetailTab.SPENDING_REPORT]: 'SpendingReport',
};

export type UseJODetailControllerHookObj = ReturnType<
  typeof useJODetailController
>;

export default function useJODetailController({
  jobOrderInfo,
  isMobile,
}: Props) {
  // #region GENERAL
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useSearchParams();
  const translator = useTranslator();
  const navigationState = useLocation()
    .state as JobOrderDetailRouteParam | null;
  const navigationStateRef = useRef(navigationState);
  const [isForceCompleteJO, setIsForceCompleteJO] = useState<boolean>(true);
  const [isModalConfirmationShown, toggleModalConfirmation] = useToggle(false);

  const tab =
    (queryParams.get('tab') as JODetailTab | null) ?? JODetailTab.SO_LIST;

  useEffect(() => {
    logEvent(`JobOrder:Detail:ChangeTabTo${analyticsMapper[tab]}`, 'JobOrder');
  }, [tab]);

  const urlSearchParams = useMemo(() => {
    const searchParams = generateArrayUrlParams(
      navigationStateRef.current?.searchParamsFromJOList,
    );
    return new URLSearchParams(searchParams);
  }, []);
  // #endregion

  // #region HANDLERS
  const handleForceCompleteClicked = useCallback(
    (isForceCompleteJobOrder: boolean) => {
      setIsForceCompleteJO(isForceCompleteJobOrder);
      toggleModalConfirmation();
    },
    [toggleModalConfirmation],
  );

  const handleClickBack = useCallback(() => {
    navigate({
      pathname: jobOrderRoute.path,
      search: `?${urlSearchParams.toString()}`,
    });
  }, [navigate, urlSearchParams]);

  const handleClickEditHeader = useCallback(() => {
    if (!jobOrderInfo?.joInfoData) return;

    const pathname = jobOrderEditRoute.path.replace(
      ':id',
      jobOrderInfo?.joInfoData.id,
    );
    navigate(pathname, {
      state: {
        searchParamsFromJODetail: queryParams.toString(),
      },
    });
  }, [jobOrderInfo?.joInfoData, navigate, queryParams]);

  const soForceCompleteActions = useCallback(
    (delivery: JobOrderInfoDelivery): ButtonDropdownAction[] => [
      {
        id: nanoid(),
        action: () => handleForceCompleteClicked(false),
        label: translator.translate('Force Complete Shipper Order'),
      },
      {
        id: nanoid(),
        action: () => {
          if (!jobOrderInfo?.joInfoData) return;
          const params = new URLSearchParams('');

          params.set('joId', jobOrderInfo?.joInfoData.id);
          params.set('joDeliveryId', delivery.id);
          params.set('breadcrumb', jobOrderInfo?.joInfoData.number);

          navigate(
            `${completeActivityRoute.path.replace(
              ':id',
              jobOrderInfo?.joInfoData.id,
            )}?${params.toString()}`,
          );
        },
        label: translator.translate('Force Complete Activity'),
      },
    ],
    [
      handleForceCompleteClicked,
      jobOrderInfo?.joInfoData,
      navigate,
      translator,
    ],
  );

  const handleChangeTabsToSOList = useCallback(() => {
    queryParams.set('tab', 'so-list');
    setQueryParams(queryParams);
  }, [queryParams, setQueryParams]);

  const handleChangeTabsToExpense = useCallback(() => {
    // trigger refetch JO info summary cards
    // this is because expense mutation happens in the mobile app
    dispatch(api.util.invalidateTags([jobOrderTagTypes.getJobOrderInfo]));
    queryParams.set('tab', 'spending-report');
    setQueryParams(queryParams);
  }, [dispatch, queryParams, setQueryParams]);

  const handleChangeTabsToEventHistory = useCallback(() => {
    queryParams.set('tab', 'event-history');
    setQueryParams(queryParams);
  }, [queryParams, setQueryParams]);
  // #endregion

  const handlePopState = useCallback(() => {
    if (!isMobile) return;
    navigate(`${jobOrderRoute.path}?${urlSearchParams.toString()}`, {
      replace: true,
    });
  }, [isMobile, navigate, urlSearchParams]);
  useEventListener('popstate', handlePopState);

  return {
    tab,
    isForceCompleteJO,
    isModalConfirmationShown,
    toggleModalConfirmation,
    handleClickBack,
    handleForceCompleteClicked,
    handleClickEditHeader,
    soForceCompleteActions,
    handleChangeTabsToSOList,
    handleChangeTabsToExpense,
    handleChangeTabsToEventHistory,
  };
}
