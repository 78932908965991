import React, { ReactNode, useCallback } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import routerConfig, { RouteConfig } from '../../config/router/router.config';
import { homeRoute } from '../../view/Home/Home.route';
import { notFoundRoute } from '../../view/NotFound/NotFound.route';

type RequireAuthProps = {
  children: JSX.Element;
  isAuthenticated: boolean;
};

function RequireAuth({ children, isAuthenticated }: RequireAuthProps) {
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to={notFoundRoute.path} state={{ from: location }} />;
  }
  return children;
}

export default function Router() {
  const isAuthenticated = true;

  // biome-ignore lint/correctness/useExhaustiveDependencies: Block-scoped variable 'generateRoute' used before its declaration
  const generateRoute = useCallback(
    (routeConfigs: RouteConfig[]): ReactNode =>
      routeConfigs.map(
        ({ name, path, Component, NormalComponent, isPrivate, options }) => {
          if (!Component) return undefined;

          return (
            <React.Fragment key={name}>
              <Route
                key={name}
                path={path}
                element={
                  <RequireAuth isAuthenticated={!isPrivate || isAuthenticated}>
                    {NormalComponent || <Component />}
                  </RequireAuth>
                }
              />

              {options?.hasSub && generateRoute(options.hasSub)}
            </React.Fragment>
          );
        },
      ),
    [],
  );

  return (
    <Routes>
      {generateRoute(routerConfig)}
      <Route path="*" element={<Navigate to={homeRoute.path} />} />
    </Routes>
  );
}
