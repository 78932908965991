import useTranslator from '../../../hook/useTranslator.hook';
import useImageGalleryController from '../../ImageGallery/hooks/useImageGalleryController.hook';
import useJODetailSpendingApprove from './useJODetailSpendingApprove.hook';
import useJODetailSpendingReportController from './useJODetailSpendingController.hook';
import useJODetailSpendingReject from './useJODetailSpendingReject.hook';
import useJODetailSpendingReportFilter from './useJODetailSpendingReportFilter.hook';
import useJODetailSpendingReportList from './useJODetailSpendingReportList.hook';
import useJODetailSpendingReportTable from './useJODetailSpendingReportTable.hook';

export type UseJODetailSpendingReport = ReturnType<
  typeof useJODetailSpendingReport
>;

export default function useJODetailSpendingReport() {
  const translator = useTranslator();

  const imageGalleryController = useImageGalleryController();
  const spendingReportController = useJODetailSpendingReportController({
    imageGalleryController,
  });
  const spendingReportList = useJODetailSpendingReportList();
  const spendingReportFilter = useJODetailSpendingReportFilter();
  const spendingReportTable = useJODetailSpendingReportTable({
    spendingReportController,
    spendingReportList,
    spendingReportFilter,
  });
  const spendingReportReject = useJODetailSpendingReject({
    spendingReportController,
    spendingReportTable,
  });
  const spendingReportApprove = useJODetailSpendingApprove({
    spendingReportController,
    spendingReportTable,
  });

  return {
    translator,
    imageGalleryController,
    spendingReportController,
    spendingReportList,
    spendingReportFilter,
    spendingReportTable,
    spendingReportReject,
    spendingReportApprove,
  };
}
