// #region IMPORT
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  ActivityTemplateCreateParam,
  ActivityTemplateCreateResponse,
  GetActivityTemplateApiRequest,
  GetActivityTemplateApiResponse,
} from '../../../model/ActivityTemplate.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, MD, QD } from '../../api.baseQuery';
import { ApiResponse } from '../../api.endpoint';
// #endregion

// #region INTERFACES
export type DeleteActivityTemplateApiRequest = {
  id: string;
};

export type ActivityTemplateEndpoint = {
  getActivityTemplateList: QD<
    GetActivityTemplateApiRequest | string,
    GetActivityTemplateApiResponse
  >;
  createActivityTemplate: MD<
    ActivityTemplateCreateParam,
    ActivityTemplateCreateResponse
  >;
  deleteActivityTemplate: MD<DeleteActivityTemplateApiRequest, ApiResponse>;
};
// #endregion

// #region ENDPOINT
export const activityTemplateTagTypes: EndpointTagTypes<ActivityTemplateEndpoint> =
  {
    getActivityTemplateList: 'activityTemplate',
    createActivityTemplate: '',
    deleteActivityTemplate: '',
  };

export const activityTemplateEndpoint = (
  builder: Builder,
): ActivityTemplateEndpoint => ({
  getActivityTemplateList: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/activity-templates.list?${param}`
        : urlcat('/v1/activity-templates.list', toSnakeCase(param)),
    providesTags: (result) =>
      result
        ? [
            ...result.activityTemplates.map(({ id }) => ({
              type: activityTemplateTagTypes.getActivityTemplateList,
              id,
            })),
            activityTemplateTagTypes.getActivityTemplateList,
          ]
        : [activityTemplateTagTypes.getActivityTemplateList],
  }),
  createActivityTemplate: builder.mutation({
    query: (body) => ({
      url: '/v1/activity-templates.create',
      method: 'post',
      body,
    }),
    invalidatesTags: [activityTemplateTagTypes.getActivityTemplateList],
  }),
  deleteActivityTemplate: builder.mutation({
    query: (body) => ({
      url: '/v1/activity-templates.delete',
      method: 'post',
      body,
    }),
    invalidatesTags: [activityTemplateTagTypes.getActivityTemplateList],
  }),
});
// #endregion
