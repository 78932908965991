import { HaulingOrderStatusEnum } from '../../model/HaulingOrder.model';
import { TimelineType } from '../../model/ShipperOrder.model';
import { SOStatus } from '../ShipperOrder.constant';

// #region INTERFACE
export type TrackingFormInitialValues = { receipt: string };
// #endregion

export const HOTrackingRegex = /^(HTRCK-)+/i;

export const trackingFormInitialValues: TrackingFormInitialValues = {
  receipt: '',
};

export const soTrackingStages = [
  { label: 'In Progress', soStatus: [SOStatus.IN_PROCESS, SOStatus.ASSIGNED] },
  { label: 'In Transit', soStatus: [SOStatus.IN_TRANSIT, SOStatus.TRANSITING] },
  { label: 'Delivering', soStatus: [SOStatus.DELIVERING] },
  { label: 'Delivered', soStatus: [SOStatus.DELIVERED] },
];

export const hoTrackingStages = [
  {
    label: 'In Progress',
    status: [
      HaulingOrderStatusEnum.IN_PROCESS,
      HaulingOrderStatusEnum.ASSIGNED,
    ],
  },
  { label: 'Delivering', status: [HaulingOrderStatusEnum.DELIVERING] },
  { label: 'Delivered', status: [HaulingOrderStatusEnum.DELIVERED] },
];

export const soTrackingTimelineLocationType = [
  TimelineType.DROP_OFF,
  TimelineType.STAND_BY,
  TimelineType.PICK_UP,
  TimelineType.STAND_BY_COMPLETED,
  TimelineType.PICK_UP_COMPLETED,
  TimelineType.DROP_OFF_STARTED,
  TimelineType.DROP_OFF_COMPLETED,
  TimelineType.IN_TRANSIT,
  TimelineType.TRANSIT_ACTIVITY_COMPLETED,
  TimelineType.TRANSIT_ACTIVITY_STARTED,
  TimelineType.TRANSITED,
  TimelineType.TRANSITING,
  TimelineType.PICK_UP_STARTED,
  TimelineType.STAND_BY_STARTED,
  TimelineType.GEOFENCE_ENTER,
  TimelineType.GEOFENCE_EXIT,
];
