import fromUnixTime from 'date-fns/fromUnixTime';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import { RedirectUrlTarget, SOActivityType } from '../../../constant';
import { JO_INFO_SO_ACTIVITY_TABLE_HEADERS } from '../../../constant/JobOrder.constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { Photo } from '../../../model/Common.model';
import { JOActivityInfo } from '../../../model/JobOrder.model';
import { ShipperOrderInfo } from '../../../model/ShipperOrder.model';
import { getDateFormat } from '../../../util/date.util';
import {
  getJODetailTravelExpense,
  getJODetailVehicle,
  mapJODetailSOActivityTypeToLabel,
  mapJOStatusToLabel,
} from '../../../util/jobOrder.util';
import { jobOrderDetailRoute } from '../../../view/JobOrderDetail/JobOrderDetail.route';
import { Text } from '../../atom';
import { JODetailSOActivityItem } from '../../molecule';
import JODetailHeader from '../JODetailHeader/JODetailHeader.organism';

const Container = tw.div`w-[720px] mx-auto pb-5`;

type Props = {
  translator: UseTranslator;
  soInfo?: ShipperOrderInfo;
  selectedJOId?: string;
  onImagesClick(photos: Photo[], index: number, activityindex: number): void;
  onDownloadClick(photos: Photo[], fileName: string): void;
};

export default function SODJobOrder({
  translator,
  selectedJOId,
  soInfo,
  onImagesClick,
  onDownloadClick,
}: Props) {
  const selectedDeliveries = useMemo(() => {
    if (!soInfo || !selectedJOId) return undefined;
    return soInfo.deliveries.find(
      ({ jobOrder }) => jobOrder.id === selectedJOId,
    );
  }, [selectedJOId, soInfo]);

  const formattedActivities = useMemo(() => {
    if (!soInfo || !selectedDeliveries?.activities.length) return [];
    return selectedDeliveries.activities.map((activity, index) => {
      const selectedDelivery = soInfo.activities.find(
        (item) => item.id === activity.soActivityId,
      );

      if (!soInfo.isTransitable) {
        return {
          ...activity,
          ...selectedDelivery,
          soActivity: {
            index,
            type:
              index === 0 ? SOActivityType.PICK_UP : SOActivityType.DROP_OFF,
            goods: selectedDelivery?.goods || [],
          },
        };
      }

      const activityLocation = selectedDelivery?.location;
      const formattedActivity: JOActivityInfo = {
        ...activity,
        events: activity.events,
        location: activity.location || activityLocation,
        soActivity: {
          index,
          type: index === 0 ? SOActivityType.PICK_UP : SOActivityType.DROP_OFF,
          goods: soInfo.activities[0].goods,
        },
      };
      return formattedActivity;
    });
  }, [selectedDeliveries?.activities, soInfo]);

  if (!soInfo || !selectedDeliveries) {
    return (
      <Container>
        {translator.translate(
          'Currently shipper order information is not available',
        )}
      </Container>
    );
  }

  return (
    <Container>
      <JODetailHeader
        key={selectedDeliveries.id}
        number={
          <Link
            to={`${jobOrderDetailRoute.path.replace(
              ':id',
              selectedDeliveries.jobOrder.id,
            )}`}
            target={RedirectUrlTarget.BLANK}
          >
            <Text.HeadingFour tw="ml-2.5 text-orange cursor-pointer">
              {selectedDeliveries.jobOrder.number}
            </Text.HeadingFour>
          </Link>
        }
        status={selectedDeliveries.jobOrder.status}
        translator={translator}
        statusLabel={translator.translate(
          mapJOStatusToLabel(selectedDeliveries.jobOrder.status),
        )}
        singleHeaderColumn
        headerContentItems={[
          {
            label: translator.translate('Job Order Date'),
            value: getDateFormat(
              fromUnixTime(selectedDeliveries.jobOrder.date),
            ),
          },
          {
            label: translator.translate('Driver'),
            value: selectedDeliveries?.jobOrder?.driver?.fullName || '-',
          },
          {
            label: translator.translate('Travel Budget'),
            value: getJODetailTravelExpense(
              selectedDeliveries.jobOrder.travelExpense,
            ),
          },
          {
            label: translator.translate('Seal Number'),
            value: selectedDeliveries.jobOrder.sealNumber || '-',
          },
          {
            label: translator.translate('Vehicle'),
            value: selectedDeliveries.jobOrder.vehicle
              ? getJODetailVehicle(selectedDeliveries.jobOrder.vehicle)
              : '-',
          },
        ]}
      />
      <div tw="flex flex-col shadow-card rounded-md bg-white mt-4">
        <div tw="flex flex-row justify-between items-center w-full p-4 rounded-t-md border-b-2 border-b-beige-lines">
          <div tw="flex flex-row justify-between items-center">
            <Text.HeadingFour tw="ml-2.5">
              {translator.translate('Shipper Order Activity Detail')}
            </Text.HeadingFour>
          </div>
        </div>
        <div tw="p-5">
          {formattedActivities.map((activity, index) => (
            <div key={activity.id} tw="mb-8">
              <JODetailSOActivityItem
                onDownloadClick={(photos) =>
                  onDownloadClick(
                    photos,
                    `${soInfo.number} - activity-${index + 1}`,
                  )
                }
                onImagesClick={onImagesClick}
                translator={translator}
                index={index + 1}
                activity={activity}
                activityTypeLabel={mapJODetailSOActivityTypeToLabel({
                  activityType: activity?.type || activity.soActivity?.type,
                  translator,
                })}
                activityTableHeaders={JO_INFO_SO_ACTIVITY_TABLE_HEADERS}
              />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}
