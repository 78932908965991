/**
 * Remove gsid on url params
 * @param params
 * @returns
 */
import { activityTemplateRoute } from '../view/ActivityTemplate/activityTemplate.route';
import { activityTemplateAddRoute } from '../view/ActivityTemplateAdd/ActivityTemplateAdd.route';
import { deliveryLocationRoute } from '../view/DeliveryLocation/deliveryLocation.route';
import { deliveryLocationAddRoute } from '../view/DeliveryLocationAdd/DeliveryLocationAdd.route';
import { deliveryLocationEditRoute } from '../view/DeliveryLocationEdit/DeliveryLocationEdit.route';
import { driverRoute } from '../view/Driver/driver.route';
import { driverAddRoute } from '../view/DriverAdd/DriverAdd.route';
import { haulingOrderRoute } from '../view/HaulingOrder/HaulingOrder.route';
import { haulingOrderCreateRoute } from '../view/HaulingOrderCreate/HaulingOrderCreate.route';
import { homeRoute } from '../view/Home/Home.route';
import { jobOrderRoute } from '../view/JobOrder/JobOrder.route';
import { jobOrderCreateRoute } from '../view/JobOrderCreate/JobOrderCreate.route';
import { jobOrderDetailRoute } from '../view/JobOrderDetail/JobOrderDetail.route';
import { notFoundRoute } from '../view/NotFound/NotFound.route';
import { reportDrivingBehaviorByJobOrderRoute } from '../view/Report/DrivingBehavior/page/JobOrder/reportDrivingBehaviorByJobOrder.route';
import { reportDrivingBehaviorByScheduleRoute } from '../view/Report/DrivingBehavior/page/Schedule/reportDrivingBehaviorBySchedule.route';
import { reportDrivingBehaviorByVehicleRoute } from '../view/Report/DrivingBehavior/page/Vehicle/reportDrivingBehaviorByVehicle.route';
import { reportJobOrderRoute } from '../view/Report/JobOrder/reportJobOrder.route';
import { reportRoute } from '../view/Report/report.route';
import { reportRevenueRoute } from '../view/Report/Revenue/reportRevenue.route';
import { reportShipperOrderRoute } from '../view/Report/ShipperOrder/reportShipperOrder.route';
import { scheduleRoute } from '../view/Schedule/Schedule.route';
import { scheduleAddRoute } from '../view/ScheduleAdd/ScheduleAdd.route';
import { shipperRoute } from '../view/Shipper/shipper.route';
import { shipperAddRoute } from '../view/ShipperAdd/ShipperAdd.route';
import { shipperOrderRoute } from '../view/ShipperOrder/ShipperOrder.route';
import { createShipperOrderRoute } from '../view/ShipperOrderCreate/ShipperOrderCreate.route';

export const removeGSIDInParam = (params: string) =>
  `?${params
    .slice(1)
    .split('&')
    .filter((v) => !v.includes('gsid'))
    .join('&')}`;

/**
 * Parse location path to document title
 * @param key
 * @returns
 */
export const routeToDocumentTitle = (key: string) => {
  const routes: Record<string, string> = {
    [homeRoute.path]: homeRoute.name,
    [haulingOrderRoute.path]: haulingOrderRoute.name,
    [haulingOrderCreateRoute.path]: haulingOrderCreateRoute.name,
    [notFoundRoute.path]: notFoundRoute.name,
    [shipperOrderRoute.path]: shipperOrderRoute.name,
    [jobOrderRoute.path]: jobOrderRoute.name,
    [jobOrderCreateRoute.path]: jobOrderCreateRoute.name,
    [jobOrderDetailRoute.path]: jobOrderDetailRoute.name,
    [createShipperOrderRoute.path]: createShipperOrderRoute.name,
    [deliveryLocationRoute.path]: deliveryLocationRoute.name,
    [deliveryLocationAddRoute.path]: deliveryLocationAddRoute.name,
    [deliveryLocationEditRoute.path]: deliveryLocationEditRoute.name,
    [driverAddRoute.path]: driverAddRoute.name,
    [shipperAddRoute.path]: shipperAddRoute.name,
    [shipperRoute.path]: shipperRoute.name,
    [driverRoute.path]: driverRoute.name,
    [reportRoute.path]: reportRoute.name,
    [activityTemplateRoute.path]: activityTemplateRoute.name,
    [activityTemplateAddRoute.path]: activityTemplateAddRoute.name,
    [scheduleRoute.path]: scheduleRoute.name,
    [scheduleAddRoute.path]: scheduleAddRoute.name,
    [reportDrivingBehaviorByVehicleRoute.path]:
      reportDrivingBehaviorByVehicleRoute.name,
    [reportDrivingBehaviorByScheduleRoute.path]:
      reportDrivingBehaviorByScheduleRoute.name,
    [reportShipperOrderRoute.path]: reportShipperOrderRoute.name,
    [reportJobOrderRoute.path]: reportJobOrderRoute.name,
    [reportRevenueRoute.path]: reportRevenueRoute.name,
    [reportDrivingBehaviorByJobOrderRoute.path]:
      reportDrivingBehaviorByJobOrderRoute.name,
  };

  return routes[key] || 'Job Order Management';
};
