import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import { SOActivityType } from '../../constant';
import { JOFormDeliveryLocationModalSelectedRadio } from '../../constant/JobOrder.constant';
import { LocationType } from '../../constant/Location.constant';
import { JobOrderForm } from '../../model/JobOrder.model';
import { Location } from '../../model/Location.model';
import {
  ShipperOrder,
  ShipperOrderUnassigned,
} from '../../model/ShipperOrder.model';
import api from '../../service/api.service';
import { navigationParamAction } from '../../store/param.store';
import { joCreateLocationAddRoute } from '../../view/JOCreateLocationAdd/JOCreateLocationAdd.route';
import { joEditDraftLocationAddRoute } from '../../view/JOEditDraftLocationAdd/JOEditDraftLocationAdd.route';
import useDebounce from '../useDebounce.hook';
import useTranslator from '../useTranslator.hook';
import { UseJOFormControllerHookObj } from './useJOFormController.hook';

// #region INTERFACES
interface Props {
  joId?: string;
  joNumber?: string;
  values: JobOrderForm;
  controller: UseJOFormControllerHookObj;
  hasUnsavedChanges?: boolean;
  setHasUnsavedChanges: (val: boolean, callback?: () => void) => void;
}
export type UseJOFormDeliveryLocationModalHookObj = ReturnType<
  typeof useJOFormDeliveryLocationModal
>;
// #endregion

const NumberContainer = tw.p`px-1 ml-2 bg-white text-orange rounded-md min-width[25px]`;

export default function useJOFormDeliveryLocationModal({
  joId,
  joNumber,
  values,
  hasUnsavedChanges,
  controller: {
    formStep,
    soController: {
      selectedSO,
      selectionSOList,
      setSelectionSOList,
      handleClearSelectedSO,
    },
  },
  setHasUnsavedChanges,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translator = useTranslator();

  // #region VALUES
  const [showDeliveryLocationModal, setShowDeliveryLocationModal] =
    useState(false);
  const [selectedSOFromRow, setSelectedSOFromRow] =
    useState<ShipperOrderUnassigned>();
  const [selectedRadio, setSelectedRadio] =
    useState<JOFormDeliveryLocationModalSelectedRadio>(
      JOFormDeliveryLocationModalSelectedRadio.SEARCH,
    );
  const [selectedDeliveryLocation, setSelectedDeliveryLocation] =
    useState<Location>();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [pageSize, setPageSize] = useState(10);
  const debouncedPageSize = useDebounce(pageSize, 500);

  // this is necessary because we need to query multiple `type[]` params
  const searchParams = useMemo(() => {
    const params = new URLSearchParams('');
    params.set('page_size', debouncedPageSize.toString());

    for (const v of [LocationType.PUBLIC, LocationType.TRANSPORTER]) {
      params.append('type[]', String(v));
    }

    if (debouncedSearch) params.set('search', debouncedSearch);

    return params.toString();
  }, [debouncedPageSize, debouncedSearch]);
  const locationsQuery = api.useGetLocationsQuery(searchParams, {
    skip: !showDeliveryLocationModal,
    selectFromResult: ({ data, ...rest }) => ({
      list: data?.locations ?? [],
      metadata: data?.metadata,
      ...rest,
    }),
  });

  const isAddNewLocationVisible = useMemo(
    () => selectedRadio === JOFormDeliveryLocationModalSelectedRadio.SEARCH,
    [selectedRadio],
  );

  // list of activities with type DROP_OFF
  const dropOffs = useMemo(
    () =>
      selectedSOFromRow
        ? // for single selection
          selectedSOFromRow.activities.filter(
            (_activity) => _activity.type === SOActivityType.DROP_OFF,
          )
        : // for multi selection
          selectedSO
            .flatMap((_so) => _so.activities)
            .filter((_activity) => _activity.type === SOActivityType.DROP_OFF),
    [selectedSO, selectedSOFromRow],
  );

  // for multi selection, show info list of last mile in selected SO list
  const lastMileSelectedSOList = useMemo(
    () => selectedSO.filter((so) => !so.isTransitable),
    [selectedSO],
  );
  // show info list of transit in selected SO list
  const transitSelectedSOList = useMemo(
    () => selectedSO.filter((so) => so.isTransitable),
    [selectedSO],
  );

  // get assigned SO list based on `isTransitable` or selected transit location defined by `deliveryLocation` key
  const assignedLastMileList = useMemo(
    () =>
      selectionSOList.filter((so) => !so.isTransitable || so.deliveryLocation),
    [selectionSOList],
  );

  // list of SO that the selected `deliveryLocation` equals to current location
  const deliveryLocationSameAsCurrentLocationList = useMemo(
    () =>
      selectionSOList
        .filter((so) => so.isTransitable)
        .filter((_so) =>
          _so.transitLocation
            ? // for so transit that has transitted before -> compare _so.deliveryLocation to transit location
              _so.transitLocation.id === _so.deliveryLocation?.id
            : // for so transit that never transitted -> compare _so.deliveryLocation to pickup location
              !!_so.activities
                .filter(
                  (_activity) => _activity.type === SOActivityType.PICK_UP,
                )
                .find(
                  (_activity) =>
                    _activity?.location.id === _so.deliveryLocation?.id,
                ),
        ),
    [selectionSOList],
  );

  const isSelectedRadioSearch =
    selectedRadio === JOFormDeliveryLocationModalSelectedRadio.SEARCH;
  const isSelectedRadioLastMile =
    selectedRadio === JOFormDeliveryLocationModalSelectedRadio.LAST_MILE;

  const modalTitle = translator.translate('Update Delivery Location');
  const modalLastMileLabel = translator.translate(
    'Delivery location are disabled for Last Mile SO below :',
  );
  const modalSearchPlaceholder = translator.translate(
    'Location Name or Address',
  );
  const toLabel = translator.translate('To');
  const radioSearchTitleLabel = translator.translate('Search Transit Location');
  const radioSearchSubitleLabel = translator.translate(
    'Set selected SO to a transit location',
  );
  const radioLastMileTitleLabel = translator.translate('Last Mile Location');
  const radioLastMileSubitleLabel = translator.translate(
    'Set selected SO to the final destination',
  );
  const addLocationLabel = translator.translate('Add New Location');
  // #endregion

  // #region HANDLERS
  const handleOpenDeliveryLocationModal = useCallback(() => {
    setShowDeliveryLocationModal(true);
  }, []);
  const handleCloseDeliveryLocationModalAndClearSelectedSO = useCallback(() => {
    setShowDeliveryLocationModal(false);
    setSelectedSOFromRow(undefined);
    setSelectedDeliveryLocation(undefined);
    setSearch('');
  }, []);

  const handleSelectSO = useCallback((_so: ShipperOrder) => {
    setSelectedSOFromRow(_so);
  }, []);

  const handleSubmitChangeLocation = useCallback(() => {
    if (selectedSOFromRow) {
      // for single selection
      // if selected radio is search, `selectedDeliveryLocation` is guaranteed NonNullable<Location>
      const deliveryLocation = isSelectedRadioSearch
        ? (selectedDeliveryLocation as Location)
        : dropOffs[0].location; // `pickUps` & `dropOffs` always consists of 1 item

      setSelectionSOList((prev) =>
        prev.map(
          (so) =>
            so.id === selectedSOFromRow.id
              ? {
                  ...so,
                  isResolved: true,
                  deliveryLocation,
                }
              : so, // for unaffected SO, return original data
        ),
      );
    } else {
      // for multi selections
      setSelectionSOList((prev) =>
        prev.map((_selectionSO) => {
          // apply state changes only to selected transitable SO
          const applicableTransitSO = transitSelectedSOList.find(
            (_so) => _so.id === _selectionSO.id,
          );

          // if selected radio is search, `selectedDeliveryLocation` is guaranteed NonNullable<Location>
          const deliveryLocation = isSelectedRadioSearch
            ? (selectedDeliveryLocation as Location)
            : _selectionSO.activities.filter(
                (_activity) => _activity.type === SOActivityType.DROP_OFF,
              )[0]?.location ?? undefined; // dropoff always consists of 1 item

          return applicableTransitSO
            ? {
                ..._selectionSO,
                deliveryLocation,
              }
            : _selectionSO; // for unaffected SO, return original data
        }),
      );
    }

    handleCloseDeliveryLocationModalAndClearSelectedSO();
    handleClearSelectedSO();
  }, [
    dropOffs,
    handleClearSelectedSO,
    handleCloseDeliveryLocationModalAndClearSelectedSO,
    isSelectedRadioSearch,
    selectedDeliveryLocation,
    selectedSOFromRow,
    setSelectionSOList,
    transitSelectedSOList,
  ]);

  const handleSearchLocation = useCallback((_search: string) => {
    setSearch(_search);
  }, []);

  const handleFetchMoreLocations: React.UIEventHandler<HTMLElement> =
    useCallback(
      (ev) => {
        const containerRefElement = ev.target as HTMLDivElement;

        if (containerRefElement) {
          const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

          if (
            scrollHeight - (scrollTop + clientHeight) < 200 &&
            locationsQuery.metadata &&
            locationsQuery.metadata.totalCount > pageSize &&
            debouncedPageSize === pageSize &&
            !locationsQuery.isFetching
          ) {
            setPageSize((prev) => prev * 2);
          }
        }
      },
      [
        locationsQuery.metadata,
        locationsQuery.isFetching,
        pageSize,
        debouncedPageSize,
      ],
    );

  const handleSelectDeliveryLocation = useCallback((location: Location) => {
    setSelectedDeliveryLocation(location);
  }, []);

  const handleChangeRadio = useCallback(
    (input: JOFormDeliveryLocationModalSelectedRadio) => {
      setSelectedRadio(input);
    },
    [],
  );
  const handleRedirect = useCallback(() => {
    setShowDeliveryLocationModal(false);
    const path = joId
      ? joEditDraftLocationAddRoute.path.replace(':id', joId)
      : joCreateLocationAddRoute.path;
    navigate(path);
    const checkedSO = selectedSOFromRow
      ? [selectedSOFromRow.id]
      : selectedSO.map((item) => item.id);
    dispatch(
      navigationParamAction.changeJobOrderLocationAddParams({
        id: joId,
        joNumber,
        formStep,
        checkedSO,
        selectedSO: selectionSOList,
        formValues: values,
      }),
    );
  }, [
    joId,
    joNumber,
    formStep,
    values,
    selectedSO,
    selectionSOList,
    selectedSOFromRow,
    navigate,
    dispatch,
  ]);

  const handleAddNewLocation = useCallback(() => {
    if (!hasUnsavedChanges) {
      handleRedirect();
      return;
    }
    setHasUnsavedChanges(false, () => {
      handleRedirect();
    });
  }, [hasUnsavedChanges, handleRedirect, setHasUnsavedChanges]);
  // #endregion

  const modalCallAction = useMemo(
    () => ({
      label: (
        <>
          {translator.translate('Change Location')}

          <NumberContainer
            css={[
              isSelectedRadioSearch &&
                !selectedDeliveryLocation &&
                tw`text-grey-two`,
            ]}
          >
            {
              selectedSOFromRow
                ? 1 // single selection
                : selectedSO.length - lastMileSelectedSOList.length // multi selection
            }
          </NumberContainer>
        </>
      ),
      disabled: isSelectedRadioSearch && !selectedDeliveryLocation,
      action: handleSubmitChangeLocation,
    }),
    [
      translator,
      isSelectedRadioSearch,
      selectedDeliveryLocation,
      selectedSOFromRow,
      selectedSO.length,
      lastMileSelectedSOList.length,
      handleSubmitChangeLocation,
    ],
  );

  return {
    locationsQuery,
    search,
    dropOffs,
    selectedSOFromRow,
    selectedDeliveryLocation,
    selectedRadio,
    lastMileSelectedSOList,
    transitSelectedSOList,
    assignedLastMileList,
    showDeliveryLocationModal,
    modalTitle,
    modalLastMileLabel,
    modalSearchPlaceholder,
    modalCallAction,
    toLabel,
    isSelectedRadioSearch,
    isSelectedRadioLastMile,
    radioSearchTitleLabel,
    radioSearchSubitleLabel,
    radioLastMileTitleLabel,
    radioLastMileSubitleLabel,
    addLocationLabel,
    isAddNewLocationVisible,
    deliveryLocationSameAsCurrentLocationList,
    handleOpenDeliveryLocationModal,
    handleCloseDeliveryLocationModalAndClearSelectedSO,
    handleSelectDeliveryLocation,
    handleSearchLocation,
    handleFetchMoreLocations,
    handleSelectSO,
    handleAddNewLocation,
    handleChangeRadio,
  };
}
