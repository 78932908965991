import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterDataManagementScheduleRouteParam = DefaultRouteParam;

export const helpCenterDataManagementScheduleRoute: RouteConfig = {
  name: 'Help Center Data Management Schedule',
  Component: React.lazy(
    () => import('./HelpCenterDataManagementSchedule.view'),
  ),
  path: '/help-center/data-management/schedule',
  isPrivate: false,
  breadcrumb: 'Help Center Data Management Schedule',
  options: {
    label: 'Penjadwalan',
    testID: 'MenuHelpCenterDataManagementSchedule',
  },
};
