import React from 'react';

export default function OnboardingShipperOrder({
  fill = 'none',
  width = 138,
  height = 138,
  viewBox = '0 0 138 138',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#EBEBEB" d="M35.415 81.506H11.25v18.638h24.165V81.506z" />
      <path
        fill="#E0E0E0"
        d="M29.36 100.011c-.027 0-.049-4.152-.049-9.277 0-5.124.022-9.279.05-9.279.027 0 .048 4.155.048 9.28 0 5.124-.022 9.276-.048 9.276z"
      />
      <path fill="#FAFAFA" d="M33.367 95.9h-1.853v2.095h1.853V95.9z" />
      <path
        fill="#E0E0E0"
        d="M21.368 81.554h-2.745v3.976h2.745v-3.976zM21.368 96.11h-2.745v3.977h2.745V96.11z"
      />
      <path fill="#EBEBEB" d="M39.194 62.911H15.029V81.55h24.165V62.91z" />
      <path
        fill="#E0E0E0"
        d="M33.139 81.416c-.026 0-.048-4.153-.048-9.277s.022-9.277.048-9.277c.025 0 .047 4.153.047 9.277s-.02 9.277-.047 9.277z"
      />
      <path fill="#FAFAFA" d="M37.144 77.306h-1.852V79.4h1.852v-2.094z" />
      <path
        fill="#E0E0E0"
        d="M25.146 62.959H22.4v3.976h2.746V62.96zM25.146 77.517H22.4v3.976h2.746v-3.976z"
      />
      <path fill="#F5F5F5" d="M37.532 47.974h-19.43V62.96h19.43V47.974z" />
      <path
        fill="#E0E0E0"
        d="M32.664 62.854c-.02 0-.039-3.341-.039-7.461s.018-7.46.039-7.46c.02 0 .038 3.34.038 7.46s-.016 7.461-.038 7.461z"
      />
      <path fill="#FAFAFA" d="M35.885 59.548h-1.49v1.683h1.49v-1.683z" />
      <path
        fill="#E0E0E0"
        d="M26.236 48.013H24.03v3.198h2.206v-3.198zM26.236 59.717H24.03v3.198h2.206v-3.198z"
      />
      <path fill="#F5F5F5" d="M124.266 58.377h-18.251v14.076h18.251V58.377z" />
      <path
        fill="#E0E0E0"
        d="M119.694 72.356c-.02 0-.037-3.139-.037-7.008 0-3.87.017-7.007.037-7.007.02 0 .035 3.137.035 7.006 0 3.87-.015 7.01-.035 7.01z"
      />
      <path fill="#FAFAFA" d="M122.717 69.25h-1.398v1.582h1.398V69.25z" />
      <path
        fill="#E0E0E0"
        d="M113.657 58.415h-2.074v3.002h2.074v-3.002zM113.657 69.41h-2.074v3.003h2.074V69.41z"
      />
      <path fill="#EBEBEB" d="M130.383 88.638h-21.035v16.225h21.035V88.638z" />
      <path
        fill="#E0E0E0"
        d="M125.12 104.748c-.022 0-.04-3.616-.04-8.076 0-4.46.018-8.078.04-8.078.022 0 .042 3.616.042 8.078s-.025 8.076-.042 8.076z"
      />
      <path fill="#FAFAFA" d="M128.6 101.168h-1.612v1.824h1.612v-1.824z" />
      <path
        fill="#E0E0E0"
        d="M118.156 88.679h-2.39v3.46h2.39v-3.46zM118.156 101.353h-2.39v3.461h2.39v-3.461z"
      />
      <path fill="#EBEBEB" d="M127.477 72.456h-21.035V88.68h21.035V72.456z" />
      <path
        fill="#E0E0E0"
        d="M122.207 88.564c-.024 0-.042-3.615-.042-8.075 0-4.46.018-8.078.042-8.078.024 0 .043 3.616.043 8.078s-.019 8.075-.043 8.075z"
      />
      <path fill="#FAFAFA" d="M125.694 84.986h-1.611v1.823h1.611v-1.823z" />
      <path
        fill="#E0E0E0"
        d="M115.251 72.496h-2.391v3.461h2.391v-3.46zM115.251 85.17h-2.391v3.461h2.391V85.17z"
      />
      <path fill="#F5F5F5" d="M88.683 58.604H70.432V72.68h18.25V58.604z" />
      <path
        fill="#E0E0E0"
        d="M84.11 72.582c-.02 0-.035-3.139-.035-7.008 0-3.87.015-7.007.035-7.007.02 0 .037 3.128.037 7.007 0 3.878-.017 7.008-.037 7.008z"
      />
      <path fill="#FAFAFA" d="M87.135 69.477h-1.398v1.582h1.398v-1.582z" />
      <path
        fill="#E0E0E0"
        d="M78.073 58.64H76v3.004h2.073V58.64zM78.073 69.637H76v3.003h2.073v-3.003z"
      />
      <path fill="#EBEBEB" d="M90.302 44.565H72.05V58.64H90.3V44.565z" />
      <path
        fill="#E0E0E0"
        d="M85.73 58.541c-.021 0-.038-3.128-.038-7.007 0-3.878.017-7.008.037-7.008.02 0 .037 3.128.037 7.008 0 3.881-.022 7.007-.037 7.007z"
      />
      <path fill="#FAFAFA" d="M88.754 55.437h-1.398v1.582h1.398v-1.582z" />
      <path
        fill="#E0E0E0"
        d="M79.692 44.6H77.62v3.002h2.073V44.6zM79.692 55.596H77.62v3.003h2.073v-3.003z"
      />
      <path fill="#EBEBEB" d="M89.617 34.794H76.56v10.073h13.058V34.794z" />
      <path
        fill="#E0E0E0"
        d="M86.347 44.797c-.014 0-.025-2.245-.025-5.014 0-2.77.01-5.007.025-5.007.015 0 .026 2.245.026 5.014 0 2.77-.013 5.007-.026 5.007z"
      />
      <path fill="#FAFAFA" d="M88.511 42.576h-1v1.131h1v-1.131z" />
      <path
        fill="#E0E0E0"
        d="M82.028 34.822h-1.484v2.149h1.484v-2.15zM82.028 42.688h-1.484v2.15h1.484v-2.15z"
      />
      <path fill="#EBEBEB" d="M94.8 88.865H73.767v16.225H94.8V88.865z" />
      <path
        fill="#E0E0E0"
        d="M89.53 104.974c-.023 0-.042-3.616-.042-8.076 0-4.46.019-8.078.043-8.078.023 0 .04 3.616.04 8.078s-.018 8.076-.04 8.076z"
      />
      <path fill="#FAFAFA" d="M93.016 101.395h-1.612v1.823h1.612v-1.823z" />
      <path
        fill="#E0E0E0"
        d="M82.572 88.905h-2.39v3.46h2.39v-3.46zM82.572 101.579h-2.39v3.461h2.39v-3.461z"
      />
      <path fill="#EBEBEB" d="M91.893 72.682H70.858v16.225h21.035V72.682z" />
      <path
        fill="#E0E0E0"
        d="M86.625 88.791c-.024 0-.042-3.615-.042-8.076 0-4.46.018-8.077.042-8.077.024 0 .04 3.615.04 8.077s-.018 8.076-.04 8.076z"
      />
      <path fill="#FAFAFA" d="M90.11 85.212H88.5v1.823h1.611v-1.823z" />
      <path
        fill="#E0E0E0"
        d="M79.666 72.722h-2.39v3.461h2.39v-3.46zM79.666 85.396h-2.39v3.461h2.39v-3.461zM46.147 101.875v-1.731h-35.42a.471.471 0 00-.473.472v.786a.47.47 0 00.473.473h.986v.797a.467.467 0 01-.465.465h-.528a.466.466 0 00-.466.455v.8a.464.464 0 00.466.466h35.427v-1.732h-8.256v-1.255l8.256.004zm-25.944 0h2.124v1.255h-2.124v-1.255zm-1.415 1.255h-2.121v-1.255h2.121v1.255zm4.948-1.255h2.122v1.255h-2.122v-1.255zm3.537 0h2.123v1.255h-2.118l-.005-1.255zm3.538 0h2.125v1.255h-2.12l-.005-1.255zm-17.688 0h2.129v1.255h-2.123l-.006-1.255zm23.348 1.255h-2.118v-1.255h2.123l-.005 1.255z"
      />
      <path fill="#F3532E" d="M44.953 65.78H25.784v14.787h19.17V65.78z" />
      <path
        fill="#000"
        d="M44.953 65.78H25.784v14.787h19.17V65.78z"
        opacity="0.3"
      />
      <path
        fill="#263238"
        d="M40.15 80.462c-.016 0-.03-3.296-.03-7.36 0-4.065.014-7.36.03-7.36.017 0 .032 3.295.032 7.36 0 4.064-.015 7.36-.031 7.36z"
      />
      <path fill="#F5F5F5" d="M43.329 77.201h-1.47v1.662h1.47V77.2z" />
      <path
        fill="#263238"
        d="M33.809 65.819h-2.177v3.155h2.177V65.82zM33.809 77.367h-2.177v3.155h2.177v-3.155zM44.787 65.78H44.042l-2.037.01H28.567l-2.034-.012 2.035-.009h13.437l2.037.01H44.738l.05.001z"
      />
      <path fill="#F3532E" d="M60.912 65.762h-19.17v14.786h19.17V65.762z" />
      <path
        fill="#263238"
        d="M56.11 80.443c-.017 0-.032-3.295-.032-7.36 0-4.064.015-7.36.031-7.36.017 0 .032 3.294.032 7.36 0 4.067-.02 7.36-.032 7.36z"
      />
      <path fill="#F5F5F5" d="M59.287 77.183h-1.47v1.661h1.47v-1.661z" />
      <path
        fill="#fff"
        d="M49.767 65.8H47.59v3.156h2.177V65.8zM49.767 77.348H47.59v3.156h2.177v-3.156z"
        opacity="0.5"
      />
      <path
        fill="#263238"
        d="M60.696 65.762H60l-2.035.01H44.526l-2.037-.01 2.037-.011 6.72-.01 6.718.01 2.035.01H60.696z"
      />
      <path fill="#F3532E" d="M61.988 50.976h-19.17v14.786h19.17V50.976z" />
      <path
        fill="#263238"
        d="M57.186 65.657c-.019 0-.032-3.295-.032-7.36 0-4.064.013-7.36.032-7.36.018 0 .03 3.294.03 7.36 0 4.066-.014 7.36-.03 7.36z"
      />
      <path fill="#F5F5F5" d="M60.363 62.397h-1.47v1.661h1.47v-1.662z" />
      <path
        fill="#263238"
        d="M50.843 51.014h-2.177v3.156h2.177v-3.156zM50.843 62.562h-2.177v3.156h2.177v-3.156zM61.824 50.968a.143.143 0 01-.048 0h-2.732l-6.718.01-6.72-.01h-2.734a.143.143 0 01-.048 0H43.57l2.037-.011H59.044l2.035.011H61.824z"
      />
      <path
        fill="#455A64"
        d="M59.938 97.029v-1.732H24.52a.474.474 0 00-.475.473v.786a.475.475 0 00.475.473h.986v.787a.468.468 0 01-.467.468h-.526a.468.468 0 00-.468.465v.795a.468.468 0 00.468.466h35.425v-1.732h-8.256v-1.255l8.256.006zm-25.944 0h2.122v1.255h-2.12l-.002-1.255zm-1.415 1.255h-2.121v-1.255h2.123l-.002 1.255zm4.957-1.255h2.124v1.255h-2.124v-1.255zm3.539 0h2.121v1.255h-2.125l.004-1.255zm3.536 0h2.125v1.255h-2.129l.004-1.255zm-17.686 0h2.122v1.255H26.92l.004-1.255zm23.346 1.255h-2.127v-1.255h2.123l.004 1.255z"
      />
      <path
        fill="#263238"
        d="M88.488 67.199a.623.623 0 00-.019-.89l-5.974-5.648a.644.644 0 00-1.043.706c.031.08.078.153.138.214l2.382 2.506-1.24 1.194-.646-.513-4.506 5.161-2.408-2.316-2.904 8.54.576 11.591h6.68l-.468-13.776 4.48-6.381.035-1.518 1.288-1.32 2.777 2.481a.626.626 0 00.865-.031h-.013zM70.472 89.891c-.026 0-.048-10.576-.048-23.62s.022-23.623.048-23.623c.026 0 .048 10.574.048 23.623 0 13.05-.028 23.62-.048 23.62z"
      />
      <path
        fill="#455A64"
        d="M85.422 86.912l4.063-11.288-1.527-.618v-2.18l11.108-.172a1.08 1.08 0 001.052-.959l1.634-14.502a1.332 1.332 0 011.32-1.18 1.32 1.32 0 01.958.408 1.336 1.336 0 01.368.974l-.622 14.944 5.8.032.197 2.725-17.153.671-4.473 10.983-2.725.162z"
      />
      <path
        fill="#263238"
        d="M87.978 86.636l-14.44.115v.962l5.981-.077 9.528-.074 23.099-3.26-2.618-9.966h-15.64a1.664 1.664 0 00-1.384.703l-4.526 11.597z"
      />
      <path
        fill="#455A64"
        d="M81.058 44.962L70.57 78.437l.073 9.313h3.325v-9.254l8.6-33.443 23.302-.131 4.457 34.837h2.629l-5.422-34.592a1.978 1.978 0 00-1.942-1.61H82.949a1.975 1.975 0 00-1.891 1.405z"
      />
      <path
        fill="#EECD4A"
        d="M68.676 98.504v-8.942l1.958-1.812 18.4-.238 3.312-2.694a3.778 3.778 0 012.17-.839l3.543-.2s1.59-1.095 3.128-2.18a11.536 11.536 0 016.665-2.124h10.199a1.396 1.396 0 011.396 1.397v17.632h-50.77z"
      />
      <path fill="#263238" d="M119.444 95.623H68.496v2.94h50.948v-2.94z" />
      <path
        fill="#263238"
        d="M71.133 98.472a6.299 6.299 0 0010.689 4.612 6.299 6.299 0 00-4.299-10.819 6.3 6.3 0 00-6.39 6.207z"
      />
      <path
        fill="#263238"
        d="M71.135 98.457v.014a6.302 6.302 0 006.208 6.336 6.302 6.302 0 006.39-6.152v-.016l-12.598-.182z"
      />
      <path
        fill="#FAFAFA"
        d="M73.01 98.5a4.415 4.415 0 108.829.112 4.415 4.415 0 00-8.83-.113z"
      />
      <path
        fill="#E0E0E0"
        d="M77.496 94.14a4.418 4.418 0 00-4.486 4.36l8.845.126a4.415 4.415 0 00-4.36-4.486z"
      />
      <path
        fill="#263238"
        d="M74.737 98.525a2.697 2.697 0 001.633 2.508 2.697 2.697 0 002.942-.547 2.7 2.7 0 00.62-2.929 2.693 2.693 0 00-5.195.968zM101.96 98.472a6.302 6.302 0 0010.689 4.612 6.3 6.3 0 10-10.689-4.612z"
      />
      <path
        fill="#263238"
        d="M101.962 98.457v.014a6.303 6.303 0 006.208 6.336 6.3 6.3 0 006.39-6.152v-.016l-12.598-.182z"
      />
      <path
        fill="#FAFAFA"
        d="M103.837 98.5a4.418 4.418 0 007.493 3.224 4.414 4.414 0 001.027-4.796 4.41 4.41 0 00-4.034-2.788 4.424 4.424 0 00-3.148 1.249 4.417 4.417 0 00-1.338 3.11z"
      />
      <path
        fill="#E0E0E0"
        d="M108.323 94.14a4.415 4.415 0 00-4.486 4.36l8.845.126a4.406 4.406 0 00-1.249-3.148 4.415 4.415 0 00-3.11-1.338z"
      />
      <path
        fill="#263238"
        d="M105.567 98.525a2.692 2.692 0 004.572 1.962 2.688 2.688 0 00.624-2.926 2.692 2.692 0 00-4.382-.933 2.694 2.694 0 00-.814 1.897zM116.458 94.08a.398.398 0 01-.032-.045l-.081-.132-.128-.217a8.916 8.916 0 00-1.605-1.945c-.162-.164-.359-.306-.552-.47-.193-.163-.414-.303-.635-.46-.22-.156-.463-.3-.725-.426-.127-.067-.255-.138-.388-.203l-.414-.184a9.055 9.055 0 00-3.866-.706 8.942 8.942 0 00-3.783 1.078l-.393.22c-.127.078-.247.163-.368.24-.249.15-.462.335-.681.495-.219.16-.409.342-.589.517s-.357.342-.504.52a8.89 8.89 0 00-1.257 1.786c-.058.098-.109.2-.155.305-.042.088-.079.164-.108.228l-.068.14a.274.274 0 01-.026.046.176.176 0 01.017-.05l.059-.143.099-.232c.043-.107.093-.21.149-.311a8.583 8.583 0 011.24-1.812c.145-.184.328-.346.502-.53.175-.184.385-.346.591-.527.206-.18.434-.347.683-.502.121-.079.243-.164.368-.243l.395-.226a9.066 9.066 0 013.82-1.09 8.984 8.984 0 013.906.727l.416.184c.135.065.263.138.392.207.263.128.493.294.736.434s.44.309.637.469c.197.16.39.31.552.477a8.613 8.613 0 011.406 1.687c.066.095.128.193.184.294l.119.223.074.138c.018.037.015.037.013.038zM85.658 95.238a.21.21 0 01-.026-.05 6.78 6.78 0 00-.06-.15l-.1-.245a34.407 34.407 0 01-.15-.33c-.32-.695-.72-1.35-1.19-1.955a7.818 7.818 0 00-2.565-2.184 7.538 7.538 0 00-1.895-.631 9.591 9.591 0 00-2.14-.195 9.45 9.45 0 00-2.13.274 7.915 7.915 0 00-1.863.727 9.313 9.313 0 00-2.647 2.103c-.497.58-.929 1.213-1.288 1.888-.13.239-.227.426-.287.552l-.074.145a.202.202 0 01-.028.048.17.17 0 01.019-.052l.066-.149c.055-.132.147-.322.27-.565a10.04 10.04 0 011.27-1.917 9.265 9.265 0 012.657-2.136 7.998 7.998 0 011.88-.736 9.552 9.552 0 012.153-.278 9.68 9.68 0 012.162.206 7.596 7.596 0 011.916.644 7.802 7.802 0 012.576 2.223 9.657 9.657 0 011.172 1.981l.14.333.09.249c.024.064.04.114.053.154.008.015.014.03.019.046zM68.663 95.297H40.736v3.266h27.927v-3.266z"
      />
      <path
        fill="#455A64"
        d="M67.907 96.683a.813.813 0 11-1.627 0 .813.813 0 011.627 0zM68.298 43.299h-2.332v46.309h2.332v-46.31z"
      />
      <path
        fill="#263238"
        d="M101.451 92.247c0 .025-3.93.047-8.777.047-4.847 0-8.777-.022-8.777-.047 0-.026 3.928-.048 8.777-.048 4.848 0 8.777.022 8.777.048zM97.874 91.553c-.028 0-.048-1.71-.048-3.818 0-2.109.02-3.816.048-3.816.027 0 .047 1.707.047 3.816s-.022 3.818-.047 3.818zM70.474 42.237h-4.758v2.124h4.758v-2.124zM68.664 89.226H65.6v6.112h3.064v-6.112z"
      />
      <path fill="#F3532E" d="M61.55 80.554H42.38V95.34h19.17V80.554z" />
      <path
        fill="#000"
        d="M61.55 80.554H42.38V95.34h19.17V80.554z"
        opacity="0.3"
      />
      <path
        fill="#263238"
        d="M56.748 95.235c-.017 0-.032-3.296-.032-7.36 0-4.065.015-7.36.032-7.36.016 0 .03 3.295.03 7.36 0 4.064-.014 7.36-.03 7.36z"
      />
      <path fill="#F5F5F5" d="M59.925 91.974h-1.47v1.662h1.47v-1.662z" />
      <path
        fill="#263238"
        d="M50.405 80.592h-2.177v3.156h2.177v-3.156zM50.405 92.14h-2.177v3.156h2.177V92.14z"
      />
      <path
        fill="#263238"
        d="M61.383 80.554h-.746l-2.037.009-6.712.03h-6.72l-2.035-.01H42.388a.239.239 0 01.048 0H43.133l2.035-.01 6.72-.008 6.718.009 2.037.01H61.34a.243.243 0 01.042-.03z"
      />
      <path fill="#F3532E" d="M41.443 80.554h-19.17V95.34h19.17V80.554z" />
      <path
        fill="#263238"
        d="M36.642 95.235c-.018 0-.031-3.296-.031-7.36 0-4.065.013-7.36.031-7.36.018 0 .03 3.295.03 7.36 0 4.064-.013 7.36-.03 7.36z"
      />
      <path fill="#F5F5F5" d="M39.82 91.974h-1.47v1.662h1.47v-1.662z" />
      <path
        fill="#fff"
        d="M30.3 80.592h-2.177v3.156h2.176v-3.156zM30.3 92.14h-2.177v3.156h2.176V92.14z"
        opacity="0.5"
      />
      <path
        fill="#263238"
        d="M41.277 80.554H40.532l-2.035.009H25.059l-2.037-.01H22.277a.275.275 0 01.05 0H23.023l2.036-.009 6.72-.009 6.718.01 2.035.009H41.227a.243.243 0 01.05 0z"
      />
      <path
        fill="#F3532E"
        d="M120.248 84.572c.988.24 1.818 1.242 1.801 2.26a2.333 2.333 0 01-1.801 2.175"
      />
      <path
        fill="#fff"
        d="M120.248 84.572c.988.24 1.818 1.242 1.801 2.26a2.333 2.333 0 01-1.801 2.175"
        opacity="0.6"
      />
      <path fill="#F3532E" d="M120.288 84.001h-.844v5.596h.844V84z" />
      <path
        fill="#000"
        d="M120.288 84.001h-.844v5.596h.844V84z"
        opacity="0.3"
      />
      <path
        fill="#FFBE9D"
        d="M80.104 61.58a.535.535 0 01-.138-.687c.142-.247.308-.537.383-.659.092-.144.176-.292.252-.445a.502.502 0 01.386-.368 2.15 2.15 0 01.676.02l2.302.497 1.072.53-1.043.92-.58-.184c-.174.15-.857.23-1.089.202-.296-.037-.889-.23-1.225-.165l-.326.046.154.128c.284-.02.567.038.82.168a.234.234 0 01-.115.427l-.756.05a4.324 4.324 0 01-.589-.335c-.058-.052-.12-.1-.184-.146z"
      />
      <path
        fill="#EB996E"
        d="M80.776 61.294a.332.332 0 01-.123.052.656.656 0 01-.368 0 .464.464 0 01-.22-.153.389.389 0 01-.07-.305.28.28 0 01.096-.155.53.53 0 01.159-.079c.11-.037.222-.066.336-.087.217-.041.437-.07.657-.084.102 0 .197-.011.29-.011h.124c.039-.006.077-.013.114-.022.157-.05.316-.093.477-.127a1.62 1.62 0 01-.46.184.768.768 0 01-.12.03.312.312 0 01-.073 0h-.063c-.086 0-.184.01-.281.02-.217.019-.433.049-.646.09a2.67 2.67 0 00-.324.08c-.107.032-.184.08-.199.166a.315.315 0 00.048.24.423.423 0 00.184.136c.11.038.227.049.342.03.07-.001.12-.014.12-.005z"
      />
      <path
        fill="#EB996E"
        d="M81.061 60.094c.026 0 .07.114.024.26-.046.145-.149.222-.165.202-.017-.02.038-.108.075-.23.037-.121.039-.228.066-.232zM81.421 60.24c.026 0 .034.092-.03.184-.065.092-.152.1-.16.074-.01-.026.038-.07.084-.133.046-.063.079-.129.106-.125z"
      />
      <path
        fill="#FFBE9D"
        d="M90.483 59.727c.317-.133-1.818 2.062-1.818 2.062l-3.945-1.472-1.392.856 4.24 2.986a2.232 2.232 0 002.895-.276l2.504-2.894-2.484-1.262z"
      />
      <path
        fill="#F3532E"
        d="M92.797 50.931c-.552-.106-.895-.276-.91-.487-.029-.438 1.36-.887 3.105-1.007 1.744-.12 3.18.142 3.21.578.013.212-.301.425-.828.603"
      />
      <path
        fill="#000"
        d="M93.104 50.894c-.488-.092-.806-.243-.806-.43 0-.525 1.205-.788 2.75-.893 1.546-.105 2.821.125 2.847.514.013.184-.267.368-.736.533"
        opacity="0.4"
      />
      <path
        fill="#F3532E"
        d="M92.307 50.208s.326-1.886.359-1.98c.033-.094.506-.94 1.755-1.258l1.045-.072s1.517.377 1.811.721c.294.344.53 2.089.53 2.089l-2.974-.438-1.96.62-.566.318z"
      />
      <path
        fill="#F3532E"
        d="M94.749 46.736l-.45.03a.736.736 0 00-.147.483c0 .326.08 2.56.08 2.56l1.306-.147-.226-2.753.45.07-.023-.157-.127-.13-.863.044z"
      />
      <path
        fill="#FFBE9D"
        d="M97.48 57.016l-3.202.07c-.04-.337-.011-2.444-.011-2.444s-1.218-.106-1.316-1.312c-.097-1.205-.075-3.626-.075-3.626l.013-.107c1.356-.694 2.97-.857 4.232-.01l.184.124.174 7.305z"
      />
      <path
        fill="#EB996E"
        d="M94.267 54.648a2.923 2.923 0 001.54-.572s-.333.92-1.544.885l.004-.313z"
      />
      <path
        fill="#FFBE9D"
        d="M97.25 50.968c.02 0 .81-.3.84.526.03.827-.806.685-.807.663-.002-.022-.034-1.189-.034-1.189z"
      />
      <path
        fill="#EB996E"
        d="M97.52 51.835a.178.178 0 00.093.03.137.137 0 00.054-.01.355.355 0 00.153-.326.465.465 0 00-.091-.271.167.167 0 00-.07-.04.074.074 0 00-.082.042c-.011.024 0 .041-.01.043-.009.002-.018-.013-.012-.048a.101.101 0 01.03-.053.11.11 0 01.08-.024.2.2 0 01.154.123c.04.067.063.142.069.219a.368.368 0 01-.2.368.15.15 0 01-.132-.017c-.037-.016-.037-.035-.037-.036z"
      />
      <path
        fill="#263238"
        d="M93.275 51.192a.184.184 0 00.184.164.167.167 0 00.167-.167.184.184 0 00-.184-.164.167.167 0 00-.167.167zM93.22 51.023c.022.02.147-.084.333-.097.185-.013.327.071.346.047.018-.023-.017-.05-.078-.088a.487.487 0 00-.28-.066.458.458 0 00-.263.105c-.053.044-.07.09-.058.1zM95.102 51.124a.17.17 0 00.058.12.173.173 0 00.126.044.165.165 0 00.166-.167.174.174 0 00-.184-.164.166.166 0 00-.166.167zM95.045 50.933c.022.022.145-.083.33-.096.187-.013.329.072.347.048.018-.024-.015-.05-.077-.09a.468.468 0 00-.278-.064.445.445 0 00-.263.105c-.054.047-.07.088-.06.097zM94.466 52.354a1.352 1.352 0 00-.306-.033c-.048 0-.094 0-.103-.041a.236.236 0 01.022-.142l.116-.368c.108-.314.191-.637.25-.964-.134.305-.244.62-.327.942l-.11.368a.28.28 0 00-.012.184.117.117 0 00.083.063.314.314 0 00.081 0c.102.01.205.007.306-.01zM95.02 52.44c-.032 0-.017.2-.185.355-.167.155-.368.147-.368.184 0 .037.05.035.142.031a.505.505 0 00.315-.134.443.443 0 00.138-.298c-.004-.087-.03-.138-.043-.138zM94.944 50.21c.022.05.204.013.423.026s.4.059.425.01c.026-.047-.022-.067-.096-.111a.705.705 0 00-.32-.089.717.717 0 00-.327.061c-.074.039-.105.081-.105.103zM93.251 50.482c.037.039.16-.013.315-.026.154-.012.285.01.313-.036.027-.046-.013-.063-.072-.1a.457.457 0 00-.505.05c-.051.046-.066.092-.051.112zM97.336 50.186c-.01.337.024.673.015 1.01a1.13 1.13 0 01-.096.368.333.333 0 01-.63.046 1 1 0 01-.102-.427 1.398 1.398 0 00-.074-.436 2.328 2.328 0 00-.28-.408.537.537 0 01-.108-.466c.445-.013.886.094 1.275.311"
      />
      <path
        fill="#455A64"
        d="M92.876 50.335l-.02-.675 1.931-.721 2.512.551.048.725a6.072 6.072 0 00-4.471.12z"
      />
      <path
        fill="#F3532E"
        d="M92.97 49.51l-.082.087-.678.495c.889-.297 1.811-.483 2.747-.552a13.223 13.223 0 013.058.288l-.972-.5a1.615 1.615 0 00-1.18-.432l-1.773.085a1.616 1.616 0 00-1.12.528z"
      />
      <path
        fill="#263238"
        d="M94.208 48.92c.032-.314.04-.629.025-.944a6.283 6.283 0 00-.047-.944c-.035.314-.044.63-.026.944-.009.316.007.631.048.944zM95.76 46.979a1.252 1.252 0 00-.338-.074.143.143 0 00-.132.142l.01.199c.014.141.025.292.037.449.026.32.056.609.08.817.005.114.023.227.055.336a1.457 1.457 0 000-.34c-.011-.21-.032-.499-.06-.817l-.04-.45c0-.067-.014-.132-.02-.194a.09.09 0 01.077-.09c.11.016.22.023.332.022z"
      />
      <path
        fill="#263238"
        d="M92.164 50.087s.08-.04.23-.105c.073-.033.163-.07.266-.127a.794.794 0 00.297-.265c.086-.129.092-.3.184-.429s.283-.149.47-.167c.38-.043.797-.074 1.237-.092.44-.019.861-.022 1.242-.019a.77.77 0 01.495.13c.059.055.113.117.16.183l.076.09.038.043a.203.203 0 00.054.035c.272.108.495.184.647.233.078.032.157.06.238.081a.326.326 0 00-.057-.033 3.747 3.747 0 00-.171-.075c-.148-.065-.368-.142-.633-.254a.187.187 0 01-.04-.024l-.034-.043-.072-.09a1.538 1.538 0 00-.165-.184.813.813 0 00-.534-.15c-.383 0-.804 0-1.246.012-.441.013-.863.052-1.243.098a1.797 1.797 0 00-.28.044.388.388 0 00-.22.149c-.106.15-.106.322-.183.436a.745.745 0 01-.273.256 3.12 3.12 0 01-.259.138c-.075.037-.127.068-.164.09a.25.25 0 00-.06.039z"
      />
      <path
        fill="#263238"
        d="M92.436 50.173a.215.215 0 00.05-.03c.03-.021.078-.05.141-.086.182-.09.372-.162.569-.215a7.137 7.137 0 011.994-.232c.396 0 .771 0 1.104.015.333.015.646.068.9.11.114.02.227.045.338.076.085.015.165.049.234.1a.23.23 0 01.087.183.09.09 0 00.01-.059.245.245 0 00-.077-.152.582.582 0 00-.24-.12 3.301 3.301 0 00-.343-.087c-.299-.058-.6-.1-.903-.127-.344-.025-.723-.018-1.117-.02a6.627 6.627 0 00-2.01.256c-.198.056-.39.137-.568.241a.92.92 0 00-.136.098c-.022.033-.035.047-.033.05z"
      />
      <path
        fill="#FAFAFA"
        d="M94.493 46.81c.061.004.123.004.184 0l.445-.017c.173 0 .334-.028.444-.017a.205.205 0 01.154.08s0-.019-.025-.043a.221.221 0 00-.127-.057 2.901 2.901 0 00-.447 0l-.446.026a.82.82 0 00-.182.028zM94.415 48.994a.46.46 0 00.094 0H95.603l.461.01c.124 0 .247.01.368.03a.368.368 0 01.21.127.375.375 0 01.044.08.173.173 0 00-.035-.087.368.368 0 00-.213-.14 2.03 2.03 0 00-.368-.037c-.142 0-.298-.01-.462-.013-.33 0-.626 0-.841.01l-.254.012a.56.56 0 00-.098.008zM95.385 48.666a.293.293 0 000-.066c0-.06-.016-.12-.026-.184-.02-.153-.046-.368-.07-.598-.023-.23-.036-.445-.046-.598 0-.063-.003-.124-.01-.184a.23.23 0 000-.066.272.272 0 000 .066v.184a8.255 8.255 0 00.116 1.198c.012.077.025.138.034.184a.22.22 0 00.002.064zM96.916 49.398c.019.011.038.02.059.026l.162.06.537.185c.1.032.196.069.29.112a.35.35 0 01.172.171.266.266 0 01.017.164c-.013.037-.028.055-.024.057a.144.144 0 00.034-.053.251.251 0 000-.175.368.368 0 00-.183-.184 1.965 1.965 0 00-.295-.116l-.54-.184-.165-.051a.27.27 0 00-.064-.012z"
      />
      <path
        fill="#F3532E"
        d="M83.317 82.281l.167 2.988s-2.98 1.172-3.003 1.726l5.804-.114-.07-4.664-2.898.064z"
      />
      <path
        fill="#000"
        d="M83.317 82.281l.167 2.988s-2.98 1.172-3.003 1.726l5.804-.114-.07-4.664-2.898.064z"
        opacity="0.4"
      />
      <path
        fill="#E0E0E0"
        d="M85.273 84.982a.235.235 0 01.169.267.226.226 0 01-.263.17.249.249 0 01-.184-.284.237.237 0 01.292-.147M86.285 86.881l-.026-.469-5.555.285s-.256.118-.222.298l5.803-.114z"
      />
      <path
        fill="#263238"
        d="M83.39 85.242c0 .027.142.036.284.125.141.088.219.206.244.195.026-.011-.02-.175-.193-.278-.173-.103-.342-.068-.335-.043zM82.785 85.505c0 .027.118.073.217.184.1.11.13.244.157.243.027-.002.044-.164-.081-.308-.125-.143-.293-.147-.293-.12zM82.454 86.24c.026 0 .063-.137-.013-.29-.075-.153-.21-.213-.22-.184-.012.03.071.105.132.232.06.127.079.239.1.243zM83.427 84.555c.013.026.142-.014.302 0 .16.015.287.056.3.032.013-.024-.107-.124-.296-.131-.19-.007-.32.077-.306.1z"
      />
      <path
        fill="#000"
        d="M83.352 82.874l.027.531 2.852-.082-.005-.48-2.874.03z"
        opacity="0.3"
      />
      <path
        fill="#263238"
        d="M83.405 83.954a.57.57 0 00.291 0 1.44 1.44 0 00.467-.192.447.447 0 00.087-.07.126.126 0 00.009-.15.155.155 0 00-.125-.063.32.32 0 00-.109.017 1.04 1.04 0 00-.449.25c-.13.127-.174.234-.165.24.009.005.077-.082.211-.185a1.16 1.16 0 01.424-.206c.06-.018.112-.018.128 0 .017.018 0 .015 0 .033a.316.316 0 01-.062.052 1.614 1.614 0 01-.43.204 1.714 1.714 0 00-.277.07z"
      />
      <path
        fill="#263238"
        d="M83.442 83.992c.01 0 .064-.097.072-.281a.993.993 0 00-.124-.502.238.238 0 00-.184-.142.136.136 0 00-.12.083.433.433 0 00-.027.106.686.686 0 000 .195c.019.109.064.211.13.299.117.149.236.183.238.17.002-.012-.09-.067-.184-.21a.67.67 0 01-.097-.439c0-.062.03-.112.053-.107a.2.2 0 01.103.094 1.025 1.025 0 01.138.445c.015.179-.013.286.002.29z"
      />
      <path
        fill="#263238"
        d="M98.992 69.59s-.635 3.254-1.688 3.3c-1.288.055-10.644.184-10.644.184l.556 10.416-4.082-.097-1.622-10.266a3.933 3.933 0 01.434-2.693l.033-.058a3.917 3.917 0 013.34-1.882l7.77-.118 5.903 1.215z"
      />
      <path
        fill="#F3532E"
        d="M84.905 82.281l.165 2.988s-2.98 1.172-3.003 1.726l5.804-.114-.07-4.664-2.896.064z"
      />
      <path
        fill="#E0E0E0"
        d="M86.859 84.982a.236.236 0 01.169.267.224.224 0 01-.263.17.25.25 0 01-.184-.284.237.237 0 01.292-.147M87.87 86.881l-.025-.469-5.555.285s-.256.118-.223.298l5.804-.114z"
      />
      <path
        fill="#263238"
        d="M84.976 85.242c0 .027.144.036.284.125.14.088.219.206.244.195.026-.011-.02-.175-.193-.278-.173-.103-.342-.068-.335-.043zM84.373 85.505c0 .027.118.073.215.184.098.11.13.244.157.243.027-.002.044-.164-.081-.308-.125-.143-.291-.147-.291-.12zM84.04 86.24c.027 0 .062-.137-.013-.29-.076-.153-.21-.213-.22-.184-.012.03.07.105.132.232.06.127.073.239.1.243zM85.008 84.555c.013.026.142-.014.302 0 .16.015.287.056.3.032.012-.024-.107-.124-.297-.131-.19-.007-.305.077-.305.1z"
      />
      <path
        fill="#000"
        d="M84.938 82.874l.03.531 2.85-.082-.006-.48-2.874.03z"
        opacity="0.3"
      />
      <path
        fill="#263238"
        d="M84.991 83.954a.57.57 0 00.29 0 1.437 1.437 0 00.468-.192.45.45 0 00.087-.07.127.127 0 00.009-.15.156.156 0 00-.125-.063.32.32 0 00-.109.017.995.995 0 00-.447.25c-.132.127-.184.234-.167.24.016.005.077-.082.211-.185a1.16 1.16 0 01.424-.206c.06-.018.112-.018.128 0 .017.018 0 .015 0 .033a.318.318 0 01-.062.052 1.612 1.612 0 01-.43.204 1.714 1.714 0 00-.277.07z"
      />
      <path
        fill="#263238"
        d="M85.028 83.992c.01 0 .064-.097.072-.281a.944.944 0 00-.046-.318.958.958 0 00-.078-.184.236.236 0 00-.184-.142.136.136 0 00-.12.083.442.442 0 00-.027.106.693.693 0 000 .195c.019.109.064.211.13.299.117.149.236.183.238.17.002-.012-.09-.067-.184-.21a.67.67 0 01-.097-.439c.009-.062.03-.112.053-.107.022.006.08.045.103.094a1.024 1.024 0 01.138.445c.015.179-.018.286.002.29z"
      />
      <path
        fill="#263238"
        d="M100.015 69.59s-.633 3.254-1.688 3.3c-1.287.055-10.644.184-10.644.184l.569 10.416-4.081-.097-1.633-10.266a3.934 3.934 0 01.435-2.693l.033-.058a3.917 3.917 0 013.341-1.882l7.769-.118 5.899 1.215z"
      />
      <path
        fill="#F3532E"
        d="M92.055 56.53l1.233 8.185-.834 3.68 7.57 2.228c.685-.979.71-4.087.687-6.285-.043-3.875.128-4.063-.039-5.423-.168-1.36-.453-2.232-1.17-2.66L97.437 55.2s-1.163.736-1.871.701c0 0-.841.035-1.306-.412 0-.005-1.708.51-2.205 1.042z"
      />
      <path
        fill="#F3532E"
        d="M92.055 56.53l-2.523 3.478 3.436 2.017-.264-1.265-.65-4.23z"
      />
      <path
        fill="#455A64"
        d="M86.044 68.544a.106.106 0 01-.044 0l-.127.009c-.07.005-.14.013-.208.026a2.22 2.22 0 00-.278.051 3.648 3.648 0 00-1.575.836c-.308.277-.573.599-.786.955a3.244 3.244 0 00-.443 1.335 6.53 6.53 0 00.059 1.573c.075.553.184 1.105.24 1.692.131 1.159.306 2.259.47 3.258.164 1 .322 1.895.449 2.653s.226 1.367.287 1.793l.066.489c0 .052.011.092.015.127a.191.191 0 010 .044.143.143 0 01-.011-.042c0-.035-.015-.076-.026-.127l-.083-.486-.316-1.787-.47-2.65a51.6 51.6 0 01-.478-3.263c-.059-.58-.163-1.14-.237-1.688a6.412 6.412 0 01-.053-1.595c.05-.484.208-.951.461-1.367.22-.361.492-.687.81-.966a3.606 3.606 0 011.612-.82c.219-.047.443-.064.666-.05z"
      />
      <path
        fill="#FFBE9D"
        d="M88.943 66.017s2.92.942 5.191 1.765a2.484 2.484 0 003.218-1.586l1.555-4.898-2.596-.91-1.735 4.404-6.459-.848"
      />
      <path
        fill="#FFBE9D"
        d="M85.082 65.007l-.024-.232a.795.795 0 01.423-.63l.763-.398a.606.606 0 01.368-.06l1.566.268 1.798.235-.368 2.05-.832-.276s-.601.333-.973.254c0 0-.344.23-.486.06 0 0-.307.265-.423.249-.116-.017-.344-.151-.335-.23l.01-.107a.368.368 0 01-.382-.016.537.537 0 01-.174-.24.709.709 0 01-.389-.167.882.882 0 01-.141-.353s-.344-.168-.401-.407z"
      />
      <path
        fill="#EB996E"
        d="M86.237 64.266c.011 0 .03.084-.01.213-.056.163-.15.31-.275.43-.138.142-.267.258-.346.354-.08.096-.121.162-.134.156-.013-.005 0-.086.08-.197.098-.136.208-.263.33-.379a1.48 1.48 0 00.283-.388c.05-.11.055-.19.072-.19zM86.535 65.085a1.603 1.603 0 01-.543.835c.068-.153.154-.296.258-.427.149-.228.26-.421.285-.408zM86.934 65.625c.022.015-.042.147-.145.298-.103.151-.203.262-.225.245-.022-.016.045-.149.146-.298.1-.149.202-.26.224-.245zM86.86 65.41c0 .011-.04.013-.088-.016a.265.265 0 01-.116-.184.253.253 0 01.055-.206c.035-.043.07-.054.08-.044.009.009-.061.112-.041.235.02.123.121.191.11.215zM87.297 65.96c0 .011-.033.013-.074 0a.224.224 0 01-.103-.141.168.168 0 01.05-.184c.048-.033.084-.011.08 0a.144.144 0 00-.036.152c.02.094.094.15.083.173zM86.607 64.825c0 .011-.04.02-.096 0a.237.237 0 01-.037-.404c.05-.035.09-.032.092-.02.002.01-.103.093-.092.224.011.13.14.178.133.2z"
      />
      <path
        fill="#F3532E"
        d="M98.92 56.919c-.593-.258-1.323 0-1.752.449-.429.449-.626 1.036-.81 1.606-.184.57-.621 1.669-.8 2.22l3.365 1.44c.287-.777.555-1.56.803-2.35.21-.662.404-1.376.162-2.035"
      />
      <path
        fill="#263238"
        d="M99.888 58.249a1.327 1.327 0 01.1.326c.052.312.044.632-.025.941-.103.472-.239.936-.406 1.388-.184.526-.368 1.113-.587 1.744l-.017.046-.048-.02-2.37-1.012-.997-.427-.04-.018.012-.037c.273-.802.576-1.536.804-2.208.1-.325.223-.643.369-.951.13-.275.308-.525.526-.736a1.782 1.782 0 011.233-.486c.125.004.249.026.368.066.04.014.078.031.115.052a1.68 1.68 0 00-.48-.085c-.444.01-.867.186-1.187.495-.207.207-.377.45-.5.716-.14.306-.258.622-.353.946-.223.682-.527 1.42-.793 2.217l-.028-.056 1 .427 2.365 1.016-.064.026c.217-.631.42-1.215.602-1.739.171-.449.313-.908.425-1.375.07-.3.088-.612.051-.92-.038-.222-.083-.334-.075-.336z"
      />
      <path
        fill="#EB996E"
        d="M95.575 66.472c-.01 0-.037-.184-.15-.52a2.687 2.687 0 00-.279-.566 1.67 1.67 0 00-.585-.561h.01l-3.646-.475-2.208-.296-.958-.132-.834-.135-.368-.057a.442.442 0 00-.3.054l-.473.228-.3.141a.44.44 0 01-.108.043.731.731 0 01.1-.061l.292-.156.465-.243c.1-.06.218-.084.334-.066l.368.051.833.122.96.125 2.209.288 3.639.5h.011c.25.143.457.348.605.595.114.183.203.379.267.584a3.046 3.046 0 01.116.537z"
      />
      <path
        fill="#263238"
        d="M93.288 64.584a1.348 1.348 0 01-.05-.265c-.031-.184-.07-.43-.116-.723-.105-.627-.243-1.463-.395-2.392-.153-.93-.293-1.76-.396-2.392l-.112-.725a1.298 1.298 0 01-.03-.269c.03.085.053.173.067.262l.141.72a105.912 105.912 0 01.793 4.785c.043.307.07.552.087.736.012.087.016.175.01.263zM128.156 104.862c0 .027-26.632.048-59.476.048-32.844 0-59.48-.021-59.48-.048 0-.028 26.627-.048 59.484-.048s59.472.022 59.472.048z"
      />
      <path
        fill="#F3532E"
        d="M12.766 98.727c-.296.4-.847.248-1.095 0s-.35-.618-.44-.964c-.204-.79-.408-1.617-.219-2.41.046-.242.158-.465.324-.646a.697.697 0 01.666-.203c.278.081.444.36.563.622.347.776.517 1.62.499 2.47.014.398-.09.792-.3 1.131"
      />
      <path
        fill="#000"
        d="M12.766 98.727c-.296.4-.847.248-1.095 0s-.35-.618-.44-.964c-.204-.79-.408-1.617-.219-2.41.046-.242.158-.465.324-.646a.697.697 0 01.666-.203c.278.081.444.36.563.622.347.776.517 1.62.499 2.47.014.398-.09.792-.3 1.131"
        opacity="0.2"
      />
      <path
        fill="#F3532E"
        d="M11.643 100.756a2.468 2.468 0 00-1.523-.226c-.283.018-.552.13-.766.317-.195.204-.239.57-.027.756a.731.731 0 00.473.132c.405 0 .855-.053 1.172.199.184.145.298.379.517.453a.523.523 0 00.58-.24.89.89 0 00.082-.656 1.01 1.01 0 00-.508-.735zM13.531 99.33a1.473 1.473 0 01-.193-1.366c.166-.452.453-.85.83-1.148.433-.364.933-.64 1.472-.814.208-.077.431-.109.653-.092a.679.679 0 01.536.344c.145.293-.011.64-.184.92a10.98 10.98 0 01-.989 1.419c-.294.359-.629.712-1.067.867-.438.154-.887.121-1.104-.184"
      />
      <path
        fill="#263238"
        d="M12.62 104.862a.805.805 0 01-.02-.184l-.037-.506a5.712 5.712 0 00-.338-1.616 3.194 3.194 0 00-.425-.804 1.677 1.677 0 00-.552-.482 1.574 1.574 0 00-.471-.162.765.765 0 01-.184-.031.943.943 0 01.184 0c.17.017.337.065.491.142.23.116.429.284.583.489.187.25.335.526.44.819.109.286.192.581.248.881.042.249.068.499.08.751 0 .215.01.388.01.508.004.065.001.13-.008.195z"
      />
      <path
        fill="#263238"
        d="M12.597 104.472a.317.317 0 010-.087v-.244c0-.212.014-.521.013-.9a37.28 37.28 0 00-.499-5.914 13.175 13.175 0 00-.094-.504c-.031-.147-.053-.276-.077-.381-.024-.105-.039-.184-.052-.24a.418.418 0 01-.012-.084s.012.028.027.081a10.852 10.852 0 01.156.615c.034.147.07.316.105.504.184.973.316 1.956.394 2.944.096 1.159.125 2.208.11 2.977 0 .381-.022.69-.038.902 0 .099-.015.184-.02.244a.669.669 0 01-.013.087z"
      />
      <path
        fill="#263238"
        d="M12.646 102.442a1.433 1.433 0 010-.257c.015-.164.043-.402.098-.69a9.508 9.508 0 01.707-2.19 6.404 6.404 0 011.287-1.9c.095-.094.195-.182.3-.264.046-.037.087-.073.127-.103l.116-.075c.07-.05.143-.093.22-.131a7.676 7.676 0 00-.724.61 6.742 6.742 0 00-1.262 1.894 9.772 9.772 0 00-.45 1.174 9.936 9.936 0 00-.39 1.678c-.003.085-.013.17-.029.254z"
      />
    </svg>
  );
}
