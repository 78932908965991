import routerConfig from '../config/router/router.config';
import { drivingContestRoute } from '../view/DrivingContest/drivingContest.route';
import { homeRoute } from '../view/Home/Home.route';
import { jobOrderRoute } from '../view/JobOrder/JobOrder.route';
import { reportDrivingBehaviorByJobOrderRoute } from '../view/Report/DrivingBehavior/page/JobOrder/reportDrivingBehaviorByJobOrder.route';
import { reportDrivingBehaviorByScheduleRoute } from '../view/Report/DrivingBehavior/page/Schedule/reportDrivingBehaviorBySchedule.route';
import { reportDrivingBehaviorByVehicleRoute } from '../view/Report/DrivingBehavior/page/Vehicle/reportDrivingBehaviorByVehicle.route';
import { shipperOrderRoute } from '../view/ShipperOrder/ShipperOrder.route';

/**
 * list of public routes from `routerConfig`
 */
export const PUBLIC_ROUTE: string[] = routerConfig.reduce(
  (result: string[], config) => {
    if (!config.isPrivate) {
      result.push(config.path);
    }
    return result;
  },
  [],
);

export const csvFileType = '.csv' as const;
export const xlsFileType = 'application/vnd.ms-excel' as const; // Excel Files 1997-2003
export const xlsxFileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' as const; // Excel Files 2007+

export const playStoreLink =
  'https://play.google.com/store/apps/details?id=id.co.ktbfuso.driver';

export const mobileBottomBarReportPath = [
  reportDrivingBehaviorByScheduleRoute.path,
  reportDrivingBehaviorByVehicleRoute.path,
  reportDrivingBehaviorByJobOrderRoute.path,
];
export const mobileExplorePath = [
  jobOrderRoute.path,
  shipperOrderRoute.path,
  drivingContestRoute.path,
  ...mobileBottomBarReportPath,
];
export const mobileBottomBarVisiblePath = [
  homeRoute.path,
  ...mobileExplorePath,
];

export const driverAppRef = 'driver-app';
