import React from 'react';
import { Icon } from '../../component/atom';
import { RouteConfig } from '../../config/router/router.config';
import {
  HaulingOrderCreateForm,
  HaulingOrderSearchParam,
} from '../../model/HaulingOrder.model';

export type HaulingOrderCreateNavigateParams =
  | {
      searchParamsFromHOList?: HaulingOrderSearchParam;
      formStep?: number;
      formValues?: HaulingOrderCreateForm;
      originPath?: string;
    }
  | undefined;

export const haulingOrderCreateRoute: RouteConfig = {
  name: 'Create Hauling Order',
  path: '/hauling-order/create',
  Component: React.lazy(() => import('./HaulingOrderCreate.view')),
  isPrivate: true,
  breadcrumb: 'Create Hauling Order',
  options: {
    icon: <Icon.ShipperOrder />,
    label: 'Create Hauling Order',
    testID: 'MenuCreateHaulingOrder',
  },
};
