import { Locale } from './locale.config';

const errorsLocaleConfig: Locale[] = [
  {
    en: "Either your organization is not yet registered on this server or you don't have permission to access this service.",
    id: 'Organisasi Anda belum terdaftar di server ini atau Anda tidak memiliki izin untuk mengakses layanan ini.',
  },
  {
    en: 'Unable to validate current session, please try again.',
    id: 'Tidak dapat memvalidasi sesi saat ini, silahkan coba lagi.',
  },
  {
    en: "Forbidden request, You don't have permission to access this service",
    id: 'Permintaan ditolak, Anda tidak memiliki izin akses layanan ini',
  },
  {
    en: 'Organization is not found',
    id: 'Organisasi tidak ditemukan',
  },

  {
    en: 'Back to Login Page',
    id: 'Kembali ke Halaman Login',
  },
  {
    en: 'Please log in again to continue',
    id: 'Masuk kembali untuk melanjutkan',
  },

  {
    en: 'Driver phone number already taken.',
    id: 'Nomor telpon sopir sudah digunakan.',
  },
  {
    en: 'Shipper phone number already taken.',
    id: 'Nomor telpon pengirim sudah digunakan.',
  },
  {
    en: 'Shipper name already taken.',
    id: 'Nama pengirim sudah digunakan.',
  },
  {
    en: 'Total shipper revenue not calculated',
    id: 'Jumlah pendapatan pengirim tidak terkalkulasi',
  },
  {
    en: 'Shipper order number is already taken, please enter another.',
    id: 'Nomor Shipper order sudah digunakan, silahkan masukkan nomor yang lain.',
  },
  {
    en: 'Shipper Order payment already paid',
    id: 'Tagihan Shipper Order sudah lunas',
  },
  {
    en: 'Shipper Order payment amount limit exceeded',
    id: 'Jumlah pembayaran tagihan Shipper Order melampaui batas',
  },
  {
    en: 'Shipper Order payment not found',
    id: 'Tagihan Shipper Order tidak ditemukan',
  },
  {
    en: 'Reference number is already taken, please enter another.',
    id: 'Nomor referensi sudah digunakan, silahkan masukkan nomor yang lain.',
  },
  {
    en: 'Tracking number is already taken, please enter another.',
    id: 'No resi sudah terpakai, gunakan nomor lain.',
  },
  {
    en: 'Invalid arguments.',
    id: 'Argumen tidak sesuai.',
  },
  {
    en: 'Location name is already taken.',
    id: 'Nama lokasi sudah digunakan.',
  },
  {
    en: 'Location name already exist',
    id: 'Nama lokasi sudah digunakan.',
  },
  {
    en: 'Location not found.',
    id: 'Lokasi tidak ditemukan.',
  },
  {
    en: 'Location exists in active Shipper Order',
    id: 'Lokasi ada dalam aktif Shipper Order',
  },
  {
    en: 'Location exists in activity template',
    id: 'Lokasi ada dalam templat pengiriman',
  },
  {
    en: 'Template name already taken.',
    id: 'Nama templat sudah digunakan.',
  },
  {
    en: 'Job order not found.',
    id: 'Perintah Kerja tidak ditemukan.',
  },
  {
    en: 'Job order number already taken.',
    id: 'Nomor Perintah Kerja sudah digunakan.',
  },
  {
    en: 'Shipper Order has been used in a Job Order.',
    id: 'Shipper Order sudah digunakan dalam Perintah Kerja.',
  },
  {
    en: 'Unknown error has occured',
    id: 'Terjadi kesalahan',
  },
  {
    en: 'Unable to download an empty table',
    id: 'Tidak dapat mengunduh tabel kosong',
  },
  {
    en: 'No internet connection',
    id: 'Tidak ada koneksi internet',
  },
  {
    en: 'Sorry, we have encountered a problem.',
    id: 'Mohon maaf, kami mengalami masalah.',
  },
  {
    en: 'Please, copy the error message from below and report to admin.',
    id: 'Silakan, salin pesan error di bawah dan laporkan ke admin.',
  },
  {
    en: 'Copy',
    id: 'Salin',
  },
  {
    en: "Can't change to number that belong to active driver.",
    id: 'Tidak dapat mengubah ke nomor milik driver aktif.',
  },
  {
    en: 'Refresh App',
    id: 'Segarkan Aplikasi',
  },
  {
    en: 'Reload App',
    id: 'Muat Ulang Aplikasi',
  },
  {
    en: 'Shipper Order already paid',
    id: 'Shipper Order sudah lunas',
  },
  {
    en: 'Hauling Order number already taken',
    id: 'Nomor Hauling Order sudah digunakan',
  },
  {
    en: 'Hauling Order reference number already taken',
    id: 'Nomor referensi Hauling Order sudah digunakan',
  },
  {
    en: 'Hauling Order number format is not allowed',
    id: 'Pola nomor Hauling Order tidak diperbolehkan',
  },
  {
    en: 'Hauling Order shipper location miss match',
    id: 'Lokasi Pengirim di Hauling Order tidak cocok',
  },
  {
    en: 'Hauling Order not yet assigned',
    id: 'Hauling Order belum ditugaskan',
  },
  {
    en: 'Hauling Order already assigned',
    id: 'Hauling Order sudah ditugaskan',
  },
  {
    en: 'Job Order already delivered.',
    id: 'Perintah Kerja sudah terkirim.',
  },
  {
    en: 'Hauling Order already started',
    id: 'Hauling Order sudah dimulai',
  },
  {
    en: 'Hauling Order already delivered',
    id: 'Hauling Order sudah terkirim',
  },
  {
    en: 'Hauling order number is already taken, please enter another.',
    id: 'Nomor Hauling order sudah digunakan, silahkan masukkan nomor yang lain.',
  },
  {
    en: 'Driver already assigned to Driver Contest.',
    id: 'Sopir sudah didaftarkan ke Driver Contest.',
  },
  {
    en: 'Driver not yet assigned to Driver Contest.',
    id: 'Sopir belum didaftarkan ke Driver Contest.',
  },
  {
    en: 'Failed to Upload',
    id: 'Gagal mengunggah',
  },
  {
    en: 'has invalid format. Please use provided XLS template and re-upload',
    id: 'memiliki format yang salah. Mohon gunakan templat XLS yang sudah diberikan dan unggah ulang',
  },
  {
    en: 'exceeded the maximum data allowed. Please reduce the data amount to a maximum of 100 rows',
    id: 'melebihi data maksimum yang diizinkan. Mohon untuk mengurangi jumlah data hingga maksimal 100 baris dan unggah ulang.',
  },
  {
    en: 'Date of Birth has invalid format. Please use provided XLS template and re-upload',
    id: 'Tanggal Lahir memiliki format yang salah. Mohon gunakan templat XLS yang sudah diberikan dan unggah ulang',
  },
  {
    en: 'Driver invalid pin format',
    id: 'Format Nomor PIN Sopir salah',
  },
  {
    en: 'Full Name is required',
    id: 'Nama Lengkap wajib diisi',
  },
  {
    en: 'Phone Number is required',
    id: 'Nomor Telepon wajib diisi',
  },
  {
    en: 'Phone Number must be a string',
    id: 'Nomor Telepon harus dalam bentuk string karakter',
  },
  {
    en: 'Address must be a string',
    id: 'Alamat harus dalam bentuk string karakter',
  },
  {
    en: 'License Number must be a string',
    id: 'Nomor SIM harus dalam bentuk string karakter',
  },
  {
    en: 'Date Of Birth must be a valid date',
    id: 'Tanggal Lahir harus dalam bentuk tanggal',
  },
  {
    en: 'Pin length must be 6 characters long',
    id: 'PIN wajib berisi 6 karakter',
  },
  {
    en: 'Invalid Full Name',
    id: 'Nama Lengkap salah',
  },
  {
    en: 'Invalid License Number',
    id: 'Nomor SIM salah',
  },
  {
    en: 'Invalid Date Of Birth',
    id: 'Tanggal Lahir salah',
  },
  {
    en: 'Invalid PIN',
    id: 'PIN salah',
  },
  {
    en: 'Invalid Pin',
    id: 'Pin salah',
  },
  {
    en: 'Invalid Phone Number',
    id: 'Nomor Telepon salah',
  },
  {
    en: 'Invalid Address',
    id: 'Alamat salah',
  },
  {
    en: 'Shipper Order already deleted',
    id: 'Shipper Order sudah terhapus',
  },
  {
    en: 'Timesheet detail not found',
    id: 'Detil waktu pengiriman tidak ditemukan',
  },
  {
    en: 'Requires at least 1 selected payment status',
    id: 'Pilih minimal 1 status pembayaran',
  },
  {
    en: 'Requires at least 1 selected status',
    id: 'Pilih minimal 1 status',
  },
  {
    en: 'Requires at least 1 selected type',
    id: 'Pilih minimal 1 tipe',
  },
  {
    en: 'Requires at least 1 selected finishing method',
    id: 'Pilih minimal 1 metode selesai',
  },
  {
    en: 'Requires at least 1 selected category',
    id: 'Pilih minimal 1 kategori',
  },
  {
    en: "End time can't be earlier than start time",
    id: 'Waktu berakhir tidak boleh lebih awal dari waktu mulai',
  },
];

export default errorsLocaleConfig;
