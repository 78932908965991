import React from 'react';
import tw, { styled, theme } from 'twin.macro';
import { UseSettingResetDataObj } from '../../../view/Setting/hook/useSettingResetData.hook';
import { LoadingIndicator } from '../../atom';
import {
  CheckCircle,
  CrossCircle,
  InfoOutlined,
} from '../../atom/Icon/Icon.atom';
import {
  BodySixteen,
  HeadingFour,
  HeadingThree,
} from '../../atom/Text/Text.atom';
import { SearchInput } from '../../molecule';
import InfoAlert from '../InfoAlert/InfoAlert.organism';

const Container = tw.div`flex flex-col gap-3 p-5`;
const Content = tw.div`flex flex-col gap-1`;
const AlertText = tw(BodySixteen)`text-text-color-text-secondary`;

const ItemContainer = tw.div`flex gap-3 items-center`;
const ItemLabel = styled(BodySixteen)(
  ({
    isLoading,
    isConditionMet,
    isAllConditionMet,
  }: {
    isLoading?: boolean;
    isConditionMet?: boolean;
    isAllConditionMet?: boolean;
  }) => [
    tw`leading-6 mt-1 text-text-color-text-secondary`,
    !isAllConditionMet &&
      isConditionMet &&
      !isLoading &&
      tw`text-grey-placeholder`,
    isAllConditionMet && isConditionMet && !isLoading && tw`text-black-primary`,
    !isConditionMet && !isLoading && tw`text-semantic-error`,
  ],
);
const StateContainer = tw.div`w-4 h-4`;

type Props = {
  resetDataHook: UseSettingResetDataObj;
};

function ItemState({
  isLoading,
  isConditionMet,
  isAllConditionMet,
}: {
  isLoading: boolean;
  isConditionMet: boolean;
  isAllConditionMet: boolean;
}) {
  if (isLoading) return <LoadingIndicator size="mini" />;
  if (isConditionMet)
    return (
      <CheckCircle
        width={18}
        height={18}
        viewBox="0 0 24 24"
        fill={
          isAllConditionMet
            ? theme`colors.semantic.success`
            : theme`colors.grey.placeholder`
        }
      />
    );
  return (
    <CrossCircle
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill={theme`colors.semantic.error`}
    />
  );
}

function SettingResetDataItem({
  label,
  isAllConditionMet,
  isConditionMet,
  isLoading,
}: {
  label: string;
  isLoading: boolean;
  isConditionMet: boolean;
  isAllConditionMet: boolean;
}) {
  return (
    <ItemContainer>
      <StateContainer>
        <ItemState
          isConditionMet={isConditionMet}
          isLoading={isLoading}
          isAllConditionMet={isAllConditionMet}
        />
      </StateContainer>
      <ItemLabel
        isConditionMet={isConditionMet}
        isLoading={isLoading}
        isAllConditionMet={isAllConditionMet}
      >
        {label}
      </ItemLabel>
    </ItemContainer>
  );
}

export default function SettingsResetData({
  resetDataHook: {
    title,
    alertLabel,
    description,
    errorLabel,
    isErrorVisible,
    isConditionMet,
    conditionMapper,
    confirmationText,
    confirmationLabel,
    confirmationKeyword,
    confirmationPlaceholder,
    isConditionFetchLoading,
    debouncedConfirmationText,
    confirmationAdditionalLabel,
    handleChangeConfirmationInput,
    handleFocusInput,
  },
}: Props) {
  return (
    <Container>
      <HeadingThree>{title}</HeadingThree>
      <Content>
        <HeadingFour>{description}</HeadingFour>
        {conditionMapper.map((item) => (
          <SettingResetDataItem
            isAllConditionMet={isConditionMet}
            key={item.label}
            label={item.label}
            isLoading={item.isLoading}
            isConditionMet={item.isConditionMet}
          />
        ))}
      </Content>

      {!isConditionFetchLoading && !isConditionMet && (
        <InfoAlert
          icon={<InfoOutlined fillPath={theme`colors.status.alert`} />}
          rootStyle={tw`bg-semantic-error-muted border-semantic-error`}
          title={<AlertText>{alertLabel}</AlertText>}
        />
      )}
      {!isConditionFetchLoading && isConditionMet && (
        <div>
          <BodySixteen>
            {confirmationLabel}
            <BodySixteen tw="font-bold">{` "${confirmationKeyword}" `}</BodySixteen>
            {confirmationAdditionalLabel}
          </BodySixteen>
          <SearchInput
            hideIcon
            error={isErrorVisible ? errorLabel : undefined}
            onFocus={handleFocusInput}
            isPasteProhibited
            tw="bg-transparent"
            placeholder={confirmationPlaceholder}
            loading={confirmationText !== debouncedConfirmationText}
            value={confirmationText}
            changeData={handleChangeConfirmationInput}
          />
        </div>
      )}
    </Container>
  );
}
