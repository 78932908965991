import React from 'react';

export default function Delete({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  fill = 'currentColor',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8 7H7.2V20.3333H16.8V7ZM5.6 5.33333V20.3333C5.6 21.2538 6.31634 22 7.2 22H16.8C17.6837 22 18.4 21.2538 18.4 20.3333V5.33333H5.6Z"
      />
      <path d="M15.2 10.3333V12H8.8V10.3333H15.2Z" />
      <path d="M15.2 15.3333V17H8.8V15.3333H15.2Z" />
      <path d="M9.6 2H14.4V3.66667H9.6V2Z" />
      <path d="M4 5.33333H20V7H4V5.33333Z" />
    </svg>
  );
}
