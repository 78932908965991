import React from 'react';
import tw, { theme } from 'twin.macro';
import { UseShipperFilterHookObj } from '../../../hook/shipper/useShipperFilter.hook';
import { Button, Chip, Divider, Icon, Text } from '../../atom';

// #region INTERFACES
type Props = {
  shipperFilter: UseShipperFilterHookObj;
};
// #endregion

// #region STYLED
const Container = tw.div`w-full h-full bg-white flex flex-col`;
const Header = tw.div`flex-none p-4 flex items-center`;
const Footer = tw.div`flex-none p-4 flex flex-col`;
const Filters = tw.div`flex-1 flex-grow overflow-auto py-5 px-4 flex flex-col space-y-6`;
const FilterItemContainer = tw.div`flex flex-col space-y-1`;
const FilterItemHeader = tw.div`flex justify-between`;
const InputHeading = tw(Text.HeadingFive)``;
const ChipContainer = tw.div`flex flex-wrap -mr-2 -mb-2`;
const FilterChip = tw.div`mb-2 mr-2`;
// #endregion

function ShipperFilter({ shipperFilter }: Props) {
  return (
    <Container>
      <Header>
        <Icon.FilterV2 />

        <Text.HeadingFour tw="flex-1 ml-2">
          {shipperFilter.titleLabel}
        </Text.HeadingFour>

        <Button.Outlined
          small
          tw="bg-white text-grey-two px-4"
          onClick={shipperFilter.onClickResetFilter}
        >
          {shipperFilter.resetBtnLabel}
        </Button.Outlined>
      </Header>

      <Divider />

      <Filters>
        <FilterItemContainer>
          <InputHeading>{shipperFilter.sortByLabel}</InputHeading>

          <ChipContainer>
            {shipperFilter.filterSortByValues.map((el) => {
              const selected = shipperFilter.form.values.sortBy === el.value;

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      shipperFilter.onClickSortByChipFilter(el.value)
                    }
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>
        </FilterItemContainer>

        <FilterItemContainer>
          <FilterItemHeader>
            <InputHeading>{shipperFilter.statusLabel}</InputHeading>

            <Button.Text
              small
              onClick={
                shipperFilter.isQueryStatusAll
                  ? shipperFilter.onClickUnselectAllStatus
                  : shipperFilter.onClickSelectAllStatus
              }
            >
              {shipperFilter.isQueryStatusAll
                ? shipperFilter.unselectAllLabel
                : shipperFilter.selectAllLabel}
            </Button.Text>
          </FilterItemHeader>

          <ChipContainer>
            {shipperFilter.filterStatusValues.map((el) => {
              const selected = shipperFilter.form.values.status.includes(
                el.value,
              );

              return (
                <FilterChip key={`Filter_Category_${el.value}`}>
                  <Chip.Small
                    tw="text-grey-two"
                    css={[selected && tw`border-white text-orange`]}
                    label={el.label}
                    selected={selected}
                    onClick={() =>
                      shipperFilter.onClickStatusChipFilter(el.value)
                    }
                    right={
                      selected ? (
                        <Icon.CheckCircle
                          height={20}
                          width={20}
                          viewBox="0 0 28 28"
                          fill={theme`colors.orange.DEFAULT`}
                        />
                      ) : (
                        <Icon.Plus height={12} width={12} viewBox="0 0 12 12" />
                      )
                    }
                    optOutRightDefaultSize
                  />
                </FilterChip>
              );
            })}
          </ChipContainer>

          {shipperFilter.isQueryStatusEmpty && (
            <div tw="flex items-center gap-2 animate-fade-in">
              <Icon.CloseOutlined
                height="1em"
                width="1em"
                viewBox="0 0 36 36"
              />
              <Text.BodySmallTwelve tw="text-semantic-error">
                {shipperFilter.errorStatusLabel}
              </Text.BodySmallTwelve>
            </div>
          )}
        </FilterItemContainer>
      </Filters>

      <Divider />

      <Footer>
        <Button.Solid
          type="submit"
          disabled={
            shipperFilter.isQueryStatusEmpty ||
            shipperFilter.filterLocalIsFetching
          }
          onClick={shipperFilter.onClickSubmitFilter}
        >
          {shipperFilter.submitBtnLabel}
        </Button.Solid>
      </Footer>
    </Container>
  );
}

export default ShipperFilter;
