import React from 'react';
import tw from 'twin.macro';
import { UseReportHaulingOrderNoteModalHook } from '../../../view/Report/HaulingOrder/hooks/useReportHaulingOrderNoteModal.hook';
import { Text } from '../../atom';
import { Modal } from '../../molecule';

type Props = {
  reportHaulingOrderNoteModal: UseReportHaulingOrderNoteModalHook;
};

const NoteText = tw(Text.Paragraph)`p-5 text-black`;

export default function ReportHONoteModal({
  reportHaulingOrderNoteModal,
}: Props) {
  if (!reportHaulingOrderNoteModal.selectedAssignment) return null;

  return (
    <Modal.WithHeader
      css={tw`p-0 w-[760px]`}
      headerTitleStyle={tw`text-base`}
      headerContainerStyle={tw`p-5`}
      title={reportHaulingOrderNoteModal.title}
      onClose={reportHaulingOrderNoteModal.handleCloseModal}
      onOverlayClick={reportHaulingOrderNoteModal.handleCloseModal}
    >
      <NoteText>
        {reportHaulingOrderNoteModal.selectedAssignment.notes}
      </NoteText>
    </Modal.WithHeader>
  );
}
