import React from 'react';
import tw from 'twin.macro';
import {
  SettingNavigationItemType,
  settingSidebarItems,
} from '../../../constant/Setting.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { FormItem } from '../../../types/input.type';
import { UseSettingAdvancedObj } from '../../../view/Setting/hook/useSettingAdvanced.hook';
import { SidebarItem } from '../../molecule';
import SettingsContent from './SettingsContent.organism';

type Props = {
  formData: FormItem[];
  currentMenu: SettingNavigationItemType;
  settingAdvanced: UseSettingAdvancedObj;
  handleSelectSidebarItem: (item: string) => void;
};

const Container = tw.div`flex flex-row flex-1`;
const SidebarContainer = tw.div`w-60 border-r py-4 border-r-beige-lines min-h-[244px]`;
const ContentContainer = tw.div`py-4 px-6 flex flex-1`;

export default function Settings({
  currentMenu,
  formData,
  settingAdvanced,
  handleSelectSidebarItem,
}: Props) {
  const { translate } = useTranslator();
  return (
    <Container>
      <SidebarContainer>
        {settingSidebarItems.map((item) => (
          <SidebarItem
            key={item.testID}
            path={item.path}
            onClick={handleSelectSidebarItem}
            label={translate(item.label)}
            isSelected={item.path === currentMenu}
            hasDivider
            isLink={false}
          />
        ))}
      </SidebarContainer>
      <ContentContainer>
        <SettingsContent
          settingAdvanced={settingAdvanced}
          formData={formData}
          currentMenu={currentMenu}
        />
      </ContentContainer>
    </Container>
  );
}
