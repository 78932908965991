import fromUnixTime from 'date-fns/fromUnixTime';
import React, { useMemo, useState } from 'react';
import 'twin.macro';
import tw, { styled } from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import useTranslator from '../../../hook/useTranslator.hook';
import { SOTrackingDelivery } from '../../../model/ShipperOrder.model';
import { getDateFormat } from '../../../util/date.util';
import { mapJOStatusToLabel } from '../../../util/jobOrder.util';
import {
  BodyFourteen,
  BodySixteen,
  HeadingFour,
} from '../../atom/Text/Text.atom';
import { JobOrderStatus } from '../../molecule';

const Nav = styled.button(({ selected }: { selected?: boolean }) => [
  tw`flex items-center justify-between duration-200 py-3 px-4 gap-4 shadow rounded-[8px] hover:(bg-orange-hover) last-of-type:mr-4`,
  selected && tw`shadow-none bg-orange-hover `,
]);

function NavigationItem({
  delivery: { jobOrder },
  onClick,
  isSelected,
}: {
  isSelected?: boolean;
  onClick: VoidFunction;
  delivery: SOTrackingDelivery;
}) {
  return (
    <Nav selected={isSelected} type="button" onClick={onClick}>
      <div tw={'w-[130px] truncate'}>
        <HeadingFour css={[!!isSelected && tw`text-orange`]}>
          {jobOrder.number}
        </HeadingFour>
      </div>
    </Nav>
  );
}

function Item({ label, value }: { label: string; value?: string }) {
  return (
    <div tw="flex py-2 items-center not-last-of-type:(border-b border-b-beige-lines)">
      <div tw={'w-[180px] truncate'}>
        <BodyFourteen tw="text-grey-three">{label}</BodyFourteen>
      </div>
      <div tw={'w-[170px] truncate'}>
        <BodySixteen tw="text-grey-two">{value || '-'}</BodySixteen>
      </div>
    </div>
  );
}

function DeliveryContent({
  delivery: { jobOrder },
  forceLang,
}: { delivery: SOTrackingDelivery; forceLang?: SupportedLanguage }) {
  const { translate } = useTranslator();

  const contentItems = useMemo(
    () => [
      {
        label: translate('Job Order Date', forceLang),
        value: jobOrder?.date
          ? getDateFormat(fromUnixTime(jobOrder?.date))
          : undefined,
      },
      {
        label: translate('Seal Number', forceLang),
        value: jobOrder.sealNumber,
      },
      {
        label: translate('Driver', forceLang),
        value: jobOrder.driverName,
      },
      {
        label: translate('Vehicle', forceLang),
        value: jobOrder.vehicleName,
      },
    ],
    [jobOrder, forceLang, translate],
  );

  return (
    <div tw="flex flex-1 flex-col w-full">
      <div tw="flex justify-between items-center p-4 pt-2 border-b gap-1 border-b-beige-lines">
        <div tw={'w-[calc(100vw - 190px)] truncate'}>
          <HeadingFour>{jobOrder.number}</HeadingFour>
        </div>
        <JobOrderStatus
          status={jobOrder.status}
          statusLabel={translate(
            mapJOStatusToLabel(jobOrder.status),
            forceLang,
          )}
        />
      </div>
      <div tw="flex flex-col gap-0.5 py-2 px-4">
        {contentItems.map((contentItem) => (
          <Item
            key={contentItem.label}
            label={contentItem.label}
            value={contentItem.value}
          />
        ))}
      </div>
    </div>
  );
}

type Props = {
  deliveries?: SOTrackingDelivery[];
  forceLang?: SupportedLanguage;
};

export default function TrackingSODeliveryMobile({
  deliveries,
  forceLang,
}: Props) {
  const { translate } = useTranslator();
  const [selectedDelivery, setSelectedDelivery] = useState<
    SOTrackingDelivery | undefined
  >(deliveries?.[0]);

  return (
    <div tw="bg-white py-4 flex flex-col gap-2">
      <div tw="px-5 flex flex-col border-b  border-b-beige-lines">
        <HeadingFour>{translate('Job Order List', forceLang)}</HeadingFour>
        <div tw="flex gap-5 overflow-scroll py-5 ">
          {deliveries?.map((delivery) => (
            <NavigationItem
              onClick={() => {
                setSelectedDelivery(delivery);
              }}
              isSelected={
                selectedDelivery?.jobOrder.number === delivery.jobOrder.number
              }
              key={delivery.jobOrder.number}
              delivery={delivery}
            />
          ))}
        </div>
      </div>
      {selectedDelivery && (
        <DeliveryContent forceLang={forceLang} delivery={selectedDelivery} />
      )}
    </div>
  );
}
