import React from 'react';

export default function CompleteOrderBG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="130"
      fill="none"
      viewBox="0 0 70 130"
    >
      <g clipPath="url(#clip0_1899_216732)">
        <path
          stroke="#E4E0CC"
          strokeWidth="2.3"
          d="M27 21h46a6 6 0 016 6v74a6 6 0 01-6 6H27a6 6 0 01-6-6V27a6 6 0 016-6z"
        />
        <rect width="16" height="2.5" x="31" y="35" fill="#E4E0CC" rx="0.4" />
        <rect width="16" height="2.5" x="31" y="42.5" fill="#E4E0CC" rx="0.4" />
        <rect width="9" height="2.5" x="31" y="50" fill="#E4E0CC" rx="0.4" />
        <path
          stroke="#E4E0CC"
          strokeWidth="2.3"
          d="M55.5 54.568V23a2 2 0 012-2h13a2 2 0 012 2v20.097a2 2 0 01-.677 1.5l-13 11.47c-1.291 1.14-3.323.223-3.323-1.499z"
        />
        <circle
          cx="25.5"
          cy="93.5"
          r="18.35"
          fill="#fff"
          stroke="#E4E0CC"
          strokeWidth="2.3"
        />
        <path
          stroke="#E4E0CC"
          strokeLinecap="round"
          strokeWidth="2.4"
          d="M18 93.5l5.21 6.14a.4.4 0 00.61 0L34.545 87"
        />
      </g>
      <defs>
        <clipPath id="clip0_1899_216732">
          <path fill="#fff" d="M0 0H70V130H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
