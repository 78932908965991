import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterDriverAppCompleteHJORouteParam = DefaultRouteParam;

export const helpCenterDriverAppCompleteHJORoute: RouteConfig = {
  name: 'Help Center Driver App Complete HJO',
  Component: React.lazy(() => import('./HelpCenterDriverAppCompleteHJO.view')),
  path: '/help-center/driver-app/complete-hjo',
  isPrivate: false,
  breadcrumb: 'Help Center Driver App Complete HJO',
  options: {
    label: 'Menyelesaikan Hauling Job Order',
    testID: 'MenuHelpCenterDriverAppCompleteHJO',
  },
};
