import { Autocomplete } from '@react-google-maps/api';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import tw from 'twin.macro';
import { mapsAutocompleteOption } from '../../../constant/Map.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { Icon } from '../../atom';

type Props = {
  onLoadAutocomplete(autocomplete: google.maps.places.Autocomplete): void;
  onPlaceChanged(): void;
  onSetInputRef(_inputRef: HTMLInputElement | null): void;
};

const Container = tw.div`w-[640px] h-11 px-3 bg-white rounded shadow absolute
right-0 left-0 mx-auto mt-[29px]`;
const InputBox = tw.div`flex items-center svg:fill-grey-two`;
const Input = tw.input`w-full box-border border outline-none overflow-ellipsis border-transparent text-grey-three`;

export default function MapsAutocomplete({
  onLoadAutocomplete,
  onPlaceChanged,
  onSetInputRef,
}: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const translator = useTranslator();

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    if (inputRef) {
      onSetInputRef(inputRef.current);
    }
  }, [inputRef, onSetInputRef]);

  return (
    <Autocomplete
      onLoad={onLoadAutocomplete}
      onPlaceChanged={onPlaceChanged}
      options={mapsAutocompleteOption}
    >
      <Container id="mapsAutocompleteContainer">
        <InputBox>
          <Icon.Search />
          <Input
            ref={inputRef as MutableRefObject<HTMLInputElement>}
            type="text"
            placeholder={translator.translate('Search Address')}
          />
        </InputBox>
      </Container>
    </Autocomplete>
  );
}
