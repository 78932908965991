import useJobOrderDeleteBulkLogic from '../../hook/jobOrderDeleteBulk/useJobOrderDeleteBulkLogic.hook';
import useJobOrderDeleteBulkTable from '../../hook/jobOrderDeleteBulk/useJobOrderDeleteBulkTable.hook';
import useTranslator from '../../hook/useTranslator.hook';

export default function useJobOrderDeleteBulkViewModel() {
  const translator = useTranslator();

  const bulkLogic = useJobOrderDeleteBulkLogic();
  const bulkTable = useJobOrderDeleteBulkTable({ bulkLogic });

  return {
    translator,
    bulkLogic,
    bulkTable,
  };
}
