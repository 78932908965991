import React from 'react';

export default function DeliveryLocation({
  fill,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7v5.47a1 1 0 0 1-.564.9L13 16m0 0V9m0 7-6-2m0 0-4.684 1.561A1 1 0 0 1 1 14.613V5.5a1 1 0 0 1 .568-.902L7 2m0 12V2m0 0 3.947 1.903m3.736 2.081-1.956-3.496A1 1 0 0 1 13.6 1h3.422a1 1 0 0 1 .923 1.387l-1.467 3.496a1 1 0 0 1-1.795.101Z"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}
