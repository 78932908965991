import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import { ScheduleSearchParam } from '../../model/Schedule.model';
import { DrivingContestListSearchParams } from '../DrivingContest/drivingContest.route';

export type ScheduleAddNavigationState =
  | {
      searchParamsFromScheduleList?: ScheduleSearchParam;
      searchParamsFromDrivingContestList?: DrivingContestListSearchParams;
    }
  | undefined;

export const scheduleAddRoute: RouteConfig = {
  name: 'Add Schedule',
  Component: React.lazy(() => import('./ScheduleAdd.view')),
  path: '/schedule/add',
  isPrivate: true,
};
