import React from 'react';

export function Medium({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15h2v2s0 1 1 1h12c1 0 1-1 1-1v-2h2v3c0 .726-.325 1.276-.79 1.611A2.116 2.116 0 0 1 16 20H2c-.726 0-1.276-.325-1.612-.79A2.116 2.116 0 0 1 0 18v-3ZM10 0v10l3-2.5 1.2 1.6L9 13.25 3.8 9.1 5 7.5 8 10V0h2Z"
        fill={fill || '#616161'}
      />
    </svg>
  );
}

export function Small({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.6 9h1.2v1.2s0 .6.6.6h7.2c.6 0 .6-.6.6-.6V9h1.2v1.8c0 .436-.195.766-.474.967A1.27 1.27 0 0 1 10.2 12H1.8c-.436 0-.766-.195-.967-.474A1.27 1.27 0 0 1 .6 10.8V9ZM6.6 0v6l1.8-1.5.72.96L6 7.95 2.88 5.46l.72-.96L5.4 6V0h1.2Z"
        fill={fill || '#616161'}
      />
    </svg>
  );
}
