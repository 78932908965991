import { ShipperOrderDetailTab } from '../../constant';
import useSOCreateGoods from '../../hook/shipperOrder/useSOCreateGoods.hook';
import useTranslator from '../../hook/useTranslator.hook';
import { generateSODetailActivityFormData } from '../../util/shipperOrderDetail.util';
import useImageGalleryController from '../ImageGallery/hooks/useImageGalleryController.hook';
import useShipperOrderInfo from './hooks/useShipperOrderInfo.hook';
import useSODEditGoodsController from './hooks/useSODEditGoodsController.hook';
import useSODetailActivity from './hooks/useSODetailActivity.hook';
import useSODetailConfirmation from './hooks/useSODetailConfirmation.hook';
import useSODetailController from './hooks/useSODetailController.hook';
import useSODetailCost from './hooks/useSODetailCost.hook';
import useSODetailCreateAT from './hooks/useSODetailCreateAT.hook';
import useSODetailEditGoodsForm from './hooks/useSODetailEditGoodsForm.hook';
import useSODetailHeader from './hooks/useSODetailHeader.hook';
import useTrackingSO from './hooks/useTrackingSO.hook';

export default function useShipperOrderDetailViewModel() {
  const translator = useTranslator();

  const soDetail = useShipperOrderInfo();
  const imageGalleryController = useImageGalleryController();

  const soDetailController = useSODetailController({ info: soDetail });
  const trackingSO = useTrackingSO({
    skip: soDetailController.tab !== ShipperOrderDetailTab.TRACKING_ORDER,
  });

  const soDetailHeader = useSODetailHeader({
    soInfo: soDetail.soInfo,
    showConfirmationModal: soDetailController.showConfirmationModal,
    setIsLoading: soDetailController.setIsLoading,
    setIsCurrentSectionChanged: soDetailController.setIsCurrentSectionChanged,
    handleConfirmChangeSection: soDetailController.handleConfirmChangeSection,
  });

  const soDetailCost = useSODetailCost({
    soInfo: soDetail.soInfo,
    showConfirmationModal: soDetailController.showConfirmationModal,
    setIsLoading: soDetailController.setIsLoading,
    setIsCurrentSectionChanged: soDetailController.setIsCurrentSectionChanged,
    handleConfirmChangeSection: soDetailController.handleConfirmChangeSection,
  });

  const soDetailActivity = useSODetailActivity({
    soInfo: soDetail.soInfo,
    showConfirmationModal: soDetailController.showConfirmationModal,
    setIsLoading: soDetailController.setIsLoading,
    setIsCurrentSectionChanged: soDetailController.setIsCurrentSectionChanged,
    handleConfirmChangeSection: soDetailController.handleConfirmChangeSection,
  });

  const soDetailUpdateGoodsForm = useSODetailEditGoodsForm({
    soInfo: soDetail.soInfo,
    activityFormData: soDetailActivity.activityFormData,
  });

  const soDetailEditGoodsController = useSODEditGoodsController({
    activityFormData: soDetailActivity.activityFormData,
    soDetailUpdateGoodsForm,
  });

  const soDetailCreateGoods = useSOCreateGoods({
    setFieldValue: soDetailActivity.soActivityForm.setFieldValue,
  });

  const soDetailCreateAT = useSODetailCreateAT({
    soInfo: soDetail.soInfo,
    activitiesError: soDetailActivity.soActivityForm.errors.activities,
    activitiesValue: soDetailActivity.soActivityForm.values,
    activitiesFormSetValues: soDetailActivity.soActivityForm.setValues,
    setGoodsByActivityIndex: soDetailCreateGoods.setGoodsByActivityIndex,
    setInitialGoodsValue: soDetailCreateGoods.goodsForm.setInitialGoodsValue,
  });

  const soDetailConfirmation = useSODetailConfirmation({
    soDetailController,
    soDetailActivity,
    soDetailCost,
    soDetailHeader,
    imageGalleryController,
  });

  const soActivityFormData = generateSODetailActivityFormData({
    soDate: soDetail.soInfo?.date,
    isLoading: soDetailController.isLoading,
    translator,
    formState: soDetailActivity.soActivityForm,
    soDetailActivity,
    soDetailCreateAT,
  });

  return {
    translator,
    soDetailCreateGoods,
    soDetail,
    soDetailController,
    soDetailHeader,
    soDetailCost,
    soDetailActivity,
    soDetailUpdateGoodsForm,
    soDetailEditGoodsController,
    soActivityFormData,
    trackingSO,
    imageGalleryController,
    soDetailConfirmation,
  };
}
