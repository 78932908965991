import React from 'react';
import tw from 'twin.macro';
import { Paper } from '../../atom';

const HeaderContainer = tw.div`p-4  border-b border-b-beige-lines`;
const Container = tw.div`p-5 grid gap-5`;
const FooterContainer = tw.div`p-4 border-t border-t-beige-lines`;

type SectionProps = React.PropsWithChildren<{
  header: React.ReactNode;
  footer?: React.ReactNode;
}>;

export default function SOSection({ header, children, footer }: SectionProps) {
  return (
    <Paper tw="mb-10">
      <HeaderContainer>{header}</HeaderContainer>
      <Container>{children}</Container>
      {footer && <FooterContainer>{footer}</FooterContainer>}
    </Paper>
  );
}
