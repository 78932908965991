import React, { ReactNode, useCallback } from 'react';
import tw from 'twin.macro';
import { RouteConfig } from '../../../config/router/router.config';
import useRunnerEventList from '../../../hook/runnerEvent/useRunnerEventList.hook';
import { drivingContestRoute } from '../../../view/DrivingContest/drivingContest.route';
import runnerRoute, {
  getRunnerMapUrl,
} from '../../../view/Runner/Runner.route';
import { SidebarItem } from '../../molecule';

type Props = {
  items: RouteConfig[];
  currentPath: string;
  onItemClick(path: string, externalLink?: () => void): void;
  expandedSidebar: boolean;
};

const Container = tw.div`py-2 pr-1`;

export default function Sidebar({
  items,
  currentPath,
  onItemClick,
  expandedSidebar,
}: Props) {
  const { isDrivingContestInProgress } = useRunnerEventList();

  const generateIcon = useCallback(
    (icon?: ReactNode) => {
      if (!icon) return undefined;
      return React.cloneElement(icon as React.ReactElement, {
        isExpanded: expandedSidebar,
      });
    },
    [expandedSidebar],
  );

  const generateSubMenu = useCallback(
    (subItems: RouteConfig[]) =>
      subItems.map((item: RouteConfig) => ({
        onClick: (path: string) => onItemClick(path, item.customNavigation),
        path: item.path,
        icon: item.options?.icon,
        label: item.options?.label,
        testID: item.options?.testID,
        isSelected: currentPath.includes(item.path),
        hasDivider: item.options?.hasDivider,
      })),
    [currentPath, onItemClick],
  );

  const checkIsSelected = (item: RouteConfig) => {
    if ((!!item.customNavigation && !item.path) || !item.path) return false;
    const currentPathArr = currentPath.split('/');
    const itemPathArr = item.path.split('/');

    return item.activePath
      ? item.activePath?.includes(currentPath)
      : currentPathArr[1] === itemPathArr[1];
  };

  return (
    <Container>
      {items.map((item: RouteConfig) => {
        const isRunnerMapSidebar = !!(
          item?.options?.testID === runnerRoute.options?.testID
        );
        const isDrivingContestSidebar = item.path === drivingContestRoute.path;
        const hideEventSidebar =
          isDrivingContestSidebar && !isDrivingContestInProgress;
        const hasDivider =
          (isRunnerMapSidebar && isDrivingContestInProgress) ||
          !!item?.options?.hasDivider;

        // do not render sidebar, when there is no `options` OR event is not in progress
        if (!item.options || hideEventSidebar) return null;

        return (
          <SidebarItem
            key={`SidebarItem-${item.options.testID}`}
            onClick={(path: string) => onItemClick(path, item.customNavigation)}
            path={item.path}
            label={item.options.label}
            // to pass custom props to the sidebar icon
            icon={generateIcon(item.options.icon)}
            testID={item.options.testID}
            event={item.options.event}
            isSelected={checkIsSelected(item)}
            hasDivider={hasDivider}
            hasSub={
              item.options?.hasSub && generateSubMenu(item.options.hasSub)
            }
            expandedSidebar={expandedSidebar}
            externalPath={
              item.name === runnerRoute.name ? getRunnerMapUrl() : undefined
            }
          />
        );
      })}
    </Container>
  );
}
