import React from 'react';
import tw from 'twin.macro';
import { Modal } from '..';
import { UseBlockerConfirmation } from '../../../hook/useBlockerConfirmation/useBlockerConfirmation.hook';
import { BodyOne } from '../../atom/Text/Text.atom';

const ModalContainerStyle = tw`p-8 w-[760px]`;
const ModalPortalStyle = tw`z-[1000]`;
const ModalWithHeaderCtaStyle = tw`justify-between`;

const ModalActionButtonContainer = tw`justify-between`;
const ModalBodyContainer = tw.div`flex flex-col py-5`;
const ModalLabel = tw(BodyOne)`whitespace-pre-wrap`;

type Props = {
  blocker: UseBlockerConfirmation;
};
export default function BlockerModal({ blocker }: Props) {
  return (
    <Modal.WithClose
      modalStyle={ModalPortalStyle}
      css={ModalContainerStyle}
      onClose={blocker.handleCloseAction}
      onOverlayClick={blocker.handleCloseAction}
      title={blocker.title}
      ctaStyle={ModalWithHeaderCtaStyle}
      callAction={{
        label: blocker.actionLabel,
        action: blocker.handleConfirmAction,
      }}
      cancelAction={{
        label: blocker.cancelLabel,
        action: blocker.handleCancelAction,
      }}
      actionButtonContainer={ModalActionButtonContainer}
    >
      <ModalBodyContainer>
        <ModalLabel>{blocker.description}</ModalLabel>
      </ModalBodyContainer>
    </Modal.WithClose>
  );
}
