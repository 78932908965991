import React, { ReactNode, useEffect, useRef, useState } from 'react';
import tw, { styled, TwStyle } from 'twin.macro';
import { Button, Icon, Text } from '../../atom';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard.molecule';
import Tooltip from '../Tooltip/Tooltip.molecule';

const Container = tw.div`rounded border border-beige-lines`;
const ButtonContainer = tw.div`inline-grid grid-template-columns[26px 1fr] gap-1 w-max`;
const IconButtonContainer = tw.div`flex items-center`;
const ActionContainer = tw.div`flex gap-2`;
const LabelContainer = tw.div`flex flex-1 gap-2`;
const Label = styled(Text.HeadingFour)(
  ({ isCollapsible }: { isCollapsible?: boolean }) => [
    tw`self-center`,
    isCollapsible && tw`ml-2 line-break[anywhere] whitespace-nowrap`,
  ],
);
const SubLabel = styled(Text.BodyTwelve)(
  ({ isCollapsible }: { isCollapsible?: boolean }) => [
    tw`self-center text-text-color-text-secondary`,
    isCollapsible && tw`ml-2 line-break[anywhere] whitespace-nowrap`,
  ],
);
const CopyOnlyContainer = styled.div(() => [
  tw`ml-1`,
  `
  & svg {
    width:16px;
    height:16px;
  }
`,
]);

const IconDropdownContainer = styled.div(
  ({ expanded, isDisabled }: { expanded?: boolean; isDisabled?: boolean }) => [
    tw`flex items-center p-1.5 -mx-1.5 rounded-full transform[rotate(90deg)] duration-200 group-hover:bg-orange-hover`,
    isDisabled &&
      tw`text-grey-placeholder! cursor-default group-hover:bg-neutral-50`,
    expanded && tw`transform[rotate(-90deg)]`,
  ],
);

export interface CollapsiblePanelAction {
  icon: React.ReactNode;
  text: string;
  click(): void;
}
interface Props {
  css?: TwStyle;
  title: ReactNode;
  action?: CollapsiblePanelAction;
  additionalAction?: CollapsiblePanelAction;
  customAction?: React.ReactNode;
  customActionContainerStyle?: TwStyle;
  collapsible?: boolean;
  containerStyle?: TwStyle;
  contentStyle?: TwStyle;
  headerStyle?: TwStyle;
  expandedHeaderStyle?: TwStyle;
  titleStyle?: TwStyle;
  iconAttributes?: React.SVGProps<SVGSVGElement>;
  iconContainerStyle?: TwStyle;
  information?: React.ReactNode;
  isCollapsed?: boolean;
  isDropdownIconOnRight?: boolean;
  valueToCopy?: string;
  soId?: string;
  subtitle?: string;
  onToggle?: () => void;
}
export default function CollapsiblePanel({
  css,
  title,
  collapsible,
  children,
  action,
  titleStyle,
  additionalAction,
  expandedHeaderStyle,
  containerStyle,
  contentStyle,
  headerStyle,
  information,
  customAction,
  customActionContainerStyle,
  isDropdownIconOnRight,
  valueToCopy,
  soId,
  subtitle,
  iconAttributes,
  iconContainerStyle,
  isCollapsed = false,
  onToggle,
}: React.PropsWithChildren<Props>) {
  const [expanded, setExpanded] = useState(!isCollapsed);

  useEffect(() => {
    setExpanded(!isCollapsed);
  }, [isCollapsed]);

  const tooltipRef = useRef(null);
  const tooltipTitleRef = useRef(null);

  const [visible, setVisible] = useState(false);
  const [isTooltipTitleVisible, setIsTooltipTitleVisible] = useState(false);

  return (
    <Container
      css={[css, containerStyle]}
      data-testid="CollapsiblePanel:Container"
    >
      <div
        css={[
          tw`bg-beige-bg flex p-3 px-5 border-b border-b-beige-lines cursor-default`,
          collapsible && tw`cursor-pointer`,
          collapsible && !expanded && tw`border-b-0`,
          collapsible && expanded && expandedHeaderStyle,
          headerStyle,
        ]}
        tabIndex={collapsible ? 0 : undefined}
        role="button"
        onKeyDown={(e) => {
          if (!collapsible) return;
          if (['Space', 'Enter'].includes(e.code)) {
            e.stopPropagation();
            setExpanded((v) => !v);
            onToggle?.();
          }
        }}
        onClick={(e) => {
          if (!collapsible) return;
          e.stopPropagation();
          setExpanded((v) => !v);
          onToggle?.();
        }}
      >
        {collapsible && !isDropdownIconOnRight && (
          <IconDropdownContainer expanded={expanded} css={iconContainerStyle}>
            <Icon.ChevronRounded {...iconAttributes} />
          </IconDropdownContainer>
        )}
        <LabelContainer>
          <Tooltip
            targetRef={tooltipTitleRef}
            visible={isTooltipTitleVisible}
            addArrow={false}
            variant="light"
            containerStyle={tw`z-[1000] overflow-wrap[anywhere]`}
            contentStyle={tw`w-full whitespace-pre-wrap`}
            placement="bottom-start"
          >
            {title}
          </Tooltip>
          {typeof title === 'string' ? (
            <Label
              ref={tooltipTitleRef}
              onMouseEnter={() => setIsTooltipTitleVisible(true)}
              onMouseLeave={() => setIsTooltipTitleVisible(false)}
              css={titleStyle}
            >
              {title}
            </Label>
          ) : (
            title
          )}

          {subtitle && <SubLabel>{subtitle}</SubLabel>}

          {valueToCopy && (
            <CopyOnlyContainer>
              <CopyToClipboard valueToCopy={valueToCopy} soId={soId} />
            </CopyOnlyContainer>
          )}
          {information && (
            <div tw="flex items-center justify-center">
              <div
                ref={tooltipRef}
                onMouseEnter={() => setVisible(true)}
                onMouseLeave={() => setVisible(false)}
              >
                <Icon.InfoOutlined />
              </div>
              <Tooltip
                targetRef={tooltipRef}
                visible={visible}
                variant="light"
                placement="top-start"
                offset={[-20, 20]}
              >
                {information}
              </Tooltip>
            </div>
          )}
        </LabelContainer>

        <ActionContainer>
          {(additionalAction || action || customAction) && (
            <div tw="flex ml-auto" css={[customActionContainerStyle]}>
              {additionalAction && (
                <Button.Text
                  onClick={(e) => {
                    e.stopPropagation();
                    additionalAction.click();
                  }}
                >
                  <ButtonContainer>
                    <IconButtonContainer>
                      {additionalAction.icon}
                    </IconButtonContainer>
                    <Text.HeadingFive tw="text-grey-two">
                      {additionalAction.text}
                    </Text.HeadingFive>
                  </ButtonContainer>
                </Button.Text>
              )}
              {action && (
                <Button.Text
                  css={additionalAction && tw`ml-5`}
                  onClick={(e) => {
                    e.stopPropagation();
                    action.click();
                  }}
                >
                  <ButtonContainer>
                    <IconButtonContainer>{action.icon}</IconButtonContainer>
                    <Text.HeadingFive tw="text-orange">
                      {action.text}
                    </Text.HeadingFive>
                  </ButtonContainer>
                </Button.Text>
              )}
              {customAction && customAction}
            </div>
          )}
          {collapsible && isDropdownIconOnRight && (
            <IconDropdownContainer expanded={expanded}>
              <Icon.ChevronRounded />
            </IconDropdownContainer>
          )}
        </ActionContainer>
      </div>
      {((collapsible && expanded) || !collapsible) && (
        <div css={[tw`p-5`, contentStyle]}>{children}</div>
      )}
    </Container>
  );
}
