import React from 'react';
import { Icon, TableNoData } from '../../component/atom';
import { BeforeLeaveAlertModal } from '../../component/molecule';
import { UploadFileModal } from '../../component/organism';
import { BulkActionLayout } from '../../component/template';
import useDriverAddBulkViewModel from './DriverAddBulk.viewModel';

export default function DriverAddBulkView() {
  const { translator, uploadModal, bulkLogic, bulkTable } =
    useDriverAddBulkViewModel();

  return (
    <>
      <BulkActionLayout
        actionLabel={translator.translate('to start the registration process.')}
        descriptionTooltipLabel={bulkLogic.filename}
        descriptionLabel={translator.translate(
          'Make sure the list below is the driver list that you want to register to your organization. Incompatible data formats will not be registered into the system.',
        )}
        headerLabel={bulkLogic.truncatedFilename}
        cancelLabel={translator.translate('Re-upload')}
        submitLabel={translator.translate('Register')}
        doneLabel={translator.translate('Done')}
        table={bulkTable.table}
        entityLength={bulkLogic.driversWithStatusDetail.length}
        showDoneBtn={bulkLogic.showDoneBtn}
        isLoading={bulkLogic.mutationsIsLoading}
        noDataPage={
          <TableNoData
            icon={<Icon.NoDataFound />}
            titleLabel={translator.translate(
              "You don't have any imported Drivers that you want to register",
            )}
            descriptionLabel={translator.translate(
              'Try to import some Drivers by clicking the Re-upload button, or you can click the button below',
            )}
            actionLabel={translator.translate('Re-upload')}
            onClickAction={uploadModal.onOpenUploadModal}
          />
        }
        onCancel={uploadModal.onOpenUploadModal}
        onBack={bulkLogic.handleDone}
        onSubmit={bulkLogic.handleAddBulk}
        onDone={bulkLogic.handleDone}
      />

      {uploadModal.showUploadModal && (
        <UploadFileModal
          filename={uploadModal.filename}
          error={uploadModal.error}
          dragActive={uploadModal.dragActive}
          isLoading={uploadModal.isLoading}
          requiredLabels={uploadModal.requiredLabels}
          sampleLink={uploadModal.sampleLink}
          onClose={uploadModal.onCloseUploadModal}
          handleReupload={uploadModal.handleReupload}
          handleDrag={uploadModal.handleDrag}
          handleDrop={uploadModal.handleDrop}
          handleChange={uploadModal.handleChange}
          subtitleLabel={uploadModal.subtitleLabel}
        />
      )}

      <BeforeLeaveAlertModal hasUnsavedChanges={bulkLogic.hasUnsavedChanges} />
    </>
  );
}
