import React, { useRef } from 'react';
import { useHoverDirty } from 'react-use';
import tw from 'twin.macro';
import { Button, Chip, Icon, TableNoData, Text } from '../../../component/atom';
import IndeterminateCheckbox from '../../../component/atom/Checkbox/IndeterminateCheckbox.atom';
import {
  DrawerPortal,
  Modal,
  TanstackTable,
  Tooltip,
} from '../../../component/molecule';
import { AllCheckboxState } from '../../../constant';
import { UseJobOrderInfoHookObj } from '../../../hook/useJobOrderInfo/useJobOrderInfo.hook';
import { JobOrderInfo } from '../../../model/JobOrder.model';
import { numberFormatter } from '../../../util/formatter.util';
import { getLabelAndCountFromFilterLabel } from '../../../util/helper.util';
import ImageGallery from '../../ImageGallery/ImageGallery.view';
import { UseJODetailSpendingReject } from '../hooks/useJODetailSpendingReject.hook';
import useJODetailSpendingReport from '../hooks/useJODetailSpendingReport.hook';
import { UseJODetailSpendingReportTable } from '../hooks/useJODetailSpendingReportTable.hook';
import JODetailSpendingDetail from './JODetailSpendingDetail';
import JODetailSpendingRejectModal from './JODetailSpendingRejectModal';
import JODetailSpendingReportFilter from './JODetailSpendingReportFilter';

// #region STYLED
const Root = tw.div`p-5 flex flex-col animate-slide-in-left`;
const Summaries = tw.div`pb-4 flex items-center w-full space-x-4`;
const SummaryCard = tw.div`px-4 h-full py-3 flex flex-col w-full rounded-md border bg-beige-bg border-beige-lines`;
const SummaryTitle = tw(Text.BodyFourteen)``;
const SummarySubtitle = tw(
  Text.HeadingThree,
)`max-w-[196px] break-words whitespace-normal overflow-hidden overflow-ellipsis display[-webkit-box] -webkit-line-clamp[2] -webkit-box-orient[vertical]`;
const Filters = tw.div`pb-4 flex items-center space-x-2`;
const AllCheckboxContainer = tw.div`flex justify-center items-center gap-2 pl-5 pr-2 animate-slide-in-left`;
const ActionButtonContainer = tw.div`flex items-center space-x-3 animate-slide-in-left`;
const RejectBtn = tw(
  Button.Solid,
)` bg-status-alert pl-2.5 pr-3.5 text-white  hover:(bg-status-alert opacity-70) active:(bg-status-alert opacity-70)`;
const RejectTooltipText = tw(Text.BodyFourteen)`bg-white`;
const ApproveButton = tw(
  Button.Solid,
)`bg-status-success text-white pl-2.5 pr-3.5 h-11  hover:(bg-status-success opacity-70) active:(bg-status-success opacity-70)`;
const ActionButtonText = tw(Text.HeadingFour)`text-white`;
const CheckboxLabel = tw.label`font-medium tracking-wide`;
const ToggleChip = tw(Chip.Small)`bg-transparent text-grey-two`;
const SortChip = tw(Chip.Small)`bg-orange-hover text-orange border-0`;
const FirstFilterChip = tw(
  Chip.Small,
)`bg-orange-hover text-orange border-0 truncate max-w-[150px]`;

const SpendingModalStyle = tw`p-0 max-w-[1024px] max-h-[768px] h-auto w-auto`;
const SpendingModalTitleStyle = tw`h-16 p-4 block mb-0`;

// #endregion

type Props = {
  jobOrderInfo: UseJobOrderInfoHookObj;
};
type RejectButtonProps = {
  spendingReportTable: UseJODetailSpendingReportTable;
  spendingReportReject: UseJODetailSpendingReject;
};

function RejectButton({
  spendingReportTable,
  spendingReportReject,
}: RejectButtonProps) {
  // show tooltip when disabled button hovered
  const tooltipRef = useRef(null);
  const isHovered = useHoverDirty(tooltipRef);

  return (
    <>
      <RejectBtn
        type="button"
        ref={tooltipRef}
        css={[
          spendingReportTable.disableRejectButton &&
            tw`cursor-default opacity-100 bg-grey-four hover:(opacity-100 bg-grey-four)`,
        ]}
        onClick={spendingReportReject.handleBulkReject}
      >
        <Icon.Close />
        <ActionButtonText>{spendingReportTable.rejectLabel}</ActionButtonText>
      </RejectBtn>

      <Tooltip
        withPortal
        variant="light"
        placement="top"
        targetRef={tooltipRef}
        visible={spendingReportTable.disableRejectButton && isHovered}
        containerStyle={tw`max-w-xs`}
      >
        <RejectTooltipText>
          {spendingReportTable.rejectTooltipLabel}
        </RejectTooltipText>
      </Tooltip>
    </>
  );
}

export default function JODetailSpendingReport({ jobOrderInfo }: Props) {
  const {
    translator,
    imageGalleryController,
    spendingReportController,
    spendingReportList,
    spendingReportFilter,
    spendingReportTable,
    spendingReportReject,
    spendingReportApprove,
  } = useJODetailSpendingReport();

  const joInfo = jobOrderInfo.joInfoData as JobOrderInfo; // at this point, `joInfoData` should NOT be undefined
  const { totalPendingExpense, totalApprovedExpense } = joInfo.expenseSummary;
  const travelBudget = joInfo?.travelExpense ?? 0;
  const remainingBudget = travelBudget - totalApprovedExpense;

  return (
    <Root>
      <Summaries>
        <SummaryCard>
          <SummaryTitle>{translator.translate('Travel Budget')}</SummaryTitle>
          <SummarySubtitle>{`Rp${numberFormatter(
            travelBudget,
          )}`}</SummarySubtitle>
        </SummaryCard>
        <SummaryCard>
          <SummaryTitle>
            {translator.translate('Remaining Travel Budget')}
          </SummaryTitle>
          <SummarySubtitle>{`Rp${numberFormatter(
            remainingBudget,
          )}`}</SummarySubtitle>
        </SummaryCard>
        <SummaryCard>
          <SummaryTitle>{translator.translate('Approved')}</SummaryTitle>
          <SummarySubtitle>{`Rp${numberFormatter(
            totalApprovedExpense,
          )}`}</SummarySubtitle>
        </SummaryCard>
        <SummaryCard>
          <SummaryTitle>{translator.translate('Need Review')}</SummaryTitle>
          <SummarySubtitle>{`Rp${numberFormatter(
            totalPendingExpense,
          )}`}</SummarySubtitle>
        </SummaryCard>
      </Summaries>

      <Filters
        css={[!!spendingReportTable.checkedItems.length && tw`justify-between`]}
      >
        <AllCheckboxContainer>
          <IndeterminateCheckbox
            id="allCheckbox"
            name="allCheckbox"
            checked={
              spendingReportTable.allCheckbox === AllCheckboxState.CHECKED
            }
            indeterminate={
              spendingReportTable.allCheckbox === AllCheckboxState.INDETERMINATE
            }
            disabled={spendingReportList.query.list.length === 0}
            onChange={spendingReportTable.onChangeAllCheckbox}
          />
          <CheckboxLabel htmlFor="allCheckbox">
            {translator.translate('All')}
          </CheckboxLabel>
        </AllCheckboxContainer>

        {spendingReportTable.checkedItems.length ? (
          <ActionButtonContainer>
            <RejectButton
              spendingReportTable={spendingReportTable}
              spendingReportReject={spendingReportReject}
            />

            <ApproveButton
              type="button"
              onClick={spendingReportTable.onNavigateToBulkApprove}
            >
              <Icon.Check />
              <ActionButtonText>
                {translator.translate('Approve')}
              </ActionButtonText>
            </ApproveButton>
          </ActionButtonContainer>
        ) : (
          <>
            <ToggleChip
              label={translator.translate('Filter')}
              left={<Icon.FilterV2 />}
              selected={spendingReportFilter.showFilter}
              onClick={spendingReportFilter.onOpenFilter}
              testID="SpendingReport:FilterToggle"
            />

            <SortChip
              label={spendingReportFilter.toggleSortLabel}
              left={<Icon.Sort />}
              onClick={spendingReportFilter.onOpenFilter}
              testID="SpendingReport:FilterToggleSort"
            />

            {spendingReportFilter.activeQueries.length > 0 && (
              <FirstFilterChip
                label={
                  getLabelAndCountFromFilterLabel(
                    spendingReportFilter.activeQueries[0],
                  ).label
                }
                numberRight={
                  getLabelAndCountFromFilterLabel(
                    spendingReportFilter.activeQueries[0],
                  ).count
                }
                onClick={spendingReportFilter.onOpenFilter}
                testID="SpendingReport:FirstFilter"
              />
            )}

            {spendingReportFilter.activeQueries.length > 1 && (
              <SortChip
                label={`+${spendingReportFilter.activeQueries.length - 1}`}
                onClick={spendingReportFilter.onOpenFilter}
                testID="SpendingReport:FilterMoreThanOne"
              />
            )}
          </>
        )}
      </Filters>

      <TanstackTable
        testID="SpendingReport"
        stickyHeader={spendingReportList.query.list.length > 10}
        table={spendingReportTable.table}
        onPageLimitClick={spendingReportTable.onPageLimitClick}
        onPageClick={spendingReportTable.onPageClick}
        noDataPage={
          <TableNoData
            rootStyle={[tw`h-[40vh]`]}
            icon={
              spendingReportTable.noResultFound ? (
                <Icon.NoResultFound />
              ) : (
                <Icon.NoDataFound />
              )
            }
            titleLabel={translator.translate(
              spendingReportTable.noResultFound
                ? 'No Result Found'
                : 'There are no submitted expenses',
            )}
            descriptionLabel={translator.translate(
              spendingReportTable.noResultFound
                ? 'Try a different search terms so we can show you some available Expenses'
                : 'The list will be updated after Driver submitted their expenses',
            )}
            actionLabel={
              spendingReportTable.noResultFound
                ? translator.translate('Set Back to Default List')
                : undefined
            }
            onClickAction={
              spendingReportTable.noResultFound
                ? spendingReportTable.onTableNoDataActionClick
                : undefined
            }
          />
        }
        tableContainerStyle={[tw`h-[unset] rounded-none`]}
        paginationContainerStyle={tw`border-t-0`}
      />

      <DrawerPortal
        isOpen={spendingReportFilter.showFilter}
        handleClose={spendingReportFilter.onCloseDrawerPortal}
      >
        <JODetailSpendingReportFilter
          spendingReportFilter={spendingReportFilter}
        />
      </DrawerPortal>

      {!!spendingReportController.showSpendingDetail && (
        <Modal.WithClose
          onClose={spendingReportController.handleCloseDetailModal}
          onOverlayClick={spendingReportController.handleCloseDetailModal}
          css={SpendingModalStyle}
          titleStyle={SpendingModalTitleStyle}
        >
          <JODetailSpendingDetail
            id={spendingReportController.showSpendingDetail.id}
            handleApproveExpense={spendingReportApprove.handleApproveExpense}
            handleOpenRejectModal={spendingReportReject.handleOpenRejectModal}
            handleClickThumbnail={spendingReportController.handleClickThumbnail}
          />
        </Modal.WithClose>
      )}

      {!!spendingReportReject.modalVisible && (
        <Modal.WithClose
          onClose={spendingReportReject.handleCloseRejectModal}
          onOverlayClick={spendingReportReject.handleCloseRejectModal}
          css={SpendingModalStyle}
          titleStyle={SpendingModalTitleStyle}
        >
          <JODetailSpendingRejectModal
            spendingReportReject={spendingReportReject}
            handleClose={spendingReportReject.handleCloseRejectModal}
          />
        </Modal.WithClose>
      )}

      {!!imageGalleryController.gallery && (
        <ImageGallery
          controller={{
            ...imageGalleryController,
            handleGalleryClose:
              spendingReportController.handleClickCloseGallery,
          }}
          showDownload
          testID="JobOrderSpendingDetail:ImageGallery"
          title={translator.translate('Expense Image')}
        />
      )}
    </Root>
  );
}
