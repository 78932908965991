import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import useTranslator from '../../../hook/useTranslator.hook';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { snackbarAction } from '../../../store/snackbar.store';
import { errorCodeToLabel } from '../../../util/error.util';

export default function useTrackingSO({ skip }: { skip?: boolean }) {
  const params = useParams();

  const translator = useTranslator();
  const dispatch = useDispatch();
  const id = String(params?.id);
  const { data, isError, error, isLoading, isUninitialized } =
    api.useGetTrackingSOQuery(
      { id },
      {
        refetchOnMountOrArgChange: true,
        skip: !id || skip,
      },
    );

  // #region ERROR
  useEffect(() => {
    if (!isError) return;
    const err = (error as FetchBaseQueryError).data as ApiErrorResponse;
    dispatch(
      snackbarAction.show({
        type: SnackbarTheme.warning,
        message: translator.translate(errorCodeToLabel(err.error.code)),
      }),
    );
  }, [dispatch, error, isError, translator]);
  // #endregion

  return { data, error, isLoading, isUninitialized };
}
