import React, { useMemo } from 'react';
import tw, { styled, theme } from 'twin.macro';
import Spinner from '../Icon/SVG/Spinner';

type Props = {
  size?: 'large' | 'medium' | 'small' | 'mini';
  color?: string;
};

const IconContainer = styled.div(`
  @-webkit-keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  height: 100%;
  width: 100%;
  animation: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0;
  animation-iteration-count: infinite;
`);

const Container = styled.div(
  ({ size }: { size?: 'large' | 'medium' | 'small' | 'mini' }) => [
    tw`inline-block`,
    size === 'large' && tw`w-16 h-16`,
    size === 'medium' && tw`w-11 h-11`,
    size === 'small' && tw`w-6 h-6`,
  ],
);

const measurementMapper = (size: 'large' | 'medium' | 'small' | 'mini') => {
  const sizeMapper: Record<string, number> = {
    large: 64,
    medium: 44,
    small: 24,
    mini: 18,
  };
  return sizeMapper[size];
};

export default function LoadingIndicator({
  size = 'medium',
  color = theme`colors.orange`,
}: Props) {
  const measurement = useMemo(() => measurementMapper(size), [size]);
  return (
    <Container size={size}>
      <IconContainer>
        <Spinner
          type={size}
          color={color}
          height={measurement}
          width={measurement}
          viewBox={`0 0 ${measurement} ${measurement}`}
        />
      </IconContainer>
    </Container>
  );
}
