import debounce from 'lodash/debounce';
import React, {
  createRef,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FixedSizeList as List, ListOnScrollProps } from 'react-window';
import tw, { styled, theme, TwStyle } from 'twin.macro';
import { Icon, IconButton, LoadingIndicator } from '../component/atom';
import { Vehicle } from '../model/Vehicle.model';

type VehicleAutocompleteHeightProps = {
  list: number;
  item: number;
};
export type VehicleAutocompleteProps = {
  alwaysOpen?: boolean;
  datas?: Vehicle[];
  disabled?: boolean;
  error?: string;
  insideLabel?: string;
  loading?: boolean;
  placeholder?: string;
  selectedOption?: Vehicle;
  value?: string;
  inputStyle?: TwStyle;
  onBlurAutocomplete?(e?: FocusEvent<HTMLInputElement>): void;
  onRemoveData?(): void;
  onFetchMore?(): void;
  refetch?(): void;
  changeInputText?(data?: string): void;
  changeData?(data?: Vehicle): void;
};

const IconContainer = styled.div(
  ({ isDropdownOpen }: { isDropdownOpen?: boolean }) => [
    tw`z-10 rotate-90 mr-[3px]`,
    isDropdownOpen && tw`-rotate-90`,
  ],
);

const normalItemHeight = 64;
const normalListHeight = normalItemHeight * 5;

export default function useVehicleAutocomplete({
  datas,
  disabled,
  loading,
  selectedOption,
  value,
  refetch = () => {},
  onBlurAutocomplete = () => {},
  onRemoveData = () => {},
  onFetchMore = () => {},
  changeInputText = () => {},
  changeData = () => {},
}: VehicleAutocompleteProps) {
  // #region GENERAL
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [selectedAutocompleteOption, setSelectedAutocompleteOption] = useState<
    Vehicle | undefined
  >(selectedOption || undefined);

  const debounceFetchMore = debounce(onFetchMore, 1000, { maxWait: 3000 });

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    if (
      selectedOption &&
      selectedOption !== selectedAutocompleteOption &&
      !openDropdown
    ) {
      setSelectedAutocompleteOption(selectedOption);
    }
  }, [openDropdown, selectedAutocompleteOption, selectedOption, value]);

  const isOpenSearch = !!openSearch || !value;
  const autocompleteHeight: VehicleAutocompleteHeightProps = useMemo(
    () => ({ list: normalListHeight, item: normalItemHeight }),
    [],
  );

  const itemHeight = autocompleteHeight.item;

  const listHeight = useMemo(() => {
    if (!datas) return normalItemHeight;
    return autocompleteHeight.item * datas.length > autocompleteHeight.list
      ? autocompleteHeight.list
      : autocompleteHeight.item * datas.length || autocompleteHeight.item;
  }, [autocompleteHeight, datas]);

  const inputRef = createRef<HTMLInputElement>();
  const listRef = useRef<List>(null);

  // #endregion

  const handleFocus = useCallback(() => {
    if (!disabled) {
      setOpenSearch(true);
    }
    setOpenDropdown(true);
  }, [disabled]);

  const handleClickTextField = useCallback(() => {
    if (!disabled) {
      refetch();
      setOpenSearch(true);
    }
  }, [disabled, refetch]);

  const handleSelect = useCallback(
    (option: Vehicle) => {
      setSearchValue(option.name);
      changeData(option);
      onBlurAutocomplete();
      setSelectedAutocompleteOption(option);
    },
    [changeData, onBlurAutocomplete],
  );
  const handleClickAway = useCallback((): void => {
    if (!openDropdown) return;
    if (selectedAutocompleteOption && value) {
      setSearchValue(selectedAutocompleteOption.name);
    }
    onBlurAutocomplete();
    setOpenSearch(false);
    setOpenDropdown(false);
  }, [onBlurAutocomplete, value, openDropdown, selectedAutocompleteOption]);

  const handleChangeTextField = useCallback(
    (data?: string) => {
      setSearchValue(data);
      changeInputText(data);
    },
    [changeInputText],
  );

  useEffect(() => {
    if (!selectedAutocompleteOption || !value) return;

    setSearchValue(selectedAutocompleteOption.name);
  }, [selectedAutocompleteOption, value]);

  useEffect(() => {
    if (!value) {
      setSearchValue(undefined);
    }
  }, [value]);

  const renderIcon = useCallback(() => {
    if (loading) return <LoadingIndicator size="small" />;
    if (disabled) return undefined;
    if (value)
      return (
        <IconButton
          tw="-mr-2"
          disabled={disabled}
          onClick={(e) => {
            if (!disabled) {
              e.stopPropagation();
              handleChangeTextField(undefined);
              setOpenDropdown(false);
              onBlurAutocomplete();
              onRemoveData();
              setSelectedAutocompleteOption(undefined);
            }
          }}
        >
          <Icon.Close tw="text-grey-two" />
        </IconButton>
      );
    return (
      <IconContainer isDropdownOpen={openDropdown}>
        <Icon.ChevronRounded stroke={theme`colors.grey.two`} />
      </IconContainer>
    );
  }, [
    disabled,
    handleChangeTextField,
    loading,
    onBlurAutocomplete,
    onRemoveData,
    openDropdown,
    value,
  ]);

  const handleOnScrollList = useCallback(
    ({ scrollOffset, scrollDirection }: ListOnScrollProps) => {
      if (!datas?.length) return;
      const offset = itemHeight * (datas.length - 6) - 50;
      if (scrollOffset > offset && scrollDirection === 'forward' && !loading) {
        debounceFetchMore();
      }
    },
    [datas?.length, debounceFetchMore, itemHeight, loading],
  );
  return {
    openDropdown,
    searchValue,
    isOpenSearch,
    inputRef,
    listHeight,
    listRef,
    itemHeight,
    debounceFetchMore,
    setOpenDropdown,
    handleOnScrollList,
    handleChangeTextField,
    handleFocus,
    handleClickAway,
    handleClickTextField,
    handleSelect,
    renderIcon,
  };
}
