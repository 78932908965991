import React from 'react';
import { RouteConfig } from '../../../config/router/router.config';

export const reportRevenueRoute: RouteConfig = {
  name: 'Revenue',
  path: '/report/revenue',
  Component: React.lazy(() => import('./ReportRevenue.view')),
  isPrivate: true,

  breadcrumb: 'Revenue',

  options: {
    icon: <div />,
    label: 'Revenue',
    testID: 'MenuReportRevenue',
  },
};

export default {};
