import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store/store.app';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import { ErrorCodes } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { LogoutReason, sessionAction } from '../../../store/session.store';
import { snackbarAction } from '../../../store/snackbar.store';
import { errorCodeToLabel } from '../../../util/error.util';
import useAuthRevoke from './useAuthRevoke.hook';
import useRefreshToken from './useRefreshToken.hook';

export default function useVerifyAuth() {
  const dispatch = useDispatch();
  const { translate } = useTranslator();
  const { handleRefreshToken } = useRefreshToken();
  const { handleAuthRevoke } = useAuthRevoke();

  const session = useSelector(
    (state: RootState) => state.session,
    shallowEqual,
  );

  const isVisaError = useCallback((code?: string) => {
    if (!code) return false;
    const visaErrors = [
      ErrorCodes.SSO_REFRESH_TOKEN_FAILED,
      ErrorCodes.SSO_VERIFY_AUTH_FAILED,
      ErrorCodes.SSO_VISA_CREDENTIAL_FAILED,
      ErrorCodes.SSO_FORBIDDEN_REQUEST,
    ];

    return visaErrors.includes(code as ErrorCodes);
  }, []);

  const isUserUnauthorizeError = useCallback((code?: string) => {
    if (!code) return false;
    const visaErrors = [ErrorCodes.SSO_FORBIDDEN_REQUEST];
    return visaErrors.includes(code as ErrorCodes);
  }, []);

  const handleLogout = useCallback(
    (type: LogoutReason = LogoutReason.REVOKE) => {
      dispatch(
        sessionAction.logout({
          type,
          token: session.globalSessionID,
        }),
      );
    },
    [dispatch, session.globalSessionID],
  );

  const handleAuthenticationFailed = useCallback(
    (errorCode?: string) => {
      const visaError = isVisaError(errorCode);
      const userUnauthorizedError = isUserUnauthorizeError(errorCode);

      if (!visaError && userUnauthorizedError) return;
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translate(errorCodeToLabel(errorCode)),
        }),
      );
      handleAuthRevoke();

      return handleLogout(LogoutReason.REVOKE);
    },
    [
      dispatch,
      translate,
      handleAuthRevoke,
      isVisaError,
      handleLogout,
      isUserUnauthorizeError,
    ],
  );

  /**
   * Verify auth validity
   * this function is for checking token to runner since runner doesnt have dedicated
   * api for checking token, if the function return true it will force pending action
   * to be run, ex. submit jo, if this function return false. submit jo won't be triggered
   * @param onAuthenticationFailed
   * @returns
   */
  const handleVerifyAuth = useCallback(
    async (
      onAuthenticationFailed?: (errorCode?: string) => void,
    ): Promise<boolean> => {
      if (!session.isLoggedIn || !session.globalSessionID) return true;

      const refreshTokenResponse = await handleRefreshToken(
        session.globalSessionID,
        session.token,
      );

      if (refreshTokenResponse?.ok) return true;

      onAuthenticationFailed
        ? onAuthenticationFailed(refreshTokenResponse?.code)
        : handleAuthenticationFailed();

      return false;
    },
    [
      session.isLoggedIn,
      session.token,
      session.globalSessionID,
      handleRefreshToken,
      handleAuthenticationFailed,
    ],
  );

  return { handleVerifyAuth };
}
