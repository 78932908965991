import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store/store.app';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { LogoutReason, sessionAction } from '../../../store/session.store';
import { ClientAuthenticationResponse } from './useSession.hook';

function useAuthRevoke() {
  const [authRevoke] = api.useAuthRevokeMutation();
  const dispatch = useDispatch();
  const gsid = useSelector(
    (state: RootState) => state.session.globalSessionID,
    shallowEqual,
  );

  const handleAuthRevoke = useCallback(
    async (token?: string): Promise<ClientAuthenticationResponse> => {
      try {
        dispatch(
          sessionAction.logout({
            type: LogoutReason.REVOKE,
            token: token ?? gsid ?? '',
          }),
        );
        await authRevoke().unwrap();
        return {
          ok: true,
        };
      } catch (error: unknown) {
        const data = (error as FetchBaseQueryError).data as ApiErrorResponse;

        return {
          ok: false,
          code: data?.error?.code,
        };
      }
    },
    [dispatch, gsid, authRevoke],
  );

  return { dispatch, handleAuthRevoke };
}

export default useAuthRevoke;
