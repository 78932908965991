import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BulkActionEntity, BulkActionStatusEnum } from '../../../constant';
import { Driver } from '../../../model/Driver.model';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { errorCodeToLabel } from '../../../util/error.util';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';

// #region TYPES
export type DeactivateDriverEntity = BulkActionEntity<Driver>;
export type UseDriverDeactivateLogic = ReturnType<
  typeof useDriverDeactivateLogic
>;
// #endregion

export default function useDriverDeactivateLogic() {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleVerifyAuth } = useVerifyAuth();

  const [showStatus, setShowStatus] = useState(false);

  // get all drivers in location state
  const driversStore = useMemo(
    () => (location?.state || []) as Driver[],
    [location?.state],
  );

  const [driversWithStatusDetail, setDriversWithStatusDetail] = useState<
    DeactivateDriverEntity[]
  >(
    driversStore.map((v) => ({
      ...v,
      _actionStatus: BulkActionStatusEnum.WAITING,
      _actionDetail: 'Waiting for deactivation',
    })),
  );
  const [showDoneBtn, setShowDoneBtn] = useState<boolean>(false);
  const [mutationsIsLoading, setMutationsIsLoading] = useState<boolean>(false); // to disable submit button when mutations is still ongoing
  const [doDeactivateDriver] = api.useDeactivateDriverMutation();
  // #endregion

  // #region HANDLERS
  const onDeactivate = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    setShowStatus(true);
    const onDeactivatePromise = async () => {
      // track loading state
      setMutationsIsLoading(true);

      // synchronous call deactivate driver mutation
      for (let i = 0; i < driversWithStatusDetail.length; i += 1) {
        const { id } = driversWithStatusDetail[i];

        try {
          // NOTE: currently our backend can not receive excessive amount of requests in parallel, that is why we used for loop instead of `Promise.allSettled`
          await doDeactivateDriver({ id }).unwrap();

          setDriversWithStatusDetail((_drivers) =>
            _drivers.map((_driver) =>
              _driver.id === id
                ? {
                    ..._driver,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Set to Inactive',
                  }
                : _driver,
            ),
          );
        } catch (err) {
          const errorQuery = (err as FetchBaseQueryError)
            ?.data as ApiErrorResponse;

          setDriversWithStatusDetail((_drivers) =>
            _drivers.map((_driver) =>
              _driver.id === id
                ? {
                    ..._driver,
                    _actionStatus: BulkActionStatusEnum.FAILED,
                    _actionDetail: errorCodeToLabel(errorQuery?.error?.code),
                  }
                : _driver,
            ),
          );
        }
      }

      // on done
      setShowDoneBtn(true);
      setMutationsIsLoading(false);
    };

    await onDeactivatePromise();
  }, [doDeactivateDriver, handleVerifyAuth, driversWithStatusDetail]);

  const onCancel = useCallback(() => {
    navigate('/driver');
  }, [navigate]);

  const onDone = useCallback(() => {
    navigate('/driver');
  }, [navigate]);

  return {
    driversWithStatusDetail,
    showDoneBtn,
    mutationsIsLoading,
    showStatus,
    onDeactivate,
    onCancel,
    onDone,
  };
}
