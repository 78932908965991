import React from 'react';
import tw from 'twin.macro';
import JoNotFoundImg from '../../assets/images/jo_not_found.png';
import {
  Button,
  ContentContainer,
  LoadingIndicator,
} from '../../component/atom';
import { BodyTwelve, HeadingFive } from '../../component/atom/Text/Text.atom';
import { Modal } from '../../component/molecule';
import MarkerDriver from '../../component/molecule/MarkerDriver/MarkerDriver.molecule';
import {
  ErrorPlaceholder,
  JODetailMobileBottomSheet,
  JODetailMobileMapsHeader,
  Map as RunnerMap,
} from '../../component/organism';
import MarkerVehicle from '../../component/organism/MarkerVehicle/MarkerVehicle.organism';
import { JOStatus } from '../../model/JobOrder.model';
import useJODetailMobileViewModel from './JobOrderDetail.mobile.viewModel';

// #region STYLED
const RootContainer = tw(
  ContentContainer,
)`self-center p-0 m-0 flex items-center flex-col w-full `;
const LoadingContainer = tw.div`h-[100vh] flex items-center justify-center`;
const MapContainer = tw.div`height[100vh] w-full bg-assigned-light relative`;
const MapOverlayContainer = tw.div` h-[100vh] background-color[rgba(97, 97, 97, 0.74)] w-full  absolute z-[1] flex justify-center`;
const MapOverlayWrapper = tw.div`h-[60%] flex`;
const MapContentArea = tw.div`flex flex-col self-center`;
const ModalBodyContainer = tw.div`flex flex-col p-4`;

// #endregion

export default function JobOrderDetailMobileView() {
  const {
    translator,
    jobOrderInfo,
    joDetailErrorPlaceholder,
    joDetailController,
    joDetailMaps,
    joDetailPolling,
    joDetailModalForceJO,
    joDetailSheet,
  } = useJODetailMobileViewModel();

  if (jobOrderInfo.joInfoError) {
    return (
      <RootContainer>
        <ErrorPlaceholder
          imageHeader={JoNotFoundImg}
          title={joDetailErrorPlaceholder.title}
          body={joDetailErrorPlaceholder.body}
          actionClick={joDetailErrorPlaceholder.handleAction}
          actionLabel={joDetailErrorPlaceholder.actionLabel}
        />
      </RootContainer>
    );
  }
  if (!jobOrderInfo.joInfoData) {
    return (
      <LoadingContainer>
        <LoadingIndicator size="small" />
      </LoadingContainer>
    );
  }
  return (
    <MapContainer>
      {![JOStatus.DELIVERED, JOStatus.DRAFT].includes(
        jobOrderInfo.joInfoData.status,
      ) && (
        <>
          <JODetailMobileMapsHeader
            mapsAction={joDetailMaps.mapActions}
            handleBack={joDetailController.handleClickBack}
          />

          {joDetailMaps.isLocationHidden && (
            <MapOverlayContainer>
              <MapOverlayWrapper>
                <MapContentArea>
                  <HeadingFive tw="text-assigned-light">
                    {translator.translate(
                      'You currently hide both the Truck and the Driver.',
                    )}
                  </HeadingFive>
                  <div tw="self-center mt-2.5 z-10">
                    <Button.Solid
                      small
                      tw="bg-orange "
                      onClick={joDetailMaps.handleMapsCenter}
                    >
                      {translator.translate('Reset Settings')}
                    </Button.Solid>
                  </div>
                </MapContentArea>
              </MapOverlayWrapper>
            </MapOverlayContainer>
          )}
          {!joDetailMaps.targetLocations?.length &&
            !joDetailMaps.isLocationHidden && (
              <MapOverlayContainer>
                <MapOverlayWrapper>
                  <MapContentArea>
                    <HeadingFive tw="text-assigned-light">
                      {translator.translate(
                        'Unknown vehicle or driver position',
                      )}
                    </HeadingFive>
                  </MapContentArea>
                </MapOverlayWrapper>
              </MapOverlayContainer>
            )}
          <RunnerMap
            targetLocations={joDetailMaps.targetLocations}
            disableDragMap
            disableOnclickTarget
          >
            {joDetailMaps.renderDriverPosition && (
              <MarkerDriver
                isOnline={joDetailMaps.isDriverOnline}
                driverName={joDetailMaps.driverName}
                driverPhoneNumber={joDetailMaps.driverPhoneNumber}
                position={joDetailMaps.renderDriverPosition}
                handleClick={joDetailMaps.handleClickMarkerDriver}
              />
            )}

            {joDetailMaps.renderVehiclePosition && (
              <MarkerVehicle
                position={joDetailMaps.renderVehiclePosition}
                rotation={joDetailMaps.vehicleHeading}
                handleClick={joDetailMaps.handleClickMarkerVehicle}
              />
            )}
          </RunnerMap>
        </>
      )}
      <JODetailMobileBottomSheet
        translator={translator}
        isLoading={jobOrderInfo.isFetching}
        joInfoData={jobOrderInfo.joInfoData}
        joDetailSheet={joDetailSheet}
        forceLoading={joDetailPolling.startPolling}
        handleBack={joDetailController.handleClickBack}
        handleForceComplete={() =>
          joDetailController.handleForceCompleteClicked(
            !joDetailSheet.selectedSOId,
          )
        }
      />
      {joDetailController.isModalConfirmationShown && (
        <Modal.Mobile
          title={joDetailModalForceJO.title}
          onClose={joDetailController.toggleModalConfirmation}
          onOverlayClick={joDetailController.toggleModalConfirmation}
          ctaStyle={tw`justify-between`}
          callAction={{
            label: joDetailModalForceJO.actionLabel,
            action: joDetailModalForceJO.handleModalAction,
          }}
          cancelAction={{
            label: translator.translate('Cancel'),
            action: joDetailController.toggleModalConfirmation,
          }}
          actionButtonContainer={tw`justify-between`}
        >
          <ModalBodyContainer>
            <BodyTwelve>
              <BodyTwelve tw="font-bold">
                {joDetailModalForceJO.contentHeader}
              </BodyTwelve>
              {` ${joDetailModalForceJO.contentDescription}`}
            </BodyTwelve>
          </ModalBodyContainer>
        </Modal.Mobile>
      )}
    </MapContainer>
  );
}
