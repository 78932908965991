import { Location } from '../model/Location.model';
import {
  ShipperOrderUnassigned,
  SOActivityDetail,
} from '../model/ShipperOrder.model';

// #region INTERFACES
export enum SOActivityType {
  DROP_OFF = 'DROP_OFF',
  PICK_UP = 'PICK_UP',
  STAND_BY = 'STAND_BY',
}

export enum SOActivityGoodsType {
  DOCUMENT = 'DOCUMENT',
  FOOD = 'FOOD',
  CLOTHES = 'CLOTHES',
  ELECTRONIC = 'ELECTRONIC',
  FRAGILE = 'FRAGILE',
  OTHERS = 'OTHERS',
}

export enum SOOrderBy {
  UPDATED_AT_DESC = 'UPDATED_AT_DESC', // Newest Updates
  UPDATED_AT_ASC = 'UPDATED_AT_ASC', // Oldest Updates
  SHIPPER_NAME_ASC = 'SHIPPER_NAME_ASC', // Shipper Name A-Z
  SHIPPER_NAME_DESC = 'SHIPPER_NAME_DESC', // Shipper Name Z-A
  DELIVERY_COST_DESC = 'DELIVERY_COST_DESC', // Highest Delivery Cost
  RECEIVABLE_DESC = 'RECEIVABLE_DESC', // Highest Remaining Receivables
  DATE_ASC = 'DATE_ASC', // Newest Date
  DATE_DESC = 'DATE_DESC', // Oldest Date
}

export enum SOOrderStatus {
  IN_PROCESS = 'IN_PROCESS',
  RESERVED = 'RESERVED',
  DELETED = 'DELETED',
  ASSIGNED = 'ASSIGNED',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
  IN_TRANSIT = 'IN_TRANSIT',
  TRANSITING = 'TRANSITING',
}

export enum SOPaymentStatusLabel {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}

export enum AllCheckboxState {
  UNCHECKED = 'UNCHECKED',
  CHECKED = 'CHECKED',
  INDETERMINATE = 'INDETERMINATE',
}

export type OrderByValue = {
  label: string;
  value: SOOrderBy;
};
export type OrderStatusValue = {
  label: string;
  value: SOOrderStatus;
};
export type PaymentStatusValue = {
  label: string;
  value: SOPaymentStatusLabel;
};

export type ShipperOrderFilterInitialValues = {
  orderBy: SOOrderBy;
  status: SOOrderStatus[];
  paymentStatus: SOPaymentStatusLabel[];
  shipperId?: string;
  startDate?: Date;
  endDate?: Date;
};

export type ShipperOrderQueryParams = {
  dateRange: string | null;
  startDate: string | null;
  endDate: string | null;
  orderBy: string | null;
  status: string | null;
  search: string | null;
  page: string | null;
  pageSize: string | null;
};
// #endregion

export enum ShipperOrderActivityEventType {
  GEOFENCE_ENTER = 'GEOFENCE_ENTER',
  GEOFENCE_EXIT = 'GEOFENCE_EXIT',
  ACTIVITY_COMPLETED = 'ACTIVITY_COMPLETED',
  ACTIVITY_STARTED = 'ACTIVITY_STARTED',
  PROOF_OF_ACTIVITY_ADDED = 'PROOF_OF_ACTIVITY_ADDED',
  NOTES_ADDED = 'NOTES_ADDED',
}

export enum SOActivitySelectionType {
  SCRATCH = 'SCRATCH',
  TRANSIT = 'TRANSIT',
  TEMPLATE = 'TEMPLATE',
  CHANGE_TEMPLATE = 'CHANGE_TEMPLATE',
}

export enum SOStatus {
  IN_PROCESS = 101,
  RESERVED = 102,
  DELETED = 103,
  ASSIGNED = 201,
  DELIVERING = 301,
  TRANSITING = 302,
  DELIVERED = 401,
  IN_TRANSIT = 402,
}

export const defaultSOOrderByForMobile = SOOrderBy.UPDATED_AT_DESC;
export const allSOOrderStatus = Object.values(SOOrderStatus);

export const soFilterInitialValues: ShipperOrderFilterInitialValues = {
  orderBy: SOOrderBy.DATE_DESC,
  status: allSOOrderStatus.filter((status) => status !== SOOrderStatus.DELETED),
  paymentStatus: Object.values(SOPaymentStatusLabel),
  shipperId: undefined,
  startDate: undefined,
  endDate: undefined,
};

export enum ShipperOrderDetailTab {
  HEADER = 'HEADER',
  DELIVERY_COST = 'DELIVERY_COST',
  ACTIVITY = 'ACTIVITY',
  JO_INFO = 'JO_INFO',
  TRACKING_ORDER = 'TRACKING_ORDER',
}
export type LabelValue = { label: string; value: string; id?: string };

export type SOActivityListData = SOActivityDetail & {
  header: LabelValue[];
};

export type ShipperOrderAddPaymentInitialValues = {
  paymentDate: Date;
  paymentAmount: string;
  note?: string;
};
// #endregion

/**
 * Maximum allowed time for goods update
 */
export const MAX_ALLOWED_TIME = 72; // hours
/**
 * Maximum allowed SO Candidate on jo create
 */
export const MAX_SO_CANDIDATE = 100;

export const MAX_SO_ACTIVITY = 100;

export const MAX_SO_GOODS = 50;

export const soListOfActivityTypes = [
  {
    id: SOActivityType.STAND_BY,
    label: 'Standby',
  },
  {
    id: SOActivityType.PICK_UP,
    label: 'Pickup',
  },
  {
    id: SOActivityType.DROP_OFF,
    label: 'Dropoff',
  },
];

export const soLastMileActivityDefaultFormItem = [
  {
    index: 0,
    expectedFinishAt: undefined,
    locationId: undefined,
    type: SOActivityType.STAND_BY,
    goods: [],
  },
];

export const soTransitActivityDefaultFormItem = [
  {
    index: 0,
    expectedFinishAt: undefined,
    locationId: undefined,
    type: SOActivityType.PICK_UP,
    goods: [],
  },
  {
    index: 1,
    expectedFinishAt: undefined,
    locationId: undefined,
    type: SOActivityType.DROP_OFF,
    goods: [],
  },
];

export interface SOSelectionItemProps {
  selected: boolean;
  image: React.ReactNode;
  title: string;
  onClick: () => void;
}

export const SOActivityConfirmationMapper = {
  [SOActivitySelectionType.SCRATCH]: 'Use Last Mile Form',
  [SOActivitySelectionType.TEMPLATE]: 'Use Template',
  [SOActivitySelectionType.TRANSIT]: 'Use Transit Form',
  [SOActivitySelectionType.CHANGE_TEMPLATE]: 'Browse Activity Template',
};

export const SOActivitiesDefaultValueMapper = {
  [SOActivitySelectionType.SCRATCH]: soLastMileActivityDefaultFormItem,
  [SOActivitySelectionType.TRANSIT]: soTransitActivityDefaultFormItem,
  [SOActivitySelectionType.TEMPLATE]: soTransitActivityDefaultFormItem,
  [SOActivitySelectionType.CHANGE_TEMPLATE]: [],
};

export interface SelectionSO extends ShipperOrderUnassigned {
  deliveryLocation?: Partial<Location>;
  currentLocation?: Partial<Location>;
}
