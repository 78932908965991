import React, { ReactChild, ReactChildren } from 'react';
import tw from 'twin.macro';
import { Icon, IconButton, Text } from '../../atom';
import { ExtraStyle } from '../../Type.component';

const ContainerWithChild = tw.div`rounded-t-md  border-b-2 border-b-beige-lines px-4 py-6`;
const Container = tw.div`rounded-t-md border-b-2 border-b-beige-lines flex flex-row px-4 py-1.5`;
const MetaTitle = tw.div`flex flex-row items-center`;
const ContainerSubHeader = tw.div`ml-auto self-center mr-2`;
interface Props {
  children?: ReactChild | ReactChildren;
  title: string;
  containerStyle?: ExtraStyle;
  subheaderStyle?: ExtraStyle;
  subHeader?: React.ReactNode;
  handleBack?(): void;
}

export default function CardHeader({
  children,
  title,
  containerStyle,
  subHeader,
  handleBack,
  subheaderStyle,
}: Props) {
  if (children) <ContainerWithChild>{children}</ContainerWithChild>;

  return (
    <Container css={containerStyle}>
      <MetaTitle>
        {handleBack && (
          <IconButton onClick={handleBack}>
            <Icon.ArrowBack fill="#F3532E" />
          </IconButton>
        )}
        <Text.HeadingFour
          css={[
            tw`truncate max-w-[600px]`,
            !!handleBack && tw`ml-2.5`,
            !!subHeader && tw`max-w-[500px]`,
          ]}
        >
          {title}
        </Text.HeadingFour>
      </MetaTitle>
      {subHeader && (
        <ContainerSubHeader css={subheaderStyle}>
          {subHeader}
        </ContainerSubHeader>
      )}
    </Container>
  );
}
