import { useCallback } from 'react';
import useTranslator from '../../hook/useTranslator.hook';
import { copyToClipboard } from '../../util/helper.util';
import { trackingRoute } from '../Tracking/Tracking.route';
import useShipperOrderInfo from './hooks/useShipperOrderInfo.hook';
import useTrackingSO from './hooks/useTrackingSO.hook';

export default function useSODetailMobileViewModel() {
  const translator = useTranslator();

  const soDetail = useShipperOrderInfo();
  const trackingSO = useTrackingSO({});

  const trackingCode = soDetail.soInfo?.trackingCode || '';
  const handleCopyClick = useCallback(() => {
    copyToClipboard(
      `${window.location.origin}${trackingRoute.path}?receipt=${trackingCode}`,
    );
  }, [trackingCode]);

  return { translator, soDetail, trackingSO, trackingCode, handleCopyClick };
}
