import React from 'react';

export default function NavActivityTemplate({
  fill = 'currentColor',
  width = 20,
  height = 20,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM2 3a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM20 16a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-4 0a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z"
        fill={fill}
      />
      <path
        d="M5 3h10c1.167 0 3.5.7 3.5 3.5S16.167 10 15 10H4.5C3.333 10 1 11.1 1 13.5S3 17 4 17h8m0 0-2 2m2-2-2-2"
        stroke={fill}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
