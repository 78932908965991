import { FormikErrors } from 'formik';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OptionType } from '../../../component/molecule/Select/Select.molecule';
import { ShipperOrderDetailTab } from '../../../constant';
import { AutocompleteType } from '../../../hook/useAutocomplete.hook';
import useDebounce from '../../../hook/useDebounce.hook';
import { Location } from '../../../model/Location.model';
import {
  Goods,
  ShipperOrderActivityFormValues,
  ShipperOrderInfo,
  SOActivitiesUpdateParam,
} from '../../../model/ShipperOrder.model';
import api from '../../../service/api.service';
import { navigationParamAction } from '../../../store/param.store';
import { getShortLabel } from '../../../util/formatter.util';
import { soDetailDeliveryLocationAddRoute } from '../../SODDeliveryLocationAdd/SODDeliveryLocationAdd.route';
import { shipperOrderDetailRoute } from '../ShipperOrderDetail.route';

type Props = {
  activitiesValue: SOActivitiesUpdateParam;
  activitiesError?:
    | string
    | string[]
    | FormikErrors<ShipperOrderActivityFormValues>[];
  soInfo?: ShipperOrderInfo;
  activitiesFormSetValues: (
    val: SOActivitiesUpdateParam,
  ) => Promise<FormikErrors<SOActivitiesUpdateParam>> | Promise<void>;
  setInitialGoodsValue: (goods: Partial<Goods>[]) => void;
  setGoodsByActivityIndex: (val?: number) => void;
};
export type UseSODetailCreateAT = ReturnType<typeof useSODetailCreateAT>;

export default function useSODetailCreateAT({
  activitiesValue,
  activitiesError,
  soInfo,
  activitiesFormSetValues,
  setInitialGoodsValue,
  setGoodsByActivityIndex,
}: Props) {
  // #region GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useState<string | undefined>(
    undefined,
  );
  const [locationData, setLocationData] = useState<Location[]>([]);
  const [page, setPage] = useState(1);
  const debouncedSearchData = useDebounce(searchParams, 500);

  const shipperId = useMemo(() => soInfo?.shipper.id, [soInfo?.shipper.id]);
  const shipperAutoComplete = useMemo(
    () => ({
      label: soInfo?.shipper.name || '',
      value: soInfo?.shipper.id || '',
    }),
    [soInfo?.shipper.id, soInfo?.shipper.name],
  );
  const soId = useMemo(() => soInfo?.id || '', [soInfo?.id]);
  // #endregion

  // #region API CALL
  const [
    fetchData,
    {
      hasNextPage,
      locationRes,
      locationListFetchLoading,
      isLocationFetchSuccess,
    },
  ] = api.useLazyGetLocationShipperQuery({
    selectFromResult: ({ data, isFetching, isSuccess }) => ({
      hasNextPage: data?.metadata
        ? data.metadata.page * data.metadata.pageSize < data.metadata.totalCount
        : false,
      locationListFetchLoading: isFetching,
      isLocationFetchSuccess: isSuccess,
      locationRes: data?.locations,
    }),
  });

  // #endregion

  // #region API ACTION LISTENER
  useEffect(() => {
    if (shipperId) {
      setPage(1);
      void fetchData({
        shipperId,
        ...(debouncedSearchData && { search: debouncedSearchData }),
      });
    }
  }, [debouncedSearchData, fetchData, shipperId]);

  useEffect(() => {
    if (isLocationFetchSuccess && locationRes) {
      setLocationData((loc) =>
        uniqBy([...(page > 1 ? loc : []), ...locationRes], 'id'),
      );
    }
  }, [locationRes, isLocationFetchSuccess, page]);

  // #endregion

  // #region HANDLER
  const locationOptions: AutocompleteType[] = useMemo(
    () =>
      locationData
        ? locationData.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        : [],
    [locationData],
  );

  const handleChangeLocationAutotext = useCallback((val?: string) => {
    setSearchParams(val);
  }, []);

  const handleFetchMoreLocation = useCallback(() => {
    if (hasNextPage && shipperId) {
      void fetchData({
        shipperId,
        page: page + 1,
        ...(searchParams && { search: searchParams }),
      });
      void setPage((v) => v + 1);
    }
  }, [fetchData, hasNextPage, page, searchParams, shipperId]);
  // #endregion

  const handleChangeDataLocation = useCallback(
    async (index: number, option?: OptionType) => {
      if (!option) return;
      const selectedLocation = (locationData as unknown as Location[]).find(
        (item) => item.id === option?.value,
      );
      if (selectedLocation) {
        await activitiesFormSetValues({
          ...activitiesValue,
          activities: activitiesValue?.activities?.map((item, i) =>
            i === index
              ? {
                  ...item,
                  location: selectedLocation,
                  locationId: option?.value,
                }
              : item,
          ),
        });

        handleChangeLocationAutotext(undefined);
      }
    },
    [
      activitiesFormSetValues,
      activitiesValue,
      handleChangeLocationAutotext,
      locationData,
    ],
  );
  // #endregion

  // #region ACTION
  const onActivityEditClick = useCallback(
    (index: number) => {
      setGoodsByActivityIndex(index);
      const arrOfGoods = activitiesValue.activities[index]
        ? activitiesValue.activities[index]?.goods || []
        : [];
      setInitialGoodsValue([
        ...(arrOfGoods.length > 0
          ? arrOfGoods
          : [
              ...arrOfGoods,
              {
                quantity: undefined,
                volume: undefined,
                weight: undefined,
                type: undefined,
                description: undefined,
                uom: undefined,
              },
            ]),
      ]);
    },
    [setGoodsByActivityIndex, setInitialGoodsValue, activitiesValue.activities],
  );

  const getActivityFormErrors = (
    index: number,
    key: keyof ShipperOrderActivityFormValues,
  ) => {
    const arr = activitiesError as unknown as Record<
      keyof ShipperOrderActivityFormValues,
      string
    >[];

    try {
      if (typeof arr === 'string' || typeof arr[index] === 'undefined')
        return '';

      return arr[index][key] || '';
    } catch (error) {
      return '';
    }
  };
  const handleAddNewLocation = useCallback(
    (idx?: number) => {
      dispatch(
        navigationParamAction.changeShipperOrderDetailDeliveryLocationAddParams(
          {
            tab: ShipperOrderDetailTab.ACTIVITY,
            originPath: `${shipperOrderDetailRoute.path.replace(
              ':id',
              soId,
            )}?breadcrumb=${getShortLabel(soInfo?.number)}`,
            activityState: activitiesValue.activities,
            activityIndex: idx,
            shipper: shipperAutoComplete,
          },
        ),
      );
      navigate(soDetailDeliveryLocationAddRoute.path.replace(':id', soId));
    },
    [
      dispatch,
      soId,
      soInfo?.number,
      activitiesValue.activities,
      shipperAutoComplete,
      navigate,
    ],
  );
  // #endregion

  return {
    locationData,
    locationOptions,
    locationListFetchLoading,
    onActivityEditClick,
    handleChangeLocationAutotext,
    handleChangeDataLocation,
    handleFetchMoreLocation,
    handleAddNewLocation,
    getActivityFormErrors,
  };
  // #endregion
}
