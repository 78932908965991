import { setDefaultOptions } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import id from 'date-fns/locale/id';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store/store.app';
import { SupportedLanguage } from '../config/locale/locale.config';
import { settingAction } from '../store/setting.store';
import useScreenDetection from './useScreenDetection/useScreenDetection.hook';

type OldNavigator = Navigator & {
  userLanguage: string;
};

export default function useLocaleUpdate() {
  const dispatch = useDispatch();
  const { isMobile } = useScreenDetection();
  const currentLangData = useSelector(
    (state: RootState) => state.setting.currentLanguage,
  );

  useEffect(() => {
    setDefaultOptions({
      locale: currentLangData === SupportedLanguage.en ? enUS : id,
    });
  }, [currentLangData]);

  // set language based on user language preferences
  // this only trigger once, if we want to sync with external state (window.navigator.language), we need `useSyncExternalStore`
  useEffect(() => {
    // (e.g. 'id' | 'en-US' | 'en-UK')
    const userLanguage =
      (window.navigator as OldNavigator).userLanguage ||
      window.navigator.language;

    if (isMobile)
      dispatch(
        settingAction.changeLanguage(
          userLanguage.includes('en')
            ? SupportedLanguage.en
            : SupportedLanguage.id,
        ),
      );
  }, [dispatch, isMobile]);
}
