import React from 'react';

export default function NavVehicles({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3v2h10.667v10.018A3.002 3.002 0 0 0 12.04 17.5H8.96a3 3 0 1 0-.36 2H12.4a2.999 2.999 0 0 0 5.198 0H20a2 2 0 0 0 2-2v-4c0-2.443-1.08-3.973-1.95-4.645a1.77 1.77 0 0 0-1.09-.355h-2.293V3H4Zm13.959 14.5H20v-2h-2.556v-2H20c0-1.71-.697-2.648-1.096-3h-2.237v5.005a3 3 0 0 1 1.291 1.995ZM4.995 18a1.005 1.005 0 1 0 2.01 0 1.005 1.005 0 0 0-2.01 0Zm9 0a1.005 1.005 0 1 0 2.01 0 1.005 1.005 0 0 0-2.01 0Z"
        fill={fill || 'currentColor'}
      />
      <path d="M3 7v2h6V7H3ZM2 11v2h6v-2H2Z" fill={fill || 'currentColor'} />
    </svg>
  );
}
