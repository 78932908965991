import { createContext, useContext, useMemo, useState } from 'react';

export type ClockPickerInitialState = Readonly<{
  input?: string;
  time?: string;
  showPickerInput?: boolean;
}>;
export type ClockPickerAction = {
  openPickerInput: () => void;
  closePickerInput: () => void;
  togglePickerInput: (_open?: boolean) => void;
  setInput: (_value: string) => void;
  resetInput: () => void;
  setTime: (_time: string) => void;
  resetTime: () => void;
};
export type TClockPickerContext = ReturnType<typeof createClockPickerContext>;

const clockPickerInitialState: ClockPickerInitialState = {
  input: '', // only consists of 4 chars (e.g. '0109' which means '01:09')
  time: '00:00', // if `undefined`, then minute picker will start in 30 instead of 0
  showPickerInput: false,
};

/**
 * This creates a ClockPickerContext.
 * It's extracted into a function to be able to type the Context before it's even initialized.
 */
export const createClockPickerContext = (
  initialState?: ClockPickerInitialState,
) => {
  const [state, setState] = useState({
    ...clockPickerInitialState,
    ...initialState,
  });

  const actions: ClockPickerAction = useMemo(
    () => ({
      openPickerInput: () =>
        setState((prev) => ({ ...prev, showPickerInput: true })),
      closePickerInput: () =>
        setState((prev) => ({ ...prev, showPickerInput: false })),
      togglePickerInput: (_open) =>
        setState((prev) => ({
          ...prev,
          showPickerInput: _open ?? !prev.showPickerInput,
        })),
      setInput: (input) => setState((prev) => ({ ...prev, input })),
      resetInput: () =>
        setState((prev) => ({
          ...prev,
          input: clockPickerInitialState.input,
        })),
      setTime: (time) =>
        setState((prev) => ({
          ...prev,
          time,
        })),
      resetTime: () =>
        setState((prev) => ({
          ...prev,
          time: clockPickerInitialState.time,
        })),
    }),
    [],
  );

  return useMemo(() => [state, actions] as const, [state, actions]);
};

export const ClockPickerContext = createContext<TClockPickerContext>(
  {} as TClockPickerContext,
);

export const useClockPicker = () => {
  const context = useContext(ClockPickerContext);
  if (!context) {
    throw new Error(
      'useClockPicker can only be used inside the ClockPickerContext provider',
    );
  }

  return context;
};
