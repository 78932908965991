import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store/store.app';
import { navbarAction } from '../../store/navbar.store';
import useRouteCheck from '../useRouteCheck/useRouteCheck.hook';

export type UseSidebar = ReturnType<typeof useSidebar>;

function useSidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isExpanded, isHovered } = useSelector(
    (state: RootState) => state.navbar,
    shallowEqual,
  );
  const isSidebarExpanded = isExpanded || isHovered;
  const setExpandSidebar = useCallback(
    (_state: boolean) => {
      dispatch(navbarAction.setExpandNavbar(_state));
    },
    [dispatch],
  );
  const setHoverSidebar = useCallback(
    (_state: boolean) => {
      dispatch(navbarAction.setHoverNavbar(_state));
    },
    [dispatch],
  );
  const { isPublic: hideSidebar } = useRouteCheck();
  const onSidebarItemClick = useCallback(
    (path: string, customNavigation?: () => void) => {
      if (customNavigation) {
        customNavigation();
      } else {
        navigate(path);
      }
    },
    [navigate],
  );

  return {
    setExpandSidebar,
    setHoverSidebar,
    onSidebarItemClick,
    hideSidebar,
    isSidebarExpanded,
  };
}

export default useSidebar;
