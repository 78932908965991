import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarTheme } from '../../component/molecule/Snackbar/Snackbar.molecule';
import api from '../../service/api.service';
import {
  CompleteJobOrderParam,
  CompleteJobOrderResponse,
} from '../../service/endpoint/jobOrder/jobOrder.endpoint';
import { snackbarAction } from '../../store/snackbar.store';
import { formatText } from '../../util/tracking/trackingTimeline.util';
import useVerifyAuth from '../../view/Wrapper/hooks/useVerifyAuth.hook';
import { UseJobOrderInfoHookObj } from '../useJobOrderInfo/useJobOrderInfo.hook';
import useTranslator from '../useTranslator.hook';

type Props = { jobOrderInfo: UseJobOrderInfoHookObj };
function useForceCompleteJO({ jobOrderInfo }: Props) {
  // #region GENERAL
  const translator = useTranslator();
  const dispatch = useDispatch();
  const { handleVerifyAuth } = useVerifyAuth();
  // #endregion

  // #region API CALL
  const [request, { error, reset, isLoading }] =
    api.useForceCompleteJobOrdersMutation();
  // #endregion

  const handleCompleteSOFailed = useCallback(() => {
    if (!jobOrderInfo.joInfoData) return;
    if (!error) return;
    dispatch(
      snackbarAction.show({
        type: SnackbarTheme.warning,
        message: formatText(
          translator.translate('Failed to complete %s'),
          jobOrderInfo.joInfoData.number,
        ),
      }),
    );

    reset();
  }, [dispatch, error, jobOrderInfo.joInfoData, reset, translator]);

  const handleCompleteSOSuccess = useCallback(
    (response: CompleteJobOrderResponse) => {
      if (!jobOrderInfo.joInfoData || !response.ok) return;

      dispatch(
        snackbarAction.show({
          message: formatText(
            translator.translate('%s has been successfully completed'),
            jobOrderInfo.joInfoData.number,
          ),
        }),
      );

      reset();
      jobOrderInfo.refetchJOInfo();
    },
    [dispatch, jobOrderInfo, reset, translator],
  );

  const handleRequest = useCallback(
    async ({ id }: CompleteJobOrderParam) => {
      try {
        const authRes = await handleVerifyAuth();
        if (!authRes) return;
        const response = await request({ id }).unwrap();
        void handleCompleteSOSuccess(response);
      } catch {
        void handleCompleteSOFailed();
      }
    },
    [
      handleCompleteSOFailed,
      handleVerifyAuth,
      handleCompleteSOSuccess,
      request,
    ],
  );

  return { handleRequest, reset, isLoading };
}
export type UseForceCompleteJOHookObj = ReturnType<typeof useForceCompleteJO>;
export default useForceCompleteJO;
