import { useCallback, useMemo, useState } from 'react';
import { RedirectUrlTarget } from '../../constant';
import { getShortLabel } from '../../util/formatter.util';
import { doRedirectUrl } from '../../util/helper.util';
import { shipperOrderDetailRoute } from '../../view/ShipperOrderDetail/ShipperOrderDetail.route';
import { UseJobOrderInfoHookObj } from '../useJobOrderInfo/useJobOrderInfo.hook';

interface UseJODetailSOProps {
  jobOrderInfo: UseJobOrderInfoHookObj;
}

export default function useJODetailSO({ jobOrderInfo }: UseJODetailSOProps) {
  // #region GENERAL
  const [selectedDeliveryId, setSelectedDeliveryId] = useState<string>('');
  const renderSelectedDelivery = useMemo(
    () =>
      selectedDeliveryId || jobOrderInfo?.joInfoData?.deliveries[0]?.id || '',
    [jobOrderInfo?.joInfoData?.deliveries, selectedDeliveryId],
  );
  const renderSelectedDeliveryItem = useMemo(
    () =>
      jobOrderInfo?.joInfoData?.deliveries?.find(
        (delivery) => delivery.id === selectedDeliveryId,
      ) || jobOrderInfo?.joInfoData?.deliveries[0],
    [jobOrderInfo?.joInfoData?.deliveries, selectedDeliveryId],
  );
  // #endregion

  const handleSelectDeliveryItem = useCallback((deliveryId: string) => {
    setSelectedDeliveryId(deliveryId);
  }, []);

  const handleSelectShipperOrder = useCallback(
    (soId: string, soNumber: string) => {
      doRedirectUrl({
        url: `${window.location.origin}${shipperOrderDetailRoute.path.replace(
          ':id',
          soId,
        )}?breadcrumb=${getShortLabel(soNumber)}`,
        target: RedirectUrlTarget.BLANK,
      });
    },
    [],
  );

  return {
    selectedDeliveryId,
    renderSelectedDelivery,
    renderSelectedDeliveryItem,
    setSelectedDelivery: setSelectedDeliveryId,
    handleSelectShipperOrder,
    handleSelectDeliveryItem,
  };
}

export type UseJODetailSOHookObj = ReturnType<typeof useJODetailSO>;
