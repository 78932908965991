import React from 'react';
import tw, { styled } from 'twin.macro';
import KTBLogo from '../../../assets/images/KTBLogo.png';
import { ProfileCardLegalItemProps } from '../../../hook/useProfile/userProfile.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { Button, Icon, Image } from '../../atom';
import {
  BodyFourteen,
  BodyTwelve,
  HeadingFive,
} from '../../atom/Text/Text.atom';
import ProfileCardLegalSection from '../ProfileCardLegalSection/ProfileCardLegalSection.organism';

// #region STYLED
const ProfileCardContainer = tw.div`flex flex-col shadow-card rounded-md bg-white width[280px] animate-slide-in-top`;
const ProfileCardHeader = tw.div`py-5 flex flex-col flex-1 items-center`;
const ProfileUsername = tw.div`flex flex-col pt-2.5 px-5 max-w-[280px] text-center`;
const ProfileAllRights = tw.div`flex flex-1 items-center justify-center py-2.5 `;
const ProfileSignOutButton = tw.div`flex flex-1 items-center justify-center py-2 border-t border-b border-b-grey-five  border-t-grey-five`;
const Avatar = styled.div`
  & svg {
    ${tw`w-14 h-14`}
  }
`;
// #endregion

// #region INTERFACES
type Props = {
  translator: UseTranslator;
  username: string;
  organizationName: string;
  profileCardLegalItems: ProfileCardLegalItemProps[];
  onLogoutClick(): void;
  onProfileClick(): void;
};
// #endregion

export default function ProfileCard({
  username,
  onLogoutClick,
  onProfileClick,
  profileCardLegalItems,
  organizationName,
  translator,
}: Props) {
  return (
    <ProfileCardContainer>
      <ProfileCardHeader>
        <Avatar>
          <Icon.AvatarPlaceholder />
        </Avatar>

        <ProfileUsername>
          <HeadingFive tw="overflow-wrap[anywhere]">{username}</HeadingFive>
          <BodyTwelve tw="overflow-wrap[anywhere] text-grey-two">
            {organizationName}
          </BodyTwelve>
        </ProfileUsername>
      </ProfileCardHeader>

      <ProfileSignOutButton>
        <Button.Text
          tw="hover:bg-orange-hover p-2 rounded-md"
          onClick={onLogoutClick}
        >
          <BodyFourteen tw="text-grey-two">
            {translator.translate('Sign Out')}
          </BodyFourteen>
        </Button.Text>
      </ProfileSignOutButton>

      <ProfileCardLegalSection
        handleProfileClick={onProfileClick}
        profileCardLegalItems={profileCardLegalItems}
      />

      <ProfileAllRights>
        <Image tw="px-5" src={KTBLogo} alt="ktb-logo" />
      </ProfileAllRights>
    </ProfileCardContainer>
  );
}
