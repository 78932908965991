import {
  ApiResponse,
  CommonApiListParams,
  CommonApiMetadata,
  CommonApiResponse,
} from '../service/api.endpoint';
import { Photo } from './Common.model';

export enum DriverStatus {
  ACTIVE = 101,
  INACTIVE = 102,
  ON_DUTY = 201,
}

export enum DriverOrderBy {
  DRIVER_NAME_ASC = 'DRIVER_NAME_ASC', // Name A-Z (default)
  DRIVER_NAME_DESC = 'DRIVER_NAME_DESC', // Name Z-A
  STATUS_ASC = 'STATUS_ASC', // Status Ascending
  STATUS_DESC = 'STATUS_DESC', // Status Descending
  CREATED_AT_DESC = 'CREATED_AT_DESC', // Recently Added
}

export type DriverPin = {
  isSet?: boolean;
  lastResetAt?: number;
  validUntil?: number;
};

export type Driver = {
  id: string;
  fullName: string;
  phoneNumber: string;
  status: DriverStatus;
  statusText: keyof typeof DriverStatus;
  createdAt: number;
  updatedAt: number;
  licenseNumber?: string;
  dateOfBirth?: number | null;
  address?: string;
  photo?: Photo;
  pin: DriverPin;
};

export type DriverTimesheet = {
  id: string;
  driverId: string;
  date: number; // seconds
  startedAt: number | null; // seconds
  stoppedAt: number | null; // seconds
  totalDuration: number | null; // seconds
  isOnSchedule: boolean;
};

export type DriverTimesheetDetail = Pick<
  DriverTimesheet,
  'id' | 'startedAt' | 'stoppedAt'
> & {
  duration: number | null; // seconds
};

export type DriverTimesheetEvent =
  | DriverTimesheetEventAdded
  | DriverTimesheetEventUpdated
  | DriverTimesheetEventRemoved;

export type DriverTimesheetEventAdded = {
  id: string;
  timesheetId: string;
  orgId: string;
  eventType: 'TIMESHEET_ADDED';
  eventData: {
    record: {
      duration: number;
      startedAt: number; // seconds
      stoppedAt: number; // seconds
    };
  };
  eventTime: number; // seconds
  performedBy: string;
  performedByName: string;
  performedByRole: 'driver' | 'user';
  createdBy: null;
  createdByName: null;
  createdByRole: null;
};

export type DriverTimesheetEventUpdated = {
  id: string;
  timesheetId: string;
  orgId: string;
  eventType: 'TIMESHEET_UPDATED';
  eventData: {
    newRecord: {
      duration: number;
      startedAt: number; // seconds
      stoppedAt: number; // seconds
    };
    oldRecord: {
      duration: number;
      startedAt: number; // seconds
      stoppedAt: number; // seconds
    };
  };
  eventTime: number; // seconds
  performedBy: string;
  performedByName: string;
  performedByRole: 'driver' | 'user';
  createdBy: string;
  createdByName: string;
  createdByRole: 'driver' | 'user';
};

export type DriverTimesheetEventRemoved = {
  id: string;
  timesheetId: string;
  orgId: string;
  eventType: 'TIMESHEET_REMOVED';
  eventData: {
    record: {
      duration: number;
      startedAt: number; // seconds
      stoppedAt: number; // seconds
    };
  };
  eventTime: number; // seconds
  performedBy: string;
  performedByName: string;
  performedByRole: 'driver' | 'user';
  createdBy: string;
  createdByName: string;
  createdByRole: 'driver' | 'user';
};

// #region API
// #region DRIVER LISTS COUNT
export type GetDriversCountApiRequest = {
  search?: string;
  'status[]'?: DriverStatus;
};

export type GetDriversCountApiResponse = CommonApiResponse & {
  drivers: {
    totalCount: number;
  };
};

// #endregion

// #region DRIVER LISTS
export type GetDriverApiRequest =
  | (CommonApiListParams & {
      'status[]'?: DriverStatus;
      orderBy?: DriverOrderBy;
    })
  | undefined;

export type GetDriverApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    drivers: Driver[];
  };

// #endregion
// #region DRIVER UNASSIGNEDLIST
export type GetDriverUnassignedApiRequest =
  | (CommonApiListParams & { from?: number; to?: number })
  | undefined;

export type GetDriverUnassignedApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    drivers: Driver[];
  };

// #endregion

// #region DRIVER ACTIVE LIST
export type GetDriverActiveApiRequest = CommonApiListParams | undefined;

export type GetDriverActiveApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    drivers: Driver[];
  };

// #endregion

// #region DEACTIVATE DRIVER
export type DeactivateDriverRequest = {
  id: string;
};
export type DeactivateDriverResponse = ApiResponse;
// #endregion
// #endregion

export interface DriverFormAdd extends DriverFormValues {
  fullName: string;
  phoneNumber: string;
  pinType: 'random' | 'manual';
  pin: string;
}
export interface PostDriverCreateParams extends DriverFormValues {
  fullName: string;
  phoneNumber?: string;
  pin: string;
}
export type PostDriverCreateResponse = ApiResponse & {
  driver: Pick<Driver, 'id'>;
};

export type DriverFormValues = {
  fullName: string;
  phoneNumber?: string | null;
  licenseNumber?: string | null;
  dateOfBirth?: number | null;
  address?: string | null;
};
export interface GetDriverInfoApiRequest {
  id: string;
}

export type GetDriverInfoApiResponse = CommonApiResponse & {
  driver: Driver;
};

export interface UpdateDriverRequest {
  id: string;
  name?: string;
  phone_number?: string | null;
  email?: string | null;
}

export type UpdateDriverResponse = CommonApiResponse;

export type DriverLastPosition = {
  id: string;
  eventTimestamp: number;
  gpsTimestamp: number;
  lat: number;
  lon: number;
  speed: number;
};

export interface DriverPINInfoProps {
  id?: string;
  name: string;
  phoneNumber: string;
  pin: string;
  expiredDate: string;
}
export interface UpdateDriverPinRequest {
  id: string;
  pin: string;
}

export type ExportDriverApiRequest = Omit<
  Exclude<GetDriverApiRequest, undefined>,
  'status[]'
> & {
  status?: DriverStatus[];
};

export type ExportDriverApiResponse = CommonApiResponse & {
  driver: {
    fileUrl: string;
  };
};

export type GetDriverTimesheetListApiRequest = {
  page: number;
  pageSize: number;
  from: number;
  to: number;
  driverId: string;
};

export type GetDriverTimesheetListApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    driverTimesheets: DriverTimesheet[];
  };

export type GetDriverTimesheetDetailApiRequest = {
  id: string;
  driverId: string;
};

export type GetDriverTimesheetDetailApiResponse = CommonApiResponse & {
  timesheetDetails: DriverTimesheetDetail[];
};

export type GetDriverTimesheetTotalApiRequest = Omit<
  GetDriverTimesheetListApiRequest,
  'page' | 'pageSize'
>;

export type GetDriverTimesheetEventListApiRequest = {
  id: string;
  page?: number;
  pageSize?: number;
};

export type GetDriverTimesheetEventListApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    timesheetEvents: DriverTimesheetEvent[];
  };

export type GetDriverTimesheetTotalApiResponse = CommonApiResponse & {
  timesheetTotal: {
    totalDuration: number; // seconds
    totalDays: number;
  };
};

export type CreateDriverTimesheetRequest = {
  driverId: string;
  startedAt: number; // seconds
  stoppedAt: number; // seconds
};

export type CreateDriverTimesheetResponse = CommonApiResponse & {
  timesheet: {
    id: string;
  };
};

export type AddDriverTimesheetDetailRequest = CreateDriverTimesheetRequest & {
  timesheetId: string;
};

export type AddDriverTimesheetDetailResponse = CommonApiResponse & {
  timesheetDetail: {
    id: string;
  };
};

export type UpdateDriverTimesheetDetailRequest =
  CreateDriverTimesheetRequest & {
    id: string;
    timesheetId: string;
  };

export type UpdateDriverTimesheetDetailResponse = CommonApiResponse;

export type RemoveDriverTimesheetDetailRequest = {
  id: string;
  timesheetId: string;
  driverId: string;
};

export type RemoveDriverTimesheetDetailResponse = CommonApiResponse;

export type ExportDriverTimesheetApiRequest = GetDriverTimesheetListApiRequest;

export type ExportDriverTimesheetApiResponse = CommonApiResponse & {
  driver: {
    fileUrl: string;
  };
};

export type ExportDriverTimesheetBulkApiRequest = Pick<
  GetDriverTimesheetListApiRequest,
  'from' | 'to'
> & {
  driverIds: string[];
};

export type ExportDriverTimesheetBulkApiResponse = CommonApiResponse & {
  driver: {
    fileUrl: string;
  };
};

export type DriverSearchParam = {
  page?: string;
  pageSize?: string;
  search?: string;
  status?: string;
  sortBy?: string;
};
