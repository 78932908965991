import format from 'date-fns/format';
import React, { useCallback } from 'react';
import { useWindowSize } from 'react-use';
import tw, { theme } from 'twin.macro';
import { JOAssignmentBy } from '../../../constant/JOAssignment.constant';
import { Schedule } from '../../../model/Schedule.model';
import { LoadingIndicator } from '../../atom';
import { Add, NoDataFound } from '../../atom/Icon/Icon.atom';
import {
  BodyTwelve,
  HeadingFive,
  HeadingFour,
} from '../../atom/Text/Text.atom';
import VehicleChipInfo from '../../molecule/VehicleChipInfo/VehicleChipInfo.molecule';
import { ExtraStyle } from '../../Type.component';
import BaseScheduleItem from '../BaseScheduleItem/BaseScheduleItem.organism';

// #region STYLED

const Container = tw.div`w-full flex flex-col rounded border border-beige-lines`;
const Title = tw(
  HeadingFour,
)`text-sm leading-6 px-4 py-2.5 border-b border-beige-lines bg-beige-bg`;
const AddButton = tw.button`flex items-center space-x-1`;

// SCHEDULE LIST
const ScheduleListContainer = tw.ul`flex flex-col overflow-y-auto relative`;
const ScheduleListLoadingContainer = tw.ul`flex flex-col overflow-y-auto relative h-40`;
const LoadingOverlay = tw.div`bg-white w-full h-full absolute z-10  opacity-[85%] rounded`;
const LoadingContainer = tw.div`flex h-full items-center justify-center`;

// EMPTY LIST
const EmptyListContainer = tw.div`flex flex-col justify-center items-center py-6`;
const EmptyListHeading = tw(HeadingFour)`font-semibold`;
const EmptyListSubtitle = tw(BodyTwelve)`mt-1`;
const EmptyListButton = tw(
  AddButton,
)`mt-2 py-1 pl-2 pr-3 rounded flex items-center space-x-2 bg-orange`;
const EmptyListLinkText = tw(HeadingFive)`font-semibold text-white`;

// #endregion

// #region INTERFACE

type EmptyListProps = {
  type?: JOAssignmentBy;
  emptyTitle?: string;
  emptySubtitle?: string;
  emptyActionLabel?: string;
  handleClickAddSchedule?: () => void;
};

type ScheduleListProps = EmptyListProps & {
  schedules: Schedule[];
  selectedHighlightId?: string;
  isLoading?: boolean;
  listContainerHeight?: number;
  handleClickScheduleItem: (item: Schedule) => void;
};

type ScheduleItemProps = {
  type?: JOAssignmentBy;
  schedule: Schedule;
  selectedHighlightId?: string;
  handleClickScheduleItem: () => void;
};

type Props = ScheduleListProps & {
  title: string;
  containerStyle?: ExtraStyle;
};

// #endregion

function EmptyList({
  type,
  emptyTitle,
  emptySubtitle,
  emptyActionLabel,
  handleClickAddSchedule,
}: EmptyListProps) {
  const { height } = useWindowSize();
  return (
    <EmptyListContainer
      style={
        type === JOAssignmentBy.DATE
          ? {
              height: `calc(${height}px - 334px)`,
            }
          : undefined
      }
    >
      <NoDataFound />
      <EmptyListHeading>{emptyTitle}</EmptyListHeading>
      <EmptyListSubtitle>{emptySubtitle}</EmptyListSubtitle>
      {!!handleClickAddSchedule && (
        <EmptyListButton type="button" onClick={handleClickAddSchedule}>
          <Add height={20} width={20} fill={theme`colors.white`} />
          <EmptyListLinkText>{emptyActionLabel}</EmptyListLinkText>
        </EmptyListButton>
      )}
    </EmptyListContainer>
  );
}

function Item({
  type,
  schedule,
  selectedHighlightId,
  handleClickScheduleItem,
}: ScheduleItemProps) {
  const start = format(schedule.from * 1000, 'dd MMM yyyy');
  const end = format(schedule.to * 1000, 'dd MMM yyyy');
  const date = `${start} - ${end}`;
  if (type === JOAssignmentBy.VEHICLE) {
    return (
      <BaseScheduleItem
        isSelected={selectedHighlightId === schedule.id}
        labelTitle={date}
        showSelection
        valueTitle={schedule.driver.fullName}
        onClickScheduleItem={handleClickScheduleItem}
      />
    );
  }
  if (type === JOAssignmentBy.DRIVER) {
    return (
      <BaseScheduleItem
        isSelected={selectedHighlightId === schedule.id}
        labelTitle={date}
        showSelection
        valueTitle={schedule.vehicle.name}
        subContent={
          <VehicleChipInfo
            isSelected={selectedHighlightId === schedule.id}
            vehicle={schedule.vehicle}
          />
        }
        onClickScheduleItem={handleClickScheduleItem}
      />
    );
  }
  return (
    <BaseScheduleItem
      isSelected={selectedHighlightId === schedule.id}
      labelTitle={date}
      labelSubtitle={schedule.driver.fullName}
      valueTitle={schedule.vehicle.name}
      showSelection
      subContent={
        <VehicleChipInfo
          isSelected={selectedHighlightId === schedule.id}
          vehicle={schedule.vehicle}
        />
      }
      onClickScheduleItem={handleClickScheduleItem}
    />
  );
}

function List({
  type,
  schedules,
  selectedHighlightId,
  listContainerHeight = 556,
  isLoading,
  emptyTitle,
  emptySubtitle,
  emptyActionLabel,
  handleClickAddSchedule,
  handleClickScheduleItem,
}: ScheduleListProps) {
  const { height } = useWindowSize();
  const renderItem = useCallback(
    (item: Schedule) => (
      <Item
        type={type}
        key={item.id}
        schedule={item}
        selectedHighlightId={selectedHighlightId}
        handleClickScheduleItem={() => handleClickScheduleItem(item)}
      />
    ),
    [handleClickScheduleItem, selectedHighlightId, type],
  );
  if (isLoading)
    return (
      <ScheduleListLoadingContainer
        style={{
          maxHeight: `calc(${height}px - ${listContainerHeight}px)`,
        }}
      >
        <LoadingOverlay>
          <LoadingContainer>
            <LoadingIndicator size="small" />
          </LoadingContainer>
        </LoadingOverlay>
      </ScheduleListLoadingContainer>
    );

  if (!schedules.length) {
    return (
      <EmptyList
        type={type}
        emptyTitle={emptyTitle}
        emptySubtitle={emptySubtitle}
        emptyActionLabel={emptyActionLabel}
        handleClickAddSchedule={handleClickAddSchedule}
      />
    );
  }
  return (
    <ScheduleListContainer
      style={{
        maxHeight: `calc(${height}px - ${listContainerHeight}px)`,
      }}
    >
      {schedules.map(renderItem)}
    </ScheduleListContainer>
  );
}

export default function SchedulesSection({
  type = JOAssignmentBy.DRIVER,
  title,
  schedules,
  selectedHighlightId,
  listContainerHeight,
  containerStyle,
  emptyTitle,
  emptySubtitle,
  emptyActionLabel,
  isLoading,
  handleClickScheduleItem,
  handleClickAddSchedule,
}: Props) {
  return (
    <Container css={containerStyle}>
      <Title>{title}</Title>
      <List
        isLoading={isLoading}
        type={type}
        schedules={schedules}
        emptyTitle={emptyTitle}
        emptySubtitle={emptySubtitle}
        emptyActionLabel={emptyActionLabel}
        selectedHighlightId={selectedHighlightId}
        listContainerHeight={listContainerHeight}
        handleClickScheduleItem={handleClickScheduleItem}
        handleClickAddSchedule={handleClickAddSchedule}
      />
    </Container>
  );
}
