import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import api from '../../service/api.service';

function useJobOrderInfo() {
  const params = useParams();

  const {
    joInfoData,
    refetch: refetchJOInfo,
    joInfoError,
    isFetchingJO,
    isLoadingJO,
  } = api.useGetJobOrderInfoQuery(params?.id ?? skipToken, {
    selectFromResult: ({ data, error, isFetching, isLoading }) => ({
      joInfoData: data?.jobOrder,
      joInfoError: error,
      isFetchingJO: isFetching,
      isLoadingJO: isLoading,
    }),
    refetchOnMountOrArgChange: true,
  });

  return {
    joInfoData,
    isFetching: isFetchingJO,
    joInfoError,
    refetchJOInfo,
    isLoadingJO,
  };
}
export type UseJobOrderInfoHookObj = ReturnType<typeof useJobOrderInfo>;
export default useJobOrderInfo;
