import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { fromUnixTime } from 'date-fns';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../component/molecule';
import { BulkActionEntity, BulkActionStatusEnum } from '../../constant';
import { JobOrder } from '../../model/JobOrder.model';
import { ErrorDetail } from '../../service/api.endpoint';
import { getDateFormat } from '../../util/date.util';
import {
  mapBulkActionStatusToLabel,
  mapErrorMessages,
} from '../../util/helper.util';
import useTranslator from '../useTranslator.hook';
import { UseJobOrderDeleteBulkLogic } from './useJobOrderDeleteBulkLogic.hook';

// #region INTERFACES
export type DeleteBulkJobOrderEntity = BulkActionEntity<JobOrder>;
export type Props = {
  bulkLogic: UseJobOrderDeleteBulkLogic;
};
// #endregion

export default function useJobOrderDeleteBulkTable({
  bulkLogic: { jobOrderWithStatusDetail, showStatus },
}: Props) {
  const translator = useTranslator();

  const columns = useMemo<ColumnDef<DeleteBulkJobOrderEntity>[]>(
    () => [
      {
        size: 200,
        header: translator.translate('JO Number'),
        accessorKey: 'number',
        cell: ({ getValue }) => {
          const number = getValue() as string;

          return (
            <CustomTableRowWithPopover
              primaryLabel={number ?? '-'}
              containerStyle={tw`max-w-[240px]`}
            />
          );
        },
      },

      {
        id: 'date',
        size: 150,
        header: translator.translate('Date'),
        accessorKey: 'date',
        cell: ({ getValue }) => {
          const date = getValue() as number;
          return (
            <CustomTableRowWithPopover
              primaryLabel={date ? getDateFormat(fromUnixTime(date)) : '-'}
              containerStyle={tw`max-w-[150px]`}
            />
          );
        },
      },
      {
        id: 'driver.fullName',
        size: 150,
        header: translator.translate('Driver Name'),
        accessorFn: (jo) => jo?.driver?.fullName,
        cell: ({ getValue }) => {
          const joDriver = getValue() as string | null;

          return (
            <CustomTableRowWithPopover
              primaryLabel={joDriver || '-'}
              containerStyle={tw`max-w-[150px]`}
            />
          );
        },
      },

      ...(showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({
                getValue,
              }: { getValue: () => string | ErrorDetail[] }) => {
                const accessor = getValue();
                const label =
                  typeof accessor === 'string'
                    ? translator.translate(accessor)
                    : mapErrorMessages(translator, accessor);

                return (
                  <CustomTableRowWithPopover
                    primaryLabel={label}
                    containerStyle={tw`max-w-[290px]`}
                  />
                );
              },
            },
          ]
        : []),
    ],
    [showStatus, translator],
  );

  const table = useReactTable({
    data: jobOrderWithStatusDetail,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
