import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import isWithinInterval from 'date-fns/isWithinInterval';
import React, { useCallback } from 'react';
import { useEvent, useWindowSize } from 'react-use';
import tw, { styled, theme } from 'twin.macro';
import { UseGetDriversAutocompleteObj } from '../../../hook/useGetDriversAutocomplete.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { DrivingContest } from '../../../model/DrivingContest.model';
import { Schedule } from '../../../model/Schedule.model';
import { indonesianPhoneNumberFormat } from '../../../util/schedule.util';
import { UseDrivingContestScheduleModalHookObj } from '../../../view/DrivingContest/hooks/useDrivingContestScheduleModal.hook';
import { Button, Divider, Icon, Text } from '../../atom';
import {
  AutoComplete,
  Modal,
  NativeCalendar,
  NativeCalendarRange,
} from '../../molecule';
import BaseScheduleItem from '../BaseScheduleItem/BaseScheduleItem.organism';

// #region STYLED
const Container = tw.div`w-full h-full max-h-screen flex flex-col bg-white`;
const Header = tw.header`px-5 py-2 min-h-[68px] flex justify-between items-center`;
const HeaderTitleContainer = tw.section`flex flex-col`;
const HeaderTitle = tw(Text.HeadingFour)`max-w-[400px] truncate`;
const HeaderPhoneNumber = tw(Text.BodyFourteen)`text-grey-two`;
const Body = tw.div`p-6 flex flex-col w-full overflow-auto h-[calc(100vh - 145px)]`;
const CalendarSection = styled.section(
  ({ isDateRangeOverlap }: { isDateRangeOverlap?: boolean }) => [
    tw`w-full flex mb-6`,
    isDateRangeOverlap && tw`mb-1`,
  ],
);
const SchedulesSection = tw.section`w-full flex flex-col rounded border border-beige-lines`;
const ScheduleAddButton = tw.button`flex items-center space-x-1`;
const ScheduleTitle = tw(
  Text.HeadingFour,
)`text-sm leading-6 px-4 py-2.5 border-b border-beige-lines bg-beige-bg`;
const ScheduleList = tw.ul`flex flex-col overflow-y-auto`;
const EmptyScheduleList = tw.div`flex flex-col justify-center items-center py-6`;
const EmptyScheduleHeading = tw(Text.HeadingFour)`font-semibold`;
const EmptyScheduleButton = tw(
  ScheduleAddButton,
)`mt-2 py-1 pl-2 pr-3 rounded flex items-center space-x-2 bg-orange`;
const EmptyScheduleSubtitle = tw(Text.BodyTwelve)`mt-1`;
const EmptyScheduleLinkText = tw(Text.HeadingFive)`font-semibold text-white`;
// --- ScheduleAddView ---
const HeaderAdd = tw(Header)`justify-start space-x-2.5`;
const DriverInputContainer = tw.section`flex items-center space-x-3 mb-6`;
const DriverInputLabel = tw(Text.MobileHeadingThree)`w-[140px]`;
const ScheduleDateLabel = tw(Text.MobileHeadingThree)`mt-6 mb-3`;
const ScheduleDatePlaceholderContainer = tw.section`w-full flex space-x-4 mb-4`;
const ScheduleDatePlaceholder = styled.div(
  ({ isSelected }: { isSelected: boolean }) => [
    tw`min-h-[56px] flex flex-col justify-center w-full px-4 py-2 rounded outline[1px solid #e4e0cc]`,
    isSelected && tw`outline[1px solid #F3532E]`,
  ],
);
const ScheduleDatePlaceholderTitle = tw(
  Text.BodyTwelve,
)`w-full text-grey-three`;
const ScheduleDatePlaceholderValue = styled(Text.TableParagraph)(
  ({ hasValue }: { hasValue: boolean }) => [hasValue && tw``],
);
const ScheduleDateErrorText = tw(Text.BodyTwelve)`mb-6 text-status-alert`;
const VehicleInputContainer = tw.section`flex items-center space-x-3`;
const VehicleInputLabel = tw(Text.MobileHeadingThree)`w-[140px]`;
const Footer = tw.footer`flex items-center justify-end mt-auto py-4 px-5`;
const FooterAddSchedule = tw(Button.Solid)`flex`;
// --- ScheduleAddUnsavedChagnesAlertModal ---
const ModalBodyContainer = tw.div`flex flex-col py-5`;
// #endregion

// #region TYPES

type CommonProps = {
  drivingContestScheduleModal: UseDrivingContestScheduleModalHookObj;
};

type Props = CommonProps & {
  driversAutocomplete: UseGetDriversAutocompleteObj;
};
// #endregion

function ScheduleListView({ drivingContestScheduleModal }: CommonProps) {
  const translator = useTranslator();
  const { height } = useWindowSize();
  const driver =
    drivingContestScheduleModal.selectedDriverContest as DrivingContest;

  const handleClickDay = (_day: Date) => {
    const idx = drivingContestScheduleModal.query.listData.findIndex(
      (_schedule) =>
        isWithinInterval(_day, {
          start: _schedule.from * 1_000,
          end: _schedule.to * 1_000,
        }),
    );

    if (!idx) return;
    drivingContestScheduleModal.handleClickScheduleItem(idx);
  };

  const renderScheduleItem = useCallback(
    (schedule: Schedule, idx: number) => {
      const start = format(schedule.from * 1000, 'dd MMM yyyy');
      const end = format(schedule.to * 1000, 'dd MMM yyyy');
      const date = `${start} - ${end}`;

      return (
        <BaseScheduleItem
          key={schedule.id}
          isSelected={drivingContestScheduleModal.selectedHighlightIdx === idx}
          labelTitle={date}
          valueTitle={schedule.vehicle.name}
          onClickScheduleItem={() =>
            drivingContestScheduleModal.handleClickScheduleItem(idx)
          }
        />
      );
    },
    [drivingContestScheduleModal],
  );

  return (
    <Container>
      <Header>
        <HeaderTitleContainer>
          <HeaderTitle>{driver.fullName}</HeaderTitle>
          <HeaderPhoneNumber>
            {indonesianPhoneNumberFormat(driver.phoneNumber)}
          </HeaderPhoneNumber>
        </HeaderTitleContainer>

        <ScheduleAddButton
          type="button"
          onClick={drivingContestScheduleModal.handleClickChangeViewToAdd}
        >
          <Icon.Add
            height={20}
            width={20}
            fill={theme`colors.orange.DEFAULT`}
          />
          <Text.TableParagraphPrimary>
            {translator.translate('Add Schedule')}
          </Text.TableParagraphPrimary>
        </ScheduleAddButton>
      </Header>

      <Divider />

      <Body>
        <CalendarSection>
          <NativeCalendar
            disableAction
            month={drivingContestScheduleModal.selectedMonth}
            highlights={drivingContestScheduleModal.query.highlights}
            selectedHighlight={drivingContestScheduleModal.selectedHighlight}
            rootStyle={tw`border-r-0 rounded-tr-none rounded-br-none`}
            handleClickDay={handleClickDay}
          />
          <NativeCalendar
            disableAction
            month={drivingContestScheduleModal.selectedMonth2}
            highlights={drivingContestScheduleModal.query.highlights}
            selectedHighlight={drivingContestScheduleModal.selectedHighlight}
            rootStyle={tw`rounded-tl-none rounded-bl-none`}
            handleClickDay={handleClickDay}
          />
        </CalendarSection>

        <SchedulesSection>
          <ScheduleTitle>{translator.translate('Schedules')}</ScheduleTitle>

          {drivingContestScheduleModal.query.listData.length === 0 && (
            <EmptyScheduleList>
              <Icon.NoDataFound />
              <EmptyScheduleHeading>
                {translator.translate(
                  "This Driver don't have any Schedule for Driver Contest",
                )}
              </EmptyScheduleHeading>
              <EmptyScheduleSubtitle>
                {translator.translate(
                  'Try to add schedule by clicking the button below:',
                )}
              </EmptyScheduleSubtitle>
              <EmptyScheduleButton
                type="button"
                onClick={drivingContestScheduleModal.handleClickChangeViewToAdd}
              >
                <Icon.Add height={20} width={20} fill={theme`colors.white`} />
                <EmptyScheduleLinkText>
                  {translator.translate('Add Schedule')}
                </EmptyScheduleLinkText>
              </EmptyScheduleButton>
            </EmptyScheduleList>
          )}

          {drivingContestScheduleModal.query.listData.length > 0 && (
            <ScheduleList
              style={{
                maxHeight: `calc(${height}px - (4.5rem + 64px + 360px + 45px))`,
              }}
            >
              {drivingContestScheduleModal.query.listData.map(
                renderScheduleItem,
              )}
            </ScheduleList>
          )}
        </SchedulesSection>
      </Body>
    </Container>
  );
}

function ScheduleAddView({
  drivingContestScheduleModal,
  driversAutocomplete,
}: Props) {
  const translator = useTranslator();
  const driver =
    drivingContestScheduleModal.selectedDriverContest as DrivingContest;

  return (
    <Container>
      <HeaderAdd>
        <button
          type="button"
          onClick={drivingContestScheduleModal.handleClickChangeViewToList}
        >
          <Icon.ArrowBack fill={theme`colors.orange.DEFAULT`} />
        </button>
        <Text.HeadingFour>
          {translator.translate('Add Schedule')}
        </Text.HeadingFour>
      </HeaderAdd>

      <Divider />

      <Body>
        <DriverInputContainer>
          <DriverInputLabel>{translator.translate('Driver')}</DriverInputLabel>

          <AutoComplete
            rootStyle={tw`w-full`}
            disabled
            value={driver.id}
            datas={driversAutocomplete.driverOptions}
            selectedOption={drivingContestScheduleModal.queryDriverOption}
            placeholder={translator.translate('Choose Driver')}
            loading={driversAutocomplete.driverListFetchLoading}
            changeInputText={driversAutocomplete.onChangeDriverAutotext}
            onFetchMore={driversAutocomplete.onFetchMoreDriver}
          />
        </DriverInputContainer>

        <Divider />

        <ScheduleDateLabel>
          {translator.translate('Schedule Date')}
        </ScheduleDateLabel>

        <ScheduleDatePlaceholderContainer>
          <ScheduleDatePlaceholder
            isSelected={
              !drivingContestScheduleModal.selectedVehicleId &&
              !!drivingContestScheduleModal.selectedDateRange.start &&
              !drivingContestScheduleModal.selectedDateRange.end
            }
          >
            {!!drivingContestScheduleModal.selectedDateRange.start && (
              <ScheduleDatePlaceholderTitle>
                {translator.translate('Start Date')}
              </ScheduleDatePlaceholderTitle>
            )}
            <ScheduleDatePlaceholderValue
              hasValue={!!drivingContestScheduleModal.selectedDateRange.start}
            >
              {drivingContestScheduleModal.selectedDateRange.start
                ? format(
                    drivingContestScheduleModal.selectedDateRange.start,
                    'dd MMM yyyy',
                  )
                : translator.translate('Start Date')}
            </ScheduleDatePlaceholderValue>
          </ScheduleDatePlaceholder>

          <ScheduleDatePlaceholder
            isSelected={
              !drivingContestScheduleModal.selectedVehicleId &&
              !!drivingContestScheduleModal.selectedDateRange.start &&
              !!drivingContestScheduleModal.selectedDateRange.end
            }
          >
            {!!drivingContestScheduleModal.selectedDateRange.end && (
              <ScheduleDatePlaceholderTitle>
                {translator.translate('End Date')}
              </ScheduleDatePlaceholderTitle>
            )}
            <ScheduleDatePlaceholderValue
              hasValue={!!drivingContestScheduleModal.selectedDateRange.end}
            >
              {drivingContestScheduleModal.selectedDateRange.end
                ? format(
                    drivingContestScheduleModal.selectedDateRange.end,
                    'dd MMM yyyy',
                  )
                : translator.translate('End Date')}
            </ScheduleDatePlaceholderValue>
          </ScheduleDatePlaceholder>
        </ScheduleDatePlaceholderContainer>

        <CalendarSection
          isDateRangeOverlap={
            drivingContestScheduleModal.isDateRangeOverlapHighlights
          }
        >
          <NativeCalendarRange
            disableMonthAndYearPicker
            disableDefinedRange
            disabledDays={drivingContestScheduleModal.query.disabledDays}
            defaultStartMonth={drivingContestScheduleModal.defaultDate.start}
            defaultEndMonth={addMonths(
              drivingContestScheduleModal.defaultDate.start,
              1,
            )}
            startDate={drivingContestScheduleModal.selectedDateRange?.start}
            endDate={drivingContestScheduleModal.selectedDateRange?.end}
            highlights={drivingContestScheduleModal.query.highlights}
            selectedHighlight={drivingContestScheduleModal.selectedHighlight}
            handleChangeDateRange={
              drivingContestScheduleModal.handleChangeDateRange
            }
          />
        </CalendarSection>

        {drivingContestScheduleModal.isDateRangeOverlapHighlights && (
          <ScheduleDateErrorText>
            {translator.translate('Cannot select overlapping schedule')}
          </ScheduleDateErrorText>
        )}

        <VehicleInputContainer>
          <VehicleInputLabel>
            {translator.translate('Vehicle')}
          </VehicleInputLabel>

          <AutoComplete
            hasExtraLabel
            labelKey="extraLabel"
            rootStyle={tw`w-full`}
            disabled={drivingContestScheduleModal.disableVehicleAutocomplete}
            value={drivingContestScheduleModal.selectedVehicleId}
            datas={
              drivingContestScheduleModal.vehiclesAutocomplete.vehicleOptions
            }
            selectedOption={drivingContestScheduleModal.queryVehicleOption}
            placeholder={translator.translate('Choose Vehicle')}
            loading={
              drivingContestScheduleModal.vehiclesAutocomplete.isFetching
            }
            changeData={drivingContestScheduleModal.handleChangeVehicleFilter}
            changeInputText={
              drivingContestScheduleModal.vehiclesAutocomplete
                .handleChangeVehicleAutotext
            }
            onFetchMore={
              drivingContestScheduleModal.vehiclesAutocomplete
                .handleFetchMoreVehicles
            }
            onRemoveData={drivingContestScheduleModal.handleRemoveVehicleFilter}
          />
        </VehicleInputContainer>
      </Body>

      <Divider />

      <Footer>
        <FooterAddSchedule
          type="button"
          disabled={
            drivingContestScheduleModal.addSheduleResponse.isLoading ||
            drivingContestScheduleModal.disableVehicleAutocomplete ||
            !drivingContestScheduleModal.selectedVehicleId
          }
          onClick={() =>
            void drivingContestScheduleModal.handleClickAddSchedule()
          }
        >
          {translator.translate(
            drivingContestScheduleModal.addSheduleResponse.isLoading
              ? 'Loading...'
              : 'Add Schedule',
          )}
        </FooterAddSchedule>
      </Footer>
    </Container>
  );
}

function ScheduleAddUnsavedChangesAlertView({
  drivingContestScheduleModal,
}: CommonProps) {
  const translator = useTranslator();

  const onBeforeunload = useCallback(
    (evt: BeforeUnloadEvent) => {
      if (drivingContestScheduleModal.hasUnsavedChanges) {
        evt.preventDefault();
        evt.returnValue = '';
      }
    },
    [drivingContestScheduleModal.hasUnsavedChanges],
  );

  const onCloseAlert = useCallback(() => {
    drivingContestScheduleModal.setDisplayAlert(false);
  }, [drivingContestScheduleModal]);

  const onDiscardChanges = useCallback(() => {
    for (const fn of drivingContestScheduleModal.leaveMethodSet.current) {
      fn(); // run the function
      drivingContestScheduleModal.leaveMethodSet.current.delete(fn); // cleanup the function from the cache
    }
  }, [drivingContestScheduleModal.leaveMethodSet]);

  useEvent('beforeunload', onBeforeunload, window, { capture: true });

  if (!drivingContestScheduleModal.displayAlert) return null;

  return (
    <Modal.WithClose
      css={tw`p-8 w-[760px]`}
      ctaStyle={tw`justify-between`}
      actionButtonContainer={tw`justify-between`}
      title={translator.translate('Are you sure you want to leave?')}
      callAction={{
        label: translator.translate('Discard Changes'),
        action: onDiscardChanges,
      }}
      cancelAction={{
        label: translator.translate('Stay on Page'),
        action: onCloseAlert,
      }}
      onClose={onCloseAlert}
      onOverlayClick={onCloseAlert}
    >
      <ModalBodyContainer>
        <Text.BodyOne tw="w-[650px]">
          {translator.translate(
            'It looks like you are in the middle of writing something and you have not saved the changes.  Unsaved changes will be lost',
          )}
        </Text.BodyOne>
      </ModalBodyContainer>
    </Modal.WithClose>
  );
}

export default function DrivingContestScheduleModal({
  drivingContestScheduleModal,
  driversAutocomplete,
}: Props) {
  if (drivingContestScheduleModal.currentView === 'list')
    return (
      <ScheduleListView
        drivingContestScheduleModal={drivingContestScheduleModal}
      />
    );

  return (
    <>
      <ScheduleAddView
        drivingContestScheduleModal={drivingContestScheduleModal}
        driversAutocomplete={driversAutocomplete}
      />

      <ScheduleAddUnsavedChangesAlertView
        drivingContestScheduleModal={drivingContestScheduleModal}
      />
    </>
  );
}
