import React from 'react';

export default function BoxWireframe() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none">
      <path
        fill="#fff"
        d="M8.667 14.5a.5.5 0 0 1-.25-.067l-5.196-3A.5.5 0 0 1 2.97 11V5a.5.5 0 0 1 .25-.433l5.196-3a.5.5 0 0 1 .5 0l5.196 3a.5.5 0 0 1 .25.433v6a.5.5 0 0 1-.25.433l-5.196 3a.5.5 0 0 1-.25.067Zm-4.696-3.789 4.696 2.711 4.696-2.71V5.288L8.667 2.578l-4.696 2.71v5.423Z"
      />
      <path
        fill="#fff"
        d="M8.667 14.5a.5.5 0 0 1-.5-.5V8a.5.5 0 0 1 1 0v6a.5.5 0 0 1-.5.5Z"
      />
      <path
        fill="#fff"
        d="M8.666 8.5a.495.495 0 0 1-.249-.067l-5.196-3a.5.5 0 1 1 .5-.866l5.196 3a.5.5 0 0 1-.25.933Z"
      />
      <path
        fill="#fff"
        d="M8.667 8.5a.5.5 0 0 1-.25-.933l5.196-3a.5.5 0 1 1 .5.866l-5.196 3a.497.497 0 0 1-.25.067Z"
      />
    </svg>
  );
}
