import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fillPath?: string;
};

export default function Runner({
  width = 166,
  height = 48,
  viewBox = '0 0 166 48',
  fill = 'none',
  fillPath = '#252525',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fillPath}
        fillRule="evenodd"
        d="M15.927 2.53c4.007-.103 10.19 2.053 14.3 6.182 4.1 4.121 6.153 10.233 6.153 14.295a1.078 1.078 0 002.156 0c0-4.604-2.26-11.27-6.782-15.815C27.24 2.655 20.488.256 15.872.375a1.078 1.078 0 00.055 2.155zm9.128 11.341c-2.635-2.648-6.608-4.026-9.143-3.961a1.063 1.063 0 01-.055-2.126c3.137-.08 7.67 1.537 10.705 4.587 3.044 3.059 4.563 7.534 4.563 10.65a1.063 1.063 0 01-2.127 0c0-2.582-1.316-6.51-3.943-9.15zm-9.181 3.382c1.08-.028 2.862.579 4.036 1.757 1.167 1.171 1.752 2.931 1.752 4.045 0 .569.46 1.03 1.026 1.03.567 0 1.026-.461 1.026-1.03 0-1.633-.783-3.926-2.352-5.501-1.563-1.568-3.884-2.403-5.54-2.36a1.028 1.028 0 00-1 1.056 1.028 1.028 0 001.052 1.003zM20.75 31.42c0 5.178-5.065 11.658-8.188 14.525h5.224v1.483H2.965v-1.483h5.222C5.065 43.08 0 36.6 0 31.421 0 25.528 4.645 20.75 10.375 20.75S20.75 25.528 20.75 31.421zm-10.375 4.15a4.446 4.446 0 100-8.892 4.446 4.446 0 000 8.892z"
        clipRule="evenodd"
      />
      <path
        fill={fillPath}
        d="M26.679 47.429h-4.447l6.315-16.418a4.447 4.447 0 014.15-2.85h17.696l-1.724 4.446H34.83c-1.89 0-2.223.741-2.964 2.223L26.68 47.43zM57.937 28.16H53.49L47.63 43.4c-.747 1.942.686 4.029 2.767 4.029h12.916a2.964 2.964 0 002.766-1.9l6.68-17.368h-4.446l-5.556 13.89c-.225.562-.77.931-1.376.931h-8.277a.741.741 0 01-.688-1.016l5.522-13.805zM72.625 47.429h-4.446l7.41-19.268h14.952c2.08 0 3.514 2.086 2.767 4.028l-5.862 15.24H83l5.116-12.79a1.482 1.482 0 00-1.377-2.032h-7.684a.74.74 0 00-.688.466l-5.742 14.356zM94.857 47.429h-4.446l7.41-19.268h14.952c2.081 0 3.514 2.086 2.767 4.028l-5.861 15.24h-4.447l5.116-12.79a1.482 1.482 0 00-1.376-2.032h-7.685a.741.741 0 00-.688.466l-5.742 14.356z"
      />
      <path
        fill={fillPath}
        fillRule="evenodd"
        d="M135.407 40.018l2.945-7.852c.727-1.938-.706-4.005-2.776-4.005h-14.99l-5.861 15.24c-.747 1.94.686 4.028 2.766 4.028h14.952l1.482-4.447h-12.286a1.483 1.483 0 01-1.4-1.97l.347-.994h14.821zm-11.355-7.41h8.674c.551 0 .909.579.663 1.072l-.536 1.072c-.251.502-.765.82-1.326.82h-9.162l.999-2.499a.74.74 0 01.688-.466z"
        clipRule="evenodd"
      />
      <path
        fill={fillPath}
        d="M140.063 47.429h-4.447l6.315-16.418a4.446 4.446 0 014.15-2.85h17.696l-1.724 4.446H147.8c-1.89 0-1.809.741-2.55 2.223l-5.187 12.599z"
      />
    </svg>
  );
}
