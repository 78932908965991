import * as Sentry from '@sentry/react';
import envConfig from '../../config/env/env.config';
import { ErrorCodes } from '../../constant';
import { getBaseErrorCode } from '../../util/error.util';

export const errorMonitoringService = Sentry;

export const initErrorMonitoring = (): void => {
  if (envConfig.mode === 'development') return;

  errorMonitoringService.init({
    environment: envConfig.mode,
    dsn: envConfig.sentryClientKey,
    normalizeDepth: 10,
    tracesSampleRate: 1.0,
  });
};
//
export const isErrorCanBeSkipped = (response?: unknown): boolean => {
  const errorCode = getBaseErrorCode(response);
  const skippedErrorCode = [
    ErrorCodes.DRIVER_LAST_POSITION_UNKNOWN,
    ErrorCodes.VEHICLE_LAST_POSITION_UNKNOWN,
    ErrorCodes.ERROR_CODE_NOT_AVAILABLE,
  ];

  try {
    return (
      (response as { [key: string]: unknown })?.error ===
        'TypeError: Network request failed' ||
      !!(errorCode && skippedErrorCode.includes(errorCode as ErrorCodes))
    );
  } catch (error) {
    return false;
  }
};

export const setMonitoringContext = ({
  organizationId,
  username,
}: {
  username: string;
  organizationId: string;
}) => {
  errorMonitoringService.setUser({
    organizationId,
    username,
  });
};

export const captureError = (
  action: string,
  params?: unknown,
  response?: unknown,
) => {
  if (isErrorCanBeSkipped(response)) return;
  if (envConfig.mode === 'development') return;

  errorMonitoringService.captureMessage(action, {
    extra: {
      params,
      response,
    },
  });
};

export default errorMonitoringService;
