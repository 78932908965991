import { useCallback, useMemo } from 'react';
import { JOFormStep } from '../../constant/JobOrder.constant';
import { formatText } from '../../util/tracking/trackingTimeline.util';
import useTranslator from '../useTranslator.hook';
import { UseJOFormControllerHookObj } from './useJOFormController.hook';
import useJOFormErrorTooltip from './useJOFormErrorTooltip.hook';
import { UseJOFormStateObj } from './useJOFormState.hook';

type Props = {
  controller: UseJOFormControllerHookObj;
  state: UseJOFormStateObj;
};

export default function useJOFormFooter({ state, controller }: Props) {
  const { translate } = useTranslator();

  const {
    isSecondaryHovered,
    tooltipPrimaryRef,
    tooltipSecondaryRef,
    tooltipPrimaryMapper,
    tooltipSecondaryMapper,
    isToolTipSecondaryVisible,
    isToolTipPrimaryVisible,
  } = useJOFormErrorTooltip({ state, controller });
  const soSelectionAssignedLabel = formatText(
    translate('%s of %s Assigned'),
    state.locationModalController.assignedLastMileList.length.toString(),
    state.soSelectionForm.selectionDataTotal.toString(),
  );

  const draftLabel = translate('Save as Draft');

  const action = useMemo(() => {
    let primaryActionLabel = 'Continue';
    let secondaryActionLabel = 'Cancel';
    let primaryActionDisabled = false;
    let primaryActionCount = 0;
    let primaryActionDisabledClickable = false;

    let handleClickPrimary = () => {
      controller.handleChangeFormState(JOFormStep.ASSIGNMENT);
    };
    if (controller.formStep === JOFormStep.HEADER) {
      primaryActionDisabled = !state.headerForm.isValid;
      handleClickPrimary = () => {
        controller.handleChangeFormState(JOFormStep.SO_SELECTION);
      };
    }

    if (
      controller.formStep === JOFormStep.SO_SELECTION &&
      state.isSOCandidateFormDisplayed
    ) {
      primaryActionLabel = translate('Add to Job Order');
      primaryActionCount = controller.soController.selectedSOIds.length;
      primaryActionDisabled = !controller.soController.selectedSOIds.length;
      secondaryActionLabel = '';
      handleClickPrimary = () => {
        void state.soCandidateForm.handleAddSOCandidate();
      };
    }
    if (controller.formStep === JOFormStep.ASSIGNMENT) {
      handleClickPrimary = () => {
        if (!tooltipPrimaryMapper.length) {
          void state.actionForm.handleSubmitForm({});
          return;
        }
        void state.handleValidate();
      };
      primaryActionLabel = state.joId ? 'Send to Driver' : 'Create JO';
      primaryActionDisabled = !!tooltipPrimaryMapper.length || state.isLoading;
      primaryActionDisabledClickable = !!tooltipPrimaryMapper.length;
    }

    return {
      primaryLabel: translate(primaryActionLabel),
      secondaryLabel: translate(secondaryActionLabel),
      primaryActionCount,
      primaryActionDisabled,
      primaryActionDisabledClickable,
      handleClickPrimary,
    };
  }, [
    state.actionForm,
    state.headerForm.isValid,
    state.isLoading,
    state.isSOCandidateFormDisplayed,
    state.joId,
    state.soCandidateForm,
    tooltipPrimaryMapper.length,
    controller.formStep,
    controller.soController.selectedSOIds.length,
    controller.handleChangeFormState,
    state.handleValidate,
    translate,
  ]);

  const isDraftDisabled = useMemo(() => {
    if (controller.formStep === JOFormStep.HEADER)
      return !state.headerForm.isValid || state.isLoading;
    return state.isLoading || !!state.joFormik.errors.travelExpenses;
  }, [
    controller.formStep,
    state.headerForm.isValid,
    state.isLoading,
    state.joFormik.errors.travelExpenses,
  ]);

  const handleSubmitDraft = useCallback(() => {
    void state.actionForm.handleSubmitForm({ isDraft: true });
  }, [state.actionForm.handleSubmitForm]);

  return {
    action,
    draftLabel,
    isDraftDisabled,
    isSecondaryHovered,
    tooltipPrimaryRef,
    tooltipSecondaryRef,
    tooltipPrimaryMapper,
    tooltipSecondaryMapper,
    step: controller.formStep,
    soSelectionAssignedLabel,
    isToolTipSecondaryVisible,
    isToolTipPrimaryVisible,
    handleSubmitDraft,
  };
}
