import { Locale } from './locale.config';

const settingLocaleConfig: Locale[] = [
  {
    en: 'Settings',
    id: 'Pengaturan',
  },
  {
    en: 'Advanced Settings',
    id: 'Pengaturan Lanjutan',
  },
  {
    en: 'Reset All Organization Data',
    id: 'Reset Semua Data Organisasi',
  },
  {
    en: 'Setting',
    id: 'Pengaturan',
  },
  {
    en: 'This action will delete all data on current organization.',
    id: 'Menghapus semua data yang ada pada organsisasi ini.',
  },
  {
    en: 'Save',
    id: 'Simpan',
  },
  {
    en: 'Reset',
    id: 'Reset',
  },
  {
    en: 'Display',
    id: 'Tampilan',
  },
  {
    en: 'Geofence',
    id: 'Geofence',
  },
  {
    en: 'Language',
    id: 'Bahasa',
  },
  {
    en: 'English',
    id: 'Inggris',
  },
  {
    en: 'Indonesian',
    id: 'Indonesia',
  },
  {
    en: 'Status Based on Geofence',
    id: 'Perubahan status dengan Geofence',
  },
  {
    en: 'Geofence helps your organization to automatically change status of your Shipper Order based on Geofence. This will applied to newly created Job Order.',
    id: 'Geofence membantu organisasi anda untuk merubah status Shipper Order secara otomatis. Pengaturan ini akan diterapkan pada perintah kerja yang baru.',
  },
  {
    en: 'Setting has been updated',
    id: 'Pengaturan berhasil diperbarui',
  },
  {
    en: 'Driver Management',
    id: 'Manajemen Sopir',
  },
  {
    en: 'Required Proof of Activity',
    id: 'Wajibkan Bukti Kirim',
  },
  {
    en: 'The driver must upload picture(s) with notes as proof of activity in order to complete the job order. This will be applied to newly created Job Order.',
    id: 'Sopir wajib mengisi bukti kirim berupa foto dan keterangan foto sebagai syarat untuk menyelesaikan perintah kerja. Pengaturan ini akan diterapkan pada perintah kerja yang baru.',
  },
  {
    en: "Allows upload photo from driver's gallery",
    id: 'Izinkan Sopir Mengambil Foto dari Galeri',
  },
  {
    en: 'Are you sure you want to reset current organization settings?',
    id: 'Apa Anda yakin ingin mengatur ulang setelan organisasi saat ini?',
  },
  {
    en: 'All settings will be reset to default value',
    id: 'Seluruh pengaturan akan di atur ulang menjadi pengaturan awal',
  },
  {
    en: 'Driver can take photo of proof of activity from phone gallery.',
    id: 'Sopir dapat mengambil foto bukti kirim dari galeri handphone sopir.',
  },
  {
    en: 'Site Marker',
    id: 'Penanda Lokasi',
  },
  {
    en: 'Please type',
    id: 'Silahkan ketik',
  },
  {
    en: 'User Guide',
    id: 'Panduan Pengguna',
  },
  {
    en: 'Confirmation Text',
    id: 'Teks Konfirmasi',
  },
  {
    en: 'Initializing Organization Data Reset...',
    id: 'Inisialisasi Reset Data Organisasi...',
  },
  {
    en: 'Please wait, reset process is in progress',
    id: 'Mohon menunggu, proses reset sedang berlangsung',
  },
  {
    en: 'An Unexpected Issue Occurred',
    id: 'Terjadi Masalah tidak Terduga',
  },
  {
    en: 'We are sorry, something went wrong. Please try again later.',
    id: 'Kami mohon maaf, terjadi sesuatu yang salah. Silahkan coba lagi nanti',
  },
  {
    en: 'This process may take a while',
    id: 'Proses ini mungkin memakan waktu cukup lama',
  },
  {
    en: 'to confirm.',
    id: 'untuk mengkonfirmasi',
  },
  {
    en: 'DELETE ALL DATA',
    id: 'HAPUS SEMUA DATA',
  },
  {
    en: 'Back to Setting',
    id: 'Kembali ke Pengaturan',
  },
  {
    en: 'Some of the conditions to perform this action is not fulfilled. You can try again later after all conditions is achieved.',
    id: 'Beberapa kondisi untuk menjalankan aksi ini belum terpenuhi. Anda dapat mencoba lagi kembali ketika semua kondisi telah terpenuhi.',
  },
  {
    en: 'Organization Data Reset',
    id: 'Reset Data Organisasi',
  },
  {
    en: 'Organization Data Reset Success!',
    id: 'Reset Data Organisasi Berhasil!',
  },
  {
    en: 'To perform data reset, these conditions must be fulfilled :',
    id: 'Untuk melakukan reset data, kondisi berikut harus dipenuhi:',
  },
  {
    en: 'No Shipper Order with status: Assigned, Delivering, In Transit, & Transiting.',
    id: 'Tidak ada Shipper Order dengan status: Ditugaskan, Dalam Pengiriman, Sedang Transit, & Perjalanan Transit.',
  },
  {
    en: 'No Job Order with status: Assigned & Delivering.',
    id: 'Tidak ada Perintah Kerja dengan status: Ditugaskan & Dalam Pengiriman.',
  },
  {
    en: 'No Hauling Order with status: Assigned & Delivering.',
    id: 'Tidak ada Hauling Order dengan status: Ditugaskan & Dalam Pengiriman.',
  },
  {
    en: 'No Hauling Job Order with status: Assigned & Delivering.',
    id: 'Tidak ada Hauling Job Order dengan status: Ditugaskan & Dalam Pengiriman.',
  },
  {
    en: 'No Driver Contest Participant.',
    id: 'Tidak ada peserta Driver Contest.',
  },
  {
    en: 'Failed to Reset Setting.',
    id: 'Gagal Melakukan Reset Pengaturan.',
  },
  {
    en: 'Failed to Reset Data.',
    id: 'Gagal Melakukan Reset Data.',
  },
  {
    en: 'Confirmation word does not match',
    id: 'Kata konfirmasi tidak cocok',
  },
  {
    en: 'Failed to Generate Reset Token.',
    id: 'Gagal Membuat Token Reset.',
  },
  {
    en: 'Status based on Site Marker',
    id: 'Status berdasarkan Penanda Lokasi',
  },
  {
    en: 'Always Use Transit Form',
    id: 'Selalu Gunakan Formulir Transit.',
  },
  {
    en: 'By enabling this, every new Shipper Order will always use transit form by default.',
    id: 'Dengan mengaktifkan ini, setiap Shipper order baru akan selalu menggunakan formulir transit sebagai setelan standard.',
  },
  {
    en: 'Site Marker helps your organization to automatically change status of your Shipper Order based on site radius. This will applied to newly created Job Order.',
    id: 'Penanda Lokasi membantu organisasi anda untuk merubah status Shipper Order secara otomatis. Pengaturan ini akan diterapkan pada perintah kerja yang baru.',
  },
  {
    en: 'Successfully Reset Setting.',
    id: 'Berhasil Reset Pengaturan.',
  },
];

export default settingLocaleConfig;
