import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterDriverAppCompleteJORouteParam = DefaultRouteParam;

export const helpCenterDriverAppCompleteJORoute: RouteConfig = {
  name: 'Help Center Driver App Complete JO',
  Component: React.lazy(() => import('./HelpCenterDriverAppCompleteJO.view')),
  path: '/help-center/driver-app/complete-jo',
  isPrivate: false,
  breadcrumb: 'Help Center Driver App Complete JO',
  options: {
    label: 'Menyelesaikan Perintah Kerja',
    testID: 'MenuHelpCenterDriverAppCompleteJO',
  },
};
