import React, { ReactElement, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import {
  getVehicleMeasurementStatus,
  getVehicleMeasurementValue,
  getVehicleStatus,
  vehicleStatusLabelMapper,
  VehicleStatusTheme,
} from '../../../constant/Vehicle.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { Vehicle } from '../../../model/Vehicle.model';
import { CubeOutline, Info, WeightOutline } from '../../atom/Icon/Icon.atom';
import { BodyFourteen, BodyTwelve } from '../../atom/Text/Text.atom';
import { ExtraStyle } from '../../Type.component';
import Tooltip from '../Tooltip/Tooltip.molecule';

const Container = tw.div`flex flex-1 bg-grey-six rounded`;

const AlertContainer = styled.div(
  ({ status }: { status?: VehicleStatusTheme }) => [
    tw`-mt-0.5`,
    status === VehicleStatusTheme.GREEN && tw`hidden`,
    status === VehicleStatusTheme.YELLOW && tw`text-semantic-warning`,
    status === VehicleStatusTheme.RED && tw`text-semantic-error`,
  ],
);

const ItemContainer = tw.div`flex flex-col flex-1 px-3 max-w-[calc(100%/3)] my-2 not-last-of-type:(border-r border-r-beige-lines)`;
const MetaContainer = tw.div`flex gap-2 items-center`;
const PrefixContainer = tw.div`flex gap-1 items-center`;

const StatusBadgeContainer = tw.div`flex gap-1 items-center`;
const StatusBadgeIndicator = styled.div(
  ({ status }: { status?: VehicleStatusTheme }) => [
    tw`w-2.5 h-2.5 rounded-sm`,
    status === VehicleStatusTheme.GREEN && tw`bg-semantic-success`,
    status === VehicleStatusTheme.YELLOW && tw`bg-semantic-warning`,
    status === VehicleStatusTheme.RED && tw`bg-semantic-error`,
  ],
);
const StatusBadgeLabel = styled(BodyTwelve)(
  ({ status }: { status?: VehicleStatusTheme }) => [
    tw`text-grey-two font-semibold`,
    status === VehicleStatusTheme.GREEN && tw`text-semantic-success`,
    status === VehicleStatusTheme.YELLOW && tw`text-semantic-warning`,
    status === VehicleStatusTheme.RED && tw`text-semantic-error`,
  ],
);

type Props = {
  vehicle?: Vehicle;
  containerStyle?: ExtraStyle;
};

type ItemProps = {
  label: string;
  value?: string;
  prefixIcon?: ReactElement;
  suffixIcon?: ReactElement | null;
};

function StatusBadge({
  status,
  value,
}: { status?: VehicleStatusTheme; value?: string }) {
  return (
    <StatusBadgeContainer>
      <StatusBadgeIndicator status={status} />
      <StatusBadgeLabel status={status}>{value}</StatusBadgeLabel>
    </StatusBadgeContainer>
  );
}

function Item({ label, value, prefixIcon, suffixIcon }: ItemProps) {
  const tooltipRef = useRef(null);
  const [visible, setVisible] = useState(false);

  return (
    <ItemContainer>
      <BodyTwelve tw="text-grey-two">{label}</BodyTwelve>
      <MetaContainer>
        <PrefixContainer tw="text-grey-two flex-1 truncate">
          {prefixIcon}
          {!!value && (
            <BodyFourteen
              ref={tooltipRef}
              onMouseEnter={() => setVisible(true)}
              onMouseLeave={() => setVisible(false)}
              tw="text-grey-two truncate"
            >
              {value}
            </BodyFourteen>
          )}
          <Tooltip
            targetRef={tooltipRef}
            visible={visible}
            variant="light"
            placement="bottom"
          >
            <BodyFourteen tw="text-grey-two ">{value}</BodyFourteen>
          </Tooltip>
        </PrefixContainer>
        {suffixIcon}
      </MetaContainer>
    </ItemContainer>
  );
}

function AlertIcon({ status }: { status: VehicleStatusTheme }) {
  return (
    <AlertContainer status={status}>
      <Info width={14} height={14} />
    </AlertContainer>
  );
}
export default function VehicleSummaryInfo({ vehicle, containerStyle }: Props) {
  const { translate } = useTranslator();
  const status = translate(
    vehicleStatusLabelMapper(getVehicleStatus(vehicle?.summary?.status)),
  );
  const weight = getVehicleMeasurementValue({
    measurement: vehicle?.weight,
    measurementUsed: vehicle?.summary?.totalWeightUsed,
  });
  const volume = getVehicleMeasurementValue({
    measurement: vehicle?.volume,
    measurementUsed: vehicle?.summary?.totalVolumeUsed,
  });

  const weightStatus = getVehicleMeasurementStatus(
    vehicle?.weight,
    vehicle?.summary?.totalWeightUsed,
  );
  const volumeStatus = getVehicleMeasurementStatus(
    vehicle?.volume,
    vehicle?.summary?.totalVolumeUsed,
  );

  return (
    <Container css={containerStyle}>
      <Item
        label={translate('Status')}
        prefixIcon={
          <StatusBadge
            status={getVehicleStatus(vehicle?.summary?.status)}
            value={status}
          />
        }
      />
      <Item
        prefixIcon={<WeightOutline />}
        suffixIcon={<AlertIcon status={weightStatus} />}
        label={translate('Free Weight (kg)')}
        value={weight}
      />
      <Item
        prefixIcon={<CubeOutline />}
        suffixIcon={<AlertIcon status={volumeStatus} />}
        label={translate('Free Volume (m3)')}
        value={volume}
      />
    </Container>
  );
}
