import { MutableRefObject, useEffect, useState } from 'react';

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

function useIntersectionObserver<T extends Element>(
  elementRef: MutableRefObject<T | undefined | null>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false,
  }: Args,
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([_entry]: IntersectionObserverEntry[]): void => {
    setEntry(_entry);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: using `JSON.stringify(threshold)` instead of just `threshold`, because otherwise `updateEntry` won't run
  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return undefined;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => {
      observer.disconnect();
    };
  }, [elementRef, root, rootMargin, frozen, JSON.stringify(threshold)]);

  return entry;
}

export default useIntersectionObserver;
