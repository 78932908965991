import React from 'react';
import SODetailMobileTracking from '../../component/organism/SODetailMobileTracking/SODetailMobileTracking.organism';
import useSODetailMobileViewModel from './ShipperOrderDetail.mobile.viewModel';

export default function ShipperOrderDetailMobileView() {
  const { soDetail, trackingSO, trackingCode, handleCopyClick } =
    useSODetailMobileViewModel();
  return (
    <SODetailMobileTracking
      soId={soDetail.soInfo?.id}
      trackingSO={trackingSO}
      trackingCode={trackingCode}
      shipperPhoneNumber={soDetail.soInfo?.shipper.phoneNumber}
      headerTitle={soDetail.soInfo?.number}
      handleCopyClick={handleCopyClick}
    />
  );
}
