import React from 'react';

export default function EditOutlined({ fill }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      fill={fill || 'currentColor'}
      viewBox="0 0 16 20"
    >
      <path
        fill="#F3532E"
        d="M15.045 5.4c.378-.377.586-.88.586-1.413 0-.534-.208-1.036-.586-1.414L13.459.987A1.986 1.986 0 0012.045.4c-.534 0-1.036.208-1.413.585L0 11.585V16h4.413L15.045 5.4zm-3-3l1.587 1.586-1.59 1.584-1.586-1.585L12.045 2.4zM2 14v-1.585l7.04-7.018 1.586 1.586L3.587 14H2zm-2 4h16v2H0v-2z"
      />
    </svg>
  );
}
