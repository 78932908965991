import React from 'react';
import tw, { styled } from 'twin.macro';
import { Text } from '../../atom';
import { ExtraStyle } from '../../Type.component';

const Container = styled.div((props: { hasDivider?: boolean }) => [
  tw`flex items-center`,
  props.hasDivider && tw`border-b pb-2.5 border-beige-lines`,
]);
const InputContainer = tw.div`w-full`;
const InputWrapper = tw.div`flex flex-1 gap-5`;

type Props = React.PropsWithChildren<{
  label: string;
  input: React.ReactNode;
  required?: boolean;
  hasDivider?: boolean;
  containerStyle?: ExtraStyle;
  labelContainerStyle?: ExtraStyle;
  labelStyle?: ExtraStyle;
  secondaryInput?: React.ReactNode;
}>;

export default function InputGroup({
  input,
  label,
  required,
  hasDivider,
  containerStyle,
  labelContainerStyle = tw`min-w-[180px]`,
  labelStyle = tw`w-44`,
  secondaryInput,
}: Props) {
  return (
    <Container hasDivider={hasDivider} css={containerStyle}>
      <div css={labelContainerStyle}>
        <Text.Label css={labelStyle}>{`${label}${
          required ? '*' : ''
        }`}</Text.Label>
      </div>
      <InputWrapper>
        <InputContainer>{input}</InputContainer>
        {secondaryInput && <InputContainer>{secondaryInput}</InputContainer>}
      </InputWrapper>
    </Container>
  );
}
