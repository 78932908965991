import { format } from 'date-fns';
import getMonth from 'date-fns/getMonth';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setMonthFns from 'date-fns/setMonth';
import setYearFns from 'date-fns/setYear';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import DayPicker, {
  BeforeAfterModifier,
  Modifier,
  WeekdayElementProps,
} from 'react-day-picker';
import { useSelector } from 'react-redux';
import { TimeOutput } from 'react-timekeeper';
import tw, { css, styled } from 'twin.macro';
import { RootState } from '../../../app/store/store.app';
import useTranslator from '../../../hook/useTranslator.hook';
import { getMonthList } from '../../../util/date.util';
import { getDisabledTimeRange } from '../../../util/helper.util';
import {
  Button,
  Divider as BaseDivider,
  Icon,
  IconButton,
  Text,
} from '../../atom';
import { ClockPicker } from '../../molecule';

// #region INTERFACES
type State = {
  selection: 'day' | 'month' | 'year' | 'clock';
  date: Date | undefined;
  monthAndYear: Date;
  isValid: boolean;
};
export type RangeYear = {
  readonly from: number;
  readonly to: number;
};

type WrapperProps = {
  readonly date: Date | undefined;
  onClickCancel: () => void;
  onClickApply: (_date: Date) => void;
  readonly testID?: string;
  readonly isTimepickerValidationDisabled?: boolean;
  readonly disableTimePicker?: boolean;
  readonly disabledDays?: Modifier | Modifier[];
  readonly rangeYear?: RangeYear;
};

type DayPickerProps = {
  readonly monthAndYear: Date;
  readonly date: Date | undefined;
  onClickOpenMonth: () => void;
  setDate: (_date: Date) => void;
  handleChangeMonthAndYear: (_date: Date) => void;

  readonly disabledDays?: Modifier | Modifier[];
  readonly testID?: string;
};

type MonthPickerProps = {
  readonly monthAndYear: Date;
  onChangeMonth: (_monthIdx: number) => void;
  onClickOpenYear: () => void;

  readonly testID?: string;
};

type YearPickerProps = {
  readonly monthAndYear: Date;
  onChangeYear: (_year: number) => void;

  readonly rangeYear?: RangeYear;
  readonly testID?: string;
};
// #endregion

// #region STYLED COMPONENTS
const RootContainer = tw.div`bg-white rounded-lg overflow-hidden shadow-calendar text-black relative width[400px]`;
const HeaderContainer = styled.section(
  (props: { withClockPicker: boolean }) => [
    tw`bg-orange h-16 flex justify-center items-center w-full`,
    props.withClockPicker && tw`flex-col space-y-1 h-20`,
  ],
);
const HeaderText = tw(
  Text.HeadingFour,
)`text-grey-six font-normal hover:text-white hover:font-bold`;
const SubheaderText = tw(
  Text.Paragraph,
)`text-grey-six text-xs cursor-pointer hover:text-white hover:font-bold`;
const WrapperBodyContainer = tw.section`flex w-full border-b border-b-grey-five`;
const WrapperFooterContainer = tw.div`flex items-center px-5 py-2.5 justify-between`;
const Caption = tw.div`w-full flex items-center justify-center mb-5`;
const IconButtonContainer = tw(
  IconButton,
)`w-8 h-8 p-1 justify-center text-orange transform rotate-90`;

// #region YEAR PICKER STYLING
const YearsContainer = styled.div`
  ${tw`p-5 pb-2.5 w-full transition-opacity animate-fade-in`}

  & .YearPicker-Years {
    ${tw`height[260px] overflow-y-scroll relative`}

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  & .YearPicker-Years::-webkit-scrollbar {
    ${tw`hidden`}
  }

  & .YearPicker-Year {
    ${tw`h-16 w-full flex items-center justify-center border-solid border-0 border-b border-b-grey-five duration-200 hover:text-orange-two`}
  }

  & .YearPicker-Year--current {
    ${tw`font-size[32px] font-semibold text-orange`}
  }
`;
// #endregion

// #region MONTH PICKER STYLING
const TopDivider = tw(BaseDivider)`w-full mb-3.5`;
const Divider = tw(BaseDivider)`w-full my-3.5`;
const MonthsContainer = styled.div`
  ${tw`flex flex-wrap justify-between p-5 pb-2.5 w-full transition-opacity animate-fade-in`}

  & .MonthPicker-Month {
    ${tw`h-9 w-1/3 flex items-center justify-center z-index[1] rounded-full duration-200`}
  }

  & .MonthPicker-Month:not(.MonthPicker-Month--start):not(.MonthPicker-Month--end):hover,
  .MonthPicker-Month--selected:not(.MonthPicker-Month--start):not(.MonthPicker-Month--end) {
    ${tw`bg-orange-hover text-black cursor-pointer`}
  }
`;
// #endregion

// #region DAY PICKER STYLING
const DaysContainer = styled.div`
  ${tw`p-5 pb-2.5 w-full transition-opacity animate-fade-in`},

  .DayPicker {
    ${tw`font-sans font-size[14px] `}
  }

  > .DayPicker > .DayPicker-wrapper > .DayPicker-Months > .DayPicker-Month > .DayPicker-Caption {
    ${tw`hidden`}
  }

  & .DayPicker-WeekdaysRow,
  .DayPicker-Week {
    ${tw`flex justify-between my-2.5 overflow-hidden`}
  }

  & .DayPicker-Weekday,
  .DayPicker-Day {
    ${tw`h-9 w-10 flex items-center justify-center relative`}
  }

  & .DayPicker-Day div {
    ${tw`absolute h-9 w-9 flex items-center justify-center z-index[1] duration-200`}
  }

  & .DayPicker-Day--outside,
  .DayPicker-Day--disabled {
    ${tw`opacity-40 cursor-default`}
  }

  & .DayPicker-Day--selected div {
    ${tw`rounded-full bg-orange text-white`}
  }

  &
    .DayPicker-Day:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):hover {
    ${tw`bg-orange-hover cursor-pointer rounded-full`}
  }
`;

const DayTime = styled.div(() => [
  tw`relative`,

  css`
    &[data-is-today='true']:not(&[data-is-selected='true']) {
      ${tw`before:(absolute inset-0 rounded-full border-2 border-black)`}
    }
  `,
]);
// #endregion

// #endregion

// #region YEAR PICKER
export function Year({
  monthAndYear,
  onChangeYear,

  rangeYear,
  testID = '',
}: YearPickerProps) {
  // #region VALUES
  const scrollRef = createRef<HTMLDivElement>();

  const yearOptions = useMemo(() => {
    if (rangeYear) {
      const { from, to } = rangeYear;
      return Array.from(Array(to - from + 1).keys()).map((i) => i + from);
    }

    // Default year range 25 years to the past and 25 years to the future
    return Array.from(Array(50).keys()).map(
      (i) => i + new Date().getFullYear() - 25,
    );
  }, [rangeYear]);
  // #endregion

  // #region HANDLERS
  const handleYearClick = (_year: number): void => {
    onChangeYear(_year);
  };
  // #endregion

  // scroll year selection to current `rangeYear`
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        behavior: 'smooth',
        top:
          (document.getElementById(
            `YearPicker-year-${monthAndYear.getFullYear()}`,
          )?.offsetTop as number) - 65,
      });
    }
  }, [scrollRef, monthAndYear]);

  return (
    <YearsContainer>
      <Caption>
        <Text.HeadingFour>Year</Text.HeadingFour>
      </Caption>

      <div className="YearPicker-Years" ref={scrollRef}>
        {yearOptions.map((_year) => (
          <button
            type="button"
            key={`YearPicker-year-${_year}`}
            id={`YearPicker-year-${_year}`}
            className={`YearPicker-Year ${
              _year === monthAndYear.getFullYear()
                ? 'YearPicker-Year--current'
                : ''
            }`}
            onClick={() => handleYearClick(_year)}
            data-testid={`${testID}:DatePickerYearPicker`}
          >
            {_year}
          </button>
        ))}
      </div>
    </YearsContainer>
  );
}
// #endregion

// #region MONTH PICKER
export function Month({
  monthAndYear,
  onChangeMonth,
  onClickOpenYear,
  testID = '',
}: MonthPickerProps) {
  // #region VALUES
  const currentLanguage = useSelector(
    (state: RootState) => state.setting.currentLanguage,
  );
  const listMonth = useMemo(
    () => getMonthList(currentLanguage),
    [currentLanguage],
  );
  // #endregion

  // #region HANDLERS
  const handleMonthClick = (idx: number) => {
    onChangeMonth(idx);
  };
  // #endregion

  return (
    <MonthsContainer>
      <Caption>
        <Text.HeadingFour>{monthAndYear.getFullYear()}</Text.HeadingFour>
        <IconButtonContainer
          onClick={(e) => {
            e.stopPropagation();
            onClickOpenYear();
          }}
          data-testid={`${testID}:DatePickerMonthChevronButton`}
        >
          <Icon.ChevronSharp height={20} width={20} strokeWidth={3} />
        </IconButtonContainer>
      </Caption>

      <TopDivider />

      {listMonth.map((_month, idx) => (
        <React.Fragment key={`MonthPicker-Month-${_month}`}>
          <button
            type="button"
            className="MonthPicker-Month"
            css={[
              monthAndYear.getMonth() <= idx &&
                monthAndYear.getMonth() >= idx &&
                tw`bg-orange text-white`,
            ]}
            onClick={(e) => {
              e.stopPropagation();
              handleMonthClick(idx); // idx starts at 0, which equals to "January"
            }}
            data-testid={`${testID}:DatePickerMonthPicker`}
          >
            {_month.slice(0, 3)}
          </button>

          {idx % 3 === 2 && <Divider />}
        </React.Fragment>
      ))}
    </MonthsContainer>
  );
}
// #endregion

// #region DATE PICKER
function WeekdayElement({ weekday, localeUtils, locale }: WeekdayElementProps) {
  return (
    <div className="DayPicker-Weekday" role="columnheader">
      {localeUtils.formatWeekdayLong(weekday, locale).slice(0, 1)}
    </div>
  );
}

export function Day({
  monthAndYear,
  date,
  setDate,
  onClickOpenMonth,
  handleChangeMonthAndYear,

  disabledDays,
  testID = '',
}: DayPickerProps) {
  // #region HANDLERS
  const checkDisabledDayIsValid = (_date: Date) => {
    const typedDisabledDays = disabledDays as Partial<BeforeAfterModifier>;

    // check if "_date" is before typedDisabledDays.before
    if (
      typedDisabledDays?.before &&
      isBefore(_date, startOfDay(typedDisabledDays?.before))
    )
      return false;

    // check if "_date" is after typedDisabledDays.after
    if (
      typedDisabledDays?.after &&
      isAfter(_date, startOfDay(typedDisabledDays?.after))
    )
      return false;

    return true;
  };

  const handleDayClick = (dayDate: Date) => {
    const startDayOfDate = startOfDay(dayDate);

    handleChangeMonthAndYear(dayDate);

    // check if "startDayOfDate" is valid based on disabledDays
    if (!checkDisabledDayIsValid(startDayOfDate)) return;

    setDate(startDayOfDate);
  };

  const getCustomDataAttrs = useCallback(
    (_day: Date) => ({
      'data-datetime': format(_day, 'yyyy-MM-dd'),
      'data-is-today': isToday(_day),
      'data-is-selected': !!date && isSameDay(_day, date),
    }),
    [date],
  );
  // #endregion

  return (
    <DaysContainer>
      <Caption>
        <Text.HeadingFour>{format(monthAndYear, 'MMMM yyyy')}</Text.HeadingFour>
        <IconButtonContainer
          onClick={(e) => {
            e.stopPropagation();
            onClickOpenMonth();
          }}
          data-testid={`${testID}:DatePickerDayChevronButton`}
        >
          <Icon.ChevronSharp height={20} width={20} strokeWidth={3} />
        </IconButtonContainer>
      </Caption>

      <DayPicker
        canChangeMonth
        showOutsideDays
        disabledDays={disabledDays}
        month={monthAndYear}
        renderDay={(day) => (
          <DayTime
            // NOTE: generate custom data attributes used for styling specificity
            {...getCustomDataAttrs(day)}
          >
            {day.getDate()}
          </DayTime>
        )}
        selectedDays={date}
        onDayClick={handleDayClick}
        weekdayElement={WeekdayElement}
        data-testid={`${testID}:DatePickerDayPicker`}
      />
    </DaysContainer>
  );
}
// #endregion

// #region WRAPPER
export function Wrapper({
  date,
  testID = '',
  isTimepickerValidationDisabled = true,
  disableTimePicker = true,
  disabledDays,
  rangeYear,
  onClickCancel,
  onClickApply,
}: WrapperProps) {
  // #region VALUES
  const translator = useTranslator();

  const [state, setState] = useState<State>({
    date,
    selection: 'day',
    isValid: false,
    monthAndYear: date ?? startOfToday(),
  });
  const disabledTimeRange = isTimepickerValidationDisabled
    ? undefined
    : getDisabledTimeRange(
        (disabledDays as Partial<BeforeAfterModifier>)?.after ||
          (disabledDays as Partial<BeforeAfterModifier>)?.before,
        state.date,
      );
  const time = useMemo(() => {
    if (!state.date) return '00:00';

    const hour = state.date.getHours();
    const minute = state.date.getMinutes();
    return `${hour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}`;
  }, [state.date]);
  // #endregion

  // #region HANDLERS
  const handleClickDate = (_date: Date) => {
    const monthIdx = getMonth(_date);
    const newMonth = setMonthFns(state.monthAndYear, monthIdx);
    setState((prev) => ({ ...prev, date: _date, monthAndYear: newMonth }));
  };

  const handleClickTime = (timeOutput: TimeOutput) => {
    if (!state.date) return;

    const newDate = setMinutes(
      setHours(state.date, timeOutput.hour),
      timeOutput.minute,
    );
    setState((prev) => ({
      ...prev,
      isValid: timeOutput.isValid,
      date: newDate,
    }));
  };

  const handleClickCancelSubmit = () => {
    // set date to initial values
    setState((prev) => ({ ...prev, date }));
    onClickCancel();
  };

  const handleClickSubmit = () => {
    if (!state.date) return;

    if (!disableTimePicker && state.selection !== 'clock') {
      setState((prev) => ({ ...prev, selection: 'clock' }));
    } else {
      onClickApply(state.date);
    }
  };
  // #endregion

  return (
    <RootContainer>
      <HeaderContainer withClockPicker={!disableTimePicker}>
        <HeaderText
          data-testid={`${testID}:DatePickerHeaderText`}
          css={[
            (state.selection === 'day' ||
              state.selection === 'month' ||
              state.selection === 'year') &&
              tw`text-white font-bold`,
            !disableTimePicker && tw`cursor-pointer`,
          ]}
          onClick={() => {
            if (!disableTimePicker)
              setState((prev) => ({ ...prev, selection: 'day' }));
          }}
        >
          {!!state.date && format(state.date, 'd MMM yyyy')}
        </HeaderText>

        {!disableTimePicker && (
          <SubheaderText
            data-testid={`${testID}:DatePickerSubheaderText`}
            css={[state.selection === 'clock' && tw`text-white font-bold`]}
            onClick={() => {
              setState((prev) => ({ ...prev, selection: 'clock' }));
            }}
          >
            {!!state.date && format(state.date, 'HH:mm')}
          </SubheaderText>
        )}
      </HeaderContainer>

      <WrapperBodyContainer>
        {state.selection === 'clock' && (
          <ClockPicker.Provider initialState={{ time }}>
            <ClockPicker.PickerContainer>
              <ClockPicker.Picker
                onChangeTime={handleClickTime}
                disabledTimeRange={disabledTimeRange}
                testID={testID}
              />
            </ClockPicker.PickerContainer>
          </ClockPicker.Provider>
        )}

        {state.selection === 'day' && (
          <Day
            monthAndYear={state.monthAndYear}
            onClickOpenMonth={() => {
              setState((prev) => ({ ...prev, selection: 'month' }));
            }}
            setDate={handleClickDate}
            handleChangeMonthAndYear={(_monthYear) => {
              setState((prev) => ({ ...prev, monthAndYear: _monthYear }));
            }}
            disabledDays={disabledDays}
            date={state.date}
            testID={testID}
          />
        )}

        {state.selection === 'month' && (
          <Month
            monthAndYear={state.monthAndYear}
            onChangeMonth={(_monthIdx) => {
              const newMonth = setMonthFns(state.monthAndYear, _monthIdx);
              setState((prev) => ({
                ...prev,
                monthAndYear: newMonth,
                selection: 'day',
              }));
            }}
            onClickOpenYear={() => {
              setState((prev) => ({ ...prev, selection: 'year' }));
            }}
            testID={testID}
          />
        )}

        {state.selection === 'year' && (
          <Year
            monthAndYear={state.monthAndYear}
            onChangeYear={(_yearNumber) => {
              const newYear = setYearFns(state.monthAndYear, _yearNumber);
              setState((prev) => ({
                ...prev,
                monthAndYear: newYear,
                selection: 'day',
              }));
            }}
            rangeYear={rangeYear}
            testID={testID}
          />
        )}
      </WrapperBodyContainer>

      <WrapperFooterContainer>
        <Button.Outlined
          data-testid={`${testID}:DatePickerCancelButton`}
          disabled={!state.date}
          onClick={handleClickCancelSubmit}
        >
          {translator.translate('Cancel')}
        </Button.Outlined>

        <Button.Solid
          data-testid={`${testID}:DatePickerApplyButton`}
          disabled={
            !state.date || (state.selection === 'clock' && !state.isValid)
          }
          onClick={handleClickSubmit}
        >
          {translator.translate('Apply')}
        </Button.Solid>
      </WrapperFooterContainer>
    </RootContainer>
  );
}
// #endregion
