import useRunnerEventList from '../../hook/runnerEvent/useRunnerEventList.hook';
import useTranslator from '../../hook/useTranslator.hook';
import useDrivingContestRemoveConfirmationTable from './hooks/useDrivingContestRemoveConfirmationTable.hook';
import useDrivingContestRemoveLogic from './hooks/useDrivingContestRemoveLogic.hook';

export default function useDrivingContestRemoveViewModel() {
  const translator = useTranslator();

  const runnerEvent = useRunnerEventList();
  const logic = useDrivingContestRemoveLogic();
  const table = useDrivingContestRemoveConfirmationTable({ translator, logic });

  return {
    translator,
    runnerEvent,
    logic,
    table,
  };
}
