import React, {
  createRef,
  CSSProperties,
  Fragment,
  MutableRefObject,
  useMemo,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import ReactPaginate from 'react-paginate';
import tw, { styled, theme } from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { Icon, List, OutsideAlerter, Popover, Text } from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region INTERFACES
type TableRow = {
  [key: string]: React.ReactNode;
};
export type HeaderCellProps = {
  label: string;
  custom?: React.ReactNode;
  accessor: string;
  disableSort?: boolean;
  headerStyle?: CSSProperties;
  cellStyle?: CSSProperties;
  sortLabel?: {
    asc: string;
    desc: string;
  };
  sort?: {
    selected: boolean;
    asc: boolean;
  };
  onClickSort?: (asc: boolean) => void;
};
export type BaseProps = React.ComponentProps<'table'> & {
  columns: HeaderCellProps[];
  rows: TableRow[];
  showNumbering?: boolean;
  stickyHeader?: boolean;
  stickyColumn?: boolean;
  noDataPage?: React.ReactNode;
  renderExpandedPage?: (row: TableRow) => React.ReactNode;
  cellTwStyle?: ExtraStyle;
  showBottomBorderForLastRow?: boolean;
};
type Props = React.ComponentProps<'div'> &
  BaseProps & {
    noDataPage?: React.ReactNode;
    containerStyle?: ExtraStyle;
    rootContainerStyle?: ExtraStyle;
    pageLimit?: number;
    pageCurrent?: number;
    pageTotal?: number;
    dataTotal?: number;
    summaryHeader?: React.ReactNode;
    onPageClick?: (page: number) => void;
    onPageLimitClick?: (limit: number) => void;
  };
// #endregion

// #region STYLED
const Container = styled.div(() => [tw`rounded-lg shadow-card`]);
const TableContainer = styled.div(() => [
  tw`w-full rounded-lg scrollbar-width[thin]`,
  `::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #F8CBBD
  }
  `,
]);
const HTMLTable = styled.table(
  (props: { stickyHeader?: boolean; stickyColumn?: boolean }) => [
    tw`w-full font-sans font-size[14px] border-separate table-fixed whitespace-nowrap border-spacing[0]`,
    props.stickyHeader &&
      `& thead tr th {
        position: sticky !important;
        top: 0;
        z-index: 1;
      }`,
    props.stickyColumn &&
      `width: auto;
      & thead th {
        position: sticky;
        top: 0;
        z-index: 2;
        width: 25vw;
      }
      & tbody th {
        position: sticky;
        left: 0;
        z-index: 2;
      }
      & thead th:first-of-type {
        position: sticky;
        left: 0;
        z-index: 2;
      }
      & thead th:nth-of-type(2) {
        position: sticky;
        left: 69px;
        z-index: 2;
      }
      & thead th:nth-of-type(2)::after {
        content: ' ';
        width: 11px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -11px;
        background: linear-gradient(to right, rgba(49, 49, 49, 0.1), transparent);
      }
      & tr td:first-of-type {
        position: sticky;
        left: 0;
        z-index: 1;
      }
      & tr td:nth-of-type(2) {
        position: sticky;
        left: 69px;
        z-index: 1;
      }
      & tr td:nth-of-type(2)::after {
        content: ' ';
        width: 11px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -11px;
        background: linear-gradient(to right, rgba(49, 49, 49, 0.1), transparent);
      }`,
  ],
);
const NumberingHeaderCell = tw.th`bg-black width[30px] box-border`;
const Header = styled.th((props: { open?: boolean }) => [
  tw`border-0 border-b font-semibold px-5 py-4 bg-orange text-white text-left relative leading-4`,
  props.open &&
    tw`before:(content-[' '] absolute bottom-0 left-0 right-0 h-1 bg-orange-four)`,
]);
const SortIndicator = tw.span`ml-2`;
const NumberingCell = tw.td`text-right pr-1 bg-white group-hover:(bg-orange-hover) duration-200`;
const Cell = tw.td`align-top px-5 py-4 bg-white group-hover:(bg-orange-hover) duration-200 border-b border-beige-lines`;
const PaginationContainer = tw.div`rounded-b-lg flex flex-row items-center justify-between w-auto px-4 py-4 bg-white text-grey-three border-t border-beige-lines`;
const StyledReactPaginate = styled.div`
  display: flex;
  align-items: center;
  .pagination {
    display: flex;
    flex-direction: row;
  }
  .break-me {
    cursor: default;
  }
  .active {
    border-radius: 3px;
    border-color: transparent;
    background-color: #f3532e;
    color: white;
  }
  a[role='button'] {
    margin: 0 0.5rem;
  }
`;
const PaginationDropdown = tw.div`flex items-center space-x-1`;
const IconWrapper = styled.div(
  (props: { expanded?: boolean; disabled?: boolean }) => [
    tw`items-center p-1 -mx-1 rounded-full transform[rotate(90deg)] duration-200 text-current`,
    !props.disabled && tw`hover:bg-orange-hover`,
    props.expanded && tw`transform[rotate(-90deg)]`,
  ],
);
// #endregion

function HeaderCell({
  label,
  headerStyle,
  sortLabel,
  sort,
  onClickSort,
  custom,
  disableSort,
  accessor,
}: HeaderCellProps) {
  const [open, setOpen] = useState<boolean>(false);
  const ref = createRef<HTMLTableCellElement>();

  const handleHeaderClick = () => {
    if (onClickSort) {
      if (!sortLabel && disableSort) {
        if (sort?.selected) {
          onClickSort(!sort.asc);
        } else {
          onClickSort(false);
        }
      } else {
        setOpen(!open);
      }
    }
  };

  return (
    <Header
      key={accessor}
      ref={ref}
      style={headerStyle}
      onClick={handleHeaderClick}
      open={open}
    >
      <OutsideAlerter onClickAway={() => setOpen(false)}>
        {custom || label}
        {sort?.selected && (
          <SortIndicator>{sort.asc ? <>&uarr;</> : <>&darr;</>}</SortIndicator>
        )}
        {onClickSort && sortLabel && (
          <Popover
            targetRef={ref as MutableRefObject<null>}
            visible={open}
            tw="w-full text-black font-normal"
          >
            <List.Regular
              options={[
                { label: sortLabel.asc, value: 'asc' },
                { label: sortLabel.desc, value: 'desc' },
              ]}
              onClickItem={(option) => onClickSort(option.value === 'asc')}
            />
          </Popover>
        )}
      </OutsideAlerter>
    </Header>
  );
}

export function TableBase({
  columns,
  rows,
  showNumbering,
  stickyHeader,
  stickyColumn,
  noDataPage,
  renderExpandedPage,
  cellTwStyle,
  showBottomBorderForLastRow = false,
  ...props
}: BaseProps) {
  const [sortAccessor, setSortAccessor] = useState<string>();
  const [sortAsc, setSortAsc] = useState<boolean>(false);

  return (
    <>
      <HTMLTable
        stickyHeader={stickyHeader}
        stickyColumn={stickyColumn}
        {...props}
      >
        <thead>
          <tr>
            {showNumbering && <NumberingHeaderCell />}
            {columns.map(
              (
                {
                  label,
                  accessor,
                  headerStyle,
                  sortLabel,
                  disableSort,
                  custom,
                },
                idx,
              ) => (
                <HeaderCell
                  accessor={accessor}
                  key={`TableHeader-${accessor}`}
                  label={label}
                  headerStyle={{
                    ...headerStyle,
                    ...(rows.length === 0 && {
                      borderBottomWidth: '1px',
                    }),
                    ...(rows.length === 0 &&
                      idx === 0 && {
                        borderBottomLeftRadius: '0.5rem',
                      }),
                    ...(rows.length === 0 &&
                      idx === columns.length - 1 && {
                        borderBottomRightRadius: '0.5rem',
                      }),
                  }}
                  sortLabel={sortLabel}
                  custom={custom}
                  sort={
                    sortAccessor === accessor
                      ? {
                          selected: true,
                          asc: sortAsc,
                        }
                      : undefined
                  }
                  onClickSort={
                    disableSort
                      ? undefined
                      : (asc) => {
                          setSortAccessor(accessor);
                          setSortAsc(asc);
                        }
                  }
                />
              ),
            )}
          </tr>
        </thead>

        {rows && rows.length > 0 && (
          <tbody>
            {rows.map((row, rowIdx) => (
              <Fragment key={`TableRow_row_${rowIdx}`}>
                <tr className="group" tw="relative">
                  {showNumbering && <NumberingCell>{rowIdx + 1}</NumberingCell>}
                  {columns.map(({ accessor, cellStyle }, colIdx) => {
                    const sharedCellStyle: CSSProperties = {
                      ...cellStyle,
                      ...((rowIdx === 0 || colIdx === rows.length - 1) && {
                        borderTopWidth: 0,
                      }),
                      ...(showBottomBorderForLastRow && {
                        borderBottomWidth: '1px',
                        borderBottomColor: theme`colors.beige.lines`,
                      }),
                      ...(showBottomBorderForLastRow &&
                        rowIdx + 1 === rows.length &&
                        colIdx === 0 && {
                          borderBottomLeftRadius: '0.5rem',
                        }),
                      ...(showBottomBorderForLastRow &&
                        rowIdx + 1 === rows.length &&
                        colIdx + 1 === columns.length && {
                          borderBottomRightRadius: '0.5rem',
                        }),
                    };

                    if (
                      Object.keys(row).length !== columns.length &&
                      Object.keys(row).length === 1 &&
                      row[accessor]
                    )
                      return (
                        <Cell
                          tw="border-b-beige-lines pt-1 relative"
                          key={`TableRow_row_${rowIdx}_${accessor}`}
                          colSpan={columns.length}
                          style={sharedCellStyle}
                          css={cellTwStyle}
                        >
                          {row[accessor]}
                        </Cell>
                      );

                    return (
                      <Cell
                        tw="border-b border-t border-t-beige-lines relative"
                        key={`TableRow_row_${rowIdx}_${accessor}`}
                        style={sharedCellStyle}
                        css={cellTwStyle}
                      >
                        {row[accessor]}
                      </Cell>
                    );
                  })}
                </tr>

                {renderExpandedPage?.(row)}
              </Fragment>
            ))}
          </tbody>
        )}
      </HTMLTable>
      {rows && rows.length < 1 && noDataPage}
    </>
  );
}

function Table({
  columns,
  noDataPage,
  renderExpandedPage,
  rows,
  showNumbering,
  stickyHeader = true,
  stickyColumn,
  containerStyle,
  rootContainerStyle,
  pageLimit = 0,
  onPageLimitClick,
  pageCurrent = 0,
  pageTotal = 0,
  onPageClick,
  dataTotal = 0,
  summaryHeader,
  cellTwStyle,
  showBottomBorderForLastRow,
  ...props
}: Props) {
  const translator = useTranslator();

  const paginationRef = createRef<HTMLDivElement>();
  const [paginationDropdown, setPaginationDropdown] = useState<boolean>(false);

  const paginationOptions = useMemo(
    () =>
      ['10', '25', '50', '100'].map((option) => ({
        label: option,
        value: option,
      })),
    [],
  );

  const showingLeft = (pageCurrent - 1) * pageLimit + 1;

  const showingRight =
    pageCurrent * pageLimit < dataTotal ? pageCurrent * pageLimit : dataTotal;

  return (
    <Container css={rootContainerStyle}>
      <TableContainer css={containerStyle} {...props}>
        {summaryHeader}
        <TableBase
          showNumbering={showNumbering}
          columns={columns}
          rows={rows}
          stickyHeader={stickyHeader}
          stickyColumn={stickyColumn}
          cellTwStyle={cellTwStyle}
          showBottomBorderForLastRow={showBottomBorderForLastRow}
          renderExpandedPage={renderExpandedPage}
        />

        {rows && rows.length < 1 && noDataPage}
      </TableContainer>
      {rows && rows.length > 0 && onPageClick && onPageLimitClick && (
        <PaginationContainer>
          {!isMobile && (
            <PaginationDropdown ref={paginationRef} tw="flex">
              <Text.BodyTwo tw="font-semibold text-grey-three">
                {translator.translate('Show')}
                {': '}
                {pageLimit}
              </Text.BodyTwo>
              <IconWrapper
                expanded={paginationDropdown}
                onClick={(e) => {
                  e.stopPropagation();
                  setPaginationDropdown(!paginationDropdown);
                }}
              >
                <Icon.ChevronSharp height={18} width={18} strokeWidth={2.5} />
              </IconWrapper>
              <Popover
                visible={paginationDropdown}
                targetRef={paginationRef as MutableRefObject<null>}
                style={{ zIndex: 10 }}
              >
                <List.Small
                  tw="w-full mx-4"
                  options={paginationOptions}
                  onClickItem={(option) => {
                    onPageLimitClick?.(parseInt(option.value, 10));
                    setPaginationDropdown(false);
                  }}
                />
              </Popover>
            </PaginationDropdown>
          )}

          <StyledReactPaginate>
            <Text.Label>
              {`${showingLeft} - ${showingRight} ${translator.translate(
                'of',
              )} ${dataTotal}`}
            </Text.Label>

            <ReactPaginate
              onPageChange={(e: { selected: number }) => {
                onPageClick?.(e.selected + 1);
                setPaginationDropdown(false);
              }}
              forcePage={pageCurrent - 1}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={pageTotal || 0}
              previousLabel={null}
              nextLabel={null}
              breakClassName="break-me"
              containerClassName="pagination"
              activeClassName="active"
            />
          </StyledReactPaginate>
        </PaginationContainer>
      )}
    </Container>
  );
}

export default Table;
