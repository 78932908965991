import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../constant';
import {
  GetVehicleApiRequest,
  GetVehicleOrderBy,
  GetVehicleOrderByRecommended,
  Vehicle,
  VehicleOrderBy,
} from '../model/Vehicle.model';
import api from '../service/api.service';
import { AutocompleteType } from './useAutocomplete.hook';
import useDebounce from './useDebounce.hook';

const useGetVehiclesAutocomplete = (params?: GetVehicleOrderBy) => {
  const [searchParams, setSearchParams] = useState<string | undefined>(
    undefined,
  );
  const [vehicleRows, setVehicleRows] = useState<Vehicle[]>([]);
  const [page, setPage] = useState(1);
  const debouncedSearchData = useDebounce(searchParams, 500);
  const [fetchData, { data, isFetching }] = api.useLazyGetVehicleListQuery();

  // biome-ignore lint/correctness/useExhaustiveDependencies: TYPESCRIPT ASSERTION ISSUE
  const apiParams = useMemo<GetVehicleApiRequest>(
    () => ({
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      ...(params?.orderBy === VehicleOrderBy.NAME_ASC && {
        orderBy: params.orderBy,
      }),
      ...(params?.orderBy === VehicleOrderBy.RECOMMENDED && {
        orderBy: params.orderBy,
        orderVolume: params?.orderVolume ?? 0,
        orderWeight: params?.orderWeight ?? 0,
      }),
      ...(params?.orderBy === VehicleOrderBy.RECOMMENDED_VOLUME && {
        orderBy: params.orderBy,
        orderVolume: params?.orderVolume ?? 0,
      }),
      ...(params?.orderBy === VehicleOrderBy.RECOMMENDED_WEIGHT && {
        orderBy: params.orderBy,
        orderWeight: params?.orderWeight ?? 0,
      }),
    }),
    [
      params?.orderBy,
      (params as GetVehicleOrderByRecommended)?.orderVolume,
      (params as GetVehicleOrderByRecommended)?.orderWeight,
    ],
  );
  const hasNextPage = useMemo(
    () =>
      data?.metadata &&
      data.metadata.page * data.metadata.pageSize < data.metadata.totalCount,
    [data?.metadata],
  );
  const vehicleOptions = useMemo(
    () =>
      vehicleRows.map(
        (vehicle): AutocompleteType => ({
          label: vehicle.number || '',
          value: vehicle.id,
          extraLabel: vehicle.name,
        }),
      ),
    [vehicleRows],
  );

  const onRefetchVehicle = useCallback(() => {
    setPage(1);
    setSearchParams(undefined);
    void fetchData(apiParams);
  }, [apiParams, fetchData]);

  const onChangeVehicleAutotext = useCallback((val?: string) => {
    setSearchParams(val);
  }, []);

  const onFetchMoreVehicle = useCallback(() => {
    if (hasNextPage) {
      void fetchData({
        ...apiParams,
        ...(searchParams ? { search: searchParams } : {}),
        page: page + 1,
      });
      void setPage((v) => v + 1);
    }
  }, [apiParams, fetchData, hasNextPage, page, searchParams]);

  useEffect(() => {
    setPage(1);
    void fetchData({
      ...apiParams,
      ...(debouncedSearchData && { search: debouncedSearchData }),
    });
  }, [apiParams, debouncedSearchData, fetchData]);

  useEffect(() => {
    if (data) {
      const res = data.vehicles || [];
      setVehicleRows((loc) => uniqBy([...(page > 1 ? loc : []), ...res], 'id'));
    }
  }, [data, page]);

  return {
    vehicleRows,
    vehicleOptions,
    vehicleListFetchLoading: isFetching,
    onRefetchVehicle,
    onChangeVehicleAutotext,
    onFetchMoreVehicle,
  };
};

export type UseGetVehiclesAutocompleteObj = ReturnType<
  typeof useGetVehiclesAutocomplete
>;
export default useGetVehiclesAutocomplete;
