import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { SnackbarTheme } from '../../component/molecule/Snackbar/Snackbar.molecule';
import { SelectionSO } from '../../constant';
import { JOAssignmentBy } from '../../constant/JOAssignment.constant';
import { FormikHookProps } from '../../constant/Types.constant';
import { JobOrderForm } from '../../model/JobOrder.model';
import { logEvent } from '../../service/analytic/analytic.service';
import { ApiErrorResponse } from '../../service/api.endpoint';
import api from '../../service/api.service';
import {
  DeleteJobOrderApiRequest,
  PostJobOrderCreateParams,
} from '../../service/endpoint/jobOrder/jobOrder.endpoint';
import { snackbarAction } from '../../store/snackbar.store';
import {
  formatCreateParamsToUpdateParams,
  formatJobOrderFormToCreateParams,
  formatJobOrderFormToSubmissionModalProps,
  mapCustomJODeleteError,
} from '../../util/jobOrder.util';
import { formatText } from '../../util/tracking/trackingTimeline.util';
import useVerifyAuth from '../../view/Wrapper/hooks/useVerifyAuth.hook';
import useTranslator from '../useTranslator.hook';
import useJOFormHandler from './useJOFormHandler.hook';
import useJOFormSubmissionModal, {
  JOSubmissionModalProps,
} from './useJOFormSubmissionModal.hook';

type Props = {
  number?: string;
  joFormik: FormikHookProps<JobOrderForm>;
  selectionSOList: SelectionSO[];
  hasUnsavedChanges?: boolean;
  handleBack: () => void;
  setSelectionSOList: Dispatch<SetStateAction<SelectionSO[]>>;
  setIsLoading: (val: boolean) => void;
  setHasUnsavedChanges: (val: boolean) => void;
  handleValidate: () => Promise<boolean>;
};

type MutationProps = {
  val: PostJobOrderCreateParams;
  submissionModal?: JOSubmissionModalProps;
  isDraft?: boolean;
};

const analyticsMapper = {
  [JOAssignmentBy.DATE]: 'Date',
  [JOAssignmentBy.DRIVER]: 'Driver',
  [JOAssignmentBy.VEHICLE]: 'Vehicle',
};

export default function useJOFormAction({
  number,
  joFormik: { values },
  selectionSOList,
  hasUnsavedChanges,
  handleBack,
  setIsLoading,
  setSelectionSOList,
  setHasUnsavedChanges,
  handleValidate,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const location = useLocation();

  const { translate } = useTranslator();
  const { handleVerifyAuth } = useVerifyAuth();

  const joId = params?.id ?? '';

  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false);

  // #region API CALL
  const [createJOMutation, createJOMutationResponse] =
    api.useCreateJobOrdersMutation();
  const [updateJOMutation, updateJOMutationResponse] =
    api.useJobOrderUpdateMutation();
  const [triggerDeleteJO, deleteJOResponse] = api.useDeleteJobOrderMutation();
  // #endregion

  const submissionModal = useJOFormSubmissionModal();

  // #region HANDLER
  const {
    handleError,
    handleSuccessJOCreate,
    handleSuccessJOEdit,
    assignJOMutationResponse,
  } = useJOFormHandler({
    setJOSubmissionModalProps: submissionModal.setJOSubmissionModalProps,
    setIsLoading,
    setHasUnsavedChanges,
    setSelectionSOList,
  });
  // #endregion

  // #region DELETE
  const handleConfirmDelete = async () => {
    const payload: DeleteJobOrderApiRequest = {
      id: joId,
    };

    try {
      await triggerDeleteJO(payload).unwrap();

      // hide the blocker modal
      if (hasUnsavedChanges) {
        /**
         * NOTE:
         * I have no idea why we need to set state twice in order to make `handleBack()` works
         * If we only set state once it won't navigate back to /job-order and instead still showing JO detail
         */
        setHasUnsavedChanges(false);
        setHasUnsavedChanges(false);
      }

      handleBack();
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.light,
          message: formatText(
            translate('Successfully deleted %s'),
            number ?? 'Unknown number',
          ),
        }),
      );
    } catch (error) {
      const data = (error as FetchBaseQueryError)?.data as ApiErrorResponse;

      navigate(location?.pathname || '', { replace: true });
      setIsDeleteConfirmationVisible(false);
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translate(mapCustomJODeleteError(data.error.code)),
        }),
      );
    }
  };

  const handleOpenConfirmationDelete = useCallback(() => {
    setIsDeleteConfirmationVisible(true);
  }, []);
  const handleCloseConfirmationDelete = useCallback(() => {
    setIsDeleteConfirmationVisible(false);
  }, []);

  // #endregion

  // #region SUBMIT
  const submitForm = useCallback(
    async ({
      isDraft,
      mutation,
    }: {
      isDraft?: boolean;
      mutation: ({
        val,
        submissionModal,
        isDraft,
      }: MutationProps) => Promise<void>;
    }) => {
      try {
        if (values.driverOption && values.vehicleOption) {
          const assignmentAnalytics = values.assignmentType
            ? values.assignmentType
              ? `ScheduleBy${analyticsMapper[values.assignmentType]}`
              : 'BySchedule'
            : 'ByManually';
          logEvent(`JobOrder:Create:SelectAssignment${assignmentAnalytics}`);
        }
        const formattedValues = formatJobOrderFormToCreateParams({
          values,
          selectionSOList,
        });
        const submissionModal = formatJobOrderFormToSubmissionModalProps({
          values,
        });

        setIsLoading(true);

        await mutation({ val: formattedValues, isDraft, submissionModal });
      } catch (err) {
        setIsLoading(false);
        dispatch(
          snackbarAction.show({
            message: translate('Failed to Submit JO Form'),
          }),
        );
      }
    },
    [dispatch, selectionSOList, setIsLoading, translate, values],
  );

  const mutationCreate = useCallback(
    async ({ val, isDraft, submissionModal }: MutationProps) => {
      try {
        const response = await createJOMutation(val).unwrap();
        handleSuccessJOCreate({
          response,
          isDraft,
          submission: {
            ...submissionModal,
            joNumber: response.jobOrder.number,
          },
        });
      } catch (err) {
        handleError(err as FetchBaseQueryError);
      } finally {
        createJOMutationResponse.reset();
      }
    },
    [
      createJOMutationResponse,
      createJOMutation,
      handleError,
      handleSuccessJOCreate,
    ],
  );

  const mutationEdit = useCallback(
    async ({ val, isDraft, submissionModal }: MutationProps) => {
      try {
        const response = await updateJOMutation(
          formatCreateParamsToUpdateParams({ values: val, joId }),
        ).unwrap();
        handleSuccessJOEdit({
          response,
          id: joId,
          isDraft,
          submission: submissionModal,
        });
      } catch (err) {
        handleError(err as FetchBaseQueryError);
      } finally {
        updateJOMutationResponse.reset();
      }
    },
    [
      joId,
      updateJOMutationResponse,
      handleError,
      handleSuccessJOEdit,
      updateJOMutation,
    ],
  );

  const handleSubmitForm = useCallback(
    async ({ isDraft }: { isDraft?: boolean }) => {
      const isFormValid = await handleValidate();
      const authRes = await handleVerifyAuth();
      if (!authRes) return;
      if (!isFormValid && !isDraft) return;

      if (joId) {
        await submitForm({ mutation: mutationEdit, isDraft });
        return;
      }
      await submitForm({ mutation: mutationCreate, isDraft });
    },
    [
      joId,
      submitForm,
      mutationEdit,
      mutationCreate,
      handleValidate,
      handleVerifyAuth,
    ],
  );

  const isSubmitting = useMemo(
    () =>
      createJOMutationResponse.isLoading ||
      updateJOMutationResponse.isLoading ||
      assignJOMutationResponse.isLoading,
    [
      assignJOMutationResponse.isLoading,
      createJOMutationResponse.isLoading,
      updateJOMutationResponse.isLoading,
    ],
  );
  // #endregion

  return {
    joId,
    isSubmitting,
    deleteJOResponse,
    createJOMutationResponse,
    updateJOMutationResponse,
    assignJOMutationResponse,
    isDeleteConfirmationVisible,
    submissionModal,
    handleSubmitForm,
    handleOpenConfirmationDelete,
    handleCloseConfirmationDelete,
    handleConfirmDelete,
  };
}

export type UseJOFormActionObj = ReturnType<typeof useJOFormAction>;
