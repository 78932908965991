import { ShipperStatusFilter } from '../../constant/Shipper.constant';
import { UseTranslator } from '../../hook/useTranslator.hook';
import { ShipperOrderBy } from '../../model/Shipper.model';
import { ErrorDetail } from '../../service/api.endpoint';
import { snakeCasedToReadableFormat, template } from '../helper.util';

const literalUploadShipperLabel: Record<string, string> = {
  name: 'Shipper Name',
  email: 'Email Address',
};

export const convertShipperStatus = (status: ShipperStatusFilter) => {
  if (!status) return {};

  return { isActive: status === ShipperStatusFilter.ACTIVE };
};

export const convertShipperSortBy = (status: ShipperOrderBy) => {
  if (!status) return ShipperOrderBy.SHIPPER_NAME_ASC;
  return status;
};

/**
 * check bulk shipper add excel data validity
 */
export const checkShipperAddExcelDataValidity = (
  columns: unknown[],
  data: unknown[],
) => {
  const commonErrorLabel =
    'has invalid format. Please use provided XLS template and re-upload';
  const exceeded100ErrorLabel =
    'exceeded the maximum data allowed. Please reduce the data amount to a maximum of 100 rows';

  // check at least 1 data / data columns to be exactly 3
  if (!data.length || columns.length !== 3) return commonErrorLabel;
  // check max 100 data
  if (data.length > 100) return exceeded100ErrorLabel;

  return '' as const;
};

function mapCustomShipperErrorMessage(label: string) {
  return literalUploadShipperLabel[label];
}

/**
 * Map error messages to string
 * @param translator
 * @param errorDetails
 * @returns
 */
export function mapShipperErrorMessages(
  translator: UseTranslator,
  errorDetails: ErrorDetail[],
) {
  const errorMessages = errorDetails.map(({ key }) => {
    const message = 'Invalid {{label}}';
    const isCustomLabel = mapCustomShipperErrorMessage(key as string);
    const templated = template(message, {
      label: isCustomLabel ?? snakeCasedToReadableFormat(key as string),
    }); // e.g. output => Invalid License Number
    return translator.translate(templated);
  });

  return `${errorMessages.join(', ')}.`;
}
