import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import { jobOrderDetailRoute } from '../../../view/JobOrderDetail/JobOrderDetail.route';
import { UseSOCreateController } from '../../../view/ShipperOrderCreate/hooks/useSOCreateController.hook';
import { UseSOCreateForm } from '../../../view/ShipperOrderCreate/hooks/useSOCreateForm.hook';
import { UseSOJODetailForm } from '../../../view/ShipperOrderCreate/hooks/useSOJODetailForm.hook';
import { Icon, Text } from '../../atom';
import { CopyToClipboard, Tooltip } from '../../molecule';

// #region TYPES
type Props = {
  soCreateController: UseSOCreateController;
  soCreateForm: UseSOCreateForm;
  soJODetailForm: UseSOJODetailForm;
};
// #endregion

// #region STYLED
const Content = tw.div`w-full flex flex-col text-center items-center`;
const HeaderTitle = tw.section`w-full flex items-center justify-center space-x-2.5 py-6`;
const NumberContainer = tw.section`w-full flex border-t border-beige-lines`;
const TrackingNumberContainer = tw.div`px-6 py-4 w-1/2 space-y-1 flex flex-col items-center border-r border-beige-lines`;
const JONumberContainer = tw.div`px-6 py-4 space-y-1 w-1/2 flex flex-col items-center`;
const CopyClipboardContainer = tw.div`flex items-center space-x-3`;
const NumberTitle = tw(Text.BodyFourteen)`text-grey-two`;
const SuccessLabel = tw(
  Text.BodySixteen,
)`text-grey-two italic text-xs leading-5`;
const TooltipText = tw(Text.BodyFourteen)`whitespace-normal break-words`;
const SuccessLinkLabel = tw(
  Text.HeadingFour,
)`text-orange font-light text-xs leading-5`;
// #endregion

export default function SOCreateTrackingNumberModal({
  soCreateController: {
    isLoading,
    lastMileSuccessSubtitleLabel,
    trackingNumberModalTitleLabel,
    joNumberLabel,
    trackingNumberSuccessLabel,
    trackingOrderLabel,
    joNumberSuccessLabel,
    joDetailLabel,
  },
  soCreateForm: { linkToTrackerRoute },
  soJODetailForm: {
    createSoMutationResponse: { data: soData },
    createJOMutationResponse: { data: joData },
  },
}: Props) {
  const tooltipRef = useRef(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const trackingCode = soData?.shipperOrder.trackingCode ?? 'Loading...';
  const joNumber = joData?.jobOrder.number ?? 'unknown';
  const linkToJODetailRoute = `${jobOrderDetailRoute.path.replace(
    ':id',
    joData?.jobOrder.id ?? 'unknown',
  )}?breadcrumb=${joNumber}`;

  return (
    <Content>
      <HeaderTitle>
        {!isLoading && <Icon.CircleCheck2 />}
        <Text.HeadingThree
          css={[
            isLoading && tw`h-5 w-72 rounded-full animate-pulse bg-grey-four`,
          ]}
        >
          {!isLoading && lastMileSuccessSubtitleLabel}
        </Text.HeadingThree>
      </HeaderTitle>

      <NumberContainer>
        <TrackingNumberContainer>
          <NumberTitle
            css={[
              isLoading &&
                tw`h-5 w-32 mb-1 rounded-full animate-pulse bg-grey-four`,
            ]}
          >
            {!isLoading && trackingNumberModalTitleLabel}:
          </NumberTitle>

          <CopyClipboardContainer
            css={[
              tw`max-w-[300px]`,
              isLoading &&
                tw`h-10 w-52 rounded-full animate-pulse bg-grey-four`,
            ]}
          >
            {!isLoading && (
              <>
                <Tooltip
                  withPortal
                  variant="light"
                  placement="top-start"
                  targetRef={tooltipRef}
                  visible={isTooltipVisible}
                  containerStyle={tw`z-[1000]`}
                  contentStyle={tw`max-w-[640px]`}
                >
                  <TooltipText>{trackingCode}</TooltipText>
                </Tooltip>

                <Text.HeadingTwo
                  onMouseEnter={() => setIsTooltipVisible(true)}
                  onMouseLeave={() => setIsTooltipVisible(false)}
                  ref={tooltipRef}
                  tw="truncate"
                >
                  {trackingCode}
                </Text.HeadingTwo>
                <CopyToClipboard valueToCopy={trackingCode} />
              </>
            )}
          </CopyClipboardContainer>

          <SuccessLabel
            css={[
              isLoading && tw`h-5 w-72 rounded-full animate-pulse bg-grey-four`,
            ]}
          >
            {!isLoading && trackingNumberSuccessLabel}{' '}
            {!isLoading && (
              <Link
                to={`${linkToTrackerRoute}?receipt=${trackingCode}`}
                target="_blank"
              >
                <SuccessLinkLabel>{trackingOrderLabel}</SuccessLinkLabel>
              </Link>
            )}
          </SuccessLabel>
        </TrackingNumberContainer>

        <JONumberContainer>
          <NumberTitle
            css={[
              isLoading &&
                tw`h-5 w-32 mb-1 rounded-full animate-pulse bg-grey-four`,
            ]}
          >
            {!isLoading && joNumberLabel}:
          </NumberTitle>

          <CopyClipboardContainer
            css={[
              isLoading &&
                tw`h-10 w-52 rounded-full animate-pulse bg-grey-four`,
            ]}
          >
            {!isLoading && (
              <>
                <Text.HeadingTwo>{joNumber}</Text.HeadingTwo>
                <CopyToClipboard valueToCopy={joNumber} />
              </>
            )}
          </CopyClipboardContainer>

          <SuccessLabel
            css={[
              isLoading && tw`h-5 w-72 rounded-full animate-pulse bg-grey-four`,
            ]}
          >
            {!isLoading && joNumberSuccessLabel}{' '}
            {!isLoading && (
              <Link to={linkToJODetailRoute} target="_blank">
                <SuccessLinkLabel>{joDetailLabel}</SuccessLinkLabel>
              </Link>
            )}
          </SuccessLabel>
        </JONumberContainer>
      </NumberContainer>
    </Content>
  );
}
