import { Locale } from './locale.config';

const soListLocaleConfig: Locale[] = [
  {
    en: 'SO Date',
    id: 'Tanggal SO',
  },
  {
    en: 'Ref Number',
    id: 'Nomor Ref',
  },
  {
    en: 'SO Status',
    id: 'Status SO',
  },
  {
    en: 'Total Delivery Cost',
    id: 'Total Biaya Kirim',
  },
  {
    en: 'Pick up',
    id: 'Pick up',
  },
  {
    en: 'Drop off',
    id: 'Drop off',
  },
  {
    en: 'Payment Status',
    id: 'Status Pembayaran',
  },
  {
    en: 'Create',
    id: 'Buat',
  },
  {
    en: 'SO or Ref Number',
    id: 'Nomor SO atau Ref',
  },
  {
    en: 'Create Shipper Order',
    id: 'Buat Shipper Order',
  },
  {
    en: 'Payment History',
    id: 'Riwayat Pembayaran',
  },
  {
    en: 'Total Payment',
    id: 'Jumlah Pembayaran',
  },
  {
    en: 'Edit Payment History',
    id: 'Ubah Riwayat Pembayaran',
  },
  {
    en: 'Add Payment',
    id: 'Tambah Pembayaran',
  },
  {
    en: 'Remaining Receivables',
    id: 'Piutang',
  },
  {
    en: 'Date of Payment',
    id: 'Tanggal Pembayaran',
  },
  {
    en: 'Amount of Payment',
    id: 'Jumlah Pembayaran',
  },
  {
    en: 'Note',
    id: 'Catatan',
  },
  {
    en: 'Note must be at most 100 characters',
    id: 'Catatan maksimal 100 karakter',
  },
  {
    en: 'Add as New Payment Entry',
    id: 'Tambahkan Pembayaran Baru',
  },
  {
    en: 'Payment from',
    id: 'Tagihan dari',
  },
  {
    en: 'has been deleted',
    id: 'telah dihapus',
  },
  {
    en: 'You can search by SO Number, Ref Number, Location, or Shipper',
    id: 'Cari menggunakan Nomor SO, Nomor Ref, Lokasi atau Pengirim',
  },
  {
    en: 'You can search by SO Number, Tracking Number, or Shipper',
    id: 'Cari menggunakan Nomor SO, Nomor Resi, atau Pengirim',
  },
];

export default soListLocaleConfig;
