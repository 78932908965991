import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarTheme } from '../../../component/molecule/Snackbar/Snackbar.molecule';
import { SettingNavigationItemType } from '../../../constant/Setting.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { logEvent } from '../../../service/analytic/analytic.service';
import { snackbarAction } from '../../../store/snackbar.store';

type Props = {
  currentMenu: SettingNavigationItemType;
  handleSaveSetting: () => Promise<void>;
  handleResetForm: () => Promise<void>;
  toggleModal: (state?: SettingNavigationItemType) => void;
};
export default function useSettingResetConfirmation({
  currentMenu,
  handleSaveSetting,
  handleResetForm,
  toggleModal,
}: Props) {
  const dispatch = useDispatch();
  const { translate } = useTranslator();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const title = translate(
    'Are you sure you want to reset current organization settings?',
  );
  const description = translate('All settings will be reset to default value');

  const handleCloseModal = useCallback(() => {
    setIsModalVisible(false);
    toggleModal(currentMenu);
  }, [currentMenu, toggleModal]);

  const handleOverlayClick = useCallback(() => {
    if (isLoading) return;
    handleCloseModal();
  }, [handleCloseModal, isLoading]);

  const handleSubmitReset = useCallback(async () => {
    try {
      setIsLoading(true);
      toggleModal(undefined);
      logEvent('Settings:Advanced:TriggerResetAllOrganizationData');
      await handleResetForm();
      await handleSaveSetting();
      dispatch(
        snackbarAction.show({
          message: translate('Successfully Reset Setting.'),
        }),
      );
    } catch (err) {
      dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translate('Failed to Reset Setting.'),
        }),
      );
      toggleModal(currentMenu);
    } finally {
      setIsModalVisible(false);
      setIsLoading(false);
    }
  }, [
    currentMenu,
    dispatch,
    handleResetForm,
    handleSaveSetting,
    toggleModal,
    translate,
  ]);

  const modalAction = useMemo(
    () => ({
      label: translate('Reset'),
      isLoading,
      action: () => void handleSubmitReset(),
    }),
    [handleSubmitReset, isLoading, translate],
  );

  const modalCancelAction = useMemo(
    () => ({
      label: translate('Cancel'),
      disabled: isLoading,
      action: () => {
        handleCloseModal();
      },
    }),
    [translate, isLoading, handleCloseModal],
  );

  return {
    title,
    isLoading,
    modalAction,
    description,
    isModalVisible,
    modalCancelAction,
    handleCloseModal,
    setIsModalVisible,
    handleOverlayClick,
  };
}
