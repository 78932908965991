import { OverlayView } from '@react-google-maps/api';
import React from 'react';
import tw, { styled } from 'twin.macro';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import { LatLng } from '../../../model/Map.model';
import { getInitialFromName } from '../../../util/helper.util';
import { indonesianPhoneNumberFormat } from '../../../util/schedule.util';
import { Icon } from '../../atom';
import { CustomDriverMarker } from '../../atom/Icon/Icon.atom';
import {
  BodyTwelve,
  HeadingFive,
  HeadingThree,
} from '../../atom/Text/Text.atom';

type Props = {
  driverName?: string;
  driverPhoneNumber?: string;
  isOnline?: boolean;
  position: LatLng;
  handleClick?: () => void;
};

const Container = tw.div`ml-[-118px] mt-[-130px] flex flex-col-reverse items-center`;
const TooltipMetaContainer = tw.div`py-1 justify-between flex`;
const MetaPhoneContainer = tw.div`flex items-center`;
const PhoneIconContainer = tw.div`py-1 pr-2`;
const DriverMarkerContainer = tw.div`flex justify-center  w-16`;
const DriverInitialContainer = tw.div`absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 -mt-0.5`;
const DriverMarkerIndicatorContainer = tw.div`absolute top-3/4 right-0 -translate-y-2/4 -mt-0.5`;
const DriverMarkerRelativeContainer = tw.div`relative cursor-pointer`;

const DriverMarkerTooltipContainer = styled.div(
  (props: { isMobile?: boolean }) => [
    tw`w-60 rounded shadow bg-white py-1 px-2 mb-2 invisible peer-hover:visible`,
    props?.isMobile && tw`visible`,
  ],
);
const StatusBadgeContainer = styled.div((props: { isOnline?: boolean }) => [
  tw`flex items-center bg-status-alert-light rounded p-1.5`,
  props?.isOnline && tw` bg-status-success-light`,
]);
const StatusBadgeIndicator = styled.div((props: { isOnline?: boolean }) => [
  tw`rounded w-2.5 h-2.5 mr-1.5 bg-status-alert`,
  props?.isOnline && tw` bg-status-success`,
]);
const DriverMarkerStatusContainer = styled.div(
  (props: { isOnline?: boolean }) => [
    tw`w-3 h-3 rounded-2xl bg-status-alert-light flex justify-center items-center`,
    props?.isOnline && tw` bg-status-success-light`,
  ],
);
const DriverMarkerStatusIndicator = styled.div(
  (props: { isOnline?: boolean }) => [
    tw`w-2 h-2 rounded bg-status-alert`,
    props?.isOnline && tw`bg-status-success`,
  ],
);

export default function MarkerDriver({
  driverName,
  driverPhoneNumber,
  isOnline,
  position,
  handleClick = () => {},
}: Props) {
  const { isMobile } = useScreenDetection();
  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Container>
        <DriverMarkerContainer className="peer">
          <DriverMarkerRelativeContainer tw="relative" onClick={handleClick}>
            <DriverInitialContainer>
              <HeadingThree tw="text-orange">
                {getInitialFromName(driverName)}
              </HeadingThree>
            </DriverInitialContainer>
            <CustomDriverMarker />
            <DriverMarkerIndicatorContainer>
              <DriverMarkerStatusContainer isOnline={isOnline}>
                <DriverMarkerStatusIndicator isOnline={isOnline} />
              </DriverMarkerStatusContainer>
            </DriverMarkerIndicatorContainer>
          </DriverMarkerRelativeContainer>
        </DriverMarkerContainer>
        <DriverMarkerTooltipContainer isMobile={isMobile}>
          <div tw="truncate">
            <HeadingFive>{driverName}</HeadingFive>
          </div>
          <TooltipMetaContainer>
            <MetaPhoneContainer>
              <PhoneIconContainer>
                <Icon.Phone />
              </PhoneIconContainer>
              <BodyTwelve>
                {indonesianPhoneNumberFormat(driverPhoneNumber)}
              </BodyTwelve>
            </MetaPhoneContainer>
            <StatusBadgeContainer isOnline={isOnline}>
              <StatusBadgeIndicator isOnline={isOnline} />
              <BodyTwelve
                css={[
                  isOnline ? tw`text-status-success` : tw`text-status-alert`,
                ]}
              >
                {isOnline ? 'Online' : 'Offline'}
              </BodyTwelve>
            </StatusBadgeContainer>
          </TooltipMetaContainer>
        </DriverMarkerTooltipContainer>
      </Container>
    </OverlayView>
  );
}
