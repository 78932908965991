import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import { UseSOCreateController } from '../../../view/ShipperOrderCreate/hooks/useSOCreateController.hook';
import { UseSOCreateForm } from '../../../view/ShipperOrderCreate/hooks/useSOCreateForm.hook';
import { UseSOJODetailForm } from '../../../view/ShipperOrderCreate/hooks/useSOJODetailForm.hook';
import { shipperOrderDetailRoute } from '../../../view/ShipperOrderDetail/ShipperOrderDetail.route';
import { Icon } from '../../atom';
import {
  BodyFourteen,
  BodySixteen,
  HeadingFour,
  HeadingOne,
  HeadingThree,
} from '../../atom/Text/Text.atom';
import { CopyToClipboard, Tooltip } from '../../molecule';

// #region TYPES
type Props = {
  soCreateController: UseSOCreateController;
  soCreateForm: UseSOCreateForm;
  soJODetailForm: UseSOJODetailForm;
};
// #endregion

// #region STYLED
const TooltipText = tw(BodyFourteen)`whitespace-normal break-words`;
const Container = tw.div`flex justify-center`;
const Content = tw.div`w-[590px] py-6 flex flex-col text-center items-center`;
const TrackingNumberContainer = tw.div`py-3 flex`;
const TrackingNumberCopyIconContainer = tw.div`pl-3 self-center`;
const HeaderIcon = tw.div`mb-6`;
// #endregion

export default function SOCreateTrackingNumberModal({
  soCreateController: {
    isLoading,
    trackingNumberModalContentTitleLabel,
    trackingNumberModalContentDescriptionLabel,
    shipperOrderDetailLabel,
    orLabel,
    trackingOrderLabel,
  },
  soCreateForm: {
    linkToTrackerRoute,
    createSoMutationResponse: { data },
  },
  soJODetailForm: {
    createSoMutationResponse: { data: joData },
  },
}: Props) {
  const tooltipRef = useRef(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const trackingCode =
    data?.shipperOrder.trackingCode ??
    joData?.shipperOrder.trackingCode ??
    'Loading...';

  const linkToSODetailRoute = shipperOrderDetailRoute.path.replace(
    ':id',
    data?.shipperOrder.id ?? joData?.shipperOrder.id ?? 'unknown',
  );

  return (
    <Container>
      <Content>
        <HeaderIcon
          css={[
            isLoading && tw`h-20 w-20 rounded-full animate-pulse bg-grey-four`,
          ]}
        >
          {!isLoading && <Icon.CircleCheck />}
        </HeaderIcon>

        <HeadingThree
          css={[
            isLoading && tw`h-5 w-64 rounded-full animate-pulse bg-grey-four`,
          ]}
        >
          {!isLoading && trackingNumberModalContentTitleLabel}
        </HeadingThree>

        <TrackingNumberContainer
          css={[
            tw`max-w-[640px]`,
            isLoading &&
              tw`mt-5 mb-7 h-9 w-64 rounded-full animate-pulse bg-grey-four`,
          ]}
        >
          {!isLoading && (
            <>
              <Tooltip
                withPortal
                variant="light"
                placement="top-start"
                targetRef={tooltipRef}
                visible={isTooltipVisible}
                containerStyle={tw`z-[1000]`}
                contentStyle={tw`max-w-[640px]`}
              >
                <TooltipText>{trackingCode}</TooltipText>
              </Tooltip>

              <HeadingOne
                onMouseEnter={() => setIsTooltipVisible(true)}
                onMouseLeave={() => setIsTooltipVisible(false)}
                ref={tooltipRef}
                tw="truncate"
              >
                {trackingCode}
              </HeadingOne>

              <TrackingNumberCopyIconContainer>
                <CopyToClipboard valueToCopy={trackingCode} />
              </TrackingNumberCopyIconContainer>
            </>
          )}
        </TrackingNumberContainer>

        {isLoading ? (
          <>
            <BodySixteen tw="mb-3 h-4 width[500px] rounded-full animate-pulse bg-grey-four" />
            <BodySixteen tw="h-4 w-48 rounded-full animate-pulse bg-grey-four" />
          </>
        ) : (
          <BodySixteen tw="text-grey-two italic">
            {trackingNumberModalContentDescriptionLabel}
            <Link to={linkToSODetailRoute}>
              <HeadingFour tw="text-orange font-light">
                {' '}
                {shipperOrderDetailLabel}{' '}
              </HeadingFour>
            </Link>
            {orLabel}
            <Link
              to={`${linkToTrackerRoute}?receipt=${trackingCode}`}
              target="_blank"
            >
              <HeadingFour tw="text-orange font-light">
                {' '}
                {trackingOrderLabel}
              </HeadingFour>
            </Link>
          </BodySixteen>
        )}
      </Content>
    </Container>
  );
}
