import { theme } from 'twin.macro';
import { HomeDateRangeOptionLabel } from '../../constant/Home.constant';

export const getChartSummaryText = (index: string) => {
  const obj: Record<string, string> = {
    [HomeDateRangeOptionLabel.THIS_MONTH]: 'Average in this month',
    [HomeDateRangeOptionLabel.LAST_MONTH]: 'Average in last month',
    [HomeDateRangeOptionLabel.ONE_YEAR]: 'Average in last 1 year',
    [HomeDateRangeOptionLabel.SIX_MONTHS]: 'Average in last 6 months',
    [HomeDateRangeOptionLabel.THREE_MONTHS]: 'Average in last 3 months',
  };

  return obj[index];
};

export const getProfitPercentageTextColor = (profitPercentage: number) => {
  if (profitPercentage < 0) return theme`colors.deleted.dark`;
  if (profitPercentage > 0) return theme`colors.status.success`;

  return theme`colors.grey.placeholder`;
};

type BaseDataType = {
  id: string;
  value: string;
  valueStyle?: string;
};
type DataWithBullet = BaseDataType & {
  bulletStyle: string;
  withBullet: true;
};
type DataWithoutBulletS = BaseDataType & {
  withBullet: false;
};

type DataTypeS = DataWithBullet | DataWithoutBulletS;
export type GenerateChartTooltipParams = {
  data: DataTypeS[];
  title: string;
};
export const generateChartTooltip = ({
  title,
  data,
}: GenerateChartTooltipParams) => {
  let items = '';

  for (const v of data) {
    items += `
      <div style="display:flex; justify-content: center; align-items: center;">
        ${
          v.withBullet
            ? `<span style="margin-right:4px; border-radius: 2px; width:10px; height:10px; background-color:${v.bulletStyle}"></span>`
            : ''
        }
        <span style="font-size: 1rem; line-height: 1.5rem; font-weight: 400; color: ${
          v.valueStyle || ''
        }">
          ${v.value}
        </span>
      </div>
    `;
  }

  return `
  <div style="display:flex; flex-direction:column; justify-content: flex-start; align-items: flex-start; font-weight:700; min-width: max-content;">
    <div>${title}</div>
    ${items}
  </div>`;
};

export const calculateProfitPercentage = (
  revenue: number,
  netIncome: number,
) => {
  if (revenue > 0) {
    return parseFloat((Number(netIncome / revenue) * 100).toFixed(2));
  }
  return 0;
};
