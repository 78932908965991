import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import LocationAddBulkView from './DeliveryLocationAddBulk.view';

export type DeliveryLocationAddBulk = {
  'Nama Lokasi': string;
  'Titik Radius': string;
  Alamat: string;
  'Koordinat Lintang': string;
  'Koordinat Bujur': string;
  'Nama Kontak': string;
  'Nomor Telepon': string;
  Tipe: string;
  'Nama Pengirim': string;
  id?: string;
};

export type DeliveryLocationAddBulkRouteParam = {
  filename: string;
  locations: DeliveryLocationAddBulk[];
};

export const deliveryLocationAddBulkRoute: RouteConfig = {
  name: 'Import Delivery Location',
  Component: React.lazy(() => import('./DeliveryLocationAddBulk.view')),
  NormalComponent: <LocationAddBulkView />,
  path: '/delivery-location/add-bulk',
  isPrivate: true,
  props: {
    isUnclickable: true,
  },
};
