import fromUnixTime from 'date-fns/fromUnixTime';
import React, { useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import tw from 'twin.macro';
import { Text } from '../../../component/atom';
import { JobOrderStatus } from '../../../component/molecule';
import { ValueOf } from '../../../constant/Types.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import {
  JobOrderEvent,
  JobOrderEventUpdated,
} from '../../../model/JobOrder.model';
import api from '../../../service/api.service';
import { getFullDateTimeFormat } from '../../../util/date.util';
import { convertCamelToTitle } from '../../../util/helper.util';
import {
  mapJOStatusLabelToEnum,
  mapJOStatusToLabel,
} from '../../../util/jobOrder.util';
import { formatEventDataValue } from '../../../util/jobOrderDetail/jobOrderDetail.util';
import { JODetailEventHistory } from '../components/JODetailEventHistory';

export type UseJODetailEventHistory = ReturnType<
  typeof useJODetailEventHistory
>;

const customTitleModifier = (title: string) => {
  if (title === 'Travel Expense') return 'Travel Budget';
  return title;
};

export default function useJODetailEventHistory() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const translator = useTranslator();
  const { tab } = Object.fromEntries(searchParams);

  const query = api.useGetJobOrderEventListQuery(
    { joId: id as string },
    {
      skip: tab !== 'event-history', // `tab` is a typeof `JODetailTab`
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, ...rest }) => ({
        // NOTE: transition from status draft to assigned is not displayed
        events: (data?.events ?? []).filter(
          (event) => event.eventType !== 'ASSIGNED',
        ),
        ...rest,
      }),
    },
  );

  // to solve nested ternary eslint error. For event history type "UPDATED"
  const updatedMapper = useCallback(
    (
      key: string,
      val: Exclude<ValueOf<JobOrderEventUpdated['eventData']>, undefined>,
    ) => {
      let component: React.ReactNode;

      if (val.initial === null || val.initial === 0) {
        component = (
          <>
            {translator.translate(`${convertCamelToTitle(key)} has been added`)}
            : <strong>{formatEventDataValue(val.current)}</strong>
          </>
        );
      } else if (val.current === null || val.current === 0) {
        component = translator.translate(
          `${convertCamelToTitle(key)} has been removed`,
        );
      } else {
        component = (
          <>
            {translator.translate(
              `Changing ${customTitleModifier(convertCamelToTitle(key))} from`,
            )}{' '}
            <strong>{formatEventDataValue(val.initial)}</strong>
            {translator.translate(' to')}{' '}
            <strong>{formatEventDataValue(val.current)}</strong>
          </>
        );
      }

      return component;
    },
    [translator],
  );

  const componentMapper = useCallback(
    (event: JobOrderEvent, idx: number) => {
      const date = getFullDateTimeFormat(fromUnixTime(event.eventTime), true);
      const titleStyle = [tw`ml-5`, idx !== 0 && tw`text-grey-three`];

      switch (event.eventType) {
        case 'DELIVERED': {
          const initialStatusEnum = mapJOStatusLabelToEnum(
            event.eventData.status.initial,
          );
          const currentStatusEnum = mapJOStatusLabelToEnum(
            event.eventData.status.current,
          );

          return (
            <JODetailEventHistory.Item
              title={translator.translate('Status is updated')}
              date={date}
              key={event.eventTime}
              titleStyle={titleStyle}
            >
              <Text.BodyFourteen css={titleStyle}>
                {translator.translate('Job Order finished by')}{' '}
                {translator.translate(
                  event.eventData.causedBy === 'user'
                    ? 'admin'
                    : event.eventData.causedBy,
                )}{' '}
                {translator.translate('and status updated from')}
                <JobOrderStatus
                  status={initialStatusEnum}
                  statusLabel={translator.translate(
                    mapJOStatusToLabel(initialStatusEnum),
                  )}
                  containerStyle={tw`mx-1`}
                />
                {translator.translate(' to')}{' '}
                <JobOrderStatus
                  status={currentStatusEnum}
                  statusLabel={translator.translate(
                    mapJOStatusToLabel(currentStatusEnum),
                  )}
                  containerStyle={tw`ml-1`}
                />
              </Text.BodyFourteen>
            </JODetailEventHistory.Item>
          );
        }

        case 'TRAVEL_EXPENSE_UPDATED': {
          return (
            <JODetailEventHistory.Item
              title={translator.translate(
                'Total Expenses exceeded Travel Budget',
              )}
              date={date}
              key={event.eventTime}
              titleStyle={titleStyle}
            >
              <Text.BodyFourteen css={titleStyle}>
                {translator.translate('Travel Budget updated from')}{' '}
                <strong>
                  {formatEventDataValue(event.eventData.travelExpense.initial)}
                </strong>
                {translator.translate(' to')}{' '}
                <strong>
                  {formatEventDataValue(event.eventData.travelExpense.current)}
                </strong>
              </Text.BodyFourteen>
            </JODetailEventHistory.Item>
          );
        }

        case 'UPDATED': {
          return (
            <JODetailEventHistory.Item
              title={translator.translate('Detail is edited')}
              date={date}
              key={event.eventTime}
              titleStyle={titleStyle}
            >
              {Object.entries(event.eventData).map(([key, val], _idx, arr) => (
                <Text.BodyFourteen key={key} css={titleStyle}>
                  {arr.length === 1 ? '' : '• '}
                  {/* seal number and travel expense initial & current value could be `null` / `0`, because it is an optional field */}
                  {updatedMapper(key, val)}
                </Text.BodyFourteen>
              ))}
            </JODetailEventHistory.Item>
          );
        }

        case 'DELIVERING': {
          const initialStatusEnum = mapJOStatusLabelToEnum(
            event.eventData.status.initial,
          );
          const currentStatusEnum = mapJOStatusLabelToEnum(
            event.eventData.status.current,
          );

          return (
            <JODetailEventHistory.Item
              title={translator.translate('Status is updated')}
              date={date}
              key={event.eventTime}
              titleStyle={titleStyle}
            >
              <Text.BodyFourteen tw="flex items-center" css={titleStyle}>
                {translator.translate('Job Order started by')}{' '}
                {translator.translate(
                  event.eventData.causedBy === 'user'
                    ? 'admin'
                    : event.eventData.causedBy,
                )}{' '}
                {translator.translate('and status updated from')}{' '}
                <JobOrderStatus
                  status={initialStatusEnum}
                  statusLabel={translator.translate(
                    mapJOStatusToLabel(initialStatusEnum),
                  )}
                  containerStyle={tw`mx-1`}
                />
                {translator.translate(' to')}{' '}
                <JobOrderStatus
                  status={currentStatusEnum}
                  statusLabel={translator.translate(
                    mapJOStatusToLabel(currentStatusEnum),
                  )}
                  containerStyle={tw`ml-1`}
                />
              </Text.BodyFourteen>
            </JODetailEventHistory.Item>
          );
        }

        default:
          return (
            <JODetailEventHistory.Item
              title={translator.translate('Job Order assigned')}
              date={date}
              key={event.eventTime}
              titleStyle={titleStyle}
            />
          );
      }
    },
    [translator, updatedMapper],
  );

  const eventHistoryList = useMemo(
    () => query.events.map(componentMapper),
    [componentMapper, query.events],
  );

  return { query, eventHistoryList };
}
