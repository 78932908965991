import React from 'react';
import tw, { styled, theme } from 'twin.macro';
import { Button } from '../../../component/atom';
import { Check, Close } from '../../../component/atom/Icon/Icon.atom';
import {
  HeadingFive,
  HeadingThree,
} from '../../../component/atom/Text/Text.atom';
import { ShipperOrderStatus } from '../../../component/molecule';
import { JOSpendingTab } from '../../../constant/JobOrder.constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { Photo } from '../../../model/Common.model';
import { JobOrderExpenseStatus } from '../../../model/JobOrder.model';
import { mapJOSpendingTabToLabel } from '../../../util/jobOrder.util';
import { mapJODetailExpenseStatusNumberToLabel } from '../../../util/jobOrderDetail/jobOrderDetail.util';
import useJODetailSpendingDetail from '../hooks/useJODetailSpendingDetail.hook';
import JODetailSpendingContent from './JODetailSpendingContent';

// #region INTERFACE
type Props = {
  id?: string;
  handleApproveExpense: () => void;
  handleOpenRejectModal: (isDetail?: boolean) => void;
  handleClickThumbnail: (photos: Photo[], index: number) => void;
};
// #endregion

// #region STYLED
const Container = tw.div`w-[740px]`;
const Header = tw.div`pt-3 px-5`;
const HeaderRow = tw.div`flex gap-2 items-center`;
const Body = tw.div`border-t border-beige-lines px-5 pt-2 pb-5`;
const TabButton = styled.button(({ isSelected }: { isSelected?: boolean }) => [
  tw`px-2 py-3`,
  isSelected && tw`border-b-2 border-b-orange`,
]);
const TabLabel = styled(HeadingFive)(
  ({ isSelected }: { isSelected?: boolean }) => [
    tw`text-grey-two`,
    isSelected && tw`text-orange`,
  ],
);

const StatusStyle = tw`h-[30px]`;
const Footer = tw.div`px-5 py-3 flex gap-3 justify-end border-t border-beige-lines`;

const ApproveButtonStyle = tw`bg-green-500 pl-2.5 pr-3.5 py-1 h-11`;
const RejectButtonStyle = tw`bg-red-500 pl-2.5 pr-3.5 py-2 h-11`;
const ButtonLabelStyle = tw`text-white -mb-0.5 -ml-2 font-sans font-semibold text-base`;
// #endregion

export default function JODetailSpendingDetail({
  id,
  handleApproveExpense,
  handleOpenRejectModal,
  handleClickThumbnail,
}: Props) {
  const translator = useTranslator();
  const spendingVM = useJODetailSpendingDetail({ id, handleClickThumbnail });

  return (
    <Container>
      <Header>
        <HeaderRow>
          <HeadingThree>
            {spendingVM.spendingInfo.spendingData?.number}
          </HeadingThree>

          <ShipperOrderStatus
            containerStyle={StatusStyle}
            expenseStatus={spendingVM.spendingInfo.spendingData?.status}
            statusLabel={translator.translate(
              mapJODetailExpenseStatusNumberToLabel(
                spendingVM.spendingInfo.spendingData
                  ?.status as JobOrderExpenseStatus,
              ),
            )}
          />
        </HeaderRow>
        <HeaderRow>
          {Object.values(JOSpendingTab).map((item) => (
            <TabButton
              key={item}
              isSelected={item === spendingVM.tab}
              onClick={() => spendingVM.setTab(item)}
            >
              <TabLabel isSelected={item === spendingVM.tab}>
                {translator.translate(mapJOSpendingTabToLabel(item))}
              </TabLabel>
            </TabButton>
          ))}
        </HeaderRow>
      </Header>
      <Body>
        <JODetailSpendingContent
          detailContent={spendingVM.detailContent}
          tab={spendingVM.tab}
          timelines={spendingVM.spendingInfo.timelines}
        />
      </Body>
      {spendingVM.spendingInfo.spendingData?.status ===
        JobOrderExpenseStatus.PENDING && (
        <Footer>
          <Button.Icon
            containerStyle={RejectButtonStyle}
            labelStyle={ButtonLabelStyle}
            action={() => handleOpenRejectModal(true)}
            icon={<Close strokeColor={theme`colors.white`} />}
            label={translator.translate('Reject')}
          />
          <Button.Icon
            containerStyle={ApproveButtonStyle}
            labelStyle={ButtonLabelStyle}
            action={handleApproveExpense}
            icon={<Check stroke={theme`colors.white`} />}
            label={translator.translate('Approve')}
          />
        </Footer>
      )}
    </Container>
  );
}
