import * as Popover from '@radix-ui/react-popover';
import React, { PropsWithChildren } from 'react';
import 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { Text } from '../../atom';
import { ExtraStyle } from '../../Type.component';

type TableHeaderInfoPopoverProps = PropsWithChildren<{
  title: string;
  subtitle?: string;
  rootStyle?: ExtraStyle;
  rootPopoverStyle?: ExtraStyle;
}>;

export default function TableHeaderInfoPopover({
  title,
  subtitle,
  children,
  rootStyle,
  rootPopoverStyle,
}: TableHeaderInfoPopoverProps) {
  const translator = useTranslator();

  return (
    <Popover.Root>
      <div tw="flex items-center justify-between gap-2" css={rootStyle}>
        {translator.translate(title)}

        <Popover.Trigger>
          <Text.BodyFourteen tw="text-white cursor-default">
            ⓘ
          </Text.BodyFourteen>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content asChild side="top">
            <section
              tw="rounded py-2 px-3 z-10 bg-white"
              css={rootPopoverStyle}
            >
              {children}

              {subtitle && (
                <Text.Label tw="whitespace-pre-wrap">
                  {translator.translate(subtitle)}
                </Text.Label>
              )}

              <Popover.Arrow tw="fill-white" />
            </section>
          </Popover.Content>
        </Popover.Portal>
      </div>
    </Popover.Root>
  );
}
